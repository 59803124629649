/**
 * Learn more about deep linking with React Navigation
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */
import { LinkingOptions } from '@react-navigation/native';
import * as Linking from 'expo-linking';
import { Platform } from 'react-native';

import { RootStackParamList } from 'app/navigation/RootNavigator';

export const appURL = (path: string): string =>
  (__DEV__ || Platform.OS !== 'web' ? '' : '') + path;

const linking: LinkingOptions<RootStackParamList> = {
  prefixes: [Linking.createURL('/')],
  config: {
    screens: {
      AppInfo: appURL('/info'),
      Authentication: {
        screens: {
          AuthenticationStepEnterEmail: appURL('/auth/email'),
          AuthenticationStepVerifyEmail: appURL('/auth/verify'),
          AuthenticationStepUserData: appURL('/auth/user'),
          AuthenticationStepLinkPasses: appURL('/auth/link'),
        },
      },
      Chat: appURL('/chat'),
      Destinations: appURL('/destinations'),
      Feedback: appURL('/feedback'),
      Language: appURL('/language'),
      NotFound: appURL('*'),
      Notifications: appURL('/notifications'),
      Tabs: {
        screens: {
          ProductStack: {
            screens: {
              Product: appURL('/product/:productId'),
              ProductFAQ: appURL('/product/:productId/faq'),
              ProductOffers: appURL('/product/:productId/offers'),
            },
          },
          AttractionTicketsStack: {
            screens: {
              AttractionTickets: appURL('/product/:productId/attractions'),
              Attraction: appURL('/product/:productId/attraction/:attractionId'),
            },
          },
          Menu: appURL('/menu'),
        },
      },
      Purchases: appURL('/purchases'),
      Profile: appURL('/profile'),
      ReservationFlow: appURL('/make-reservation'),
      TermsConditions: appURL('/terms-conditions'),
      Welcome: appURL('/welcome'),
    },
  },
};

export default linking;
