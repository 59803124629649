import { Box, Center, Heading, HStack, Radio, Text, VStack } from 'native-base';
import React, { memo, useMemo } from 'react';

import Accordion from 'app/components/Accordion';
import TextWithTitle from 'app/components/TextWithTitle';
import { useI18n } from 'app/providers/I18nProvider';
import { Entitlement, ProductFamily, TicketClass } from 'app/services/GuestCenterService.types';
import { date } from 'app/services/ReservationHelper';
import { unslugify } from 'app/utils/string';

export type EntitlementCardProps = {
  entitlement: Entitlement;
  active: boolean;
  hideRadio?: boolean;
  onTouchEnd?: (entitlementKey: string) => void;
};

/**
 * Memoizing helps with the accordion animation when there are more than 2 entitlements.
 */
export default memo(
  function EntitlementCard({
    entitlement,
    active,
    hideRadio = false,
    onTouchEnd,
  }: EntitlementCardProps) {
    const { t } = useI18n();
    const sections = useMemo(() => {
      if (!entitlement) return [];
      const isALaCarte = entitlement.productLineup.familyCode === ProductFamily.Alacarte;
      const showOrderNumber = entitlement.travelParty && entitlement.travelParty.orderNumber;

      // hide expiration when invalid, a la carte or card travel parties with no order number
      const showExpiration = !hideRadio && !isALaCarte && showOrderNumber;

      // hide validity when invalid, a la carte or no usage window provided
      const showValidity =
        !hideRadio && !isALaCarte && entitlement.productLineup.usageWindowDays > 0;

      const handleTouchEnd = () => {
        if (onTouchEnd) onTouchEnd(entitlement.entitlementKey);
      };

      return [
        {
          id: entitlement.entitlementKey,
          initialOpen: false,
          title: (
            <HStack alignItems="center" flexShrink="0" space="1.5">
              {hideRadio ? null : (
                <Radio
                  _icon={{ size: 2 }}
                  accessibilityLabel={entitlement.entitlementKey}
                  borderWidth="1"
                  mr="1"
                  padding="1.5"
                  value={entitlement.entitlementKey}
                  onTouchEnd={handleTouchEnd}
                />
              )}
              <Box>
                <Heading fontSize="sm">
                  {entitlement.name || unslugify(entitlement.productLineup?.contentKey ?? '')}
                </Heading>
                <Text lineHeight="sm">
                  {t('app_ticket_class_summary', {
                    adult: entitlement.quantity[TicketClass.Adult],
                    child: entitlement.quantity[TicketClass.Child],
                  })}
                </Text>
              </Box>
            </HStack>
          ),
          content: (
            <VStack mb="3" ml="7" mt="3" space="3">
              {entitlement.travelParty && showOrderNumber && (
                <TextWithTitle color="text.500" selectable title={t('app_order_number_title')}>
                  {entitlement.travelParty.orderNumber}{' '}
                </TextWithTitle>
              )}
              <Box>
                {entitlement.travelParty && showExpiration && (
                  <TextWithTitle color="text.500" title={t('app_expiration_title')}>
                    {t('app_expiration_detail', {
                      date: date(entitlement.travelParty.firstUsageBy).format('MMM D, YYYY'),
                    })}
                  </TextWithTitle>
                )}
                {showValidity && (
                  <TextWithTitle color="text.500" title={t('app_validity_title')}>
                    {t('app_validity_detail', {
                      days: entitlement.productLineup.usageWindowDays.toString(),
                    })}
                  </TextWithTitle>
                )}
              </Box>
              {entitlement.passes.map((pass) => (
                <Box key={pass.barcode}>
                  {!!pass.name && (
                    <Text color="text.500" numberOfLines={1}>
                      {pass.name}
                    </Text>
                  )}
                  <TextWithTitle
                    bold={false}
                    color="text.500"
                    selectable
                    title={t('app_ticket_number_title')}
                  >
                    {pass.barcode}
                  </TextWithTitle>
                </Box>
              ))}
            </VStack>
          ),
          status: (
            <Center>
              {hideRadio && entitlement.travelParty && (
                <Heading fontSize="sm">
                  {t(`app_entitlement_status_${entitlement.travelParty.status}`)}
                </Heading>
              )}
            </Center>
          ),
          _status: {
            justifyContent: 'space-between',
            w: '90%',
          },
          _container: {
            borderTopWidth: 0,
            borderBottomWidth: 0,
            pb: 0,
          },
          _trigger: {
            py: 0,
          },
        },
      ];
    }, [entitlement, hideRadio, onTouchEnd, t]);

    return (
      <Box
        borderColor={active ? 'brand.primary' : 'brand.border'}
        borderWidth="2"
        p="3"
        rounded="md"
        testID="EntitlementCard"
        w="full"
      >
        <Accordion sections={sections} w="full" />
      </Box>
    );
  },
  (prev, next) => {
    return (
      false &&
      prev.entitlement.entitlementKey === next.entitlement.entitlementKey &&
      prev.entitlement.name === next.entitlement.name &&
      prev.active === next.active &&
      prev.hideRadio === next.hideRadio
    );
  }
);
