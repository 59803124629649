/* eslint-disable simple-import-sort/imports */
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import 'dayjs/locale/es';
import 'dayjs/locale/fr';
import 'dayjs/locale/it';
import 'dayjs/locale/ja';
import 'dayjs/locale/pt';
import 'dayjs/locale/zh';
/* eslint-enable simple-import-sort/imports */

import isToday from 'dayjs/plugin/isToday';
import localeData from 'dayjs/plugin/localeData';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import * as Font from 'expo-font';
import * as SplashScreen from 'expo-splash-screen';
import { useEffect, useState } from 'react';
import { QueryClient, useQueryClient } from 'react-query';

import { Locale, useGetContentQuery, useGetDestinationsQuery } from 'app/generated/hygraph';
import { fetcher as configFetcher, queryKey as configQueryKey } from 'app/hooks/useConfiguration';
import useStore, { useStorePersist } from 'app/hooks/useStore';
import { useI18n } from 'app/providers/I18nProvider';
import { remoteConfig } from 'app/services/Firebase';
import {
  getBaseURL as getGuestCenterBaseURL,
  setEnv as setGuestCenterEnv,
} from 'app/services/GuestCenterService';
import HygraphService from 'app/services/HygraphService';
import Logger from 'app/services/Logger';
import { toMs } from 'app/utils/time';
import { LogBox } from 'react-native';

const MODULE = '[useBootstrap]';
LogBox.ignoreLogs(['NativeBase: The contrast ratio of 1:1 for black on transparent']);

dayjs.extend(localeData);
dayjs.extend(isToday);
dayjs.extend(utc);
dayjs.extend(timezone);

const { client } = HygraphService();

/**
 * Prepares the app for startup: global dependencies, static assets,
 * loading locally cached data, prefetching remote data and config, etc.
 */
export default function useBootstrap() {
  const queryClient = useQueryClient();
  const { locale } = useI18n();
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    dayjs.locale(locale);
  }, [locale]);

  setGuestCenterClientEnv(queryClient);

  // Load any resources or data that we need prior to rendering the app
  useEffect(() => {
    (async () => {
      try {
        SplashScreen.preventAutoHideAsync();
        await bootstrap({ queryClient, locale });
      } catch (err) {
        Logger.error(`${MODULE} bootstrap failed`, { err });
      } finally {
        setIsReady(true);
        SplashScreen.hideAsync();
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { isReady, bootstrap: () => bootstrap({ queryClient, locale }) };
}

type BootstrapProps = {
  queryClient: QueryClient;
  locale: Locale;
};

function bootstrap({ queryClient, locale }: BootstrapProps) {
  return Promise.all([
    ...loadCachedData(),
    ...prefetchRemoteData({ queryClient, locale }),
    loadRemoteConfig(),
  ]);
}

function loadCachedData(): Promise<void>[] {
  const loadFonts = Font.loadAsync({
    /* eslint-disable global-require */
    ProximaNova: require('../../assets/fonts/ProximaNova.ttf'),
    'ProximaNova-Bold': require('../../assets/fonts/ProximaNova-Bold.ttf'),
    /* eslint-enable global-require */
  });
  const hydrateStore = new Promise<void>((resolve) => {
    let timeout: NodeJS.Timeout;
    const unsubscribe = useStorePersist.onFinishHydration(() => {
      Logger.debug('[bootstrap] store hydration finish');
      clearTimeout(timeout);
      resolve();
    });
    timeout = setTimeout(() => {
      Logger.debug('[bootstrap] store hydration timeout');
      unsubscribe();
      resolve();
    }, 3000);
  });

  return [loadFonts, hydrateStore];
}

function prefetchRemoteData({ queryClient, locale }: BootstrapProps): Promise<void>[] {
  const prefetchContent = queryClient.prefetchQuery(
    useGetContentQuery.getKey({ locale }),
    useGetContentQuery.fetcher(client, { locale })
  );
  const prefetchConfiguration = queryClient.prefetchQuery(configQueryKey, configFetcher);
  const prefetchDestinations = queryClient.prefetchQuery(
    useGetDestinationsQuery.getKey({ locale }),
    useGetDestinationsQuery.fetcher(client, { locale })
  );

  return [prefetchContent, prefetchDestinations, prefetchConfiguration];
}

function setGuestCenterClientEnv(queryClient: QueryClient) {
  const { env } = useStore.getState();
  const prevBaseURL = getGuestCenterBaseURL();
  setGuestCenterEnv(env);
  const hasChanged = getGuestCenterBaseURL() !== prevBaseURL;
  if (hasChanged) {
    queryClient.cancelQueries();
    queryClient.invalidateQueries();
    queryClient.clear();
  }
}

function loadRemoteConfig() {
  remoteConfig().setConfigSettings({
    minimumFetchIntervalMillis: __DEV__ ? toMs(5, 'm') : toMs(12, 'h'),
  });

  return remoteConfig()
    .fetchAndActivate()
    .catch((err) => {
      Logger.warn(`${MODULE} failed to fetch and activate remote config`, { err });
    });
}
