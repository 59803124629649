import { Text } from 'native-base';
import React, { ReactNode, useEffect, useState } from 'react';

import CPModal from 'app/components/CPModal';
import EntitlementsList from 'app/components/EntitlementsList';
import useAutoEntitlementSwitcher from 'app/components/EntitlementSwitcher/useAutoEntitlementSwitcher';
import { useI18n } from 'app/providers/I18nProvider';
import { tID } from 'app/services/TestHelper';
import { onTimeout } from 'app/utils/time';

export type EntitlementSwitcherProps = {
  children?: ReactNode;
};

export default function EntitlementSwitcher({ children }: EntitlementSwitcherProps) {
  const { t } = useI18n();
  const { mustChooseEntitlement, productEntitlements } = useAutoEntitlementSwitcher();
  const [render, setRender] = useState(false);

  // guard so that we're not rendering the modal when not needed
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (mustChooseEntitlement && !render) {
      setRender(true);
    } else if (!mustChooseEntitlement && render) {
      return onTimeout(() => setRender(false), 2000); // after modal closes
    }
  }, [mustChooseEntitlement, render]);

  if (!render) return null;

  return (
    <CPModal isOpen={mustChooseEntitlement} testID={tID('EntitlementSwitcher')}>
      <CPModal.Content>
        <CPModal.Header>{t('app_auth_tickets_linked_multiple_title')}</CPModal.Header>
        <CPModal.Body>
          <Text mb="4">{t('app_auth_tickets_select')}</Text>
          <EntitlementsList
            filter={(ent) => productEntitlements.some((key) => key === ent.entitlementKey)}
            showIntro={false}
          />
        </CPModal.Body>
      </CPModal.Content>
    </CPModal>
  );
}
