import { Box, Radio, VStack } from 'native-base';
import React, { useEffect, useMemo } from 'react';

import EntitlementCard from 'app/components/EntitlementsList/EntitlementCard';
import HTML from 'app/components/HTML';
import useGuest from 'app/hooks/useGuest';
import useStore from 'app/hooks/useStore';
import { useI18n } from 'app/providers/I18nProvider';
import { Entitlement } from 'app/services/GuestCenterService.types';
import { TranslationHelper } from 'app/services/I18n';

export type EntitlementsListProps = {
  onChange?: (entitlementKey: string) => void;
  filter?: (entitlement: Entitlement) => boolean;
  showInvalid?: boolean;
  showIntro?: boolean;
};

export default function EntitlementsList({
  onChange,
  filter,
  showIntro = true,
  showInvalid = false,
}: EntitlementsListProps) {
  const { t } = useI18n();
  const { email, entitlements, allEntitlements } = useGuest();
  const {
    entitlementKey,
    product: productId,
    setEntitlementKey,
    setProduct,
  } = useStore((s) => ({
    entitlementKey: s.entitlementKey,
    product: s.productId,
    setEntitlementKey: s.setEntitlementKey,
    setProduct: s.setProduct,
  }));

  const filteredEntitlements = useMemo(() => {
    const ents = !showInvalid ? entitlements : allEntitlements;
    return filter ? ents.filter(filter) : ents;
  }, [showInvalid, entitlements, allEntitlements, filter]);
  const messages = getIntroTexts({ count: filteredEntitlements.length, email, t });

  const handleChange = (newEntitlementKey: string) => {
    setEntitlementKey(newEntitlementKey);
    const newEntitlement = entitlements.find((ent) => ent.entitlementKey === newEntitlementKey);
    setProduct(newEntitlement?.productContentKey ?? null);
    if (onChange) onChange(newEntitlementKey);
  };
  const handleRadioTouchEnd = (newEntitlementKey: string) => {
    const reselectedCurrentEntitlement = newEntitlementKey === entitlementKey;
    if (reselectedCurrentEntitlement) {
      if (onChange) onChange(newEntitlementKey);
    }
  };

  useEffect(() => {
    if (
      !entitlementKey &&
      filteredEntitlements.length === 1 &&
      filteredEntitlements[0].entitlementKey
    ) {
      setEntitlementKey(filteredEntitlements[0].entitlementKey);
    } else if (entitlementKey && !productId) {
      const newEntitlement = filteredEntitlements.find(
        (ent) => ent.entitlementKey === entitlementKey
      );
      setProduct(newEntitlement?.productContentKey ?? null);
    }
  }, [entitlementKey, productId, filteredEntitlements, setEntitlementKey, setProduct, email]);

  return (
    <Box testID={`EntitlementsListWith${filteredEntitlements.length}Entitlements`}>
      {showIntro && (
        <VStack mb="5" space="3">
          {messages.map((msg) => (
            <HTML key={msg} html={msg} />
          ))}
        </VStack>
      )}

      <Radio.Group
        accessibilityLabel="entitlements group"
        name="entitlements-group"
        value={entitlementKey ?? ''}
        onChange={handleChange}
      >
        <VStack space="4" w="full">
          {filteredEntitlements.map((ent) => (
            <EntitlementCard
              key={ent.entitlementKey}
              active={entitlementKey === ent.entitlementKey}
              entitlement={ent}
              hideRadio={!ent.isValid}
              onTouchEnd={handleRadioTouchEnd}
            />
          ))}
        </VStack>
      </Radio.Group>
    </Box>
  );
}

function getIntroTexts({
  count,
  email,
  t,
}: {
  count: number;
  email: string;
  t: TranslationHelper;
}) {
  switch (count) {
    case 0:
      return [t('app_auth_tickets_not_found', { email })];

    case 1:
      return [t('app_auth_tickets_found_one', { email })];

    default:
      return [t('app_auth_tickets_found', { count, email }), t('app_auth_tickets_select')];
  }
}
