import { Box, Button, FormControl, Heading, Text, VStack } from 'native-base';
import React from 'react';
import { Platform } from 'react-native';
import { shallow } from 'zustand/shallow';

import AppDownloadPrompt from 'app/components/AppDownloadPrompt';
import MyCitypassLogo from 'app/components/icons/MyCitypassLogo';
import LocaleSelect from 'app/components/LocaleSelect';
import { DEFAULT_PADDING } from 'app/components/ResponsiveContainer';
import Screen from 'app/components/Screen';
import TextLink from 'app/components/TextLink';
import useStore, { VerificationState } from 'app/hooks/useStore';
import { RootScreenProps, RootStackParamList } from 'app/navigation/RootNavigator';
import { useI18n } from 'app/providers/I18nProvider';
import Analytics from 'app/services/Analytics';
import { Events } from 'app/services/Analytics.types';
import { tID } from 'app/services/TestHelper';

export type WelcomeScreenProps = RootScreenProps<RootStackParamList, 'Welcome'>;

export default function WelcomeScreen({ navigation, route }: WelcomeScreenProps) {
  const { t } = useI18n();
  const { completeWelcome, verificationProcessing, verificationState } = useStore(
    (s) => ({
      completeWelcome: s.completeWelcome,
      verificationProcessing: s.verificationProcessing,
      verificationState: s.verificationState,
    }),
    shallow
  );

  const canExplore = !verificationProcessing && verificationState === VerificationState.Unverified;

  const handleLinkTickets = () => {
    completeWelcome();
    Analytics.trackEvent(Events.WelcomeLinkTickets);
    navigation.navigate('Destinations');
    navigation.navigate('Authentication');
  };
  const handleExploreNow = () => {
    if (!canExplore) return;
    completeWelcome();
    Analytics.trackEvent(Events.WelcomeExplore);
    navigation.navigate('Destinations');
  };
  const handleLocaleChange = () => {
    Analytics.trackEvent(Events.WelcomeChangeLanguage);
  };

  return (
    <Screen
      contentContainerStyle={{
        marginTop: Platform.OS === 'web' ? 50 : undefined,
        flex: 1,
        alignSelf: 'stretch',
      }}
      testID={tID('WelcomeScreen')}
    >
      <VStack
        alignItems="center"
        h="full"
        justifyContent="center"
        pt="3"
        px={DEFAULT_PADDING}
        space="6"
      >
        <MyCitypassLogo size={220} />

        <Box w="full">
          <FormControl>
            <LocaleSelect w="full" onValueChange={handleLocaleChange} />
          </FormControl>
        </Box>

        <Heading textAlign="center">{t('app_welcome_title')}</Heading>
        <Text>{t('app_welcome_intro')}</Text>

        {Platform.OS === 'web' ? (
          <AppDownloadPrompt onContinue={canExplore ? handleExploreNow : handleLinkTickets} />
        ) : (
          <VStack space="6" w="full">
            <Button
              colorScheme="primary"
              testID={tID('btn-link-tickets')}
              w="full"
              onPress={handleLinkTickets}
            >
              {t('gen_link_tickets')}
            </Button>

            {/* using opacity to prevent layout shift on auth link sign in */}
            <Text opacity={canExplore ? 1 : 0} textAlign="center">
              {t('app_welcome_explore_label')}{' '}
              <TextLink testID={tID('btn-explore-now')} onPress={handleExploreNow}>
                {t('app_welcome_explore_cta')}
              </TextLink>
            </Text>
          </VStack>
        )}
      </VStack>
    </Screen>
  );
}
