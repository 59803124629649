import { Button } from 'native-base';
import React from 'react';

import AuthTitle from 'app/components/AuthTitle';
import CPAlert from 'app/components/CPAlert';
import EntitlementsList from 'app/components/EntitlementsList';
import Loading from 'app/components/Loading';
import Screen from 'app/components/Screen';
import useGuest from 'app/hooks/useGuest';
import useGuestDataQuery from 'app/hooks/useGuestDataQuery';
import useStore from 'app/hooks/useStore';
import { AuthenticationStackParamList } from 'app/navigation/AuthenticationStackNavigator';
import { RootScreenProps } from 'app/navigation/RootNavigator';
import { useI18n } from 'app/providers/I18nProvider';
import Analytics, { Events } from 'app/services/Analytics';

export type AuthenticationStepUserDataScreenProps = RootScreenProps<
  AuthenticationStackParamList,
  'AuthenticationStepUserData'
>;

export default function AuthenticationUserDataScreen({
  navigation,
}: AuthenticationStepUserDataScreenProps) {
  const { t } = useI18n();
  const { email, hasResponse, entitlements, hasEntitlements, hasMultipleEntitlements } = useGuest();
  const { isLoading, isError, isSuccess } = useGuestDataQuery();
  const [entitlementKey, productContentKey, verificationState] = useStore((s) => [
    s.entitlementKey,
    s.productId,
    s.verificationState,
  ]);
  const disableStartPlanning = !(entitlementKey && productContentKey);

  const handleStartPlanning = async () => {
    Analytics.trackEvent(Events.AuthStartPlanning);
    if (entitlements.length > 0 && entitlementKey && productContentKey) {
      navigation.replace('Tabs', {
        screen: 'ProductStack',
        params: {
          screen: 'Product',
          params: { productId: productContentKey },
        },
      });
      return;
    }

    navigation.replace('Destinations');
  };
  const handleLinkTickets = async () => {
    Analytics.trackEvent(Events.AuthManualLinkTickets);
    navigation.navigate('Authentication', { screen: 'AuthenticationStepLinkPasses' });
  };
  const handleExploreNow = () => navigation.replace('Destinations');

  if (isLoading || !email || !hasResponse) {
    return (
      <Loading
        testID="AuthenticationUserDataScreenLoading"
        text={t<string>('app_auth_tickets_loading')}
      />
    );
  }

  if (isError) {
    return (
      <Screen testID="AuthenticationUserDataScreenLoading">
        <CPAlert status="error">{t('app_error_unknown')}</CPAlert>
      </Screen>
    );
  }
  return (
    <Screen testID="AuthenticationUserDataScreen">
      <AuthTitle>
        {t(
          hasMultipleEntitlements
            ? 'app_auth_tickets_linked_multiple_title'
            : 'app_auth_tickets_linked_title'
        )}
      </AuthTitle>

      <EntitlementsList />

      <Button.Group mt="8">
        {hasEntitlements ? (
          <Button isDisabled={disableStartPlanning} onPress={handleStartPlanning}>
            {t('app_auth_tickets_start_planning')}
          </Button>
        ) : (
          <Button onPress={handleExploreNow}>{t('app_explore_now')}</Button>
        )}
        <Button variant="link" onPress={handleLinkTickets}>
          {t('app_auth_tickets_link_new')}
        </Button>
      </Button.Group>
    </Screen>
  );
}
