/* eslint-disable global-require */
import Constants from 'expo-constants';

import { Locale } from 'app/generated/hygraph';

export type AppStorePlatform = 'ios' | 'android';

const config: Record<
  AppStorePlatform,
  {
    appURL: string;
    btnRatio: number;
    btns: Record<Locale, string>;
  }
> = {
  ios: {
    appURL: Constants.expoConfig?.extra?.install?.appStoreURL || '',
    btnRatio: 2.99,
    btns: {
      en: require('../../assets/images/store/apple/app-store-button-en.png'),
      de: require('../../assets/images/store/apple/app-store-button-de.png'),
      es: require('../../assets/images/store/apple/app-store-button-es.png'),
      fr: require('../../assets/images/store/apple/app-store-button-fr.png'),
      it: require('../../assets/images/store/apple/app-store-button-it.png'),
      ja: require('../../assets/images/store/apple/app-store-button-ja.png'),
      pt: require('../../assets/images/store/apple/app-store-button-pt.png'),
      zh: require('../../assets/images/store/apple/app-store-button-zh.png'),
    },
  },
  android: {
    appURL: Constants.expoConfig?.extra?.install?.playStoreURL || '',
    btnRatio: 3.33,
    btns: {
      en: require('../../assets/images/store/google/play-store-button-en.png'),
      de: require('../../assets/images/store/google/play-store-button-de.png'),
      es: require('../../assets/images/store/google/play-store-button-es.png'),
      fr: require('../../assets/images/store/google/play-store-button-fr.png'),
      it: require('../../assets/images/store/google/play-store-button-it.png'),
      ja: require('../../assets/images/store/google/play-store-button-ja.png'),
      pt: require('../../assets/images/store/google/play-store-button-pt.png'),
      zh: require('../../assets/images/store/google/play-store-button-zh.png'),
    },
  },
};

export default config;
