/* eslint-disable no-underscore-dangle */
import dayjs from 'dayjs';
import set from 'lodash/set';
import size from 'lodash/size';
import { Platform } from 'react-native';

import { ImageCarouselImage } from 'app/components/ImageCarousel';
import { AllAttractionPropsFragment, AttractionOfferType } from 'app/generated/hygraph';
import { AttractionContent } from 'app/hooks/useAttraction';
import { AttractionGroup, ProductLineup } from 'app/services/GuestCenterService.types';
import { cdn } from 'app/utils/image';

export function mapProductLineupToAttractions({
  productLineup,
  attractions,
}: {
  productLineup: ProductLineup;
  attractions: AllAttractionPropsFragment[];
}) {
  if (size(productLineup.attractionGroups) === 0) return [];

  const productId = productLineup.contentKey;
  const attractionMap = attractions?.reduce<Record<string, AllAttractionPropsFragment>>(
    (acc, attr) => {
      acc[attr.key] = attr;
      return acc;
    },
    {}
  );

  return productLineup.attractionGroups.reduce<AllAttractionPropsFragment[]>((acc, group) => {
    group.attractions
      .flatMap((a) => a.contentKey)
      .forEach((attractionKey) => {
        const attraction = attractionMap[attractionKey];
        if (attraction) acc.push(mergeVariant({ attraction, productId }));
      });
    return acc;
  }, []);
}

export function processAttractions({
  attractions = [],
  productId,
}: {
  productId: string;
  attractions: AllAttractionPropsFragment[];
}): AllAttractionPropsFragment[] {
  return attractions.map((attraction) => mergeVariant({ attraction, productId }));
}

const IGNORE_VARIANT_KEYS: Record<string, boolean> = {
  product: true,
};

export function mergeVariant({
  attraction,
  productId,
}: {
  attraction: AllAttractionPropsFragment;
  productId: string;
}) {
  // temporary workaround until JL finishes some work in hygraph
  // eslint-disable-next-line no-param-reassign
  attraction.key = attraction.key.replace(/2$/, '');

  if (!productId || size(attraction?.variants) === 0) return attraction;

  const attr = { ...attraction };
  const variant = attr.variants.find(({ product }) => product?.key === productId);

  if (variant) {
    // Logger.debug('[AttractionHelpers] found variant', { attraction, variant });

    Object.entries(variant).forEach(([key, newValue]) => {
      if (IGNORE_VARIANT_KEYS[key]) return;

      // merge if variant has content otherwise leave original alone
      if (Array.isArray(newValue) ? size(newValue) > 0 : !!newValue) {
        set(attr, key, newValue);
        // Logger.debug('[AttractionHelpers] merging variant content', {
        //   attractionId: attr.key,
        //   key,
        //   currentValue: get(attr, key),
        //   newValue,
        // });
      }
    });
  }

  return attr;
}

export function getOffers(attraction: Maybe<AttractionContent>) {
  const now = dayjs().startOf('day');

  return (attraction?.offers ?? []).filter(
    (offer) =>
      now.isAfter(offer.startDate) && (now.isBefore(offer.endDate) || now.isSame(offer.endDate))
  );
}

export function getSharedAdmissionOffers(attraction: Maybe<AttractionContent>) {
  return getOffers(attraction).filter((offer) => offer.type === AttractionOfferType.Shared);
}

export function hasSharedAdmissionOffers(attraction: Maybe<AttractionContent>) {
  return getSharedAdmissionOffers(attraction).length > 0;
}

export function getLocations(attraction: Maybe<AttractionContent>) {
  return attraction?.locations ?? [];
}

export function getLocationAddressLink(location: Single<AttractionContent['locations']>) {
  const { latitude = 0, longitude = 0 } = location.geolocation ?? {};
  const daddr = `${latitude},${longitude}`;
  const prefix = Platform.OS === 'ios' ? 'maps://' : `https://maps.google.com/maps`;

  return `${prefix}?daddr=${daddr}`;
}

export function getReservationTypeByAttractionKey(
  attractionGroups: Maybe<AttractionGroup[]>,
  attractionKey: string
) {
  if (!attractionGroups) return undefined;

  /* eslint-disable no-restricted-syntax */
  for (const group of attractionGroups) {
    for (const attr of group.attractions) {
      if (attr.attractionKey === attractionKey) {
        return attr.reservationType;
      }
    }
  }
  /* eslint-enable no-restricted-syntax */

  return undefined;
}

export function getVideo(attraction: Maybe<AttractionContent>) {
  if (!attraction?.gallery) return null;

  const video = attraction.gallery.find((item) => {
    return item.__typename === 'Video' && item.url;
  });

  return video?.__typename === 'Video' ? video : null;
}

export function getGalleryImages(attraction: Maybe<AttractionContent>) {
  if (!attraction?.gallery) return [];

  return attraction.gallery.reduce<ImageCarouselImage[]>((acc, item) => {
    if (item.__typename === 'Image' && item.image) {
      acc.push({
        url: cdn(item.image.url, [
          ['resize', { width: 600 }],
          ['quality', { value: 80 }],
        ]),
        alt: item.caption || item.credit || attraction.name || '',
      });
    }
    return acc;
  }, []);
}
