import { useQuery, UseQueryOptions } from 'react-query';

import useStore, { VerificationState } from 'app/hooks/useStore';
import { getGuestData } from 'app/services/GuestCenterService';
import { QueryKeys } from 'app/services/QueryClient';

export const queryKey = QueryKeys.GuestData;

export default function useGuestDataQuery({
  notifyOnChangeProps,
}: {
  notifyOnChangeProps?: UseQueryOptions['notifyOnChangeProps'];
} = {}) {
  const { email, verificationState } = useStore((s) => ({
    email: s.email,
    verificationState: s.verificationState,
  }));
  return useQuery([queryKey], () => getGuestData(email), {
    enabled: !!email && verificationState === VerificationState.Verified,
    notifyOnChangeProps,
  });
}
