import { NavigationState } from '@react-navigation/native';
import Constants from 'expo-constants';

import { Events, EventsByValue, IAnalytics, NavigationRoute } from 'app/services/Analytics.types';
import { analytics as googleAnalytics } from 'app/services/Firebase';
import HeapAnalytics from 'app/services/HeapAnalytics';
import Logger from 'app/services/Logger';

export { Events, UserProps } from 'app/services/Analytics.types';

const MODULE = `[Analytics]`;

const Analytics: IAnalytics = {
  init: () => {
    Logger.debug(`${MODULE} init`);
    HeapAnalytics.init();

    if (__DEV__) {
      Logger.debug(`${MODULE} setting userId to "developer"`);
      Analytics.setUserId('developer');
    }
  },

  trackEvent: (event, data) => {
    // eslint-disable-next-line no-param-reassign
    data = data ?? {};
    try {
      Logger.debug(`${MODULE} track event ${event}`, data);
      googleAnalytics().logEvent(event as string, data);
      HeapAnalytics.track(event, data);
    } catch (error) {
      Logger.error(`${MODULE} track failed`, { error, event, data });
    }
  },

  setUserId: (id) => {
    try {
      Logger.debug(`${MODULE} setUserId`, { id });
      googleAnalytics().setUserId(id);
      HeapAnalytics.identify(id);
    } catch (error) {
      Logger.error(`${MODULE} setUserId failed`, { error, id });
    }
  },

  setUserProperty: (key, value) => {
    try {
      Logger.debug(`${MODULE} setUserProperty`, { key, value });
      googleAnalytics().setUserProperties({ [key]: `${value}` });
      HeapAnalytics.addUserProperties({ [key]: value });
    } catch (error) {
      Logger.error(`${MODULE} setUserProperty failed`, { error, key, value });
    }
  },

  setUserProperties: (props) => {
    try {
      Logger.debug(`${MODULE} setUserProperties`, { ...props });
      googleAnalytics().setUserProperties(mapValuesToString(props));
      HeapAnalytics.addUserProperties(props);
    } catch (error) {
      Logger.error(`${MODULE} setUserProperties failed`, { error, ...props });
    }
  },

  onScreenChange: (state) => {
    const route = getActiveRoute(state);

    if (route) {
      Logger.debug(`${MODULE} onScreenChange`, route);
      Analytics.trackEvent(Events.ScreenView, {
        screen_name: route.name,
        firebase_screen: `${route.name}`,
        firebase_screen_class: `${route.name}`,
        appVersion: Constants.expoConfig?.version ?? 'UNKNOWN',
        sdkVersion: Constants.expoConfig?.sdkVersion ?? 'UNKNOWN',
        ...sanitizeRouteParams(route.params),
      });
    } else {
      Logger.debug(`${MODULE} unable to find screen route name`);
    }
  },

  trackEventFromLinkPress: (htmlAttributes, data) => {
    const event = htmlAttributes?.['data-track-event'];
    if (!event) return;

    if (Analytics.isEvent(event)) {
      Analytics.trackEvent(event, data);
      return;
    }
    Logger.warn(`${MODULE} unknown data-track-event on link`, { htmlAttributes, data });
  },

  isEvent(value: unknown): value is Events {
    return typeof value === 'string' && value in EventsByValue;
  },
};

Analytics.init();

export default Analytics;

function getActiveRoute(state?: NavigationState): Maybe<NavigationRoute> {
  if (!state) return null;
  const route = state.routes[state.index];

  if (isNavState(route.state)) {
    return getActiveRoute(route.state);
  }

  return route;
}

function isNavState(value: unknown): value is NavigationState {
  return typeof value === 'object' && value !== null && 'routes' in value;
}

function mapValuesToString(data: Record<string, unknown>) {
  return Object.entries(data).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: `${value}`,
    }),
    {}
  );
}

function sanitizeRouteParams($params: Readonly<object | undefined> = {}) {
  const params: Record<string, string> = { ...$params };

  if (params.productId) {
    params.productKey = params.productId;
    delete params.productId;
  }

  if (params.attractionId) {
    params.attractionKey = params.attractionId;
    delete params.attractionId;
  }

  return params;
}
