import { useNetInfo } from '@react-native-community/netinfo';
import { useFocusEffect } from '@react-navigation/native';
import { Box, Flex, useToast, VStack } from 'native-base';
import React from 'react';
import { useQueryClient } from 'react-query';

import CitypassLogoAnniversary from 'app/components/icons/CitypassLogoAnniversary';
import LinkTicketsPrompt from 'app/components/LinkTicketsPrompt';
import Screen from 'app/components/Screen';
import StaggeredList from 'app/components/StaggeredList';
import useDestinations from 'app/hooks/useDestinations';
import useStore from 'app/hooks/useStore';
import { RootScreenProps, RootStackParamList } from 'app/navigation/RootNavigator';
import DestinationCard from 'app/screens/DestinationsScreen/DestinationCard';
import { tID } from 'app/services/TestHelper';

export type DestinationsScreenProps = RootScreenProps<RootStackParamList, 'Destinations'>;

export default function DestinationsScreen({ navigation, route }: DestinationsScreenProps) {
  const { isInternetReachable } = useNetInfo();
  const setProduct = useStore((s) => s.setProduct);
  const productId = useStore((s) => s.productId);
  const { data: destinations } = useDestinations();
  const queryClient = useQueryClient();
  const toast = useToast();
  const initialDestinationsDisplayed = 6;
  const destinationRenderDelayMS = 50;

  const handleProductPress = async (productContentKey: string) => {
    if (!isInternetReachable) {
      const hasProductContent = queryClient
        .getQueryCache()
        .findAll(['getProduct'])
        .find((p) => p.queryHash.includes(`"productId":"${productContentKey}"`));

      if (!hasProductContent) {
        const toastId = 'noConnectionContent';
        if (!toast.isActive(toastId)) {
          toast.show({
            id: toastId,
            title: 'Offline',
            description: 'You need an internet connection to view this destination',
          });
        }
        return;
      }
    }

    setProduct(productContentKey);

    navigation.reset({
      index: 0,
      routes: [{ name: 'Destinations' }],
    });
    navigation.push('Tabs', {
      screen: 'ProductStack',
      params: {
        screen: 'Product',
        params: { productId: productContentKey },
      },
    });
  };

  useFocusEffect(() => {
    const destinationsRenderComplete =
      (destinations.length - initialDestinationsDisplayed) * destinationRenderDelayMS;

    // wait for render complete to avoid ui jank
    setTimeout(() => {
      // only clear the product here now and leave the entitlementKey in case it was set by token auth
      if (productId) setProduct(null);
    }, destinationsRenderComplete);
  });

  return (
    <Screen after={<LinkTicketsPrompt safeInsetBottom />} pt="3" testID={tID('DestinationsScreen')}>
      <VStack alignItems="center">
        <CitypassLogoAnniversary size={230} />
        {/* <CitypassLogo size={240} /> */}
      </VStack>

      <Box mt={4} testID="ProductLinkGrid">
        <Flex alignItems="stretch" flexDirection="row" flexWrap="wrap" justifyContent="center">
          {destinations.map((product, i) => {
            if (!product.key) return null;
            return (
              <Box key={product.key} flexBasis="50%" p={1.5} position="relative">
                <StaggeredList.Item
                  delay={destinationRenderDelayMS}
                  position={i < initialDestinationsDisplayed ? 0 : i}
                >
                  <DestinationCard product={product} onPress={handleProductPress} />
                </StaggeredList.Item>
              </Box>
            );
          })}
        </Flex>
      </Box>
    </Screen>
  );
}
