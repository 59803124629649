import { useNavigation } from '@react-navigation/native';
import { Button } from 'native-base';
import React, { useRef, useState } from 'react';

import CPAlertDialog from 'app/components/CPAlertDialog';
import useAuth from 'app/hooks/useAuth';
import { useI18n } from 'app/providers/I18nProvider';
import { tID } from 'app/services/TestHelper';

export type LogoutDialogProps = {
  alignment?: LogoutDialogAlignment;
  disabled: boolean;
};

export enum LogoutDialogAlignment {
  left,
  center,
}

export default function LogoutDialog({
  alignment = LogoutDialogAlignment.center,
  disabled,
}: LogoutDialogProps) {
  const { t } = useI18n();
  const { signOut } = useAuth();
  const signOutRef = useRef();
  const navigation = useNavigation();
  const [isOpen, setIsOpen] = useState(false);

  const handleSignOut = async () => {
    signOut();
    setIsOpen(false);
    navigation.navigate('Destinations');
  };

  const handleCancelSignOut = async () => {
    setIsOpen(false);
  };
  return (
    <>
      <Button
        alignSelf={alignment === LogoutDialogAlignment.left ? 'flex-start' : 'center'}
        disabled={disabled}
        isDisabled={disabled}
        ml={alignment === LogoutDialogAlignment.left ? -5 : 0}
        testID={tID('btn-sign-out')}
        variant="link"
        onPress={() => setIsOpen(true)}
      >
        {t('app_auth_sign_out')}
      </Button>
      <CPAlertDialog isOpen={isOpen} leastDestructiveRef={signOutRef}>
        <CPAlertDialog.Content>
          <CPAlertDialog.Header>{`${t('app_auth_sign_out')}?`}</CPAlertDialog.Header>
          <CPAlertDialog.Body>{t('app_auth_sign_out_description')}</CPAlertDialog.Body>
          <CPAlertDialog.Footer>
            <Button onPress={handleSignOut}>{t('gen_yes')}</Button>
            <Button variant="link" onPress={handleCancelSignOut}>
              {t('gen_no')}
            </Button>
          </CPAlertDialog.Footer>
        </CPAlertDialog.Content>
      </CPAlertDialog>
    </>
  );
}
