import AsyncStorage from '@react-native-async-storage/async-storage';
import Constants from 'expo-constants';
import firebase from 'firebase/compat/app';
import { QueryClient } from 'react-query';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

import { Locale, Stage } from 'app/generated/hygraph';
import Analytics, { Events } from 'app/services/Analytics';
import { setEnv as setGuestCenterEnv } from 'app/services/GuestCenterService';
import { ContentEnv } from 'app/services/HygraphService';
import { getInitialLocale, I18nContent, initialContent, locales } from 'app/services/I18n';

const localeEntries = Object.entries(locales);

export enum VerificationState {
  Unverified = 'UNVERIFIED',
  Pending = 'PENDING',
  Verified = 'VERIFIED',
}

export enum AuthMode {
  Unset = 'UNSET',
  Token = 'TOKEN',
  VerifyLink = 'VERIFYLINK',
  SignInLink = 'SIGNINLINK',
  SignInGatherEmail = 'SIGNINGATHEREMAIL',
  TokenVerifyEmail = 'TOKENVERIFYEMAIL',
}

export type StoreState = {
  locale: Locale;
  locales: [Locale, { label: string }][];
  orderAuthToken: Maybe<string>;
  email: Maybe<string>;
  user: Maybe<firebase.User>;
  accessToken: Maybe<string>;
  emailReservingFor: Maybe<string>;
  verificationState: VerificationState;
  verificationProcessing: boolean;
  authMode: AuthMode;
  productId: Maybe<string>;
  entitlementKey: Maybe<string>;
  hasCompletedWelcome: boolean;
  hasCompletedTicketsInfo: boolean;
  colorMode: 'light' | 'dark';
  content: I18nContent;
  env: Env;
  showContentKeys: boolean;
  cmsEnv: string;
  cmsStage: Stage;
  devTools: boolean;
};

export type StoreActions = {
  setLocale: (locale: Locale) => void;
  setOrderAuthToken: (orderAuthToken: Maybe<string>) => void;
  setEmail: (email: Maybe<string>) => void;
  setUser: (user: Maybe<firebase.User>) => void;
  setAccessToken: (user: Maybe<string>) => void;
  setEmailReservingFor: (verificationEmail: Maybe<string>) => void;
  setVerificationState: (state: VerificationState) => void;
  setAuthMode: (state: AuthMode) => void;
  setProduct: (productId: Maybe<string>) => void;
  setEntitlementKey: (entitlementKey: Maybe<string>) => void;
  setColorMode: (colorMode: StoreState['colorMode']) => void;
  completeWelcome: () => void;
  setEnv: (env: Env, queryClient: QueryClient) => void;
  setVerificationProcessing: (isProcessing: boolean) => void;
  setShowContentKeys: (showContentKeys: boolean) => void;
  setHasCompletedTicketsInfo: (value: boolean) => void;
  resetVerificationEmail: () => void;
  setCMSEnv: (url: string) => void;
  setCMSStage: (stage: Stage) => void;
  setDevTools: (devTools: boolean) => void;

  reset: () => void;
};

export type Store = StoreState & StoreActions;

export enum Env {
  Dev = 'dev',
  Uat = 'uat',
  Integration = 'integration',
  Demo = 'demo',
  Staging = 'staging',
  Production = 'production',
}

function createInitialState(): StoreState {
  return {
    locale: getInitialLocale(),
    locales: localeEntries,
    content: initialContent,
    orderAuthToken: null,
    email: null,
    user: null,
    accessToken: null,
    emailReservingFor: null,
    verificationState: VerificationState.Unverified,
    verificationProcessing: false,
    authMode: AuthMode.Unset,
    productId: null,
    entitlementKey: null,
    hasCompletedWelcome: false,
    hasCompletedTicketsInfo: false,
    colorMode: 'light' as StoreState['colorMode'],
    env: (Constants.expoConfig?.extra?.environment as Env) ?? Env.Dev,
    showContentKeys: false,
    cmsEnv: ContentEnv.Master,
    cmsStage: Stage.Published,
    devTools: false,
  };
}

const useStore = create<Store>()(
  persist(
    (set, get) => ({
      ...createInitialState(),

      setEnv: async (env, queryClient) => {
        set({ env });
        setGuestCenterEnv(env);
        await Promise.all([queryClient.cancelQueries(), queryClient.cancelMutations()]);
        await queryClient.invalidateQueries();
      },
      setLocale: (locale) => set({ locale }),
      setOrderAuthToken: (orderAuthToken) => set({ orderAuthToken }),
      setEmail: (email) => set({ email }),
      setUser: (user) => set({ user }),
      setAccessToken: (accessToken) => set({ accessToken }),
      setEmailReservingFor: (verificationEmail) => set({ emailReservingFor: verificationEmail }),
      setVerificationState: (verificationState) => {
        if (verificationState === VerificationState.Verified) {
          Analytics.trackEvent(Events.AuthEmailVerified);
        }
        set({ verificationState });
      },
      setAuthMode: (authMode) => set({ authMode }),
      setProduct: (productId) => set({ productId }),
      completeWelcome: () => set({ hasCompletedWelcome: true }),
      setColorMode: (colorMode) => set({ colorMode }),
      setEntitlementKey: (entitlementKey) => set({ entitlementKey }),
      setVerificationProcessing: (verificationProceesing) =>
        set({ verificationProcessing: verificationProceesing }),
      setShowContentKeys: (showContentKeys) => set({ showContentKeys }),
      setHasCompletedTicketsInfo: (hasCompletedTicketsInfo) => set({ hasCompletedTicketsInfo }),

      resetVerificationEmail: () => {
        set({
          accessToken: null,
          authMode: AuthMode.Unset,
          email: null,
          emailReservingFor: null,
          user: null,
          verificationProcessing: false,
          verificationState: VerificationState.Unverified,
        });
      },

      setCMSEnv: (cmsEnv) => set({ cmsEnv }),
      setCMSStage: (cmsStage) => set({ cmsStage }),

      setDevTools: (devTools) => set({ devTools }),

      reset: () =>
        set({
          ...createInitialState(),
          devTools: get().devTools,
        }),
    }),
    {
      name: 'GLOBAL_STORE',
      storage: createJSONStorage(() => AsyncStorage),
    }
  )
);

export default useStore;

// TODO find example of how to type this
export const useStorePersist: {
  onHydrate: (cb: () => void) => () => void;
  onFinishHydration: (cb: () => void) => () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} = (useStore as typeof useStore & { persist: any }).persist;
