import { useHeaderHeight } from '@react-navigation/elements';
import { Box, Button, FormControl, Input, KeyboardAvoidingView, Text, VStack } from 'native-base';
import React, { useState } from 'react';
import { Platform } from 'react-native';

import AuthTitle from 'app/components/AuthTitle';
import Loading from 'app/components/Loading';
import Screen from 'app/components/Screen';
import TextLink from 'app/components/TextLink';
import useAuth from 'app/hooks/useAuth';
import { AuthenticationStackParamList } from 'app/navigation/AuthenticationStackNavigator';
import { RootScreenProps } from 'app/navigation/RootNavigator';
import { useI18n } from 'app/providers/I18nProvider';
import Analytics from 'app/services/Analytics';
import { Events } from 'app/services/Analytics.types';
import Logger from 'app/services/Logger';
import { tID } from 'app/services/TestHelper';
import { isEmail } from 'app/utils/validation';

const MODULE = '[AuthenticationEnterEmailScreen]';

export type AuthenticationStepEnterEmailScreenProps = RootScreenProps<
  AuthenticationStackParamList,
  'AuthenticationStepEnterEmail'
>;

export default function AuthenticationEnterEmailScreen({
  navigation,
  route,
}: AuthenticationStepEnterEmailScreenProps) {
  const { t } = useI18n();
  const headerHeight = useHeaderHeight();
  const [email, setEmail] = useState('');
  const [isValid, setIsValid] = useState(true);
  const { verifyEmail } = useAuth();

  const handleEmailChange = (value: string) => {
    setIsValid(isEmail(value));
    setEmail(value.toLowerCase());
  };

  const handleSubmit = () => {
    if (email) {
      Analytics.trackEvent(Events.AuthEmailSubmitted);
      verifyEmail.mutate(
        { email },
        {
          onSuccess: () => {
            navigation.navigate('Authentication', { screen: 'AuthenticationStepVerifyEmail' });
          },
          onError: (error) => {
            Logger.error(`${MODULE} verifyEmail.mutate.onError`, { error });
          },
        }
      );
    }
  };

  const handleExploreApp = () => {
    navigation.navigate('Destinations');
    Analytics.trackEvent(Events.AuthExploreAppLink);
  };

  if (verifyEmail.isLoading) {
    return <Loading text={t<string>('app_auth_email_verifying')} />;
  }

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === 'ios' ? 'padding' : undefined}
      keyboardVerticalOffset={headerHeight + 30}
    >
      <Screen keyboardShouldPersistTaps="handled" testID={tID('AuthenticationEnterEmailScreen')}>
        <AuthTitle>{t('app_auth_email_title')}</AuthTitle>

        <VStack space="4">
          <Text>{t('app_auth_email_description')}</Text>

          <Box>
            <FormControl isInvalid={!isValid} isRequired>
              <FormControl.Label my="0">{t('app_auth_email_input')}</FormControl.Label>
              <Input
                _focus={{ borderColor: 'black' }}
                autoComplete="email"
                clearButtonMode="while-editing"
                keyboardType="email-address"
                placeholder={t<string>('app_auth_email_input_placeholder')}
                px="0"
                size="lg"
                testID={tID('txt-email')}
                textContentType="emailAddress"
                type="text"
                variant="underlined"
                onChangeText={handleEmailChange}
              />
            </FormControl>
          </Box>

          <Button isDisabled={!isValid} onPress={handleSubmit}>
            {t('gen_continue')}
          </Button>

          <Text fontSize="xs" textAlign="center">
            {t('app_welcome_explore_label')}{' '}
            <TextLink onPress={handleExploreApp}>{t('app_welcome_explore_cta')}</TextLink>
          </Text>
        </VStack>
      </Screen>
    </KeyboardAvoidingView>
  );
}
