import { useRoute } from '@react-navigation/native';
import { createNativeStackNavigator, NativeStackScreenProps } from '@react-navigation/native-stack';
import { setStatusBarStyle } from 'expo-status-bar';
import get from 'lodash/get';
import { useTheme } from 'native-base';
import React, { lazy, useEffect, useMemo } from 'react';
import { shallow } from 'zustand/shallow';

import HeaderCloseButton from 'app/components/HeaderCloseButton/HeaderCloseButton';
import useHeaderScreenOptions from 'app/hooks/useHeaderScreenOptions';
import useRootNavigation from 'app/hooks/useRootNavigation';
import useStore, { VerificationState } from 'app/hooks/useStore';
import { useI18n } from 'app/providers/I18nProvider';
import AuthenticationEnterEmailScreen from 'app/screens/Authentication/AuthenticationEnterEmailScreen';
import AuthenticationUserDataScreen from 'app/screens/Authentication/AuthenticationUserDataScreen';
import AuthenticationVerifyEmailScreen from 'app/screens/Authentication/AuthenticationVerifyEmailScreen';

const AuthenticationLinkPassesScreen = lazy(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error: needs module=Node16/ESNext but dont want to play with that right before launch
  () => import('../../screens/Authentication/AuthenticationLinkPassesScreen')
);
export type AuthenticationStackParamList = {
  AuthenticationStepEnterEmail: undefined; // AuthenticationParams;
  AuthenticationStepVerifyEmail?: {
    mode?: string;
  };
  AuthenticationStepUserData: undefined; // AuthenticationParams;
  AuthenticationStepLinkPasses: undefined;
};

export type AuthenticationStackScreenProps<Screen extends keyof AuthenticationStackParamList> =
  NativeStackScreenProps<AuthenticationStackParamList, Screen>;

const Stack = createNativeStackNavigator<AuthenticationStackParamList>();

export default function AuthenticationStackNavigator() {
  const theme = useTheme();
  const [verificationState] = useStore(
    (s) => [s.verificationState, s.verificationProcessing],
    shallow
  );
  const navigation = useRootNavigation();
  const route = useRoute();
  const { t } = useI18n();
  const headerScreenOptions = useHeaderScreenOptions();

  const currentScreen = get(route, ['params', 'screen']);
  const targetScreen = useMemo((): keyof AuthenticationStackParamList => {
    switch (verificationState) {
      case VerificationState.Unverified:
        return 'AuthenticationStepEnterEmail';
      case VerificationState.Pending:
        return 'AuthenticationStepVerifyEmail';
      default:
        return currentScreen === 'AuthenticationStepLinkPasses'
          ? 'AuthenticationStepLinkPasses'
          : 'AuthenticationStepUserData';
    }
  }, [verificationState, currentScreen]);

  useEffect(() => {
    if (route.name === 'Authentication' && currentScreen !== targetScreen) {
      navigation.navigate('Authentication', { screen: targetScreen });
    }
  }, [route, currentScreen, navigation, targetScreen]);

  useEffect(() => {
    setStatusBarStyle('light');
    return () => setStatusBarStyle('dark');
  }, []);

  return (
    <Stack.Navigator
      initialRouteName={targetScreen}
      screenOptions={{
        ...headerScreenOptions,
        title: t<string>('app_auth_header'),
        animation: 'none',
        headerBackVisible: false,
        headerBackButtonMenuEnabled: false,
        headerTintColor: theme.colors.white,
        headerStyle: {
          backgroundColor: theme.colors.brand.primary,
        },
        headerRight: () => (
          <HeaderCloseButton
            onPress={() => {
              if (navigation.canGoBack()) {
                navigation.goBack();
              } else {
                navigation.navigate('Destinations');
              }
            }}
          />
        ),
      }}
    >
      <Stack.Screen
        component={AuthenticationEnterEmailScreen}
        name="AuthenticationStepEnterEmail"
      />

      <Stack.Screen
        component={AuthenticationVerifyEmailScreen}
        name="AuthenticationStepVerifyEmail"
      />

      <Stack.Screen
        component={AuthenticationUserDataScreen}
        name="AuthenticationStepUserData"
        options={{
          headerRight: () => null,
        }}
      />

      <Stack.Screen
        component={AuthenticationLinkPassesScreen}
        name="AuthenticationStepLinkPasses"
        options={{
          headerBackVisible: true,
          headerBackButtonMenuEnabled: true,
        }}
      />
    </Stack.Navigator>
  );
}
