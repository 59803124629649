import { Box, Input, Spinner, Text, Toast, useToast, VStack } from 'native-base';
import React from 'react';
import { Platform, Pressable } from 'react-native';
import { shallow } from 'zustand/shallow';

import AuthTitle from 'app/components/AuthTitle';
import HTML from 'app/components/HTML';
import Loading from 'app/components/Loading';
import Screen from 'app/components/Screen';
import TextLink from 'app/components/TextLink';
import withNativeBase from 'app/hocs/withNativeBase';
import useAuth from 'app/hooks/useAuth';
import useGuestDataQuery from 'app/hooks/useGuestDataQuery';
import { routeLink } from 'app/hooks/useLinkHandling';
import useStore from 'app/hooks/useStore';
import useToggle from 'app/hooks/useToggle';
import { AuthenticationStackParamList } from 'app/navigation/AuthenticationStackNavigator';
import { RootScreenProps } from 'app/navigation/RootNavigator';
import { useI18n } from 'app/providers/I18nProvider';
import Analytics, { Events } from 'app/services/Analytics';
import Logger from 'app/services/Logger';
import { tID } from 'app/services/TestHelper';
import { isValidAuthLink } from 'app/utils/validation';

const MODULE = '[AuthentictionVerifyEmailScreen]';

export type AuthenticationStepVerifyEmailScreenProps = RootScreenProps<
  AuthenticationStackParamList,
  'AuthenticationStepVerifyEmail'
>;

function AuthenticationVerifyEmailScreen({
  navigation,
  route,
}: AuthenticationStepVerifyEmailScreenProps) {
  const toast = useToast();
  const { t, setLocale, locale } = useI18n();
  const { user, verifyEmail } = useAuth();
  const { email, verificationProcessing, resetVerificationEmail } = useStore(
    (s) => ({
      email: s.email,
      verificationProcessing: s.verificationProcessing,
      resetVerificationEmail: s.resetVerificationEmail,
    }),
    shallow
  );

  const [showSignInLinkInput, toggleSignInLinkInput] = useToggle(false);
  const { remove: clearUserData, refetch: userDataRefetch } = useGuestDataQuery();

  const handleResendVerification = async () => {
    if (email) {
      Analytics.trackEvent(Events.AuthResendVerifyEmail);
      Logger.info(`${MODULE} resending verify`, {
        email,
        uid: user?.uid,
      });
      toast.show({
        title: t<string>('app_auth_verify_resend_loading'),
        description: <Spinner color="white" />,
      });

      verifyEmail.mutate(
        { email },
        {
          onSuccess: () => {
            toast.show({
              title: t<string>('gen_success'),
              description: t<string>('app_auth_verify_resend_success', { email }),
            });
          },
          onError: () => {
            toast.show({
              title: t<string>('gen_failed'),
              description: t<string>('app_auth_verify_resend_failed'),
            });
          },
        }
      );
    }
  };

  const handleLinkChange = (value: string) => {
    if (isValidAuthLink(value)) {
      routeLink({
        link: value,
        suppressAlerts: true,
        goToAuthScreen: false,
        toast: Toast,
        raiseToast: () => null,
        clearUserData,
        locale,
        setLocale,
        userDataRefetch,
        invalidLinkLogAndToast: () => null,
        t,
      });
    }
  };

  const handleChangeEmail = () => {
    resetVerificationEmail();
    navigation.navigate('Authentication', { screen: 'AuthenticationStepEnterEmail' });
  };

  if (verificationProcessing || user?.emailVerified) {
    return <Loading text={t<string>('app_auth_link_loading')} />;
  }

  return (
    <Screen testID="AuthenticationVerifyEmailScreen">
      <AuthTitle>{t('app_auth_verify_title')}</AuthTitle>

      <VStack space="4">
        <Box testID={tID('text-email-sent-details')}>
          <Pressable testID={tID('press-show-link-input')} onLongPress={toggleSignInLinkInput}>
            <HTML html={t<string>('app_auth_verify_intro', { email: email ?? '' })} />
          </Pressable>
        </Box>

        <Text>
          {t('app_auth_verify_help')}{' '}
          <TextLink onPress={handleResendVerification}>{t('app_auth_verify_resend')}</TextLink>
        </Text>

        <Text>
          {t('app_auth_verify_change_email')}{' '}
          <TextLink onPress={handleChangeEmail}>{t('app_auth_verify_start_over')}</TextLink>
        </Text>

        {Platform.OS !== 'web' && showSignInLinkInput && (
          <Input
            _focus={{
              borderColor: 'black',
            }}
            clearButtonMode="while-editing"
            keyboardType="url"
            placeholder="Paste Sign In Link Here"
            px="0"
            size="lg"
            testID={tID('input-paste-sign-in-link')}
            textContentType="URL"
            type="text"
            variant="underlined"
            onChangeText={handleLinkChange}
          />
        )}
      </VStack>
    </Screen>
  );
}

export default withNativeBase(AuthenticationVerifyEmailScreen);
