import Constants from 'expo-constants';
import * as Device from 'expo-device';
import { Image, Pressable } from 'native-base';
import React from 'react';
import { Linking, Platform } from 'react-native';
import { shallow } from 'zustand/shallow';

import useStore from 'app/hooks/useStore';
import { useI18n } from 'app/providers/I18nProvider';
import Analytics, { Events } from 'app/services/Analytics';
import { tID } from 'app/services/TestHelper';

import config, { AppStorePlatform } from './AppDownloadButton.config';

export type AppDownloadButtonProps = {
  platform: AppStorePlatform;
  height?: number;
};

export default function AppDownloadButton({
  platform: appStorePlatform = 'ios',
  height = 35,
}: AppDownloadButtonProps) {
  const { locale } = useI18n();
  const { appURL, btnRatio, btns } = config[appStorePlatform];
  const [orderAuthToken, env] = useStore((s) => [s.orderAuthToken, s.env], shallow);
  const uri = btns[locale] ?? btns.en;
  const isProduction = Constants?.manifest?.extra?.profile === 'production';

  // TODO: need to pull this all from server side configuration once we get it working or maybe even have the server generate???
  function buildTargetedUrl(): string {
    const osName = Device.osName?.toLowerCase();
    if (
      orderAuthToken &&
      Platform.OS === 'web' &&
      (osName === 'ios' || osName === 'ipados' || osName === 'android')
    ) {
      const encodedTarget = encodeURIComponent(
        isProduction
          ? `https://my.citypass.com/welcome?order_token=${orderAuthToken}`
          : `https://${env}.my.citypassdev.com/welcome?order_token=${orderAuthToken}`
      );
      const platformEncodedTarget =
        appStorePlatform === 'ios'
          ? `&ibi=com.citypass.my&isi=1612279736&efr=1`
          : `&apn=com.citypass.my&amv=12`;
      return `https://links.citypass.com/?link=${encodedTarget}${platformEncodedTarget}`;
    }
    return appURL;
  }

  return (
    <Pressable
      _web={{
        width: height * btnRatio,
      }}
      height={`${height}px`}
      testID={tID('AppDownloadButton')}
      onPress={() => {
        Linking.openURL(buildTargetedUrl());
        Analytics.trackEvent(Events.WebPromptDownloadApp, { platform: appStorePlatform });
      }}
    >
      <Image
        alignSelf="stretch"
        alt="Download on the App Store"
        flex="1"
        h="full"
        source={{ uri }}
        style={{ aspectRatio: btnRatio }}
      />
    </Pressable>
  );
}
