import dayjs, { Dayjs } from 'dayjs';

import { ProductType, ReservationTextType } from 'app/generated/hygraph';
import { AttractionContent } from 'app/hooks/useAttraction';
import {
  AttractionConfig,
  EntitlementReservation,
  ProductFamily,
  ReservationModel,
  ReservationOption,
  ReservationRequirement,
  ReservationType,
} from 'app/services/GuestCenterService.types';
import { getTextKey } from 'app/services/I18n';
import { toYMD } from 'app/utils/time';

export enum ReservationActions {
  ReservePhone = 'reserve-phone',
  ReserveRedirect = 'reserve-redirect',
  Reserve = 'reserve',
  ViewTickets = 'view-tickets',
}

export function isReservable(config: AttractionConfig) {
  return (
    !config.isClosed &&
    config.reservationRequirement !== ReservationRequirement.NotRequired &&
    (hasTicketingSystem(config) || isRedirect(config))
  );
}

export function hasTicketingSystem(config: Maybe<AttractionConfig>) {
  return !!config?.ticketingSystem;
}

export function isPhone(config: AttractionConfig) {
  return config.reservationModel === ReservationModel.Phone;
}

// when true = model 3/4
export function isRedirect(config: Maybe<AttractionConfig>) {
  return config?.reservationModel === ReservationModel.Redirect;
}

// TODO: or false when user has reservations
export function canViewTickets(config: AttractionConfig, productType: ProductType) {
  return !(
    config.isClosed ||
    hasIntegratedReservations(config) ||
    productType === ProductType.Alacarte
  );
}

export function hasIntegratedReservations(config: AttractionConfig) {
  return config.reservationModel === ReservationModel.Integrated;
}

export function redirectURL(
  config: AttractionConfig,
  props?: {
    [k: string]: string | number | string[] | number[] | undefined;
    barcodes?: string[];
  }
) {
  return expandURLTemplate(config.ticketingSystem?.redirectURL || '', props);
}

export function phoneNumber(reservationContent: AttractionContent['reservation']): string[] {
  return (
    reservationContent?.texts.find((text) => getTextKey(text) === ReservationTextType.PhoneNumber)
      ?.value ?? ''
  )
    .split('|')
    .map((v) => v.trim());
}

export function userActions(
  config: AttractionConfig,
  productType: ProductType
): ReservationActions[] {
  const actions: ReservationActions[] = [];

  if (isPhone(config)) {
    actions.push(ReservationActions.ReservePhone);
  } else if (isReservable(config)) {
    actions.push(
      isRedirect(config) ? ReservationActions.ReserveRedirect : ReservationActions.Reserve
    );
  }

  if (canViewTickets(config, productType)) {
    actions.push(ReservationActions.ViewTickets);
  }

  return actions;
}

export function expandURLTemplate(url: string, props?: Record<string, unknown>): string {
  if (!props || !url) return url;

  return url.replace(/\[\[(.+?)]]/g, (_, group) => {
    const [key, separator = ','] = group.trim().split(':');
    const value = props?.[key] ?? '';
    return Array.isArray(value) ? value.join(separator) : `${value}`;
  });
}

export function barcodeTail(barcode: string): string {
  return barcode.slice(-4);
}

export function prettyBarcode(barcode: string): string {
  return barcode.replace(/(\d{4})/g, '$1 ');
}

export function ticketingDaysOutMax(config?: AttractionConfig): number | undefined {
  const daysOutMax = config?.ticketingSystem?.search?.daysOutMax;
  return daysOutMax === -1 ? undefined : daysOutMax;
}

export function ticketingDaysOutMin(config?: AttractionConfig): number {
  const daysOutMin = config?.ticketingSystem?.search?.daysOutMin;
  return daysOutMin ?? 0;
}

export function allowHold(config?: AttractionConfig): boolean {
  return !!config?.ticketingSystem?.reserve?.allowHold;
}

export function hasOptions(
  config: AttractionConfig | undefined,
  familyCode: ProductFamily
): boolean {
  return getOptions(config, familyCode).length > 0;
}

export function getOptions(
  config: AttractionConfig | undefined,
  familyCode: ProductFamily
): ReservationOption[] {
  return (config?.ticketingSystem?.options || []).filter(
    (option) => option.familyCode === familyCode
  );
}

export function getOptionType(
  config: AttractionConfig | undefined,
  familyCode: ProductFamily
): ReservationOption['optionType'] {
  return getOptions(config, familyCode)[0]?.optionType ?? '';
}

export function allowCancel(config?: AttractionConfig): boolean {
  return config?.ticketingSystem?.cancel?.allowCancel === true;
}

export function cancelMinutesOut(config?: AttractionConfig): number {
  const num = config?.ticketingSystem?.cancel?.minutesOut ?? 0;
  return num < 0 ? 0 : num;
}

export function cancelDaysOut(config?: AttractionConfig): number {
  const num = config?.ticketingSystem?.cancel?.daysOut ?? 0;
  return num < 0 ? 0 : num;
}

export function getCutoffForReservationTime(
  config: AttractionConfig | undefined,
  reservationTime: string
): Dayjs {
  return time(reservationTime, config?.ianaTimezone)
    .subtract(cancelDaysOut(config), 'days')
    .subtract(cancelMinutesOut(config), 'minutes');
}

export function canCancel(config: AttractionConfig | undefined, reservationTime: string): boolean {
  if (config?.isClosed || config?.ticketingSystem?.online === false || !allowCancel(config)) {
    return false;
  }

  const now = dayjs();
  const cutoff = getCutoffForReservationTime(config, reservationTime);

  return now.isBefore(cutoff);
}

export function date(dateTime: string) {
  return dayjs(toYMD(dateTime));
}

export function time(dateTime: string, ianaTimezone?: string) {
  return dayjs(dateTime.split('+')[0]).tz(ianaTimezone, true);
}

export function reservationLabel(reservation?: EntitlementReservation) {
  return reservation?.reservationDateTime
    ? time(reservation.reservationDateTime, reservation.attractionConfig?.ianaTimezone).format(
        `dddd, MMM D, YYYY${reservation.type !== ReservationType.Day ? ', h:mma' : ''}`
      )
    : '';
}

export function hasUsage(reservation?: EntitlementReservation) {
  return reservation?.reservationDateTime
    ? time(reservation.reservationDateTime, reservation.attractionConfig?.ianaTimezone).isBefore(
        dayjs()
      )
    : false;
}
