import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
import { GraphQLClient } from 'graphql-request';
import { RequestInit } from 'graphql-request/dist/types.dom';
import { useQuery, useMutation, UseQueryOptions, UseMutationOptions } from 'react-query';
export type Maybe<T> = T | null;
export type InputMaybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> };

function fetcher<TData, TVariables>(
  client: GraphQLClient,
  query: string,
  variables?: TVariables,
  headers?: RequestInit['headers']
) {
  return async (): Promise<TData> => client.request<TData, TVariables>(query, variables, headers);
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  DateTime: any;
  Hex: any;
  Json: any;
  Long: any;
  RGBAHue: any;
  RGBATransparency: any;
  RichTextAST: any;
};

export type AddOn = Node & {
  __typename?: 'AddOn';
  attraction: Maybe<Attraction>;
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** User that created this document */
  createdBy: Maybe<User>;
  description: Maybe<Scalars['String']>;
  /** Get the document in other stages */
  documentInStages: Array<AddOn>;
  /** List of AddOn versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID'];
  image: Maybe<Asset>;
  internalTitle: Scalars['String'];
  link: Maybe<Scalars['String']>;
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<AddOn>;
  products: Array<Product>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt: Maybe<Scalars['DateTime']>;
  /** User that last published this document */
  publishedBy: Maybe<User>;
  qabu: Maybe<Scalars['Json']>;
  scheduledIn: Array<ScheduledOperation>;
  /** System stage field */
  stage: Stage;
  title: Maybe<Scalars['String']>;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** User that last updated this document */
  updatedBy: Maybe<User>;
};

export type AddOnAttractionArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type AddOnCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type AddOnCreatedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type AddOnDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};

export type AddOnHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride: InputMaybe<Stage>;
};

export type AddOnImageArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type AddOnLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};

export type AddOnProductsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<ProductOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ProductWhereInput>;
};

export type AddOnPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type AddOnPublishedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type AddOnScheduledInArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ScheduledOperationWhereInput>;
};

export type AddOnUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type AddOnUpdatedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type AddOnConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: AddOnWhereUniqueInput;
};

/** A connection to a list of items. */
export type AddOnConnection = {
  __typename?: 'AddOnConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<AddOnEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type AddOnCreateInput = {
  attraction?: InputMaybe<AttractionCreateOneInlineInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** description input for default locale (en) */
  description?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<AssetCreateOneInlineInput>;
  internalTitle: Scalars['String'];
  link?: InputMaybe<Scalars['String']>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<AddOnCreateLocalizationsInput>;
  products?: InputMaybe<ProductCreateManyInlineInput>;
  qabu?: InputMaybe<Scalars['Json']>;
  /** title input for default locale (en) */
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type AddOnCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type AddOnCreateLocalizationInput = {
  /** Localization input */
  data: AddOnCreateLocalizationDataInput;
  locale: Locale;
};

export type AddOnCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<AddOnCreateLocalizationInput>>;
};

export type AddOnCreateManyInlineInput = {
  /** Connect multiple existing AddOn documents */
  connect?: InputMaybe<Array<AddOnWhereUniqueInput>>;
  /** Create and connect multiple existing AddOn documents */
  create?: InputMaybe<Array<AddOnCreateInput>>;
};

export type AddOnCreateOneInlineInput = {
  /** Connect one existing AddOn document */
  connect?: InputMaybe<AddOnWhereUniqueInput>;
  /** Create and connect one AddOn document */
  create?: InputMaybe<AddOnCreateInput>;
};

/** An edge in a connection. */
export type AddOnEdge = {
  __typename?: 'AddOnEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: AddOn;
};

/** Identifies documents */
export type AddOnManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AddOnWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AddOnWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AddOnWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  attraction?: InputMaybe<AttractionWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<AddOnWhereStageInput>;
  documentInStages_none?: InputMaybe<AddOnWhereStageInput>;
  documentInStages_some?: InputMaybe<AddOnWhereStageInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  image?: InputMaybe<AssetWhereInput>;
  internalTitle?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  internalTitle_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  internalTitle_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  internalTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  internalTitle_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  internalTitle_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  internalTitle_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  internalTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  internalTitle_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  internalTitle_starts_with?: InputMaybe<Scalars['String']>;
  link?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  link_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  link_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  link_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  link_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  link_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  link_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  link_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  link_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  link_starts_with?: InputMaybe<Scalars['String']>;
  products_every?: InputMaybe<ProductWhereInput>;
  products_none?: InputMaybe<ProductWhereInput>;
  products_some?: InputMaybe<ProductWhereInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  /** All values containing the given json path. */
  qabu_json_path_exists?: InputMaybe<Scalars['String']>;
  /**
   * Recursively tries to find the provided JSON scalar value inside the field.
   * It does use an exact match when comparing values.
   * If you pass `null` as value the filter will be ignored.
   * Note: This filter fails if you try to look for a non scalar JSON value!
   */
  qabu_value_recursive?: InputMaybe<Scalars['Json']>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum AddOnOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  InternalTitleAsc = 'internalTitle_ASC',
  InternalTitleDesc = 'internalTitle_DESC',
  LinkAsc = 'link_ASC',
  LinkDesc = 'link_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type AddOnUpdateInput = {
  attraction?: InputMaybe<AttractionUpdateOneInlineInput>;
  /** description input for default locale (en) */
  description?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<AssetUpdateOneInlineInput>;
  internalTitle?: InputMaybe<Scalars['String']>;
  link?: InputMaybe<Scalars['String']>;
  /** Manage document localizations */
  localizations?: InputMaybe<AddOnUpdateLocalizationsInput>;
  products?: InputMaybe<ProductUpdateManyInlineInput>;
  qabu?: InputMaybe<Scalars['Json']>;
  /** title input for default locale (en) */
  title?: InputMaybe<Scalars['String']>;
};

export type AddOnUpdateLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type AddOnUpdateLocalizationInput = {
  data: AddOnUpdateLocalizationDataInput;
  locale: Locale;
};

export type AddOnUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<AddOnCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<AddOnUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<AddOnUpsertLocalizationInput>>;
};

export type AddOnUpdateManyInlineInput = {
  /** Connect multiple existing AddOn documents */
  connect?: InputMaybe<Array<AddOnConnectInput>>;
  /** Create and connect multiple AddOn documents */
  create?: InputMaybe<Array<AddOnCreateInput>>;
  /** Delete multiple AddOn documents */
  delete?: InputMaybe<Array<AddOnWhereUniqueInput>>;
  /** Disconnect multiple AddOn documents */
  disconnect?: InputMaybe<Array<AddOnWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing AddOn documents */
  set?: InputMaybe<Array<AddOnWhereUniqueInput>>;
  /** Update multiple AddOn documents */
  update?: InputMaybe<Array<AddOnUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple AddOn documents */
  upsert?: InputMaybe<Array<AddOnUpsertWithNestedWhereUniqueInput>>;
};

export type AddOnUpdateManyInput = {
  /** description input for default locale (en) */
  description?: InputMaybe<Scalars['String']>;
  link?: InputMaybe<Scalars['String']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<AddOnUpdateManyLocalizationsInput>;
  qabu?: InputMaybe<Scalars['Json']>;
  /** title input for default locale (en) */
  title?: InputMaybe<Scalars['String']>;
};

export type AddOnUpdateManyLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type AddOnUpdateManyLocalizationInput = {
  data: AddOnUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type AddOnUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<AddOnUpdateManyLocalizationInput>>;
};

export type AddOnUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: AddOnUpdateManyInput;
  /** Document search */
  where: AddOnWhereInput;
};

export type AddOnUpdateOneInlineInput = {
  /** Connect existing AddOn document */
  connect?: InputMaybe<AddOnWhereUniqueInput>;
  /** Create and connect one AddOn document */
  create?: InputMaybe<AddOnCreateInput>;
  /** Delete currently connected AddOn document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected AddOn document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single AddOn document */
  update?: InputMaybe<AddOnUpdateWithNestedWhereUniqueInput>;
  /** Upsert single AddOn document */
  upsert?: InputMaybe<AddOnUpsertWithNestedWhereUniqueInput>;
};

export type AddOnUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: AddOnUpdateInput;
  /** Unique document search */
  where: AddOnWhereUniqueInput;
};

export type AddOnUpsertInput = {
  /** Create document if it didn't exist */
  create: AddOnCreateInput;
  /** Update document if it exists */
  update: AddOnUpdateInput;
};

export type AddOnUpsertLocalizationInput = {
  create: AddOnCreateLocalizationDataInput;
  locale: Locale;
  update: AddOnUpdateLocalizationDataInput;
};

export type AddOnUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: AddOnUpsertInput;
  /** Unique document search */
  where: AddOnWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type AddOnWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']>;
};

/** Identifies documents */
export type AddOnWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AddOnWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AddOnWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AddOnWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  attraction?: InputMaybe<AttractionWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  description?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  description_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars['String']>;
  documentInStages_every?: InputMaybe<AddOnWhereStageInput>;
  documentInStages_none?: InputMaybe<AddOnWhereStageInput>;
  documentInStages_some?: InputMaybe<AddOnWhereStageInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  image?: InputMaybe<AssetWhereInput>;
  internalTitle?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  internalTitle_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  internalTitle_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  internalTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  internalTitle_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  internalTitle_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  internalTitle_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  internalTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  internalTitle_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  internalTitle_starts_with?: InputMaybe<Scalars['String']>;
  link?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  link_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  link_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  link_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  link_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  link_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  link_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  link_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  link_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  link_starts_with?: InputMaybe<Scalars['String']>;
  products_every?: InputMaybe<ProductWhereInput>;
  products_none?: InputMaybe<ProductWhereInput>;
  products_some?: InputMaybe<ProductWhereInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  /** All values containing the given json path. */
  qabu_json_path_exists?: InputMaybe<Scalars['String']>;
  /**
   * Recursively tries to find the provided JSON scalar value inside the field.
   * It does use an exact match when comparing values.
   * If you pass `null` as value the filter will be ignored.
   * Note: This filter fails if you try to look for a non scalar JSON value!
   */
  qabu_value_recursive?: InputMaybe<Scalars['Json']>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  title?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type AddOnWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AddOnWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AddOnWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AddOnWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<AddOnWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References AddOn record uniquely */
export type AddOnWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
  internalTitle?: InputMaybe<Scalars['String']>;
};

export type Aggregate = {
  __typename?: 'Aggregate';
  count: Scalars['Int'];
};

export type AlacarteTicket = Node & {
  __typename?: 'AlacarteTicket';
  age: Maybe<Scalars['String']>;
  attractionAlacarteTickets: Maybe<AttractionAlacarte>;
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** User that created this document */
  createdBy: Maybe<User>;
  /** Get the document in other stages */
  documentInStages: Array<AlacarteTicket>;
  expiration: Maybe<Scalars['Date']>;
  /** List of AlacarteTicket versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID'];
  internalTitle: Maybe<Scalars['String']>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt: Maybe<Scalars['DateTime']>;
  /** User that last published this document */
  publishedBy: Maybe<User>;
  scheduledIn: Array<ScheduledOperation>;
  sku: Maybe<Scalars['String']>;
  /** System stage field */
  stage: Stage;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** User that last updated this document */
  updatedBy: Maybe<User>;
};

export type AlacarteTicketAttractionAlacarteTicketsArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type AlacarteTicketCreatedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type AlacarteTicketDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};

export type AlacarteTicketHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride: InputMaybe<Stage>;
};

export type AlacarteTicketPublishedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type AlacarteTicketScheduledInArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ScheduledOperationWhereInput>;
};

export type AlacarteTicketUpdatedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type AlacarteTicketConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: AlacarteTicketWhereUniqueInput;
};

/** A connection to a list of items. */
export type AlacarteTicketConnection = {
  __typename?: 'AlacarteTicketConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<AlacarteTicketEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type AlacarteTicketCreateInput = {
  age?: InputMaybe<Scalars['String']>;
  attractionAlacarteTickets?: InputMaybe<AttractionAlacarteCreateOneInlineInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  expiration?: InputMaybe<Scalars['Date']>;
  internalTitle?: InputMaybe<Scalars['String']>;
  sku?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type AlacarteTicketCreateManyInlineInput = {
  /** Connect multiple existing AlacarteTicket documents */
  connect?: InputMaybe<Array<AlacarteTicketWhereUniqueInput>>;
  /** Create and connect multiple existing AlacarteTicket documents */
  create?: InputMaybe<Array<AlacarteTicketCreateInput>>;
};

export type AlacarteTicketCreateOneInlineInput = {
  /** Connect one existing AlacarteTicket document */
  connect?: InputMaybe<AlacarteTicketWhereUniqueInput>;
  /** Create and connect one AlacarteTicket document */
  create?: InputMaybe<AlacarteTicketCreateInput>;
};

/** An edge in a connection. */
export type AlacarteTicketEdge = {
  __typename?: 'AlacarteTicketEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: AlacarteTicket;
};

/** Identifies documents */
export type AlacarteTicketManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AlacarteTicketWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AlacarteTicketWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AlacarteTicketWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  age?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  age_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  age_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  age_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  age_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  age_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  age_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  age_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  age_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  age_starts_with?: InputMaybe<Scalars['String']>;
  attractionAlacarteTickets?: InputMaybe<AttractionAlacarteWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<AlacarteTicketWhereStageInput>;
  documentInStages_none?: InputMaybe<AlacarteTicketWhereStageInput>;
  documentInStages_some?: InputMaybe<AlacarteTicketWhereStageInput>;
  expiration?: InputMaybe<Scalars['Date']>;
  /** All values greater than the given value. */
  expiration_gt?: InputMaybe<Scalars['Date']>;
  /** All values greater than or equal the given value. */
  expiration_gte?: InputMaybe<Scalars['Date']>;
  /** All values that are contained in given list. */
  expiration_in?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  /** All values less than the given value. */
  expiration_lt?: InputMaybe<Scalars['Date']>;
  /** All values less than or equal the given value. */
  expiration_lte?: InputMaybe<Scalars['Date']>;
  /** Any other value that exists and is not equal to the given value. */
  expiration_not?: InputMaybe<Scalars['Date']>;
  /** All values that are not contained in given list. */
  expiration_not_in?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  internalTitle?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  internalTitle_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  internalTitle_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  internalTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  internalTitle_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  internalTitle_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  internalTitle_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  internalTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  internalTitle_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  internalTitle_starts_with?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  sku?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  sku_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  sku_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  sku_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  sku_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  sku_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  sku_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  sku_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  sku_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  sku_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum AlacarteTicketOrderByInput {
  AgeAsc = 'age_ASC',
  AgeDesc = 'age_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  ExpirationAsc = 'expiration_ASC',
  ExpirationDesc = 'expiration_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  InternalTitleAsc = 'internalTitle_ASC',
  InternalTitleDesc = 'internalTitle_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  SkuAsc = 'sku_ASC',
  SkuDesc = 'sku_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type AlacarteTicketType = Node & {
  __typename?: 'AlacarteTicketType';
  attractionAlacarteTicketTypes: Maybe<AttractionAlacarte>;
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** User that created this document */
  createdBy: Maybe<User>;
  description: Array<Text>;
  /** Get the document in other stages */
  documentInStages: Array<AlacarteTicketType>;
  expiration: Maybe<Scalars['Date']>;
  /** List of AlacarteTicketType versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID'];
  inclusions: Array<Text>;
  inclusionsNote: Array<Text>;
  key: Scalars['String'];
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt: Maybe<Scalars['DateTime']>;
  /** User that last published this document */
  publishedBy: Maybe<User>;
  restrictions: Array<Text>;
  scheduledIn: Array<ScheduledOperation>;
  /** System stage field */
  stage: Stage;
  ticketType: Maybe<Scalars['String']>;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** User that last updated this document */
  updatedBy: Maybe<User>;
  validPostPurchaseForDateRange: Array<Text>;
  validity: Array<Text>;
  validityPostPurchase: Array<Text>;
  validityPostPurchaseNote: Array<Text>;
  validityShort: Array<Text>;
  validityWillCall: Array<Text>;
};

export type AlacarteTicketTypeAttractionAlacarteTicketTypesArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type AlacarteTicketTypeCreatedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type AlacarteTicketTypeDescriptionArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<TextOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<TextWhereInput>;
};

export type AlacarteTicketTypeDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};

export type AlacarteTicketTypeHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride: InputMaybe<Stage>;
};

export type AlacarteTicketTypeInclusionsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<TextOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<TextWhereInput>;
};

export type AlacarteTicketTypeInclusionsNoteArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<TextOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<TextWhereInput>;
};

export type AlacarteTicketTypePublishedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type AlacarteTicketTypeRestrictionsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<TextOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<TextWhereInput>;
};

export type AlacarteTicketTypeScheduledInArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ScheduledOperationWhereInput>;
};

export type AlacarteTicketTypeUpdatedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type AlacarteTicketTypeValidPostPurchaseForDateRangeArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<TextOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<TextWhereInput>;
};

export type AlacarteTicketTypeValidityArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<TextOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<TextWhereInput>;
};

export type AlacarteTicketTypeValidityPostPurchaseArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<TextOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<TextWhereInput>;
};

export type AlacarteTicketTypeValidityPostPurchaseNoteArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<TextOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<TextWhereInput>;
};

export type AlacarteTicketTypeValidityShortArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<TextOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<TextWhereInput>;
};

export type AlacarteTicketTypeValidityWillCallArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<TextOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<TextWhereInput>;
};

export type AlacarteTicketTypeConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: AlacarteTicketTypeWhereUniqueInput;
};

/** A connection to a list of items. */
export type AlacarteTicketTypeConnection = {
  __typename?: 'AlacarteTicketTypeConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<AlacarteTicketTypeEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type AlacarteTicketTypeCreateInput = {
  attractionAlacarteTicketTypes?: InputMaybe<AttractionAlacarteCreateOneInlineInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<TextCreateManyInlineInput>;
  expiration?: InputMaybe<Scalars['Date']>;
  inclusions?: InputMaybe<TextCreateManyInlineInput>;
  inclusionsNote?: InputMaybe<TextCreateManyInlineInput>;
  key: Scalars['String'];
  restrictions?: InputMaybe<TextCreateManyInlineInput>;
  ticketType?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  validPostPurchaseForDateRange?: InputMaybe<TextCreateManyInlineInput>;
  validity?: InputMaybe<TextCreateManyInlineInput>;
  validityPostPurchase?: InputMaybe<TextCreateManyInlineInput>;
  validityPostPurchaseNote?: InputMaybe<TextCreateManyInlineInput>;
  validityShort?: InputMaybe<TextCreateManyInlineInput>;
  validityWillCall?: InputMaybe<TextCreateManyInlineInput>;
};

export type AlacarteTicketTypeCreateManyInlineInput = {
  /** Connect multiple existing AlacarteTicketType documents */
  connect?: InputMaybe<Array<AlacarteTicketTypeWhereUniqueInput>>;
  /** Create and connect multiple existing AlacarteTicketType documents */
  create?: InputMaybe<Array<AlacarteTicketTypeCreateInput>>;
};

export type AlacarteTicketTypeCreateOneInlineInput = {
  /** Connect one existing AlacarteTicketType document */
  connect?: InputMaybe<AlacarteTicketTypeWhereUniqueInput>;
  /** Create and connect one AlacarteTicketType document */
  create?: InputMaybe<AlacarteTicketTypeCreateInput>;
};

/** An edge in a connection. */
export type AlacarteTicketTypeEdge = {
  __typename?: 'AlacarteTicketTypeEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: AlacarteTicketType;
};

/** Identifies documents */
export type AlacarteTicketTypeManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AlacarteTicketTypeWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AlacarteTicketTypeWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AlacarteTicketTypeWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  attractionAlacarteTicketTypes?: InputMaybe<AttractionAlacarteWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  description_every?: InputMaybe<TextWhereInput>;
  description_none?: InputMaybe<TextWhereInput>;
  description_some?: InputMaybe<TextWhereInput>;
  documentInStages_every?: InputMaybe<AlacarteTicketTypeWhereStageInput>;
  documentInStages_none?: InputMaybe<AlacarteTicketTypeWhereStageInput>;
  documentInStages_some?: InputMaybe<AlacarteTicketTypeWhereStageInput>;
  expiration?: InputMaybe<Scalars['Date']>;
  /** All values greater than the given value. */
  expiration_gt?: InputMaybe<Scalars['Date']>;
  /** All values greater than or equal the given value. */
  expiration_gte?: InputMaybe<Scalars['Date']>;
  /** All values that are contained in given list. */
  expiration_in?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  /** All values less than the given value. */
  expiration_lt?: InputMaybe<Scalars['Date']>;
  /** All values less than or equal the given value. */
  expiration_lte?: InputMaybe<Scalars['Date']>;
  /** Any other value that exists and is not equal to the given value. */
  expiration_not?: InputMaybe<Scalars['Date']>;
  /** All values that are not contained in given list. */
  expiration_not_in?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  inclusionsNote_every?: InputMaybe<TextWhereInput>;
  inclusionsNote_none?: InputMaybe<TextWhereInput>;
  inclusionsNote_some?: InputMaybe<TextWhereInput>;
  inclusions_every?: InputMaybe<TextWhereInput>;
  inclusions_none?: InputMaybe<TextWhereInput>;
  inclusions_some?: InputMaybe<TextWhereInput>;
  key?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  key_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  key_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  key_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  key_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  key_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  key_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  key_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  key_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  key_starts_with?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  restrictions_every?: InputMaybe<TextWhereInput>;
  restrictions_none?: InputMaybe<TextWhereInput>;
  restrictions_some?: InputMaybe<TextWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  ticketType?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  ticketType_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  ticketType_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  ticketType_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  ticketType_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  ticketType_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  ticketType_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  ticketType_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  ticketType_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  ticketType_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
  validPostPurchaseForDateRange_every?: InputMaybe<TextWhereInput>;
  validPostPurchaseForDateRange_none?: InputMaybe<TextWhereInput>;
  validPostPurchaseForDateRange_some?: InputMaybe<TextWhereInput>;
  validityPostPurchaseNote_every?: InputMaybe<TextWhereInput>;
  validityPostPurchaseNote_none?: InputMaybe<TextWhereInput>;
  validityPostPurchaseNote_some?: InputMaybe<TextWhereInput>;
  validityPostPurchase_every?: InputMaybe<TextWhereInput>;
  validityPostPurchase_none?: InputMaybe<TextWhereInput>;
  validityPostPurchase_some?: InputMaybe<TextWhereInput>;
  validityShort_every?: InputMaybe<TextWhereInput>;
  validityShort_none?: InputMaybe<TextWhereInput>;
  validityShort_some?: InputMaybe<TextWhereInput>;
  validityWillCall_every?: InputMaybe<TextWhereInput>;
  validityWillCall_none?: InputMaybe<TextWhereInput>;
  validityWillCall_some?: InputMaybe<TextWhereInput>;
  validity_every?: InputMaybe<TextWhereInput>;
  validity_none?: InputMaybe<TextWhereInput>;
  validity_some?: InputMaybe<TextWhereInput>;
};

export enum AlacarteTicketTypeOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  ExpirationAsc = 'expiration_ASC',
  ExpirationDesc = 'expiration_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  TicketTypeAsc = 'ticketType_ASC',
  TicketTypeDesc = 'ticketType_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type AlacarteTicketTypeUpdateInput = {
  attractionAlacarteTicketTypes?: InputMaybe<AttractionAlacarteUpdateOneInlineInput>;
  description?: InputMaybe<TextUpdateManyInlineInput>;
  expiration?: InputMaybe<Scalars['Date']>;
  inclusions?: InputMaybe<TextUpdateManyInlineInput>;
  inclusionsNote?: InputMaybe<TextUpdateManyInlineInput>;
  key?: InputMaybe<Scalars['String']>;
  restrictions?: InputMaybe<TextUpdateManyInlineInput>;
  ticketType?: InputMaybe<Scalars['String']>;
  validPostPurchaseForDateRange?: InputMaybe<TextUpdateManyInlineInput>;
  validity?: InputMaybe<TextUpdateManyInlineInput>;
  validityPostPurchase?: InputMaybe<TextUpdateManyInlineInput>;
  validityPostPurchaseNote?: InputMaybe<TextUpdateManyInlineInput>;
  validityShort?: InputMaybe<TextUpdateManyInlineInput>;
  validityWillCall?: InputMaybe<TextUpdateManyInlineInput>;
};

export type AlacarteTicketTypeUpdateManyInlineInput = {
  /** Connect multiple existing AlacarteTicketType documents */
  connect?: InputMaybe<Array<AlacarteTicketTypeConnectInput>>;
  /** Create and connect multiple AlacarteTicketType documents */
  create?: InputMaybe<Array<AlacarteTicketTypeCreateInput>>;
  /** Delete multiple AlacarteTicketType documents */
  delete?: InputMaybe<Array<AlacarteTicketTypeWhereUniqueInput>>;
  /** Disconnect multiple AlacarteTicketType documents */
  disconnect?: InputMaybe<Array<AlacarteTicketTypeWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing AlacarteTicketType documents */
  set?: InputMaybe<Array<AlacarteTicketTypeWhereUniqueInput>>;
  /** Update multiple AlacarteTicketType documents */
  update?: InputMaybe<Array<AlacarteTicketTypeUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple AlacarteTicketType documents */
  upsert?: InputMaybe<Array<AlacarteTicketTypeUpsertWithNestedWhereUniqueInput>>;
};

export type AlacarteTicketTypeUpdateManyInput = {
  expiration?: InputMaybe<Scalars['Date']>;
  ticketType?: InputMaybe<Scalars['String']>;
};

export type AlacarteTicketTypeUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: AlacarteTicketTypeUpdateManyInput;
  /** Document search */
  where: AlacarteTicketTypeWhereInput;
};

export type AlacarteTicketTypeUpdateOneInlineInput = {
  /** Connect existing AlacarteTicketType document */
  connect?: InputMaybe<AlacarteTicketTypeWhereUniqueInput>;
  /** Create and connect one AlacarteTicketType document */
  create?: InputMaybe<AlacarteTicketTypeCreateInput>;
  /** Delete currently connected AlacarteTicketType document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected AlacarteTicketType document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single AlacarteTicketType document */
  update?: InputMaybe<AlacarteTicketTypeUpdateWithNestedWhereUniqueInput>;
  /** Upsert single AlacarteTicketType document */
  upsert?: InputMaybe<AlacarteTicketTypeUpsertWithNestedWhereUniqueInput>;
};

export type AlacarteTicketTypeUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: AlacarteTicketTypeUpdateInput;
  /** Unique document search */
  where: AlacarteTicketTypeWhereUniqueInput;
};

export type AlacarteTicketTypeUpsertInput = {
  /** Create document if it didn't exist */
  create: AlacarteTicketTypeCreateInput;
  /** Update document if it exists */
  update: AlacarteTicketTypeUpdateInput;
};

export type AlacarteTicketTypeUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: AlacarteTicketTypeUpsertInput;
  /** Unique document search */
  where: AlacarteTicketTypeWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type AlacarteTicketTypeWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']>;
};

/** Identifies documents */
export type AlacarteTicketTypeWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AlacarteTicketTypeWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AlacarteTicketTypeWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AlacarteTicketTypeWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  attractionAlacarteTicketTypes?: InputMaybe<AttractionAlacarteWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  description_every?: InputMaybe<TextWhereInput>;
  description_none?: InputMaybe<TextWhereInput>;
  description_some?: InputMaybe<TextWhereInput>;
  documentInStages_every?: InputMaybe<AlacarteTicketTypeWhereStageInput>;
  documentInStages_none?: InputMaybe<AlacarteTicketTypeWhereStageInput>;
  documentInStages_some?: InputMaybe<AlacarteTicketTypeWhereStageInput>;
  expiration?: InputMaybe<Scalars['Date']>;
  /** All values greater than the given value. */
  expiration_gt?: InputMaybe<Scalars['Date']>;
  /** All values greater than or equal the given value. */
  expiration_gte?: InputMaybe<Scalars['Date']>;
  /** All values that are contained in given list. */
  expiration_in?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  /** All values less than the given value. */
  expiration_lt?: InputMaybe<Scalars['Date']>;
  /** All values less than or equal the given value. */
  expiration_lte?: InputMaybe<Scalars['Date']>;
  /** Any other value that exists and is not equal to the given value. */
  expiration_not?: InputMaybe<Scalars['Date']>;
  /** All values that are not contained in given list. */
  expiration_not_in?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  inclusionsNote_every?: InputMaybe<TextWhereInput>;
  inclusionsNote_none?: InputMaybe<TextWhereInput>;
  inclusionsNote_some?: InputMaybe<TextWhereInput>;
  inclusions_every?: InputMaybe<TextWhereInput>;
  inclusions_none?: InputMaybe<TextWhereInput>;
  inclusions_some?: InputMaybe<TextWhereInput>;
  key?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  key_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  key_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  key_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  key_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  key_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  key_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  key_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  key_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  key_starts_with?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  restrictions_every?: InputMaybe<TextWhereInput>;
  restrictions_none?: InputMaybe<TextWhereInput>;
  restrictions_some?: InputMaybe<TextWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  ticketType?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  ticketType_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  ticketType_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  ticketType_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  ticketType_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  ticketType_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  ticketType_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  ticketType_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  ticketType_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  ticketType_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
  validPostPurchaseForDateRange_every?: InputMaybe<TextWhereInput>;
  validPostPurchaseForDateRange_none?: InputMaybe<TextWhereInput>;
  validPostPurchaseForDateRange_some?: InputMaybe<TextWhereInput>;
  validityPostPurchaseNote_every?: InputMaybe<TextWhereInput>;
  validityPostPurchaseNote_none?: InputMaybe<TextWhereInput>;
  validityPostPurchaseNote_some?: InputMaybe<TextWhereInput>;
  validityPostPurchase_every?: InputMaybe<TextWhereInput>;
  validityPostPurchase_none?: InputMaybe<TextWhereInput>;
  validityPostPurchase_some?: InputMaybe<TextWhereInput>;
  validityShort_every?: InputMaybe<TextWhereInput>;
  validityShort_none?: InputMaybe<TextWhereInput>;
  validityShort_some?: InputMaybe<TextWhereInput>;
  validityWillCall_every?: InputMaybe<TextWhereInput>;
  validityWillCall_none?: InputMaybe<TextWhereInput>;
  validityWillCall_some?: InputMaybe<TextWhereInput>;
  validity_every?: InputMaybe<TextWhereInput>;
  validity_none?: InputMaybe<TextWhereInput>;
  validity_some?: InputMaybe<TextWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type AlacarteTicketTypeWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AlacarteTicketTypeWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AlacarteTicketTypeWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AlacarteTicketTypeWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<AlacarteTicketTypeWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References AlacarteTicketType record uniquely */
export type AlacarteTicketTypeWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
  key?: InputMaybe<Scalars['String']>;
};

export type AlacarteTicketUpdateInput = {
  age?: InputMaybe<Scalars['String']>;
  attractionAlacarteTickets?: InputMaybe<AttractionAlacarteUpdateOneInlineInput>;
  expiration?: InputMaybe<Scalars['Date']>;
  internalTitle?: InputMaybe<Scalars['String']>;
  sku?: InputMaybe<Scalars['String']>;
};

export type AlacarteTicketUpdateManyInlineInput = {
  /** Connect multiple existing AlacarteTicket documents */
  connect?: InputMaybe<Array<AlacarteTicketConnectInput>>;
  /** Create and connect multiple AlacarteTicket documents */
  create?: InputMaybe<Array<AlacarteTicketCreateInput>>;
  /** Delete multiple AlacarteTicket documents */
  delete?: InputMaybe<Array<AlacarteTicketWhereUniqueInput>>;
  /** Disconnect multiple AlacarteTicket documents */
  disconnect?: InputMaybe<Array<AlacarteTicketWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing AlacarteTicket documents */
  set?: InputMaybe<Array<AlacarteTicketWhereUniqueInput>>;
  /** Update multiple AlacarteTicket documents */
  update?: InputMaybe<Array<AlacarteTicketUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple AlacarteTicket documents */
  upsert?: InputMaybe<Array<AlacarteTicketUpsertWithNestedWhereUniqueInput>>;
};

export type AlacarteTicketUpdateManyInput = {
  age?: InputMaybe<Scalars['String']>;
  expiration?: InputMaybe<Scalars['Date']>;
  internalTitle?: InputMaybe<Scalars['String']>;
  sku?: InputMaybe<Scalars['String']>;
};

export type AlacarteTicketUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: AlacarteTicketUpdateManyInput;
  /** Document search */
  where: AlacarteTicketWhereInput;
};

export type AlacarteTicketUpdateOneInlineInput = {
  /** Connect existing AlacarteTicket document */
  connect?: InputMaybe<AlacarteTicketWhereUniqueInput>;
  /** Create and connect one AlacarteTicket document */
  create?: InputMaybe<AlacarteTicketCreateInput>;
  /** Delete currently connected AlacarteTicket document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected AlacarteTicket document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single AlacarteTicket document */
  update?: InputMaybe<AlacarteTicketUpdateWithNestedWhereUniqueInput>;
  /** Upsert single AlacarteTicket document */
  upsert?: InputMaybe<AlacarteTicketUpsertWithNestedWhereUniqueInput>;
};

export type AlacarteTicketUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: AlacarteTicketUpdateInput;
  /** Unique document search */
  where: AlacarteTicketWhereUniqueInput;
};

export type AlacarteTicketUpsertInput = {
  /** Create document if it didn't exist */
  create: AlacarteTicketCreateInput;
  /** Update document if it exists */
  update: AlacarteTicketUpdateInput;
};

export type AlacarteTicketUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: AlacarteTicketUpsertInput;
  /** Unique document search */
  where: AlacarteTicketWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type AlacarteTicketWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']>;
};

/** Identifies documents */
export type AlacarteTicketWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AlacarteTicketWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AlacarteTicketWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AlacarteTicketWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  age?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  age_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  age_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  age_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  age_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  age_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  age_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  age_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  age_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  age_starts_with?: InputMaybe<Scalars['String']>;
  attractionAlacarteTickets?: InputMaybe<AttractionAlacarteWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<AlacarteTicketWhereStageInput>;
  documentInStages_none?: InputMaybe<AlacarteTicketWhereStageInput>;
  documentInStages_some?: InputMaybe<AlacarteTicketWhereStageInput>;
  expiration?: InputMaybe<Scalars['Date']>;
  /** All values greater than the given value. */
  expiration_gt?: InputMaybe<Scalars['Date']>;
  /** All values greater than or equal the given value. */
  expiration_gte?: InputMaybe<Scalars['Date']>;
  /** All values that are contained in given list. */
  expiration_in?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  /** All values less than the given value. */
  expiration_lt?: InputMaybe<Scalars['Date']>;
  /** All values less than or equal the given value. */
  expiration_lte?: InputMaybe<Scalars['Date']>;
  /** Any other value that exists and is not equal to the given value. */
  expiration_not?: InputMaybe<Scalars['Date']>;
  /** All values that are not contained in given list. */
  expiration_not_in?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  internalTitle?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  internalTitle_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  internalTitle_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  internalTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  internalTitle_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  internalTitle_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  internalTitle_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  internalTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  internalTitle_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  internalTitle_starts_with?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  sku?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  sku_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  sku_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  sku_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  sku_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  sku_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  sku_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  sku_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  sku_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  sku_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type AlacarteTicketWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AlacarteTicketWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AlacarteTicketWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AlacarteTicketWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<AlacarteTicketWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References AlacarteTicket record uniquely */
export type AlacarteTicketWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type Article = Node & {
  __typename?: 'Article';
  author: Maybe<Author>;
  body: Maybe<Scalars['String']>;
  category: Maybe<ArticleCategory>;
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** User that created this document */
  createdBy: Maybe<User>;
  /** Get the document in other stages */
  documentInStages: Array<Article>;
  firstPublishedAt: Maybe<Scalars['Date']>;
  hero: Asset;
  /** List of Article versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID'];
  key: Maybe<Scalars['String']>;
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<Article>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt: Maybe<Scalars['DateTime']>;
  /** User that last published this document */
  publishedBy: Maybe<User>;
  qabu: Maybe<Scalars['Json']>;
  scheduledIn: Array<ScheduledOperation>;
  seo: Maybe<Seo>;
  seoDescription: Maybe<Scalars['String']>;
  seoTitle: Maybe<Scalars['String']>;
  showPublishDate: Scalars['Boolean'];
  slug: Scalars['String'];
  /** System stage field */
  stage: Stage;
  tags: Array<Scalars['String']>;
  teaser: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
  type: Maybe<ArticleContentType>;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** User that last updated this document */
  updatedBy: Maybe<User>;
};

export type ArticleAuthorArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type ArticleCategoryArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type ArticleCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type ArticleCreatedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type ArticleDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};

export type ArticleHeroArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type ArticleHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride: InputMaybe<Stage>;
};

export type ArticleLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};

export type ArticlePublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type ArticlePublishedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type ArticleScheduledInArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ScheduledOperationWhereInput>;
};

export type ArticleSeoArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type ArticleUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type ArticleUpdatedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

/** WARNING: Enabling localizations for any fields on this model will change the query results for listing pages and related articles! If localizations are needed you MUST update the queries to use locale fallbacks. */
export type ArticleCategory = Node & {
  __typename?: 'ArticleCategory';
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** User that created this document */
  createdBy: Maybe<User>;
  /** Get the document in other stages */
  documentInStages: Array<ArticleCategory>;
  /** List of ArticleCategory versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID'];
  product: Maybe<Product>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt: Maybe<Scalars['DateTime']>;
  /** User that last published this document */
  publishedBy: Maybe<User>;
  qabu: Maybe<Scalars['Json']>;
  scheduledIn: Array<ScheduledOperation>;
  slug: Scalars['String'];
  /** System stage field */
  stage: Stage;
  /** WARNING: Enabling localizations for any fields on this model will change the query results for listing pages and related articles! If localizations are needed you MUST update the queries to use locale fallbacks. */
  title: Maybe<Scalars['String']>;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** User that last updated this document */
  updatedBy: Maybe<User>;
};

/** WARNING: Enabling localizations for any fields on this model will change the query results for listing pages and related articles! If localizations are needed you MUST update the queries to use locale fallbacks. */
export type ArticleCategoryCreatedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

/** WARNING: Enabling localizations for any fields on this model will change the query results for listing pages and related articles! If localizations are needed you MUST update the queries to use locale fallbacks. */
export type ArticleCategoryDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};

/** WARNING: Enabling localizations for any fields on this model will change the query results for listing pages and related articles! If localizations are needed you MUST update the queries to use locale fallbacks. */
export type ArticleCategoryHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride: InputMaybe<Stage>;
};

/** WARNING: Enabling localizations for any fields on this model will change the query results for listing pages and related articles! If localizations are needed you MUST update the queries to use locale fallbacks. */
export type ArticleCategoryProductArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

/** WARNING: Enabling localizations for any fields on this model will change the query results for listing pages and related articles! If localizations are needed you MUST update the queries to use locale fallbacks. */
export type ArticleCategoryPublishedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

/** WARNING: Enabling localizations for any fields on this model will change the query results for listing pages and related articles! If localizations are needed you MUST update the queries to use locale fallbacks. */
export type ArticleCategoryScheduledInArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ScheduledOperationWhereInput>;
};

/** WARNING: Enabling localizations for any fields on this model will change the query results for listing pages and related articles! If localizations are needed you MUST update the queries to use locale fallbacks. */
export type ArticleCategoryUpdatedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type ArticleCategoryConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: ArticleCategoryWhereUniqueInput;
};

/** A connection to a list of items. */
export type ArticleCategoryConnection = {
  __typename?: 'ArticleCategoryConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<ArticleCategoryEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type ArticleCategoryCreateInput = {
  ckwnz492o08mm01xm2yohe928?: InputMaybe<ArticleCreateManyInlineInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  product?: InputMaybe<ProductCreateOneInlineInput>;
  qabu?: InputMaybe<Scalars['Json']>;
  slug: Scalars['String'];
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ArticleCategoryCreateManyInlineInput = {
  /** Connect multiple existing ArticleCategory documents */
  connect?: InputMaybe<Array<ArticleCategoryWhereUniqueInput>>;
  /** Create and connect multiple existing ArticleCategory documents */
  create?: InputMaybe<Array<ArticleCategoryCreateInput>>;
};

export type ArticleCategoryCreateOneInlineInput = {
  /** Connect one existing ArticleCategory document */
  connect?: InputMaybe<ArticleCategoryWhereUniqueInput>;
  /** Create and connect one ArticleCategory document */
  create?: InputMaybe<ArticleCategoryCreateInput>;
};

/** An edge in a connection. */
export type ArticleCategoryEdge = {
  __typename?: 'ArticleCategoryEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: ArticleCategory;
};

/** Identifies documents */
export type ArticleCategoryManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ArticleCategoryWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ArticleCategoryWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ArticleCategoryWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<ArticleCategoryWhereStageInput>;
  documentInStages_none?: InputMaybe<ArticleCategoryWhereStageInput>;
  documentInStages_some?: InputMaybe<ArticleCategoryWhereStageInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  product?: InputMaybe<ProductWhereInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  /** All values containing the given json path. */
  qabu_json_path_exists?: InputMaybe<Scalars['String']>;
  /**
   * Recursively tries to find the provided JSON scalar value inside the field.
   * It does use an exact match when comparing values.
   * If you pass `null` as value the filter will be ignored.
   * Note: This filter fails if you try to look for a non scalar JSON value!
   */
  qabu_value_recursive?: InputMaybe<Scalars['Json']>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  slug?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  slug_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  slug_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  slug_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  slug_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  slug_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  slug_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  slug_starts_with?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum ArticleCategoryOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type ArticleCategoryUpdateInput = {
  ckwnz492o08mm01xm2yohe928?: InputMaybe<ArticleUpdateManyInlineInput>;
  product?: InputMaybe<ProductUpdateOneInlineInput>;
  qabu?: InputMaybe<Scalars['Json']>;
  slug?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type ArticleCategoryUpdateManyInlineInput = {
  /** Connect multiple existing ArticleCategory documents */
  connect?: InputMaybe<Array<ArticleCategoryConnectInput>>;
  /** Create and connect multiple ArticleCategory documents */
  create?: InputMaybe<Array<ArticleCategoryCreateInput>>;
  /** Delete multiple ArticleCategory documents */
  delete?: InputMaybe<Array<ArticleCategoryWhereUniqueInput>>;
  /** Disconnect multiple ArticleCategory documents */
  disconnect?: InputMaybe<Array<ArticleCategoryWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing ArticleCategory documents */
  set?: InputMaybe<Array<ArticleCategoryWhereUniqueInput>>;
  /** Update multiple ArticleCategory documents */
  update?: InputMaybe<Array<ArticleCategoryUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple ArticleCategory documents */
  upsert?: InputMaybe<Array<ArticleCategoryUpsertWithNestedWhereUniqueInput>>;
};

export type ArticleCategoryUpdateManyInput = {
  qabu?: InputMaybe<Scalars['Json']>;
};

export type ArticleCategoryUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: ArticleCategoryUpdateManyInput;
  /** Document search */
  where: ArticleCategoryWhereInput;
};

export type ArticleCategoryUpdateOneInlineInput = {
  /** Connect existing ArticleCategory document */
  connect?: InputMaybe<ArticleCategoryWhereUniqueInput>;
  /** Create and connect one ArticleCategory document */
  create?: InputMaybe<ArticleCategoryCreateInput>;
  /** Delete currently connected ArticleCategory document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected ArticleCategory document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single ArticleCategory document */
  update?: InputMaybe<ArticleCategoryUpdateWithNestedWhereUniqueInput>;
  /** Upsert single ArticleCategory document */
  upsert?: InputMaybe<ArticleCategoryUpsertWithNestedWhereUniqueInput>;
};

export type ArticleCategoryUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: ArticleCategoryUpdateInput;
  /** Unique document search */
  where: ArticleCategoryWhereUniqueInput;
};

export type ArticleCategoryUpsertInput = {
  /** Create document if it didn't exist */
  create: ArticleCategoryCreateInput;
  /** Update document if it exists */
  update: ArticleCategoryUpdateInput;
};

export type ArticleCategoryUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: ArticleCategoryUpsertInput;
  /** Unique document search */
  where: ArticleCategoryWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type ArticleCategoryWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']>;
};

/** Identifies documents */
export type ArticleCategoryWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ArticleCategoryWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ArticleCategoryWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ArticleCategoryWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<ArticleCategoryWhereStageInput>;
  documentInStages_none?: InputMaybe<ArticleCategoryWhereStageInput>;
  documentInStages_some?: InputMaybe<ArticleCategoryWhereStageInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  product?: InputMaybe<ProductWhereInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  /** All values containing the given json path. */
  qabu_json_path_exists?: InputMaybe<Scalars['String']>;
  /**
   * Recursively tries to find the provided JSON scalar value inside the field.
   * It does use an exact match when comparing values.
   * If you pass `null` as value the filter will be ignored.
   * Note: This filter fails if you try to look for a non scalar JSON value!
   */
  qabu_value_recursive?: InputMaybe<Scalars['Json']>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  slug?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  slug_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  slug_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  slug_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  slug_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  slug_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  slug_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  slug_starts_with?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type ArticleCategoryWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ArticleCategoryWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ArticleCategoryWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ArticleCategoryWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<ArticleCategoryWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References ArticleCategory record uniquely */
export type ArticleCategoryWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
  slug?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type ArticleConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: ArticleWhereUniqueInput;
};

/** A connection to a list of items. */
export type ArticleConnection = {
  __typename?: 'ArticleConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<ArticleEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export enum ArticleContentType {
  Evergreen = 'evergreen',
}

export type ArticleCreateInput = {
  author?: InputMaybe<AuthorCreateOneInlineInput>;
  /** body input for default locale (en) */
  body?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<ArticleCategoryCreateOneInlineInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  firstPublishedAt?: InputMaybe<Scalars['Date']>;
  hero: AssetCreateOneInlineInput;
  key?: InputMaybe<Scalars['String']>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<ArticleCreateLocalizationsInput>;
  qabu?: InputMaybe<Scalars['Json']>;
  seo?: InputMaybe<SeoCreateOneInlineInput>;
  /** seoDescription input for default locale (en) */
  seoDescription?: InputMaybe<Scalars['String']>;
  /** seoTitle input for default locale (en) */
  seoTitle?: InputMaybe<Scalars['String']>;
  showPublishDate: Scalars['Boolean'];
  slug: Scalars['String'];
  tags?: InputMaybe<Array<Scalars['String']>>;
  /** teaser input for default locale (en) */
  teaser?: InputMaybe<Scalars['String']>;
  /** title input for default locale (en) */
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<ArticleContentType>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ArticleCreateLocalizationDataInput = {
  body?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  seoDescription?: InputMaybe<Scalars['String']>;
  seoTitle?: InputMaybe<Scalars['String']>;
  teaser?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ArticleCreateLocalizationInput = {
  /** Localization input */
  data: ArticleCreateLocalizationDataInput;
  locale: Locale;
};

export type ArticleCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<ArticleCreateLocalizationInput>>;
};

export type ArticleCreateManyInlineInput = {
  /** Connect multiple existing Article documents */
  connect?: InputMaybe<Array<ArticleWhereUniqueInput>>;
  /** Create and connect multiple existing Article documents */
  create?: InputMaybe<Array<ArticleCreateInput>>;
};

export type ArticleCreateOneInlineInput = {
  /** Connect one existing Article document */
  connect?: InputMaybe<ArticleWhereUniqueInput>;
  /** Create and connect one Article document */
  create?: InputMaybe<ArticleCreateInput>;
};

/** An edge in a connection. */
export type ArticleEdge = {
  __typename?: 'ArticleEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Article;
};

/** Identifies documents */
export type ArticleManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ArticleWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ArticleWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ArticleWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  author?: InputMaybe<AuthorWhereInput>;
  category?: InputMaybe<ArticleCategoryWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<ArticleWhereStageInput>;
  documentInStages_none?: InputMaybe<ArticleWhereStageInput>;
  documentInStages_some?: InputMaybe<ArticleWhereStageInput>;
  firstPublishedAt?: InputMaybe<Scalars['Date']>;
  /** All values greater than the given value. */
  firstPublishedAt_gt?: InputMaybe<Scalars['Date']>;
  /** All values greater than or equal the given value. */
  firstPublishedAt_gte?: InputMaybe<Scalars['Date']>;
  /** All values that are contained in given list. */
  firstPublishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  /** All values less than the given value. */
  firstPublishedAt_lt?: InputMaybe<Scalars['Date']>;
  /** All values less than or equal the given value. */
  firstPublishedAt_lte?: InputMaybe<Scalars['Date']>;
  /** Any other value that exists and is not equal to the given value. */
  firstPublishedAt_not?: InputMaybe<Scalars['Date']>;
  /** All values that are not contained in given list. */
  firstPublishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  hero?: InputMaybe<AssetWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  key?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  key_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  key_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  key_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  key_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  key_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  key_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  key_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  key_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  key_starts_with?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  /** All values containing the given json path. */
  qabu_json_path_exists?: InputMaybe<Scalars['String']>;
  /**
   * Recursively tries to find the provided JSON scalar value inside the field.
   * It does use an exact match when comparing values.
   * If you pass `null` as value the filter will be ignored.
   * Note: This filter fails if you try to look for a non scalar JSON value!
   */
  qabu_value_recursive?: InputMaybe<Scalars['Json']>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  seo?: InputMaybe<SeoWhereInput>;
  showPublishDate?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  showPublishDate_not?: InputMaybe<Scalars['Boolean']>;
  slug?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  slug_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  slug_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  slug_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  slug_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  slug_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  slug_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  slug_starts_with?: InputMaybe<Scalars['String']>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  tags?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains *all* items provided to the filter */
  tags_contains_all?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  tags_contains_none?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains at least one item provided to the filter */
  tags_contains_some?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  tags_not?: InputMaybe<Array<Scalars['String']>>;
  type?: InputMaybe<ArticleContentType>;
  /** All values that are contained in given list. */
  type_in?: InputMaybe<Array<InputMaybe<ArticleContentType>>>;
  /** Any other value that exists and is not equal to the given value. */
  type_not?: InputMaybe<ArticleContentType>;
  /** All values that are not contained in given list. */
  type_not_in?: InputMaybe<Array<InputMaybe<ArticleContentType>>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum ArticleOrderByInput {
  BodyAsc = 'body_ASC',
  BodyDesc = 'body_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  FirstPublishedAtAsc = 'firstPublishedAt_ASC',
  FirstPublishedAtDesc = 'firstPublishedAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  SeoDescriptionAsc = 'seoDescription_ASC',
  SeoDescriptionDesc = 'seoDescription_DESC',
  SeoTitleAsc = 'seoTitle_ASC',
  SeoTitleDesc = 'seoTitle_DESC',
  ShowPublishDateAsc = 'showPublishDate_ASC',
  ShowPublishDateDesc = 'showPublishDate_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  TagsAsc = 'tags_ASC',
  TagsDesc = 'tags_DESC',
  TeaserAsc = 'teaser_ASC',
  TeaserDesc = 'teaser_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type ArticleUpdateInput = {
  author?: InputMaybe<AuthorUpdateOneInlineInput>;
  /** body input for default locale (en) */
  body?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<ArticleCategoryUpdateOneInlineInput>;
  firstPublishedAt?: InputMaybe<Scalars['Date']>;
  hero?: InputMaybe<AssetUpdateOneInlineInput>;
  key?: InputMaybe<Scalars['String']>;
  /** Manage document localizations */
  localizations?: InputMaybe<ArticleUpdateLocalizationsInput>;
  qabu?: InputMaybe<Scalars['Json']>;
  seo?: InputMaybe<SeoUpdateOneInlineInput>;
  /** seoDescription input for default locale (en) */
  seoDescription?: InputMaybe<Scalars['String']>;
  /** seoTitle input for default locale (en) */
  seoTitle?: InputMaybe<Scalars['String']>;
  showPublishDate?: InputMaybe<Scalars['Boolean']>;
  slug?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Array<Scalars['String']>>;
  /** teaser input for default locale (en) */
  teaser?: InputMaybe<Scalars['String']>;
  /** title input for default locale (en) */
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<ArticleContentType>;
};

export type ArticleUpdateLocalizationDataInput = {
  body?: InputMaybe<Scalars['String']>;
  seoDescription?: InputMaybe<Scalars['String']>;
  seoTitle?: InputMaybe<Scalars['String']>;
  teaser?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type ArticleUpdateLocalizationInput = {
  data: ArticleUpdateLocalizationDataInput;
  locale: Locale;
};

export type ArticleUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<ArticleCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<ArticleUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<ArticleUpsertLocalizationInput>>;
};

export type ArticleUpdateManyInlineInput = {
  /** Connect multiple existing Article documents */
  connect?: InputMaybe<Array<ArticleConnectInput>>;
  /** Create and connect multiple Article documents */
  create?: InputMaybe<Array<ArticleCreateInput>>;
  /** Delete multiple Article documents */
  delete?: InputMaybe<Array<ArticleWhereUniqueInput>>;
  /** Disconnect multiple Article documents */
  disconnect?: InputMaybe<Array<ArticleWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing Article documents */
  set?: InputMaybe<Array<ArticleWhereUniqueInput>>;
  /** Update multiple Article documents */
  update?: InputMaybe<Array<ArticleUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple Article documents */
  upsert?: InputMaybe<Array<ArticleUpsertWithNestedWhereUniqueInput>>;
};

export type ArticleUpdateManyInput = {
  /** body input for default locale (en) */
  body?: InputMaybe<Scalars['String']>;
  firstPublishedAt?: InputMaybe<Scalars['Date']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<ArticleUpdateManyLocalizationsInput>;
  qabu?: InputMaybe<Scalars['Json']>;
  /** seoDescription input for default locale (en) */
  seoDescription?: InputMaybe<Scalars['String']>;
  /** seoTitle input for default locale (en) */
  seoTitle?: InputMaybe<Scalars['String']>;
  showPublishDate?: InputMaybe<Scalars['Boolean']>;
  tags?: InputMaybe<Array<Scalars['String']>>;
  /** teaser input for default locale (en) */
  teaser?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<ArticleContentType>;
};

export type ArticleUpdateManyLocalizationDataInput = {
  body?: InputMaybe<Scalars['String']>;
  seoDescription?: InputMaybe<Scalars['String']>;
  seoTitle?: InputMaybe<Scalars['String']>;
  teaser?: InputMaybe<Scalars['String']>;
};

export type ArticleUpdateManyLocalizationInput = {
  data: ArticleUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type ArticleUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<ArticleUpdateManyLocalizationInput>>;
};

export type ArticleUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: ArticleUpdateManyInput;
  /** Document search */
  where: ArticleWhereInput;
};

export type ArticleUpdateOneInlineInput = {
  /** Connect existing Article document */
  connect?: InputMaybe<ArticleWhereUniqueInput>;
  /** Create and connect one Article document */
  create?: InputMaybe<ArticleCreateInput>;
  /** Delete currently connected Article document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected Article document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single Article document */
  update?: InputMaybe<ArticleUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Article document */
  upsert?: InputMaybe<ArticleUpsertWithNestedWhereUniqueInput>;
};

export type ArticleUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: ArticleUpdateInput;
  /** Unique document search */
  where: ArticleWhereUniqueInput;
};

export type ArticleUpsertInput = {
  /** Create document if it didn't exist */
  create: ArticleCreateInput;
  /** Update document if it exists */
  update: ArticleUpdateInput;
};

export type ArticleUpsertLocalizationInput = {
  create: ArticleCreateLocalizationDataInput;
  locale: Locale;
  update: ArticleUpdateLocalizationDataInput;
};

export type ArticleUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: ArticleUpsertInput;
  /** Unique document search */
  where: ArticleWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type ArticleWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']>;
};

/** Identifies documents */
export type ArticleWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ArticleWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ArticleWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ArticleWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  author?: InputMaybe<AuthorWhereInput>;
  body?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  body_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  body_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  body_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  body_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  body_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  body_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  body_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  body_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  body_starts_with?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<ArticleCategoryWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<ArticleWhereStageInput>;
  documentInStages_none?: InputMaybe<ArticleWhereStageInput>;
  documentInStages_some?: InputMaybe<ArticleWhereStageInput>;
  firstPublishedAt?: InputMaybe<Scalars['Date']>;
  /** All values greater than the given value. */
  firstPublishedAt_gt?: InputMaybe<Scalars['Date']>;
  /** All values greater than or equal the given value. */
  firstPublishedAt_gte?: InputMaybe<Scalars['Date']>;
  /** All values that are contained in given list. */
  firstPublishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  /** All values less than the given value. */
  firstPublishedAt_lt?: InputMaybe<Scalars['Date']>;
  /** All values less than or equal the given value. */
  firstPublishedAt_lte?: InputMaybe<Scalars['Date']>;
  /** Any other value that exists and is not equal to the given value. */
  firstPublishedAt_not?: InputMaybe<Scalars['Date']>;
  /** All values that are not contained in given list. */
  firstPublishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  hero?: InputMaybe<AssetWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  key?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  key_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  key_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  key_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  key_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  key_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  key_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  key_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  key_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  key_starts_with?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  /** All values containing the given json path. */
  qabu_json_path_exists?: InputMaybe<Scalars['String']>;
  /**
   * Recursively tries to find the provided JSON scalar value inside the field.
   * It does use an exact match when comparing values.
   * If you pass `null` as value the filter will be ignored.
   * Note: This filter fails if you try to look for a non scalar JSON value!
   */
  qabu_value_recursive?: InputMaybe<Scalars['Json']>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  seo?: InputMaybe<SeoWhereInput>;
  seoDescription?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  seoDescription_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  seoDescription_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  seoDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  seoDescription_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  seoDescription_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  seoDescription_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  seoDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  seoDescription_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  seoDescription_starts_with?: InputMaybe<Scalars['String']>;
  seoTitle?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  seoTitle_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  seoTitle_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  seoTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  seoTitle_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  seoTitle_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  seoTitle_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  seoTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  seoTitle_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  seoTitle_starts_with?: InputMaybe<Scalars['String']>;
  showPublishDate?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  showPublishDate_not?: InputMaybe<Scalars['Boolean']>;
  slug?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  slug_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  slug_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  slug_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  slug_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  slug_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  slug_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  slug_starts_with?: InputMaybe<Scalars['String']>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  tags?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains *all* items provided to the filter */
  tags_contains_all?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  tags_contains_none?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains at least one item provided to the filter */
  tags_contains_some?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  tags_not?: InputMaybe<Array<Scalars['String']>>;
  teaser?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  teaser_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  teaser_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  teaser_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  teaser_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  teaser_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  teaser_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  teaser_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  teaser_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  teaser_starts_with?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<ArticleContentType>;
  /** All values that are contained in given list. */
  type_in?: InputMaybe<Array<InputMaybe<ArticleContentType>>>;
  /** Any other value that exists and is not equal to the given value. */
  type_not?: InputMaybe<ArticleContentType>;
  /** All values that are not contained in given list. */
  type_not_in?: InputMaybe<Array<InputMaybe<ArticleContentType>>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type ArticleWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ArticleWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ArticleWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ArticleWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<ArticleWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References Article record uniquely */
export type ArticleWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
  key?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
};

/** Asset system model */
export type Asset = Node & {
  __typename?: 'Asset';
  blurDataURL: Maybe<Scalars['String']>;
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** User that created this document */
  createdBy: Maybe<User>;
  crossPromoImageProduct: Array<Product>;
  /** Get the document in other stages */
  documentInStages: Array<Asset>;
  /** The file name */
  fileName: Scalars['String'];
  /** The file handle */
  handle: Scalars['String'];
  /** The height of the file */
  height: Maybe<Scalars['Float']>;
  heroArticle: Array<Article>;
  heroAttraction: Array<Attraction>;
  heroLandingPage: Array<LandingPage>;
  heroPage: Array<Page>;
  heroPageTemplate: Array<PageTemplate>;
  heroProduct: Array<Product>;
  /** List of Asset versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID'];
  imageAddOn: Array<AddOn>;
  imageBlock: Array<Block>;
  imageImage: Array<Image>;
  imagePartnerOffer: Array<PartnerOffer>;
  internalTitle: Maybe<Scalars['String']>;
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<Asset>;
  logoAttraction: Array<Attraction>;
  logoProduct: Array<Product>;
  logoSpotOutlineProduct: Array<Product>;
  logoSpotProduct: Array<Product>;
  logoTicketGroup: Array<TicketGroup>;
  logoTicketProduct: Array<Product>;
  logoWhiteProduct: Array<Product>;
  mapImageProduct: Array<Product>;
  /** The mime type of the file */
  mimeType: Maybe<Scalars['String']>;
  productImageProduct: Array<Product>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt: Maybe<Scalars['DateTime']>;
  /** User that last published this document */
  publishedBy: Maybe<User>;
  relatedProductActivityItem: Array<ProductActivityItem>;
  scheduledIn: Array<ScheduledOperation>;
  /** The file size */
  size: Maybe<Scalars['Float']>;
  /** System stage field */
  stage: Stage;
  thumbAttraction: Array<Attraction>;
  thumbProduct: Array<Product>;
  thumbnailVideo: Array<Video>;
  ticketsBackgroundProductReservation: Array<ProductReservation>;
  title: Maybe<Scalars['String']>;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** User that last updated this document */
  updatedBy: Maybe<User>;
  /** Get the url for the asset with provided transformations applied. */
  url: Scalars['String'];
  variants: Array<AssetVariant>;
  /** The file width */
  width: Maybe<Scalars['Float']>;
};

/** Asset system model */
export type AssetCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

/** Asset system model */
export type AssetCreatedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

/** Asset system model */
export type AssetCrossPromoImageProductArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<ProductOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ProductWhereInput>;
};

/** Asset system model */
export type AssetDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};

/** Asset system model */
export type AssetHeroArticleArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<ArticleOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ArticleWhereInput>;
};

/** Asset system model */
export type AssetHeroAttractionArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<AttractionOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AttractionWhereInput>;
};

/** Asset system model */
export type AssetHeroLandingPageArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<LandingPageOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<LandingPageWhereInput>;
};

/** Asset system model */
export type AssetHeroPageArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<PageOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<PageWhereInput>;
};

/** Asset system model */
export type AssetHeroPageTemplateArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<PageTemplateOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<PageTemplateWhereInput>;
};

/** Asset system model */
export type AssetHeroProductArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<ProductOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ProductWhereInput>;
};

/** Asset system model */
export type AssetHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride: InputMaybe<Stage>;
};

/** Asset system model */
export type AssetImageAddOnArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<AddOnOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AddOnWhereInput>;
};

/** Asset system model */
export type AssetImageBlockArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<BlockOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<BlockWhereInput>;
};

/** Asset system model */
export type AssetImageImageArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<ImageOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ImageWhereInput>;
};

/** Asset system model */
export type AssetImagePartnerOfferArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<PartnerOfferOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<PartnerOfferWhereInput>;
};

/** Asset system model */
export type AssetLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};

/** Asset system model */
export type AssetLogoAttractionArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<AttractionOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AttractionWhereInput>;
};

/** Asset system model */
export type AssetLogoProductArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<ProductOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ProductWhereInput>;
};

/** Asset system model */
export type AssetLogoSpotOutlineProductArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<ProductOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ProductWhereInput>;
};

/** Asset system model */
export type AssetLogoSpotProductArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<ProductOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ProductWhereInput>;
};

/** Asset system model */
export type AssetLogoTicketGroupArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<TicketGroupOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<TicketGroupWhereInput>;
};

/** Asset system model */
export type AssetLogoTicketProductArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<ProductOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ProductWhereInput>;
};

/** Asset system model */
export type AssetLogoWhiteProductArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<ProductOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ProductWhereInput>;
};

/** Asset system model */
export type AssetMapImageProductArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<ProductOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ProductWhereInput>;
};

/** Asset system model */
export type AssetProductImageProductArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<ProductOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ProductWhereInput>;
};

/** Asset system model */
export type AssetPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

/** Asset system model */
export type AssetPublishedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

/** Asset system model */
export type AssetRelatedProductActivityItemArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<ProductActivityItemOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ProductActivityItemWhereInput>;
};

/** Asset system model */
export type AssetScheduledInArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ScheduledOperationWhereInput>;
};

/** Asset system model */
export type AssetThumbAttractionArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<AttractionOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AttractionWhereInput>;
};

/** Asset system model */
export type AssetThumbProductArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<ProductOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ProductWhereInput>;
};

/** Asset system model */
export type AssetThumbnailVideoArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<VideoOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<VideoWhereInput>;
};

/** Asset system model */
export type AssetTicketsBackgroundProductReservationArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<ProductReservationOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ProductReservationWhereInput>;
};

/** Asset system model */
export type AssetUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

/** Asset system model */
export type AssetUpdatedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

/** Asset system model */
export type AssetUrlArgs = {
  transformation: InputMaybe<AssetTransformationInput>;
};

/** Asset system model */
export type AssetVariantsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<AssetVariantOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AssetVariantWhereInput>;
};

export type AssetConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: AssetWhereUniqueInput;
};

/** A connection to a list of items. */
export type AssetConnection = {
  __typename?: 'AssetConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<AssetEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type AssetCreateInput = {
  blurDataURL?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  crossPromoImageProduct?: InputMaybe<ProductCreateManyInlineInput>;
  fileName: Scalars['String'];
  handle: Scalars['String'];
  height?: InputMaybe<Scalars['Float']>;
  heroArticle?: InputMaybe<ArticleCreateManyInlineInput>;
  heroAttraction?: InputMaybe<AttractionCreateManyInlineInput>;
  heroLandingPage?: InputMaybe<LandingPageCreateManyInlineInput>;
  heroPage?: InputMaybe<PageCreateManyInlineInput>;
  heroPageTemplate?: InputMaybe<PageTemplateCreateManyInlineInput>;
  heroProduct?: InputMaybe<ProductCreateManyInlineInput>;
  imageAddOn?: InputMaybe<AddOnCreateManyInlineInput>;
  imageBlock?: InputMaybe<BlockCreateManyInlineInput>;
  imageCrossPromoBanner?: InputMaybe<CrossPromoBannerCreateManyInlineInput>;
  imageImage?: InputMaybe<ImageCreateManyInlineInput>;
  imageImageVariant?: InputMaybe<AssetVariantCreateManyInlineInput>;
  imagePartnerOffer?: InputMaybe<PartnerOfferCreateManyInlineInput>;
  imagePromoBanner?: InputMaybe<PromoBannerCreateManyInlineInput>;
  imagePromoTicketBanner?: InputMaybe<PromoTicketBannerCreateManyInlineInput>;
  internalTitle?: InputMaybe<Scalars['String']>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<AssetCreateLocalizationsInput>;
  logoAttraction?: InputMaybe<AttractionCreateManyInlineInput>;
  logoCrossPromoBanner?: InputMaybe<CrossPromoBannerCreateManyInlineInput>;
  logoProduct?: InputMaybe<ProductCreateManyInlineInput>;
  logoPromoBanner?: InputMaybe<PromoBannerCreateManyInlineInput>;
  logoPromoTicketBanner?: InputMaybe<PromoTicketBannerCreateManyInlineInput>;
  logoSpotOutlineProduct?: InputMaybe<ProductCreateManyInlineInput>;
  logoSpotProduct?: InputMaybe<ProductCreateManyInlineInput>;
  logoTicketGroup?: InputMaybe<TicketGroupCreateManyInlineInput>;
  logoTicketProduct?: InputMaybe<ProductCreateManyInlineInput>;
  logoWhiteProduct?: InputMaybe<ProductCreateManyInlineInput>;
  mapImageProduct?: InputMaybe<ProductCreateManyInlineInput>;
  mimeType?: InputMaybe<Scalars['String']>;
  productImageProduct?: InputMaybe<ProductCreateManyInlineInput>;
  relatedProductActivityItem?: InputMaybe<ProductActivityItemCreateManyInlineInput>;
  size?: InputMaybe<Scalars['Float']>;
  thumbAttraction?: InputMaybe<AttractionCreateManyInlineInput>;
  thumbProduct?: InputMaybe<ProductCreateManyInlineInput>;
  thumbnailVideo?: InputMaybe<VideoCreateManyInlineInput>;
  ticketsBackgroundProductReservation?: InputMaybe<ProductReservationCreateManyInlineInput>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  variants?: InputMaybe<AssetVariantCreateManyInlineInput>;
  width?: InputMaybe<Scalars['Float']>;
};

export type AssetCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  fileName: Scalars['String'];
  handle: Scalars['String'];
  height?: InputMaybe<Scalars['Float']>;
  mimeType?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<Scalars['Float']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  width?: InputMaybe<Scalars['Float']>;
};

export type AssetCreateLocalizationInput = {
  /** Localization input */
  data: AssetCreateLocalizationDataInput;
  locale: Locale;
};

export type AssetCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<AssetCreateLocalizationInput>>;
};

export type AssetCreateManyInlineInput = {
  /** Connect multiple existing Asset documents */
  connect?: InputMaybe<Array<AssetWhereUniqueInput>>;
  /** Create and connect multiple existing Asset documents */
  create?: InputMaybe<Array<AssetCreateInput>>;
};

export type AssetCreateOneInlineInput = {
  /** Connect one existing Asset document */
  connect?: InputMaybe<AssetWhereUniqueInput>;
  /** Create and connect one Asset document */
  create?: InputMaybe<AssetCreateInput>;
};

/** An edge in a connection. */
export type AssetEdge = {
  __typename?: 'AssetEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Asset;
};

/** Identifies documents */
export type AssetManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AssetWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AssetWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AssetWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  blurDataURL?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  blurDataURL_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  blurDataURL_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  blurDataURL_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  blurDataURL_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  blurDataURL_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  blurDataURL_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  blurDataURL_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  blurDataURL_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  blurDataURL_starts_with?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  crossPromoImageProduct_every?: InputMaybe<ProductWhereInput>;
  crossPromoImageProduct_none?: InputMaybe<ProductWhereInput>;
  crossPromoImageProduct_some?: InputMaybe<ProductWhereInput>;
  documentInStages_every?: InputMaybe<AssetWhereStageInput>;
  documentInStages_none?: InputMaybe<AssetWhereStageInput>;
  documentInStages_some?: InputMaybe<AssetWhereStageInput>;
  heroArticle_every?: InputMaybe<ArticleWhereInput>;
  heroArticle_none?: InputMaybe<ArticleWhereInput>;
  heroArticle_some?: InputMaybe<ArticleWhereInput>;
  heroAttraction_every?: InputMaybe<AttractionWhereInput>;
  heroAttraction_none?: InputMaybe<AttractionWhereInput>;
  heroAttraction_some?: InputMaybe<AttractionWhereInput>;
  heroLandingPage_every?: InputMaybe<LandingPageWhereInput>;
  heroLandingPage_none?: InputMaybe<LandingPageWhereInput>;
  heroLandingPage_some?: InputMaybe<LandingPageWhereInput>;
  heroPageTemplate_every?: InputMaybe<PageTemplateWhereInput>;
  heroPageTemplate_none?: InputMaybe<PageTemplateWhereInput>;
  heroPageTemplate_some?: InputMaybe<PageTemplateWhereInput>;
  heroPage_every?: InputMaybe<PageWhereInput>;
  heroPage_none?: InputMaybe<PageWhereInput>;
  heroPage_some?: InputMaybe<PageWhereInput>;
  heroProduct_every?: InputMaybe<ProductWhereInput>;
  heroProduct_none?: InputMaybe<ProductWhereInput>;
  heroProduct_some?: InputMaybe<ProductWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  imageAddOn_every?: InputMaybe<AddOnWhereInput>;
  imageAddOn_none?: InputMaybe<AddOnWhereInput>;
  imageAddOn_some?: InputMaybe<AddOnWhereInput>;
  imageBlock_every?: InputMaybe<BlockWhereInput>;
  imageBlock_none?: InputMaybe<BlockWhereInput>;
  imageBlock_some?: InputMaybe<BlockWhereInput>;
  imageImage_every?: InputMaybe<ImageWhereInput>;
  imageImage_none?: InputMaybe<ImageWhereInput>;
  imageImage_some?: InputMaybe<ImageWhereInput>;
  imagePartnerOffer_every?: InputMaybe<PartnerOfferWhereInput>;
  imagePartnerOffer_none?: InputMaybe<PartnerOfferWhereInput>;
  imagePartnerOffer_some?: InputMaybe<PartnerOfferWhereInput>;
  internalTitle?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  internalTitle_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  internalTitle_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  internalTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  internalTitle_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  internalTitle_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  internalTitle_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  internalTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  internalTitle_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  internalTitle_starts_with?: InputMaybe<Scalars['String']>;
  logoAttraction_every?: InputMaybe<AttractionWhereInput>;
  logoAttraction_none?: InputMaybe<AttractionWhereInput>;
  logoAttraction_some?: InputMaybe<AttractionWhereInput>;
  logoProduct_every?: InputMaybe<ProductWhereInput>;
  logoProduct_none?: InputMaybe<ProductWhereInput>;
  logoProduct_some?: InputMaybe<ProductWhereInput>;
  logoSpotOutlineProduct_every?: InputMaybe<ProductWhereInput>;
  logoSpotOutlineProduct_none?: InputMaybe<ProductWhereInput>;
  logoSpotOutlineProduct_some?: InputMaybe<ProductWhereInput>;
  logoSpotProduct_every?: InputMaybe<ProductWhereInput>;
  logoSpotProduct_none?: InputMaybe<ProductWhereInput>;
  logoSpotProduct_some?: InputMaybe<ProductWhereInput>;
  logoTicketGroup_every?: InputMaybe<TicketGroupWhereInput>;
  logoTicketGroup_none?: InputMaybe<TicketGroupWhereInput>;
  logoTicketGroup_some?: InputMaybe<TicketGroupWhereInput>;
  logoTicketProduct_every?: InputMaybe<ProductWhereInput>;
  logoTicketProduct_none?: InputMaybe<ProductWhereInput>;
  logoTicketProduct_some?: InputMaybe<ProductWhereInput>;
  logoWhiteProduct_every?: InputMaybe<ProductWhereInput>;
  logoWhiteProduct_none?: InputMaybe<ProductWhereInput>;
  logoWhiteProduct_some?: InputMaybe<ProductWhereInput>;
  mapImageProduct_every?: InputMaybe<ProductWhereInput>;
  mapImageProduct_none?: InputMaybe<ProductWhereInput>;
  mapImageProduct_some?: InputMaybe<ProductWhereInput>;
  productImageProduct_every?: InputMaybe<ProductWhereInput>;
  productImageProduct_none?: InputMaybe<ProductWhereInput>;
  productImageProduct_some?: InputMaybe<ProductWhereInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  relatedProductActivityItem_every?: InputMaybe<ProductActivityItemWhereInput>;
  relatedProductActivityItem_none?: InputMaybe<ProductActivityItemWhereInput>;
  relatedProductActivityItem_some?: InputMaybe<ProductActivityItemWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  thumbAttraction_every?: InputMaybe<AttractionWhereInput>;
  thumbAttraction_none?: InputMaybe<AttractionWhereInput>;
  thumbAttraction_some?: InputMaybe<AttractionWhereInput>;
  thumbProduct_every?: InputMaybe<ProductWhereInput>;
  thumbProduct_none?: InputMaybe<ProductWhereInput>;
  thumbProduct_some?: InputMaybe<ProductWhereInput>;
  thumbnailVideo_every?: InputMaybe<VideoWhereInput>;
  thumbnailVideo_none?: InputMaybe<VideoWhereInput>;
  thumbnailVideo_some?: InputMaybe<VideoWhereInput>;
  ticketsBackgroundProductReservation_every?: InputMaybe<ProductReservationWhereInput>;
  ticketsBackgroundProductReservation_none?: InputMaybe<ProductReservationWhereInput>;
  ticketsBackgroundProductReservation_some?: InputMaybe<ProductReservationWhereInput>;
  title?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
  variants_every?: InputMaybe<AssetVariantWhereInput>;
  variants_none?: InputMaybe<AssetVariantWhereInput>;
  variants_some?: InputMaybe<AssetVariantWhereInput>;
};

export enum AssetOrderByInput {
  BlurDataUrlAsc = 'blurDataURL_ASC',
  BlurDataUrlDesc = 'blurDataURL_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  FileNameAsc = 'fileName_ASC',
  FileNameDesc = 'fileName_DESC',
  HandleAsc = 'handle_ASC',
  HandleDesc = 'handle_DESC',
  HeightAsc = 'height_ASC',
  HeightDesc = 'height_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  InternalTitleAsc = 'internalTitle_ASC',
  InternalTitleDesc = 'internalTitle_DESC',
  MimeTypeAsc = 'mimeType_ASC',
  MimeTypeDesc = 'mimeType_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  SizeAsc = 'size_ASC',
  SizeDesc = 'size_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  WidthAsc = 'width_ASC',
  WidthDesc = 'width_DESC',
}

export enum AssetSizes {
  Lg = 'lg',
  Md = 'md',
  Sm = 'sm',
  Thumb = 'thumb',
}

/** Transformations for Assets */
export type AssetTransformationInput = {
  document?: InputMaybe<DocumentTransformationInput>;
  image?: InputMaybe<ImageTransformationInput>;
  /** Pass true if you want to validate the passed transformation parameters */
  validateOptions?: InputMaybe<Scalars['Boolean']>;
};

export type AssetUpdateInput = {
  blurDataURL?: InputMaybe<Scalars['String']>;
  crossPromoImageProduct?: InputMaybe<ProductUpdateManyInlineInput>;
  fileName?: InputMaybe<Scalars['String']>;
  handle?: InputMaybe<Scalars['String']>;
  height?: InputMaybe<Scalars['Float']>;
  heroArticle?: InputMaybe<ArticleUpdateManyInlineInput>;
  heroAttraction?: InputMaybe<AttractionUpdateManyInlineInput>;
  heroLandingPage?: InputMaybe<LandingPageUpdateManyInlineInput>;
  heroPage?: InputMaybe<PageUpdateManyInlineInput>;
  heroPageTemplate?: InputMaybe<PageTemplateUpdateManyInlineInput>;
  heroProduct?: InputMaybe<ProductUpdateManyInlineInput>;
  imageAddOn?: InputMaybe<AddOnUpdateManyInlineInput>;
  imageBlock?: InputMaybe<BlockUpdateManyInlineInput>;
  imageCrossPromoBanner?: InputMaybe<CrossPromoBannerUpdateManyInlineInput>;
  imageImage?: InputMaybe<ImageUpdateManyInlineInput>;
  imageImageVariant?: InputMaybe<AssetVariantUpdateManyInlineInput>;
  imagePartnerOffer?: InputMaybe<PartnerOfferUpdateManyInlineInput>;
  imagePromoBanner?: InputMaybe<PromoBannerUpdateManyInlineInput>;
  imagePromoTicketBanner?: InputMaybe<PromoTicketBannerUpdateManyInlineInput>;
  internalTitle?: InputMaybe<Scalars['String']>;
  /** Manage document localizations */
  localizations?: InputMaybe<AssetUpdateLocalizationsInput>;
  logoAttraction?: InputMaybe<AttractionUpdateManyInlineInput>;
  logoCrossPromoBanner?: InputMaybe<CrossPromoBannerUpdateManyInlineInput>;
  logoProduct?: InputMaybe<ProductUpdateManyInlineInput>;
  logoPromoBanner?: InputMaybe<PromoBannerUpdateManyInlineInput>;
  logoPromoTicketBanner?: InputMaybe<PromoTicketBannerUpdateManyInlineInput>;
  logoSpotOutlineProduct?: InputMaybe<ProductUpdateManyInlineInput>;
  logoSpotProduct?: InputMaybe<ProductUpdateManyInlineInput>;
  logoTicketGroup?: InputMaybe<TicketGroupUpdateManyInlineInput>;
  logoTicketProduct?: InputMaybe<ProductUpdateManyInlineInput>;
  logoWhiteProduct?: InputMaybe<ProductUpdateManyInlineInput>;
  mapImageProduct?: InputMaybe<ProductUpdateManyInlineInput>;
  mimeType?: InputMaybe<Scalars['String']>;
  productImageProduct?: InputMaybe<ProductUpdateManyInlineInput>;
  relatedProductActivityItem?: InputMaybe<ProductActivityItemUpdateManyInlineInput>;
  size?: InputMaybe<Scalars['Float']>;
  thumbAttraction?: InputMaybe<AttractionUpdateManyInlineInput>;
  thumbProduct?: InputMaybe<ProductUpdateManyInlineInput>;
  thumbnailVideo?: InputMaybe<VideoUpdateManyInlineInput>;
  ticketsBackgroundProductReservation?: InputMaybe<ProductReservationUpdateManyInlineInput>;
  title?: InputMaybe<Scalars['String']>;
  variants?: InputMaybe<AssetVariantUpdateManyInlineInput>;
  width?: InputMaybe<Scalars['Float']>;
};

export type AssetUpdateLocalizationDataInput = {
  fileName?: InputMaybe<Scalars['String']>;
  handle?: InputMaybe<Scalars['String']>;
  height?: InputMaybe<Scalars['Float']>;
  mimeType?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<Scalars['Float']>;
  width?: InputMaybe<Scalars['Float']>;
};

export type AssetUpdateLocalizationInput = {
  data: AssetUpdateLocalizationDataInput;
  locale: Locale;
};

export type AssetUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<AssetCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<AssetUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<AssetUpsertLocalizationInput>>;
};

export type AssetUpdateManyInlineInput = {
  /** Connect multiple existing Asset documents */
  connect?: InputMaybe<Array<AssetConnectInput>>;
  /** Create and connect multiple Asset documents */
  create?: InputMaybe<Array<AssetCreateInput>>;
  /** Delete multiple Asset documents */
  delete?: InputMaybe<Array<AssetWhereUniqueInput>>;
  /** Disconnect multiple Asset documents */
  disconnect?: InputMaybe<Array<AssetWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing Asset documents */
  set?: InputMaybe<Array<AssetWhereUniqueInput>>;
  /** Update multiple Asset documents */
  update?: InputMaybe<Array<AssetUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple Asset documents */
  upsert?: InputMaybe<Array<AssetUpsertWithNestedWhereUniqueInput>>;
};

export type AssetUpdateManyInput = {
  blurDataURL?: InputMaybe<Scalars['String']>;
  fileName?: InputMaybe<Scalars['String']>;
  height?: InputMaybe<Scalars['Float']>;
  internalTitle?: InputMaybe<Scalars['String']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<AssetUpdateManyLocalizationsInput>;
  mimeType?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<Scalars['Float']>;
  title?: InputMaybe<Scalars['String']>;
  width?: InputMaybe<Scalars['Float']>;
};

export type AssetUpdateManyLocalizationDataInput = {
  fileName?: InputMaybe<Scalars['String']>;
  height?: InputMaybe<Scalars['Float']>;
  mimeType?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<Scalars['Float']>;
  width?: InputMaybe<Scalars['Float']>;
};

export type AssetUpdateManyLocalizationInput = {
  data: AssetUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type AssetUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<AssetUpdateManyLocalizationInput>>;
};

export type AssetUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: AssetUpdateManyInput;
  /** Document search */
  where: AssetWhereInput;
};

export type AssetUpdateOneInlineInput = {
  /** Connect existing Asset document */
  connect?: InputMaybe<AssetWhereUniqueInput>;
  /** Create and connect one Asset document */
  create?: InputMaybe<AssetCreateInput>;
  /** Delete currently connected Asset document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected Asset document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single Asset document */
  update?: InputMaybe<AssetUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Asset document */
  upsert?: InputMaybe<AssetUpsertWithNestedWhereUniqueInput>;
};

export type AssetUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: AssetUpdateInput;
  /** Unique document search */
  where: AssetWhereUniqueInput;
};

export type AssetUpsertInput = {
  /** Create document if it didn't exist */
  create: AssetCreateInput;
  /** Update document if it exists */
  update: AssetUpdateInput;
};

export type AssetUpsertLocalizationInput = {
  create: AssetCreateLocalizationDataInput;
  locale: Locale;
  update: AssetUpdateLocalizationDataInput;
};

export type AssetUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: AssetUpsertInput;
  /** Unique document search */
  where: AssetWhereUniqueInput;
};

export type AssetVariant = {
  __typename?: 'AssetVariant';
  asset: Asset;
  /** The unique identifier */
  id: Scalars['ID'];
  /**
   * Sizes displayed on citypass.com are:
   *
   * lg: 1920x500
   * md: 1000x310
   * sm: 700x500
   * thumb: 400x400
   */
  size: AssetSizes;
  /** System stage field */
  stage: Stage;
};

export type AssetVariantAssetArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type AssetVariantConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: AssetVariantWhereUniqueInput;
};

/** A connection to a list of items. */
export type AssetVariantConnection = {
  __typename?: 'AssetVariantConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<AssetVariantEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type AssetVariantCreateInput = {
  asset: AssetCreateOneInlineInput;
  size: AssetSizes;
};

export type AssetVariantCreateManyInlineInput = {
  /** Create and connect multiple existing AssetVariant documents */
  create?: InputMaybe<Array<AssetVariantCreateInput>>;
};

export type AssetVariantCreateOneInlineInput = {
  /** Create and connect one AssetVariant document */
  create?: InputMaybe<AssetVariantCreateInput>;
};

export type AssetVariantCreateWithPositionInput = {
  /** Document to create */
  data: AssetVariantCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type AssetVariantEdge = {
  __typename?: 'AssetVariantEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: AssetVariant;
};

/** Identifies documents */
export type AssetVariantManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AssetVariantWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AssetVariantWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AssetVariantWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  asset?: InputMaybe<AssetWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  size?: InputMaybe<AssetSizes>;
  /** All values that are contained in given list. */
  size_in?: InputMaybe<Array<InputMaybe<AssetSizes>>>;
  /** Any other value that exists and is not equal to the given value. */
  size_not?: InputMaybe<AssetSizes>;
  /** All values that are not contained in given list. */
  size_not_in?: InputMaybe<Array<InputMaybe<AssetSizes>>>;
};

export enum AssetVariantOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  SizeAsc = 'size_ASC',
  SizeDesc = 'size_DESC',
}

export type AssetVariantParent = Asset;

export type AssetVariantParentConnectInput = {
  Asset?: InputMaybe<AssetConnectInput>;
};

export type AssetVariantParentCreateInput = {
  Asset?: InputMaybe<AssetCreateInput>;
};

export type AssetVariantParentCreateManyInlineInput = {
  /** Connect multiple existing AssetVariantParent documents */
  connect?: InputMaybe<Array<AssetVariantParentWhereUniqueInput>>;
  /** Create and connect multiple existing AssetVariantParent documents */
  create?: InputMaybe<Array<AssetVariantParentCreateInput>>;
};

export type AssetVariantParentCreateOneInlineInput = {
  /** Connect one existing AssetVariantParent document */
  connect?: InputMaybe<AssetVariantParentWhereUniqueInput>;
  /** Create and connect one AssetVariantParent document */
  create?: InputMaybe<AssetVariantParentCreateInput>;
};

export type AssetVariantParentUpdateInput = {
  Asset?: InputMaybe<AssetUpdateInput>;
};

export type AssetVariantParentUpdateManyInlineInput = {
  /** Connect multiple existing AssetVariantParent documents */
  connect?: InputMaybe<Array<AssetVariantParentConnectInput>>;
  /** Create and connect multiple AssetVariantParent documents */
  create?: InputMaybe<Array<AssetVariantParentCreateInput>>;
  /** Delete multiple AssetVariantParent documents */
  delete?: InputMaybe<Array<AssetVariantParentWhereUniqueInput>>;
  /** Disconnect multiple AssetVariantParent documents */
  disconnect?: InputMaybe<Array<AssetVariantParentWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing AssetVariantParent documents */
  set?: InputMaybe<Array<AssetVariantParentWhereUniqueInput>>;
  /** Update multiple AssetVariantParent documents */
  update?: InputMaybe<Array<AssetVariantParentUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple AssetVariantParent documents */
  upsert?: InputMaybe<Array<AssetVariantParentUpsertWithNestedWhereUniqueInput>>;
};

export type AssetVariantParentUpdateManyWithNestedWhereInput = {
  Asset?: InputMaybe<AssetUpdateManyWithNestedWhereInput>;
};

export type AssetVariantParentUpdateOneInlineInput = {
  /** Connect existing AssetVariantParent document */
  connect?: InputMaybe<AssetVariantParentWhereUniqueInput>;
  /** Create and connect one AssetVariantParent document */
  create?: InputMaybe<AssetVariantParentCreateInput>;
  /** Delete currently connected AssetVariantParent document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected AssetVariantParent document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single AssetVariantParent document */
  update?: InputMaybe<AssetVariantParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single AssetVariantParent document */
  upsert?: InputMaybe<AssetVariantParentUpsertWithNestedWhereUniqueInput>;
};

export type AssetVariantParentUpdateWithNestedWhereUniqueInput = {
  Asset?: InputMaybe<AssetUpdateWithNestedWhereUniqueInput>;
};

export type AssetVariantParentUpsertWithNestedWhereUniqueInput = {
  Asset?: InputMaybe<AssetUpsertWithNestedWhereUniqueInput>;
};

export type AssetVariantParentWhereInput = {
  Asset?: InputMaybe<AssetWhereInput>;
};

export type AssetVariantParentWhereUniqueInput = {
  Asset?: InputMaybe<AssetWhereUniqueInput>;
};

export type AssetVariantUpdateInput = {
  asset?: InputMaybe<AssetUpdateOneInlineInput>;
  size?: InputMaybe<AssetSizes>;
};

export type AssetVariantUpdateManyInlineInput = {
  /** Create and connect multiple AssetVariant component instances */
  create?: InputMaybe<Array<AssetVariantCreateWithPositionInput>>;
  /** Delete multiple AssetVariant documents */
  delete?: InputMaybe<Array<AssetVariantWhereUniqueInput>>;
  /** Update multiple AssetVariant component instances */
  update?: InputMaybe<Array<AssetVariantUpdateWithNestedWhereUniqueAndPositionInput>>;
  /** Upsert multiple AssetVariant component instances */
  upsert?: InputMaybe<Array<AssetVariantUpsertWithNestedWhereUniqueAndPositionInput>>;
};

export type AssetVariantUpdateManyInput = {
  size?: InputMaybe<AssetSizes>;
};

export type AssetVariantUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: AssetVariantUpdateManyInput;
  /** Document search */
  where: AssetVariantWhereInput;
};

export type AssetVariantUpdateOneInlineInput = {
  /** Create and connect one AssetVariant document */
  create?: InputMaybe<AssetVariantCreateInput>;
  /** Delete currently connected AssetVariant document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single AssetVariant document */
  update?: InputMaybe<AssetVariantUpdateWithNestedWhereUniqueInput>;
  /** Upsert single AssetVariant document */
  upsert?: InputMaybe<AssetVariantUpsertWithNestedWhereUniqueInput>;
};

export type AssetVariantUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<AssetVariantUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: AssetVariantWhereUniqueInput;
};

export type AssetVariantUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: AssetVariantUpdateInput;
  /** Unique document search */
  where: AssetVariantWhereUniqueInput;
};

export type AssetVariantUpsertInput = {
  /** Create document if it didn't exist */
  create: AssetVariantCreateInput;
  /** Update document if it exists */
  update: AssetVariantUpdateInput;
};

export type AssetVariantUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<AssetVariantUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: AssetVariantWhereUniqueInput;
};

export type AssetVariantUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: AssetVariantUpsertInput;
  /** Unique document search */
  where: AssetVariantWhereUniqueInput;
};

/** Identifies documents */
export type AssetVariantWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AssetVariantWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AssetVariantWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AssetVariantWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  asset?: InputMaybe<AssetWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  size?: InputMaybe<AssetSizes>;
  /** All values that are contained in given list. */
  size_in?: InputMaybe<Array<InputMaybe<AssetSizes>>>;
  /** Any other value that exists and is not equal to the given value. */
  size_not?: InputMaybe<AssetSizes>;
  /** All values that are not contained in given list. */
  size_not_in?: InputMaybe<Array<InputMaybe<AssetSizes>>>;
};

/** References AssetVariant record uniquely */
export type AssetVariantWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

/** This contains a set of filters that can be used to compare values internally */
export type AssetWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']>;
};

/** Identifies documents */
export type AssetWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AssetWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AssetWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AssetWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  blurDataURL?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  blurDataURL_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  blurDataURL_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  blurDataURL_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  blurDataURL_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  blurDataURL_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  blurDataURL_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  blurDataURL_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  blurDataURL_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  blurDataURL_starts_with?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  crossPromoImageProduct_every?: InputMaybe<ProductWhereInput>;
  crossPromoImageProduct_none?: InputMaybe<ProductWhereInput>;
  crossPromoImageProduct_some?: InputMaybe<ProductWhereInput>;
  documentInStages_every?: InputMaybe<AssetWhereStageInput>;
  documentInStages_none?: InputMaybe<AssetWhereStageInput>;
  documentInStages_some?: InputMaybe<AssetWhereStageInput>;
  fileName?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  fileName_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  fileName_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  fileName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  fileName_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  fileName_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  fileName_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  fileName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  fileName_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  fileName_starts_with?: InputMaybe<Scalars['String']>;
  handle?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  handle_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  handle_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  handle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  handle_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  handle_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  handle_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  handle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  handle_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  handle_starts_with?: InputMaybe<Scalars['String']>;
  height?: InputMaybe<Scalars['Float']>;
  /** All values greater than the given value. */
  height_gt?: InputMaybe<Scalars['Float']>;
  /** All values greater than or equal the given value. */
  height_gte?: InputMaybe<Scalars['Float']>;
  /** All values that are contained in given list. */
  height_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  /** All values less than the given value. */
  height_lt?: InputMaybe<Scalars['Float']>;
  /** All values less than or equal the given value. */
  height_lte?: InputMaybe<Scalars['Float']>;
  /** Any other value that exists and is not equal to the given value. */
  height_not?: InputMaybe<Scalars['Float']>;
  /** All values that are not contained in given list. */
  height_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  heroArticle_every?: InputMaybe<ArticleWhereInput>;
  heroArticle_none?: InputMaybe<ArticleWhereInput>;
  heroArticle_some?: InputMaybe<ArticleWhereInput>;
  heroAttraction_every?: InputMaybe<AttractionWhereInput>;
  heroAttraction_none?: InputMaybe<AttractionWhereInput>;
  heroAttraction_some?: InputMaybe<AttractionWhereInput>;
  heroLandingPage_every?: InputMaybe<LandingPageWhereInput>;
  heroLandingPage_none?: InputMaybe<LandingPageWhereInput>;
  heroLandingPage_some?: InputMaybe<LandingPageWhereInput>;
  heroPageTemplate_every?: InputMaybe<PageTemplateWhereInput>;
  heroPageTemplate_none?: InputMaybe<PageTemplateWhereInput>;
  heroPageTemplate_some?: InputMaybe<PageTemplateWhereInput>;
  heroPage_every?: InputMaybe<PageWhereInput>;
  heroPage_none?: InputMaybe<PageWhereInput>;
  heroPage_some?: InputMaybe<PageWhereInput>;
  heroProduct_every?: InputMaybe<ProductWhereInput>;
  heroProduct_none?: InputMaybe<ProductWhereInput>;
  heroProduct_some?: InputMaybe<ProductWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  imageAddOn_every?: InputMaybe<AddOnWhereInput>;
  imageAddOn_none?: InputMaybe<AddOnWhereInput>;
  imageAddOn_some?: InputMaybe<AddOnWhereInput>;
  imageBlock_every?: InputMaybe<BlockWhereInput>;
  imageBlock_none?: InputMaybe<BlockWhereInput>;
  imageBlock_some?: InputMaybe<BlockWhereInput>;
  imageImage_every?: InputMaybe<ImageWhereInput>;
  imageImage_none?: InputMaybe<ImageWhereInput>;
  imageImage_some?: InputMaybe<ImageWhereInput>;
  imagePartnerOffer_every?: InputMaybe<PartnerOfferWhereInput>;
  imagePartnerOffer_none?: InputMaybe<PartnerOfferWhereInput>;
  imagePartnerOffer_some?: InputMaybe<PartnerOfferWhereInput>;
  internalTitle?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  internalTitle_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  internalTitle_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  internalTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  internalTitle_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  internalTitle_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  internalTitle_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  internalTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  internalTitle_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  internalTitle_starts_with?: InputMaybe<Scalars['String']>;
  logoAttraction_every?: InputMaybe<AttractionWhereInput>;
  logoAttraction_none?: InputMaybe<AttractionWhereInput>;
  logoAttraction_some?: InputMaybe<AttractionWhereInput>;
  logoProduct_every?: InputMaybe<ProductWhereInput>;
  logoProduct_none?: InputMaybe<ProductWhereInput>;
  logoProduct_some?: InputMaybe<ProductWhereInput>;
  logoSpotOutlineProduct_every?: InputMaybe<ProductWhereInput>;
  logoSpotOutlineProduct_none?: InputMaybe<ProductWhereInput>;
  logoSpotOutlineProduct_some?: InputMaybe<ProductWhereInput>;
  logoSpotProduct_every?: InputMaybe<ProductWhereInput>;
  logoSpotProduct_none?: InputMaybe<ProductWhereInput>;
  logoSpotProduct_some?: InputMaybe<ProductWhereInput>;
  logoTicketGroup_every?: InputMaybe<TicketGroupWhereInput>;
  logoTicketGroup_none?: InputMaybe<TicketGroupWhereInput>;
  logoTicketGroup_some?: InputMaybe<TicketGroupWhereInput>;
  logoTicketProduct_every?: InputMaybe<ProductWhereInput>;
  logoTicketProduct_none?: InputMaybe<ProductWhereInput>;
  logoTicketProduct_some?: InputMaybe<ProductWhereInput>;
  logoWhiteProduct_every?: InputMaybe<ProductWhereInput>;
  logoWhiteProduct_none?: InputMaybe<ProductWhereInput>;
  logoWhiteProduct_some?: InputMaybe<ProductWhereInput>;
  mapImageProduct_every?: InputMaybe<ProductWhereInput>;
  mapImageProduct_none?: InputMaybe<ProductWhereInput>;
  mapImageProduct_some?: InputMaybe<ProductWhereInput>;
  mimeType?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  mimeType_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  mimeType_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  mimeType_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  mimeType_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  mimeType_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  mimeType_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  mimeType_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  mimeType_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  mimeType_starts_with?: InputMaybe<Scalars['String']>;
  productImageProduct_every?: InputMaybe<ProductWhereInput>;
  productImageProduct_none?: InputMaybe<ProductWhereInput>;
  productImageProduct_some?: InputMaybe<ProductWhereInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  relatedProductActivityItem_every?: InputMaybe<ProductActivityItemWhereInput>;
  relatedProductActivityItem_none?: InputMaybe<ProductActivityItemWhereInput>;
  relatedProductActivityItem_some?: InputMaybe<ProductActivityItemWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  size?: InputMaybe<Scalars['Float']>;
  /** All values greater than the given value. */
  size_gt?: InputMaybe<Scalars['Float']>;
  /** All values greater than or equal the given value. */
  size_gte?: InputMaybe<Scalars['Float']>;
  /** All values that are contained in given list. */
  size_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  /** All values less than the given value. */
  size_lt?: InputMaybe<Scalars['Float']>;
  /** All values less than or equal the given value. */
  size_lte?: InputMaybe<Scalars['Float']>;
  /** Any other value that exists and is not equal to the given value. */
  size_not?: InputMaybe<Scalars['Float']>;
  /** All values that are not contained in given list. */
  size_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  thumbAttraction_every?: InputMaybe<AttractionWhereInput>;
  thumbAttraction_none?: InputMaybe<AttractionWhereInput>;
  thumbAttraction_some?: InputMaybe<AttractionWhereInput>;
  thumbProduct_every?: InputMaybe<ProductWhereInput>;
  thumbProduct_none?: InputMaybe<ProductWhereInput>;
  thumbProduct_some?: InputMaybe<ProductWhereInput>;
  thumbnailVideo_every?: InputMaybe<VideoWhereInput>;
  thumbnailVideo_none?: InputMaybe<VideoWhereInput>;
  thumbnailVideo_some?: InputMaybe<VideoWhereInput>;
  ticketsBackgroundProductReservation_every?: InputMaybe<ProductReservationWhereInput>;
  ticketsBackgroundProductReservation_none?: InputMaybe<ProductReservationWhereInput>;
  ticketsBackgroundProductReservation_some?: InputMaybe<ProductReservationWhereInput>;
  title?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
  variants_every?: InputMaybe<AssetVariantWhereInput>;
  variants_none?: InputMaybe<AssetVariantWhereInput>;
  variants_some?: InputMaybe<AssetVariantWhereInput>;
  width?: InputMaybe<Scalars['Float']>;
  /** All values greater than the given value. */
  width_gt?: InputMaybe<Scalars['Float']>;
  /** All values greater than or equal the given value. */
  width_gte?: InputMaybe<Scalars['Float']>;
  /** All values that are contained in given list. */
  width_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  /** All values less than the given value. */
  width_lt?: InputMaybe<Scalars['Float']>;
  /** All values less than or equal the given value. */
  width_lte?: InputMaybe<Scalars['Float']>;
  /** Any other value that exists and is not equal to the given value. */
  width_not?: InputMaybe<Scalars['Float']>;
  /** All values that are not contained in given list. */
  width_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type AssetWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AssetWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AssetWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AssetWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<AssetWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References Asset record uniquely */
export type AssetWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type Attraction = Node & {
  __typename?: 'Attraction';
  accessibility: Maybe<Scalars['String']>;
  addon: Maybe<Scalars['Boolean']>;
  addonAttractionProducts: Array<Product>;
  admission: Array<Text>;
  affiliateId: Maybe<Scalars['String']>;
  ageAdult: Maybe<Scalars['String']>;
  ageChild: Maybe<Scalars['String']>;
  alacarte: Maybe<AttractionAlacarte>;
  amenities: Array<Text>;
  attractionAddOn: Maybe<AddOn>;
  availability: Maybe<AttractionAvailability>;
  bestTime: Maybe<Scalars['String']>;
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** User that created this document */
  createdBy: Maybe<User>;
  /** Get the document in other stages */
  documentInStages: Array<Attraction>;
  exclusiveFeature: Maybe<Scalars['String']>;
  exclusiveTo: Maybe<Product>;
  gallery: Array<AttractionGallery>;
  googlePlaceId: Maybe<Scalars['String']>;
  hero: Maybe<Asset>;
  heroCredit: Maybe<Scalars['String']>;
  highlights: Array<Text>;
  /** List of Attraction versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID'];
  intro: Maybe<Scalars['String']>;
  /** IMPORTANT: do NOT change or remove this field once set */
  key: Scalars['String'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<Attraction>;
  locations: Array<AttractionLocation>;
  logo: Maybe<Asset>;
  name: Maybe<Scalars['String']>;
  nextPrice: Maybe<AttractionPrice>;
  offers: Array<AttractionOffer>;
  openStatus: Maybe<Text>;
  parking: Maybe<Scalars['String']>;
  partnerOffers: Array<PartnerOffer>;
  planning: Maybe<DetailBlock>;
  price: Maybe<AttractionPrice>;
  products: Array<Product>;
  promos: Array<Promo>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt: Maybe<Scalars['DateTime']>;
  /** User that last published this document */
  publishedBy: Maybe<User>;
  qabu: Maybe<Scalars['Json']>;
  reservation: Maybe<AttractionReservation>;
  safety: Array<Text>;
  scheduledIn: Array<ScheduledOperation>;
  seo: Maybe<Seo>;
  shortIntro: Maybe<Scalars['String']>;
  skuProperties: Array<SkuProperty>;
  slug: Maybe<Scalars['String']>;
  /** System stage field */
  stage: Stage;
  thumb: Maybe<Asset>;
  tips: Array<Text>;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** User that last updated this document */
  updatedBy: Maybe<User>;
  variants: Array<AttractionVariant>;
};

export type AttractionAddonAttractionProductsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<ProductOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ProductWhereInput>;
};

export type AttractionAdmissionArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<TextOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<TextWhereInput>;
};

export type AttractionAlacarteArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type AttractionAmenitiesArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<TextOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<TextWhereInput>;
};

export type AttractionAttractionAddOnArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type AttractionAvailabilityArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type AttractionCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type AttractionCreatedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type AttractionDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};

export type AttractionExclusiveToArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type AttractionGalleryArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  skip: InputMaybe<Scalars['Int']>;
};

export type AttractionHeroArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type AttractionHighlightsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<TextOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<TextWhereInput>;
};

export type AttractionHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride: InputMaybe<Stage>;
};

export type AttractionLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};

export type AttractionLocationsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<AttractionLocationOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AttractionLocationWhereInput>;
};

export type AttractionLogoArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type AttractionNextPriceArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type AttractionOffersArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<AttractionOfferOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AttractionOfferWhereInput>;
};

export type AttractionOpenStatusArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type AttractionPartnerOffersArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<PartnerOfferOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<PartnerOfferWhereInput>;
};

export type AttractionPlanningArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type AttractionPriceArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type AttractionProductsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<ProductOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ProductWhereInput>;
};

export type AttractionPromosArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<PromoOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<PromoWhereInput>;
};

export type AttractionPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type AttractionPublishedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type AttractionReservationArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type AttractionSafetyArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<TextOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<TextWhereInput>;
};

export type AttractionScheduledInArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ScheduledOperationWhereInput>;
};

export type AttractionSeoArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type AttractionSkuPropertiesArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<SkuPropertyOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<SkuPropertyWhereInput>;
};

export type AttractionThumbArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type AttractionTipsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<TextOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<TextWhereInput>;
};

export type AttractionUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type AttractionUpdatedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type AttractionVariantsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<AttractionVariantOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AttractionVariantWhereInput>;
};

export type AttractionAlacarte = Node & {
  __typename?: 'AttractionAlacarte';
  attraction: Maybe<Attraction>;
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** User that created this document */
  createdBy: Maybe<User>;
  customInstructions: Array<TitleDescription>;
  description: Array<Text>;
  /** Get the document in other stages */
  documentInStages: Array<AttractionAlacarte>;
  filters: Maybe<Scalars['Json']>;
  hideTicketPicker: Maybe<Scalars['Boolean']>;
  /** List of AttractionAlacarte versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID'];
  key: Scalars['String'];
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt: Maybe<Scalars['DateTime']>;
  /** User that last published this document */
  publishedBy: Maybe<User>;
  redemption: Array<Text>;
  redemptionShort: Array<Text>;
  restrictionsCommon: Array<Text>;
  scheduledIn: Array<ScheduledOperation>;
  /** System stage field */
  stage: Stage;
  termsAndConditions: Array<Text>;
  ticketTypes: Array<AlacarteTicketType>;
  tickets: Array<AlacarteTicket>;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** User that last updated this document */
  updatedBy: Maybe<User>;
  validityCommon: Array<Text>;
  validityWillCall: Array<Text>;
};

export type AttractionAlacarteAttractionArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type AttractionAlacarteCreatedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type AttractionAlacarteCustomInstructionsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<TitleDescriptionOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<TitleDescriptionWhereInput>;
};

export type AttractionAlacarteDescriptionArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<TextOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<TextWhereInput>;
};

export type AttractionAlacarteDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};

export type AttractionAlacarteHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride: InputMaybe<Stage>;
};

export type AttractionAlacartePublishedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type AttractionAlacarteRedemptionArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<TextOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<TextWhereInput>;
};

export type AttractionAlacarteRedemptionShortArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<TextOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<TextWhereInput>;
};

export type AttractionAlacarteRestrictionsCommonArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<TextOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<TextWhereInput>;
};

export type AttractionAlacarteScheduledInArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ScheduledOperationWhereInput>;
};

export type AttractionAlacarteTermsAndConditionsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<TextOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<TextWhereInput>;
};

export type AttractionAlacarteTicketTypesArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<AlacarteTicketTypeOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AlacarteTicketTypeWhereInput>;
};

export type AttractionAlacarteTicketsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<AlacarteTicketOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AlacarteTicketWhereInput>;
};

export type AttractionAlacarteUpdatedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type AttractionAlacarteValidityCommonArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<TextOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<TextWhereInput>;
};

export type AttractionAlacarteValidityWillCallArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<TextOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<TextWhereInput>;
};

export type AttractionAlacarteConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: AttractionAlacarteWhereUniqueInput;
};

/** A connection to a list of items. */
export type AttractionAlacarteConnection = {
  __typename?: 'AttractionAlacarteConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<AttractionAlacarteEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type AttractionAlacarteCreateInput = {
  attraction?: InputMaybe<AttractionCreateOneInlineInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customInstructions?: InputMaybe<TitleDescriptionCreateManyInlineInput>;
  description?: InputMaybe<TextCreateManyInlineInput>;
  filters?: InputMaybe<Scalars['Json']>;
  hideTicketPicker?: InputMaybe<Scalars['Boolean']>;
  key: Scalars['String'];
  redemption?: InputMaybe<TextCreateManyInlineInput>;
  redemptionShort?: InputMaybe<TextCreateManyInlineInput>;
  restrictionsCommon?: InputMaybe<TextCreateManyInlineInput>;
  termsAndConditions?: InputMaybe<TextCreateManyInlineInput>;
  ticketTypes?: InputMaybe<AlacarteTicketTypeCreateManyInlineInput>;
  tickets?: InputMaybe<AlacarteTicketCreateManyInlineInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  validityCommon?: InputMaybe<TextCreateManyInlineInput>;
  validityWillCall?: InputMaybe<TextCreateManyInlineInput>;
};

export type AttractionAlacarteCreateManyInlineInput = {
  /** Connect multiple existing AttractionAlacarte documents */
  connect?: InputMaybe<Array<AttractionAlacarteWhereUniqueInput>>;
  /** Create and connect multiple existing AttractionAlacarte documents */
  create?: InputMaybe<Array<AttractionAlacarteCreateInput>>;
};

export type AttractionAlacarteCreateOneInlineInput = {
  /** Connect one existing AttractionAlacarte document */
  connect?: InputMaybe<AttractionAlacarteWhereUniqueInput>;
  /** Create and connect one AttractionAlacarte document */
  create?: InputMaybe<AttractionAlacarteCreateInput>;
};

/** An edge in a connection. */
export type AttractionAlacarteEdge = {
  __typename?: 'AttractionAlacarteEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: AttractionAlacarte;
};

/** Identifies documents */
export type AttractionAlacarteManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AttractionAlacarteWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AttractionAlacarteWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AttractionAlacarteWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  attraction?: InputMaybe<AttractionWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  customInstructions_every?: InputMaybe<TitleDescriptionWhereInput>;
  customInstructions_none?: InputMaybe<TitleDescriptionWhereInput>;
  customInstructions_some?: InputMaybe<TitleDescriptionWhereInput>;
  description_every?: InputMaybe<TextWhereInput>;
  description_none?: InputMaybe<TextWhereInput>;
  description_some?: InputMaybe<TextWhereInput>;
  documentInStages_every?: InputMaybe<AttractionAlacarteWhereStageInput>;
  documentInStages_none?: InputMaybe<AttractionAlacarteWhereStageInput>;
  documentInStages_some?: InputMaybe<AttractionAlacarteWhereStageInput>;
  /** All values containing the given json path. */
  filters_json_path_exists?: InputMaybe<Scalars['String']>;
  /**
   * Recursively tries to find the provided JSON scalar value inside the field.
   * It does use an exact match when comparing values.
   * If you pass `null` as value the filter will be ignored.
   * Note: This filter fails if you try to look for a non scalar JSON value!
   */
  filters_value_recursive?: InputMaybe<Scalars['Json']>;
  hideTicketPicker?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  hideTicketPicker_not?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  key?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  key_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  key_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  key_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  key_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  key_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  key_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  key_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  key_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  key_starts_with?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  redemptionShort_every?: InputMaybe<TextWhereInput>;
  redemptionShort_none?: InputMaybe<TextWhereInput>;
  redemptionShort_some?: InputMaybe<TextWhereInput>;
  redemption_every?: InputMaybe<TextWhereInput>;
  redemption_none?: InputMaybe<TextWhereInput>;
  redemption_some?: InputMaybe<TextWhereInput>;
  restrictionsCommon_every?: InputMaybe<TextWhereInput>;
  restrictionsCommon_none?: InputMaybe<TextWhereInput>;
  restrictionsCommon_some?: InputMaybe<TextWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  termsAndConditions_every?: InputMaybe<TextWhereInput>;
  termsAndConditions_none?: InputMaybe<TextWhereInput>;
  termsAndConditions_some?: InputMaybe<TextWhereInput>;
  ticketTypes_every?: InputMaybe<AlacarteTicketTypeWhereInput>;
  ticketTypes_none?: InputMaybe<AlacarteTicketTypeWhereInput>;
  ticketTypes_some?: InputMaybe<AlacarteTicketTypeWhereInput>;
  tickets_every?: InputMaybe<AlacarteTicketWhereInput>;
  tickets_none?: InputMaybe<AlacarteTicketWhereInput>;
  tickets_some?: InputMaybe<AlacarteTicketWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
  validityCommon_every?: InputMaybe<TextWhereInput>;
  validityCommon_none?: InputMaybe<TextWhereInput>;
  validityCommon_some?: InputMaybe<TextWhereInput>;
  validityWillCall_every?: InputMaybe<TextWhereInput>;
  validityWillCall_none?: InputMaybe<TextWhereInput>;
  validityWillCall_some?: InputMaybe<TextWhereInput>;
};

export enum AttractionAlacarteOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  HideTicketPickerAsc = 'hideTicketPicker_ASC',
  HideTicketPickerDesc = 'hideTicketPicker_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type AttractionAlacarteUpdateInput = {
  attraction?: InputMaybe<AttractionUpdateOneInlineInput>;
  customInstructions?: InputMaybe<TitleDescriptionUpdateManyInlineInput>;
  description?: InputMaybe<TextUpdateManyInlineInput>;
  filters?: InputMaybe<Scalars['Json']>;
  hideTicketPicker?: InputMaybe<Scalars['Boolean']>;
  key?: InputMaybe<Scalars['String']>;
  redemption?: InputMaybe<TextUpdateManyInlineInput>;
  redemptionShort?: InputMaybe<TextUpdateManyInlineInput>;
  restrictionsCommon?: InputMaybe<TextUpdateManyInlineInput>;
  termsAndConditions?: InputMaybe<TextUpdateManyInlineInput>;
  ticketTypes?: InputMaybe<AlacarteTicketTypeUpdateManyInlineInput>;
  tickets?: InputMaybe<AlacarteTicketUpdateManyInlineInput>;
  validityCommon?: InputMaybe<TextUpdateManyInlineInput>;
  validityWillCall?: InputMaybe<TextUpdateManyInlineInput>;
};

export type AttractionAlacarteUpdateManyInlineInput = {
  /** Connect multiple existing AttractionAlacarte documents */
  connect?: InputMaybe<Array<AttractionAlacarteConnectInput>>;
  /** Create and connect multiple AttractionAlacarte documents */
  create?: InputMaybe<Array<AttractionAlacarteCreateInput>>;
  /** Delete multiple AttractionAlacarte documents */
  delete?: InputMaybe<Array<AttractionAlacarteWhereUniqueInput>>;
  /** Disconnect multiple AttractionAlacarte documents */
  disconnect?: InputMaybe<Array<AttractionAlacarteWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing AttractionAlacarte documents */
  set?: InputMaybe<Array<AttractionAlacarteWhereUniqueInput>>;
  /** Update multiple AttractionAlacarte documents */
  update?: InputMaybe<Array<AttractionAlacarteUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple AttractionAlacarte documents */
  upsert?: InputMaybe<Array<AttractionAlacarteUpsertWithNestedWhereUniqueInput>>;
};

export type AttractionAlacarteUpdateManyInput = {
  filters?: InputMaybe<Scalars['Json']>;
  hideTicketPicker?: InputMaybe<Scalars['Boolean']>;
};

export type AttractionAlacarteUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: AttractionAlacarteUpdateManyInput;
  /** Document search */
  where: AttractionAlacarteWhereInput;
};

export type AttractionAlacarteUpdateOneInlineInput = {
  /** Connect existing AttractionAlacarte document */
  connect?: InputMaybe<AttractionAlacarteWhereUniqueInput>;
  /** Create and connect one AttractionAlacarte document */
  create?: InputMaybe<AttractionAlacarteCreateInput>;
  /** Delete currently connected AttractionAlacarte document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected AttractionAlacarte document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single AttractionAlacarte document */
  update?: InputMaybe<AttractionAlacarteUpdateWithNestedWhereUniqueInput>;
  /** Upsert single AttractionAlacarte document */
  upsert?: InputMaybe<AttractionAlacarteUpsertWithNestedWhereUniqueInput>;
};

export type AttractionAlacarteUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: AttractionAlacarteUpdateInput;
  /** Unique document search */
  where: AttractionAlacarteWhereUniqueInput;
};

export type AttractionAlacarteUpsertInput = {
  /** Create document if it didn't exist */
  create: AttractionAlacarteCreateInput;
  /** Update document if it exists */
  update: AttractionAlacarteUpdateInput;
};

export type AttractionAlacarteUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: AttractionAlacarteUpsertInput;
  /** Unique document search */
  where: AttractionAlacarteWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type AttractionAlacarteWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']>;
};

/** Identifies documents */
export type AttractionAlacarteWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AttractionAlacarteWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AttractionAlacarteWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AttractionAlacarteWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  attraction?: InputMaybe<AttractionWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  customInstructions_every?: InputMaybe<TitleDescriptionWhereInput>;
  customInstructions_none?: InputMaybe<TitleDescriptionWhereInput>;
  customInstructions_some?: InputMaybe<TitleDescriptionWhereInput>;
  description_every?: InputMaybe<TextWhereInput>;
  description_none?: InputMaybe<TextWhereInput>;
  description_some?: InputMaybe<TextWhereInput>;
  documentInStages_every?: InputMaybe<AttractionAlacarteWhereStageInput>;
  documentInStages_none?: InputMaybe<AttractionAlacarteWhereStageInput>;
  documentInStages_some?: InputMaybe<AttractionAlacarteWhereStageInput>;
  /** All values containing the given json path. */
  filters_json_path_exists?: InputMaybe<Scalars['String']>;
  /**
   * Recursively tries to find the provided JSON scalar value inside the field.
   * It does use an exact match when comparing values.
   * If you pass `null` as value the filter will be ignored.
   * Note: This filter fails if you try to look for a non scalar JSON value!
   */
  filters_value_recursive?: InputMaybe<Scalars['Json']>;
  hideTicketPicker?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  hideTicketPicker_not?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  key?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  key_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  key_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  key_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  key_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  key_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  key_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  key_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  key_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  key_starts_with?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  redemptionShort_every?: InputMaybe<TextWhereInput>;
  redemptionShort_none?: InputMaybe<TextWhereInput>;
  redemptionShort_some?: InputMaybe<TextWhereInput>;
  redemption_every?: InputMaybe<TextWhereInput>;
  redemption_none?: InputMaybe<TextWhereInput>;
  redemption_some?: InputMaybe<TextWhereInput>;
  restrictionsCommon_every?: InputMaybe<TextWhereInput>;
  restrictionsCommon_none?: InputMaybe<TextWhereInput>;
  restrictionsCommon_some?: InputMaybe<TextWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  termsAndConditions_every?: InputMaybe<TextWhereInput>;
  termsAndConditions_none?: InputMaybe<TextWhereInput>;
  termsAndConditions_some?: InputMaybe<TextWhereInput>;
  ticketTypes_every?: InputMaybe<AlacarteTicketTypeWhereInput>;
  ticketTypes_none?: InputMaybe<AlacarteTicketTypeWhereInput>;
  ticketTypes_some?: InputMaybe<AlacarteTicketTypeWhereInput>;
  tickets_every?: InputMaybe<AlacarteTicketWhereInput>;
  tickets_none?: InputMaybe<AlacarteTicketWhereInput>;
  tickets_some?: InputMaybe<AlacarteTicketWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
  validityCommon_every?: InputMaybe<TextWhereInput>;
  validityCommon_none?: InputMaybe<TextWhereInput>;
  validityCommon_some?: InputMaybe<TextWhereInput>;
  validityWillCall_every?: InputMaybe<TextWhereInput>;
  validityWillCall_none?: InputMaybe<TextWhereInput>;
  validityWillCall_some?: InputMaybe<TextWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type AttractionAlacarteWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AttractionAlacarteWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AttractionAlacarteWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AttractionAlacarteWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<AttractionAlacarteWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References AttractionAlacarte record uniquely */
export type AttractionAlacarteWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
  key?: InputMaybe<Scalars['String']>;
};

export type AttractionAvailability = Node & {
  __typename?: 'AttractionAvailability';
  attraction: Maybe<Attraction>;
  attractionVariant: Maybe<AttractionVariant>;
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** User that created this document */
  createdBy: Maybe<User>;
  /** Get the document in other stages */
  documentInStages: Array<AttractionAvailability>;
  /** List of AttractionAvailability versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID'];
  internalTitle: Scalars['String'];
  limited: Maybe<Scalars['String']>;
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<AttractionAvailability>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt: Maybe<Scalars['DateTime']>;
  /** User that last published this document */
  publishedBy: Maybe<User>;
  qabu: Maybe<Scalars['Json']>;
  schedule: Maybe<Scalars['String']>;
  scheduledIn: Array<ScheduledOperation>;
  soldOut: Maybe<Scalars['String']>;
  /** System stage field */
  stage: Stage;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** User that last updated this document */
  updatedBy: Maybe<User>;
};

export type AttractionAvailabilityAttractionArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type AttractionAvailabilityAttractionVariantArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type AttractionAvailabilityCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type AttractionAvailabilityCreatedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type AttractionAvailabilityDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};

export type AttractionAvailabilityHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride: InputMaybe<Stage>;
};

export type AttractionAvailabilityLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};

export type AttractionAvailabilityPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type AttractionAvailabilityPublishedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type AttractionAvailabilityScheduledInArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ScheduledOperationWhereInput>;
};

export type AttractionAvailabilityUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type AttractionAvailabilityUpdatedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type AttractionAvailabilityConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: AttractionAvailabilityWhereUniqueInput;
};

/** A connection to a list of items. */
export type AttractionAvailabilityConnection = {
  __typename?: 'AttractionAvailabilityConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<AttractionAvailabilityEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type AttractionAvailabilityCreateInput = {
  attraction?: InputMaybe<AttractionCreateOneInlineInput>;
  attractionVariant?: InputMaybe<AttractionVariantCreateOneInlineInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  internalTitle: Scalars['String'];
  /** limited input for default locale (en) */
  limited?: InputMaybe<Scalars['String']>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<AttractionAvailabilityCreateLocalizationsInput>;
  qabu?: InputMaybe<Scalars['Json']>;
  /** schedule input for default locale (en) */
  schedule?: InputMaybe<Scalars['String']>;
  /** soldOut input for default locale (en) */
  soldOut?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type AttractionAvailabilityCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  limited?: InputMaybe<Scalars['String']>;
  schedule?: InputMaybe<Scalars['String']>;
  soldOut?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type AttractionAvailabilityCreateLocalizationInput = {
  /** Localization input */
  data: AttractionAvailabilityCreateLocalizationDataInput;
  locale: Locale;
};

export type AttractionAvailabilityCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<AttractionAvailabilityCreateLocalizationInput>>;
};

export type AttractionAvailabilityCreateManyInlineInput = {
  /** Connect multiple existing AttractionAvailability documents */
  connect?: InputMaybe<Array<AttractionAvailabilityWhereUniqueInput>>;
  /** Create and connect multiple existing AttractionAvailability documents */
  create?: InputMaybe<Array<AttractionAvailabilityCreateInput>>;
};

export type AttractionAvailabilityCreateOneInlineInput = {
  /** Connect one existing AttractionAvailability document */
  connect?: InputMaybe<AttractionAvailabilityWhereUniqueInput>;
  /** Create and connect one AttractionAvailability document */
  create?: InputMaybe<AttractionAvailabilityCreateInput>;
};

/** An edge in a connection. */
export type AttractionAvailabilityEdge = {
  __typename?: 'AttractionAvailabilityEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: AttractionAvailability;
};

/** Identifies documents */
export type AttractionAvailabilityManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AttractionAvailabilityWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AttractionAvailabilityWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AttractionAvailabilityWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  attraction?: InputMaybe<AttractionWhereInput>;
  attractionVariant?: InputMaybe<AttractionVariantWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<AttractionAvailabilityWhereStageInput>;
  documentInStages_none?: InputMaybe<AttractionAvailabilityWhereStageInput>;
  documentInStages_some?: InputMaybe<AttractionAvailabilityWhereStageInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  internalTitle?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  internalTitle_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  internalTitle_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  internalTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  internalTitle_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  internalTitle_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  internalTitle_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  internalTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  internalTitle_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  internalTitle_starts_with?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  /** All values containing the given json path. */
  qabu_json_path_exists?: InputMaybe<Scalars['String']>;
  /**
   * Recursively tries to find the provided JSON scalar value inside the field.
   * It does use an exact match when comparing values.
   * If you pass `null` as value the filter will be ignored.
   * Note: This filter fails if you try to look for a non scalar JSON value!
   */
  qabu_value_recursive?: InputMaybe<Scalars['Json']>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum AttractionAvailabilityOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  InternalTitleAsc = 'internalTitle_ASC',
  InternalTitleDesc = 'internalTitle_DESC',
  LimitedAsc = 'limited_ASC',
  LimitedDesc = 'limited_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  ScheduleAsc = 'schedule_ASC',
  ScheduleDesc = 'schedule_DESC',
  SoldOutAsc = 'soldOut_ASC',
  SoldOutDesc = 'soldOut_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type AttractionAvailabilityUpdateInput = {
  attraction?: InputMaybe<AttractionUpdateOneInlineInput>;
  attractionVariant?: InputMaybe<AttractionVariantUpdateOneInlineInput>;
  internalTitle?: InputMaybe<Scalars['String']>;
  /** limited input for default locale (en) */
  limited?: InputMaybe<Scalars['String']>;
  /** Manage document localizations */
  localizations?: InputMaybe<AttractionAvailabilityUpdateLocalizationsInput>;
  qabu?: InputMaybe<Scalars['Json']>;
  /** schedule input for default locale (en) */
  schedule?: InputMaybe<Scalars['String']>;
  /** soldOut input for default locale (en) */
  soldOut?: InputMaybe<Scalars['String']>;
};

export type AttractionAvailabilityUpdateLocalizationDataInput = {
  limited?: InputMaybe<Scalars['String']>;
  schedule?: InputMaybe<Scalars['String']>;
  soldOut?: InputMaybe<Scalars['String']>;
};

export type AttractionAvailabilityUpdateLocalizationInput = {
  data: AttractionAvailabilityUpdateLocalizationDataInput;
  locale: Locale;
};

export type AttractionAvailabilityUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<AttractionAvailabilityCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<AttractionAvailabilityUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<AttractionAvailabilityUpsertLocalizationInput>>;
};

export type AttractionAvailabilityUpdateManyInlineInput = {
  /** Connect multiple existing AttractionAvailability documents */
  connect?: InputMaybe<Array<AttractionAvailabilityConnectInput>>;
  /** Create and connect multiple AttractionAvailability documents */
  create?: InputMaybe<Array<AttractionAvailabilityCreateInput>>;
  /** Delete multiple AttractionAvailability documents */
  delete?: InputMaybe<Array<AttractionAvailabilityWhereUniqueInput>>;
  /** Disconnect multiple AttractionAvailability documents */
  disconnect?: InputMaybe<Array<AttractionAvailabilityWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing AttractionAvailability documents */
  set?: InputMaybe<Array<AttractionAvailabilityWhereUniqueInput>>;
  /** Update multiple AttractionAvailability documents */
  update?: InputMaybe<Array<AttractionAvailabilityUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple AttractionAvailability documents */
  upsert?: InputMaybe<Array<AttractionAvailabilityUpsertWithNestedWhereUniqueInput>>;
};

export type AttractionAvailabilityUpdateManyInput = {
  /** limited input for default locale (en) */
  limited?: InputMaybe<Scalars['String']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<AttractionAvailabilityUpdateManyLocalizationsInput>;
  qabu?: InputMaybe<Scalars['Json']>;
  /** schedule input for default locale (en) */
  schedule?: InputMaybe<Scalars['String']>;
  /** soldOut input for default locale (en) */
  soldOut?: InputMaybe<Scalars['String']>;
};

export type AttractionAvailabilityUpdateManyLocalizationDataInput = {
  limited?: InputMaybe<Scalars['String']>;
  schedule?: InputMaybe<Scalars['String']>;
  soldOut?: InputMaybe<Scalars['String']>;
};

export type AttractionAvailabilityUpdateManyLocalizationInput = {
  data: AttractionAvailabilityUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type AttractionAvailabilityUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<AttractionAvailabilityUpdateManyLocalizationInput>>;
};

export type AttractionAvailabilityUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: AttractionAvailabilityUpdateManyInput;
  /** Document search */
  where: AttractionAvailabilityWhereInput;
};

export type AttractionAvailabilityUpdateOneInlineInput = {
  /** Connect existing AttractionAvailability document */
  connect?: InputMaybe<AttractionAvailabilityWhereUniqueInput>;
  /** Create and connect one AttractionAvailability document */
  create?: InputMaybe<AttractionAvailabilityCreateInput>;
  /** Delete currently connected AttractionAvailability document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected AttractionAvailability document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single AttractionAvailability document */
  update?: InputMaybe<AttractionAvailabilityUpdateWithNestedWhereUniqueInput>;
  /** Upsert single AttractionAvailability document */
  upsert?: InputMaybe<AttractionAvailabilityUpsertWithNestedWhereUniqueInput>;
};

export type AttractionAvailabilityUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: AttractionAvailabilityUpdateInput;
  /** Unique document search */
  where: AttractionAvailabilityWhereUniqueInput;
};

export type AttractionAvailabilityUpsertInput = {
  /** Create document if it didn't exist */
  create: AttractionAvailabilityCreateInput;
  /** Update document if it exists */
  update: AttractionAvailabilityUpdateInput;
};

export type AttractionAvailabilityUpsertLocalizationInput = {
  create: AttractionAvailabilityCreateLocalizationDataInput;
  locale: Locale;
  update: AttractionAvailabilityUpdateLocalizationDataInput;
};

export type AttractionAvailabilityUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: AttractionAvailabilityUpsertInput;
  /** Unique document search */
  where: AttractionAvailabilityWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type AttractionAvailabilityWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']>;
};

/** Identifies documents */
export type AttractionAvailabilityWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AttractionAvailabilityWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AttractionAvailabilityWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AttractionAvailabilityWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  attraction?: InputMaybe<AttractionWhereInput>;
  attractionVariant?: InputMaybe<AttractionVariantWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<AttractionAvailabilityWhereStageInput>;
  documentInStages_none?: InputMaybe<AttractionAvailabilityWhereStageInput>;
  documentInStages_some?: InputMaybe<AttractionAvailabilityWhereStageInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  internalTitle?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  internalTitle_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  internalTitle_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  internalTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  internalTitle_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  internalTitle_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  internalTitle_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  internalTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  internalTitle_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  internalTitle_starts_with?: InputMaybe<Scalars['String']>;
  limited?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  limited_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  limited_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  limited_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  limited_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  limited_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  limited_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  limited_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  limited_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  limited_starts_with?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  /** All values containing the given json path. */
  qabu_json_path_exists?: InputMaybe<Scalars['String']>;
  /**
   * Recursively tries to find the provided JSON scalar value inside the field.
   * It does use an exact match when comparing values.
   * If you pass `null` as value the filter will be ignored.
   * Note: This filter fails if you try to look for a non scalar JSON value!
   */
  qabu_value_recursive?: InputMaybe<Scalars['Json']>;
  schedule?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  schedule_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  schedule_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  schedule_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  schedule_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  schedule_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  schedule_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  schedule_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  schedule_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  schedule_starts_with?: InputMaybe<Scalars['String']>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  soldOut?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  soldOut_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  soldOut_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  soldOut_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  soldOut_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  soldOut_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  soldOut_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  soldOut_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  soldOut_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  soldOut_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type AttractionAvailabilityWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AttractionAvailabilityWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AttractionAvailabilityWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AttractionAvailabilityWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<AttractionAvailabilityWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References AttractionAvailability record uniquely */
export type AttractionAvailabilityWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
  internalTitle?: InputMaybe<Scalars['String']>;
};

export type AttractionConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: AttractionWhereUniqueInput;
};

/** A connection to a list of items. */
export type AttractionConnection = {
  __typename?: 'AttractionConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<AttractionEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type AttractionCreateInput = {
  /** accessibility input for default locale (en) */
  accessibility?: InputMaybe<Scalars['String']>;
  addon?: InputMaybe<Scalars['Boolean']>;
  addonAttractionProducts?: InputMaybe<ProductCreateManyInlineInput>;
  admission?: InputMaybe<TextCreateManyInlineInput>;
  affiliateId?: InputMaybe<Scalars['String']>;
  ageAdult?: InputMaybe<Scalars['String']>;
  ageChild?: InputMaybe<Scalars['String']>;
  alacarte?: InputMaybe<AttractionAlacarteCreateOneInlineInput>;
  amenities?: InputMaybe<TextCreateManyInlineInput>;
  attractionAddOn?: InputMaybe<AddOnCreateOneInlineInput>;
  availability?: InputMaybe<AttractionAvailabilityCreateOneInlineInput>;
  /** bestTime input for default locale (en) */
  bestTime?: InputMaybe<Scalars['String']>;
  ckx0w0ikl1a5j01xq50lngfsk?: InputMaybe<NoticeCreateManyInlineInput>;
  cl2j6ef1o0bt701xh589e0zd8?: InputMaybe<ProductReservationCreateManyInlineInput>;
  cl2jgb55t0p5e01z95bx28lb7?: InputMaybe<AttractionGroupCreateManyInlineInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** exclusiveFeature input for default locale (en) */
  exclusiveFeature?: InputMaybe<Scalars['String']>;
  exclusiveTo?: InputMaybe<ProductCreateOneInlineInput>;
  gallery?: InputMaybe<AttractionGalleryCreateManyInlineInput>;
  googlePlaceId?: InputMaybe<Scalars['String']>;
  hero?: InputMaybe<AssetCreateOneInlineInput>;
  /** heroCredit input for default locale (en) */
  heroCredit?: InputMaybe<Scalars['String']>;
  highlights?: InputMaybe<TextCreateManyInlineInput>;
  /** intro input for default locale (en) */
  intro?: InputMaybe<Scalars['String']>;
  key: Scalars['String'];
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<AttractionCreateLocalizationsInput>;
  locations?: InputMaybe<AttractionLocationCreateManyInlineInput>;
  logo?: InputMaybe<AssetCreateOneInlineInput>;
  /** name input for default locale (en) */
  name?: InputMaybe<Scalars['String']>;
  nextPrice?: InputMaybe<AttractionPriceCreateOneInlineInput>;
  offers?: InputMaybe<AttractionOfferCreateManyInlineInput>;
  openStatus?: InputMaybe<TextCreateOneInlineInput>;
  /** parking input for default locale (en) */
  parking?: InputMaybe<Scalars['String']>;
  partnerOffers?: InputMaybe<PartnerOfferCreateManyInlineInput>;
  planning?: InputMaybe<DetailBlockCreateOneInlineInput>;
  price?: InputMaybe<AttractionPriceCreateOneInlineInput>;
  products?: InputMaybe<ProductCreateManyInlineInput>;
  promos?: InputMaybe<PromoCreateManyInlineInput>;
  qabu?: InputMaybe<Scalars['Json']>;
  reservation?: InputMaybe<AttractionReservationCreateOneInlineInput>;
  safety?: InputMaybe<TextCreateManyInlineInput>;
  seo?: InputMaybe<SeoCreateOneInlineInput>;
  /** shortIntro input for default locale (en) */
  shortIntro?: InputMaybe<Scalars['String']>;
  skuProperties?: InputMaybe<SkuPropertyCreateManyInlineInput>;
  slug?: InputMaybe<Scalars['String']>;
  thumb?: InputMaybe<AssetCreateOneInlineInput>;
  tips?: InputMaybe<TextCreateManyInlineInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  variants?: InputMaybe<AttractionVariantCreateManyInlineInput>;
};

export type AttractionCreateLocalizationDataInput = {
  accessibility?: InputMaybe<Scalars['String']>;
  bestTime?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  exclusiveFeature?: InputMaybe<Scalars['String']>;
  heroCredit?: InputMaybe<Scalars['String']>;
  intro?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  parking?: InputMaybe<Scalars['String']>;
  shortIntro?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type AttractionCreateLocalizationInput = {
  /** Localization input */
  data: AttractionCreateLocalizationDataInput;
  locale: Locale;
};

export type AttractionCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<AttractionCreateLocalizationInput>>;
};

export type AttractionCreateManyInlineInput = {
  /** Connect multiple existing Attraction documents */
  connect?: InputMaybe<Array<AttractionWhereUniqueInput>>;
  /** Create and connect multiple existing Attraction documents */
  create?: InputMaybe<Array<AttractionCreateInput>>;
};

export type AttractionCreateOneInlineInput = {
  /** Connect one existing Attraction document */
  connect?: InputMaybe<AttractionWhereUniqueInput>;
  /** Create and connect one Attraction document */
  create?: InputMaybe<AttractionCreateInput>;
};

/** An edge in a connection. */
export type AttractionEdge = {
  __typename?: 'AttractionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Attraction;
};

export type AttractionGallery = Image | Video;

export type AttractionGalleryConnectInput = {
  Image?: InputMaybe<ImageConnectInput>;
  Video?: InputMaybe<VideoConnectInput>;
};

export type AttractionGalleryCreateInput = {
  Image?: InputMaybe<ImageCreateInput>;
  Video?: InputMaybe<VideoCreateInput>;
};

export type AttractionGalleryCreateManyInlineInput = {
  /** Connect multiple existing AttractionGallery documents */
  connect?: InputMaybe<Array<AttractionGalleryWhereUniqueInput>>;
  /** Create and connect multiple existing AttractionGallery documents */
  create?: InputMaybe<Array<AttractionGalleryCreateInput>>;
};

export type AttractionGalleryCreateOneInlineInput = {
  /** Connect one existing AttractionGallery document */
  connect?: InputMaybe<AttractionGalleryWhereUniqueInput>;
  /** Create and connect one AttractionGallery document */
  create?: InputMaybe<AttractionGalleryCreateInput>;
};

export type AttractionGalleryUpdateInput = {
  Image?: InputMaybe<ImageUpdateInput>;
  Video?: InputMaybe<VideoUpdateInput>;
};

export type AttractionGalleryUpdateManyInlineInput = {
  /** Connect multiple existing AttractionGallery documents */
  connect?: InputMaybe<Array<AttractionGalleryConnectInput>>;
  /** Create and connect multiple AttractionGallery documents */
  create?: InputMaybe<Array<AttractionGalleryCreateInput>>;
  /** Delete multiple AttractionGallery documents */
  delete?: InputMaybe<Array<AttractionGalleryWhereUniqueInput>>;
  /** Disconnect multiple AttractionGallery documents */
  disconnect?: InputMaybe<Array<AttractionGalleryWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing AttractionGallery documents */
  set?: InputMaybe<Array<AttractionGalleryWhereUniqueInput>>;
  /** Update multiple AttractionGallery documents */
  update?: InputMaybe<Array<AttractionGalleryUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple AttractionGallery documents */
  upsert?: InputMaybe<Array<AttractionGalleryUpsertWithNestedWhereUniqueInput>>;
};

export type AttractionGalleryUpdateManyWithNestedWhereInput = {
  Image?: InputMaybe<ImageUpdateManyWithNestedWhereInput>;
  Video?: InputMaybe<VideoUpdateManyWithNestedWhereInput>;
};

export type AttractionGalleryUpdateOneInlineInput = {
  /** Connect existing AttractionGallery document */
  connect?: InputMaybe<AttractionGalleryWhereUniqueInput>;
  /** Create and connect one AttractionGallery document */
  create?: InputMaybe<AttractionGalleryCreateInput>;
  /** Delete currently connected AttractionGallery document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected AttractionGallery document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single AttractionGallery document */
  update?: InputMaybe<AttractionGalleryUpdateWithNestedWhereUniqueInput>;
  /** Upsert single AttractionGallery document */
  upsert?: InputMaybe<AttractionGalleryUpsertWithNestedWhereUniqueInput>;
};

export type AttractionGalleryUpdateWithNestedWhereUniqueInput = {
  Image?: InputMaybe<ImageUpdateWithNestedWhereUniqueInput>;
  Video?: InputMaybe<VideoUpdateWithNestedWhereUniqueInput>;
};

export type AttractionGalleryUpsertWithNestedWhereUniqueInput = {
  Image?: InputMaybe<ImageUpsertWithNestedWhereUniqueInput>;
  Video?: InputMaybe<VideoUpsertWithNestedWhereUniqueInput>;
};

export type AttractionGalleryWhereInput = {
  Image?: InputMaybe<ImageWhereInput>;
  Video?: InputMaybe<VideoWhereInput>;
};

export type AttractionGalleryWhereUniqueInput = {
  Image?: InputMaybe<ImageWhereUniqueInput>;
  Video?: InputMaybe<VideoWhereUniqueInput>;
};

export type AttractionGroup = {
  __typename?: 'AttractionGroup';
  attractions: Array<Attraction>;
  choices: Scalars['Int'];
  /** The unique identifier */
  id: Scalars['ID'];
  /** System stage field */
  stage: Stage;
  title: Maybe<Text>;
  type: Maybe<AttractionGroupType>;
};

export type AttractionGroupAttractionsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<AttractionOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AttractionWhereInput>;
};

export type AttractionGroupTitleArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type AttractionGroupConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: AttractionGroupWhereUniqueInput;
};

/** A connection to a list of items. */
export type AttractionGroupConnection = {
  __typename?: 'AttractionGroupConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<AttractionGroupEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type AttractionGroupCreateInput = {
  attractions?: InputMaybe<AttractionCreateManyInlineInput>;
  choices: Scalars['Int'];
  title?: InputMaybe<TextCreateOneInlineInput>;
  type?: InputMaybe<AttractionGroupType>;
};

export type AttractionGroupCreateManyInlineInput = {
  /** Create and connect multiple existing AttractionGroup documents */
  create?: InputMaybe<Array<AttractionGroupCreateInput>>;
};

export type AttractionGroupCreateOneInlineInput = {
  /** Create and connect one AttractionGroup document */
  create?: InputMaybe<AttractionGroupCreateInput>;
};

export type AttractionGroupCreateWithPositionInput = {
  /** Document to create */
  data: AttractionGroupCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type AttractionGroupEdge = {
  __typename?: 'AttractionGroupEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: AttractionGroup;
};

/** Identifies documents */
export type AttractionGroupManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AttractionGroupWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AttractionGroupWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AttractionGroupWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  attractions_every?: InputMaybe<AttractionWhereInput>;
  attractions_none?: InputMaybe<AttractionWhereInput>;
  attractions_some?: InputMaybe<AttractionWhereInput>;
  choices?: InputMaybe<Scalars['Int']>;
  /** All values greater than the given value. */
  choices_gt?: InputMaybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  choices_gte?: InputMaybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  choices_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  /** All values less than the given value. */
  choices_lt?: InputMaybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  choices_lte?: InputMaybe<Scalars['Int']>;
  /** Any other value that exists and is not equal to the given value. */
  choices_not?: InputMaybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  choices_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  title?: InputMaybe<TextWhereInput>;
  type?: InputMaybe<AttractionGroupType>;
  /** All values that are contained in given list. */
  type_in?: InputMaybe<Array<InputMaybe<AttractionGroupType>>>;
  /** Any other value that exists and is not equal to the given value. */
  type_not?: InputMaybe<AttractionGroupType>;
  /** All values that are not contained in given list. */
  type_not_in?: InputMaybe<Array<InputMaybe<AttractionGroupType>>>;
};

export enum AttractionGroupOrderByInput {
  ChoicesAsc = 'choices_ASC',
  ChoicesDesc = 'choices_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
}

export type AttractionGroupParent = ProductAttractionLineup;

export type AttractionGroupParentConnectInput = {
  ProductAttractionLineup?: InputMaybe<ProductAttractionLineupConnectInput>;
};

export type AttractionGroupParentCreateInput = {
  ProductAttractionLineup?: InputMaybe<ProductAttractionLineupCreateInput>;
};

export type AttractionGroupParentCreateManyInlineInput = {
  /** Connect multiple existing AttractionGroupParent documents */
  connect?: InputMaybe<Array<AttractionGroupParentWhereUniqueInput>>;
  /** Create and connect multiple existing AttractionGroupParent documents */
  create?: InputMaybe<Array<AttractionGroupParentCreateInput>>;
};

export type AttractionGroupParentCreateOneInlineInput = {
  /** Connect one existing AttractionGroupParent document */
  connect?: InputMaybe<AttractionGroupParentWhereUniqueInput>;
  /** Create and connect one AttractionGroupParent document */
  create?: InputMaybe<AttractionGroupParentCreateInput>;
};

export type AttractionGroupParentUpdateInput = {
  ProductAttractionLineup?: InputMaybe<ProductAttractionLineupUpdateInput>;
};

export type AttractionGroupParentUpdateManyInlineInput = {
  /** Connect multiple existing AttractionGroupParent documents */
  connect?: InputMaybe<Array<AttractionGroupParentConnectInput>>;
  /** Create and connect multiple AttractionGroupParent documents */
  create?: InputMaybe<Array<AttractionGroupParentCreateInput>>;
  /** Delete multiple AttractionGroupParent documents */
  delete?: InputMaybe<Array<AttractionGroupParentWhereUniqueInput>>;
  /** Disconnect multiple AttractionGroupParent documents */
  disconnect?: InputMaybe<Array<AttractionGroupParentWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing AttractionGroupParent documents */
  set?: InputMaybe<Array<AttractionGroupParentWhereUniqueInput>>;
  /** Update multiple AttractionGroupParent documents */
  update?: InputMaybe<Array<AttractionGroupParentUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple AttractionGroupParent documents */
  upsert?: InputMaybe<Array<AttractionGroupParentUpsertWithNestedWhereUniqueInput>>;
};

export type AttractionGroupParentUpdateManyWithNestedWhereInput = {
  ProductAttractionLineup?: InputMaybe<ProductAttractionLineupUpdateManyWithNestedWhereInput>;
};

export type AttractionGroupParentUpdateOneInlineInput = {
  /** Connect existing AttractionGroupParent document */
  connect?: InputMaybe<AttractionGroupParentWhereUniqueInput>;
  /** Create and connect one AttractionGroupParent document */
  create?: InputMaybe<AttractionGroupParentCreateInput>;
  /** Delete currently connected AttractionGroupParent document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected AttractionGroupParent document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single AttractionGroupParent document */
  update?: InputMaybe<AttractionGroupParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single AttractionGroupParent document */
  upsert?: InputMaybe<AttractionGroupParentUpsertWithNestedWhereUniqueInput>;
};

export type AttractionGroupParentUpdateWithNestedWhereUniqueInput = {
  ProductAttractionLineup?: InputMaybe<ProductAttractionLineupUpdateWithNestedWhereUniqueInput>;
};

export type AttractionGroupParentUpsertWithNestedWhereUniqueInput = {
  ProductAttractionLineup?: InputMaybe<ProductAttractionLineupUpsertWithNestedWhereUniqueInput>;
};

export type AttractionGroupParentWhereInput = {
  ProductAttractionLineup?: InputMaybe<ProductAttractionLineupWhereInput>;
};

export type AttractionGroupParentWhereUniqueInput = {
  ProductAttractionLineup?: InputMaybe<ProductAttractionLineupWhereUniqueInput>;
};

export enum AttractionGroupType {
  Choice = 'choice',
  Cticket = 'cticket',
  Single = 'single',
  Split = 'split',
}

export type AttractionGroupUpdateInput = {
  attractions?: InputMaybe<AttractionUpdateManyInlineInput>;
  choices?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<TextUpdateOneInlineInput>;
  type?: InputMaybe<AttractionGroupType>;
};

export type AttractionGroupUpdateManyInlineInput = {
  /** Create and connect multiple AttractionGroup component instances */
  create?: InputMaybe<Array<AttractionGroupCreateWithPositionInput>>;
  /** Delete multiple AttractionGroup documents */
  delete?: InputMaybe<Array<AttractionGroupWhereUniqueInput>>;
  /** Update multiple AttractionGroup component instances */
  update?: InputMaybe<Array<AttractionGroupUpdateWithNestedWhereUniqueAndPositionInput>>;
  /** Upsert multiple AttractionGroup component instances */
  upsert?: InputMaybe<Array<AttractionGroupUpsertWithNestedWhereUniqueAndPositionInput>>;
};

export type AttractionGroupUpdateManyInput = {
  choices?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<AttractionGroupType>;
};

export type AttractionGroupUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: AttractionGroupUpdateManyInput;
  /** Document search */
  where: AttractionGroupWhereInput;
};

export type AttractionGroupUpdateOneInlineInput = {
  /** Create and connect one AttractionGroup document */
  create?: InputMaybe<AttractionGroupCreateInput>;
  /** Delete currently connected AttractionGroup document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single AttractionGroup document */
  update?: InputMaybe<AttractionGroupUpdateWithNestedWhereUniqueInput>;
  /** Upsert single AttractionGroup document */
  upsert?: InputMaybe<AttractionGroupUpsertWithNestedWhereUniqueInput>;
};

export type AttractionGroupUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<AttractionGroupUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: AttractionGroupWhereUniqueInput;
};

export type AttractionGroupUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: AttractionGroupUpdateInput;
  /** Unique document search */
  where: AttractionGroupWhereUniqueInput;
};

export type AttractionGroupUpsertInput = {
  /** Create document if it didn't exist */
  create: AttractionGroupCreateInput;
  /** Update document if it exists */
  update: AttractionGroupUpdateInput;
};

export type AttractionGroupUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<AttractionGroupUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: AttractionGroupWhereUniqueInput;
};

export type AttractionGroupUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: AttractionGroupUpsertInput;
  /** Unique document search */
  where: AttractionGroupWhereUniqueInput;
};

/** Identifies documents */
export type AttractionGroupWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AttractionGroupWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AttractionGroupWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AttractionGroupWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  attractions_every?: InputMaybe<AttractionWhereInput>;
  attractions_none?: InputMaybe<AttractionWhereInput>;
  attractions_some?: InputMaybe<AttractionWhereInput>;
  choices?: InputMaybe<Scalars['Int']>;
  /** All values greater than the given value. */
  choices_gt?: InputMaybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  choices_gte?: InputMaybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  choices_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  /** All values less than the given value. */
  choices_lt?: InputMaybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  choices_lte?: InputMaybe<Scalars['Int']>;
  /** Any other value that exists and is not equal to the given value. */
  choices_not?: InputMaybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  choices_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  title?: InputMaybe<TextWhereInput>;
  type?: InputMaybe<AttractionGroupType>;
  /** All values that are contained in given list. */
  type_in?: InputMaybe<Array<InputMaybe<AttractionGroupType>>>;
  /** Any other value that exists and is not equal to the given value. */
  type_not?: InputMaybe<AttractionGroupType>;
  /** All values that are not contained in given list. */
  type_not_in?: InputMaybe<Array<InputMaybe<AttractionGroupType>>>;
};

/** References AttractionGroup record uniquely */
export type AttractionGroupWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type AttractionLocation = Node & {
  __typename?: 'AttractionLocation';
  address: Array<Scalars['String']>;
  addressNote: Maybe<Scalars['String']>;
  attraction: Maybe<Attraction>;
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** User that created this document */
  createdBy: Maybe<User>;
  /** Get the document in other stages */
  documentInStages: Array<AttractionLocation>;
  geolocation: Maybe<Location>;
  /** List of AttractionLocation versions */
  history: Array<Version>;
  hours: Array<Scalars['String']>;
  hoursNotes: Array<Text>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<AttractionLocation>;
  name: Maybe<Scalars['String']>;
  phone: Array<Scalars['String']>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt: Maybe<Scalars['DateTime']>;
  /** User that last published this document */
  publishedBy: Maybe<User>;
  qabu: Maybe<Scalars['Json']>;
  scheduledIn: Array<ScheduledOperation>;
  /** System stage field */
  stage: Stage;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** User that last updated this document */
  updatedBy: Maybe<User>;
  website: Maybe<Scalars['String']>;
};

export type AttractionLocationAttractionArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type AttractionLocationCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type AttractionLocationCreatedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type AttractionLocationDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};

export type AttractionLocationHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride: InputMaybe<Stage>;
};

export type AttractionLocationHoursNotesArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<TextOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<TextWhereInput>;
};

export type AttractionLocationLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};

export type AttractionLocationPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type AttractionLocationPublishedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type AttractionLocationScheduledInArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ScheduledOperationWhereInput>;
};

export type AttractionLocationUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type AttractionLocationUpdatedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type AttractionLocationConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: AttractionLocationWhereUniqueInput;
};

/** A connection to a list of items. */
export type AttractionLocationConnection = {
  __typename?: 'AttractionLocationConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<AttractionLocationEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type AttractionLocationCreateInput = {
  address?: InputMaybe<Array<Scalars['String']>>;
  /** addressNote input for default locale (en) */
  addressNote?: InputMaybe<Scalars['String']>;
  attraction?: InputMaybe<AttractionCreateOneInlineInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  geolocation?: InputMaybe<LocationInput>;
  hours?: InputMaybe<Array<Scalars['String']>>;
  hoursNotes?: InputMaybe<TextCreateManyInlineInput>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<AttractionLocationCreateLocalizationsInput>;
  /** name input for default locale (en) */
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Array<Scalars['String']>>;
  qabu?: InputMaybe<Scalars['Json']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  website?: InputMaybe<Scalars['String']>;
};

export type AttractionLocationCreateLocalizationDataInput = {
  addressNote?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type AttractionLocationCreateLocalizationInput = {
  /** Localization input */
  data: AttractionLocationCreateLocalizationDataInput;
  locale: Locale;
};

export type AttractionLocationCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<AttractionLocationCreateLocalizationInput>>;
};

export type AttractionLocationCreateManyInlineInput = {
  /** Connect multiple existing AttractionLocation documents */
  connect?: InputMaybe<Array<AttractionLocationWhereUniqueInput>>;
  /** Create and connect multiple existing AttractionLocation documents */
  create?: InputMaybe<Array<AttractionLocationCreateInput>>;
};

export type AttractionLocationCreateOneInlineInput = {
  /** Connect one existing AttractionLocation document */
  connect?: InputMaybe<AttractionLocationWhereUniqueInput>;
  /** Create and connect one AttractionLocation document */
  create?: InputMaybe<AttractionLocationCreateInput>;
};

/** An edge in a connection. */
export type AttractionLocationEdge = {
  __typename?: 'AttractionLocationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: AttractionLocation;
};

/** Identifies documents */
export type AttractionLocationManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AttractionLocationWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AttractionLocationWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AttractionLocationWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  address?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains *all* items provided to the filter */
  address_contains_all?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  address_contains_none?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains at least one item provided to the filter */
  address_contains_some?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  address_not?: InputMaybe<Array<Scalars['String']>>;
  attraction?: InputMaybe<AttractionWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<AttractionLocationWhereStageInput>;
  documentInStages_none?: InputMaybe<AttractionLocationWhereStageInput>;
  documentInStages_some?: InputMaybe<AttractionLocationWhereStageInput>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  hours?: InputMaybe<Array<Scalars['String']>>;
  hoursNotes_every?: InputMaybe<TextWhereInput>;
  hoursNotes_none?: InputMaybe<TextWhereInput>;
  hoursNotes_some?: InputMaybe<TextWhereInput>;
  /** Matches if the field array contains *all* items provided to the filter */
  hours_contains_all?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  hours_contains_none?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains at least one item provided to the filter */
  hours_contains_some?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  hours_not?: InputMaybe<Array<Scalars['String']>>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  phone?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains *all* items provided to the filter */
  phone_contains_all?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  phone_contains_none?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains at least one item provided to the filter */
  phone_contains_some?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  phone_not?: InputMaybe<Array<Scalars['String']>>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  /** All values containing the given json path. */
  qabu_json_path_exists?: InputMaybe<Scalars['String']>;
  /**
   * Recursively tries to find the provided JSON scalar value inside the field.
   * It does use an exact match when comparing values.
   * If you pass `null` as value the filter will be ignored.
   * Note: This filter fails if you try to look for a non scalar JSON value!
   */
  qabu_value_recursive?: InputMaybe<Scalars['Json']>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
  website?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  website_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  website_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  website_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  website_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  website_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  website_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  website_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  website_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  website_starts_with?: InputMaybe<Scalars['String']>;
};

export enum AttractionLocationOrderByInput {
  AddressNoteAsc = 'addressNote_ASC',
  AddressNoteDesc = 'addressNote_DESC',
  AddressAsc = 'address_ASC',
  AddressDesc = 'address_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  HoursAsc = 'hours_ASC',
  HoursDesc = 'hours_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PhoneAsc = 'phone_ASC',
  PhoneDesc = 'phone_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  WebsiteAsc = 'website_ASC',
  WebsiteDesc = 'website_DESC',
}

export type AttractionLocationUpdateInput = {
  address?: InputMaybe<Array<Scalars['String']>>;
  /** addressNote input for default locale (en) */
  addressNote?: InputMaybe<Scalars['String']>;
  attraction?: InputMaybe<AttractionUpdateOneInlineInput>;
  geolocation?: InputMaybe<LocationInput>;
  hours?: InputMaybe<Array<Scalars['String']>>;
  hoursNotes?: InputMaybe<TextUpdateManyInlineInput>;
  /** Manage document localizations */
  localizations?: InputMaybe<AttractionLocationUpdateLocalizationsInput>;
  /** name input for default locale (en) */
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Array<Scalars['String']>>;
  qabu?: InputMaybe<Scalars['Json']>;
  website?: InputMaybe<Scalars['String']>;
};

export type AttractionLocationUpdateLocalizationDataInput = {
  addressNote?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type AttractionLocationUpdateLocalizationInput = {
  data: AttractionLocationUpdateLocalizationDataInput;
  locale: Locale;
};

export type AttractionLocationUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<AttractionLocationCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<AttractionLocationUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<AttractionLocationUpsertLocalizationInput>>;
};

export type AttractionLocationUpdateManyInlineInput = {
  /** Connect multiple existing AttractionLocation documents */
  connect?: InputMaybe<Array<AttractionLocationConnectInput>>;
  /** Create and connect multiple AttractionLocation documents */
  create?: InputMaybe<Array<AttractionLocationCreateInput>>;
  /** Delete multiple AttractionLocation documents */
  delete?: InputMaybe<Array<AttractionLocationWhereUniqueInput>>;
  /** Disconnect multiple AttractionLocation documents */
  disconnect?: InputMaybe<Array<AttractionLocationWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing AttractionLocation documents */
  set?: InputMaybe<Array<AttractionLocationWhereUniqueInput>>;
  /** Update multiple AttractionLocation documents */
  update?: InputMaybe<Array<AttractionLocationUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple AttractionLocation documents */
  upsert?: InputMaybe<Array<AttractionLocationUpsertWithNestedWhereUniqueInput>>;
};

export type AttractionLocationUpdateManyInput = {
  address?: InputMaybe<Array<Scalars['String']>>;
  /** addressNote input for default locale (en) */
  addressNote?: InputMaybe<Scalars['String']>;
  geolocation?: InputMaybe<LocationInput>;
  hours?: InputMaybe<Array<Scalars['String']>>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<AttractionLocationUpdateManyLocalizationsInput>;
  /** name input for default locale (en) */
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Array<Scalars['String']>>;
  qabu?: InputMaybe<Scalars['Json']>;
  website?: InputMaybe<Scalars['String']>;
};

export type AttractionLocationUpdateManyLocalizationDataInput = {
  addressNote?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type AttractionLocationUpdateManyLocalizationInput = {
  data: AttractionLocationUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type AttractionLocationUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<AttractionLocationUpdateManyLocalizationInput>>;
};

export type AttractionLocationUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: AttractionLocationUpdateManyInput;
  /** Document search */
  where: AttractionLocationWhereInput;
};

export type AttractionLocationUpdateOneInlineInput = {
  /** Connect existing AttractionLocation document */
  connect?: InputMaybe<AttractionLocationWhereUniqueInput>;
  /** Create and connect one AttractionLocation document */
  create?: InputMaybe<AttractionLocationCreateInput>;
  /** Delete currently connected AttractionLocation document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected AttractionLocation document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single AttractionLocation document */
  update?: InputMaybe<AttractionLocationUpdateWithNestedWhereUniqueInput>;
  /** Upsert single AttractionLocation document */
  upsert?: InputMaybe<AttractionLocationUpsertWithNestedWhereUniqueInput>;
};

export type AttractionLocationUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: AttractionLocationUpdateInput;
  /** Unique document search */
  where: AttractionLocationWhereUniqueInput;
};

export type AttractionLocationUpsertInput = {
  /** Create document if it didn't exist */
  create: AttractionLocationCreateInput;
  /** Update document if it exists */
  update: AttractionLocationUpdateInput;
};

export type AttractionLocationUpsertLocalizationInput = {
  create: AttractionLocationCreateLocalizationDataInput;
  locale: Locale;
  update: AttractionLocationUpdateLocalizationDataInput;
};

export type AttractionLocationUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: AttractionLocationUpsertInput;
  /** Unique document search */
  where: AttractionLocationWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type AttractionLocationWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']>;
};

/** Identifies documents */
export type AttractionLocationWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AttractionLocationWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AttractionLocationWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AttractionLocationWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  address?: InputMaybe<Array<Scalars['String']>>;
  addressNote?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  addressNote_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  addressNote_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  addressNote_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  addressNote_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  addressNote_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  addressNote_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  addressNote_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  addressNote_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  addressNote_starts_with?: InputMaybe<Scalars['String']>;
  /** Matches if the field array contains *all* items provided to the filter */
  address_contains_all?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  address_contains_none?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains at least one item provided to the filter */
  address_contains_some?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  address_not?: InputMaybe<Array<Scalars['String']>>;
  attraction?: InputMaybe<AttractionWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<AttractionLocationWhereStageInput>;
  documentInStages_none?: InputMaybe<AttractionLocationWhereStageInput>;
  documentInStages_some?: InputMaybe<AttractionLocationWhereStageInput>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  hours?: InputMaybe<Array<Scalars['String']>>;
  hoursNotes_every?: InputMaybe<TextWhereInput>;
  hoursNotes_none?: InputMaybe<TextWhereInput>;
  hoursNotes_some?: InputMaybe<TextWhereInput>;
  /** Matches if the field array contains *all* items provided to the filter */
  hours_contains_all?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  hours_contains_none?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains at least one item provided to the filter */
  hours_contains_some?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  hours_not?: InputMaybe<Array<Scalars['String']>>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  phone?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains *all* items provided to the filter */
  phone_contains_all?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  phone_contains_none?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains at least one item provided to the filter */
  phone_contains_some?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  phone_not?: InputMaybe<Array<Scalars['String']>>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  /** All values containing the given json path. */
  qabu_json_path_exists?: InputMaybe<Scalars['String']>;
  /**
   * Recursively tries to find the provided JSON scalar value inside the field.
   * It does use an exact match when comparing values.
   * If you pass `null` as value the filter will be ignored.
   * Note: This filter fails if you try to look for a non scalar JSON value!
   */
  qabu_value_recursive?: InputMaybe<Scalars['Json']>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
  website?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  website_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  website_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  website_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  website_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  website_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  website_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  website_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  website_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  website_starts_with?: InputMaybe<Scalars['String']>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type AttractionLocationWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AttractionLocationWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AttractionLocationWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AttractionLocationWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<AttractionLocationWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References AttractionLocation record uniquely */
export type AttractionLocationWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

/** Identifies documents */
export type AttractionManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AttractionWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AttractionWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AttractionWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  addon?: InputMaybe<Scalars['Boolean']>;
  addonAttractionProducts_every?: InputMaybe<ProductWhereInput>;
  addonAttractionProducts_none?: InputMaybe<ProductWhereInput>;
  addonAttractionProducts_some?: InputMaybe<ProductWhereInput>;
  /** Any other value that exists and is not equal to the given value. */
  addon_not?: InputMaybe<Scalars['Boolean']>;
  admission_every?: InputMaybe<TextWhereInput>;
  admission_none?: InputMaybe<TextWhereInput>;
  admission_some?: InputMaybe<TextWhereInput>;
  affiliateId?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  affiliateId_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  affiliateId_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  affiliateId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  affiliateId_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  affiliateId_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  affiliateId_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  affiliateId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  affiliateId_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  affiliateId_starts_with?: InputMaybe<Scalars['String']>;
  ageAdult?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  ageAdult_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  ageAdult_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  ageAdult_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  ageAdult_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  ageAdult_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  ageAdult_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  ageAdult_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  ageAdult_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  ageAdult_starts_with?: InputMaybe<Scalars['String']>;
  ageChild?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  ageChild_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  ageChild_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  ageChild_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  ageChild_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  ageChild_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  ageChild_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  ageChild_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  ageChild_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  ageChild_starts_with?: InputMaybe<Scalars['String']>;
  alacarte?: InputMaybe<AttractionAlacarteWhereInput>;
  amenities_every?: InputMaybe<TextWhereInput>;
  amenities_none?: InputMaybe<TextWhereInput>;
  amenities_some?: InputMaybe<TextWhereInput>;
  attractionAddOn?: InputMaybe<AddOnWhereInput>;
  availability?: InputMaybe<AttractionAvailabilityWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<AttractionWhereStageInput>;
  documentInStages_none?: InputMaybe<AttractionWhereStageInput>;
  documentInStages_some?: InputMaybe<AttractionWhereStageInput>;
  exclusiveTo?: InputMaybe<ProductWhereInput>;
  /** All values in which the union is empty */
  gallery_empty?: InputMaybe<Scalars['Boolean']>;
  /** Matches if the union contains at least one connection to the provided item to the filter */
  gallery_some?: InputMaybe<AttractionGalleryWhereInput>;
  googlePlaceId?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  googlePlaceId_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  googlePlaceId_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  googlePlaceId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  googlePlaceId_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  googlePlaceId_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  googlePlaceId_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  googlePlaceId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  googlePlaceId_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  googlePlaceId_starts_with?: InputMaybe<Scalars['String']>;
  hero?: InputMaybe<AssetWhereInput>;
  highlights_every?: InputMaybe<TextWhereInput>;
  highlights_none?: InputMaybe<TextWhereInput>;
  highlights_some?: InputMaybe<TextWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  key?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  key_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  key_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  key_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  key_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  key_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  key_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  key_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  key_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  key_starts_with?: InputMaybe<Scalars['String']>;
  locations_every?: InputMaybe<AttractionLocationWhereInput>;
  locations_none?: InputMaybe<AttractionLocationWhereInput>;
  locations_some?: InputMaybe<AttractionLocationWhereInput>;
  logo?: InputMaybe<AssetWhereInput>;
  nextPrice?: InputMaybe<AttractionPriceWhereInput>;
  offers_every?: InputMaybe<AttractionOfferWhereInput>;
  offers_none?: InputMaybe<AttractionOfferWhereInput>;
  offers_some?: InputMaybe<AttractionOfferWhereInput>;
  openStatus?: InputMaybe<TextWhereInput>;
  partnerOffers_every?: InputMaybe<PartnerOfferWhereInput>;
  partnerOffers_none?: InputMaybe<PartnerOfferWhereInput>;
  partnerOffers_some?: InputMaybe<PartnerOfferWhereInput>;
  planning?: InputMaybe<DetailBlockWhereInput>;
  price?: InputMaybe<AttractionPriceWhereInput>;
  products_every?: InputMaybe<ProductWhereInput>;
  products_none?: InputMaybe<ProductWhereInput>;
  products_some?: InputMaybe<ProductWhereInput>;
  promos_every?: InputMaybe<PromoWhereInput>;
  promos_none?: InputMaybe<PromoWhereInput>;
  promos_some?: InputMaybe<PromoWhereInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  /** All values containing the given json path. */
  qabu_json_path_exists?: InputMaybe<Scalars['String']>;
  /**
   * Recursively tries to find the provided JSON scalar value inside the field.
   * It does use an exact match when comparing values.
   * If you pass `null` as value the filter will be ignored.
   * Note: This filter fails if you try to look for a non scalar JSON value!
   */
  qabu_value_recursive?: InputMaybe<Scalars['Json']>;
  reservation?: InputMaybe<AttractionReservationWhereInput>;
  safety_every?: InputMaybe<TextWhereInput>;
  safety_none?: InputMaybe<TextWhereInput>;
  safety_some?: InputMaybe<TextWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  seo?: InputMaybe<SeoWhereInput>;
  skuProperties_every?: InputMaybe<SkuPropertyWhereInput>;
  skuProperties_none?: InputMaybe<SkuPropertyWhereInput>;
  skuProperties_some?: InputMaybe<SkuPropertyWhereInput>;
  slug?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  slug_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  slug_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  slug_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  slug_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  slug_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  slug_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  slug_starts_with?: InputMaybe<Scalars['String']>;
  thumb?: InputMaybe<AssetWhereInput>;
  tips_every?: InputMaybe<TextWhereInput>;
  tips_none?: InputMaybe<TextWhereInput>;
  tips_some?: InputMaybe<TextWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
  variants_every?: InputMaybe<AttractionVariantWhereInput>;
  variants_none?: InputMaybe<AttractionVariantWhereInput>;
  variants_some?: InputMaybe<AttractionVariantWhereInput>;
};

export type AttractionOffer = Node & {
  __typename?: 'AttractionOffer';
  active: Maybe<Scalars['Boolean']>;
  attraction: Maybe<Attraction>;
  attractionVariants: Array<AttractionVariant>;
  code: Maybe<Scalars['String']>;
  conditions: Maybe<Scalars['String']>;
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** User that created this document */
  createdBy: Maybe<User>;
  description: Maybe<Scalars['String']>;
  /** Get the document in other stages */
  documentInStages: Array<AttractionOffer>;
  endDate: Maybe<Scalars['Date']>;
  expirationDate: Maybe<Scalars['Date']>;
  /** List of AttractionOffer versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID'];
  internalTitle: Scalars['String'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<AttractionOffer>;
  platforms: Array<Platforms>;
  products: Array<Product>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt: Maybe<Scalars['DateTime']>;
  /** User that last published this document */
  publishedBy: Maybe<User>;
  qabu: Maybe<Scalars['Json']>;
  scheduledIn: Array<ScheduledOperation>;
  /** System stage field */
  stage: Stage;
  startDate: Scalars['Date'];
  title: Maybe<Scalars['String']>;
  type: AttractionOfferType;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** User that last updated this document */
  updatedBy: Maybe<User>;
};

export type AttractionOfferAttractionArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type AttractionOfferAttractionVariantsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<AttractionVariantOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AttractionVariantWhereInput>;
};

export type AttractionOfferCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type AttractionOfferCreatedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type AttractionOfferDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};

export type AttractionOfferHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride: InputMaybe<Stage>;
};

export type AttractionOfferLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};

export type AttractionOfferProductsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<ProductOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ProductWhereInput>;
};

export type AttractionOfferPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type AttractionOfferPublishedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type AttractionOfferScheduledInArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ScheduledOperationWhereInput>;
};

export type AttractionOfferUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type AttractionOfferUpdatedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type AttractionOfferConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: AttractionOfferWhereUniqueInput;
};

/** A connection to a list of items. */
export type AttractionOfferConnection = {
  __typename?: 'AttractionOfferConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<AttractionOfferEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type AttractionOfferCreateInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  attraction?: InputMaybe<AttractionCreateOneInlineInput>;
  attractionVariants?: InputMaybe<AttractionVariantCreateManyInlineInput>;
  code?: InputMaybe<Scalars['String']>;
  /** conditions input for default locale (en) */
  conditions?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** description input for default locale (en) */
  description?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['Date']>;
  expirationDate?: InputMaybe<Scalars['Date']>;
  internalTitle: Scalars['String'];
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<AttractionOfferCreateLocalizationsInput>;
  platforms?: InputMaybe<Array<Platforms>>;
  products?: InputMaybe<ProductCreateManyInlineInput>;
  qabu?: InputMaybe<Scalars['Json']>;
  startDate: Scalars['Date'];
  /** title input for default locale (en) */
  title?: InputMaybe<Scalars['String']>;
  type: AttractionOfferType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type AttractionOfferCreateLocalizationDataInput = {
  conditions?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type AttractionOfferCreateLocalizationInput = {
  /** Localization input */
  data: AttractionOfferCreateLocalizationDataInput;
  locale: Locale;
};

export type AttractionOfferCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<AttractionOfferCreateLocalizationInput>>;
};

export type AttractionOfferCreateManyInlineInput = {
  /** Connect multiple existing AttractionOffer documents */
  connect?: InputMaybe<Array<AttractionOfferWhereUniqueInput>>;
  /** Create and connect multiple existing AttractionOffer documents */
  create?: InputMaybe<Array<AttractionOfferCreateInput>>;
};

export type AttractionOfferCreateOneInlineInput = {
  /** Connect one existing AttractionOffer document */
  connect?: InputMaybe<AttractionOfferWhereUniqueInput>;
  /** Create and connect one AttractionOffer document */
  create?: InputMaybe<AttractionOfferCreateInput>;
};

/** An edge in a connection. */
export type AttractionOfferEdge = {
  __typename?: 'AttractionOfferEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: AttractionOffer;
};

/** Identifies documents */
export type AttractionOfferManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AttractionOfferWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AttractionOfferWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AttractionOfferWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  active_not?: InputMaybe<Scalars['Boolean']>;
  attraction?: InputMaybe<AttractionWhereInput>;
  attractionVariants_every?: InputMaybe<AttractionVariantWhereInput>;
  attractionVariants_none?: InputMaybe<AttractionVariantWhereInput>;
  attractionVariants_some?: InputMaybe<AttractionVariantWhereInput>;
  code?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  code_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  code_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  code_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  code_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  code_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  code_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  code_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  code_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  code_starts_with?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<AttractionOfferWhereStageInput>;
  documentInStages_none?: InputMaybe<AttractionOfferWhereStageInput>;
  documentInStages_some?: InputMaybe<AttractionOfferWhereStageInput>;
  endDate?: InputMaybe<Scalars['Date']>;
  /** All values greater than the given value. */
  endDate_gt?: InputMaybe<Scalars['Date']>;
  /** All values greater than or equal the given value. */
  endDate_gte?: InputMaybe<Scalars['Date']>;
  /** All values that are contained in given list. */
  endDate_in?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  /** All values less than the given value. */
  endDate_lt?: InputMaybe<Scalars['Date']>;
  /** All values less than or equal the given value. */
  endDate_lte?: InputMaybe<Scalars['Date']>;
  /** Any other value that exists and is not equal to the given value. */
  endDate_not?: InputMaybe<Scalars['Date']>;
  /** All values that are not contained in given list. */
  endDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  expirationDate?: InputMaybe<Scalars['Date']>;
  /** All values greater than the given value. */
  expirationDate_gt?: InputMaybe<Scalars['Date']>;
  /** All values greater than or equal the given value. */
  expirationDate_gte?: InputMaybe<Scalars['Date']>;
  /** All values that are contained in given list. */
  expirationDate_in?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  /** All values less than the given value. */
  expirationDate_lt?: InputMaybe<Scalars['Date']>;
  /** All values less than or equal the given value. */
  expirationDate_lte?: InputMaybe<Scalars['Date']>;
  /** Any other value that exists and is not equal to the given value. */
  expirationDate_not?: InputMaybe<Scalars['Date']>;
  /** All values that are not contained in given list. */
  expirationDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  internalTitle?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  internalTitle_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  internalTitle_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  internalTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  internalTitle_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  internalTitle_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  internalTitle_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  internalTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  internalTitle_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  internalTitle_starts_with?: InputMaybe<Scalars['String']>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  platforms?: InputMaybe<Array<Platforms>>;
  /** Matches if the field array contains *all* items provided to the filter */
  platforms_contains_all?: InputMaybe<Array<Platforms>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  platforms_contains_none?: InputMaybe<Array<Platforms>>;
  /** Matches if the field array contains at least one item provided to the filter */
  platforms_contains_some?: InputMaybe<Array<Platforms>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  platforms_not?: InputMaybe<Array<Platforms>>;
  products_every?: InputMaybe<ProductWhereInput>;
  products_none?: InputMaybe<ProductWhereInput>;
  products_some?: InputMaybe<ProductWhereInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  /** All values containing the given json path. */
  qabu_json_path_exists?: InputMaybe<Scalars['String']>;
  /**
   * Recursively tries to find the provided JSON scalar value inside the field.
   * It does use an exact match when comparing values.
   * If you pass `null` as value the filter will be ignored.
   * Note: This filter fails if you try to look for a non scalar JSON value!
   */
  qabu_value_recursive?: InputMaybe<Scalars['Json']>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  startDate?: InputMaybe<Scalars['Date']>;
  /** All values greater than the given value. */
  startDate_gt?: InputMaybe<Scalars['Date']>;
  /** All values greater than or equal the given value. */
  startDate_gte?: InputMaybe<Scalars['Date']>;
  /** All values that are contained in given list. */
  startDate_in?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  /** All values less than the given value. */
  startDate_lt?: InputMaybe<Scalars['Date']>;
  /** All values less than or equal the given value. */
  startDate_lte?: InputMaybe<Scalars['Date']>;
  /** Any other value that exists and is not equal to the given value. */
  startDate_not?: InputMaybe<Scalars['Date']>;
  /** All values that are not contained in given list. */
  startDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  type?: InputMaybe<AttractionOfferType>;
  /** All values that are contained in given list. */
  type_in?: InputMaybe<Array<InputMaybe<AttractionOfferType>>>;
  /** Any other value that exists and is not equal to the given value. */
  type_not?: InputMaybe<AttractionOfferType>;
  /** All values that are not contained in given list. */
  type_not_in?: InputMaybe<Array<InputMaybe<AttractionOfferType>>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum AttractionOfferOrderByInput {
  ActiveAsc = 'active_ASC',
  ActiveDesc = 'active_DESC',
  CodeAsc = 'code_ASC',
  CodeDesc = 'code_DESC',
  ConditionsAsc = 'conditions_ASC',
  ConditionsDesc = 'conditions_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  EndDateAsc = 'endDate_ASC',
  EndDateDesc = 'endDate_DESC',
  ExpirationDateAsc = 'expirationDate_ASC',
  ExpirationDateDesc = 'expirationDate_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  InternalTitleAsc = 'internalTitle_ASC',
  InternalTitleDesc = 'internalTitle_DESC',
  PlatformsAsc = 'platforms_ASC',
  PlatformsDesc = 'platforms_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  StartDateAsc = 'startDate_ASC',
  StartDateDesc = 'startDate_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export enum AttractionOfferType {
  Dining = 'dining',
  Exclusive = 'exclusive',
  Retail = 'retail',
  Shared = 'shared',
  Upgrade = 'upgrade',
}

export type AttractionOfferUpdateInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  attraction?: InputMaybe<AttractionUpdateOneInlineInput>;
  attractionVariants?: InputMaybe<AttractionVariantUpdateManyInlineInput>;
  code?: InputMaybe<Scalars['String']>;
  /** conditions input for default locale (en) */
  conditions?: InputMaybe<Scalars['String']>;
  /** description input for default locale (en) */
  description?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['Date']>;
  expirationDate?: InputMaybe<Scalars['Date']>;
  internalTitle?: InputMaybe<Scalars['String']>;
  /** Manage document localizations */
  localizations?: InputMaybe<AttractionOfferUpdateLocalizationsInput>;
  platforms?: InputMaybe<Array<Platforms>>;
  products?: InputMaybe<ProductUpdateManyInlineInput>;
  qabu?: InputMaybe<Scalars['Json']>;
  startDate?: InputMaybe<Scalars['Date']>;
  /** title input for default locale (en) */
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<AttractionOfferType>;
};

export type AttractionOfferUpdateLocalizationDataInput = {
  conditions?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type AttractionOfferUpdateLocalizationInput = {
  data: AttractionOfferUpdateLocalizationDataInput;
  locale: Locale;
};

export type AttractionOfferUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<AttractionOfferCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<AttractionOfferUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<AttractionOfferUpsertLocalizationInput>>;
};

export type AttractionOfferUpdateManyInlineInput = {
  /** Connect multiple existing AttractionOffer documents */
  connect?: InputMaybe<Array<AttractionOfferConnectInput>>;
  /** Create and connect multiple AttractionOffer documents */
  create?: InputMaybe<Array<AttractionOfferCreateInput>>;
  /** Delete multiple AttractionOffer documents */
  delete?: InputMaybe<Array<AttractionOfferWhereUniqueInput>>;
  /** Disconnect multiple AttractionOffer documents */
  disconnect?: InputMaybe<Array<AttractionOfferWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing AttractionOffer documents */
  set?: InputMaybe<Array<AttractionOfferWhereUniqueInput>>;
  /** Update multiple AttractionOffer documents */
  update?: InputMaybe<Array<AttractionOfferUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple AttractionOffer documents */
  upsert?: InputMaybe<Array<AttractionOfferUpsertWithNestedWhereUniqueInput>>;
};

export type AttractionOfferUpdateManyInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  code?: InputMaybe<Scalars['String']>;
  /** conditions input for default locale (en) */
  conditions?: InputMaybe<Scalars['String']>;
  /** description input for default locale (en) */
  description?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['Date']>;
  expirationDate?: InputMaybe<Scalars['Date']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<AttractionOfferUpdateManyLocalizationsInput>;
  platforms?: InputMaybe<Array<Platforms>>;
  qabu?: InputMaybe<Scalars['Json']>;
  startDate?: InputMaybe<Scalars['Date']>;
  /** title input for default locale (en) */
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<AttractionOfferType>;
};

export type AttractionOfferUpdateManyLocalizationDataInput = {
  conditions?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type AttractionOfferUpdateManyLocalizationInput = {
  data: AttractionOfferUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type AttractionOfferUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<AttractionOfferUpdateManyLocalizationInput>>;
};

export type AttractionOfferUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: AttractionOfferUpdateManyInput;
  /** Document search */
  where: AttractionOfferWhereInput;
};

export type AttractionOfferUpdateOneInlineInput = {
  /** Connect existing AttractionOffer document */
  connect?: InputMaybe<AttractionOfferWhereUniqueInput>;
  /** Create and connect one AttractionOffer document */
  create?: InputMaybe<AttractionOfferCreateInput>;
  /** Delete currently connected AttractionOffer document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected AttractionOffer document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single AttractionOffer document */
  update?: InputMaybe<AttractionOfferUpdateWithNestedWhereUniqueInput>;
  /** Upsert single AttractionOffer document */
  upsert?: InputMaybe<AttractionOfferUpsertWithNestedWhereUniqueInput>;
};

export type AttractionOfferUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: AttractionOfferUpdateInput;
  /** Unique document search */
  where: AttractionOfferWhereUniqueInput;
};

export type AttractionOfferUpsertInput = {
  /** Create document if it didn't exist */
  create: AttractionOfferCreateInput;
  /** Update document if it exists */
  update: AttractionOfferUpdateInput;
};

export type AttractionOfferUpsertLocalizationInput = {
  create: AttractionOfferCreateLocalizationDataInput;
  locale: Locale;
  update: AttractionOfferUpdateLocalizationDataInput;
};

export type AttractionOfferUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: AttractionOfferUpsertInput;
  /** Unique document search */
  where: AttractionOfferWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type AttractionOfferWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']>;
};

/** Identifies documents */
export type AttractionOfferWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AttractionOfferWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AttractionOfferWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AttractionOfferWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  active_not?: InputMaybe<Scalars['Boolean']>;
  attraction?: InputMaybe<AttractionWhereInput>;
  attractionVariants_every?: InputMaybe<AttractionVariantWhereInput>;
  attractionVariants_none?: InputMaybe<AttractionVariantWhereInput>;
  attractionVariants_some?: InputMaybe<AttractionVariantWhereInput>;
  code?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  code_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  code_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  code_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  code_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  code_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  code_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  code_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  code_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  code_starts_with?: InputMaybe<Scalars['String']>;
  conditions?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  conditions_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  conditions_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  conditions_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  conditions_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  conditions_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  conditions_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  conditions_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  conditions_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  conditions_starts_with?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  description?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  description_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars['String']>;
  documentInStages_every?: InputMaybe<AttractionOfferWhereStageInput>;
  documentInStages_none?: InputMaybe<AttractionOfferWhereStageInput>;
  documentInStages_some?: InputMaybe<AttractionOfferWhereStageInput>;
  endDate?: InputMaybe<Scalars['Date']>;
  /** All values greater than the given value. */
  endDate_gt?: InputMaybe<Scalars['Date']>;
  /** All values greater than or equal the given value. */
  endDate_gte?: InputMaybe<Scalars['Date']>;
  /** All values that are contained in given list. */
  endDate_in?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  /** All values less than the given value. */
  endDate_lt?: InputMaybe<Scalars['Date']>;
  /** All values less than or equal the given value. */
  endDate_lte?: InputMaybe<Scalars['Date']>;
  /** Any other value that exists and is not equal to the given value. */
  endDate_not?: InputMaybe<Scalars['Date']>;
  /** All values that are not contained in given list. */
  endDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  expirationDate?: InputMaybe<Scalars['Date']>;
  /** All values greater than the given value. */
  expirationDate_gt?: InputMaybe<Scalars['Date']>;
  /** All values greater than or equal the given value. */
  expirationDate_gte?: InputMaybe<Scalars['Date']>;
  /** All values that are contained in given list. */
  expirationDate_in?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  /** All values less than the given value. */
  expirationDate_lt?: InputMaybe<Scalars['Date']>;
  /** All values less than or equal the given value. */
  expirationDate_lte?: InputMaybe<Scalars['Date']>;
  /** Any other value that exists and is not equal to the given value. */
  expirationDate_not?: InputMaybe<Scalars['Date']>;
  /** All values that are not contained in given list. */
  expirationDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  internalTitle?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  internalTitle_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  internalTitle_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  internalTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  internalTitle_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  internalTitle_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  internalTitle_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  internalTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  internalTitle_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  internalTitle_starts_with?: InputMaybe<Scalars['String']>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  platforms?: InputMaybe<Array<Platforms>>;
  /** Matches if the field array contains *all* items provided to the filter */
  platforms_contains_all?: InputMaybe<Array<Platforms>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  platforms_contains_none?: InputMaybe<Array<Platforms>>;
  /** Matches if the field array contains at least one item provided to the filter */
  platforms_contains_some?: InputMaybe<Array<Platforms>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  platforms_not?: InputMaybe<Array<Platforms>>;
  products_every?: InputMaybe<ProductWhereInput>;
  products_none?: InputMaybe<ProductWhereInput>;
  products_some?: InputMaybe<ProductWhereInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  /** All values containing the given json path. */
  qabu_json_path_exists?: InputMaybe<Scalars['String']>;
  /**
   * Recursively tries to find the provided JSON scalar value inside the field.
   * It does use an exact match when comparing values.
   * If you pass `null` as value the filter will be ignored.
   * Note: This filter fails if you try to look for a non scalar JSON value!
   */
  qabu_value_recursive?: InputMaybe<Scalars['Json']>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  startDate?: InputMaybe<Scalars['Date']>;
  /** All values greater than the given value. */
  startDate_gt?: InputMaybe<Scalars['Date']>;
  /** All values greater than or equal the given value. */
  startDate_gte?: InputMaybe<Scalars['Date']>;
  /** All values that are contained in given list. */
  startDate_in?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  /** All values less than the given value. */
  startDate_lt?: InputMaybe<Scalars['Date']>;
  /** All values less than or equal the given value. */
  startDate_lte?: InputMaybe<Scalars['Date']>;
  /** Any other value that exists and is not equal to the given value. */
  startDate_not?: InputMaybe<Scalars['Date']>;
  /** All values that are not contained in given list. */
  startDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  title?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<AttractionOfferType>;
  /** All values that are contained in given list. */
  type_in?: InputMaybe<Array<InputMaybe<AttractionOfferType>>>;
  /** Any other value that exists and is not equal to the given value. */
  type_not?: InputMaybe<AttractionOfferType>;
  /** All values that are not contained in given list. */
  type_not_in?: InputMaybe<Array<InputMaybe<AttractionOfferType>>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type AttractionOfferWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AttractionOfferWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AttractionOfferWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AttractionOfferWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<AttractionOfferWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References AttractionOffer record uniquely */
export type AttractionOfferWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
  internalTitle?: InputMaybe<Scalars['String']>;
};

export enum AttractionOrderByInput {
  AccessibilityAsc = 'accessibility_ASC',
  AccessibilityDesc = 'accessibility_DESC',
  AddonAsc = 'addon_ASC',
  AddonDesc = 'addon_DESC',
  AffiliateIdAsc = 'affiliateId_ASC',
  AffiliateIdDesc = 'affiliateId_DESC',
  AgeAdultAsc = 'ageAdult_ASC',
  AgeAdultDesc = 'ageAdult_DESC',
  AgeChildAsc = 'ageChild_ASC',
  AgeChildDesc = 'ageChild_DESC',
  BestTimeAsc = 'bestTime_ASC',
  BestTimeDesc = 'bestTime_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  ExclusiveFeatureAsc = 'exclusiveFeature_ASC',
  ExclusiveFeatureDesc = 'exclusiveFeature_DESC',
  GooglePlaceIdAsc = 'googlePlaceId_ASC',
  GooglePlaceIdDesc = 'googlePlaceId_DESC',
  HeroCreditAsc = 'heroCredit_ASC',
  HeroCreditDesc = 'heroCredit_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  IntroAsc = 'intro_ASC',
  IntroDesc = 'intro_DESC',
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  ParkingAsc = 'parking_ASC',
  ParkingDesc = 'parking_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  ShortIntroAsc = 'shortIntro_ASC',
  ShortIntroDesc = 'shortIntro_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type AttractionPrice = Node & {
  __typename?: 'AttractionPrice';
  adult: Maybe<Scalars['Float']>;
  attraction: Maybe<Attraction>;
  attractionNext: Maybe<Attraction>;
  attractionVariant: Maybe<AttractionVariant>;
  child: Maybe<Scalars['Float']>;
  childNote: Maybe<Scalars['String']>;
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** User that created this document */
  createdBy: Maybe<User>;
  /** Get the document in other stages */
  documentInStages: Array<AttractionPrice>;
  /** List of AttractionPrice versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID'];
  includesTax: Scalars['Boolean'];
  internalTitle: Scalars['String'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<AttractionPrice>;
  note: Maybe<Scalars['String']>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt: Maybe<Scalars['DateTime']>;
  /** User that last published this document */
  publishedBy: Maybe<User>;
  qabu: Maybe<Scalars['Json']>;
  scheduledIn: Array<ScheduledOperation>;
  /** System stage field */
  stage: Stage;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** User that last updated this document */
  updatedBy: Maybe<User>;
};

export type AttractionPriceAttractionArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type AttractionPriceAttractionNextArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type AttractionPriceAttractionVariantArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type AttractionPriceCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type AttractionPriceCreatedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type AttractionPriceDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};

export type AttractionPriceHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride: InputMaybe<Stage>;
};

export type AttractionPriceLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};

export type AttractionPricePublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type AttractionPricePublishedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type AttractionPriceScheduledInArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ScheduledOperationWhereInput>;
};

export type AttractionPriceUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type AttractionPriceUpdatedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type AttractionPriceConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: AttractionPriceWhereUniqueInput;
};

/** A connection to a list of items. */
export type AttractionPriceConnection = {
  __typename?: 'AttractionPriceConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<AttractionPriceEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type AttractionPriceCreateInput = {
  adult?: InputMaybe<Scalars['Float']>;
  attraction?: InputMaybe<AttractionCreateOneInlineInput>;
  attractionNext?: InputMaybe<AttractionCreateOneInlineInput>;
  attractionVariant?: InputMaybe<AttractionVariantCreateOneInlineInput>;
  child?: InputMaybe<Scalars['Float']>;
  /** childNote input for default locale (en) */
  childNote?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  includesTax: Scalars['Boolean'];
  internalTitle: Scalars['String'];
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<AttractionPriceCreateLocalizationsInput>;
  /** note input for default locale (en) */
  note?: InputMaybe<Scalars['String']>;
  qabu?: InputMaybe<Scalars['Json']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type AttractionPriceCreateLocalizationDataInput = {
  childNote?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  note?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type AttractionPriceCreateLocalizationInput = {
  /** Localization input */
  data: AttractionPriceCreateLocalizationDataInput;
  locale: Locale;
};

export type AttractionPriceCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<AttractionPriceCreateLocalizationInput>>;
};

export type AttractionPriceCreateManyInlineInput = {
  /** Connect multiple existing AttractionPrice documents */
  connect?: InputMaybe<Array<AttractionPriceWhereUniqueInput>>;
  /** Create and connect multiple existing AttractionPrice documents */
  create?: InputMaybe<Array<AttractionPriceCreateInput>>;
};

export type AttractionPriceCreateOneInlineInput = {
  /** Connect one existing AttractionPrice document */
  connect?: InputMaybe<AttractionPriceWhereUniqueInput>;
  /** Create and connect one AttractionPrice document */
  create?: InputMaybe<AttractionPriceCreateInput>;
};

/** An edge in a connection. */
export type AttractionPriceEdge = {
  __typename?: 'AttractionPriceEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: AttractionPrice;
};

/** Identifies documents */
export type AttractionPriceManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AttractionPriceWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AttractionPriceWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AttractionPriceWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  adult?: InputMaybe<Scalars['Float']>;
  /** All values greater than the given value. */
  adult_gt?: InputMaybe<Scalars['Float']>;
  /** All values greater than or equal the given value. */
  adult_gte?: InputMaybe<Scalars['Float']>;
  /** All values that are contained in given list. */
  adult_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  /** All values less than the given value. */
  adult_lt?: InputMaybe<Scalars['Float']>;
  /** All values less than or equal the given value. */
  adult_lte?: InputMaybe<Scalars['Float']>;
  /** Any other value that exists and is not equal to the given value. */
  adult_not?: InputMaybe<Scalars['Float']>;
  /** All values that are not contained in given list. */
  adult_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  attraction?: InputMaybe<AttractionWhereInput>;
  attractionNext?: InputMaybe<AttractionWhereInput>;
  attractionVariant?: InputMaybe<AttractionVariantWhereInput>;
  child?: InputMaybe<Scalars['Float']>;
  /** All values greater than the given value. */
  child_gt?: InputMaybe<Scalars['Float']>;
  /** All values greater than or equal the given value. */
  child_gte?: InputMaybe<Scalars['Float']>;
  /** All values that are contained in given list. */
  child_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  /** All values less than the given value. */
  child_lt?: InputMaybe<Scalars['Float']>;
  /** All values less than or equal the given value. */
  child_lte?: InputMaybe<Scalars['Float']>;
  /** Any other value that exists and is not equal to the given value. */
  child_not?: InputMaybe<Scalars['Float']>;
  /** All values that are not contained in given list. */
  child_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<AttractionPriceWhereStageInput>;
  documentInStages_none?: InputMaybe<AttractionPriceWhereStageInput>;
  documentInStages_some?: InputMaybe<AttractionPriceWhereStageInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  includesTax?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  includesTax_not?: InputMaybe<Scalars['Boolean']>;
  internalTitle?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  internalTitle_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  internalTitle_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  internalTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  internalTitle_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  internalTitle_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  internalTitle_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  internalTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  internalTitle_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  internalTitle_starts_with?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  /** All values containing the given json path. */
  qabu_json_path_exists?: InputMaybe<Scalars['String']>;
  /**
   * Recursively tries to find the provided JSON scalar value inside the field.
   * It does use an exact match when comparing values.
   * If you pass `null` as value the filter will be ignored.
   * Note: This filter fails if you try to look for a non scalar JSON value!
   */
  qabu_value_recursive?: InputMaybe<Scalars['Json']>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum AttractionPriceOrderByInput {
  AdultAsc = 'adult_ASC',
  AdultDesc = 'adult_DESC',
  ChildNoteAsc = 'childNote_ASC',
  ChildNoteDesc = 'childNote_DESC',
  ChildAsc = 'child_ASC',
  ChildDesc = 'child_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  IncludesTaxAsc = 'includesTax_ASC',
  IncludesTaxDesc = 'includesTax_DESC',
  InternalTitleAsc = 'internalTitle_ASC',
  InternalTitleDesc = 'internalTitle_DESC',
  NoteAsc = 'note_ASC',
  NoteDesc = 'note_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type AttractionPriceUpdateInput = {
  adult?: InputMaybe<Scalars['Float']>;
  attraction?: InputMaybe<AttractionUpdateOneInlineInput>;
  attractionNext?: InputMaybe<AttractionUpdateOneInlineInput>;
  attractionVariant?: InputMaybe<AttractionVariantUpdateOneInlineInput>;
  child?: InputMaybe<Scalars['Float']>;
  /** childNote input for default locale (en) */
  childNote?: InputMaybe<Scalars['String']>;
  includesTax?: InputMaybe<Scalars['Boolean']>;
  internalTitle?: InputMaybe<Scalars['String']>;
  /** Manage document localizations */
  localizations?: InputMaybe<AttractionPriceUpdateLocalizationsInput>;
  /** note input for default locale (en) */
  note?: InputMaybe<Scalars['String']>;
  qabu?: InputMaybe<Scalars['Json']>;
};

export type AttractionPriceUpdateLocalizationDataInput = {
  childNote?: InputMaybe<Scalars['String']>;
  note?: InputMaybe<Scalars['String']>;
};

export type AttractionPriceUpdateLocalizationInput = {
  data: AttractionPriceUpdateLocalizationDataInput;
  locale: Locale;
};

export type AttractionPriceUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<AttractionPriceCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<AttractionPriceUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<AttractionPriceUpsertLocalizationInput>>;
};

export type AttractionPriceUpdateManyInlineInput = {
  /** Connect multiple existing AttractionPrice documents */
  connect?: InputMaybe<Array<AttractionPriceConnectInput>>;
  /** Create and connect multiple AttractionPrice documents */
  create?: InputMaybe<Array<AttractionPriceCreateInput>>;
  /** Delete multiple AttractionPrice documents */
  delete?: InputMaybe<Array<AttractionPriceWhereUniqueInput>>;
  /** Disconnect multiple AttractionPrice documents */
  disconnect?: InputMaybe<Array<AttractionPriceWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing AttractionPrice documents */
  set?: InputMaybe<Array<AttractionPriceWhereUniqueInput>>;
  /** Update multiple AttractionPrice documents */
  update?: InputMaybe<Array<AttractionPriceUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple AttractionPrice documents */
  upsert?: InputMaybe<Array<AttractionPriceUpsertWithNestedWhereUniqueInput>>;
};

export type AttractionPriceUpdateManyInput = {
  adult?: InputMaybe<Scalars['Float']>;
  child?: InputMaybe<Scalars['Float']>;
  /** childNote input for default locale (en) */
  childNote?: InputMaybe<Scalars['String']>;
  includesTax?: InputMaybe<Scalars['Boolean']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<AttractionPriceUpdateManyLocalizationsInput>;
  /** note input for default locale (en) */
  note?: InputMaybe<Scalars['String']>;
  qabu?: InputMaybe<Scalars['Json']>;
};

export type AttractionPriceUpdateManyLocalizationDataInput = {
  childNote?: InputMaybe<Scalars['String']>;
  note?: InputMaybe<Scalars['String']>;
};

export type AttractionPriceUpdateManyLocalizationInput = {
  data: AttractionPriceUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type AttractionPriceUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<AttractionPriceUpdateManyLocalizationInput>>;
};

export type AttractionPriceUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: AttractionPriceUpdateManyInput;
  /** Document search */
  where: AttractionPriceWhereInput;
};

export type AttractionPriceUpdateOneInlineInput = {
  /** Connect existing AttractionPrice document */
  connect?: InputMaybe<AttractionPriceWhereUniqueInput>;
  /** Create and connect one AttractionPrice document */
  create?: InputMaybe<AttractionPriceCreateInput>;
  /** Delete currently connected AttractionPrice document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected AttractionPrice document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single AttractionPrice document */
  update?: InputMaybe<AttractionPriceUpdateWithNestedWhereUniqueInput>;
  /** Upsert single AttractionPrice document */
  upsert?: InputMaybe<AttractionPriceUpsertWithNestedWhereUniqueInput>;
};

export type AttractionPriceUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: AttractionPriceUpdateInput;
  /** Unique document search */
  where: AttractionPriceWhereUniqueInput;
};

export type AttractionPriceUpsertInput = {
  /** Create document if it didn't exist */
  create: AttractionPriceCreateInput;
  /** Update document if it exists */
  update: AttractionPriceUpdateInput;
};

export type AttractionPriceUpsertLocalizationInput = {
  create: AttractionPriceCreateLocalizationDataInput;
  locale: Locale;
  update: AttractionPriceUpdateLocalizationDataInput;
};

export type AttractionPriceUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: AttractionPriceUpsertInput;
  /** Unique document search */
  where: AttractionPriceWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type AttractionPriceWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']>;
};

/** Identifies documents */
export type AttractionPriceWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AttractionPriceWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AttractionPriceWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AttractionPriceWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  adult?: InputMaybe<Scalars['Float']>;
  /** All values greater than the given value. */
  adult_gt?: InputMaybe<Scalars['Float']>;
  /** All values greater than or equal the given value. */
  adult_gte?: InputMaybe<Scalars['Float']>;
  /** All values that are contained in given list. */
  adult_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  /** All values less than the given value. */
  adult_lt?: InputMaybe<Scalars['Float']>;
  /** All values less than or equal the given value. */
  adult_lte?: InputMaybe<Scalars['Float']>;
  /** Any other value that exists and is not equal to the given value. */
  adult_not?: InputMaybe<Scalars['Float']>;
  /** All values that are not contained in given list. */
  adult_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  attraction?: InputMaybe<AttractionWhereInput>;
  attractionNext?: InputMaybe<AttractionWhereInput>;
  attractionVariant?: InputMaybe<AttractionVariantWhereInput>;
  child?: InputMaybe<Scalars['Float']>;
  childNote?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  childNote_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  childNote_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  childNote_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  childNote_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  childNote_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  childNote_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  childNote_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  childNote_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  childNote_starts_with?: InputMaybe<Scalars['String']>;
  /** All values greater than the given value. */
  child_gt?: InputMaybe<Scalars['Float']>;
  /** All values greater than or equal the given value. */
  child_gte?: InputMaybe<Scalars['Float']>;
  /** All values that are contained in given list. */
  child_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  /** All values less than the given value. */
  child_lt?: InputMaybe<Scalars['Float']>;
  /** All values less than or equal the given value. */
  child_lte?: InputMaybe<Scalars['Float']>;
  /** Any other value that exists and is not equal to the given value. */
  child_not?: InputMaybe<Scalars['Float']>;
  /** All values that are not contained in given list. */
  child_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<AttractionPriceWhereStageInput>;
  documentInStages_none?: InputMaybe<AttractionPriceWhereStageInput>;
  documentInStages_some?: InputMaybe<AttractionPriceWhereStageInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  includesTax?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  includesTax_not?: InputMaybe<Scalars['Boolean']>;
  internalTitle?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  internalTitle_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  internalTitle_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  internalTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  internalTitle_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  internalTitle_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  internalTitle_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  internalTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  internalTitle_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  internalTitle_starts_with?: InputMaybe<Scalars['String']>;
  note?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  note_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  note_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  note_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  note_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  note_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  note_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  note_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  note_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  note_starts_with?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  /** All values containing the given json path. */
  qabu_json_path_exists?: InputMaybe<Scalars['String']>;
  /**
   * Recursively tries to find the provided JSON scalar value inside the field.
   * It does use an exact match when comparing values.
   * If you pass `null` as value the filter will be ignored.
   * Note: This filter fails if you try to look for a non scalar JSON value!
   */
  qabu_value_recursive?: InputMaybe<Scalars['Json']>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type AttractionPriceWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AttractionPriceWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AttractionPriceWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AttractionPriceWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<AttractionPriceWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References AttractionPrice record uniquely */
export type AttractionPriceWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
  internalTitle?: InputMaybe<Scalars['String']>;
};

export type AttractionReservation = Node & {
  __typename?: 'AttractionReservation';
  attraction: Maybe<Attraction>;
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** User that created this document */
  createdBy: Maybe<User>;
  /** Get the document in other stages */
  documentInStages: Array<AttractionReservation>;
  /** List of AttractionReservation versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID'];
  internalTitle: Maybe<Scalars['String']>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt: Maybe<Scalars['DateTime']>;
  /** User that last published this document */
  publishedBy: Maybe<User>;
  scheduledIn: Array<ScheduledOperation>;
  showAttractionClosed: Maybe<Scalars['Boolean']>;
  showPhoneButton: Maybe<Scalars['Boolean']>;
  showReservationButton: Maybe<Scalars['Boolean']>;
  showReservationIndicator: Maybe<Scalars['Boolean']>;
  /** System stage field */
  stage: Stage;
  /**
   * Valid keys include:
   * entry
   * reservation_required
   * reservation_not_required
   */
  text: Array<ReservationText>;
  texts: Array<Text>;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** User that last updated this document */
  updatedBy: Maybe<User>;
};

export type AttractionReservationAttractionArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type AttractionReservationCreatedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type AttractionReservationDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};

export type AttractionReservationHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride: InputMaybe<Stage>;
};

export type AttractionReservationPublishedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type AttractionReservationScheduledInArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ScheduledOperationWhereInput>;
};

export type AttractionReservationTextArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<ReservationTextOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ReservationTextWhereInput>;
};

export type AttractionReservationTextsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<TextOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<TextWhereInput>;
};

export type AttractionReservationUpdatedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type AttractionReservationConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: AttractionReservationWhereUniqueInput;
};

/** A connection to a list of items. */
export type AttractionReservationConnection = {
  __typename?: 'AttractionReservationConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<AttractionReservationEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type AttractionReservationCreateInput = {
  attraction?: InputMaybe<AttractionCreateOneInlineInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  internalTitle?: InputMaybe<Scalars['String']>;
  showAttractionClosed?: InputMaybe<Scalars['Boolean']>;
  showPhoneButton?: InputMaybe<Scalars['Boolean']>;
  showReservationButton?: InputMaybe<Scalars['Boolean']>;
  showReservationIndicator?: InputMaybe<Scalars['Boolean']>;
  text?: InputMaybe<ReservationTextCreateManyInlineInput>;
  texts?: InputMaybe<TextCreateManyInlineInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type AttractionReservationCreateManyInlineInput = {
  /** Connect multiple existing AttractionReservation documents */
  connect?: InputMaybe<Array<AttractionReservationWhereUniqueInput>>;
  /** Create and connect multiple existing AttractionReservation documents */
  create?: InputMaybe<Array<AttractionReservationCreateInput>>;
};

export type AttractionReservationCreateOneInlineInput = {
  /** Connect one existing AttractionReservation document */
  connect?: InputMaybe<AttractionReservationWhereUniqueInput>;
  /** Create and connect one AttractionReservation document */
  create?: InputMaybe<AttractionReservationCreateInput>;
};

/** An edge in a connection. */
export type AttractionReservationEdge = {
  __typename?: 'AttractionReservationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: AttractionReservation;
};

/** Identifies documents */
export type AttractionReservationManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AttractionReservationWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AttractionReservationWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AttractionReservationWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  attraction?: InputMaybe<AttractionWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<AttractionReservationWhereStageInput>;
  documentInStages_none?: InputMaybe<AttractionReservationWhereStageInput>;
  documentInStages_some?: InputMaybe<AttractionReservationWhereStageInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  internalTitle?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  internalTitle_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  internalTitle_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  internalTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  internalTitle_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  internalTitle_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  internalTitle_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  internalTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  internalTitle_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  internalTitle_starts_with?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  showAttractionClosed?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  showAttractionClosed_not?: InputMaybe<Scalars['Boolean']>;
  showPhoneButton?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  showPhoneButton_not?: InputMaybe<Scalars['Boolean']>;
  showReservationButton?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  showReservationButton_not?: InputMaybe<Scalars['Boolean']>;
  showReservationIndicator?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  showReservationIndicator_not?: InputMaybe<Scalars['Boolean']>;
  text_every?: InputMaybe<ReservationTextWhereInput>;
  text_none?: InputMaybe<ReservationTextWhereInput>;
  text_some?: InputMaybe<ReservationTextWhereInput>;
  texts_every?: InputMaybe<TextWhereInput>;
  texts_none?: InputMaybe<TextWhereInput>;
  texts_some?: InputMaybe<TextWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum AttractionReservationOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  InternalTitleAsc = 'internalTitle_ASC',
  InternalTitleDesc = 'internalTitle_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  ShowAttractionClosedAsc = 'showAttractionClosed_ASC',
  ShowAttractionClosedDesc = 'showAttractionClosed_DESC',
  ShowPhoneButtonAsc = 'showPhoneButton_ASC',
  ShowPhoneButtonDesc = 'showPhoneButton_DESC',
  ShowReservationButtonAsc = 'showReservationButton_ASC',
  ShowReservationButtonDesc = 'showReservationButton_DESC',
  ShowReservationIndicatorAsc = 'showReservationIndicator_ASC',
  ShowReservationIndicatorDesc = 'showReservationIndicator_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type AttractionReservationUpdateInput = {
  attraction?: InputMaybe<AttractionUpdateOneInlineInput>;
  internalTitle?: InputMaybe<Scalars['String']>;
  showAttractionClosed?: InputMaybe<Scalars['Boolean']>;
  showPhoneButton?: InputMaybe<Scalars['Boolean']>;
  showReservationButton?: InputMaybe<Scalars['Boolean']>;
  showReservationIndicator?: InputMaybe<Scalars['Boolean']>;
  text?: InputMaybe<ReservationTextUpdateManyInlineInput>;
  texts?: InputMaybe<TextUpdateManyInlineInput>;
};

export type AttractionReservationUpdateManyInlineInput = {
  /** Connect multiple existing AttractionReservation documents */
  connect?: InputMaybe<Array<AttractionReservationConnectInput>>;
  /** Create and connect multiple AttractionReservation documents */
  create?: InputMaybe<Array<AttractionReservationCreateInput>>;
  /** Delete multiple AttractionReservation documents */
  delete?: InputMaybe<Array<AttractionReservationWhereUniqueInput>>;
  /** Disconnect multiple AttractionReservation documents */
  disconnect?: InputMaybe<Array<AttractionReservationWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing AttractionReservation documents */
  set?: InputMaybe<Array<AttractionReservationWhereUniqueInput>>;
  /** Update multiple AttractionReservation documents */
  update?: InputMaybe<Array<AttractionReservationUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple AttractionReservation documents */
  upsert?: InputMaybe<Array<AttractionReservationUpsertWithNestedWhereUniqueInput>>;
};

export type AttractionReservationUpdateManyInput = {
  internalTitle?: InputMaybe<Scalars['String']>;
  showAttractionClosed?: InputMaybe<Scalars['Boolean']>;
  showPhoneButton?: InputMaybe<Scalars['Boolean']>;
  showReservationButton?: InputMaybe<Scalars['Boolean']>;
  showReservationIndicator?: InputMaybe<Scalars['Boolean']>;
};

export type AttractionReservationUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: AttractionReservationUpdateManyInput;
  /** Document search */
  where: AttractionReservationWhereInput;
};

export type AttractionReservationUpdateOneInlineInput = {
  /** Connect existing AttractionReservation document */
  connect?: InputMaybe<AttractionReservationWhereUniqueInput>;
  /** Create and connect one AttractionReservation document */
  create?: InputMaybe<AttractionReservationCreateInput>;
  /** Delete currently connected AttractionReservation document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected AttractionReservation document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single AttractionReservation document */
  update?: InputMaybe<AttractionReservationUpdateWithNestedWhereUniqueInput>;
  /** Upsert single AttractionReservation document */
  upsert?: InputMaybe<AttractionReservationUpsertWithNestedWhereUniqueInput>;
};

export type AttractionReservationUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: AttractionReservationUpdateInput;
  /** Unique document search */
  where: AttractionReservationWhereUniqueInput;
};

export type AttractionReservationUpsertInput = {
  /** Create document if it didn't exist */
  create: AttractionReservationCreateInput;
  /** Update document if it exists */
  update: AttractionReservationUpdateInput;
};

export type AttractionReservationUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: AttractionReservationUpsertInput;
  /** Unique document search */
  where: AttractionReservationWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type AttractionReservationWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']>;
};

/** Identifies documents */
export type AttractionReservationWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AttractionReservationWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AttractionReservationWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AttractionReservationWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  attraction?: InputMaybe<AttractionWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<AttractionReservationWhereStageInput>;
  documentInStages_none?: InputMaybe<AttractionReservationWhereStageInput>;
  documentInStages_some?: InputMaybe<AttractionReservationWhereStageInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  internalTitle?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  internalTitle_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  internalTitle_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  internalTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  internalTitle_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  internalTitle_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  internalTitle_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  internalTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  internalTitle_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  internalTitle_starts_with?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  showAttractionClosed?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  showAttractionClosed_not?: InputMaybe<Scalars['Boolean']>;
  showPhoneButton?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  showPhoneButton_not?: InputMaybe<Scalars['Boolean']>;
  showReservationButton?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  showReservationButton_not?: InputMaybe<Scalars['Boolean']>;
  showReservationIndicator?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  showReservationIndicator_not?: InputMaybe<Scalars['Boolean']>;
  text_every?: InputMaybe<ReservationTextWhereInput>;
  text_none?: InputMaybe<ReservationTextWhereInput>;
  text_some?: InputMaybe<ReservationTextWhereInput>;
  texts_every?: InputMaybe<TextWhereInput>;
  texts_none?: InputMaybe<TextWhereInput>;
  texts_some?: InputMaybe<TextWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type AttractionReservationWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AttractionReservationWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AttractionReservationWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AttractionReservationWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<AttractionReservationWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References AttractionReservation record uniquely */
export type AttractionReservationWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type AttractionUpdateInput = {
  /** accessibility input for default locale (en) */
  accessibility?: InputMaybe<Scalars['String']>;
  addon?: InputMaybe<Scalars['Boolean']>;
  addonAttractionProducts?: InputMaybe<ProductUpdateManyInlineInput>;
  admission?: InputMaybe<TextUpdateManyInlineInput>;
  affiliateId?: InputMaybe<Scalars['String']>;
  ageAdult?: InputMaybe<Scalars['String']>;
  ageChild?: InputMaybe<Scalars['String']>;
  alacarte?: InputMaybe<AttractionAlacarteUpdateOneInlineInput>;
  amenities?: InputMaybe<TextUpdateManyInlineInput>;
  attractionAddOn?: InputMaybe<AddOnUpdateOneInlineInput>;
  availability?: InputMaybe<AttractionAvailabilityUpdateOneInlineInput>;
  /** bestTime input for default locale (en) */
  bestTime?: InputMaybe<Scalars['String']>;
  ckx0w0ikl1a5j01xq50lngfsk?: InputMaybe<NoticeUpdateManyInlineInput>;
  cl2j6ef1o0bt701xh589e0zd8?: InputMaybe<ProductReservationUpdateManyInlineInput>;
  cl2jgb55t0p5e01z95bx28lb7?: InputMaybe<AttractionGroupUpdateManyInlineInput>;
  /** exclusiveFeature input for default locale (en) */
  exclusiveFeature?: InputMaybe<Scalars['String']>;
  exclusiveTo?: InputMaybe<ProductUpdateOneInlineInput>;
  gallery?: InputMaybe<AttractionGalleryUpdateManyInlineInput>;
  googlePlaceId?: InputMaybe<Scalars['String']>;
  hero?: InputMaybe<AssetUpdateOneInlineInput>;
  /** heroCredit input for default locale (en) */
  heroCredit?: InputMaybe<Scalars['String']>;
  highlights?: InputMaybe<TextUpdateManyInlineInput>;
  /** intro input for default locale (en) */
  intro?: InputMaybe<Scalars['String']>;
  key?: InputMaybe<Scalars['String']>;
  /** Manage document localizations */
  localizations?: InputMaybe<AttractionUpdateLocalizationsInput>;
  locations?: InputMaybe<AttractionLocationUpdateManyInlineInput>;
  logo?: InputMaybe<AssetUpdateOneInlineInput>;
  /** name input for default locale (en) */
  name?: InputMaybe<Scalars['String']>;
  nextPrice?: InputMaybe<AttractionPriceUpdateOneInlineInput>;
  offers?: InputMaybe<AttractionOfferUpdateManyInlineInput>;
  openStatus?: InputMaybe<TextUpdateOneInlineInput>;
  /** parking input for default locale (en) */
  parking?: InputMaybe<Scalars['String']>;
  partnerOffers?: InputMaybe<PartnerOfferUpdateManyInlineInput>;
  planning?: InputMaybe<DetailBlockUpdateOneInlineInput>;
  price?: InputMaybe<AttractionPriceUpdateOneInlineInput>;
  products?: InputMaybe<ProductUpdateManyInlineInput>;
  promos?: InputMaybe<PromoUpdateManyInlineInput>;
  qabu?: InputMaybe<Scalars['Json']>;
  reservation?: InputMaybe<AttractionReservationUpdateOneInlineInput>;
  safety?: InputMaybe<TextUpdateManyInlineInput>;
  seo?: InputMaybe<SeoUpdateOneInlineInput>;
  /** shortIntro input for default locale (en) */
  shortIntro?: InputMaybe<Scalars['String']>;
  skuProperties?: InputMaybe<SkuPropertyUpdateManyInlineInput>;
  slug?: InputMaybe<Scalars['String']>;
  thumb?: InputMaybe<AssetUpdateOneInlineInput>;
  tips?: InputMaybe<TextUpdateManyInlineInput>;
  variants?: InputMaybe<AttractionVariantUpdateManyInlineInput>;
};

export type AttractionUpdateLocalizationDataInput = {
  accessibility?: InputMaybe<Scalars['String']>;
  bestTime?: InputMaybe<Scalars['String']>;
  exclusiveFeature?: InputMaybe<Scalars['String']>;
  heroCredit?: InputMaybe<Scalars['String']>;
  intro?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  parking?: InputMaybe<Scalars['String']>;
  shortIntro?: InputMaybe<Scalars['String']>;
};

export type AttractionUpdateLocalizationInput = {
  data: AttractionUpdateLocalizationDataInput;
  locale: Locale;
};

export type AttractionUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<AttractionCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<AttractionUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<AttractionUpsertLocalizationInput>>;
};

export type AttractionUpdateManyInlineInput = {
  /** Connect multiple existing Attraction documents */
  connect?: InputMaybe<Array<AttractionConnectInput>>;
  /** Create and connect multiple Attraction documents */
  create?: InputMaybe<Array<AttractionCreateInput>>;
  /** Delete multiple Attraction documents */
  delete?: InputMaybe<Array<AttractionWhereUniqueInput>>;
  /** Disconnect multiple Attraction documents */
  disconnect?: InputMaybe<Array<AttractionWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing Attraction documents */
  set?: InputMaybe<Array<AttractionWhereUniqueInput>>;
  /** Update multiple Attraction documents */
  update?: InputMaybe<Array<AttractionUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple Attraction documents */
  upsert?: InputMaybe<Array<AttractionUpsertWithNestedWhereUniqueInput>>;
};

export type AttractionUpdateManyInput = {
  /** accessibility input for default locale (en) */
  accessibility?: InputMaybe<Scalars['String']>;
  addon?: InputMaybe<Scalars['Boolean']>;
  affiliateId?: InputMaybe<Scalars['String']>;
  ageAdult?: InputMaybe<Scalars['String']>;
  ageChild?: InputMaybe<Scalars['String']>;
  /** bestTime input for default locale (en) */
  bestTime?: InputMaybe<Scalars['String']>;
  /** exclusiveFeature input for default locale (en) */
  exclusiveFeature?: InputMaybe<Scalars['String']>;
  googlePlaceId?: InputMaybe<Scalars['String']>;
  /** heroCredit input for default locale (en) */
  heroCredit?: InputMaybe<Scalars['String']>;
  /** intro input for default locale (en) */
  intro?: InputMaybe<Scalars['String']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<AttractionUpdateManyLocalizationsInput>;
  /** name input for default locale (en) */
  name?: InputMaybe<Scalars['String']>;
  /** parking input for default locale (en) */
  parking?: InputMaybe<Scalars['String']>;
  qabu?: InputMaybe<Scalars['Json']>;
  /** shortIntro input for default locale (en) */
  shortIntro?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
};

export type AttractionUpdateManyLocalizationDataInput = {
  accessibility?: InputMaybe<Scalars['String']>;
  bestTime?: InputMaybe<Scalars['String']>;
  exclusiveFeature?: InputMaybe<Scalars['String']>;
  heroCredit?: InputMaybe<Scalars['String']>;
  intro?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  parking?: InputMaybe<Scalars['String']>;
  shortIntro?: InputMaybe<Scalars['String']>;
};

export type AttractionUpdateManyLocalizationInput = {
  data: AttractionUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type AttractionUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<AttractionUpdateManyLocalizationInput>>;
};

export type AttractionUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: AttractionUpdateManyInput;
  /** Document search */
  where: AttractionWhereInput;
};

export type AttractionUpdateOneInlineInput = {
  /** Connect existing Attraction document */
  connect?: InputMaybe<AttractionWhereUniqueInput>;
  /** Create and connect one Attraction document */
  create?: InputMaybe<AttractionCreateInput>;
  /** Delete currently connected Attraction document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected Attraction document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single Attraction document */
  update?: InputMaybe<AttractionUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Attraction document */
  upsert?: InputMaybe<AttractionUpsertWithNestedWhereUniqueInput>;
};

export type AttractionUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: AttractionUpdateInput;
  /** Unique document search */
  where: AttractionWhereUniqueInput;
};

export type AttractionUpsertInput = {
  /** Create document if it didn't exist */
  create: AttractionCreateInput;
  /** Update document if it exists */
  update: AttractionUpdateInput;
};

export type AttractionUpsertLocalizationInput = {
  create: AttractionCreateLocalizationDataInput;
  locale: Locale;
  update: AttractionUpdateLocalizationDataInput;
};

export type AttractionUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: AttractionUpsertInput;
  /** Unique document search */
  where: AttractionWhereUniqueInput;
};

export type AttractionVariant = Node & {
  __typename?: 'AttractionVariant';
  admission: Array<Text>;
  attraction: Maybe<Attraction>;
  availability: Maybe<AttractionAvailability>;
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** User that created this document */
  createdBy: Maybe<User>;
  /** Get the document in other stages */
  documentInStages: Array<AttractionVariant>;
  exclusiveFeature: Maybe<Scalars['String']>;
  gallery: Array<AttractionVariantGallery>;
  highlights: Array<Text>;
  /** List of AttractionVariant versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID'];
  internalTitle: Scalars['String'];
  intro: Maybe<Scalars['String']>;
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<AttractionVariant>;
  name: Maybe<Scalars['String']>;
  offers: Array<AttractionOffer>;
  openStatus: Maybe<Text>;
  price: Maybe<AttractionPrice>;
  product: Maybe<Product>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt: Maybe<Scalars['DateTime']>;
  /** User that last published this document */
  publishedBy: Maybe<User>;
  qabu: Maybe<Scalars['Json']>;
  scheduledIn: Array<ScheduledOperation>;
  shortIntro: Maybe<Scalars['String']>;
  /** System stage field */
  stage: Stage;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** User that last updated this document */
  updatedBy: Maybe<User>;
};

export type AttractionVariantAdmissionArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<TextOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<TextWhereInput>;
};

export type AttractionVariantAttractionArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type AttractionVariantAvailabilityArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type AttractionVariantCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type AttractionVariantCreatedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type AttractionVariantDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};

export type AttractionVariantGalleryArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  skip: InputMaybe<Scalars['Int']>;
};

export type AttractionVariantHighlightsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<TextOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<TextWhereInput>;
};

export type AttractionVariantHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride: InputMaybe<Stage>;
};

export type AttractionVariantLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};

export type AttractionVariantOffersArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<AttractionOfferOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AttractionOfferWhereInput>;
};

export type AttractionVariantOpenStatusArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type AttractionVariantPriceArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type AttractionVariantProductArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type AttractionVariantPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type AttractionVariantPublishedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type AttractionVariantScheduledInArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ScheduledOperationWhereInput>;
};

export type AttractionVariantUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type AttractionVariantUpdatedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type AttractionVariantConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: AttractionVariantWhereUniqueInput;
};

/** A connection to a list of items. */
export type AttractionVariantConnection = {
  __typename?: 'AttractionVariantConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<AttractionVariantEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type AttractionVariantCreateInput = {
  admission?: InputMaybe<TextCreateManyInlineInput>;
  attraction?: InputMaybe<AttractionCreateOneInlineInput>;
  availability?: InputMaybe<AttractionAvailabilityCreateOneInlineInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** exclusiveFeature input for default locale (en) */
  exclusiveFeature?: InputMaybe<Scalars['String']>;
  gallery?: InputMaybe<AttractionVariantGalleryCreateManyInlineInput>;
  highlights?: InputMaybe<TextCreateManyInlineInput>;
  internalTitle: Scalars['String'];
  /** intro input for default locale (en) */
  intro?: InputMaybe<Scalars['String']>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<AttractionVariantCreateLocalizationsInput>;
  /** name input for default locale (en) */
  name?: InputMaybe<Scalars['String']>;
  offers?: InputMaybe<AttractionOfferCreateManyInlineInput>;
  openStatus?: InputMaybe<TextCreateOneInlineInput>;
  price?: InputMaybe<AttractionPriceCreateOneInlineInput>;
  product?: InputMaybe<ProductCreateOneInlineInput>;
  qabu?: InputMaybe<Scalars['Json']>;
  /** shortIntro input for default locale (en) */
  shortIntro?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type AttractionVariantCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  exclusiveFeature?: InputMaybe<Scalars['String']>;
  intro?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  shortIntro?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type AttractionVariantCreateLocalizationInput = {
  /** Localization input */
  data: AttractionVariantCreateLocalizationDataInput;
  locale: Locale;
};

export type AttractionVariantCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<AttractionVariantCreateLocalizationInput>>;
};

export type AttractionVariantCreateManyInlineInput = {
  /** Connect multiple existing AttractionVariant documents */
  connect?: InputMaybe<Array<AttractionVariantWhereUniqueInput>>;
  /** Create and connect multiple existing AttractionVariant documents */
  create?: InputMaybe<Array<AttractionVariantCreateInput>>;
};

export type AttractionVariantCreateOneInlineInput = {
  /** Connect one existing AttractionVariant document */
  connect?: InputMaybe<AttractionVariantWhereUniqueInput>;
  /** Create and connect one AttractionVariant document */
  create?: InputMaybe<AttractionVariantCreateInput>;
};

/** An edge in a connection. */
export type AttractionVariantEdge = {
  __typename?: 'AttractionVariantEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: AttractionVariant;
};

export type AttractionVariantGallery = Image | Video;

export type AttractionVariantGalleryConnectInput = {
  Image?: InputMaybe<ImageConnectInput>;
  Video?: InputMaybe<VideoConnectInput>;
};

export type AttractionVariantGalleryCreateInput = {
  Image?: InputMaybe<ImageCreateInput>;
  Video?: InputMaybe<VideoCreateInput>;
};

export type AttractionVariantGalleryCreateManyInlineInput = {
  /** Connect multiple existing AttractionVariantGallery documents */
  connect?: InputMaybe<Array<AttractionVariantGalleryWhereUniqueInput>>;
  /** Create and connect multiple existing AttractionVariantGallery documents */
  create?: InputMaybe<Array<AttractionVariantGalleryCreateInput>>;
};

export type AttractionVariantGalleryCreateOneInlineInput = {
  /** Connect one existing AttractionVariantGallery document */
  connect?: InputMaybe<AttractionVariantGalleryWhereUniqueInput>;
  /** Create and connect one AttractionVariantGallery document */
  create?: InputMaybe<AttractionVariantGalleryCreateInput>;
};

export type AttractionVariantGalleryUpdateInput = {
  Image?: InputMaybe<ImageUpdateInput>;
  Video?: InputMaybe<VideoUpdateInput>;
};

export type AttractionVariantGalleryUpdateManyInlineInput = {
  /** Connect multiple existing AttractionVariantGallery documents */
  connect?: InputMaybe<Array<AttractionVariantGalleryConnectInput>>;
  /** Create and connect multiple AttractionVariantGallery documents */
  create?: InputMaybe<Array<AttractionVariantGalleryCreateInput>>;
  /** Delete multiple AttractionVariantGallery documents */
  delete?: InputMaybe<Array<AttractionVariantGalleryWhereUniqueInput>>;
  /** Disconnect multiple AttractionVariantGallery documents */
  disconnect?: InputMaybe<Array<AttractionVariantGalleryWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing AttractionVariantGallery documents */
  set?: InputMaybe<Array<AttractionVariantGalleryWhereUniqueInput>>;
  /** Update multiple AttractionVariantGallery documents */
  update?: InputMaybe<Array<AttractionVariantGalleryUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple AttractionVariantGallery documents */
  upsert?: InputMaybe<Array<AttractionVariantGalleryUpsertWithNestedWhereUniqueInput>>;
};

export type AttractionVariantGalleryUpdateManyWithNestedWhereInput = {
  Image?: InputMaybe<ImageUpdateManyWithNestedWhereInput>;
  Video?: InputMaybe<VideoUpdateManyWithNestedWhereInput>;
};

export type AttractionVariantGalleryUpdateOneInlineInput = {
  /** Connect existing AttractionVariantGallery document */
  connect?: InputMaybe<AttractionVariantGalleryWhereUniqueInput>;
  /** Create and connect one AttractionVariantGallery document */
  create?: InputMaybe<AttractionVariantGalleryCreateInput>;
  /** Delete currently connected AttractionVariantGallery document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected AttractionVariantGallery document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single AttractionVariantGallery document */
  update?: InputMaybe<AttractionVariantGalleryUpdateWithNestedWhereUniqueInput>;
  /** Upsert single AttractionVariantGallery document */
  upsert?: InputMaybe<AttractionVariantGalleryUpsertWithNestedWhereUniqueInput>;
};

export type AttractionVariantGalleryUpdateWithNestedWhereUniqueInput = {
  Image?: InputMaybe<ImageUpdateWithNestedWhereUniqueInput>;
  Video?: InputMaybe<VideoUpdateWithNestedWhereUniqueInput>;
};

export type AttractionVariantGalleryUpsertWithNestedWhereUniqueInput = {
  Image?: InputMaybe<ImageUpsertWithNestedWhereUniqueInput>;
  Video?: InputMaybe<VideoUpsertWithNestedWhereUniqueInput>;
};

export type AttractionVariantGalleryWhereInput = {
  Image?: InputMaybe<ImageWhereInput>;
  Video?: InputMaybe<VideoWhereInput>;
};

export type AttractionVariantGalleryWhereUniqueInput = {
  Image?: InputMaybe<ImageWhereUniqueInput>;
  Video?: InputMaybe<VideoWhereUniqueInput>;
};

/** Identifies documents */
export type AttractionVariantManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AttractionVariantWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AttractionVariantWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AttractionVariantWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  admission_every?: InputMaybe<TextWhereInput>;
  admission_none?: InputMaybe<TextWhereInput>;
  admission_some?: InputMaybe<TextWhereInput>;
  attraction?: InputMaybe<AttractionWhereInput>;
  availability?: InputMaybe<AttractionAvailabilityWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<AttractionVariantWhereStageInput>;
  documentInStages_none?: InputMaybe<AttractionVariantWhereStageInput>;
  documentInStages_some?: InputMaybe<AttractionVariantWhereStageInput>;
  /** All values in which the union is empty */
  gallery_empty?: InputMaybe<Scalars['Boolean']>;
  /** Matches if the union contains at least one connection to the provided item to the filter */
  gallery_some?: InputMaybe<AttractionVariantGalleryWhereInput>;
  highlights_every?: InputMaybe<TextWhereInput>;
  highlights_none?: InputMaybe<TextWhereInput>;
  highlights_some?: InputMaybe<TextWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  internalTitle?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  internalTitle_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  internalTitle_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  internalTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  internalTitle_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  internalTitle_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  internalTitle_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  internalTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  internalTitle_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  internalTitle_starts_with?: InputMaybe<Scalars['String']>;
  offers_every?: InputMaybe<AttractionOfferWhereInput>;
  offers_none?: InputMaybe<AttractionOfferWhereInput>;
  offers_some?: InputMaybe<AttractionOfferWhereInput>;
  openStatus?: InputMaybe<TextWhereInput>;
  price?: InputMaybe<AttractionPriceWhereInput>;
  product?: InputMaybe<ProductWhereInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  /** All values containing the given json path. */
  qabu_json_path_exists?: InputMaybe<Scalars['String']>;
  /**
   * Recursively tries to find the provided JSON scalar value inside the field.
   * It does use an exact match when comparing values.
   * If you pass `null` as value the filter will be ignored.
   * Note: This filter fails if you try to look for a non scalar JSON value!
   */
  qabu_value_recursive?: InputMaybe<Scalars['Json']>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum AttractionVariantOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  ExclusiveFeatureAsc = 'exclusiveFeature_ASC',
  ExclusiveFeatureDesc = 'exclusiveFeature_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  InternalTitleAsc = 'internalTitle_ASC',
  InternalTitleDesc = 'internalTitle_DESC',
  IntroAsc = 'intro_ASC',
  IntroDesc = 'intro_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  ShortIntroAsc = 'shortIntro_ASC',
  ShortIntroDesc = 'shortIntro_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type AttractionVariantUpdateInput = {
  admission?: InputMaybe<TextUpdateManyInlineInput>;
  attraction?: InputMaybe<AttractionUpdateOneInlineInput>;
  availability?: InputMaybe<AttractionAvailabilityUpdateOneInlineInput>;
  /** exclusiveFeature input for default locale (en) */
  exclusiveFeature?: InputMaybe<Scalars['String']>;
  gallery?: InputMaybe<AttractionVariantGalleryUpdateManyInlineInput>;
  highlights?: InputMaybe<TextUpdateManyInlineInput>;
  internalTitle?: InputMaybe<Scalars['String']>;
  /** intro input for default locale (en) */
  intro?: InputMaybe<Scalars['String']>;
  /** Manage document localizations */
  localizations?: InputMaybe<AttractionVariantUpdateLocalizationsInput>;
  /** name input for default locale (en) */
  name?: InputMaybe<Scalars['String']>;
  offers?: InputMaybe<AttractionOfferUpdateManyInlineInput>;
  openStatus?: InputMaybe<TextUpdateOneInlineInput>;
  price?: InputMaybe<AttractionPriceUpdateOneInlineInput>;
  product?: InputMaybe<ProductUpdateOneInlineInput>;
  qabu?: InputMaybe<Scalars['Json']>;
  /** shortIntro input for default locale (en) */
  shortIntro?: InputMaybe<Scalars['String']>;
};

export type AttractionVariantUpdateLocalizationDataInput = {
  exclusiveFeature?: InputMaybe<Scalars['String']>;
  intro?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  shortIntro?: InputMaybe<Scalars['String']>;
};

export type AttractionVariantUpdateLocalizationInput = {
  data: AttractionVariantUpdateLocalizationDataInput;
  locale: Locale;
};

export type AttractionVariantUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<AttractionVariantCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<AttractionVariantUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<AttractionVariantUpsertLocalizationInput>>;
};

export type AttractionVariantUpdateManyInlineInput = {
  /** Connect multiple existing AttractionVariant documents */
  connect?: InputMaybe<Array<AttractionVariantConnectInput>>;
  /** Create and connect multiple AttractionVariant documents */
  create?: InputMaybe<Array<AttractionVariantCreateInput>>;
  /** Delete multiple AttractionVariant documents */
  delete?: InputMaybe<Array<AttractionVariantWhereUniqueInput>>;
  /** Disconnect multiple AttractionVariant documents */
  disconnect?: InputMaybe<Array<AttractionVariantWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing AttractionVariant documents */
  set?: InputMaybe<Array<AttractionVariantWhereUniqueInput>>;
  /** Update multiple AttractionVariant documents */
  update?: InputMaybe<Array<AttractionVariantUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple AttractionVariant documents */
  upsert?: InputMaybe<Array<AttractionVariantUpsertWithNestedWhereUniqueInput>>;
};

export type AttractionVariantUpdateManyInput = {
  /** exclusiveFeature input for default locale (en) */
  exclusiveFeature?: InputMaybe<Scalars['String']>;
  /** intro input for default locale (en) */
  intro?: InputMaybe<Scalars['String']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<AttractionVariantUpdateManyLocalizationsInput>;
  /** name input for default locale (en) */
  name?: InputMaybe<Scalars['String']>;
  qabu?: InputMaybe<Scalars['Json']>;
  /** shortIntro input for default locale (en) */
  shortIntro?: InputMaybe<Scalars['String']>;
};

export type AttractionVariantUpdateManyLocalizationDataInput = {
  exclusiveFeature?: InputMaybe<Scalars['String']>;
  intro?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  shortIntro?: InputMaybe<Scalars['String']>;
};

export type AttractionVariantUpdateManyLocalizationInput = {
  data: AttractionVariantUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type AttractionVariantUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<AttractionVariantUpdateManyLocalizationInput>>;
};

export type AttractionVariantUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: AttractionVariantUpdateManyInput;
  /** Document search */
  where: AttractionVariantWhereInput;
};

export type AttractionVariantUpdateOneInlineInput = {
  /** Connect existing AttractionVariant document */
  connect?: InputMaybe<AttractionVariantWhereUniqueInput>;
  /** Create and connect one AttractionVariant document */
  create?: InputMaybe<AttractionVariantCreateInput>;
  /** Delete currently connected AttractionVariant document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected AttractionVariant document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single AttractionVariant document */
  update?: InputMaybe<AttractionVariantUpdateWithNestedWhereUniqueInput>;
  /** Upsert single AttractionVariant document */
  upsert?: InputMaybe<AttractionVariantUpsertWithNestedWhereUniqueInput>;
};

export type AttractionVariantUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: AttractionVariantUpdateInput;
  /** Unique document search */
  where: AttractionVariantWhereUniqueInput;
};

export type AttractionVariantUpsertInput = {
  /** Create document if it didn't exist */
  create: AttractionVariantCreateInput;
  /** Update document if it exists */
  update: AttractionVariantUpdateInput;
};

export type AttractionVariantUpsertLocalizationInput = {
  create: AttractionVariantCreateLocalizationDataInput;
  locale: Locale;
  update: AttractionVariantUpdateLocalizationDataInput;
};

export type AttractionVariantUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: AttractionVariantUpsertInput;
  /** Unique document search */
  where: AttractionVariantWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type AttractionVariantWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']>;
};

/** Identifies documents */
export type AttractionVariantWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AttractionVariantWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AttractionVariantWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AttractionVariantWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  admission_every?: InputMaybe<TextWhereInput>;
  admission_none?: InputMaybe<TextWhereInput>;
  admission_some?: InputMaybe<TextWhereInput>;
  attraction?: InputMaybe<AttractionWhereInput>;
  availability?: InputMaybe<AttractionAvailabilityWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<AttractionVariantWhereStageInput>;
  documentInStages_none?: InputMaybe<AttractionVariantWhereStageInput>;
  documentInStages_some?: InputMaybe<AttractionVariantWhereStageInput>;
  exclusiveFeature?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  exclusiveFeature_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  exclusiveFeature_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  exclusiveFeature_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  exclusiveFeature_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  exclusiveFeature_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  exclusiveFeature_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  exclusiveFeature_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  exclusiveFeature_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  exclusiveFeature_starts_with?: InputMaybe<Scalars['String']>;
  /** All values in which the union is empty */
  gallery_empty?: InputMaybe<Scalars['Boolean']>;
  /** Matches if the union contains at least one connection to the provided item to the filter */
  gallery_some?: InputMaybe<AttractionVariantGalleryWhereInput>;
  highlights_every?: InputMaybe<TextWhereInput>;
  highlights_none?: InputMaybe<TextWhereInput>;
  highlights_some?: InputMaybe<TextWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  internalTitle?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  internalTitle_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  internalTitle_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  internalTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  internalTitle_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  internalTitle_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  internalTitle_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  internalTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  internalTitle_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  internalTitle_starts_with?: InputMaybe<Scalars['String']>;
  intro?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  intro_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  intro_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  intro_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  intro_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  intro_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  intro_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  intro_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  intro_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  intro_starts_with?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']>;
  offers_every?: InputMaybe<AttractionOfferWhereInput>;
  offers_none?: InputMaybe<AttractionOfferWhereInput>;
  offers_some?: InputMaybe<AttractionOfferWhereInput>;
  openStatus?: InputMaybe<TextWhereInput>;
  price?: InputMaybe<AttractionPriceWhereInput>;
  product?: InputMaybe<ProductWhereInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  /** All values containing the given json path. */
  qabu_json_path_exists?: InputMaybe<Scalars['String']>;
  /**
   * Recursively tries to find the provided JSON scalar value inside the field.
   * It does use an exact match when comparing values.
   * If you pass `null` as value the filter will be ignored.
   * Note: This filter fails if you try to look for a non scalar JSON value!
   */
  qabu_value_recursive?: InputMaybe<Scalars['Json']>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  shortIntro?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  shortIntro_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  shortIntro_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  shortIntro_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  shortIntro_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  shortIntro_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  shortIntro_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  shortIntro_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  shortIntro_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  shortIntro_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type AttractionVariantWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AttractionVariantWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AttractionVariantWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AttractionVariantWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<AttractionVariantWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References AttractionVariant record uniquely */
export type AttractionVariantWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
  internalTitle?: InputMaybe<Scalars['String']>;
};

/** This contains a set of filters that can be used to compare values internally */
export type AttractionWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']>;
};

/** Identifies documents */
export type AttractionWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AttractionWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AttractionWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AttractionWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  accessibility?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  accessibility_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  accessibility_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  accessibility_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  accessibility_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  accessibility_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  accessibility_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  accessibility_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  accessibility_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  accessibility_starts_with?: InputMaybe<Scalars['String']>;
  addon?: InputMaybe<Scalars['Boolean']>;
  addonAttractionProducts_every?: InputMaybe<ProductWhereInput>;
  addonAttractionProducts_none?: InputMaybe<ProductWhereInput>;
  addonAttractionProducts_some?: InputMaybe<ProductWhereInput>;
  /** Any other value that exists and is not equal to the given value. */
  addon_not?: InputMaybe<Scalars['Boolean']>;
  admission_every?: InputMaybe<TextWhereInput>;
  admission_none?: InputMaybe<TextWhereInput>;
  admission_some?: InputMaybe<TextWhereInput>;
  affiliateId?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  affiliateId_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  affiliateId_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  affiliateId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  affiliateId_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  affiliateId_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  affiliateId_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  affiliateId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  affiliateId_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  affiliateId_starts_with?: InputMaybe<Scalars['String']>;
  ageAdult?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  ageAdult_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  ageAdult_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  ageAdult_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  ageAdult_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  ageAdult_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  ageAdult_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  ageAdult_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  ageAdult_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  ageAdult_starts_with?: InputMaybe<Scalars['String']>;
  ageChild?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  ageChild_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  ageChild_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  ageChild_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  ageChild_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  ageChild_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  ageChild_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  ageChild_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  ageChild_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  ageChild_starts_with?: InputMaybe<Scalars['String']>;
  alacarte?: InputMaybe<AttractionAlacarteWhereInput>;
  amenities_every?: InputMaybe<TextWhereInput>;
  amenities_none?: InputMaybe<TextWhereInput>;
  amenities_some?: InputMaybe<TextWhereInput>;
  attractionAddOn?: InputMaybe<AddOnWhereInput>;
  availability?: InputMaybe<AttractionAvailabilityWhereInput>;
  bestTime?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  bestTime_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  bestTime_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  bestTime_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  bestTime_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  bestTime_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  bestTime_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  bestTime_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  bestTime_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  bestTime_starts_with?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<AttractionWhereStageInput>;
  documentInStages_none?: InputMaybe<AttractionWhereStageInput>;
  documentInStages_some?: InputMaybe<AttractionWhereStageInput>;
  exclusiveFeature?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  exclusiveFeature_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  exclusiveFeature_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  exclusiveFeature_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  exclusiveFeature_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  exclusiveFeature_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  exclusiveFeature_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  exclusiveFeature_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  exclusiveFeature_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  exclusiveFeature_starts_with?: InputMaybe<Scalars['String']>;
  exclusiveTo?: InputMaybe<ProductWhereInput>;
  /** All values in which the union is empty */
  gallery_empty?: InputMaybe<Scalars['Boolean']>;
  /** Matches if the union contains at least one connection to the provided item to the filter */
  gallery_some?: InputMaybe<AttractionGalleryWhereInput>;
  googlePlaceId?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  googlePlaceId_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  googlePlaceId_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  googlePlaceId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  googlePlaceId_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  googlePlaceId_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  googlePlaceId_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  googlePlaceId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  googlePlaceId_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  googlePlaceId_starts_with?: InputMaybe<Scalars['String']>;
  hero?: InputMaybe<AssetWhereInput>;
  heroCredit?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  heroCredit_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  heroCredit_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  heroCredit_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  heroCredit_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  heroCredit_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  heroCredit_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  heroCredit_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  heroCredit_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  heroCredit_starts_with?: InputMaybe<Scalars['String']>;
  highlights_every?: InputMaybe<TextWhereInput>;
  highlights_none?: InputMaybe<TextWhereInput>;
  highlights_some?: InputMaybe<TextWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  intro?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  intro_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  intro_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  intro_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  intro_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  intro_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  intro_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  intro_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  intro_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  intro_starts_with?: InputMaybe<Scalars['String']>;
  key?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  key_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  key_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  key_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  key_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  key_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  key_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  key_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  key_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  key_starts_with?: InputMaybe<Scalars['String']>;
  locations_every?: InputMaybe<AttractionLocationWhereInput>;
  locations_none?: InputMaybe<AttractionLocationWhereInput>;
  locations_some?: InputMaybe<AttractionLocationWhereInput>;
  logo?: InputMaybe<AssetWhereInput>;
  name?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']>;
  nextPrice?: InputMaybe<AttractionPriceWhereInput>;
  offers_every?: InputMaybe<AttractionOfferWhereInput>;
  offers_none?: InputMaybe<AttractionOfferWhereInput>;
  offers_some?: InputMaybe<AttractionOfferWhereInput>;
  openStatus?: InputMaybe<TextWhereInput>;
  parking?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  parking_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  parking_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  parking_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  parking_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  parking_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  parking_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  parking_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  parking_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  parking_starts_with?: InputMaybe<Scalars['String']>;
  partnerOffers_every?: InputMaybe<PartnerOfferWhereInput>;
  partnerOffers_none?: InputMaybe<PartnerOfferWhereInput>;
  partnerOffers_some?: InputMaybe<PartnerOfferWhereInput>;
  planning?: InputMaybe<DetailBlockWhereInput>;
  price?: InputMaybe<AttractionPriceWhereInput>;
  products_every?: InputMaybe<ProductWhereInput>;
  products_none?: InputMaybe<ProductWhereInput>;
  products_some?: InputMaybe<ProductWhereInput>;
  promos_every?: InputMaybe<PromoWhereInput>;
  promos_none?: InputMaybe<PromoWhereInput>;
  promos_some?: InputMaybe<PromoWhereInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  /** All values containing the given json path. */
  qabu_json_path_exists?: InputMaybe<Scalars['String']>;
  /**
   * Recursively tries to find the provided JSON scalar value inside the field.
   * It does use an exact match when comparing values.
   * If you pass `null` as value the filter will be ignored.
   * Note: This filter fails if you try to look for a non scalar JSON value!
   */
  qabu_value_recursive?: InputMaybe<Scalars['Json']>;
  reservation?: InputMaybe<AttractionReservationWhereInput>;
  safety_every?: InputMaybe<TextWhereInput>;
  safety_none?: InputMaybe<TextWhereInput>;
  safety_some?: InputMaybe<TextWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  seo?: InputMaybe<SeoWhereInput>;
  shortIntro?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  shortIntro_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  shortIntro_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  shortIntro_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  shortIntro_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  shortIntro_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  shortIntro_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  shortIntro_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  shortIntro_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  shortIntro_starts_with?: InputMaybe<Scalars['String']>;
  skuProperties_every?: InputMaybe<SkuPropertyWhereInput>;
  skuProperties_none?: InputMaybe<SkuPropertyWhereInput>;
  skuProperties_some?: InputMaybe<SkuPropertyWhereInput>;
  slug?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  slug_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  slug_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  slug_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  slug_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  slug_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  slug_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  slug_starts_with?: InputMaybe<Scalars['String']>;
  thumb?: InputMaybe<AssetWhereInput>;
  tips_every?: InputMaybe<TextWhereInput>;
  tips_none?: InputMaybe<TextWhereInput>;
  tips_some?: InputMaybe<TextWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
  variants_every?: InputMaybe<AttractionVariantWhereInput>;
  variants_none?: InputMaybe<AttractionVariantWhereInput>;
  variants_some?: InputMaybe<AttractionVariantWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type AttractionWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AttractionWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AttractionWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AttractionWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<AttractionWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References Attraction record uniquely */
export type AttractionWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
  key?: InputMaybe<Scalars['String']>;
};

export type Author = Node & {
  __typename?: 'Author';
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** User that created this document */
  createdBy: Maybe<User>;
  /** Get the document in other stages */
  documentInStages: Array<Author>;
  /** List of Author versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID'];
  name: Scalars['String'];
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt: Maybe<Scalars['DateTime']>;
  /** User that last published this document */
  publishedBy: Maybe<User>;
  scheduledIn: Array<ScheduledOperation>;
  /** System stage field */
  stage: Stage;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** User that last updated this document */
  updatedBy: Maybe<User>;
};

export type AuthorCreatedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type AuthorDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};

export type AuthorHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride: InputMaybe<Stage>;
};

export type AuthorPublishedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type AuthorScheduledInArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ScheduledOperationWhereInput>;
};

export type AuthorUpdatedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type AuthorConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: AuthorWhereUniqueInput;
};

/** A connection to a list of items. */
export type AuthorConnection = {
  __typename?: 'AuthorConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<AuthorEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type AuthorCreateInput = {
  ckwnyvsd307wf01z1f0e394qs?: InputMaybe<ArticleCreateManyInlineInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  name: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type AuthorCreateManyInlineInput = {
  /** Connect multiple existing Author documents */
  connect?: InputMaybe<Array<AuthorWhereUniqueInput>>;
  /** Create and connect multiple existing Author documents */
  create?: InputMaybe<Array<AuthorCreateInput>>;
};

export type AuthorCreateOneInlineInput = {
  /** Connect one existing Author document */
  connect?: InputMaybe<AuthorWhereUniqueInput>;
  /** Create and connect one Author document */
  create?: InputMaybe<AuthorCreateInput>;
};

/** An edge in a connection. */
export type AuthorEdge = {
  __typename?: 'AuthorEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Author;
};

/** Identifies documents */
export type AuthorManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AuthorWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AuthorWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AuthorWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<AuthorWhereStageInput>;
  documentInStages_none?: InputMaybe<AuthorWhereStageInput>;
  documentInStages_some?: InputMaybe<AuthorWhereStageInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum AuthorOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type AuthorUpdateInput = {
  ckwnyvsd307wf01z1f0e394qs?: InputMaybe<ArticleUpdateManyInlineInput>;
  name?: InputMaybe<Scalars['String']>;
};

export type AuthorUpdateManyInlineInput = {
  /** Connect multiple existing Author documents */
  connect?: InputMaybe<Array<AuthorConnectInput>>;
  /** Create and connect multiple Author documents */
  create?: InputMaybe<Array<AuthorCreateInput>>;
  /** Delete multiple Author documents */
  delete?: InputMaybe<Array<AuthorWhereUniqueInput>>;
  /** Disconnect multiple Author documents */
  disconnect?: InputMaybe<Array<AuthorWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing Author documents */
  set?: InputMaybe<Array<AuthorWhereUniqueInput>>;
  /** Update multiple Author documents */
  update?: InputMaybe<Array<AuthorUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple Author documents */
  upsert?: InputMaybe<Array<AuthorUpsertWithNestedWhereUniqueInput>>;
};

export type AuthorUpdateManyInput = {
  /** No fields in updateMany data input */
  _?: InputMaybe<Scalars['String']>;
};

export type AuthorUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: AuthorUpdateManyInput;
  /** Document search */
  where: AuthorWhereInput;
};

export type AuthorUpdateOneInlineInput = {
  /** Connect existing Author document */
  connect?: InputMaybe<AuthorWhereUniqueInput>;
  /** Create and connect one Author document */
  create?: InputMaybe<AuthorCreateInput>;
  /** Delete currently connected Author document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected Author document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single Author document */
  update?: InputMaybe<AuthorUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Author document */
  upsert?: InputMaybe<AuthorUpsertWithNestedWhereUniqueInput>;
};

export type AuthorUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: AuthorUpdateInput;
  /** Unique document search */
  where: AuthorWhereUniqueInput;
};

export type AuthorUpsertInput = {
  /** Create document if it didn't exist */
  create: AuthorCreateInput;
  /** Update document if it exists */
  update: AuthorUpdateInput;
};

export type AuthorUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: AuthorUpsertInput;
  /** Unique document search */
  where: AuthorWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type AuthorWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']>;
};

/** Identifies documents */
export type AuthorWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AuthorWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AuthorWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AuthorWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<AuthorWhereStageInput>;
  documentInStages_none?: InputMaybe<AuthorWhereStageInput>;
  documentInStages_some?: InputMaybe<AuthorWhereStageInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type AuthorWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AuthorWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AuthorWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AuthorWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<AuthorWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References Author record uniquely */
export type AuthorWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
};

export type BatchPayload = {
  __typename?: 'BatchPayload';
  /** The number of nodes that have been affected by the Batch operation. */
  count: Scalars['Long'];
};

export type Block = Node & {
  __typename?: 'Block';
  active: Scalars['Boolean'];
  blockGroups: Array<BlockGroup>;
  body: Maybe<Text>;
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** User that created this document */
  createdBy: Maybe<User>;
  cta: Maybe<Text>;
  /** Get the document in other stages */
  documentInStages: Array<Block>;
  /** List of Block versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID'];
  image: Maybe<Asset>;
  internalTitle: Maybe<Scalars['String']>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt: Maybe<Scalars['DateTime']>;
  /** User that last published this document */
  publishedBy: Maybe<User>;
  scheduledIn: Array<ScheduledOperation>;
  /** System stage field */
  stage: Stage;
  title: Maybe<Text>;
  ui: Array<BlockUi>;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** User that last updated this document */
  updatedBy: Maybe<User>;
  url: Maybe<Scalars['String']>;
};

export type BlockBlockGroupsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<BlockGroupOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<BlockGroupWhereInput>;
};

export type BlockBodyArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type BlockCreatedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type BlockCtaArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type BlockDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};

export type BlockHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride: InputMaybe<Stage>;
};

export type BlockImageArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type BlockPublishedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type BlockScheduledInArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ScheduledOperationWhereInput>;
};

export type BlockTitleArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type BlockUiArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<BlockUiOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<BlockUiWhereInput>;
};

export type BlockUpdatedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type BlockConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: BlockWhereUniqueInput;
};

/** A connection to a list of items. */
export type BlockConnection = {
  __typename?: 'BlockConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<BlockEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type BlockCreateInput = {
  active: Scalars['Boolean'];
  blockGroups?: InputMaybe<BlockGroupCreateManyInlineInput>;
  body?: InputMaybe<TextCreateOneInlineInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  cta?: InputMaybe<TextCreateOneInlineInput>;
  image?: InputMaybe<AssetCreateOneInlineInput>;
  internalTitle?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<TextCreateOneInlineInput>;
  ui?: InputMaybe<BlockUiCreateManyInlineInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  url?: InputMaybe<Scalars['String']>;
};

export type BlockCreateManyInlineInput = {
  /** Connect multiple existing Block documents */
  connect?: InputMaybe<Array<BlockWhereUniqueInput>>;
  /** Create and connect multiple existing Block documents */
  create?: InputMaybe<Array<BlockCreateInput>>;
};

export type BlockCreateOneInlineInput = {
  /** Connect one existing Block document */
  connect?: InputMaybe<BlockWhereUniqueInput>;
  /** Create and connect one Block document */
  create?: InputMaybe<BlockCreateInput>;
};

/** An edge in a connection. */
export type BlockEdge = {
  __typename?: 'BlockEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Block;
};

export type BlockGroup = Node & {
  __typename?: 'BlockGroup';
  blocks: Array<Block>;
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** User that created this document */
  createdBy: Maybe<User>;
  /** Get the document in other stages */
  documentInStages: Array<BlockGroup>;
  /** List of BlockGroup versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID'];
  key: Scalars['String'];
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt: Maybe<Scalars['DateTime']>;
  /** User that last published this document */
  publishedBy: Maybe<User>;
  scheduledIn: Array<ScheduledOperation>;
  /** System stage field */
  stage: Stage;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** User that last updated this document */
  updatedBy: Maybe<User>;
};

export type BlockGroupBlocksArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<BlockOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<BlockWhereInput>;
};

export type BlockGroupCreatedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type BlockGroupDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};

export type BlockGroupHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride: InputMaybe<Stage>;
};

export type BlockGroupPublishedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type BlockGroupScheduledInArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ScheduledOperationWhereInput>;
};

export type BlockGroupUpdatedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type BlockGroupConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: BlockGroupWhereUniqueInput;
};

/** A connection to a list of items. */
export type BlockGroupConnection = {
  __typename?: 'BlockGroupConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<BlockGroupEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type BlockGroupCreateInput = {
  blocks?: InputMaybe<BlockCreateManyInlineInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  key: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type BlockGroupCreateManyInlineInput = {
  /** Connect multiple existing BlockGroup documents */
  connect?: InputMaybe<Array<BlockGroupWhereUniqueInput>>;
  /** Create and connect multiple existing BlockGroup documents */
  create?: InputMaybe<Array<BlockGroupCreateInput>>;
};

export type BlockGroupCreateOneInlineInput = {
  /** Connect one existing BlockGroup document */
  connect?: InputMaybe<BlockGroupWhereUniqueInput>;
  /** Create and connect one BlockGroup document */
  create?: InputMaybe<BlockGroupCreateInput>;
};

/** An edge in a connection. */
export type BlockGroupEdge = {
  __typename?: 'BlockGroupEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: BlockGroup;
};

/** Identifies documents */
export type BlockGroupManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<BlockGroupWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<BlockGroupWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<BlockGroupWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  blocks_every?: InputMaybe<BlockWhereInput>;
  blocks_none?: InputMaybe<BlockWhereInput>;
  blocks_some?: InputMaybe<BlockWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<BlockGroupWhereStageInput>;
  documentInStages_none?: InputMaybe<BlockGroupWhereStageInput>;
  documentInStages_some?: InputMaybe<BlockGroupWhereStageInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  key?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  key_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  key_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  key_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  key_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  key_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  key_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  key_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  key_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  key_starts_with?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum BlockGroupOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type BlockGroupUpdateInput = {
  blocks?: InputMaybe<BlockUpdateManyInlineInput>;
  key?: InputMaybe<Scalars['String']>;
};

export type BlockGroupUpdateManyInlineInput = {
  /** Connect multiple existing BlockGroup documents */
  connect?: InputMaybe<Array<BlockGroupConnectInput>>;
  /** Create and connect multiple BlockGroup documents */
  create?: InputMaybe<Array<BlockGroupCreateInput>>;
  /** Delete multiple BlockGroup documents */
  delete?: InputMaybe<Array<BlockGroupWhereUniqueInput>>;
  /** Disconnect multiple BlockGroup documents */
  disconnect?: InputMaybe<Array<BlockGroupWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing BlockGroup documents */
  set?: InputMaybe<Array<BlockGroupWhereUniqueInput>>;
  /** Update multiple BlockGroup documents */
  update?: InputMaybe<Array<BlockGroupUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple BlockGroup documents */
  upsert?: InputMaybe<Array<BlockGroupUpsertWithNestedWhereUniqueInput>>;
};

export type BlockGroupUpdateManyInput = {
  /** No fields in updateMany data input */
  _?: InputMaybe<Scalars['String']>;
};

export type BlockGroupUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: BlockGroupUpdateManyInput;
  /** Document search */
  where: BlockGroupWhereInput;
};

export type BlockGroupUpdateOneInlineInput = {
  /** Connect existing BlockGroup document */
  connect?: InputMaybe<BlockGroupWhereUniqueInput>;
  /** Create and connect one BlockGroup document */
  create?: InputMaybe<BlockGroupCreateInput>;
  /** Delete currently connected BlockGroup document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected BlockGroup document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single BlockGroup document */
  update?: InputMaybe<BlockGroupUpdateWithNestedWhereUniqueInput>;
  /** Upsert single BlockGroup document */
  upsert?: InputMaybe<BlockGroupUpsertWithNestedWhereUniqueInput>;
};

export type BlockGroupUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: BlockGroupUpdateInput;
  /** Unique document search */
  where: BlockGroupWhereUniqueInput;
};

export type BlockGroupUpsertInput = {
  /** Create document if it didn't exist */
  create: BlockGroupCreateInput;
  /** Update document if it exists */
  update: BlockGroupUpdateInput;
};

export type BlockGroupUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: BlockGroupUpsertInput;
  /** Unique document search */
  where: BlockGroupWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type BlockGroupWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']>;
};

/** Identifies documents */
export type BlockGroupWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<BlockGroupWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<BlockGroupWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<BlockGroupWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  blocks_every?: InputMaybe<BlockWhereInput>;
  blocks_none?: InputMaybe<BlockWhereInput>;
  blocks_some?: InputMaybe<BlockWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<BlockGroupWhereStageInput>;
  documentInStages_none?: InputMaybe<BlockGroupWhereStageInput>;
  documentInStages_some?: InputMaybe<BlockGroupWhereStageInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  key?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  key_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  key_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  key_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  key_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  key_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  key_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  key_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  key_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  key_starts_with?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type BlockGroupWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<BlockGroupWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<BlockGroupWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<BlockGroupWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<BlockGroupWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References BlockGroup record uniquely */
export type BlockGroupWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
  key?: InputMaybe<Scalars['String']>;
};

/** Identifies documents */
export type BlockManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<BlockWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<BlockWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<BlockWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  active_not?: InputMaybe<Scalars['Boolean']>;
  blockGroups_every?: InputMaybe<BlockGroupWhereInput>;
  blockGroups_none?: InputMaybe<BlockGroupWhereInput>;
  blockGroups_some?: InputMaybe<BlockGroupWhereInput>;
  body?: InputMaybe<TextWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  cta?: InputMaybe<TextWhereInput>;
  documentInStages_every?: InputMaybe<BlockWhereStageInput>;
  documentInStages_none?: InputMaybe<BlockWhereStageInput>;
  documentInStages_some?: InputMaybe<BlockWhereStageInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  image?: InputMaybe<AssetWhereInput>;
  internalTitle?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  internalTitle_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  internalTitle_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  internalTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  internalTitle_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  internalTitle_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  internalTitle_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  internalTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  internalTitle_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  internalTitle_starts_with?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  title?: InputMaybe<TextWhereInput>;
  ui_every?: InputMaybe<BlockUiWhereInput>;
  ui_none?: InputMaybe<BlockUiWhereInput>;
  ui_some?: InputMaybe<BlockUiWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
  url?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  url_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  url_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  url_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  url_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  url_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  url_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  url_starts_with?: InputMaybe<Scalars['String']>;
};

export enum BlockOrderByInput {
  ActiveAsc = 'active_ASC',
  ActiveDesc = 'active_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  InternalTitleAsc = 'internalTitle_ASC',
  InternalTitleDesc = 'internalTitle_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC',
}

export type BlockUi = {
  __typename?: 'BlockUI';
  align: Scalars['String'];
  analyticsEvent: Maybe<Scalars['String']>;
  colorScheme: Scalars['String'];
  component: Maybe<Scalars['String']>;
  fullWidth: Scalars['Boolean'];
  /** The unique identifier */
  id: Scalars['ID'];
  platforms: Array<Platforms>;
  props: Maybe<Scalars['Json']>;
  /** System stage field */
  stage: Stage;
};

export type BlockUiConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: BlockUiWhereUniqueInput;
};

/** A connection to a list of items. */
export type BlockUiConnection = {
  __typename?: 'BlockUIConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<BlockUiEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type BlockUiCreateInput = {
  align: Scalars['String'];
  analyticsEvent?: InputMaybe<Scalars['String']>;
  colorScheme: Scalars['String'];
  component?: InputMaybe<Scalars['String']>;
  fullWidth: Scalars['Boolean'];
  platforms?: InputMaybe<Array<Platforms>>;
  props?: InputMaybe<Scalars['Json']>;
};

export type BlockUiCreateManyInlineInput = {
  /** Create and connect multiple existing BlockUI documents */
  create?: InputMaybe<Array<BlockUiCreateInput>>;
};

export type BlockUiCreateOneInlineInput = {
  /** Create and connect one BlockUI document */
  create?: InputMaybe<BlockUiCreateInput>;
};

export type BlockUiCreateWithPositionInput = {
  /** Document to create */
  data: BlockUiCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type BlockUiEdge = {
  __typename?: 'BlockUIEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: BlockUi;
};

/** Identifies documents */
export type BlockUiManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<BlockUiWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<BlockUiWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<BlockUiWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  align?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  align_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  align_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  align_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  align_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  align_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  align_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  align_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  align_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  align_starts_with?: InputMaybe<Scalars['String']>;
  analyticsEvent?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  analyticsEvent_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  analyticsEvent_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  analyticsEvent_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  analyticsEvent_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  analyticsEvent_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  analyticsEvent_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  analyticsEvent_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  analyticsEvent_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  analyticsEvent_starts_with?: InputMaybe<Scalars['String']>;
  colorScheme?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  colorScheme_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  colorScheme_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  colorScheme_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  colorScheme_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  colorScheme_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  colorScheme_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  colorScheme_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  colorScheme_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  colorScheme_starts_with?: InputMaybe<Scalars['String']>;
  component?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  component_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  component_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  component_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  component_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  component_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  component_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  component_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  component_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  component_starts_with?: InputMaybe<Scalars['String']>;
  fullWidth?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  fullWidth_not?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  platforms?: InputMaybe<Array<Platforms>>;
  /** Matches if the field array contains *all* items provided to the filter */
  platforms_contains_all?: InputMaybe<Array<Platforms>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  platforms_contains_none?: InputMaybe<Array<Platforms>>;
  /** Matches if the field array contains at least one item provided to the filter */
  platforms_contains_some?: InputMaybe<Array<Platforms>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  platforms_not?: InputMaybe<Array<Platforms>>;
  /** All values containing the given json path. */
  props_json_path_exists?: InputMaybe<Scalars['String']>;
  /**
   * Recursively tries to find the provided JSON scalar value inside the field.
   * It does use an exact match when comparing values.
   * If you pass `null` as value the filter will be ignored.
   * Note: This filter fails if you try to look for a non scalar JSON value!
   */
  props_value_recursive?: InputMaybe<Scalars['Json']>;
};

export enum BlockUiOrderByInput {
  AlignAsc = 'align_ASC',
  AlignDesc = 'align_DESC',
  AnalyticsEventAsc = 'analyticsEvent_ASC',
  AnalyticsEventDesc = 'analyticsEvent_DESC',
  ColorSchemeAsc = 'colorScheme_ASC',
  ColorSchemeDesc = 'colorScheme_DESC',
  ComponentAsc = 'component_ASC',
  ComponentDesc = 'component_DESC',
  FullWidthAsc = 'fullWidth_ASC',
  FullWidthDesc = 'fullWidth_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PlatformsAsc = 'platforms_ASC',
  PlatformsDesc = 'platforms_DESC',
}

export type BlockUiParent = Block;

export type BlockUiParentConnectInput = {
  Block?: InputMaybe<BlockConnectInput>;
};

export type BlockUiParentCreateInput = {
  Block?: InputMaybe<BlockCreateInput>;
};

export type BlockUiParentCreateManyInlineInput = {
  /** Connect multiple existing BlockUIParent documents */
  connect?: InputMaybe<Array<BlockUiParentWhereUniqueInput>>;
  /** Create and connect multiple existing BlockUIParent documents */
  create?: InputMaybe<Array<BlockUiParentCreateInput>>;
};

export type BlockUiParentCreateOneInlineInput = {
  /** Connect one existing BlockUIParent document */
  connect?: InputMaybe<BlockUiParentWhereUniqueInput>;
  /** Create and connect one BlockUIParent document */
  create?: InputMaybe<BlockUiParentCreateInput>;
};

export type BlockUiParentUpdateInput = {
  Block?: InputMaybe<BlockUpdateInput>;
};

export type BlockUiParentUpdateManyInlineInput = {
  /** Connect multiple existing BlockUIParent documents */
  connect?: InputMaybe<Array<BlockUiParentConnectInput>>;
  /** Create and connect multiple BlockUIParent documents */
  create?: InputMaybe<Array<BlockUiParentCreateInput>>;
  /** Delete multiple BlockUIParent documents */
  delete?: InputMaybe<Array<BlockUiParentWhereUniqueInput>>;
  /** Disconnect multiple BlockUIParent documents */
  disconnect?: InputMaybe<Array<BlockUiParentWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing BlockUIParent documents */
  set?: InputMaybe<Array<BlockUiParentWhereUniqueInput>>;
  /** Update multiple BlockUIParent documents */
  update?: InputMaybe<Array<BlockUiParentUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple BlockUIParent documents */
  upsert?: InputMaybe<Array<BlockUiParentUpsertWithNestedWhereUniqueInput>>;
};

export type BlockUiParentUpdateManyWithNestedWhereInput = {
  Block?: InputMaybe<BlockUpdateManyWithNestedWhereInput>;
};

export type BlockUiParentUpdateOneInlineInput = {
  /** Connect existing BlockUIParent document */
  connect?: InputMaybe<BlockUiParentWhereUniqueInput>;
  /** Create and connect one BlockUIParent document */
  create?: InputMaybe<BlockUiParentCreateInput>;
  /** Delete currently connected BlockUIParent document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected BlockUIParent document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single BlockUIParent document */
  update?: InputMaybe<BlockUiParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single BlockUIParent document */
  upsert?: InputMaybe<BlockUiParentUpsertWithNestedWhereUniqueInput>;
};

export type BlockUiParentUpdateWithNestedWhereUniqueInput = {
  Block?: InputMaybe<BlockUpdateWithNestedWhereUniqueInput>;
};

export type BlockUiParentUpsertWithNestedWhereUniqueInput = {
  Block?: InputMaybe<BlockUpsertWithNestedWhereUniqueInput>;
};

export type BlockUiParentWhereInput = {
  Block?: InputMaybe<BlockWhereInput>;
};

export type BlockUiParentWhereUniqueInput = {
  Block?: InputMaybe<BlockWhereUniqueInput>;
};

export type BlockUiUpdateInput = {
  align?: InputMaybe<Scalars['String']>;
  analyticsEvent?: InputMaybe<Scalars['String']>;
  colorScheme?: InputMaybe<Scalars['String']>;
  component?: InputMaybe<Scalars['String']>;
  fullWidth?: InputMaybe<Scalars['Boolean']>;
  platforms?: InputMaybe<Array<Platforms>>;
  props?: InputMaybe<Scalars['Json']>;
};

export type BlockUiUpdateManyInlineInput = {
  /** Create and connect multiple BlockUI component instances */
  create?: InputMaybe<Array<BlockUiCreateWithPositionInput>>;
  /** Delete multiple BlockUI documents */
  delete?: InputMaybe<Array<BlockUiWhereUniqueInput>>;
  /** Update multiple BlockUI component instances */
  update?: InputMaybe<Array<BlockUiUpdateWithNestedWhereUniqueAndPositionInput>>;
  /** Upsert multiple BlockUI component instances */
  upsert?: InputMaybe<Array<BlockUiUpsertWithNestedWhereUniqueAndPositionInput>>;
};

export type BlockUiUpdateManyInput = {
  align?: InputMaybe<Scalars['String']>;
  analyticsEvent?: InputMaybe<Scalars['String']>;
  colorScheme?: InputMaybe<Scalars['String']>;
  component?: InputMaybe<Scalars['String']>;
  fullWidth?: InputMaybe<Scalars['Boolean']>;
  platforms?: InputMaybe<Array<Platforms>>;
  props?: InputMaybe<Scalars['Json']>;
};

export type BlockUiUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: BlockUiUpdateManyInput;
  /** Document search */
  where: BlockUiWhereInput;
};

export type BlockUiUpdateOneInlineInput = {
  /** Create and connect one BlockUI document */
  create?: InputMaybe<BlockUiCreateInput>;
  /** Delete currently connected BlockUI document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single BlockUI document */
  update?: InputMaybe<BlockUiUpdateWithNestedWhereUniqueInput>;
  /** Upsert single BlockUI document */
  upsert?: InputMaybe<BlockUiUpsertWithNestedWhereUniqueInput>;
};

export type BlockUiUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<BlockUiUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: BlockUiWhereUniqueInput;
};

export type BlockUiUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: BlockUiUpdateInput;
  /** Unique document search */
  where: BlockUiWhereUniqueInput;
};

export type BlockUiUpsertInput = {
  /** Create document if it didn't exist */
  create: BlockUiCreateInput;
  /** Update document if it exists */
  update: BlockUiUpdateInput;
};

export type BlockUiUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<BlockUiUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: BlockUiWhereUniqueInput;
};

export type BlockUiUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: BlockUiUpsertInput;
  /** Unique document search */
  where: BlockUiWhereUniqueInput;
};

/** Identifies documents */
export type BlockUiWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<BlockUiWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<BlockUiWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<BlockUiWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  align?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  align_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  align_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  align_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  align_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  align_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  align_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  align_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  align_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  align_starts_with?: InputMaybe<Scalars['String']>;
  analyticsEvent?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  analyticsEvent_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  analyticsEvent_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  analyticsEvent_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  analyticsEvent_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  analyticsEvent_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  analyticsEvent_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  analyticsEvent_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  analyticsEvent_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  analyticsEvent_starts_with?: InputMaybe<Scalars['String']>;
  colorScheme?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  colorScheme_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  colorScheme_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  colorScheme_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  colorScheme_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  colorScheme_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  colorScheme_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  colorScheme_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  colorScheme_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  colorScheme_starts_with?: InputMaybe<Scalars['String']>;
  component?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  component_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  component_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  component_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  component_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  component_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  component_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  component_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  component_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  component_starts_with?: InputMaybe<Scalars['String']>;
  fullWidth?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  fullWidth_not?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  platforms?: InputMaybe<Array<Platforms>>;
  /** Matches if the field array contains *all* items provided to the filter */
  platforms_contains_all?: InputMaybe<Array<Platforms>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  platforms_contains_none?: InputMaybe<Array<Platforms>>;
  /** Matches if the field array contains at least one item provided to the filter */
  platforms_contains_some?: InputMaybe<Array<Platforms>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  platforms_not?: InputMaybe<Array<Platforms>>;
  /** All values containing the given json path. */
  props_json_path_exists?: InputMaybe<Scalars['String']>;
  /**
   * Recursively tries to find the provided JSON scalar value inside the field.
   * It does use an exact match when comparing values.
   * If you pass `null` as value the filter will be ignored.
   * Note: This filter fails if you try to look for a non scalar JSON value!
   */
  props_value_recursive?: InputMaybe<Scalars['Json']>;
};

/** References BlockUI record uniquely */
export type BlockUiWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type BlockUpdateInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  blockGroups?: InputMaybe<BlockGroupUpdateManyInlineInput>;
  body?: InputMaybe<TextUpdateOneInlineInput>;
  cta?: InputMaybe<TextUpdateOneInlineInput>;
  image?: InputMaybe<AssetUpdateOneInlineInput>;
  internalTitle?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<TextUpdateOneInlineInput>;
  ui?: InputMaybe<BlockUiUpdateManyInlineInput>;
  url?: InputMaybe<Scalars['String']>;
};

export type BlockUpdateManyInlineInput = {
  /** Connect multiple existing Block documents */
  connect?: InputMaybe<Array<BlockConnectInput>>;
  /** Create and connect multiple Block documents */
  create?: InputMaybe<Array<BlockCreateInput>>;
  /** Delete multiple Block documents */
  delete?: InputMaybe<Array<BlockWhereUniqueInput>>;
  /** Disconnect multiple Block documents */
  disconnect?: InputMaybe<Array<BlockWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing Block documents */
  set?: InputMaybe<Array<BlockWhereUniqueInput>>;
  /** Update multiple Block documents */
  update?: InputMaybe<Array<BlockUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple Block documents */
  upsert?: InputMaybe<Array<BlockUpsertWithNestedWhereUniqueInput>>;
};

export type BlockUpdateManyInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  internalTitle?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
};

export type BlockUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: BlockUpdateManyInput;
  /** Document search */
  where: BlockWhereInput;
};

export type BlockUpdateOneInlineInput = {
  /** Connect existing Block document */
  connect?: InputMaybe<BlockWhereUniqueInput>;
  /** Create and connect one Block document */
  create?: InputMaybe<BlockCreateInput>;
  /** Delete currently connected Block document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected Block document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single Block document */
  update?: InputMaybe<BlockUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Block document */
  upsert?: InputMaybe<BlockUpsertWithNestedWhereUniqueInput>;
};

export type BlockUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: BlockUpdateInput;
  /** Unique document search */
  where: BlockWhereUniqueInput;
};

export type BlockUpsertInput = {
  /** Create document if it didn't exist */
  create: BlockCreateInput;
  /** Update document if it exists */
  update: BlockUpdateInput;
};

export type BlockUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: BlockUpsertInput;
  /** Unique document search */
  where: BlockWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type BlockWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']>;
};

/** Identifies documents */
export type BlockWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<BlockWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<BlockWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<BlockWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  active_not?: InputMaybe<Scalars['Boolean']>;
  blockGroups_every?: InputMaybe<BlockGroupWhereInput>;
  blockGroups_none?: InputMaybe<BlockGroupWhereInput>;
  blockGroups_some?: InputMaybe<BlockGroupWhereInput>;
  body?: InputMaybe<TextWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  cta?: InputMaybe<TextWhereInput>;
  documentInStages_every?: InputMaybe<BlockWhereStageInput>;
  documentInStages_none?: InputMaybe<BlockWhereStageInput>;
  documentInStages_some?: InputMaybe<BlockWhereStageInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  image?: InputMaybe<AssetWhereInput>;
  internalTitle?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  internalTitle_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  internalTitle_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  internalTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  internalTitle_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  internalTitle_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  internalTitle_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  internalTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  internalTitle_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  internalTitle_starts_with?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  title?: InputMaybe<TextWhereInput>;
  ui_every?: InputMaybe<BlockUiWhereInput>;
  ui_none?: InputMaybe<BlockUiWhereInput>;
  ui_some?: InputMaybe<BlockUiWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
  url?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  url_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  url_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  url_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  url_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  url_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  url_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  url_starts_with?: InputMaybe<Scalars['String']>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type BlockWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<BlockWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<BlockWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<BlockWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<BlockWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References Block record uniquely */
export type BlockWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

/** Representing a color value comprising of HEX, RGBA and css color values */
export type Color = {
  __typename?: 'Color';
  css: Scalars['String'];
  hex: Scalars['Hex'];
  rgba: Rgba;
};

/** Accepts either HEX or RGBA color value. At least one of hex or rgba value should be passed. If both are passed RGBA is used. */
export type ColorInput = {
  hex?: InputMaybe<Scalars['Hex']>;
  rgba?: InputMaybe<RgbaInput>;
};

export enum ComponentType {
  ArticleCategorySelector = 'ArticleCategorySelector',
  ArticleListingTitle = 'ArticleListingTitle',
  ProductAttractionsDetail = 'ProductAttractionsDetail',
  ProductDetailsAccordion = 'ProductDetailsAccordion',
  ProductInspiration = 'ProductInspiration',
  ProductReservations = 'ProductReservations',
  ProductReviews = 'ProductReviews',
  ProductSafety = 'ProductSafety',
  ProductTips = 'ProductTips',
  ProductValidity = 'ProductValidity',
  ProductWhatsIncluded = 'ProductWhatsIncluded',
  SocialLinks = 'SocialLinks',
}

export type ConnectPositionInput = {
  /** Connect document after specified document */
  after?: InputMaybe<Scalars['ID']>;
  /** Connect document before specified document */
  before?: InputMaybe<Scalars['ID']>;
  /** Connect document at last position */
  end?: InputMaybe<Scalars['Boolean']>;
  /** Connect document at first position */
  start?: InputMaybe<Scalars['Boolean']>;
};

export type CrossPromoBanner = {
  __typename?: 'CrossPromoBanner';
  copyright: Maybe<Text>;
  description: Maybe<Text>;
  /** The unique identifier */
  id: Scalars['ID'];
  image: Maybe<Asset>;
  logo: Maybe<Asset>;
  product: Maybe<Product>;
  /** System stage field */
  stage: Stage;
  subDescription: Maybe<Text>;
  title: Maybe<Text>;
};

export type CrossPromoBannerCopyrightArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type CrossPromoBannerDescriptionArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type CrossPromoBannerImageArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type CrossPromoBannerLogoArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type CrossPromoBannerProductArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type CrossPromoBannerSubDescriptionArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type CrossPromoBannerTitleArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type CrossPromoBannerConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: CrossPromoBannerWhereUniqueInput;
};

/** A connection to a list of items. */
export type CrossPromoBannerConnection = {
  __typename?: 'CrossPromoBannerConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<CrossPromoBannerEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type CrossPromoBannerCreateInput = {
  copyright?: InputMaybe<TextCreateOneInlineInput>;
  description?: InputMaybe<TextCreateOneInlineInput>;
  image?: InputMaybe<AssetCreateOneInlineInput>;
  logo?: InputMaybe<AssetCreateOneInlineInput>;
  product?: InputMaybe<ProductCreateOneInlineInput>;
  subDescription?: InputMaybe<TextCreateOneInlineInput>;
  title?: InputMaybe<TextCreateOneInlineInput>;
};

export type CrossPromoBannerCreateManyInlineInput = {
  /** Create and connect multiple existing CrossPromoBanner documents */
  create?: InputMaybe<Array<CrossPromoBannerCreateInput>>;
};

export type CrossPromoBannerCreateOneInlineInput = {
  /** Create and connect one CrossPromoBanner document */
  create?: InputMaybe<CrossPromoBannerCreateInput>;
};

export type CrossPromoBannerCreateWithPositionInput = {
  /** Document to create */
  data: CrossPromoBannerCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type CrossPromoBannerEdge = {
  __typename?: 'CrossPromoBannerEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: CrossPromoBanner;
};

/** Identifies documents */
export type CrossPromoBannerManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<CrossPromoBannerWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<CrossPromoBannerWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<CrossPromoBannerWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  copyright?: InputMaybe<TextWhereInput>;
  description?: InputMaybe<TextWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  image?: InputMaybe<AssetWhereInput>;
  logo?: InputMaybe<AssetWhereInput>;
  product?: InputMaybe<ProductWhereInput>;
  subDescription?: InputMaybe<TextWhereInput>;
  title?: InputMaybe<TextWhereInput>;
};

export enum CrossPromoBannerOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
}

export type CrossPromoBannerParent = Promo;

export type CrossPromoBannerParentConnectInput = {
  Promo?: InputMaybe<PromoConnectInput>;
};

export type CrossPromoBannerParentCreateInput = {
  Promo?: InputMaybe<PromoCreateInput>;
};

export type CrossPromoBannerParentCreateManyInlineInput = {
  /** Connect multiple existing CrossPromoBannerParent documents */
  connect?: InputMaybe<Array<CrossPromoBannerParentWhereUniqueInput>>;
  /** Create and connect multiple existing CrossPromoBannerParent documents */
  create?: InputMaybe<Array<CrossPromoBannerParentCreateInput>>;
};

export type CrossPromoBannerParentCreateOneInlineInput = {
  /** Connect one existing CrossPromoBannerParent document */
  connect?: InputMaybe<CrossPromoBannerParentWhereUniqueInput>;
  /** Create and connect one CrossPromoBannerParent document */
  create?: InputMaybe<CrossPromoBannerParentCreateInput>;
};

export type CrossPromoBannerParentUpdateInput = {
  Promo?: InputMaybe<PromoUpdateInput>;
};

export type CrossPromoBannerParentUpdateManyInlineInput = {
  /** Connect multiple existing CrossPromoBannerParent documents */
  connect?: InputMaybe<Array<CrossPromoBannerParentConnectInput>>;
  /** Create and connect multiple CrossPromoBannerParent documents */
  create?: InputMaybe<Array<CrossPromoBannerParentCreateInput>>;
  /** Delete multiple CrossPromoBannerParent documents */
  delete?: InputMaybe<Array<CrossPromoBannerParentWhereUniqueInput>>;
  /** Disconnect multiple CrossPromoBannerParent documents */
  disconnect?: InputMaybe<Array<CrossPromoBannerParentWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing CrossPromoBannerParent documents */
  set?: InputMaybe<Array<CrossPromoBannerParentWhereUniqueInput>>;
  /** Update multiple CrossPromoBannerParent documents */
  update?: InputMaybe<Array<CrossPromoBannerParentUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple CrossPromoBannerParent documents */
  upsert?: InputMaybe<Array<CrossPromoBannerParentUpsertWithNestedWhereUniqueInput>>;
};

export type CrossPromoBannerParentUpdateManyWithNestedWhereInput = {
  Promo?: InputMaybe<PromoUpdateManyWithNestedWhereInput>;
};

export type CrossPromoBannerParentUpdateOneInlineInput = {
  /** Connect existing CrossPromoBannerParent document */
  connect?: InputMaybe<CrossPromoBannerParentWhereUniqueInput>;
  /** Create and connect one CrossPromoBannerParent document */
  create?: InputMaybe<CrossPromoBannerParentCreateInput>;
  /** Delete currently connected CrossPromoBannerParent document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected CrossPromoBannerParent document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single CrossPromoBannerParent document */
  update?: InputMaybe<CrossPromoBannerParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single CrossPromoBannerParent document */
  upsert?: InputMaybe<CrossPromoBannerParentUpsertWithNestedWhereUniqueInput>;
};

export type CrossPromoBannerParentUpdateWithNestedWhereUniqueInput = {
  Promo?: InputMaybe<PromoUpdateWithNestedWhereUniqueInput>;
};

export type CrossPromoBannerParentUpsertWithNestedWhereUniqueInput = {
  Promo?: InputMaybe<PromoUpsertWithNestedWhereUniqueInput>;
};

export type CrossPromoBannerParentWhereInput = {
  Promo?: InputMaybe<PromoWhereInput>;
};

export type CrossPromoBannerParentWhereUniqueInput = {
  Promo?: InputMaybe<PromoWhereUniqueInput>;
};

export type CrossPromoBannerUpdateInput = {
  copyright?: InputMaybe<TextUpdateOneInlineInput>;
  description?: InputMaybe<TextUpdateOneInlineInput>;
  image?: InputMaybe<AssetUpdateOneInlineInput>;
  logo?: InputMaybe<AssetUpdateOneInlineInput>;
  product?: InputMaybe<ProductUpdateOneInlineInput>;
  subDescription?: InputMaybe<TextUpdateOneInlineInput>;
  title?: InputMaybe<TextUpdateOneInlineInput>;
};

export type CrossPromoBannerUpdateManyInlineInput = {
  /** Create and connect multiple CrossPromoBanner component instances */
  create?: InputMaybe<Array<CrossPromoBannerCreateWithPositionInput>>;
  /** Delete multiple CrossPromoBanner documents */
  delete?: InputMaybe<Array<CrossPromoBannerWhereUniqueInput>>;
  /** Update multiple CrossPromoBanner component instances */
  update?: InputMaybe<Array<CrossPromoBannerUpdateWithNestedWhereUniqueAndPositionInput>>;
  /** Upsert multiple CrossPromoBanner component instances */
  upsert?: InputMaybe<Array<CrossPromoBannerUpsertWithNestedWhereUniqueAndPositionInput>>;
};

export type CrossPromoBannerUpdateManyInput = {
  /** No fields in updateMany data input */
  _?: InputMaybe<Scalars['String']>;
};

export type CrossPromoBannerUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: CrossPromoBannerUpdateManyInput;
  /** Document search */
  where: CrossPromoBannerWhereInput;
};

export type CrossPromoBannerUpdateOneInlineInput = {
  /** Create and connect one CrossPromoBanner document */
  create?: InputMaybe<CrossPromoBannerCreateInput>;
  /** Delete currently connected CrossPromoBanner document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single CrossPromoBanner document */
  update?: InputMaybe<CrossPromoBannerUpdateWithNestedWhereUniqueInput>;
  /** Upsert single CrossPromoBanner document */
  upsert?: InputMaybe<CrossPromoBannerUpsertWithNestedWhereUniqueInput>;
};

export type CrossPromoBannerUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<CrossPromoBannerUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: CrossPromoBannerWhereUniqueInput;
};

export type CrossPromoBannerUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: CrossPromoBannerUpdateInput;
  /** Unique document search */
  where: CrossPromoBannerWhereUniqueInput;
};

export type CrossPromoBannerUpsertInput = {
  /** Create document if it didn't exist */
  create: CrossPromoBannerCreateInput;
  /** Update document if it exists */
  update: CrossPromoBannerUpdateInput;
};

export type CrossPromoBannerUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<CrossPromoBannerUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: CrossPromoBannerWhereUniqueInput;
};

export type CrossPromoBannerUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: CrossPromoBannerUpsertInput;
  /** Unique document search */
  where: CrossPromoBannerWhereUniqueInput;
};

/** Identifies documents */
export type CrossPromoBannerWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<CrossPromoBannerWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<CrossPromoBannerWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<CrossPromoBannerWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  copyright?: InputMaybe<TextWhereInput>;
  description?: InputMaybe<TextWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  image?: InputMaybe<AssetWhereInput>;
  logo?: InputMaybe<AssetWhereInput>;
  product?: InputMaybe<ProductWhereInput>;
  subDescription?: InputMaybe<TextWhereInput>;
  title?: InputMaybe<TextWhereInput>;
};

/** References CrossPromoBanner record uniquely */
export type CrossPromoBannerWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export enum Currency {
  Cad = 'CAD',
  Eur = 'EUR',
  Gbp = 'GBP',
  Usd = 'USD',
}

export type DateRange = {
  __typename?: 'DateRange';
  endDate: Scalars['Date'];
  /** The unique identifier */
  id: Scalars['ID'];
  /** System stage field */
  stage: Stage;
  startDate: Scalars['Date'];
};

export type DateRangeConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: DateRangeWhereUniqueInput;
};

/** A connection to a list of items. */
export type DateRangeConnection = {
  __typename?: 'DateRangeConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<DateRangeEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type DateRangeCreateInput = {
  endDate: Scalars['Date'];
  startDate: Scalars['Date'];
};

export type DateRangeCreateManyInlineInput = {
  /** Create and connect multiple existing DateRange documents */
  create?: InputMaybe<Array<DateRangeCreateInput>>;
};

export type DateRangeCreateOneInlineInput = {
  /** Create and connect one DateRange document */
  create?: InputMaybe<DateRangeCreateInput>;
};

export type DateRangeCreateWithPositionInput = {
  /** Document to create */
  data: DateRangeCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type DateRangeEdge = {
  __typename?: 'DateRangeEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: DateRange;
};

/** Identifies documents */
export type DateRangeManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<DateRangeWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<DateRangeWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<DateRangeWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['Date']>;
  /** All values greater than the given value. */
  endDate_gt?: InputMaybe<Scalars['Date']>;
  /** All values greater than or equal the given value. */
  endDate_gte?: InputMaybe<Scalars['Date']>;
  /** All values that are contained in given list. */
  endDate_in?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  /** All values less than the given value. */
  endDate_lt?: InputMaybe<Scalars['Date']>;
  /** All values less than or equal the given value. */
  endDate_lte?: InputMaybe<Scalars['Date']>;
  /** Any other value that exists and is not equal to the given value. */
  endDate_not?: InputMaybe<Scalars['Date']>;
  /** All values that are not contained in given list. */
  endDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  startDate?: InputMaybe<Scalars['Date']>;
  /** All values greater than the given value. */
  startDate_gt?: InputMaybe<Scalars['Date']>;
  /** All values greater than or equal the given value. */
  startDate_gte?: InputMaybe<Scalars['Date']>;
  /** All values that are contained in given list. */
  startDate_in?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  /** All values less than the given value. */
  startDate_lt?: InputMaybe<Scalars['Date']>;
  /** All values less than or equal the given value. */
  startDate_lte?: InputMaybe<Scalars['Date']>;
  /** Any other value that exists and is not equal to the given value. */
  startDate_not?: InputMaybe<Scalars['Date']>;
  /** All values that are not contained in given list. */
  startDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
};

export enum DateRangeOrderByInput {
  EndDateAsc = 'endDate_ASC',
  EndDateDesc = 'endDate_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  StartDateAsc = 'startDate_ASC',
  StartDateDesc = 'startDate_DESC',
}

export type DateRangeParent = PromoTicketText;

export type DateRangeParentConnectInput = {
  PromoTicketText?: InputMaybe<PromoTicketTextConnectInput>;
};

export type DateRangeParentCreateInput = {
  PromoTicketText?: InputMaybe<PromoTicketTextCreateInput>;
};

export type DateRangeParentCreateManyInlineInput = {
  /** Create and connect multiple existing DateRangeParent documents */
  create?: InputMaybe<Array<DateRangeParentCreateInput>>;
};

export type DateRangeParentCreateOneInlineInput = {
  /** Create and connect one DateRangeParent document */
  create?: InputMaybe<DateRangeParentCreateInput>;
};

export type DateRangeParentCreateWithPositionInput = {
  PromoTicketText?: InputMaybe<PromoTicketTextCreateWithPositionInput>;
};

export type DateRangeParentUpdateInput = {
  PromoTicketText?: InputMaybe<PromoTicketTextUpdateInput>;
};

export type DateRangeParentUpdateManyInlineInput = {
  /** Create and connect multiple DateRangeParent component instances */
  create?: InputMaybe<Array<DateRangeParentCreateWithPositionInput>>;
  /** Delete multiple DateRangeParent documents */
  delete?: InputMaybe<Array<DateRangeParentWhereUniqueInput>>;
  /** Update multiple DateRangeParent component instances */
  update?: InputMaybe<Array<DateRangeParentUpdateWithNestedWhereUniqueAndPositionInput>>;
  /** Upsert multiple DateRangeParent component instances */
  upsert?: InputMaybe<Array<DateRangeParentUpsertWithNestedWhereUniqueAndPositionInput>>;
};

export type DateRangeParentUpdateManyWithNestedWhereInput = {
  PromoTicketText?: InputMaybe<PromoTicketTextUpdateManyWithNestedWhereInput>;
};

export type DateRangeParentUpdateOneInlineInput = {
  /** Create and connect one DateRangeParent document */
  create?: InputMaybe<DateRangeParentCreateInput>;
  /** Delete currently connected DateRangeParent document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single DateRangeParent document */
  update?: InputMaybe<DateRangeParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single DateRangeParent document */
  upsert?: InputMaybe<DateRangeParentUpsertWithNestedWhereUniqueInput>;
};

export type DateRangeParentUpdateWithNestedWhereUniqueAndPositionInput = {
  PromoTicketText?: InputMaybe<PromoTicketTextUpdateWithNestedWhereUniqueAndPositionInput>;
};

export type DateRangeParentUpdateWithNestedWhereUniqueInput = {
  PromoTicketText?: InputMaybe<PromoTicketTextUpdateWithNestedWhereUniqueInput>;
};

export type DateRangeParentUpsertWithNestedWhereUniqueAndPositionInput = {
  PromoTicketText?: InputMaybe<PromoTicketTextUpsertWithNestedWhereUniqueAndPositionInput>;
};

export type DateRangeParentUpsertWithNestedWhereUniqueInput = {
  PromoTicketText?: InputMaybe<PromoTicketTextUpsertWithNestedWhereUniqueInput>;
};

export type DateRangeParentWhereInput = {
  PromoTicketText?: InputMaybe<PromoTicketTextWhereInput>;
};

export type DateRangeParentWhereUniqueInput = {
  PromoTicketText?: InputMaybe<PromoTicketTextWhereUniqueInput>;
};

export type DateRangeUpdateInput = {
  endDate?: InputMaybe<Scalars['Date']>;
  startDate?: InputMaybe<Scalars['Date']>;
};

export type DateRangeUpdateManyInlineInput = {
  /** Create and connect multiple DateRange component instances */
  create?: InputMaybe<Array<DateRangeCreateWithPositionInput>>;
  /** Delete multiple DateRange documents */
  delete?: InputMaybe<Array<DateRangeWhereUniqueInput>>;
  /** Update multiple DateRange component instances */
  update?: InputMaybe<Array<DateRangeUpdateWithNestedWhereUniqueAndPositionInput>>;
  /** Upsert multiple DateRange component instances */
  upsert?: InputMaybe<Array<DateRangeUpsertWithNestedWhereUniqueAndPositionInput>>;
};

export type DateRangeUpdateManyInput = {
  endDate?: InputMaybe<Scalars['Date']>;
  startDate?: InputMaybe<Scalars['Date']>;
};

export type DateRangeUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: DateRangeUpdateManyInput;
  /** Document search */
  where: DateRangeWhereInput;
};

export type DateRangeUpdateOneInlineInput = {
  /** Create and connect one DateRange document */
  create?: InputMaybe<DateRangeCreateInput>;
  /** Delete currently connected DateRange document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single DateRange document */
  update?: InputMaybe<DateRangeUpdateWithNestedWhereUniqueInput>;
  /** Upsert single DateRange document */
  upsert?: InputMaybe<DateRangeUpsertWithNestedWhereUniqueInput>;
};

export type DateRangeUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<DateRangeUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: DateRangeWhereUniqueInput;
};

export type DateRangeUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: DateRangeUpdateInput;
  /** Unique document search */
  where: DateRangeWhereUniqueInput;
};

export type DateRangeUpsertInput = {
  /** Create document if it didn't exist */
  create: DateRangeCreateInput;
  /** Update document if it exists */
  update: DateRangeUpdateInput;
};

export type DateRangeUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<DateRangeUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: DateRangeWhereUniqueInput;
};

export type DateRangeUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: DateRangeUpsertInput;
  /** Unique document search */
  where: DateRangeWhereUniqueInput;
};

/** Identifies documents */
export type DateRangeWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<DateRangeWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<DateRangeWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<DateRangeWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['Date']>;
  /** All values greater than the given value. */
  endDate_gt?: InputMaybe<Scalars['Date']>;
  /** All values greater than or equal the given value. */
  endDate_gte?: InputMaybe<Scalars['Date']>;
  /** All values that are contained in given list. */
  endDate_in?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  /** All values less than the given value. */
  endDate_lt?: InputMaybe<Scalars['Date']>;
  /** All values less than or equal the given value. */
  endDate_lte?: InputMaybe<Scalars['Date']>;
  /** Any other value that exists and is not equal to the given value. */
  endDate_not?: InputMaybe<Scalars['Date']>;
  /** All values that are not contained in given list. */
  endDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  startDate?: InputMaybe<Scalars['Date']>;
  /** All values greater than the given value. */
  startDate_gt?: InputMaybe<Scalars['Date']>;
  /** All values greater than or equal the given value. */
  startDate_gte?: InputMaybe<Scalars['Date']>;
  /** All values that are contained in given list. */
  startDate_in?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  /** All values less than the given value. */
  startDate_lt?: InputMaybe<Scalars['Date']>;
  /** All values less than or equal the given value. */
  startDate_lte?: InputMaybe<Scalars['Date']>;
  /** Any other value that exists and is not equal to the given value. */
  startDate_not?: InputMaybe<Scalars['Date']>;
  /** All values that are not contained in given list. */
  startDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
};

/** References DateRange record uniquely */
export type DateRangeWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type DetailBlock = Node & {
  __typename?: 'DetailBlock';
  attractions: Array<Attraction>;
  body: Array<Text>;
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** User that created this document */
  createdBy: Maybe<User>;
  /** Get the document in other stages */
  documentInStages: Array<DetailBlock>;
  /** List of DetailBlock versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID'];
  internalTitle: Scalars['String'];
  products: Array<Product>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt: Maybe<Scalars['DateTime']>;
  /** User that last published this document */
  publishedBy: Maybe<User>;
  scheduledIn: Array<ScheduledOperation>;
  /** System stage field */
  stage: Stage;
  title: Maybe<Text>;
  type: Maybe<DetailType>;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** User that last updated this document */
  updatedBy: Maybe<User>;
};

export type DetailBlockAttractionsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<AttractionOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AttractionWhereInput>;
};

export type DetailBlockBodyArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<TextOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<TextWhereInput>;
};

export type DetailBlockCreatedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type DetailBlockDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};

export type DetailBlockHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride: InputMaybe<Stage>;
};

export type DetailBlockProductsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<ProductOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ProductWhereInput>;
};

export type DetailBlockPublishedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type DetailBlockScheduledInArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ScheduledOperationWhereInput>;
};

export type DetailBlockTitleArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type DetailBlockUpdatedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type DetailBlockConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: DetailBlockWhereUniqueInput;
};

/** A connection to a list of items. */
export type DetailBlockConnection = {
  __typename?: 'DetailBlockConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<DetailBlockEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type DetailBlockCreateInput = {
  attractions?: InputMaybe<AttractionCreateManyInlineInput>;
  body?: InputMaybe<TextCreateManyInlineInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  internalTitle: Scalars['String'];
  products?: InputMaybe<ProductCreateManyInlineInput>;
  title?: InputMaybe<TextCreateOneInlineInput>;
  type?: InputMaybe<DetailType>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type DetailBlockCreateManyInlineInput = {
  /** Connect multiple existing DetailBlock documents */
  connect?: InputMaybe<Array<DetailBlockWhereUniqueInput>>;
  /** Create and connect multiple existing DetailBlock documents */
  create?: InputMaybe<Array<DetailBlockCreateInput>>;
};

export type DetailBlockCreateOneInlineInput = {
  /** Connect one existing DetailBlock document */
  connect?: InputMaybe<DetailBlockWhereUniqueInput>;
  /** Create and connect one DetailBlock document */
  create?: InputMaybe<DetailBlockCreateInput>;
};

/** An edge in a connection. */
export type DetailBlockEdge = {
  __typename?: 'DetailBlockEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: DetailBlock;
};

/** Identifies documents */
export type DetailBlockManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<DetailBlockWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<DetailBlockWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<DetailBlockWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  attractions_every?: InputMaybe<AttractionWhereInput>;
  attractions_none?: InputMaybe<AttractionWhereInput>;
  attractions_some?: InputMaybe<AttractionWhereInput>;
  body_every?: InputMaybe<TextWhereInput>;
  body_none?: InputMaybe<TextWhereInput>;
  body_some?: InputMaybe<TextWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<DetailBlockWhereStageInput>;
  documentInStages_none?: InputMaybe<DetailBlockWhereStageInput>;
  documentInStages_some?: InputMaybe<DetailBlockWhereStageInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  internalTitle?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  internalTitle_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  internalTitle_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  internalTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  internalTitle_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  internalTitle_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  internalTitle_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  internalTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  internalTitle_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  internalTitle_starts_with?: InputMaybe<Scalars['String']>;
  products_every?: InputMaybe<ProductWhereInput>;
  products_none?: InputMaybe<ProductWhereInput>;
  products_some?: InputMaybe<ProductWhereInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  title?: InputMaybe<TextWhereInput>;
  type?: InputMaybe<DetailType>;
  /** All values that are contained in given list. */
  type_in?: InputMaybe<Array<InputMaybe<DetailType>>>;
  /** Any other value that exists and is not equal to the given value. */
  type_not?: InputMaybe<DetailType>;
  /** All values that are not contained in given list. */
  type_not_in?: InputMaybe<Array<InputMaybe<DetailType>>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum DetailBlockOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  InternalTitleAsc = 'internalTitle_ASC',
  InternalTitleDesc = 'internalTitle_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type DetailBlockUpdateInput = {
  attractions?: InputMaybe<AttractionUpdateManyInlineInput>;
  body?: InputMaybe<TextUpdateManyInlineInput>;
  internalTitle?: InputMaybe<Scalars['String']>;
  products?: InputMaybe<ProductUpdateManyInlineInput>;
  title?: InputMaybe<TextUpdateOneInlineInput>;
  type?: InputMaybe<DetailType>;
};

export type DetailBlockUpdateManyInlineInput = {
  /** Connect multiple existing DetailBlock documents */
  connect?: InputMaybe<Array<DetailBlockConnectInput>>;
  /** Create and connect multiple DetailBlock documents */
  create?: InputMaybe<Array<DetailBlockCreateInput>>;
  /** Delete multiple DetailBlock documents */
  delete?: InputMaybe<Array<DetailBlockWhereUniqueInput>>;
  /** Disconnect multiple DetailBlock documents */
  disconnect?: InputMaybe<Array<DetailBlockWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing DetailBlock documents */
  set?: InputMaybe<Array<DetailBlockWhereUniqueInput>>;
  /** Update multiple DetailBlock documents */
  update?: InputMaybe<Array<DetailBlockUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple DetailBlock documents */
  upsert?: InputMaybe<Array<DetailBlockUpsertWithNestedWhereUniqueInput>>;
};

export type DetailBlockUpdateManyInput = {
  internalTitle?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<DetailType>;
};

export type DetailBlockUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: DetailBlockUpdateManyInput;
  /** Document search */
  where: DetailBlockWhereInput;
};

export type DetailBlockUpdateOneInlineInput = {
  /** Connect existing DetailBlock document */
  connect?: InputMaybe<DetailBlockWhereUniqueInput>;
  /** Create and connect one DetailBlock document */
  create?: InputMaybe<DetailBlockCreateInput>;
  /** Delete currently connected DetailBlock document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected DetailBlock document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single DetailBlock document */
  update?: InputMaybe<DetailBlockUpdateWithNestedWhereUniqueInput>;
  /** Upsert single DetailBlock document */
  upsert?: InputMaybe<DetailBlockUpsertWithNestedWhereUniqueInput>;
};

export type DetailBlockUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: DetailBlockUpdateInput;
  /** Unique document search */
  where: DetailBlockWhereUniqueInput;
};

export type DetailBlockUpsertInput = {
  /** Create document if it didn't exist */
  create: DetailBlockCreateInput;
  /** Update document if it exists */
  update: DetailBlockUpdateInput;
};

export type DetailBlockUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: DetailBlockUpsertInput;
  /** Unique document search */
  where: DetailBlockWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type DetailBlockWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']>;
};

/** Identifies documents */
export type DetailBlockWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<DetailBlockWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<DetailBlockWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<DetailBlockWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  attractions_every?: InputMaybe<AttractionWhereInput>;
  attractions_none?: InputMaybe<AttractionWhereInput>;
  attractions_some?: InputMaybe<AttractionWhereInput>;
  body_every?: InputMaybe<TextWhereInput>;
  body_none?: InputMaybe<TextWhereInput>;
  body_some?: InputMaybe<TextWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<DetailBlockWhereStageInput>;
  documentInStages_none?: InputMaybe<DetailBlockWhereStageInput>;
  documentInStages_some?: InputMaybe<DetailBlockWhereStageInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  internalTitle?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  internalTitle_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  internalTitle_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  internalTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  internalTitle_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  internalTitle_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  internalTitle_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  internalTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  internalTitle_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  internalTitle_starts_with?: InputMaybe<Scalars['String']>;
  products_every?: InputMaybe<ProductWhereInput>;
  products_none?: InputMaybe<ProductWhereInput>;
  products_some?: InputMaybe<ProductWhereInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  title?: InputMaybe<TextWhereInput>;
  type?: InputMaybe<DetailType>;
  /** All values that are contained in given list. */
  type_in?: InputMaybe<Array<InputMaybe<DetailType>>>;
  /** Any other value that exists and is not equal to the given value. */
  type_not?: InputMaybe<DetailType>;
  /** All values that are not contained in given list. */
  type_not_in?: InputMaybe<Array<InputMaybe<DetailType>>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type DetailBlockWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<DetailBlockWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<DetailBlockWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<DetailBlockWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<DetailBlockWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References DetailBlock record uniquely */
export type DetailBlockWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export enum DetailType {
  Reservations = 'reservations',
}

export enum DocumentFileTypes {
  Doc = 'doc',
  Docx = 'docx',
  Html = 'html',
  Jpg = 'jpg',
  Odp = 'odp',
  Ods = 'ods',
  Odt = 'odt',
  Pdf = 'pdf',
  Png = 'png',
  Ppt = 'ppt',
  Pptx = 'pptx',
  Svg = 'svg',
  Txt = 'txt',
  Webp = 'webp',
  Xls = 'xls',
  Xlsx = 'xlsx',
}

export type DocumentOutputInput = {
  /**
   * Transforms a document into a desired file type.
   * See this matrix for format support:
   *
   * PDF:	jpg, odp, ods, odt, png, svg, txt, and webp
   * DOC:	docx, html, jpg, odt, pdf, png, svg, txt, and webp
   * DOCX:	doc, html, jpg, odt, pdf, png, svg, txt, and webp
   * ODT:	doc, docx, html, jpg, pdf, png, svg, txt, and webp
   * XLS:	jpg, pdf, ods, png, svg, xlsx, and webp
   * XLSX:	jpg, pdf, ods, png, svg, xls, and webp
   * ODS:	jpg, pdf, png, xls, svg, xlsx, and webp
   * PPT:	jpg, odp, pdf, png, svg, pptx, and webp
   * PPTX:	jpg, odp, pdf, png, svg, ppt, and webp
   * ODP:	jpg, pdf, png, ppt, svg, pptx, and webp
   * BMP:	jpg, odp, ods, odt, pdf, png, svg, and webp
   * GIF:	jpg, odp, ods, odt, pdf, png, svg, and webp
   * JPG:	jpg, odp, ods, odt, pdf, png, svg, and webp
   * PNG:	jpg, odp, ods, odt, pdf, png, svg, and webp
   * WEBP:	jpg, odp, ods, odt, pdf, png, svg, and webp
   * TIFF:	jpg, odp, ods, odt, pdf, png, svg, and webp
   * AI:	    jpg, odp, ods, odt, pdf, png, svg, and webp
   * PSD:	jpg, odp, ods, odt, pdf, png, svg, and webp
   * SVG:	jpg, odp, ods, odt, pdf, png, and webp
   * HTML:	jpg, odt, pdf, svg, txt, and webp
   * TXT:	jpg, html, odt, pdf, svg, and webp
   */
  format?: InputMaybe<DocumentFileTypes>;
};

/** Transformations for Documents */
export type DocumentTransformationInput = {
  /** Changes the output for the file. */
  output?: InputMaybe<DocumentOutputInput>;
};

export type DocumentVersion = {
  __typename?: 'DocumentVersion';
  createdAt: Scalars['DateTime'];
  data: Maybe<Scalars['Json']>;
  id: Scalars['ID'];
  revision: Scalars['Int'];
  stage: Stage;
};

export type GeneralReservation = Node & {
  __typename?: 'GeneralReservation';
  attractionText: Array<ReservationText>;
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** User that created this document */
  createdBy: Maybe<User>;
  /** Get the document in other stages */
  documentInStages: Array<GeneralReservation>;
  generalText: Array<ReservationText>;
  /** List of GeneralReservation versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID'];
  productText: Array<ReservationText>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt: Maybe<Scalars['DateTime']>;
  /** User that last published this document */
  publishedBy: Maybe<User>;
  scheduledIn: Array<ScheduledOperation>;
  /** System stage field */
  stage: Stage;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** User that last updated this document */
  updatedBy: Maybe<User>;
};

export type GeneralReservationAttractionTextArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<ReservationTextOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ReservationTextWhereInput>;
};

export type GeneralReservationCreatedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type GeneralReservationDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};

export type GeneralReservationGeneralTextArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<ReservationTextOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ReservationTextWhereInput>;
};

export type GeneralReservationHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride: InputMaybe<Stage>;
};

export type GeneralReservationProductTextArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<ReservationTextOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ReservationTextWhereInput>;
};

export type GeneralReservationPublishedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type GeneralReservationScheduledInArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ScheduledOperationWhereInput>;
};

export type GeneralReservationUpdatedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type GeneralReservationConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: GeneralReservationWhereUniqueInput;
};

/** A connection to a list of items. */
export type GeneralReservationConnection = {
  __typename?: 'GeneralReservationConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<GeneralReservationEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type GeneralReservationCreateInput = {
  attractionText?: InputMaybe<ReservationTextCreateManyInlineInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  generalText?: InputMaybe<ReservationTextCreateManyInlineInput>;
  productText?: InputMaybe<ReservationTextCreateManyInlineInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type GeneralReservationCreateManyInlineInput = {
  /** Connect multiple existing GeneralReservation documents */
  connect?: InputMaybe<Array<GeneralReservationWhereUniqueInput>>;
  /** Create and connect multiple existing GeneralReservation documents */
  create?: InputMaybe<Array<GeneralReservationCreateInput>>;
};

export type GeneralReservationCreateOneInlineInput = {
  /** Connect one existing GeneralReservation document */
  connect?: InputMaybe<GeneralReservationWhereUniqueInput>;
  /** Create and connect one GeneralReservation document */
  create?: InputMaybe<GeneralReservationCreateInput>;
};

/** An edge in a connection. */
export type GeneralReservationEdge = {
  __typename?: 'GeneralReservationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: GeneralReservation;
};

/** Identifies documents */
export type GeneralReservationManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<GeneralReservationWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<GeneralReservationWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<GeneralReservationWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  attractionText_every?: InputMaybe<ReservationTextWhereInput>;
  attractionText_none?: InputMaybe<ReservationTextWhereInput>;
  attractionText_some?: InputMaybe<ReservationTextWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<GeneralReservationWhereStageInput>;
  documentInStages_none?: InputMaybe<GeneralReservationWhereStageInput>;
  documentInStages_some?: InputMaybe<GeneralReservationWhereStageInput>;
  generalText_every?: InputMaybe<ReservationTextWhereInput>;
  generalText_none?: InputMaybe<ReservationTextWhereInput>;
  generalText_some?: InputMaybe<ReservationTextWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  productText_every?: InputMaybe<ReservationTextWhereInput>;
  productText_none?: InputMaybe<ReservationTextWhereInput>;
  productText_some?: InputMaybe<ReservationTextWhereInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum GeneralReservationOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type GeneralReservationUpdateInput = {
  attractionText?: InputMaybe<ReservationTextUpdateManyInlineInput>;
  generalText?: InputMaybe<ReservationTextUpdateManyInlineInput>;
  productText?: InputMaybe<ReservationTextUpdateManyInlineInput>;
};

export type GeneralReservationUpdateManyInlineInput = {
  /** Connect multiple existing GeneralReservation documents */
  connect?: InputMaybe<Array<GeneralReservationConnectInput>>;
  /** Create and connect multiple GeneralReservation documents */
  create?: InputMaybe<Array<GeneralReservationCreateInput>>;
  /** Delete multiple GeneralReservation documents */
  delete?: InputMaybe<Array<GeneralReservationWhereUniqueInput>>;
  /** Disconnect multiple GeneralReservation documents */
  disconnect?: InputMaybe<Array<GeneralReservationWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing GeneralReservation documents */
  set?: InputMaybe<Array<GeneralReservationWhereUniqueInput>>;
  /** Update multiple GeneralReservation documents */
  update?: InputMaybe<Array<GeneralReservationUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple GeneralReservation documents */
  upsert?: InputMaybe<Array<GeneralReservationUpsertWithNestedWhereUniqueInput>>;
};

export type GeneralReservationUpdateManyInput = {
  /** No fields in updateMany data input */
  _?: InputMaybe<Scalars['String']>;
};

export type GeneralReservationUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: GeneralReservationUpdateManyInput;
  /** Document search */
  where: GeneralReservationWhereInput;
};

export type GeneralReservationUpdateOneInlineInput = {
  /** Connect existing GeneralReservation document */
  connect?: InputMaybe<GeneralReservationWhereUniqueInput>;
  /** Create and connect one GeneralReservation document */
  create?: InputMaybe<GeneralReservationCreateInput>;
  /** Delete currently connected GeneralReservation document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected GeneralReservation document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single GeneralReservation document */
  update?: InputMaybe<GeneralReservationUpdateWithNestedWhereUniqueInput>;
  /** Upsert single GeneralReservation document */
  upsert?: InputMaybe<GeneralReservationUpsertWithNestedWhereUniqueInput>;
};

export type GeneralReservationUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: GeneralReservationUpdateInput;
  /** Unique document search */
  where: GeneralReservationWhereUniqueInput;
};

export type GeneralReservationUpsertInput = {
  /** Create document if it didn't exist */
  create: GeneralReservationCreateInput;
  /** Update document if it exists */
  update: GeneralReservationUpdateInput;
};

export type GeneralReservationUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: GeneralReservationUpsertInput;
  /** Unique document search */
  where: GeneralReservationWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type GeneralReservationWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']>;
};

/** Identifies documents */
export type GeneralReservationWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<GeneralReservationWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<GeneralReservationWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<GeneralReservationWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  attractionText_every?: InputMaybe<ReservationTextWhereInput>;
  attractionText_none?: InputMaybe<ReservationTextWhereInput>;
  attractionText_some?: InputMaybe<ReservationTextWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<GeneralReservationWhereStageInput>;
  documentInStages_none?: InputMaybe<GeneralReservationWhereStageInput>;
  documentInStages_some?: InputMaybe<GeneralReservationWhereStageInput>;
  generalText_every?: InputMaybe<ReservationTextWhereInput>;
  generalText_none?: InputMaybe<ReservationTextWhereInput>;
  generalText_some?: InputMaybe<ReservationTextWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  productText_every?: InputMaybe<ReservationTextWhereInput>;
  productText_none?: InputMaybe<ReservationTextWhereInput>;
  productText_some?: InputMaybe<ReservationTextWhereInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type GeneralReservationWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<GeneralReservationWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<GeneralReservationWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<GeneralReservationWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<GeneralReservationWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References GeneralReservation record uniquely */
export type GeneralReservationWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type Image = Node & {
  __typename?: 'Image';
  attraction: Maybe<Attraction>;
  attractionVariants: Array<AttractionVariant>;
  caption: Maybe<Scalars['String']>;
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** User that created this document */
  createdBy: Maybe<User>;
  credit: Maybe<Scalars['String']>;
  /** Get the document in other stages */
  documentInStages: Array<Image>;
  /** List of Image versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID'];
  image: Asset;
  internalTags: Array<Scalars['String']>;
  internalTitle: Maybe<Scalars['String']>;
  key: Maybe<Scalars['String']>;
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<Image>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt: Maybe<Scalars['DateTime']>;
  /** User that last published this document */
  publishedBy: Maybe<User>;
  qabu: Maybe<Scalars['Json']>;
  scheduledIn: Array<ScheduledOperation>;
  /** System stage field */
  stage: Stage;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** User that last updated this document */
  updatedBy: Maybe<User>;
};

export type ImageAttractionArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type ImageAttractionVariantsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AttractionVariantWhereInput>;
};

export type ImageCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type ImageCreatedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type ImageDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};

export type ImageHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride: InputMaybe<Stage>;
};

export type ImageImageArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type ImageLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};

export type ImagePublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type ImagePublishedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type ImageScheduledInArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ScheduledOperationWhereInput>;
};

export type ImageUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type ImageUpdatedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type ImageConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: ImageWhereUniqueInput;
};

/** A connection to a list of items. */
export type ImageConnection = {
  __typename?: 'ImageConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<ImageEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type ImageCreateInput = {
  attraction?: InputMaybe<AttractionCreateOneInlineInput>;
  attractionVariants?: InputMaybe<AttractionVariantCreateManyInlineInput>;
  /** caption input for default locale (en) */
  caption?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** credit input for default locale (en) */
  credit?: InputMaybe<Scalars['String']>;
  image: AssetCreateOneInlineInput;
  internalTags?: InputMaybe<Array<Scalars['String']>>;
  internalTitle?: InputMaybe<Scalars['String']>;
  key?: InputMaybe<Scalars['String']>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<ImageCreateLocalizationsInput>;
  qabu?: InputMaybe<Scalars['Json']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ImageCreateLocalizationDataInput = {
  caption?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  credit?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ImageCreateLocalizationInput = {
  /** Localization input */
  data: ImageCreateLocalizationDataInput;
  locale: Locale;
};

export type ImageCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<ImageCreateLocalizationInput>>;
};

export type ImageCreateManyInlineInput = {
  /** Connect multiple existing Image documents */
  connect?: InputMaybe<Array<ImageWhereUniqueInput>>;
  /** Create and connect multiple existing Image documents */
  create?: InputMaybe<Array<ImageCreateInput>>;
};

export type ImageCreateOneInlineInput = {
  /** Connect one existing Image document */
  connect?: InputMaybe<ImageWhereUniqueInput>;
  /** Create and connect one Image document */
  create?: InputMaybe<ImageCreateInput>;
};

/** An edge in a connection. */
export type ImageEdge = {
  __typename?: 'ImageEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Image;
};

export enum ImageFit {
  /** Resizes the image to fit within the specified parameters without distorting, cropping, or changing the aspect ratio. */
  Clip = 'clip',
  /** Resizes the image to fit the specified parameters exactly by removing any parts of the image that don't fit within the boundaries. */
  Crop = 'crop',
  /** Resizes the image to fit within the parameters, but as opposed to 'fit:clip' will not scale the image if the image is smaller than the output size. */
  Max = 'max',
  /** Resizes the image to fit the specified parameters exactly by scaling the image to the desired size. The aspect ratio of the image is not respected and the image can be distorted using this method. */
  Scale = 'scale',
}

/** Identifies documents */
export type ImageManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ImageWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ImageWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ImageWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  attraction?: InputMaybe<AttractionWhereInput>;
  attractionVariants_every?: InputMaybe<AttractionVariantWhereInput>;
  attractionVariants_none?: InputMaybe<AttractionVariantWhereInput>;
  attractionVariants_some?: InputMaybe<AttractionVariantWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<ImageWhereStageInput>;
  documentInStages_none?: InputMaybe<ImageWhereStageInput>;
  documentInStages_some?: InputMaybe<ImageWhereStageInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  image?: InputMaybe<AssetWhereInput>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  internalTags?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains *all* items provided to the filter */
  internalTags_contains_all?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  internalTags_contains_none?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains at least one item provided to the filter */
  internalTags_contains_some?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  internalTags_not?: InputMaybe<Array<Scalars['String']>>;
  internalTitle?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  internalTitle_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  internalTitle_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  internalTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  internalTitle_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  internalTitle_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  internalTitle_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  internalTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  internalTitle_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  internalTitle_starts_with?: InputMaybe<Scalars['String']>;
  key?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  key_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  key_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  key_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  key_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  key_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  key_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  key_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  key_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  key_starts_with?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  /** All values containing the given json path. */
  qabu_json_path_exists?: InputMaybe<Scalars['String']>;
  /**
   * Recursively tries to find the provided JSON scalar value inside the field.
   * It does use an exact match when comparing values.
   * If you pass `null` as value the filter will be ignored.
   * Note: This filter fails if you try to look for a non scalar JSON value!
   */
  qabu_value_recursive?: InputMaybe<Scalars['Json']>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum ImageOrderByInput {
  CaptionAsc = 'caption_ASC',
  CaptionDesc = 'caption_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  CreditAsc = 'credit_ASC',
  CreditDesc = 'credit_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  InternalTagsAsc = 'internalTags_ASC',
  InternalTagsDesc = 'internalTags_DESC',
  InternalTitleAsc = 'internalTitle_ASC',
  InternalTitleDesc = 'internalTitle_DESC',
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type ImageResizeInput = {
  /** The default value for the fit parameter is fit:clip. */
  fit?: InputMaybe<ImageFit>;
  /** The height in pixels to resize the image to. The value must be an integer from 1 to 10000. */
  height?: InputMaybe<Scalars['Int']>;
  /** The width in pixels to resize the image to. The value must be an integer from 1 to 10000. */
  width?: InputMaybe<Scalars['Int']>;
};

/** Transformations for Images */
export type ImageTransformationInput = {
  /** Resizes the image */
  resize?: InputMaybe<ImageResizeInput>;
};

export type ImageUpdateInput = {
  attraction?: InputMaybe<AttractionUpdateOneInlineInput>;
  attractionVariants?: InputMaybe<AttractionVariantUpdateManyInlineInput>;
  /** caption input for default locale (en) */
  caption?: InputMaybe<Scalars['String']>;
  /** credit input for default locale (en) */
  credit?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<AssetUpdateOneInlineInput>;
  internalTags?: InputMaybe<Array<Scalars['String']>>;
  internalTitle?: InputMaybe<Scalars['String']>;
  key?: InputMaybe<Scalars['String']>;
  /** Manage document localizations */
  localizations?: InputMaybe<ImageUpdateLocalizationsInput>;
  qabu?: InputMaybe<Scalars['Json']>;
};

export type ImageUpdateLocalizationDataInput = {
  caption?: InputMaybe<Scalars['String']>;
  credit?: InputMaybe<Scalars['String']>;
};

export type ImageUpdateLocalizationInput = {
  data: ImageUpdateLocalizationDataInput;
  locale: Locale;
};

export type ImageUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<ImageCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<ImageUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<ImageUpsertLocalizationInput>>;
};

export type ImageUpdateManyInlineInput = {
  /** Connect multiple existing Image documents */
  connect?: InputMaybe<Array<ImageConnectInput>>;
  /** Create and connect multiple Image documents */
  create?: InputMaybe<Array<ImageCreateInput>>;
  /** Delete multiple Image documents */
  delete?: InputMaybe<Array<ImageWhereUniqueInput>>;
  /** Disconnect multiple Image documents */
  disconnect?: InputMaybe<Array<ImageWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing Image documents */
  set?: InputMaybe<Array<ImageWhereUniqueInput>>;
  /** Update multiple Image documents */
  update?: InputMaybe<Array<ImageUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple Image documents */
  upsert?: InputMaybe<Array<ImageUpsertWithNestedWhereUniqueInput>>;
};

export type ImageUpdateManyInput = {
  /** caption input for default locale (en) */
  caption?: InputMaybe<Scalars['String']>;
  /** credit input for default locale (en) */
  credit?: InputMaybe<Scalars['String']>;
  internalTags?: InputMaybe<Array<Scalars['String']>>;
  internalTitle?: InputMaybe<Scalars['String']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<ImageUpdateManyLocalizationsInput>;
  qabu?: InputMaybe<Scalars['Json']>;
};

export type ImageUpdateManyLocalizationDataInput = {
  caption?: InputMaybe<Scalars['String']>;
  credit?: InputMaybe<Scalars['String']>;
};

export type ImageUpdateManyLocalizationInput = {
  data: ImageUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type ImageUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<ImageUpdateManyLocalizationInput>>;
};

export type ImageUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: ImageUpdateManyInput;
  /** Document search */
  where: ImageWhereInput;
};

export type ImageUpdateOneInlineInput = {
  /** Connect existing Image document */
  connect?: InputMaybe<ImageWhereUniqueInput>;
  /** Create and connect one Image document */
  create?: InputMaybe<ImageCreateInput>;
  /** Delete currently connected Image document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected Image document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single Image document */
  update?: InputMaybe<ImageUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Image document */
  upsert?: InputMaybe<ImageUpsertWithNestedWhereUniqueInput>;
};

export type ImageUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: ImageUpdateInput;
  /** Unique document search */
  where: ImageWhereUniqueInput;
};

export type ImageUpsertInput = {
  /** Create document if it didn't exist */
  create: ImageCreateInput;
  /** Update document if it exists */
  update: ImageUpdateInput;
};

export type ImageUpsertLocalizationInput = {
  create: ImageCreateLocalizationDataInput;
  locale: Locale;
  update: ImageUpdateLocalizationDataInput;
};

export type ImageUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: ImageUpsertInput;
  /** Unique document search */
  where: ImageWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type ImageWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']>;
};

/** Identifies documents */
export type ImageWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ImageWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ImageWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ImageWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  attraction?: InputMaybe<AttractionWhereInput>;
  attractionVariants_every?: InputMaybe<AttractionVariantWhereInput>;
  attractionVariants_none?: InputMaybe<AttractionVariantWhereInput>;
  attractionVariants_some?: InputMaybe<AttractionVariantWhereInput>;
  caption?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  caption_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  caption_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  caption_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  caption_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  caption_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  caption_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  caption_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  caption_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  caption_starts_with?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  credit?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  credit_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  credit_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  credit_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  credit_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  credit_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  credit_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  credit_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  credit_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  credit_starts_with?: InputMaybe<Scalars['String']>;
  documentInStages_every?: InputMaybe<ImageWhereStageInput>;
  documentInStages_none?: InputMaybe<ImageWhereStageInput>;
  documentInStages_some?: InputMaybe<ImageWhereStageInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  image?: InputMaybe<AssetWhereInput>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  internalTags?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains *all* items provided to the filter */
  internalTags_contains_all?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  internalTags_contains_none?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains at least one item provided to the filter */
  internalTags_contains_some?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  internalTags_not?: InputMaybe<Array<Scalars['String']>>;
  internalTitle?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  internalTitle_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  internalTitle_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  internalTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  internalTitle_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  internalTitle_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  internalTitle_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  internalTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  internalTitle_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  internalTitle_starts_with?: InputMaybe<Scalars['String']>;
  key?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  key_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  key_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  key_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  key_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  key_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  key_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  key_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  key_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  key_starts_with?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  /** All values containing the given json path. */
  qabu_json_path_exists?: InputMaybe<Scalars['String']>;
  /**
   * Recursively tries to find the provided JSON scalar value inside the field.
   * It does use an exact match when comparing values.
   * If you pass `null` as value the filter will be ignored.
   * Note: This filter fails if you try to look for a non scalar JSON value!
   */
  qabu_value_recursive?: InputMaybe<Scalars['Json']>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type ImageWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ImageWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ImageWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ImageWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<ImageWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References Image record uniquely */
export type ImageWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
  key?: InputMaybe<Scalars['String']>;
};

export type LandingPage = Node & {
  __typename?: 'LandingPage';
  body: Maybe<Scalars['String']>;
  bodyRich: Maybe<LandingPageBodyRichRichText>;
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** User that created this document */
  createdBy: Maybe<User>;
  /** Get the document in other stages */
  documentInStages: Array<LandingPage>;
  hero: Maybe<Asset>;
  /** List of LandingPage versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID'];
  internalTitle: Scalars['String'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<LandingPage>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt: Maybe<Scalars['DateTime']>;
  /** User that last published this document */
  publishedBy: Maybe<User>;
  scheduledIn: Array<ScheduledOperation>;
  slug: Scalars['String'];
  /** System stage field */
  stage: Stage;
  title: Maybe<Scalars['String']>;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** User that last updated this document */
  updatedBy: Maybe<User>;
};

export type LandingPageCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type LandingPageCreatedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type LandingPageDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};

export type LandingPageHeroArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type LandingPageHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride: InputMaybe<Stage>;
};

export type LandingPageLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};

export type LandingPagePublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type LandingPagePublishedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type LandingPageScheduledInArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ScheduledOperationWhereInput>;
};

export type LandingPageUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type LandingPageUpdatedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type LandingPageBodyRichRichText = {
  __typename?: 'LandingPageBodyRichRichText';
  /** Returns HTMl representation */
  html: Scalars['String'];
  json: Scalars['RichTextAST'];
  /** Returns Markdown representation */
  markdown: Scalars['String'];
  /** @deprecated Please use the 'json' field */
  raw: Scalars['RichTextAST'];
  references: Array<LandingPageBodyRichRichTextEmbeddedTypes>;
  /** Returns plain-text contents of RichText */
  text: Scalars['String'];
};

export type LandingPageBodyRichRichTextReferencesArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
};

export type LandingPageBodyRichRichTextEmbeddedTypes =
  | Article
  | Asset
  | Attraction
  | Product
  | Text;

export type LandingPageConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: LandingPageWhereUniqueInput;
};

/** A connection to a list of items. */
export type LandingPageConnection = {
  __typename?: 'LandingPageConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<LandingPageEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type LandingPageCreateInput = {
  body?: InputMaybe<Scalars['String']>;
  bodyRich?: InputMaybe<Scalars['RichTextAST']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  hero?: InputMaybe<AssetCreateOneInlineInput>;
  internalTitle: Scalars['String'];
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<LandingPageCreateLocalizationsInput>;
  slug: Scalars['String'];
  /** title input for default locale (en) */
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type LandingPageCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type LandingPageCreateLocalizationInput = {
  /** Localization input */
  data: LandingPageCreateLocalizationDataInput;
  locale: Locale;
};

export type LandingPageCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<LandingPageCreateLocalizationInput>>;
};

export type LandingPageCreateManyInlineInput = {
  /** Connect multiple existing LandingPage documents */
  connect?: InputMaybe<Array<LandingPageWhereUniqueInput>>;
  /** Create and connect multiple existing LandingPage documents */
  create?: InputMaybe<Array<LandingPageCreateInput>>;
};

export type LandingPageCreateOneInlineInput = {
  /** Connect one existing LandingPage document */
  connect?: InputMaybe<LandingPageWhereUniqueInput>;
  /** Create and connect one LandingPage document */
  create?: InputMaybe<LandingPageCreateInput>;
};

/** An edge in a connection. */
export type LandingPageEdge = {
  __typename?: 'LandingPageEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: LandingPage;
};

/** Identifies documents */
export type LandingPageManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<LandingPageWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<LandingPageWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<LandingPageWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  body?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  body_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  body_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  body_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  body_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  body_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  body_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  body_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  body_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  body_starts_with?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<LandingPageWhereStageInput>;
  documentInStages_none?: InputMaybe<LandingPageWhereStageInput>;
  documentInStages_some?: InputMaybe<LandingPageWhereStageInput>;
  hero?: InputMaybe<AssetWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  internalTitle?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  internalTitle_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  internalTitle_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  internalTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  internalTitle_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  internalTitle_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  internalTitle_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  internalTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  internalTitle_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  internalTitle_starts_with?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  slug?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  slug_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  slug_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  slug_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  slug_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  slug_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  slug_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  slug_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum LandingPageOrderByInput {
  BodyAsc = 'body_ASC',
  BodyDesc = 'body_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  InternalTitleAsc = 'internalTitle_ASC',
  InternalTitleDesc = 'internalTitle_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type LandingPageUpdateInput = {
  body?: InputMaybe<Scalars['String']>;
  bodyRich?: InputMaybe<Scalars['RichTextAST']>;
  hero?: InputMaybe<AssetUpdateOneInlineInput>;
  internalTitle?: InputMaybe<Scalars['String']>;
  /** Manage document localizations */
  localizations?: InputMaybe<LandingPageUpdateLocalizationsInput>;
  slug?: InputMaybe<Scalars['String']>;
  /** title input for default locale (en) */
  title?: InputMaybe<Scalars['String']>;
};

export type LandingPageUpdateLocalizationDataInput = {
  title?: InputMaybe<Scalars['String']>;
};

export type LandingPageUpdateLocalizationInput = {
  data: LandingPageUpdateLocalizationDataInput;
  locale: Locale;
};

export type LandingPageUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<LandingPageCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<LandingPageUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<LandingPageUpsertLocalizationInput>>;
};

export type LandingPageUpdateManyInlineInput = {
  /** Connect multiple existing LandingPage documents */
  connect?: InputMaybe<Array<LandingPageConnectInput>>;
  /** Create and connect multiple LandingPage documents */
  create?: InputMaybe<Array<LandingPageCreateInput>>;
  /** Delete multiple LandingPage documents */
  delete?: InputMaybe<Array<LandingPageWhereUniqueInput>>;
  /** Disconnect multiple LandingPage documents */
  disconnect?: InputMaybe<Array<LandingPageWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing LandingPage documents */
  set?: InputMaybe<Array<LandingPageWhereUniqueInput>>;
  /** Update multiple LandingPage documents */
  update?: InputMaybe<Array<LandingPageUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple LandingPage documents */
  upsert?: InputMaybe<Array<LandingPageUpsertWithNestedWhereUniqueInput>>;
};

export type LandingPageUpdateManyInput = {
  body?: InputMaybe<Scalars['String']>;
  bodyRich?: InputMaybe<Scalars['RichTextAST']>;
  internalTitle?: InputMaybe<Scalars['String']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<LandingPageUpdateManyLocalizationsInput>;
  /** title input for default locale (en) */
  title?: InputMaybe<Scalars['String']>;
};

export type LandingPageUpdateManyLocalizationDataInput = {
  title?: InputMaybe<Scalars['String']>;
};

export type LandingPageUpdateManyLocalizationInput = {
  data: LandingPageUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type LandingPageUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<LandingPageUpdateManyLocalizationInput>>;
};

export type LandingPageUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: LandingPageUpdateManyInput;
  /** Document search */
  where: LandingPageWhereInput;
};

export type LandingPageUpdateOneInlineInput = {
  /** Connect existing LandingPage document */
  connect?: InputMaybe<LandingPageWhereUniqueInput>;
  /** Create and connect one LandingPage document */
  create?: InputMaybe<LandingPageCreateInput>;
  /** Delete currently connected LandingPage document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected LandingPage document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single LandingPage document */
  update?: InputMaybe<LandingPageUpdateWithNestedWhereUniqueInput>;
  /** Upsert single LandingPage document */
  upsert?: InputMaybe<LandingPageUpsertWithNestedWhereUniqueInput>;
};

export type LandingPageUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: LandingPageUpdateInput;
  /** Unique document search */
  where: LandingPageWhereUniqueInput;
};

export type LandingPageUpsertInput = {
  /** Create document if it didn't exist */
  create: LandingPageCreateInput;
  /** Update document if it exists */
  update: LandingPageUpdateInput;
};

export type LandingPageUpsertLocalizationInput = {
  create: LandingPageCreateLocalizationDataInput;
  locale: Locale;
  update: LandingPageUpdateLocalizationDataInput;
};

export type LandingPageUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: LandingPageUpsertInput;
  /** Unique document search */
  where: LandingPageWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type LandingPageWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']>;
};

/** Identifies documents */
export type LandingPageWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<LandingPageWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<LandingPageWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<LandingPageWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  body?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  body_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  body_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  body_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  body_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  body_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  body_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  body_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  body_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  body_starts_with?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<LandingPageWhereStageInput>;
  documentInStages_none?: InputMaybe<LandingPageWhereStageInput>;
  documentInStages_some?: InputMaybe<LandingPageWhereStageInput>;
  hero?: InputMaybe<AssetWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  internalTitle?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  internalTitle_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  internalTitle_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  internalTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  internalTitle_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  internalTitle_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  internalTitle_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  internalTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  internalTitle_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  internalTitle_starts_with?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  slug?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  slug_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  slug_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  slug_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  slug_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  slug_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  slug_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  slug_starts_with?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type LandingPageWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<LandingPageWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<LandingPageWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<LandingPageWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<LandingPageWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References LandingPage record uniquely */
export type LandingPageWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
  slug?: InputMaybe<Scalars['String']>;
};

export type Link = Node & {
  __typename?: 'Link';
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** User that created this document */
  createdBy: Maybe<User>;
  /** Get the document in other stages */
  documentInStages: Array<Link>;
  /** List of Link versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID'];
  internalTitle: Scalars['String'];
  label: Maybe<Text>;
  menus: Array<Menu>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt: Maybe<Scalars['DateTime']>;
  /** User that last published this document */
  publishedBy: Maybe<User>;
  scheduledIn: Array<ScheduledOperation>;
  /** System stage field */
  stage: Stage;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** User that last updated this document */
  updatedBy: Maybe<User>;
  url: Maybe<Scalars['String']>;
};

export type LinkCreatedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type LinkDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};

export type LinkHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride: InputMaybe<Stage>;
};

export type LinkLabelArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type LinkMenusArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<MenuWhereInput>;
};

export type LinkPublishedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type LinkScheduledInArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ScheduledOperationWhereInput>;
};

export type LinkUpdatedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type LinkConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: LinkWhereUniqueInput;
};

/** A connection to a list of items. */
export type LinkConnection = {
  __typename?: 'LinkConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<LinkEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type LinkCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  internalTitle: Scalars['String'];
  label?: InputMaybe<TextCreateOneInlineInput>;
  menus?: InputMaybe<MenuCreateManyInlineInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  url?: InputMaybe<Scalars['String']>;
};

export type LinkCreateManyInlineInput = {
  /** Connect multiple existing Link documents */
  connect?: InputMaybe<Array<LinkWhereUniqueInput>>;
  /** Create and connect multiple existing Link documents */
  create?: InputMaybe<Array<LinkCreateInput>>;
};

export type LinkCreateOneInlineInput = {
  /** Connect one existing Link document */
  connect?: InputMaybe<LinkWhereUniqueInput>;
  /** Create and connect one Link document */
  create?: InputMaybe<LinkCreateInput>;
};

/** An edge in a connection. */
export type LinkEdge = {
  __typename?: 'LinkEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Link;
};

/** Identifies documents */
export type LinkManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<LinkWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<LinkWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<LinkWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<LinkWhereStageInput>;
  documentInStages_none?: InputMaybe<LinkWhereStageInput>;
  documentInStages_some?: InputMaybe<LinkWhereStageInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  internalTitle?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  internalTitle_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  internalTitle_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  internalTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  internalTitle_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  internalTitle_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  internalTitle_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  internalTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  internalTitle_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  internalTitle_starts_with?: InputMaybe<Scalars['String']>;
  label?: InputMaybe<TextWhereInput>;
  menus_every?: InputMaybe<MenuWhereInput>;
  menus_none?: InputMaybe<MenuWhereInput>;
  menus_some?: InputMaybe<MenuWhereInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
  url?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  url_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  url_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  url_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  url_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  url_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  url_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  url_starts_with?: InputMaybe<Scalars['String']>;
};

export enum LinkOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  InternalTitleAsc = 'internalTitle_ASC',
  InternalTitleDesc = 'internalTitle_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC',
}

export type LinkUpdateInput = {
  internalTitle?: InputMaybe<Scalars['String']>;
  label?: InputMaybe<TextUpdateOneInlineInput>;
  menus?: InputMaybe<MenuUpdateManyInlineInput>;
  url?: InputMaybe<Scalars['String']>;
};

export type LinkUpdateManyInlineInput = {
  /** Connect multiple existing Link documents */
  connect?: InputMaybe<Array<LinkConnectInput>>;
  /** Create and connect multiple Link documents */
  create?: InputMaybe<Array<LinkCreateInput>>;
  /** Delete multiple Link documents */
  delete?: InputMaybe<Array<LinkWhereUniqueInput>>;
  /** Disconnect multiple Link documents */
  disconnect?: InputMaybe<Array<LinkWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing Link documents */
  set?: InputMaybe<Array<LinkWhereUniqueInput>>;
  /** Update multiple Link documents */
  update?: InputMaybe<Array<LinkUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple Link documents */
  upsert?: InputMaybe<Array<LinkUpsertWithNestedWhereUniqueInput>>;
};

export type LinkUpdateManyInput = {
  internalTitle?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
};

export type LinkUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: LinkUpdateManyInput;
  /** Document search */
  where: LinkWhereInput;
};

export type LinkUpdateOneInlineInput = {
  /** Connect existing Link document */
  connect?: InputMaybe<LinkWhereUniqueInput>;
  /** Create and connect one Link document */
  create?: InputMaybe<LinkCreateInput>;
  /** Delete currently connected Link document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected Link document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single Link document */
  update?: InputMaybe<LinkUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Link document */
  upsert?: InputMaybe<LinkUpsertWithNestedWhereUniqueInput>;
};

export type LinkUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: LinkUpdateInput;
  /** Unique document search */
  where: LinkWhereUniqueInput;
};

export type LinkUpsertInput = {
  /** Create document if it didn't exist */
  create: LinkCreateInput;
  /** Update document if it exists */
  update: LinkUpdateInput;
};

export type LinkUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: LinkUpsertInput;
  /** Unique document search */
  where: LinkWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type LinkWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']>;
};

/** Identifies documents */
export type LinkWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<LinkWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<LinkWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<LinkWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<LinkWhereStageInput>;
  documentInStages_none?: InputMaybe<LinkWhereStageInput>;
  documentInStages_some?: InputMaybe<LinkWhereStageInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  internalTitle?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  internalTitle_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  internalTitle_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  internalTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  internalTitle_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  internalTitle_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  internalTitle_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  internalTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  internalTitle_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  internalTitle_starts_with?: InputMaybe<Scalars['String']>;
  label?: InputMaybe<TextWhereInput>;
  menus_every?: InputMaybe<MenuWhereInput>;
  menus_none?: InputMaybe<MenuWhereInput>;
  menus_some?: InputMaybe<MenuWhereInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
  url?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  url_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  url_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  url_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  url_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  url_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  url_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  url_starts_with?: InputMaybe<Scalars['String']>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type LinkWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<LinkWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<LinkWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<LinkWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<LinkWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References Link record uniquely */
export type LinkWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

/** Locale system enumeration */
export enum Locale {
  De = 'de',
  /** System locale */
  En = 'en',
  Es = 'es',
  Fr = 'fr',
  It = 'it',
  Ja = 'ja',
  Pt = 'pt',
  Zh = 'zh',
}

/** Representing a geolocation point with latitude and longitude */
export type Location = {
  __typename?: 'Location';
  distance: Scalars['Float'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

/** Representing a geolocation point with latitude and longitude */
export type LocationDistanceArgs = {
  from: LocationInput;
};

/** Input for a geolocation point with latitude and longitude */
export type LocationInput = {
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type Menu = Node & {
  __typename?: 'Menu';
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** User that created this document */
  createdBy: Maybe<User>;
  /** Get the document in other stages */
  documentInStages: Array<Menu>;
  /** List of Menu versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID'];
  internalTitle: Scalars['String'];
  items: Array<MenuItems>;
  menus: Array<Menu>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt: Maybe<Scalars['DateTime']>;
  /** User that last published this document */
  publishedBy: Maybe<User>;
  scheduledIn: Array<ScheduledOperation>;
  /** System stage field */
  stage: Stage;
  title: Maybe<Text>;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** User that last updated this document */
  updatedBy: Maybe<User>;
};

export type MenuCreatedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type MenuDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};

export type MenuHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride: InputMaybe<Stage>;
};

export type MenuItemsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  skip: InputMaybe<Scalars['Int']>;
};

export type MenuMenusArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<MenuWhereInput>;
};

export type MenuPublishedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type MenuScheduledInArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ScheduledOperationWhereInput>;
};

export type MenuTitleArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type MenuUpdatedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type MenuConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: MenuWhereUniqueInput;
};

/** A connection to a list of items. */
export type MenuConnection = {
  __typename?: 'MenuConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<MenuEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type MenuCreateInput = {
  ckyapvpva0qzr01z25mdb2kyj?: InputMaybe<PageCreateManyInlineInput>;
  ckyaq41v20ooe01z163hk9lu6?: InputMaybe<PageCreateManyInlineInput>;
  ckyw1g5pu0r4801xnen5j04gr?: InputMaybe<PageTemplateCreateManyInlineInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  internalTitle: Scalars['String'];
  items?: InputMaybe<MenuItemsCreateManyInlineInput>;
  menus?: InputMaybe<MenuCreateManyInlineInput>;
  title?: InputMaybe<TextCreateOneInlineInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type MenuCreateManyInlineInput = {
  /** Connect multiple existing Menu documents */
  connect?: InputMaybe<Array<MenuWhereUniqueInput>>;
  /** Create and connect multiple existing Menu documents */
  create?: InputMaybe<Array<MenuCreateInput>>;
};

export type MenuCreateOneInlineInput = {
  /** Connect one existing Menu document */
  connect?: InputMaybe<MenuWhereUniqueInput>;
  /** Create and connect one Menu document */
  create?: InputMaybe<MenuCreateInput>;
};

/** An edge in a connection. */
export type MenuEdge = {
  __typename?: 'MenuEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Menu;
};

export type MenuItems = Link | Menu | Page | Product;

export type MenuItemsConnectInput = {
  Link?: InputMaybe<LinkConnectInput>;
  Menu?: InputMaybe<MenuConnectInput>;
  Page?: InputMaybe<PageConnectInput>;
  Product?: InputMaybe<ProductConnectInput>;
};

export type MenuItemsCreateInput = {
  Link?: InputMaybe<LinkCreateInput>;
  Menu?: InputMaybe<MenuCreateInput>;
  Page?: InputMaybe<PageCreateInput>;
  Product?: InputMaybe<ProductCreateInput>;
};

export type MenuItemsCreateManyInlineInput = {
  /** Connect multiple existing MenuItems documents */
  connect?: InputMaybe<Array<MenuItemsWhereUniqueInput>>;
  /** Create and connect multiple existing MenuItems documents */
  create?: InputMaybe<Array<MenuItemsCreateInput>>;
};

export type MenuItemsCreateOneInlineInput = {
  /** Connect one existing MenuItems document */
  connect?: InputMaybe<MenuItemsWhereUniqueInput>;
  /** Create and connect one MenuItems document */
  create?: InputMaybe<MenuItemsCreateInput>;
};

export type MenuItemsUpdateInput = {
  Link?: InputMaybe<LinkUpdateInput>;
  Menu?: InputMaybe<MenuUpdateInput>;
  Page?: InputMaybe<PageUpdateInput>;
  Product?: InputMaybe<ProductUpdateInput>;
};

export type MenuItemsUpdateManyInlineInput = {
  /** Connect multiple existing MenuItems documents */
  connect?: InputMaybe<Array<MenuItemsConnectInput>>;
  /** Create and connect multiple MenuItems documents */
  create?: InputMaybe<Array<MenuItemsCreateInput>>;
  /** Delete multiple MenuItems documents */
  delete?: InputMaybe<Array<MenuItemsWhereUniqueInput>>;
  /** Disconnect multiple MenuItems documents */
  disconnect?: InputMaybe<Array<MenuItemsWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing MenuItems documents */
  set?: InputMaybe<Array<MenuItemsWhereUniqueInput>>;
  /** Update multiple MenuItems documents */
  update?: InputMaybe<Array<MenuItemsUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple MenuItems documents */
  upsert?: InputMaybe<Array<MenuItemsUpsertWithNestedWhereUniqueInput>>;
};

export type MenuItemsUpdateManyWithNestedWhereInput = {
  Link?: InputMaybe<LinkUpdateManyWithNestedWhereInput>;
  Menu?: InputMaybe<MenuUpdateManyWithNestedWhereInput>;
  Page?: InputMaybe<PageUpdateManyWithNestedWhereInput>;
  Product?: InputMaybe<ProductUpdateManyWithNestedWhereInput>;
};

export type MenuItemsUpdateOneInlineInput = {
  /** Connect existing MenuItems document */
  connect?: InputMaybe<MenuItemsWhereUniqueInput>;
  /** Create and connect one MenuItems document */
  create?: InputMaybe<MenuItemsCreateInput>;
  /** Delete currently connected MenuItems document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected MenuItems document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single MenuItems document */
  update?: InputMaybe<MenuItemsUpdateWithNestedWhereUniqueInput>;
  /** Upsert single MenuItems document */
  upsert?: InputMaybe<MenuItemsUpsertWithNestedWhereUniqueInput>;
};

export type MenuItemsUpdateWithNestedWhereUniqueInput = {
  Link?: InputMaybe<LinkUpdateWithNestedWhereUniqueInput>;
  Menu?: InputMaybe<MenuUpdateWithNestedWhereUniqueInput>;
  Page?: InputMaybe<PageUpdateWithNestedWhereUniqueInput>;
  Product?: InputMaybe<ProductUpdateWithNestedWhereUniqueInput>;
};

export type MenuItemsUpsertWithNestedWhereUniqueInput = {
  Link?: InputMaybe<LinkUpsertWithNestedWhereUniqueInput>;
  Menu?: InputMaybe<MenuUpsertWithNestedWhereUniqueInput>;
  Page?: InputMaybe<PageUpsertWithNestedWhereUniqueInput>;
  Product?: InputMaybe<ProductUpsertWithNestedWhereUniqueInput>;
};

export type MenuItemsWhereInput = {
  Link?: InputMaybe<LinkWhereInput>;
  Menu?: InputMaybe<MenuWhereInput>;
  Page?: InputMaybe<PageWhereInput>;
  Product?: InputMaybe<ProductWhereInput>;
};

export type MenuItemsWhereUniqueInput = {
  Link?: InputMaybe<LinkWhereUniqueInput>;
  Menu?: InputMaybe<MenuWhereUniqueInput>;
  Page?: InputMaybe<PageWhereUniqueInput>;
  Product?: InputMaybe<ProductWhereUniqueInput>;
};

/** Identifies documents */
export type MenuManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MenuWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MenuWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MenuWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<MenuWhereStageInput>;
  documentInStages_none?: InputMaybe<MenuWhereStageInput>;
  documentInStages_some?: InputMaybe<MenuWhereStageInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  internalTitle?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  internalTitle_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  internalTitle_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  internalTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  internalTitle_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  internalTitle_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  internalTitle_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  internalTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  internalTitle_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  internalTitle_starts_with?: InputMaybe<Scalars['String']>;
  /** All values in which the union is empty */
  items_empty?: InputMaybe<Scalars['Boolean']>;
  /** Matches if the union contains at least one connection to the provided item to the filter */
  items_some?: InputMaybe<MenuItemsWhereInput>;
  menus_every?: InputMaybe<MenuWhereInput>;
  menus_none?: InputMaybe<MenuWhereInput>;
  menus_some?: InputMaybe<MenuWhereInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  title?: InputMaybe<TextWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum MenuOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  InternalTitleAsc = 'internalTitle_ASC',
  InternalTitleDesc = 'internalTitle_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type MenuUpdateInput = {
  ckyapvpva0qzr01z25mdb2kyj?: InputMaybe<PageUpdateManyInlineInput>;
  ckyaq41v20ooe01z163hk9lu6?: InputMaybe<PageUpdateManyInlineInput>;
  ckyw1g5pu0r4801xnen5j04gr?: InputMaybe<PageTemplateUpdateManyInlineInput>;
  internalTitle?: InputMaybe<Scalars['String']>;
  items?: InputMaybe<MenuItemsUpdateManyInlineInput>;
  menus?: InputMaybe<MenuUpdateManyInlineInput>;
  title?: InputMaybe<TextUpdateOneInlineInput>;
};

export type MenuUpdateManyInlineInput = {
  /** Connect multiple existing Menu documents */
  connect?: InputMaybe<Array<MenuConnectInput>>;
  /** Create and connect multiple Menu documents */
  create?: InputMaybe<Array<MenuCreateInput>>;
  /** Delete multiple Menu documents */
  delete?: InputMaybe<Array<MenuWhereUniqueInput>>;
  /** Disconnect multiple Menu documents */
  disconnect?: InputMaybe<Array<MenuWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing Menu documents */
  set?: InputMaybe<Array<MenuWhereUniqueInput>>;
  /** Update multiple Menu documents */
  update?: InputMaybe<Array<MenuUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple Menu documents */
  upsert?: InputMaybe<Array<MenuUpsertWithNestedWhereUniqueInput>>;
};

export type MenuUpdateManyInput = {
  /** No fields in updateMany data input */
  _?: InputMaybe<Scalars['String']>;
};

export type MenuUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: MenuUpdateManyInput;
  /** Document search */
  where: MenuWhereInput;
};

export type MenuUpdateOneInlineInput = {
  /** Connect existing Menu document */
  connect?: InputMaybe<MenuWhereUniqueInput>;
  /** Create and connect one Menu document */
  create?: InputMaybe<MenuCreateInput>;
  /** Delete currently connected Menu document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected Menu document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single Menu document */
  update?: InputMaybe<MenuUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Menu document */
  upsert?: InputMaybe<MenuUpsertWithNestedWhereUniqueInput>;
};

export type MenuUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: MenuUpdateInput;
  /** Unique document search */
  where: MenuWhereUniqueInput;
};

export type MenuUpsertInput = {
  /** Create document if it didn't exist */
  create: MenuCreateInput;
  /** Update document if it exists */
  update: MenuUpdateInput;
};

export type MenuUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: MenuUpsertInput;
  /** Unique document search */
  where: MenuWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type MenuWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']>;
};

/** Identifies documents */
export type MenuWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MenuWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MenuWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MenuWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<MenuWhereStageInput>;
  documentInStages_none?: InputMaybe<MenuWhereStageInput>;
  documentInStages_some?: InputMaybe<MenuWhereStageInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  internalTitle?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  internalTitle_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  internalTitle_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  internalTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  internalTitle_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  internalTitle_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  internalTitle_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  internalTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  internalTitle_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  internalTitle_starts_with?: InputMaybe<Scalars['String']>;
  /** All values in which the union is empty */
  items_empty?: InputMaybe<Scalars['Boolean']>;
  /** Matches if the union contains at least one connection to the provided item to the filter */
  items_some?: InputMaybe<MenuItemsWhereInput>;
  menus_every?: InputMaybe<MenuWhereInput>;
  menus_none?: InputMaybe<MenuWhereInput>;
  menus_some?: InputMaybe<MenuWhereInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  title?: InputMaybe<TextWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type MenuWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MenuWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MenuWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MenuWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<MenuWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References Menu record uniquely */
export type MenuWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
  internalTitle?: InputMaybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Create one addOn */
  createAddOn: Maybe<AddOn>;
  /** Create one alacarteTicket */
  createAlacarteTicket: Maybe<AlacarteTicket>;
  /** Create one alacarteTicketType */
  createAlacarteTicketType: Maybe<AlacarteTicketType>;
  /** Create one article */
  createArticle: Maybe<Article>;
  /** Create one articleCategory */
  createArticleCategory: Maybe<ArticleCategory>;
  /**
   * Create one asset
   * @deprecated Asset mutations will be overhauled soon
   */
  createAsset: Maybe<Asset>;
  /** Create one attraction */
  createAttraction: Maybe<Attraction>;
  /** Create one attractionAlacarte */
  createAttractionAlacarte: Maybe<AttractionAlacarte>;
  /** Create one attractionAvailability */
  createAttractionAvailability: Maybe<AttractionAvailability>;
  /** Create one attractionLocation */
  createAttractionLocation: Maybe<AttractionLocation>;
  /** Create one attractionOffer */
  createAttractionOffer: Maybe<AttractionOffer>;
  /** Create one attractionPrice */
  createAttractionPrice: Maybe<AttractionPrice>;
  /** Create one attractionReservation */
  createAttractionReservation: Maybe<AttractionReservation>;
  /** Create one attractionVariant */
  createAttractionVariant: Maybe<AttractionVariant>;
  /** Create one author */
  createAuthor: Maybe<Author>;
  /** Create one block */
  createBlock: Maybe<Block>;
  /** Create one blockGroup */
  createBlockGroup: Maybe<BlockGroup>;
  /** Create one detailBlock */
  createDetailBlock: Maybe<DetailBlock>;
  /** Create one generalReservation */
  createGeneralReservation: Maybe<GeneralReservation>;
  /** Create one image */
  createImage: Maybe<Image>;
  /** Create one landingPage */
  createLandingPage: Maybe<LandingPage>;
  /** Create one link */
  createLink: Maybe<Link>;
  /** Create one menu */
  createMenu: Maybe<Menu>;
  /** Create one notice */
  createNotice: Maybe<Notice>;
  /** Create one page */
  createPage: Maybe<Page>;
  /** Create one pageTemplate */
  createPageTemplate: Maybe<PageTemplate>;
  /** Create one partner */
  createPartner: Maybe<Partner>;
  /** Create one partnerOffer */
  createPartnerOffer: Maybe<PartnerOffer>;
  /** Create one product */
  createProduct: Maybe<Product>;
  /** Create one productActivity */
  createProductActivity: Maybe<ProductActivity>;
  /** Create one productActivityItem */
  createProductActivityItem: Maybe<ProductActivityItem>;
  /** Create one productAttractionLineup */
  createProductAttractionLineup: Maybe<ProductAttractionLineup>;
  /** Create one productReservation */
  createProductReservation: Maybe<ProductReservation>;
  /** Create one promo */
  createPromo: Maybe<Promo>;
  /** Create one reservationText */
  createReservationText: Maybe<ReservationText>;
  /** Create one sandbox */
  createSandbox: Maybe<Sandbox>;
  /** Create one scheduledRelease */
  createScheduledRelease: Maybe<ScheduledRelease>;
  /** Create one seo */
  createSeo: Maybe<Seo>;
  /** Create one text */
  createText: Maybe<Text>;
  /** Create one textGroup */
  createTextGroup: Maybe<TextGroup>;
  /** Create one textList */
  createTextList: Maybe<TextList>;
  /** Create one ticketGroup */
  createTicketGroup: Maybe<TicketGroup>;
  /** Create one titleDescription */
  createTitleDescription: Maybe<TitleDescription>;
  /** Create one video */
  createVideo: Maybe<Video>;
  /** Delete one addOn from _all_ existing stages. Returns deleted document. */
  deleteAddOn: Maybe<AddOn>;
  /** Delete one alacarteTicket from _all_ existing stages. Returns deleted document. */
  deleteAlacarteTicket: Maybe<AlacarteTicket>;
  /** Delete one alacarteTicketType from _all_ existing stages. Returns deleted document. */
  deleteAlacarteTicketType: Maybe<AlacarteTicketType>;
  /** Delete one article from _all_ existing stages. Returns deleted document. */
  deleteArticle: Maybe<Article>;
  /** Delete one articleCategory from _all_ existing stages. Returns deleted document. */
  deleteArticleCategory: Maybe<ArticleCategory>;
  /** Delete one asset from _all_ existing stages. Returns deleted document. */
  deleteAsset: Maybe<Asset>;
  /** Delete one attraction from _all_ existing stages. Returns deleted document. */
  deleteAttraction: Maybe<Attraction>;
  /** Delete one attractionAlacarte from _all_ existing stages. Returns deleted document. */
  deleteAttractionAlacarte: Maybe<AttractionAlacarte>;
  /** Delete one attractionAvailability from _all_ existing stages. Returns deleted document. */
  deleteAttractionAvailability: Maybe<AttractionAvailability>;
  /** Delete one attractionLocation from _all_ existing stages. Returns deleted document. */
  deleteAttractionLocation: Maybe<AttractionLocation>;
  /** Delete one attractionOffer from _all_ existing stages. Returns deleted document. */
  deleteAttractionOffer: Maybe<AttractionOffer>;
  /** Delete one attractionPrice from _all_ existing stages. Returns deleted document. */
  deleteAttractionPrice: Maybe<AttractionPrice>;
  /** Delete one attractionReservation from _all_ existing stages. Returns deleted document. */
  deleteAttractionReservation: Maybe<AttractionReservation>;
  /** Delete one attractionVariant from _all_ existing stages. Returns deleted document. */
  deleteAttractionVariant: Maybe<AttractionVariant>;
  /** Delete one author from _all_ existing stages. Returns deleted document. */
  deleteAuthor: Maybe<Author>;
  /** Delete one block from _all_ existing stages. Returns deleted document. */
  deleteBlock: Maybe<Block>;
  /** Delete one blockGroup from _all_ existing stages. Returns deleted document. */
  deleteBlockGroup: Maybe<BlockGroup>;
  /** Delete one detailBlock from _all_ existing stages. Returns deleted document. */
  deleteDetailBlock: Maybe<DetailBlock>;
  /** Delete one generalReservation from _all_ existing stages. Returns deleted document. */
  deleteGeneralReservation: Maybe<GeneralReservation>;
  /** Delete one image from _all_ existing stages. Returns deleted document. */
  deleteImage: Maybe<Image>;
  /** Delete one landingPage from _all_ existing stages. Returns deleted document. */
  deleteLandingPage: Maybe<LandingPage>;
  /** Delete one link from _all_ existing stages. Returns deleted document. */
  deleteLink: Maybe<Link>;
  /**
   * Delete many AddOn documents
   * @deprecated Please use the new paginated many mutation (deleteManyAddOnsConnection)
   */
  deleteManyAddOns: BatchPayload;
  /** Delete many AddOn documents, return deleted documents */
  deleteManyAddOnsConnection: AddOnConnection;
  /**
   * Delete many AlacarteTicketType documents
   * @deprecated Please use the new paginated many mutation (deleteManyAlacarteTicketTypesConnection)
   */
  deleteManyAlacarteTicketTypes: BatchPayload;
  /** Delete many AlacarteTicketType documents, return deleted documents */
  deleteManyAlacarteTicketTypesConnection: AlacarteTicketTypeConnection;
  /**
   * Delete many AlacarteTicket documents
   * @deprecated Please use the new paginated many mutation (deleteManyAlacarteTicketsConnection)
   */
  deleteManyAlacarteTickets: BatchPayload;
  /** Delete many AlacarteTicket documents, return deleted documents */
  deleteManyAlacarteTicketsConnection: AlacarteTicketConnection;
  /**
   * Delete many ArticleCategory documents
   * @deprecated Please use the new paginated many mutation (deleteManyArticleCategoriesConnection)
   */
  deleteManyArticleCategories: BatchPayload;
  /** Delete many ArticleCategory documents, return deleted documents */
  deleteManyArticleCategoriesConnection: ArticleCategoryConnection;
  /**
   * Delete many Article documents
   * @deprecated Please use the new paginated many mutation (deleteManyArticlesConnection)
   */
  deleteManyArticles: BatchPayload;
  /** Delete many Article documents, return deleted documents */
  deleteManyArticlesConnection: ArticleConnection;
  /**
   * Delete many Asset documents
   * @deprecated Please use the new paginated many mutation (deleteManyAssetsConnection)
   */
  deleteManyAssets: BatchPayload;
  /** Delete many Asset documents, return deleted documents */
  deleteManyAssetsConnection: AssetConnection;
  /**
   * Delete many AttractionAlacarte documents
   * @deprecated Please use the new paginated many mutation (deleteManyAttractionAlacartesConnection)
   */
  deleteManyAttractionAlacartes: BatchPayload;
  /** Delete many AttractionAlacarte documents, return deleted documents */
  deleteManyAttractionAlacartesConnection: AttractionAlacarteConnection;
  /**
   * Delete many AttractionAvailability documents
   * @deprecated Please use the new paginated many mutation (deleteManyAttractionAvailabilitiesConnection)
   */
  deleteManyAttractionAvailabilities: BatchPayload;
  /** Delete many AttractionAvailability documents, return deleted documents */
  deleteManyAttractionAvailabilitiesConnection: AttractionAvailabilityConnection;
  /**
   * Delete many AttractionLocation documents
   * @deprecated Please use the new paginated many mutation (deleteManyAttractionLocationsConnection)
   */
  deleteManyAttractionLocations: BatchPayload;
  /** Delete many AttractionLocation documents, return deleted documents */
  deleteManyAttractionLocationsConnection: AttractionLocationConnection;
  /**
   * Delete many AttractionOffer documents
   * @deprecated Please use the new paginated many mutation (deleteManyAttractionOffersConnection)
   */
  deleteManyAttractionOffers: BatchPayload;
  /** Delete many AttractionOffer documents, return deleted documents */
  deleteManyAttractionOffersConnection: AttractionOfferConnection;
  /**
   * Delete many AttractionPrice documents
   * @deprecated Please use the new paginated many mutation (deleteManyAttractionPricesConnection)
   */
  deleteManyAttractionPrices: BatchPayload;
  /** Delete many AttractionPrice documents, return deleted documents */
  deleteManyAttractionPricesConnection: AttractionPriceConnection;
  /**
   * Delete many AttractionReservation documents
   * @deprecated Please use the new paginated many mutation (deleteManyAttractionReservationsConnection)
   */
  deleteManyAttractionReservations: BatchPayload;
  /** Delete many AttractionReservation documents, return deleted documents */
  deleteManyAttractionReservationsConnection: AttractionReservationConnection;
  /**
   * Delete many AttractionVariant documents
   * @deprecated Please use the new paginated many mutation (deleteManyAttractionVariantsConnection)
   */
  deleteManyAttractionVariants: BatchPayload;
  /** Delete many AttractionVariant documents, return deleted documents */
  deleteManyAttractionVariantsConnection: AttractionVariantConnection;
  /**
   * Delete many Attraction documents
   * @deprecated Please use the new paginated many mutation (deleteManyAttractionsConnection)
   */
  deleteManyAttractions: BatchPayload;
  /** Delete many Attraction documents, return deleted documents */
  deleteManyAttractionsConnection: AttractionConnection;
  /**
   * Delete many Author documents
   * @deprecated Please use the new paginated many mutation (deleteManyAuthorsConnection)
   */
  deleteManyAuthors: BatchPayload;
  /** Delete many Author documents, return deleted documents */
  deleteManyAuthorsConnection: AuthorConnection;
  /**
   * Delete many BlockGroup documents
   * @deprecated Please use the new paginated many mutation (deleteManyBlockGroupsConnection)
   */
  deleteManyBlockGroups: BatchPayload;
  /** Delete many BlockGroup documents, return deleted documents */
  deleteManyBlockGroupsConnection: BlockGroupConnection;
  /**
   * Delete many Block documents
   * @deprecated Please use the new paginated many mutation (deleteManyBlocksConnection)
   */
  deleteManyBlocks: BatchPayload;
  /** Delete many Block documents, return deleted documents */
  deleteManyBlocksConnection: BlockConnection;
  /**
   * Delete many DetailBlock documents
   * @deprecated Please use the new paginated many mutation (deleteManyDetailBlocksConnection)
   */
  deleteManyDetailBlocks: BatchPayload;
  /** Delete many DetailBlock documents, return deleted documents */
  deleteManyDetailBlocksConnection: DetailBlockConnection;
  /**
   * Delete many GeneralReservation documents
   * @deprecated Please use the new paginated many mutation (deleteManyGeneralReservationsConnection)
   */
  deleteManyGeneralReservations: BatchPayload;
  /** Delete many GeneralReservation documents, return deleted documents */
  deleteManyGeneralReservationsConnection: GeneralReservationConnection;
  /**
   * Delete many Image documents
   * @deprecated Please use the new paginated many mutation (deleteManyImagesConnection)
   */
  deleteManyImages: BatchPayload;
  /** Delete many Image documents, return deleted documents */
  deleteManyImagesConnection: ImageConnection;
  /**
   * Delete many LandingPage documents
   * @deprecated Please use the new paginated many mutation (deleteManyLandingPagesConnection)
   */
  deleteManyLandingPages: BatchPayload;
  /** Delete many LandingPage documents, return deleted documents */
  deleteManyLandingPagesConnection: LandingPageConnection;
  /**
   * Delete many Link documents
   * @deprecated Please use the new paginated many mutation (deleteManyLinksConnection)
   */
  deleteManyLinks: BatchPayload;
  /** Delete many Link documents, return deleted documents */
  deleteManyLinksConnection: LinkConnection;
  /**
   * Delete many Menu documents
   * @deprecated Please use the new paginated many mutation (deleteManyMenusConnection)
   */
  deleteManyMenus: BatchPayload;
  /** Delete many Menu documents, return deleted documents */
  deleteManyMenusConnection: MenuConnection;
  /**
   * Delete many Notice documents
   * @deprecated Please use the new paginated many mutation (deleteManyNoticesConnection)
   */
  deleteManyNotices: BatchPayload;
  /** Delete many Notice documents, return deleted documents */
  deleteManyNoticesConnection: NoticeConnection;
  /**
   * Delete many PageTemplate documents
   * @deprecated Please use the new paginated many mutation (deleteManyPageTemplatesConnection)
   */
  deleteManyPageTemplates: BatchPayload;
  /** Delete many PageTemplate documents, return deleted documents */
  deleteManyPageTemplatesConnection: PageTemplateConnection;
  /**
   * Delete many Page documents
   * @deprecated Please use the new paginated many mutation (deleteManyPagesConnection)
   */
  deleteManyPages: BatchPayload;
  /** Delete many Page documents, return deleted documents */
  deleteManyPagesConnection: PageConnection;
  /**
   * Delete many PartnerOffer documents
   * @deprecated Please use the new paginated many mutation (deleteManyPartnerOffersConnection)
   */
  deleteManyPartnerOffers: BatchPayload;
  /** Delete many PartnerOffer documents, return deleted documents */
  deleteManyPartnerOffersConnection: PartnerOfferConnection;
  /**
   * Delete many Partner documents
   * @deprecated Please use the new paginated many mutation (deleteManyPartnersConnection)
   */
  deleteManyPartners: BatchPayload;
  /** Delete many Partner documents, return deleted documents */
  deleteManyPartnersConnection: PartnerConnection;
  /**
   * Delete many ProductActivity documents
   * @deprecated Please use the new paginated many mutation (deleteManyProductActivitiesConnection)
   */
  deleteManyProductActivities: BatchPayload;
  /** Delete many ProductActivity documents, return deleted documents */
  deleteManyProductActivitiesConnection: ProductActivityConnection;
  /**
   * Delete many ProductActivityItem documents
   * @deprecated Please use the new paginated many mutation (deleteManyProductActivityItemsConnection)
   */
  deleteManyProductActivityItems: BatchPayload;
  /** Delete many ProductActivityItem documents, return deleted documents */
  deleteManyProductActivityItemsConnection: ProductActivityItemConnection;
  /**
   * Delete many ProductAttractionLineup documents
   * @deprecated Please use the new paginated many mutation (deleteManyProductAttractionLineupsConnection)
   */
  deleteManyProductAttractionLineups: BatchPayload;
  /** Delete many ProductAttractionLineup documents, return deleted documents */
  deleteManyProductAttractionLineupsConnection: ProductAttractionLineupConnection;
  /**
   * Delete many ProductReservation documents
   * @deprecated Please use the new paginated many mutation (deleteManyProductReservationsConnection)
   */
  deleteManyProductReservations: BatchPayload;
  /** Delete many ProductReservation documents, return deleted documents */
  deleteManyProductReservationsConnection: ProductReservationConnection;
  /**
   * Delete many Product documents
   * @deprecated Please use the new paginated many mutation (deleteManyProductsConnection)
   */
  deleteManyProducts: BatchPayload;
  /** Delete many Product documents, return deleted documents */
  deleteManyProductsConnection: ProductConnection;
  /**
   * Delete many Promo documents
   * @deprecated Please use the new paginated many mutation (deleteManyPromosConnection)
   */
  deleteManyPromos: BatchPayload;
  /** Delete many Promo documents, return deleted documents */
  deleteManyPromosConnection: PromoConnection;
  /**
   * Delete many ReservationText documents
   * @deprecated Please use the new paginated many mutation (deleteManyReservationTextsConnection)
   */
  deleteManyReservationTexts: BatchPayload;
  /** Delete many ReservationText documents, return deleted documents */
  deleteManyReservationTextsConnection: ReservationTextConnection;
  /**
   * Delete many Sandbox documents
   * @deprecated Please use the new paginated many mutation (deleteManySandboxesConnection)
   */
  deleteManySandboxes: BatchPayload;
  /** Delete many Sandbox documents, return deleted documents */
  deleteManySandboxesConnection: SandboxConnection;
  /**
   * Delete many Seo documents
   * @deprecated Please use the new paginated many mutation (deleteManySeosConnection)
   */
  deleteManySeos: BatchPayload;
  /** Delete many Seo documents, return deleted documents */
  deleteManySeosConnection: SeoConnection;
  /**
   * Delete many TextGroup documents
   * @deprecated Please use the new paginated many mutation (deleteManyTextGroupsConnection)
   */
  deleteManyTextGroups: BatchPayload;
  /** Delete many TextGroup documents, return deleted documents */
  deleteManyTextGroupsConnection: TextGroupConnection;
  /**
   * Delete many TextList documents
   * @deprecated Please use the new paginated many mutation (deleteManyTextListsConnection)
   */
  deleteManyTextLists: BatchPayload;
  /** Delete many TextList documents, return deleted documents */
  deleteManyTextListsConnection: TextListConnection;
  /**
   * Delete many Text documents
   * @deprecated Please use the new paginated many mutation (deleteManyTextsConnection)
   */
  deleteManyTexts: BatchPayload;
  /** Delete many Text documents, return deleted documents */
  deleteManyTextsConnection: TextConnection;
  /**
   * Delete many TicketGroup documents
   * @deprecated Please use the new paginated many mutation (deleteManyTicketGroupsConnection)
   */
  deleteManyTicketGroups: BatchPayload;
  /** Delete many TicketGroup documents, return deleted documents */
  deleteManyTicketGroupsConnection: TicketGroupConnection;
  /**
   * Delete many TitleDescription documents
   * @deprecated Please use the new paginated many mutation (deleteManyTitleDescriptionsConnection)
   */
  deleteManyTitleDescriptions: BatchPayload;
  /** Delete many TitleDescription documents, return deleted documents */
  deleteManyTitleDescriptionsConnection: TitleDescriptionConnection;
  /**
   * Delete many Video documents
   * @deprecated Please use the new paginated many mutation (deleteManyVideosConnection)
   */
  deleteManyVideos: BatchPayload;
  /** Delete many Video documents, return deleted documents */
  deleteManyVideosConnection: VideoConnection;
  /** Delete one menu from _all_ existing stages. Returns deleted document. */
  deleteMenu: Maybe<Menu>;
  /** Delete one notice from _all_ existing stages. Returns deleted document. */
  deleteNotice: Maybe<Notice>;
  /** Delete one page from _all_ existing stages. Returns deleted document. */
  deletePage: Maybe<Page>;
  /** Delete one pageTemplate from _all_ existing stages. Returns deleted document. */
  deletePageTemplate: Maybe<PageTemplate>;
  /** Delete one partner from _all_ existing stages. Returns deleted document. */
  deletePartner: Maybe<Partner>;
  /** Delete one partnerOffer from _all_ existing stages. Returns deleted document. */
  deletePartnerOffer: Maybe<PartnerOffer>;
  /** Delete one product from _all_ existing stages. Returns deleted document. */
  deleteProduct: Maybe<Product>;
  /** Delete one productActivity from _all_ existing stages. Returns deleted document. */
  deleteProductActivity: Maybe<ProductActivity>;
  /** Delete one productActivityItem from _all_ existing stages. Returns deleted document. */
  deleteProductActivityItem: Maybe<ProductActivityItem>;
  /** Delete one productAttractionLineup from _all_ existing stages. Returns deleted document. */
  deleteProductAttractionLineup: Maybe<ProductAttractionLineup>;
  /** Delete one productReservation from _all_ existing stages. Returns deleted document. */
  deleteProductReservation: Maybe<ProductReservation>;
  /** Delete one promo from _all_ existing stages. Returns deleted document. */
  deletePromo: Maybe<Promo>;
  /** Delete one reservationText from _all_ existing stages. Returns deleted document. */
  deleteReservationText: Maybe<ReservationText>;
  /** Delete one sandbox from _all_ existing stages. Returns deleted document. */
  deleteSandbox: Maybe<Sandbox>;
  /** Delete and return scheduled operation */
  deleteScheduledOperation: Maybe<ScheduledOperation>;
  /** Delete one scheduledRelease from _all_ existing stages. Returns deleted document. */
  deleteScheduledRelease: Maybe<ScheduledRelease>;
  /** Delete one seo from _all_ existing stages. Returns deleted document. */
  deleteSeo: Maybe<Seo>;
  /** Delete one text from _all_ existing stages. Returns deleted document. */
  deleteText: Maybe<Text>;
  /** Delete one textGroup from _all_ existing stages. Returns deleted document. */
  deleteTextGroup: Maybe<TextGroup>;
  /** Delete one textList from _all_ existing stages. Returns deleted document. */
  deleteTextList: Maybe<TextList>;
  /** Delete one ticketGroup from _all_ existing stages. Returns deleted document. */
  deleteTicketGroup: Maybe<TicketGroup>;
  /** Delete one titleDescription from _all_ existing stages. Returns deleted document. */
  deleteTitleDescription: Maybe<TitleDescription>;
  /** Delete one video from _all_ existing stages. Returns deleted document. */
  deleteVideo: Maybe<Video>;
  /** Publish one addOn */
  publishAddOn: Maybe<AddOn>;
  /** Publish one alacarteTicket */
  publishAlacarteTicket: Maybe<AlacarteTicket>;
  /** Publish one alacarteTicketType */
  publishAlacarteTicketType: Maybe<AlacarteTicketType>;
  /** Publish one article */
  publishArticle: Maybe<Article>;
  /** Publish one articleCategory */
  publishArticleCategory: Maybe<ArticleCategory>;
  /** Publish one asset */
  publishAsset: Maybe<Asset>;
  /** Publish one attraction */
  publishAttraction: Maybe<Attraction>;
  /** Publish one attractionAlacarte */
  publishAttractionAlacarte: Maybe<AttractionAlacarte>;
  /** Publish one attractionAvailability */
  publishAttractionAvailability: Maybe<AttractionAvailability>;
  /** Publish one attractionLocation */
  publishAttractionLocation: Maybe<AttractionLocation>;
  /** Publish one attractionOffer */
  publishAttractionOffer: Maybe<AttractionOffer>;
  /** Publish one attractionPrice */
  publishAttractionPrice: Maybe<AttractionPrice>;
  /** Publish one attractionReservation */
  publishAttractionReservation: Maybe<AttractionReservation>;
  /** Publish one attractionVariant */
  publishAttractionVariant: Maybe<AttractionVariant>;
  /** Publish one author */
  publishAuthor: Maybe<Author>;
  /** Publish one block */
  publishBlock: Maybe<Block>;
  /** Publish one blockGroup */
  publishBlockGroup: Maybe<BlockGroup>;
  /** Publish one detailBlock */
  publishDetailBlock: Maybe<DetailBlock>;
  /** Publish one generalReservation */
  publishGeneralReservation: Maybe<GeneralReservation>;
  /** Publish one image */
  publishImage: Maybe<Image>;
  /** Publish one landingPage */
  publishLandingPage: Maybe<LandingPage>;
  /** Publish one link */
  publishLink: Maybe<Link>;
  /**
   * Publish many AddOn documents
   * @deprecated Please use the new paginated many mutation (publishManyAddOnsConnection)
   */
  publishManyAddOns: BatchPayload;
  /** Publish many AddOn documents */
  publishManyAddOnsConnection: AddOnConnection;
  /**
   * Publish many AlacarteTicketType documents
   * @deprecated Please use the new paginated many mutation (publishManyAlacarteTicketTypesConnection)
   */
  publishManyAlacarteTicketTypes: BatchPayload;
  /** Publish many AlacarteTicketType documents */
  publishManyAlacarteTicketTypesConnection: AlacarteTicketTypeConnection;
  /**
   * Publish many AlacarteTicket documents
   * @deprecated Please use the new paginated many mutation (publishManyAlacarteTicketsConnection)
   */
  publishManyAlacarteTickets: BatchPayload;
  /** Publish many AlacarteTicket documents */
  publishManyAlacarteTicketsConnection: AlacarteTicketConnection;
  /**
   * Publish many ArticleCategory documents
   * @deprecated Please use the new paginated many mutation (publishManyArticleCategoriesConnection)
   */
  publishManyArticleCategories: BatchPayload;
  /** Publish many ArticleCategory documents */
  publishManyArticleCategoriesConnection: ArticleCategoryConnection;
  /**
   * Publish many Article documents
   * @deprecated Please use the new paginated many mutation (publishManyArticlesConnection)
   */
  publishManyArticles: BatchPayload;
  /** Publish many Article documents */
  publishManyArticlesConnection: ArticleConnection;
  /**
   * Publish many Asset documents
   * @deprecated Please use the new paginated many mutation (publishManyAssetsConnection)
   */
  publishManyAssets: BatchPayload;
  /** Publish many Asset documents */
  publishManyAssetsConnection: AssetConnection;
  /**
   * Publish many AttractionAlacarte documents
   * @deprecated Please use the new paginated many mutation (publishManyAttractionAlacartesConnection)
   */
  publishManyAttractionAlacartes: BatchPayload;
  /** Publish many AttractionAlacarte documents */
  publishManyAttractionAlacartesConnection: AttractionAlacarteConnection;
  /**
   * Publish many AttractionAvailability documents
   * @deprecated Please use the new paginated many mutation (publishManyAttractionAvailabilitiesConnection)
   */
  publishManyAttractionAvailabilities: BatchPayload;
  /** Publish many AttractionAvailability documents */
  publishManyAttractionAvailabilitiesConnection: AttractionAvailabilityConnection;
  /**
   * Publish many AttractionLocation documents
   * @deprecated Please use the new paginated many mutation (publishManyAttractionLocationsConnection)
   */
  publishManyAttractionLocations: BatchPayload;
  /** Publish many AttractionLocation documents */
  publishManyAttractionLocationsConnection: AttractionLocationConnection;
  /**
   * Publish many AttractionOffer documents
   * @deprecated Please use the new paginated many mutation (publishManyAttractionOffersConnection)
   */
  publishManyAttractionOffers: BatchPayload;
  /** Publish many AttractionOffer documents */
  publishManyAttractionOffersConnection: AttractionOfferConnection;
  /**
   * Publish many AttractionPrice documents
   * @deprecated Please use the new paginated many mutation (publishManyAttractionPricesConnection)
   */
  publishManyAttractionPrices: BatchPayload;
  /** Publish many AttractionPrice documents */
  publishManyAttractionPricesConnection: AttractionPriceConnection;
  /**
   * Publish many AttractionReservation documents
   * @deprecated Please use the new paginated many mutation (publishManyAttractionReservationsConnection)
   */
  publishManyAttractionReservations: BatchPayload;
  /** Publish many AttractionReservation documents */
  publishManyAttractionReservationsConnection: AttractionReservationConnection;
  /**
   * Publish many AttractionVariant documents
   * @deprecated Please use the new paginated many mutation (publishManyAttractionVariantsConnection)
   */
  publishManyAttractionVariants: BatchPayload;
  /** Publish many AttractionVariant documents */
  publishManyAttractionVariantsConnection: AttractionVariantConnection;
  /**
   * Publish many Attraction documents
   * @deprecated Please use the new paginated many mutation (publishManyAttractionsConnection)
   */
  publishManyAttractions: BatchPayload;
  /** Publish many Attraction documents */
  publishManyAttractionsConnection: AttractionConnection;
  /**
   * Publish many Author documents
   * @deprecated Please use the new paginated many mutation (publishManyAuthorsConnection)
   */
  publishManyAuthors: BatchPayload;
  /** Publish many Author documents */
  publishManyAuthorsConnection: AuthorConnection;
  /**
   * Publish many BlockGroup documents
   * @deprecated Please use the new paginated many mutation (publishManyBlockGroupsConnection)
   */
  publishManyBlockGroups: BatchPayload;
  /** Publish many BlockGroup documents */
  publishManyBlockGroupsConnection: BlockGroupConnection;
  /**
   * Publish many Block documents
   * @deprecated Please use the new paginated many mutation (publishManyBlocksConnection)
   */
  publishManyBlocks: BatchPayload;
  /** Publish many Block documents */
  publishManyBlocksConnection: BlockConnection;
  /**
   * Publish many DetailBlock documents
   * @deprecated Please use the new paginated many mutation (publishManyDetailBlocksConnection)
   */
  publishManyDetailBlocks: BatchPayload;
  /** Publish many DetailBlock documents */
  publishManyDetailBlocksConnection: DetailBlockConnection;
  /**
   * Publish many GeneralReservation documents
   * @deprecated Please use the new paginated many mutation (publishManyGeneralReservationsConnection)
   */
  publishManyGeneralReservations: BatchPayload;
  /** Publish many GeneralReservation documents */
  publishManyGeneralReservationsConnection: GeneralReservationConnection;
  /**
   * Publish many Image documents
   * @deprecated Please use the new paginated many mutation (publishManyImagesConnection)
   */
  publishManyImages: BatchPayload;
  /** Publish many Image documents */
  publishManyImagesConnection: ImageConnection;
  /**
   * Publish many LandingPage documents
   * @deprecated Please use the new paginated many mutation (publishManyLandingPagesConnection)
   */
  publishManyLandingPages: BatchPayload;
  /** Publish many LandingPage documents */
  publishManyLandingPagesConnection: LandingPageConnection;
  /**
   * Publish many Link documents
   * @deprecated Please use the new paginated many mutation (publishManyLinksConnection)
   */
  publishManyLinks: BatchPayload;
  /** Publish many Link documents */
  publishManyLinksConnection: LinkConnection;
  /**
   * Publish many Menu documents
   * @deprecated Please use the new paginated many mutation (publishManyMenusConnection)
   */
  publishManyMenus: BatchPayload;
  /** Publish many Menu documents */
  publishManyMenusConnection: MenuConnection;
  /**
   * Publish many Notice documents
   * @deprecated Please use the new paginated many mutation (publishManyNoticesConnection)
   */
  publishManyNotices: BatchPayload;
  /** Publish many Notice documents */
  publishManyNoticesConnection: NoticeConnection;
  /**
   * Publish many PageTemplate documents
   * @deprecated Please use the new paginated many mutation (publishManyPageTemplatesConnection)
   */
  publishManyPageTemplates: BatchPayload;
  /** Publish many PageTemplate documents */
  publishManyPageTemplatesConnection: PageTemplateConnection;
  /**
   * Publish many Page documents
   * @deprecated Please use the new paginated many mutation (publishManyPagesConnection)
   */
  publishManyPages: BatchPayload;
  /** Publish many Page documents */
  publishManyPagesConnection: PageConnection;
  /**
   * Publish many PartnerOffer documents
   * @deprecated Please use the new paginated many mutation (publishManyPartnerOffersConnection)
   */
  publishManyPartnerOffers: BatchPayload;
  /** Publish many PartnerOffer documents */
  publishManyPartnerOffersConnection: PartnerOfferConnection;
  /**
   * Publish many Partner documents
   * @deprecated Please use the new paginated many mutation (publishManyPartnersConnection)
   */
  publishManyPartners: BatchPayload;
  /** Publish many Partner documents */
  publishManyPartnersConnection: PartnerConnection;
  /**
   * Publish many ProductActivity documents
   * @deprecated Please use the new paginated many mutation (publishManyProductActivitiesConnection)
   */
  publishManyProductActivities: BatchPayload;
  /** Publish many ProductActivity documents */
  publishManyProductActivitiesConnection: ProductActivityConnection;
  /**
   * Publish many ProductActivityItem documents
   * @deprecated Please use the new paginated many mutation (publishManyProductActivityItemsConnection)
   */
  publishManyProductActivityItems: BatchPayload;
  /** Publish many ProductActivityItem documents */
  publishManyProductActivityItemsConnection: ProductActivityItemConnection;
  /**
   * Publish many ProductAttractionLineup documents
   * @deprecated Please use the new paginated many mutation (publishManyProductAttractionLineupsConnection)
   */
  publishManyProductAttractionLineups: BatchPayload;
  /** Publish many ProductAttractionLineup documents */
  publishManyProductAttractionLineupsConnection: ProductAttractionLineupConnection;
  /**
   * Publish many ProductReservation documents
   * @deprecated Please use the new paginated many mutation (publishManyProductReservationsConnection)
   */
  publishManyProductReservations: BatchPayload;
  /** Publish many ProductReservation documents */
  publishManyProductReservationsConnection: ProductReservationConnection;
  /**
   * Publish many Product documents
   * @deprecated Please use the new paginated many mutation (publishManyProductsConnection)
   */
  publishManyProducts: BatchPayload;
  /** Publish many Product documents */
  publishManyProductsConnection: ProductConnection;
  /**
   * Publish many Promo documents
   * @deprecated Please use the new paginated many mutation (publishManyPromosConnection)
   */
  publishManyPromos: BatchPayload;
  /** Publish many Promo documents */
  publishManyPromosConnection: PromoConnection;
  /**
   * Publish many ReservationText documents
   * @deprecated Please use the new paginated many mutation (publishManyReservationTextsConnection)
   */
  publishManyReservationTexts: BatchPayload;
  /** Publish many ReservationText documents */
  publishManyReservationTextsConnection: ReservationTextConnection;
  /**
   * Publish many Sandbox documents
   * @deprecated Please use the new paginated many mutation (publishManySandboxesConnection)
   */
  publishManySandboxes: BatchPayload;
  /** Publish many Sandbox documents */
  publishManySandboxesConnection: SandboxConnection;
  /**
   * Publish many Seo documents
   * @deprecated Please use the new paginated many mutation (publishManySeosConnection)
   */
  publishManySeos: BatchPayload;
  /** Publish many Seo documents */
  publishManySeosConnection: SeoConnection;
  /**
   * Publish many TextGroup documents
   * @deprecated Please use the new paginated many mutation (publishManyTextGroupsConnection)
   */
  publishManyTextGroups: BatchPayload;
  /** Publish many TextGroup documents */
  publishManyTextGroupsConnection: TextGroupConnection;
  /**
   * Publish many TextList documents
   * @deprecated Please use the new paginated many mutation (publishManyTextListsConnection)
   */
  publishManyTextLists: BatchPayload;
  /** Publish many TextList documents */
  publishManyTextListsConnection: TextListConnection;
  /**
   * Publish many Text documents
   * @deprecated Please use the new paginated many mutation (publishManyTextsConnection)
   */
  publishManyTexts: BatchPayload;
  /** Publish many Text documents */
  publishManyTextsConnection: TextConnection;
  /**
   * Publish many TicketGroup documents
   * @deprecated Please use the new paginated many mutation (publishManyTicketGroupsConnection)
   */
  publishManyTicketGroups: BatchPayload;
  /** Publish many TicketGroup documents */
  publishManyTicketGroupsConnection: TicketGroupConnection;
  /**
   * Publish many TitleDescription documents
   * @deprecated Please use the new paginated many mutation (publishManyTitleDescriptionsConnection)
   */
  publishManyTitleDescriptions: BatchPayload;
  /** Publish many TitleDescription documents */
  publishManyTitleDescriptionsConnection: TitleDescriptionConnection;
  /**
   * Publish many Video documents
   * @deprecated Please use the new paginated many mutation (publishManyVideosConnection)
   */
  publishManyVideos: BatchPayload;
  /** Publish many Video documents */
  publishManyVideosConnection: VideoConnection;
  /** Publish one menu */
  publishMenu: Maybe<Menu>;
  /** Publish one notice */
  publishNotice: Maybe<Notice>;
  /** Publish one page */
  publishPage: Maybe<Page>;
  /** Publish one pageTemplate */
  publishPageTemplate: Maybe<PageTemplate>;
  /** Publish one partner */
  publishPartner: Maybe<Partner>;
  /** Publish one partnerOffer */
  publishPartnerOffer: Maybe<PartnerOffer>;
  /** Publish one product */
  publishProduct: Maybe<Product>;
  /** Publish one productActivity */
  publishProductActivity: Maybe<ProductActivity>;
  /** Publish one productActivityItem */
  publishProductActivityItem: Maybe<ProductActivityItem>;
  /** Publish one productAttractionLineup */
  publishProductAttractionLineup: Maybe<ProductAttractionLineup>;
  /** Publish one productReservation */
  publishProductReservation: Maybe<ProductReservation>;
  /** Publish one promo */
  publishPromo: Maybe<Promo>;
  /** Publish one reservationText */
  publishReservationText: Maybe<ReservationText>;
  /** Publish one sandbox */
  publishSandbox: Maybe<Sandbox>;
  /** Publish one seo */
  publishSeo: Maybe<Seo>;
  /** Publish one text */
  publishText: Maybe<Text>;
  /** Publish one textGroup */
  publishTextGroup: Maybe<TextGroup>;
  /** Publish one textList */
  publishTextList: Maybe<TextList>;
  /** Publish one ticketGroup */
  publishTicketGroup: Maybe<TicketGroup>;
  /** Publish one titleDescription */
  publishTitleDescription: Maybe<TitleDescription>;
  /** Publish one video */
  publishVideo: Maybe<Video>;
  /** Schedule to publish one addOn */
  schedulePublishAddOn: Maybe<AddOn>;
  /** Schedule to publish one alacarteTicket */
  schedulePublishAlacarteTicket: Maybe<AlacarteTicket>;
  /** Schedule to publish one alacarteTicketType */
  schedulePublishAlacarteTicketType: Maybe<AlacarteTicketType>;
  /** Schedule to publish one article */
  schedulePublishArticle: Maybe<Article>;
  /** Schedule to publish one articleCategory */
  schedulePublishArticleCategory: Maybe<ArticleCategory>;
  /** Schedule to publish one asset */
  schedulePublishAsset: Maybe<Asset>;
  /** Schedule to publish one attraction */
  schedulePublishAttraction: Maybe<Attraction>;
  /** Schedule to publish one attractionAlacarte */
  schedulePublishAttractionAlacarte: Maybe<AttractionAlacarte>;
  /** Schedule to publish one attractionAvailability */
  schedulePublishAttractionAvailability: Maybe<AttractionAvailability>;
  /** Schedule to publish one attractionLocation */
  schedulePublishAttractionLocation: Maybe<AttractionLocation>;
  /** Schedule to publish one attractionOffer */
  schedulePublishAttractionOffer: Maybe<AttractionOffer>;
  /** Schedule to publish one attractionPrice */
  schedulePublishAttractionPrice: Maybe<AttractionPrice>;
  /** Schedule to publish one attractionReservation */
  schedulePublishAttractionReservation: Maybe<AttractionReservation>;
  /** Schedule to publish one attractionVariant */
  schedulePublishAttractionVariant: Maybe<AttractionVariant>;
  /** Schedule to publish one author */
  schedulePublishAuthor: Maybe<Author>;
  /** Schedule to publish one block */
  schedulePublishBlock: Maybe<Block>;
  /** Schedule to publish one blockGroup */
  schedulePublishBlockGroup: Maybe<BlockGroup>;
  /** Schedule to publish one detailBlock */
  schedulePublishDetailBlock: Maybe<DetailBlock>;
  /** Schedule to publish one generalReservation */
  schedulePublishGeneralReservation: Maybe<GeneralReservation>;
  /** Schedule to publish one image */
  schedulePublishImage: Maybe<Image>;
  /** Schedule to publish one landingPage */
  schedulePublishLandingPage: Maybe<LandingPage>;
  /** Schedule to publish one link */
  schedulePublishLink: Maybe<Link>;
  /** Schedule to publish one menu */
  schedulePublishMenu: Maybe<Menu>;
  /** Schedule to publish one notice */
  schedulePublishNotice: Maybe<Notice>;
  /** Schedule to publish one page */
  schedulePublishPage: Maybe<Page>;
  /** Schedule to publish one pageTemplate */
  schedulePublishPageTemplate: Maybe<PageTemplate>;
  /** Schedule to publish one partner */
  schedulePublishPartner: Maybe<Partner>;
  /** Schedule to publish one partnerOffer */
  schedulePublishPartnerOffer: Maybe<PartnerOffer>;
  /** Schedule to publish one product */
  schedulePublishProduct: Maybe<Product>;
  /** Schedule to publish one productActivity */
  schedulePublishProductActivity: Maybe<ProductActivity>;
  /** Schedule to publish one productActivityItem */
  schedulePublishProductActivityItem: Maybe<ProductActivityItem>;
  /** Schedule to publish one productAttractionLineup */
  schedulePublishProductAttractionLineup: Maybe<ProductAttractionLineup>;
  /** Schedule to publish one productReservation */
  schedulePublishProductReservation: Maybe<ProductReservation>;
  /** Schedule to publish one promo */
  schedulePublishPromo: Maybe<Promo>;
  /** Schedule to publish one reservationText */
  schedulePublishReservationText: Maybe<ReservationText>;
  /** Schedule to publish one sandbox */
  schedulePublishSandbox: Maybe<Sandbox>;
  /** Schedule to publish one seo */
  schedulePublishSeo: Maybe<Seo>;
  /** Schedule to publish one text */
  schedulePublishText: Maybe<Text>;
  /** Schedule to publish one textGroup */
  schedulePublishTextGroup: Maybe<TextGroup>;
  /** Schedule to publish one textList */
  schedulePublishTextList: Maybe<TextList>;
  /** Schedule to publish one ticketGroup */
  schedulePublishTicketGroup: Maybe<TicketGroup>;
  /** Schedule to publish one titleDescription */
  schedulePublishTitleDescription: Maybe<TitleDescription>;
  /** Schedule to publish one video */
  schedulePublishVideo: Maybe<Video>;
  /** Unpublish one addOn from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishAddOn: Maybe<AddOn>;
  /** Unpublish one alacarteTicket from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishAlacarteTicket: Maybe<AlacarteTicket>;
  /** Unpublish one alacarteTicketType from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishAlacarteTicketType: Maybe<AlacarteTicketType>;
  /** Unpublish one article from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishArticle: Maybe<Article>;
  /** Unpublish one articleCategory from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishArticleCategory: Maybe<ArticleCategory>;
  /** Unpublish one asset from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishAsset: Maybe<Asset>;
  /** Unpublish one attraction from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishAttraction: Maybe<Attraction>;
  /** Unpublish one attractionAlacarte from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishAttractionAlacarte: Maybe<AttractionAlacarte>;
  /** Unpublish one attractionAvailability from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishAttractionAvailability: Maybe<AttractionAvailability>;
  /** Unpublish one attractionLocation from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishAttractionLocation: Maybe<AttractionLocation>;
  /** Unpublish one attractionOffer from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishAttractionOffer: Maybe<AttractionOffer>;
  /** Unpublish one attractionPrice from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishAttractionPrice: Maybe<AttractionPrice>;
  /** Unpublish one attractionReservation from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishAttractionReservation: Maybe<AttractionReservation>;
  /** Unpublish one attractionVariant from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishAttractionVariant: Maybe<AttractionVariant>;
  /** Unpublish one author from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishAuthor: Maybe<Author>;
  /** Unpublish one block from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishBlock: Maybe<Block>;
  /** Unpublish one blockGroup from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishBlockGroup: Maybe<BlockGroup>;
  /** Unpublish one detailBlock from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishDetailBlock: Maybe<DetailBlock>;
  /** Unpublish one generalReservation from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishGeneralReservation: Maybe<GeneralReservation>;
  /** Unpublish one image from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishImage: Maybe<Image>;
  /** Unpublish one landingPage from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishLandingPage: Maybe<LandingPage>;
  /** Unpublish one link from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishLink: Maybe<Link>;
  /** Unpublish one menu from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishMenu: Maybe<Menu>;
  /** Unpublish one notice from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishNotice: Maybe<Notice>;
  /** Unpublish one page from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishPage: Maybe<Page>;
  /** Unpublish one pageTemplate from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishPageTemplate: Maybe<PageTemplate>;
  /** Unpublish one partner from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishPartner: Maybe<Partner>;
  /** Unpublish one partnerOffer from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishPartnerOffer: Maybe<PartnerOffer>;
  /** Unpublish one product from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishProduct: Maybe<Product>;
  /** Unpublish one productActivity from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishProductActivity: Maybe<ProductActivity>;
  /** Unpublish one productActivityItem from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishProductActivityItem: Maybe<ProductActivityItem>;
  /** Unpublish one productAttractionLineup from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishProductAttractionLineup: Maybe<ProductAttractionLineup>;
  /** Unpublish one productReservation from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishProductReservation: Maybe<ProductReservation>;
  /** Unpublish one promo from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishPromo: Maybe<Promo>;
  /** Unpublish one reservationText from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishReservationText: Maybe<ReservationText>;
  /** Unpublish one sandbox from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishSandbox: Maybe<Sandbox>;
  /** Unpublish one seo from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishSeo: Maybe<Seo>;
  /** Unpublish one text from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishText: Maybe<Text>;
  /** Unpublish one textGroup from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishTextGroup: Maybe<TextGroup>;
  /** Unpublish one textList from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishTextList: Maybe<TextList>;
  /** Unpublish one ticketGroup from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishTicketGroup: Maybe<TicketGroup>;
  /** Unpublish one titleDescription from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishTitleDescription: Maybe<TitleDescription>;
  /** Unpublish one video from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishVideo: Maybe<Video>;
  /** Unpublish one addOn from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishAddOn: Maybe<AddOn>;
  /** Unpublish one alacarteTicket from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishAlacarteTicket: Maybe<AlacarteTicket>;
  /** Unpublish one alacarteTicketType from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishAlacarteTicketType: Maybe<AlacarteTicketType>;
  /** Unpublish one article from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishArticle: Maybe<Article>;
  /** Unpublish one articleCategory from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishArticleCategory: Maybe<ArticleCategory>;
  /** Unpublish one asset from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishAsset: Maybe<Asset>;
  /** Unpublish one attraction from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishAttraction: Maybe<Attraction>;
  /** Unpublish one attractionAlacarte from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishAttractionAlacarte: Maybe<AttractionAlacarte>;
  /** Unpublish one attractionAvailability from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishAttractionAvailability: Maybe<AttractionAvailability>;
  /** Unpublish one attractionLocation from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishAttractionLocation: Maybe<AttractionLocation>;
  /** Unpublish one attractionOffer from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishAttractionOffer: Maybe<AttractionOffer>;
  /** Unpublish one attractionPrice from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishAttractionPrice: Maybe<AttractionPrice>;
  /** Unpublish one attractionReservation from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishAttractionReservation: Maybe<AttractionReservation>;
  /** Unpublish one attractionVariant from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishAttractionVariant: Maybe<AttractionVariant>;
  /** Unpublish one author from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishAuthor: Maybe<Author>;
  /** Unpublish one block from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishBlock: Maybe<Block>;
  /** Unpublish one blockGroup from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishBlockGroup: Maybe<BlockGroup>;
  /** Unpublish one detailBlock from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishDetailBlock: Maybe<DetailBlock>;
  /** Unpublish one generalReservation from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishGeneralReservation: Maybe<GeneralReservation>;
  /** Unpublish one image from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishImage: Maybe<Image>;
  /** Unpublish one landingPage from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishLandingPage: Maybe<LandingPage>;
  /** Unpublish one link from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishLink: Maybe<Link>;
  /**
   * Unpublish many AddOn documents
   * @deprecated Please use the new paginated many mutation (unpublishManyAddOnsConnection)
   */
  unpublishManyAddOns: BatchPayload;
  /** Find many AddOn documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyAddOnsConnection: AddOnConnection;
  /**
   * Unpublish many AlacarteTicketType documents
   * @deprecated Please use the new paginated many mutation (unpublishManyAlacarteTicketTypesConnection)
   */
  unpublishManyAlacarteTicketTypes: BatchPayload;
  /** Find many AlacarteTicketType documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyAlacarteTicketTypesConnection: AlacarteTicketTypeConnection;
  /**
   * Unpublish many AlacarteTicket documents
   * @deprecated Please use the new paginated many mutation (unpublishManyAlacarteTicketsConnection)
   */
  unpublishManyAlacarteTickets: BatchPayload;
  /** Find many AlacarteTicket documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyAlacarteTicketsConnection: AlacarteTicketConnection;
  /**
   * Unpublish many ArticleCategory documents
   * @deprecated Please use the new paginated many mutation (unpublishManyArticleCategoriesConnection)
   */
  unpublishManyArticleCategories: BatchPayload;
  /** Find many ArticleCategory documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyArticleCategoriesConnection: ArticleCategoryConnection;
  /**
   * Unpublish many Article documents
   * @deprecated Please use the new paginated many mutation (unpublishManyArticlesConnection)
   */
  unpublishManyArticles: BatchPayload;
  /** Find many Article documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyArticlesConnection: ArticleConnection;
  /**
   * Unpublish many Asset documents
   * @deprecated Please use the new paginated many mutation (unpublishManyAssetsConnection)
   */
  unpublishManyAssets: BatchPayload;
  /** Find many Asset documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyAssetsConnection: AssetConnection;
  /**
   * Unpublish many AttractionAlacarte documents
   * @deprecated Please use the new paginated many mutation (unpublishManyAttractionAlacartesConnection)
   */
  unpublishManyAttractionAlacartes: BatchPayload;
  /** Find many AttractionAlacarte documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyAttractionAlacartesConnection: AttractionAlacarteConnection;
  /**
   * Unpublish many AttractionAvailability documents
   * @deprecated Please use the new paginated many mutation (unpublishManyAttractionAvailabilitiesConnection)
   */
  unpublishManyAttractionAvailabilities: BatchPayload;
  /** Find many AttractionAvailability documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyAttractionAvailabilitiesConnection: AttractionAvailabilityConnection;
  /**
   * Unpublish many AttractionLocation documents
   * @deprecated Please use the new paginated many mutation (unpublishManyAttractionLocationsConnection)
   */
  unpublishManyAttractionLocations: BatchPayload;
  /** Find many AttractionLocation documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyAttractionLocationsConnection: AttractionLocationConnection;
  /**
   * Unpublish many AttractionOffer documents
   * @deprecated Please use the new paginated many mutation (unpublishManyAttractionOffersConnection)
   */
  unpublishManyAttractionOffers: BatchPayload;
  /** Find many AttractionOffer documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyAttractionOffersConnection: AttractionOfferConnection;
  /**
   * Unpublish many AttractionPrice documents
   * @deprecated Please use the new paginated many mutation (unpublishManyAttractionPricesConnection)
   */
  unpublishManyAttractionPrices: BatchPayload;
  /** Find many AttractionPrice documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyAttractionPricesConnection: AttractionPriceConnection;
  /**
   * Unpublish many AttractionReservation documents
   * @deprecated Please use the new paginated many mutation (unpublishManyAttractionReservationsConnection)
   */
  unpublishManyAttractionReservations: BatchPayload;
  /** Find many AttractionReservation documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyAttractionReservationsConnection: AttractionReservationConnection;
  /**
   * Unpublish many AttractionVariant documents
   * @deprecated Please use the new paginated many mutation (unpublishManyAttractionVariantsConnection)
   */
  unpublishManyAttractionVariants: BatchPayload;
  /** Find many AttractionVariant documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyAttractionVariantsConnection: AttractionVariantConnection;
  /**
   * Unpublish many Attraction documents
   * @deprecated Please use the new paginated many mutation (unpublishManyAttractionsConnection)
   */
  unpublishManyAttractions: BatchPayload;
  /** Find many Attraction documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyAttractionsConnection: AttractionConnection;
  /**
   * Unpublish many Author documents
   * @deprecated Please use the new paginated many mutation (unpublishManyAuthorsConnection)
   */
  unpublishManyAuthors: BatchPayload;
  /** Find many Author documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyAuthorsConnection: AuthorConnection;
  /**
   * Unpublish many BlockGroup documents
   * @deprecated Please use the new paginated many mutation (unpublishManyBlockGroupsConnection)
   */
  unpublishManyBlockGroups: BatchPayload;
  /** Find many BlockGroup documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyBlockGroupsConnection: BlockGroupConnection;
  /**
   * Unpublish many Block documents
   * @deprecated Please use the new paginated many mutation (unpublishManyBlocksConnection)
   */
  unpublishManyBlocks: BatchPayload;
  /** Find many Block documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyBlocksConnection: BlockConnection;
  /**
   * Unpublish many DetailBlock documents
   * @deprecated Please use the new paginated many mutation (unpublishManyDetailBlocksConnection)
   */
  unpublishManyDetailBlocks: BatchPayload;
  /** Find many DetailBlock documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyDetailBlocksConnection: DetailBlockConnection;
  /**
   * Unpublish many GeneralReservation documents
   * @deprecated Please use the new paginated many mutation (unpublishManyGeneralReservationsConnection)
   */
  unpublishManyGeneralReservations: BatchPayload;
  /** Find many GeneralReservation documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyGeneralReservationsConnection: GeneralReservationConnection;
  /**
   * Unpublish many Image documents
   * @deprecated Please use the new paginated many mutation (unpublishManyImagesConnection)
   */
  unpublishManyImages: BatchPayload;
  /** Find many Image documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyImagesConnection: ImageConnection;
  /**
   * Unpublish many LandingPage documents
   * @deprecated Please use the new paginated many mutation (unpublishManyLandingPagesConnection)
   */
  unpublishManyLandingPages: BatchPayload;
  /** Find many LandingPage documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyLandingPagesConnection: LandingPageConnection;
  /**
   * Unpublish many Link documents
   * @deprecated Please use the new paginated many mutation (unpublishManyLinksConnection)
   */
  unpublishManyLinks: BatchPayload;
  /** Find many Link documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyLinksConnection: LinkConnection;
  /**
   * Unpublish many Menu documents
   * @deprecated Please use the new paginated many mutation (unpublishManyMenusConnection)
   */
  unpublishManyMenus: BatchPayload;
  /** Find many Menu documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyMenusConnection: MenuConnection;
  /**
   * Unpublish many Notice documents
   * @deprecated Please use the new paginated many mutation (unpublishManyNoticesConnection)
   */
  unpublishManyNotices: BatchPayload;
  /** Find many Notice documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyNoticesConnection: NoticeConnection;
  /**
   * Unpublish many PageTemplate documents
   * @deprecated Please use the new paginated many mutation (unpublishManyPageTemplatesConnection)
   */
  unpublishManyPageTemplates: BatchPayload;
  /** Find many PageTemplate documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyPageTemplatesConnection: PageTemplateConnection;
  /**
   * Unpublish many Page documents
   * @deprecated Please use the new paginated many mutation (unpublishManyPagesConnection)
   */
  unpublishManyPages: BatchPayload;
  /** Find many Page documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyPagesConnection: PageConnection;
  /**
   * Unpublish many PartnerOffer documents
   * @deprecated Please use the new paginated many mutation (unpublishManyPartnerOffersConnection)
   */
  unpublishManyPartnerOffers: BatchPayload;
  /** Find many PartnerOffer documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyPartnerOffersConnection: PartnerOfferConnection;
  /**
   * Unpublish many Partner documents
   * @deprecated Please use the new paginated many mutation (unpublishManyPartnersConnection)
   */
  unpublishManyPartners: BatchPayload;
  /** Find many Partner documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyPartnersConnection: PartnerConnection;
  /**
   * Unpublish many ProductActivity documents
   * @deprecated Please use the new paginated many mutation (unpublishManyProductActivitiesConnection)
   */
  unpublishManyProductActivities: BatchPayload;
  /** Find many ProductActivity documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyProductActivitiesConnection: ProductActivityConnection;
  /**
   * Unpublish many ProductActivityItem documents
   * @deprecated Please use the new paginated many mutation (unpublishManyProductActivityItemsConnection)
   */
  unpublishManyProductActivityItems: BatchPayload;
  /** Find many ProductActivityItem documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyProductActivityItemsConnection: ProductActivityItemConnection;
  /**
   * Unpublish many ProductAttractionLineup documents
   * @deprecated Please use the new paginated many mutation (unpublishManyProductAttractionLineupsConnection)
   */
  unpublishManyProductAttractionLineups: BatchPayload;
  /** Find many ProductAttractionLineup documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyProductAttractionLineupsConnection: ProductAttractionLineupConnection;
  /**
   * Unpublish many ProductReservation documents
   * @deprecated Please use the new paginated many mutation (unpublishManyProductReservationsConnection)
   */
  unpublishManyProductReservations: BatchPayload;
  /** Find many ProductReservation documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyProductReservationsConnection: ProductReservationConnection;
  /**
   * Unpublish many Product documents
   * @deprecated Please use the new paginated many mutation (unpublishManyProductsConnection)
   */
  unpublishManyProducts: BatchPayload;
  /** Find many Product documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyProductsConnection: ProductConnection;
  /**
   * Unpublish many Promo documents
   * @deprecated Please use the new paginated many mutation (unpublishManyPromosConnection)
   */
  unpublishManyPromos: BatchPayload;
  /** Find many Promo documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyPromosConnection: PromoConnection;
  /**
   * Unpublish many ReservationText documents
   * @deprecated Please use the new paginated many mutation (unpublishManyReservationTextsConnection)
   */
  unpublishManyReservationTexts: BatchPayload;
  /** Find many ReservationText documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyReservationTextsConnection: ReservationTextConnection;
  /**
   * Unpublish many Sandbox documents
   * @deprecated Please use the new paginated many mutation (unpublishManySandboxesConnection)
   */
  unpublishManySandboxes: BatchPayload;
  /** Find many Sandbox documents that match criteria in specified stage and unpublish from target stages */
  unpublishManySandboxesConnection: SandboxConnection;
  /**
   * Unpublish many Seo documents
   * @deprecated Please use the new paginated many mutation (unpublishManySeosConnection)
   */
  unpublishManySeos: BatchPayload;
  /** Find many Seo documents that match criteria in specified stage and unpublish from target stages */
  unpublishManySeosConnection: SeoConnection;
  /**
   * Unpublish many TextGroup documents
   * @deprecated Please use the new paginated many mutation (unpublishManyTextGroupsConnection)
   */
  unpublishManyTextGroups: BatchPayload;
  /** Find many TextGroup documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyTextGroupsConnection: TextGroupConnection;
  /**
   * Unpublish many TextList documents
   * @deprecated Please use the new paginated many mutation (unpublishManyTextListsConnection)
   */
  unpublishManyTextLists: BatchPayload;
  /** Find many TextList documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyTextListsConnection: TextListConnection;
  /**
   * Unpublish many Text documents
   * @deprecated Please use the new paginated many mutation (unpublishManyTextsConnection)
   */
  unpublishManyTexts: BatchPayload;
  /** Find many Text documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyTextsConnection: TextConnection;
  /**
   * Unpublish many TicketGroup documents
   * @deprecated Please use the new paginated many mutation (unpublishManyTicketGroupsConnection)
   */
  unpublishManyTicketGroups: BatchPayload;
  /** Find many TicketGroup documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyTicketGroupsConnection: TicketGroupConnection;
  /**
   * Unpublish many TitleDescription documents
   * @deprecated Please use the new paginated many mutation (unpublishManyTitleDescriptionsConnection)
   */
  unpublishManyTitleDescriptions: BatchPayload;
  /** Find many TitleDescription documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyTitleDescriptionsConnection: TitleDescriptionConnection;
  /**
   * Unpublish many Video documents
   * @deprecated Please use the new paginated many mutation (unpublishManyVideosConnection)
   */
  unpublishManyVideos: BatchPayload;
  /** Find many Video documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyVideosConnection: VideoConnection;
  /** Unpublish one menu from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishMenu: Maybe<Menu>;
  /** Unpublish one notice from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishNotice: Maybe<Notice>;
  /** Unpublish one page from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishPage: Maybe<Page>;
  /** Unpublish one pageTemplate from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishPageTemplate: Maybe<PageTemplate>;
  /** Unpublish one partner from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishPartner: Maybe<Partner>;
  /** Unpublish one partnerOffer from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishPartnerOffer: Maybe<PartnerOffer>;
  /** Unpublish one product from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishProduct: Maybe<Product>;
  /** Unpublish one productActivity from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishProductActivity: Maybe<ProductActivity>;
  /** Unpublish one productActivityItem from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishProductActivityItem: Maybe<ProductActivityItem>;
  /** Unpublish one productAttractionLineup from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishProductAttractionLineup: Maybe<ProductAttractionLineup>;
  /** Unpublish one productReservation from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishProductReservation: Maybe<ProductReservation>;
  /** Unpublish one promo from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishPromo: Maybe<Promo>;
  /** Unpublish one reservationText from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishReservationText: Maybe<ReservationText>;
  /** Unpublish one sandbox from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishSandbox: Maybe<Sandbox>;
  /** Unpublish one seo from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishSeo: Maybe<Seo>;
  /** Unpublish one text from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishText: Maybe<Text>;
  /** Unpublish one textGroup from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishTextGroup: Maybe<TextGroup>;
  /** Unpublish one textList from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishTextList: Maybe<TextList>;
  /** Unpublish one ticketGroup from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishTicketGroup: Maybe<TicketGroup>;
  /** Unpublish one titleDescription from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishTitleDescription: Maybe<TitleDescription>;
  /** Unpublish one video from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishVideo: Maybe<Video>;
  /** Update one addOn */
  updateAddOn: Maybe<AddOn>;
  /** Update one alacarteTicket */
  updateAlacarteTicket: Maybe<AlacarteTicket>;
  /** Update one alacarteTicketType */
  updateAlacarteTicketType: Maybe<AlacarteTicketType>;
  /** Update one article */
  updateArticle: Maybe<Article>;
  /** Update one articleCategory */
  updateArticleCategory: Maybe<ArticleCategory>;
  /** Update one asset */
  updateAsset: Maybe<Asset>;
  /** Update one attraction */
  updateAttraction: Maybe<Attraction>;
  /** Update one attractionAlacarte */
  updateAttractionAlacarte: Maybe<AttractionAlacarte>;
  /** Update one attractionAvailability */
  updateAttractionAvailability: Maybe<AttractionAvailability>;
  /** Update one attractionLocation */
  updateAttractionLocation: Maybe<AttractionLocation>;
  /** Update one attractionOffer */
  updateAttractionOffer: Maybe<AttractionOffer>;
  /** Update one attractionPrice */
  updateAttractionPrice: Maybe<AttractionPrice>;
  /** Update one attractionReservation */
  updateAttractionReservation: Maybe<AttractionReservation>;
  /** Update one attractionVariant */
  updateAttractionVariant: Maybe<AttractionVariant>;
  /** Update one author */
  updateAuthor: Maybe<Author>;
  /** Update one block */
  updateBlock: Maybe<Block>;
  /** Update one blockGroup */
  updateBlockGroup: Maybe<BlockGroup>;
  /** Update one detailBlock */
  updateDetailBlock: Maybe<DetailBlock>;
  /** Update one generalReservation */
  updateGeneralReservation: Maybe<GeneralReservation>;
  /** Update one image */
  updateImage: Maybe<Image>;
  /** Update one landingPage */
  updateLandingPage: Maybe<LandingPage>;
  /** Update one link */
  updateLink: Maybe<Link>;
  /**
   * Update many addOns
   * @deprecated Please use the new paginated many mutation (updateManyAddOnsConnection)
   */
  updateManyAddOns: BatchPayload;
  /** Update many AddOn documents */
  updateManyAddOnsConnection: AddOnConnection;
  /**
   * Update many alacarteTicketTypes
   * @deprecated Please use the new paginated many mutation (updateManyAlacarteTicketTypesConnection)
   */
  updateManyAlacarteTicketTypes: BatchPayload;
  /** Update many AlacarteTicketType documents */
  updateManyAlacarteTicketTypesConnection: AlacarteTicketTypeConnection;
  /**
   * Update many alacarteTickets
   * @deprecated Please use the new paginated many mutation (updateManyAlacarteTicketsConnection)
   */
  updateManyAlacarteTickets: BatchPayload;
  /** Update many AlacarteTicket documents */
  updateManyAlacarteTicketsConnection: AlacarteTicketConnection;
  /**
   * Update many articleCategories
   * @deprecated Please use the new paginated many mutation (updateManyArticleCategoriesConnection)
   */
  updateManyArticleCategories: BatchPayload;
  /** Update many ArticleCategory documents */
  updateManyArticleCategoriesConnection: ArticleCategoryConnection;
  /**
   * Update many articles
   * @deprecated Please use the new paginated many mutation (updateManyArticlesConnection)
   */
  updateManyArticles: BatchPayload;
  /** Update many Article documents */
  updateManyArticlesConnection: ArticleConnection;
  /**
   * Update many assets
   * @deprecated Please use the new paginated many mutation (updateManyAssetsConnection)
   */
  updateManyAssets: BatchPayload;
  /** Update many Asset documents */
  updateManyAssetsConnection: AssetConnection;
  /**
   * Update many attractionAlacartes
   * @deprecated Please use the new paginated many mutation (updateManyAttractionAlacartesConnection)
   */
  updateManyAttractionAlacartes: BatchPayload;
  /** Update many AttractionAlacarte documents */
  updateManyAttractionAlacartesConnection: AttractionAlacarteConnection;
  /**
   * Update many attractionAvailabilities
   * @deprecated Please use the new paginated many mutation (updateManyAttractionAvailabilitiesConnection)
   */
  updateManyAttractionAvailabilities: BatchPayload;
  /** Update many AttractionAvailability documents */
  updateManyAttractionAvailabilitiesConnection: AttractionAvailabilityConnection;
  /**
   * Update many attractionLocations
   * @deprecated Please use the new paginated many mutation (updateManyAttractionLocationsConnection)
   */
  updateManyAttractionLocations: BatchPayload;
  /** Update many AttractionLocation documents */
  updateManyAttractionLocationsConnection: AttractionLocationConnection;
  /**
   * Update many attractionOffers
   * @deprecated Please use the new paginated many mutation (updateManyAttractionOffersConnection)
   */
  updateManyAttractionOffers: BatchPayload;
  /** Update many AttractionOffer documents */
  updateManyAttractionOffersConnection: AttractionOfferConnection;
  /**
   * Update many attractionPrices
   * @deprecated Please use the new paginated many mutation (updateManyAttractionPricesConnection)
   */
  updateManyAttractionPrices: BatchPayload;
  /** Update many AttractionPrice documents */
  updateManyAttractionPricesConnection: AttractionPriceConnection;
  /**
   * Update many attractionReservations
   * @deprecated Please use the new paginated many mutation (updateManyAttractionReservationsConnection)
   */
  updateManyAttractionReservations: BatchPayload;
  /** Update many AttractionReservation documents */
  updateManyAttractionReservationsConnection: AttractionReservationConnection;
  /**
   * Update many attractionVariants
   * @deprecated Please use the new paginated many mutation (updateManyAttractionVariantsConnection)
   */
  updateManyAttractionVariants: BatchPayload;
  /** Update many AttractionVariant documents */
  updateManyAttractionVariantsConnection: AttractionVariantConnection;
  /**
   * Update many attractions
   * @deprecated Please use the new paginated many mutation (updateManyAttractionsConnection)
   */
  updateManyAttractions: BatchPayload;
  /** Update many Attraction documents */
  updateManyAttractionsConnection: AttractionConnection;
  /**
   * Update many authors
   * @deprecated Please use the new paginated many mutation (updateManyAuthorsConnection)
   */
  updateManyAuthors: BatchPayload;
  /** Update many Author documents */
  updateManyAuthorsConnection: AuthorConnection;
  /**
   * Update many blockGroups
   * @deprecated Please use the new paginated many mutation (updateManyBlockGroupsConnection)
   */
  updateManyBlockGroups: BatchPayload;
  /** Update many BlockGroup documents */
  updateManyBlockGroupsConnection: BlockGroupConnection;
  /**
   * Update many blocks
   * @deprecated Please use the new paginated many mutation (updateManyBlocksConnection)
   */
  updateManyBlocks: BatchPayload;
  /** Update many Block documents */
  updateManyBlocksConnection: BlockConnection;
  /**
   * Update many detailBlocks
   * @deprecated Please use the new paginated many mutation (updateManyDetailBlocksConnection)
   */
  updateManyDetailBlocks: BatchPayload;
  /** Update many DetailBlock documents */
  updateManyDetailBlocksConnection: DetailBlockConnection;
  /**
   * Update many generalReservations
   * @deprecated Please use the new paginated many mutation (updateManyGeneralReservationsConnection)
   */
  updateManyGeneralReservations: BatchPayload;
  /** Update many GeneralReservation documents */
  updateManyGeneralReservationsConnection: GeneralReservationConnection;
  /**
   * Update many images
   * @deprecated Please use the new paginated many mutation (updateManyImagesConnection)
   */
  updateManyImages: BatchPayload;
  /** Update many Image documents */
  updateManyImagesConnection: ImageConnection;
  /**
   * Update many landingPages
   * @deprecated Please use the new paginated many mutation (updateManyLandingPagesConnection)
   */
  updateManyLandingPages: BatchPayload;
  /** Update many LandingPage documents */
  updateManyLandingPagesConnection: LandingPageConnection;
  /**
   * Update many links
   * @deprecated Please use the new paginated many mutation (updateManyLinksConnection)
   */
  updateManyLinks: BatchPayload;
  /** Update many Link documents */
  updateManyLinksConnection: LinkConnection;
  /**
   * Update many menus
   * @deprecated Please use the new paginated many mutation (updateManyMenusConnection)
   */
  updateManyMenus: BatchPayload;
  /** Update many Menu documents */
  updateManyMenusConnection: MenuConnection;
  /**
   * Update many notices
   * @deprecated Please use the new paginated many mutation (updateManyNoticesConnection)
   */
  updateManyNotices: BatchPayload;
  /** Update many Notice documents */
  updateManyNoticesConnection: NoticeConnection;
  /**
   * Update many pageTemplates
   * @deprecated Please use the new paginated many mutation (updateManyPageTemplatesConnection)
   */
  updateManyPageTemplates: BatchPayload;
  /** Update many PageTemplate documents */
  updateManyPageTemplatesConnection: PageTemplateConnection;
  /**
   * Update many pages
   * @deprecated Please use the new paginated many mutation (updateManyPagesConnection)
   */
  updateManyPages: BatchPayload;
  /** Update many Page documents */
  updateManyPagesConnection: PageConnection;
  /**
   * Update many partnerOffers
   * @deprecated Please use the new paginated many mutation (updateManyPartnerOffersConnection)
   */
  updateManyPartnerOffers: BatchPayload;
  /** Update many PartnerOffer documents */
  updateManyPartnerOffersConnection: PartnerOfferConnection;
  /**
   * Update many partners
   * @deprecated Please use the new paginated many mutation (updateManyPartnersConnection)
   */
  updateManyPartners: BatchPayload;
  /** Update many Partner documents */
  updateManyPartnersConnection: PartnerConnection;
  /**
   * Update many productActivities
   * @deprecated Please use the new paginated many mutation (updateManyProductActivitiesConnection)
   */
  updateManyProductActivities: BatchPayload;
  /** Update many ProductActivity documents */
  updateManyProductActivitiesConnection: ProductActivityConnection;
  /**
   * Update many productActivityItems
   * @deprecated Please use the new paginated many mutation (updateManyProductActivityItemsConnection)
   */
  updateManyProductActivityItems: BatchPayload;
  /** Update many ProductActivityItem documents */
  updateManyProductActivityItemsConnection: ProductActivityItemConnection;
  /**
   * Update many productAttractionLineups
   * @deprecated Please use the new paginated many mutation (updateManyProductAttractionLineupsConnection)
   */
  updateManyProductAttractionLineups: BatchPayload;
  /** Update many ProductAttractionLineup documents */
  updateManyProductAttractionLineupsConnection: ProductAttractionLineupConnection;
  /**
   * Update many productReservations
   * @deprecated Please use the new paginated many mutation (updateManyProductReservationsConnection)
   */
  updateManyProductReservations: BatchPayload;
  /** Update many ProductReservation documents */
  updateManyProductReservationsConnection: ProductReservationConnection;
  /**
   * Update many products
   * @deprecated Please use the new paginated many mutation (updateManyProductsConnection)
   */
  updateManyProducts: BatchPayload;
  /** Update many Product documents */
  updateManyProductsConnection: ProductConnection;
  /**
   * Update many promos
   * @deprecated Please use the new paginated many mutation (updateManyPromosConnection)
   */
  updateManyPromos: BatchPayload;
  /** Update many Promo documents */
  updateManyPromosConnection: PromoConnection;
  /**
   * Update many reservationTexts
   * @deprecated Please use the new paginated many mutation (updateManyReservationTextsConnection)
   */
  updateManyReservationTexts: BatchPayload;
  /** Update many ReservationText documents */
  updateManyReservationTextsConnection: ReservationTextConnection;
  /**
   * Update many sandboxes
   * @deprecated Please use the new paginated many mutation (updateManySandboxesConnection)
   */
  updateManySandboxes: BatchPayload;
  /** Update many Sandbox documents */
  updateManySandboxesConnection: SandboxConnection;
  /**
   * Update many seos
   * @deprecated Please use the new paginated many mutation (updateManySeosConnection)
   */
  updateManySeos: BatchPayload;
  /** Update many Seo documents */
  updateManySeosConnection: SeoConnection;
  /**
   * Update many textGroups
   * @deprecated Please use the new paginated many mutation (updateManyTextGroupsConnection)
   */
  updateManyTextGroups: BatchPayload;
  /** Update many TextGroup documents */
  updateManyTextGroupsConnection: TextGroupConnection;
  /**
   * Update many textLists
   * @deprecated Please use the new paginated many mutation (updateManyTextListsConnection)
   */
  updateManyTextLists: BatchPayload;
  /** Update many TextList documents */
  updateManyTextListsConnection: TextListConnection;
  /**
   * Update many texts
   * @deprecated Please use the new paginated many mutation (updateManyTextsConnection)
   */
  updateManyTexts: BatchPayload;
  /** Update many Text documents */
  updateManyTextsConnection: TextConnection;
  /**
   * Update many ticketGroups
   * @deprecated Please use the new paginated many mutation (updateManyTicketGroupsConnection)
   */
  updateManyTicketGroups: BatchPayload;
  /** Update many TicketGroup documents */
  updateManyTicketGroupsConnection: TicketGroupConnection;
  /**
   * Update many titleDescriptions
   * @deprecated Please use the new paginated many mutation (updateManyTitleDescriptionsConnection)
   */
  updateManyTitleDescriptions: BatchPayload;
  /** Update many TitleDescription documents */
  updateManyTitleDescriptionsConnection: TitleDescriptionConnection;
  /**
   * Update many videos
   * @deprecated Please use the new paginated many mutation (updateManyVideosConnection)
   */
  updateManyVideos: BatchPayload;
  /** Update many Video documents */
  updateManyVideosConnection: VideoConnection;
  /** Update one menu */
  updateMenu: Maybe<Menu>;
  /** Update one notice */
  updateNotice: Maybe<Notice>;
  /** Update one page */
  updatePage: Maybe<Page>;
  /** Update one pageTemplate */
  updatePageTemplate: Maybe<PageTemplate>;
  /** Update one partner */
  updatePartner: Maybe<Partner>;
  /** Update one partnerOffer */
  updatePartnerOffer: Maybe<PartnerOffer>;
  /** Update one product */
  updateProduct: Maybe<Product>;
  /** Update one productActivity */
  updateProductActivity: Maybe<ProductActivity>;
  /** Update one productActivityItem */
  updateProductActivityItem: Maybe<ProductActivityItem>;
  /** Update one productAttractionLineup */
  updateProductAttractionLineup: Maybe<ProductAttractionLineup>;
  /** Update one productReservation */
  updateProductReservation: Maybe<ProductReservation>;
  /** Update one promo */
  updatePromo: Maybe<Promo>;
  /** Update one reservationText */
  updateReservationText: Maybe<ReservationText>;
  /** Update one sandbox */
  updateSandbox: Maybe<Sandbox>;
  /** Update one scheduledRelease */
  updateScheduledRelease: Maybe<ScheduledRelease>;
  /** Update one seo */
  updateSeo: Maybe<Seo>;
  /** Update one text */
  updateText: Maybe<Text>;
  /** Update one textGroup */
  updateTextGroup: Maybe<TextGroup>;
  /** Update one textList */
  updateTextList: Maybe<TextList>;
  /** Update one ticketGroup */
  updateTicketGroup: Maybe<TicketGroup>;
  /** Update one titleDescription */
  updateTitleDescription: Maybe<TitleDescription>;
  /** Update one video */
  updateVideo: Maybe<Video>;
  /** Upsert one addOn */
  upsertAddOn: Maybe<AddOn>;
  /** Upsert one alacarteTicket */
  upsertAlacarteTicket: Maybe<AlacarteTicket>;
  /** Upsert one alacarteTicketType */
  upsertAlacarteTicketType: Maybe<AlacarteTicketType>;
  /** Upsert one article */
  upsertArticle: Maybe<Article>;
  /** Upsert one articleCategory */
  upsertArticleCategory: Maybe<ArticleCategory>;
  /** Upsert one asset */
  upsertAsset: Maybe<Asset>;
  /** Upsert one attraction */
  upsertAttraction: Maybe<Attraction>;
  /** Upsert one attractionAlacarte */
  upsertAttractionAlacarte: Maybe<AttractionAlacarte>;
  /** Upsert one attractionAvailability */
  upsertAttractionAvailability: Maybe<AttractionAvailability>;
  /** Upsert one attractionLocation */
  upsertAttractionLocation: Maybe<AttractionLocation>;
  /** Upsert one attractionOffer */
  upsertAttractionOffer: Maybe<AttractionOffer>;
  /** Upsert one attractionPrice */
  upsertAttractionPrice: Maybe<AttractionPrice>;
  /** Upsert one attractionReservation */
  upsertAttractionReservation: Maybe<AttractionReservation>;
  /** Upsert one attractionVariant */
  upsertAttractionVariant: Maybe<AttractionVariant>;
  /** Upsert one author */
  upsertAuthor: Maybe<Author>;
  /** Upsert one block */
  upsertBlock: Maybe<Block>;
  /** Upsert one blockGroup */
  upsertBlockGroup: Maybe<BlockGroup>;
  /** Upsert one detailBlock */
  upsertDetailBlock: Maybe<DetailBlock>;
  /** Upsert one generalReservation */
  upsertGeneralReservation: Maybe<GeneralReservation>;
  /** Upsert one image */
  upsertImage: Maybe<Image>;
  /** Upsert one landingPage */
  upsertLandingPage: Maybe<LandingPage>;
  /** Upsert one link */
  upsertLink: Maybe<Link>;
  /** Upsert one menu */
  upsertMenu: Maybe<Menu>;
  /** Upsert one notice */
  upsertNotice: Maybe<Notice>;
  /** Upsert one page */
  upsertPage: Maybe<Page>;
  /** Upsert one pageTemplate */
  upsertPageTemplate: Maybe<PageTemplate>;
  /** Upsert one partner */
  upsertPartner: Maybe<Partner>;
  /** Upsert one partnerOffer */
  upsertPartnerOffer: Maybe<PartnerOffer>;
  /** Upsert one product */
  upsertProduct: Maybe<Product>;
  /** Upsert one productActivity */
  upsertProductActivity: Maybe<ProductActivity>;
  /** Upsert one productActivityItem */
  upsertProductActivityItem: Maybe<ProductActivityItem>;
  /** Upsert one productAttractionLineup */
  upsertProductAttractionLineup: Maybe<ProductAttractionLineup>;
  /** Upsert one productReservation */
  upsertProductReservation: Maybe<ProductReservation>;
  /** Upsert one promo */
  upsertPromo: Maybe<Promo>;
  /** Upsert one reservationText */
  upsertReservationText: Maybe<ReservationText>;
  /** Upsert one sandbox */
  upsertSandbox: Maybe<Sandbox>;
  /** Upsert one seo */
  upsertSeo: Maybe<Seo>;
  /** Upsert one text */
  upsertText: Maybe<Text>;
  /** Upsert one textGroup */
  upsertTextGroup: Maybe<TextGroup>;
  /** Upsert one textList */
  upsertTextList: Maybe<TextList>;
  /** Upsert one ticketGroup */
  upsertTicketGroup: Maybe<TicketGroup>;
  /** Upsert one titleDescription */
  upsertTitleDescription: Maybe<TitleDescription>;
  /** Upsert one video */
  upsertVideo: Maybe<Video>;
};

export type MutationCreateAddOnArgs = {
  data: AddOnCreateInput;
};

export type MutationCreateAlacarteTicketArgs = {
  data: AlacarteTicketCreateInput;
};

export type MutationCreateAlacarteTicketTypeArgs = {
  data: AlacarteTicketTypeCreateInput;
};

export type MutationCreateArticleArgs = {
  data: ArticleCreateInput;
};

export type MutationCreateArticleCategoryArgs = {
  data: ArticleCategoryCreateInput;
};

export type MutationCreateAssetArgs = {
  data: AssetCreateInput;
};

export type MutationCreateAttractionArgs = {
  data: AttractionCreateInput;
};

export type MutationCreateAttractionAlacarteArgs = {
  data: AttractionAlacarteCreateInput;
};

export type MutationCreateAttractionAvailabilityArgs = {
  data: AttractionAvailabilityCreateInput;
};

export type MutationCreateAttractionLocationArgs = {
  data: AttractionLocationCreateInput;
};

export type MutationCreateAttractionOfferArgs = {
  data: AttractionOfferCreateInput;
};

export type MutationCreateAttractionPriceArgs = {
  data: AttractionPriceCreateInput;
};

export type MutationCreateAttractionReservationArgs = {
  data: AttractionReservationCreateInput;
};

export type MutationCreateAttractionVariantArgs = {
  data: AttractionVariantCreateInput;
};

export type MutationCreateAuthorArgs = {
  data: AuthorCreateInput;
};

export type MutationCreateBlockArgs = {
  data: BlockCreateInput;
};

export type MutationCreateBlockGroupArgs = {
  data: BlockGroupCreateInput;
};

export type MutationCreateDetailBlockArgs = {
  data: DetailBlockCreateInput;
};

export type MutationCreateGeneralReservationArgs = {
  data: GeneralReservationCreateInput;
};

export type MutationCreateImageArgs = {
  data: ImageCreateInput;
};

export type MutationCreateLandingPageArgs = {
  data: LandingPageCreateInput;
};

export type MutationCreateLinkArgs = {
  data: LinkCreateInput;
};

export type MutationCreateMenuArgs = {
  data: MenuCreateInput;
};

export type MutationCreateNoticeArgs = {
  data: NoticeCreateInput;
};

export type MutationCreatePageArgs = {
  data: PageCreateInput;
};

export type MutationCreatePageTemplateArgs = {
  data: PageTemplateCreateInput;
};

export type MutationCreatePartnerArgs = {
  data: PartnerCreateInput;
};

export type MutationCreatePartnerOfferArgs = {
  data: PartnerOfferCreateInput;
};

export type MutationCreateProductArgs = {
  data: ProductCreateInput;
};

export type MutationCreateProductActivityArgs = {
  data: ProductActivityCreateInput;
};

export type MutationCreateProductActivityItemArgs = {
  data: ProductActivityItemCreateInput;
};

export type MutationCreateProductAttractionLineupArgs = {
  data: ProductAttractionLineupCreateInput;
};

export type MutationCreateProductReservationArgs = {
  data: ProductReservationCreateInput;
};

export type MutationCreatePromoArgs = {
  data: PromoCreateInput;
};

export type MutationCreateReservationTextArgs = {
  data: ReservationTextCreateInput;
};

export type MutationCreateSandboxArgs = {
  data: SandboxCreateInput;
};

export type MutationCreateScheduledReleaseArgs = {
  data: ScheduledReleaseCreateInput;
};

export type MutationCreateSeoArgs = {
  data: SeoCreateInput;
};

export type MutationCreateTextArgs = {
  data: TextCreateInput;
};

export type MutationCreateTextGroupArgs = {
  data: TextGroupCreateInput;
};

export type MutationCreateTextListArgs = {
  data: TextListCreateInput;
};

export type MutationCreateTicketGroupArgs = {
  data: TicketGroupCreateInput;
};

export type MutationCreateTitleDescriptionArgs = {
  data: TitleDescriptionCreateInput;
};

export type MutationCreateVideoArgs = {
  data: VideoCreateInput;
};

export type MutationDeleteAddOnArgs = {
  where: AddOnWhereUniqueInput;
};

export type MutationDeleteAlacarteTicketArgs = {
  where: AlacarteTicketWhereUniqueInput;
};

export type MutationDeleteAlacarteTicketTypeArgs = {
  where: AlacarteTicketTypeWhereUniqueInput;
};

export type MutationDeleteArticleArgs = {
  where: ArticleWhereUniqueInput;
};

export type MutationDeleteArticleCategoryArgs = {
  where: ArticleCategoryWhereUniqueInput;
};

export type MutationDeleteAssetArgs = {
  where: AssetWhereUniqueInput;
};

export type MutationDeleteAttractionArgs = {
  where: AttractionWhereUniqueInput;
};

export type MutationDeleteAttractionAlacarteArgs = {
  where: AttractionAlacarteWhereUniqueInput;
};

export type MutationDeleteAttractionAvailabilityArgs = {
  where: AttractionAvailabilityWhereUniqueInput;
};

export type MutationDeleteAttractionLocationArgs = {
  where: AttractionLocationWhereUniqueInput;
};

export type MutationDeleteAttractionOfferArgs = {
  where: AttractionOfferWhereUniqueInput;
};

export type MutationDeleteAttractionPriceArgs = {
  where: AttractionPriceWhereUniqueInput;
};

export type MutationDeleteAttractionReservationArgs = {
  where: AttractionReservationWhereUniqueInput;
};

export type MutationDeleteAttractionVariantArgs = {
  where: AttractionVariantWhereUniqueInput;
};

export type MutationDeleteAuthorArgs = {
  where: AuthorWhereUniqueInput;
};

export type MutationDeleteBlockArgs = {
  where: BlockWhereUniqueInput;
};

export type MutationDeleteBlockGroupArgs = {
  where: BlockGroupWhereUniqueInput;
};

export type MutationDeleteDetailBlockArgs = {
  where: DetailBlockWhereUniqueInput;
};

export type MutationDeleteGeneralReservationArgs = {
  where: GeneralReservationWhereUniqueInput;
};

export type MutationDeleteImageArgs = {
  where: ImageWhereUniqueInput;
};

export type MutationDeleteLandingPageArgs = {
  where: LandingPageWhereUniqueInput;
};

export type MutationDeleteLinkArgs = {
  where: LinkWhereUniqueInput;
};

export type MutationDeleteManyAddOnsArgs = {
  where: InputMaybe<AddOnManyWhereInput>;
};

export type MutationDeleteManyAddOnsConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AddOnManyWhereInput>;
};

export type MutationDeleteManyAlacarteTicketTypesArgs = {
  where: InputMaybe<AlacarteTicketTypeManyWhereInput>;
};

export type MutationDeleteManyAlacarteTicketTypesConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AlacarteTicketTypeManyWhereInput>;
};

export type MutationDeleteManyAlacarteTicketsArgs = {
  where: InputMaybe<AlacarteTicketManyWhereInput>;
};

export type MutationDeleteManyAlacarteTicketsConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AlacarteTicketManyWhereInput>;
};

export type MutationDeleteManyArticleCategoriesArgs = {
  where: InputMaybe<ArticleCategoryManyWhereInput>;
};

export type MutationDeleteManyArticleCategoriesConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ArticleCategoryManyWhereInput>;
};

export type MutationDeleteManyArticlesArgs = {
  where: InputMaybe<ArticleManyWhereInput>;
};

export type MutationDeleteManyArticlesConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ArticleManyWhereInput>;
};

export type MutationDeleteManyAssetsArgs = {
  where: InputMaybe<AssetManyWhereInput>;
};

export type MutationDeleteManyAssetsConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AssetManyWhereInput>;
};

export type MutationDeleteManyAttractionAlacartesArgs = {
  where: InputMaybe<AttractionAlacarteManyWhereInput>;
};

export type MutationDeleteManyAttractionAlacartesConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AttractionAlacarteManyWhereInput>;
};

export type MutationDeleteManyAttractionAvailabilitiesArgs = {
  where: InputMaybe<AttractionAvailabilityManyWhereInput>;
};

export type MutationDeleteManyAttractionAvailabilitiesConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AttractionAvailabilityManyWhereInput>;
};

export type MutationDeleteManyAttractionLocationsArgs = {
  where: InputMaybe<AttractionLocationManyWhereInput>;
};

export type MutationDeleteManyAttractionLocationsConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AttractionLocationManyWhereInput>;
};

export type MutationDeleteManyAttractionOffersArgs = {
  where: InputMaybe<AttractionOfferManyWhereInput>;
};

export type MutationDeleteManyAttractionOffersConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AttractionOfferManyWhereInput>;
};

export type MutationDeleteManyAttractionPricesArgs = {
  where: InputMaybe<AttractionPriceManyWhereInput>;
};

export type MutationDeleteManyAttractionPricesConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AttractionPriceManyWhereInput>;
};

export type MutationDeleteManyAttractionReservationsArgs = {
  where: InputMaybe<AttractionReservationManyWhereInput>;
};

export type MutationDeleteManyAttractionReservationsConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AttractionReservationManyWhereInput>;
};

export type MutationDeleteManyAttractionVariantsArgs = {
  where: InputMaybe<AttractionVariantManyWhereInput>;
};

export type MutationDeleteManyAttractionVariantsConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AttractionVariantManyWhereInput>;
};

export type MutationDeleteManyAttractionsArgs = {
  where: InputMaybe<AttractionManyWhereInput>;
};

export type MutationDeleteManyAttractionsConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AttractionManyWhereInput>;
};

export type MutationDeleteManyAuthorsArgs = {
  where: InputMaybe<AuthorManyWhereInput>;
};

export type MutationDeleteManyAuthorsConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AuthorManyWhereInput>;
};

export type MutationDeleteManyBlockGroupsArgs = {
  where: InputMaybe<BlockGroupManyWhereInput>;
};

export type MutationDeleteManyBlockGroupsConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<BlockGroupManyWhereInput>;
};

export type MutationDeleteManyBlocksArgs = {
  where: InputMaybe<BlockManyWhereInput>;
};

export type MutationDeleteManyBlocksConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<BlockManyWhereInput>;
};

export type MutationDeleteManyDetailBlocksArgs = {
  where: InputMaybe<DetailBlockManyWhereInput>;
};

export type MutationDeleteManyDetailBlocksConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<DetailBlockManyWhereInput>;
};

export type MutationDeleteManyGeneralReservationsArgs = {
  where: InputMaybe<GeneralReservationManyWhereInput>;
};

export type MutationDeleteManyGeneralReservationsConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<GeneralReservationManyWhereInput>;
};

export type MutationDeleteManyImagesArgs = {
  where: InputMaybe<ImageManyWhereInput>;
};

export type MutationDeleteManyImagesConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ImageManyWhereInput>;
};

export type MutationDeleteManyLandingPagesArgs = {
  where: InputMaybe<LandingPageManyWhereInput>;
};

export type MutationDeleteManyLandingPagesConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<LandingPageManyWhereInput>;
};

export type MutationDeleteManyLinksArgs = {
  where: InputMaybe<LinkManyWhereInput>;
};

export type MutationDeleteManyLinksConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<LinkManyWhereInput>;
};

export type MutationDeleteManyMenusArgs = {
  where: InputMaybe<MenuManyWhereInput>;
};

export type MutationDeleteManyMenusConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<MenuManyWhereInput>;
};

export type MutationDeleteManyNoticesArgs = {
  where: InputMaybe<NoticeManyWhereInput>;
};

export type MutationDeleteManyNoticesConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<NoticeManyWhereInput>;
};

export type MutationDeleteManyPageTemplatesArgs = {
  where: InputMaybe<PageTemplateManyWhereInput>;
};

export type MutationDeleteManyPageTemplatesConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<PageTemplateManyWhereInput>;
};

export type MutationDeleteManyPagesArgs = {
  where: InputMaybe<PageManyWhereInput>;
};

export type MutationDeleteManyPagesConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<PageManyWhereInput>;
};

export type MutationDeleteManyPartnerOffersArgs = {
  where: InputMaybe<PartnerOfferManyWhereInput>;
};

export type MutationDeleteManyPartnerOffersConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<PartnerOfferManyWhereInput>;
};

export type MutationDeleteManyPartnersArgs = {
  where: InputMaybe<PartnerManyWhereInput>;
};

export type MutationDeleteManyPartnersConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<PartnerManyWhereInput>;
};

export type MutationDeleteManyProductActivitiesArgs = {
  where: InputMaybe<ProductActivityManyWhereInput>;
};

export type MutationDeleteManyProductActivitiesConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ProductActivityManyWhereInput>;
};

export type MutationDeleteManyProductActivityItemsArgs = {
  where: InputMaybe<ProductActivityItemManyWhereInput>;
};

export type MutationDeleteManyProductActivityItemsConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ProductActivityItemManyWhereInput>;
};

export type MutationDeleteManyProductAttractionLineupsArgs = {
  where: InputMaybe<ProductAttractionLineupManyWhereInput>;
};

export type MutationDeleteManyProductAttractionLineupsConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ProductAttractionLineupManyWhereInput>;
};

export type MutationDeleteManyProductReservationsArgs = {
  where: InputMaybe<ProductReservationManyWhereInput>;
};

export type MutationDeleteManyProductReservationsConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ProductReservationManyWhereInput>;
};

export type MutationDeleteManyProductsArgs = {
  where: InputMaybe<ProductManyWhereInput>;
};

export type MutationDeleteManyProductsConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ProductManyWhereInput>;
};

export type MutationDeleteManyPromosArgs = {
  where: InputMaybe<PromoManyWhereInput>;
};

export type MutationDeleteManyPromosConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<PromoManyWhereInput>;
};

export type MutationDeleteManyReservationTextsArgs = {
  where: InputMaybe<ReservationTextManyWhereInput>;
};

export type MutationDeleteManyReservationTextsConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ReservationTextManyWhereInput>;
};

export type MutationDeleteManySandboxesArgs = {
  where: InputMaybe<SandboxManyWhereInput>;
};

export type MutationDeleteManySandboxesConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<SandboxManyWhereInput>;
};

export type MutationDeleteManySeosArgs = {
  where: InputMaybe<SeoManyWhereInput>;
};

export type MutationDeleteManySeosConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<SeoManyWhereInput>;
};

export type MutationDeleteManyTextGroupsArgs = {
  where: InputMaybe<TextGroupManyWhereInput>;
};

export type MutationDeleteManyTextGroupsConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<TextGroupManyWhereInput>;
};

export type MutationDeleteManyTextListsArgs = {
  where: InputMaybe<TextListManyWhereInput>;
};

export type MutationDeleteManyTextListsConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<TextListManyWhereInput>;
};

export type MutationDeleteManyTextsArgs = {
  where: InputMaybe<TextManyWhereInput>;
};

export type MutationDeleteManyTextsConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<TextManyWhereInput>;
};

export type MutationDeleteManyTicketGroupsArgs = {
  where: InputMaybe<TicketGroupManyWhereInput>;
};

export type MutationDeleteManyTicketGroupsConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<TicketGroupManyWhereInput>;
};

export type MutationDeleteManyTitleDescriptionsArgs = {
  where: InputMaybe<TitleDescriptionManyWhereInput>;
};

export type MutationDeleteManyTitleDescriptionsConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<TitleDescriptionManyWhereInput>;
};

export type MutationDeleteManyVideosArgs = {
  where: InputMaybe<VideoManyWhereInput>;
};

export type MutationDeleteManyVideosConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<VideoManyWhereInput>;
};

export type MutationDeleteMenuArgs = {
  where: MenuWhereUniqueInput;
};

export type MutationDeleteNoticeArgs = {
  where: NoticeWhereUniqueInput;
};

export type MutationDeletePageArgs = {
  where: PageWhereUniqueInput;
};

export type MutationDeletePageTemplateArgs = {
  where: PageTemplateWhereUniqueInput;
};

export type MutationDeletePartnerArgs = {
  where: PartnerWhereUniqueInput;
};

export type MutationDeletePartnerOfferArgs = {
  where: PartnerOfferWhereUniqueInput;
};

export type MutationDeleteProductArgs = {
  where: ProductWhereUniqueInput;
};

export type MutationDeleteProductActivityArgs = {
  where: ProductActivityWhereUniqueInput;
};

export type MutationDeleteProductActivityItemArgs = {
  where: ProductActivityItemWhereUniqueInput;
};

export type MutationDeleteProductAttractionLineupArgs = {
  where: ProductAttractionLineupWhereUniqueInput;
};

export type MutationDeleteProductReservationArgs = {
  where: ProductReservationWhereUniqueInput;
};

export type MutationDeletePromoArgs = {
  where: PromoWhereUniqueInput;
};

export type MutationDeleteReservationTextArgs = {
  where: ReservationTextWhereUniqueInput;
};

export type MutationDeleteSandboxArgs = {
  where: SandboxWhereUniqueInput;
};

export type MutationDeleteScheduledOperationArgs = {
  where: ScheduledOperationWhereUniqueInput;
};

export type MutationDeleteScheduledReleaseArgs = {
  where: ScheduledReleaseWhereUniqueInput;
};

export type MutationDeleteSeoArgs = {
  where: SeoWhereUniqueInput;
};

export type MutationDeleteTextArgs = {
  where: TextWhereUniqueInput;
};

export type MutationDeleteTextGroupArgs = {
  where: TextGroupWhereUniqueInput;
};

export type MutationDeleteTextListArgs = {
  where: TextListWhereUniqueInput;
};

export type MutationDeleteTicketGroupArgs = {
  where: TicketGroupWhereUniqueInput;
};

export type MutationDeleteTitleDescriptionArgs = {
  where: TitleDescriptionWhereUniqueInput;
};

export type MutationDeleteVideoArgs = {
  where: VideoWhereUniqueInput;
};

export type MutationPublishAddOnArgs = {
  locales: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where: AddOnWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishAlacarteTicketArgs = {
  to?: Array<Stage>;
  where: AlacarteTicketWhereUniqueInput;
};

export type MutationPublishAlacarteTicketTypeArgs = {
  to?: Array<Stage>;
  where: AlacarteTicketTypeWhereUniqueInput;
};

export type MutationPublishArticleArgs = {
  locales: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where: ArticleWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishArticleCategoryArgs = {
  to?: Array<Stage>;
  where: ArticleCategoryWhereUniqueInput;
};

export type MutationPublishAssetArgs = {
  locales: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where: AssetWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishAttractionArgs = {
  locales: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where: AttractionWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishAttractionAlacarteArgs = {
  to?: Array<Stage>;
  where: AttractionAlacarteWhereUniqueInput;
};

export type MutationPublishAttractionAvailabilityArgs = {
  locales: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where: AttractionAvailabilityWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishAttractionLocationArgs = {
  locales: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where: AttractionLocationWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishAttractionOfferArgs = {
  locales: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where: AttractionOfferWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishAttractionPriceArgs = {
  locales: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where: AttractionPriceWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishAttractionReservationArgs = {
  to?: Array<Stage>;
  where: AttractionReservationWhereUniqueInput;
};

export type MutationPublishAttractionVariantArgs = {
  locales: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where: AttractionVariantWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishAuthorArgs = {
  to?: Array<Stage>;
  where: AuthorWhereUniqueInput;
};

export type MutationPublishBlockArgs = {
  to?: Array<Stage>;
  where: BlockWhereUniqueInput;
};

export type MutationPublishBlockGroupArgs = {
  to?: Array<Stage>;
  where: BlockGroupWhereUniqueInput;
};

export type MutationPublishDetailBlockArgs = {
  to?: Array<Stage>;
  where: DetailBlockWhereUniqueInput;
};

export type MutationPublishGeneralReservationArgs = {
  to?: Array<Stage>;
  where: GeneralReservationWhereUniqueInput;
};

export type MutationPublishImageArgs = {
  locales: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where: ImageWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishLandingPageArgs = {
  locales: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where: LandingPageWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishLinkArgs = {
  to?: Array<Stage>;
  where: LinkWhereUniqueInput;
};

export type MutationPublishManyAddOnsArgs = {
  locales: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where: InputMaybe<AddOnManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishManyAddOnsConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Stage>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  to?: Array<Stage>;
  where: InputMaybe<AddOnManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishManyAlacarteTicketTypesArgs = {
  to?: Array<Stage>;
  where: InputMaybe<AlacarteTicketTypeManyWhereInput>;
};

export type MutationPublishManyAlacarteTicketTypesConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Stage>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  to?: Array<Stage>;
  where: InputMaybe<AlacarteTicketTypeManyWhereInput>;
};

export type MutationPublishManyAlacarteTicketsArgs = {
  to?: Array<Stage>;
  where: InputMaybe<AlacarteTicketManyWhereInput>;
};

export type MutationPublishManyAlacarteTicketsConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Stage>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  to?: Array<Stage>;
  where: InputMaybe<AlacarteTicketManyWhereInput>;
};

export type MutationPublishManyArticleCategoriesArgs = {
  to?: Array<Stage>;
  where: InputMaybe<ArticleCategoryManyWhereInput>;
};

export type MutationPublishManyArticleCategoriesConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Stage>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  to?: Array<Stage>;
  where: InputMaybe<ArticleCategoryManyWhereInput>;
};

export type MutationPublishManyArticlesArgs = {
  locales: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where: InputMaybe<ArticleManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishManyArticlesConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Stage>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  to?: Array<Stage>;
  where: InputMaybe<ArticleManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishManyAssetsArgs = {
  locales: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where: InputMaybe<AssetManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishManyAssetsConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Stage>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  to?: Array<Stage>;
  where: InputMaybe<AssetManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishManyAttractionAlacartesArgs = {
  to?: Array<Stage>;
  where: InputMaybe<AttractionAlacarteManyWhereInput>;
};

export type MutationPublishManyAttractionAlacartesConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Stage>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  to?: Array<Stage>;
  where: InputMaybe<AttractionAlacarteManyWhereInput>;
};

export type MutationPublishManyAttractionAvailabilitiesArgs = {
  locales: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where: InputMaybe<AttractionAvailabilityManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishManyAttractionAvailabilitiesConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Stage>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  to?: Array<Stage>;
  where: InputMaybe<AttractionAvailabilityManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishManyAttractionLocationsArgs = {
  locales: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where: InputMaybe<AttractionLocationManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishManyAttractionLocationsConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Stage>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  to?: Array<Stage>;
  where: InputMaybe<AttractionLocationManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishManyAttractionOffersArgs = {
  locales: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where: InputMaybe<AttractionOfferManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishManyAttractionOffersConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Stage>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  to?: Array<Stage>;
  where: InputMaybe<AttractionOfferManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishManyAttractionPricesArgs = {
  locales: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where: InputMaybe<AttractionPriceManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishManyAttractionPricesConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Stage>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  to?: Array<Stage>;
  where: InputMaybe<AttractionPriceManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishManyAttractionReservationsArgs = {
  to?: Array<Stage>;
  where: InputMaybe<AttractionReservationManyWhereInput>;
};

export type MutationPublishManyAttractionReservationsConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Stage>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  to?: Array<Stage>;
  where: InputMaybe<AttractionReservationManyWhereInput>;
};

export type MutationPublishManyAttractionVariantsArgs = {
  locales: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where: InputMaybe<AttractionVariantManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishManyAttractionVariantsConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Stage>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  to?: Array<Stage>;
  where: InputMaybe<AttractionVariantManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishManyAttractionsArgs = {
  locales: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where: InputMaybe<AttractionManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishManyAttractionsConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Stage>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  to?: Array<Stage>;
  where: InputMaybe<AttractionManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishManyAuthorsArgs = {
  to?: Array<Stage>;
  where: InputMaybe<AuthorManyWhereInput>;
};

export type MutationPublishManyAuthorsConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Stage>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  to?: Array<Stage>;
  where: InputMaybe<AuthorManyWhereInput>;
};

export type MutationPublishManyBlockGroupsArgs = {
  to?: Array<Stage>;
  where: InputMaybe<BlockGroupManyWhereInput>;
};

export type MutationPublishManyBlockGroupsConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Stage>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  to?: Array<Stage>;
  where: InputMaybe<BlockGroupManyWhereInput>;
};

export type MutationPublishManyBlocksArgs = {
  to?: Array<Stage>;
  where: InputMaybe<BlockManyWhereInput>;
};

export type MutationPublishManyBlocksConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Stage>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  to?: Array<Stage>;
  where: InputMaybe<BlockManyWhereInput>;
};

export type MutationPublishManyDetailBlocksArgs = {
  to?: Array<Stage>;
  where: InputMaybe<DetailBlockManyWhereInput>;
};

export type MutationPublishManyDetailBlocksConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Stage>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  to?: Array<Stage>;
  where: InputMaybe<DetailBlockManyWhereInput>;
};

export type MutationPublishManyGeneralReservationsArgs = {
  to?: Array<Stage>;
  where: InputMaybe<GeneralReservationManyWhereInput>;
};

export type MutationPublishManyGeneralReservationsConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Stage>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  to?: Array<Stage>;
  where: InputMaybe<GeneralReservationManyWhereInput>;
};

export type MutationPublishManyImagesArgs = {
  locales: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where: InputMaybe<ImageManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishManyImagesConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Stage>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  to?: Array<Stage>;
  where: InputMaybe<ImageManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishManyLandingPagesArgs = {
  locales: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where: InputMaybe<LandingPageManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishManyLandingPagesConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Stage>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  to?: Array<Stage>;
  where: InputMaybe<LandingPageManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishManyLinksArgs = {
  to?: Array<Stage>;
  where: InputMaybe<LinkManyWhereInput>;
};

export type MutationPublishManyLinksConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Stage>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  to?: Array<Stage>;
  where: InputMaybe<LinkManyWhereInput>;
};

export type MutationPublishManyMenusArgs = {
  to?: Array<Stage>;
  where: InputMaybe<MenuManyWhereInput>;
};

export type MutationPublishManyMenusConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Stage>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  to?: Array<Stage>;
  where: InputMaybe<MenuManyWhereInput>;
};

export type MutationPublishManyNoticesArgs = {
  locales: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where: InputMaybe<NoticeManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishManyNoticesConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Stage>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  to?: Array<Stage>;
  where: InputMaybe<NoticeManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishManyPageTemplatesArgs = {
  to?: Array<Stage>;
  where: InputMaybe<PageTemplateManyWhereInput>;
};

export type MutationPublishManyPageTemplatesConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Stage>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  to?: Array<Stage>;
  where: InputMaybe<PageTemplateManyWhereInput>;
};

export type MutationPublishManyPagesArgs = {
  locales: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where: InputMaybe<PageManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishManyPagesConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Stage>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  to?: Array<Stage>;
  where: InputMaybe<PageManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishManyPartnerOffersArgs = {
  locales: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where: InputMaybe<PartnerOfferManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishManyPartnerOffersConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Stage>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  to?: Array<Stage>;
  where: InputMaybe<PartnerOfferManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishManyPartnersArgs = {
  to?: Array<Stage>;
  where: InputMaybe<PartnerManyWhereInput>;
};

export type MutationPublishManyPartnersConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Stage>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  to?: Array<Stage>;
  where: InputMaybe<PartnerManyWhereInput>;
};

export type MutationPublishManyProductActivitiesArgs = {
  locales: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where: InputMaybe<ProductActivityManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishManyProductActivitiesConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Stage>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  to?: Array<Stage>;
  where: InputMaybe<ProductActivityManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishManyProductActivityItemsArgs = {
  locales: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where: InputMaybe<ProductActivityItemManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishManyProductActivityItemsConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Stage>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  to?: Array<Stage>;
  where: InputMaybe<ProductActivityItemManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishManyProductAttractionLineupsArgs = {
  to?: Array<Stage>;
  where: InputMaybe<ProductAttractionLineupManyWhereInput>;
};

export type MutationPublishManyProductAttractionLineupsConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Stage>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  to?: Array<Stage>;
  where: InputMaybe<ProductAttractionLineupManyWhereInput>;
};

export type MutationPublishManyProductReservationsArgs = {
  to?: Array<Stage>;
  where: InputMaybe<ProductReservationManyWhereInput>;
};

export type MutationPublishManyProductReservationsConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Stage>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  to?: Array<Stage>;
  where: InputMaybe<ProductReservationManyWhereInput>;
};

export type MutationPublishManyProductsArgs = {
  locales: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where: InputMaybe<ProductManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishManyProductsConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Stage>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  to?: Array<Stage>;
  where: InputMaybe<ProductManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishManyPromosArgs = {
  locales: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where: InputMaybe<PromoManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishManyPromosConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Stage>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  to?: Array<Stage>;
  where: InputMaybe<PromoManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishManyReservationTextsArgs = {
  locales: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where: InputMaybe<ReservationTextManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishManyReservationTextsConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Stage>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  to?: Array<Stage>;
  where: InputMaybe<ReservationTextManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishManySandboxesArgs = {
  locales: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where: InputMaybe<SandboxManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishManySandboxesConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Stage>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  to?: Array<Stage>;
  where: InputMaybe<SandboxManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishManySeosArgs = {
  locales: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where: InputMaybe<SeoManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishManySeosConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Stage>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  to?: Array<Stage>;
  where: InputMaybe<SeoManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishManyTextGroupsArgs = {
  to?: Array<Stage>;
  where: InputMaybe<TextGroupManyWhereInput>;
};

export type MutationPublishManyTextGroupsConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Stage>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  to?: Array<Stage>;
  where: InputMaybe<TextGroupManyWhereInput>;
};

export type MutationPublishManyTextListsArgs = {
  to?: Array<Stage>;
  where: InputMaybe<TextListManyWhereInput>;
};

export type MutationPublishManyTextListsConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Stage>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  to?: Array<Stage>;
  where: InputMaybe<TextListManyWhereInput>;
};

export type MutationPublishManyTextsArgs = {
  locales: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where: InputMaybe<TextManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishManyTextsConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Stage>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  to?: Array<Stage>;
  where: InputMaybe<TextManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishManyTicketGroupsArgs = {
  to?: Array<Stage>;
  where: InputMaybe<TicketGroupManyWhereInput>;
};

export type MutationPublishManyTicketGroupsConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Stage>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  to?: Array<Stage>;
  where: InputMaybe<TicketGroupManyWhereInput>;
};

export type MutationPublishManyTitleDescriptionsArgs = {
  locales: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where: InputMaybe<TitleDescriptionManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishManyTitleDescriptionsConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Stage>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  to?: Array<Stage>;
  where: InputMaybe<TitleDescriptionManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishManyVideosArgs = {
  locales: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where: InputMaybe<VideoManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishManyVideosConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Stage>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  skip: InputMaybe<Scalars['Int']>;
  to?: Array<Stage>;
  where: InputMaybe<VideoManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishMenuArgs = {
  to?: Array<Stage>;
  where: MenuWhereUniqueInput;
};

export type MutationPublishNoticeArgs = {
  locales: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where: NoticeWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishPageArgs = {
  locales: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where: PageWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishPageTemplateArgs = {
  to?: Array<Stage>;
  where: PageTemplateWhereUniqueInput;
};

export type MutationPublishPartnerArgs = {
  to?: Array<Stage>;
  where: PartnerWhereUniqueInput;
};

export type MutationPublishPartnerOfferArgs = {
  locales: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where: PartnerOfferWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishProductArgs = {
  locales: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where: ProductWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishProductActivityArgs = {
  locales: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where: ProductActivityWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishProductActivityItemArgs = {
  locales: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where: ProductActivityItemWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishProductAttractionLineupArgs = {
  to?: Array<Stage>;
  where: ProductAttractionLineupWhereUniqueInput;
};

export type MutationPublishProductReservationArgs = {
  to?: Array<Stage>;
  where: ProductReservationWhereUniqueInput;
};

export type MutationPublishPromoArgs = {
  locales: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where: PromoWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishReservationTextArgs = {
  locales: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where: ReservationTextWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishSandboxArgs = {
  locales: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where: SandboxWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishSeoArgs = {
  locales: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where: SeoWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishTextArgs = {
  locales: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where: TextWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishTextGroupArgs = {
  to?: Array<Stage>;
  where: TextGroupWhereUniqueInput;
};

export type MutationPublishTextListArgs = {
  to?: Array<Stage>;
  where: TextListWhereUniqueInput;
};

export type MutationPublishTicketGroupArgs = {
  to?: Array<Stage>;
  where: TicketGroupWhereUniqueInput;
};

export type MutationPublishTitleDescriptionArgs = {
  locales: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where: TitleDescriptionWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishVideoArgs = {
  locales: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where: VideoWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationSchedulePublishAddOnArgs = {
  locales: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  releaseAt: InputMaybe<Scalars['DateTime']>;
  releaseId: InputMaybe<Scalars['String']>;
  to?: Array<Stage>;
  where: AddOnWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationSchedulePublishAlacarteTicketArgs = {
  releaseAt: InputMaybe<Scalars['DateTime']>;
  releaseId: InputMaybe<Scalars['String']>;
  to?: Array<Stage>;
  where: AlacarteTicketWhereUniqueInput;
};

export type MutationSchedulePublishAlacarteTicketTypeArgs = {
  releaseAt: InputMaybe<Scalars['DateTime']>;
  releaseId: InputMaybe<Scalars['String']>;
  to?: Array<Stage>;
  where: AlacarteTicketTypeWhereUniqueInput;
};

export type MutationSchedulePublishArticleArgs = {
  locales: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  releaseAt: InputMaybe<Scalars['DateTime']>;
  releaseId: InputMaybe<Scalars['String']>;
  to?: Array<Stage>;
  where: ArticleWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationSchedulePublishArticleCategoryArgs = {
  releaseAt: InputMaybe<Scalars['DateTime']>;
  releaseId: InputMaybe<Scalars['String']>;
  to?: Array<Stage>;
  where: ArticleCategoryWhereUniqueInput;
};

export type MutationSchedulePublishAssetArgs = {
  locales: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  releaseAt: InputMaybe<Scalars['DateTime']>;
  releaseId: InputMaybe<Scalars['String']>;
  to?: Array<Stage>;
  where: AssetWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationSchedulePublishAttractionArgs = {
  locales: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  releaseAt: InputMaybe<Scalars['DateTime']>;
  releaseId: InputMaybe<Scalars['String']>;
  to?: Array<Stage>;
  where: AttractionWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationSchedulePublishAttractionAlacarteArgs = {
  releaseAt: InputMaybe<Scalars['DateTime']>;
  releaseId: InputMaybe<Scalars['String']>;
  to?: Array<Stage>;
  where: AttractionAlacarteWhereUniqueInput;
};

export type MutationSchedulePublishAttractionAvailabilityArgs = {
  locales: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  releaseAt: InputMaybe<Scalars['DateTime']>;
  releaseId: InputMaybe<Scalars['String']>;
  to?: Array<Stage>;
  where: AttractionAvailabilityWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationSchedulePublishAttractionLocationArgs = {
  locales: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  releaseAt: InputMaybe<Scalars['DateTime']>;
  releaseId: InputMaybe<Scalars['String']>;
  to?: Array<Stage>;
  where: AttractionLocationWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationSchedulePublishAttractionOfferArgs = {
  locales: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  releaseAt: InputMaybe<Scalars['DateTime']>;
  releaseId: InputMaybe<Scalars['String']>;
  to?: Array<Stage>;
  where: AttractionOfferWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationSchedulePublishAttractionPriceArgs = {
  locales: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  releaseAt: InputMaybe<Scalars['DateTime']>;
  releaseId: InputMaybe<Scalars['String']>;
  to?: Array<Stage>;
  where: AttractionPriceWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationSchedulePublishAttractionReservationArgs = {
  releaseAt: InputMaybe<Scalars['DateTime']>;
  releaseId: InputMaybe<Scalars['String']>;
  to?: Array<Stage>;
  where: AttractionReservationWhereUniqueInput;
};

export type MutationSchedulePublishAttractionVariantArgs = {
  locales: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  releaseAt: InputMaybe<Scalars['DateTime']>;
  releaseId: InputMaybe<Scalars['String']>;
  to?: Array<Stage>;
  where: AttractionVariantWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationSchedulePublishAuthorArgs = {
  releaseAt: InputMaybe<Scalars['DateTime']>;
  releaseId: InputMaybe<Scalars['String']>;
  to?: Array<Stage>;
  where: AuthorWhereUniqueInput;
};

export type MutationSchedulePublishBlockArgs = {
  releaseAt: InputMaybe<Scalars['DateTime']>;
  releaseId: InputMaybe<Scalars['String']>;
  to?: Array<Stage>;
  where: BlockWhereUniqueInput;
};

export type MutationSchedulePublishBlockGroupArgs = {
  releaseAt: InputMaybe<Scalars['DateTime']>;
  releaseId: InputMaybe<Scalars['String']>;
  to?: Array<Stage>;
  where: BlockGroupWhereUniqueInput;
};

export type MutationSchedulePublishDetailBlockArgs = {
  releaseAt: InputMaybe<Scalars['DateTime']>;
  releaseId: InputMaybe<Scalars['String']>;
  to?: Array<Stage>;
  where: DetailBlockWhereUniqueInput;
};

export type MutationSchedulePublishGeneralReservationArgs = {
  releaseAt: InputMaybe<Scalars['DateTime']>;
  releaseId: InputMaybe<Scalars['String']>;
  to?: Array<Stage>;
  where: GeneralReservationWhereUniqueInput;
};

export type MutationSchedulePublishImageArgs = {
  locales: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  releaseAt: InputMaybe<Scalars['DateTime']>;
  releaseId: InputMaybe<Scalars['String']>;
  to?: Array<Stage>;
  where: ImageWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationSchedulePublishLandingPageArgs = {
  locales: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  releaseAt: InputMaybe<Scalars['DateTime']>;
  releaseId: InputMaybe<Scalars['String']>;
  to?: Array<Stage>;
  where: LandingPageWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationSchedulePublishLinkArgs = {
  releaseAt: InputMaybe<Scalars['DateTime']>;
  releaseId: InputMaybe<Scalars['String']>;
  to?: Array<Stage>;
  where: LinkWhereUniqueInput;
};

export type MutationSchedulePublishMenuArgs = {
  releaseAt: InputMaybe<Scalars['DateTime']>;
  releaseId: InputMaybe<Scalars['String']>;
  to?: Array<Stage>;
  where: MenuWhereUniqueInput;
};

export type MutationSchedulePublishNoticeArgs = {
  locales: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  releaseAt: InputMaybe<Scalars['DateTime']>;
  releaseId: InputMaybe<Scalars['String']>;
  to?: Array<Stage>;
  where: NoticeWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationSchedulePublishPageArgs = {
  locales: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  releaseAt: InputMaybe<Scalars['DateTime']>;
  releaseId: InputMaybe<Scalars['String']>;
  to?: Array<Stage>;
  where: PageWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationSchedulePublishPageTemplateArgs = {
  releaseAt: InputMaybe<Scalars['DateTime']>;
  releaseId: InputMaybe<Scalars['String']>;
  to?: Array<Stage>;
  where: PageTemplateWhereUniqueInput;
};

export type MutationSchedulePublishPartnerArgs = {
  releaseAt: InputMaybe<Scalars['DateTime']>;
  releaseId: InputMaybe<Scalars['String']>;
  to?: Array<Stage>;
  where: PartnerWhereUniqueInput;
};

export type MutationSchedulePublishPartnerOfferArgs = {
  locales: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  releaseAt: InputMaybe<Scalars['DateTime']>;
  releaseId: InputMaybe<Scalars['String']>;
  to?: Array<Stage>;
  where: PartnerOfferWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationSchedulePublishProductArgs = {
  locales: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  releaseAt: InputMaybe<Scalars['DateTime']>;
  releaseId: InputMaybe<Scalars['String']>;
  to?: Array<Stage>;
  where: ProductWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationSchedulePublishProductActivityArgs = {
  locales: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  releaseAt: InputMaybe<Scalars['DateTime']>;
  releaseId: InputMaybe<Scalars['String']>;
  to?: Array<Stage>;
  where: ProductActivityWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationSchedulePublishProductActivityItemArgs = {
  locales: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  releaseAt: InputMaybe<Scalars['DateTime']>;
  releaseId: InputMaybe<Scalars['String']>;
  to?: Array<Stage>;
  where: ProductActivityItemWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationSchedulePublishProductAttractionLineupArgs = {
  releaseAt: InputMaybe<Scalars['DateTime']>;
  releaseId: InputMaybe<Scalars['String']>;
  to?: Array<Stage>;
  where: ProductAttractionLineupWhereUniqueInput;
};

export type MutationSchedulePublishProductReservationArgs = {
  releaseAt: InputMaybe<Scalars['DateTime']>;
  releaseId: InputMaybe<Scalars['String']>;
  to?: Array<Stage>;
  where: ProductReservationWhereUniqueInput;
};

export type MutationSchedulePublishPromoArgs = {
  locales: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  releaseAt: InputMaybe<Scalars['DateTime']>;
  releaseId: InputMaybe<Scalars['String']>;
  to?: Array<Stage>;
  where: PromoWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationSchedulePublishReservationTextArgs = {
  locales: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  releaseAt: InputMaybe<Scalars['DateTime']>;
  releaseId: InputMaybe<Scalars['String']>;
  to?: Array<Stage>;
  where: ReservationTextWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationSchedulePublishSandboxArgs = {
  locales: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  releaseAt: InputMaybe<Scalars['DateTime']>;
  releaseId: InputMaybe<Scalars['String']>;
  to?: Array<Stage>;
  where: SandboxWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationSchedulePublishSeoArgs = {
  locales: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  releaseAt: InputMaybe<Scalars['DateTime']>;
  releaseId: InputMaybe<Scalars['String']>;
  to?: Array<Stage>;
  where: SeoWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationSchedulePublishTextArgs = {
  locales: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  releaseAt: InputMaybe<Scalars['DateTime']>;
  releaseId: InputMaybe<Scalars['String']>;
  to?: Array<Stage>;
  where: TextWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationSchedulePublishTextGroupArgs = {
  releaseAt: InputMaybe<Scalars['DateTime']>;
  releaseId: InputMaybe<Scalars['String']>;
  to?: Array<Stage>;
  where: TextGroupWhereUniqueInput;
};

export type MutationSchedulePublishTextListArgs = {
  releaseAt: InputMaybe<Scalars['DateTime']>;
  releaseId: InputMaybe<Scalars['String']>;
  to?: Array<Stage>;
  where: TextListWhereUniqueInput;
};

export type MutationSchedulePublishTicketGroupArgs = {
  releaseAt: InputMaybe<Scalars['DateTime']>;
  releaseId: InputMaybe<Scalars['String']>;
  to?: Array<Stage>;
  where: TicketGroupWhereUniqueInput;
};

export type MutationSchedulePublishTitleDescriptionArgs = {
  locales: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  releaseAt: InputMaybe<Scalars['DateTime']>;
  releaseId: InputMaybe<Scalars['String']>;
  to?: Array<Stage>;
  where: TitleDescriptionWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationSchedulePublishVideoArgs = {
  locales: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  releaseAt: InputMaybe<Scalars['DateTime']>;
  releaseId: InputMaybe<Scalars['String']>;
  to?: Array<Stage>;
  where: VideoWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationScheduleUnpublishAddOnArgs = {
  from?: Array<Stage>;
  locales: InputMaybe<Array<Locale>>;
  releaseAt: InputMaybe<Scalars['DateTime']>;
  releaseId: InputMaybe<Scalars['String']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: AddOnWhereUniqueInput;
};

export type MutationScheduleUnpublishAlacarteTicketArgs = {
  from?: Array<Stage>;
  releaseAt: InputMaybe<Scalars['DateTime']>;
  releaseId: InputMaybe<Scalars['String']>;
  where: AlacarteTicketWhereUniqueInput;
};

export type MutationScheduleUnpublishAlacarteTicketTypeArgs = {
  from?: Array<Stage>;
  releaseAt: InputMaybe<Scalars['DateTime']>;
  releaseId: InputMaybe<Scalars['String']>;
  where: AlacarteTicketTypeWhereUniqueInput;
};

export type MutationScheduleUnpublishArticleArgs = {
  from?: Array<Stage>;
  locales: InputMaybe<Array<Locale>>;
  releaseAt: InputMaybe<Scalars['DateTime']>;
  releaseId: InputMaybe<Scalars['String']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: ArticleWhereUniqueInput;
};

export type MutationScheduleUnpublishArticleCategoryArgs = {
  from?: Array<Stage>;
  releaseAt: InputMaybe<Scalars['DateTime']>;
  releaseId: InputMaybe<Scalars['String']>;
  where: ArticleCategoryWhereUniqueInput;
};

export type MutationScheduleUnpublishAssetArgs = {
  from?: Array<Stage>;
  locales: InputMaybe<Array<Locale>>;
  releaseAt: InputMaybe<Scalars['DateTime']>;
  releaseId: InputMaybe<Scalars['String']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: AssetWhereUniqueInput;
};

export type MutationScheduleUnpublishAttractionArgs = {
  from?: Array<Stage>;
  locales: InputMaybe<Array<Locale>>;
  releaseAt: InputMaybe<Scalars['DateTime']>;
  releaseId: InputMaybe<Scalars['String']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: AttractionWhereUniqueInput;
};

export type MutationScheduleUnpublishAttractionAlacarteArgs = {
  from?: Array<Stage>;
  releaseAt: InputMaybe<Scalars['DateTime']>;
  releaseId: InputMaybe<Scalars['String']>;
  where: AttractionAlacarteWhereUniqueInput;
};

export type MutationScheduleUnpublishAttractionAvailabilityArgs = {
  from?: Array<Stage>;
  locales: InputMaybe<Array<Locale>>;
  releaseAt: InputMaybe<Scalars['DateTime']>;
  releaseId: InputMaybe<Scalars['String']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: AttractionAvailabilityWhereUniqueInput;
};

export type MutationScheduleUnpublishAttractionLocationArgs = {
  from?: Array<Stage>;
  locales: InputMaybe<Array<Locale>>;
  releaseAt: InputMaybe<Scalars['DateTime']>;
  releaseId: InputMaybe<Scalars['String']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: AttractionLocationWhereUniqueInput;
};

export type MutationScheduleUnpublishAttractionOfferArgs = {
  from?: Array<Stage>;
  locales: InputMaybe<Array<Locale>>;
  releaseAt: InputMaybe<Scalars['DateTime']>;
  releaseId: InputMaybe<Scalars['String']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: AttractionOfferWhereUniqueInput;
};

export type MutationScheduleUnpublishAttractionPriceArgs = {
  from?: Array<Stage>;
  locales: InputMaybe<Array<Locale>>;
  releaseAt: InputMaybe<Scalars['DateTime']>;
  releaseId: InputMaybe<Scalars['String']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: AttractionPriceWhereUniqueInput;
};

export type MutationScheduleUnpublishAttractionReservationArgs = {
  from?: Array<Stage>;
  releaseAt: InputMaybe<Scalars['DateTime']>;
  releaseId: InputMaybe<Scalars['String']>;
  where: AttractionReservationWhereUniqueInput;
};

export type MutationScheduleUnpublishAttractionVariantArgs = {
  from?: Array<Stage>;
  locales: InputMaybe<Array<Locale>>;
  releaseAt: InputMaybe<Scalars['DateTime']>;
  releaseId: InputMaybe<Scalars['String']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: AttractionVariantWhereUniqueInput;
};

export type MutationScheduleUnpublishAuthorArgs = {
  from?: Array<Stage>;
  releaseAt: InputMaybe<Scalars['DateTime']>;
  releaseId: InputMaybe<Scalars['String']>;
  where: AuthorWhereUniqueInput;
};

export type MutationScheduleUnpublishBlockArgs = {
  from?: Array<Stage>;
  releaseAt: InputMaybe<Scalars['DateTime']>;
  releaseId: InputMaybe<Scalars['String']>;
  where: BlockWhereUniqueInput;
};

export type MutationScheduleUnpublishBlockGroupArgs = {
  from?: Array<Stage>;
  releaseAt: InputMaybe<Scalars['DateTime']>;
  releaseId: InputMaybe<Scalars['String']>;
  where: BlockGroupWhereUniqueInput;
};

export type MutationScheduleUnpublishDetailBlockArgs = {
  from?: Array<Stage>;
  releaseAt: InputMaybe<Scalars['DateTime']>;
  releaseId: InputMaybe<Scalars['String']>;
  where: DetailBlockWhereUniqueInput;
};

export type MutationScheduleUnpublishGeneralReservationArgs = {
  from?: Array<Stage>;
  releaseAt: InputMaybe<Scalars['DateTime']>;
  releaseId: InputMaybe<Scalars['String']>;
  where: GeneralReservationWhereUniqueInput;
};

export type MutationScheduleUnpublishImageArgs = {
  from?: Array<Stage>;
  locales: InputMaybe<Array<Locale>>;
  releaseAt: InputMaybe<Scalars['DateTime']>;
  releaseId: InputMaybe<Scalars['String']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: ImageWhereUniqueInput;
};

export type MutationScheduleUnpublishLandingPageArgs = {
  from?: Array<Stage>;
  locales: InputMaybe<Array<Locale>>;
  releaseAt: InputMaybe<Scalars['DateTime']>;
  releaseId: InputMaybe<Scalars['String']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: LandingPageWhereUniqueInput;
};

export type MutationScheduleUnpublishLinkArgs = {
  from?: Array<Stage>;
  releaseAt: InputMaybe<Scalars['DateTime']>;
  releaseId: InputMaybe<Scalars['String']>;
  where: LinkWhereUniqueInput;
};

export type MutationScheduleUnpublishMenuArgs = {
  from?: Array<Stage>;
  releaseAt: InputMaybe<Scalars['DateTime']>;
  releaseId: InputMaybe<Scalars['String']>;
  where: MenuWhereUniqueInput;
};

export type MutationScheduleUnpublishNoticeArgs = {
  from?: Array<Stage>;
  locales: InputMaybe<Array<Locale>>;
  releaseAt: InputMaybe<Scalars['DateTime']>;
  releaseId: InputMaybe<Scalars['String']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: NoticeWhereUniqueInput;
};

export type MutationScheduleUnpublishPageArgs = {
  from?: Array<Stage>;
  locales: InputMaybe<Array<Locale>>;
  releaseAt: InputMaybe<Scalars['DateTime']>;
  releaseId: InputMaybe<Scalars['String']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: PageWhereUniqueInput;
};

export type MutationScheduleUnpublishPageTemplateArgs = {
  from?: Array<Stage>;
  releaseAt: InputMaybe<Scalars['DateTime']>;
  releaseId: InputMaybe<Scalars['String']>;
  where: PageTemplateWhereUniqueInput;
};

export type MutationScheduleUnpublishPartnerArgs = {
  from?: Array<Stage>;
  releaseAt: InputMaybe<Scalars['DateTime']>;
  releaseId: InputMaybe<Scalars['String']>;
  where: PartnerWhereUniqueInput;
};

export type MutationScheduleUnpublishPartnerOfferArgs = {
  from?: Array<Stage>;
  locales: InputMaybe<Array<Locale>>;
  releaseAt: InputMaybe<Scalars['DateTime']>;
  releaseId: InputMaybe<Scalars['String']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: PartnerOfferWhereUniqueInput;
};

export type MutationScheduleUnpublishProductArgs = {
  from?: Array<Stage>;
  locales: InputMaybe<Array<Locale>>;
  releaseAt: InputMaybe<Scalars['DateTime']>;
  releaseId: InputMaybe<Scalars['String']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: ProductWhereUniqueInput;
};

export type MutationScheduleUnpublishProductActivityArgs = {
  from?: Array<Stage>;
  locales: InputMaybe<Array<Locale>>;
  releaseAt: InputMaybe<Scalars['DateTime']>;
  releaseId: InputMaybe<Scalars['String']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: ProductActivityWhereUniqueInput;
};

export type MutationScheduleUnpublishProductActivityItemArgs = {
  from?: Array<Stage>;
  locales: InputMaybe<Array<Locale>>;
  releaseAt: InputMaybe<Scalars['DateTime']>;
  releaseId: InputMaybe<Scalars['String']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: ProductActivityItemWhereUniqueInput;
};

export type MutationScheduleUnpublishProductAttractionLineupArgs = {
  from?: Array<Stage>;
  releaseAt: InputMaybe<Scalars['DateTime']>;
  releaseId: InputMaybe<Scalars['String']>;
  where: ProductAttractionLineupWhereUniqueInput;
};

export type MutationScheduleUnpublishProductReservationArgs = {
  from?: Array<Stage>;
  releaseAt: InputMaybe<Scalars['DateTime']>;
  releaseId: InputMaybe<Scalars['String']>;
  where: ProductReservationWhereUniqueInput;
};

export type MutationScheduleUnpublishPromoArgs = {
  from?: Array<Stage>;
  locales: InputMaybe<Array<Locale>>;
  releaseAt: InputMaybe<Scalars['DateTime']>;
  releaseId: InputMaybe<Scalars['String']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: PromoWhereUniqueInput;
};

export type MutationScheduleUnpublishReservationTextArgs = {
  from?: Array<Stage>;
  locales: InputMaybe<Array<Locale>>;
  releaseAt: InputMaybe<Scalars['DateTime']>;
  releaseId: InputMaybe<Scalars['String']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: ReservationTextWhereUniqueInput;
};

export type MutationScheduleUnpublishSandboxArgs = {
  from?: Array<Stage>;
  locales: InputMaybe<Array<Locale>>;
  releaseAt: InputMaybe<Scalars['DateTime']>;
  releaseId: InputMaybe<Scalars['String']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: SandboxWhereUniqueInput;
};

export type MutationScheduleUnpublishSeoArgs = {
  from?: Array<Stage>;
  locales: InputMaybe<Array<Locale>>;
  releaseAt: InputMaybe<Scalars['DateTime']>;
  releaseId: InputMaybe<Scalars['String']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: SeoWhereUniqueInput;
};

export type MutationScheduleUnpublishTextArgs = {
  from?: Array<Stage>;
  locales: InputMaybe<Array<Locale>>;
  releaseAt: InputMaybe<Scalars['DateTime']>;
  releaseId: InputMaybe<Scalars['String']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: TextWhereUniqueInput;
};

export type MutationScheduleUnpublishTextGroupArgs = {
  from?: Array<Stage>;
  releaseAt: InputMaybe<Scalars['DateTime']>;
  releaseId: InputMaybe<Scalars['String']>;
  where: TextGroupWhereUniqueInput;
};

export type MutationScheduleUnpublishTextListArgs = {
  from?: Array<Stage>;
  releaseAt: InputMaybe<Scalars['DateTime']>;
  releaseId: InputMaybe<Scalars['String']>;
  where: TextListWhereUniqueInput;
};

export type MutationScheduleUnpublishTicketGroupArgs = {
  from?: Array<Stage>;
  releaseAt: InputMaybe<Scalars['DateTime']>;
  releaseId: InputMaybe<Scalars['String']>;
  where: TicketGroupWhereUniqueInput;
};

export type MutationScheduleUnpublishTitleDescriptionArgs = {
  from?: Array<Stage>;
  locales: InputMaybe<Array<Locale>>;
  releaseAt: InputMaybe<Scalars['DateTime']>;
  releaseId: InputMaybe<Scalars['String']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: TitleDescriptionWhereUniqueInput;
};

export type MutationScheduleUnpublishVideoArgs = {
  from?: Array<Stage>;
  locales: InputMaybe<Array<Locale>>;
  releaseAt: InputMaybe<Scalars['DateTime']>;
  releaseId: InputMaybe<Scalars['String']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: VideoWhereUniqueInput;
};

export type MutationUnpublishAddOnArgs = {
  from?: Array<Stage>;
  locales: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: AddOnWhereUniqueInput;
};

export type MutationUnpublishAlacarteTicketArgs = {
  from?: Array<Stage>;
  where: AlacarteTicketWhereUniqueInput;
};

export type MutationUnpublishAlacarteTicketTypeArgs = {
  from?: Array<Stage>;
  where: AlacarteTicketTypeWhereUniqueInput;
};

export type MutationUnpublishArticleArgs = {
  from?: Array<Stage>;
  locales: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: ArticleWhereUniqueInput;
};

export type MutationUnpublishArticleCategoryArgs = {
  from?: Array<Stage>;
  where: ArticleCategoryWhereUniqueInput;
};

export type MutationUnpublishAssetArgs = {
  from?: Array<Stage>;
  locales: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: AssetWhereUniqueInput;
};

export type MutationUnpublishAttractionArgs = {
  from?: Array<Stage>;
  locales: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: AttractionWhereUniqueInput;
};

export type MutationUnpublishAttractionAlacarteArgs = {
  from?: Array<Stage>;
  where: AttractionAlacarteWhereUniqueInput;
};

export type MutationUnpublishAttractionAvailabilityArgs = {
  from?: Array<Stage>;
  locales: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: AttractionAvailabilityWhereUniqueInput;
};

export type MutationUnpublishAttractionLocationArgs = {
  from?: Array<Stage>;
  locales: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: AttractionLocationWhereUniqueInput;
};

export type MutationUnpublishAttractionOfferArgs = {
  from?: Array<Stage>;
  locales: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: AttractionOfferWhereUniqueInput;
};

export type MutationUnpublishAttractionPriceArgs = {
  from?: Array<Stage>;
  locales: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: AttractionPriceWhereUniqueInput;
};

export type MutationUnpublishAttractionReservationArgs = {
  from?: Array<Stage>;
  where: AttractionReservationWhereUniqueInput;
};

export type MutationUnpublishAttractionVariantArgs = {
  from?: Array<Stage>;
  locales: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: AttractionVariantWhereUniqueInput;
};

export type MutationUnpublishAuthorArgs = {
  from?: Array<Stage>;
  where: AuthorWhereUniqueInput;
};

export type MutationUnpublishBlockArgs = {
  from?: Array<Stage>;
  where: BlockWhereUniqueInput;
};

export type MutationUnpublishBlockGroupArgs = {
  from?: Array<Stage>;
  where: BlockGroupWhereUniqueInput;
};

export type MutationUnpublishDetailBlockArgs = {
  from?: Array<Stage>;
  where: DetailBlockWhereUniqueInput;
};

export type MutationUnpublishGeneralReservationArgs = {
  from?: Array<Stage>;
  where: GeneralReservationWhereUniqueInput;
};

export type MutationUnpublishImageArgs = {
  from?: Array<Stage>;
  locales: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: ImageWhereUniqueInput;
};

export type MutationUnpublishLandingPageArgs = {
  from?: Array<Stage>;
  locales: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: LandingPageWhereUniqueInput;
};

export type MutationUnpublishLinkArgs = {
  from?: Array<Stage>;
  where: LinkWhereUniqueInput;
};

export type MutationUnpublishManyAddOnsArgs = {
  from?: Array<Stage>;
  locales: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<AddOnManyWhereInput>;
};

export type MutationUnpublishManyAddOnsConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  from?: Array<Stage>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<AddOnManyWhereInput>;
};

export type MutationUnpublishManyAlacarteTicketTypesArgs = {
  from?: Array<Stage>;
  where: InputMaybe<AlacarteTicketTypeManyWhereInput>;
};

export type MutationUnpublishManyAlacarteTicketTypesConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  from?: Array<Stage>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: InputMaybe<Stage>;
  where: InputMaybe<AlacarteTicketTypeManyWhereInput>;
};

export type MutationUnpublishManyAlacarteTicketsArgs = {
  from?: Array<Stage>;
  where: InputMaybe<AlacarteTicketManyWhereInput>;
};

export type MutationUnpublishManyAlacarteTicketsConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  from?: Array<Stage>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: InputMaybe<Stage>;
  where: InputMaybe<AlacarteTicketManyWhereInput>;
};

export type MutationUnpublishManyArticleCategoriesArgs = {
  from?: Array<Stage>;
  where: InputMaybe<ArticleCategoryManyWhereInput>;
};

export type MutationUnpublishManyArticleCategoriesConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  from?: Array<Stage>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: InputMaybe<Stage>;
  where: InputMaybe<ArticleCategoryManyWhereInput>;
};

export type MutationUnpublishManyArticlesArgs = {
  from?: Array<Stage>;
  locales: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<ArticleManyWhereInput>;
};

export type MutationUnpublishManyArticlesConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  from?: Array<Stage>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<ArticleManyWhereInput>;
};

export type MutationUnpublishManyAssetsArgs = {
  from?: Array<Stage>;
  locales: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<AssetManyWhereInput>;
};

export type MutationUnpublishManyAssetsConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  from?: Array<Stage>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<AssetManyWhereInput>;
};

export type MutationUnpublishManyAttractionAlacartesArgs = {
  from?: Array<Stage>;
  where: InputMaybe<AttractionAlacarteManyWhereInput>;
};

export type MutationUnpublishManyAttractionAlacartesConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  from?: Array<Stage>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: InputMaybe<Stage>;
  where: InputMaybe<AttractionAlacarteManyWhereInput>;
};

export type MutationUnpublishManyAttractionAvailabilitiesArgs = {
  from?: Array<Stage>;
  locales: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<AttractionAvailabilityManyWhereInput>;
};

export type MutationUnpublishManyAttractionAvailabilitiesConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  from?: Array<Stage>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<AttractionAvailabilityManyWhereInput>;
};

export type MutationUnpublishManyAttractionLocationsArgs = {
  from?: Array<Stage>;
  locales: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<AttractionLocationManyWhereInput>;
};

export type MutationUnpublishManyAttractionLocationsConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  from?: Array<Stage>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<AttractionLocationManyWhereInput>;
};

export type MutationUnpublishManyAttractionOffersArgs = {
  from?: Array<Stage>;
  locales: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<AttractionOfferManyWhereInput>;
};

export type MutationUnpublishManyAttractionOffersConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  from?: Array<Stage>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<AttractionOfferManyWhereInput>;
};

export type MutationUnpublishManyAttractionPricesArgs = {
  from?: Array<Stage>;
  locales: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<AttractionPriceManyWhereInput>;
};

export type MutationUnpublishManyAttractionPricesConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  from?: Array<Stage>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<AttractionPriceManyWhereInput>;
};

export type MutationUnpublishManyAttractionReservationsArgs = {
  from?: Array<Stage>;
  where: InputMaybe<AttractionReservationManyWhereInput>;
};

export type MutationUnpublishManyAttractionReservationsConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  from?: Array<Stage>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: InputMaybe<Stage>;
  where: InputMaybe<AttractionReservationManyWhereInput>;
};

export type MutationUnpublishManyAttractionVariantsArgs = {
  from?: Array<Stage>;
  locales: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<AttractionVariantManyWhereInput>;
};

export type MutationUnpublishManyAttractionVariantsConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  from?: Array<Stage>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<AttractionVariantManyWhereInput>;
};

export type MutationUnpublishManyAttractionsArgs = {
  from?: Array<Stage>;
  locales: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<AttractionManyWhereInput>;
};

export type MutationUnpublishManyAttractionsConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  from?: Array<Stage>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<AttractionManyWhereInput>;
};

export type MutationUnpublishManyAuthorsArgs = {
  from?: Array<Stage>;
  where: InputMaybe<AuthorManyWhereInput>;
};

export type MutationUnpublishManyAuthorsConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  from?: Array<Stage>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: InputMaybe<Stage>;
  where: InputMaybe<AuthorManyWhereInput>;
};

export type MutationUnpublishManyBlockGroupsArgs = {
  from?: Array<Stage>;
  where: InputMaybe<BlockGroupManyWhereInput>;
};

export type MutationUnpublishManyBlockGroupsConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  from?: Array<Stage>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: InputMaybe<Stage>;
  where: InputMaybe<BlockGroupManyWhereInput>;
};

export type MutationUnpublishManyBlocksArgs = {
  from?: Array<Stage>;
  where: InputMaybe<BlockManyWhereInput>;
};

export type MutationUnpublishManyBlocksConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  from?: Array<Stage>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: InputMaybe<Stage>;
  where: InputMaybe<BlockManyWhereInput>;
};

export type MutationUnpublishManyDetailBlocksArgs = {
  from?: Array<Stage>;
  where: InputMaybe<DetailBlockManyWhereInput>;
};

export type MutationUnpublishManyDetailBlocksConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  from?: Array<Stage>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: InputMaybe<Stage>;
  where: InputMaybe<DetailBlockManyWhereInput>;
};

export type MutationUnpublishManyGeneralReservationsArgs = {
  from?: Array<Stage>;
  where: InputMaybe<GeneralReservationManyWhereInput>;
};

export type MutationUnpublishManyGeneralReservationsConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  from?: Array<Stage>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: InputMaybe<Stage>;
  where: InputMaybe<GeneralReservationManyWhereInput>;
};

export type MutationUnpublishManyImagesArgs = {
  from?: Array<Stage>;
  locales: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<ImageManyWhereInput>;
};

export type MutationUnpublishManyImagesConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  from?: Array<Stage>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<ImageManyWhereInput>;
};

export type MutationUnpublishManyLandingPagesArgs = {
  from?: Array<Stage>;
  locales: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<LandingPageManyWhereInput>;
};

export type MutationUnpublishManyLandingPagesConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  from?: Array<Stage>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<LandingPageManyWhereInput>;
};

export type MutationUnpublishManyLinksArgs = {
  from?: Array<Stage>;
  where: InputMaybe<LinkManyWhereInput>;
};

export type MutationUnpublishManyLinksConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  from?: Array<Stage>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: InputMaybe<Stage>;
  where: InputMaybe<LinkManyWhereInput>;
};

export type MutationUnpublishManyMenusArgs = {
  from?: Array<Stage>;
  where: InputMaybe<MenuManyWhereInput>;
};

export type MutationUnpublishManyMenusConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  from?: Array<Stage>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: InputMaybe<Stage>;
  where: InputMaybe<MenuManyWhereInput>;
};

export type MutationUnpublishManyNoticesArgs = {
  from?: Array<Stage>;
  locales: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<NoticeManyWhereInput>;
};

export type MutationUnpublishManyNoticesConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  from?: Array<Stage>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<NoticeManyWhereInput>;
};

export type MutationUnpublishManyPageTemplatesArgs = {
  from?: Array<Stage>;
  where: InputMaybe<PageTemplateManyWhereInput>;
};

export type MutationUnpublishManyPageTemplatesConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  from?: Array<Stage>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: InputMaybe<Stage>;
  where: InputMaybe<PageTemplateManyWhereInput>;
};

export type MutationUnpublishManyPagesArgs = {
  from?: Array<Stage>;
  locales: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<PageManyWhereInput>;
};

export type MutationUnpublishManyPagesConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  from?: Array<Stage>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<PageManyWhereInput>;
};

export type MutationUnpublishManyPartnerOffersArgs = {
  from?: Array<Stage>;
  locales: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<PartnerOfferManyWhereInput>;
};

export type MutationUnpublishManyPartnerOffersConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  from?: Array<Stage>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<PartnerOfferManyWhereInput>;
};

export type MutationUnpublishManyPartnersArgs = {
  from?: Array<Stage>;
  where: InputMaybe<PartnerManyWhereInput>;
};

export type MutationUnpublishManyPartnersConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  from?: Array<Stage>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: InputMaybe<Stage>;
  where: InputMaybe<PartnerManyWhereInput>;
};

export type MutationUnpublishManyProductActivitiesArgs = {
  from?: Array<Stage>;
  locales: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<ProductActivityManyWhereInput>;
};

export type MutationUnpublishManyProductActivitiesConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  from?: Array<Stage>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<ProductActivityManyWhereInput>;
};

export type MutationUnpublishManyProductActivityItemsArgs = {
  from?: Array<Stage>;
  locales: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<ProductActivityItemManyWhereInput>;
};

export type MutationUnpublishManyProductActivityItemsConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  from?: Array<Stage>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<ProductActivityItemManyWhereInput>;
};

export type MutationUnpublishManyProductAttractionLineupsArgs = {
  from?: Array<Stage>;
  where: InputMaybe<ProductAttractionLineupManyWhereInput>;
};

export type MutationUnpublishManyProductAttractionLineupsConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  from?: Array<Stage>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: InputMaybe<Stage>;
  where: InputMaybe<ProductAttractionLineupManyWhereInput>;
};

export type MutationUnpublishManyProductReservationsArgs = {
  from?: Array<Stage>;
  where: InputMaybe<ProductReservationManyWhereInput>;
};

export type MutationUnpublishManyProductReservationsConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  from?: Array<Stage>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: InputMaybe<Stage>;
  where: InputMaybe<ProductReservationManyWhereInput>;
};

export type MutationUnpublishManyProductsArgs = {
  from?: Array<Stage>;
  locales: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<ProductManyWhereInput>;
};

export type MutationUnpublishManyProductsConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  from?: Array<Stage>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<ProductManyWhereInput>;
};

export type MutationUnpublishManyPromosArgs = {
  from?: Array<Stage>;
  locales: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<PromoManyWhereInput>;
};

export type MutationUnpublishManyPromosConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  from?: Array<Stage>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<PromoManyWhereInput>;
};

export type MutationUnpublishManyReservationTextsArgs = {
  from?: Array<Stage>;
  locales: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<ReservationTextManyWhereInput>;
};

export type MutationUnpublishManyReservationTextsConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  from?: Array<Stage>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<ReservationTextManyWhereInput>;
};

export type MutationUnpublishManySandboxesArgs = {
  from?: Array<Stage>;
  locales: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<SandboxManyWhereInput>;
};

export type MutationUnpublishManySandboxesConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  from?: Array<Stage>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<SandboxManyWhereInput>;
};

export type MutationUnpublishManySeosArgs = {
  from?: Array<Stage>;
  locales: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<SeoManyWhereInput>;
};

export type MutationUnpublishManySeosConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  from?: Array<Stage>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<SeoManyWhereInput>;
};

export type MutationUnpublishManyTextGroupsArgs = {
  from?: Array<Stage>;
  where: InputMaybe<TextGroupManyWhereInput>;
};

export type MutationUnpublishManyTextGroupsConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  from?: Array<Stage>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: InputMaybe<Stage>;
  where: InputMaybe<TextGroupManyWhereInput>;
};

export type MutationUnpublishManyTextListsArgs = {
  from?: Array<Stage>;
  where: InputMaybe<TextListManyWhereInput>;
};

export type MutationUnpublishManyTextListsConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  from?: Array<Stage>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: InputMaybe<Stage>;
  where: InputMaybe<TextListManyWhereInput>;
};

export type MutationUnpublishManyTextsArgs = {
  from?: Array<Stage>;
  locales: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<TextManyWhereInput>;
};

export type MutationUnpublishManyTextsConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  from?: Array<Stage>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<TextManyWhereInput>;
};

export type MutationUnpublishManyTicketGroupsArgs = {
  from?: Array<Stage>;
  where: InputMaybe<TicketGroupManyWhereInput>;
};

export type MutationUnpublishManyTicketGroupsConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  from?: Array<Stage>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: InputMaybe<Stage>;
  where: InputMaybe<TicketGroupManyWhereInput>;
};

export type MutationUnpublishManyTitleDescriptionsArgs = {
  from?: Array<Stage>;
  locales: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<TitleDescriptionManyWhereInput>;
};

export type MutationUnpublishManyTitleDescriptionsConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  from?: Array<Stage>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<TitleDescriptionManyWhereInput>;
};

export type MutationUnpublishManyVideosArgs = {
  from?: Array<Stage>;
  locales: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<VideoManyWhereInput>;
};

export type MutationUnpublishManyVideosConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  first: InputMaybe<Scalars['Int']>;
  from?: Array<Stage>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: InputMaybe<VideoManyWhereInput>;
};

export type MutationUnpublishMenuArgs = {
  from?: Array<Stage>;
  where: MenuWhereUniqueInput;
};

export type MutationUnpublishNoticeArgs = {
  from?: Array<Stage>;
  locales: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: NoticeWhereUniqueInput;
};

export type MutationUnpublishPageArgs = {
  from?: Array<Stage>;
  locales: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: PageWhereUniqueInput;
};

export type MutationUnpublishPageTemplateArgs = {
  from?: Array<Stage>;
  where: PageTemplateWhereUniqueInput;
};

export type MutationUnpublishPartnerArgs = {
  from?: Array<Stage>;
  where: PartnerWhereUniqueInput;
};

export type MutationUnpublishPartnerOfferArgs = {
  from?: Array<Stage>;
  locales: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: PartnerOfferWhereUniqueInput;
};

export type MutationUnpublishProductArgs = {
  from?: Array<Stage>;
  locales: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: ProductWhereUniqueInput;
};

export type MutationUnpublishProductActivityArgs = {
  from?: Array<Stage>;
  locales: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: ProductActivityWhereUniqueInput;
};

export type MutationUnpublishProductActivityItemArgs = {
  from?: Array<Stage>;
  locales: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: ProductActivityItemWhereUniqueInput;
};

export type MutationUnpublishProductAttractionLineupArgs = {
  from?: Array<Stage>;
  where: ProductAttractionLineupWhereUniqueInput;
};

export type MutationUnpublishProductReservationArgs = {
  from?: Array<Stage>;
  where: ProductReservationWhereUniqueInput;
};

export type MutationUnpublishPromoArgs = {
  from?: Array<Stage>;
  locales: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: PromoWhereUniqueInput;
};

export type MutationUnpublishReservationTextArgs = {
  from?: Array<Stage>;
  locales: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: ReservationTextWhereUniqueInput;
};

export type MutationUnpublishSandboxArgs = {
  from?: Array<Stage>;
  locales: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: SandboxWhereUniqueInput;
};

export type MutationUnpublishSeoArgs = {
  from?: Array<Stage>;
  locales: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: SeoWhereUniqueInput;
};

export type MutationUnpublishTextArgs = {
  from?: Array<Stage>;
  locales: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: TextWhereUniqueInput;
};

export type MutationUnpublishTextGroupArgs = {
  from?: Array<Stage>;
  where: TextGroupWhereUniqueInput;
};

export type MutationUnpublishTextListArgs = {
  from?: Array<Stage>;
  where: TextListWhereUniqueInput;
};

export type MutationUnpublishTicketGroupArgs = {
  from?: Array<Stage>;
  where: TicketGroupWhereUniqueInput;
};

export type MutationUnpublishTitleDescriptionArgs = {
  from?: Array<Stage>;
  locales: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: TitleDescriptionWhereUniqueInput;
};

export type MutationUnpublishVideoArgs = {
  from?: Array<Stage>;
  locales: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: VideoWhereUniqueInput;
};

export type MutationUpdateAddOnArgs = {
  data: AddOnUpdateInput;
  where: AddOnWhereUniqueInput;
};

export type MutationUpdateAlacarteTicketArgs = {
  data: AlacarteTicketUpdateInput;
  where: AlacarteTicketWhereUniqueInput;
};

export type MutationUpdateAlacarteTicketTypeArgs = {
  data: AlacarteTicketTypeUpdateInput;
  where: AlacarteTicketTypeWhereUniqueInput;
};

export type MutationUpdateArticleArgs = {
  data: ArticleUpdateInput;
  where: ArticleWhereUniqueInput;
};

export type MutationUpdateArticleCategoryArgs = {
  data: ArticleCategoryUpdateInput;
  where: ArticleCategoryWhereUniqueInput;
};

export type MutationUpdateAssetArgs = {
  data: AssetUpdateInput;
  where: AssetWhereUniqueInput;
};

export type MutationUpdateAttractionArgs = {
  data: AttractionUpdateInput;
  where: AttractionWhereUniqueInput;
};

export type MutationUpdateAttractionAlacarteArgs = {
  data: AttractionAlacarteUpdateInput;
  where: AttractionAlacarteWhereUniqueInput;
};

export type MutationUpdateAttractionAvailabilityArgs = {
  data: AttractionAvailabilityUpdateInput;
  where: AttractionAvailabilityWhereUniqueInput;
};

export type MutationUpdateAttractionLocationArgs = {
  data: AttractionLocationUpdateInput;
  where: AttractionLocationWhereUniqueInput;
};

export type MutationUpdateAttractionOfferArgs = {
  data: AttractionOfferUpdateInput;
  where: AttractionOfferWhereUniqueInput;
};

export type MutationUpdateAttractionPriceArgs = {
  data: AttractionPriceUpdateInput;
  where: AttractionPriceWhereUniqueInput;
};

export type MutationUpdateAttractionReservationArgs = {
  data: AttractionReservationUpdateInput;
  where: AttractionReservationWhereUniqueInput;
};

export type MutationUpdateAttractionVariantArgs = {
  data: AttractionVariantUpdateInput;
  where: AttractionVariantWhereUniqueInput;
};

export type MutationUpdateAuthorArgs = {
  data: AuthorUpdateInput;
  where: AuthorWhereUniqueInput;
};

export type MutationUpdateBlockArgs = {
  data: BlockUpdateInput;
  where: BlockWhereUniqueInput;
};

export type MutationUpdateBlockGroupArgs = {
  data: BlockGroupUpdateInput;
  where: BlockGroupWhereUniqueInput;
};

export type MutationUpdateDetailBlockArgs = {
  data: DetailBlockUpdateInput;
  where: DetailBlockWhereUniqueInput;
};

export type MutationUpdateGeneralReservationArgs = {
  data: GeneralReservationUpdateInput;
  where: GeneralReservationWhereUniqueInput;
};

export type MutationUpdateImageArgs = {
  data: ImageUpdateInput;
  where: ImageWhereUniqueInput;
};

export type MutationUpdateLandingPageArgs = {
  data: LandingPageUpdateInput;
  where: LandingPageWhereUniqueInput;
};

export type MutationUpdateLinkArgs = {
  data: LinkUpdateInput;
  where: LinkWhereUniqueInput;
};

export type MutationUpdateManyAddOnsArgs = {
  data: AddOnUpdateManyInput;
  where: InputMaybe<AddOnManyWhereInput>;
};

export type MutationUpdateManyAddOnsConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  data: AddOnUpdateManyInput;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AddOnManyWhereInput>;
};

export type MutationUpdateManyAlacarteTicketTypesArgs = {
  data: AlacarteTicketTypeUpdateManyInput;
  where: InputMaybe<AlacarteTicketTypeManyWhereInput>;
};

export type MutationUpdateManyAlacarteTicketTypesConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  data: AlacarteTicketTypeUpdateManyInput;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AlacarteTicketTypeManyWhereInput>;
};

export type MutationUpdateManyAlacarteTicketsArgs = {
  data: AlacarteTicketUpdateManyInput;
  where: InputMaybe<AlacarteTicketManyWhereInput>;
};

export type MutationUpdateManyAlacarteTicketsConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  data: AlacarteTicketUpdateManyInput;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AlacarteTicketManyWhereInput>;
};

export type MutationUpdateManyArticleCategoriesArgs = {
  data: ArticleCategoryUpdateManyInput;
  where: InputMaybe<ArticleCategoryManyWhereInput>;
};

export type MutationUpdateManyArticleCategoriesConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  data: ArticleCategoryUpdateManyInput;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ArticleCategoryManyWhereInput>;
};

export type MutationUpdateManyArticlesArgs = {
  data: ArticleUpdateManyInput;
  where: InputMaybe<ArticleManyWhereInput>;
};

export type MutationUpdateManyArticlesConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  data: ArticleUpdateManyInput;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ArticleManyWhereInput>;
};

export type MutationUpdateManyAssetsArgs = {
  data: AssetUpdateManyInput;
  where: InputMaybe<AssetManyWhereInput>;
};

export type MutationUpdateManyAssetsConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  data: AssetUpdateManyInput;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AssetManyWhereInput>;
};

export type MutationUpdateManyAttractionAlacartesArgs = {
  data: AttractionAlacarteUpdateManyInput;
  where: InputMaybe<AttractionAlacarteManyWhereInput>;
};

export type MutationUpdateManyAttractionAlacartesConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  data: AttractionAlacarteUpdateManyInput;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AttractionAlacarteManyWhereInput>;
};

export type MutationUpdateManyAttractionAvailabilitiesArgs = {
  data: AttractionAvailabilityUpdateManyInput;
  where: InputMaybe<AttractionAvailabilityManyWhereInput>;
};

export type MutationUpdateManyAttractionAvailabilitiesConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  data: AttractionAvailabilityUpdateManyInput;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AttractionAvailabilityManyWhereInput>;
};

export type MutationUpdateManyAttractionLocationsArgs = {
  data: AttractionLocationUpdateManyInput;
  where: InputMaybe<AttractionLocationManyWhereInput>;
};

export type MutationUpdateManyAttractionLocationsConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  data: AttractionLocationUpdateManyInput;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AttractionLocationManyWhereInput>;
};

export type MutationUpdateManyAttractionOffersArgs = {
  data: AttractionOfferUpdateManyInput;
  where: InputMaybe<AttractionOfferManyWhereInput>;
};

export type MutationUpdateManyAttractionOffersConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  data: AttractionOfferUpdateManyInput;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AttractionOfferManyWhereInput>;
};

export type MutationUpdateManyAttractionPricesArgs = {
  data: AttractionPriceUpdateManyInput;
  where: InputMaybe<AttractionPriceManyWhereInput>;
};

export type MutationUpdateManyAttractionPricesConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  data: AttractionPriceUpdateManyInput;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AttractionPriceManyWhereInput>;
};

export type MutationUpdateManyAttractionReservationsArgs = {
  data: AttractionReservationUpdateManyInput;
  where: InputMaybe<AttractionReservationManyWhereInput>;
};

export type MutationUpdateManyAttractionReservationsConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  data: AttractionReservationUpdateManyInput;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AttractionReservationManyWhereInput>;
};

export type MutationUpdateManyAttractionVariantsArgs = {
  data: AttractionVariantUpdateManyInput;
  where: InputMaybe<AttractionVariantManyWhereInput>;
};

export type MutationUpdateManyAttractionVariantsConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  data: AttractionVariantUpdateManyInput;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AttractionVariantManyWhereInput>;
};

export type MutationUpdateManyAttractionsArgs = {
  data: AttractionUpdateManyInput;
  where: InputMaybe<AttractionManyWhereInput>;
};

export type MutationUpdateManyAttractionsConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  data: AttractionUpdateManyInput;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AttractionManyWhereInput>;
};

export type MutationUpdateManyAuthorsArgs = {
  data: AuthorUpdateManyInput;
  where: InputMaybe<AuthorManyWhereInput>;
};

export type MutationUpdateManyAuthorsConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  data: AuthorUpdateManyInput;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AuthorManyWhereInput>;
};

export type MutationUpdateManyBlockGroupsArgs = {
  data: BlockGroupUpdateManyInput;
  where: InputMaybe<BlockGroupManyWhereInput>;
};

export type MutationUpdateManyBlockGroupsConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  data: BlockGroupUpdateManyInput;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<BlockGroupManyWhereInput>;
};

export type MutationUpdateManyBlocksArgs = {
  data: BlockUpdateManyInput;
  where: InputMaybe<BlockManyWhereInput>;
};

export type MutationUpdateManyBlocksConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  data: BlockUpdateManyInput;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<BlockManyWhereInput>;
};

export type MutationUpdateManyDetailBlocksArgs = {
  data: DetailBlockUpdateManyInput;
  where: InputMaybe<DetailBlockManyWhereInput>;
};

export type MutationUpdateManyDetailBlocksConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  data: DetailBlockUpdateManyInput;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<DetailBlockManyWhereInput>;
};

export type MutationUpdateManyGeneralReservationsArgs = {
  data: GeneralReservationUpdateManyInput;
  where: InputMaybe<GeneralReservationManyWhereInput>;
};

export type MutationUpdateManyGeneralReservationsConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  data: GeneralReservationUpdateManyInput;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<GeneralReservationManyWhereInput>;
};

export type MutationUpdateManyImagesArgs = {
  data: ImageUpdateManyInput;
  where: InputMaybe<ImageManyWhereInput>;
};

export type MutationUpdateManyImagesConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  data: ImageUpdateManyInput;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ImageManyWhereInput>;
};

export type MutationUpdateManyLandingPagesArgs = {
  data: LandingPageUpdateManyInput;
  where: InputMaybe<LandingPageManyWhereInput>;
};

export type MutationUpdateManyLandingPagesConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  data: LandingPageUpdateManyInput;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<LandingPageManyWhereInput>;
};

export type MutationUpdateManyLinksArgs = {
  data: LinkUpdateManyInput;
  where: InputMaybe<LinkManyWhereInput>;
};

export type MutationUpdateManyLinksConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  data: LinkUpdateManyInput;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<LinkManyWhereInput>;
};

export type MutationUpdateManyMenusArgs = {
  data: MenuUpdateManyInput;
  where: InputMaybe<MenuManyWhereInput>;
};

export type MutationUpdateManyMenusConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  data: MenuUpdateManyInput;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<MenuManyWhereInput>;
};

export type MutationUpdateManyNoticesArgs = {
  data: NoticeUpdateManyInput;
  where: InputMaybe<NoticeManyWhereInput>;
};

export type MutationUpdateManyNoticesConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  data: NoticeUpdateManyInput;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<NoticeManyWhereInput>;
};

export type MutationUpdateManyPageTemplatesArgs = {
  data: PageTemplateUpdateManyInput;
  where: InputMaybe<PageTemplateManyWhereInput>;
};

export type MutationUpdateManyPageTemplatesConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  data: PageTemplateUpdateManyInput;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<PageTemplateManyWhereInput>;
};

export type MutationUpdateManyPagesArgs = {
  data: PageUpdateManyInput;
  where: InputMaybe<PageManyWhereInput>;
};

export type MutationUpdateManyPagesConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  data: PageUpdateManyInput;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<PageManyWhereInput>;
};

export type MutationUpdateManyPartnerOffersArgs = {
  data: PartnerOfferUpdateManyInput;
  where: InputMaybe<PartnerOfferManyWhereInput>;
};

export type MutationUpdateManyPartnerOffersConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  data: PartnerOfferUpdateManyInput;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<PartnerOfferManyWhereInput>;
};

export type MutationUpdateManyPartnersArgs = {
  data: PartnerUpdateManyInput;
  where: InputMaybe<PartnerManyWhereInput>;
};

export type MutationUpdateManyPartnersConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  data: PartnerUpdateManyInput;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<PartnerManyWhereInput>;
};

export type MutationUpdateManyProductActivitiesArgs = {
  data: ProductActivityUpdateManyInput;
  where: InputMaybe<ProductActivityManyWhereInput>;
};

export type MutationUpdateManyProductActivitiesConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  data: ProductActivityUpdateManyInput;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ProductActivityManyWhereInput>;
};

export type MutationUpdateManyProductActivityItemsArgs = {
  data: ProductActivityItemUpdateManyInput;
  where: InputMaybe<ProductActivityItemManyWhereInput>;
};

export type MutationUpdateManyProductActivityItemsConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  data: ProductActivityItemUpdateManyInput;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ProductActivityItemManyWhereInput>;
};

export type MutationUpdateManyProductAttractionLineupsArgs = {
  data: ProductAttractionLineupUpdateManyInput;
  where: InputMaybe<ProductAttractionLineupManyWhereInput>;
};

export type MutationUpdateManyProductAttractionLineupsConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  data: ProductAttractionLineupUpdateManyInput;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ProductAttractionLineupManyWhereInput>;
};

export type MutationUpdateManyProductReservationsArgs = {
  data: ProductReservationUpdateManyInput;
  where: InputMaybe<ProductReservationManyWhereInput>;
};

export type MutationUpdateManyProductReservationsConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  data: ProductReservationUpdateManyInput;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ProductReservationManyWhereInput>;
};

export type MutationUpdateManyProductsArgs = {
  data: ProductUpdateManyInput;
  where: InputMaybe<ProductManyWhereInput>;
};

export type MutationUpdateManyProductsConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  data: ProductUpdateManyInput;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ProductManyWhereInput>;
};

export type MutationUpdateManyPromosArgs = {
  data: PromoUpdateManyInput;
  where: InputMaybe<PromoManyWhereInput>;
};

export type MutationUpdateManyPromosConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  data: PromoUpdateManyInput;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<PromoManyWhereInput>;
};

export type MutationUpdateManyReservationTextsArgs = {
  data: ReservationTextUpdateManyInput;
  where: InputMaybe<ReservationTextManyWhereInput>;
};

export type MutationUpdateManyReservationTextsConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  data: ReservationTextUpdateManyInput;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ReservationTextManyWhereInput>;
};

export type MutationUpdateManySandboxesArgs = {
  data: SandboxUpdateManyInput;
  where: InputMaybe<SandboxManyWhereInput>;
};

export type MutationUpdateManySandboxesConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  data: SandboxUpdateManyInput;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<SandboxManyWhereInput>;
};

export type MutationUpdateManySeosArgs = {
  data: SeoUpdateManyInput;
  where: InputMaybe<SeoManyWhereInput>;
};

export type MutationUpdateManySeosConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  data: SeoUpdateManyInput;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<SeoManyWhereInput>;
};

export type MutationUpdateManyTextGroupsArgs = {
  data: TextGroupUpdateManyInput;
  where: InputMaybe<TextGroupManyWhereInput>;
};

export type MutationUpdateManyTextGroupsConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  data: TextGroupUpdateManyInput;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<TextGroupManyWhereInput>;
};

export type MutationUpdateManyTextListsArgs = {
  data: TextListUpdateManyInput;
  where: InputMaybe<TextListManyWhereInput>;
};

export type MutationUpdateManyTextListsConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  data: TextListUpdateManyInput;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<TextListManyWhereInput>;
};

export type MutationUpdateManyTextsArgs = {
  data: TextUpdateManyInput;
  where: InputMaybe<TextManyWhereInput>;
};

export type MutationUpdateManyTextsConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  data: TextUpdateManyInput;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<TextManyWhereInput>;
};

export type MutationUpdateManyTicketGroupsArgs = {
  data: TicketGroupUpdateManyInput;
  where: InputMaybe<TicketGroupManyWhereInput>;
};

export type MutationUpdateManyTicketGroupsConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  data: TicketGroupUpdateManyInput;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<TicketGroupManyWhereInput>;
};

export type MutationUpdateManyTitleDescriptionsArgs = {
  data: TitleDescriptionUpdateManyInput;
  where: InputMaybe<TitleDescriptionManyWhereInput>;
};

export type MutationUpdateManyTitleDescriptionsConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  data: TitleDescriptionUpdateManyInput;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<TitleDescriptionManyWhereInput>;
};

export type MutationUpdateManyVideosArgs = {
  data: VideoUpdateManyInput;
  where: InputMaybe<VideoManyWhereInput>;
};

export type MutationUpdateManyVideosConnectionArgs = {
  after: InputMaybe<Scalars['ID']>;
  before: InputMaybe<Scalars['ID']>;
  data: VideoUpdateManyInput;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<VideoManyWhereInput>;
};

export type MutationUpdateMenuArgs = {
  data: MenuUpdateInput;
  where: MenuWhereUniqueInput;
};

export type MutationUpdateNoticeArgs = {
  data: NoticeUpdateInput;
  where: NoticeWhereUniqueInput;
};

export type MutationUpdatePageArgs = {
  data: PageUpdateInput;
  where: PageWhereUniqueInput;
};

export type MutationUpdatePageTemplateArgs = {
  data: PageTemplateUpdateInput;
  where: PageTemplateWhereUniqueInput;
};

export type MutationUpdatePartnerArgs = {
  data: PartnerUpdateInput;
  where: PartnerWhereUniqueInput;
};

export type MutationUpdatePartnerOfferArgs = {
  data: PartnerOfferUpdateInput;
  where: PartnerOfferWhereUniqueInput;
};

export type MutationUpdateProductArgs = {
  data: ProductUpdateInput;
  where: ProductWhereUniqueInput;
};

export type MutationUpdateProductActivityArgs = {
  data: ProductActivityUpdateInput;
  where: ProductActivityWhereUniqueInput;
};

export type MutationUpdateProductActivityItemArgs = {
  data: ProductActivityItemUpdateInput;
  where: ProductActivityItemWhereUniqueInput;
};

export type MutationUpdateProductAttractionLineupArgs = {
  data: ProductAttractionLineupUpdateInput;
  where: ProductAttractionLineupWhereUniqueInput;
};

export type MutationUpdateProductReservationArgs = {
  data: ProductReservationUpdateInput;
  where: ProductReservationWhereUniqueInput;
};

export type MutationUpdatePromoArgs = {
  data: PromoUpdateInput;
  where: PromoWhereUniqueInput;
};

export type MutationUpdateReservationTextArgs = {
  data: ReservationTextUpdateInput;
  where: ReservationTextWhereUniqueInput;
};

export type MutationUpdateSandboxArgs = {
  data: SandboxUpdateInput;
  where: SandboxWhereUniqueInput;
};

export type MutationUpdateScheduledReleaseArgs = {
  data: ScheduledReleaseUpdateInput;
  where: ScheduledReleaseWhereUniqueInput;
};

export type MutationUpdateSeoArgs = {
  data: SeoUpdateInput;
  where: SeoWhereUniqueInput;
};

export type MutationUpdateTextArgs = {
  data: TextUpdateInput;
  where: TextWhereUniqueInput;
};

export type MutationUpdateTextGroupArgs = {
  data: TextGroupUpdateInput;
  where: TextGroupWhereUniqueInput;
};

export type MutationUpdateTextListArgs = {
  data: TextListUpdateInput;
  where: TextListWhereUniqueInput;
};

export type MutationUpdateTicketGroupArgs = {
  data: TicketGroupUpdateInput;
  where: TicketGroupWhereUniqueInput;
};

export type MutationUpdateTitleDescriptionArgs = {
  data: TitleDescriptionUpdateInput;
  where: TitleDescriptionWhereUniqueInput;
};

export type MutationUpdateVideoArgs = {
  data: VideoUpdateInput;
  where: VideoWhereUniqueInput;
};

export type MutationUpsertAddOnArgs = {
  upsert: AddOnUpsertInput;
  where: AddOnWhereUniqueInput;
};

export type MutationUpsertAlacarteTicketArgs = {
  upsert: AlacarteTicketUpsertInput;
  where: AlacarteTicketWhereUniqueInput;
};

export type MutationUpsertAlacarteTicketTypeArgs = {
  upsert: AlacarteTicketTypeUpsertInput;
  where: AlacarteTicketTypeWhereUniqueInput;
};

export type MutationUpsertArticleArgs = {
  upsert: ArticleUpsertInput;
  where: ArticleWhereUniqueInput;
};

export type MutationUpsertArticleCategoryArgs = {
  upsert: ArticleCategoryUpsertInput;
  where: ArticleCategoryWhereUniqueInput;
};

export type MutationUpsertAssetArgs = {
  upsert: AssetUpsertInput;
  where: AssetWhereUniqueInput;
};

export type MutationUpsertAttractionArgs = {
  upsert: AttractionUpsertInput;
  where: AttractionWhereUniqueInput;
};

export type MutationUpsertAttractionAlacarteArgs = {
  upsert: AttractionAlacarteUpsertInput;
  where: AttractionAlacarteWhereUniqueInput;
};

export type MutationUpsertAttractionAvailabilityArgs = {
  upsert: AttractionAvailabilityUpsertInput;
  where: AttractionAvailabilityWhereUniqueInput;
};

export type MutationUpsertAttractionLocationArgs = {
  upsert: AttractionLocationUpsertInput;
  where: AttractionLocationWhereUniqueInput;
};

export type MutationUpsertAttractionOfferArgs = {
  upsert: AttractionOfferUpsertInput;
  where: AttractionOfferWhereUniqueInput;
};

export type MutationUpsertAttractionPriceArgs = {
  upsert: AttractionPriceUpsertInput;
  where: AttractionPriceWhereUniqueInput;
};

export type MutationUpsertAttractionReservationArgs = {
  upsert: AttractionReservationUpsertInput;
  where: AttractionReservationWhereUniqueInput;
};

export type MutationUpsertAttractionVariantArgs = {
  upsert: AttractionVariantUpsertInput;
  where: AttractionVariantWhereUniqueInput;
};

export type MutationUpsertAuthorArgs = {
  upsert: AuthorUpsertInput;
  where: AuthorWhereUniqueInput;
};

export type MutationUpsertBlockArgs = {
  upsert: BlockUpsertInput;
  where: BlockWhereUniqueInput;
};

export type MutationUpsertBlockGroupArgs = {
  upsert: BlockGroupUpsertInput;
  where: BlockGroupWhereUniqueInput;
};

export type MutationUpsertDetailBlockArgs = {
  upsert: DetailBlockUpsertInput;
  where: DetailBlockWhereUniqueInput;
};

export type MutationUpsertGeneralReservationArgs = {
  upsert: GeneralReservationUpsertInput;
  where: GeneralReservationWhereUniqueInput;
};

export type MutationUpsertImageArgs = {
  upsert: ImageUpsertInput;
  where: ImageWhereUniqueInput;
};

export type MutationUpsertLandingPageArgs = {
  upsert: LandingPageUpsertInput;
  where: LandingPageWhereUniqueInput;
};

export type MutationUpsertLinkArgs = {
  upsert: LinkUpsertInput;
  where: LinkWhereUniqueInput;
};

export type MutationUpsertMenuArgs = {
  upsert: MenuUpsertInput;
  where: MenuWhereUniqueInput;
};

export type MutationUpsertNoticeArgs = {
  upsert: NoticeUpsertInput;
  where: NoticeWhereUniqueInput;
};

export type MutationUpsertPageArgs = {
  upsert: PageUpsertInput;
  where: PageWhereUniqueInput;
};

export type MutationUpsertPageTemplateArgs = {
  upsert: PageTemplateUpsertInput;
  where: PageTemplateWhereUniqueInput;
};

export type MutationUpsertPartnerArgs = {
  upsert: PartnerUpsertInput;
  where: PartnerWhereUniqueInput;
};

export type MutationUpsertPartnerOfferArgs = {
  upsert: PartnerOfferUpsertInput;
  where: PartnerOfferWhereUniqueInput;
};

export type MutationUpsertProductArgs = {
  upsert: ProductUpsertInput;
  where: ProductWhereUniqueInput;
};

export type MutationUpsertProductActivityArgs = {
  upsert: ProductActivityUpsertInput;
  where: ProductActivityWhereUniqueInput;
};

export type MutationUpsertProductActivityItemArgs = {
  upsert: ProductActivityItemUpsertInput;
  where: ProductActivityItemWhereUniqueInput;
};

export type MutationUpsertProductAttractionLineupArgs = {
  upsert: ProductAttractionLineupUpsertInput;
  where: ProductAttractionLineupWhereUniqueInput;
};

export type MutationUpsertProductReservationArgs = {
  upsert: ProductReservationUpsertInput;
  where: ProductReservationWhereUniqueInput;
};

export type MutationUpsertPromoArgs = {
  upsert: PromoUpsertInput;
  where: PromoWhereUniqueInput;
};

export type MutationUpsertReservationTextArgs = {
  upsert: ReservationTextUpsertInput;
  where: ReservationTextWhereUniqueInput;
};

export type MutationUpsertSandboxArgs = {
  upsert: SandboxUpsertInput;
  where: SandboxWhereUniqueInput;
};

export type MutationUpsertSeoArgs = {
  upsert: SeoUpsertInput;
  where: SeoWhereUniqueInput;
};

export type MutationUpsertTextArgs = {
  upsert: TextUpsertInput;
  where: TextWhereUniqueInput;
};

export type MutationUpsertTextGroupArgs = {
  upsert: TextGroupUpsertInput;
  where: TextGroupWhereUniqueInput;
};

export type MutationUpsertTextListArgs = {
  upsert: TextListUpsertInput;
  where: TextListWhereUniqueInput;
};

export type MutationUpsertTicketGroupArgs = {
  upsert: TicketGroupUpsertInput;
  where: TicketGroupWhereUniqueInput;
};

export type MutationUpsertTitleDescriptionArgs = {
  upsert: TitleDescriptionUpsertInput;
  where: TitleDescriptionWhereUniqueInput;
};

export type MutationUpsertVideoArgs = {
  upsert: VideoUpsertInput;
  where: VideoWhereUniqueInput;
};

/** An object with an ID */
export type Node = {
  /** The id of the object. */
  id: Scalars['ID'];
  /** The Stage of an object */
  stage: Stage;
};

/** Notices are for displaying information about an attraction or product. */
export type Notice = Node & {
  __typename?: 'Notice';
  attractions: Array<Attraction>;
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** User that created this document */
  createdBy: Maybe<User>;
  /** Displays the notice fully expanded by default. */
  defaultOpen: Scalars['Boolean'];
  description: Maybe<Scalars['String']>;
  /** Get the document in other stages */
  documentInStages: Array<Notice>;
  endDate: Maybe<Scalars['Date']>;
  /** List of Notice versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID'];
  key: Scalars['String'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<Notice>;
  placements: Array<NoticePlacements>;
  platforms: Array<Platforms>;
  /** NOTE: If you are targeting a C3 Exclusive attraction you must also include the core product as well as the C3 product. */
  products: Array<Product>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt: Maybe<Scalars['DateTime']>;
  /** User that last published this document */
  publishedBy: Maybe<User>;
  qabu: Maybe<Scalars['Json']>;
  scheduledIn: Array<ScheduledOperation>;
  /** System stage field */
  stage: Stage;
  startDate: Maybe<Scalars['Date']>;
  theme: NoticeTheme;
  /** A title for the notice heading */
  title: Maybe<Scalars['String']>;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** User that last updated this document */
  updatedBy: Maybe<User>;
};

/** Notices are for displaying information about an attraction or product. */
export type NoticeAttractionsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<AttractionOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AttractionWhereInput>;
};

/** Notices are for displaying information about an attraction or product. */
export type NoticeCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

/** Notices are for displaying information about an attraction or product. */
export type NoticeCreatedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

/** Notices are for displaying information about an attraction or product. */
export type NoticeDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};

/** Notices are for displaying information about an attraction or product. */
export type NoticeHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride: InputMaybe<Stage>;
};

/** Notices are for displaying information about an attraction or product. */
export type NoticeLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};

/** Notices are for displaying information about an attraction or product. */
export type NoticeProductsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<ProductOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ProductWhereInput>;
};

/** Notices are for displaying information about an attraction or product. */
export type NoticePublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

/** Notices are for displaying information about an attraction or product. */
export type NoticePublishedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

/** Notices are for displaying information about an attraction or product. */
export type NoticeScheduledInArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ScheduledOperationWhereInput>;
};

/** Notices are for displaying information about an attraction or product. */
export type NoticeUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

/** Notices are for displaying information about an attraction or product. */
export type NoticeUpdatedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type NoticeConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: NoticeWhereUniqueInput;
};

/** A connection to a list of items. */
export type NoticeConnection = {
  __typename?: 'NoticeConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<NoticeEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type NoticeCreateInput = {
  attractions?: InputMaybe<AttractionCreateManyInlineInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  defaultOpen: Scalars['Boolean'];
  /** description input for default locale (en) */
  description?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['Date']>;
  key: Scalars['String'];
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<NoticeCreateLocalizationsInput>;
  placements?: InputMaybe<Array<NoticePlacements>>;
  platforms?: InputMaybe<Array<Platforms>>;
  products?: InputMaybe<ProductCreateManyInlineInput>;
  qabu?: InputMaybe<Scalars['Json']>;
  startDate?: InputMaybe<Scalars['Date']>;
  theme: NoticeTheme;
  /** title input for default locale (en) */
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type NoticeCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type NoticeCreateLocalizationInput = {
  /** Localization input */
  data: NoticeCreateLocalizationDataInput;
  locale: Locale;
};

export type NoticeCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<NoticeCreateLocalizationInput>>;
};

export type NoticeCreateManyInlineInput = {
  /** Connect multiple existing Notice documents */
  connect?: InputMaybe<Array<NoticeWhereUniqueInput>>;
  /** Create and connect multiple existing Notice documents */
  create?: InputMaybe<Array<NoticeCreateInput>>;
};

export type NoticeCreateOneInlineInput = {
  /** Connect one existing Notice document */
  connect?: InputMaybe<NoticeWhereUniqueInput>;
  /** Create and connect one Notice document */
  create?: InputMaybe<NoticeCreateInput>;
};

/** An edge in a connection. */
export type NoticeEdge = {
  __typename?: 'NoticeEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Notice;
};

/** Identifies documents */
export type NoticeManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<NoticeWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<NoticeWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<NoticeWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  attractions_every?: InputMaybe<AttractionWhereInput>;
  attractions_none?: InputMaybe<AttractionWhereInput>;
  attractions_some?: InputMaybe<AttractionWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  defaultOpen?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  defaultOpen_not?: InputMaybe<Scalars['Boolean']>;
  documentInStages_every?: InputMaybe<NoticeWhereStageInput>;
  documentInStages_none?: InputMaybe<NoticeWhereStageInput>;
  documentInStages_some?: InputMaybe<NoticeWhereStageInput>;
  endDate?: InputMaybe<Scalars['Date']>;
  /** All values greater than the given value. */
  endDate_gt?: InputMaybe<Scalars['Date']>;
  /** All values greater than or equal the given value. */
  endDate_gte?: InputMaybe<Scalars['Date']>;
  /** All values that are contained in given list. */
  endDate_in?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  /** All values less than the given value. */
  endDate_lt?: InputMaybe<Scalars['Date']>;
  /** All values less than or equal the given value. */
  endDate_lte?: InputMaybe<Scalars['Date']>;
  /** Any other value that exists and is not equal to the given value. */
  endDate_not?: InputMaybe<Scalars['Date']>;
  /** All values that are not contained in given list. */
  endDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  key?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  key_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  key_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  key_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  key_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  key_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  key_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  key_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  key_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  key_starts_with?: InputMaybe<Scalars['String']>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  placements?: InputMaybe<Array<NoticePlacements>>;
  /** Matches if the field array contains *all* items provided to the filter */
  placements_contains_all?: InputMaybe<Array<NoticePlacements>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  placements_contains_none?: InputMaybe<Array<NoticePlacements>>;
  /** Matches if the field array contains at least one item provided to the filter */
  placements_contains_some?: InputMaybe<Array<NoticePlacements>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  placements_not?: InputMaybe<Array<NoticePlacements>>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  platforms?: InputMaybe<Array<Platforms>>;
  /** Matches if the field array contains *all* items provided to the filter */
  platforms_contains_all?: InputMaybe<Array<Platforms>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  platforms_contains_none?: InputMaybe<Array<Platforms>>;
  /** Matches if the field array contains at least one item provided to the filter */
  platforms_contains_some?: InputMaybe<Array<Platforms>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  platforms_not?: InputMaybe<Array<Platforms>>;
  products_every?: InputMaybe<ProductWhereInput>;
  products_none?: InputMaybe<ProductWhereInput>;
  products_some?: InputMaybe<ProductWhereInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  /** All values containing the given json path. */
  qabu_json_path_exists?: InputMaybe<Scalars['String']>;
  /**
   * Recursively tries to find the provided JSON scalar value inside the field.
   * It does use an exact match when comparing values.
   * If you pass `null` as value the filter will be ignored.
   * Note: This filter fails if you try to look for a non scalar JSON value!
   */
  qabu_value_recursive?: InputMaybe<Scalars['Json']>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  startDate?: InputMaybe<Scalars['Date']>;
  /** All values greater than the given value. */
  startDate_gt?: InputMaybe<Scalars['Date']>;
  /** All values greater than or equal the given value. */
  startDate_gte?: InputMaybe<Scalars['Date']>;
  /** All values that are contained in given list. */
  startDate_in?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  /** All values less than the given value. */
  startDate_lt?: InputMaybe<Scalars['Date']>;
  /** All values less than or equal the given value. */
  startDate_lte?: InputMaybe<Scalars['Date']>;
  /** Any other value that exists and is not equal to the given value. */
  startDate_not?: InputMaybe<Scalars['Date']>;
  /** All values that are not contained in given list. */
  startDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  theme?: InputMaybe<NoticeTheme>;
  /** All values that are contained in given list. */
  theme_in?: InputMaybe<Array<InputMaybe<NoticeTheme>>>;
  /** Any other value that exists and is not equal to the given value. */
  theme_not?: InputMaybe<NoticeTheme>;
  /** All values that are not contained in given list. */
  theme_not_in?: InputMaybe<Array<InputMaybe<NoticeTheme>>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum NoticeOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DefaultOpenAsc = 'defaultOpen_ASC',
  DefaultOpenDesc = 'defaultOpen_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  EndDateAsc = 'endDate_ASC',
  EndDateDesc = 'endDate_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  PlacementsAsc = 'placements_ASC',
  PlacementsDesc = 'placements_DESC',
  PlatformsAsc = 'platforms_ASC',
  PlatformsDesc = 'platforms_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  StartDateAsc = 'startDate_ASC',
  StartDateDesc = 'startDate_DESC',
  ThemeAsc = 'theme_ASC',
  ThemeDesc = 'theme_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export enum NoticePlacements {
  AlacarteTicketPickerTop = 'alacarteTicketPickerTop',
  AttractionContentMiddle = 'attractionContentMiddle',
  AttractionContentTop = 'attractionContentTop',
  ContentTop = 'contentTop',
  HomeContentTop = 'homeContentTop',
  Notifications = 'notifications',
  ProductContentTop = 'productContentTop',
  ProductLineupBottom = 'productLineupBottom',
  ProductLineupTop = 'productLineupTop',
  ReservationStepDate = 'reservationStepDate',
  ReservationStepLocation = 'reservationStepLocation',
  ReservationStepTime = 'reservationStepTime',
}

export enum NoticeTheme {
  Danger = 'danger',
  Default = 'default',
  Info = 'info',
  Warn = 'warn',
}

export type NoticeUpdateInput = {
  attractions?: InputMaybe<AttractionUpdateManyInlineInput>;
  defaultOpen?: InputMaybe<Scalars['Boolean']>;
  /** description input for default locale (en) */
  description?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['Date']>;
  key?: InputMaybe<Scalars['String']>;
  /** Manage document localizations */
  localizations?: InputMaybe<NoticeUpdateLocalizationsInput>;
  placements?: InputMaybe<Array<NoticePlacements>>;
  platforms?: InputMaybe<Array<Platforms>>;
  products?: InputMaybe<ProductUpdateManyInlineInput>;
  qabu?: InputMaybe<Scalars['Json']>;
  startDate?: InputMaybe<Scalars['Date']>;
  theme?: InputMaybe<NoticeTheme>;
  /** title input for default locale (en) */
  title?: InputMaybe<Scalars['String']>;
};

export type NoticeUpdateLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type NoticeUpdateLocalizationInput = {
  data: NoticeUpdateLocalizationDataInput;
  locale: Locale;
};

export type NoticeUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<NoticeCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<NoticeUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<NoticeUpsertLocalizationInput>>;
};

export type NoticeUpdateManyInlineInput = {
  /** Connect multiple existing Notice documents */
  connect?: InputMaybe<Array<NoticeConnectInput>>;
  /** Create and connect multiple Notice documents */
  create?: InputMaybe<Array<NoticeCreateInput>>;
  /** Delete multiple Notice documents */
  delete?: InputMaybe<Array<NoticeWhereUniqueInput>>;
  /** Disconnect multiple Notice documents */
  disconnect?: InputMaybe<Array<NoticeWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing Notice documents */
  set?: InputMaybe<Array<NoticeWhereUniqueInput>>;
  /** Update multiple Notice documents */
  update?: InputMaybe<Array<NoticeUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple Notice documents */
  upsert?: InputMaybe<Array<NoticeUpsertWithNestedWhereUniqueInput>>;
};

export type NoticeUpdateManyInput = {
  defaultOpen?: InputMaybe<Scalars['Boolean']>;
  /** description input for default locale (en) */
  description?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['Date']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<NoticeUpdateManyLocalizationsInput>;
  placements?: InputMaybe<Array<NoticePlacements>>;
  platforms?: InputMaybe<Array<Platforms>>;
  qabu?: InputMaybe<Scalars['Json']>;
  startDate?: InputMaybe<Scalars['Date']>;
  theme?: InputMaybe<NoticeTheme>;
  /** title input for default locale (en) */
  title?: InputMaybe<Scalars['String']>;
};

export type NoticeUpdateManyLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type NoticeUpdateManyLocalizationInput = {
  data: NoticeUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type NoticeUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<NoticeUpdateManyLocalizationInput>>;
};

export type NoticeUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: NoticeUpdateManyInput;
  /** Document search */
  where: NoticeWhereInput;
};

export type NoticeUpdateOneInlineInput = {
  /** Connect existing Notice document */
  connect?: InputMaybe<NoticeWhereUniqueInput>;
  /** Create and connect one Notice document */
  create?: InputMaybe<NoticeCreateInput>;
  /** Delete currently connected Notice document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected Notice document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single Notice document */
  update?: InputMaybe<NoticeUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Notice document */
  upsert?: InputMaybe<NoticeUpsertWithNestedWhereUniqueInput>;
};

export type NoticeUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: NoticeUpdateInput;
  /** Unique document search */
  where: NoticeWhereUniqueInput;
};

export type NoticeUpsertInput = {
  /** Create document if it didn't exist */
  create: NoticeCreateInput;
  /** Update document if it exists */
  update: NoticeUpdateInput;
};

export type NoticeUpsertLocalizationInput = {
  create: NoticeCreateLocalizationDataInput;
  locale: Locale;
  update: NoticeUpdateLocalizationDataInput;
};

export type NoticeUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: NoticeUpsertInput;
  /** Unique document search */
  where: NoticeWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type NoticeWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']>;
};

/** Identifies documents */
export type NoticeWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<NoticeWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<NoticeWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<NoticeWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  attractions_every?: InputMaybe<AttractionWhereInput>;
  attractions_none?: InputMaybe<AttractionWhereInput>;
  attractions_some?: InputMaybe<AttractionWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  defaultOpen?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  defaultOpen_not?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  description_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars['String']>;
  documentInStages_every?: InputMaybe<NoticeWhereStageInput>;
  documentInStages_none?: InputMaybe<NoticeWhereStageInput>;
  documentInStages_some?: InputMaybe<NoticeWhereStageInput>;
  endDate?: InputMaybe<Scalars['Date']>;
  /** All values greater than the given value. */
  endDate_gt?: InputMaybe<Scalars['Date']>;
  /** All values greater than or equal the given value. */
  endDate_gte?: InputMaybe<Scalars['Date']>;
  /** All values that are contained in given list. */
  endDate_in?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  /** All values less than the given value. */
  endDate_lt?: InputMaybe<Scalars['Date']>;
  /** All values less than or equal the given value. */
  endDate_lte?: InputMaybe<Scalars['Date']>;
  /** Any other value that exists and is not equal to the given value. */
  endDate_not?: InputMaybe<Scalars['Date']>;
  /** All values that are not contained in given list. */
  endDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  key?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  key_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  key_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  key_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  key_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  key_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  key_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  key_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  key_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  key_starts_with?: InputMaybe<Scalars['String']>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  placements?: InputMaybe<Array<NoticePlacements>>;
  /** Matches if the field array contains *all* items provided to the filter */
  placements_contains_all?: InputMaybe<Array<NoticePlacements>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  placements_contains_none?: InputMaybe<Array<NoticePlacements>>;
  /** Matches if the field array contains at least one item provided to the filter */
  placements_contains_some?: InputMaybe<Array<NoticePlacements>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  placements_not?: InputMaybe<Array<NoticePlacements>>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  platforms?: InputMaybe<Array<Platforms>>;
  /** Matches if the field array contains *all* items provided to the filter */
  platforms_contains_all?: InputMaybe<Array<Platforms>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  platforms_contains_none?: InputMaybe<Array<Platforms>>;
  /** Matches if the field array contains at least one item provided to the filter */
  platforms_contains_some?: InputMaybe<Array<Platforms>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  platforms_not?: InputMaybe<Array<Platforms>>;
  products_every?: InputMaybe<ProductWhereInput>;
  products_none?: InputMaybe<ProductWhereInput>;
  products_some?: InputMaybe<ProductWhereInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  /** All values containing the given json path. */
  qabu_json_path_exists?: InputMaybe<Scalars['String']>;
  /**
   * Recursively tries to find the provided JSON scalar value inside the field.
   * It does use an exact match when comparing values.
   * If you pass `null` as value the filter will be ignored.
   * Note: This filter fails if you try to look for a non scalar JSON value!
   */
  qabu_value_recursive?: InputMaybe<Scalars['Json']>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  startDate?: InputMaybe<Scalars['Date']>;
  /** All values greater than the given value. */
  startDate_gt?: InputMaybe<Scalars['Date']>;
  /** All values greater than or equal the given value. */
  startDate_gte?: InputMaybe<Scalars['Date']>;
  /** All values that are contained in given list. */
  startDate_in?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  /** All values less than the given value. */
  startDate_lt?: InputMaybe<Scalars['Date']>;
  /** All values less than or equal the given value. */
  startDate_lte?: InputMaybe<Scalars['Date']>;
  /** Any other value that exists and is not equal to the given value. */
  startDate_not?: InputMaybe<Scalars['Date']>;
  /** All values that are not contained in given list. */
  startDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  theme?: InputMaybe<NoticeTheme>;
  /** All values that are contained in given list. */
  theme_in?: InputMaybe<Array<InputMaybe<NoticeTheme>>>;
  /** Any other value that exists and is not equal to the given value. */
  theme_not?: InputMaybe<NoticeTheme>;
  /** All values that are not contained in given list. */
  theme_not_in?: InputMaybe<Array<InputMaybe<NoticeTheme>>>;
  title?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type NoticeWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<NoticeWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<NoticeWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<NoticeWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<NoticeWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References Notice record uniquely */
export type NoticeWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
  key?: InputMaybe<Scalars['String']>;
};

export type Page = Node & {
  __typename?: 'Page';
  body: Maybe<Scalars['String']>;
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** User that created this document */
  createdBy: Maybe<User>;
  /** Get the document in other stages */
  documentInStages: Array<Page>;
  footer: Maybe<Menu>;
  header: Maybe<Menu>;
  hero: Maybe<Asset>;
  /** List of Page versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID'];
  internalTitle: Scalars['String'];
  isTemplate: Scalars['Boolean'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<Page>;
  menus: Array<Menu>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt: Maybe<Scalars['DateTime']>;
  /** User that last published this document */
  publishedBy: Maybe<User>;
  qabu: Maybe<Scalars['Json']>;
  scheduledIn: Array<ScheduledOperation>;
  seo: Maybe<Seo>;
  siteSection: SiteSection;
  slug: Maybe<Scalars['String']>;
  /** System stage field */
  stage: Stage;
  textGroups: Array<TextGroup>;
  title: Maybe<Scalars['String']>;
  type: PageType;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** User that last updated this document */
  updatedBy: Maybe<User>;
};

export type PageCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type PageCreatedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type PageDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};

export type PageFooterArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type PageHeaderArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type PageHeroArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type PageHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride: InputMaybe<Stage>;
};

export type PageLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};

export type PageMenusArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<MenuWhereInput>;
};

export type PagePublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type PagePublishedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type PageScheduledInArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ScheduledOperationWhereInput>;
};

export type PageSeoArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type PageTextGroupsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<TextGroupOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<TextGroupWhereInput>;
};

export type PageUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type PageUpdatedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type PageConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: PageWhereUniqueInput;
};

/** A connection to a list of items. */
export type PageConnection = {
  __typename?: 'PageConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<PageEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type PageCreateInput = {
  /** body input for default locale (en) */
  body?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  footer?: InputMaybe<MenuCreateOneInlineInput>;
  header?: InputMaybe<MenuCreateOneInlineInput>;
  hero?: InputMaybe<AssetCreateOneInlineInput>;
  internalTitle: Scalars['String'];
  isTemplate: Scalars['Boolean'];
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<PageCreateLocalizationsInput>;
  menus?: InputMaybe<MenuCreateManyInlineInput>;
  qabu?: InputMaybe<Scalars['Json']>;
  seo?: InputMaybe<SeoCreateOneInlineInput>;
  siteSection: SiteSection;
  slug?: InputMaybe<Scalars['String']>;
  textGroups?: InputMaybe<TextGroupCreateManyInlineInput>;
  /** title input for default locale (en) */
  title?: InputMaybe<Scalars['String']>;
  type: PageType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PageCreateLocalizationDataInput = {
  body?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PageCreateLocalizationInput = {
  /** Localization input */
  data: PageCreateLocalizationDataInput;
  locale: Locale;
};

export type PageCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<PageCreateLocalizationInput>>;
};

export type PageCreateManyInlineInput = {
  /** Connect multiple existing Page documents */
  connect?: InputMaybe<Array<PageWhereUniqueInput>>;
  /** Create and connect multiple existing Page documents */
  create?: InputMaybe<Array<PageCreateInput>>;
};

export type PageCreateOneInlineInput = {
  /** Connect one existing Page document */
  connect?: InputMaybe<PageWhereUniqueInput>;
  /** Create and connect one Page document */
  create?: InputMaybe<PageCreateInput>;
};

/** An edge in a connection. */
export type PageEdge = {
  __typename?: 'PageEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Page;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** Number of items in the current page. */
  pageSize: Maybe<Scalars['Int']>;
  /** When paginating backwards, the cursor to continue. */
  startCursor: Maybe<Scalars['String']>;
};

/** Identifies documents */
export type PageManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<PageWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<PageWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<PageWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<PageWhereStageInput>;
  documentInStages_none?: InputMaybe<PageWhereStageInput>;
  documentInStages_some?: InputMaybe<PageWhereStageInput>;
  footer?: InputMaybe<MenuWhereInput>;
  header?: InputMaybe<MenuWhereInput>;
  hero?: InputMaybe<AssetWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  internalTitle?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  internalTitle_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  internalTitle_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  internalTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  internalTitle_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  internalTitle_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  internalTitle_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  internalTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  internalTitle_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  internalTitle_starts_with?: InputMaybe<Scalars['String']>;
  isTemplate?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  isTemplate_not?: InputMaybe<Scalars['Boolean']>;
  menus_every?: InputMaybe<MenuWhereInput>;
  menus_none?: InputMaybe<MenuWhereInput>;
  menus_some?: InputMaybe<MenuWhereInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  /** All values containing the given json path. */
  qabu_json_path_exists?: InputMaybe<Scalars['String']>;
  /**
   * Recursively tries to find the provided JSON scalar value inside the field.
   * It does use an exact match when comparing values.
   * If you pass `null` as value the filter will be ignored.
   * Note: This filter fails if you try to look for a non scalar JSON value!
   */
  qabu_value_recursive?: InputMaybe<Scalars['Json']>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  seo?: InputMaybe<SeoWhereInput>;
  siteSection?: InputMaybe<SiteSection>;
  /** All values that are contained in given list. */
  siteSection_in?: InputMaybe<Array<InputMaybe<SiteSection>>>;
  /** Any other value that exists and is not equal to the given value. */
  siteSection_not?: InputMaybe<SiteSection>;
  /** All values that are not contained in given list. */
  siteSection_not_in?: InputMaybe<Array<InputMaybe<SiteSection>>>;
  slug?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  slug_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  slug_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  slug_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  slug_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  slug_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  slug_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  slug_starts_with?: InputMaybe<Scalars['String']>;
  textGroups_every?: InputMaybe<TextGroupWhereInput>;
  textGroups_none?: InputMaybe<TextGroupWhereInput>;
  textGroups_some?: InputMaybe<TextGroupWhereInput>;
  type?: InputMaybe<PageType>;
  /** All values that are contained in given list. */
  type_in?: InputMaybe<Array<InputMaybe<PageType>>>;
  /** Any other value that exists and is not equal to the given value. */
  type_not?: InputMaybe<PageType>;
  /** All values that are not contained in given list. */
  type_not_in?: InputMaybe<Array<InputMaybe<PageType>>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum PageOrderByInput {
  BodyAsc = 'body_ASC',
  BodyDesc = 'body_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  InternalTitleAsc = 'internalTitle_ASC',
  InternalTitleDesc = 'internalTitle_DESC',
  IsTemplateAsc = 'isTemplate_ASC',
  IsTemplateDesc = 'isTemplate_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  SiteSectionAsc = 'siteSection_ASC',
  SiteSectionDesc = 'siteSection_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type PageTemplate = Node & {
  __typename?: 'PageTemplate';
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** User that created this document */
  createdBy: Maybe<User>;
  /** Get the document in other stages */
  documentInStages: Array<PageTemplate>;
  header: Maybe<Menu>;
  hero: Maybe<Asset>;
  /** List of PageTemplate versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID'];
  internalTitle: Scalars['String'];
  key: Scalars['String'];
  pageType: PageType;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt: Maybe<Scalars['DateTime']>;
  /** User that last published this document */
  publishedBy: Maybe<User>;
  scheduledIn: Array<ScheduledOperation>;
  seo: Maybe<Seo>;
  /** System stage field */
  stage: Stage;
  textGroups: Array<TextGroup>;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** User that last updated this document */
  updatedBy: Maybe<User>;
};

export type PageTemplateCreatedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type PageTemplateDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};

export type PageTemplateHeaderArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type PageTemplateHeroArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type PageTemplateHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride: InputMaybe<Stage>;
};

export type PageTemplatePublishedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type PageTemplateScheduledInArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ScheduledOperationWhereInput>;
};

export type PageTemplateSeoArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type PageTemplateTextGroupsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<TextGroupOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<TextGroupWhereInput>;
};

export type PageTemplateUpdatedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type PageTemplateConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: PageTemplateWhereUniqueInput;
};

/** A connection to a list of items. */
export type PageTemplateConnection = {
  __typename?: 'PageTemplateConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<PageTemplateEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type PageTemplateCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  header?: InputMaybe<MenuCreateOneInlineInput>;
  hero?: InputMaybe<AssetCreateOneInlineInput>;
  internalTitle: Scalars['String'];
  key: Scalars['String'];
  pageType: PageType;
  seo?: InputMaybe<SeoCreateOneInlineInput>;
  textGroups?: InputMaybe<TextGroupCreateManyInlineInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PageTemplateCreateManyInlineInput = {
  /** Connect multiple existing PageTemplate documents */
  connect?: InputMaybe<Array<PageTemplateWhereUniqueInput>>;
  /** Create and connect multiple existing PageTemplate documents */
  create?: InputMaybe<Array<PageTemplateCreateInput>>;
};

export type PageTemplateCreateOneInlineInput = {
  /** Connect one existing PageTemplate document */
  connect?: InputMaybe<PageTemplateWhereUniqueInput>;
  /** Create and connect one PageTemplate document */
  create?: InputMaybe<PageTemplateCreateInput>;
};

/** An edge in a connection. */
export type PageTemplateEdge = {
  __typename?: 'PageTemplateEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: PageTemplate;
};

/** Identifies documents */
export type PageTemplateManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<PageTemplateWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<PageTemplateWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<PageTemplateWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<PageTemplateWhereStageInput>;
  documentInStages_none?: InputMaybe<PageTemplateWhereStageInput>;
  documentInStages_some?: InputMaybe<PageTemplateWhereStageInput>;
  header?: InputMaybe<MenuWhereInput>;
  hero?: InputMaybe<AssetWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  internalTitle?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  internalTitle_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  internalTitle_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  internalTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  internalTitle_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  internalTitle_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  internalTitle_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  internalTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  internalTitle_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  internalTitle_starts_with?: InputMaybe<Scalars['String']>;
  key?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  key_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  key_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  key_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  key_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  key_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  key_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  key_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  key_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  key_starts_with?: InputMaybe<Scalars['String']>;
  pageType?: InputMaybe<PageType>;
  /** All values that are contained in given list. */
  pageType_in?: InputMaybe<Array<InputMaybe<PageType>>>;
  /** Any other value that exists and is not equal to the given value. */
  pageType_not?: InputMaybe<PageType>;
  /** All values that are not contained in given list. */
  pageType_not_in?: InputMaybe<Array<InputMaybe<PageType>>>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  seo?: InputMaybe<SeoWhereInput>;
  textGroups_every?: InputMaybe<TextGroupWhereInput>;
  textGroups_none?: InputMaybe<TextGroupWhereInput>;
  textGroups_some?: InputMaybe<TextGroupWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum PageTemplateOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  InternalTitleAsc = 'internalTitle_ASC',
  InternalTitleDesc = 'internalTitle_DESC',
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  PageTypeAsc = 'pageType_ASC',
  PageTypeDesc = 'pageType_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type PageTemplateUpdateInput = {
  header?: InputMaybe<MenuUpdateOneInlineInput>;
  hero?: InputMaybe<AssetUpdateOneInlineInput>;
  internalTitle?: InputMaybe<Scalars['String']>;
  key?: InputMaybe<Scalars['String']>;
  pageType?: InputMaybe<PageType>;
  seo?: InputMaybe<SeoUpdateOneInlineInput>;
  textGroups?: InputMaybe<TextGroupUpdateManyInlineInput>;
};

export type PageTemplateUpdateManyInlineInput = {
  /** Connect multiple existing PageTemplate documents */
  connect?: InputMaybe<Array<PageTemplateConnectInput>>;
  /** Create and connect multiple PageTemplate documents */
  create?: InputMaybe<Array<PageTemplateCreateInput>>;
  /** Delete multiple PageTemplate documents */
  delete?: InputMaybe<Array<PageTemplateWhereUniqueInput>>;
  /** Disconnect multiple PageTemplate documents */
  disconnect?: InputMaybe<Array<PageTemplateWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing PageTemplate documents */
  set?: InputMaybe<Array<PageTemplateWhereUniqueInput>>;
  /** Update multiple PageTemplate documents */
  update?: InputMaybe<Array<PageTemplateUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple PageTemplate documents */
  upsert?: InputMaybe<Array<PageTemplateUpsertWithNestedWhereUniqueInput>>;
};

export type PageTemplateUpdateManyInput = {
  internalTitle?: InputMaybe<Scalars['String']>;
  pageType?: InputMaybe<PageType>;
};

export type PageTemplateUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: PageTemplateUpdateManyInput;
  /** Document search */
  where: PageTemplateWhereInput;
};

export type PageTemplateUpdateOneInlineInput = {
  /** Connect existing PageTemplate document */
  connect?: InputMaybe<PageTemplateWhereUniqueInput>;
  /** Create and connect one PageTemplate document */
  create?: InputMaybe<PageTemplateCreateInput>;
  /** Delete currently connected PageTemplate document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected PageTemplate document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single PageTemplate document */
  update?: InputMaybe<PageTemplateUpdateWithNestedWhereUniqueInput>;
  /** Upsert single PageTemplate document */
  upsert?: InputMaybe<PageTemplateUpsertWithNestedWhereUniqueInput>;
};

export type PageTemplateUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: PageTemplateUpdateInput;
  /** Unique document search */
  where: PageTemplateWhereUniqueInput;
};

export type PageTemplateUpsertInput = {
  /** Create document if it didn't exist */
  create: PageTemplateCreateInput;
  /** Update document if it exists */
  update: PageTemplateUpdateInput;
};

export type PageTemplateUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: PageTemplateUpsertInput;
  /** Unique document search */
  where: PageTemplateWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type PageTemplateWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']>;
};

/** Identifies documents */
export type PageTemplateWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<PageTemplateWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<PageTemplateWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<PageTemplateWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<PageTemplateWhereStageInput>;
  documentInStages_none?: InputMaybe<PageTemplateWhereStageInput>;
  documentInStages_some?: InputMaybe<PageTemplateWhereStageInput>;
  header?: InputMaybe<MenuWhereInput>;
  hero?: InputMaybe<AssetWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  internalTitle?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  internalTitle_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  internalTitle_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  internalTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  internalTitle_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  internalTitle_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  internalTitle_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  internalTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  internalTitle_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  internalTitle_starts_with?: InputMaybe<Scalars['String']>;
  key?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  key_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  key_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  key_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  key_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  key_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  key_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  key_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  key_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  key_starts_with?: InputMaybe<Scalars['String']>;
  pageType?: InputMaybe<PageType>;
  /** All values that are contained in given list. */
  pageType_in?: InputMaybe<Array<InputMaybe<PageType>>>;
  /** Any other value that exists and is not equal to the given value. */
  pageType_not?: InputMaybe<PageType>;
  /** All values that are not contained in given list. */
  pageType_not_in?: InputMaybe<Array<InputMaybe<PageType>>>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  seo?: InputMaybe<SeoWhereInput>;
  textGroups_every?: InputMaybe<TextGroupWhereInput>;
  textGroups_none?: InputMaybe<TextGroupWhereInput>;
  textGroups_some?: InputMaybe<TextGroupWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type PageTemplateWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<PageTemplateWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<PageTemplateWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<PageTemplateWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<PageTemplateWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References PageTemplate record uniquely */
export type PageTemplateWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
  key?: InputMaybe<Scalars['String']>;
};

export enum PageType {
  Addon = 'addon',
  Affiliatebuyer = 'affiliatebuyer',
  Article = 'article',
  Articlepage = 'articlepage',
  Attraction = 'attraction',
  Checkout = 'checkout',
  Compare = 'compare',
  Embed = 'embed',
  Error404 = 'error404',
  General = 'general',
  Home = 'home',
  Planyourvisit = 'planyourvisit',
  Product = 'product',
  Promo = 'promo',
  Receipt = 'receipt',
  Thingstodo = 'thingstodo',
  Topmuseums = 'topmuseums',
  Traveldeals = 'traveldeals',
  Traveltrade = 'traveltrade',
}

export type PageUpdateInput = {
  /** body input for default locale (en) */
  body?: InputMaybe<Scalars['String']>;
  footer?: InputMaybe<MenuUpdateOneInlineInput>;
  header?: InputMaybe<MenuUpdateOneInlineInput>;
  hero?: InputMaybe<AssetUpdateOneInlineInput>;
  internalTitle?: InputMaybe<Scalars['String']>;
  isTemplate?: InputMaybe<Scalars['Boolean']>;
  /** Manage document localizations */
  localizations?: InputMaybe<PageUpdateLocalizationsInput>;
  menus?: InputMaybe<MenuUpdateManyInlineInput>;
  qabu?: InputMaybe<Scalars['Json']>;
  seo?: InputMaybe<SeoUpdateOneInlineInput>;
  siteSection?: InputMaybe<SiteSection>;
  slug?: InputMaybe<Scalars['String']>;
  textGroups?: InputMaybe<TextGroupUpdateManyInlineInput>;
  /** title input for default locale (en) */
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<PageType>;
};

export type PageUpdateLocalizationDataInput = {
  body?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type PageUpdateLocalizationInput = {
  data: PageUpdateLocalizationDataInput;
  locale: Locale;
};

export type PageUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<PageCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<PageUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<PageUpsertLocalizationInput>>;
};

export type PageUpdateManyInlineInput = {
  /** Connect multiple existing Page documents */
  connect?: InputMaybe<Array<PageConnectInput>>;
  /** Create and connect multiple Page documents */
  create?: InputMaybe<Array<PageCreateInput>>;
  /** Delete multiple Page documents */
  delete?: InputMaybe<Array<PageWhereUniqueInput>>;
  /** Disconnect multiple Page documents */
  disconnect?: InputMaybe<Array<PageWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing Page documents */
  set?: InputMaybe<Array<PageWhereUniqueInput>>;
  /** Update multiple Page documents */
  update?: InputMaybe<Array<PageUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple Page documents */
  upsert?: InputMaybe<Array<PageUpsertWithNestedWhereUniqueInput>>;
};

export type PageUpdateManyInput = {
  /** body input for default locale (en) */
  body?: InputMaybe<Scalars['String']>;
  internalTitle?: InputMaybe<Scalars['String']>;
  isTemplate?: InputMaybe<Scalars['Boolean']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<PageUpdateManyLocalizationsInput>;
  qabu?: InputMaybe<Scalars['Json']>;
  siteSection?: InputMaybe<SiteSection>;
  /** title input for default locale (en) */
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<PageType>;
};

export type PageUpdateManyLocalizationDataInput = {
  body?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type PageUpdateManyLocalizationInput = {
  data: PageUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type PageUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<PageUpdateManyLocalizationInput>>;
};

export type PageUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: PageUpdateManyInput;
  /** Document search */
  where: PageWhereInput;
};

export type PageUpdateOneInlineInput = {
  /** Connect existing Page document */
  connect?: InputMaybe<PageWhereUniqueInput>;
  /** Create and connect one Page document */
  create?: InputMaybe<PageCreateInput>;
  /** Delete currently connected Page document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected Page document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single Page document */
  update?: InputMaybe<PageUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Page document */
  upsert?: InputMaybe<PageUpsertWithNestedWhereUniqueInput>;
};

export type PageUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: PageUpdateInput;
  /** Unique document search */
  where: PageWhereUniqueInput;
};

export type PageUpsertInput = {
  /** Create document if it didn't exist */
  create: PageCreateInput;
  /** Update document if it exists */
  update: PageUpdateInput;
};

export type PageUpsertLocalizationInput = {
  create: PageCreateLocalizationDataInput;
  locale: Locale;
  update: PageUpdateLocalizationDataInput;
};

export type PageUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: PageUpsertInput;
  /** Unique document search */
  where: PageWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type PageWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']>;
};

/** Identifies documents */
export type PageWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<PageWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<PageWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<PageWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  body?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  body_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  body_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  body_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  body_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  body_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  body_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  body_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  body_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  body_starts_with?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<PageWhereStageInput>;
  documentInStages_none?: InputMaybe<PageWhereStageInput>;
  documentInStages_some?: InputMaybe<PageWhereStageInput>;
  footer?: InputMaybe<MenuWhereInput>;
  header?: InputMaybe<MenuWhereInput>;
  hero?: InputMaybe<AssetWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  internalTitle?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  internalTitle_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  internalTitle_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  internalTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  internalTitle_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  internalTitle_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  internalTitle_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  internalTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  internalTitle_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  internalTitle_starts_with?: InputMaybe<Scalars['String']>;
  isTemplate?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  isTemplate_not?: InputMaybe<Scalars['Boolean']>;
  menus_every?: InputMaybe<MenuWhereInput>;
  menus_none?: InputMaybe<MenuWhereInput>;
  menus_some?: InputMaybe<MenuWhereInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  /** All values containing the given json path. */
  qabu_json_path_exists?: InputMaybe<Scalars['String']>;
  /**
   * Recursively tries to find the provided JSON scalar value inside the field.
   * It does use an exact match when comparing values.
   * If you pass `null` as value the filter will be ignored.
   * Note: This filter fails if you try to look for a non scalar JSON value!
   */
  qabu_value_recursive?: InputMaybe<Scalars['Json']>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  seo?: InputMaybe<SeoWhereInput>;
  siteSection?: InputMaybe<SiteSection>;
  /** All values that are contained in given list. */
  siteSection_in?: InputMaybe<Array<InputMaybe<SiteSection>>>;
  /** Any other value that exists and is not equal to the given value. */
  siteSection_not?: InputMaybe<SiteSection>;
  /** All values that are not contained in given list. */
  siteSection_not_in?: InputMaybe<Array<InputMaybe<SiteSection>>>;
  slug?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  slug_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  slug_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  slug_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  slug_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  slug_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  slug_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  slug_starts_with?: InputMaybe<Scalars['String']>;
  textGroups_every?: InputMaybe<TextGroupWhereInput>;
  textGroups_none?: InputMaybe<TextGroupWhereInput>;
  textGroups_some?: InputMaybe<TextGroupWhereInput>;
  title?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<PageType>;
  /** All values that are contained in given list. */
  type_in?: InputMaybe<Array<InputMaybe<PageType>>>;
  /** Any other value that exists and is not equal to the given value. */
  type_not?: InputMaybe<PageType>;
  /** All values that are not contained in given list. */
  type_not_in?: InputMaybe<Array<InputMaybe<PageType>>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type PageWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<PageWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<PageWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<PageWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<PageWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References Page record uniquely */
export type PageWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
  slug?: InputMaybe<Scalars['String']>;
};

export type Partner = Node & {
  __typename?: 'Partner';
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** User that created this document */
  createdBy: Maybe<User>;
  /** Get the document in other stages */
  documentInStages: Array<Partner>;
  excludedProducts: Array<Product>;
  /** List of Partner versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt: Maybe<Scalars['DateTime']>;
  /** User that last published this document */
  publishedBy: Maybe<User>;
  scheduledIn: Array<ScheduledOperation>;
  /** System stage field */
  stage: Stage;
  type: PartnerType;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** User that last updated this document */
  updatedBy: Maybe<User>;
};

export type PartnerCreatedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type PartnerDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};

export type PartnerExcludedProductsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<ProductOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ProductWhereInput>;
};

export type PartnerHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride: InputMaybe<Stage>;
};

export type PartnerPublishedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type PartnerScheduledInArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ScheduledOperationWhereInput>;
};

export type PartnerUpdatedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type PartnerConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: PartnerWhereUniqueInput;
};

/** A connection to a list of items. */
export type PartnerConnection = {
  __typename?: 'PartnerConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<PartnerEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type PartnerCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  excludedProducts?: InputMaybe<ProductCreateManyInlineInput>;
  type: PartnerType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PartnerCreateManyInlineInput = {
  /** Connect multiple existing Partner documents */
  connect?: InputMaybe<Array<PartnerWhereUniqueInput>>;
  /** Create and connect multiple existing Partner documents */
  create?: InputMaybe<Array<PartnerCreateInput>>;
};

export type PartnerCreateOneInlineInput = {
  /** Connect one existing Partner document */
  connect?: InputMaybe<PartnerWhereUniqueInput>;
  /** Create and connect one Partner document */
  create?: InputMaybe<PartnerCreateInput>;
};

/** An edge in a connection. */
export type PartnerEdge = {
  __typename?: 'PartnerEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Partner;
};

/** Identifies documents */
export type PartnerManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<PartnerWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<PartnerWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<PartnerWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<PartnerWhereStageInput>;
  documentInStages_none?: InputMaybe<PartnerWhereStageInput>;
  documentInStages_some?: InputMaybe<PartnerWhereStageInput>;
  excludedProducts_every?: InputMaybe<ProductWhereInput>;
  excludedProducts_none?: InputMaybe<ProductWhereInput>;
  excludedProducts_some?: InputMaybe<ProductWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  type?: InputMaybe<PartnerType>;
  /** All values that are contained in given list. */
  type_in?: InputMaybe<Array<InputMaybe<PartnerType>>>;
  /** Any other value that exists and is not equal to the given value. */
  type_not?: InputMaybe<PartnerType>;
  /** All values that are not contained in given list. */
  type_not_in?: InputMaybe<Array<InputMaybe<PartnerType>>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export type PartnerOffer = Node & {
  __typename?: 'PartnerOffer';
  attractions: Array<Attraction>;
  campaignId: Scalars['String'];
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** User that created this document */
  createdBy: Maybe<User>;
  description: Maybe<Scalars['String']>;
  /** Get the document in other stages */
  documentInStages: Array<PartnerOffer>;
  endDate: Scalars['Date'];
  /** List of PartnerOffer versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID'];
  image: Asset;
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<PartnerOffer>;
  platforms: Array<Platforms>;
  products: Array<Product>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt: Maybe<Scalars['DateTime']>;
  /** User that last published this document */
  publishedBy: Maybe<User>;
  qabu: Maybe<Scalars['Json']>;
  scheduledIn: Array<ScheduledOperation>;
  /** System stage field */
  stage: Stage;
  startDate: Scalars['Date'];
  title: Maybe<Scalars['String']>;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** User that last updated this document */
  updatedBy: Maybe<User>;
};

export type PartnerOfferAttractionsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<AttractionOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AttractionWhereInput>;
};

export type PartnerOfferCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type PartnerOfferCreatedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type PartnerOfferDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};

export type PartnerOfferHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride: InputMaybe<Stage>;
};

export type PartnerOfferImageArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type PartnerOfferLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};

export type PartnerOfferProductsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<ProductOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ProductWhereInput>;
};

export type PartnerOfferPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type PartnerOfferPublishedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type PartnerOfferScheduledInArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ScheduledOperationWhereInput>;
};

export type PartnerOfferUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type PartnerOfferUpdatedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type PartnerOfferConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: PartnerOfferWhereUniqueInput;
};

/** A connection to a list of items. */
export type PartnerOfferConnection = {
  __typename?: 'PartnerOfferConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<PartnerOfferEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type PartnerOfferCreateInput = {
  attractions?: InputMaybe<AttractionCreateManyInlineInput>;
  campaignId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** description input for default locale (en) */
  description?: InputMaybe<Scalars['String']>;
  endDate: Scalars['Date'];
  image: AssetCreateOneInlineInput;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<PartnerOfferCreateLocalizationsInput>;
  platforms?: InputMaybe<Array<Platforms>>;
  products?: InputMaybe<ProductCreateManyInlineInput>;
  qabu?: InputMaybe<Scalars['Json']>;
  startDate: Scalars['Date'];
  /** title input for default locale (en) */
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PartnerOfferCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PartnerOfferCreateLocalizationInput = {
  /** Localization input */
  data: PartnerOfferCreateLocalizationDataInput;
  locale: Locale;
};

export type PartnerOfferCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<PartnerOfferCreateLocalizationInput>>;
};

export type PartnerOfferCreateManyInlineInput = {
  /** Connect multiple existing PartnerOffer documents */
  connect?: InputMaybe<Array<PartnerOfferWhereUniqueInput>>;
  /** Create and connect multiple existing PartnerOffer documents */
  create?: InputMaybe<Array<PartnerOfferCreateInput>>;
};

export type PartnerOfferCreateOneInlineInput = {
  /** Connect one existing PartnerOffer document */
  connect?: InputMaybe<PartnerOfferWhereUniqueInput>;
  /** Create and connect one PartnerOffer document */
  create?: InputMaybe<PartnerOfferCreateInput>;
};

/** An edge in a connection. */
export type PartnerOfferEdge = {
  __typename?: 'PartnerOfferEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: PartnerOffer;
};

/** Identifies documents */
export type PartnerOfferManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<PartnerOfferWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<PartnerOfferWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<PartnerOfferWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  attractions_every?: InputMaybe<AttractionWhereInput>;
  attractions_none?: InputMaybe<AttractionWhereInput>;
  attractions_some?: InputMaybe<AttractionWhereInput>;
  campaignId?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  campaignId_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  campaignId_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  campaignId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  campaignId_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  campaignId_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  campaignId_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  campaignId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  campaignId_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  campaignId_starts_with?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<PartnerOfferWhereStageInput>;
  documentInStages_none?: InputMaybe<PartnerOfferWhereStageInput>;
  documentInStages_some?: InputMaybe<PartnerOfferWhereStageInput>;
  endDate?: InputMaybe<Scalars['Date']>;
  /** All values greater than the given value. */
  endDate_gt?: InputMaybe<Scalars['Date']>;
  /** All values greater than or equal the given value. */
  endDate_gte?: InputMaybe<Scalars['Date']>;
  /** All values that are contained in given list. */
  endDate_in?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  /** All values less than the given value. */
  endDate_lt?: InputMaybe<Scalars['Date']>;
  /** All values less than or equal the given value. */
  endDate_lte?: InputMaybe<Scalars['Date']>;
  /** Any other value that exists and is not equal to the given value. */
  endDate_not?: InputMaybe<Scalars['Date']>;
  /** All values that are not contained in given list. */
  endDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  image?: InputMaybe<AssetWhereInput>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  platforms?: InputMaybe<Array<Platforms>>;
  /** Matches if the field array contains *all* items provided to the filter */
  platforms_contains_all?: InputMaybe<Array<Platforms>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  platforms_contains_none?: InputMaybe<Array<Platforms>>;
  /** Matches if the field array contains at least one item provided to the filter */
  platforms_contains_some?: InputMaybe<Array<Platforms>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  platforms_not?: InputMaybe<Array<Platforms>>;
  products_every?: InputMaybe<ProductWhereInput>;
  products_none?: InputMaybe<ProductWhereInput>;
  products_some?: InputMaybe<ProductWhereInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  /** All values containing the given json path. */
  qabu_json_path_exists?: InputMaybe<Scalars['String']>;
  /**
   * Recursively tries to find the provided JSON scalar value inside the field.
   * It does use an exact match when comparing values.
   * If you pass `null` as value the filter will be ignored.
   * Note: This filter fails if you try to look for a non scalar JSON value!
   */
  qabu_value_recursive?: InputMaybe<Scalars['Json']>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  startDate?: InputMaybe<Scalars['Date']>;
  /** All values greater than the given value. */
  startDate_gt?: InputMaybe<Scalars['Date']>;
  /** All values greater than or equal the given value. */
  startDate_gte?: InputMaybe<Scalars['Date']>;
  /** All values that are contained in given list. */
  startDate_in?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  /** All values less than the given value. */
  startDate_lt?: InputMaybe<Scalars['Date']>;
  /** All values less than or equal the given value. */
  startDate_lte?: InputMaybe<Scalars['Date']>;
  /** Any other value that exists and is not equal to the given value. */
  startDate_not?: InputMaybe<Scalars['Date']>;
  /** All values that are not contained in given list. */
  startDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum PartnerOfferOrderByInput {
  CampaignIdAsc = 'campaignId_ASC',
  CampaignIdDesc = 'campaignId_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  EndDateAsc = 'endDate_ASC',
  EndDateDesc = 'endDate_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PlatformsAsc = 'platforms_ASC',
  PlatformsDesc = 'platforms_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  StartDateAsc = 'startDate_ASC',
  StartDateDesc = 'startDate_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type PartnerOfferUpdateInput = {
  attractions?: InputMaybe<AttractionUpdateManyInlineInput>;
  campaignId?: InputMaybe<Scalars['String']>;
  /** description input for default locale (en) */
  description?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['Date']>;
  image?: InputMaybe<AssetUpdateOneInlineInput>;
  /** Manage document localizations */
  localizations?: InputMaybe<PartnerOfferUpdateLocalizationsInput>;
  platforms?: InputMaybe<Array<Platforms>>;
  products?: InputMaybe<ProductUpdateManyInlineInput>;
  qabu?: InputMaybe<Scalars['Json']>;
  startDate?: InputMaybe<Scalars['Date']>;
  /** title input for default locale (en) */
  title?: InputMaybe<Scalars['String']>;
};

export type PartnerOfferUpdateLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type PartnerOfferUpdateLocalizationInput = {
  data: PartnerOfferUpdateLocalizationDataInput;
  locale: Locale;
};

export type PartnerOfferUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<PartnerOfferCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<PartnerOfferUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<PartnerOfferUpsertLocalizationInput>>;
};

export type PartnerOfferUpdateManyInlineInput = {
  /** Connect multiple existing PartnerOffer documents */
  connect?: InputMaybe<Array<PartnerOfferConnectInput>>;
  /** Create and connect multiple PartnerOffer documents */
  create?: InputMaybe<Array<PartnerOfferCreateInput>>;
  /** Delete multiple PartnerOffer documents */
  delete?: InputMaybe<Array<PartnerOfferWhereUniqueInput>>;
  /** Disconnect multiple PartnerOffer documents */
  disconnect?: InputMaybe<Array<PartnerOfferWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing PartnerOffer documents */
  set?: InputMaybe<Array<PartnerOfferWhereUniqueInput>>;
  /** Update multiple PartnerOffer documents */
  update?: InputMaybe<Array<PartnerOfferUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple PartnerOffer documents */
  upsert?: InputMaybe<Array<PartnerOfferUpsertWithNestedWhereUniqueInput>>;
};

export type PartnerOfferUpdateManyInput = {
  /** description input for default locale (en) */
  description?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['Date']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<PartnerOfferUpdateManyLocalizationsInput>;
  platforms?: InputMaybe<Array<Platforms>>;
  qabu?: InputMaybe<Scalars['Json']>;
  startDate?: InputMaybe<Scalars['Date']>;
  /** title input for default locale (en) */
  title?: InputMaybe<Scalars['String']>;
};

export type PartnerOfferUpdateManyLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type PartnerOfferUpdateManyLocalizationInput = {
  data: PartnerOfferUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type PartnerOfferUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<PartnerOfferUpdateManyLocalizationInput>>;
};

export type PartnerOfferUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: PartnerOfferUpdateManyInput;
  /** Document search */
  where: PartnerOfferWhereInput;
};

export type PartnerOfferUpdateOneInlineInput = {
  /** Connect existing PartnerOffer document */
  connect?: InputMaybe<PartnerOfferWhereUniqueInput>;
  /** Create and connect one PartnerOffer document */
  create?: InputMaybe<PartnerOfferCreateInput>;
  /** Delete currently connected PartnerOffer document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected PartnerOffer document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single PartnerOffer document */
  update?: InputMaybe<PartnerOfferUpdateWithNestedWhereUniqueInput>;
  /** Upsert single PartnerOffer document */
  upsert?: InputMaybe<PartnerOfferUpsertWithNestedWhereUniqueInput>;
};

export type PartnerOfferUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: PartnerOfferUpdateInput;
  /** Unique document search */
  where: PartnerOfferWhereUniqueInput;
};

export type PartnerOfferUpsertInput = {
  /** Create document if it didn't exist */
  create: PartnerOfferCreateInput;
  /** Update document if it exists */
  update: PartnerOfferUpdateInput;
};

export type PartnerOfferUpsertLocalizationInput = {
  create: PartnerOfferCreateLocalizationDataInput;
  locale: Locale;
  update: PartnerOfferUpdateLocalizationDataInput;
};

export type PartnerOfferUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: PartnerOfferUpsertInput;
  /** Unique document search */
  where: PartnerOfferWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type PartnerOfferWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']>;
};

/** Identifies documents */
export type PartnerOfferWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<PartnerOfferWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<PartnerOfferWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<PartnerOfferWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  attractions_every?: InputMaybe<AttractionWhereInput>;
  attractions_none?: InputMaybe<AttractionWhereInput>;
  attractions_some?: InputMaybe<AttractionWhereInput>;
  campaignId?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  campaignId_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  campaignId_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  campaignId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  campaignId_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  campaignId_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  campaignId_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  campaignId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  campaignId_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  campaignId_starts_with?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  description?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  description_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars['String']>;
  documentInStages_every?: InputMaybe<PartnerOfferWhereStageInput>;
  documentInStages_none?: InputMaybe<PartnerOfferWhereStageInput>;
  documentInStages_some?: InputMaybe<PartnerOfferWhereStageInput>;
  endDate?: InputMaybe<Scalars['Date']>;
  /** All values greater than the given value. */
  endDate_gt?: InputMaybe<Scalars['Date']>;
  /** All values greater than or equal the given value. */
  endDate_gte?: InputMaybe<Scalars['Date']>;
  /** All values that are contained in given list. */
  endDate_in?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  /** All values less than the given value. */
  endDate_lt?: InputMaybe<Scalars['Date']>;
  /** All values less than or equal the given value. */
  endDate_lte?: InputMaybe<Scalars['Date']>;
  /** Any other value that exists and is not equal to the given value. */
  endDate_not?: InputMaybe<Scalars['Date']>;
  /** All values that are not contained in given list. */
  endDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  image?: InputMaybe<AssetWhereInput>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  platforms?: InputMaybe<Array<Platforms>>;
  /** Matches if the field array contains *all* items provided to the filter */
  platforms_contains_all?: InputMaybe<Array<Platforms>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  platforms_contains_none?: InputMaybe<Array<Platforms>>;
  /** Matches if the field array contains at least one item provided to the filter */
  platforms_contains_some?: InputMaybe<Array<Platforms>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  platforms_not?: InputMaybe<Array<Platforms>>;
  products_every?: InputMaybe<ProductWhereInput>;
  products_none?: InputMaybe<ProductWhereInput>;
  products_some?: InputMaybe<ProductWhereInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  /** All values containing the given json path. */
  qabu_json_path_exists?: InputMaybe<Scalars['String']>;
  /**
   * Recursively tries to find the provided JSON scalar value inside the field.
   * It does use an exact match when comparing values.
   * If you pass `null` as value the filter will be ignored.
   * Note: This filter fails if you try to look for a non scalar JSON value!
   */
  qabu_value_recursive?: InputMaybe<Scalars['Json']>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  startDate?: InputMaybe<Scalars['Date']>;
  /** All values greater than the given value. */
  startDate_gt?: InputMaybe<Scalars['Date']>;
  /** All values greater than or equal the given value. */
  startDate_gte?: InputMaybe<Scalars['Date']>;
  /** All values that are contained in given list. */
  startDate_in?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  /** All values less than the given value. */
  startDate_lt?: InputMaybe<Scalars['Date']>;
  /** All values less than or equal the given value. */
  startDate_lte?: InputMaybe<Scalars['Date']>;
  /** Any other value that exists and is not equal to the given value. */
  startDate_not?: InputMaybe<Scalars['Date']>;
  /** All values that are not contained in given list. */
  startDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  title?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type PartnerOfferWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<PartnerOfferWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<PartnerOfferWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<PartnerOfferWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<PartnerOfferWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References PartnerOffer record uniquely */
export type PartnerOfferWhereUniqueInput = {
  campaignId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
};

export enum PartnerOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export enum PartnerType {
  Affiliate = 'affiliate',
  TravelTrade = 'travel_trade',
}

export type PartnerUpdateInput = {
  excludedProducts?: InputMaybe<ProductUpdateManyInlineInput>;
  type?: InputMaybe<PartnerType>;
};

export type PartnerUpdateManyInlineInput = {
  /** Connect multiple existing Partner documents */
  connect?: InputMaybe<Array<PartnerConnectInput>>;
  /** Create and connect multiple Partner documents */
  create?: InputMaybe<Array<PartnerCreateInput>>;
  /** Delete multiple Partner documents */
  delete?: InputMaybe<Array<PartnerWhereUniqueInput>>;
  /** Disconnect multiple Partner documents */
  disconnect?: InputMaybe<Array<PartnerWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing Partner documents */
  set?: InputMaybe<Array<PartnerWhereUniqueInput>>;
  /** Update multiple Partner documents */
  update?: InputMaybe<Array<PartnerUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple Partner documents */
  upsert?: InputMaybe<Array<PartnerUpsertWithNestedWhereUniqueInput>>;
};

export type PartnerUpdateManyInput = {
  /** No fields in updateMany data input */
  _?: InputMaybe<Scalars['String']>;
};

export type PartnerUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: PartnerUpdateManyInput;
  /** Document search */
  where: PartnerWhereInput;
};

export type PartnerUpdateOneInlineInput = {
  /** Connect existing Partner document */
  connect?: InputMaybe<PartnerWhereUniqueInput>;
  /** Create and connect one Partner document */
  create?: InputMaybe<PartnerCreateInput>;
  /** Delete currently connected Partner document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected Partner document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single Partner document */
  update?: InputMaybe<PartnerUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Partner document */
  upsert?: InputMaybe<PartnerUpsertWithNestedWhereUniqueInput>;
};

export type PartnerUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: PartnerUpdateInput;
  /** Unique document search */
  where: PartnerWhereUniqueInput;
};

export type PartnerUpsertInput = {
  /** Create document if it didn't exist */
  create: PartnerCreateInput;
  /** Update document if it exists */
  update: PartnerUpdateInput;
};

export type PartnerUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: PartnerUpsertInput;
  /** Unique document search */
  where: PartnerWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type PartnerWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']>;
};

/** Identifies documents */
export type PartnerWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<PartnerWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<PartnerWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<PartnerWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<PartnerWhereStageInput>;
  documentInStages_none?: InputMaybe<PartnerWhereStageInput>;
  documentInStages_some?: InputMaybe<PartnerWhereStageInput>;
  excludedProducts_every?: InputMaybe<ProductWhereInput>;
  excludedProducts_none?: InputMaybe<ProductWhereInput>;
  excludedProducts_some?: InputMaybe<ProductWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  type?: InputMaybe<PartnerType>;
  /** All values that are contained in given list. */
  type_in?: InputMaybe<Array<InputMaybe<PartnerType>>>;
  /** Any other value that exists and is not equal to the given value. */
  type_not?: InputMaybe<PartnerType>;
  /** All values that are not contained in given list. */
  type_not_in?: InputMaybe<Array<InputMaybe<PartnerType>>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type PartnerWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<PartnerWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<PartnerWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<PartnerWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<PartnerWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References Partner record uniquely */
export type PartnerWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
  type?: InputMaybe<PartnerType>;
};

export enum Platforms {
  App = 'app',
  Deliverable = 'deliverable',
  Guide = 'guide',
  Native = 'native',
  Web = 'web',
}

export type Product = Node & {
  __typename?: 'Product';
  addOns: Array<AddOn>;
  addonAttractions: Array<Attraction>;
  admissionTitle: Maybe<Text>;
  ageAdult: Maybe<Scalars['String']>;
  ageChild: Maybe<Scalars['String']>;
  attractionLineups: Array<ProductAttractionLineup>;
  /** This is a list of all attractions that have ever been associated with this product in current or previous lineups. Use Attraction Lineups to define attraction order, grouping, etc */
  attractions: Array<Attraction>;
  bookingComId: Maybe<Scalars['String']>;
  cityName: Maybe<Text>;
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** User that created this document */
  createdBy: Maybe<User>;
  crossPromoImage: Maybe<Asset>;
  crowdriffAlbumId: Maybe<Scalars['String']>;
  description: Maybe<Text>;
  /** Get the document in other stages */
  documentInStages: Array<Product>;
  excludeDiscounts: Maybe<Scalars['Boolean']>;
  exclusiveAttraction: Array<Attraction>;
  exlcudedPartners: Array<Partner>;
  geolocation: Maybe<Location>;
  hasThingsToDo: Maybe<Scalars['Boolean']>;
  hasTopMuseums: Maybe<Scalars['Boolean']>;
  headline: Maybe<Text>;
  hero: Maybe<Asset>;
  hideReviews: Maybe<Scalars['Boolean']>;
  hideVideo: Maybe<Scalars['Boolean']>;
  /** List of Product versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID'];
  interchangeId: Maybe<Scalars['String']>;
  interfaceKey: Maybe<Scalars['String']>;
  key: Maybe<Scalars['String']>;
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<Product>;
  logo: Maybe<Asset>;
  /** STOP: if you don't know what SVG, stroke, and fill are then you probably should not be updating this! This SVG must define colors as attributes (no inline style) for stroke and/or fill for all shapes. Make sure you run svgo on it and use the existing SVG as an example. */
  logoSpot: Maybe<Asset>;
  /** STOP: if you don't know what SVG, stroke, and fill are then you probably should not be updating this! This SVG must use "currentColor" for stroke/fill on all shapes. Make sure you run svgo on it and use the existing SVG as an example. */
  logoSpotOutline: Maybe<Asset>;
  logoTicket: Maybe<Asset>;
  logoWhite: Maybe<Asset>;
  mapImage: Maybe<Asset>;
  menus: Array<Menu>;
  name: Maybe<Scalars['String']>;
  nativeCurrency: Maybe<Currency>;
  notices: Array<Notice>;
  partnerOffers: Array<PartnerOffer>;
  planning: Maybe<DetailBlock>;
  productActivityItem: Array<ProductActivityItem>;
  productImage: Maybe<Asset>;
  promos: Array<Promo>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt: Maybe<Scalars['DateTime']>;
  /** User that last published this document */
  publishedBy: Maybe<User>;
  qabu: Maybe<Scalars['Json']>;
  relatedProducts: Array<Product>;
  reservation: Maybe<ProductReservation>;
  reservationsRequired: Maybe<Scalars['Boolean']>;
  scheduledIn: Array<ScheduledOperation>;
  seo: Maybe<Seo>;
  slug: Scalars['String'];
  /** System stage field */
  stage: Stage;
  tagline: Maybe<Text>;
  text: Array<Text>;
  thingsToDo: Maybe<ProductActivity>;
  thumb: Maybe<Asset>;
  ticketGroups: Array<TicketGroup>;
  title: Maybe<Scalars['String']>;
  topMuseums: Maybe<ProductActivity>;
  type: Maybe<ProductType>;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** User that last updated this document */
  updatedBy: Maybe<User>;
  validDays: Maybe<Scalars['Int']>;
  video: Maybe<Video>;
};

export type ProductAddOnsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<AddOnOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AddOnWhereInput>;
};

export type ProductAddonAttractionsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<AttractionOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AttractionWhereInput>;
};

export type ProductAdmissionTitleArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type ProductAttractionLineupsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<ProductAttractionLineupOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ProductAttractionLineupWhereInput>;
};

export type ProductAttractionsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<AttractionOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AttractionWhereInput>;
};

export type ProductCityNameArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type ProductCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type ProductCreatedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type ProductCrossPromoImageArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type ProductDescriptionArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type ProductDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};

export type ProductExclusiveAttractionArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<AttractionOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AttractionWhereInput>;
};

export type ProductExlcudedPartnersArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<PartnerOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<PartnerWhereInput>;
};

export type ProductHeadlineArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type ProductHeroArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type ProductHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride: InputMaybe<Stage>;
};

export type ProductLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};

export type ProductLogoArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type ProductLogoSpotArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type ProductLogoSpotOutlineArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type ProductLogoTicketArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type ProductLogoWhiteArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type ProductMapImageArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type ProductMenusArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<MenuWhereInput>;
};

export type ProductNoticesArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<NoticeOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<NoticeWhereInput>;
};

export type ProductPartnerOffersArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<PartnerOfferOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<PartnerOfferWhereInput>;
};

export type ProductPlanningArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type ProductProductActivityItemArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<ProductActivityItemOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ProductActivityItemWhereInput>;
};

export type ProductProductImageArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type ProductPromosArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<PromoOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<PromoWhereInput>;
};

export type ProductPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type ProductPublishedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type ProductRelatedProductsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<ProductOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ProductWhereInput>;
};

export type ProductReservationArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type ProductScheduledInArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ScheduledOperationWhereInput>;
};

export type ProductSeoArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type ProductTaglineArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type ProductTextArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<TextOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<TextWhereInput>;
};

export type ProductThingsToDoArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type ProductThumbArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type ProductTicketGroupsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<TicketGroupOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<TicketGroupWhereInput>;
};

export type ProductTopMuseumsArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type ProductUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type ProductUpdatedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type ProductVideoArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type ProductActivity = Node & {
  __typename?: 'ProductActivity';
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** User that created this document */
  createdBy: Maybe<User>;
  /** Get the document in other stages */
  documentInStages: Array<ProductActivity>;
  /** List of ProductActivity versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID'];
  intro: Maybe<Scalars['String']>;
  items: Array<ProductActivityItem>;
  key: Scalars['String'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<ProductActivity>;
  outro: Maybe<Scalars['String']>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt: Maybe<Scalars['DateTime']>;
  /** User that last published this document */
  publishedBy: Maybe<User>;
  qabu: Maybe<Scalars['Json']>;
  scheduledIn: Array<ScheduledOperation>;
  /** System stage field */
  stage: Stage;
  thingsToDo: Maybe<Product>;
  title: Maybe<Text>;
  topMuseums: Maybe<Product>;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** User that last updated this document */
  updatedBy: Maybe<User>;
};

export type ProductActivityCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type ProductActivityCreatedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type ProductActivityDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};

export type ProductActivityHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride: InputMaybe<Stage>;
};

export type ProductActivityItemsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<ProductActivityItemOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ProductActivityItemWhereInput>;
};

export type ProductActivityLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};

export type ProductActivityPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type ProductActivityPublishedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type ProductActivityScheduledInArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ScheduledOperationWhereInput>;
};

export type ProductActivityThingsToDoArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type ProductActivityTitleArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type ProductActivityTopMuseumsArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type ProductActivityUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type ProductActivityUpdatedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type ProductActivityConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: ProductActivityWhereUniqueInput;
};

/** A connection to a list of items. */
export type ProductActivityConnection = {
  __typename?: 'ProductActivityConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<ProductActivityEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type ProductActivityCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** intro input for default locale (en) */
  intro?: InputMaybe<Scalars['String']>;
  items?: InputMaybe<ProductActivityItemCreateManyInlineInput>;
  key: Scalars['String'];
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<ProductActivityCreateLocalizationsInput>;
  /** outro input for default locale (en) */
  outro?: InputMaybe<Scalars['String']>;
  qabu?: InputMaybe<Scalars['Json']>;
  thingsToDo?: InputMaybe<ProductCreateOneInlineInput>;
  title?: InputMaybe<TextCreateOneInlineInput>;
  topMuseums?: InputMaybe<ProductCreateOneInlineInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ProductActivityCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  intro?: InputMaybe<Scalars['String']>;
  outro?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ProductActivityCreateLocalizationInput = {
  /** Localization input */
  data: ProductActivityCreateLocalizationDataInput;
  locale: Locale;
};

export type ProductActivityCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<ProductActivityCreateLocalizationInput>>;
};

export type ProductActivityCreateManyInlineInput = {
  /** Connect multiple existing ProductActivity documents */
  connect?: InputMaybe<Array<ProductActivityWhereUniqueInput>>;
  /** Create and connect multiple existing ProductActivity documents */
  create?: InputMaybe<Array<ProductActivityCreateInput>>;
};

export type ProductActivityCreateOneInlineInput = {
  /** Connect one existing ProductActivity document */
  connect?: InputMaybe<ProductActivityWhereUniqueInput>;
  /** Create and connect one ProductActivity document */
  create?: InputMaybe<ProductActivityCreateInput>;
};

/** An edge in a connection. */
export type ProductActivityEdge = {
  __typename?: 'ProductActivityEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: ProductActivity;
};

export type ProductActivityItem = Node & {
  __typename?: 'ProductActivityItem';
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** User that created this document */
  createdBy: Maybe<User>;
  description: Scalars['String'];
  /** Get the document in other stages */
  documentInStages: Array<ProductActivityItem>;
  /** List of ProductActivityItem versions */
  history: Array<Version>;
  hours: Maybe<Scalars['Int']>;
  /** The unique identifier */
  id: Scalars['ID'];
  image: Asset;
  intro: Scalars['String'];
  key: Scalars['String'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<ProductActivityItem>;
  productActivity: Array<ProductActivity>;
  products: Array<Product>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt: Maybe<Scalars['DateTime']>;
  /** User that last published this document */
  publishedBy: Maybe<User>;
  qabu: Maybe<Scalars['Json']>;
  scheduledIn: Array<ScheduledOperation>;
  /** System stage field */
  stage: Stage;
  title: Scalars['String'];
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** User that last updated this document */
  updatedBy: Maybe<User>;
};

export type ProductActivityItemCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type ProductActivityItemCreatedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type ProductActivityItemDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};

export type ProductActivityItemHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride: InputMaybe<Stage>;
};

export type ProductActivityItemImageArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type ProductActivityItemLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};

export type ProductActivityItemProductActivityArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<ProductActivityOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ProductActivityWhereInput>;
};

export type ProductActivityItemProductsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<ProductOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ProductWhereInput>;
};

export type ProductActivityItemPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type ProductActivityItemPublishedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type ProductActivityItemScheduledInArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ScheduledOperationWhereInput>;
};

export type ProductActivityItemUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type ProductActivityItemUpdatedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type ProductActivityItemConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: ProductActivityItemWhereUniqueInput;
};

/** A connection to a list of items. */
export type ProductActivityItemConnection = {
  __typename?: 'ProductActivityItemConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<ProductActivityItemEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type ProductActivityItemCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** description input for default locale (en) */
  description: Scalars['String'];
  hours?: InputMaybe<Scalars['Int']>;
  image: AssetCreateOneInlineInput;
  /** intro input for default locale (en) */
  intro: Scalars['String'];
  key: Scalars['String'];
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<ProductActivityItemCreateLocalizationsInput>;
  productActivity?: InputMaybe<ProductActivityCreateManyInlineInput>;
  products?: InputMaybe<ProductCreateManyInlineInput>;
  qabu?: InputMaybe<Scalars['Json']>;
  /** title input for default locale (en) */
  title: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ProductActivityItemCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description: Scalars['String'];
  intro: Scalars['String'];
  title: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ProductActivityItemCreateLocalizationInput = {
  /** Localization input */
  data: ProductActivityItemCreateLocalizationDataInput;
  locale: Locale;
};

export type ProductActivityItemCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<ProductActivityItemCreateLocalizationInput>>;
};

export type ProductActivityItemCreateManyInlineInput = {
  /** Connect multiple existing ProductActivityItem documents */
  connect?: InputMaybe<Array<ProductActivityItemWhereUniqueInput>>;
  /** Create and connect multiple existing ProductActivityItem documents */
  create?: InputMaybe<Array<ProductActivityItemCreateInput>>;
};

export type ProductActivityItemCreateOneInlineInput = {
  /** Connect one existing ProductActivityItem document */
  connect?: InputMaybe<ProductActivityItemWhereUniqueInput>;
  /** Create and connect one ProductActivityItem document */
  create?: InputMaybe<ProductActivityItemCreateInput>;
};

/** An edge in a connection. */
export type ProductActivityItemEdge = {
  __typename?: 'ProductActivityItemEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: ProductActivityItem;
};

/** Identifies documents */
export type ProductActivityItemManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ProductActivityItemWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ProductActivityItemWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ProductActivityItemWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<ProductActivityItemWhereStageInput>;
  documentInStages_none?: InputMaybe<ProductActivityItemWhereStageInput>;
  documentInStages_some?: InputMaybe<ProductActivityItemWhereStageInput>;
  hours?: InputMaybe<Scalars['Int']>;
  /** All values greater than the given value. */
  hours_gt?: InputMaybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  hours_gte?: InputMaybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  hours_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  /** All values less than the given value. */
  hours_lt?: InputMaybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  hours_lte?: InputMaybe<Scalars['Int']>;
  /** Any other value that exists and is not equal to the given value. */
  hours_not?: InputMaybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  hours_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  image?: InputMaybe<AssetWhereInput>;
  key?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  key_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  key_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  key_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  key_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  key_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  key_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  key_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  key_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  key_starts_with?: InputMaybe<Scalars['String']>;
  productActivity_every?: InputMaybe<ProductActivityWhereInput>;
  productActivity_none?: InputMaybe<ProductActivityWhereInput>;
  productActivity_some?: InputMaybe<ProductActivityWhereInput>;
  products_every?: InputMaybe<ProductWhereInput>;
  products_none?: InputMaybe<ProductWhereInput>;
  products_some?: InputMaybe<ProductWhereInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  /** All values containing the given json path. */
  qabu_json_path_exists?: InputMaybe<Scalars['String']>;
  /**
   * Recursively tries to find the provided JSON scalar value inside the field.
   * It does use an exact match when comparing values.
   * If you pass `null` as value the filter will be ignored.
   * Note: This filter fails if you try to look for a non scalar JSON value!
   */
  qabu_value_recursive?: InputMaybe<Scalars['Json']>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum ProductActivityItemOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  HoursAsc = 'hours_ASC',
  HoursDesc = 'hours_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  IntroAsc = 'intro_ASC',
  IntroDesc = 'intro_DESC',
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type ProductActivityItemUpdateInput = {
  /** description input for default locale (en) */
  description?: InputMaybe<Scalars['String']>;
  hours?: InputMaybe<Scalars['Int']>;
  image?: InputMaybe<AssetUpdateOneInlineInput>;
  /** intro input for default locale (en) */
  intro?: InputMaybe<Scalars['String']>;
  key?: InputMaybe<Scalars['String']>;
  /** Manage document localizations */
  localizations?: InputMaybe<ProductActivityItemUpdateLocalizationsInput>;
  productActivity?: InputMaybe<ProductActivityUpdateManyInlineInput>;
  products?: InputMaybe<ProductUpdateManyInlineInput>;
  qabu?: InputMaybe<Scalars['Json']>;
  /** title input for default locale (en) */
  title?: InputMaybe<Scalars['String']>;
};

export type ProductActivityItemUpdateLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']>;
  intro?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type ProductActivityItemUpdateLocalizationInput = {
  data: ProductActivityItemUpdateLocalizationDataInput;
  locale: Locale;
};

export type ProductActivityItemUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<ProductActivityItemCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<ProductActivityItemUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<ProductActivityItemUpsertLocalizationInput>>;
};

export type ProductActivityItemUpdateManyInlineInput = {
  /** Connect multiple existing ProductActivityItem documents */
  connect?: InputMaybe<Array<ProductActivityItemConnectInput>>;
  /** Create and connect multiple ProductActivityItem documents */
  create?: InputMaybe<Array<ProductActivityItemCreateInput>>;
  /** Delete multiple ProductActivityItem documents */
  delete?: InputMaybe<Array<ProductActivityItemWhereUniqueInput>>;
  /** Disconnect multiple ProductActivityItem documents */
  disconnect?: InputMaybe<Array<ProductActivityItemWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing ProductActivityItem documents */
  set?: InputMaybe<Array<ProductActivityItemWhereUniqueInput>>;
  /** Update multiple ProductActivityItem documents */
  update?: InputMaybe<Array<ProductActivityItemUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple ProductActivityItem documents */
  upsert?: InputMaybe<Array<ProductActivityItemUpsertWithNestedWhereUniqueInput>>;
};

export type ProductActivityItemUpdateManyInput = {
  /** description input for default locale (en) */
  description?: InputMaybe<Scalars['String']>;
  hours?: InputMaybe<Scalars['Int']>;
  /** intro input for default locale (en) */
  intro?: InputMaybe<Scalars['String']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<ProductActivityItemUpdateManyLocalizationsInput>;
  qabu?: InputMaybe<Scalars['Json']>;
  /** title input for default locale (en) */
  title?: InputMaybe<Scalars['String']>;
};

export type ProductActivityItemUpdateManyLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']>;
  intro?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type ProductActivityItemUpdateManyLocalizationInput = {
  data: ProductActivityItemUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type ProductActivityItemUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<ProductActivityItemUpdateManyLocalizationInput>>;
};

export type ProductActivityItemUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: ProductActivityItemUpdateManyInput;
  /** Document search */
  where: ProductActivityItemWhereInput;
};

export type ProductActivityItemUpdateOneInlineInput = {
  /** Connect existing ProductActivityItem document */
  connect?: InputMaybe<ProductActivityItemWhereUniqueInput>;
  /** Create and connect one ProductActivityItem document */
  create?: InputMaybe<ProductActivityItemCreateInput>;
  /** Delete currently connected ProductActivityItem document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected ProductActivityItem document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single ProductActivityItem document */
  update?: InputMaybe<ProductActivityItemUpdateWithNestedWhereUniqueInput>;
  /** Upsert single ProductActivityItem document */
  upsert?: InputMaybe<ProductActivityItemUpsertWithNestedWhereUniqueInput>;
};

export type ProductActivityItemUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: ProductActivityItemUpdateInput;
  /** Unique document search */
  where: ProductActivityItemWhereUniqueInput;
};

export type ProductActivityItemUpsertInput = {
  /** Create document if it didn't exist */
  create: ProductActivityItemCreateInput;
  /** Update document if it exists */
  update: ProductActivityItemUpdateInput;
};

export type ProductActivityItemUpsertLocalizationInput = {
  create: ProductActivityItemCreateLocalizationDataInput;
  locale: Locale;
  update: ProductActivityItemUpdateLocalizationDataInput;
};

export type ProductActivityItemUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: ProductActivityItemUpsertInput;
  /** Unique document search */
  where: ProductActivityItemWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type ProductActivityItemWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']>;
};

/** Identifies documents */
export type ProductActivityItemWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ProductActivityItemWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ProductActivityItemWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ProductActivityItemWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  description?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  description_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars['String']>;
  documentInStages_every?: InputMaybe<ProductActivityItemWhereStageInput>;
  documentInStages_none?: InputMaybe<ProductActivityItemWhereStageInput>;
  documentInStages_some?: InputMaybe<ProductActivityItemWhereStageInput>;
  hours?: InputMaybe<Scalars['Int']>;
  /** All values greater than the given value. */
  hours_gt?: InputMaybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  hours_gte?: InputMaybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  hours_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  /** All values less than the given value. */
  hours_lt?: InputMaybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  hours_lte?: InputMaybe<Scalars['Int']>;
  /** Any other value that exists and is not equal to the given value. */
  hours_not?: InputMaybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  hours_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  image?: InputMaybe<AssetWhereInput>;
  intro?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  intro_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  intro_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  intro_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  intro_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  intro_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  intro_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  intro_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  intro_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  intro_starts_with?: InputMaybe<Scalars['String']>;
  key?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  key_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  key_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  key_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  key_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  key_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  key_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  key_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  key_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  key_starts_with?: InputMaybe<Scalars['String']>;
  productActivity_every?: InputMaybe<ProductActivityWhereInput>;
  productActivity_none?: InputMaybe<ProductActivityWhereInput>;
  productActivity_some?: InputMaybe<ProductActivityWhereInput>;
  products_every?: InputMaybe<ProductWhereInput>;
  products_none?: InputMaybe<ProductWhereInput>;
  products_some?: InputMaybe<ProductWhereInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  /** All values containing the given json path. */
  qabu_json_path_exists?: InputMaybe<Scalars['String']>;
  /**
   * Recursively tries to find the provided JSON scalar value inside the field.
   * It does use an exact match when comparing values.
   * If you pass `null` as value the filter will be ignored.
   * Note: This filter fails if you try to look for a non scalar JSON value!
   */
  qabu_value_recursive?: InputMaybe<Scalars['Json']>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  title?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type ProductActivityItemWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ProductActivityItemWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ProductActivityItemWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ProductActivityItemWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<ProductActivityItemWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References ProductActivityItem record uniquely */
export type ProductActivityItemWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
  key?: InputMaybe<Scalars['String']>;
};

/** Identifies documents */
export type ProductActivityManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ProductActivityWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ProductActivityWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ProductActivityWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<ProductActivityWhereStageInput>;
  documentInStages_none?: InputMaybe<ProductActivityWhereStageInput>;
  documentInStages_some?: InputMaybe<ProductActivityWhereStageInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  items_every?: InputMaybe<ProductActivityItemWhereInput>;
  items_none?: InputMaybe<ProductActivityItemWhereInput>;
  items_some?: InputMaybe<ProductActivityItemWhereInput>;
  key?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  key_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  key_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  key_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  key_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  key_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  key_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  key_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  key_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  key_starts_with?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  /** All values containing the given json path. */
  qabu_json_path_exists?: InputMaybe<Scalars['String']>;
  /**
   * Recursively tries to find the provided JSON scalar value inside the field.
   * It does use an exact match when comparing values.
   * If you pass `null` as value the filter will be ignored.
   * Note: This filter fails if you try to look for a non scalar JSON value!
   */
  qabu_value_recursive?: InputMaybe<Scalars['Json']>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  thingsToDo?: InputMaybe<ProductWhereInput>;
  title?: InputMaybe<TextWhereInput>;
  topMuseums?: InputMaybe<ProductWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum ProductActivityOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  IntroAsc = 'intro_ASC',
  IntroDesc = 'intro_DESC',
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  OutroAsc = 'outro_ASC',
  OutroDesc = 'outro_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type ProductActivityUpdateInput = {
  /** intro input for default locale (en) */
  intro?: InputMaybe<Scalars['String']>;
  items?: InputMaybe<ProductActivityItemUpdateManyInlineInput>;
  key?: InputMaybe<Scalars['String']>;
  /** Manage document localizations */
  localizations?: InputMaybe<ProductActivityUpdateLocalizationsInput>;
  /** outro input for default locale (en) */
  outro?: InputMaybe<Scalars['String']>;
  qabu?: InputMaybe<Scalars['Json']>;
  thingsToDo?: InputMaybe<ProductUpdateOneInlineInput>;
  title?: InputMaybe<TextUpdateOneInlineInput>;
  topMuseums?: InputMaybe<ProductUpdateOneInlineInput>;
};

export type ProductActivityUpdateLocalizationDataInput = {
  intro?: InputMaybe<Scalars['String']>;
  outro?: InputMaybe<Scalars['String']>;
};

export type ProductActivityUpdateLocalizationInput = {
  data: ProductActivityUpdateLocalizationDataInput;
  locale: Locale;
};

export type ProductActivityUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<ProductActivityCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<ProductActivityUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<ProductActivityUpsertLocalizationInput>>;
};

export type ProductActivityUpdateManyInlineInput = {
  /** Connect multiple existing ProductActivity documents */
  connect?: InputMaybe<Array<ProductActivityConnectInput>>;
  /** Create and connect multiple ProductActivity documents */
  create?: InputMaybe<Array<ProductActivityCreateInput>>;
  /** Delete multiple ProductActivity documents */
  delete?: InputMaybe<Array<ProductActivityWhereUniqueInput>>;
  /** Disconnect multiple ProductActivity documents */
  disconnect?: InputMaybe<Array<ProductActivityWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing ProductActivity documents */
  set?: InputMaybe<Array<ProductActivityWhereUniqueInput>>;
  /** Update multiple ProductActivity documents */
  update?: InputMaybe<Array<ProductActivityUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple ProductActivity documents */
  upsert?: InputMaybe<Array<ProductActivityUpsertWithNestedWhereUniqueInput>>;
};

export type ProductActivityUpdateManyInput = {
  /** intro input for default locale (en) */
  intro?: InputMaybe<Scalars['String']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<ProductActivityUpdateManyLocalizationsInput>;
  /** outro input for default locale (en) */
  outro?: InputMaybe<Scalars['String']>;
  qabu?: InputMaybe<Scalars['Json']>;
};

export type ProductActivityUpdateManyLocalizationDataInput = {
  intro?: InputMaybe<Scalars['String']>;
  outro?: InputMaybe<Scalars['String']>;
};

export type ProductActivityUpdateManyLocalizationInput = {
  data: ProductActivityUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type ProductActivityUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<ProductActivityUpdateManyLocalizationInput>>;
};

export type ProductActivityUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: ProductActivityUpdateManyInput;
  /** Document search */
  where: ProductActivityWhereInput;
};

export type ProductActivityUpdateOneInlineInput = {
  /** Connect existing ProductActivity document */
  connect?: InputMaybe<ProductActivityWhereUniqueInput>;
  /** Create and connect one ProductActivity document */
  create?: InputMaybe<ProductActivityCreateInput>;
  /** Delete currently connected ProductActivity document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected ProductActivity document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single ProductActivity document */
  update?: InputMaybe<ProductActivityUpdateWithNestedWhereUniqueInput>;
  /** Upsert single ProductActivity document */
  upsert?: InputMaybe<ProductActivityUpsertWithNestedWhereUniqueInput>;
};

export type ProductActivityUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: ProductActivityUpdateInput;
  /** Unique document search */
  where: ProductActivityWhereUniqueInput;
};

export type ProductActivityUpsertInput = {
  /** Create document if it didn't exist */
  create: ProductActivityCreateInput;
  /** Update document if it exists */
  update: ProductActivityUpdateInput;
};

export type ProductActivityUpsertLocalizationInput = {
  create: ProductActivityCreateLocalizationDataInput;
  locale: Locale;
  update: ProductActivityUpdateLocalizationDataInput;
};

export type ProductActivityUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: ProductActivityUpsertInput;
  /** Unique document search */
  where: ProductActivityWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type ProductActivityWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']>;
};

/** Identifies documents */
export type ProductActivityWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ProductActivityWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ProductActivityWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ProductActivityWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<ProductActivityWhereStageInput>;
  documentInStages_none?: InputMaybe<ProductActivityWhereStageInput>;
  documentInStages_some?: InputMaybe<ProductActivityWhereStageInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  intro?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  intro_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  intro_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  intro_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  intro_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  intro_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  intro_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  intro_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  intro_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  intro_starts_with?: InputMaybe<Scalars['String']>;
  items_every?: InputMaybe<ProductActivityItemWhereInput>;
  items_none?: InputMaybe<ProductActivityItemWhereInput>;
  items_some?: InputMaybe<ProductActivityItemWhereInput>;
  key?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  key_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  key_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  key_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  key_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  key_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  key_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  key_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  key_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  key_starts_with?: InputMaybe<Scalars['String']>;
  outro?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  outro_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  outro_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  outro_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  outro_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  outro_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  outro_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  outro_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  outro_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  outro_starts_with?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  /** All values containing the given json path. */
  qabu_json_path_exists?: InputMaybe<Scalars['String']>;
  /**
   * Recursively tries to find the provided JSON scalar value inside the field.
   * It does use an exact match when comparing values.
   * If you pass `null` as value the filter will be ignored.
   * Note: This filter fails if you try to look for a non scalar JSON value!
   */
  qabu_value_recursive?: InputMaybe<Scalars['Json']>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  thingsToDo?: InputMaybe<ProductWhereInput>;
  title?: InputMaybe<TextWhereInput>;
  topMuseums?: InputMaybe<ProductWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type ProductActivityWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ProductActivityWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ProductActivityWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ProductActivityWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<ProductActivityWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References ProductActivity record uniquely */
export type ProductActivityWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
  key?: InputMaybe<Scalars['String']>;
};

export type ProductAttractionLineup = Node & {
  __typename?: 'ProductAttractionLineup';
  attractionGroups: Array<AttractionGroup>;
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** User that created this document */
  createdBy: Maybe<User>;
  /** Get the document in other stages */
  documentInStages: Array<ProductAttractionLineup>;
  /** List of ProductAttractionLineup versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID'];
  internalTitle: Maybe<Scalars['String']>;
  product: Maybe<Product>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt: Maybe<Scalars['DateTime']>;
  /** User that last published this document */
  publishedBy: Maybe<User>;
  scheduledIn: Array<ScheduledOperation>;
  shortDescription: Maybe<Text>;
  /** System stage field */
  stage: Stage;
  startDate: Scalars['Date'];
  subtitle: Maybe<Text>;
  ticketGroup: Maybe<TicketGroup>;
  title: Maybe<Text>;
  type: Maybe<ProductType>;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** User that last updated this document */
  updatedBy: Maybe<User>;
  year: Scalars['Int'];
};

export type ProductAttractionLineupAttractionGroupsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<AttractionGroupOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AttractionGroupWhereInput>;
};

export type ProductAttractionLineupCreatedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type ProductAttractionLineupDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};

export type ProductAttractionLineupHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride: InputMaybe<Stage>;
};

export type ProductAttractionLineupProductArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type ProductAttractionLineupPublishedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type ProductAttractionLineupScheduledInArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ScheduledOperationWhereInput>;
};

export type ProductAttractionLineupShortDescriptionArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type ProductAttractionLineupSubtitleArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type ProductAttractionLineupTicketGroupArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type ProductAttractionLineupTitleArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type ProductAttractionLineupUpdatedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type ProductAttractionLineupConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: ProductAttractionLineupWhereUniqueInput;
};

/** A connection to a list of items. */
export type ProductAttractionLineupConnection = {
  __typename?: 'ProductAttractionLineupConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<ProductAttractionLineupEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type ProductAttractionLineupCreateInput = {
  attractionGroups?: InputMaybe<AttractionGroupCreateManyInlineInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  internalTitle?: InputMaybe<Scalars['String']>;
  product?: InputMaybe<ProductCreateOneInlineInput>;
  shortDescription?: InputMaybe<TextCreateOneInlineInput>;
  startDate: Scalars['Date'];
  subtitle?: InputMaybe<TextCreateOneInlineInput>;
  ticketGroup?: InputMaybe<TicketGroupCreateOneInlineInput>;
  title?: InputMaybe<TextCreateOneInlineInput>;
  type?: InputMaybe<ProductType>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  year: Scalars['Int'];
};

export type ProductAttractionLineupCreateManyInlineInput = {
  /** Connect multiple existing ProductAttractionLineup documents */
  connect?: InputMaybe<Array<ProductAttractionLineupWhereUniqueInput>>;
  /** Create and connect multiple existing ProductAttractionLineup documents */
  create?: InputMaybe<Array<ProductAttractionLineupCreateInput>>;
};

export type ProductAttractionLineupCreateOneInlineInput = {
  /** Connect one existing ProductAttractionLineup document */
  connect?: InputMaybe<ProductAttractionLineupWhereUniqueInput>;
  /** Create and connect one ProductAttractionLineup document */
  create?: InputMaybe<ProductAttractionLineupCreateInput>;
};

/** An edge in a connection. */
export type ProductAttractionLineupEdge = {
  __typename?: 'ProductAttractionLineupEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: ProductAttractionLineup;
};

/** Identifies documents */
export type ProductAttractionLineupManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ProductAttractionLineupWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ProductAttractionLineupWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ProductAttractionLineupWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  attractionGroups_every?: InputMaybe<AttractionGroupWhereInput>;
  attractionGroups_none?: InputMaybe<AttractionGroupWhereInput>;
  attractionGroups_some?: InputMaybe<AttractionGroupWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<ProductAttractionLineupWhereStageInput>;
  documentInStages_none?: InputMaybe<ProductAttractionLineupWhereStageInput>;
  documentInStages_some?: InputMaybe<ProductAttractionLineupWhereStageInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  internalTitle?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  internalTitle_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  internalTitle_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  internalTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  internalTitle_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  internalTitle_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  internalTitle_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  internalTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  internalTitle_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  internalTitle_starts_with?: InputMaybe<Scalars['String']>;
  product?: InputMaybe<ProductWhereInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  shortDescription?: InputMaybe<TextWhereInput>;
  startDate?: InputMaybe<Scalars['Date']>;
  /** All values greater than the given value. */
  startDate_gt?: InputMaybe<Scalars['Date']>;
  /** All values greater than or equal the given value. */
  startDate_gte?: InputMaybe<Scalars['Date']>;
  /** All values that are contained in given list. */
  startDate_in?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  /** All values less than the given value. */
  startDate_lt?: InputMaybe<Scalars['Date']>;
  /** All values less than or equal the given value. */
  startDate_lte?: InputMaybe<Scalars['Date']>;
  /** Any other value that exists and is not equal to the given value. */
  startDate_not?: InputMaybe<Scalars['Date']>;
  /** All values that are not contained in given list. */
  startDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  subtitle?: InputMaybe<TextWhereInput>;
  ticketGroup?: InputMaybe<TicketGroupWhereInput>;
  title?: InputMaybe<TextWhereInput>;
  type?: InputMaybe<ProductType>;
  /** All values that are contained in given list. */
  type_in?: InputMaybe<Array<InputMaybe<ProductType>>>;
  /** Any other value that exists and is not equal to the given value. */
  type_not?: InputMaybe<ProductType>;
  /** All values that are not contained in given list. */
  type_not_in?: InputMaybe<Array<InputMaybe<ProductType>>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
  year?: InputMaybe<Scalars['Int']>;
  /** All values greater than the given value. */
  year_gt?: InputMaybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  year_gte?: InputMaybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  year_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  /** All values less than the given value. */
  year_lt?: InputMaybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  year_lte?: InputMaybe<Scalars['Int']>;
  /** Any other value that exists and is not equal to the given value. */
  year_not?: InputMaybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  year_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export enum ProductAttractionLineupOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  InternalTitleAsc = 'internalTitle_ASC',
  InternalTitleDesc = 'internalTitle_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  StartDateAsc = 'startDate_ASC',
  StartDateDesc = 'startDate_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  YearAsc = 'year_ASC',
  YearDesc = 'year_DESC',
}

export type ProductAttractionLineupUpdateInput = {
  attractionGroups?: InputMaybe<AttractionGroupUpdateManyInlineInput>;
  internalTitle?: InputMaybe<Scalars['String']>;
  product?: InputMaybe<ProductUpdateOneInlineInput>;
  shortDescription?: InputMaybe<TextUpdateOneInlineInput>;
  startDate?: InputMaybe<Scalars['Date']>;
  subtitle?: InputMaybe<TextUpdateOneInlineInput>;
  ticketGroup?: InputMaybe<TicketGroupUpdateOneInlineInput>;
  title?: InputMaybe<TextUpdateOneInlineInput>;
  type?: InputMaybe<ProductType>;
  year?: InputMaybe<Scalars['Int']>;
};

export type ProductAttractionLineupUpdateManyInlineInput = {
  /** Connect multiple existing ProductAttractionLineup documents */
  connect?: InputMaybe<Array<ProductAttractionLineupConnectInput>>;
  /** Create and connect multiple ProductAttractionLineup documents */
  create?: InputMaybe<Array<ProductAttractionLineupCreateInput>>;
  /** Delete multiple ProductAttractionLineup documents */
  delete?: InputMaybe<Array<ProductAttractionLineupWhereUniqueInput>>;
  /** Disconnect multiple ProductAttractionLineup documents */
  disconnect?: InputMaybe<Array<ProductAttractionLineupWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing ProductAttractionLineup documents */
  set?: InputMaybe<Array<ProductAttractionLineupWhereUniqueInput>>;
  /** Update multiple ProductAttractionLineup documents */
  update?: InputMaybe<Array<ProductAttractionLineupUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple ProductAttractionLineup documents */
  upsert?: InputMaybe<Array<ProductAttractionLineupUpsertWithNestedWhereUniqueInput>>;
};

export type ProductAttractionLineupUpdateManyInput = {
  internalTitle?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['Date']>;
  type?: InputMaybe<ProductType>;
  year?: InputMaybe<Scalars['Int']>;
};

export type ProductAttractionLineupUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: ProductAttractionLineupUpdateManyInput;
  /** Document search */
  where: ProductAttractionLineupWhereInput;
};

export type ProductAttractionLineupUpdateOneInlineInput = {
  /** Connect existing ProductAttractionLineup document */
  connect?: InputMaybe<ProductAttractionLineupWhereUniqueInput>;
  /** Create and connect one ProductAttractionLineup document */
  create?: InputMaybe<ProductAttractionLineupCreateInput>;
  /** Delete currently connected ProductAttractionLineup document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected ProductAttractionLineup document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single ProductAttractionLineup document */
  update?: InputMaybe<ProductAttractionLineupUpdateWithNestedWhereUniqueInput>;
  /** Upsert single ProductAttractionLineup document */
  upsert?: InputMaybe<ProductAttractionLineupUpsertWithNestedWhereUniqueInput>;
};

export type ProductAttractionLineupUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: ProductAttractionLineupUpdateInput;
  /** Unique document search */
  where: ProductAttractionLineupWhereUniqueInput;
};

export type ProductAttractionLineupUpsertInput = {
  /** Create document if it didn't exist */
  create: ProductAttractionLineupCreateInput;
  /** Update document if it exists */
  update: ProductAttractionLineupUpdateInput;
};

export type ProductAttractionLineupUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: ProductAttractionLineupUpsertInput;
  /** Unique document search */
  where: ProductAttractionLineupWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type ProductAttractionLineupWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']>;
};

/** Identifies documents */
export type ProductAttractionLineupWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ProductAttractionLineupWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ProductAttractionLineupWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ProductAttractionLineupWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  attractionGroups_every?: InputMaybe<AttractionGroupWhereInput>;
  attractionGroups_none?: InputMaybe<AttractionGroupWhereInput>;
  attractionGroups_some?: InputMaybe<AttractionGroupWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<ProductAttractionLineupWhereStageInput>;
  documentInStages_none?: InputMaybe<ProductAttractionLineupWhereStageInput>;
  documentInStages_some?: InputMaybe<ProductAttractionLineupWhereStageInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  internalTitle?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  internalTitle_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  internalTitle_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  internalTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  internalTitle_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  internalTitle_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  internalTitle_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  internalTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  internalTitle_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  internalTitle_starts_with?: InputMaybe<Scalars['String']>;
  product?: InputMaybe<ProductWhereInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  shortDescription?: InputMaybe<TextWhereInput>;
  startDate?: InputMaybe<Scalars['Date']>;
  /** All values greater than the given value. */
  startDate_gt?: InputMaybe<Scalars['Date']>;
  /** All values greater than or equal the given value. */
  startDate_gte?: InputMaybe<Scalars['Date']>;
  /** All values that are contained in given list. */
  startDate_in?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  /** All values less than the given value. */
  startDate_lt?: InputMaybe<Scalars['Date']>;
  /** All values less than or equal the given value. */
  startDate_lte?: InputMaybe<Scalars['Date']>;
  /** Any other value that exists and is not equal to the given value. */
  startDate_not?: InputMaybe<Scalars['Date']>;
  /** All values that are not contained in given list. */
  startDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  subtitle?: InputMaybe<TextWhereInput>;
  ticketGroup?: InputMaybe<TicketGroupWhereInput>;
  title?: InputMaybe<TextWhereInput>;
  type?: InputMaybe<ProductType>;
  /** All values that are contained in given list. */
  type_in?: InputMaybe<Array<InputMaybe<ProductType>>>;
  /** Any other value that exists and is not equal to the given value. */
  type_not?: InputMaybe<ProductType>;
  /** All values that are not contained in given list. */
  type_not_in?: InputMaybe<Array<InputMaybe<ProductType>>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
  year?: InputMaybe<Scalars['Int']>;
  /** All values greater than the given value. */
  year_gt?: InputMaybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  year_gte?: InputMaybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  year_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  /** All values less than the given value. */
  year_lt?: InputMaybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  year_lte?: InputMaybe<Scalars['Int']>;
  /** Any other value that exists and is not equal to the given value. */
  year_not?: InputMaybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  year_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type ProductAttractionLineupWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ProductAttractionLineupWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ProductAttractionLineupWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ProductAttractionLineupWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<ProductAttractionLineupWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References ProductAttractionLineup record uniquely */
export type ProductAttractionLineupWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type ProductConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: ProductWhereUniqueInput;
};

/** A connection to a list of items. */
export type ProductConnection = {
  __typename?: 'ProductConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<ProductEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type ProductCreateInput = {
  addOns?: InputMaybe<AddOnCreateManyInlineInput>;
  addonAttractions?: InputMaybe<AttractionCreateManyInlineInput>;
  admissionTitle?: InputMaybe<TextCreateOneInlineInput>;
  ageAdult?: InputMaybe<Scalars['String']>;
  ageChild?: InputMaybe<Scalars['String']>;
  attractionLineups?: InputMaybe<ProductAttractionLineupCreateManyInlineInput>;
  attractions?: InputMaybe<AttractionCreateManyInlineInput>;
  bookingComId?: InputMaybe<Scalars['String']>;
  cityName?: InputMaybe<TextCreateOneInlineInput>;
  ckwnyqpfq072z01xr98sl7iwx?: InputMaybe<ArticleCategoryCreateManyInlineInput>;
  ckyb4vjce11hc01z2a4b0d1gt?: InputMaybe<AttractionVariantCreateManyInlineInput>;
  ckzgmi3kf1l4201xsbab36297?: InputMaybe<AttractionOfferCreateManyInlineInput>;
  cl2goe8km3a9y01w05yet0gss?: InputMaybe<ProductCreateManyInlineInput>;
  cl2t5ktyl3n7b01yx9o8rdruh?: InputMaybe<ReservationTextCreateManyInlineInput>;
  cl7kvbw1y0w8g01t74iib4v5z?: InputMaybe<PromoBannerCreateManyInlineInput>;
  cle38hrnb0fy001ufdp4a5ewj?: InputMaybe<CrossPromoBannerCreateManyInlineInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  crossPromoImage?: InputMaybe<AssetCreateOneInlineInput>;
  crowdriffAlbumId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<TextCreateOneInlineInput>;
  excludeDiscounts?: InputMaybe<Scalars['Boolean']>;
  exclusiveAttraction?: InputMaybe<AttractionCreateManyInlineInput>;
  exlcudedPartners?: InputMaybe<PartnerCreateManyInlineInput>;
  geolocation?: InputMaybe<LocationInput>;
  hasThingsToDo?: InputMaybe<Scalars['Boolean']>;
  hasTopMuseums?: InputMaybe<Scalars['Boolean']>;
  headline?: InputMaybe<TextCreateOneInlineInput>;
  hero?: InputMaybe<AssetCreateOneInlineInput>;
  hideReviews?: InputMaybe<Scalars['Boolean']>;
  hideVideo?: InputMaybe<Scalars['Boolean']>;
  interchangeId?: InputMaybe<Scalars['String']>;
  interfaceKey?: InputMaybe<Scalars['String']>;
  key?: InputMaybe<Scalars['String']>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<ProductCreateLocalizationsInput>;
  logo?: InputMaybe<AssetCreateOneInlineInput>;
  logoSpot?: InputMaybe<AssetCreateOneInlineInput>;
  logoSpotOutline?: InputMaybe<AssetCreateOneInlineInput>;
  logoTicket?: InputMaybe<AssetCreateOneInlineInput>;
  logoWhite?: InputMaybe<AssetCreateOneInlineInput>;
  mapImage?: InputMaybe<AssetCreateOneInlineInput>;
  menus?: InputMaybe<MenuCreateManyInlineInput>;
  name?: InputMaybe<Scalars['String']>;
  nativeCurrency?: InputMaybe<Currency>;
  notices?: InputMaybe<NoticeCreateManyInlineInput>;
  partnerOffers?: InputMaybe<PartnerOfferCreateManyInlineInput>;
  planning?: InputMaybe<DetailBlockCreateOneInlineInput>;
  productActivityItem?: InputMaybe<ProductActivityItemCreateManyInlineInput>;
  productImage?: InputMaybe<AssetCreateOneInlineInput>;
  promos?: InputMaybe<PromoCreateManyInlineInput>;
  qabu?: InputMaybe<Scalars['Json']>;
  relatedProducts?: InputMaybe<ProductCreateManyInlineInput>;
  reservation?: InputMaybe<ProductReservationCreateOneInlineInput>;
  reservationsRequired?: InputMaybe<Scalars['Boolean']>;
  seo?: InputMaybe<SeoCreateOneInlineInput>;
  slug: Scalars['String'];
  tagline?: InputMaybe<TextCreateOneInlineInput>;
  text?: InputMaybe<TextCreateManyInlineInput>;
  thingsToDo?: InputMaybe<ProductActivityCreateOneInlineInput>;
  thumb?: InputMaybe<AssetCreateOneInlineInput>;
  ticketGroups?: InputMaybe<TicketGroupCreateManyInlineInput>;
  /** title input for default locale (en) */
  title?: InputMaybe<Scalars['String']>;
  topMuseums?: InputMaybe<ProductActivityCreateOneInlineInput>;
  type?: InputMaybe<ProductType>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  validDays?: InputMaybe<Scalars['Int']>;
  video?: InputMaybe<VideoCreateOneInlineInput>;
};

export type ProductCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ProductCreateLocalizationInput = {
  /** Localization input */
  data: ProductCreateLocalizationDataInput;
  locale: Locale;
};

export type ProductCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<ProductCreateLocalizationInput>>;
};

export type ProductCreateManyInlineInput = {
  /** Connect multiple existing Product documents */
  connect?: InputMaybe<Array<ProductWhereUniqueInput>>;
  /** Create and connect multiple existing Product documents */
  create?: InputMaybe<Array<ProductCreateInput>>;
};

export type ProductCreateOneInlineInput = {
  /** Connect one existing Product document */
  connect?: InputMaybe<ProductWhereUniqueInput>;
  /** Create and connect one Product document */
  create?: InputMaybe<ProductCreateInput>;
};

/** An edge in a connection. */
export type ProductEdge = {
  __typename?: 'ProductEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Product;
};

/** Identifies documents */
export type ProductManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ProductWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ProductWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ProductWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  addOns_every?: InputMaybe<AddOnWhereInput>;
  addOns_none?: InputMaybe<AddOnWhereInput>;
  addOns_some?: InputMaybe<AddOnWhereInput>;
  addonAttractions_every?: InputMaybe<AttractionWhereInput>;
  addonAttractions_none?: InputMaybe<AttractionWhereInput>;
  addonAttractions_some?: InputMaybe<AttractionWhereInput>;
  admissionTitle?: InputMaybe<TextWhereInput>;
  ageAdult?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  ageAdult_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  ageAdult_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  ageAdult_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  ageAdult_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  ageAdult_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  ageAdult_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  ageAdult_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  ageAdult_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  ageAdult_starts_with?: InputMaybe<Scalars['String']>;
  ageChild?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  ageChild_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  ageChild_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  ageChild_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  ageChild_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  ageChild_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  ageChild_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  ageChild_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  ageChild_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  ageChild_starts_with?: InputMaybe<Scalars['String']>;
  attractionLineups_every?: InputMaybe<ProductAttractionLineupWhereInput>;
  attractionLineups_none?: InputMaybe<ProductAttractionLineupWhereInput>;
  attractionLineups_some?: InputMaybe<ProductAttractionLineupWhereInput>;
  attractions_every?: InputMaybe<AttractionWhereInput>;
  attractions_none?: InputMaybe<AttractionWhereInput>;
  attractions_some?: InputMaybe<AttractionWhereInput>;
  bookingComId?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  bookingComId_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  bookingComId_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  bookingComId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  bookingComId_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  bookingComId_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  bookingComId_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  bookingComId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  bookingComId_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  bookingComId_starts_with?: InputMaybe<Scalars['String']>;
  cityName?: InputMaybe<TextWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  crossPromoImage?: InputMaybe<AssetWhereInput>;
  crowdriffAlbumId?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  crowdriffAlbumId_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  crowdriffAlbumId_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  crowdriffAlbumId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  crowdriffAlbumId_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  crowdriffAlbumId_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  crowdriffAlbumId_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  crowdriffAlbumId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  crowdriffAlbumId_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  crowdriffAlbumId_starts_with?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<TextWhereInput>;
  documentInStages_every?: InputMaybe<ProductWhereStageInput>;
  documentInStages_none?: InputMaybe<ProductWhereStageInput>;
  documentInStages_some?: InputMaybe<ProductWhereStageInput>;
  excludeDiscounts?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  excludeDiscounts_not?: InputMaybe<Scalars['Boolean']>;
  exclusiveAttraction_every?: InputMaybe<AttractionWhereInput>;
  exclusiveAttraction_none?: InputMaybe<AttractionWhereInput>;
  exclusiveAttraction_some?: InputMaybe<AttractionWhereInput>;
  exlcudedPartners_every?: InputMaybe<PartnerWhereInput>;
  exlcudedPartners_none?: InputMaybe<PartnerWhereInput>;
  exlcudedPartners_some?: InputMaybe<PartnerWhereInput>;
  hasThingsToDo?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  hasThingsToDo_not?: InputMaybe<Scalars['Boolean']>;
  hasTopMuseums?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  hasTopMuseums_not?: InputMaybe<Scalars['Boolean']>;
  headline?: InputMaybe<TextWhereInput>;
  hero?: InputMaybe<AssetWhereInput>;
  hideReviews?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  hideReviews_not?: InputMaybe<Scalars['Boolean']>;
  hideVideo?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  hideVideo_not?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  interchangeId?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  interchangeId_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  interchangeId_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  interchangeId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  interchangeId_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  interchangeId_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  interchangeId_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  interchangeId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  interchangeId_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  interchangeId_starts_with?: InputMaybe<Scalars['String']>;
  interfaceKey?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  interfaceKey_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  interfaceKey_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  interfaceKey_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  interfaceKey_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  interfaceKey_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  interfaceKey_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  interfaceKey_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  interfaceKey_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  interfaceKey_starts_with?: InputMaybe<Scalars['String']>;
  key?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  key_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  key_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  key_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  key_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  key_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  key_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  key_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  key_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  key_starts_with?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<AssetWhereInput>;
  logoSpot?: InputMaybe<AssetWhereInput>;
  logoSpotOutline?: InputMaybe<AssetWhereInput>;
  logoTicket?: InputMaybe<AssetWhereInput>;
  logoWhite?: InputMaybe<AssetWhereInput>;
  mapImage?: InputMaybe<AssetWhereInput>;
  menus_every?: InputMaybe<MenuWhereInput>;
  menus_none?: InputMaybe<MenuWhereInput>;
  menus_some?: InputMaybe<MenuWhereInput>;
  name?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']>;
  nativeCurrency?: InputMaybe<Currency>;
  /** All values that are contained in given list. */
  nativeCurrency_in?: InputMaybe<Array<InputMaybe<Currency>>>;
  /** Any other value that exists and is not equal to the given value. */
  nativeCurrency_not?: InputMaybe<Currency>;
  /** All values that are not contained in given list. */
  nativeCurrency_not_in?: InputMaybe<Array<InputMaybe<Currency>>>;
  notices_every?: InputMaybe<NoticeWhereInput>;
  notices_none?: InputMaybe<NoticeWhereInput>;
  notices_some?: InputMaybe<NoticeWhereInput>;
  partnerOffers_every?: InputMaybe<PartnerOfferWhereInput>;
  partnerOffers_none?: InputMaybe<PartnerOfferWhereInput>;
  partnerOffers_some?: InputMaybe<PartnerOfferWhereInput>;
  planning?: InputMaybe<DetailBlockWhereInput>;
  productActivityItem_every?: InputMaybe<ProductActivityItemWhereInput>;
  productActivityItem_none?: InputMaybe<ProductActivityItemWhereInput>;
  productActivityItem_some?: InputMaybe<ProductActivityItemWhereInput>;
  productImage?: InputMaybe<AssetWhereInput>;
  promos_every?: InputMaybe<PromoWhereInput>;
  promos_none?: InputMaybe<PromoWhereInput>;
  promos_some?: InputMaybe<PromoWhereInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  /** All values containing the given json path. */
  qabu_json_path_exists?: InputMaybe<Scalars['String']>;
  /**
   * Recursively tries to find the provided JSON scalar value inside the field.
   * It does use an exact match when comparing values.
   * If you pass `null` as value the filter will be ignored.
   * Note: This filter fails if you try to look for a non scalar JSON value!
   */
  qabu_value_recursive?: InputMaybe<Scalars['Json']>;
  relatedProducts_every?: InputMaybe<ProductWhereInput>;
  relatedProducts_none?: InputMaybe<ProductWhereInput>;
  relatedProducts_some?: InputMaybe<ProductWhereInput>;
  reservation?: InputMaybe<ProductReservationWhereInput>;
  reservationsRequired?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  reservationsRequired_not?: InputMaybe<Scalars['Boolean']>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  seo?: InputMaybe<SeoWhereInput>;
  slug?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  slug_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  slug_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  slug_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  slug_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  slug_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  slug_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  slug_starts_with?: InputMaybe<Scalars['String']>;
  tagline?: InputMaybe<TextWhereInput>;
  text_every?: InputMaybe<TextWhereInput>;
  text_none?: InputMaybe<TextWhereInput>;
  text_some?: InputMaybe<TextWhereInput>;
  thingsToDo?: InputMaybe<ProductActivityWhereInput>;
  thumb?: InputMaybe<AssetWhereInput>;
  ticketGroups_every?: InputMaybe<TicketGroupWhereInput>;
  ticketGroups_none?: InputMaybe<TicketGroupWhereInput>;
  ticketGroups_some?: InputMaybe<TicketGroupWhereInput>;
  topMuseums?: InputMaybe<ProductActivityWhereInput>;
  type?: InputMaybe<ProductType>;
  /** All values that are contained in given list. */
  type_in?: InputMaybe<Array<InputMaybe<ProductType>>>;
  /** Any other value that exists and is not equal to the given value. */
  type_not?: InputMaybe<ProductType>;
  /** All values that are not contained in given list. */
  type_not_in?: InputMaybe<Array<InputMaybe<ProductType>>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
  validDays?: InputMaybe<Scalars['Int']>;
  /** All values greater than the given value. */
  validDays_gt?: InputMaybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  validDays_gte?: InputMaybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  validDays_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  /** All values less than the given value. */
  validDays_lt?: InputMaybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  validDays_lte?: InputMaybe<Scalars['Int']>;
  /** Any other value that exists and is not equal to the given value. */
  validDays_not?: InputMaybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  validDays_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  video?: InputMaybe<VideoWhereInput>;
};

export enum ProductOrderByInput {
  AgeAdultAsc = 'ageAdult_ASC',
  AgeAdultDesc = 'ageAdult_DESC',
  AgeChildAsc = 'ageChild_ASC',
  AgeChildDesc = 'ageChild_DESC',
  BookingComIdAsc = 'bookingComId_ASC',
  BookingComIdDesc = 'bookingComId_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  CrowdriffAlbumIdAsc = 'crowdriffAlbumId_ASC',
  CrowdriffAlbumIdDesc = 'crowdriffAlbumId_DESC',
  ExcludeDiscountsAsc = 'excludeDiscounts_ASC',
  ExcludeDiscountsDesc = 'excludeDiscounts_DESC',
  HasThingsToDoAsc = 'hasThingsToDo_ASC',
  HasThingsToDoDesc = 'hasThingsToDo_DESC',
  HasTopMuseumsAsc = 'hasTopMuseums_ASC',
  HasTopMuseumsDesc = 'hasTopMuseums_DESC',
  HideReviewsAsc = 'hideReviews_ASC',
  HideReviewsDesc = 'hideReviews_DESC',
  HideVideoAsc = 'hideVideo_ASC',
  HideVideoDesc = 'hideVideo_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  InterchangeIdAsc = 'interchangeId_ASC',
  InterchangeIdDesc = 'interchangeId_DESC',
  InterfaceKeyAsc = 'interfaceKey_ASC',
  InterfaceKeyDesc = 'interfaceKey_DESC',
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  NativeCurrencyAsc = 'nativeCurrency_ASC',
  NativeCurrencyDesc = 'nativeCurrency_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  ReservationsRequiredAsc = 'reservationsRequired_ASC',
  ReservationsRequiredDesc = 'reservationsRequired_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  ValidDaysAsc = 'validDays_ASC',
  ValidDaysDesc = 'validDays_DESC',
}

export type ProductReservation = Node & {
  __typename?: 'ProductReservation';
  attractionSorting: Array<Attraction>;
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** User that created this document */
  createdBy: Maybe<User>;
  /** Get the document in other stages */
  documentInStages: Array<ProductReservation>;
  /** List of ProductReservation versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID'];
  internalTitle: Maybe<Scalars['String']>;
  product: Maybe<Product>;
  productCodes: Maybe<Scalars['Json']>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt: Maybe<Scalars['DateTime']>;
  /** User that last published this document */
  publishedBy: Maybe<User>;
  scheduledIn: Array<ScheduledOperation>;
  showReservationHelp: Maybe<Scalars['Boolean']>;
  sorting: Maybe<Scalars['String']>;
  /** System stage field */
  stage: Stage;
  text: Array<ReservationText>;
  texts: Array<Text>;
  ticketsBackground: Maybe<Asset>;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** User that last updated this document */
  updatedBy: Maybe<User>;
};

export type ProductReservationAttractionSortingArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<AttractionOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AttractionWhereInput>;
};

export type ProductReservationCreatedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type ProductReservationDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};

export type ProductReservationHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride: InputMaybe<Stage>;
};

export type ProductReservationProductArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type ProductReservationPublishedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type ProductReservationScheduledInArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ScheduledOperationWhereInput>;
};

export type ProductReservationTextArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<ReservationTextOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ReservationTextWhereInput>;
};

export type ProductReservationTextsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<TextOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<TextWhereInput>;
};

export type ProductReservationTicketsBackgroundArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type ProductReservationUpdatedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type ProductReservationConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: ProductReservationWhereUniqueInput;
};

/** A connection to a list of items. */
export type ProductReservationConnection = {
  __typename?: 'ProductReservationConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<ProductReservationEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type ProductReservationCreateInput = {
  attractionSorting?: InputMaybe<AttractionCreateManyInlineInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  internalTitle?: InputMaybe<Scalars['String']>;
  product?: InputMaybe<ProductCreateOneInlineInput>;
  productCodes?: InputMaybe<Scalars['Json']>;
  showReservationHelp?: InputMaybe<Scalars['Boolean']>;
  sorting?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<ReservationTextCreateManyInlineInput>;
  texts?: InputMaybe<TextCreateManyInlineInput>;
  ticketsBackground?: InputMaybe<AssetCreateOneInlineInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ProductReservationCreateManyInlineInput = {
  /** Connect multiple existing ProductReservation documents */
  connect?: InputMaybe<Array<ProductReservationWhereUniqueInput>>;
  /** Create and connect multiple existing ProductReservation documents */
  create?: InputMaybe<Array<ProductReservationCreateInput>>;
};

export type ProductReservationCreateOneInlineInput = {
  /** Connect one existing ProductReservation document */
  connect?: InputMaybe<ProductReservationWhereUniqueInput>;
  /** Create and connect one ProductReservation document */
  create?: InputMaybe<ProductReservationCreateInput>;
};

/** An edge in a connection. */
export type ProductReservationEdge = {
  __typename?: 'ProductReservationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: ProductReservation;
};

/** Identifies documents */
export type ProductReservationManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ProductReservationWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ProductReservationWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ProductReservationWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  attractionSorting_every?: InputMaybe<AttractionWhereInput>;
  attractionSorting_none?: InputMaybe<AttractionWhereInput>;
  attractionSorting_some?: InputMaybe<AttractionWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<ProductReservationWhereStageInput>;
  documentInStages_none?: InputMaybe<ProductReservationWhereStageInput>;
  documentInStages_some?: InputMaybe<ProductReservationWhereStageInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  internalTitle?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  internalTitle_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  internalTitle_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  internalTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  internalTitle_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  internalTitle_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  internalTitle_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  internalTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  internalTitle_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  internalTitle_starts_with?: InputMaybe<Scalars['String']>;
  product?: InputMaybe<ProductWhereInput>;
  /** All values containing the given json path. */
  productCodes_json_path_exists?: InputMaybe<Scalars['String']>;
  /**
   * Recursively tries to find the provided JSON scalar value inside the field.
   * It does use an exact match when comparing values.
   * If you pass `null` as value the filter will be ignored.
   * Note: This filter fails if you try to look for a non scalar JSON value!
   */
  productCodes_value_recursive?: InputMaybe<Scalars['Json']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  showReservationHelp?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  showReservationHelp_not?: InputMaybe<Scalars['Boolean']>;
  sorting?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  sorting_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  sorting_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  sorting_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  sorting_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  sorting_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  sorting_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  sorting_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  sorting_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  sorting_starts_with?: InputMaybe<Scalars['String']>;
  text_every?: InputMaybe<ReservationTextWhereInput>;
  text_none?: InputMaybe<ReservationTextWhereInput>;
  text_some?: InputMaybe<ReservationTextWhereInput>;
  texts_every?: InputMaybe<TextWhereInput>;
  texts_none?: InputMaybe<TextWhereInput>;
  texts_some?: InputMaybe<TextWhereInput>;
  ticketsBackground?: InputMaybe<AssetWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum ProductReservationOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  InternalTitleAsc = 'internalTitle_ASC',
  InternalTitleDesc = 'internalTitle_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  ShowReservationHelpAsc = 'showReservationHelp_ASC',
  ShowReservationHelpDesc = 'showReservationHelp_DESC',
  SortingAsc = 'sorting_ASC',
  SortingDesc = 'sorting_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type ProductReservationUpdateInput = {
  attractionSorting?: InputMaybe<AttractionUpdateManyInlineInput>;
  internalTitle?: InputMaybe<Scalars['String']>;
  product?: InputMaybe<ProductUpdateOneInlineInput>;
  productCodes?: InputMaybe<Scalars['Json']>;
  showReservationHelp?: InputMaybe<Scalars['Boolean']>;
  sorting?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<ReservationTextUpdateManyInlineInput>;
  texts?: InputMaybe<TextUpdateManyInlineInput>;
  ticketsBackground?: InputMaybe<AssetUpdateOneInlineInput>;
};

export type ProductReservationUpdateManyInlineInput = {
  /** Connect multiple existing ProductReservation documents */
  connect?: InputMaybe<Array<ProductReservationConnectInput>>;
  /** Create and connect multiple ProductReservation documents */
  create?: InputMaybe<Array<ProductReservationCreateInput>>;
  /** Delete multiple ProductReservation documents */
  delete?: InputMaybe<Array<ProductReservationWhereUniqueInput>>;
  /** Disconnect multiple ProductReservation documents */
  disconnect?: InputMaybe<Array<ProductReservationWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing ProductReservation documents */
  set?: InputMaybe<Array<ProductReservationWhereUniqueInput>>;
  /** Update multiple ProductReservation documents */
  update?: InputMaybe<Array<ProductReservationUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple ProductReservation documents */
  upsert?: InputMaybe<Array<ProductReservationUpsertWithNestedWhereUniqueInput>>;
};

export type ProductReservationUpdateManyInput = {
  internalTitle?: InputMaybe<Scalars['String']>;
  productCodes?: InputMaybe<Scalars['Json']>;
  showReservationHelp?: InputMaybe<Scalars['Boolean']>;
  sorting?: InputMaybe<Scalars['String']>;
};

export type ProductReservationUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: ProductReservationUpdateManyInput;
  /** Document search */
  where: ProductReservationWhereInput;
};

export type ProductReservationUpdateOneInlineInput = {
  /** Connect existing ProductReservation document */
  connect?: InputMaybe<ProductReservationWhereUniqueInput>;
  /** Create and connect one ProductReservation document */
  create?: InputMaybe<ProductReservationCreateInput>;
  /** Delete currently connected ProductReservation document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected ProductReservation document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single ProductReservation document */
  update?: InputMaybe<ProductReservationUpdateWithNestedWhereUniqueInput>;
  /** Upsert single ProductReservation document */
  upsert?: InputMaybe<ProductReservationUpsertWithNestedWhereUniqueInput>;
};

export type ProductReservationUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: ProductReservationUpdateInput;
  /** Unique document search */
  where: ProductReservationWhereUniqueInput;
};

export type ProductReservationUpsertInput = {
  /** Create document if it didn't exist */
  create: ProductReservationCreateInput;
  /** Update document if it exists */
  update: ProductReservationUpdateInput;
};

export type ProductReservationUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: ProductReservationUpsertInput;
  /** Unique document search */
  where: ProductReservationWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type ProductReservationWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']>;
};

/** Identifies documents */
export type ProductReservationWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ProductReservationWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ProductReservationWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ProductReservationWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  attractionSorting_every?: InputMaybe<AttractionWhereInput>;
  attractionSorting_none?: InputMaybe<AttractionWhereInput>;
  attractionSorting_some?: InputMaybe<AttractionWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<ProductReservationWhereStageInput>;
  documentInStages_none?: InputMaybe<ProductReservationWhereStageInput>;
  documentInStages_some?: InputMaybe<ProductReservationWhereStageInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  internalTitle?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  internalTitle_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  internalTitle_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  internalTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  internalTitle_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  internalTitle_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  internalTitle_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  internalTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  internalTitle_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  internalTitle_starts_with?: InputMaybe<Scalars['String']>;
  product?: InputMaybe<ProductWhereInput>;
  /** All values containing the given json path. */
  productCodes_json_path_exists?: InputMaybe<Scalars['String']>;
  /**
   * Recursively tries to find the provided JSON scalar value inside the field.
   * It does use an exact match when comparing values.
   * If you pass `null` as value the filter will be ignored.
   * Note: This filter fails if you try to look for a non scalar JSON value!
   */
  productCodes_value_recursive?: InputMaybe<Scalars['Json']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  showReservationHelp?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  showReservationHelp_not?: InputMaybe<Scalars['Boolean']>;
  sorting?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  sorting_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  sorting_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  sorting_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  sorting_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  sorting_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  sorting_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  sorting_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  sorting_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  sorting_starts_with?: InputMaybe<Scalars['String']>;
  text_every?: InputMaybe<ReservationTextWhereInput>;
  text_none?: InputMaybe<ReservationTextWhereInput>;
  text_some?: InputMaybe<ReservationTextWhereInput>;
  texts_every?: InputMaybe<TextWhereInput>;
  texts_none?: InputMaybe<TextWhereInput>;
  texts_some?: InputMaybe<TextWhereInput>;
  ticketsBackground?: InputMaybe<AssetWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type ProductReservationWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ProductReservationWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ProductReservationWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ProductReservationWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<ProductReservationWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References ProductReservation record uniquely */
export type ProductReservationWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export enum ProductType {
  Alacarte = 'alacarte',
  C3 = 'c3',
  Call = 'call',
  Core = 'core',
  Corechoice = 'corechoice',
  Cx = 'cx',
  Cxplus = 'cxplus',
}

export type ProductUpdateInput = {
  addOns?: InputMaybe<AddOnUpdateManyInlineInput>;
  addonAttractions?: InputMaybe<AttractionUpdateManyInlineInput>;
  admissionTitle?: InputMaybe<TextUpdateOneInlineInput>;
  ageAdult?: InputMaybe<Scalars['String']>;
  ageChild?: InputMaybe<Scalars['String']>;
  attractionLineups?: InputMaybe<ProductAttractionLineupUpdateManyInlineInput>;
  attractions?: InputMaybe<AttractionUpdateManyInlineInput>;
  bookingComId?: InputMaybe<Scalars['String']>;
  cityName?: InputMaybe<TextUpdateOneInlineInput>;
  ckwnyqpfq072z01xr98sl7iwx?: InputMaybe<ArticleCategoryUpdateManyInlineInput>;
  ckyb4vjce11hc01z2a4b0d1gt?: InputMaybe<AttractionVariantUpdateManyInlineInput>;
  ckzgmi3kf1l4201xsbab36297?: InputMaybe<AttractionOfferUpdateManyInlineInput>;
  cl2goe8km3a9y01w05yet0gss?: InputMaybe<ProductUpdateManyInlineInput>;
  cl2t5ktyl3n7b01yx9o8rdruh?: InputMaybe<ReservationTextUpdateManyInlineInput>;
  cl7kvbw1y0w8g01t74iib4v5z?: InputMaybe<PromoBannerUpdateManyInlineInput>;
  cle38hrnb0fy001ufdp4a5ewj?: InputMaybe<CrossPromoBannerUpdateManyInlineInput>;
  crossPromoImage?: InputMaybe<AssetUpdateOneInlineInput>;
  crowdriffAlbumId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<TextUpdateOneInlineInput>;
  excludeDiscounts?: InputMaybe<Scalars['Boolean']>;
  exclusiveAttraction?: InputMaybe<AttractionUpdateManyInlineInput>;
  exlcudedPartners?: InputMaybe<PartnerUpdateManyInlineInput>;
  geolocation?: InputMaybe<LocationInput>;
  hasThingsToDo?: InputMaybe<Scalars['Boolean']>;
  hasTopMuseums?: InputMaybe<Scalars['Boolean']>;
  headline?: InputMaybe<TextUpdateOneInlineInput>;
  hero?: InputMaybe<AssetUpdateOneInlineInput>;
  hideReviews?: InputMaybe<Scalars['Boolean']>;
  hideVideo?: InputMaybe<Scalars['Boolean']>;
  interchangeId?: InputMaybe<Scalars['String']>;
  interfaceKey?: InputMaybe<Scalars['String']>;
  key?: InputMaybe<Scalars['String']>;
  /** Manage document localizations */
  localizations?: InputMaybe<ProductUpdateLocalizationsInput>;
  logo?: InputMaybe<AssetUpdateOneInlineInput>;
  logoSpot?: InputMaybe<AssetUpdateOneInlineInput>;
  logoSpotOutline?: InputMaybe<AssetUpdateOneInlineInput>;
  logoTicket?: InputMaybe<AssetUpdateOneInlineInput>;
  logoWhite?: InputMaybe<AssetUpdateOneInlineInput>;
  mapImage?: InputMaybe<AssetUpdateOneInlineInput>;
  menus?: InputMaybe<MenuUpdateManyInlineInput>;
  name?: InputMaybe<Scalars['String']>;
  nativeCurrency?: InputMaybe<Currency>;
  notices?: InputMaybe<NoticeUpdateManyInlineInput>;
  partnerOffers?: InputMaybe<PartnerOfferUpdateManyInlineInput>;
  planning?: InputMaybe<DetailBlockUpdateOneInlineInput>;
  productActivityItem?: InputMaybe<ProductActivityItemUpdateManyInlineInput>;
  productImage?: InputMaybe<AssetUpdateOneInlineInput>;
  promos?: InputMaybe<PromoUpdateManyInlineInput>;
  qabu?: InputMaybe<Scalars['Json']>;
  relatedProducts?: InputMaybe<ProductUpdateManyInlineInput>;
  reservation?: InputMaybe<ProductReservationUpdateOneInlineInput>;
  reservationsRequired?: InputMaybe<Scalars['Boolean']>;
  seo?: InputMaybe<SeoUpdateOneInlineInput>;
  slug?: InputMaybe<Scalars['String']>;
  tagline?: InputMaybe<TextUpdateOneInlineInput>;
  text?: InputMaybe<TextUpdateManyInlineInput>;
  thingsToDo?: InputMaybe<ProductActivityUpdateOneInlineInput>;
  thumb?: InputMaybe<AssetUpdateOneInlineInput>;
  ticketGroups?: InputMaybe<TicketGroupUpdateManyInlineInput>;
  /** title input for default locale (en) */
  title?: InputMaybe<Scalars['String']>;
  topMuseums?: InputMaybe<ProductActivityUpdateOneInlineInput>;
  type?: InputMaybe<ProductType>;
  validDays?: InputMaybe<Scalars['Int']>;
  video?: InputMaybe<VideoUpdateOneInlineInput>;
};

export type ProductUpdateLocalizationDataInput = {
  title?: InputMaybe<Scalars['String']>;
};

export type ProductUpdateLocalizationInput = {
  data: ProductUpdateLocalizationDataInput;
  locale: Locale;
};

export type ProductUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<ProductCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<ProductUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<ProductUpsertLocalizationInput>>;
};

export type ProductUpdateManyInlineInput = {
  /** Connect multiple existing Product documents */
  connect?: InputMaybe<Array<ProductConnectInput>>;
  /** Create and connect multiple Product documents */
  create?: InputMaybe<Array<ProductCreateInput>>;
  /** Delete multiple Product documents */
  delete?: InputMaybe<Array<ProductWhereUniqueInput>>;
  /** Disconnect multiple Product documents */
  disconnect?: InputMaybe<Array<ProductWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing Product documents */
  set?: InputMaybe<Array<ProductWhereUniqueInput>>;
  /** Update multiple Product documents */
  update?: InputMaybe<Array<ProductUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple Product documents */
  upsert?: InputMaybe<Array<ProductUpsertWithNestedWhereUniqueInput>>;
};

export type ProductUpdateManyInput = {
  ageAdult?: InputMaybe<Scalars['String']>;
  ageChild?: InputMaybe<Scalars['String']>;
  bookingComId?: InputMaybe<Scalars['String']>;
  crowdriffAlbumId?: InputMaybe<Scalars['String']>;
  excludeDiscounts?: InputMaybe<Scalars['Boolean']>;
  geolocation?: InputMaybe<LocationInput>;
  hasThingsToDo?: InputMaybe<Scalars['Boolean']>;
  hasTopMuseums?: InputMaybe<Scalars['Boolean']>;
  hideReviews?: InputMaybe<Scalars['Boolean']>;
  hideVideo?: InputMaybe<Scalars['Boolean']>;
  interchangeId?: InputMaybe<Scalars['String']>;
  interfaceKey?: InputMaybe<Scalars['String']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<ProductUpdateManyLocalizationsInput>;
  name?: InputMaybe<Scalars['String']>;
  nativeCurrency?: InputMaybe<Currency>;
  qabu?: InputMaybe<Scalars['Json']>;
  reservationsRequired?: InputMaybe<Scalars['Boolean']>;
  /** title input for default locale (en) */
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<ProductType>;
  validDays?: InputMaybe<Scalars['Int']>;
};

export type ProductUpdateManyLocalizationDataInput = {
  title?: InputMaybe<Scalars['String']>;
};

export type ProductUpdateManyLocalizationInput = {
  data: ProductUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type ProductUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<ProductUpdateManyLocalizationInput>>;
};

export type ProductUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: ProductUpdateManyInput;
  /** Document search */
  where: ProductWhereInput;
};

export type ProductUpdateOneInlineInput = {
  /** Connect existing Product document */
  connect?: InputMaybe<ProductWhereUniqueInput>;
  /** Create and connect one Product document */
  create?: InputMaybe<ProductCreateInput>;
  /** Delete currently connected Product document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected Product document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single Product document */
  update?: InputMaybe<ProductUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Product document */
  upsert?: InputMaybe<ProductUpsertWithNestedWhereUniqueInput>;
};

export type ProductUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: ProductUpdateInput;
  /** Unique document search */
  where: ProductWhereUniqueInput;
};

export type ProductUpsertInput = {
  /** Create document if it didn't exist */
  create: ProductCreateInput;
  /** Update document if it exists */
  update: ProductUpdateInput;
};

export type ProductUpsertLocalizationInput = {
  create: ProductCreateLocalizationDataInput;
  locale: Locale;
  update: ProductUpdateLocalizationDataInput;
};

export type ProductUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: ProductUpsertInput;
  /** Unique document search */
  where: ProductWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type ProductWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']>;
};

/** Identifies documents */
export type ProductWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ProductWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ProductWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ProductWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  addOns_every?: InputMaybe<AddOnWhereInput>;
  addOns_none?: InputMaybe<AddOnWhereInput>;
  addOns_some?: InputMaybe<AddOnWhereInput>;
  addonAttractions_every?: InputMaybe<AttractionWhereInput>;
  addonAttractions_none?: InputMaybe<AttractionWhereInput>;
  addonAttractions_some?: InputMaybe<AttractionWhereInput>;
  admissionTitle?: InputMaybe<TextWhereInput>;
  ageAdult?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  ageAdult_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  ageAdult_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  ageAdult_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  ageAdult_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  ageAdult_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  ageAdult_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  ageAdult_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  ageAdult_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  ageAdult_starts_with?: InputMaybe<Scalars['String']>;
  ageChild?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  ageChild_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  ageChild_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  ageChild_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  ageChild_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  ageChild_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  ageChild_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  ageChild_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  ageChild_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  ageChild_starts_with?: InputMaybe<Scalars['String']>;
  attractionLineups_every?: InputMaybe<ProductAttractionLineupWhereInput>;
  attractionLineups_none?: InputMaybe<ProductAttractionLineupWhereInput>;
  attractionLineups_some?: InputMaybe<ProductAttractionLineupWhereInput>;
  attractions_every?: InputMaybe<AttractionWhereInput>;
  attractions_none?: InputMaybe<AttractionWhereInput>;
  attractions_some?: InputMaybe<AttractionWhereInput>;
  bookingComId?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  bookingComId_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  bookingComId_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  bookingComId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  bookingComId_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  bookingComId_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  bookingComId_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  bookingComId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  bookingComId_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  bookingComId_starts_with?: InputMaybe<Scalars['String']>;
  cityName?: InputMaybe<TextWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  crossPromoImage?: InputMaybe<AssetWhereInput>;
  crowdriffAlbumId?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  crowdriffAlbumId_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  crowdriffAlbumId_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  crowdriffAlbumId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  crowdriffAlbumId_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  crowdriffAlbumId_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  crowdriffAlbumId_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  crowdriffAlbumId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  crowdriffAlbumId_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  crowdriffAlbumId_starts_with?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<TextWhereInput>;
  documentInStages_every?: InputMaybe<ProductWhereStageInput>;
  documentInStages_none?: InputMaybe<ProductWhereStageInput>;
  documentInStages_some?: InputMaybe<ProductWhereStageInput>;
  excludeDiscounts?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  excludeDiscounts_not?: InputMaybe<Scalars['Boolean']>;
  exclusiveAttraction_every?: InputMaybe<AttractionWhereInput>;
  exclusiveAttraction_none?: InputMaybe<AttractionWhereInput>;
  exclusiveAttraction_some?: InputMaybe<AttractionWhereInput>;
  exlcudedPartners_every?: InputMaybe<PartnerWhereInput>;
  exlcudedPartners_none?: InputMaybe<PartnerWhereInput>;
  exlcudedPartners_some?: InputMaybe<PartnerWhereInput>;
  hasThingsToDo?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  hasThingsToDo_not?: InputMaybe<Scalars['Boolean']>;
  hasTopMuseums?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  hasTopMuseums_not?: InputMaybe<Scalars['Boolean']>;
  headline?: InputMaybe<TextWhereInput>;
  hero?: InputMaybe<AssetWhereInput>;
  hideReviews?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  hideReviews_not?: InputMaybe<Scalars['Boolean']>;
  hideVideo?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  hideVideo_not?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  interchangeId?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  interchangeId_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  interchangeId_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  interchangeId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  interchangeId_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  interchangeId_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  interchangeId_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  interchangeId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  interchangeId_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  interchangeId_starts_with?: InputMaybe<Scalars['String']>;
  interfaceKey?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  interfaceKey_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  interfaceKey_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  interfaceKey_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  interfaceKey_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  interfaceKey_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  interfaceKey_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  interfaceKey_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  interfaceKey_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  interfaceKey_starts_with?: InputMaybe<Scalars['String']>;
  key?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  key_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  key_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  key_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  key_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  key_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  key_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  key_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  key_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  key_starts_with?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<AssetWhereInput>;
  logoSpot?: InputMaybe<AssetWhereInput>;
  logoSpotOutline?: InputMaybe<AssetWhereInput>;
  logoTicket?: InputMaybe<AssetWhereInput>;
  logoWhite?: InputMaybe<AssetWhereInput>;
  mapImage?: InputMaybe<AssetWhereInput>;
  menus_every?: InputMaybe<MenuWhereInput>;
  menus_none?: InputMaybe<MenuWhereInput>;
  menus_some?: InputMaybe<MenuWhereInput>;
  name?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']>;
  nativeCurrency?: InputMaybe<Currency>;
  /** All values that are contained in given list. */
  nativeCurrency_in?: InputMaybe<Array<InputMaybe<Currency>>>;
  /** Any other value that exists and is not equal to the given value. */
  nativeCurrency_not?: InputMaybe<Currency>;
  /** All values that are not contained in given list. */
  nativeCurrency_not_in?: InputMaybe<Array<InputMaybe<Currency>>>;
  notices_every?: InputMaybe<NoticeWhereInput>;
  notices_none?: InputMaybe<NoticeWhereInput>;
  notices_some?: InputMaybe<NoticeWhereInput>;
  partnerOffers_every?: InputMaybe<PartnerOfferWhereInput>;
  partnerOffers_none?: InputMaybe<PartnerOfferWhereInput>;
  partnerOffers_some?: InputMaybe<PartnerOfferWhereInput>;
  planning?: InputMaybe<DetailBlockWhereInput>;
  productActivityItem_every?: InputMaybe<ProductActivityItemWhereInput>;
  productActivityItem_none?: InputMaybe<ProductActivityItemWhereInput>;
  productActivityItem_some?: InputMaybe<ProductActivityItemWhereInput>;
  productImage?: InputMaybe<AssetWhereInput>;
  promos_every?: InputMaybe<PromoWhereInput>;
  promos_none?: InputMaybe<PromoWhereInput>;
  promos_some?: InputMaybe<PromoWhereInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  /** All values containing the given json path. */
  qabu_json_path_exists?: InputMaybe<Scalars['String']>;
  /**
   * Recursively tries to find the provided JSON scalar value inside the field.
   * It does use an exact match when comparing values.
   * If you pass `null` as value the filter will be ignored.
   * Note: This filter fails if you try to look for a non scalar JSON value!
   */
  qabu_value_recursive?: InputMaybe<Scalars['Json']>;
  relatedProducts_every?: InputMaybe<ProductWhereInput>;
  relatedProducts_none?: InputMaybe<ProductWhereInput>;
  relatedProducts_some?: InputMaybe<ProductWhereInput>;
  reservation?: InputMaybe<ProductReservationWhereInput>;
  reservationsRequired?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  reservationsRequired_not?: InputMaybe<Scalars['Boolean']>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  seo?: InputMaybe<SeoWhereInput>;
  slug?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  slug_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  slug_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  slug_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  slug_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  slug_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  slug_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  slug_starts_with?: InputMaybe<Scalars['String']>;
  tagline?: InputMaybe<TextWhereInput>;
  text_every?: InputMaybe<TextWhereInput>;
  text_none?: InputMaybe<TextWhereInput>;
  text_some?: InputMaybe<TextWhereInput>;
  thingsToDo?: InputMaybe<ProductActivityWhereInput>;
  thumb?: InputMaybe<AssetWhereInput>;
  ticketGroups_every?: InputMaybe<TicketGroupWhereInput>;
  ticketGroups_none?: InputMaybe<TicketGroupWhereInput>;
  ticketGroups_some?: InputMaybe<TicketGroupWhereInput>;
  title?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']>;
  topMuseums?: InputMaybe<ProductActivityWhereInput>;
  type?: InputMaybe<ProductType>;
  /** All values that are contained in given list. */
  type_in?: InputMaybe<Array<InputMaybe<ProductType>>>;
  /** Any other value that exists and is not equal to the given value. */
  type_not?: InputMaybe<ProductType>;
  /** All values that are not contained in given list. */
  type_not_in?: InputMaybe<Array<InputMaybe<ProductType>>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
  validDays?: InputMaybe<Scalars['Int']>;
  /** All values greater than the given value. */
  validDays_gt?: InputMaybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  validDays_gte?: InputMaybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  validDays_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  /** All values less than the given value. */
  validDays_lt?: InputMaybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  validDays_lte?: InputMaybe<Scalars['Int']>;
  /** Any other value that exists and is not equal to the given value. */
  validDays_not?: InputMaybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  validDays_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  video?: InputMaybe<VideoWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type ProductWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ProductWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ProductWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ProductWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<ProductWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References Product record uniquely */
export type ProductWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
  key?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
};

export type Promo = Node & {
  __typename?: 'Promo';
  active: Scalars['Boolean'];
  attraction: Maybe<Attraction>;
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** User that created this document */
  createdBy: Maybe<User>;
  /** Get the document in other stages */
  documentInStages: Array<Promo>;
  endDate: Scalars['Date'];
  /** List of Promo versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID'];
  internalTitle: Scalars['String'];
  /** System Locale field */
  locale: Locale;
  localizationFix: Maybe<Scalars['String']>;
  /** Get the other localizations for this document */
  localizations: Array<Promo>;
  placements: Array<PromoPlacement>;
  product: Maybe<Product>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt: Maybe<Scalars['DateTime']>;
  /** User that last published this document */
  publishedBy: Maybe<User>;
  qabu: Maybe<Scalars['Json']>;
  scheduledIn: Array<ScheduledOperation>;
  /** System stage field */
  stage: Stage;
  startDate: Scalars['Date'];
  template: Maybe<PromotemplateUnion>;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** User that last updated this document */
  updatedBy: Maybe<User>;
};

export type PromoAttractionArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type PromoCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type PromoCreatedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type PromoDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};

export type PromoHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride: InputMaybe<Stage>;
};

export type PromoLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};

export type PromoProductArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type PromoPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type PromoPublishedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type PromoScheduledInArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ScheduledOperationWhereInput>;
};

export type PromoTemplateArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type PromoUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type PromoUpdatedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type PromoBanner = {
  __typename?: 'PromoBanner';
  copyright: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  /** The unique identifier */
  id: Scalars['ID'];
  image: Maybe<Asset>;
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<PromoBanner>;
  logo: Maybe<Asset>;
  product: Maybe<Product>;
  /** System stage field */
  stage: Stage;
  subDescription: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
};

export type PromoBannerImageArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type PromoBannerLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};

export type PromoBannerLogoArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type PromoBannerProductArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type PromoBannerConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: PromoBannerWhereUniqueInput;
};

/** A connection to a list of items. */
export type PromoBannerConnection = {
  __typename?: 'PromoBannerConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<PromoBannerEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type PromoBannerCreateInput = {
  /** copyright input for default locale (en) */
  copyright?: InputMaybe<Scalars['String']>;
  /** description input for default locale (en) */
  description?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<AssetCreateOneInlineInput>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<PromoBannerCreateLocalizationsInput>;
  logo?: InputMaybe<AssetCreateOneInlineInput>;
  product?: InputMaybe<ProductCreateOneInlineInput>;
  /** subDescription input for default locale (en) */
  subDescription?: InputMaybe<Scalars['String']>;
  /** title input for default locale (en) */
  title?: InputMaybe<Scalars['String']>;
};

export type PromoBannerCreateLocalizationDataInput = {
  copyright?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  subDescription?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type PromoBannerCreateLocalizationInput = {
  /** Localization input */
  data: PromoBannerCreateLocalizationDataInput;
  locale: Locale;
};

export type PromoBannerCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<PromoBannerCreateLocalizationInput>>;
};

export type PromoBannerCreateManyInlineInput = {
  /** Create and connect multiple existing PromoBanner documents */
  create?: InputMaybe<Array<PromoBannerCreateInput>>;
};

export type PromoBannerCreateOneInlineInput = {
  /** Create and connect one PromoBanner document */
  create?: InputMaybe<PromoBannerCreateInput>;
};

export type PromoBannerCreateWithPositionInput = {
  /** Document to create */
  data: PromoBannerCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type PromoBannerEdge = {
  __typename?: 'PromoBannerEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: PromoBanner;
};

/** Identifies documents */
export type PromoBannerManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<PromoBannerWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<PromoBannerWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<PromoBannerWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  image?: InputMaybe<AssetWhereInput>;
  logo?: InputMaybe<AssetWhereInput>;
  product?: InputMaybe<ProductWhereInput>;
};

export enum PromoBannerOrderByInput {
  CopyrightAsc = 'copyright_ASC',
  CopyrightDesc = 'copyright_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  SubDescriptionAsc = 'subDescription_ASC',
  SubDescriptionDesc = 'subDescription_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type PromoBannerParent = Promo;

export type PromoBannerParentConnectInput = {
  Promo?: InputMaybe<PromoConnectInput>;
};

export type PromoBannerParentCreateInput = {
  Promo?: InputMaybe<PromoCreateInput>;
};

export type PromoBannerParentCreateManyInlineInput = {
  /** Connect multiple existing PromoBannerParent documents */
  connect?: InputMaybe<Array<PromoBannerParentWhereUniqueInput>>;
  /** Create and connect multiple existing PromoBannerParent documents */
  create?: InputMaybe<Array<PromoBannerParentCreateInput>>;
};

export type PromoBannerParentCreateOneInlineInput = {
  /** Connect one existing PromoBannerParent document */
  connect?: InputMaybe<PromoBannerParentWhereUniqueInput>;
  /** Create and connect one PromoBannerParent document */
  create?: InputMaybe<PromoBannerParentCreateInput>;
};

export type PromoBannerParentUpdateInput = {
  Promo?: InputMaybe<PromoUpdateInput>;
};

export type PromoBannerParentUpdateManyInlineInput = {
  /** Connect multiple existing PromoBannerParent documents */
  connect?: InputMaybe<Array<PromoBannerParentConnectInput>>;
  /** Create and connect multiple PromoBannerParent documents */
  create?: InputMaybe<Array<PromoBannerParentCreateInput>>;
  /** Delete multiple PromoBannerParent documents */
  delete?: InputMaybe<Array<PromoBannerParentWhereUniqueInput>>;
  /** Disconnect multiple PromoBannerParent documents */
  disconnect?: InputMaybe<Array<PromoBannerParentWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing PromoBannerParent documents */
  set?: InputMaybe<Array<PromoBannerParentWhereUniqueInput>>;
  /** Update multiple PromoBannerParent documents */
  update?: InputMaybe<Array<PromoBannerParentUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple PromoBannerParent documents */
  upsert?: InputMaybe<Array<PromoBannerParentUpsertWithNestedWhereUniqueInput>>;
};

export type PromoBannerParentUpdateManyWithNestedWhereInput = {
  Promo?: InputMaybe<PromoUpdateManyWithNestedWhereInput>;
};

export type PromoBannerParentUpdateOneInlineInput = {
  /** Connect existing PromoBannerParent document */
  connect?: InputMaybe<PromoBannerParentWhereUniqueInput>;
  /** Create and connect one PromoBannerParent document */
  create?: InputMaybe<PromoBannerParentCreateInput>;
  /** Delete currently connected PromoBannerParent document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected PromoBannerParent document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single PromoBannerParent document */
  update?: InputMaybe<PromoBannerParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single PromoBannerParent document */
  upsert?: InputMaybe<PromoBannerParentUpsertWithNestedWhereUniqueInput>;
};

export type PromoBannerParentUpdateWithNestedWhereUniqueInput = {
  Promo?: InputMaybe<PromoUpdateWithNestedWhereUniqueInput>;
};

export type PromoBannerParentUpsertWithNestedWhereUniqueInput = {
  Promo?: InputMaybe<PromoUpsertWithNestedWhereUniqueInput>;
};

export type PromoBannerParentWhereInput = {
  Promo?: InputMaybe<PromoWhereInput>;
};

export type PromoBannerParentWhereUniqueInput = {
  Promo?: InputMaybe<PromoWhereUniqueInput>;
};

export type PromoBannerUpdateInput = {
  /** copyright input for default locale (en) */
  copyright?: InputMaybe<Scalars['String']>;
  /** description input for default locale (en) */
  description?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<AssetUpdateOneInlineInput>;
  /** Manage document localizations */
  localizations?: InputMaybe<PromoBannerUpdateLocalizationsInput>;
  logo?: InputMaybe<AssetUpdateOneInlineInput>;
  product?: InputMaybe<ProductUpdateOneInlineInput>;
  /** subDescription input for default locale (en) */
  subDescription?: InputMaybe<Scalars['String']>;
  /** title input for default locale (en) */
  title?: InputMaybe<Scalars['String']>;
};

export type PromoBannerUpdateLocalizationDataInput = {
  copyright?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  subDescription?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type PromoBannerUpdateLocalizationInput = {
  data: PromoBannerUpdateLocalizationDataInput;
  locale: Locale;
};

export type PromoBannerUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<PromoBannerCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<PromoBannerUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<PromoBannerUpsertLocalizationInput>>;
};

export type PromoBannerUpdateManyInlineInput = {
  /** Create and connect multiple PromoBanner component instances */
  create?: InputMaybe<Array<PromoBannerCreateWithPositionInput>>;
  /** Delete multiple PromoBanner documents */
  delete?: InputMaybe<Array<PromoBannerWhereUniqueInput>>;
  /** Update multiple PromoBanner component instances */
  update?: InputMaybe<Array<PromoBannerUpdateWithNestedWhereUniqueAndPositionInput>>;
  /** Upsert multiple PromoBanner component instances */
  upsert?: InputMaybe<Array<PromoBannerUpsertWithNestedWhereUniqueAndPositionInput>>;
};

export type PromoBannerUpdateManyInput = {
  /** copyright input for default locale (en) */
  copyright?: InputMaybe<Scalars['String']>;
  /** description input for default locale (en) */
  description?: InputMaybe<Scalars['String']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<PromoBannerUpdateManyLocalizationsInput>;
  /** subDescription input for default locale (en) */
  subDescription?: InputMaybe<Scalars['String']>;
  /** title input for default locale (en) */
  title?: InputMaybe<Scalars['String']>;
};

export type PromoBannerUpdateManyLocalizationDataInput = {
  copyright?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  subDescription?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type PromoBannerUpdateManyLocalizationInput = {
  data: PromoBannerUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type PromoBannerUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<PromoBannerUpdateManyLocalizationInput>>;
};

export type PromoBannerUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: PromoBannerUpdateManyInput;
  /** Document search */
  where: PromoBannerWhereInput;
};

export type PromoBannerUpdateOneInlineInput = {
  /** Create and connect one PromoBanner document */
  create?: InputMaybe<PromoBannerCreateInput>;
  /** Delete currently connected PromoBanner document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single PromoBanner document */
  update?: InputMaybe<PromoBannerUpdateWithNestedWhereUniqueInput>;
  /** Upsert single PromoBanner document */
  upsert?: InputMaybe<PromoBannerUpsertWithNestedWhereUniqueInput>;
};

export type PromoBannerUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<PromoBannerUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: PromoBannerWhereUniqueInput;
};

export type PromoBannerUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: PromoBannerUpdateInput;
  /** Unique document search */
  where: PromoBannerWhereUniqueInput;
};

export type PromoBannerUpsertInput = {
  /** Create document if it didn't exist */
  create: PromoBannerCreateInput;
  /** Update document if it exists */
  update: PromoBannerUpdateInput;
};

export type PromoBannerUpsertLocalizationInput = {
  create: PromoBannerCreateLocalizationDataInput;
  locale: Locale;
  update: PromoBannerUpdateLocalizationDataInput;
};

export type PromoBannerUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<PromoBannerUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: PromoBannerWhereUniqueInput;
};

export type PromoBannerUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: PromoBannerUpsertInput;
  /** Unique document search */
  where: PromoBannerWhereUniqueInput;
};

/** Identifies documents */
export type PromoBannerWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<PromoBannerWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<PromoBannerWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<PromoBannerWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  copyright?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  copyright_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  copyright_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  copyright_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  copyright_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  copyright_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  copyright_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  copyright_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  copyright_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  copyright_starts_with?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  description_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  image?: InputMaybe<AssetWhereInput>;
  logo?: InputMaybe<AssetWhereInput>;
  product?: InputMaybe<ProductWhereInput>;
  subDescription?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  subDescription_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  subDescription_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  subDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  subDescription_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  subDescription_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  subDescription_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  subDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  subDescription_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  subDescription_starts_with?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']>;
};

/** References PromoBanner record uniquely */
export type PromoBannerWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type PromoConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: PromoWhereUniqueInput;
};

/** A connection to a list of items. */
export type PromoConnection = {
  __typename?: 'PromoConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<PromoEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type PromoCreateInput = {
  active: Scalars['Boolean'];
  attraction?: InputMaybe<AttractionCreateOneInlineInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  endDate: Scalars['Date'];
  internalTitle: Scalars['String'];
  /** localizationFix input for default locale (en) */
  localizationFix?: InputMaybe<Scalars['String']>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<PromoCreateLocalizationsInput>;
  placements?: InputMaybe<Array<PromoPlacement>>;
  product?: InputMaybe<ProductCreateOneInlineInput>;
  qabu?: InputMaybe<Scalars['Json']>;
  startDate: Scalars['Date'];
  template?: InputMaybe<PromotemplateUnionCreateOneInlineInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PromoCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  localizationFix?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PromoCreateLocalizationInput = {
  /** Localization input */
  data: PromoCreateLocalizationDataInput;
  locale: Locale;
};

export type PromoCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<PromoCreateLocalizationInput>>;
};

export type PromoCreateManyInlineInput = {
  /** Connect multiple existing Promo documents */
  connect?: InputMaybe<Array<PromoWhereUniqueInput>>;
  /** Create and connect multiple existing Promo documents */
  create?: InputMaybe<Array<PromoCreateInput>>;
};

export type PromoCreateOneInlineInput = {
  /** Connect one existing Promo document */
  connect?: InputMaybe<PromoWhereUniqueInput>;
  /** Create and connect one Promo document */
  create?: InputMaybe<PromoCreateInput>;
};

/** An edge in a connection. */
export type PromoEdge = {
  __typename?: 'PromoEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Promo;
};

/** Identifies documents */
export type PromoManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<PromoWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<PromoWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<PromoWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  active_not?: InputMaybe<Scalars['Boolean']>;
  attraction?: InputMaybe<AttractionWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<PromoWhereStageInput>;
  documentInStages_none?: InputMaybe<PromoWhereStageInput>;
  documentInStages_some?: InputMaybe<PromoWhereStageInput>;
  endDate?: InputMaybe<Scalars['Date']>;
  /** All values greater than the given value. */
  endDate_gt?: InputMaybe<Scalars['Date']>;
  /** All values greater than or equal the given value. */
  endDate_gte?: InputMaybe<Scalars['Date']>;
  /** All values that are contained in given list. */
  endDate_in?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  /** All values less than the given value. */
  endDate_lt?: InputMaybe<Scalars['Date']>;
  /** All values less than or equal the given value. */
  endDate_lte?: InputMaybe<Scalars['Date']>;
  /** Any other value that exists and is not equal to the given value. */
  endDate_not?: InputMaybe<Scalars['Date']>;
  /** All values that are not contained in given list. */
  endDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  internalTitle?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  internalTitle_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  internalTitle_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  internalTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  internalTitle_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  internalTitle_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  internalTitle_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  internalTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  internalTitle_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  internalTitle_starts_with?: InputMaybe<Scalars['String']>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  placements?: InputMaybe<Array<PromoPlacement>>;
  /** Matches if the field array contains *all* items provided to the filter */
  placements_contains_all?: InputMaybe<Array<PromoPlacement>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  placements_contains_none?: InputMaybe<Array<PromoPlacement>>;
  /** Matches if the field array contains at least one item provided to the filter */
  placements_contains_some?: InputMaybe<Array<PromoPlacement>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  placements_not?: InputMaybe<Array<PromoPlacement>>;
  product?: InputMaybe<ProductWhereInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  /** All values containing the given json path. */
  qabu_json_path_exists?: InputMaybe<Scalars['String']>;
  /**
   * Recursively tries to find the provided JSON scalar value inside the field.
   * It does use an exact match when comparing values.
   * If you pass `null` as value the filter will be ignored.
   * Note: This filter fails if you try to look for a non scalar JSON value!
   */
  qabu_value_recursive?: InputMaybe<Scalars['Json']>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  startDate?: InputMaybe<Scalars['Date']>;
  /** All values greater than the given value. */
  startDate_gt?: InputMaybe<Scalars['Date']>;
  /** All values greater than or equal the given value. */
  startDate_gte?: InputMaybe<Scalars['Date']>;
  /** All values that are contained in given list. */
  startDate_in?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  /** All values less than the given value. */
  startDate_lt?: InputMaybe<Scalars['Date']>;
  /** All values less than or equal the given value. */
  startDate_lte?: InputMaybe<Scalars['Date']>;
  /** Any other value that exists and is not equal to the given value. */
  startDate_not?: InputMaybe<Scalars['Date']>;
  /** All values that are not contained in given list. */
  startDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  /** All values in which the modular component is connected to the given models */
  template?: InputMaybe<PromotemplateUnionWhereInput>;
  /** All values in which the union is empty. */
  template_empty?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum PromoOrderByInput {
  ActiveAsc = 'active_ASC',
  ActiveDesc = 'active_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  EndDateAsc = 'endDate_ASC',
  EndDateDesc = 'endDate_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  InternalTitleAsc = 'internalTitle_ASC',
  InternalTitleDesc = 'internalTitle_DESC',
  LocalizationFixAsc = 'localizationFix_ASC',
  LocalizationFixDesc = 'localizationFix_DESC',
  PlacementsAsc = 'placements_ASC',
  PlacementsDesc = 'placements_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  StartDateAsc = 'startDate_ASC',
  StartDateDesc = 'startDate_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export enum PromoPlacement {
  ProductBottom = 'productBottom',
  ProductMiddle = 'productMiddle',
  ProductTop = 'productTop',
  TicketPickerBottom = 'ticketPickerBottom',
  TicketPickerTop = 'ticketPickerTop',
}

export type PromoText = {
  __typename?: 'PromoText';
  badge: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<PromoText>;
  /** System stage field */
  stage: Stage;
};

export type PromoTextLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};

export type PromoTextConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: PromoTextWhereUniqueInput;
};

/** A connection to a list of items. */
export type PromoTextConnection = {
  __typename?: 'PromoTextConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<PromoTextEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type PromoTextCreateInput = {
  /** badge input for default locale (en) */
  badge?: InputMaybe<Scalars['String']>;
  /** description input for default locale (en) */
  description?: InputMaybe<Scalars['String']>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<PromoTextCreateLocalizationsInput>;
};

export type PromoTextCreateLocalizationDataInput = {
  badge?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
};

export type PromoTextCreateLocalizationInput = {
  /** Localization input */
  data: PromoTextCreateLocalizationDataInput;
  locale: Locale;
};

export type PromoTextCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<PromoTextCreateLocalizationInput>>;
};

export type PromoTextCreateManyInlineInput = {
  /** Create and connect multiple existing PromoText documents */
  create?: InputMaybe<Array<PromoTextCreateInput>>;
};

export type PromoTextCreateOneInlineInput = {
  /** Create and connect one PromoText document */
  create?: InputMaybe<PromoTextCreateInput>;
};

export type PromoTextCreateWithPositionInput = {
  /** Document to create */
  data: PromoTextCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type PromoTextEdge = {
  __typename?: 'PromoTextEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: PromoText;
};

/** Identifies documents */
export type PromoTextManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<PromoTextWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<PromoTextWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<PromoTextWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
};

export enum PromoTextOrderByInput {
  BadgeAsc = 'badge_ASC',
  BadgeDesc = 'badge_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
}

export type PromoTextParent = Promo;

export type PromoTextParentConnectInput = {
  Promo?: InputMaybe<PromoConnectInput>;
};

export type PromoTextParentCreateInput = {
  Promo?: InputMaybe<PromoCreateInput>;
};

export type PromoTextParentCreateManyInlineInput = {
  /** Connect multiple existing PromoTextParent documents */
  connect?: InputMaybe<Array<PromoTextParentWhereUniqueInput>>;
  /** Create and connect multiple existing PromoTextParent documents */
  create?: InputMaybe<Array<PromoTextParentCreateInput>>;
};

export type PromoTextParentCreateOneInlineInput = {
  /** Connect one existing PromoTextParent document */
  connect?: InputMaybe<PromoTextParentWhereUniqueInput>;
  /** Create and connect one PromoTextParent document */
  create?: InputMaybe<PromoTextParentCreateInput>;
};

export type PromoTextParentUpdateInput = {
  Promo?: InputMaybe<PromoUpdateInput>;
};

export type PromoTextParentUpdateManyInlineInput = {
  /** Connect multiple existing PromoTextParent documents */
  connect?: InputMaybe<Array<PromoTextParentConnectInput>>;
  /** Create and connect multiple PromoTextParent documents */
  create?: InputMaybe<Array<PromoTextParentCreateInput>>;
  /** Delete multiple PromoTextParent documents */
  delete?: InputMaybe<Array<PromoTextParentWhereUniqueInput>>;
  /** Disconnect multiple PromoTextParent documents */
  disconnect?: InputMaybe<Array<PromoTextParentWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing PromoTextParent documents */
  set?: InputMaybe<Array<PromoTextParentWhereUniqueInput>>;
  /** Update multiple PromoTextParent documents */
  update?: InputMaybe<Array<PromoTextParentUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple PromoTextParent documents */
  upsert?: InputMaybe<Array<PromoTextParentUpsertWithNestedWhereUniqueInput>>;
};

export type PromoTextParentUpdateManyWithNestedWhereInput = {
  Promo?: InputMaybe<PromoUpdateManyWithNestedWhereInput>;
};

export type PromoTextParentUpdateOneInlineInput = {
  /** Connect existing PromoTextParent document */
  connect?: InputMaybe<PromoTextParentWhereUniqueInput>;
  /** Create and connect one PromoTextParent document */
  create?: InputMaybe<PromoTextParentCreateInput>;
  /** Delete currently connected PromoTextParent document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected PromoTextParent document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single PromoTextParent document */
  update?: InputMaybe<PromoTextParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single PromoTextParent document */
  upsert?: InputMaybe<PromoTextParentUpsertWithNestedWhereUniqueInput>;
};

export type PromoTextParentUpdateWithNestedWhereUniqueInput = {
  Promo?: InputMaybe<PromoUpdateWithNestedWhereUniqueInput>;
};

export type PromoTextParentUpsertWithNestedWhereUniqueInput = {
  Promo?: InputMaybe<PromoUpsertWithNestedWhereUniqueInput>;
};

export type PromoTextParentWhereInput = {
  Promo?: InputMaybe<PromoWhereInput>;
};

export type PromoTextParentWhereUniqueInput = {
  Promo?: InputMaybe<PromoWhereUniqueInput>;
};

export type PromoTextUpdateInput = {
  /** badge input for default locale (en) */
  badge?: InputMaybe<Scalars['String']>;
  /** description input for default locale (en) */
  description?: InputMaybe<Scalars['String']>;
  /** Manage document localizations */
  localizations?: InputMaybe<PromoTextUpdateLocalizationsInput>;
};

export type PromoTextUpdateLocalizationDataInput = {
  badge?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
};

export type PromoTextUpdateLocalizationInput = {
  data: PromoTextUpdateLocalizationDataInput;
  locale: Locale;
};

export type PromoTextUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<PromoTextCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<PromoTextUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<PromoTextUpsertLocalizationInput>>;
};

export type PromoTextUpdateManyInlineInput = {
  /** Create and connect multiple PromoText component instances */
  create?: InputMaybe<Array<PromoTextCreateWithPositionInput>>;
  /** Delete multiple PromoText documents */
  delete?: InputMaybe<Array<PromoTextWhereUniqueInput>>;
  /** Update multiple PromoText component instances */
  update?: InputMaybe<Array<PromoTextUpdateWithNestedWhereUniqueAndPositionInput>>;
  /** Upsert multiple PromoText component instances */
  upsert?: InputMaybe<Array<PromoTextUpsertWithNestedWhereUniqueAndPositionInput>>;
};

export type PromoTextUpdateManyInput = {
  /** badge input for default locale (en) */
  badge?: InputMaybe<Scalars['String']>;
  /** description input for default locale (en) */
  description?: InputMaybe<Scalars['String']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<PromoTextUpdateManyLocalizationsInput>;
};

export type PromoTextUpdateManyLocalizationDataInput = {
  badge?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
};

export type PromoTextUpdateManyLocalizationInput = {
  data: PromoTextUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type PromoTextUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<PromoTextUpdateManyLocalizationInput>>;
};

export type PromoTextUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: PromoTextUpdateManyInput;
  /** Document search */
  where: PromoTextWhereInput;
};

export type PromoTextUpdateOneInlineInput = {
  /** Create and connect one PromoText document */
  create?: InputMaybe<PromoTextCreateInput>;
  /** Delete currently connected PromoText document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single PromoText document */
  update?: InputMaybe<PromoTextUpdateWithNestedWhereUniqueInput>;
  /** Upsert single PromoText document */
  upsert?: InputMaybe<PromoTextUpsertWithNestedWhereUniqueInput>;
};

export type PromoTextUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<PromoTextUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: PromoTextWhereUniqueInput;
};

export type PromoTextUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: PromoTextUpdateInput;
  /** Unique document search */
  where: PromoTextWhereUniqueInput;
};

export type PromoTextUpsertInput = {
  /** Create document if it didn't exist */
  create: PromoTextCreateInput;
  /** Update document if it exists */
  update: PromoTextUpdateInput;
};

export type PromoTextUpsertLocalizationInput = {
  create: PromoTextCreateLocalizationDataInput;
  locale: Locale;
  update: PromoTextUpdateLocalizationDataInput;
};

export type PromoTextUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<PromoTextUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: PromoTextWhereUniqueInput;
};

export type PromoTextUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: PromoTextUpsertInput;
  /** Unique document search */
  where: PromoTextWhereUniqueInput;
};

/** Identifies documents */
export type PromoTextWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<PromoTextWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<PromoTextWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<PromoTextWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  badge?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  badge_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  badge_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  badge_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  badge_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  badge_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  badge_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  badge_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  badge_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  badge_starts_with?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  description_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
};

/** References PromoText record uniquely */
export type PromoTextWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type PromoTicketBanner = {
  __typename?: 'PromoTicketBanner';
  badge: Maybe<Text>;
  button: Maybe<Text>;
  clickHandler: Maybe<Scalars['Json']>;
  copyright: Maybe<Text>;
  description: Maybe<Text>;
  /** The unique identifier */
  id: Scalars['ID'];
  image: Maybe<Asset>;
  logo: Maybe<Asset>;
  showBadge: Scalars['Boolean'];
  /** System stage field */
  stage: Stage;
  subDescription: Maybe<Text>;
  title: Maybe<Text>;
};

export type PromoTicketBannerBadgeArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type PromoTicketBannerButtonArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type PromoTicketBannerCopyrightArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type PromoTicketBannerDescriptionArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type PromoTicketBannerImageArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type PromoTicketBannerLogoArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type PromoTicketBannerSubDescriptionArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type PromoTicketBannerTitleArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type PromoTicketBannerConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: PromoTicketBannerWhereUniqueInput;
};

/** A connection to a list of items. */
export type PromoTicketBannerConnection = {
  __typename?: 'PromoTicketBannerConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<PromoTicketBannerEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type PromoTicketBannerCreateInput = {
  badge?: InputMaybe<TextCreateOneInlineInput>;
  button?: InputMaybe<TextCreateOneInlineInput>;
  clickHandler?: InputMaybe<Scalars['Json']>;
  copyright?: InputMaybe<TextCreateOneInlineInput>;
  description?: InputMaybe<TextCreateOneInlineInput>;
  image?: InputMaybe<AssetCreateOneInlineInput>;
  logo?: InputMaybe<AssetCreateOneInlineInput>;
  showBadge: Scalars['Boolean'];
  subDescription?: InputMaybe<TextCreateOneInlineInput>;
  title?: InputMaybe<TextCreateOneInlineInput>;
};

export type PromoTicketBannerCreateManyInlineInput = {
  /** Create and connect multiple existing PromoTicketBanner documents */
  create?: InputMaybe<Array<PromoTicketBannerCreateInput>>;
};

export type PromoTicketBannerCreateOneInlineInput = {
  /** Create and connect one PromoTicketBanner document */
  create?: InputMaybe<PromoTicketBannerCreateInput>;
};

export type PromoTicketBannerCreateWithPositionInput = {
  /** Document to create */
  data: PromoTicketBannerCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type PromoTicketBannerEdge = {
  __typename?: 'PromoTicketBannerEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: PromoTicketBanner;
};

/** Identifies documents */
export type PromoTicketBannerManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<PromoTicketBannerWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<PromoTicketBannerWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<PromoTicketBannerWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  badge?: InputMaybe<TextWhereInput>;
  button?: InputMaybe<TextWhereInput>;
  /** All values containing the given json path. */
  clickHandler_json_path_exists?: InputMaybe<Scalars['String']>;
  /**
   * Recursively tries to find the provided JSON scalar value inside the field.
   * It does use an exact match when comparing values.
   * If you pass `null` as value the filter will be ignored.
   * Note: This filter fails if you try to look for a non scalar JSON value!
   */
  clickHandler_value_recursive?: InputMaybe<Scalars['Json']>;
  copyright?: InputMaybe<TextWhereInput>;
  description?: InputMaybe<TextWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  image?: InputMaybe<AssetWhereInput>;
  logo?: InputMaybe<AssetWhereInput>;
  showBadge?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  showBadge_not?: InputMaybe<Scalars['Boolean']>;
  subDescription?: InputMaybe<TextWhereInput>;
  title?: InputMaybe<TextWhereInput>;
};

export enum PromoTicketBannerOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  ShowBadgeAsc = 'showBadge_ASC',
  ShowBadgeDesc = 'showBadge_DESC',
}

export type PromoTicketBannerParent = Promo;

export type PromoTicketBannerParentConnectInput = {
  Promo?: InputMaybe<PromoConnectInput>;
};

export type PromoTicketBannerParentCreateInput = {
  Promo?: InputMaybe<PromoCreateInput>;
};

export type PromoTicketBannerParentCreateManyInlineInput = {
  /** Connect multiple existing PromoTicketBannerParent documents */
  connect?: InputMaybe<Array<PromoTicketBannerParentWhereUniqueInput>>;
  /** Create and connect multiple existing PromoTicketBannerParent documents */
  create?: InputMaybe<Array<PromoTicketBannerParentCreateInput>>;
};

export type PromoTicketBannerParentCreateOneInlineInput = {
  /** Connect one existing PromoTicketBannerParent document */
  connect?: InputMaybe<PromoTicketBannerParentWhereUniqueInput>;
  /** Create and connect one PromoTicketBannerParent document */
  create?: InputMaybe<PromoTicketBannerParentCreateInput>;
};

export type PromoTicketBannerParentUpdateInput = {
  Promo?: InputMaybe<PromoUpdateInput>;
};

export type PromoTicketBannerParentUpdateManyInlineInput = {
  /** Connect multiple existing PromoTicketBannerParent documents */
  connect?: InputMaybe<Array<PromoTicketBannerParentConnectInput>>;
  /** Create and connect multiple PromoTicketBannerParent documents */
  create?: InputMaybe<Array<PromoTicketBannerParentCreateInput>>;
  /** Delete multiple PromoTicketBannerParent documents */
  delete?: InputMaybe<Array<PromoTicketBannerParentWhereUniqueInput>>;
  /** Disconnect multiple PromoTicketBannerParent documents */
  disconnect?: InputMaybe<Array<PromoTicketBannerParentWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing PromoTicketBannerParent documents */
  set?: InputMaybe<Array<PromoTicketBannerParentWhereUniqueInput>>;
  /** Update multiple PromoTicketBannerParent documents */
  update?: InputMaybe<Array<PromoTicketBannerParentUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple PromoTicketBannerParent documents */
  upsert?: InputMaybe<Array<PromoTicketBannerParentUpsertWithNestedWhereUniqueInput>>;
};

export type PromoTicketBannerParentUpdateManyWithNestedWhereInput = {
  Promo?: InputMaybe<PromoUpdateManyWithNestedWhereInput>;
};

export type PromoTicketBannerParentUpdateOneInlineInput = {
  /** Connect existing PromoTicketBannerParent document */
  connect?: InputMaybe<PromoTicketBannerParentWhereUniqueInput>;
  /** Create and connect one PromoTicketBannerParent document */
  create?: InputMaybe<PromoTicketBannerParentCreateInput>;
  /** Delete currently connected PromoTicketBannerParent document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected PromoTicketBannerParent document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single PromoTicketBannerParent document */
  update?: InputMaybe<PromoTicketBannerParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single PromoTicketBannerParent document */
  upsert?: InputMaybe<PromoTicketBannerParentUpsertWithNestedWhereUniqueInput>;
};

export type PromoTicketBannerParentUpdateWithNestedWhereUniqueInput = {
  Promo?: InputMaybe<PromoUpdateWithNestedWhereUniqueInput>;
};

export type PromoTicketBannerParentUpsertWithNestedWhereUniqueInput = {
  Promo?: InputMaybe<PromoUpsertWithNestedWhereUniqueInput>;
};

export type PromoTicketBannerParentWhereInput = {
  Promo?: InputMaybe<PromoWhereInput>;
};

export type PromoTicketBannerParentWhereUniqueInput = {
  Promo?: InputMaybe<PromoWhereUniqueInput>;
};

export type PromoTicketBannerUpdateInput = {
  badge?: InputMaybe<TextUpdateOneInlineInput>;
  button?: InputMaybe<TextUpdateOneInlineInput>;
  clickHandler?: InputMaybe<Scalars['Json']>;
  copyright?: InputMaybe<TextUpdateOneInlineInput>;
  description?: InputMaybe<TextUpdateOneInlineInput>;
  image?: InputMaybe<AssetUpdateOneInlineInput>;
  logo?: InputMaybe<AssetUpdateOneInlineInput>;
  showBadge?: InputMaybe<Scalars['Boolean']>;
  subDescription?: InputMaybe<TextUpdateOneInlineInput>;
  title?: InputMaybe<TextUpdateOneInlineInput>;
};

export type PromoTicketBannerUpdateManyInlineInput = {
  /** Create and connect multiple PromoTicketBanner component instances */
  create?: InputMaybe<Array<PromoTicketBannerCreateWithPositionInput>>;
  /** Delete multiple PromoTicketBanner documents */
  delete?: InputMaybe<Array<PromoTicketBannerWhereUniqueInput>>;
  /** Update multiple PromoTicketBanner component instances */
  update?: InputMaybe<Array<PromoTicketBannerUpdateWithNestedWhereUniqueAndPositionInput>>;
  /** Upsert multiple PromoTicketBanner component instances */
  upsert?: InputMaybe<Array<PromoTicketBannerUpsertWithNestedWhereUniqueAndPositionInput>>;
};

export type PromoTicketBannerUpdateManyInput = {
  clickHandler?: InputMaybe<Scalars['Json']>;
  showBadge?: InputMaybe<Scalars['Boolean']>;
};

export type PromoTicketBannerUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: PromoTicketBannerUpdateManyInput;
  /** Document search */
  where: PromoTicketBannerWhereInput;
};

export type PromoTicketBannerUpdateOneInlineInput = {
  /** Create and connect one PromoTicketBanner document */
  create?: InputMaybe<PromoTicketBannerCreateInput>;
  /** Delete currently connected PromoTicketBanner document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single PromoTicketBanner document */
  update?: InputMaybe<PromoTicketBannerUpdateWithNestedWhereUniqueInput>;
  /** Upsert single PromoTicketBanner document */
  upsert?: InputMaybe<PromoTicketBannerUpsertWithNestedWhereUniqueInput>;
};

export type PromoTicketBannerUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<PromoTicketBannerUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: PromoTicketBannerWhereUniqueInput;
};

export type PromoTicketBannerUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: PromoTicketBannerUpdateInput;
  /** Unique document search */
  where: PromoTicketBannerWhereUniqueInput;
};

export type PromoTicketBannerUpsertInput = {
  /** Create document if it didn't exist */
  create: PromoTicketBannerCreateInput;
  /** Update document if it exists */
  update: PromoTicketBannerUpdateInput;
};

export type PromoTicketBannerUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<PromoTicketBannerUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: PromoTicketBannerWhereUniqueInput;
};

export type PromoTicketBannerUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: PromoTicketBannerUpsertInput;
  /** Unique document search */
  where: PromoTicketBannerWhereUniqueInput;
};

/** Identifies documents */
export type PromoTicketBannerWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<PromoTicketBannerWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<PromoTicketBannerWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<PromoTicketBannerWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  badge?: InputMaybe<TextWhereInput>;
  button?: InputMaybe<TextWhereInput>;
  /** All values containing the given json path. */
  clickHandler_json_path_exists?: InputMaybe<Scalars['String']>;
  /**
   * Recursively tries to find the provided JSON scalar value inside the field.
   * It does use an exact match when comparing values.
   * If you pass `null` as value the filter will be ignored.
   * Note: This filter fails if you try to look for a non scalar JSON value!
   */
  clickHandler_value_recursive?: InputMaybe<Scalars['Json']>;
  copyright?: InputMaybe<TextWhereInput>;
  description?: InputMaybe<TextWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  image?: InputMaybe<AssetWhereInput>;
  logo?: InputMaybe<AssetWhereInput>;
  showBadge?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  showBadge_not?: InputMaybe<Scalars['Boolean']>;
  subDescription?: InputMaybe<TextWhereInput>;
  title?: InputMaybe<TextWhereInput>;
};

/** References PromoTicketBanner record uniquely */
export type PromoTicketBannerWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type PromoTicketText = {
  __typename?: 'PromoTicketText';
  badge: Maybe<Text>;
  description: Maybe<Text>;
  /** The unique identifier */
  id: Scalars['ID'];
  skus: Maybe<Scalars['Json']>;
  /** System stage field */
  stage: Stage;
  ticketTypes: Maybe<Scalars['Json']>;
  validity: Array<DateRange>;
};

export type PromoTicketTextBadgeArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type PromoTicketTextDescriptionArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type PromoTicketTextValidityArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<DateRangeOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<DateRangeWhereInput>;
};

export type PromoTicketTextConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: PromoTicketTextWhereUniqueInput;
};

/** A connection to a list of items. */
export type PromoTicketTextConnection = {
  __typename?: 'PromoTicketTextConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<PromoTicketTextEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type PromoTicketTextCreateInput = {
  badge?: InputMaybe<TextCreateOneInlineInput>;
  description?: InputMaybe<TextCreateOneInlineInput>;
  skus?: InputMaybe<Scalars['Json']>;
  ticketTypes?: InputMaybe<Scalars['Json']>;
  validity?: InputMaybe<DateRangeCreateManyInlineInput>;
};

export type PromoTicketTextCreateManyInlineInput = {
  /** Create and connect multiple existing PromoTicketText documents */
  create?: InputMaybe<Array<PromoTicketTextCreateInput>>;
};

export type PromoTicketTextCreateOneInlineInput = {
  /** Create and connect one PromoTicketText document */
  create?: InputMaybe<PromoTicketTextCreateInput>;
};

export type PromoTicketTextCreateWithPositionInput = {
  /** Document to create */
  data: PromoTicketTextCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type PromoTicketTextEdge = {
  __typename?: 'PromoTicketTextEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: PromoTicketText;
};

/** Identifies documents */
export type PromoTicketTextManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<PromoTicketTextWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<PromoTicketTextWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<PromoTicketTextWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  badge?: InputMaybe<TextWhereInput>;
  description?: InputMaybe<TextWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values containing the given json path. */
  skus_json_path_exists?: InputMaybe<Scalars['String']>;
  /**
   * Recursively tries to find the provided JSON scalar value inside the field.
   * It does use an exact match when comparing values.
   * If you pass `null` as value the filter will be ignored.
   * Note: This filter fails if you try to look for a non scalar JSON value!
   */
  skus_value_recursive?: InputMaybe<Scalars['Json']>;
  /** All values containing the given json path. */
  ticketTypes_json_path_exists?: InputMaybe<Scalars['String']>;
  /**
   * Recursively tries to find the provided JSON scalar value inside the field.
   * It does use an exact match when comparing values.
   * If you pass `null` as value the filter will be ignored.
   * Note: This filter fails if you try to look for a non scalar JSON value!
   */
  ticketTypes_value_recursive?: InputMaybe<Scalars['Json']>;
  validity_every?: InputMaybe<DateRangeWhereInput>;
  validity_none?: InputMaybe<DateRangeWhereInput>;
  validity_some?: InputMaybe<DateRangeWhereInput>;
};

export enum PromoTicketTextOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
}

export type PromoTicketTextParent = Promo;

export type PromoTicketTextParentConnectInput = {
  Promo?: InputMaybe<PromoConnectInput>;
};

export type PromoTicketTextParentCreateInput = {
  Promo?: InputMaybe<PromoCreateInput>;
};

export type PromoTicketTextParentCreateManyInlineInput = {
  /** Connect multiple existing PromoTicketTextParent documents */
  connect?: InputMaybe<Array<PromoTicketTextParentWhereUniqueInput>>;
  /** Create and connect multiple existing PromoTicketTextParent documents */
  create?: InputMaybe<Array<PromoTicketTextParentCreateInput>>;
};

export type PromoTicketTextParentCreateOneInlineInput = {
  /** Connect one existing PromoTicketTextParent document */
  connect?: InputMaybe<PromoTicketTextParentWhereUniqueInput>;
  /** Create and connect one PromoTicketTextParent document */
  create?: InputMaybe<PromoTicketTextParentCreateInput>;
};

export type PromoTicketTextParentUpdateInput = {
  Promo?: InputMaybe<PromoUpdateInput>;
};

export type PromoTicketTextParentUpdateManyInlineInput = {
  /** Connect multiple existing PromoTicketTextParent documents */
  connect?: InputMaybe<Array<PromoTicketTextParentConnectInput>>;
  /** Create and connect multiple PromoTicketTextParent documents */
  create?: InputMaybe<Array<PromoTicketTextParentCreateInput>>;
  /** Delete multiple PromoTicketTextParent documents */
  delete?: InputMaybe<Array<PromoTicketTextParentWhereUniqueInput>>;
  /** Disconnect multiple PromoTicketTextParent documents */
  disconnect?: InputMaybe<Array<PromoTicketTextParentWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing PromoTicketTextParent documents */
  set?: InputMaybe<Array<PromoTicketTextParentWhereUniqueInput>>;
  /** Update multiple PromoTicketTextParent documents */
  update?: InputMaybe<Array<PromoTicketTextParentUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple PromoTicketTextParent documents */
  upsert?: InputMaybe<Array<PromoTicketTextParentUpsertWithNestedWhereUniqueInput>>;
};

export type PromoTicketTextParentUpdateManyWithNestedWhereInput = {
  Promo?: InputMaybe<PromoUpdateManyWithNestedWhereInput>;
};

export type PromoTicketTextParentUpdateOneInlineInput = {
  /** Connect existing PromoTicketTextParent document */
  connect?: InputMaybe<PromoTicketTextParentWhereUniqueInput>;
  /** Create and connect one PromoTicketTextParent document */
  create?: InputMaybe<PromoTicketTextParentCreateInput>;
  /** Delete currently connected PromoTicketTextParent document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected PromoTicketTextParent document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single PromoTicketTextParent document */
  update?: InputMaybe<PromoTicketTextParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single PromoTicketTextParent document */
  upsert?: InputMaybe<PromoTicketTextParentUpsertWithNestedWhereUniqueInput>;
};

export type PromoTicketTextParentUpdateWithNestedWhereUniqueInput = {
  Promo?: InputMaybe<PromoUpdateWithNestedWhereUniqueInput>;
};

export type PromoTicketTextParentUpsertWithNestedWhereUniqueInput = {
  Promo?: InputMaybe<PromoUpsertWithNestedWhereUniqueInput>;
};

export type PromoTicketTextParentWhereInput = {
  Promo?: InputMaybe<PromoWhereInput>;
};

export type PromoTicketTextParentWhereUniqueInput = {
  Promo?: InputMaybe<PromoWhereUniqueInput>;
};

export type PromoTicketTextUpdateInput = {
  badge?: InputMaybe<TextUpdateOneInlineInput>;
  description?: InputMaybe<TextUpdateOneInlineInput>;
  skus?: InputMaybe<Scalars['Json']>;
  ticketTypes?: InputMaybe<Scalars['Json']>;
  validity?: InputMaybe<DateRangeUpdateManyInlineInput>;
};

export type PromoTicketTextUpdateManyInlineInput = {
  /** Create and connect multiple PromoTicketText component instances */
  create?: InputMaybe<Array<PromoTicketTextCreateWithPositionInput>>;
  /** Delete multiple PromoTicketText documents */
  delete?: InputMaybe<Array<PromoTicketTextWhereUniqueInput>>;
  /** Update multiple PromoTicketText component instances */
  update?: InputMaybe<Array<PromoTicketTextUpdateWithNestedWhereUniqueAndPositionInput>>;
  /** Upsert multiple PromoTicketText component instances */
  upsert?: InputMaybe<Array<PromoTicketTextUpsertWithNestedWhereUniqueAndPositionInput>>;
};

export type PromoTicketTextUpdateManyInput = {
  skus?: InputMaybe<Scalars['Json']>;
  ticketTypes?: InputMaybe<Scalars['Json']>;
};

export type PromoTicketTextUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: PromoTicketTextUpdateManyInput;
  /** Document search */
  where: PromoTicketTextWhereInput;
};

export type PromoTicketTextUpdateOneInlineInput = {
  /** Create and connect one PromoTicketText document */
  create?: InputMaybe<PromoTicketTextCreateInput>;
  /** Delete currently connected PromoTicketText document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single PromoTicketText document */
  update?: InputMaybe<PromoTicketTextUpdateWithNestedWhereUniqueInput>;
  /** Upsert single PromoTicketText document */
  upsert?: InputMaybe<PromoTicketTextUpsertWithNestedWhereUniqueInput>;
};

export type PromoTicketTextUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<PromoTicketTextUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: PromoTicketTextWhereUniqueInput;
};

export type PromoTicketTextUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: PromoTicketTextUpdateInput;
  /** Unique document search */
  where: PromoTicketTextWhereUniqueInput;
};

export type PromoTicketTextUpsertInput = {
  /** Create document if it didn't exist */
  create: PromoTicketTextCreateInput;
  /** Update document if it exists */
  update: PromoTicketTextUpdateInput;
};

export type PromoTicketTextUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<PromoTicketTextUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: PromoTicketTextWhereUniqueInput;
};

export type PromoTicketTextUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: PromoTicketTextUpsertInput;
  /** Unique document search */
  where: PromoTicketTextWhereUniqueInput;
};

/** Identifies documents */
export type PromoTicketTextWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<PromoTicketTextWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<PromoTicketTextWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<PromoTicketTextWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  badge?: InputMaybe<TextWhereInput>;
  description?: InputMaybe<TextWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values containing the given json path. */
  skus_json_path_exists?: InputMaybe<Scalars['String']>;
  /**
   * Recursively tries to find the provided JSON scalar value inside the field.
   * It does use an exact match when comparing values.
   * If you pass `null` as value the filter will be ignored.
   * Note: This filter fails if you try to look for a non scalar JSON value!
   */
  skus_value_recursive?: InputMaybe<Scalars['Json']>;
  /** All values containing the given json path. */
  ticketTypes_json_path_exists?: InputMaybe<Scalars['String']>;
  /**
   * Recursively tries to find the provided JSON scalar value inside the field.
   * It does use an exact match when comparing values.
   * If you pass `null` as value the filter will be ignored.
   * Note: This filter fails if you try to look for a non scalar JSON value!
   */
  ticketTypes_value_recursive?: InputMaybe<Scalars['Json']>;
  validity_every?: InputMaybe<DateRangeWhereInput>;
  validity_none?: InputMaybe<DateRangeWhereInput>;
  validity_some?: InputMaybe<DateRangeWhereInput>;
};

/** References PromoTicketText record uniquely */
export type PromoTicketTextWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type PromoUpdateInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  attraction?: InputMaybe<AttractionUpdateOneInlineInput>;
  endDate?: InputMaybe<Scalars['Date']>;
  internalTitle?: InputMaybe<Scalars['String']>;
  /** localizationFix input for default locale (en) */
  localizationFix?: InputMaybe<Scalars['String']>;
  /** Manage document localizations */
  localizations?: InputMaybe<PromoUpdateLocalizationsInput>;
  placements?: InputMaybe<Array<PromoPlacement>>;
  product?: InputMaybe<ProductUpdateOneInlineInput>;
  qabu?: InputMaybe<Scalars['Json']>;
  startDate?: InputMaybe<Scalars['Date']>;
  template?: InputMaybe<PromotemplateUnionUpdateOneInlineInput>;
};

export type PromoUpdateLocalizationDataInput = {
  localizationFix?: InputMaybe<Scalars['String']>;
};

export type PromoUpdateLocalizationInput = {
  data: PromoUpdateLocalizationDataInput;
  locale: Locale;
};

export type PromoUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<PromoCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<PromoUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<PromoUpsertLocalizationInput>>;
};

export type PromoUpdateManyInlineInput = {
  /** Connect multiple existing Promo documents */
  connect?: InputMaybe<Array<PromoConnectInput>>;
  /** Create and connect multiple Promo documents */
  create?: InputMaybe<Array<PromoCreateInput>>;
  /** Delete multiple Promo documents */
  delete?: InputMaybe<Array<PromoWhereUniqueInput>>;
  /** Disconnect multiple Promo documents */
  disconnect?: InputMaybe<Array<PromoWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing Promo documents */
  set?: InputMaybe<Array<PromoWhereUniqueInput>>;
  /** Update multiple Promo documents */
  update?: InputMaybe<Array<PromoUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple Promo documents */
  upsert?: InputMaybe<Array<PromoUpsertWithNestedWhereUniqueInput>>;
};

export type PromoUpdateManyInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  endDate?: InputMaybe<Scalars['Date']>;
  internalTitle?: InputMaybe<Scalars['String']>;
  /** localizationFix input for default locale (en) */
  localizationFix?: InputMaybe<Scalars['String']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<PromoUpdateManyLocalizationsInput>;
  placements?: InputMaybe<Array<PromoPlacement>>;
  qabu?: InputMaybe<Scalars['Json']>;
  startDate?: InputMaybe<Scalars['Date']>;
};

export type PromoUpdateManyLocalizationDataInput = {
  localizationFix?: InputMaybe<Scalars['String']>;
};

export type PromoUpdateManyLocalizationInput = {
  data: PromoUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type PromoUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<PromoUpdateManyLocalizationInput>>;
};

export type PromoUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: PromoUpdateManyInput;
  /** Document search */
  where: PromoWhereInput;
};

export type PromoUpdateOneInlineInput = {
  /** Connect existing Promo document */
  connect?: InputMaybe<PromoWhereUniqueInput>;
  /** Create and connect one Promo document */
  create?: InputMaybe<PromoCreateInput>;
  /** Delete currently connected Promo document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected Promo document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single Promo document */
  update?: InputMaybe<PromoUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Promo document */
  upsert?: InputMaybe<PromoUpsertWithNestedWhereUniqueInput>;
};

export type PromoUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: PromoUpdateInput;
  /** Unique document search */
  where: PromoWhereUniqueInput;
};

export type PromoUpsertInput = {
  /** Create document if it didn't exist */
  create: PromoCreateInput;
  /** Update document if it exists */
  update: PromoUpdateInput;
};

export type PromoUpsertLocalizationInput = {
  create: PromoCreateLocalizationDataInput;
  locale: Locale;
  update: PromoUpdateLocalizationDataInput;
};

export type PromoUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: PromoUpsertInput;
  /** Unique document search */
  where: PromoWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type PromoWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']>;
};

/** Identifies documents */
export type PromoWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<PromoWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<PromoWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<PromoWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  active_not?: InputMaybe<Scalars['Boolean']>;
  attraction?: InputMaybe<AttractionWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<PromoWhereStageInput>;
  documentInStages_none?: InputMaybe<PromoWhereStageInput>;
  documentInStages_some?: InputMaybe<PromoWhereStageInput>;
  endDate?: InputMaybe<Scalars['Date']>;
  /** All values greater than the given value. */
  endDate_gt?: InputMaybe<Scalars['Date']>;
  /** All values greater than or equal the given value. */
  endDate_gte?: InputMaybe<Scalars['Date']>;
  /** All values that are contained in given list. */
  endDate_in?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  /** All values less than the given value. */
  endDate_lt?: InputMaybe<Scalars['Date']>;
  /** All values less than or equal the given value. */
  endDate_lte?: InputMaybe<Scalars['Date']>;
  /** Any other value that exists and is not equal to the given value. */
  endDate_not?: InputMaybe<Scalars['Date']>;
  /** All values that are not contained in given list. */
  endDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  internalTitle?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  internalTitle_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  internalTitle_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  internalTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  internalTitle_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  internalTitle_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  internalTitle_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  internalTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  internalTitle_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  internalTitle_starts_with?: InputMaybe<Scalars['String']>;
  localizationFix?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  localizationFix_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  localizationFix_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  localizationFix_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  localizationFix_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  localizationFix_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  localizationFix_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  localizationFix_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  localizationFix_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  localizationFix_starts_with?: InputMaybe<Scalars['String']>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  placements?: InputMaybe<Array<PromoPlacement>>;
  /** Matches if the field array contains *all* items provided to the filter */
  placements_contains_all?: InputMaybe<Array<PromoPlacement>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  placements_contains_none?: InputMaybe<Array<PromoPlacement>>;
  /** Matches if the field array contains at least one item provided to the filter */
  placements_contains_some?: InputMaybe<Array<PromoPlacement>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  placements_not?: InputMaybe<Array<PromoPlacement>>;
  product?: InputMaybe<ProductWhereInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  /** All values containing the given json path. */
  qabu_json_path_exists?: InputMaybe<Scalars['String']>;
  /**
   * Recursively tries to find the provided JSON scalar value inside the field.
   * It does use an exact match when comparing values.
   * If you pass `null` as value the filter will be ignored.
   * Note: This filter fails if you try to look for a non scalar JSON value!
   */
  qabu_value_recursive?: InputMaybe<Scalars['Json']>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  startDate?: InputMaybe<Scalars['Date']>;
  /** All values greater than the given value. */
  startDate_gt?: InputMaybe<Scalars['Date']>;
  /** All values greater than or equal the given value. */
  startDate_gte?: InputMaybe<Scalars['Date']>;
  /** All values that are contained in given list. */
  startDate_in?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  /** All values less than the given value. */
  startDate_lt?: InputMaybe<Scalars['Date']>;
  /** All values less than or equal the given value. */
  startDate_lte?: InputMaybe<Scalars['Date']>;
  /** Any other value that exists and is not equal to the given value. */
  startDate_not?: InputMaybe<Scalars['Date']>;
  /** All values that are not contained in given list. */
  startDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  /** All values in which the modular component is connected to the given models */
  template?: InputMaybe<PromotemplateUnionWhereInput>;
  /** All values in which the union is empty. */
  template_empty?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type PromoWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<PromoWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<PromoWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<PromoWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<PromoWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References Promo record uniquely */
export type PromoWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type PromotemplateUnion =
  | CrossPromoBanner
  | PromoBanner
  | PromoText
  | PromoTicketBanner
  | PromoTicketText;

export type PromotemplateUnionConnectInput = {
  CrossPromoBanner?: InputMaybe<CrossPromoBannerConnectInput>;
  PromoBanner?: InputMaybe<PromoBannerConnectInput>;
  PromoText?: InputMaybe<PromoTextConnectInput>;
  PromoTicketBanner?: InputMaybe<PromoTicketBannerConnectInput>;
  PromoTicketText?: InputMaybe<PromoTicketTextConnectInput>;
};

export type PromotemplateUnionCreateInput = {
  CrossPromoBanner?: InputMaybe<CrossPromoBannerCreateInput>;
  PromoBanner?: InputMaybe<PromoBannerCreateInput>;
  PromoText?: InputMaybe<PromoTextCreateInput>;
  PromoTicketBanner?: InputMaybe<PromoTicketBannerCreateInput>;
  PromoTicketText?: InputMaybe<PromoTicketTextCreateInput>;
};

export type PromotemplateUnionCreateManyInlineInput = {
  /** Create and connect multiple existing PromotemplateUnion documents */
  create?: InputMaybe<Array<PromotemplateUnionCreateInput>>;
};

export type PromotemplateUnionCreateOneInlineInput = {
  /** Create and connect one PromotemplateUnion document */
  create?: InputMaybe<PromotemplateUnionCreateInput>;
};

export type PromotemplateUnionCreateWithPositionInput = {
  CrossPromoBanner?: InputMaybe<CrossPromoBannerCreateWithPositionInput>;
  PromoBanner?: InputMaybe<PromoBannerCreateWithPositionInput>;
  PromoText?: InputMaybe<PromoTextCreateWithPositionInput>;
  PromoTicketBanner?: InputMaybe<PromoTicketBannerCreateWithPositionInput>;
  PromoTicketText?: InputMaybe<PromoTicketTextCreateWithPositionInput>;
};

export type PromotemplateUnionUpdateInput = {
  CrossPromoBanner?: InputMaybe<CrossPromoBannerUpdateInput>;
  PromoBanner?: InputMaybe<PromoBannerUpdateInput>;
  PromoText?: InputMaybe<PromoTextUpdateInput>;
  PromoTicketBanner?: InputMaybe<PromoTicketBannerUpdateInput>;
  PromoTicketText?: InputMaybe<PromoTicketTextUpdateInput>;
};

export type PromotemplateUnionUpdateManyInlineInput = {
  /** Create and connect multiple PromotemplateUnion component instances */
  create?: InputMaybe<Array<PromotemplateUnionCreateWithPositionInput>>;
  /** Delete multiple PromotemplateUnion documents */
  delete?: InputMaybe<Array<PromotemplateUnionWhereUniqueInput>>;
  /** Update multiple PromotemplateUnion component instances */
  update?: InputMaybe<Array<PromotemplateUnionUpdateWithNestedWhereUniqueAndPositionInput>>;
  /** Upsert multiple PromotemplateUnion component instances */
  upsert?: InputMaybe<Array<PromotemplateUnionUpsertWithNestedWhereUniqueAndPositionInput>>;
};

export type PromotemplateUnionUpdateManyWithNestedWhereInput = {
  CrossPromoBanner?: InputMaybe<CrossPromoBannerUpdateManyWithNestedWhereInput>;
  PromoBanner?: InputMaybe<PromoBannerUpdateManyWithNestedWhereInput>;
  PromoText?: InputMaybe<PromoTextUpdateManyWithNestedWhereInput>;
  PromoTicketBanner?: InputMaybe<PromoTicketBannerUpdateManyWithNestedWhereInput>;
  PromoTicketText?: InputMaybe<PromoTicketTextUpdateManyWithNestedWhereInput>;
};

export type PromotemplateUnionUpdateOneInlineInput = {
  /** Create and connect one PromotemplateUnion document */
  create?: InputMaybe<PromotemplateUnionCreateInput>;
  /** Delete currently connected PromotemplateUnion document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single PromotemplateUnion document */
  update?: InputMaybe<PromotemplateUnionUpdateWithNestedWhereUniqueInput>;
  /** Upsert single PromotemplateUnion document */
  upsert?: InputMaybe<PromotemplateUnionUpsertWithNestedWhereUniqueInput>;
};

export type PromotemplateUnionUpdateWithNestedWhereUniqueAndPositionInput = {
  CrossPromoBanner?: InputMaybe<CrossPromoBannerUpdateWithNestedWhereUniqueAndPositionInput>;
  PromoBanner?: InputMaybe<PromoBannerUpdateWithNestedWhereUniqueAndPositionInput>;
  PromoText?: InputMaybe<PromoTextUpdateWithNestedWhereUniqueAndPositionInput>;
  PromoTicketBanner?: InputMaybe<PromoTicketBannerUpdateWithNestedWhereUniqueAndPositionInput>;
  PromoTicketText?: InputMaybe<PromoTicketTextUpdateWithNestedWhereUniqueAndPositionInput>;
};

export type PromotemplateUnionUpdateWithNestedWhereUniqueInput = {
  CrossPromoBanner?: InputMaybe<CrossPromoBannerUpdateWithNestedWhereUniqueInput>;
  PromoBanner?: InputMaybe<PromoBannerUpdateWithNestedWhereUniqueInput>;
  PromoText?: InputMaybe<PromoTextUpdateWithNestedWhereUniqueInput>;
  PromoTicketBanner?: InputMaybe<PromoTicketBannerUpdateWithNestedWhereUniqueInput>;
  PromoTicketText?: InputMaybe<PromoTicketTextUpdateWithNestedWhereUniqueInput>;
};

export type PromotemplateUnionUpsertWithNestedWhereUniqueAndPositionInput = {
  CrossPromoBanner?: InputMaybe<CrossPromoBannerUpsertWithNestedWhereUniqueAndPositionInput>;
  PromoBanner?: InputMaybe<PromoBannerUpsertWithNestedWhereUniqueAndPositionInput>;
  PromoText?: InputMaybe<PromoTextUpsertWithNestedWhereUniqueAndPositionInput>;
  PromoTicketBanner?: InputMaybe<PromoTicketBannerUpsertWithNestedWhereUniqueAndPositionInput>;
  PromoTicketText?: InputMaybe<PromoTicketTextUpsertWithNestedWhereUniqueAndPositionInput>;
};

export type PromotemplateUnionUpsertWithNestedWhereUniqueInput = {
  CrossPromoBanner?: InputMaybe<CrossPromoBannerUpsertWithNestedWhereUniqueInput>;
  PromoBanner?: InputMaybe<PromoBannerUpsertWithNestedWhereUniqueInput>;
  PromoText?: InputMaybe<PromoTextUpsertWithNestedWhereUniqueInput>;
  PromoTicketBanner?: InputMaybe<PromoTicketBannerUpsertWithNestedWhereUniqueInput>;
  PromoTicketText?: InputMaybe<PromoTicketTextUpsertWithNestedWhereUniqueInput>;
};

export type PromotemplateUnionWhereInput = {
  CrossPromoBanner?: InputMaybe<CrossPromoBannerWhereInput>;
  PromoBanner?: InputMaybe<PromoBannerWhereInput>;
  PromoText?: InputMaybe<PromoTextWhereInput>;
  PromoTicketBanner?: InputMaybe<PromoTicketBannerWhereInput>;
  PromoTicketText?: InputMaybe<PromoTicketTextWhereInput>;
};

export type PromotemplateUnionWhereUniqueInput = {
  CrossPromoBanner?: InputMaybe<CrossPromoBannerWhereUniqueInput>;
  PromoBanner?: InputMaybe<PromoBannerWhereUniqueInput>;
  PromoText?: InputMaybe<PromoTextWhereUniqueInput>;
  PromoTicketBanner?: InputMaybe<PromoTicketBannerWhereUniqueInput>;
  PromoTicketText?: InputMaybe<PromoTicketTextWhereUniqueInput>;
};

export type PublishLocaleInput = {
  /** Locales to publish */
  locale: Locale;
  /** Stages to publish selected locales to */
  stages: Array<Stage>;
};

export type Query = {
  __typename?: 'Query';
  /** Retrieve a single addOn */
  addOn: Maybe<AddOn>;
  /** Retrieve document version */
  addOnVersion: Maybe<DocumentVersion>;
  /** Retrieve multiple addOns */
  addOns: Array<AddOn>;
  /** Retrieve multiple addOns using the Relay connection interface */
  addOnsConnection: AddOnConnection;
  /** Retrieve a single alacarteTicket */
  alacarteTicket: Maybe<AlacarteTicket>;
  /** Retrieve a single alacarteTicketType */
  alacarteTicketType: Maybe<AlacarteTicketType>;
  /** Retrieve document version */
  alacarteTicketTypeVersion: Maybe<DocumentVersion>;
  /** Retrieve multiple alacarteTicketTypes */
  alacarteTicketTypes: Array<AlacarteTicketType>;
  /** Retrieve multiple alacarteTicketTypes using the Relay connection interface */
  alacarteTicketTypesConnection: AlacarteTicketTypeConnection;
  /** Retrieve document version */
  alacarteTicketVersion: Maybe<DocumentVersion>;
  /** Retrieve multiple alacarteTickets */
  alacarteTickets: Array<AlacarteTicket>;
  /** Retrieve multiple alacarteTickets using the Relay connection interface */
  alacarteTicketsConnection: AlacarteTicketConnection;
  /** Retrieve a single article */
  article: Maybe<Article>;
  /** Retrieve multiple articleCategories */
  articleCategories: Array<ArticleCategory>;
  /** Retrieve multiple articleCategories using the Relay connection interface */
  articleCategoriesConnection: ArticleCategoryConnection;
  /** Retrieve a single articleCategory */
  articleCategory: Maybe<ArticleCategory>;
  /** Retrieve document version */
  articleCategoryVersion: Maybe<DocumentVersion>;
  /** Retrieve document version */
  articleVersion: Maybe<DocumentVersion>;
  /** Retrieve multiple articles */
  articles: Array<Article>;
  /** Retrieve multiple articles using the Relay connection interface */
  articlesConnection: ArticleConnection;
  /** Retrieve a single asset */
  asset: Maybe<Asset>;
  /** Retrieve document version */
  assetVersion: Maybe<DocumentVersion>;
  /** Retrieve multiple assets */
  assets: Array<Asset>;
  /** Retrieve multiple assets using the Relay connection interface */
  assetsConnection: AssetConnection;
  /** Retrieve a single attraction */
  attraction: Maybe<Attraction>;
  /** Retrieve a single attractionAlacarte */
  attractionAlacarte: Maybe<AttractionAlacarte>;
  /** Retrieve document version */
  attractionAlacarteVersion: Maybe<DocumentVersion>;
  /** Retrieve multiple attractionAlacartes */
  attractionAlacartes: Array<AttractionAlacarte>;
  /** Retrieve multiple attractionAlacartes using the Relay connection interface */
  attractionAlacartesConnection: AttractionAlacarteConnection;
  /** Retrieve multiple attractionAvailabilities */
  attractionAvailabilities: Array<AttractionAvailability>;
  /** Retrieve multiple attractionAvailabilities using the Relay connection interface */
  attractionAvailabilitiesConnection: AttractionAvailabilityConnection;
  /** Retrieve a single attractionAvailability */
  attractionAvailability: Maybe<AttractionAvailability>;
  /** Retrieve document version */
  attractionAvailabilityVersion: Maybe<DocumentVersion>;
  /** Retrieve a single attractionLocation */
  attractionLocation: Maybe<AttractionLocation>;
  /** Retrieve document version */
  attractionLocationVersion: Maybe<DocumentVersion>;
  /** Retrieve multiple attractionLocations */
  attractionLocations: Array<AttractionLocation>;
  /** Retrieve multiple attractionLocations using the Relay connection interface */
  attractionLocationsConnection: AttractionLocationConnection;
  /** Retrieve a single attractionOffer */
  attractionOffer: Maybe<AttractionOffer>;
  /** Retrieve document version */
  attractionOfferVersion: Maybe<DocumentVersion>;
  /** Retrieve multiple attractionOffers */
  attractionOffers: Array<AttractionOffer>;
  /** Retrieve multiple attractionOffers using the Relay connection interface */
  attractionOffersConnection: AttractionOfferConnection;
  /** Retrieve a single attractionPrice */
  attractionPrice: Maybe<AttractionPrice>;
  /** Retrieve document version */
  attractionPriceVersion: Maybe<DocumentVersion>;
  /** Retrieve multiple attractionPrices */
  attractionPrices: Array<AttractionPrice>;
  /** Retrieve multiple attractionPrices using the Relay connection interface */
  attractionPricesConnection: AttractionPriceConnection;
  /** Retrieve a single attractionReservation */
  attractionReservation: Maybe<AttractionReservation>;
  /** Retrieve document version */
  attractionReservationVersion: Maybe<DocumentVersion>;
  /** Retrieve multiple attractionReservations */
  attractionReservations: Array<AttractionReservation>;
  /** Retrieve multiple attractionReservations using the Relay connection interface */
  attractionReservationsConnection: AttractionReservationConnection;
  /** Retrieve a single attractionVariant */
  attractionVariant: Maybe<AttractionVariant>;
  /** Retrieve document version */
  attractionVariantVersion: Maybe<DocumentVersion>;
  /** Retrieve multiple attractionVariants */
  attractionVariants: Array<AttractionVariant>;
  /** Retrieve multiple attractionVariants using the Relay connection interface */
  attractionVariantsConnection: AttractionVariantConnection;
  /** Retrieve document version */
  attractionVersion: Maybe<DocumentVersion>;
  /** Retrieve multiple attractions */
  attractions: Array<Attraction>;
  /** Retrieve multiple attractions using the Relay connection interface */
  attractionsConnection: AttractionConnection;
  /** Retrieve a single author */
  author: Maybe<Author>;
  /** Retrieve document version */
  authorVersion: Maybe<DocumentVersion>;
  /** Retrieve multiple authors */
  authors: Array<Author>;
  /** Retrieve multiple authors using the Relay connection interface */
  authorsConnection: AuthorConnection;
  /** Retrieve a single block */
  block: Maybe<Block>;
  /** Retrieve a single blockGroup */
  blockGroup: Maybe<BlockGroup>;
  /** Retrieve document version */
  blockGroupVersion: Maybe<DocumentVersion>;
  /** Retrieve multiple blockGroups */
  blockGroups: Array<BlockGroup>;
  /** Retrieve multiple blockGroups using the Relay connection interface */
  blockGroupsConnection: BlockGroupConnection;
  /** Retrieve document version */
  blockVersion: Maybe<DocumentVersion>;
  /** Retrieve multiple blocks */
  blocks: Array<Block>;
  /** Retrieve multiple blocks using the Relay connection interface */
  blocksConnection: BlockConnection;
  /** Retrieve a single detailBlock */
  detailBlock: Maybe<DetailBlock>;
  /** Retrieve document version */
  detailBlockVersion: Maybe<DocumentVersion>;
  /** Retrieve multiple detailBlocks */
  detailBlocks: Array<DetailBlock>;
  /** Retrieve multiple detailBlocks using the Relay connection interface */
  detailBlocksConnection: DetailBlockConnection;
  /** Retrieve a single generalReservation */
  generalReservation: Maybe<GeneralReservation>;
  /** Retrieve document version */
  generalReservationVersion: Maybe<DocumentVersion>;
  /** Retrieve multiple generalReservations */
  generalReservations: Array<GeneralReservation>;
  /** Retrieve multiple generalReservations using the Relay connection interface */
  generalReservationsConnection: GeneralReservationConnection;
  /** Retrieve a single image */
  image: Maybe<Image>;
  /** Retrieve document version */
  imageVersion: Maybe<DocumentVersion>;
  /** Retrieve multiple images */
  images: Array<Image>;
  /** Retrieve multiple images using the Relay connection interface */
  imagesConnection: ImageConnection;
  /** Retrieve a single landingPage */
  landingPage: Maybe<LandingPage>;
  /** Retrieve document version */
  landingPageVersion: Maybe<DocumentVersion>;
  /** Retrieve multiple landingPages */
  landingPages: Array<LandingPage>;
  /** Retrieve multiple landingPages using the Relay connection interface */
  landingPagesConnection: LandingPageConnection;
  /** Retrieve a single link */
  link: Maybe<Link>;
  /** Retrieve document version */
  linkVersion: Maybe<DocumentVersion>;
  /** Retrieve multiple links */
  links: Array<Link>;
  /** Retrieve multiple links using the Relay connection interface */
  linksConnection: LinkConnection;
  /** Retrieve a single menu */
  menu: Maybe<Menu>;
  /** Retrieve document version */
  menuVersion: Maybe<DocumentVersion>;
  /** Retrieve multiple menus */
  menus: Array<Menu>;
  /** Retrieve multiple menus using the Relay connection interface */
  menusConnection: MenuConnection;
  /** Fetches an object given its ID */
  node: Maybe<Node>;
  /** Retrieve a single notice */
  notice: Maybe<Notice>;
  /** Retrieve document version */
  noticeVersion: Maybe<DocumentVersion>;
  /** Retrieve multiple notices */
  notices: Array<Notice>;
  /** Retrieve multiple notices using the Relay connection interface */
  noticesConnection: NoticeConnection;
  /** Retrieve a single page */
  page: Maybe<Page>;
  /** Retrieve a single pageTemplate */
  pageTemplate: Maybe<PageTemplate>;
  /** Retrieve document version */
  pageTemplateVersion: Maybe<DocumentVersion>;
  /** Retrieve multiple pageTemplates */
  pageTemplates: Array<PageTemplate>;
  /** Retrieve multiple pageTemplates using the Relay connection interface */
  pageTemplatesConnection: PageTemplateConnection;
  /** Retrieve document version */
  pageVersion: Maybe<DocumentVersion>;
  /** Retrieve multiple pages */
  pages: Array<Page>;
  /** Retrieve multiple pages using the Relay connection interface */
  pagesConnection: PageConnection;
  /** Retrieve a single partner */
  partner: Maybe<Partner>;
  /** Retrieve a single partnerOffer */
  partnerOffer: Maybe<PartnerOffer>;
  /** Retrieve document version */
  partnerOfferVersion: Maybe<DocumentVersion>;
  /** Retrieve multiple partnerOffers */
  partnerOffers: Array<PartnerOffer>;
  /** Retrieve multiple partnerOffers using the Relay connection interface */
  partnerOffersConnection: PartnerOfferConnection;
  /** Retrieve document version */
  partnerVersion: Maybe<DocumentVersion>;
  /** Retrieve multiple partners */
  partners: Array<Partner>;
  /** Retrieve multiple partners using the Relay connection interface */
  partnersConnection: PartnerConnection;
  /** Retrieve a single product */
  product: Maybe<Product>;
  /** Retrieve multiple productActivities */
  productActivities: Array<ProductActivity>;
  /** Retrieve multiple productActivities using the Relay connection interface */
  productActivitiesConnection: ProductActivityConnection;
  /** Retrieve a single productActivity */
  productActivity: Maybe<ProductActivity>;
  /** Retrieve a single productActivityItem */
  productActivityItem: Maybe<ProductActivityItem>;
  /** Retrieve document version */
  productActivityItemVersion: Maybe<DocumentVersion>;
  /** Retrieve multiple productActivityItems */
  productActivityItems: Array<ProductActivityItem>;
  /** Retrieve multiple productActivityItems using the Relay connection interface */
  productActivityItemsConnection: ProductActivityItemConnection;
  /** Retrieve document version */
  productActivityVersion: Maybe<DocumentVersion>;
  /** Retrieve a single productAttractionLineup */
  productAttractionLineup: Maybe<ProductAttractionLineup>;
  /** Retrieve document version */
  productAttractionLineupVersion: Maybe<DocumentVersion>;
  /** Retrieve multiple productAttractionLineups */
  productAttractionLineups: Array<ProductAttractionLineup>;
  /** Retrieve multiple productAttractionLineups using the Relay connection interface */
  productAttractionLineupsConnection: ProductAttractionLineupConnection;
  /** Retrieve a single productReservation */
  productReservation: Maybe<ProductReservation>;
  /** Retrieve document version */
  productReservationVersion: Maybe<DocumentVersion>;
  /** Retrieve multiple productReservations */
  productReservations: Array<ProductReservation>;
  /** Retrieve multiple productReservations using the Relay connection interface */
  productReservationsConnection: ProductReservationConnection;
  /** Retrieve document version */
  productVersion: Maybe<DocumentVersion>;
  /** Retrieve multiple products */
  products: Array<Product>;
  /** Retrieve multiple products using the Relay connection interface */
  productsConnection: ProductConnection;
  /** Retrieve a single promo */
  promo: Maybe<Promo>;
  /** Retrieve document version */
  promoVersion: Maybe<DocumentVersion>;
  /** Retrieve multiple promos */
  promos: Array<Promo>;
  /** Retrieve multiple promos using the Relay connection interface */
  promosConnection: PromoConnection;
  /** Retrieve a single reservationText */
  reservationText: Maybe<ReservationText>;
  /** Retrieve document version */
  reservationTextVersion: Maybe<DocumentVersion>;
  /** Retrieve multiple reservationTexts */
  reservationTexts: Array<ReservationText>;
  /** Retrieve multiple reservationTexts using the Relay connection interface */
  reservationTextsConnection: ReservationTextConnection;
  /** Retrieve a single sandbox */
  sandbox: Maybe<Sandbox>;
  /** Retrieve document version */
  sandboxVersion: Maybe<DocumentVersion>;
  /** Retrieve multiple sandboxes */
  sandboxes: Array<Sandbox>;
  /** Retrieve multiple sandboxes using the Relay connection interface */
  sandboxesConnection: SandboxConnection;
  /** Retrieve a single scheduledOperation */
  scheduledOperation: Maybe<ScheduledOperation>;
  /** Retrieve multiple scheduledOperations */
  scheduledOperations: Array<ScheduledOperation>;
  /** Retrieve multiple scheduledOperations using the Relay connection interface */
  scheduledOperationsConnection: ScheduledOperationConnection;
  /** Retrieve a single scheduledRelease */
  scheduledRelease: Maybe<ScheduledRelease>;
  /** Retrieve multiple scheduledReleases */
  scheduledReleases: Array<ScheduledRelease>;
  /** Retrieve multiple scheduledReleases using the Relay connection interface */
  scheduledReleasesConnection: ScheduledReleaseConnection;
  /** Retrieve a single seo */
  seo: Maybe<Seo>;
  /** Retrieve document version */
  seoVersion: Maybe<DocumentVersion>;
  /** Retrieve multiple seos */
  seos: Array<Seo>;
  /** Retrieve multiple seos using the Relay connection interface */
  seosConnection: SeoConnection;
  /** Retrieve a single text */
  text: Maybe<Text>;
  /** Retrieve a single textGroup */
  textGroup: Maybe<TextGroup>;
  /** Retrieve document version */
  textGroupVersion: Maybe<DocumentVersion>;
  /** Retrieve multiple textGroups */
  textGroups: Array<TextGroup>;
  /** Retrieve multiple textGroups using the Relay connection interface */
  textGroupsConnection: TextGroupConnection;
  /** Retrieve a single textList */
  textList: Maybe<TextList>;
  /** Retrieve document version */
  textListVersion: Maybe<DocumentVersion>;
  /** Retrieve multiple textLists */
  textLists: Array<TextList>;
  /** Retrieve multiple textLists using the Relay connection interface */
  textListsConnection: TextListConnection;
  /** Retrieve document version */
  textVersion: Maybe<DocumentVersion>;
  /** Retrieve multiple texts */
  texts: Array<Text>;
  /** Retrieve multiple texts using the Relay connection interface */
  textsConnection: TextConnection;
  /** Retrieve a single ticketGroup */
  ticketGroup: Maybe<TicketGroup>;
  /** Retrieve document version */
  ticketGroupVersion: Maybe<DocumentVersion>;
  /** Retrieve multiple ticketGroups */
  ticketGroups: Array<TicketGroup>;
  /** Retrieve multiple ticketGroups using the Relay connection interface */
  ticketGroupsConnection: TicketGroupConnection;
  /** Retrieve a single titleDescription */
  titleDescription: Maybe<TitleDescription>;
  /** Retrieve document version */
  titleDescriptionVersion: Maybe<DocumentVersion>;
  /** Retrieve multiple titleDescriptions */
  titleDescriptions: Array<TitleDescription>;
  /** Retrieve multiple titleDescriptions using the Relay connection interface */
  titleDescriptionsConnection: TitleDescriptionConnection;
  /** Retrieve a single user */
  user: Maybe<User>;
  /** Retrieve multiple users */
  users: Array<User>;
  /** Retrieve multiple users using the Relay connection interface */
  usersConnection: UserConnection;
  /** Retrieve a single video */
  video: Maybe<Video>;
  /** Retrieve document version */
  videoVersion: Maybe<DocumentVersion>;
  /** Retrieve multiple videos */
  videos: Array<Video>;
  /** Retrieve multiple videos using the Relay connection interface */
  videosConnection: VideoConnection;
};

export type QueryAddOnArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: AddOnWhereUniqueInput;
};

export type QueryAddOnVersionArgs = {
  where: VersionWhereInput;
};

export type QueryAddOnsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy: InputMaybe<AddOnOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where: InputMaybe<AddOnWhereInput>;
};

export type QueryAddOnsConnectionArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy: InputMaybe<AddOnOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where: InputMaybe<AddOnWhereInput>;
};

export type QueryAlacarteTicketArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: AlacarteTicketWhereUniqueInput;
};

export type QueryAlacarteTicketTypeArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: AlacarteTicketTypeWhereUniqueInput;
};

export type QueryAlacarteTicketTypeVersionArgs = {
  where: VersionWhereInput;
};

export type QueryAlacarteTicketTypesArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy: InputMaybe<AlacarteTicketTypeOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where: InputMaybe<AlacarteTicketTypeWhereInput>;
};

export type QueryAlacarteTicketTypesConnectionArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy: InputMaybe<AlacarteTicketTypeOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where: InputMaybe<AlacarteTicketTypeWhereInput>;
};

export type QueryAlacarteTicketVersionArgs = {
  where: VersionWhereInput;
};

export type QueryAlacarteTicketsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy: InputMaybe<AlacarteTicketOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where: InputMaybe<AlacarteTicketWhereInput>;
};

export type QueryAlacarteTicketsConnectionArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy: InputMaybe<AlacarteTicketOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where: InputMaybe<AlacarteTicketWhereInput>;
};

export type QueryArticleArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: ArticleWhereUniqueInput;
};

export type QueryArticleCategoriesArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy: InputMaybe<ArticleCategoryOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where: InputMaybe<ArticleCategoryWhereInput>;
};

export type QueryArticleCategoriesConnectionArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy: InputMaybe<ArticleCategoryOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where: InputMaybe<ArticleCategoryWhereInput>;
};

export type QueryArticleCategoryArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: ArticleCategoryWhereUniqueInput;
};

export type QueryArticleCategoryVersionArgs = {
  where: VersionWhereInput;
};

export type QueryArticleVersionArgs = {
  where: VersionWhereInput;
};

export type QueryArticlesArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy: InputMaybe<ArticleOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where: InputMaybe<ArticleWhereInput>;
};

export type QueryArticlesConnectionArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy: InputMaybe<ArticleOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where: InputMaybe<ArticleWhereInput>;
};

export type QueryAssetArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: AssetWhereUniqueInput;
};

export type QueryAssetVersionArgs = {
  where: VersionWhereInput;
};

export type QueryAssetsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy: InputMaybe<AssetOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where: InputMaybe<AssetWhereInput>;
};

export type QueryAssetsConnectionArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy: InputMaybe<AssetOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where: InputMaybe<AssetWhereInput>;
};

export type QueryAttractionArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: AttractionWhereUniqueInput;
};

export type QueryAttractionAlacarteArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: AttractionAlacarteWhereUniqueInput;
};

export type QueryAttractionAlacarteVersionArgs = {
  where: VersionWhereInput;
};

export type QueryAttractionAlacartesArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy: InputMaybe<AttractionAlacarteOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where: InputMaybe<AttractionAlacarteWhereInput>;
};

export type QueryAttractionAlacartesConnectionArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy: InputMaybe<AttractionAlacarteOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where: InputMaybe<AttractionAlacarteWhereInput>;
};

export type QueryAttractionAvailabilitiesArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy: InputMaybe<AttractionAvailabilityOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where: InputMaybe<AttractionAvailabilityWhereInput>;
};

export type QueryAttractionAvailabilitiesConnectionArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy: InputMaybe<AttractionAvailabilityOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where: InputMaybe<AttractionAvailabilityWhereInput>;
};

export type QueryAttractionAvailabilityArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: AttractionAvailabilityWhereUniqueInput;
};

export type QueryAttractionAvailabilityVersionArgs = {
  where: VersionWhereInput;
};

export type QueryAttractionLocationArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: AttractionLocationWhereUniqueInput;
};

export type QueryAttractionLocationVersionArgs = {
  where: VersionWhereInput;
};

export type QueryAttractionLocationsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy: InputMaybe<AttractionLocationOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where: InputMaybe<AttractionLocationWhereInput>;
};

export type QueryAttractionLocationsConnectionArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy: InputMaybe<AttractionLocationOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where: InputMaybe<AttractionLocationWhereInput>;
};

export type QueryAttractionOfferArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: AttractionOfferWhereUniqueInput;
};

export type QueryAttractionOfferVersionArgs = {
  where: VersionWhereInput;
};

export type QueryAttractionOffersArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy: InputMaybe<AttractionOfferOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where: InputMaybe<AttractionOfferWhereInput>;
};

export type QueryAttractionOffersConnectionArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy: InputMaybe<AttractionOfferOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where: InputMaybe<AttractionOfferWhereInput>;
};

export type QueryAttractionPriceArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: AttractionPriceWhereUniqueInput;
};

export type QueryAttractionPriceVersionArgs = {
  where: VersionWhereInput;
};

export type QueryAttractionPricesArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy: InputMaybe<AttractionPriceOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where: InputMaybe<AttractionPriceWhereInput>;
};

export type QueryAttractionPricesConnectionArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy: InputMaybe<AttractionPriceOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where: InputMaybe<AttractionPriceWhereInput>;
};

export type QueryAttractionReservationArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: AttractionReservationWhereUniqueInput;
};

export type QueryAttractionReservationVersionArgs = {
  where: VersionWhereInput;
};

export type QueryAttractionReservationsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy: InputMaybe<AttractionReservationOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where: InputMaybe<AttractionReservationWhereInput>;
};

export type QueryAttractionReservationsConnectionArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy: InputMaybe<AttractionReservationOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where: InputMaybe<AttractionReservationWhereInput>;
};

export type QueryAttractionVariantArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: AttractionVariantWhereUniqueInput;
};

export type QueryAttractionVariantVersionArgs = {
  where: VersionWhereInput;
};

export type QueryAttractionVariantsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy: InputMaybe<AttractionVariantOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where: InputMaybe<AttractionVariantWhereInput>;
};

export type QueryAttractionVariantsConnectionArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy: InputMaybe<AttractionVariantOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where: InputMaybe<AttractionVariantWhereInput>;
};

export type QueryAttractionVersionArgs = {
  where: VersionWhereInput;
};

export type QueryAttractionsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy: InputMaybe<AttractionOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where: InputMaybe<AttractionWhereInput>;
};

export type QueryAttractionsConnectionArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy: InputMaybe<AttractionOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where: InputMaybe<AttractionWhereInput>;
};

export type QueryAuthorArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: AuthorWhereUniqueInput;
};

export type QueryAuthorVersionArgs = {
  where: VersionWhereInput;
};

export type QueryAuthorsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy: InputMaybe<AuthorOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where: InputMaybe<AuthorWhereInput>;
};

export type QueryAuthorsConnectionArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy: InputMaybe<AuthorOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where: InputMaybe<AuthorWhereInput>;
};

export type QueryBlockArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: BlockWhereUniqueInput;
};

export type QueryBlockGroupArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: BlockGroupWhereUniqueInput;
};

export type QueryBlockGroupVersionArgs = {
  where: VersionWhereInput;
};

export type QueryBlockGroupsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy: InputMaybe<BlockGroupOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where: InputMaybe<BlockGroupWhereInput>;
};

export type QueryBlockGroupsConnectionArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy: InputMaybe<BlockGroupOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where: InputMaybe<BlockGroupWhereInput>;
};

export type QueryBlockVersionArgs = {
  where: VersionWhereInput;
};

export type QueryBlocksArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy: InputMaybe<BlockOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where: InputMaybe<BlockWhereInput>;
};

export type QueryBlocksConnectionArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy: InputMaybe<BlockOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where: InputMaybe<BlockWhereInput>;
};

export type QueryDetailBlockArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: DetailBlockWhereUniqueInput;
};

export type QueryDetailBlockVersionArgs = {
  where: VersionWhereInput;
};

export type QueryDetailBlocksArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy: InputMaybe<DetailBlockOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where: InputMaybe<DetailBlockWhereInput>;
};

export type QueryDetailBlocksConnectionArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy: InputMaybe<DetailBlockOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where: InputMaybe<DetailBlockWhereInput>;
};

export type QueryGeneralReservationArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: GeneralReservationWhereUniqueInput;
};

export type QueryGeneralReservationVersionArgs = {
  where: VersionWhereInput;
};

export type QueryGeneralReservationsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy: InputMaybe<GeneralReservationOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where: InputMaybe<GeneralReservationWhereInput>;
};

export type QueryGeneralReservationsConnectionArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy: InputMaybe<GeneralReservationOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where: InputMaybe<GeneralReservationWhereInput>;
};

export type QueryImageArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: ImageWhereUniqueInput;
};

export type QueryImageVersionArgs = {
  where: VersionWhereInput;
};

export type QueryImagesArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy: InputMaybe<ImageOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where: InputMaybe<ImageWhereInput>;
};

export type QueryImagesConnectionArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy: InputMaybe<ImageOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where: InputMaybe<ImageWhereInput>;
};

export type QueryLandingPageArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: LandingPageWhereUniqueInput;
};

export type QueryLandingPageVersionArgs = {
  where: VersionWhereInput;
};

export type QueryLandingPagesArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy: InputMaybe<LandingPageOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where: InputMaybe<LandingPageWhereInput>;
};

export type QueryLandingPagesConnectionArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy: InputMaybe<LandingPageOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where: InputMaybe<LandingPageWhereInput>;
};

export type QueryLinkArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: LinkWhereUniqueInput;
};

export type QueryLinkVersionArgs = {
  where: VersionWhereInput;
};

export type QueryLinksArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy: InputMaybe<LinkOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where: InputMaybe<LinkWhereInput>;
};

export type QueryLinksConnectionArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy: InputMaybe<LinkOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where: InputMaybe<LinkWhereInput>;
};

export type QueryMenuArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: MenuWhereUniqueInput;
};

export type QueryMenuVersionArgs = {
  where: VersionWhereInput;
};

export type QueryMenusArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy: InputMaybe<MenuOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where: InputMaybe<MenuWhereInput>;
};

export type QueryMenusConnectionArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy: InputMaybe<MenuOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where: InputMaybe<MenuWhereInput>;
};

export type QueryNodeArgs = {
  id: Scalars['ID'];
  locales?: Array<Locale>;
  stage?: Stage;
};

export type QueryNoticeArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: NoticeWhereUniqueInput;
};

export type QueryNoticeVersionArgs = {
  where: VersionWhereInput;
};

export type QueryNoticesArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy: InputMaybe<NoticeOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where: InputMaybe<NoticeWhereInput>;
};

export type QueryNoticesConnectionArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy: InputMaybe<NoticeOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where: InputMaybe<NoticeWhereInput>;
};

export type QueryPageArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: PageWhereUniqueInput;
};

export type QueryPageTemplateArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: PageTemplateWhereUniqueInput;
};

export type QueryPageTemplateVersionArgs = {
  where: VersionWhereInput;
};

export type QueryPageTemplatesArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy: InputMaybe<PageTemplateOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where: InputMaybe<PageTemplateWhereInput>;
};

export type QueryPageTemplatesConnectionArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy: InputMaybe<PageTemplateOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where: InputMaybe<PageTemplateWhereInput>;
};

export type QueryPageVersionArgs = {
  where: VersionWhereInput;
};

export type QueryPagesArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy: InputMaybe<PageOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where: InputMaybe<PageWhereInput>;
};

export type QueryPagesConnectionArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy: InputMaybe<PageOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where: InputMaybe<PageWhereInput>;
};

export type QueryPartnerArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: PartnerWhereUniqueInput;
};

export type QueryPartnerOfferArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: PartnerOfferWhereUniqueInput;
};

export type QueryPartnerOfferVersionArgs = {
  where: VersionWhereInput;
};

export type QueryPartnerOffersArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy: InputMaybe<PartnerOfferOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where: InputMaybe<PartnerOfferWhereInput>;
};

export type QueryPartnerOffersConnectionArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy: InputMaybe<PartnerOfferOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where: InputMaybe<PartnerOfferWhereInput>;
};

export type QueryPartnerVersionArgs = {
  where: VersionWhereInput;
};

export type QueryPartnersArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy: InputMaybe<PartnerOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where: InputMaybe<PartnerWhereInput>;
};

export type QueryPartnersConnectionArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy: InputMaybe<PartnerOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where: InputMaybe<PartnerWhereInput>;
};

export type QueryProductArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: ProductWhereUniqueInput;
};

export type QueryProductActivitiesArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy: InputMaybe<ProductActivityOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where: InputMaybe<ProductActivityWhereInput>;
};

export type QueryProductActivitiesConnectionArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy: InputMaybe<ProductActivityOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where: InputMaybe<ProductActivityWhereInput>;
};

export type QueryProductActivityArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: ProductActivityWhereUniqueInput;
};

export type QueryProductActivityItemArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: ProductActivityItemWhereUniqueInput;
};

export type QueryProductActivityItemVersionArgs = {
  where: VersionWhereInput;
};

export type QueryProductActivityItemsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy: InputMaybe<ProductActivityItemOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where: InputMaybe<ProductActivityItemWhereInput>;
};

export type QueryProductActivityItemsConnectionArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy: InputMaybe<ProductActivityItemOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where: InputMaybe<ProductActivityItemWhereInput>;
};

export type QueryProductActivityVersionArgs = {
  where: VersionWhereInput;
};

export type QueryProductAttractionLineupArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: ProductAttractionLineupWhereUniqueInput;
};

export type QueryProductAttractionLineupVersionArgs = {
  where: VersionWhereInput;
};

export type QueryProductAttractionLineupsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy: InputMaybe<ProductAttractionLineupOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where: InputMaybe<ProductAttractionLineupWhereInput>;
};

export type QueryProductAttractionLineupsConnectionArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy: InputMaybe<ProductAttractionLineupOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where: InputMaybe<ProductAttractionLineupWhereInput>;
};

export type QueryProductReservationArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: ProductReservationWhereUniqueInput;
};

export type QueryProductReservationVersionArgs = {
  where: VersionWhereInput;
};

export type QueryProductReservationsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy: InputMaybe<ProductReservationOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where: InputMaybe<ProductReservationWhereInput>;
};

export type QueryProductReservationsConnectionArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy: InputMaybe<ProductReservationOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where: InputMaybe<ProductReservationWhereInput>;
};

export type QueryProductVersionArgs = {
  where: VersionWhereInput;
};

export type QueryProductsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy: InputMaybe<ProductOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where: InputMaybe<ProductWhereInput>;
};

export type QueryProductsConnectionArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy: InputMaybe<ProductOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where: InputMaybe<ProductWhereInput>;
};

export type QueryPromoArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: PromoWhereUniqueInput;
};

export type QueryPromoVersionArgs = {
  where: VersionWhereInput;
};

export type QueryPromosArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy: InputMaybe<PromoOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where: InputMaybe<PromoWhereInput>;
};

export type QueryPromosConnectionArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy: InputMaybe<PromoOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where: InputMaybe<PromoWhereInput>;
};

export type QueryReservationTextArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: ReservationTextWhereUniqueInput;
};

export type QueryReservationTextVersionArgs = {
  where: VersionWhereInput;
};

export type QueryReservationTextsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy: InputMaybe<ReservationTextOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where: InputMaybe<ReservationTextWhereInput>;
};

export type QueryReservationTextsConnectionArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy: InputMaybe<ReservationTextOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where: InputMaybe<ReservationTextWhereInput>;
};

export type QuerySandboxArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: SandboxWhereUniqueInput;
};

export type QuerySandboxVersionArgs = {
  where: VersionWhereInput;
};

export type QuerySandboxesArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy: InputMaybe<SandboxOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where: InputMaybe<SandboxWhereInput>;
};

export type QuerySandboxesConnectionArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy: InputMaybe<SandboxOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where: InputMaybe<SandboxWhereInput>;
};

export type QueryScheduledOperationArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: ScheduledOperationWhereUniqueInput;
};

export type QueryScheduledOperationsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy: InputMaybe<ScheduledOperationOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where: InputMaybe<ScheduledOperationWhereInput>;
};

export type QueryScheduledOperationsConnectionArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy: InputMaybe<ScheduledOperationOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where: InputMaybe<ScheduledOperationWhereInput>;
};

export type QueryScheduledReleaseArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: ScheduledReleaseWhereUniqueInput;
};

export type QueryScheduledReleasesArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy: InputMaybe<ScheduledReleaseOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where: InputMaybe<ScheduledReleaseWhereInput>;
};

export type QueryScheduledReleasesConnectionArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy: InputMaybe<ScheduledReleaseOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where: InputMaybe<ScheduledReleaseWhereInput>;
};

export type QuerySeoArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: SeoWhereUniqueInput;
};

export type QuerySeoVersionArgs = {
  where: VersionWhereInput;
};

export type QuerySeosArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy: InputMaybe<SeoOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where: InputMaybe<SeoWhereInput>;
};

export type QuerySeosConnectionArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy: InputMaybe<SeoOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where: InputMaybe<SeoWhereInput>;
};

export type QueryTextArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: TextWhereUniqueInput;
};

export type QueryTextGroupArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: TextGroupWhereUniqueInput;
};

export type QueryTextGroupVersionArgs = {
  where: VersionWhereInput;
};

export type QueryTextGroupsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy: InputMaybe<TextGroupOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where: InputMaybe<TextGroupWhereInput>;
};

export type QueryTextGroupsConnectionArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy: InputMaybe<TextGroupOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where: InputMaybe<TextGroupWhereInput>;
};

export type QueryTextListArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: TextListWhereUniqueInput;
};

export type QueryTextListVersionArgs = {
  where: VersionWhereInput;
};

export type QueryTextListsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy: InputMaybe<TextListOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where: InputMaybe<TextListWhereInput>;
};

export type QueryTextListsConnectionArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy: InputMaybe<TextListOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where: InputMaybe<TextListWhereInput>;
};

export type QueryTextVersionArgs = {
  where: VersionWhereInput;
};

export type QueryTextsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy: InputMaybe<TextOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where: InputMaybe<TextWhereInput>;
};

export type QueryTextsConnectionArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy: InputMaybe<TextOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where: InputMaybe<TextWhereInput>;
};

export type QueryTicketGroupArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: TicketGroupWhereUniqueInput;
};

export type QueryTicketGroupVersionArgs = {
  where: VersionWhereInput;
};

export type QueryTicketGroupsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy: InputMaybe<TicketGroupOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where: InputMaybe<TicketGroupWhereInput>;
};

export type QueryTicketGroupsConnectionArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy: InputMaybe<TicketGroupOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where: InputMaybe<TicketGroupWhereInput>;
};

export type QueryTitleDescriptionArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: TitleDescriptionWhereUniqueInput;
};

export type QueryTitleDescriptionVersionArgs = {
  where: VersionWhereInput;
};

export type QueryTitleDescriptionsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy: InputMaybe<TitleDescriptionOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where: InputMaybe<TitleDescriptionWhereInput>;
};

export type QueryTitleDescriptionsConnectionArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy: InputMaybe<TitleDescriptionOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where: InputMaybe<TitleDescriptionWhereInput>;
};

export type QueryUserArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: UserWhereUniqueInput;
};

export type QueryUsersArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy: InputMaybe<UserOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where: InputMaybe<UserWhereInput>;
};

export type QueryUsersConnectionArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy: InputMaybe<UserOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where: InputMaybe<UserWhereInput>;
};

export type QueryVideoArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: VideoWhereUniqueInput;
};

export type QueryVideoVersionArgs = {
  where: VersionWhereInput;
};

export type QueryVideosArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy: InputMaybe<VideoOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where: InputMaybe<VideoWhereInput>;
};

export type QueryVideosConnectionArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  last: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy: InputMaybe<VideoOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where: InputMaybe<VideoWhereInput>;
};

/** Representing a RGBA color value: https://developer.mozilla.org/en-US/docs/Web/CSS/color_value#rgb()_and_rgba() */
export type Rgba = {
  __typename?: 'RGBA';
  a: Scalars['RGBATransparency'];
  b: Scalars['RGBAHue'];
  g: Scalars['RGBAHue'];
  r: Scalars['RGBAHue'];
};

/** Input type representing a RGBA color value: https://developer.mozilla.org/en-US/docs/Web/CSS/color_value#rgb()_and_rgba() */
export type RgbaInput = {
  a: Scalars['RGBATransparency'];
  b: Scalars['RGBAHue'];
  g: Scalars['RGBAHue'];
  r: Scalars['RGBAHue'];
};

export type ReservationText = Node & {
  __typename?: 'ReservationText';
  attractionReservations: Array<AttractionReservation>;
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** User that created this document */
  createdBy: Maybe<User>;
  /** Get the document in other stages */
  documentInStages: Array<ReservationText>;
  generalReservation: Maybe<GeneralReservation>;
  generalReservationAttraction: Maybe<GeneralReservation>;
  generalReservationProduct: Maybe<GeneralReservation>;
  /** List of ReservationText versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID'];
  internalTitle: Maybe<Scalars['String']>;
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<ReservationText>;
  platforms: Array<Platforms>;
  productReservations: Array<ProductReservation>;
  products: Array<Product>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt: Maybe<Scalars['DateTime']>;
  /** User that last published this document */
  publishedBy: Maybe<User>;
  qabu: Maybe<Scalars['Json']>;
  scheduledIn: Array<ScheduledOperation>;
  /** System stage field */
  stage: Stage;
  type: ReservationTextType;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** User that last updated this document */
  updatedBy: Maybe<User>;
  value: Maybe<Scalars['String']>;
};

export type ReservationTextAttractionReservationsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<AttractionReservationOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AttractionReservationWhereInput>;
};

export type ReservationTextCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type ReservationTextCreatedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type ReservationTextDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};

export type ReservationTextGeneralReservationArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type ReservationTextGeneralReservationAttractionArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type ReservationTextGeneralReservationProductArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type ReservationTextHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride: InputMaybe<Stage>;
};

export type ReservationTextLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};

export type ReservationTextProductReservationsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<ProductReservationOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ProductReservationWhereInput>;
};

export type ReservationTextProductsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<ProductOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ProductWhereInput>;
};

export type ReservationTextPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type ReservationTextPublishedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type ReservationTextScheduledInArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ScheduledOperationWhereInput>;
};

export type ReservationTextUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type ReservationTextUpdatedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type ReservationTextConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: ReservationTextWhereUniqueInput;
};

/** A connection to a list of items. */
export type ReservationTextConnection = {
  __typename?: 'ReservationTextConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<ReservationTextEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type ReservationTextCreateInput = {
  attractionReservations?: InputMaybe<AttractionReservationCreateManyInlineInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  generalReservation?: InputMaybe<GeneralReservationCreateOneInlineInput>;
  generalReservationAttraction?: InputMaybe<GeneralReservationCreateOneInlineInput>;
  generalReservationProduct?: InputMaybe<GeneralReservationCreateOneInlineInput>;
  internalTitle?: InputMaybe<Scalars['String']>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<ReservationTextCreateLocalizationsInput>;
  platforms?: InputMaybe<Array<Platforms>>;
  productReservations?: InputMaybe<ProductReservationCreateManyInlineInput>;
  products?: InputMaybe<ProductCreateManyInlineInput>;
  qabu?: InputMaybe<Scalars['Json']>;
  type: ReservationTextType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** value input for default locale (en) */
  value?: InputMaybe<Scalars['String']>;
};

export type ReservationTextCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  value?: InputMaybe<Scalars['String']>;
};

export type ReservationTextCreateLocalizationInput = {
  /** Localization input */
  data: ReservationTextCreateLocalizationDataInput;
  locale: Locale;
};

export type ReservationTextCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<ReservationTextCreateLocalizationInput>>;
};

export type ReservationTextCreateManyInlineInput = {
  /** Connect multiple existing ReservationText documents */
  connect?: InputMaybe<Array<ReservationTextWhereUniqueInput>>;
  /** Create and connect multiple existing ReservationText documents */
  create?: InputMaybe<Array<ReservationTextCreateInput>>;
};

export type ReservationTextCreateOneInlineInput = {
  /** Connect one existing ReservationText document */
  connect?: InputMaybe<ReservationTextWhereUniqueInput>;
  /** Create and connect one ReservationText document */
  create?: InputMaybe<ReservationTextCreateInput>;
};

/** An edge in a connection. */
export type ReservationTextEdge = {
  __typename?: 'ReservationTextEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: ReservationText;
};

/** Identifies documents */
export type ReservationTextManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ReservationTextWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ReservationTextWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ReservationTextWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  attractionReservations_every?: InputMaybe<AttractionReservationWhereInput>;
  attractionReservations_none?: InputMaybe<AttractionReservationWhereInput>;
  attractionReservations_some?: InputMaybe<AttractionReservationWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<ReservationTextWhereStageInput>;
  documentInStages_none?: InputMaybe<ReservationTextWhereStageInput>;
  documentInStages_some?: InputMaybe<ReservationTextWhereStageInput>;
  generalReservation?: InputMaybe<GeneralReservationWhereInput>;
  generalReservationAttraction?: InputMaybe<GeneralReservationWhereInput>;
  generalReservationProduct?: InputMaybe<GeneralReservationWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  internalTitle?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  internalTitle_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  internalTitle_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  internalTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  internalTitle_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  internalTitle_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  internalTitle_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  internalTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  internalTitle_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  internalTitle_starts_with?: InputMaybe<Scalars['String']>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  platforms?: InputMaybe<Array<Platforms>>;
  /** Matches if the field array contains *all* items provided to the filter */
  platforms_contains_all?: InputMaybe<Array<Platforms>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  platforms_contains_none?: InputMaybe<Array<Platforms>>;
  /** Matches if the field array contains at least one item provided to the filter */
  platforms_contains_some?: InputMaybe<Array<Platforms>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  platforms_not?: InputMaybe<Array<Platforms>>;
  productReservations_every?: InputMaybe<ProductReservationWhereInput>;
  productReservations_none?: InputMaybe<ProductReservationWhereInput>;
  productReservations_some?: InputMaybe<ProductReservationWhereInput>;
  products_every?: InputMaybe<ProductWhereInput>;
  products_none?: InputMaybe<ProductWhereInput>;
  products_some?: InputMaybe<ProductWhereInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  /** All values containing the given json path. */
  qabu_json_path_exists?: InputMaybe<Scalars['String']>;
  /**
   * Recursively tries to find the provided JSON scalar value inside the field.
   * It does use an exact match when comparing values.
   * If you pass `null` as value the filter will be ignored.
   * Note: This filter fails if you try to look for a non scalar JSON value!
   */
  qabu_value_recursive?: InputMaybe<Scalars['Json']>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  type?: InputMaybe<ReservationTextType>;
  /** All values that are contained in given list. */
  type_in?: InputMaybe<Array<InputMaybe<ReservationTextType>>>;
  /** Any other value that exists and is not equal to the given value. */
  type_not?: InputMaybe<ReservationTextType>;
  /** All values that are not contained in given list. */
  type_not_in?: InputMaybe<Array<InputMaybe<ReservationTextType>>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum ReservationTextOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  InternalTitleAsc = 'internalTitle_ASC',
  InternalTitleDesc = 'internalTitle_DESC',
  PlatformsAsc = 'platforms_ASC',
  PlatformsDesc = 'platforms_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  ValueAsc = 'value_ASC',
  ValueDesc = 'value_DESC',
}

export enum ReservationTextType {
  AllDay = 'all_day',
  AttractionClosed = 'attraction_closed',
  AttractionIntroNoTravelparty = 'attraction_intro_no_travelparty',
  AttractionIntroTravelparty = 'attraction_intro_travelparty',
  AttractionNotReserved = 'attraction_not_reserved',
  AttractionNotReservedHtml = 'attraction_not_reserved_html',
  AttractionReserved = 'attraction_reserved',
  AttractionReservedHtml = 'attraction_reserved_html',
  AvailabilityTitle = 'availability_title',
  BatteryparkDetails = 'batterypark_details',
  BatteryparkMap = 'batterypark_map',
  BatteryparkTitle = 'batterypark_title',
  ChangeOrCancelModal = 'change_or_cancel_modal',
  ChangeOrCancelModalContent = 'change_or_cancel_modal_content',
  ChangeOrCancelModalTitle = 'change_or_cancel_modal_title',
  Changes = 'changes',
  ChangesAgreement = 'changes_agreement',
  ChangesTitle = 'changes_title',
  ClosedLegend = 'closed_legend',
  ClosedMessage = 'closed_message',
  ClosedTitle = 'closed_title',
  Entry = 'entry',
  EntryTitle = 'entry_title',
  HoursTitle = 'hours_title',
  LibertyparkDetails = 'libertypark_details',
  LibertyparkMap = 'libertypark_map',
  LibertyparkTitle = 'libertypark_title',
  LineupDetails = 'lineup_details',
  LineupDetailsAnonymous = 'lineup_details_anonymous',
  LineupDetailsCticket = 'lineup_details_cticket',
  LineupDetailsMoreAlacarte = 'lineup_details_more_alacarte',
  LineupDetailsMoreChoice = 'lineup_details_more_choice',
  LineupDetailsMoreCticket = 'lineup_details_more_cticket',
  LineupInstructions = 'lineup_instructions',
  LineupTitle = 'lineup_title',
  LineupTitleAlacarte = 'lineup_title_alacarte',
  LineupTitleAlacarteAnonymous = 'lineup_title_alacarte_anonymous',
  LineupTitleAll = 'lineup_title_all',
  LineupTitleAllAnonymous = 'lineup_title_all_anonymous',
  LineupTitleAnonymous = 'lineup_title_anonymous',
  LineupTitleCticket = 'lineup_title_cticket',
  LineupTitleCticketAnonymous = 'lineup_title_cticket_anonymous',
  LineupValidity = 'lineup_validity',
  LineupValidityLong = 'lineup_validity_long',
  MichiganaveDetails = 'michiganave_details',
  MichiganaveMap = 'michiganave_map',
  MichiganaveTitle = 'michiganave_title',
  Misc = 'misc',
  MiscTitle = 'misc_title',
  NavypierDetails = 'navypier_details',
  NavypierMap = 'navypier_map',
  NavypierTitle = 'navypier_title',
  OffersAndDiscountsLink = 'offers_and_discounts_link',
  OfflineCancelContent = 'offline_cancel_content',
  OfflineReservationContent = 'offline_reservation_content',
  PhoneNumber = 'phone_number',
  ProductIntroBody = 'product_intro_body',
  ProductIntroBodyAnonymous = 'product_intro_body_anonymous',
  ProductIntroBtn = 'product_intro_btn',
  ProductIntroBtnAnonymous = 'product_intro_btn_anonymous',
  ProductIntroTitle = 'product_intro_title',
  ProductIntroTitleAnonymous = 'product_intro_title_anonymous',
  Questions = 'questions',
  QuestionsTitle = 'questions_title',
  RedirectDetails = 'redirect_details',
  RedirectDetailsHtml = 'redirect_details_html',
  RedirectInfo = 'redirect_info',
  ReservationArrival = 'reservation_arrival',
  ReservationChanges = 'reservation_changes',
  ReservationEmail = 'reservation_email',
  ReservationEmailConfirm = 'reservation_email_confirm',
  ReservationFirstname = 'reservation_firstname',
  ReservationLastname = 'reservation_lastname',
  ReservationNotRequired = 'reservation_not_required',
  ReservationOptinmarketing = 'reservation_optinmarketing',
  ReservationOptinmarketingTerms = 'reservation_optinmarketing_terms',
  ReservationPhone = 'reservation_phone',
  ReservationRequired = 'reservation_required',
  ReservationZip = 'reservation_zip',
  Reservations = 'reservations',
  ReservationsHelp = 'reservations_help',
  ReservationsHelpTitle = 'reservations_help_title',
  ReservationsLegend = 'reservations_legend',
  ReservationsTitle = 'reservations_title',
  SelectTimeAfternoon = 'select_time_afternoon',
  SelectTimeAll = 'select_time_all',
  SelectTimeClose = 'select_time_close',
  SelectTimeEvening = 'select_time_evening',
  SelectTimeFromTo = 'select_time_from_to',
  SelectTimeInterval = 'select_time_interval',
  SelectTimeMidday = 'select_time_midday',
  SelectTimeMorning = 'select_time_morning',
  SelectTimeNight = 'select_time_night',
  SelectTimeOpen = 'select_time_open',
  StepTitleLongOptionLocation = 'step_title_long_option_location',
  StepTitleOptionLocation = 'step_title_option_location',
  Terms = 'terms',
  TimedEntryEmailChanges = 'timed_entry_email_changes',
  TimesEntryEmailIntro = 'times_entry_email_intro',
}

export type ReservationTextUpdateInput = {
  attractionReservations?: InputMaybe<AttractionReservationUpdateManyInlineInput>;
  generalReservation?: InputMaybe<GeneralReservationUpdateOneInlineInput>;
  generalReservationAttraction?: InputMaybe<GeneralReservationUpdateOneInlineInput>;
  generalReservationProduct?: InputMaybe<GeneralReservationUpdateOneInlineInput>;
  internalTitle?: InputMaybe<Scalars['String']>;
  /** Manage document localizations */
  localizations?: InputMaybe<ReservationTextUpdateLocalizationsInput>;
  platforms?: InputMaybe<Array<Platforms>>;
  productReservations?: InputMaybe<ProductReservationUpdateManyInlineInput>;
  products?: InputMaybe<ProductUpdateManyInlineInput>;
  qabu?: InputMaybe<Scalars['Json']>;
  type?: InputMaybe<ReservationTextType>;
  /** value input for default locale (en) */
  value?: InputMaybe<Scalars['String']>;
};

export type ReservationTextUpdateLocalizationDataInput = {
  value?: InputMaybe<Scalars['String']>;
};

export type ReservationTextUpdateLocalizationInput = {
  data: ReservationTextUpdateLocalizationDataInput;
  locale: Locale;
};

export type ReservationTextUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<ReservationTextCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<ReservationTextUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<ReservationTextUpsertLocalizationInput>>;
};

export type ReservationTextUpdateManyInlineInput = {
  /** Connect multiple existing ReservationText documents */
  connect?: InputMaybe<Array<ReservationTextConnectInput>>;
  /** Create and connect multiple ReservationText documents */
  create?: InputMaybe<Array<ReservationTextCreateInput>>;
  /** Delete multiple ReservationText documents */
  delete?: InputMaybe<Array<ReservationTextWhereUniqueInput>>;
  /** Disconnect multiple ReservationText documents */
  disconnect?: InputMaybe<Array<ReservationTextWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing ReservationText documents */
  set?: InputMaybe<Array<ReservationTextWhereUniqueInput>>;
  /** Update multiple ReservationText documents */
  update?: InputMaybe<Array<ReservationTextUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple ReservationText documents */
  upsert?: InputMaybe<Array<ReservationTextUpsertWithNestedWhereUniqueInput>>;
};

export type ReservationTextUpdateManyInput = {
  internalTitle?: InputMaybe<Scalars['String']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<ReservationTextUpdateManyLocalizationsInput>;
  platforms?: InputMaybe<Array<Platforms>>;
  qabu?: InputMaybe<Scalars['Json']>;
  type?: InputMaybe<ReservationTextType>;
  /** value input for default locale (en) */
  value?: InputMaybe<Scalars['String']>;
};

export type ReservationTextUpdateManyLocalizationDataInput = {
  value?: InputMaybe<Scalars['String']>;
};

export type ReservationTextUpdateManyLocalizationInput = {
  data: ReservationTextUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type ReservationTextUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<ReservationTextUpdateManyLocalizationInput>>;
};

export type ReservationTextUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: ReservationTextUpdateManyInput;
  /** Document search */
  where: ReservationTextWhereInput;
};

export type ReservationTextUpdateOneInlineInput = {
  /** Connect existing ReservationText document */
  connect?: InputMaybe<ReservationTextWhereUniqueInput>;
  /** Create and connect one ReservationText document */
  create?: InputMaybe<ReservationTextCreateInput>;
  /** Delete currently connected ReservationText document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected ReservationText document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single ReservationText document */
  update?: InputMaybe<ReservationTextUpdateWithNestedWhereUniqueInput>;
  /** Upsert single ReservationText document */
  upsert?: InputMaybe<ReservationTextUpsertWithNestedWhereUniqueInput>;
};

export type ReservationTextUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: ReservationTextUpdateInput;
  /** Unique document search */
  where: ReservationTextWhereUniqueInput;
};

export type ReservationTextUpsertInput = {
  /** Create document if it didn't exist */
  create: ReservationTextCreateInput;
  /** Update document if it exists */
  update: ReservationTextUpdateInput;
};

export type ReservationTextUpsertLocalizationInput = {
  create: ReservationTextCreateLocalizationDataInput;
  locale: Locale;
  update: ReservationTextUpdateLocalizationDataInput;
};

export type ReservationTextUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: ReservationTextUpsertInput;
  /** Unique document search */
  where: ReservationTextWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type ReservationTextWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']>;
};

/** Identifies documents */
export type ReservationTextWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ReservationTextWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ReservationTextWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ReservationTextWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  attractionReservations_every?: InputMaybe<AttractionReservationWhereInput>;
  attractionReservations_none?: InputMaybe<AttractionReservationWhereInput>;
  attractionReservations_some?: InputMaybe<AttractionReservationWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<ReservationTextWhereStageInput>;
  documentInStages_none?: InputMaybe<ReservationTextWhereStageInput>;
  documentInStages_some?: InputMaybe<ReservationTextWhereStageInput>;
  generalReservation?: InputMaybe<GeneralReservationWhereInput>;
  generalReservationAttraction?: InputMaybe<GeneralReservationWhereInput>;
  generalReservationProduct?: InputMaybe<GeneralReservationWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  internalTitle?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  internalTitle_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  internalTitle_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  internalTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  internalTitle_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  internalTitle_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  internalTitle_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  internalTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  internalTitle_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  internalTitle_starts_with?: InputMaybe<Scalars['String']>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  platforms?: InputMaybe<Array<Platforms>>;
  /** Matches if the field array contains *all* items provided to the filter */
  platforms_contains_all?: InputMaybe<Array<Platforms>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  platforms_contains_none?: InputMaybe<Array<Platforms>>;
  /** Matches if the field array contains at least one item provided to the filter */
  platforms_contains_some?: InputMaybe<Array<Platforms>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  platforms_not?: InputMaybe<Array<Platforms>>;
  productReservations_every?: InputMaybe<ProductReservationWhereInput>;
  productReservations_none?: InputMaybe<ProductReservationWhereInput>;
  productReservations_some?: InputMaybe<ProductReservationWhereInput>;
  products_every?: InputMaybe<ProductWhereInput>;
  products_none?: InputMaybe<ProductWhereInput>;
  products_some?: InputMaybe<ProductWhereInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  /** All values containing the given json path. */
  qabu_json_path_exists?: InputMaybe<Scalars['String']>;
  /**
   * Recursively tries to find the provided JSON scalar value inside the field.
   * It does use an exact match when comparing values.
   * If you pass `null` as value the filter will be ignored.
   * Note: This filter fails if you try to look for a non scalar JSON value!
   */
  qabu_value_recursive?: InputMaybe<Scalars['Json']>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  type?: InputMaybe<ReservationTextType>;
  /** All values that are contained in given list. */
  type_in?: InputMaybe<Array<InputMaybe<ReservationTextType>>>;
  /** Any other value that exists and is not equal to the given value. */
  type_not?: InputMaybe<ReservationTextType>;
  /** All values that are not contained in given list. */
  type_not_in?: InputMaybe<Array<InputMaybe<ReservationTextType>>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
  value?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  value_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  value_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  value_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  value_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  value_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  value_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  value_starts_with?: InputMaybe<Scalars['String']>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type ReservationTextWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ReservationTextWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ReservationTextWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ReservationTextWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<ReservationTextWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References ReservationText record uniquely */
export type ReservationTextWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

/** Custom type representing a rich text value comprising of raw rich text ast, html, markdown and text values */
export type RichText = {
  __typename?: 'RichText';
  /** Returns HTMl representation */
  html: Scalars['String'];
  /** Returns Markdown representation */
  markdown: Scalars['String'];
  /** Returns AST representation */
  raw: Scalars['RichTextAST'];
  /** Returns plain-text contents of RichText */
  text: Scalars['String'];
};

export type Sandbox = Node & {
  __typename?: 'Sandbox';
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** User that created this document */
  createdBy: Maybe<User>;
  /** Get the document in other stages */
  documentInStages: Array<Sandbox>;
  focalPoint: Maybe<Scalars['Json']>;
  /** List of Sandbox versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<Sandbox>;
  markdown: Maybe<Scalars['String']>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt: Maybe<Scalars['DateTime']>;
  /** User that last published this document */
  publishedBy: Maybe<User>;
  qabu: Maybe<Scalars['Json']>;
  richText: Maybe<RichText>;
  scheduledIn: Array<ScheduledOperation>;
  /** System stage field */
  stage: Stage;
  test: Maybe<Scalars['String']>;
  test2: Maybe<Scalars['String']>;
  text: Maybe<Text>;
  textList: Array<Scalars['String']>;
  texts: Array<Text>;
  title: Maybe<Scalars['String']>;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** User that last updated this document */
  updatedBy: Maybe<User>;
};

export type SandboxCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type SandboxCreatedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type SandboxDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};

export type SandboxHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride: InputMaybe<Stage>;
};

export type SandboxLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};

export type SandboxPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type SandboxPublishedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type SandboxScheduledInArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ScheduledOperationWhereInput>;
};

export type SandboxTextArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type SandboxTextsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<TextOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<TextWhereInput>;
};

export type SandboxUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type SandboxUpdatedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type SandboxConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: SandboxWhereUniqueInput;
};

/** A connection to a list of items. */
export type SandboxConnection = {
  __typename?: 'SandboxConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<SandboxEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type SandboxCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  focalPoint?: InputMaybe<Scalars['Json']>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<SandboxCreateLocalizationsInput>;
  markdown?: InputMaybe<Scalars['String']>;
  qabu?: InputMaybe<Scalars['Json']>;
  richText?: InputMaybe<Scalars['RichTextAST']>;
  test?: InputMaybe<Scalars['String']>;
  test2?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<TextCreateOneInlineInput>;
  /** textList input for default locale (en) */
  textList?: InputMaybe<Array<Scalars['String']>>;
  texts?: InputMaybe<TextCreateManyInlineInput>;
  /** title input for default locale (en) */
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type SandboxCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  textList?: InputMaybe<Array<Scalars['String']>>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type SandboxCreateLocalizationInput = {
  /** Localization input */
  data: SandboxCreateLocalizationDataInput;
  locale: Locale;
};

export type SandboxCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<SandboxCreateLocalizationInput>>;
};

export type SandboxCreateManyInlineInput = {
  /** Connect multiple existing Sandbox documents */
  connect?: InputMaybe<Array<SandboxWhereUniqueInput>>;
  /** Create and connect multiple existing Sandbox documents */
  create?: InputMaybe<Array<SandboxCreateInput>>;
};

export type SandboxCreateOneInlineInput = {
  /** Connect one existing Sandbox document */
  connect?: InputMaybe<SandboxWhereUniqueInput>;
  /** Create and connect one Sandbox document */
  create?: InputMaybe<SandboxCreateInput>;
};

/** An edge in a connection. */
export type SandboxEdge = {
  __typename?: 'SandboxEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Sandbox;
};

/** Identifies documents */
export type SandboxManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SandboxWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SandboxWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SandboxWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<SandboxWhereStageInput>;
  documentInStages_none?: InputMaybe<SandboxWhereStageInput>;
  documentInStages_some?: InputMaybe<SandboxWhereStageInput>;
  /** All values containing the given json path. */
  focalPoint_json_path_exists?: InputMaybe<Scalars['String']>;
  /**
   * Recursively tries to find the provided JSON scalar value inside the field.
   * It does use an exact match when comparing values.
   * If you pass `null` as value the filter will be ignored.
   * Note: This filter fails if you try to look for a non scalar JSON value!
   */
  focalPoint_value_recursive?: InputMaybe<Scalars['Json']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  markdown?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  markdown_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  markdown_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  markdown_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  markdown_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  markdown_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  markdown_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  markdown_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  markdown_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  markdown_starts_with?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  /** All values containing the given json path. */
  qabu_json_path_exists?: InputMaybe<Scalars['String']>;
  /**
   * Recursively tries to find the provided JSON scalar value inside the field.
   * It does use an exact match when comparing values.
   * If you pass `null` as value the filter will be ignored.
   * Note: This filter fails if you try to look for a non scalar JSON value!
   */
  qabu_value_recursive?: InputMaybe<Scalars['Json']>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  test?: InputMaybe<Scalars['String']>;
  test2?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  test2_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  test2_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  test2_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  test2_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  test2_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  test2_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  test2_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  test2_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  test2_starts_with?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  test_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  test_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  test_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  test_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  test_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  test_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  test_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  test_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  test_starts_with?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<TextWhereInput>;
  texts_every?: InputMaybe<TextWhereInput>;
  texts_none?: InputMaybe<TextWhereInput>;
  texts_some?: InputMaybe<TextWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum SandboxOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  MarkdownAsc = 'markdown_ASC',
  MarkdownDesc = 'markdown_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  Test2Asc = 'test2_ASC',
  Test2Desc = 'test2_DESC',
  TestAsc = 'test_ASC',
  TestDesc = 'test_DESC',
  TextListAsc = 'textList_ASC',
  TextListDesc = 'textList_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type SandboxUpdateInput = {
  focalPoint?: InputMaybe<Scalars['Json']>;
  /** Manage document localizations */
  localizations?: InputMaybe<SandboxUpdateLocalizationsInput>;
  markdown?: InputMaybe<Scalars['String']>;
  qabu?: InputMaybe<Scalars['Json']>;
  richText?: InputMaybe<Scalars['RichTextAST']>;
  test?: InputMaybe<Scalars['String']>;
  test2?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<TextUpdateOneInlineInput>;
  /** textList input for default locale (en) */
  textList?: InputMaybe<Array<Scalars['String']>>;
  texts?: InputMaybe<TextUpdateManyInlineInput>;
  /** title input for default locale (en) */
  title?: InputMaybe<Scalars['String']>;
};

export type SandboxUpdateLocalizationDataInput = {
  textList?: InputMaybe<Array<Scalars['String']>>;
  title?: InputMaybe<Scalars['String']>;
};

export type SandboxUpdateLocalizationInput = {
  data: SandboxUpdateLocalizationDataInput;
  locale: Locale;
};

export type SandboxUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<SandboxCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<SandboxUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<SandboxUpsertLocalizationInput>>;
};

export type SandboxUpdateManyInlineInput = {
  /** Connect multiple existing Sandbox documents */
  connect?: InputMaybe<Array<SandboxConnectInput>>;
  /** Create and connect multiple Sandbox documents */
  create?: InputMaybe<Array<SandboxCreateInput>>;
  /** Delete multiple Sandbox documents */
  delete?: InputMaybe<Array<SandboxWhereUniqueInput>>;
  /** Disconnect multiple Sandbox documents */
  disconnect?: InputMaybe<Array<SandboxWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing Sandbox documents */
  set?: InputMaybe<Array<SandboxWhereUniqueInput>>;
  /** Update multiple Sandbox documents */
  update?: InputMaybe<Array<SandboxUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple Sandbox documents */
  upsert?: InputMaybe<Array<SandboxUpsertWithNestedWhereUniqueInput>>;
};

export type SandboxUpdateManyInput = {
  focalPoint?: InputMaybe<Scalars['Json']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<SandboxUpdateManyLocalizationsInput>;
  markdown?: InputMaybe<Scalars['String']>;
  qabu?: InputMaybe<Scalars['Json']>;
  richText?: InputMaybe<Scalars['RichTextAST']>;
  test?: InputMaybe<Scalars['String']>;
  test2?: InputMaybe<Scalars['String']>;
  /** textList input for default locale (en) */
  textList?: InputMaybe<Array<Scalars['String']>>;
  /** title input for default locale (en) */
  title?: InputMaybe<Scalars['String']>;
};

export type SandboxUpdateManyLocalizationDataInput = {
  textList?: InputMaybe<Array<Scalars['String']>>;
  title?: InputMaybe<Scalars['String']>;
};

export type SandboxUpdateManyLocalizationInput = {
  data: SandboxUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type SandboxUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<SandboxUpdateManyLocalizationInput>>;
};

export type SandboxUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: SandboxUpdateManyInput;
  /** Document search */
  where: SandboxWhereInput;
};

export type SandboxUpdateOneInlineInput = {
  /** Connect existing Sandbox document */
  connect?: InputMaybe<SandboxWhereUniqueInput>;
  /** Create and connect one Sandbox document */
  create?: InputMaybe<SandboxCreateInput>;
  /** Delete currently connected Sandbox document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected Sandbox document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single Sandbox document */
  update?: InputMaybe<SandboxUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Sandbox document */
  upsert?: InputMaybe<SandboxUpsertWithNestedWhereUniqueInput>;
};

export type SandboxUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: SandboxUpdateInput;
  /** Unique document search */
  where: SandboxWhereUniqueInput;
};

export type SandboxUpsertInput = {
  /** Create document if it didn't exist */
  create: SandboxCreateInput;
  /** Update document if it exists */
  update: SandboxUpdateInput;
};

export type SandboxUpsertLocalizationInput = {
  create: SandboxCreateLocalizationDataInput;
  locale: Locale;
  update: SandboxUpdateLocalizationDataInput;
};

export type SandboxUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: SandboxUpsertInput;
  /** Unique document search */
  where: SandboxWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type SandboxWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']>;
};

/** Identifies documents */
export type SandboxWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SandboxWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SandboxWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SandboxWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<SandboxWhereStageInput>;
  documentInStages_none?: InputMaybe<SandboxWhereStageInput>;
  documentInStages_some?: InputMaybe<SandboxWhereStageInput>;
  /** All values containing the given json path. */
  focalPoint_json_path_exists?: InputMaybe<Scalars['String']>;
  /**
   * Recursively tries to find the provided JSON scalar value inside the field.
   * It does use an exact match when comparing values.
   * If you pass `null` as value the filter will be ignored.
   * Note: This filter fails if you try to look for a non scalar JSON value!
   */
  focalPoint_value_recursive?: InputMaybe<Scalars['Json']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  markdown?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  markdown_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  markdown_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  markdown_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  markdown_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  markdown_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  markdown_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  markdown_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  markdown_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  markdown_starts_with?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  /** All values containing the given json path. */
  qabu_json_path_exists?: InputMaybe<Scalars['String']>;
  /**
   * Recursively tries to find the provided JSON scalar value inside the field.
   * It does use an exact match when comparing values.
   * If you pass `null` as value the filter will be ignored.
   * Note: This filter fails if you try to look for a non scalar JSON value!
   */
  qabu_value_recursive?: InputMaybe<Scalars['Json']>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  test?: InputMaybe<Scalars['String']>;
  test2?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  test2_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  test2_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  test2_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  test2_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  test2_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  test2_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  test2_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  test2_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  test2_starts_with?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  test_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  test_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  test_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  test_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  test_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  test_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  test_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  test_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  test_starts_with?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<TextWhereInput>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  textList?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains *all* items provided to the filter */
  textList_contains_all?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  textList_contains_none?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains at least one item provided to the filter */
  textList_contains_some?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  textList_not?: InputMaybe<Array<Scalars['String']>>;
  texts_every?: InputMaybe<TextWhereInput>;
  texts_none?: InputMaybe<TextWhereInput>;
  texts_some?: InputMaybe<TextWhereInput>;
  title?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type SandboxWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SandboxWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SandboxWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SandboxWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<SandboxWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References Sandbox record uniquely */
export type SandboxWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

/** Scheduled Operation system model */
export type ScheduledOperation = Node & {
  __typename?: 'ScheduledOperation';
  affectedDocuments: Array<ScheduledOperationAffectedDocument>;
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** User that created this document */
  createdBy: Maybe<User>;
  /** Operation description */
  description: Maybe<Scalars['String']>;
  /** Get the document in other stages */
  documentInStages: Array<ScheduledOperation>;
  /** Operation error message */
  errorMessage: Maybe<Scalars['String']>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt: Maybe<Scalars['DateTime']>;
  /** User that last published this document */
  publishedBy: Maybe<User>;
  /** Raw operation payload including all details, this field is subject to change */
  rawPayload: Scalars['Json'];
  /** The release this operation is scheduled for */
  release: Maybe<ScheduledRelease>;
  /** System stage field */
  stage: Stage;
  /** operation Status */
  status: ScheduledOperationStatus;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** User that last updated this document */
  updatedBy: Maybe<User>;
};

/** Scheduled Operation system model */
export type ScheduledOperationAffectedDocumentsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  skip: InputMaybe<Scalars['Int']>;
};

/** Scheduled Operation system model */
export type ScheduledOperationCreatedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

/** Scheduled Operation system model */
export type ScheduledOperationDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};

/** Scheduled Operation system model */
export type ScheduledOperationPublishedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

/** Scheduled Operation system model */
export type ScheduledOperationReleaseArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

/** Scheduled Operation system model */
export type ScheduledOperationUpdatedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type ScheduledOperationAffectedDocument =
  | AddOn
  | AlacarteTicket
  | AlacarteTicketType
  | Article
  | ArticleCategory
  | Asset
  | Attraction
  | AttractionAlacarte
  | AttractionAvailability
  | AttractionLocation
  | AttractionOffer
  | AttractionPrice
  | AttractionReservation
  | AttractionVariant
  | Author
  | Block
  | BlockGroup
  | DetailBlock
  | GeneralReservation
  | Image
  | LandingPage
  | Link
  | Menu
  | Notice
  | Page
  | PageTemplate
  | Partner
  | PartnerOffer
  | Product
  | ProductActivity
  | ProductActivityItem
  | ProductAttractionLineup
  | ProductReservation
  | Promo
  | ReservationText
  | Sandbox
  | Seo
  | Text
  | TextGroup
  | TextList
  | TicketGroup
  | TitleDescription
  | Video;

export type ScheduledOperationConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: ScheduledOperationWhereUniqueInput;
};

/** A connection to a list of items. */
export type ScheduledOperationConnection = {
  __typename?: 'ScheduledOperationConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<ScheduledOperationEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type ScheduledOperationCreateManyInlineInput = {
  /** Connect multiple existing ScheduledOperation documents */
  connect?: InputMaybe<Array<ScheduledOperationWhereUniqueInput>>;
};

export type ScheduledOperationCreateOneInlineInput = {
  /** Connect one existing ScheduledOperation document */
  connect?: InputMaybe<ScheduledOperationWhereUniqueInput>;
};

/** An edge in a connection. */
export type ScheduledOperationEdge = {
  __typename?: 'ScheduledOperationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: ScheduledOperation;
};

/** Identifies documents */
export type ScheduledOperationManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ScheduledOperationWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ScheduledOperationWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ScheduledOperationWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  description?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  description_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars['String']>;
  errorMessage?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  errorMessage_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  errorMessage_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  errorMessage_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  errorMessage_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  errorMessage_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  errorMessage_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  errorMessage_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  errorMessage_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  errorMessage_starts_with?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  /** All values containing the given json path. */
  rawPayload_json_path_exists?: InputMaybe<Scalars['String']>;
  /**
   * Recursively tries to find the provided JSON scalar value inside the field.
   * It does use an exact match when comparing values.
   * If you pass `null` as value the filter will be ignored.
   * Note: This filter fails if you try to look for a non scalar JSON value!
   */
  rawPayload_value_recursive?: InputMaybe<Scalars['Json']>;
  release?: InputMaybe<ScheduledReleaseWhereInput>;
  status?: InputMaybe<ScheduledOperationStatus>;
  /** All values that are contained in given list. */
  status_in?: InputMaybe<Array<InputMaybe<ScheduledOperationStatus>>>;
  /** Any other value that exists and is not equal to the given value. */
  status_not?: InputMaybe<ScheduledOperationStatus>;
  /** All values that are not contained in given list. */
  status_not_in?: InputMaybe<Array<InputMaybe<ScheduledOperationStatus>>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum ScheduledOperationOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  ErrorMessageAsc = 'errorMessage_ASC',
  ErrorMessageDesc = 'errorMessage_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

/** System Scheduled Operation Status */
export enum ScheduledOperationStatus {
  Canceled = 'CANCELED',
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS',
  Pending = 'PENDING',
}

export type ScheduledOperationUpdateManyInlineInput = {
  /** Connect multiple existing ScheduledOperation documents */
  connect?: InputMaybe<Array<ScheduledOperationConnectInput>>;
  /** Disconnect multiple ScheduledOperation documents */
  disconnect?: InputMaybe<Array<ScheduledOperationWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing ScheduledOperation documents */
  set?: InputMaybe<Array<ScheduledOperationWhereUniqueInput>>;
};

export type ScheduledOperationUpdateOneInlineInput = {
  /** Connect existing ScheduledOperation document */
  connect?: InputMaybe<ScheduledOperationWhereUniqueInput>;
  /** Disconnect currently connected ScheduledOperation document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
};

/** Identifies documents */
export type ScheduledOperationWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ScheduledOperationWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ScheduledOperationWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ScheduledOperationWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  description?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  description_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars['String']>;
  errorMessage?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  errorMessage_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  errorMessage_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  errorMessage_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  errorMessage_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  errorMessage_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  errorMessage_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  errorMessage_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  errorMessage_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  errorMessage_starts_with?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  /** All values containing the given json path. */
  rawPayload_json_path_exists?: InputMaybe<Scalars['String']>;
  /**
   * Recursively tries to find the provided JSON scalar value inside the field.
   * It does use an exact match when comparing values.
   * If you pass `null` as value the filter will be ignored.
   * Note: This filter fails if you try to look for a non scalar JSON value!
   */
  rawPayload_value_recursive?: InputMaybe<Scalars['Json']>;
  release?: InputMaybe<ScheduledReleaseWhereInput>;
  status?: InputMaybe<ScheduledOperationStatus>;
  /** All values that are contained in given list. */
  status_in?: InputMaybe<Array<InputMaybe<ScheduledOperationStatus>>>;
  /** Any other value that exists and is not equal to the given value. */
  status_not?: InputMaybe<ScheduledOperationStatus>;
  /** All values that are not contained in given list. */
  status_not_in?: InputMaybe<Array<InputMaybe<ScheduledOperationStatus>>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** References ScheduledOperation record uniquely */
export type ScheduledOperationWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

/** Scheduled Release system model */
export type ScheduledRelease = Node & {
  __typename?: 'ScheduledRelease';
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** User that created this document */
  createdBy: Maybe<User>;
  /** Release description */
  description: Maybe<Scalars['String']>;
  /** Get the document in other stages */
  documentInStages: Array<ScheduledRelease>;
  /** Release error message */
  errorMessage: Maybe<Scalars['String']>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** Whether scheduled release should be run */
  isActive: Scalars['Boolean'];
  /** Whether scheduled release is implicit */
  isImplicit: Scalars['Boolean'];
  /** Operations to run with this release */
  operations: Array<ScheduledOperation>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt: Maybe<Scalars['DateTime']>;
  /** User that last published this document */
  publishedBy: Maybe<User>;
  /** Release date and time */
  releaseAt: Maybe<Scalars['DateTime']>;
  /** System stage field */
  stage: Stage;
  /** Release Status */
  status: ScheduledReleaseStatus;
  /** Release Title */
  title: Maybe<Scalars['String']>;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** User that last updated this document */
  updatedBy: Maybe<User>;
};

/** Scheduled Release system model */
export type ScheduledReleaseCreatedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

/** Scheduled Release system model */
export type ScheduledReleaseDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};

/** Scheduled Release system model */
export type ScheduledReleaseOperationsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<ScheduledOperationOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ScheduledOperationWhereInput>;
};

/** Scheduled Release system model */
export type ScheduledReleasePublishedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

/** Scheduled Release system model */
export type ScheduledReleaseUpdatedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type ScheduledReleaseConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: ScheduledReleaseWhereUniqueInput;
};

/** A connection to a list of items. */
export type ScheduledReleaseConnection = {
  __typename?: 'ScheduledReleaseConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<ScheduledReleaseEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type ScheduledReleaseCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  errorMessage?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ScheduledReleaseCreateManyInlineInput = {
  /** Connect multiple existing ScheduledRelease documents */
  connect?: InputMaybe<Array<ScheduledReleaseWhereUniqueInput>>;
  /** Create and connect multiple existing ScheduledRelease documents */
  create?: InputMaybe<Array<ScheduledReleaseCreateInput>>;
};

export type ScheduledReleaseCreateOneInlineInput = {
  /** Connect one existing ScheduledRelease document */
  connect?: InputMaybe<ScheduledReleaseWhereUniqueInput>;
  /** Create and connect one ScheduledRelease document */
  create?: InputMaybe<ScheduledReleaseCreateInput>;
};

/** An edge in a connection. */
export type ScheduledReleaseEdge = {
  __typename?: 'ScheduledReleaseEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: ScheduledRelease;
};

/** Identifies documents */
export type ScheduledReleaseManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ScheduledReleaseWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ScheduledReleaseWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ScheduledReleaseWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  description?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  description_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars['String']>;
  errorMessage?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  errorMessage_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  errorMessage_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  errorMessage_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  errorMessage_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  errorMessage_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  errorMessage_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  errorMessage_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  errorMessage_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  errorMessage_starts_with?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  isActive_not?: InputMaybe<Scalars['Boolean']>;
  isImplicit?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  isImplicit_not?: InputMaybe<Scalars['Boolean']>;
  operations_every?: InputMaybe<ScheduledOperationWhereInput>;
  operations_none?: InputMaybe<ScheduledOperationWhereInput>;
  operations_some?: InputMaybe<ScheduledOperationWhereInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  releaseAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  releaseAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  releaseAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  releaseAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  releaseAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  releaseAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  releaseAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  status?: InputMaybe<ScheduledReleaseStatus>;
  /** All values that are contained in given list. */
  status_in?: InputMaybe<Array<InputMaybe<ScheduledReleaseStatus>>>;
  /** Any other value that exists and is not equal to the given value. */
  status_not?: InputMaybe<ScheduledReleaseStatus>;
  /** All values that are not contained in given list. */
  status_not_in?: InputMaybe<Array<InputMaybe<ScheduledReleaseStatus>>>;
  title?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum ScheduledReleaseOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  ErrorMessageAsc = 'errorMessage_ASC',
  ErrorMessageDesc = 'errorMessage_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  IsActiveAsc = 'isActive_ASC',
  IsActiveDesc = 'isActive_DESC',
  IsImplicitAsc = 'isImplicit_ASC',
  IsImplicitDesc = 'isImplicit_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  ReleaseAtAsc = 'releaseAt_ASC',
  ReleaseAtDesc = 'releaseAt_DESC',
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

/** System Scheduled Release Status */
export enum ScheduledReleaseStatus {
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS',
  Pending = 'PENDING',
}

export type ScheduledReleaseUpdateInput = {
  description?: InputMaybe<Scalars['String']>;
  errorMessage?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  title?: InputMaybe<Scalars['String']>;
};

export type ScheduledReleaseUpdateManyInlineInput = {
  /** Connect multiple existing ScheduledRelease documents */
  connect?: InputMaybe<Array<ScheduledReleaseConnectInput>>;
  /** Create and connect multiple ScheduledRelease documents */
  create?: InputMaybe<Array<ScheduledReleaseCreateInput>>;
  /** Delete multiple ScheduledRelease documents */
  delete?: InputMaybe<Array<ScheduledReleaseWhereUniqueInput>>;
  /** Disconnect multiple ScheduledRelease documents */
  disconnect?: InputMaybe<Array<ScheduledReleaseWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing ScheduledRelease documents */
  set?: InputMaybe<Array<ScheduledReleaseWhereUniqueInput>>;
  /** Update multiple ScheduledRelease documents */
  update?: InputMaybe<Array<ScheduledReleaseUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple ScheduledRelease documents */
  upsert?: InputMaybe<Array<ScheduledReleaseUpsertWithNestedWhereUniqueInput>>;
};

export type ScheduledReleaseUpdateManyInput = {
  description?: InputMaybe<Scalars['String']>;
  errorMessage?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  title?: InputMaybe<Scalars['String']>;
};

export type ScheduledReleaseUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: ScheduledReleaseUpdateManyInput;
  /** Document search */
  where: ScheduledReleaseWhereInput;
};

export type ScheduledReleaseUpdateOneInlineInput = {
  /** Connect existing ScheduledRelease document */
  connect?: InputMaybe<ScheduledReleaseWhereUniqueInput>;
  /** Create and connect one ScheduledRelease document */
  create?: InputMaybe<ScheduledReleaseCreateInput>;
  /** Delete currently connected ScheduledRelease document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected ScheduledRelease document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single ScheduledRelease document */
  update?: InputMaybe<ScheduledReleaseUpdateWithNestedWhereUniqueInput>;
  /** Upsert single ScheduledRelease document */
  upsert?: InputMaybe<ScheduledReleaseUpsertWithNestedWhereUniqueInput>;
};

export type ScheduledReleaseUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: ScheduledReleaseUpdateInput;
  /** Unique document search */
  where: ScheduledReleaseWhereUniqueInput;
};

export type ScheduledReleaseUpsertInput = {
  /** Create document if it didn't exist */
  create: ScheduledReleaseCreateInput;
  /** Update document if it exists */
  update: ScheduledReleaseUpdateInput;
};

export type ScheduledReleaseUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: ScheduledReleaseUpsertInput;
  /** Unique document search */
  where: ScheduledReleaseWhereUniqueInput;
};

/** Identifies documents */
export type ScheduledReleaseWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ScheduledReleaseWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ScheduledReleaseWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ScheduledReleaseWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  description?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  description_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars['String']>;
  errorMessage?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  errorMessage_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  errorMessage_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  errorMessage_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  errorMessage_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  errorMessage_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  errorMessage_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  errorMessage_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  errorMessage_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  errorMessage_starts_with?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  isActive_not?: InputMaybe<Scalars['Boolean']>;
  isImplicit?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  isImplicit_not?: InputMaybe<Scalars['Boolean']>;
  operations_every?: InputMaybe<ScheduledOperationWhereInput>;
  operations_none?: InputMaybe<ScheduledOperationWhereInput>;
  operations_some?: InputMaybe<ScheduledOperationWhereInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  releaseAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  releaseAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  releaseAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  releaseAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  releaseAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  releaseAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  releaseAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  status?: InputMaybe<ScheduledReleaseStatus>;
  /** All values that are contained in given list. */
  status_in?: InputMaybe<Array<InputMaybe<ScheduledReleaseStatus>>>;
  /** Any other value that exists and is not equal to the given value. */
  status_not?: InputMaybe<ScheduledReleaseStatus>;
  /** All values that are not contained in given list. */
  status_not_in?: InputMaybe<Array<InputMaybe<ScheduledReleaseStatus>>>;
  title?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** References ScheduledRelease record uniquely */
export type ScheduledReleaseWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type Seo = Node & {
  __typename?: 'Seo';
  attractions: Array<Attraction>;
  canonical: Maybe<Scalars['String']>;
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** User that created this document */
  createdBy: Maybe<User>;
  description: Maybe<Scalars['String']>;
  /** Get the document in other stages */
  documentInStages: Array<Seo>;
  /** If true then <meta name="robots" content="noindex,nofollow"/> will be added to the page. */
  excludeRobots: Scalars['Boolean'];
  /** List of Seo versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID'];
  internalTags: Array<Scalars['String']>;
  internalTitle: Scalars['String'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<Seo>;
  pageTemplates: Array<PageTemplate>;
  productSeos: Array<Product>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt: Maybe<Scalars['DateTime']>;
  /** User that last published this document */
  publishedBy: Maybe<User>;
  qabu: Maybe<Scalars['Json']>;
  scheduledIn: Array<ScheduledOperation>;
  /** System stage field */
  stage: Stage;
  title: Maybe<Scalars['String']>;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** User that last updated this document */
  updatedBy: Maybe<User>;
};

export type SeoAttractionsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<AttractionOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AttractionWhereInput>;
};

export type SeoCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type SeoCreatedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type SeoDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};

export type SeoHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride: InputMaybe<Stage>;
};

export type SeoLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};

export type SeoPageTemplatesArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<PageTemplateOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<PageTemplateWhereInput>;
};

export type SeoProductSeosArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<ProductOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ProductWhereInput>;
};

export type SeoPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type SeoPublishedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type SeoScheduledInArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ScheduledOperationWhereInput>;
};

export type SeoUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type SeoUpdatedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type SeoConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: SeoWhereUniqueInput;
};

/** A connection to a list of items. */
export type SeoConnection = {
  __typename?: 'SeoConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<SeoEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type SeoCreateInput = {
  attractions?: InputMaybe<AttractionCreateManyInlineInput>;
  canonical?: InputMaybe<Scalars['String']>;
  ckwolze6t0q2j01z16px2fylz?: InputMaybe<PageCreateManyInlineInput>;
  ckxa3kabc1xo801yz142v52x8?: InputMaybe<ArticleCreateManyInlineInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** description input for default locale (en) */
  description?: InputMaybe<Scalars['String']>;
  excludeRobots: Scalars['Boolean'];
  internalTags?: InputMaybe<Array<Scalars['String']>>;
  internalTitle: Scalars['String'];
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<SeoCreateLocalizationsInput>;
  pageTemplates?: InputMaybe<PageTemplateCreateManyInlineInput>;
  productSeos?: InputMaybe<ProductCreateManyInlineInput>;
  qabu?: InputMaybe<Scalars['Json']>;
  /** title input for default locale (en) */
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type SeoCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type SeoCreateLocalizationInput = {
  /** Localization input */
  data: SeoCreateLocalizationDataInput;
  locale: Locale;
};

export type SeoCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<SeoCreateLocalizationInput>>;
};

export type SeoCreateManyInlineInput = {
  /** Connect multiple existing Seo documents */
  connect?: InputMaybe<Array<SeoWhereUniqueInput>>;
  /** Create and connect multiple existing Seo documents */
  create?: InputMaybe<Array<SeoCreateInput>>;
};

export type SeoCreateOneInlineInput = {
  /** Connect one existing Seo document */
  connect?: InputMaybe<SeoWhereUniqueInput>;
  /** Create and connect one Seo document */
  create?: InputMaybe<SeoCreateInput>;
};

/** An edge in a connection. */
export type SeoEdge = {
  __typename?: 'SeoEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Seo;
};

/** Identifies documents */
export type SeoManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SeoWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SeoWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SeoWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  attractions_every?: InputMaybe<AttractionWhereInput>;
  attractions_none?: InputMaybe<AttractionWhereInput>;
  attractions_some?: InputMaybe<AttractionWhereInput>;
  canonical?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  canonical_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  canonical_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  canonical_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  canonical_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  canonical_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  canonical_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  canonical_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  canonical_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  canonical_starts_with?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<SeoWhereStageInput>;
  documentInStages_none?: InputMaybe<SeoWhereStageInput>;
  documentInStages_some?: InputMaybe<SeoWhereStageInput>;
  excludeRobots?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  excludeRobots_not?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  internalTags?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains *all* items provided to the filter */
  internalTags_contains_all?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  internalTags_contains_none?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains at least one item provided to the filter */
  internalTags_contains_some?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  internalTags_not?: InputMaybe<Array<Scalars['String']>>;
  internalTitle?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  internalTitle_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  internalTitle_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  internalTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  internalTitle_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  internalTitle_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  internalTitle_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  internalTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  internalTitle_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  internalTitle_starts_with?: InputMaybe<Scalars['String']>;
  pageTemplates_every?: InputMaybe<PageTemplateWhereInput>;
  pageTemplates_none?: InputMaybe<PageTemplateWhereInput>;
  pageTemplates_some?: InputMaybe<PageTemplateWhereInput>;
  productSeos_every?: InputMaybe<ProductWhereInput>;
  productSeos_none?: InputMaybe<ProductWhereInput>;
  productSeos_some?: InputMaybe<ProductWhereInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  /** All values containing the given json path. */
  qabu_json_path_exists?: InputMaybe<Scalars['String']>;
  /**
   * Recursively tries to find the provided JSON scalar value inside the field.
   * It does use an exact match when comparing values.
   * If you pass `null` as value the filter will be ignored.
   * Note: This filter fails if you try to look for a non scalar JSON value!
   */
  qabu_value_recursive?: InputMaybe<Scalars['Json']>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum SeoOrderByInput {
  CanonicalAsc = 'canonical_ASC',
  CanonicalDesc = 'canonical_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  ExcludeRobotsAsc = 'excludeRobots_ASC',
  ExcludeRobotsDesc = 'excludeRobots_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  InternalTagsAsc = 'internalTags_ASC',
  InternalTagsDesc = 'internalTags_DESC',
  InternalTitleAsc = 'internalTitle_ASC',
  InternalTitleDesc = 'internalTitle_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type SeoUpdateInput = {
  attractions?: InputMaybe<AttractionUpdateManyInlineInput>;
  canonical?: InputMaybe<Scalars['String']>;
  ckwolze6t0q2j01z16px2fylz?: InputMaybe<PageUpdateManyInlineInput>;
  ckxa3kabc1xo801yz142v52x8?: InputMaybe<ArticleUpdateManyInlineInput>;
  /** description input for default locale (en) */
  description?: InputMaybe<Scalars['String']>;
  excludeRobots?: InputMaybe<Scalars['Boolean']>;
  internalTags?: InputMaybe<Array<Scalars['String']>>;
  internalTitle?: InputMaybe<Scalars['String']>;
  /** Manage document localizations */
  localizations?: InputMaybe<SeoUpdateLocalizationsInput>;
  pageTemplates?: InputMaybe<PageTemplateUpdateManyInlineInput>;
  productSeos?: InputMaybe<ProductUpdateManyInlineInput>;
  qabu?: InputMaybe<Scalars['Json']>;
  /** title input for default locale (en) */
  title?: InputMaybe<Scalars['String']>;
};

export type SeoUpdateLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type SeoUpdateLocalizationInput = {
  data: SeoUpdateLocalizationDataInput;
  locale: Locale;
};

export type SeoUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<SeoCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<SeoUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<SeoUpsertLocalizationInput>>;
};

export type SeoUpdateManyInlineInput = {
  /** Connect multiple existing Seo documents */
  connect?: InputMaybe<Array<SeoConnectInput>>;
  /** Create and connect multiple Seo documents */
  create?: InputMaybe<Array<SeoCreateInput>>;
  /** Delete multiple Seo documents */
  delete?: InputMaybe<Array<SeoWhereUniqueInput>>;
  /** Disconnect multiple Seo documents */
  disconnect?: InputMaybe<Array<SeoWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing Seo documents */
  set?: InputMaybe<Array<SeoWhereUniqueInput>>;
  /** Update multiple Seo documents */
  update?: InputMaybe<Array<SeoUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple Seo documents */
  upsert?: InputMaybe<Array<SeoUpsertWithNestedWhereUniqueInput>>;
};

export type SeoUpdateManyInput = {
  canonical?: InputMaybe<Scalars['String']>;
  /** description input for default locale (en) */
  description?: InputMaybe<Scalars['String']>;
  excludeRobots?: InputMaybe<Scalars['Boolean']>;
  internalTags?: InputMaybe<Array<Scalars['String']>>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<SeoUpdateManyLocalizationsInput>;
  qabu?: InputMaybe<Scalars['Json']>;
  /** title input for default locale (en) */
  title?: InputMaybe<Scalars['String']>;
};

export type SeoUpdateManyLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type SeoUpdateManyLocalizationInput = {
  data: SeoUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type SeoUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<SeoUpdateManyLocalizationInput>>;
};

export type SeoUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: SeoUpdateManyInput;
  /** Document search */
  where: SeoWhereInput;
};

export type SeoUpdateOneInlineInput = {
  /** Connect existing Seo document */
  connect?: InputMaybe<SeoWhereUniqueInput>;
  /** Create and connect one Seo document */
  create?: InputMaybe<SeoCreateInput>;
  /** Delete currently connected Seo document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected Seo document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single Seo document */
  update?: InputMaybe<SeoUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Seo document */
  upsert?: InputMaybe<SeoUpsertWithNestedWhereUniqueInput>;
};

export type SeoUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: SeoUpdateInput;
  /** Unique document search */
  where: SeoWhereUniqueInput;
};

export type SeoUpsertInput = {
  /** Create document if it didn't exist */
  create: SeoCreateInput;
  /** Update document if it exists */
  update: SeoUpdateInput;
};

export type SeoUpsertLocalizationInput = {
  create: SeoCreateLocalizationDataInput;
  locale: Locale;
  update: SeoUpdateLocalizationDataInput;
};

export type SeoUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: SeoUpsertInput;
  /** Unique document search */
  where: SeoWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type SeoWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']>;
};

/** Identifies documents */
export type SeoWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SeoWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SeoWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SeoWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  attractions_every?: InputMaybe<AttractionWhereInput>;
  attractions_none?: InputMaybe<AttractionWhereInput>;
  attractions_some?: InputMaybe<AttractionWhereInput>;
  canonical?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  canonical_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  canonical_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  canonical_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  canonical_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  canonical_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  canonical_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  canonical_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  canonical_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  canonical_starts_with?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  description?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  description_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars['String']>;
  documentInStages_every?: InputMaybe<SeoWhereStageInput>;
  documentInStages_none?: InputMaybe<SeoWhereStageInput>;
  documentInStages_some?: InputMaybe<SeoWhereStageInput>;
  excludeRobots?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  excludeRobots_not?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  internalTags?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains *all* items provided to the filter */
  internalTags_contains_all?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  internalTags_contains_none?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains at least one item provided to the filter */
  internalTags_contains_some?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  internalTags_not?: InputMaybe<Array<Scalars['String']>>;
  internalTitle?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  internalTitle_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  internalTitle_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  internalTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  internalTitle_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  internalTitle_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  internalTitle_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  internalTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  internalTitle_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  internalTitle_starts_with?: InputMaybe<Scalars['String']>;
  pageTemplates_every?: InputMaybe<PageTemplateWhereInput>;
  pageTemplates_none?: InputMaybe<PageTemplateWhereInput>;
  pageTemplates_some?: InputMaybe<PageTemplateWhereInput>;
  productSeos_every?: InputMaybe<ProductWhereInput>;
  productSeos_none?: InputMaybe<ProductWhereInput>;
  productSeos_some?: InputMaybe<ProductWhereInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  /** All values containing the given json path. */
  qabu_json_path_exists?: InputMaybe<Scalars['String']>;
  /**
   * Recursively tries to find the provided JSON scalar value inside the field.
   * It does use an exact match when comparing values.
   * If you pass `null` as value the filter will be ignored.
   * Note: This filter fails if you try to look for a non scalar JSON value!
   */
  qabu_value_recursive?: InputMaybe<Scalars['Json']>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  title?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type SeoWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SeoWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SeoWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SeoWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<SeoWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References Seo record uniquely */
export type SeoWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
  internalTitle?: InputMaybe<Scalars['String']>;
};

export enum SiteSection {
  Content = 'content',
  CoreCommerce = 'coreCommerce',
  Error = 'error',
  EvergreenContent = 'evergreenContent',
  GeneralInfo = 'generalInfo',
  PartnerInfo = 'partnerInfo',
  Promotions = 'promotions',
}

export type SkuProperty = {
  __typename?: 'SkuProperty';
  /** The unique identifier */
  id: Scalars['ID'];
  sku: Scalars['String'];
  /** System stage field */
  stage: Stage;
  type: SkuTicketTypeProperty;
  value: Scalars['String'];
};

export type SkuPropertyConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: SkuPropertyWhereUniqueInput;
};

/** A connection to a list of items. */
export type SkuPropertyConnection = {
  __typename?: 'SkuPropertyConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<SkuPropertyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type SkuPropertyCreateInput = {
  sku: Scalars['String'];
  type: SkuTicketTypeProperty;
  value: Scalars['String'];
};

export type SkuPropertyCreateManyInlineInput = {
  /** Create and connect multiple existing SkuProperty documents */
  create?: InputMaybe<Array<SkuPropertyCreateInput>>;
};

export type SkuPropertyCreateOneInlineInput = {
  /** Create and connect one SkuProperty document */
  create?: InputMaybe<SkuPropertyCreateInput>;
};

export type SkuPropertyCreateWithPositionInput = {
  /** Document to create */
  data: SkuPropertyCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type SkuPropertyEdge = {
  __typename?: 'SkuPropertyEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: SkuProperty;
};

/** Identifies documents */
export type SkuPropertyManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SkuPropertyWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SkuPropertyWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SkuPropertyWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  sku?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  sku_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  sku_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  sku_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  sku_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  sku_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  sku_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  sku_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  sku_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  sku_starts_with?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<SkuTicketTypeProperty>;
  /** All values that are contained in given list. */
  type_in?: InputMaybe<Array<InputMaybe<SkuTicketTypeProperty>>>;
  /** Any other value that exists and is not equal to the given value. */
  type_not?: InputMaybe<SkuTicketTypeProperty>;
  /** All values that are not contained in given list. */
  type_not_in?: InputMaybe<Array<InputMaybe<SkuTicketTypeProperty>>>;
  value?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  value_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  value_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  value_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  value_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  value_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  value_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  value_starts_with?: InputMaybe<Scalars['String']>;
};

export enum SkuPropertyOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  SkuAsc = 'sku_ASC',
  SkuDesc = 'sku_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  ValueAsc = 'value_ASC',
  ValueDesc = 'value_DESC',
}

export type SkuPropertyParent = Attraction;

export type SkuPropertyParentConnectInput = {
  Attraction?: InputMaybe<AttractionConnectInput>;
};

export type SkuPropertyParentCreateInput = {
  Attraction?: InputMaybe<AttractionCreateInput>;
};

export type SkuPropertyParentCreateManyInlineInput = {
  /** Connect multiple existing SkuPropertyParent documents */
  connect?: InputMaybe<Array<SkuPropertyParentWhereUniqueInput>>;
  /** Create and connect multiple existing SkuPropertyParent documents */
  create?: InputMaybe<Array<SkuPropertyParentCreateInput>>;
};

export type SkuPropertyParentCreateOneInlineInput = {
  /** Connect one existing SkuPropertyParent document */
  connect?: InputMaybe<SkuPropertyParentWhereUniqueInput>;
  /** Create and connect one SkuPropertyParent document */
  create?: InputMaybe<SkuPropertyParentCreateInput>;
};

export type SkuPropertyParentUpdateInput = {
  Attraction?: InputMaybe<AttractionUpdateInput>;
};

export type SkuPropertyParentUpdateManyInlineInput = {
  /** Connect multiple existing SkuPropertyParent documents */
  connect?: InputMaybe<Array<SkuPropertyParentConnectInput>>;
  /** Create and connect multiple SkuPropertyParent documents */
  create?: InputMaybe<Array<SkuPropertyParentCreateInput>>;
  /** Delete multiple SkuPropertyParent documents */
  delete?: InputMaybe<Array<SkuPropertyParentWhereUniqueInput>>;
  /** Disconnect multiple SkuPropertyParent documents */
  disconnect?: InputMaybe<Array<SkuPropertyParentWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing SkuPropertyParent documents */
  set?: InputMaybe<Array<SkuPropertyParentWhereUniqueInput>>;
  /** Update multiple SkuPropertyParent documents */
  update?: InputMaybe<Array<SkuPropertyParentUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple SkuPropertyParent documents */
  upsert?: InputMaybe<Array<SkuPropertyParentUpsertWithNestedWhereUniqueInput>>;
};

export type SkuPropertyParentUpdateManyWithNestedWhereInput = {
  Attraction?: InputMaybe<AttractionUpdateManyWithNestedWhereInput>;
};

export type SkuPropertyParentUpdateOneInlineInput = {
  /** Connect existing SkuPropertyParent document */
  connect?: InputMaybe<SkuPropertyParentWhereUniqueInput>;
  /** Create and connect one SkuPropertyParent document */
  create?: InputMaybe<SkuPropertyParentCreateInput>;
  /** Delete currently connected SkuPropertyParent document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected SkuPropertyParent document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single SkuPropertyParent document */
  update?: InputMaybe<SkuPropertyParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single SkuPropertyParent document */
  upsert?: InputMaybe<SkuPropertyParentUpsertWithNestedWhereUniqueInput>;
};

export type SkuPropertyParentUpdateWithNestedWhereUniqueInput = {
  Attraction?: InputMaybe<AttractionUpdateWithNestedWhereUniqueInput>;
};

export type SkuPropertyParentUpsertWithNestedWhereUniqueInput = {
  Attraction?: InputMaybe<AttractionUpsertWithNestedWhereUniqueInput>;
};

export type SkuPropertyParentWhereInput = {
  Attraction?: InputMaybe<AttractionWhereInput>;
};

export type SkuPropertyParentWhereUniqueInput = {
  Attraction?: InputMaybe<AttractionWhereUniqueInput>;
};

export type SkuPropertyUpdateInput = {
  sku?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<SkuTicketTypeProperty>;
  value?: InputMaybe<Scalars['String']>;
};

export type SkuPropertyUpdateManyInlineInput = {
  /** Create and connect multiple SkuProperty component instances */
  create?: InputMaybe<Array<SkuPropertyCreateWithPositionInput>>;
  /** Delete multiple SkuProperty documents */
  delete?: InputMaybe<Array<SkuPropertyWhereUniqueInput>>;
  /** Update multiple SkuProperty component instances */
  update?: InputMaybe<Array<SkuPropertyUpdateWithNestedWhereUniqueAndPositionInput>>;
  /** Upsert multiple SkuProperty component instances */
  upsert?: InputMaybe<Array<SkuPropertyUpsertWithNestedWhereUniqueAndPositionInput>>;
};

export type SkuPropertyUpdateManyInput = {
  sku?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<SkuTicketTypeProperty>;
  value?: InputMaybe<Scalars['String']>;
};

export type SkuPropertyUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: SkuPropertyUpdateManyInput;
  /** Document search */
  where: SkuPropertyWhereInput;
};

export type SkuPropertyUpdateOneInlineInput = {
  /** Create and connect one SkuProperty document */
  create?: InputMaybe<SkuPropertyCreateInput>;
  /** Delete currently connected SkuProperty document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single SkuProperty document */
  update?: InputMaybe<SkuPropertyUpdateWithNestedWhereUniqueInput>;
  /** Upsert single SkuProperty document */
  upsert?: InputMaybe<SkuPropertyUpsertWithNestedWhereUniqueInput>;
};

export type SkuPropertyUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<SkuPropertyUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: SkuPropertyWhereUniqueInput;
};

export type SkuPropertyUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: SkuPropertyUpdateInput;
  /** Unique document search */
  where: SkuPropertyWhereUniqueInput;
};

export type SkuPropertyUpsertInput = {
  /** Create document if it didn't exist */
  create: SkuPropertyCreateInput;
  /** Update document if it exists */
  update: SkuPropertyUpdateInput;
};

export type SkuPropertyUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<SkuPropertyUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: SkuPropertyWhereUniqueInput;
};

export type SkuPropertyUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: SkuPropertyUpsertInput;
  /** Unique document search */
  where: SkuPropertyWhereUniqueInput;
};

/** Identifies documents */
export type SkuPropertyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SkuPropertyWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SkuPropertyWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SkuPropertyWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  sku?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  sku_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  sku_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  sku_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  sku_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  sku_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  sku_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  sku_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  sku_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  sku_starts_with?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<SkuTicketTypeProperty>;
  /** All values that are contained in given list. */
  type_in?: InputMaybe<Array<InputMaybe<SkuTicketTypeProperty>>>;
  /** Any other value that exists and is not equal to the given value. */
  type_not?: InputMaybe<SkuTicketTypeProperty>;
  /** All values that are not contained in given list. */
  type_not_in?: InputMaybe<Array<InputMaybe<SkuTicketTypeProperty>>>;
  value?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  value_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  value_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  value_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  value_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  value_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  value_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  value_starts_with?: InputMaybe<Scalars['String']>;
};

/** References SkuProperty record uniquely */
export type SkuPropertyWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export enum SkuTicketTypeProperty {
  Age = 'age',
  ExpirationDate = 'expirationDate',
}

/** Stage system enumeration */
export enum Stage {
  /** The Draft is the default stage for all your content. */
  Draft = 'DRAFT',
  /** The Published stage is where you can publish your content to. */
  Published = 'PUBLISHED',
}

export enum SystemDateTimeFieldVariation {
  Base = 'BASE',
  Combined = 'COMBINED',
  Localization = 'LOCALIZATION',
}

export type Text = Node & {
  __typename?: 'Text';
  alacarteTicketTypeDescription: Array<AlacarteTicketType>;
  alacarteTicketTypeInclusions: Array<AlacarteTicketType>;
  alacarteTicketTypeInclusionsNote: Array<AlacarteTicketType>;
  alacarteTicketTypeRestrictions: Array<AlacarteTicketType>;
  alacarteTicketTypeValidPostPurchaseForDateRange: Array<AlacarteTicketType>;
  alacarteTicketTypeValidity: Array<AlacarteTicketType>;
  alacarteTicketTypeValidityPostPurchase: Array<AlacarteTicketType>;
  alacarteTicketTypeValidityPostPurchaseNote: Array<AlacarteTicketType>;
  alacarteTicketTypeValidityShort: Array<AlacarteTicketType>;
  alacarteTicketTypeValidityWillCall: Array<AlacarteTicketType>;
  attractionAdmission: Array<Attraction>;
  attractionAlacarteDescription: Array<AttractionAlacarte>;
  attractionAlacarteRedemption: Array<AttractionAlacarte>;
  attractionAlacarteRedemptionShort: Array<AttractionAlacarte>;
  attractionAlacarteRestrictionsCommon: Array<AttractionAlacarte>;
  attractionAlacarteTermsAndConditions: Array<AttractionAlacarte>;
  attractionAlacarteValidityCommon: Array<AttractionAlacarte>;
  attractionAlacarteValidityWillCall: Array<AttractionAlacarte>;
  attractionAmenities: Array<Attraction>;
  attractionHighlights: Array<Attraction>;
  attractionLocationHoursNotes: Array<AttractionLocation>;
  attractionOpenStatus: Array<Attraction>;
  attractionReservations: Array<AttractionReservation>;
  attractionSafety: Array<Attraction>;
  attractionTips: Array<Attraction>;
  attractionVariantAdmission: Array<AttractionVariant>;
  attractionVariantHighlights: Array<AttractionVariant>;
  attractionVariantOpenStatus: Array<AttractionVariant>;
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** User that created this document */
  createdBy: Maybe<User>;
  /** Get the document in other stages */
  documentInStages: Array<Text>;
  /** List of Text versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID'];
  key: Scalars['String'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<Text>;
  productAdmissionTitles: Array<Product>;
  productAttractionLineups: Array<ProductAttractionLineup>;
  productAttractionLineupsSubtitle: Array<ProductAttractionLineup>;
  productAttractionLineupsTitle: Array<ProductAttractionLineup>;
  productHeadlines: Array<Product>;
  productReservations: Array<ProductReservation>;
  products: Array<Product>;
  productsCityName: Array<Product>;
  productsDescription: Array<Product>;
  productsTagline: Array<Product>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt: Maybe<Scalars['DateTime']>;
  /** User that last published this document */
  publishedBy: Maybe<User>;
  qabu: Maybe<Scalars['Json']>;
  scheduledIn: Array<ScheduledOperation>;
  /** System stage field */
  stage: Stage;
  tags: Array<Scalars['String']>;
  textGroups: Array<TextGroup>;
  title: Array<ProductActivity>;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** User that last updated this document */
  updatedBy: Maybe<User>;
  value: Maybe<Scalars['String']>;
};

export type TextAlacarteTicketTypeDescriptionArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<AlacarteTicketTypeOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AlacarteTicketTypeWhereInput>;
};

export type TextAlacarteTicketTypeInclusionsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<AlacarteTicketTypeOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AlacarteTicketTypeWhereInput>;
};

export type TextAlacarteTicketTypeInclusionsNoteArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<AlacarteTicketTypeOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AlacarteTicketTypeWhereInput>;
};

export type TextAlacarteTicketTypeRestrictionsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<AlacarteTicketTypeOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AlacarteTicketTypeWhereInput>;
};

export type TextAlacarteTicketTypeValidPostPurchaseForDateRangeArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<AlacarteTicketTypeOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AlacarteTicketTypeWhereInput>;
};

export type TextAlacarteTicketTypeValidityArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<AlacarteTicketTypeOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AlacarteTicketTypeWhereInput>;
};

export type TextAlacarteTicketTypeValidityPostPurchaseArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<AlacarteTicketTypeOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AlacarteTicketTypeWhereInput>;
};

export type TextAlacarteTicketTypeValidityPostPurchaseNoteArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<AlacarteTicketTypeOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AlacarteTicketTypeWhereInput>;
};

export type TextAlacarteTicketTypeValidityShortArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<AlacarteTicketTypeOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AlacarteTicketTypeWhereInput>;
};

export type TextAlacarteTicketTypeValidityWillCallArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<AlacarteTicketTypeOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AlacarteTicketTypeWhereInput>;
};

export type TextAttractionAdmissionArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<AttractionOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AttractionWhereInput>;
};

export type TextAttractionAlacarteDescriptionArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<AttractionAlacarteOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AttractionAlacarteWhereInput>;
};

export type TextAttractionAlacarteRedemptionArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<AttractionAlacarteOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AttractionAlacarteWhereInput>;
};

export type TextAttractionAlacarteRedemptionShortArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<AttractionAlacarteOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AttractionAlacarteWhereInput>;
};

export type TextAttractionAlacarteRestrictionsCommonArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<AttractionAlacarteOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AttractionAlacarteWhereInput>;
};

export type TextAttractionAlacarteTermsAndConditionsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<AttractionAlacarteOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AttractionAlacarteWhereInput>;
};

export type TextAttractionAlacarteValidityCommonArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<AttractionAlacarteOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AttractionAlacarteWhereInput>;
};

export type TextAttractionAlacarteValidityWillCallArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<AttractionAlacarteOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AttractionAlacarteWhereInput>;
};

export type TextAttractionAmenitiesArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<AttractionOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AttractionWhereInput>;
};

export type TextAttractionHighlightsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<AttractionOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AttractionWhereInput>;
};

export type TextAttractionLocationHoursNotesArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<AttractionLocationOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AttractionLocationWhereInput>;
};

export type TextAttractionOpenStatusArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<AttractionOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AttractionWhereInput>;
};

export type TextAttractionReservationsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<AttractionReservationOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AttractionReservationWhereInput>;
};

export type TextAttractionSafetyArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<AttractionOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AttractionWhereInput>;
};

export type TextAttractionTipsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<AttractionOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AttractionWhereInput>;
};

export type TextAttractionVariantAdmissionArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<AttractionVariantOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AttractionVariantWhereInput>;
};

export type TextAttractionVariantHighlightsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<AttractionVariantOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AttractionVariantWhereInput>;
};

export type TextAttractionVariantOpenStatusArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<AttractionVariantOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AttractionVariantWhereInput>;
};

export type TextCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type TextCreatedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type TextDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};

export type TextHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride: InputMaybe<Stage>;
};

export type TextLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};

export type TextProductAdmissionTitlesArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<ProductOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ProductWhereInput>;
};

export type TextProductAttractionLineupsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<ProductAttractionLineupOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ProductAttractionLineupWhereInput>;
};

export type TextProductAttractionLineupsSubtitleArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<ProductAttractionLineupOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ProductAttractionLineupWhereInput>;
};

export type TextProductAttractionLineupsTitleArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<ProductAttractionLineupOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ProductAttractionLineupWhereInput>;
};

export type TextProductHeadlinesArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<ProductOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ProductWhereInput>;
};

export type TextProductReservationsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<ProductReservationOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ProductReservationWhereInput>;
};

export type TextProductsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<ProductOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ProductWhereInput>;
};

export type TextProductsCityNameArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<ProductOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ProductWhereInput>;
};

export type TextProductsDescriptionArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<ProductOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ProductWhereInput>;
};

export type TextProductsTaglineArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<ProductOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ProductWhereInput>;
};

export type TextPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type TextPublishedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type TextScheduledInArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ScheduledOperationWhereInput>;
};

export type TextTextGroupsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<TextGroupWhereInput>;
};

export type TextTitleArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<ProductActivityOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ProductActivityWhereInput>;
};

export type TextUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type TextUpdatedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type TextConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: TextWhereUniqueInput;
};

/** A connection to a list of items. */
export type TextConnection = {
  __typename?: 'TextConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<TextEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type TextCreateInput = {
  alacarteTicketTypeDescription?: InputMaybe<AlacarteTicketTypeCreateManyInlineInput>;
  alacarteTicketTypeInclusions?: InputMaybe<AlacarteTicketTypeCreateManyInlineInput>;
  alacarteTicketTypeInclusionsNote?: InputMaybe<AlacarteTicketTypeCreateManyInlineInput>;
  alacarteTicketTypeRestrictions?: InputMaybe<AlacarteTicketTypeCreateManyInlineInput>;
  alacarteTicketTypeValidPostPurchaseForDateRange?: InputMaybe<AlacarteTicketTypeCreateManyInlineInput>;
  alacarteTicketTypeValidity?: InputMaybe<AlacarteTicketTypeCreateManyInlineInput>;
  alacarteTicketTypeValidityPostPurchase?: InputMaybe<AlacarteTicketTypeCreateManyInlineInput>;
  alacarteTicketTypeValidityPostPurchaseNote?: InputMaybe<AlacarteTicketTypeCreateManyInlineInput>;
  alacarteTicketTypeValidityShort?: InputMaybe<AlacarteTicketTypeCreateManyInlineInput>;
  alacarteTicketTypeValidityWillCall?: InputMaybe<AlacarteTicketTypeCreateManyInlineInput>;
  attractionAdmission?: InputMaybe<AttractionCreateManyInlineInput>;
  attractionAlacarteDescription?: InputMaybe<AttractionAlacarteCreateManyInlineInput>;
  attractionAlacarteRedemption?: InputMaybe<AttractionAlacarteCreateManyInlineInput>;
  attractionAlacarteRedemptionShort?: InputMaybe<AttractionAlacarteCreateManyInlineInput>;
  attractionAlacarteRestrictionsCommon?: InputMaybe<AttractionAlacarteCreateManyInlineInput>;
  attractionAlacarteTermsAndConditions?: InputMaybe<AttractionAlacarteCreateManyInlineInput>;
  attractionAlacarteValidityCommon?: InputMaybe<AttractionAlacarteCreateManyInlineInput>;
  attractionAlacarteValidityWillCall?: InputMaybe<AttractionAlacarteCreateManyInlineInput>;
  attractionAmenities?: InputMaybe<AttractionCreateManyInlineInput>;
  attractionHighlights?: InputMaybe<AttractionCreateManyInlineInput>;
  attractionLocationHoursNotes?: InputMaybe<AttractionLocationCreateManyInlineInput>;
  attractionOpenStatus?: InputMaybe<AttractionCreateManyInlineInput>;
  attractionReservations?: InputMaybe<AttractionReservationCreateManyInlineInput>;
  attractionSafety?: InputMaybe<AttractionCreateManyInlineInput>;
  attractionTips?: InputMaybe<AttractionCreateManyInlineInput>;
  attractionVariantAdmission?: InputMaybe<AttractionVariantCreateManyInlineInput>;
  attractionVariantHighlights?: InputMaybe<AttractionVariantCreateManyInlineInput>;
  attractionVariantOpenStatus?: InputMaybe<AttractionVariantCreateManyInlineInput>;
  ckwzcvr81249j01z4c6cf66g8?: InputMaybe<SandboxCreateManyInlineInput>;
  ckwzcw6hr23s601z06njp2mf8?: InputMaybe<SandboxCreateManyInlineInput>;
  ckyb2vp2r0xc001z1azrd6fyn?: InputMaybe<LinkCreateManyInlineInput>;
  ckydtod2w0azx01xwbngodzlh?: InputMaybe<MenuCreateManyInlineInput>;
  cl1y3z86e0xlj01xteyweawlm?: InputMaybe<TextListCreateManyInlineInput>;
  cl2jgcyk10nab01xh6mz4h4lm?: InputMaybe<AttractionGroupCreateManyInlineInput>;
  clbl4g5i306y701tc9cwe1eqx?: InputMaybe<DetailBlockCreateManyInlineInput>;
  clbl4hopd071i01tc5qfq5nx3?: InputMaybe<DetailBlockCreateManyInlineInput>;
  clbvfsqnl3ufg01t9b9dt0epw?: InputMaybe<BlockCreateManyInlineInput>;
  clbvftry93pc801us7scdf4ld?: InputMaybe<BlockCreateManyInlineInput>;
  clbvfue4m3qfw01ukcka9a5oc?: InputMaybe<BlockCreateManyInlineInput>;
  cldz2nm6g216s01t2fob01qcu?: InputMaybe<PromoTicketTextCreateManyInlineInput>;
  cle32q3a6097i01uhcf0i206s?: InputMaybe<PromoTicketTextCreateManyInlineInput>;
  cle33lzb70b0101t20k38fhyn?: InputMaybe<PromoTicketBannerCreateManyInlineInput>;
  cle33mvft0b8m01tbh2t1bu7i?: InputMaybe<PromoTicketBannerCreateManyInlineInput>;
  cle33pvdm0aco01ufhqubdiva?: InputMaybe<PromoTicketBannerCreateManyInlineInput>;
  cle33qsed0atr01umfjz94paj?: InputMaybe<PromoTicketBannerCreateManyInlineInput>;
  cle33rr9d0avl01ur1n3g2114?: InputMaybe<PromoTicketBannerCreateManyInlineInput>;
  cle33sqm90bfy01tbbdum49tk?: InputMaybe<PromoTicketBannerCreateManyInlineInput>;
  cle38d3160h1i01t2a99whhvh?: InputMaybe<CrossPromoBannerCreateManyInlineInput>;
  cle38duu60h2601t2787v66cc?: InputMaybe<CrossPromoBannerCreateManyInlineInput>;
  cle38f5860fzy01urbrcr0bh5?: InputMaybe<CrossPromoBannerCreateManyInlineInput>;
  cle38fu350gwq01uh9p1nfb0y?: InputMaybe<CrossPromoBannerCreateManyInlineInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  key: Scalars['String'];
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<TextCreateLocalizationsInput>;
  productAdmissionTitles?: InputMaybe<ProductCreateManyInlineInput>;
  productAttractionLineups?: InputMaybe<ProductAttractionLineupCreateManyInlineInput>;
  productAttractionLineupsSubtitle?: InputMaybe<ProductAttractionLineupCreateManyInlineInput>;
  productAttractionLineupsTitle?: InputMaybe<ProductAttractionLineupCreateManyInlineInput>;
  productHeadlines?: InputMaybe<ProductCreateManyInlineInput>;
  productReservations?: InputMaybe<ProductReservationCreateManyInlineInput>;
  products?: InputMaybe<ProductCreateManyInlineInput>;
  productsCityName?: InputMaybe<ProductCreateManyInlineInput>;
  productsDescription?: InputMaybe<ProductCreateManyInlineInput>;
  productsTagline?: InputMaybe<ProductCreateManyInlineInput>;
  qabu?: InputMaybe<Scalars['Json']>;
  tags?: InputMaybe<Array<Scalars['String']>>;
  textGroups?: InputMaybe<TextGroupCreateManyInlineInput>;
  title?: InputMaybe<ProductActivityCreateManyInlineInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** value input for default locale (en) */
  value?: InputMaybe<Scalars['String']>;
};

export type TextCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  value?: InputMaybe<Scalars['String']>;
};

export type TextCreateLocalizationInput = {
  /** Localization input */
  data: TextCreateLocalizationDataInput;
  locale: Locale;
};

export type TextCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<TextCreateLocalizationInput>>;
};

export type TextCreateManyInlineInput = {
  /** Connect multiple existing Text documents */
  connect?: InputMaybe<Array<TextWhereUniqueInput>>;
  /** Create and connect multiple existing Text documents */
  create?: InputMaybe<Array<TextCreateInput>>;
};

export type TextCreateOneInlineInput = {
  /** Connect one existing Text document */
  connect?: InputMaybe<TextWhereUniqueInput>;
  /** Create and connect one Text document */
  create?: InputMaybe<TextCreateInput>;
};

/** An edge in a connection. */
export type TextEdge = {
  __typename?: 'TextEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Text;
};

export type TextGroup = Node & {
  __typename?: 'TextGroup';
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** User that created this document */
  createdBy: Maybe<User>;
  /** Get the document in other stages */
  documentInStages: Array<TextGroup>;
  /** List of TextGroup versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID'];
  key: Maybe<Scalars['String']>;
  pageTemplates: Array<PageTemplate>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt: Maybe<Scalars['DateTime']>;
  /** User that last published this document */
  publishedBy: Maybe<User>;
  scheduledIn: Array<ScheduledOperation>;
  /** System stage field */
  stage: Stage;
  text: Array<TextGroupText>;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** User that last updated this document */
  updatedBy: Maybe<User>;
};

export type TextGroupCreatedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type TextGroupDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};

export type TextGroupHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride: InputMaybe<Stage>;
};

export type TextGroupPageTemplatesArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<PageTemplateOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<PageTemplateWhereInput>;
};

export type TextGroupPublishedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type TextGroupScheduledInArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ScheduledOperationWhereInput>;
};

export type TextGroupTextArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  skip: InputMaybe<Scalars['Int']>;
};

export type TextGroupUpdatedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type TextGroupConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: TextGroupWhereUniqueInput;
};

/** A connection to a list of items. */
export type TextGroupConnection = {
  __typename?: 'TextGroupConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<TextGroupEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type TextGroupCreateInput = {
  ckyndyjyg0mr701xu3t702zpm?: InputMaybe<PageCreateManyInlineInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  key?: InputMaybe<Scalars['String']>;
  pageTemplates?: InputMaybe<PageTemplateCreateManyInlineInput>;
  text?: InputMaybe<TextGroupTextCreateManyInlineInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type TextGroupCreateManyInlineInput = {
  /** Connect multiple existing TextGroup documents */
  connect?: InputMaybe<Array<TextGroupWhereUniqueInput>>;
  /** Create and connect multiple existing TextGroup documents */
  create?: InputMaybe<Array<TextGroupCreateInput>>;
};

export type TextGroupCreateOneInlineInput = {
  /** Connect one existing TextGroup document */
  connect?: InputMaybe<TextGroupWhereUniqueInput>;
  /** Create and connect one TextGroup document */
  create?: InputMaybe<TextGroupCreateInput>;
};

/** An edge in a connection. */
export type TextGroupEdge = {
  __typename?: 'TextGroupEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: TextGroup;
};

/** Identifies documents */
export type TextGroupManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<TextGroupWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<TextGroupWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<TextGroupWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<TextGroupWhereStageInput>;
  documentInStages_none?: InputMaybe<TextGroupWhereStageInput>;
  documentInStages_some?: InputMaybe<TextGroupWhereStageInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  key?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  key_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  key_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  key_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  key_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  key_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  key_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  key_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  key_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  key_starts_with?: InputMaybe<Scalars['String']>;
  pageTemplates_every?: InputMaybe<PageTemplateWhereInput>;
  pageTemplates_none?: InputMaybe<PageTemplateWhereInput>;
  pageTemplates_some?: InputMaybe<PageTemplateWhereInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  /** All values in which the union is empty */
  text_empty?: InputMaybe<Scalars['Boolean']>;
  /** Matches if the union contains at least one connection to the provided item to the filter */
  text_some?: InputMaybe<TextGroupTextWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum TextGroupOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type TextGroupText = Text | TextList;

export type TextGroupTextConnectInput = {
  Text?: InputMaybe<TextConnectInput>;
  TextList?: InputMaybe<TextListConnectInput>;
};

export type TextGroupTextCreateInput = {
  Text?: InputMaybe<TextCreateInput>;
  TextList?: InputMaybe<TextListCreateInput>;
};

export type TextGroupTextCreateManyInlineInput = {
  /** Connect multiple existing TextGroupText documents */
  connect?: InputMaybe<Array<TextGroupTextWhereUniqueInput>>;
  /** Create and connect multiple existing TextGroupText documents */
  create?: InputMaybe<Array<TextGroupTextCreateInput>>;
};

export type TextGroupTextCreateOneInlineInput = {
  /** Connect one existing TextGroupText document */
  connect?: InputMaybe<TextGroupTextWhereUniqueInput>;
  /** Create and connect one TextGroupText document */
  create?: InputMaybe<TextGroupTextCreateInput>;
};

export type TextGroupTextUpdateInput = {
  Text?: InputMaybe<TextUpdateInput>;
  TextList?: InputMaybe<TextListUpdateInput>;
};

export type TextGroupTextUpdateManyInlineInput = {
  /** Connect multiple existing TextGroupText documents */
  connect?: InputMaybe<Array<TextGroupTextConnectInput>>;
  /** Create and connect multiple TextGroupText documents */
  create?: InputMaybe<Array<TextGroupTextCreateInput>>;
  /** Delete multiple TextGroupText documents */
  delete?: InputMaybe<Array<TextGroupTextWhereUniqueInput>>;
  /** Disconnect multiple TextGroupText documents */
  disconnect?: InputMaybe<Array<TextGroupTextWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing TextGroupText documents */
  set?: InputMaybe<Array<TextGroupTextWhereUniqueInput>>;
  /** Update multiple TextGroupText documents */
  update?: InputMaybe<Array<TextGroupTextUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple TextGroupText documents */
  upsert?: InputMaybe<Array<TextGroupTextUpsertWithNestedWhereUniqueInput>>;
};

export type TextGroupTextUpdateManyWithNestedWhereInput = {
  Text?: InputMaybe<TextUpdateManyWithNestedWhereInput>;
  TextList?: InputMaybe<TextListUpdateManyWithNestedWhereInput>;
};

export type TextGroupTextUpdateOneInlineInput = {
  /** Connect existing TextGroupText document */
  connect?: InputMaybe<TextGroupTextWhereUniqueInput>;
  /** Create and connect one TextGroupText document */
  create?: InputMaybe<TextGroupTextCreateInput>;
  /** Delete currently connected TextGroupText document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected TextGroupText document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single TextGroupText document */
  update?: InputMaybe<TextGroupTextUpdateWithNestedWhereUniqueInput>;
  /** Upsert single TextGroupText document */
  upsert?: InputMaybe<TextGroupTextUpsertWithNestedWhereUniqueInput>;
};

export type TextGroupTextUpdateWithNestedWhereUniqueInput = {
  Text?: InputMaybe<TextUpdateWithNestedWhereUniqueInput>;
  TextList?: InputMaybe<TextListUpdateWithNestedWhereUniqueInput>;
};

export type TextGroupTextUpsertWithNestedWhereUniqueInput = {
  Text?: InputMaybe<TextUpsertWithNestedWhereUniqueInput>;
  TextList?: InputMaybe<TextListUpsertWithNestedWhereUniqueInput>;
};

export type TextGroupTextWhereInput = {
  Text?: InputMaybe<TextWhereInput>;
  TextList?: InputMaybe<TextListWhereInput>;
};

export type TextGroupTextWhereUniqueInput = {
  Text?: InputMaybe<TextWhereUniqueInput>;
  TextList?: InputMaybe<TextListWhereUniqueInput>;
};

export type TextGroupUpdateInput = {
  ckyndyjyg0mr701xu3t702zpm?: InputMaybe<PageUpdateManyInlineInput>;
  key?: InputMaybe<Scalars['String']>;
  pageTemplates?: InputMaybe<PageTemplateUpdateManyInlineInput>;
  text?: InputMaybe<TextGroupTextUpdateManyInlineInput>;
};

export type TextGroupUpdateManyInlineInput = {
  /** Connect multiple existing TextGroup documents */
  connect?: InputMaybe<Array<TextGroupConnectInput>>;
  /** Create and connect multiple TextGroup documents */
  create?: InputMaybe<Array<TextGroupCreateInput>>;
  /** Delete multiple TextGroup documents */
  delete?: InputMaybe<Array<TextGroupWhereUniqueInput>>;
  /** Disconnect multiple TextGroup documents */
  disconnect?: InputMaybe<Array<TextGroupWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing TextGroup documents */
  set?: InputMaybe<Array<TextGroupWhereUniqueInput>>;
  /** Update multiple TextGroup documents */
  update?: InputMaybe<Array<TextGroupUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple TextGroup documents */
  upsert?: InputMaybe<Array<TextGroupUpsertWithNestedWhereUniqueInput>>;
};

export type TextGroupUpdateManyInput = {
  /** No fields in updateMany data input */
  _?: InputMaybe<Scalars['String']>;
};

export type TextGroupUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: TextGroupUpdateManyInput;
  /** Document search */
  where: TextGroupWhereInput;
};

export type TextGroupUpdateOneInlineInput = {
  /** Connect existing TextGroup document */
  connect?: InputMaybe<TextGroupWhereUniqueInput>;
  /** Create and connect one TextGroup document */
  create?: InputMaybe<TextGroupCreateInput>;
  /** Delete currently connected TextGroup document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected TextGroup document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single TextGroup document */
  update?: InputMaybe<TextGroupUpdateWithNestedWhereUniqueInput>;
  /** Upsert single TextGroup document */
  upsert?: InputMaybe<TextGroupUpsertWithNestedWhereUniqueInput>;
};

export type TextGroupUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: TextGroupUpdateInput;
  /** Unique document search */
  where: TextGroupWhereUniqueInput;
};

export type TextGroupUpsertInput = {
  /** Create document if it didn't exist */
  create: TextGroupCreateInput;
  /** Update document if it exists */
  update: TextGroupUpdateInput;
};

export type TextGroupUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: TextGroupUpsertInput;
  /** Unique document search */
  where: TextGroupWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type TextGroupWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']>;
};

/** Identifies documents */
export type TextGroupWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<TextGroupWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<TextGroupWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<TextGroupWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<TextGroupWhereStageInput>;
  documentInStages_none?: InputMaybe<TextGroupWhereStageInput>;
  documentInStages_some?: InputMaybe<TextGroupWhereStageInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  key?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  key_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  key_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  key_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  key_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  key_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  key_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  key_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  key_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  key_starts_with?: InputMaybe<Scalars['String']>;
  pageTemplates_every?: InputMaybe<PageTemplateWhereInput>;
  pageTemplates_none?: InputMaybe<PageTemplateWhereInput>;
  pageTemplates_some?: InputMaybe<PageTemplateWhereInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  /** All values in which the union is empty */
  text_empty?: InputMaybe<Scalars['Boolean']>;
  /** Matches if the union contains at least one connection to the provided item to the filter */
  text_some?: InputMaybe<TextGroupTextWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type TextGroupWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<TextGroupWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<TextGroupWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<TextGroupWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<TextGroupWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References TextGroup record uniquely */
export type TextGroupWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
  key?: InputMaybe<Scalars['String']>;
};

export type TextList = Node & {
  __typename?: 'TextList';
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** User that created this document */
  createdBy: Maybe<User>;
  /** Get the document in other stages */
  documentInStages: Array<TextList>;
  /** List of TextList versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID'];
  key: Scalars['String'];
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt: Maybe<Scalars['DateTime']>;
  /** User that last published this document */
  publishedBy: Maybe<User>;
  scheduledIn: Array<ScheduledOperation>;
  /** System stage field */
  stage: Stage;
  textGroups: Array<TextGroup>;
  texts: Array<Text>;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** User that last updated this document */
  updatedBy: Maybe<User>;
};

export type TextListCreatedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type TextListDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};

export type TextListHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride: InputMaybe<Stage>;
};

export type TextListPublishedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type TextListScheduledInArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ScheduledOperationWhereInput>;
};

export type TextListTextGroupsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<TextGroupWhereInput>;
};

export type TextListTextsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<TextOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<TextWhereInput>;
};

export type TextListUpdatedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type TextListConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: TextListWhereUniqueInput;
};

/** A connection to a list of items. */
export type TextListConnection = {
  __typename?: 'TextListConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<TextListEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type TextListCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  key: Scalars['String'];
  textGroups?: InputMaybe<TextGroupCreateManyInlineInput>;
  texts?: InputMaybe<TextCreateManyInlineInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type TextListCreateManyInlineInput = {
  /** Connect multiple existing TextList documents */
  connect?: InputMaybe<Array<TextListWhereUniqueInput>>;
  /** Create and connect multiple existing TextList documents */
  create?: InputMaybe<Array<TextListCreateInput>>;
};

export type TextListCreateOneInlineInput = {
  /** Connect one existing TextList document */
  connect?: InputMaybe<TextListWhereUniqueInput>;
  /** Create and connect one TextList document */
  create?: InputMaybe<TextListCreateInput>;
};

/** An edge in a connection. */
export type TextListEdge = {
  __typename?: 'TextListEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: TextList;
};

/** Identifies documents */
export type TextListManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<TextListWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<TextListWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<TextListWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<TextListWhereStageInput>;
  documentInStages_none?: InputMaybe<TextListWhereStageInput>;
  documentInStages_some?: InputMaybe<TextListWhereStageInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  key?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  key_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  key_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  key_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  key_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  key_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  key_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  key_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  key_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  key_starts_with?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  textGroups_every?: InputMaybe<TextGroupWhereInput>;
  textGroups_none?: InputMaybe<TextGroupWhereInput>;
  textGroups_some?: InputMaybe<TextGroupWhereInput>;
  texts_every?: InputMaybe<TextWhereInput>;
  texts_none?: InputMaybe<TextWhereInput>;
  texts_some?: InputMaybe<TextWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum TextListOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type TextListUpdateInput = {
  key?: InputMaybe<Scalars['String']>;
  textGroups?: InputMaybe<TextGroupUpdateManyInlineInput>;
  texts?: InputMaybe<TextUpdateManyInlineInput>;
};

export type TextListUpdateManyInlineInput = {
  /** Connect multiple existing TextList documents */
  connect?: InputMaybe<Array<TextListConnectInput>>;
  /** Create and connect multiple TextList documents */
  create?: InputMaybe<Array<TextListCreateInput>>;
  /** Delete multiple TextList documents */
  delete?: InputMaybe<Array<TextListWhereUniqueInput>>;
  /** Disconnect multiple TextList documents */
  disconnect?: InputMaybe<Array<TextListWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing TextList documents */
  set?: InputMaybe<Array<TextListWhereUniqueInput>>;
  /** Update multiple TextList documents */
  update?: InputMaybe<Array<TextListUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple TextList documents */
  upsert?: InputMaybe<Array<TextListUpsertWithNestedWhereUniqueInput>>;
};

export type TextListUpdateManyInput = {
  /** No fields in updateMany data input */
  _?: InputMaybe<Scalars['String']>;
};

export type TextListUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: TextListUpdateManyInput;
  /** Document search */
  where: TextListWhereInput;
};

export type TextListUpdateOneInlineInput = {
  /** Connect existing TextList document */
  connect?: InputMaybe<TextListWhereUniqueInput>;
  /** Create and connect one TextList document */
  create?: InputMaybe<TextListCreateInput>;
  /** Delete currently connected TextList document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected TextList document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single TextList document */
  update?: InputMaybe<TextListUpdateWithNestedWhereUniqueInput>;
  /** Upsert single TextList document */
  upsert?: InputMaybe<TextListUpsertWithNestedWhereUniqueInput>;
};

export type TextListUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: TextListUpdateInput;
  /** Unique document search */
  where: TextListWhereUniqueInput;
};

export type TextListUpsertInput = {
  /** Create document if it didn't exist */
  create: TextListCreateInput;
  /** Update document if it exists */
  update: TextListUpdateInput;
};

export type TextListUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: TextListUpsertInput;
  /** Unique document search */
  where: TextListWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type TextListWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']>;
};

/** Identifies documents */
export type TextListWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<TextListWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<TextListWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<TextListWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<TextListWhereStageInput>;
  documentInStages_none?: InputMaybe<TextListWhereStageInput>;
  documentInStages_some?: InputMaybe<TextListWhereStageInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  key?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  key_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  key_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  key_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  key_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  key_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  key_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  key_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  key_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  key_starts_with?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  textGroups_every?: InputMaybe<TextGroupWhereInput>;
  textGroups_none?: InputMaybe<TextGroupWhereInput>;
  textGroups_some?: InputMaybe<TextGroupWhereInput>;
  texts_every?: InputMaybe<TextWhereInput>;
  texts_none?: InputMaybe<TextWhereInput>;
  texts_some?: InputMaybe<TextWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type TextListWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<TextListWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<TextListWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<TextListWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<TextListWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References TextList record uniquely */
export type TextListWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
  key?: InputMaybe<Scalars['String']>;
};

/** Identifies documents */
export type TextManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<TextWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<TextWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<TextWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  alacarteTicketTypeDescription_every?: InputMaybe<AlacarteTicketTypeWhereInput>;
  alacarteTicketTypeDescription_none?: InputMaybe<AlacarteTicketTypeWhereInput>;
  alacarteTicketTypeDescription_some?: InputMaybe<AlacarteTicketTypeWhereInput>;
  alacarteTicketTypeInclusionsNote_every?: InputMaybe<AlacarteTicketTypeWhereInput>;
  alacarteTicketTypeInclusionsNote_none?: InputMaybe<AlacarteTicketTypeWhereInput>;
  alacarteTicketTypeInclusionsNote_some?: InputMaybe<AlacarteTicketTypeWhereInput>;
  alacarteTicketTypeInclusions_every?: InputMaybe<AlacarteTicketTypeWhereInput>;
  alacarteTicketTypeInclusions_none?: InputMaybe<AlacarteTicketTypeWhereInput>;
  alacarteTicketTypeInclusions_some?: InputMaybe<AlacarteTicketTypeWhereInput>;
  alacarteTicketTypeRestrictions_every?: InputMaybe<AlacarteTicketTypeWhereInput>;
  alacarteTicketTypeRestrictions_none?: InputMaybe<AlacarteTicketTypeWhereInput>;
  alacarteTicketTypeRestrictions_some?: InputMaybe<AlacarteTicketTypeWhereInput>;
  alacarteTicketTypeValidPostPurchaseForDateRange_every?: InputMaybe<AlacarteTicketTypeWhereInput>;
  alacarteTicketTypeValidPostPurchaseForDateRange_none?: InputMaybe<AlacarteTicketTypeWhereInput>;
  alacarteTicketTypeValidPostPurchaseForDateRange_some?: InputMaybe<AlacarteTicketTypeWhereInput>;
  alacarteTicketTypeValidityPostPurchaseNote_every?: InputMaybe<AlacarteTicketTypeWhereInput>;
  alacarteTicketTypeValidityPostPurchaseNote_none?: InputMaybe<AlacarteTicketTypeWhereInput>;
  alacarteTicketTypeValidityPostPurchaseNote_some?: InputMaybe<AlacarteTicketTypeWhereInput>;
  alacarteTicketTypeValidityPostPurchase_every?: InputMaybe<AlacarteTicketTypeWhereInput>;
  alacarteTicketTypeValidityPostPurchase_none?: InputMaybe<AlacarteTicketTypeWhereInput>;
  alacarteTicketTypeValidityPostPurchase_some?: InputMaybe<AlacarteTicketTypeWhereInput>;
  alacarteTicketTypeValidityShort_every?: InputMaybe<AlacarteTicketTypeWhereInput>;
  alacarteTicketTypeValidityShort_none?: InputMaybe<AlacarteTicketTypeWhereInput>;
  alacarteTicketTypeValidityShort_some?: InputMaybe<AlacarteTicketTypeWhereInput>;
  alacarteTicketTypeValidityWillCall_every?: InputMaybe<AlacarteTicketTypeWhereInput>;
  alacarteTicketTypeValidityWillCall_none?: InputMaybe<AlacarteTicketTypeWhereInput>;
  alacarteTicketTypeValidityWillCall_some?: InputMaybe<AlacarteTicketTypeWhereInput>;
  alacarteTicketTypeValidity_every?: InputMaybe<AlacarteTicketTypeWhereInput>;
  alacarteTicketTypeValidity_none?: InputMaybe<AlacarteTicketTypeWhereInput>;
  alacarteTicketTypeValidity_some?: InputMaybe<AlacarteTicketTypeWhereInput>;
  attractionAdmission_every?: InputMaybe<AttractionWhereInput>;
  attractionAdmission_none?: InputMaybe<AttractionWhereInput>;
  attractionAdmission_some?: InputMaybe<AttractionWhereInput>;
  attractionAlacarteDescription_every?: InputMaybe<AttractionAlacarteWhereInput>;
  attractionAlacarteDescription_none?: InputMaybe<AttractionAlacarteWhereInput>;
  attractionAlacarteDescription_some?: InputMaybe<AttractionAlacarteWhereInput>;
  attractionAlacarteRedemptionShort_every?: InputMaybe<AttractionAlacarteWhereInput>;
  attractionAlacarteRedemptionShort_none?: InputMaybe<AttractionAlacarteWhereInput>;
  attractionAlacarteRedemptionShort_some?: InputMaybe<AttractionAlacarteWhereInput>;
  attractionAlacarteRedemption_every?: InputMaybe<AttractionAlacarteWhereInput>;
  attractionAlacarteRedemption_none?: InputMaybe<AttractionAlacarteWhereInput>;
  attractionAlacarteRedemption_some?: InputMaybe<AttractionAlacarteWhereInput>;
  attractionAlacarteRestrictionsCommon_every?: InputMaybe<AttractionAlacarteWhereInput>;
  attractionAlacarteRestrictionsCommon_none?: InputMaybe<AttractionAlacarteWhereInput>;
  attractionAlacarteRestrictionsCommon_some?: InputMaybe<AttractionAlacarteWhereInput>;
  attractionAlacarteTermsAndConditions_every?: InputMaybe<AttractionAlacarteWhereInput>;
  attractionAlacarteTermsAndConditions_none?: InputMaybe<AttractionAlacarteWhereInput>;
  attractionAlacarteTermsAndConditions_some?: InputMaybe<AttractionAlacarteWhereInput>;
  attractionAlacarteValidityCommon_every?: InputMaybe<AttractionAlacarteWhereInput>;
  attractionAlacarteValidityCommon_none?: InputMaybe<AttractionAlacarteWhereInput>;
  attractionAlacarteValidityCommon_some?: InputMaybe<AttractionAlacarteWhereInput>;
  attractionAlacarteValidityWillCall_every?: InputMaybe<AttractionAlacarteWhereInput>;
  attractionAlacarteValidityWillCall_none?: InputMaybe<AttractionAlacarteWhereInput>;
  attractionAlacarteValidityWillCall_some?: InputMaybe<AttractionAlacarteWhereInput>;
  attractionAmenities_every?: InputMaybe<AttractionWhereInput>;
  attractionAmenities_none?: InputMaybe<AttractionWhereInput>;
  attractionAmenities_some?: InputMaybe<AttractionWhereInput>;
  attractionHighlights_every?: InputMaybe<AttractionWhereInput>;
  attractionHighlights_none?: InputMaybe<AttractionWhereInput>;
  attractionHighlights_some?: InputMaybe<AttractionWhereInput>;
  attractionLocationHoursNotes_every?: InputMaybe<AttractionLocationWhereInput>;
  attractionLocationHoursNotes_none?: InputMaybe<AttractionLocationWhereInput>;
  attractionLocationHoursNotes_some?: InputMaybe<AttractionLocationWhereInput>;
  attractionOpenStatus_every?: InputMaybe<AttractionWhereInput>;
  attractionOpenStatus_none?: InputMaybe<AttractionWhereInput>;
  attractionOpenStatus_some?: InputMaybe<AttractionWhereInput>;
  attractionReservations_every?: InputMaybe<AttractionReservationWhereInput>;
  attractionReservations_none?: InputMaybe<AttractionReservationWhereInput>;
  attractionReservations_some?: InputMaybe<AttractionReservationWhereInput>;
  attractionSafety_every?: InputMaybe<AttractionWhereInput>;
  attractionSafety_none?: InputMaybe<AttractionWhereInput>;
  attractionSafety_some?: InputMaybe<AttractionWhereInput>;
  attractionTips_every?: InputMaybe<AttractionWhereInput>;
  attractionTips_none?: InputMaybe<AttractionWhereInput>;
  attractionTips_some?: InputMaybe<AttractionWhereInput>;
  attractionVariantAdmission_every?: InputMaybe<AttractionVariantWhereInput>;
  attractionVariantAdmission_none?: InputMaybe<AttractionVariantWhereInput>;
  attractionVariantAdmission_some?: InputMaybe<AttractionVariantWhereInput>;
  attractionVariantHighlights_every?: InputMaybe<AttractionVariantWhereInput>;
  attractionVariantHighlights_none?: InputMaybe<AttractionVariantWhereInput>;
  attractionVariantHighlights_some?: InputMaybe<AttractionVariantWhereInput>;
  attractionVariantOpenStatus_every?: InputMaybe<AttractionVariantWhereInput>;
  attractionVariantOpenStatus_none?: InputMaybe<AttractionVariantWhereInput>;
  attractionVariantOpenStatus_some?: InputMaybe<AttractionVariantWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<TextWhereStageInput>;
  documentInStages_none?: InputMaybe<TextWhereStageInput>;
  documentInStages_some?: InputMaybe<TextWhereStageInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  key?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  key_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  key_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  key_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  key_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  key_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  key_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  key_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  key_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  key_starts_with?: InputMaybe<Scalars['String']>;
  productAdmissionTitles_every?: InputMaybe<ProductWhereInput>;
  productAdmissionTitles_none?: InputMaybe<ProductWhereInput>;
  productAdmissionTitles_some?: InputMaybe<ProductWhereInput>;
  productAttractionLineupsSubtitle_every?: InputMaybe<ProductAttractionLineupWhereInput>;
  productAttractionLineupsSubtitle_none?: InputMaybe<ProductAttractionLineupWhereInput>;
  productAttractionLineupsSubtitle_some?: InputMaybe<ProductAttractionLineupWhereInput>;
  productAttractionLineupsTitle_every?: InputMaybe<ProductAttractionLineupWhereInput>;
  productAttractionLineupsTitle_none?: InputMaybe<ProductAttractionLineupWhereInput>;
  productAttractionLineupsTitle_some?: InputMaybe<ProductAttractionLineupWhereInput>;
  productAttractionLineups_every?: InputMaybe<ProductAttractionLineupWhereInput>;
  productAttractionLineups_none?: InputMaybe<ProductAttractionLineupWhereInput>;
  productAttractionLineups_some?: InputMaybe<ProductAttractionLineupWhereInput>;
  productHeadlines_every?: InputMaybe<ProductWhereInput>;
  productHeadlines_none?: InputMaybe<ProductWhereInput>;
  productHeadlines_some?: InputMaybe<ProductWhereInput>;
  productReservations_every?: InputMaybe<ProductReservationWhereInput>;
  productReservations_none?: InputMaybe<ProductReservationWhereInput>;
  productReservations_some?: InputMaybe<ProductReservationWhereInput>;
  productsCityName_every?: InputMaybe<ProductWhereInput>;
  productsCityName_none?: InputMaybe<ProductWhereInput>;
  productsCityName_some?: InputMaybe<ProductWhereInput>;
  productsDescription_every?: InputMaybe<ProductWhereInput>;
  productsDescription_none?: InputMaybe<ProductWhereInput>;
  productsDescription_some?: InputMaybe<ProductWhereInput>;
  productsTagline_every?: InputMaybe<ProductWhereInput>;
  productsTagline_none?: InputMaybe<ProductWhereInput>;
  productsTagline_some?: InputMaybe<ProductWhereInput>;
  products_every?: InputMaybe<ProductWhereInput>;
  products_none?: InputMaybe<ProductWhereInput>;
  products_some?: InputMaybe<ProductWhereInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  /** All values containing the given json path. */
  qabu_json_path_exists?: InputMaybe<Scalars['String']>;
  /**
   * Recursively tries to find the provided JSON scalar value inside the field.
   * It does use an exact match when comparing values.
   * If you pass `null` as value the filter will be ignored.
   * Note: This filter fails if you try to look for a non scalar JSON value!
   */
  qabu_value_recursive?: InputMaybe<Scalars['Json']>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  tags?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains *all* items provided to the filter */
  tags_contains_all?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  tags_contains_none?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains at least one item provided to the filter */
  tags_contains_some?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  tags_not?: InputMaybe<Array<Scalars['String']>>;
  textGroups_every?: InputMaybe<TextGroupWhereInput>;
  textGroups_none?: InputMaybe<TextGroupWhereInput>;
  textGroups_some?: InputMaybe<TextGroupWhereInput>;
  title_every?: InputMaybe<ProductActivityWhereInput>;
  title_none?: InputMaybe<ProductActivityWhereInput>;
  title_some?: InputMaybe<ProductActivityWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum TextOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  TagsAsc = 'tags_ASC',
  TagsDesc = 'tags_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  ValueAsc = 'value_ASC',
  ValueDesc = 'value_DESC',
}

export type TextUpdateInput = {
  alacarteTicketTypeDescription?: InputMaybe<AlacarteTicketTypeUpdateManyInlineInput>;
  alacarteTicketTypeInclusions?: InputMaybe<AlacarteTicketTypeUpdateManyInlineInput>;
  alacarteTicketTypeInclusionsNote?: InputMaybe<AlacarteTicketTypeUpdateManyInlineInput>;
  alacarteTicketTypeRestrictions?: InputMaybe<AlacarteTicketTypeUpdateManyInlineInput>;
  alacarteTicketTypeValidPostPurchaseForDateRange?: InputMaybe<AlacarteTicketTypeUpdateManyInlineInput>;
  alacarteTicketTypeValidity?: InputMaybe<AlacarteTicketTypeUpdateManyInlineInput>;
  alacarteTicketTypeValidityPostPurchase?: InputMaybe<AlacarteTicketTypeUpdateManyInlineInput>;
  alacarteTicketTypeValidityPostPurchaseNote?: InputMaybe<AlacarteTicketTypeUpdateManyInlineInput>;
  alacarteTicketTypeValidityShort?: InputMaybe<AlacarteTicketTypeUpdateManyInlineInput>;
  alacarteTicketTypeValidityWillCall?: InputMaybe<AlacarteTicketTypeUpdateManyInlineInput>;
  attractionAdmission?: InputMaybe<AttractionUpdateManyInlineInput>;
  attractionAlacarteDescription?: InputMaybe<AttractionAlacarteUpdateManyInlineInput>;
  attractionAlacarteRedemption?: InputMaybe<AttractionAlacarteUpdateManyInlineInput>;
  attractionAlacarteRedemptionShort?: InputMaybe<AttractionAlacarteUpdateManyInlineInput>;
  attractionAlacarteRestrictionsCommon?: InputMaybe<AttractionAlacarteUpdateManyInlineInput>;
  attractionAlacarteTermsAndConditions?: InputMaybe<AttractionAlacarteUpdateManyInlineInput>;
  attractionAlacarteValidityCommon?: InputMaybe<AttractionAlacarteUpdateManyInlineInput>;
  attractionAlacarteValidityWillCall?: InputMaybe<AttractionAlacarteUpdateManyInlineInput>;
  attractionAmenities?: InputMaybe<AttractionUpdateManyInlineInput>;
  attractionHighlights?: InputMaybe<AttractionUpdateManyInlineInput>;
  attractionLocationHoursNotes?: InputMaybe<AttractionLocationUpdateManyInlineInput>;
  attractionOpenStatus?: InputMaybe<AttractionUpdateManyInlineInput>;
  attractionReservations?: InputMaybe<AttractionReservationUpdateManyInlineInput>;
  attractionSafety?: InputMaybe<AttractionUpdateManyInlineInput>;
  attractionTips?: InputMaybe<AttractionUpdateManyInlineInput>;
  attractionVariantAdmission?: InputMaybe<AttractionVariantUpdateManyInlineInput>;
  attractionVariantHighlights?: InputMaybe<AttractionVariantUpdateManyInlineInput>;
  attractionVariantOpenStatus?: InputMaybe<AttractionVariantUpdateManyInlineInput>;
  ckwzcvr81249j01z4c6cf66g8?: InputMaybe<SandboxUpdateManyInlineInput>;
  ckwzcw6hr23s601z06njp2mf8?: InputMaybe<SandboxUpdateManyInlineInput>;
  ckyb2vp2r0xc001z1azrd6fyn?: InputMaybe<LinkUpdateManyInlineInput>;
  ckydtod2w0azx01xwbngodzlh?: InputMaybe<MenuUpdateManyInlineInput>;
  cl1y3z86e0xlj01xteyweawlm?: InputMaybe<TextListUpdateManyInlineInput>;
  cl2jgcyk10nab01xh6mz4h4lm?: InputMaybe<AttractionGroupUpdateManyInlineInput>;
  clbl4g5i306y701tc9cwe1eqx?: InputMaybe<DetailBlockUpdateManyInlineInput>;
  clbl4hopd071i01tc5qfq5nx3?: InputMaybe<DetailBlockUpdateManyInlineInput>;
  clbvfsqnl3ufg01t9b9dt0epw?: InputMaybe<BlockUpdateManyInlineInput>;
  clbvftry93pc801us7scdf4ld?: InputMaybe<BlockUpdateManyInlineInput>;
  clbvfue4m3qfw01ukcka9a5oc?: InputMaybe<BlockUpdateManyInlineInput>;
  cldz2nm6g216s01t2fob01qcu?: InputMaybe<PromoTicketTextUpdateManyInlineInput>;
  cle32q3a6097i01uhcf0i206s?: InputMaybe<PromoTicketTextUpdateManyInlineInput>;
  cle33lzb70b0101t20k38fhyn?: InputMaybe<PromoTicketBannerUpdateManyInlineInput>;
  cle33mvft0b8m01tbh2t1bu7i?: InputMaybe<PromoTicketBannerUpdateManyInlineInput>;
  cle33pvdm0aco01ufhqubdiva?: InputMaybe<PromoTicketBannerUpdateManyInlineInput>;
  cle33qsed0atr01umfjz94paj?: InputMaybe<PromoTicketBannerUpdateManyInlineInput>;
  cle33rr9d0avl01ur1n3g2114?: InputMaybe<PromoTicketBannerUpdateManyInlineInput>;
  cle33sqm90bfy01tbbdum49tk?: InputMaybe<PromoTicketBannerUpdateManyInlineInput>;
  cle38d3160h1i01t2a99whhvh?: InputMaybe<CrossPromoBannerUpdateManyInlineInput>;
  cle38duu60h2601t2787v66cc?: InputMaybe<CrossPromoBannerUpdateManyInlineInput>;
  cle38f5860fzy01urbrcr0bh5?: InputMaybe<CrossPromoBannerUpdateManyInlineInput>;
  cle38fu350gwq01uh9p1nfb0y?: InputMaybe<CrossPromoBannerUpdateManyInlineInput>;
  key?: InputMaybe<Scalars['String']>;
  /** Manage document localizations */
  localizations?: InputMaybe<TextUpdateLocalizationsInput>;
  productAdmissionTitles?: InputMaybe<ProductUpdateManyInlineInput>;
  productAttractionLineups?: InputMaybe<ProductAttractionLineupUpdateManyInlineInput>;
  productAttractionLineupsSubtitle?: InputMaybe<ProductAttractionLineupUpdateManyInlineInput>;
  productAttractionLineupsTitle?: InputMaybe<ProductAttractionLineupUpdateManyInlineInput>;
  productHeadlines?: InputMaybe<ProductUpdateManyInlineInput>;
  productReservations?: InputMaybe<ProductReservationUpdateManyInlineInput>;
  products?: InputMaybe<ProductUpdateManyInlineInput>;
  productsCityName?: InputMaybe<ProductUpdateManyInlineInput>;
  productsDescription?: InputMaybe<ProductUpdateManyInlineInput>;
  productsTagline?: InputMaybe<ProductUpdateManyInlineInput>;
  qabu?: InputMaybe<Scalars['Json']>;
  tags?: InputMaybe<Array<Scalars['String']>>;
  textGroups?: InputMaybe<TextGroupUpdateManyInlineInput>;
  title?: InputMaybe<ProductActivityUpdateManyInlineInput>;
  /** value input for default locale (en) */
  value?: InputMaybe<Scalars['String']>;
};

export type TextUpdateLocalizationDataInput = {
  value?: InputMaybe<Scalars['String']>;
};

export type TextUpdateLocalizationInput = {
  data: TextUpdateLocalizationDataInput;
  locale: Locale;
};

export type TextUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<TextCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<TextUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<TextUpsertLocalizationInput>>;
};

export type TextUpdateManyInlineInput = {
  /** Connect multiple existing Text documents */
  connect?: InputMaybe<Array<TextConnectInput>>;
  /** Create and connect multiple Text documents */
  create?: InputMaybe<Array<TextCreateInput>>;
  /** Delete multiple Text documents */
  delete?: InputMaybe<Array<TextWhereUniqueInput>>;
  /** Disconnect multiple Text documents */
  disconnect?: InputMaybe<Array<TextWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing Text documents */
  set?: InputMaybe<Array<TextWhereUniqueInput>>;
  /** Update multiple Text documents */
  update?: InputMaybe<Array<TextUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple Text documents */
  upsert?: InputMaybe<Array<TextUpsertWithNestedWhereUniqueInput>>;
};

export type TextUpdateManyInput = {
  key?: InputMaybe<Scalars['String']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<TextUpdateManyLocalizationsInput>;
  qabu?: InputMaybe<Scalars['Json']>;
  tags?: InputMaybe<Array<Scalars['String']>>;
  /** value input for default locale (en) */
  value?: InputMaybe<Scalars['String']>;
};

export type TextUpdateManyLocalizationDataInput = {
  value?: InputMaybe<Scalars['String']>;
};

export type TextUpdateManyLocalizationInput = {
  data: TextUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type TextUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<TextUpdateManyLocalizationInput>>;
};

export type TextUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: TextUpdateManyInput;
  /** Document search */
  where: TextWhereInput;
};

export type TextUpdateOneInlineInput = {
  /** Connect existing Text document */
  connect?: InputMaybe<TextWhereUniqueInput>;
  /** Create and connect one Text document */
  create?: InputMaybe<TextCreateInput>;
  /** Delete currently connected Text document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected Text document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single Text document */
  update?: InputMaybe<TextUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Text document */
  upsert?: InputMaybe<TextUpsertWithNestedWhereUniqueInput>;
};

export type TextUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: TextUpdateInput;
  /** Unique document search */
  where: TextWhereUniqueInput;
};

export type TextUpsertInput = {
  /** Create document if it didn't exist */
  create: TextCreateInput;
  /** Update document if it exists */
  update: TextUpdateInput;
};

export type TextUpsertLocalizationInput = {
  create: TextCreateLocalizationDataInput;
  locale: Locale;
  update: TextUpdateLocalizationDataInput;
};

export type TextUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: TextUpsertInput;
  /** Unique document search */
  where: TextWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type TextWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']>;
};

/** Identifies documents */
export type TextWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<TextWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<TextWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<TextWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  alacarteTicketTypeDescription_every?: InputMaybe<AlacarteTicketTypeWhereInput>;
  alacarteTicketTypeDescription_none?: InputMaybe<AlacarteTicketTypeWhereInput>;
  alacarteTicketTypeDescription_some?: InputMaybe<AlacarteTicketTypeWhereInput>;
  alacarteTicketTypeInclusionsNote_every?: InputMaybe<AlacarteTicketTypeWhereInput>;
  alacarteTicketTypeInclusionsNote_none?: InputMaybe<AlacarteTicketTypeWhereInput>;
  alacarteTicketTypeInclusionsNote_some?: InputMaybe<AlacarteTicketTypeWhereInput>;
  alacarteTicketTypeInclusions_every?: InputMaybe<AlacarteTicketTypeWhereInput>;
  alacarteTicketTypeInclusions_none?: InputMaybe<AlacarteTicketTypeWhereInput>;
  alacarteTicketTypeInclusions_some?: InputMaybe<AlacarteTicketTypeWhereInput>;
  alacarteTicketTypeRestrictions_every?: InputMaybe<AlacarteTicketTypeWhereInput>;
  alacarteTicketTypeRestrictions_none?: InputMaybe<AlacarteTicketTypeWhereInput>;
  alacarteTicketTypeRestrictions_some?: InputMaybe<AlacarteTicketTypeWhereInput>;
  alacarteTicketTypeValidPostPurchaseForDateRange_every?: InputMaybe<AlacarteTicketTypeWhereInput>;
  alacarteTicketTypeValidPostPurchaseForDateRange_none?: InputMaybe<AlacarteTicketTypeWhereInput>;
  alacarteTicketTypeValidPostPurchaseForDateRange_some?: InputMaybe<AlacarteTicketTypeWhereInput>;
  alacarteTicketTypeValidityPostPurchaseNote_every?: InputMaybe<AlacarteTicketTypeWhereInput>;
  alacarteTicketTypeValidityPostPurchaseNote_none?: InputMaybe<AlacarteTicketTypeWhereInput>;
  alacarteTicketTypeValidityPostPurchaseNote_some?: InputMaybe<AlacarteTicketTypeWhereInput>;
  alacarteTicketTypeValidityPostPurchase_every?: InputMaybe<AlacarteTicketTypeWhereInput>;
  alacarteTicketTypeValidityPostPurchase_none?: InputMaybe<AlacarteTicketTypeWhereInput>;
  alacarteTicketTypeValidityPostPurchase_some?: InputMaybe<AlacarteTicketTypeWhereInput>;
  alacarteTicketTypeValidityShort_every?: InputMaybe<AlacarteTicketTypeWhereInput>;
  alacarteTicketTypeValidityShort_none?: InputMaybe<AlacarteTicketTypeWhereInput>;
  alacarteTicketTypeValidityShort_some?: InputMaybe<AlacarteTicketTypeWhereInput>;
  alacarteTicketTypeValidityWillCall_every?: InputMaybe<AlacarteTicketTypeWhereInput>;
  alacarteTicketTypeValidityWillCall_none?: InputMaybe<AlacarteTicketTypeWhereInput>;
  alacarteTicketTypeValidityWillCall_some?: InputMaybe<AlacarteTicketTypeWhereInput>;
  alacarteTicketTypeValidity_every?: InputMaybe<AlacarteTicketTypeWhereInput>;
  alacarteTicketTypeValidity_none?: InputMaybe<AlacarteTicketTypeWhereInput>;
  alacarteTicketTypeValidity_some?: InputMaybe<AlacarteTicketTypeWhereInput>;
  attractionAdmission_every?: InputMaybe<AttractionWhereInput>;
  attractionAdmission_none?: InputMaybe<AttractionWhereInput>;
  attractionAdmission_some?: InputMaybe<AttractionWhereInput>;
  attractionAlacarteDescription_every?: InputMaybe<AttractionAlacarteWhereInput>;
  attractionAlacarteDescription_none?: InputMaybe<AttractionAlacarteWhereInput>;
  attractionAlacarteDescription_some?: InputMaybe<AttractionAlacarteWhereInput>;
  attractionAlacarteRedemptionShort_every?: InputMaybe<AttractionAlacarteWhereInput>;
  attractionAlacarteRedemptionShort_none?: InputMaybe<AttractionAlacarteWhereInput>;
  attractionAlacarteRedemptionShort_some?: InputMaybe<AttractionAlacarteWhereInput>;
  attractionAlacarteRedemption_every?: InputMaybe<AttractionAlacarteWhereInput>;
  attractionAlacarteRedemption_none?: InputMaybe<AttractionAlacarteWhereInput>;
  attractionAlacarteRedemption_some?: InputMaybe<AttractionAlacarteWhereInput>;
  attractionAlacarteRestrictionsCommon_every?: InputMaybe<AttractionAlacarteWhereInput>;
  attractionAlacarteRestrictionsCommon_none?: InputMaybe<AttractionAlacarteWhereInput>;
  attractionAlacarteRestrictionsCommon_some?: InputMaybe<AttractionAlacarteWhereInput>;
  attractionAlacarteTermsAndConditions_every?: InputMaybe<AttractionAlacarteWhereInput>;
  attractionAlacarteTermsAndConditions_none?: InputMaybe<AttractionAlacarteWhereInput>;
  attractionAlacarteTermsAndConditions_some?: InputMaybe<AttractionAlacarteWhereInput>;
  attractionAlacarteValidityCommon_every?: InputMaybe<AttractionAlacarteWhereInput>;
  attractionAlacarteValidityCommon_none?: InputMaybe<AttractionAlacarteWhereInput>;
  attractionAlacarteValidityCommon_some?: InputMaybe<AttractionAlacarteWhereInput>;
  attractionAlacarteValidityWillCall_every?: InputMaybe<AttractionAlacarteWhereInput>;
  attractionAlacarteValidityWillCall_none?: InputMaybe<AttractionAlacarteWhereInput>;
  attractionAlacarteValidityWillCall_some?: InputMaybe<AttractionAlacarteWhereInput>;
  attractionAmenities_every?: InputMaybe<AttractionWhereInput>;
  attractionAmenities_none?: InputMaybe<AttractionWhereInput>;
  attractionAmenities_some?: InputMaybe<AttractionWhereInput>;
  attractionHighlights_every?: InputMaybe<AttractionWhereInput>;
  attractionHighlights_none?: InputMaybe<AttractionWhereInput>;
  attractionHighlights_some?: InputMaybe<AttractionWhereInput>;
  attractionLocationHoursNotes_every?: InputMaybe<AttractionLocationWhereInput>;
  attractionLocationHoursNotes_none?: InputMaybe<AttractionLocationWhereInput>;
  attractionLocationHoursNotes_some?: InputMaybe<AttractionLocationWhereInput>;
  attractionOpenStatus_every?: InputMaybe<AttractionWhereInput>;
  attractionOpenStatus_none?: InputMaybe<AttractionWhereInput>;
  attractionOpenStatus_some?: InputMaybe<AttractionWhereInput>;
  attractionReservations_every?: InputMaybe<AttractionReservationWhereInput>;
  attractionReservations_none?: InputMaybe<AttractionReservationWhereInput>;
  attractionReservations_some?: InputMaybe<AttractionReservationWhereInput>;
  attractionSafety_every?: InputMaybe<AttractionWhereInput>;
  attractionSafety_none?: InputMaybe<AttractionWhereInput>;
  attractionSafety_some?: InputMaybe<AttractionWhereInput>;
  attractionTips_every?: InputMaybe<AttractionWhereInput>;
  attractionTips_none?: InputMaybe<AttractionWhereInput>;
  attractionTips_some?: InputMaybe<AttractionWhereInput>;
  attractionVariantAdmission_every?: InputMaybe<AttractionVariantWhereInput>;
  attractionVariantAdmission_none?: InputMaybe<AttractionVariantWhereInput>;
  attractionVariantAdmission_some?: InputMaybe<AttractionVariantWhereInput>;
  attractionVariantHighlights_every?: InputMaybe<AttractionVariantWhereInput>;
  attractionVariantHighlights_none?: InputMaybe<AttractionVariantWhereInput>;
  attractionVariantHighlights_some?: InputMaybe<AttractionVariantWhereInput>;
  attractionVariantOpenStatus_every?: InputMaybe<AttractionVariantWhereInput>;
  attractionVariantOpenStatus_none?: InputMaybe<AttractionVariantWhereInput>;
  attractionVariantOpenStatus_some?: InputMaybe<AttractionVariantWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<TextWhereStageInput>;
  documentInStages_none?: InputMaybe<TextWhereStageInput>;
  documentInStages_some?: InputMaybe<TextWhereStageInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  key?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  key_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  key_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  key_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  key_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  key_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  key_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  key_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  key_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  key_starts_with?: InputMaybe<Scalars['String']>;
  productAdmissionTitles_every?: InputMaybe<ProductWhereInput>;
  productAdmissionTitles_none?: InputMaybe<ProductWhereInput>;
  productAdmissionTitles_some?: InputMaybe<ProductWhereInput>;
  productAttractionLineupsSubtitle_every?: InputMaybe<ProductAttractionLineupWhereInput>;
  productAttractionLineupsSubtitle_none?: InputMaybe<ProductAttractionLineupWhereInput>;
  productAttractionLineupsSubtitle_some?: InputMaybe<ProductAttractionLineupWhereInput>;
  productAttractionLineupsTitle_every?: InputMaybe<ProductAttractionLineupWhereInput>;
  productAttractionLineupsTitle_none?: InputMaybe<ProductAttractionLineupWhereInput>;
  productAttractionLineupsTitle_some?: InputMaybe<ProductAttractionLineupWhereInput>;
  productAttractionLineups_every?: InputMaybe<ProductAttractionLineupWhereInput>;
  productAttractionLineups_none?: InputMaybe<ProductAttractionLineupWhereInput>;
  productAttractionLineups_some?: InputMaybe<ProductAttractionLineupWhereInput>;
  productHeadlines_every?: InputMaybe<ProductWhereInput>;
  productHeadlines_none?: InputMaybe<ProductWhereInput>;
  productHeadlines_some?: InputMaybe<ProductWhereInput>;
  productReservations_every?: InputMaybe<ProductReservationWhereInput>;
  productReservations_none?: InputMaybe<ProductReservationWhereInput>;
  productReservations_some?: InputMaybe<ProductReservationWhereInput>;
  productsCityName_every?: InputMaybe<ProductWhereInput>;
  productsCityName_none?: InputMaybe<ProductWhereInput>;
  productsCityName_some?: InputMaybe<ProductWhereInput>;
  productsDescription_every?: InputMaybe<ProductWhereInput>;
  productsDescription_none?: InputMaybe<ProductWhereInput>;
  productsDescription_some?: InputMaybe<ProductWhereInput>;
  productsTagline_every?: InputMaybe<ProductWhereInput>;
  productsTagline_none?: InputMaybe<ProductWhereInput>;
  productsTagline_some?: InputMaybe<ProductWhereInput>;
  products_every?: InputMaybe<ProductWhereInput>;
  products_none?: InputMaybe<ProductWhereInput>;
  products_some?: InputMaybe<ProductWhereInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  /** All values containing the given json path. */
  qabu_json_path_exists?: InputMaybe<Scalars['String']>;
  /**
   * Recursively tries to find the provided JSON scalar value inside the field.
   * It does use an exact match when comparing values.
   * If you pass `null` as value the filter will be ignored.
   * Note: This filter fails if you try to look for a non scalar JSON value!
   */
  qabu_value_recursive?: InputMaybe<Scalars['Json']>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  tags?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains *all* items provided to the filter */
  tags_contains_all?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  tags_contains_none?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains at least one item provided to the filter */
  tags_contains_some?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  tags_not?: InputMaybe<Array<Scalars['String']>>;
  textGroups_every?: InputMaybe<TextGroupWhereInput>;
  textGroups_none?: InputMaybe<TextGroupWhereInput>;
  textGroups_some?: InputMaybe<TextGroupWhereInput>;
  title_every?: InputMaybe<ProductActivityWhereInput>;
  title_none?: InputMaybe<ProductActivityWhereInput>;
  title_some?: InputMaybe<ProductActivityWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
  value?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  value_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  value_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  value_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  value_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  value_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  value_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  value_starts_with?: InputMaybe<Scalars['String']>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type TextWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<TextWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<TextWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<TextWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<TextWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References Text record uniquely */
export type TextWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type TicketGroup = Node & {
  __typename?: 'TicketGroup';
  attractionLineup: Maybe<ProductAttractionLineup>;
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** User that created this document */
  createdBy: Maybe<User>;
  /** Get the document in other stages */
  documentInStages: Array<TicketGroup>;
  /** List of TicketGroup versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID'];
  key: Maybe<Scalars['String']>;
  logo: Maybe<Asset>;
  product: Maybe<Product>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt: Maybe<Scalars['DateTime']>;
  /** User that last published this document */
  publishedBy: Maybe<User>;
  scheduledIn: Array<ScheduledOperation>;
  skus: Array<Scalars['String']>;
  /** System stage field */
  stage: Stage;
  subtitle: Maybe<Scalars['String']>;
  title: Scalars['String'];
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** User that last updated this document */
  updatedBy: Maybe<User>;
};

export type TicketGroupAttractionLineupArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type TicketGroupCreatedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type TicketGroupDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};

export type TicketGroupHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride: InputMaybe<Stage>;
};

export type TicketGroupLogoArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type TicketGroupProductArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type TicketGroupPublishedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type TicketGroupScheduledInArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ScheduledOperationWhereInput>;
};

export type TicketGroupUpdatedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type TicketGroupConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: TicketGroupWhereUniqueInput;
};

/** A connection to a list of items. */
export type TicketGroupConnection = {
  __typename?: 'TicketGroupConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<TicketGroupEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type TicketGroupCreateInput = {
  attractionLineup?: InputMaybe<ProductAttractionLineupCreateOneInlineInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  key?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<AssetCreateOneInlineInput>;
  product?: InputMaybe<ProductCreateOneInlineInput>;
  skus?: InputMaybe<Array<Scalars['String']>>;
  subtitle?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type TicketGroupCreateManyInlineInput = {
  /** Connect multiple existing TicketGroup documents */
  connect?: InputMaybe<Array<TicketGroupWhereUniqueInput>>;
  /** Create and connect multiple existing TicketGroup documents */
  create?: InputMaybe<Array<TicketGroupCreateInput>>;
};

export type TicketGroupCreateOneInlineInput = {
  /** Connect one existing TicketGroup document */
  connect?: InputMaybe<TicketGroupWhereUniqueInput>;
  /** Create and connect one TicketGroup document */
  create?: InputMaybe<TicketGroupCreateInput>;
};

/** An edge in a connection. */
export type TicketGroupEdge = {
  __typename?: 'TicketGroupEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: TicketGroup;
};

/** Identifies documents */
export type TicketGroupManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<TicketGroupWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<TicketGroupWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<TicketGroupWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  attractionLineup?: InputMaybe<ProductAttractionLineupWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<TicketGroupWhereStageInput>;
  documentInStages_none?: InputMaybe<TicketGroupWhereStageInput>;
  documentInStages_some?: InputMaybe<TicketGroupWhereStageInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  key?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  key_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  key_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  key_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  key_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  key_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  key_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  key_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  key_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  key_starts_with?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<AssetWhereInput>;
  product?: InputMaybe<ProductWhereInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  skus?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains *all* items provided to the filter */
  skus_contains_all?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  skus_contains_none?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains at least one item provided to the filter */
  skus_contains_some?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  skus_not?: InputMaybe<Array<Scalars['String']>>;
  subtitle?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  subtitle_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  subtitle_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  subtitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  subtitle_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  subtitle_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  subtitle_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  subtitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  subtitle_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  subtitle_starts_with?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum TicketGroupOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  SkusAsc = 'skus_ASC',
  SkusDesc = 'skus_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type TicketGroupUpdateInput = {
  attractionLineup?: InputMaybe<ProductAttractionLineupUpdateOneInlineInput>;
  key?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<AssetUpdateOneInlineInput>;
  product?: InputMaybe<ProductUpdateOneInlineInput>;
  skus?: InputMaybe<Array<Scalars['String']>>;
  subtitle?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type TicketGroupUpdateManyInlineInput = {
  /** Connect multiple existing TicketGroup documents */
  connect?: InputMaybe<Array<TicketGroupConnectInput>>;
  /** Create and connect multiple TicketGroup documents */
  create?: InputMaybe<Array<TicketGroupCreateInput>>;
  /** Delete multiple TicketGroup documents */
  delete?: InputMaybe<Array<TicketGroupWhereUniqueInput>>;
  /** Disconnect multiple TicketGroup documents */
  disconnect?: InputMaybe<Array<TicketGroupWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing TicketGroup documents */
  set?: InputMaybe<Array<TicketGroupWhereUniqueInput>>;
  /** Update multiple TicketGroup documents */
  update?: InputMaybe<Array<TicketGroupUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple TicketGroup documents */
  upsert?: InputMaybe<Array<TicketGroupUpsertWithNestedWhereUniqueInput>>;
};

export type TicketGroupUpdateManyInput = {
  skus?: InputMaybe<Array<Scalars['String']>>;
  subtitle?: InputMaybe<Scalars['String']>;
};

export type TicketGroupUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: TicketGroupUpdateManyInput;
  /** Document search */
  where: TicketGroupWhereInput;
};

export type TicketGroupUpdateOneInlineInput = {
  /** Connect existing TicketGroup document */
  connect?: InputMaybe<TicketGroupWhereUniqueInput>;
  /** Create and connect one TicketGroup document */
  create?: InputMaybe<TicketGroupCreateInput>;
  /** Delete currently connected TicketGroup document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected TicketGroup document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single TicketGroup document */
  update?: InputMaybe<TicketGroupUpdateWithNestedWhereUniqueInput>;
  /** Upsert single TicketGroup document */
  upsert?: InputMaybe<TicketGroupUpsertWithNestedWhereUniqueInput>;
};

export type TicketGroupUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: TicketGroupUpdateInput;
  /** Unique document search */
  where: TicketGroupWhereUniqueInput;
};

export type TicketGroupUpsertInput = {
  /** Create document if it didn't exist */
  create: TicketGroupCreateInput;
  /** Update document if it exists */
  update: TicketGroupUpdateInput;
};

export type TicketGroupUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: TicketGroupUpsertInput;
  /** Unique document search */
  where: TicketGroupWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type TicketGroupWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']>;
};

/** Identifies documents */
export type TicketGroupWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<TicketGroupWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<TicketGroupWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<TicketGroupWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  attractionLineup?: InputMaybe<ProductAttractionLineupWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<TicketGroupWhereStageInput>;
  documentInStages_none?: InputMaybe<TicketGroupWhereStageInput>;
  documentInStages_some?: InputMaybe<TicketGroupWhereStageInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  key?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  key_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  key_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  key_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  key_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  key_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  key_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  key_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  key_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  key_starts_with?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<AssetWhereInput>;
  product?: InputMaybe<ProductWhereInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  skus?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains *all* items provided to the filter */
  skus_contains_all?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  skus_contains_none?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array contains at least one item provided to the filter */
  skus_contains_some?: InputMaybe<Array<Scalars['String']>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  skus_not?: InputMaybe<Array<Scalars['String']>>;
  subtitle?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  subtitle_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  subtitle_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  subtitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  subtitle_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  subtitle_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  subtitle_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  subtitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  subtitle_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  subtitle_starts_with?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type TicketGroupWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<TicketGroupWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<TicketGroupWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<TicketGroupWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<TicketGroupWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References TicketGroup record uniquely */
export type TicketGroupWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
  key?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type TitleDescription = Node & {
  __typename?: 'TitleDescription';
  attractionAlacarteCustomInstructions: Maybe<AttractionAlacarte>;
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** User that created this document */
  createdBy: Maybe<User>;
  description: Maybe<Scalars['String']>;
  /** Get the document in other stages */
  documentInStages: Array<TitleDescription>;
  /** List of TitleDescription versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID'];
  key: Scalars['String'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<TitleDescription>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt: Maybe<Scalars['DateTime']>;
  /** User that last published this document */
  publishedBy: Maybe<User>;
  qabu: Maybe<Scalars['Json']>;
  scheduledIn: Array<ScheduledOperation>;
  /** System stage field */
  stage: Stage;
  title: Maybe<Scalars['String']>;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** User that last updated this document */
  updatedBy: Maybe<User>;
};

export type TitleDescriptionAttractionAlacarteCustomInstructionsArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type TitleDescriptionCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type TitleDescriptionCreatedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type TitleDescriptionDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};

export type TitleDescriptionHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride: InputMaybe<Stage>;
};

export type TitleDescriptionLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};

export type TitleDescriptionPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type TitleDescriptionPublishedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type TitleDescriptionScheduledInArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ScheduledOperationWhereInput>;
};

export type TitleDescriptionUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type TitleDescriptionUpdatedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type TitleDescriptionConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: TitleDescriptionWhereUniqueInput;
};

/** A connection to a list of items. */
export type TitleDescriptionConnection = {
  __typename?: 'TitleDescriptionConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<TitleDescriptionEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type TitleDescriptionCreateInput = {
  attractionAlacarteCustomInstructions?: InputMaybe<AttractionAlacarteCreateOneInlineInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** description input for default locale (en) */
  description?: InputMaybe<Scalars['String']>;
  key: Scalars['String'];
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<TitleDescriptionCreateLocalizationsInput>;
  qabu?: InputMaybe<Scalars['Json']>;
  /** title input for default locale (en) */
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type TitleDescriptionCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type TitleDescriptionCreateLocalizationInput = {
  /** Localization input */
  data: TitleDescriptionCreateLocalizationDataInput;
  locale: Locale;
};

export type TitleDescriptionCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<TitleDescriptionCreateLocalizationInput>>;
};

export type TitleDescriptionCreateManyInlineInput = {
  /** Connect multiple existing TitleDescription documents */
  connect?: InputMaybe<Array<TitleDescriptionWhereUniqueInput>>;
  /** Create and connect multiple existing TitleDescription documents */
  create?: InputMaybe<Array<TitleDescriptionCreateInput>>;
};

export type TitleDescriptionCreateOneInlineInput = {
  /** Connect one existing TitleDescription document */
  connect?: InputMaybe<TitleDescriptionWhereUniqueInput>;
  /** Create and connect one TitleDescription document */
  create?: InputMaybe<TitleDescriptionCreateInput>;
};

/** An edge in a connection. */
export type TitleDescriptionEdge = {
  __typename?: 'TitleDescriptionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: TitleDescription;
};

/** Identifies documents */
export type TitleDescriptionManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<TitleDescriptionWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<TitleDescriptionWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<TitleDescriptionWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  attractionAlacarteCustomInstructions?: InputMaybe<AttractionAlacarteWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<TitleDescriptionWhereStageInput>;
  documentInStages_none?: InputMaybe<TitleDescriptionWhereStageInput>;
  documentInStages_some?: InputMaybe<TitleDescriptionWhereStageInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  key?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  key_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  key_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  key_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  key_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  key_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  key_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  key_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  key_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  key_starts_with?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  /** All values containing the given json path. */
  qabu_json_path_exists?: InputMaybe<Scalars['String']>;
  /**
   * Recursively tries to find the provided JSON scalar value inside the field.
   * It does use an exact match when comparing values.
   * If you pass `null` as value the filter will be ignored.
   * Note: This filter fails if you try to look for a non scalar JSON value!
   */
  qabu_value_recursive?: InputMaybe<Scalars['Json']>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum TitleDescriptionOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type TitleDescriptionUpdateInput = {
  attractionAlacarteCustomInstructions?: InputMaybe<AttractionAlacarteUpdateOneInlineInput>;
  /** description input for default locale (en) */
  description?: InputMaybe<Scalars['String']>;
  key?: InputMaybe<Scalars['String']>;
  /** Manage document localizations */
  localizations?: InputMaybe<TitleDescriptionUpdateLocalizationsInput>;
  qabu?: InputMaybe<Scalars['Json']>;
  /** title input for default locale (en) */
  title?: InputMaybe<Scalars['String']>;
};

export type TitleDescriptionUpdateLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type TitleDescriptionUpdateLocalizationInput = {
  data: TitleDescriptionUpdateLocalizationDataInput;
  locale: Locale;
};

export type TitleDescriptionUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<TitleDescriptionCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<TitleDescriptionUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<TitleDescriptionUpsertLocalizationInput>>;
};

export type TitleDescriptionUpdateManyInlineInput = {
  /** Connect multiple existing TitleDescription documents */
  connect?: InputMaybe<Array<TitleDescriptionConnectInput>>;
  /** Create and connect multiple TitleDescription documents */
  create?: InputMaybe<Array<TitleDescriptionCreateInput>>;
  /** Delete multiple TitleDescription documents */
  delete?: InputMaybe<Array<TitleDescriptionWhereUniqueInput>>;
  /** Disconnect multiple TitleDescription documents */
  disconnect?: InputMaybe<Array<TitleDescriptionWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing TitleDescription documents */
  set?: InputMaybe<Array<TitleDescriptionWhereUniqueInput>>;
  /** Update multiple TitleDescription documents */
  update?: InputMaybe<Array<TitleDescriptionUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple TitleDescription documents */
  upsert?: InputMaybe<Array<TitleDescriptionUpsertWithNestedWhereUniqueInput>>;
};

export type TitleDescriptionUpdateManyInput = {
  /** description input for default locale (en) */
  description?: InputMaybe<Scalars['String']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<TitleDescriptionUpdateManyLocalizationsInput>;
  qabu?: InputMaybe<Scalars['Json']>;
  /** title input for default locale (en) */
  title?: InputMaybe<Scalars['String']>;
};

export type TitleDescriptionUpdateManyLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type TitleDescriptionUpdateManyLocalizationInput = {
  data: TitleDescriptionUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type TitleDescriptionUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<TitleDescriptionUpdateManyLocalizationInput>>;
};

export type TitleDescriptionUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: TitleDescriptionUpdateManyInput;
  /** Document search */
  where: TitleDescriptionWhereInput;
};

export type TitleDescriptionUpdateOneInlineInput = {
  /** Connect existing TitleDescription document */
  connect?: InputMaybe<TitleDescriptionWhereUniqueInput>;
  /** Create and connect one TitleDescription document */
  create?: InputMaybe<TitleDescriptionCreateInput>;
  /** Delete currently connected TitleDescription document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected TitleDescription document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single TitleDescription document */
  update?: InputMaybe<TitleDescriptionUpdateWithNestedWhereUniqueInput>;
  /** Upsert single TitleDescription document */
  upsert?: InputMaybe<TitleDescriptionUpsertWithNestedWhereUniqueInput>;
};

export type TitleDescriptionUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: TitleDescriptionUpdateInput;
  /** Unique document search */
  where: TitleDescriptionWhereUniqueInput;
};

export type TitleDescriptionUpsertInput = {
  /** Create document if it didn't exist */
  create: TitleDescriptionCreateInput;
  /** Update document if it exists */
  update: TitleDescriptionUpdateInput;
};

export type TitleDescriptionUpsertLocalizationInput = {
  create: TitleDescriptionCreateLocalizationDataInput;
  locale: Locale;
  update: TitleDescriptionUpdateLocalizationDataInput;
};

export type TitleDescriptionUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: TitleDescriptionUpsertInput;
  /** Unique document search */
  where: TitleDescriptionWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type TitleDescriptionWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']>;
};

/** Identifies documents */
export type TitleDescriptionWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<TitleDescriptionWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<TitleDescriptionWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<TitleDescriptionWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  attractionAlacarteCustomInstructions?: InputMaybe<AttractionAlacarteWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  description?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  description_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars['String']>;
  documentInStages_every?: InputMaybe<TitleDescriptionWhereStageInput>;
  documentInStages_none?: InputMaybe<TitleDescriptionWhereStageInput>;
  documentInStages_some?: InputMaybe<TitleDescriptionWhereStageInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  key?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  key_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  key_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  key_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  key_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  key_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  key_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  key_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  key_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  key_starts_with?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  /** All values containing the given json path. */
  qabu_json_path_exists?: InputMaybe<Scalars['String']>;
  /**
   * Recursively tries to find the provided JSON scalar value inside the field.
   * It does use an exact match when comparing values.
   * If you pass `null` as value the filter will be ignored.
   * Note: This filter fails if you try to look for a non scalar JSON value!
   */
  qabu_value_recursive?: InputMaybe<Scalars['Json']>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  title?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type TitleDescriptionWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<TitleDescriptionWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<TitleDescriptionWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<TitleDescriptionWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<TitleDescriptionWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References TitleDescription record uniquely */
export type TitleDescriptionWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
  key?: InputMaybe<Scalars['String']>;
};

export type UnpublishLocaleInput = {
  /** Locales to unpublish */
  locale: Locale;
  /** Stages to unpublish selected locales from */
  stages: Array<Stage>;
};

/** User system model */
export type User = Node & {
  __typename?: 'User';
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** Get the document in other stages */
  documentInStages: Array<User>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** Flag to determine if user is active or not */
  isActive: Scalars['Boolean'];
  /** User Kind. Can be either MEMBER, PAT or PUBLIC */
  kind: UserKind;
  /** The username */
  name: Scalars['String'];
  /** Profile Picture url */
  picture: Maybe<Scalars['String']>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt: Maybe<Scalars['DateTime']>;
  /** System stage field */
  stage: Stage;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
};

/** User system model */
export type UserDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};

export type UserConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: UserWhereUniqueInput;
};

/** A connection to a list of items. */
export type UserConnection = {
  __typename?: 'UserConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<UserEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type UserCreateManyInlineInput = {
  /** Connect multiple existing User documents */
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
};

export type UserCreateOneInlineInput = {
  /** Connect one existing User document */
  connect?: InputMaybe<UserWhereUniqueInput>;
};

/** An edge in a connection. */
export type UserEdge = {
  __typename?: 'UserEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: User;
};

/** System User Kind */
export enum UserKind {
  Member = 'MEMBER',
  Pat = 'PAT',
  Public = 'PUBLIC',
  Webhook = 'WEBHOOK',
}

/** Identifies documents */
export type UserManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<UserWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<UserWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<UserWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  documentInStages_every?: InputMaybe<UserWhereStageInput>;
  documentInStages_none?: InputMaybe<UserWhereStageInput>;
  documentInStages_some?: InputMaybe<UserWhereStageInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  isActive_not?: InputMaybe<Scalars['Boolean']>;
  kind?: InputMaybe<UserKind>;
  /** All values that are contained in given list. */
  kind_in?: InputMaybe<Array<InputMaybe<UserKind>>>;
  /** Any other value that exists and is not equal to the given value. */
  kind_not?: InputMaybe<UserKind>;
  /** All values that are not contained in given list. */
  kind_not_in?: InputMaybe<Array<InputMaybe<UserKind>>>;
  name?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']>;
  picture?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  picture_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  picture_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  picture_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  picture_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  picture_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  picture_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  picture_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  picture_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  picture_starts_with?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
};

export enum UserOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  IsActiveAsc = 'isActive_ASC',
  IsActiveDesc = 'isActive_DESC',
  KindAsc = 'kind_ASC',
  KindDesc = 'kind_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PictureAsc = 'picture_ASC',
  PictureDesc = 'picture_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type UserUpdateManyInlineInput = {
  /** Connect multiple existing User documents */
  connect?: InputMaybe<Array<UserConnectInput>>;
  /** Disconnect multiple User documents */
  disconnect?: InputMaybe<Array<UserWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing User documents */
  set?: InputMaybe<Array<UserWhereUniqueInput>>;
};

export type UserUpdateOneInlineInput = {
  /** Connect existing User document */
  connect?: InputMaybe<UserWhereUniqueInput>;
  /** Disconnect currently connected User document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
};

/** This contains a set of filters that can be used to compare values internally */
export type UserWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']>;
};

/** Identifies documents */
export type UserWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<UserWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<UserWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<UserWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  documentInStages_every?: InputMaybe<UserWhereStageInput>;
  documentInStages_none?: InputMaybe<UserWhereStageInput>;
  documentInStages_some?: InputMaybe<UserWhereStageInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  isActive_not?: InputMaybe<Scalars['Boolean']>;
  kind?: InputMaybe<UserKind>;
  /** All values that are contained in given list. */
  kind_in?: InputMaybe<Array<InputMaybe<UserKind>>>;
  /** Any other value that exists and is not equal to the given value. */
  kind_not?: InputMaybe<UserKind>;
  /** All values that are not contained in given list. */
  kind_not_in?: InputMaybe<Array<InputMaybe<UserKind>>>;
  name?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']>;
  picture?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  picture_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  picture_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  picture_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  picture_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  picture_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  picture_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  picture_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  picture_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  picture_starts_with?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type UserWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<UserWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<UserWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<UserWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<UserWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References User record uniquely */
export type UserWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type Version = {
  __typename?: 'Version';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  revision: Scalars['Int'];
  stage: Stage;
};

export type VersionWhereInput = {
  id: Scalars['ID'];
  revision: Scalars['Int'];
  stage: Stage;
};

export type Video = Node & {
  __typename?: 'Video';
  attraction: Maybe<Attraction>;
  attractionVariants: Array<AttractionVariant>;
  caption: Maybe<Scalars['String']>;
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** User that created this document */
  createdBy: Maybe<User>;
  /** Get the document in other stages */
  documentInStages: Array<Video>;
  /** List of Video versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID'];
  internalTitle: Maybe<Scalars['String']>;
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<Video>;
  products: Array<Product>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt: Maybe<Scalars['DateTime']>;
  /** User that last published this document */
  publishedBy: Maybe<User>;
  qabu: Maybe<Scalars['Json']>;
  scheduledIn: Array<ScheduledOperation>;
  /** System stage field */
  stage: Stage;
  thumb: Maybe<Asset>;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** User that last updated this document */
  updatedBy: Maybe<User>;
  /** Use the embed URL (not the page) https://www.youtube.com/embed/abc123 */
  url: Maybe<Scalars['String']>;
};

export type VideoAttractionArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type VideoAttractionVariantsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<AttractionVariantWhereInput>;
};

export type VideoCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type VideoCreatedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type VideoDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};

export type VideoHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride: InputMaybe<Stage>;
};

export type VideoLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};

export type VideoProductsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  orderBy: InputMaybe<ProductOrderByInput>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ProductWhereInput>;
};

export type VideoPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type VideoPublishedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type VideoScheduledInArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  first: InputMaybe<Scalars['Int']>;
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  last: InputMaybe<Scalars['Int']>;
  locales: InputMaybe<Array<Locale>>;
  skip: InputMaybe<Scalars['Int']>;
  where: InputMaybe<ScheduledOperationWhereInput>;
};

export type VideoThumbArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type VideoUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type VideoUpdatedByArgs = {
  forceParentLocale: InputMaybe<Scalars['Boolean']>;
  locales: InputMaybe<Array<Locale>>;
};

export type VideoConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: VideoWhereUniqueInput;
};

/** A connection to a list of items. */
export type VideoConnection = {
  __typename?: 'VideoConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<VideoEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type VideoCreateInput = {
  attraction?: InputMaybe<AttractionCreateOneInlineInput>;
  attractionVariants?: InputMaybe<AttractionVariantCreateManyInlineInput>;
  /** caption input for default locale (en) */
  caption?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  internalTitle?: InputMaybe<Scalars['String']>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<VideoCreateLocalizationsInput>;
  products?: InputMaybe<ProductCreateManyInlineInput>;
  qabu?: InputMaybe<Scalars['Json']>;
  thumb?: InputMaybe<AssetCreateOneInlineInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  url?: InputMaybe<Scalars['String']>;
};

export type VideoCreateLocalizationDataInput = {
  caption?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type VideoCreateLocalizationInput = {
  /** Localization input */
  data: VideoCreateLocalizationDataInput;
  locale: Locale;
};

export type VideoCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<VideoCreateLocalizationInput>>;
};

export type VideoCreateManyInlineInput = {
  /** Connect multiple existing Video documents */
  connect?: InputMaybe<Array<VideoWhereUniqueInput>>;
  /** Create and connect multiple existing Video documents */
  create?: InputMaybe<Array<VideoCreateInput>>;
};

export type VideoCreateOneInlineInput = {
  /** Connect one existing Video document */
  connect?: InputMaybe<VideoWhereUniqueInput>;
  /** Create and connect one Video document */
  create?: InputMaybe<VideoCreateInput>;
};

/** An edge in a connection. */
export type VideoEdge = {
  __typename?: 'VideoEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Video;
};

/** Identifies documents */
export type VideoManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<VideoWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<VideoWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<VideoWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  attraction?: InputMaybe<AttractionWhereInput>;
  attractionVariants_every?: InputMaybe<AttractionVariantWhereInput>;
  attractionVariants_none?: InputMaybe<AttractionVariantWhereInput>;
  attractionVariants_some?: InputMaybe<AttractionVariantWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<VideoWhereStageInput>;
  documentInStages_none?: InputMaybe<VideoWhereStageInput>;
  documentInStages_some?: InputMaybe<VideoWhereStageInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  internalTitle?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  internalTitle_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  internalTitle_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  internalTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  internalTitle_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  internalTitle_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  internalTitle_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  internalTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  internalTitle_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  internalTitle_starts_with?: InputMaybe<Scalars['String']>;
  products_every?: InputMaybe<ProductWhereInput>;
  products_none?: InputMaybe<ProductWhereInput>;
  products_some?: InputMaybe<ProductWhereInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  /** All values containing the given json path. */
  qabu_json_path_exists?: InputMaybe<Scalars['String']>;
  /**
   * Recursively tries to find the provided JSON scalar value inside the field.
   * It does use an exact match when comparing values.
   * If you pass `null` as value the filter will be ignored.
   * Note: This filter fails if you try to look for a non scalar JSON value!
   */
  qabu_value_recursive?: InputMaybe<Scalars['Json']>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  thumb?: InputMaybe<AssetWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
  url?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  url_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  url_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  url_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  url_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  url_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  url_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  url_starts_with?: InputMaybe<Scalars['String']>;
};

export enum VideoOrderByInput {
  CaptionAsc = 'caption_ASC',
  CaptionDesc = 'caption_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  InternalTitleAsc = 'internalTitle_ASC',
  InternalTitleDesc = 'internalTitle_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC',
}

export type VideoUpdateInput = {
  attraction?: InputMaybe<AttractionUpdateOneInlineInput>;
  attractionVariants?: InputMaybe<AttractionVariantUpdateManyInlineInput>;
  /** caption input for default locale (en) */
  caption?: InputMaybe<Scalars['String']>;
  internalTitle?: InputMaybe<Scalars['String']>;
  /** Manage document localizations */
  localizations?: InputMaybe<VideoUpdateLocalizationsInput>;
  products?: InputMaybe<ProductUpdateManyInlineInput>;
  qabu?: InputMaybe<Scalars['Json']>;
  thumb?: InputMaybe<AssetUpdateOneInlineInput>;
  url?: InputMaybe<Scalars['String']>;
};

export type VideoUpdateLocalizationDataInput = {
  caption?: InputMaybe<Scalars['String']>;
};

export type VideoUpdateLocalizationInput = {
  data: VideoUpdateLocalizationDataInput;
  locale: Locale;
};

export type VideoUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<VideoCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<VideoUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<VideoUpsertLocalizationInput>>;
};

export type VideoUpdateManyInlineInput = {
  /** Connect multiple existing Video documents */
  connect?: InputMaybe<Array<VideoConnectInput>>;
  /** Create and connect multiple Video documents */
  create?: InputMaybe<Array<VideoCreateInput>>;
  /** Delete multiple Video documents */
  delete?: InputMaybe<Array<VideoWhereUniqueInput>>;
  /** Disconnect multiple Video documents */
  disconnect?: InputMaybe<Array<VideoWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing Video documents */
  set?: InputMaybe<Array<VideoWhereUniqueInput>>;
  /** Update multiple Video documents */
  update?: InputMaybe<Array<VideoUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple Video documents */
  upsert?: InputMaybe<Array<VideoUpsertWithNestedWhereUniqueInput>>;
};

export type VideoUpdateManyInput = {
  /** caption input for default locale (en) */
  caption?: InputMaybe<Scalars['String']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<VideoUpdateManyLocalizationsInput>;
  qabu?: InputMaybe<Scalars['Json']>;
};

export type VideoUpdateManyLocalizationDataInput = {
  caption?: InputMaybe<Scalars['String']>;
};

export type VideoUpdateManyLocalizationInput = {
  data: VideoUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type VideoUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<VideoUpdateManyLocalizationInput>>;
};

export type VideoUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: VideoUpdateManyInput;
  /** Document search */
  where: VideoWhereInput;
};

export type VideoUpdateOneInlineInput = {
  /** Connect existing Video document */
  connect?: InputMaybe<VideoWhereUniqueInput>;
  /** Create and connect one Video document */
  create?: InputMaybe<VideoCreateInput>;
  /** Delete currently connected Video document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected Video document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single Video document */
  update?: InputMaybe<VideoUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Video document */
  upsert?: InputMaybe<VideoUpsertWithNestedWhereUniqueInput>;
};

export type VideoUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: VideoUpdateInput;
  /** Unique document search */
  where: VideoWhereUniqueInput;
};

export type VideoUpsertInput = {
  /** Create document if it didn't exist */
  create: VideoCreateInput;
  /** Update document if it exists */
  update: VideoUpdateInput;
};

export type VideoUpsertLocalizationInput = {
  create: VideoCreateLocalizationDataInput;
  locale: Locale;
  update: VideoUpdateLocalizationDataInput;
};

export type VideoUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: VideoUpsertInput;
  /** Unique document search */
  where: VideoWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type VideoWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']>;
};

/** Identifies documents */
export type VideoWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<VideoWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<VideoWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<VideoWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  attraction?: InputMaybe<AttractionWhereInput>;
  attractionVariants_every?: InputMaybe<AttractionVariantWhereInput>;
  attractionVariants_none?: InputMaybe<AttractionVariantWhereInput>;
  attractionVariants_some?: InputMaybe<AttractionVariantWhereInput>;
  caption?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  caption_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  caption_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  caption_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  caption_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  caption_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  caption_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  caption_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  caption_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  caption_starts_with?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<VideoWhereStageInput>;
  documentInStages_none?: InputMaybe<VideoWhereStageInput>;
  documentInStages_some?: InputMaybe<VideoWhereStageInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  internalTitle?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  internalTitle_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  internalTitle_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  internalTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  internalTitle_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  internalTitle_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  internalTitle_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  internalTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  internalTitle_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  internalTitle_starts_with?: InputMaybe<Scalars['String']>;
  products_every?: InputMaybe<ProductWhereInput>;
  products_none?: InputMaybe<ProductWhereInput>;
  products_some?: InputMaybe<ProductWhereInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  /** All values containing the given json path. */
  qabu_json_path_exists?: InputMaybe<Scalars['String']>;
  /**
   * Recursively tries to find the provided JSON scalar value inside the field.
   * It does use an exact match when comparing values.
   * If you pass `null` as value the filter will be ignored.
   * Note: This filter fails if you try to look for a non scalar JSON value!
   */
  qabu_value_recursive?: InputMaybe<Scalars['Json']>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  thumb?: InputMaybe<AssetWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
  url?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  url_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  url_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  url_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  url_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  url_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  url_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  url_starts_with?: InputMaybe<Scalars['String']>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type VideoWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<VideoWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<VideoWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<VideoWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<VideoWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References Video record uniquely */
export type VideoWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
  internalTitle?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
};

export enum _FilterKind {
  And = 'AND',
  Not = 'NOT',
  Or = 'OR',
  Contains = 'contains',
  ContainsAll = 'contains_all',
  ContainsNone = 'contains_none',
  ContainsSome = 'contains_some',
  EndsWith = 'ends_with',
  Eq = 'eq',
  EqNot = 'eq_not',
  Gt = 'gt',
  Gte = 'gte',
  In = 'in',
  JsonPathExists = 'json_path_exists',
  JsonValueRecursive = 'json_value_recursive',
  Lt = 'lt',
  Lte = 'lte',
  NotContains = 'not_contains',
  NotEndsWith = 'not_ends_with',
  NotIn = 'not_in',
  NotStartsWith = 'not_starts_with',
  RelationalEvery = 'relational_every',
  RelationalNone = 'relational_none',
  RelationalSingle = 'relational_single',
  RelationalSome = 'relational_some',
  Search = 'search',
  StartsWith = 'starts_with',
  UnionEmpty = 'union_empty',
  UnionEvery = 'union_every',
  UnionNone = 'union_none',
  UnionSingle = 'union_single',
  UnionSome = 'union_some',
}

export enum _MutationInputFieldKind {
  Enum = 'enum',
  Relation = 'relation',
  RichText = 'richText',
  RichTextWithEmbeds = 'richTextWithEmbeds',
  Scalar = 'scalar',
  Union = 'union',
  Virtual = 'virtual',
}

export enum _MutationKind {
  Create = 'create',
  Delete = 'delete',
  DeleteMany = 'deleteMany',
  Publish = 'publish',
  PublishMany = 'publishMany',
  SchedulePublish = 'schedulePublish',
  ScheduleUnpublish = 'scheduleUnpublish',
  Unpublish = 'unpublish',
  UnpublishMany = 'unpublishMany',
  Update = 'update',
  UpdateMany = 'updateMany',
  Upsert = 'upsert',
}

export enum _OrderDirection {
  Asc = 'asc',
  Desc = 'desc',
}

export enum _RelationInputCardinality {
  Many = 'many',
  One = 'one',
}

export enum _RelationInputKind {
  Create = 'create',
  Update = 'update',
}

export enum _RelationKind {
  Regular = 'regular',
  Union = 'union',
}

export enum _SystemDateTimeFieldVariation {
  Base = 'base',
  Combined = 'combined',
  Localization = 'localization',
}

export type ResolverTypeWrapper<T> = Promise<T> | T;

export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> =
  | ResolverFn<TResult, TParent, TContext, TArgs>
  | ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<
  TResult,
  TKey extends string,
  TParent,
  TContext,
  TArgs
> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<
  TResult,
  TKey extends string,
  TParent = {},
  TContext = {},
  TArgs = {}
> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (
  obj: T,
  context: TContext,
  info: GraphQLResolveInfo
) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

/** Mapping of union types */
export type ResolversUnionTypes = {
  AssetVariantParent: Asset;
  AttractionGallery: Image | Video;
  AttractionGroupParent: ProductAttractionLineup;
  AttractionVariantGallery: Image | Video;
  BlockUIParent: Block;
  CrossPromoBannerParent: Omit<Promo, 'template'> & {
    template: Maybe<ResolversTypes['PromotemplateUnion']>;
  };
  DateRangeParent: PromoTicketText;
  LandingPageBodyRichRichTextEmbeddedTypes:
    | Article
    | Asset
    | (Omit<Attraction, 'gallery'> & { gallery: Array<ResolversTypes['AttractionGallery']> })
    | Product
    | Text;
  MenuItems:
    | Link
    | (Omit<Menu, 'items'> & { items: Array<ResolversTypes['MenuItems']> })
    | Page
    | Product;
  PromoBannerParent: Omit<Promo, 'template'> & {
    template: Maybe<ResolversTypes['PromotemplateUnion']>;
  };
  PromoTextParent: Omit<Promo, 'template'> & {
    template: Maybe<ResolversTypes['PromotemplateUnion']>;
  };
  PromoTicketBannerParent: Omit<Promo, 'template'> & {
    template: Maybe<ResolversTypes['PromotemplateUnion']>;
  };
  PromoTicketTextParent: Omit<Promo, 'template'> & {
    template: Maybe<ResolversTypes['PromotemplateUnion']>;
  };
  PromotemplateUnion:
    | CrossPromoBanner
    | PromoBanner
    | PromoText
    | PromoTicketBanner
    | PromoTicketText;
  ScheduledOperationAffectedDocument:
    | AddOn
    | AlacarteTicket
    | AlacarteTicketType
    | Article
    | ArticleCategory
    | Asset
    | (Omit<Attraction, 'gallery'> & { gallery: Array<ResolversTypes['AttractionGallery']> })
    | AttractionAlacarte
    | AttractionAvailability
    | AttractionLocation
    | AttractionOffer
    | AttractionPrice
    | AttractionReservation
    | (Omit<AttractionVariant, 'gallery'> & {
        gallery: Array<ResolversTypes['AttractionVariantGallery']>;
      })
    | Author
    | Block
    | BlockGroup
    | DetailBlock
    | GeneralReservation
    | Image
    | LandingPage
    | Link
    | (Omit<Menu, 'items'> & { items: Array<ResolversTypes['MenuItems']> })
    | Notice
    | Page
    | PageTemplate
    | Partner
    | PartnerOffer
    | Product
    | ProductActivity
    | ProductActivityItem
    | ProductAttractionLineup
    | ProductReservation
    | (Omit<Promo, 'template'> & { template: Maybe<ResolversTypes['PromotemplateUnion']> })
    | ReservationText
    | Sandbox
    | Seo
    | Text
    | (Omit<TextGroup, 'text'> & { text: Array<ResolversTypes['TextGroupText']> })
    | TextList
    | TicketGroup
    | TitleDescription
    | Video;
  SkuPropertyParent: Omit<Attraction, 'gallery'> & {
    gallery: Array<ResolversTypes['AttractionGallery']>;
  };
  TextGroupText: Text | TextList;
};

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  AddOn: ResolverTypeWrapper<AddOn>;
  AddOnConnectInput: AddOnConnectInput;
  AddOnConnection: ResolverTypeWrapper<AddOnConnection>;
  AddOnCreateInput: AddOnCreateInput;
  AddOnCreateLocalizationDataInput: AddOnCreateLocalizationDataInput;
  AddOnCreateLocalizationInput: AddOnCreateLocalizationInput;
  AddOnCreateLocalizationsInput: AddOnCreateLocalizationsInput;
  AddOnCreateManyInlineInput: AddOnCreateManyInlineInput;
  AddOnCreateOneInlineInput: AddOnCreateOneInlineInput;
  AddOnEdge: ResolverTypeWrapper<AddOnEdge>;
  AddOnManyWhereInput: AddOnManyWhereInput;
  AddOnOrderByInput: AddOnOrderByInput;
  AddOnUpdateInput: AddOnUpdateInput;
  AddOnUpdateLocalizationDataInput: AddOnUpdateLocalizationDataInput;
  AddOnUpdateLocalizationInput: AddOnUpdateLocalizationInput;
  AddOnUpdateLocalizationsInput: AddOnUpdateLocalizationsInput;
  AddOnUpdateManyInlineInput: AddOnUpdateManyInlineInput;
  AddOnUpdateManyInput: AddOnUpdateManyInput;
  AddOnUpdateManyLocalizationDataInput: AddOnUpdateManyLocalizationDataInput;
  AddOnUpdateManyLocalizationInput: AddOnUpdateManyLocalizationInput;
  AddOnUpdateManyLocalizationsInput: AddOnUpdateManyLocalizationsInput;
  AddOnUpdateManyWithNestedWhereInput: AddOnUpdateManyWithNestedWhereInput;
  AddOnUpdateOneInlineInput: AddOnUpdateOneInlineInput;
  AddOnUpdateWithNestedWhereUniqueInput: AddOnUpdateWithNestedWhereUniqueInput;
  AddOnUpsertInput: AddOnUpsertInput;
  AddOnUpsertLocalizationInput: AddOnUpsertLocalizationInput;
  AddOnUpsertWithNestedWhereUniqueInput: AddOnUpsertWithNestedWhereUniqueInput;
  AddOnWhereComparatorInput: AddOnWhereComparatorInput;
  AddOnWhereInput: AddOnWhereInput;
  AddOnWhereStageInput: AddOnWhereStageInput;
  AddOnWhereUniqueInput: AddOnWhereUniqueInput;
  Aggregate: ResolverTypeWrapper<Aggregate>;
  AlacarteTicket: ResolverTypeWrapper<AlacarteTicket>;
  AlacarteTicketConnectInput: AlacarteTicketConnectInput;
  AlacarteTicketConnection: ResolverTypeWrapper<AlacarteTicketConnection>;
  AlacarteTicketCreateInput: AlacarteTicketCreateInput;
  AlacarteTicketCreateManyInlineInput: AlacarteTicketCreateManyInlineInput;
  AlacarteTicketCreateOneInlineInput: AlacarteTicketCreateOneInlineInput;
  AlacarteTicketEdge: ResolverTypeWrapper<AlacarteTicketEdge>;
  AlacarteTicketManyWhereInput: AlacarteTicketManyWhereInput;
  AlacarteTicketOrderByInput: AlacarteTicketOrderByInput;
  AlacarteTicketType: ResolverTypeWrapper<AlacarteTicketType>;
  AlacarteTicketTypeConnectInput: AlacarteTicketTypeConnectInput;
  AlacarteTicketTypeConnection: ResolverTypeWrapper<AlacarteTicketTypeConnection>;
  AlacarteTicketTypeCreateInput: AlacarteTicketTypeCreateInput;
  AlacarteTicketTypeCreateManyInlineInput: AlacarteTicketTypeCreateManyInlineInput;
  AlacarteTicketTypeCreateOneInlineInput: AlacarteTicketTypeCreateOneInlineInput;
  AlacarteTicketTypeEdge: ResolverTypeWrapper<AlacarteTicketTypeEdge>;
  AlacarteTicketTypeManyWhereInput: AlacarteTicketTypeManyWhereInput;
  AlacarteTicketTypeOrderByInput: AlacarteTicketTypeOrderByInput;
  AlacarteTicketTypeUpdateInput: AlacarteTicketTypeUpdateInput;
  AlacarteTicketTypeUpdateManyInlineInput: AlacarteTicketTypeUpdateManyInlineInput;
  AlacarteTicketTypeUpdateManyInput: AlacarteTicketTypeUpdateManyInput;
  AlacarteTicketTypeUpdateManyWithNestedWhereInput: AlacarteTicketTypeUpdateManyWithNestedWhereInput;
  AlacarteTicketTypeUpdateOneInlineInput: AlacarteTicketTypeUpdateOneInlineInput;
  AlacarteTicketTypeUpdateWithNestedWhereUniqueInput: AlacarteTicketTypeUpdateWithNestedWhereUniqueInput;
  AlacarteTicketTypeUpsertInput: AlacarteTicketTypeUpsertInput;
  AlacarteTicketTypeUpsertWithNestedWhereUniqueInput: AlacarteTicketTypeUpsertWithNestedWhereUniqueInput;
  AlacarteTicketTypeWhereComparatorInput: AlacarteTicketTypeWhereComparatorInput;
  AlacarteTicketTypeWhereInput: AlacarteTicketTypeWhereInput;
  AlacarteTicketTypeWhereStageInput: AlacarteTicketTypeWhereStageInput;
  AlacarteTicketTypeWhereUniqueInput: AlacarteTicketTypeWhereUniqueInput;
  AlacarteTicketUpdateInput: AlacarteTicketUpdateInput;
  AlacarteTicketUpdateManyInlineInput: AlacarteTicketUpdateManyInlineInput;
  AlacarteTicketUpdateManyInput: AlacarteTicketUpdateManyInput;
  AlacarteTicketUpdateManyWithNestedWhereInput: AlacarteTicketUpdateManyWithNestedWhereInput;
  AlacarteTicketUpdateOneInlineInput: AlacarteTicketUpdateOneInlineInput;
  AlacarteTicketUpdateWithNestedWhereUniqueInput: AlacarteTicketUpdateWithNestedWhereUniqueInput;
  AlacarteTicketUpsertInput: AlacarteTicketUpsertInput;
  AlacarteTicketUpsertWithNestedWhereUniqueInput: AlacarteTicketUpsertWithNestedWhereUniqueInput;
  AlacarteTicketWhereComparatorInput: AlacarteTicketWhereComparatorInput;
  AlacarteTicketWhereInput: AlacarteTicketWhereInput;
  AlacarteTicketWhereStageInput: AlacarteTicketWhereStageInput;
  AlacarteTicketWhereUniqueInput: AlacarteTicketWhereUniqueInput;
  Article: ResolverTypeWrapper<Article>;
  ArticleCategory: ResolverTypeWrapper<ArticleCategory>;
  ArticleCategoryConnectInput: ArticleCategoryConnectInput;
  ArticleCategoryConnection: ResolverTypeWrapper<ArticleCategoryConnection>;
  ArticleCategoryCreateInput: ArticleCategoryCreateInput;
  ArticleCategoryCreateManyInlineInput: ArticleCategoryCreateManyInlineInput;
  ArticleCategoryCreateOneInlineInput: ArticleCategoryCreateOneInlineInput;
  ArticleCategoryEdge: ResolverTypeWrapper<ArticleCategoryEdge>;
  ArticleCategoryManyWhereInput: ArticleCategoryManyWhereInput;
  ArticleCategoryOrderByInput: ArticleCategoryOrderByInput;
  ArticleCategoryUpdateInput: ArticleCategoryUpdateInput;
  ArticleCategoryUpdateManyInlineInput: ArticleCategoryUpdateManyInlineInput;
  ArticleCategoryUpdateManyInput: ArticleCategoryUpdateManyInput;
  ArticleCategoryUpdateManyWithNestedWhereInput: ArticleCategoryUpdateManyWithNestedWhereInput;
  ArticleCategoryUpdateOneInlineInput: ArticleCategoryUpdateOneInlineInput;
  ArticleCategoryUpdateWithNestedWhereUniqueInput: ArticleCategoryUpdateWithNestedWhereUniqueInput;
  ArticleCategoryUpsertInput: ArticleCategoryUpsertInput;
  ArticleCategoryUpsertWithNestedWhereUniqueInput: ArticleCategoryUpsertWithNestedWhereUniqueInput;
  ArticleCategoryWhereComparatorInput: ArticleCategoryWhereComparatorInput;
  ArticleCategoryWhereInput: ArticleCategoryWhereInput;
  ArticleCategoryWhereStageInput: ArticleCategoryWhereStageInput;
  ArticleCategoryWhereUniqueInput: ArticleCategoryWhereUniqueInput;
  ArticleConnectInput: ArticleConnectInput;
  ArticleConnection: ResolverTypeWrapper<ArticleConnection>;
  ArticleContentType: ArticleContentType;
  ArticleCreateInput: ArticleCreateInput;
  ArticleCreateLocalizationDataInput: ArticleCreateLocalizationDataInput;
  ArticleCreateLocalizationInput: ArticleCreateLocalizationInput;
  ArticleCreateLocalizationsInput: ArticleCreateLocalizationsInput;
  ArticleCreateManyInlineInput: ArticleCreateManyInlineInput;
  ArticleCreateOneInlineInput: ArticleCreateOneInlineInput;
  ArticleEdge: ResolverTypeWrapper<ArticleEdge>;
  ArticleManyWhereInput: ArticleManyWhereInput;
  ArticleOrderByInput: ArticleOrderByInput;
  ArticleUpdateInput: ArticleUpdateInput;
  ArticleUpdateLocalizationDataInput: ArticleUpdateLocalizationDataInput;
  ArticleUpdateLocalizationInput: ArticleUpdateLocalizationInput;
  ArticleUpdateLocalizationsInput: ArticleUpdateLocalizationsInput;
  ArticleUpdateManyInlineInput: ArticleUpdateManyInlineInput;
  ArticleUpdateManyInput: ArticleUpdateManyInput;
  ArticleUpdateManyLocalizationDataInput: ArticleUpdateManyLocalizationDataInput;
  ArticleUpdateManyLocalizationInput: ArticleUpdateManyLocalizationInput;
  ArticleUpdateManyLocalizationsInput: ArticleUpdateManyLocalizationsInput;
  ArticleUpdateManyWithNestedWhereInput: ArticleUpdateManyWithNestedWhereInput;
  ArticleUpdateOneInlineInput: ArticleUpdateOneInlineInput;
  ArticleUpdateWithNestedWhereUniqueInput: ArticleUpdateWithNestedWhereUniqueInput;
  ArticleUpsertInput: ArticleUpsertInput;
  ArticleUpsertLocalizationInput: ArticleUpsertLocalizationInput;
  ArticleUpsertWithNestedWhereUniqueInput: ArticleUpsertWithNestedWhereUniqueInput;
  ArticleWhereComparatorInput: ArticleWhereComparatorInput;
  ArticleWhereInput: ArticleWhereInput;
  ArticleWhereStageInput: ArticleWhereStageInput;
  ArticleWhereUniqueInput: ArticleWhereUniqueInput;
  Asset: ResolverTypeWrapper<Asset>;
  AssetConnectInput: AssetConnectInput;
  AssetConnection: ResolverTypeWrapper<AssetConnection>;
  AssetCreateInput: AssetCreateInput;
  AssetCreateLocalizationDataInput: AssetCreateLocalizationDataInput;
  AssetCreateLocalizationInput: AssetCreateLocalizationInput;
  AssetCreateLocalizationsInput: AssetCreateLocalizationsInput;
  AssetCreateManyInlineInput: AssetCreateManyInlineInput;
  AssetCreateOneInlineInput: AssetCreateOneInlineInput;
  AssetEdge: ResolverTypeWrapper<AssetEdge>;
  AssetManyWhereInput: AssetManyWhereInput;
  AssetOrderByInput: AssetOrderByInput;
  AssetSizes: AssetSizes;
  AssetTransformationInput: AssetTransformationInput;
  AssetUpdateInput: AssetUpdateInput;
  AssetUpdateLocalizationDataInput: AssetUpdateLocalizationDataInput;
  AssetUpdateLocalizationInput: AssetUpdateLocalizationInput;
  AssetUpdateLocalizationsInput: AssetUpdateLocalizationsInput;
  AssetUpdateManyInlineInput: AssetUpdateManyInlineInput;
  AssetUpdateManyInput: AssetUpdateManyInput;
  AssetUpdateManyLocalizationDataInput: AssetUpdateManyLocalizationDataInput;
  AssetUpdateManyLocalizationInput: AssetUpdateManyLocalizationInput;
  AssetUpdateManyLocalizationsInput: AssetUpdateManyLocalizationsInput;
  AssetUpdateManyWithNestedWhereInput: AssetUpdateManyWithNestedWhereInput;
  AssetUpdateOneInlineInput: AssetUpdateOneInlineInput;
  AssetUpdateWithNestedWhereUniqueInput: AssetUpdateWithNestedWhereUniqueInput;
  AssetUpsertInput: AssetUpsertInput;
  AssetUpsertLocalizationInput: AssetUpsertLocalizationInput;
  AssetUpsertWithNestedWhereUniqueInput: AssetUpsertWithNestedWhereUniqueInput;
  AssetVariant: ResolverTypeWrapper<AssetVariant>;
  AssetVariantConnectInput: AssetVariantConnectInput;
  AssetVariantConnection: ResolverTypeWrapper<AssetVariantConnection>;
  AssetVariantCreateInput: AssetVariantCreateInput;
  AssetVariantCreateManyInlineInput: AssetVariantCreateManyInlineInput;
  AssetVariantCreateOneInlineInput: AssetVariantCreateOneInlineInput;
  AssetVariantCreateWithPositionInput: AssetVariantCreateWithPositionInput;
  AssetVariantEdge: ResolverTypeWrapper<AssetVariantEdge>;
  AssetVariantManyWhereInput: AssetVariantManyWhereInput;
  AssetVariantOrderByInput: AssetVariantOrderByInput;
  AssetVariantParent: ResolverTypeWrapper<ResolversUnionTypes['AssetVariantParent']>;
  AssetVariantParentConnectInput: AssetVariantParentConnectInput;
  AssetVariantParentCreateInput: AssetVariantParentCreateInput;
  AssetVariantParentCreateManyInlineInput: AssetVariantParentCreateManyInlineInput;
  AssetVariantParentCreateOneInlineInput: AssetVariantParentCreateOneInlineInput;
  AssetVariantParentUpdateInput: AssetVariantParentUpdateInput;
  AssetVariantParentUpdateManyInlineInput: AssetVariantParentUpdateManyInlineInput;
  AssetVariantParentUpdateManyWithNestedWhereInput: AssetVariantParentUpdateManyWithNestedWhereInput;
  AssetVariantParentUpdateOneInlineInput: AssetVariantParentUpdateOneInlineInput;
  AssetVariantParentUpdateWithNestedWhereUniqueInput: AssetVariantParentUpdateWithNestedWhereUniqueInput;
  AssetVariantParentUpsertWithNestedWhereUniqueInput: AssetVariantParentUpsertWithNestedWhereUniqueInput;
  AssetVariantParentWhereInput: AssetVariantParentWhereInput;
  AssetVariantParentWhereUniqueInput: AssetVariantParentWhereUniqueInput;
  AssetVariantUpdateInput: AssetVariantUpdateInput;
  AssetVariantUpdateManyInlineInput: AssetVariantUpdateManyInlineInput;
  AssetVariantUpdateManyInput: AssetVariantUpdateManyInput;
  AssetVariantUpdateManyWithNestedWhereInput: AssetVariantUpdateManyWithNestedWhereInput;
  AssetVariantUpdateOneInlineInput: AssetVariantUpdateOneInlineInput;
  AssetVariantUpdateWithNestedWhereUniqueAndPositionInput: AssetVariantUpdateWithNestedWhereUniqueAndPositionInput;
  AssetVariantUpdateWithNestedWhereUniqueInput: AssetVariantUpdateWithNestedWhereUniqueInput;
  AssetVariantUpsertInput: AssetVariantUpsertInput;
  AssetVariantUpsertWithNestedWhereUniqueAndPositionInput: AssetVariantUpsertWithNestedWhereUniqueAndPositionInput;
  AssetVariantUpsertWithNestedWhereUniqueInput: AssetVariantUpsertWithNestedWhereUniqueInput;
  AssetVariantWhereInput: AssetVariantWhereInput;
  AssetVariantWhereUniqueInput: AssetVariantWhereUniqueInput;
  AssetWhereComparatorInput: AssetWhereComparatorInput;
  AssetWhereInput: AssetWhereInput;
  AssetWhereStageInput: AssetWhereStageInput;
  AssetWhereUniqueInput: AssetWhereUniqueInput;
  Attraction: ResolverTypeWrapper<
    Omit<Attraction, 'gallery'> & { gallery: Array<ResolversTypes['AttractionGallery']> }
  >;
  AttractionAlacarte: ResolverTypeWrapper<AttractionAlacarte>;
  AttractionAlacarteConnectInput: AttractionAlacarteConnectInput;
  AttractionAlacarteConnection: ResolverTypeWrapper<AttractionAlacarteConnection>;
  AttractionAlacarteCreateInput: AttractionAlacarteCreateInput;
  AttractionAlacarteCreateManyInlineInput: AttractionAlacarteCreateManyInlineInput;
  AttractionAlacarteCreateOneInlineInput: AttractionAlacarteCreateOneInlineInput;
  AttractionAlacarteEdge: ResolverTypeWrapper<AttractionAlacarteEdge>;
  AttractionAlacarteManyWhereInput: AttractionAlacarteManyWhereInput;
  AttractionAlacarteOrderByInput: AttractionAlacarteOrderByInput;
  AttractionAlacarteUpdateInput: AttractionAlacarteUpdateInput;
  AttractionAlacarteUpdateManyInlineInput: AttractionAlacarteUpdateManyInlineInput;
  AttractionAlacarteUpdateManyInput: AttractionAlacarteUpdateManyInput;
  AttractionAlacarteUpdateManyWithNestedWhereInput: AttractionAlacarteUpdateManyWithNestedWhereInput;
  AttractionAlacarteUpdateOneInlineInput: AttractionAlacarteUpdateOneInlineInput;
  AttractionAlacarteUpdateWithNestedWhereUniqueInput: AttractionAlacarteUpdateWithNestedWhereUniqueInput;
  AttractionAlacarteUpsertInput: AttractionAlacarteUpsertInput;
  AttractionAlacarteUpsertWithNestedWhereUniqueInput: AttractionAlacarteUpsertWithNestedWhereUniqueInput;
  AttractionAlacarteWhereComparatorInput: AttractionAlacarteWhereComparatorInput;
  AttractionAlacarteWhereInput: AttractionAlacarteWhereInput;
  AttractionAlacarteWhereStageInput: AttractionAlacarteWhereStageInput;
  AttractionAlacarteWhereUniqueInput: AttractionAlacarteWhereUniqueInput;
  AttractionAvailability: ResolverTypeWrapper<AttractionAvailability>;
  AttractionAvailabilityConnectInput: AttractionAvailabilityConnectInput;
  AttractionAvailabilityConnection: ResolverTypeWrapper<AttractionAvailabilityConnection>;
  AttractionAvailabilityCreateInput: AttractionAvailabilityCreateInput;
  AttractionAvailabilityCreateLocalizationDataInput: AttractionAvailabilityCreateLocalizationDataInput;
  AttractionAvailabilityCreateLocalizationInput: AttractionAvailabilityCreateLocalizationInput;
  AttractionAvailabilityCreateLocalizationsInput: AttractionAvailabilityCreateLocalizationsInput;
  AttractionAvailabilityCreateManyInlineInput: AttractionAvailabilityCreateManyInlineInput;
  AttractionAvailabilityCreateOneInlineInput: AttractionAvailabilityCreateOneInlineInput;
  AttractionAvailabilityEdge: ResolverTypeWrapper<AttractionAvailabilityEdge>;
  AttractionAvailabilityManyWhereInput: AttractionAvailabilityManyWhereInput;
  AttractionAvailabilityOrderByInput: AttractionAvailabilityOrderByInput;
  AttractionAvailabilityUpdateInput: AttractionAvailabilityUpdateInput;
  AttractionAvailabilityUpdateLocalizationDataInput: AttractionAvailabilityUpdateLocalizationDataInput;
  AttractionAvailabilityUpdateLocalizationInput: AttractionAvailabilityUpdateLocalizationInput;
  AttractionAvailabilityUpdateLocalizationsInput: AttractionAvailabilityUpdateLocalizationsInput;
  AttractionAvailabilityUpdateManyInlineInput: AttractionAvailabilityUpdateManyInlineInput;
  AttractionAvailabilityUpdateManyInput: AttractionAvailabilityUpdateManyInput;
  AttractionAvailabilityUpdateManyLocalizationDataInput: AttractionAvailabilityUpdateManyLocalizationDataInput;
  AttractionAvailabilityUpdateManyLocalizationInput: AttractionAvailabilityUpdateManyLocalizationInput;
  AttractionAvailabilityUpdateManyLocalizationsInput: AttractionAvailabilityUpdateManyLocalizationsInput;
  AttractionAvailabilityUpdateManyWithNestedWhereInput: AttractionAvailabilityUpdateManyWithNestedWhereInput;
  AttractionAvailabilityUpdateOneInlineInput: AttractionAvailabilityUpdateOneInlineInput;
  AttractionAvailabilityUpdateWithNestedWhereUniqueInput: AttractionAvailabilityUpdateWithNestedWhereUniqueInput;
  AttractionAvailabilityUpsertInput: AttractionAvailabilityUpsertInput;
  AttractionAvailabilityUpsertLocalizationInput: AttractionAvailabilityUpsertLocalizationInput;
  AttractionAvailabilityUpsertWithNestedWhereUniqueInput: AttractionAvailabilityUpsertWithNestedWhereUniqueInput;
  AttractionAvailabilityWhereComparatorInput: AttractionAvailabilityWhereComparatorInput;
  AttractionAvailabilityWhereInput: AttractionAvailabilityWhereInput;
  AttractionAvailabilityWhereStageInput: AttractionAvailabilityWhereStageInput;
  AttractionAvailabilityWhereUniqueInput: AttractionAvailabilityWhereUniqueInput;
  AttractionConnectInput: AttractionConnectInput;
  AttractionConnection: ResolverTypeWrapper<AttractionConnection>;
  AttractionCreateInput: AttractionCreateInput;
  AttractionCreateLocalizationDataInput: AttractionCreateLocalizationDataInput;
  AttractionCreateLocalizationInput: AttractionCreateLocalizationInput;
  AttractionCreateLocalizationsInput: AttractionCreateLocalizationsInput;
  AttractionCreateManyInlineInput: AttractionCreateManyInlineInput;
  AttractionCreateOneInlineInput: AttractionCreateOneInlineInput;
  AttractionEdge: ResolverTypeWrapper<AttractionEdge>;
  AttractionGallery: ResolverTypeWrapper<ResolversUnionTypes['AttractionGallery']>;
  AttractionGalleryConnectInput: AttractionGalleryConnectInput;
  AttractionGalleryCreateInput: AttractionGalleryCreateInput;
  AttractionGalleryCreateManyInlineInput: AttractionGalleryCreateManyInlineInput;
  AttractionGalleryCreateOneInlineInput: AttractionGalleryCreateOneInlineInput;
  AttractionGalleryUpdateInput: AttractionGalleryUpdateInput;
  AttractionGalleryUpdateManyInlineInput: AttractionGalleryUpdateManyInlineInput;
  AttractionGalleryUpdateManyWithNestedWhereInput: AttractionGalleryUpdateManyWithNestedWhereInput;
  AttractionGalleryUpdateOneInlineInput: AttractionGalleryUpdateOneInlineInput;
  AttractionGalleryUpdateWithNestedWhereUniqueInput: AttractionGalleryUpdateWithNestedWhereUniqueInput;
  AttractionGalleryUpsertWithNestedWhereUniqueInput: AttractionGalleryUpsertWithNestedWhereUniqueInput;
  AttractionGalleryWhereInput: AttractionGalleryWhereInput;
  AttractionGalleryWhereUniqueInput: AttractionGalleryWhereUniqueInput;
  AttractionGroup: ResolverTypeWrapper<AttractionGroup>;
  AttractionGroupConnectInput: AttractionGroupConnectInput;
  AttractionGroupConnection: ResolverTypeWrapper<AttractionGroupConnection>;
  AttractionGroupCreateInput: AttractionGroupCreateInput;
  AttractionGroupCreateManyInlineInput: AttractionGroupCreateManyInlineInput;
  AttractionGroupCreateOneInlineInput: AttractionGroupCreateOneInlineInput;
  AttractionGroupCreateWithPositionInput: AttractionGroupCreateWithPositionInput;
  AttractionGroupEdge: ResolverTypeWrapper<AttractionGroupEdge>;
  AttractionGroupManyWhereInput: AttractionGroupManyWhereInput;
  AttractionGroupOrderByInput: AttractionGroupOrderByInput;
  AttractionGroupParent: ResolverTypeWrapper<ResolversUnionTypes['AttractionGroupParent']>;
  AttractionGroupParentConnectInput: AttractionGroupParentConnectInput;
  AttractionGroupParentCreateInput: AttractionGroupParentCreateInput;
  AttractionGroupParentCreateManyInlineInput: AttractionGroupParentCreateManyInlineInput;
  AttractionGroupParentCreateOneInlineInput: AttractionGroupParentCreateOneInlineInput;
  AttractionGroupParentUpdateInput: AttractionGroupParentUpdateInput;
  AttractionGroupParentUpdateManyInlineInput: AttractionGroupParentUpdateManyInlineInput;
  AttractionGroupParentUpdateManyWithNestedWhereInput: AttractionGroupParentUpdateManyWithNestedWhereInput;
  AttractionGroupParentUpdateOneInlineInput: AttractionGroupParentUpdateOneInlineInput;
  AttractionGroupParentUpdateWithNestedWhereUniqueInput: AttractionGroupParentUpdateWithNestedWhereUniqueInput;
  AttractionGroupParentUpsertWithNestedWhereUniqueInput: AttractionGroupParentUpsertWithNestedWhereUniqueInput;
  AttractionGroupParentWhereInput: AttractionGroupParentWhereInput;
  AttractionGroupParentWhereUniqueInput: AttractionGroupParentWhereUniqueInput;
  AttractionGroupType: AttractionGroupType;
  AttractionGroupUpdateInput: AttractionGroupUpdateInput;
  AttractionGroupUpdateManyInlineInput: AttractionGroupUpdateManyInlineInput;
  AttractionGroupUpdateManyInput: AttractionGroupUpdateManyInput;
  AttractionGroupUpdateManyWithNestedWhereInput: AttractionGroupUpdateManyWithNestedWhereInput;
  AttractionGroupUpdateOneInlineInput: AttractionGroupUpdateOneInlineInput;
  AttractionGroupUpdateWithNestedWhereUniqueAndPositionInput: AttractionGroupUpdateWithNestedWhereUniqueAndPositionInput;
  AttractionGroupUpdateWithNestedWhereUniqueInput: AttractionGroupUpdateWithNestedWhereUniqueInput;
  AttractionGroupUpsertInput: AttractionGroupUpsertInput;
  AttractionGroupUpsertWithNestedWhereUniqueAndPositionInput: AttractionGroupUpsertWithNestedWhereUniqueAndPositionInput;
  AttractionGroupUpsertWithNestedWhereUniqueInput: AttractionGroupUpsertWithNestedWhereUniqueInput;
  AttractionGroupWhereInput: AttractionGroupWhereInput;
  AttractionGroupWhereUniqueInput: AttractionGroupWhereUniqueInput;
  AttractionLocation: ResolverTypeWrapper<AttractionLocation>;
  AttractionLocationConnectInput: AttractionLocationConnectInput;
  AttractionLocationConnection: ResolverTypeWrapper<AttractionLocationConnection>;
  AttractionLocationCreateInput: AttractionLocationCreateInput;
  AttractionLocationCreateLocalizationDataInput: AttractionLocationCreateLocalizationDataInput;
  AttractionLocationCreateLocalizationInput: AttractionLocationCreateLocalizationInput;
  AttractionLocationCreateLocalizationsInput: AttractionLocationCreateLocalizationsInput;
  AttractionLocationCreateManyInlineInput: AttractionLocationCreateManyInlineInput;
  AttractionLocationCreateOneInlineInput: AttractionLocationCreateOneInlineInput;
  AttractionLocationEdge: ResolverTypeWrapper<AttractionLocationEdge>;
  AttractionLocationManyWhereInput: AttractionLocationManyWhereInput;
  AttractionLocationOrderByInput: AttractionLocationOrderByInput;
  AttractionLocationUpdateInput: AttractionLocationUpdateInput;
  AttractionLocationUpdateLocalizationDataInput: AttractionLocationUpdateLocalizationDataInput;
  AttractionLocationUpdateLocalizationInput: AttractionLocationUpdateLocalizationInput;
  AttractionLocationUpdateLocalizationsInput: AttractionLocationUpdateLocalizationsInput;
  AttractionLocationUpdateManyInlineInput: AttractionLocationUpdateManyInlineInput;
  AttractionLocationUpdateManyInput: AttractionLocationUpdateManyInput;
  AttractionLocationUpdateManyLocalizationDataInput: AttractionLocationUpdateManyLocalizationDataInput;
  AttractionLocationUpdateManyLocalizationInput: AttractionLocationUpdateManyLocalizationInput;
  AttractionLocationUpdateManyLocalizationsInput: AttractionLocationUpdateManyLocalizationsInput;
  AttractionLocationUpdateManyWithNestedWhereInput: AttractionLocationUpdateManyWithNestedWhereInput;
  AttractionLocationUpdateOneInlineInput: AttractionLocationUpdateOneInlineInput;
  AttractionLocationUpdateWithNestedWhereUniqueInput: AttractionLocationUpdateWithNestedWhereUniqueInput;
  AttractionLocationUpsertInput: AttractionLocationUpsertInput;
  AttractionLocationUpsertLocalizationInput: AttractionLocationUpsertLocalizationInput;
  AttractionLocationUpsertWithNestedWhereUniqueInput: AttractionLocationUpsertWithNestedWhereUniqueInput;
  AttractionLocationWhereComparatorInput: AttractionLocationWhereComparatorInput;
  AttractionLocationWhereInput: AttractionLocationWhereInput;
  AttractionLocationWhereStageInput: AttractionLocationWhereStageInput;
  AttractionLocationWhereUniqueInput: AttractionLocationWhereUniqueInput;
  AttractionManyWhereInput: AttractionManyWhereInput;
  AttractionOffer: ResolverTypeWrapper<AttractionOffer>;
  AttractionOfferConnectInput: AttractionOfferConnectInput;
  AttractionOfferConnection: ResolverTypeWrapper<AttractionOfferConnection>;
  AttractionOfferCreateInput: AttractionOfferCreateInput;
  AttractionOfferCreateLocalizationDataInput: AttractionOfferCreateLocalizationDataInput;
  AttractionOfferCreateLocalizationInput: AttractionOfferCreateLocalizationInput;
  AttractionOfferCreateLocalizationsInput: AttractionOfferCreateLocalizationsInput;
  AttractionOfferCreateManyInlineInput: AttractionOfferCreateManyInlineInput;
  AttractionOfferCreateOneInlineInput: AttractionOfferCreateOneInlineInput;
  AttractionOfferEdge: ResolverTypeWrapper<AttractionOfferEdge>;
  AttractionOfferManyWhereInput: AttractionOfferManyWhereInput;
  AttractionOfferOrderByInput: AttractionOfferOrderByInput;
  AttractionOfferType: AttractionOfferType;
  AttractionOfferUpdateInput: AttractionOfferUpdateInput;
  AttractionOfferUpdateLocalizationDataInput: AttractionOfferUpdateLocalizationDataInput;
  AttractionOfferUpdateLocalizationInput: AttractionOfferUpdateLocalizationInput;
  AttractionOfferUpdateLocalizationsInput: AttractionOfferUpdateLocalizationsInput;
  AttractionOfferUpdateManyInlineInput: AttractionOfferUpdateManyInlineInput;
  AttractionOfferUpdateManyInput: AttractionOfferUpdateManyInput;
  AttractionOfferUpdateManyLocalizationDataInput: AttractionOfferUpdateManyLocalizationDataInput;
  AttractionOfferUpdateManyLocalizationInput: AttractionOfferUpdateManyLocalizationInput;
  AttractionOfferUpdateManyLocalizationsInput: AttractionOfferUpdateManyLocalizationsInput;
  AttractionOfferUpdateManyWithNestedWhereInput: AttractionOfferUpdateManyWithNestedWhereInput;
  AttractionOfferUpdateOneInlineInput: AttractionOfferUpdateOneInlineInput;
  AttractionOfferUpdateWithNestedWhereUniqueInput: AttractionOfferUpdateWithNestedWhereUniqueInput;
  AttractionOfferUpsertInput: AttractionOfferUpsertInput;
  AttractionOfferUpsertLocalizationInput: AttractionOfferUpsertLocalizationInput;
  AttractionOfferUpsertWithNestedWhereUniqueInput: AttractionOfferUpsertWithNestedWhereUniqueInput;
  AttractionOfferWhereComparatorInput: AttractionOfferWhereComparatorInput;
  AttractionOfferWhereInput: AttractionOfferWhereInput;
  AttractionOfferWhereStageInput: AttractionOfferWhereStageInput;
  AttractionOfferWhereUniqueInput: AttractionOfferWhereUniqueInput;
  AttractionOrderByInput: AttractionOrderByInput;
  AttractionPrice: ResolverTypeWrapper<AttractionPrice>;
  AttractionPriceConnectInput: AttractionPriceConnectInput;
  AttractionPriceConnection: ResolverTypeWrapper<AttractionPriceConnection>;
  AttractionPriceCreateInput: AttractionPriceCreateInput;
  AttractionPriceCreateLocalizationDataInput: AttractionPriceCreateLocalizationDataInput;
  AttractionPriceCreateLocalizationInput: AttractionPriceCreateLocalizationInput;
  AttractionPriceCreateLocalizationsInput: AttractionPriceCreateLocalizationsInput;
  AttractionPriceCreateManyInlineInput: AttractionPriceCreateManyInlineInput;
  AttractionPriceCreateOneInlineInput: AttractionPriceCreateOneInlineInput;
  AttractionPriceEdge: ResolverTypeWrapper<AttractionPriceEdge>;
  AttractionPriceManyWhereInput: AttractionPriceManyWhereInput;
  AttractionPriceOrderByInput: AttractionPriceOrderByInput;
  AttractionPriceUpdateInput: AttractionPriceUpdateInput;
  AttractionPriceUpdateLocalizationDataInput: AttractionPriceUpdateLocalizationDataInput;
  AttractionPriceUpdateLocalizationInput: AttractionPriceUpdateLocalizationInput;
  AttractionPriceUpdateLocalizationsInput: AttractionPriceUpdateLocalizationsInput;
  AttractionPriceUpdateManyInlineInput: AttractionPriceUpdateManyInlineInput;
  AttractionPriceUpdateManyInput: AttractionPriceUpdateManyInput;
  AttractionPriceUpdateManyLocalizationDataInput: AttractionPriceUpdateManyLocalizationDataInput;
  AttractionPriceUpdateManyLocalizationInput: AttractionPriceUpdateManyLocalizationInput;
  AttractionPriceUpdateManyLocalizationsInput: AttractionPriceUpdateManyLocalizationsInput;
  AttractionPriceUpdateManyWithNestedWhereInput: AttractionPriceUpdateManyWithNestedWhereInput;
  AttractionPriceUpdateOneInlineInput: AttractionPriceUpdateOneInlineInput;
  AttractionPriceUpdateWithNestedWhereUniqueInput: AttractionPriceUpdateWithNestedWhereUniqueInput;
  AttractionPriceUpsertInput: AttractionPriceUpsertInput;
  AttractionPriceUpsertLocalizationInput: AttractionPriceUpsertLocalizationInput;
  AttractionPriceUpsertWithNestedWhereUniqueInput: AttractionPriceUpsertWithNestedWhereUniqueInput;
  AttractionPriceWhereComparatorInput: AttractionPriceWhereComparatorInput;
  AttractionPriceWhereInput: AttractionPriceWhereInput;
  AttractionPriceWhereStageInput: AttractionPriceWhereStageInput;
  AttractionPriceWhereUniqueInput: AttractionPriceWhereUniqueInput;
  AttractionReservation: ResolverTypeWrapper<AttractionReservation>;
  AttractionReservationConnectInput: AttractionReservationConnectInput;
  AttractionReservationConnection: ResolverTypeWrapper<AttractionReservationConnection>;
  AttractionReservationCreateInput: AttractionReservationCreateInput;
  AttractionReservationCreateManyInlineInput: AttractionReservationCreateManyInlineInput;
  AttractionReservationCreateOneInlineInput: AttractionReservationCreateOneInlineInput;
  AttractionReservationEdge: ResolverTypeWrapper<AttractionReservationEdge>;
  AttractionReservationManyWhereInput: AttractionReservationManyWhereInput;
  AttractionReservationOrderByInput: AttractionReservationOrderByInput;
  AttractionReservationUpdateInput: AttractionReservationUpdateInput;
  AttractionReservationUpdateManyInlineInput: AttractionReservationUpdateManyInlineInput;
  AttractionReservationUpdateManyInput: AttractionReservationUpdateManyInput;
  AttractionReservationUpdateManyWithNestedWhereInput: AttractionReservationUpdateManyWithNestedWhereInput;
  AttractionReservationUpdateOneInlineInput: AttractionReservationUpdateOneInlineInput;
  AttractionReservationUpdateWithNestedWhereUniqueInput: AttractionReservationUpdateWithNestedWhereUniqueInput;
  AttractionReservationUpsertInput: AttractionReservationUpsertInput;
  AttractionReservationUpsertWithNestedWhereUniqueInput: AttractionReservationUpsertWithNestedWhereUniqueInput;
  AttractionReservationWhereComparatorInput: AttractionReservationWhereComparatorInput;
  AttractionReservationWhereInput: AttractionReservationWhereInput;
  AttractionReservationWhereStageInput: AttractionReservationWhereStageInput;
  AttractionReservationWhereUniqueInput: AttractionReservationWhereUniqueInput;
  AttractionUpdateInput: AttractionUpdateInput;
  AttractionUpdateLocalizationDataInput: AttractionUpdateLocalizationDataInput;
  AttractionUpdateLocalizationInput: AttractionUpdateLocalizationInput;
  AttractionUpdateLocalizationsInput: AttractionUpdateLocalizationsInput;
  AttractionUpdateManyInlineInput: AttractionUpdateManyInlineInput;
  AttractionUpdateManyInput: AttractionUpdateManyInput;
  AttractionUpdateManyLocalizationDataInput: AttractionUpdateManyLocalizationDataInput;
  AttractionUpdateManyLocalizationInput: AttractionUpdateManyLocalizationInput;
  AttractionUpdateManyLocalizationsInput: AttractionUpdateManyLocalizationsInput;
  AttractionUpdateManyWithNestedWhereInput: AttractionUpdateManyWithNestedWhereInput;
  AttractionUpdateOneInlineInput: AttractionUpdateOneInlineInput;
  AttractionUpdateWithNestedWhereUniqueInput: AttractionUpdateWithNestedWhereUniqueInput;
  AttractionUpsertInput: AttractionUpsertInput;
  AttractionUpsertLocalizationInput: AttractionUpsertLocalizationInput;
  AttractionUpsertWithNestedWhereUniqueInput: AttractionUpsertWithNestedWhereUniqueInput;
  AttractionVariant: ResolverTypeWrapper<
    Omit<AttractionVariant, 'gallery'> & {
      gallery: Array<ResolversTypes['AttractionVariantGallery']>;
    }
  >;
  AttractionVariantConnectInput: AttractionVariantConnectInput;
  AttractionVariantConnection: ResolverTypeWrapper<AttractionVariantConnection>;
  AttractionVariantCreateInput: AttractionVariantCreateInput;
  AttractionVariantCreateLocalizationDataInput: AttractionVariantCreateLocalizationDataInput;
  AttractionVariantCreateLocalizationInput: AttractionVariantCreateLocalizationInput;
  AttractionVariantCreateLocalizationsInput: AttractionVariantCreateLocalizationsInput;
  AttractionVariantCreateManyInlineInput: AttractionVariantCreateManyInlineInput;
  AttractionVariantCreateOneInlineInput: AttractionVariantCreateOneInlineInput;
  AttractionVariantEdge: ResolverTypeWrapper<AttractionVariantEdge>;
  AttractionVariantGallery: ResolverTypeWrapper<ResolversUnionTypes['AttractionVariantGallery']>;
  AttractionVariantGalleryConnectInput: AttractionVariantGalleryConnectInput;
  AttractionVariantGalleryCreateInput: AttractionVariantGalleryCreateInput;
  AttractionVariantGalleryCreateManyInlineInput: AttractionVariantGalleryCreateManyInlineInput;
  AttractionVariantGalleryCreateOneInlineInput: AttractionVariantGalleryCreateOneInlineInput;
  AttractionVariantGalleryUpdateInput: AttractionVariantGalleryUpdateInput;
  AttractionVariantGalleryUpdateManyInlineInput: AttractionVariantGalleryUpdateManyInlineInput;
  AttractionVariantGalleryUpdateManyWithNestedWhereInput: AttractionVariantGalleryUpdateManyWithNestedWhereInput;
  AttractionVariantGalleryUpdateOneInlineInput: AttractionVariantGalleryUpdateOneInlineInput;
  AttractionVariantGalleryUpdateWithNestedWhereUniqueInput: AttractionVariantGalleryUpdateWithNestedWhereUniqueInput;
  AttractionVariantGalleryUpsertWithNestedWhereUniqueInput: AttractionVariantGalleryUpsertWithNestedWhereUniqueInput;
  AttractionVariantGalleryWhereInput: AttractionVariantGalleryWhereInput;
  AttractionVariantGalleryWhereUniqueInput: AttractionVariantGalleryWhereUniqueInput;
  AttractionVariantManyWhereInput: AttractionVariantManyWhereInput;
  AttractionVariantOrderByInput: AttractionVariantOrderByInput;
  AttractionVariantUpdateInput: AttractionVariantUpdateInput;
  AttractionVariantUpdateLocalizationDataInput: AttractionVariantUpdateLocalizationDataInput;
  AttractionVariantUpdateLocalizationInput: AttractionVariantUpdateLocalizationInput;
  AttractionVariantUpdateLocalizationsInput: AttractionVariantUpdateLocalizationsInput;
  AttractionVariantUpdateManyInlineInput: AttractionVariantUpdateManyInlineInput;
  AttractionVariantUpdateManyInput: AttractionVariantUpdateManyInput;
  AttractionVariantUpdateManyLocalizationDataInput: AttractionVariantUpdateManyLocalizationDataInput;
  AttractionVariantUpdateManyLocalizationInput: AttractionVariantUpdateManyLocalizationInput;
  AttractionVariantUpdateManyLocalizationsInput: AttractionVariantUpdateManyLocalizationsInput;
  AttractionVariantUpdateManyWithNestedWhereInput: AttractionVariantUpdateManyWithNestedWhereInput;
  AttractionVariantUpdateOneInlineInput: AttractionVariantUpdateOneInlineInput;
  AttractionVariantUpdateWithNestedWhereUniqueInput: AttractionVariantUpdateWithNestedWhereUniqueInput;
  AttractionVariantUpsertInput: AttractionVariantUpsertInput;
  AttractionVariantUpsertLocalizationInput: AttractionVariantUpsertLocalizationInput;
  AttractionVariantUpsertWithNestedWhereUniqueInput: AttractionVariantUpsertWithNestedWhereUniqueInput;
  AttractionVariantWhereComparatorInput: AttractionVariantWhereComparatorInput;
  AttractionVariantWhereInput: AttractionVariantWhereInput;
  AttractionVariantWhereStageInput: AttractionVariantWhereStageInput;
  AttractionVariantWhereUniqueInput: AttractionVariantWhereUniqueInput;
  AttractionWhereComparatorInput: AttractionWhereComparatorInput;
  AttractionWhereInput: AttractionWhereInput;
  AttractionWhereStageInput: AttractionWhereStageInput;
  AttractionWhereUniqueInput: AttractionWhereUniqueInput;
  Author: ResolverTypeWrapper<Author>;
  AuthorConnectInput: AuthorConnectInput;
  AuthorConnection: ResolverTypeWrapper<AuthorConnection>;
  AuthorCreateInput: AuthorCreateInput;
  AuthorCreateManyInlineInput: AuthorCreateManyInlineInput;
  AuthorCreateOneInlineInput: AuthorCreateOneInlineInput;
  AuthorEdge: ResolverTypeWrapper<AuthorEdge>;
  AuthorManyWhereInput: AuthorManyWhereInput;
  AuthorOrderByInput: AuthorOrderByInput;
  AuthorUpdateInput: AuthorUpdateInput;
  AuthorUpdateManyInlineInput: AuthorUpdateManyInlineInput;
  AuthorUpdateManyInput: AuthorUpdateManyInput;
  AuthorUpdateManyWithNestedWhereInput: AuthorUpdateManyWithNestedWhereInput;
  AuthorUpdateOneInlineInput: AuthorUpdateOneInlineInput;
  AuthorUpdateWithNestedWhereUniqueInput: AuthorUpdateWithNestedWhereUniqueInput;
  AuthorUpsertInput: AuthorUpsertInput;
  AuthorUpsertWithNestedWhereUniqueInput: AuthorUpsertWithNestedWhereUniqueInput;
  AuthorWhereComparatorInput: AuthorWhereComparatorInput;
  AuthorWhereInput: AuthorWhereInput;
  AuthorWhereStageInput: AuthorWhereStageInput;
  AuthorWhereUniqueInput: AuthorWhereUniqueInput;
  BatchPayload: ResolverTypeWrapper<BatchPayload>;
  Block: ResolverTypeWrapper<Block>;
  BlockConnectInput: BlockConnectInput;
  BlockConnection: ResolverTypeWrapper<BlockConnection>;
  BlockCreateInput: BlockCreateInput;
  BlockCreateManyInlineInput: BlockCreateManyInlineInput;
  BlockCreateOneInlineInput: BlockCreateOneInlineInput;
  BlockEdge: ResolverTypeWrapper<BlockEdge>;
  BlockGroup: ResolverTypeWrapper<BlockGroup>;
  BlockGroupConnectInput: BlockGroupConnectInput;
  BlockGroupConnection: ResolverTypeWrapper<BlockGroupConnection>;
  BlockGroupCreateInput: BlockGroupCreateInput;
  BlockGroupCreateManyInlineInput: BlockGroupCreateManyInlineInput;
  BlockGroupCreateOneInlineInput: BlockGroupCreateOneInlineInput;
  BlockGroupEdge: ResolverTypeWrapper<BlockGroupEdge>;
  BlockGroupManyWhereInput: BlockGroupManyWhereInput;
  BlockGroupOrderByInput: BlockGroupOrderByInput;
  BlockGroupUpdateInput: BlockGroupUpdateInput;
  BlockGroupUpdateManyInlineInput: BlockGroupUpdateManyInlineInput;
  BlockGroupUpdateManyInput: BlockGroupUpdateManyInput;
  BlockGroupUpdateManyWithNestedWhereInput: BlockGroupUpdateManyWithNestedWhereInput;
  BlockGroupUpdateOneInlineInput: BlockGroupUpdateOneInlineInput;
  BlockGroupUpdateWithNestedWhereUniqueInput: BlockGroupUpdateWithNestedWhereUniqueInput;
  BlockGroupUpsertInput: BlockGroupUpsertInput;
  BlockGroupUpsertWithNestedWhereUniqueInput: BlockGroupUpsertWithNestedWhereUniqueInput;
  BlockGroupWhereComparatorInput: BlockGroupWhereComparatorInput;
  BlockGroupWhereInput: BlockGroupWhereInput;
  BlockGroupWhereStageInput: BlockGroupWhereStageInput;
  BlockGroupWhereUniqueInput: BlockGroupWhereUniqueInput;
  BlockManyWhereInput: BlockManyWhereInput;
  BlockOrderByInput: BlockOrderByInput;
  BlockUI: ResolverTypeWrapper<BlockUi>;
  BlockUIConnectInput: BlockUiConnectInput;
  BlockUIConnection: ResolverTypeWrapper<BlockUiConnection>;
  BlockUICreateInput: BlockUiCreateInput;
  BlockUICreateManyInlineInput: BlockUiCreateManyInlineInput;
  BlockUICreateOneInlineInput: BlockUiCreateOneInlineInput;
  BlockUICreateWithPositionInput: BlockUiCreateWithPositionInput;
  BlockUIEdge: ResolverTypeWrapper<BlockUiEdge>;
  BlockUIManyWhereInput: BlockUiManyWhereInput;
  BlockUIOrderByInput: BlockUiOrderByInput;
  BlockUIParent: ResolverTypeWrapper<ResolversUnionTypes['BlockUIParent']>;
  BlockUIParentConnectInput: BlockUiParentConnectInput;
  BlockUIParentCreateInput: BlockUiParentCreateInput;
  BlockUIParentCreateManyInlineInput: BlockUiParentCreateManyInlineInput;
  BlockUIParentCreateOneInlineInput: BlockUiParentCreateOneInlineInput;
  BlockUIParentUpdateInput: BlockUiParentUpdateInput;
  BlockUIParentUpdateManyInlineInput: BlockUiParentUpdateManyInlineInput;
  BlockUIParentUpdateManyWithNestedWhereInput: BlockUiParentUpdateManyWithNestedWhereInput;
  BlockUIParentUpdateOneInlineInput: BlockUiParentUpdateOneInlineInput;
  BlockUIParentUpdateWithNestedWhereUniqueInput: BlockUiParentUpdateWithNestedWhereUniqueInput;
  BlockUIParentUpsertWithNestedWhereUniqueInput: BlockUiParentUpsertWithNestedWhereUniqueInput;
  BlockUIParentWhereInput: BlockUiParentWhereInput;
  BlockUIParentWhereUniqueInput: BlockUiParentWhereUniqueInput;
  BlockUIUpdateInput: BlockUiUpdateInput;
  BlockUIUpdateManyInlineInput: BlockUiUpdateManyInlineInput;
  BlockUIUpdateManyInput: BlockUiUpdateManyInput;
  BlockUIUpdateManyWithNestedWhereInput: BlockUiUpdateManyWithNestedWhereInput;
  BlockUIUpdateOneInlineInput: BlockUiUpdateOneInlineInput;
  BlockUIUpdateWithNestedWhereUniqueAndPositionInput: BlockUiUpdateWithNestedWhereUniqueAndPositionInput;
  BlockUIUpdateWithNestedWhereUniqueInput: BlockUiUpdateWithNestedWhereUniqueInput;
  BlockUIUpsertInput: BlockUiUpsertInput;
  BlockUIUpsertWithNestedWhereUniqueAndPositionInput: BlockUiUpsertWithNestedWhereUniqueAndPositionInput;
  BlockUIUpsertWithNestedWhereUniqueInput: BlockUiUpsertWithNestedWhereUniqueInput;
  BlockUIWhereInput: BlockUiWhereInput;
  BlockUIWhereUniqueInput: BlockUiWhereUniqueInput;
  BlockUpdateInput: BlockUpdateInput;
  BlockUpdateManyInlineInput: BlockUpdateManyInlineInput;
  BlockUpdateManyInput: BlockUpdateManyInput;
  BlockUpdateManyWithNestedWhereInput: BlockUpdateManyWithNestedWhereInput;
  BlockUpdateOneInlineInput: BlockUpdateOneInlineInput;
  BlockUpdateWithNestedWhereUniqueInput: BlockUpdateWithNestedWhereUniqueInput;
  BlockUpsertInput: BlockUpsertInput;
  BlockUpsertWithNestedWhereUniqueInput: BlockUpsertWithNestedWhereUniqueInput;
  BlockWhereComparatorInput: BlockWhereComparatorInput;
  BlockWhereInput: BlockWhereInput;
  BlockWhereStageInput: BlockWhereStageInput;
  BlockWhereUniqueInput: BlockWhereUniqueInput;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']>;
  Color: ResolverTypeWrapper<Color>;
  ColorInput: ColorInput;
  ComponentType: ComponentType;
  ConnectPositionInput: ConnectPositionInput;
  CrossPromoBanner: ResolverTypeWrapper<CrossPromoBanner>;
  CrossPromoBannerConnectInput: CrossPromoBannerConnectInput;
  CrossPromoBannerConnection: ResolverTypeWrapper<CrossPromoBannerConnection>;
  CrossPromoBannerCreateInput: CrossPromoBannerCreateInput;
  CrossPromoBannerCreateManyInlineInput: CrossPromoBannerCreateManyInlineInput;
  CrossPromoBannerCreateOneInlineInput: CrossPromoBannerCreateOneInlineInput;
  CrossPromoBannerCreateWithPositionInput: CrossPromoBannerCreateWithPositionInput;
  CrossPromoBannerEdge: ResolverTypeWrapper<CrossPromoBannerEdge>;
  CrossPromoBannerManyWhereInput: CrossPromoBannerManyWhereInput;
  CrossPromoBannerOrderByInput: CrossPromoBannerOrderByInput;
  CrossPromoBannerParent: ResolverTypeWrapper<ResolversUnionTypes['CrossPromoBannerParent']>;
  CrossPromoBannerParentConnectInput: CrossPromoBannerParentConnectInput;
  CrossPromoBannerParentCreateInput: CrossPromoBannerParentCreateInput;
  CrossPromoBannerParentCreateManyInlineInput: CrossPromoBannerParentCreateManyInlineInput;
  CrossPromoBannerParentCreateOneInlineInput: CrossPromoBannerParentCreateOneInlineInput;
  CrossPromoBannerParentUpdateInput: CrossPromoBannerParentUpdateInput;
  CrossPromoBannerParentUpdateManyInlineInput: CrossPromoBannerParentUpdateManyInlineInput;
  CrossPromoBannerParentUpdateManyWithNestedWhereInput: CrossPromoBannerParentUpdateManyWithNestedWhereInput;
  CrossPromoBannerParentUpdateOneInlineInput: CrossPromoBannerParentUpdateOneInlineInput;
  CrossPromoBannerParentUpdateWithNestedWhereUniqueInput: CrossPromoBannerParentUpdateWithNestedWhereUniqueInput;
  CrossPromoBannerParentUpsertWithNestedWhereUniqueInput: CrossPromoBannerParentUpsertWithNestedWhereUniqueInput;
  CrossPromoBannerParentWhereInput: CrossPromoBannerParentWhereInput;
  CrossPromoBannerParentWhereUniqueInput: CrossPromoBannerParentWhereUniqueInput;
  CrossPromoBannerUpdateInput: CrossPromoBannerUpdateInput;
  CrossPromoBannerUpdateManyInlineInput: CrossPromoBannerUpdateManyInlineInput;
  CrossPromoBannerUpdateManyInput: CrossPromoBannerUpdateManyInput;
  CrossPromoBannerUpdateManyWithNestedWhereInput: CrossPromoBannerUpdateManyWithNestedWhereInput;
  CrossPromoBannerUpdateOneInlineInput: CrossPromoBannerUpdateOneInlineInput;
  CrossPromoBannerUpdateWithNestedWhereUniqueAndPositionInput: CrossPromoBannerUpdateWithNestedWhereUniqueAndPositionInput;
  CrossPromoBannerUpdateWithNestedWhereUniqueInput: CrossPromoBannerUpdateWithNestedWhereUniqueInput;
  CrossPromoBannerUpsertInput: CrossPromoBannerUpsertInput;
  CrossPromoBannerUpsertWithNestedWhereUniqueAndPositionInput: CrossPromoBannerUpsertWithNestedWhereUniqueAndPositionInput;
  CrossPromoBannerUpsertWithNestedWhereUniqueInput: CrossPromoBannerUpsertWithNestedWhereUniqueInput;
  CrossPromoBannerWhereInput: CrossPromoBannerWhereInput;
  CrossPromoBannerWhereUniqueInput: CrossPromoBannerWhereUniqueInput;
  Currency: Currency;
  Date: ResolverTypeWrapper<Scalars['Date']>;
  DateRange: ResolverTypeWrapper<DateRange>;
  DateRangeConnectInput: DateRangeConnectInput;
  DateRangeConnection: ResolverTypeWrapper<DateRangeConnection>;
  DateRangeCreateInput: DateRangeCreateInput;
  DateRangeCreateManyInlineInput: DateRangeCreateManyInlineInput;
  DateRangeCreateOneInlineInput: DateRangeCreateOneInlineInput;
  DateRangeCreateWithPositionInput: DateRangeCreateWithPositionInput;
  DateRangeEdge: ResolverTypeWrapper<DateRangeEdge>;
  DateRangeManyWhereInput: DateRangeManyWhereInput;
  DateRangeOrderByInput: DateRangeOrderByInput;
  DateRangeParent: ResolverTypeWrapper<ResolversUnionTypes['DateRangeParent']>;
  DateRangeParentConnectInput: DateRangeParentConnectInput;
  DateRangeParentCreateInput: DateRangeParentCreateInput;
  DateRangeParentCreateManyInlineInput: DateRangeParentCreateManyInlineInput;
  DateRangeParentCreateOneInlineInput: DateRangeParentCreateOneInlineInput;
  DateRangeParentCreateWithPositionInput: DateRangeParentCreateWithPositionInput;
  DateRangeParentUpdateInput: DateRangeParentUpdateInput;
  DateRangeParentUpdateManyInlineInput: DateRangeParentUpdateManyInlineInput;
  DateRangeParentUpdateManyWithNestedWhereInput: DateRangeParentUpdateManyWithNestedWhereInput;
  DateRangeParentUpdateOneInlineInput: DateRangeParentUpdateOneInlineInput;
  DateRangeParentUpdateWithNestedWhereUniqueAndPositionInput: DateRangeParentUpdateWithNestedWhereUniqueAndPositionInput;
  DateRangeParentUpdateWithNestedWhereUniqueInput: DateRangeParentUpdateWithNestedWhereUniqueInput;
  DateRangeParentUpsertWithNestedWhereUniqueAndPositionInput: DateRangeParentUpsertWithNestedWhereUniqueAndPositionInput;
  DateRangeParentUpsertWithNestedWhereUniqueInput: DateRangeParentUpsertWithNestedWhereUniqueInput;
  DateRangeParentWhereInput: DateRangeParentWhereInput;
  DateRangeParentWhereUniqueInput: DateRangeParentWhereUniqueInput;
  DateRangeUpdateInput: DateRangeUpdateInput;
  DateRangeUpdateManyInlineInput: DateRangeUpdateManyInlineInput;
  DateRangeUpdateManyInput: DateRangeUpdateManyInput;
  DateRangeUpdateManyWithNestedWhereInput: DateRangeUpdateManyWithNestedWhereInput;
  DateRangeUpdateOneInlineInput: DateRangeUpdateOneInlineInput;
  DateRangeUpdateWithNestedWhereUniqueAndPositionInput: DateRangeUpdateWithNestedWhereUniqueAndPositionInput;
  DateRangeUpdateWithNestedWhereUniqueInput: DateRangeUpdateWithNestedWhereUniqueInput;
  DateRangeUpsertInput: DateRangeUpsertInput;
  DateRangeUpsertWithNestedWhereUniqueAndPositionInput: DateRangeUpsertWithNestedWhereUniqueAndPositionInput;
  DateRangeUpsertWithNestedWhereUniqueInput: DateRangeUpsertWithNestedWhereUniqueInput;
  DateRangeWhereInput: DateRangeWhereInput;
  DateRangeWhereUniqueInput: DateRangeWhereUniqueInput;
  DateTime: ResolverTypeWrapper<Scalars['DateTime']>;
  DetailBlock: ResolverTypeWrapper<DetailBlock>;
  DetailBlockConnectInput: DetailBlockConnectInput;
  DetailBlockConnection: ResolverTypeWrapper<DetailBlockConnection>;
  DetailBlockCreateInput: DetailBlockCreateInput;
  DetailBlockCreateManyInlineInput: DetailBlockCreateManyInlineInput;
  DetailBlockCreateOneInlineInput: DetailBlockCreateOneInlineInput;
  DetailBlockEdge: ResolverTypeWrapper<DetailBlockEdge>;
  DetailBlockManyWhereInput: DetailBlockManyWhereInput;
  DetailBlockOrderByInput: DetailBlockOrderByInput;
  DetailBlockUpdateInput: DetailBlockUpdateInput;
  DetailBlockUpdateManyInlineInput: DetailBlockUpdateManyInlineInput;
  DetailBlockUpdateManyInput: DetailBlockUpdateManyInput;
  DetailBlockUpdateManyWithNestedWhereInput: DetailBlockUpdateManyWithNestedWhereInput;
  DetailBlockUpdateOneInlineInput: DetailBlockUpdateOneInlineInput;
  DetailBlockUpdateWithNestedWhereUniqueInput: DetailBlockUpdateWithNestedWhereUniqueInput;
  DetailBlockUpsertInput: DetailBlockUpsertInput;
  DetailBlockUpsertWithNestedWhereUniqueInput: DetailBlockUpsertWithNestedWhereUniqueInput;
  DetailBlockWhereComparatorInput: DetailBlockWhereComparatorInput;
  DetailBlockWhereInput: DetailBlockWhereInput;
  DetailBlockWhereStageInput: DetailBlockWhereStageInput;
  DetailBlockWhereUniqueInput: DetailBlockWhereUniqueInput;
  DetailType: DetailType;
  DocumentFileTypes: DocumentFileTypes;
  DocumentOutputInput: DocumentOutputInput;
  DocumentTransformationInput: DocumentTransformationInput;
  DocumentVersion: ResolverTypeWrapper<DocumentVersion>;
  Float: ResolverTypeWrapper<Scalars['Float']>;
  GeneralReservation: ResolverTypeWrapper<GeneralReservation>;
  GeneralReservationConnectInput: GeneralReservationConnectInput;
  GeneralReservationConnection: ResolverTypeWrapper<GeneralReservationConnection>;
  GeneralReservationCreateInput: GeneralReservationCreateInput;
  GeneralReservationCreateManyInlineInput: GeneralReservationCreateManyInlineInput;
  GeneralReservationCreateOneInlineInput: GeneralReservationCreateOneInlineInput;
  GeneralReservationEdge: ResolverTypeWrapper<GeneralReservationEdge>;
  GeneralReservationManyWhereInput: GeneralReservationManyWhereInput;
  GeneralReservationOrderByInput: GeneralReservationOrderByInput;
  GeneralReservationUpdateInput: GeneralReservationUpdateInput;
  GeneralReservationUpdateManyInlineInput: GeneralReservationUpdateManyInlineInput;
  GeneralReservationUpdateManyInput: GeneralReservationUpdateManyInput;
  GeneralReservationUpdateManyWithNestedWhereInput: GeneralReservationUpdateManyWithNestedWhereInput;
  GeneralReservationUpdateOneInlineInput: GeneralReservationUpdateOneInlineInput;
  GeneralReservationUpdateWithNestedWhereUniqueInput: GeneralReservationUpdateWithNestedWhereUniqueInput;
  GeneralReservationUpsertInput: GeneralReservationUpsertInput;
  GeneralReservationUpsertWithNestedWhereUniqueInput: GeneralReservationUpsertWithNestedWhereUniqueInput;
  GeneralReservationWhereComparatorInput: GeneralReservationWhereComparatorInput;
  GeneralReservationWhereInput: GeneralReservationWhereInput;
  GeneralReservationWhereStageInput: GeneralReservationWhereStageInput;
  GeneralReservationWhereUniqueInput: GeneralReservationWhereUniqueInput;
  Hex: ResolverTypeWrapper<Scalars['Hex']>;
  ID: ResolverTypeWrapper<Scalars['ID']>;
  Image: ResolverTypeWrapper<Image>;
  ImageConnectInput: ImageConnectInput;
  ImageConnection: ResolverTypeWrapper<ImageConnection>;
  ImageCreateInput: ImageCreateInput;
  ImageCreateLocalizationDataInput: ImageCreateLocalizationDataInput;
  ImageCreateLocalizationInput: ImageCreateLocalizationInput;
  ImageCreateLocalizationsInput: ImageCreateLocalizationsInput;
  ImageCreateManyInlineInput: ImageCreateManyInlineInput;
  ImageCreateOneInlineInput: ImageCreateOneInlineInput;
  ImageEdge: ResolverTypeWrapper<ImageEdge>;
  ImageFit: ImageFit;
  ImageManyWhereInput: ImageManyWhereInput;
  ImageOrderByInput: ImageOrderByInput;
  ImageResizeInput: ImageResizeInput;
  ImageTransformationInput: ImageTransformationInput;
  ImageUpdateInput: ImageUpdateInput;
  ImageUpdateLocalizationDataInput: ImageUpdateLocalizationDataInput;
  ImageUpdateLocalizationInput: ImageUpdateLocalizationInput;
  ImageUpdateLocalizationsInput: ImageUpdateLocalizationsInput;
  ImageUpdateManyInlineInput: ImageUpdateManyInlineInput;
  ImageUpdateManyInput: ImageUpdateManyInput;
  ImageUpdateManyLocalizationDataInput: ImageUpdateManyLocalizationDataInput;
  ImageUpdateManyLocalizationInput: ImageUpdateManyLocalizationInput;
  ImageUpdateManyLocalizationsInput: ImageUpdateManyLocalizationsInput;
  ImageUpdateManyWithNestedWhereInput: ImageUpdateManyWithNestedWhereInput;
  ImageUpdateOneInlineInput: ImageUpdateOneInlineInput;
  ImageUpdateWithNestedWhereUniqueInput: ImageUpdateWithNestedWhereUniqueInput;
  ImageUpsertInput: ImageUpsertInput;
  ImageUpsertLocalizationInput: ImageUpsertLocalizationInput;
  ImageUpsertWithNestedWhereUniqueInput: ImageUpsertWithNestedWhereUniqueInput;
  ImageWhereComparatorInput: ImageWhereComparatorInput;
  ImageWhereInput: ImageWhereInput;
  ImageWhereStageInput: ImageWhereStageInput;
  ImageWhereUniqueInput: ImageWhereUniqueInput;
  Int: ResolverTypeWrapper<Scalars['Int']>;
  Json: ResolverTypeWrapper<Scalars['Json']>;
  LandingPage: ResolverTypeWrapper<LandingPage>;
  LandingPageBodyRichRichText: ResolverTypeWrapper<
    Omit<LandingPageBodyRichRichText, 'references'> & {
      references: Array<ResolversTypes['LandingPageBodyRichRichTextEmbeddedTypes']>;
    }
  >;
  LandingPageBodyRichRichTextEmbeddedTypes: ResolverTypeWrapper<
    ResolversUnionTypes['LandingPageBodyRichRichTextEmbeddedTypes']
  >;
  LandingPageConnectInput: LandingPageConnectInput;
  LandingPageConnection: ResolverTypeWrapper<LandingPageConnection>;
  LandingPageCreateInput: LandingPageCreateInput;
  LandingPageCreateLocalizationDataInput: LandingPageCreateLocalizationDataInput;
  LandingPageCreateLocalizationInput: LandingPageCreateLocalizationInput;
  LandingPageCreateLocalizationsInput: LandingPageCreateLocalizationsInput;
  LandingPageCreateManyInlineInput: LandingPageCreateManyInlineInput;
  LandingPageCreateOneInlineInput: LandingPageCreateOneInlineInput;
  LandingPageEdge: ResolverTypeWrapper<LandingPageEdge>;
  LandingPageManyWhereInput: LandingPageManyWhereInput;
  LandingPageOrderByInput: LandingPageOrderByInput;
  LandingPageUpdateInput: LandingPageUpdateInput;
  LandingPageUpdateLocalizationDataInput: LandingPageUpdateLocalizationDataInput;
  LandingPageUpdateLocalizationInput: LandingPageUpdateLocalizationInput;
  LandingPageUpdateLocalizationsInput: LandingPageUpdateLocalizationsInput;
  LandingPageUpdateManyInlineInput: LandingPageUpdateManyInlineInput;
  LandingPageUpdateManyInput: LandingPageUpdateManyInput;
  LandingPageUpdateManyLocalizationDataInput: LandingPageUpdateManyLocalizationDataInput;
  LandingPageUpdateManyLocalizationInput: LandingPageUpdateManyLocalizationInput;
  LandingPageUpdateManyLocalizationsInput: LandingPageUpdateManyLocalizationsInput;
  LandingPageUpdateManyWithNestedWhereInput: LandingPageUpdateManyWithNestedWhereInput;
  LandingPageUpdateOneInlineInput: LandingPageUpdateOneInlineInput;
  LandingPageUpdateWithNestedWhereUniqueInput: LandingPageUpdateWithNestedWhereUniqueInput;
  LandingPageUpsertInput: LandingPageUpsertInput;
  LandingPageUpsertLocalizationInput: LandingPageUpsertLocalizationInput;
  LandingPageUpsertWithNestedWhereUniqueInput: LandingPageUpsertWithNestedWhereUniqueInput;
  LandingPageWhereComparatorInput: LandingPageWhereComparatorInput;
  LandingPageWhereInput: LandingPageWhereInput;
  LandingPageWhereStageInput: LandingPageWhereStageInput;
  LandingPageWhereUniqueInput: LandingPageWhereUniqueInput;
  Link: ResolverTypeWrapper<Link>;
  LinkConnectInput: LinkConnectInput;
  LinkConnection: ResolverTypeWrapper<LinkConnection>;
  LinkCreateInput: LinkCreateInput;
  LinkCreateManyInlineInput: LinkCreateManyInlineInput;
  LinkCreateOneInlineInput: LinkCreateOneInlineInput;
  LinkEdge: ResolverTypeWrapper<LinkEdge>;
  LinkManyWhereInput: LinkManyWhereInput;
  LinkOrderByInput: LinkOrderByInput;
  LinkUpdateInput: LinkUpdateInput;
  LinkUpdateManyInlineInput: LinkUpdateManyInlineInput;
  LinkUpdateManyInput: LinkUpdateManyInput;
  LinkUpdateManyWithNestedWhereInput: LinkUpdateManyWithNestedWhereInput;
  LinkUpdateOneInlineInput: LinkUpdateOneInlineInput;
  LinkUpdateWithNestedWhereUniqueInput: LinkUpdateWithNestedWhereUniqueInput;
  LinkUpsertInput: LinkUpsertInput;
  LinkUpsertWithNestedWhereUniqueInput: LinkUpsertWithNestedWhereUniqueInput;
  LinkWhereComparatorInput: LinkWhereComparatorInput;
  LinkWhereInput: LinkWhereInput;
  LinkWhereStageInput: LinkWhereStageInput;
  LinkWhereUniqueInput: LinkWhereUniqueInput;
  Locale: Locale;
  Location: ResolverTypeWrapper<Location>;
  LocationInput: LocationInput;
  Long: ResolverTypeWrapper<Scalars['Long']>;
  Menu: ResolverTypeWrapper<Omit<Menu, 'items'> & { items: Array<ResolversTypes['MenuItems']> }>;
  MenuConnectInput: MenuConnectInput;
  MenuConnection: ResolverTypeWrapper<MenuConnection>;
  MenuCreateInput: MenuCreateInput;
  MenuCreateManyInlineInput: MenuCreateManyInlineInput;
  MenuCreateOneInlineInput: MenuCreateOneInlineInput;
  MenuEdge: ResolverTypeWrapper<MenuEdge>;
  MenuItems: ResolverTypeWrapper<ResolversUnionTypes['MenuItems']>;
  MenuItemsConnectInput: MenuItemsConnectInput;
  MenuItemsCreateInput: MenuItemsCreateInput;
  MenuItemsCreateManyInlineInput: MenuItemsCreateManyInlineInput;
  MenuItemsCreateOneInlineInput: MenuItemsCreateOneInlineInput;
  MenuItemsUpdateInput: MenuItemsUpdateInput;
  MenuItemsUpdateManyInlineInput: MenuItemsUpdateManyInlineInput;
  MenuItemsUpdateManyWithNestedWhereInput: MenuItemsUpdateManyWithNestedWhereInput;
  MenuItemsUpdateOneInlineInput: MenuItemsUpdateOneInlineInput;
  MenuItemsUpdateWithNestedWhereUniqueInput: MenuItemsUpdateWithNestedWhereUniqueInput;
  MenuItemsUpsertWithNestedWhereUniqueInput: MenuItemsUpsertWithNestedWhereUniqueInput;
  MenuItemsWhereInput: MenuItemsWhereInput;
  MenuItemsWhereUniqueInput: MenuItemsWhereUniqueInput;
  MenuManyWhereInput: MenuManyWhereInput;
  MenuOrderByInput: MenuOrderByInput;
  MenuUpdateInput: MenuUpdateInput;
  MenuUpdateManyInlineInput: MenuUpdateManyInlineInput;
  MenuUpdateManyInput: MenuUpdateManyInput;
  MenuUpdateManyWithNestedWhereInput: MenuUpdateManyWithNestedWhereInput;
  MenuUpdateOneInlineInput: MenuUpdateOneInlineInput;
  MenuUpdateWithNestedWhereUniqueInput: MenuUpdateWithNestedWhereUniqueInput;
  MenuUpsertInput: MenuUpsertInput;
  MenuUpsertWithNestedWhereUniqueInput: MenuUpsertWithNestedWhereUniqueInput;
  MenuWhereComparatorInput: MenuWhereComparatorInput;
  MenuWhereInput: MenuWhereInput;
  MenuWhereStageInput: MenuWhereStageInput;
  MenuWhereUniqueInput: MenuWhereUniqueInput;
  Mutation: ResolverTypeWrapper<{}>;
  Node:
    | ResolversTypes['AddOn']
    | ResolversTypes['AlacarteTicket']
    | ResolversTypes['AlacarteTicketType']
    | ResolversTypes['Article']
    | ResolversTypes['ArticleCategory']
    | ResolversTypes['Asset']
    | ResolversTypes['Attraction']
    | ResolversTypes['AttractionAlacarte']
    | ResolversTypes['AttractionAvailability']
    | ResolversTypes['AttractionLocation']
    | ResolversTypes['AttractionOffer']
    | ResolversTypes['AttractionPrice']
    | ResolversTypes['AttractionReservation']
    | ResolversTypes['AttractionVariant']
    | ResolversTypes['Author']
    | ResolversTypes['Block']
    | ResolversTypes['BlockGroup']
    | ResolversTypes['DetailBlock']
    | ResolversTypes['GeneralReservation']
    | ResolversTypes['Image']
    | ResolversTypes['LandingPage']
    | ResolversTypes['Link']
    | ResolversTypes['Menu']
    | ResolversTypes['Notice']
    | ResolversTypes['Page']
    | ResolversTypes['PageTemplate']
    | ResolversTypes['Partner']
    | ResolversTypes['PartnerOffer']
    | ResolversTypes['Product']
    | ResolversTypes['ProductActivity']
    | ResolversTypes['ProductActivityItem']
    | ResolversTypes['ProductAttractionLineup']
    | ResolversTypes['ProductReservation']
    | ResolversTypes['Promo']
    | ResolversTypes['ReservationText']
    | ResolversTypes['Sandbox']
    | ResolversTypes['ScheduledOperation']
    | ResolversTypes['ScheduledRelease']
    | ResolversTypes['Seo']
    | ResolversTypes['Text']
    | ResolversTypes['TextGroup']
    | ResolversTypes['TextList']
    | ResolversTypes['TicketGroup']
    | ResolversTypes['TitleDescription']
    | ResolversTypes['User']
    | ResolversTypes['Video'];
  Notice: ResolverTypeWrapper<Notice>;
  NoticeConnectInput: NoticeConnectInput;
  NoticeConnection: ResolverTypeWrapper<NoticeConnection>;
  NoticeCreateInput: NoticeCreateInput;
  NoticeCreateLocalizationDataInput: NoticeCreateLocalizationDataInput;
  NoticeCreateLocalizationInput: NoticeCreateLocalizationInput;
  NoticeCreateLocalizationsInput: NoticeCreateLocalizationsInput;
  NoticeCreateManyInlineInput: NoticeCreateManyInlineInput;
  NoticeCreateOneInlineInput: NoticeCreateOneInlineInput;
  NoticeEdge: ResolverTypeWrapper<NoticeEdge>;
  NoticeManyWhereInput: NoticeManyWhereInput;
  NoticeOrderByInput: NoticeOrderByInput;
  NoticePlacements: NoticePlacements;
  NoticeTheme: NoticeTheme;
  NoticeUpdateInput: NoticeUpdateInput;
  NoticeUpdateLocalizationDataInput: NoticeUpdateLocalizationDataInput;
  NoticeUpdateLocalizationInput: NoticeUpdateLocalizationInput;
  NoticeUpdateLocalizationsInput: NoticeUpdateLocalizationsInput;
  NoticeUpdateManyInlineInput: NoticeUpdateManyInlineInput;
  NoticeUpdateManyInput: NoticeUpdateManyInput;
  NoticeUpdateManyLocalizationDataInput: NoticeUpdateManyLocalizationDataInput;
  NoticeUpdateManyLocalizationInput: NoticeUpdateManyLocalizationInput;
  NoticeUpdateManyLocalizationsInput: NoticeUpdateManyLocalizationsInput;
  NoticeUpdateManyWithNestedWhereInput: NoticeUpdateManyWithNestedWhereInput;
  NoticeUpdateOneInlineInput: NoticeUpdateOneInlineInput;
  NoticeUpdateWithNestedWhereUniqueInput: NoticeUpdateWithNestedWhereUniqueInput;
  NoticeUpsertInput: NoticeUpsertInput;
  NoticeUpsertLocalizationInput: NoticeUpsertLocalizationInput;
  NoticeUpsertWithNestedWhereUniqueInput: NoticeUpsertWithNestedWhereUniqueInput;
  NoticeWhereComparatorInput: NoticeWhereComparatorInput;
  NoticeWhereInput: NoticeWhereInput;
  NoticeWhereStageInput: NoticeWhereStageInput;
  NoticeWhereUniqueInput: NoticeWhereUniqueInput;
  Page: ResolverTypeWrapper<Page>;
  PageConnectInput: PageConnectInput;
  PageConnection: ResolverTypeWrapper<PageConnection>;
  PageCreateInput: PageCreateInput;
  PageCreateLocalizationDataInput: PageCreateLocalizationDataInput;
  PageCreateLocalizationInput: PageCreateLocalizationInput;
  PageCreateLocalizationsInput: PageCreateLocalizationsInput;
  PageCreateManyInlineInput: PageCreateManyInlineInput;
  PageCreateOneInlineInput: PageCreateOneInlineInput;
  PageEdge: ResolverTypeWrapper<PageEdge>;
  PageInfo: ResolverTypeWrapper<PageInfo>;
  PageManyWhereInput: PageManyWhereInput;
  PageOrderByInput: PageOrderByInput;
  PageTemplate: ResolverTypeWrapper<PageTemplate>;
  PageTemplateConnectInput: PageTemplateConnectInput;
  PageTemplateConnection: ResolverTypeWrapper<PageTemplateConnection>;
  PageTemplateCreateInput: PageTemplateCreateInput;
  PageTemplateCreateManyInlineInput: PageTemplateCreateManyInlineInput;
  PageTemplateCreateOneInlineInput: PageTemplateCreateOneInlineInput;
  PageTemplateEdge: ResolverTypeWrapper<PageTemplateEdge>;
  PageTemplateManyWhereInput: PageTemplateManyWhereInput;
  PageTemplateOrderByInput: PageTemplateOrderByInput;
  PageTemplateUpdateInput: PageTemplateUpdateInput;
  PageTemplateUpdateManyInlineInput: PageTemplateUpdateManyInlineInput;
  PageTemplateUpdateManyInput: PageTemplateUpdateManyInput;
  PageTemplateUpdateManyWithNestedWhereInput: PageTemplateUpdateManyWithNestedWhereInput;
  PageTemplateUpdateOneInlineInput: PageTemplateUpdateOneInlineInput;
  PageTemplateUpdateWithNestedWhereUniqueInput: PageTemplateUpdateWithNestedWhereUniqueInput;
  PageTemplateUpsertInput: PageTemplateUpsertInput;
  PageTemplateUpsertWithNestedWhereUniqueInput: PageTemplateUpsertWithNestedWhereUniqueInput;
  PageTemplateWhereComparatorInput: PageTemplateWhereComparatorInput;
  PageTemplateWhereInput: PageTemplateWhereInput;
  PageTemplateWhereStageInput: PageTemplateWhereStageInput;
  PageTemplateWhereUniqueInput: PageTemplateWhereUniqueInput;
  PageType: PageType;
  PageUpdateInput: PageUpdateInput;
  PageUpdateLocalizationDataInput: PageUpdateLocalizationDataInput;
  PageUpdateLocalizationInput: PageUpdateLocalizationInput;
  PageUpdateLocalizationsInput: PageUpdateLocalizationsInput;
  PageUpdateManyInlineInput: PageUpdateManyInlineInput;
  PageUpdateManyInput: PageUpdateManyInput;
  PageUpdateManyLocalizationDataInput: PageUpdateManyLocalizationDataInput;
  PageUpdateManyLocalizationInput: PageUpdateManyLocalizationInput;
  PageUpdateManyLocalizationsInput: PageUpdateManyLocalizationsInput;
  PageUpdateManyWithNestedWhereInput: PageUpdateManyWithNestedWhereInput;
  PageUpdateOneInlineInput: PageUpdateOneInlineInput;
  PageUpdateWithNestedWhereUniqueInput: PageUpdateWithNestedWhereUniqueInput;
  PageUpsertInput: PageUpsertInput;
  PageUpsertLocalizationInput: PageUpsertLocalizationInput;
  PageUpsertWithNestedWhereUniqueInput: PageUpsertWithNestedWhereUniqueInput;
  PageWhereComparatorInput: PageWhereComparatorInput;
  PageWhereInput: PageWhereInput;
  PageWhereStageInput: PageWhereStageInput;
  PageWhereUniqueInput: PageWhereUniqueInput;
  Partner: ResolverTypeWrapper<Partner>;
  PartnerConnectInput: PartnerConnectInput;
  PartnerConnection: ResolverTypeWrapper<PartnerConnection>;
  PartnerCreateInput: PartnerCreateInput;
  PartnerCreateManyInlineInput: PartnerCreateManyInlineInput;
  PartnerCreateOneInlineInput: PartnerCreateOneInlineInput;
  PartnerEdge: ResolverTypeWrapper<PartnerEdge>;
  PartnerManyWhereInput: PartnerManyWhereInput;
  PartnerOffer: ResolverTypeWrapper<PartnerOffer>;
  PartnerOfferConnectInput: PartnerOfferConnectInput;
  PartnerOfferConnection: ResolverTypeWrapper<PartnerOfferConnection>;
  PartnerOfferCreateInput: PartnerOfferCreateInput;
  PartnerOfferCreateLocalizationDataInput: PartnerOfferCreateLocalizationDataInput;
  PartnerOfferCreateLocalizationInput: PartnerOfferCreateLocalizationInput;
  PartnerOfferCreateLocalizationsInput: PartnerOfferCreateLocalizationsInput;
  PartnerOfferCreateManyInlineInput: PartnerOfferCreateManyInlineInput;
  PartnerOfferCreateOneInlineInput: PartnerOfferCreateOneInlineInput;
  PartnerOfferEdge: ResolverTypeWrapper<PartnerOfferEdge>;
  PartnerOfferManyWhereInput: PartnerOfferManyWhereInput;
  PartnerOfferOrderByInput: PartnerOfferOrderByInput;
  PartnerOfferUpdateInput: PartnerOfferUpdateInput;
  PartnerOfferUpdateLocalizationDataInput: PartnerOfferUpdateLocalizationDataInput;
  PartnerOfferUpdateLocalizationInput: PartnerOfferUpdateLocalizationInput;
  PartnerOfferUpdateLocalizationsInput: PartnerOfferUpdateLocalizationsInput;
  PartnerOfferUpdateManyInlineInput: PartnerOfferUpdateManyInlineInput;
  PartnerOfferUpdateManyInput: PartnerOfferUpdateManyInput;
  PartnerOfferUpdateManyLocalizationDataInput: PartnerOfferUpdateManyLocalizationDataInput;
  PartnerOfferUpdateManyLocalizationInput: PartnerOfferUpdateManyLocalizationInput;
  PartnerOfferUpdateManyLocalizationsInput: PartnerOfferUpdateManyLocalizationsInput;
  PartnerOfferUpdateManyWithNestedWhereInput: PartnerOfferUpdateManyWithNestedWhereInput;
  PartnerOfferUpdateOneInlineInput: PartnerOfferUpdateOneInlineInput;
  PartnerOfferUpdateWithNestedWhereUniqueInput: PartnerOfferUpdateWithNestedWhereUniqueInput;
  PartnerOfferUpsertInput: PartnerOfferUpsertInput;
  PartnerOfferUpsertLocalizationInput: PartnerOfferUpsertLocalizationInput;
  PartnerOfferUpsertWithNestedWhereUniqueInput: PartnerOfferUpsertWithNestedWhereUniqueInput;
  PartnerOfferWhereComparatorInput: PartnerOfferWhereComparatorInput;
  PartnerOfferWhereInput: PartnerOfferWhereInput;
  PartnerOfferWhereStageInput: PartnerOfferWhereStageInput;
  PartnerOfferWhereUniqueInput: PartnerOfferWhereUniqueInput;
  PartnerOrderByInput: PartnerOrderByInput;
  PartnerType: PartnerType;
  PartnerUpdateInput: PartnerUpdateInput;
  PartnerUpdateManyInlineInput: PartnerUpdateManyInlineInput;
  PartnerUpdateManyInput: PartnerUpdateManyInput;
  PartnerUpdateManyWithNestedWhereInput: PartnerUpdateManyWithNestedWhereInput;
  PartnerUpdateOneInlineInput: PartnerUpdateOneInlineInput;
  PartnerUpdateWithNestedWhereUniqueInput: PartnerUpdateWithNestedWhereUniqueInput;
  PartnerUpsertInput: PartnerUpsertInput;
  PartnerUpsertWithNestedWhereUniqueInput: PartnerUpsertWithNestedWhereUniqueInput;
  PartnerWhereComparatorInput: PartnerWhereComparatorInput;
  PartnerWhereInput: PartnerWhereInput;
  PartnerWhereStageInput: PartnerWhereStageInput;
  PartnerWhereUniqueInput: PartnerWhereUniqueInput;
  Platforms: Platforms;
  Product: ResolverTypeWrapper<Product>;
  ProductActivity: ResolverTypeWrapper<ProductActivity>;
  ProductActivityConnectInput: ProductActivityConnectInput;
  ProductActivityConnection: ResolverTypeWrapper<ProductActivityConnection>;
  ProductActivityCreateInput: ProductActivityCreateInput;
  ProductActivityCreateLocalizationDataInput: ProductActivityCreateLocalizationDataInput;
  ProductActivityCreateLocalizationInput: ProductActivityCreateLocalizationInput;
  ProductActivityCreateLocalizationsInput: ProductActivityCreateLocalizationsInput;
  ProductActivityCreateManyInlineInput: ProductActivityCreateManyInlineInput;
  ProductActivityCreateOneInlineInput: ProductActivityCreateOneInlineInput;
  ProductActivityEdge: ResolverTypeWrapper<ProductActivityEdge>;
  ProductActivityItem: ResolverTypeWrapper<ProductActivityItem>;
  ProductActivityItemConnectInput: ProductActivityItemConnectInput;
  ProductActivityItemConnection: ResolverTypeWrapper<ProductActivityItemConnection>;
  ProductActivityItemCreateInput: ProductActivityItemCreateInput;
  ProductActivityItemCreateLocalizationDataInput: ProductActivityItemCreateLocalizationDataInput;
  ProductActivityItemCreateLocalizationInput: ProductActivityItemCreateLocalizationInput;
  ProductActivityItemCreateLocalizationsInput: ProductActivityItemCreateLocalizationsInput;
  ProductActivityItemCreateManyInlineInput: ProductActivityItemCreateManyInlineInput;
  ProductActivityItemCreateOneInlineInput: ProductActivityItemCreateOneInlineInput;
  ProductActivityItemEdge: ResolverTypeWrapper<ProductActivityItemEdge>;
  ProductActivityItemManyWhereInput: ProductActivityItemManyWhereInput;
  ProductActivityItemOrderByInput: ProductActivityItemOrderByInput;
  ProductActivityItemUpdateInput: ProductActivityItemUpdateInput;
  ProductActivityItemUpdateLocalizationDataInput: ProductActivityItemUpdateLocalizationDataInput;
  ProductActivityItemUpdateLocalizationInput: ProductActivityItemUpdateLocalizationInput;
  ProductActivityItemUpdateLocalizationsInput: ProductActivityItemUpdateLocalizationsInput;
  ProductActivityItemUpdateManyInlineInput: ProductActivityItemUpdateManyInlineInput;
  ProductActivityItemUpdateManyInput: ProductActivityItemUpdateManyInput;
  ProductActivityItemUpdateManyLocalizationDataInput: ProductActivityItemUpdateManyLocalizationDataInput;
  ProductActivityItemUpdateManyLocalizationInput: ProductActivityItemUpdateManyLocalizationInput;
  ProductActivityItemUpdateManyLocalizationsInput: ProductActivityItemUpdateManyLocalizationsInput;
  ProductActivityItemUpdateManyWithNestedWhereInput: ProductActivityItemUpdateManyWithNestedWhereInput;
  ProductActivityItemUpdateOneInlineInput: ProductActivityItemUpdateOneInlineInput;
  ProductActivityItemUpdateWithNestedWhereUniqueInput: ProductActivityItemUpdateWithNestedWhereUniqueInput;
  ProductActivityItemUpsertInput: ProductActivityItemUpsertInput;
  ProductActivityItemUpsertLocalizationInput: ProductActivityItemUpsertLocalizationInput;
  ProductActivityItemUpsertWithNestedWhereUniqueInput: ProductActivityItemUpsertWithNestedWhereUniqueInput;
  ProductActivityItemWhereComparatorInput: ProductActivityItemWhereComparatorInput;
  ProductActivityItemWhereInput: ProductActivityItemWhereInput;
  ProductActivityItemWhereStageInput: ProductActivityItemWhereStageInput;
  ProductActivityItemWhereUniqueInput: ProductActivityItemWhereUniqueInput;
  ProductActivityManyWhereInput: ProductActivityManyWhereInput;
  ProductActivityOrderByInput: ProductActivityOrderByInput;
  ProductActivityUpdateInput: ProductActivityUpdateInput;
  ProductActivityUpdateLocalizationDataInput: ProductActivityUpdateLocalizationDataInput;
  ProductActivityUpdateLocalizationInput: ProductActivityUpdateLocalizationInput;
  ProductActivityUpdateLocalizationsInput: ProductActivityUpdateLocalizationsInput;
  ProductActivityUpdateManyInlineInput: ProductActivityUpdateManyInlineInput;
  ProductActivityUpdateManyInput: ProductActivityUpdateManyInput;
  ProductActivityUpdateManyLocalizationDataInput: ProductActivityUpdateManyLocalizationDataInput;
  ProductActivityUpdateManyLocalizationInput: ProductActivityUpdateManyLocalizationInput;
  ProductActivityUpdateManyLocalizationsInput: ProductActivityUpdateManyLocalizationsInput;
  ProductActivityUpdateManyWithNestedWhereInput: ProductActivityUpdateManyWithNestedWhereInput;
  ProductActivityUpdateOneInlineInput: ProductActivityUpdateOneInlineInput;
  ProductActivityUpdateWithNestedWhereUniqueInput: ProductActivityUpdateWithNestedWhereUniqueInput;
  ProductActivityUpsertInput: ProductActivityUpsertInput;
  ProductActivityUpsertLocalizationInput: ProductActivityUpsertLocalizationInput;
  ProductActivityUpsertWithNestedWhereUniqueInput: ProductActivityUpsertWithNestedWhereUniqueInput;
  ProductActivityWhereComparatorInput: ProductActivityWhereComparatorInput;
  ProductActivityWhereInput: ProductActivityWhereInput;
  ProductActivityWhereStageInput: ProductActivityWhereStageInput;
  ProductActivityWhereUniqueInput: ProductActivityWhereUniqueInput;
  ProductAttractionLineup: ResolverTypeWrapper<ProductAttractionLineup>;
  ProductAttractionLineupConnectInput: ProductAttractionLineupConnectInput;
  ProductAttractionLineupConnection: ResolverTypeWrapper<ProductAttractionLineupConnection>;
  ProductAttractionLineupCreateInput: ProductAttractionLineupCreateInput;
  ProductAttractionLineupCreateManyInlineInput: ProductAttractionLineupCreateManyInlineInput;
  ProductAttractionLineupCreateOneInlineInput: ProductAttractionLineupCreateOneInlineInput;
  ProductAttractionLineupEdge: ResolverTypeWrapper<ProductAttractionLineupEdge>;
  ProductAttractionLineupManyWhereInput: ProductAttractionLineupManyWhereInput;
  ProductAttractionLineupOrderByInput: ProductAttractionLineupOrderByInput;
  ProductAttractionLineupUpdateInput: ProductAttractionLineupUpdateInput;
  ProductAttractionLineupUpdateManyInlineInput: ProductAttractionLineupUpdateManyInlineInput;
  ProductAttractionLineupUpdateManyInput: ProductAttractionLineupUpdateManyInput;
  ProductAttractionLineupUpdateManyWithNestedWhereInput: ProductAttractionLineupUpdateManyWithNestedWhereInput;
  ProductAttractionLineupUpdateOneInlineInput: ProductAttractionLineupUpdateOneInlineInput;
  ProductAttractionLineupUpdateWithNestedWhereUniqueInput: ProductAttractionLineupUpdateWithNestedWhereUniqueInput;
  ProductAttractionLineupUpsertInput: ProductAttractionLineupUpsertInput;
  ProductAttractionLineupUpsertWithNestedWhereUniqueInput: ProductAttractionLineupUpsertWithNestedWhereUniqueInput;
  ProductAttractionLineupWhereComparatorInput: ProductAttractionLineupWhereComparatorInput;
  ProductAttractionLineupWhereInput: ProductAttractionLineupWhereInput;
  ProductAttractionLineupWhereStageInput: ProductAttractionLineupWhereStageInput;
  ProductAttractionLineupWhereUniqueInput: ProductAttractionLineupWhereUniqueInput;
  ProductConnectInput: ProductConnectInput;
  ProductConnection: ResolverTypeWrapper<ProductConnection>;
  ProductCreateInput: ProductCreateInput;
  ProductCreateLocalizationDataInput: ProductCreateLocalizationDataInput;
  ProductCreateLocalizationInput: ProductCreateLocalizationInput;
  ProductCreateLocalizationsInput: ProductCreateLocalizationsInput;
  ProductCreateManyInlineInput: ProductCreateManyInlineInput;
  ProductCreateOneInlineInput: ProductCreateOneInlineInput;
  ProductEdge: ResolverTypeWrapper<ProductEdge>;
  ProductManyWhereInput: ProductManyWhereInput;
  ProductOrderByInput: ProductOrderByInput;
  ProductReservation: ResolverTypeWrapper<ProductReservation>;
  ProductReservationConnectInput: ProductReservationConnectInput;
  ProductReservationConnection: ResolverTypeWrapper<ProductReservationConnection>;
  ProductReservationCreateInput: ProductReservationCreateInput;
  ProductReservationCreateManyInlineInput: ProductReservationCreateManyInlineInput;
  ProductReservationCreateOneInlineInput: ProductReservationCreateOneInlineInput;
  ProductReservationEdge: ResolverTypeWrapper<ProductReservationEdge>;
  ProductReservationManyWhereInput: ProductReservationManyWhereInput;
  ProductReservationOrderByInput: ProductReservationOrderByInput;
  ProductReservationUpdateInput: ProductReservationUpdateInput;
  ProductReservationUpdateManyInlineInput: ProductReservationUpdateManyInlineInput;
  ProductReservationUpdateManyInput: ProductReservationUpdateManyInput;
  ProductReservationUpdateManyWithNestedWhereInput: ProductReservationUpdateManyWithNestedWhereInput;
  ProductReservationUpdateOneInlineInput: ProductReservationUpdateOneInlineInput;
  ProductReservationUpdateWithNestedWhereUniqueInput: ProductReservationUpdateWithNestedWhereUniqueInput;
  ProductReservationUpsertInput: ProductReservationUpsertInput;
  ProductReservationUpsertWithNestedWhereUniqueInput: ProductReservationUpsertWithNestedWhereUniqueInput;
  ProductReservationWhereComparatorInput: ProductReservationWhereComparatorInput;
  ProductReservationWhereInput: ProductReservationWhereInput;
  ProductReservationWhereStageInput: ProductReservationWhereStageInput;
  ProductReservationWhereUniqueInput: ProductReservationWhereUniqueInput;
  ProductType: ProductType;
  ProductUpdateInput: ProductUpdateInput;
  ProductUpdateLocalizationDataInput: ProductUpdateLocalizationDataInput;
  ProductUpdateLocalizationInput: ProductUpdateLocalizationInput;
  ProductUpdateLocalizationsInput: ProductUpdateLocalizationsInput;
  ProductUpdateManyInlineInput: ProductUpdateManyInlineInput;
  ProductUpdateManyInput: ProductUpdateManyInput;
  ProductUpdateManyLocalizationDataInput: ProductUpdateManyLocalizationDataInput;
  ProductUpdateManyLocalizationInput: ProductUpdateManyLocalizationInput;
  ProductUpdateManyLocalizationsInput: ProductUpdateManyLocalizationsInput;
  ProductUpdateManyWithNestedWhereInput: ProductUpdateManyWithNestedWhereInput;
  ProductUpdateOneInlineInput: ProductUpdateOneInlineInput;
  ProductUpdateWithNestedWhereUniqueInput: ProductUpdateWithNestedWhereUniqueInput;
  ProductUpsertInput: ProductUpsertInput;
  ProductUpsertLocalizationInput: ProductUpsertLocalizationInput;
  ProductUpsertWithNestedWhereUniqueInput: ProductUpsertWithNestedWhereUniqueInput;
  ProductWhereComparatorInput: ProductWhereComparatorInput;
  ProductWhereInput: ProductWhereInput;
  ProductWhereStageInput: ProductWhereStageInput;
  ProductWhereUniqueInput: ProductWhereUniqueInput;
  Promo: ResolverTypeWrapper<
    Omit<Promo, 'template'> & { template: Maybe<ResolversTypes['PromotemplateUnion']> }
  >;
  PromoBanner: ResolverTypeWrapper<PromoBanner>;
  PromoBannerConnectInput: PromoBannerConnectInput;
  PromoBannerConnection: ResolverTypeWrapper<PromoBannerConnection>;
  PromoBannerCreateInput: PromoBannerCreateInput;
  PromoBannerCreateLocalizationDataInput: PromoBannerCreateLocalizationDataInput;
  PromoBannerCreateLocalizationInput: PromoBannerCreateLocalizationInput;
  PromoBannerCreateLocalizationsInput: PromoBannerCreateLocalizationsInput;
  PromoBannerCreateManyInlineInput: PromoBannerCreateManyInlineInput;
  PromoBannerCreateOneInlineInput: PromoBannerCreateOneInlineInput;
  PromoBannerCreateWithPositionInput: PromoBannerCreateWithPositionInput;
  PromoBannerEdge: ResolverTypeWrapper<PromoBannerEdge>;
  PromoBannerManyWhereInput: PromoBannerManyWhereInput;
  PromoBannerOrderByInput: PromoBannerOrderByInput;
  PromoBannerParent: ResolverTypeWrapper<ResolversUnionTypes['PromoBannerParent']>;
  PromoBannerParentConnectInput: PromoBannerParentConnectInput;
  PromoBannerParentCreateInput: PromoBannerParentCreateInput;
  PromoBannerParentCreateManyInlineInput: PromoBannerParentCreateManyInlineInput;
  PromoBannerParentCreateOneInlineInput: PromoBannerParentCreateOneInlineInput;
  PromoBannerParentUpdateInput: PromoBannerParentUpdateInput;
  PromoBannerParentUpdateManyInlineInput: PromoBannerParentUpdateManyInlineInput;
  PromoBannerParentUpdateManyWithNestedWhereInput: PromoBannerParentUpdateManyWithNestedWhereInput;
  PromoBannerParentUpdateOneInlineInput: PromoBannerParentUpdateOneInlineInput;
  PromoBannerParentUpdateWithNestedWhereUniqueInput: PromoBannerParentUpdateWithNestedWhereUniqueInput;
  PromoBannerParentUpsertWithNestedWhereUniqueInput: PromoBannerParentUpsertWithNestedWhereUniqueInput;
  PromoBannerParentWhereInput: PromoBannerParentWhereInput;
  PromoBannerParentWhereUniqueInput: PromoBannerParentWhereUniqueInput;
  PromoBannerUpdateInput: PromoBannerUpdateInput;
  PromoBannerUpdateLocalizationDataInput: PromoBannerUpdateLocalizationDataInput;
  PromoBannerUpdateLocalizationInput: PromoBannerUpdateLocalizationInput;
  PromoBannerUpdateLocalizationsInput: PromoBannerUpdateLocalizationsInput;
  PromoBannerUpdateManyInlineInput: PromoBannerUpdateManyInlineInput;
  PromoBannerUpdateManyInput: PromoBannerUpdateManyInput;
  PromoBannerUpdateManyLocalizationDataInput: PromoBannerUpdateManyLocalizationDataInput;
  PromoBannerUpdateManyLocalizationInput: PromoBannerUpdateManyLocalizationInput;
  PromoBannerUpdateManyLocalizationsInput: PromoBannerUpdateManyLocalizationsInput;
  PromoBannerUpdateManyWithNestedWhereInput: PromoBannerUpdateManyWithNestedWhereInput;
  PromoBannerUpdateOneInlineInput: PromoBannerUpdateOneInlineInput;
  PromoBannerUpdateWithNestedWhereUniqueAndPositionInput: PromoBannerUpdateWithNestedWhereUniqueAndPositionInput;
  PromoBannerUpdateWithNestedWhereUniqueInput: PromoBannerUpdateWithNestedWhereUniqueInput;
  PromoBannerUpsertInput: PromoBannerUpsertInput;
  PromoBannerUpsertLocalizationInput: PromoBannerUpsertLocalizationInput;
  PromoBannerUpsertWithNestedWhereUniqueAndPositionInput: PromoBannerUpsertWithNestedWhereUniqueAndPositionInput;
  PromoBannerUpsertWithNestedWhereUniqueInput: PromoBannerUpsertWithNestedWhereUniqueInput;
  PromoBannerWhereInput: PromoBannerWhereInput;
  PromoBannerWhereUniqueInput: PromoBannerWhereUniqueInput;
  PromoConnectInput: PromoConnectInput;
  PromoConnection: ResolverTypeWrapper<PromoConnection>;
  PromoCreateInput: PromoCreateInput;
  PromoCreateLocalizationDataInput: PromoCreateLocalizationDataInput;
  PromoCreateLocalizationInput: PromoCreateLocalizationInput;
  PromoCreateLocalizationsInput: PromoCreateLocalizationsInput;
  PromoCreateManyInlineInput: PromoCreateManyInlineInput;
  PromoCreateOneInlineInput: PromoCreateOneInlineInput;
  PromoEdge: ResolverTypeWrapper<PromoEdge>;
  PromoManyWhereInput: PromoManyWhereInput;
  PromoOrderByInput: PromoOrderByInput;
  PromoPlacement: PromoPlacement;
  PromoText: ResolverTypeWrapper<PromoText>;
  PromoTextConnectInput: PromoTextConnectInput;
  PromoTextConnection: ResolverTypeWrapper<PromoTextConnection>;
  PromoTextCreateInput: PromoTextCreateInput;
  PromoTextCreateLocalizationDataInput: PromoTextCreateLocalizationDataInput;
  PromoTextCreateLocalizationInput: PromoTextCreateLocalizationInput;
  PromoTextCreateLocalizationsInput: PromoTextCreateLocalizationsInput;
  PromoTextCreateManyInlineInput: PromoTextCreateManyInlineInput;
  PromoTextCreateOneInlineInput: PromoTextCreateOneInlineInput;
  PromoTextCreateWithPositionInput: PromoTextCreateWithPositionInput;
  PromoTextEdge: ResolverTypeWrapper<PromoTextEdge>;
  PromoTextManyWhereInput: PromoTextManyWhereInput;
  PromoTextOrderByInput: PromoTextOrderByInput;
  PromoTextParent: ResolverTypeWrapper<ResolversUnionTypes['PromoTextParent']>;
  PromoTextParentConnectInput: PromoTextParentConnectInput;
  PromoTextParentCreateInput: PromoTextParentCreateInput;
  PromoTextParentCreateManyInlineInput: PromoTextParentCreateManyInlineInput;
  PromoTextParentCreateOneInlineInput: PromoTextParentCreateOneInlineInput;
  PromoTextParentUpdateInput: PromoTextParentUpdateInput;
  PromoTextParentUpdateManyInlineInput: PromoTextParentUpdateManyInlineInput;
  PromoTextParentUpdateManyWithNestedWhereInput: PromoTextParentUpdateManyWithNestedWhereInput;
  PromoTextParentUpdateOneInlineInput: PromoTextParentUpdateOneInlineInput;
  PromoTextParentUpdateWithNestedWhereUniqueInput: PromoTextParentUpdateWithNestedWhereUniqueInput;
  PromoTextParentUpsertWithNestedWhereUniqueInput: PromoTextParentUpsertWithNestedWhereUniqueInput;
  PromoTextParentWhereInput: PromoTextParentWhereInput;
  PromoTextParentWhereUniqueInput: PromoTextParentWhereUniqueInput;
  PromoTextUpdateInput: PromoTextUpdateInput;
  PromoTextUpdateLocalizationDataInput: PromoTextUpdateLocalizationDataInput;
  PromoTextUpdateLocalizationInput: PromoTextUpdateLocalizationInput;
  PromoTextUpdateLocalizationsInput: PromoTextUpdateLocalizationsInput;
  PromoTextUpdateManyInlineInput: PromoTextUpdateManyInlineInput;
  PromoTextUpdateManyInput: PromoTextUpdateManyInput;
  PromoTextUpdateManyLocalizationDataInput: PromoTextUpdateManyLocalizationDataInput;
  PromoTextUpdateManyLocalizationInput: PromoTextUpdateManyLocalizationInput;
  PromoTextUpdateManyLocalizationsInput: PromoTextUpdateManyLocalizationsInput;
  PromoTextUpdateManyWithNestedWhereInput: PromoTextUpdateManyWithNestedWhereInput;
  PromoTextUpdateOneInlineInput: PromoTextUpdateOneInlineInput;
  PromoTextUpdateWithNestedWhereUniqueAndPositionInput: PromoTextUpdateWithNestedWhereUniqueAndPositionInput;
  PromoTextUpdateWithNestedWhereUniqueInput: PromoTextUpdateWithNestedWhereUniqueInput;
  PromoTextUpsertInput: PromoTextUpsertInput;
  PromoTextUpsertLocalizationInput: PromoTextUpsertLocalizationInput;
  PromoTextUpsertWithNestedWhereUniqueAndPositionInput: PromoTextUpsertWithNestedWhereUniqueAndPositionInput;
  PromoTextUpsertWithNestedWhereUniqueInput: PromoTextUpsertWithNestedWhereUniqueInput;
  PromoTextWhereInput: PromoTextWhereInput;
  PromoTextWhereUniqueInput: PromoTextWhereUniqueInput;
  PromoTicketBanner: ResolverTypeWrapper<PromoTicketBanner>;
  PromoTicketBannerConnectInput: PromoTicketBannerConnectInput;
  PromoTicketBannerConnection: ResolverTypeWrapper<PromoTicketBannerConnection>;
  PromoTicketBannerCreateInput: PromoTicketBannerCreateInput;
  PromoTicketBannerCreateManyInlineInput: PromoTicketBannerCreateManyInlineInput;
  PromoTicketBannerCreateOneInlineInput: PromoTicketBannerCreateOneInlineInput;
  PromoTicketBannerCreateWithPositionInput: PromoTicketBannerCreateWithPositionInput;
  PromoTicketBannerEdge: ResolverTypeWrapper<PromoTicketBannerEdge>;
  PromoTicketBannerManyWhereInput: PromoTicketBannerManyWhereInput;
  PromoTicketBannerOrderByInput: PromoTicketBannerOrderByInput;
  PromoTicketBannerParent: ResolverTypeWrapper<ResolversUnionTypes['PromoTicketBannerParent']>;
  PromoTicketBannerParentConnectInput: PromoTicketBannerParentConnectInput;
  PromoTicketBannerParentCreateInput: PromoTicketBannerParentCreateInput;
  PromoTicketBannerParentCreateManyInlineInput: PromoTicketBannerParentCreateManyInlineInput;
  PromoTicketBannerParentCreateOneInlineInput: PromoTicketBannerParentCreateOneInlineInput;
  PromoTicketBannerParentUpdateInput: PromoTicketBannerParentUpdateInput;
  PromoTicketBannerParentUpdateManyInlineInput: PromoTicketBannerParentUpdateManyInlineInput;
  PromoTicketBannerParentUpdateManyWithNestedWhereInput: PromoTicketBannerParentUpdateManyWithNestedWhereInput;
  PromoTicketBannerParentUpdateOneInlineInput: PromoTicketBannerParentUpdateOneInlineInput;
  PromoTicketBannerParentUpdateWithNestedWhereUniqueInput: PromoTicketBannerParentUpdateWithNestedWhereUniqueInput;
  PromoTicketBannerParentUpsertWithNestedWhereUniqueInput: PromoTicketBannerParentUpsertWithNestedWhereUniqueInput;
  PromoTicketBannerParentWhereInput: PromoTicketBannerParentWhereInput;
  PromoTicketBannerParentWhereUniqueInput: PromoTicketBannerParentWhereUniqueInput;
  PromoTicketBannerUpdateInput: PromoTicketBannerUpdateInput;
  PromoTicketBannerUpdateManyInlineInput: PromoTicketBannerUpdateManyInlineInput;
  PromoTicketBannerUpdateManyInput: PromoTicketBannerUpdateManyInput;
  PromoTicketBannerUpdateManyWithNestedWhereInput: PromoTicketBannerUpdateManyWithNestedWhereInput;
  PromoTicketBannerUpdateOneInlineInput: PromoTicketBannerUpdateOneInlineInput;
  PromoTicketBannerUpdateWithNestedWhereUniqueAndPositionInput: PromoTicketBannerUpdateWithNestedWhereUniqueAndPositionInput;
  PromoTicketBannerUpdateWithNestedWhereUniqueInput: PromoTicketBannerUpdateWithNestedWhereUniqueInput;
  PromoTicketBannerUpsertInput: PromoTicketBannerUpsertInput;
  PromoTicketBannerUpsertWithNestedWhereUniqueAndPositionInput: PromoTicketBannerUpsertWithNestedWhereUniqueAndPositionInput;
  PromoTicketBannerUpsertWithNestedWhereUniqueInput: PromoTicketBannerUpsertWithNestedWhereUniqueInput;
  PromoTicketBannerWhereInput: PromoTicketBannerWhereInput;
  PromoTicketBannerWhereUniqueInput: PromoTicketBannerWhereUniqueInput;
  PromoTicketText: ResolverTypeWrapper<PromoTicketText>;
  PromoTicketTextConnectInput: PromoTicketTextConnectInput;
  PromoTicketTextConnection: ResolverTypeWrapper<PromoTicketTextConnection>;
  PromoTicketTextCreateInput: PromoTicketTextCreateInput;
  PromoTicketTextCreateManyInlineInput: PromoTicketTextCreateManyInlineInput;
  PromoTicketTextCreateOneInlineInput: PromoTicketTextCreateOneInlineInput;
  PromoTicketTextCreateWithPositionInput: PromoTicketTextCreateWithPositionInput;
  PromoTicketTextEdge: ResolverTypeWrapper<PromoTicketTextEdge>;
  PromoTicketTextManyWhereInput: PromoTicketTextManyWhereInput;
  PromoTicketTextOrderByInput: PromoTicketTextOrderByInput;
  PromoTicketTextParent: ResolverTypeWrapper<ResolversUnionTypes['PromoTicketTextParent']>;
  PromoTicketTextParentConnectInput: PromoTicketTextParentConnectInput;
  PromoTicketTextParentCreateInput: PromoTicketTextParentCreateInput;
  PromoTicketTextParentCreateManyInlineInput: PromoTicketTextParentCreateManyInlineInput;
  PromoTicketTextParentCreateOneInlineInput: PromoTicketTextParentCreateOneInlineInput;
  PromoTicketTextParentUpdateInput: PromoTicketTextParentUpdateInput;
  PromoTicketTextParentUpdateManyInlineInput: PromoTicketTextParentUpdateManyInlineInput;
  PromoTicketTextParentUpdateManyWithNestedWhereInput: PromoTicketTextParentUpdateManyWithNestedWhereInput;
  PromoTicketTextParentUpdateOneInlineInput: PromoTicketTextParentUpdateOneInlineInput;
  PromoTicketTextParentUpdateWithNestedWhereUniqueInput: PromoTicketTextParentUpdateWithNestedWhereUniqueInput;
  PromoTicketTextParentUpsertWithNestedWhereUniqueInput: PromoTicketTextParentUpsertWithNestedWhereUniqueInput;
  PromoTicketTextParentWhereInput: PromoTicketTextParentWhereInput;
  PromoTicketTextParentWhereUniqueInput: PromoTicketTextParentWhereUniqueInput;
  PromoTicketTextUpdateInput: PromoTicketTextUpdateInput;
  PromoTicketTextUpdateManyInlineInput: PromoTicketTextUpdateManyInlineInput;
  PromoTicketTextUpdateManyInput: PromoTicketTextUpdateManyInput;
  PromoTicketTextUpdateManyWithNestedWhereInput: PromoTicketTextUpdateManyWithNestedWhereInput;
  PromoTicketTextUpdateOneInlineInput: PromoTicketTextUpdateOneInlineInput;
  PromoTicketTextUpdateWithNestedWhereUniqueAndPositionInput: PromoTicketTextUpdateWithNestedWhereUniqueAndPositionInput;
  PromoTicketTextUpdateWithNestedWhereUniqueInput: PromoTicketTextUpdateWithNestedWhereUniqueInput;
  PromoTicketTextUpsertInput: PromoTicketTextUpsertInput;
  PromoTicketTextUpsertWithNestedWhereUniqueAndPositionInput: PromoTicketTextUpsertWithNestedWhereUniqueAndPositionInput;
  PromoTicketTextUpsertWithNestedWhereUniqueInput: PromoTicketTextUpsertWithNestedWhereUniqueInput;
  PromoTicketTextWhereInput: PromoTicketTextWhereInput;
  PromoTicketTextWhereUniqueInput: PromoTicketTextWhereUniqueInput;
  PromoUpdateInput: PromoUpdateInput;
  PromoUpdateLocalizationDataInput: PromoUpdateLocalizationDataInput;
  PromoUpdateLocalizationInput: PromoUpdateLocalizationInput;
  PromoUpdateLocalizationsInput: PromoUpdateLocalizationsInput;
  PromoUpdateManyInlineInput: PromoUpdateManyInlineInput;
  PromoUpdateManyInput: PromoUpdateManyInput;
  PromoUpdateManyLocalizationDataInput: PromoUpdateManyLocalizationDataInput;
  PromoUpdateManyLocalizationInput: PromoUpdateManyLocalizationInput;
  PromoUpdateManyLocalizationsInput: PromoUpdateManyLocalizationsInput;
  PromoUpdateManyWithNestedWhereInput: PromoUpdateManyWithNestedWhereInput;
  PromoUpdateOneInlineInput: PromoUpdateOneInlineInput;
  PromoUpdateWithNestedWhereUniqueInput: PromoUpdateWithNestedWhereUniqueInput;
  PromoUpsertInput: PromoUpsertInput;
  PromoUpsertLocalizationInput: PromoUpsertLocalizationInput;
  PromoUpsertWithNestedWhereUniqueInput: PromoUpsertWithNestedWhereUniqueInput;
  PromoWhereComparatorInput: PromoWhereComparatorInput;
  PromoWhereInput: PromoWhereInput;
  PromoWhereStageInput: PromoWhereStageInput;
  PromoWhereUniqueInput: PromoWhereUniqueInput;
  PromotemplateUnion: ResolverTypeWrapper<ResolversUnionTypes['PromotemplateUnion']>;
  PromotemplateUnionConnectInput: PromotemplateUnionConnectInput;
  PromotemplateUnionCreateInput: PromotemplateUnionCreateInput;
  PromotemplateUnionCreateManyInlineInput: PromotemplateUnionCreateManyInlineInput;
  PromotemplateUnionCreateOneInlineInput: PromotemplateUnionCreateOneInlineInput;
  PromotemplateUnionCreateWithPositionInput: PromotemplateUnionCreateWithPositionInput;
  PromotemplateUnionUpdateInput: PromotemplateUnionUpdateInput;
  PromotemplateUnionUpdateManyInlineInput: PromotemplateUnionUpdateManyInlineInput;
  PromotemplateUnionUpdateManyWithNestedWhereInput: PromotemplateUnionUpdateManyWithNestedWhereInput;
  PromotemplateUnionUpdateOneInlineInput: PromotemplateUnionUpdateOneInlineInput;
  PromotemplateUnionUpdateWithNestedWhereUniqueAndPositionInput: PromotemplateUnionUpdateWithNestedWhereUniqueAndPositionInput;
  PromotemplateUnionUpdateWithNestedWhereUniqueInput: PromotemplateUnionUpdateWithNestedWhereUniqueInput;
  PromotemplateUnionUpsertWithNestedWhereUniqueAndPositionInput: PromotemplateUnionUpsertWithNestedWhereUniqueAndPositionInput;
  PromotemplateUnionUpsertWithNestedWhereUniqueInput: PromotemplateUnionUpsertWithNestedWhereUniqueInput;
  PromotemplateUnionWhereInput: PromotemplateUnionWhereInput;
  PromotemplateUnionWhereUniqueInput: PromotemplateUnionWhereUniqueInput;
  PublishLocaleInput: PublishLocaleInput;
  Query: ResolverTypeWrapper<{}>;
  RGBA: ResolverTypeWrapper<Rgba>;
  RGBAHue: ResolverTypeWrapper<Scalars['RGBAHue']>;
  RGBAInput: RgbaInput;
  RGBATransparency: ResolverTypeWrapper<Scalars['RGBATransparency']>;
  ReservationText: ResolverTypeWrapper<ReservationText>;
  ReservationTextConnectInput: ReservationTextConnectInput;
  ReservationTextConnection: ResolverTypeWrapper<ReservationTextConnection>;
  ReservationTextCreateInput: ReservationTextCreateInput;
  ReservationTextCreateLocalizationDataInput: ReservationTextCreateLocalizationDataInput;
  ReservationTextCreateLocalizationInput: ReservationTextCreateLocalizationInput;
  ReservationTextCreateLocalizationsInput: ReservationTextCreateLocalizationsInput;
  ReservationTextCreateManyInlineInput: ReservationTextCreateManyInlineInput;
  ReservationTextCreateOneInlineInput: ReservationTextCreateOneInlineInput;
  ReservationTextEdge: ResolverTypeWrapper<ReservationTextEdge>;
  ReservationTextManyWhereInput: ReservationTextManyWhereInput;
  ReservationTextOrderByInput: ReservationTextOrderByInput;
  ReservationTextType: ReservationTextType;
  ReservationTextUpdateInput: ReservationTextUpdateInput;
  ReservationTextUpdateLocalizationDataInput: ReservationTextUpdateLocalizationDataInput;
  ReservationTextUpdateLocalizationInput: ReservationTextUpdateLocalizationInput;
  ReservationTextUpdateLocalizationsInput: ReservationTextUpdateLocalizationsInput;
  ReservationTextUpdateManyInlineInput: ReservationTextUpdateManyInlineInput;
  ReservationTextUpdateManyInput: ReservationTextUpdateManyInput;
  ReservationTextUpdateManyLocalizationDataInput: ReservationTextUpdateManyLocalizationDataInput;
  ReservationTextUpdateManyLocalizationInput: ReservationTextUpdateManyLocalizationInput;
  ReservationTextUpdateManyLocalizationsInput: ReservationTextUpdateManyLocalizationsInput;
  ReservationTextUpdateManyWithNestedWhereInput: ReservationTextUpdateManyWithNestedWhereInput;
  ReservationTextUpdateOneInlineInput: ReservationTextUpdateOneInlineInput;
  ReservationTextUpdateWithNestedWhereUniqueInput: ReservationTextUpdateWithNestedWhereUniqueInput;
  ReservationTextUpsertInput: ReservationTextUpsertInput;
  ReservationTextUpsertLocalizationInput: ReservationTextUpsertLocalizationInput;
  ReservationTextUpsertWithNestedWhereUniqueInput: ReservationTextUpsertWithNestedWhereUniqueInput;
  ReservationTextWhereComparatorInput: ReservationTextWhereComparatorInput;
  ReservationTextWhereInput: ReservationTextWhereInput;
  ReservationTextWhereStageInput: ReservationTextWhereStageInput;
  ReservationTextWhereUniqueInput: ReservationTextWhereUniqueInput;
  RichText: ResolverTypeWrapper<RichText>;
  RichTextAST: ResolverTypeWrapper<Scalars['RichTextAST']>;
  Sandbox: ResolverTypeWrapper<Sandbox>;
  SandboxConnectInput: SandboxConnectInput;
  SandboxConnection: ResolverTypeWrapper<SandboxConnection>;
  SandboxCreateInput: SandboxCreateInput;
  SandboxCreateLocalizationDataInput: SandboxCreateLocalizationDataInput;
  SandboxCreateLocalizationInput: SandboxCreateLocalizationInput;
  SandboxCreateLocalizationsInput: SandboxCreateLocalizationsInput;
  SandboxCreateManyInlineInput: SandboxCreateManyInlineInput;
  SandboxCreateOneInlineInput: SandboxCreateOneInlineInput;
  SandboxEdge: ResolverTypeWrapper<SandboxEdge>;
  SandboxManyWhereInput: SandboxManyWhereInput;
  SandboxOrderByInput: SandboxOrderByInput;
  SandboxUpdateInput: SandboxUpdateInput;
  SandboxUpdateLocalizationDataInput: SandboxUpdateLocalizationDataInput;
  SandboxUpdateLocalizationInput: SandboxUpdateLocalizationInput;
  SandboxUpdateLocalizationsInput: SandboxUpdateLocalizationsInput;
  SandboxUpdateManyInlineInput: SandboxUpdateManyInlineInput;
  SandboxUpdateManyInput: SandboxUpdateManyInput;
  SandboxUpdateManyLocalizationDataInput: SandboxUpdateManyLocalizationDataInput;
  SandboxUpdateManyLocalizationInput: SandboxUpdateManyLocalizationInput;
  SandboxUpdateManyLocalizationsInput: SandboxUpdateManyLocalizationsInput;
  SandboxUpdateManyWithNestedWhereInput: SandboxUpdateManyWithNestedWhereInput;
  SandboxUpdateOneInlineInput: SandboxUpdateOneInlineInput;
  SandboxUpdateWithNestedWhereUniqueInput: SandboxUpdateWithNestedWhereUniqueInput;
  SandboxUpsertInput: SandboxUpsertInput;
  SandboxUpsertLocalizationInput: SandboxUpsertLocalizationInput;
  SandboxUpsertWithNestedWhereUniqueInput: SandboxUpsertWithNestedWhereUniqueInput;
  SandboxWhereComparatorInput: SandboxWhereComparatorInput;
  SandboxWhereInput: SandboxWhereInput;
  SandboxWhereStageInput: SandboxWhereStageInput;
  SandboxWhereUniqueInput: SandboxWhereUniqueInput;
  ScheduledOperation: ResolverTypeWrapper<
    Omit<ScheduledOperation, 'affectedDocuments'> & {
      affectedDocuments: Array<ResolversTypes['ScheduledOperationAffectedDocument']>;
    }
  >;
  ScheduledOperationAffectedDocument: ResolverTypeWrapper<
    ResolversUnionTypes['ScheduledOperationAffectedDocument']
  >;
  ScheduledOperationConnectInput: ScheduledOperationConnectInput;
  ScheduledOperationConnection: ResolverTypeWrapper<ScheduledOperationConnection>;
  ScheduledOperationCreateManyInlineInput: ScheduledOperationCreateManyInlineInput;
  ScheduledOperationCreateOneInlineInput: ScheduledOperationCreateOneInlineInput;
  ScheduledOperationEdge: ResolverTypeWrapper<ScheduledOperationEdge>;
  ScheduledOperationManyWhereInput: ScheduledOperationManyWhereInput;
  ScheduledOperationOrderByInput: ScheduledOperationOrderByInput;
  ScheduledOperationStatus: ScheduledOperationStatus;
  ScheduledOperationUpdateManyInlineInput: ScheduledOperationUpdateManyInlineInput;
  ScheduledOperationUpdateOneInlineInput: ScheduledOperationUpdateOneInlineInput;
  ScheduledOperationWhereInput: ScheduledOperationWhereInput;
  ScheduledOperationWhereUniqueInput: ScheduledOperationWhereUniqueInput;
  ScheduledRelease: ResolverTypeWrapper<ScheduledRelease>;
  ScheduledReleaseConnectInput: ScheduledReleaseConnectInput;
  ScheduledReleaseConnection: ResolverTypeWrapper<ScheduledReleaseConnection>;
  ScheduledReleaseCreateInput: ScheduledReleaseCreateInput;
  ScheduledReleaseCreateManyInlineInput: ScheduledReleaseCreateManyInlineInput;
  ScheduledReleaseCreateOneInlineInput: ScheduledReleaseCreateOneInlineInput;
  ScheduledReleaseEdge: ResolverTypeWrapper<ScheduledReleaseEdge>;
  ScheduledReleaseManyWhereInput: ScheduledReleaseManyWhereInput;
  ScheduledReleaseOrderByInput: ScheduledReleaseOrderByInput;
  ScheduledReleaseStatus: ScheduledReleaseStatus;
  ScheduledReleaseUpdateInput: ScheduledReleaseUpdateInput;
  ScheduledReleaseUpdateManyInlineInput: ScheduledReleaseUpdateManyInlineInput;
  ScheduledReleaseUpdateManyInput: ScheduledReleaseUpdateManyInput;
  ScheduledReleaseUpdateManyWithNestedWhereInput: ScheduledReleaseUpdateManyWithNestedWhereInput;
  ScheduledReleaseUpdateOneInlineInput: ScheduledReleaseUpdateOneInlineInput;
  ScheduledReleaseUpdateWithNestedWhereUniqueInput: ScheduledReleaseUpdateWithNestedWhereUniqueInput;
  ScheduledReleaseUpsertInput: ScheduledReleaseUpsertInput;
  ScheduledReleaseUpsertWithNestedWhereUniqueInput: ScheduledReleaseUpsertWithNestedWhereUniqueInput;
  ScheduledReleaseWhereInput: ScheduledReleaseWhereInput;
  ScheduledReleaseWhereUniqueInput: ScheduledReleaseWhereUniqueInput;
  Seo: ResolverTypeWrapper<Seo>;
  SeoConnectInput: SeoConnectInput;
  SeoConnection: ResolverTypeWrapper<SeoConnection>;
  SeoCreateInput: SeoCreateInput;
  SeoCreateLocalizationDataInput: SeoCreateLocalizationDataInput;
  SeoCreateLocalizationInput: SeoCreateLocalizationInput;
  SeoCreateLocalizationsInput: SeoCreateLocalizationsInput;
  SeoCreateManyInlineInput: SeoCreateManyInlineInput;
  SeoCreateOneInlineInput: SeoCreateOneInlineInput;
  SeoEdge: ResolverTypeWrapper<SeoEdge>;
  SeoManyWhereInput: SeoManyWhereInput;
  SeoOrderByInput: SeoOrderByInput;
  SeoUpdateInput: SeoUpdateInput;
  SeoUpdateLocalizationDataInput: SeoUpdateLocalizationDataInput;
  SeoUpdateLocalizationInput: SeoUpdateLocalizationInput;
  SeoUpdateLocalizationsInput: SeoUpdateLocalizationsInput;
  SeoUpdateManyInlineInput: SeoUpdateManyInlineInput;
  SeoUpdateManyInput: SeoUpdateManyInput;
  SeoUpdateManyLocalizationDataInput: SeoUpdateManyLocalizationDataInput;
  SeoUpdateManyLocalizationInput: SeoUpdateManyLocalizationInput;
  SeoUpdateManyLocalizationsInput: SeoUpdateManyLocalizationsInput;
  SeoUpdateManyWithNestedWhereInput: SeoUpdateManyWithNestedWhereInput;
  SeoUpdateOneInlineInput: SeoUpdateOneInlineInput;
  SeoUpdateWithNestedWhereUniqueInput: SeoUpdateWithNestedWhereUniqueInput;
  SeoUpsertInput: SeoUpsertInput;
  SeoUpsertLocalizationInput: SeoUpsertLocalizationInput;
  SeoUpsertWithNestedWhereUniqueInput: SeoUpsertWithNestedWhereUniqueInput;
  SeoWhereComparatorInput: SeoWhereComparatorInput;
  SeoWhereInput: SeoWhereInput;
  SeoWhereStageInput: SeoWhereStageInput;
  SeoWhereUniqueInput: SeoWhereUniqueInput;
  SiteSection: SiteSection;
  SkuProperty: ResolverTypeWrapper<SkuProperty>;
  SkuPropertyConnectInput: SkuPropertyConnectInput;
  SkuPropertyConnection: ResolverTypeWrapper<SkuPropertyConnection>;
  SkuPropertyCreateInput: SkuPropertyCreateInput;
  SkuPropertyCreateManyInlineInput: SkuPropertyCreateManyInlineInput;
  SkuPropertyCreateOneInlineInput: SkuPropertyCreateOneInlineInput;
  SkuPropertyCreateWithPositionInput: SkuPropertyCreateWithPositionInput;
  SkuPropertyEdge: ResolverTypeWrapper<SkuPropertyEdge>;
  SkuPropertyManyWhereInput: SkuPropertyManyWhereInput;
  SkuPropertyOrderByInput: SkuPropertyOrderByInput;
  SkuPropertyParent: ResolverTypeWrapper<ResolversUnionTypes['SkuPropertyParent']>;
  SkuPropertyParentConnectInput: SkuPropertyParentConnectInput;
  SkuPropertyParentCreateInput: SkuPropertyParentCreateInput;
  SkuPropertyParentCreateManyInlineInput: SkuPropertyParentCreateManyInlineInput;
  SkuPropertyParentCreateOneInlineInput: SkuPropertyParentCreateOneInlineInput;
  SkuPropertyParentUpdateInput: SkuPropertyParentUpdateInput;
  SkuPropertyParentUpdateManyInlineInput: SkuPropertyParentUpdateManyInlineInput;
  SkuPropertyParentUpdateManyWithNestedWhereInput: SkuPropertyParentUpdateManyWithNestedWhereInput;
  SkuPropertyParentUpdateOneInlineInput: SkuPropertyParentUpdateOneInlineInput;
  SkuPropertyParentUpdateWithNestedWhereUniqueInput: SkuPropertyParentUpdateWithNestedWhereUniqueInput;
  SkuPropertyParentUpsertWithNestedWhereUniqueInput: SkuPropertyParentUpsertWithNestedWhereUniqueInput;
  SkuPropertyParentWhereInput: SkuPropertyParentWhereInput;
  SkuPropertyParentWhereUniqueInput: SkuPropertyParentWhereUniqueInput;
  SkuPropertyUpdateInput: SkuPropertyUpdateInput;
  SkuPropertyUpdateManyInlineInput: SkuPropertyUpdateManyInlineInput;
  SkuPropertyUpdateManyInput: SkuPropertyUpdateManyInput;
  SkuPropertyUpdateManyWithNestedWhereInput: SkuPropertyUpdateManyWithNestedWhereInput;
  SkuPropertyUpdateOneInlineInput: SkuPropertyUpdateOneInlineInput;
  SkuPropertyUpdateWithNestedWhereUniqueAndPositionInput: SkuPropertyUpdateWithNestedWhereUniqueAndPositionInput;
  SkuPropertyUpdateWithNestedWhereUniqueInput: SkuPropertyUpdateWithNestedWhereUniqueInput;
  SkuPropertyUpsertInput: SkuPropertyUpsertInput;
  SkuPropertyUpsertWithNestedWhereUniqueAndPositionInput: SkuPropertyUpsertWithNestedWhereUniqueAndPositionInput;
  SkuPropertyUpsertWithNestedWhereUniqueInput: SkuPropertyUpsertWithNestedWhereUniqueInput;
  SkuPropertyWhereInput: SkuPropertyWhereInput;
  SkuPropertyWhereUniqueInput: SkuPropertyWhereUniqueInput;
  SkuTicketTypeProperty: SkuTicketTypeProperty;
  Stage: Stage;
  String: ResolverTypeWrapper<Scalars['String']>;
  SystemDateTimeFieldVariation: SystemDateTimeFieldVariation;
  Text: ResolverTypeWrapper<Text>;
  TextConnectInput: TextConnectInput;
  TextConnection: ResolverTypeWrapper<TextConnection>;
  TextCreateInput: TextCreateInput;
  TextCreateLocalizationDataInput: TextCreateLocalizationDataInput;
  TextCreateLocalizationInput: TextCreateLocalizationInput;
  TextCreateLocalizationsInput: TextCreateLocalizationsInput;
  TextCreateManyInlineInput: TextCreateManyInlineInput;
  TextCreateOneInlineInput: TextCreateOneInlineInput;
  TextEdge: ResolverTypeWrapper<TextEdge>;
  TextGroup: ResolverTypeWrapper<
    Omit<TextGroup, 'text'> & { text: Array<ResolversTypes['TextGroupText']> }
  >;
  TextGroupConnectInput: TextGroupConnectInput;
  TextGroupConnection: ResolverTypeWrapper<TextGroupConnection>;
  TextGroupCreateInput: TextGroupCreateInput;
  TextGroupCreateManyInlineInput: TextGroupCreateManyInlineInput;
  TextGroupCreateOneInlineInput: TextGroupCreateOneInlineInput;
  TextGroupEdge: ResolverTypeWrapper<TextGroupEdge>;
  TextGroupManyWhereInput: TextGroupManyWhereInput;
  TextGroupOrderByInput: TextGroupOrderByInput;
  TextGroupText: ResolverTypeWrapper<ResolversUnionTypes['TextGroupText']>;
  TextGroupTextConnectInput: TextGroupTextConnectInput;
  TextGroupTextCreateInput: TextGroupTextCreateInput;
  TextGroupTextCreateManyInlineInput: TextGroupTextCreateManyInlineInput;
  TextGroupTextCreateOneInlineInput: TextGroupTextCreateOneInlineInput;
  TextGroupTextUpdateInput: TextGroupTextUpdateInput;
  TextGroupTextUpdateManyInlineInput: TextGroupTextUpdateManyInlineInput;
  TextGroupTextUpdateManyWithNestedWhereInput: TextGroupTextUpdateManyWithNestedWhereInput;
  TextGroupTextUpdateOneInlineInput: TextGroupTextUpdateOneInlineInput;
  TextGroupTextUpdateWithNestedWhereUniqueInput: TextGroupTextUpdateWithNestedWhereUniqueInput;
  TextGroupTextUpsertWithNestedWhereUniqueInput: TextGroupTextUpsertWithNestedWhereUniqueInput;
  TextGroupTextWhereInput: TextGroupTextWhereInput;
  TextGroupTextWhereUniqueInput: TextGroupTextWhereUniqueInput;
  TextGroupUpdateInput: TextGroupUpdateInput;
  TextGroupUpdateManyInlineInput: TextGroupUpdateManyInlineInput;
  TextGroupUpdateManyInput: TextGroupUpdateManyInput;
  TextGroupUpdateManyWithNestedWhereInput: TextGroupUpdateManyWithNestedWhereInput;
  TextGroupUpdateOneInlineInput: TextGroupUpdateOneInlineInput;
  TextGroupUpdateWithNestedWhereUniqueInput: TextGroupUpdateWithNestedWhereUniqueInput;
  TextGroupUpsertInput: TextGroupUpsertInput;
  TextGroupUpsertWithNestedWhereUniqueInput: TextGroupUpsertWithNestedWhereUniqueInput;
  TextGroupWhereComparatorInput: TextGroupWhereComparatorInput;
  TextGroupWhereInput: TextGroupWhereInput;
  TextGroupWhereStageInput: TextGroupWhereStageInput;
  TextGroupWhereUniqueInput: TextGroupWhereUniqueInput;
  TextList: ResolverTypeWrapper<TextList>;
  TextListConnectInput: TextListConnectInput;
  TextListConnection: ResolverTypeWrapper<TextListConnection>;
  TextListCreateInput: TextListCreateInput;
  TextListCreateManyInlineInput: TextListCreateManyInlineInput;
  TextListCreateOneInlineInput: TextListCreateOneInlineInput;
  TextListEdge: ResolverTypeWrapper<TextListEdge>;
  TextListManyWhereInput: TextListManyWhereInput;
  TextListOrderByInput: TextListOrderByInput;
  TextListUpdateInput: TextListUpdateInput;
  TextListUpdateManyInlineInput: TextListUpdateManyInlineInput;
  TextListUpdateManyInput: TextListUpdateManyInput;
  TextListUpdateManyWithNestedWhereInput: TextListUpdateManyWithNestedWhereInput;
  TextListUpdateOneInlineInput: TextListUpdateOneInlineInput;
  TextListUpdateWithNestedWhereUniqueInput: TextListUpdateWithNestedWhereUniqueInput;
  TextListUpsertInput: TextListUpsertInput;
  TextListUpsertWithNestedWhereUniqueInput: TextListUpsertWithNestedWhereUniqueInput;
  TextListWhereComparatorInput: TextListWhereComparatorInput;
  TextListWhereInput: TextListWhereInput;
  TextListWhereStageInput: TextListWhereStageInput;
  TextListWhereUniqueInput: TextListWhereUniqueInput;
  TextManyWhereInput: TextManyWhereInput;
  TextOrderByInput: TextOrderByInput;
  TextUpdateInput: TextUpdateInput;
  TextUpdateLocalizationDataInput: TextUpdateLocalizationDataInput;
  TextUpdateLocalizationInput: TextUpdateLocalizationInput;
  TextUpdateLocalizationsInput: TextUpdateLocalizationsInput;
  TextUpdateManyInlineInput: TextUpdateManyInlineInput;
  TextUpdateManyInput: TextUpdateManyInput;
  TextUpdateManyLocalizationDataInput: TextUpdateManyLocalizationDataInput;
  TextUpdateManyLocalizationInput: TextUpdateManyLocalizationInput;
  TextUpdateManyLocalizationsInput: TextUpdateManyLocalizationsInput;
  TextUpdateManyWithNestedWhereInput: TextUpdateManyWithNestedWhereInput;
  TextUpdateOneInlineInput: TextUpdateOneInlineInput;
  TextUpdateWithNestedWhereUniqueInput: TextUpdateWithNestedWhereUniqueInput;
  TextUpsertInput: TextUpsertInput;
  TextUpsertLocalizationInput: TextUpsertLocalizationInput;
  TextUpsertWithNestedWhereUniqueInput: TextUpsertWithNestedWhereUniqueInput;
  TextWhereComparatorInput: TextWhereComparatorInput;
  TextWhereInput: TextWhereInput;
  TextWhereStageInput: TextWhereStageInput;
  TextWhereUniqueInput: TextWhereUniqueInput;
  TicketGroup: ResolverTypeWrapper<TicketGroup>;
  TicketGroupConnectInput: TicketGroupConnectInput;
  TicketGroupConnection: ResolverTypeWrapper<TicketGroupConnection>;
  TicketGroupCreateInput: TicketGroupCreateInput;
  TicketGroupCreateManyInlineInput: TicketGroupCreateManyInlineInput;
  TicketGroupCreateOneInlineInput: TicketGroupCreateOneInlineInput;
  TicketGroupEdge: ResolverTypeWrapper<TicketGroupEdge>;
  TicketGroupManyWhereInput: TicketGroupManyWhereInput;
  TicketGroupOrderByInput: TicketGroupOrderByInput;
  TicketGroupUpdateInput: TicketGroupUpdateInput;
  TicketGroupUpdateManyInlineInput: TicketGroupUpdateManyInlineInput;
  TicketGroupUpdateManyInput: TicketGroupUpdateManyInput;
  TicketGroupUpdateManyWithNestedWhereInput: TicketGroupUpdateManyWithNestedWhereInput;
  TicketGroupUpdateOneInlineInput: TicketGroupUpdateOneInlineInput;
  TicketGroupUpdateWithNestedWhereUniqueInput: TicketGroupUpdateWithNestedWhereUniqueInput;
  TicketGroupUpsertInput: TicketGroupUpsertInput;
  TicketGroupUpsertWithNestedWhereUniqueInput: TicketGroupUpsertWithNestedWhereUniqueInput;
  TicketGroupWhereComparatorInput: TicketGroupWhereComparatorInput;
  TicketGroupWhereInput: TicketGroupWhereInput;
  TicketGroupWhereStageInput: TicketGroupWhereStageInput;
  TicketGroupWhereUniqueInput: TicketGroupWhereUniqueInput;
  TitleDescription: ResolverTypeWrapper<TitleDescription>;
  TitleDescriptionConnectInput: TitleDescriptionConnectInput;
  TitleDescriptionConnection: ResolverTypeWrapper<TitleDescriptionConnection>;
  TitleDescriptionCreateInput: TitleDescriptionCreateInput;
  TitleDescriptionCreateLocalizationDataInput: TitleDescriptionCreateLocalizationDataInput;
  TitleDescriptionCreateLocalizationInput: TitleDescriptionCreateLocalizationInput;
  TitleDescriptionCreateLocalizationsInput: TitleDescriptionCreateLocalizationsInput;
  TitleDescriptionCreateManyInlineInput: TitleDescriptionCreateManyInlineInput;
  TitleDescriptionCreateOneInlineInput: TitleDescriptionCreateOneInlineInput;
  TitleDescriptionEdge: ResolverTypeWrapper<TitleDescriptionEdge>;
  TitleDescriptionManyWhereInput: TitleDescriptionManyWhereInput;
  TitleDescriptionOrderByInput: TitleDescriptionOrderByInput;
  TitleDescriptionUpdateInput: TitleDescriptionUpdateInput;
  TitleDescriptionUpdateLocalizationDataInput: TitleDescriptionUpdateLocalizationDataInput;
  TitleDescriptionUpdateLocalizationInput: TitleDescriptionUpdateLocalizationInput;
  TitleDescriptionUpdateLocalizationsInput: TitleDescriptionUpdateLocalizationsInput;
  TitleDescriptionUpdateManyInlineInput: TitleDescriptionUpdateManyInlineInput;
  TitleDescriptionUpdateManyInput: TitleDescriptionUpdateManyInput;
  TitleDescriptionUpdateManyLocalizationDataInput: TitleDescriptionUpdateManyLocalizationDataInput;
  TitleDescriptionUpdateManyLocalizationInput: TitleDescriptionUpdateManyLocalizationInput;
  TitleDescriptionUpdateManyLocalizationsInput: TitleDescriptionUpdateManyLocalizationsInput;
  TitleDescriptionUpdateManyWithNestedWhereInput: TitleDescriptionUpdateManyWithNestedWhereInput;
  TitleDescriptionUpdateOneInlineInput: TitleDescriptionUpdateOneInlineInput;
  TitleDescriptionUpdateWithNestedWhereUniqueInput: TitleDescriptionUpdateWithNestedWhereUniqueInput;
  TitleDescriptionUpsertInput: TitleDescriptionUpsertInput;
  TitleDescriptionUpsertLocalizationInput: TitleDescriptionUpsertLocalizationInput;
  TitleDescriptionUpsertWithNestedWhereUniqueInput: TitleDescriptionUpsertWithNestedWhereUniqueInput;
  TitleDescriptionWhereComparatorInput: TitleDescriptionWhereComparatorInput;
  TitleDescriptionWhereInput: TitleDescriptionWhereInput;
  TitleDescriptionWhereStageInput: TitleDescriptionWhereStageInput;
  TitleDescriptionWhereUniqueInput: TitleDescriptionWhereUniqueInput;
  UnpublishLocaleInput: UnpublishLocaleInput;
  User: ResolverTypeWrapper<User>;
  UserConnectInput: UserConnectInput;
  UserConnection: ResolverTypeWrapper<UserConnection>;
  UserCreateManyInlineInput: UserCreateManyInlineInput;
  UserCreateOneInlineInput: UserCreateOneInlineInput;
  UserEdge: ResolverTypeWrapper<UserEdge>;
  UserKind: UserKind;
  UserManyWhereInput: UserManyWhereInput;
  UserOrderByInput: UserOrderByInput;
  UserUpdateManyInlineInput: UserUpdateManyInlineInput;
  UserUpdateOneInlineInput: UserUpdateOneInlineInput;
  UserWhereComparatorInput: UserWhereComparatorInput;
  UserWhereInput: UserWhereInput;
  UserWhereStageInput: UserWhereStageInput;
  UserWhereUniqueInput: UserWhereUniqueInput;
  Version: ResolverTypeWrapper<Version>;
  VersionWhereInput: VersionWhereInput;
  Video: ResolverTypeWrapper<Video>;
  VideoConnectInput: VideoConnectInput;
  VideoConnection: ResolverTypeWrapper<VideoConnection>;
  VideoCreateInput: VideoCreateInput;
  VideoCreateLocalizationDataInput: VideoCreateLocalizationDataInput;
  VideoCreateLocalizationInput: VideoCreateLocalizationInput;
  VideoCreateLocalizationsInput: VideoCreateLocalizationsInput;
  VideoCreateManyInlineInput: VideoCreateManyInlineInput;
  VideoCreateOneInlineInput: VideoCreateOneInlineInput;
  VideoEdge: ResolverTypeWrapper<VideoEdge>;
  VideoManyWhereInput: VideoManyWhereInput;
  VideoOrderByInput: VideoOrderByInput;
  VideoUpdateInput: VideoUpdateInput;
  VideoUpdateLocalizationDataInput: VideoUpdateLocalizationDataInput;
  VideoUpdateLocalizationInput: VideoUpdateLocalizationInput;
  VideoUpdateLocalizationsInput: VideoUpdateLocalizationsInput;
  VideoUpdateManyInlineInput: VideoUpdateManyInlineInput;
  VideoUpdateManyInput: VideoUpdateManyInput;
  VideoUpdateManyLocalizationDataInput: VideoUpdateManyLocalizationDataInput;
  VideoUpdateManyLocalizationInput: VideoUpdateManyLocalizationInput;
  VideoUpdateManyLocalizationsInput: VideoUpdateManyLocalizationsInput;
  VideoUpdateManyWithNestedWhereInput: VideoUpdateManyWithNestedWhereInput;
  VideoUpdateOneInlineInput: VideoUpdateOneInlineInput;
  VideoUpdateWithNestedWhereUniqueInput: VideoUpdateWithNestedWhereUniqueInput;
  VideoUpsertInput: VideoUpsertInput;
  VideoUpsertLocalizationInput: VideoUpsertLocalizationInput;
  VideoUpsertWithNestedWhereUniqueInput: VideoUpsertWithNestedWhereUniqueInput;
  VideoWhereComparatorInput: VideoWhereComparatorInput;
  VideoWhereInput: VideoWhereInput;
  VideoWhereStageInput: VideoWhereStageInput;
  VideoWhereUniqueInput: VideoWhereUniqueInput;
  _FilterKind: _FilterKind;
  _MutationInputFieldKind: _MutationInputFieldKind;
  _MutationKind: _MutationKind;
  _OrderDirection: _OrderDirection;
  _RelationInputCardinality: _RelationInputCardinality;
  _RelationInputKind: _RelationInputKind;
  _RelationKind: _RelationKind;
  _SystemDateTimeFieldVariation: _SystemDateTimeFieldVariation;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  AddOn: AddOn;
  AddOnConnectInput: AddOnConnectInput;
  AddOnConnection: AddOnConnection;
  AddOnCreateInput: AddOnCreateInput;
  AddOnCreateLocalizationDataInput: AddOnCreateLocalizationDataInput;
  AddOnCreateLocalizationInput: AddOnCreateLocalizationInput;
  AddOnCreateLocalizationsInput: AddOnCreateLocalizationsInput;
  AddOnCreateManyInlineInput: AddOnCreateManyInlineInput;
  AddOnCreateOneInlineInput: AddOnCreateOneInlineInput;
  AddOnEdge: AddOnEdge;
  AddOnManyWhereInput: AddOnManyWhereInput;
  AddOnUpdateInput: AddOnUpdateInput;
  AddOnUpdateLocalizationDataInput: AddOnUpdateLocalizationDataInput;
  AddOnUpdateLocalizationInput: AddOnUpdateLocalizationInput;
  AddOnUpdateLocalizationsInput: AddOnUpdateLocalizationsInput;
  AddOnUpdateManyInlineInput: AddOnUpdateManyInlineInput;
  AddOnUpdateManyInput: AddOnUpdateManyInput;
  AddOnUpdateManyLocalizationDataInput: AddOnUpdateManyLocalizationDataInput;
  AddOnUpdateManyLocalizationInput: AddOnUpdateManyLocalizationInput;
  AddOnUpdateManyLocalizationsInput: AddOnUpdateManyLocalizationsInput;
  AddOnUpdateManyWithNestedWhereInput: AddOnUpdateManyWithNestedWhereInput;
  AddOnUpdateOneInlineInput: AddOnUpdateOneInlineInput;
  AddOnUpdateWithNestedWhereUniqueInput: AddOnUpdateWithNestedWhereUniqueInput;
  AddOnUpsertInput: AddOnUpsertInput;
  AddOnUpsertLocalizationInput: AddOnUpsertLocalizationInput;
  AddOnUpsertWithNestedWhereUniqueInput: AddOnUpsertWithNestedWhereUniqueInput;
  AddOnWhereComparatorInput: AddOnWhereComparatorInput;
  AddOnWhereInput: AddOnWhereInput;
  AddOnWhereStageInput: AddOnWhereStageInput;
  AddOnWhereUniqueInput: AddOnWhereUniqueInput;
  Aggregate: Aggregate;
  AlacarteTicket: AlacarteTicket;
  AlacarteTicketConnectInput: AlacarteTicketConnectInput;
  AlacarteTicketConnection: AlacarteTicketConnection;
  AlacarteTicketCreateInput: AlacarteTicketCreateInput;
  AlacarteTicketCreateManyInlineInput: AlacarteTicketCreateManyInlineInput;
  AlacarteTicketCreateOneInlineInput: AlacarteTicketCreateOneInlineInput;
  AlacarteTicketEdge: AlacarteTicketEdge;
  AlacarteTicketManyWhereInput: AlacarteTicketManyWhereInput;
  AlacarteTicketType: AlacarteTicketType;
  AlacarteTicketTypeConnectInput: AlacarteTicketTypeConnectInput;
  AlacarteTicketTypeConnection: AlacarteTicketTypeConnection;
  AlacarteTicketTypeCreateInput: AlacarteTicketTypeCreateInput;
  AlacarteTicketTypeCreateManyInlineInput: AlacarteTicketTypeCreateManyInlineInput;
  AlacarteTicketTypeCreateOneInlineInput: AlacarteTicketTypeCreateOneInlineInput;
  AlacarteTicketTypeEdge: AlacarteTicketTypeEdge;
  AlacarteTicketTypeManyWhereInput: AlacarteTicketTypeManyWhereInput;
  AlacarteTicketTypeUpdateInput: AlacarteTicketTypeUpdateInput;
  AlacarteTicketTypeUpdateManyInlineInput: AlacarteTicketTypeUpdateManyInlineInput;
  AlacarteTicketTypeUpdateManyInput: AlacarteTicketTypeUpdateManyInput;
  AlacarteTicketTypeUpdateManyWithNestedWhereInput: AlacarteTicketTypeUpdateManyWithNestedWhereInput;
  AlacarteTicketTypeUpdateOneInlineInput: AlacarteTicketTypeUpdateOneInlineInput;
  AlacarteTicketTypeUpdateWithNestedWhereUniqueInput: AlacarteTicketTypeUpdateWithNestedWhereUniqueInput;
  AlacarteTicketTypeUpsertInput: AlacarteTicketTypeUpsertInput;
  AlacarteTicketTypeUpsertWithNestedWhereUniqueInput: AlacarteTicketTypeUpsertWithNestedWhereUniqueInput;
  AlacarteTicketTypeWhereComparatorInput: AlacarteTicketTypeWhereComparatorInput;
  AlacarteTicketTypeWhereInput: AlacarteTicketTypeWhereInput;
  AlacarteTicketTypeWhereStageInput: AlacarteTicketTypeWhereStageInput;
  AlacarteTicketTypeWhereUniqueInput: AlacarteTicketTypeWhereUniqueInput;
  AlacarteTicketUpdateInput: AlacarteTicketUpdateInput;
  AlacarteTicketUpdateManyInlineInput: AlacarteTicketUpdateManyInlineInput;
  AlacarteTicketUpdateManyInput: AlacarteTicketUpdateManyInput;
  AlacarteTicketUpdateManyWithNestedWhereInput: AlacarteTicketUpdateManyWithNestedWhereInput;
  AlacarteTicketUpdateOneInlineInput: AlacarteTicketUpdateOneInlineInput;
  AlacarteTicketUpdateWithNestedWhereUniqueInput: AlacarteTicketUpdateWithNestedWhereUniqueInput;
  AlacarteTicketUpsertInput: AlacarteTicketUpsertInput;
  AlacarteTicketUpsertWithNestedWhereUniqueInput: AlacarteTicketUpsertWithNestedWhereUniqueInput;
  AlacarteTicketWhereComparatorInput: AlacarteTicketWhereComparatorInput;
  AlacarteTicketWhereInput: AlacarteTicketWhereInput;
  AlacarteTicketWhereStageInput: AlacarteTicketWhereStageInput;
  AlacarteTicketWhereUniqueInput: AlacarteTicketWhereUniqueInput;
  Article: Article;
  ArticleCategory: ArticleCategory;
  ArticleCategoryConnectInput: ArticleCategoryConnectInput;
  ArticleCategoryConnection: ArticleCategoryConnection;
  ArticleCategoryCreateInput: ArticleCategoryCreateInput;
  ArticleCategoryCreateManyInlineInput: ArticleCategoryCreateManyInlineInput;
  ArticleCategoryCreateOneInlineInput: ArticleCategoryCreateOneInlineInput;
  ArticleCategoryEdge: ArticleCategoryEdge;
  ArticleCategoryManyWhereInput: ArticleCategoryManyWhereInput;
  ArticleCategoryUpdateInput: ArticleCategoryUpdateInput;
  ArticleCategoryUpdateManyInlineInput: ArticleCategoryUpdateManyInlineInput;
  ArticleCategoryUpdateManyInput: ArticleCategoryUpdateManyInput;
  ArticleCategoryUpdateManyWithNestedWhereInput: ArticleCategoryUpdateManyWithNestedWhereInput;
  ArticleCategoryUpdateOneInlineInput: ArticleCategoryUpdateOneInlineInput;
  ArticleCategoryUpdateWithNestedWhereUniqueInput: ArticleCategoryUpdateWithNestedWhereUniqueInput;
  ArticleCategoryUpsertInput: ArticleCategoryUpsertInput;
  ArticleCategoryUpsertWithNestedWhereUniqueInput: ArticleCategoryUpsertWithNestedWhereUniqueInput;
  ArticleCategoryWhereComparatorInput: ArticleCategoryWhereComparatorInput;
  ArticleCategoryWhereInput: ArticleCategoryWhereInput;
  ArticleCategoryWhereStageInput: ArticleCategoryWhereStageInput;
  ArticleCategoryWhereUniqueInput: ArticleCategoryWhereUniqueInput;
  ArticleConnectInput: ArticleConnectInput;
  ArticleConnection: ArticleConnection;
  ArticleCreateInput: ArticleCreateInput;
  ArticleCreateLocalizationDataInput: ArticleCreateLocalizationDataInput;
  ArticleCreateLocalizationInput: ArticleCreateLocalizationInput;
  ArticleCreateLocalizationsInput: ArticleCreateLocalizationsInput;
  ArticleCreateManyInlineInput: ArticleCreateManyInlineInput;
  ArticleCreateOneInlineInput: ArticleCreateOneInlineInput;
  ArticleEdge: ArticleEdge;
  ArticleManyWhereInput: ArticleManyWhereInput;
  ArticleUpdateInput: ArticleUpdateInput;
  ArticleUpdateLocalizationDataInput: ArticleUpdateLocalizationDataInput;
  ArticleUpdateLocalizationInput: ArticleUpdateLocalizationInput;
  ArticleUpdateLocalizationsInput: ArticleUpdateLocalizationsInput;
  ArticleUpdateManyInlineInput: ArticleUpdateManyInlineInput;
  ArticleUpdateManyInput: ArticleUpdateManyInput;
  ArticleUpdateManyLocalizationDataInput: ArticleUpdateManyLocalizationDataInput;
  ArticleUpdateManyLocalizationInput: ArticleUpdateManyLocalizationInput;
  ArticleUpdateManyLocalizationsInput: ArticleUpdateManyLocalizationsInput;
  ArticleUpdateManyWithNestedWhereInput: ArticleUpdateManyWithNestedWhereInput;
  ArticleUpdateOneInlineInput: ArticleUpdateOneInlineInput;
  ArticleUpdateWithNestedWhereUniqueInput: ArticleUpdateWithNestedWhereUniqueInput;
  ArticleUpsertInput: ArticleUpsertInput;
  ArticleUpsertLocalizationInput: ArticleUpsertLocalizationInput;
  ArticleUpsertWithNestedWhereUniqueInput: ArticleUpsertWithNestedWhereUniqueInput;
  ArticleWhereComparatorInput: ArticleWhereComparatorInput;
  ArticleWhereInput: ArticleWhereInput;
  ArticleWhereStageInput: ArticleWhereStageInput;
  ArticleWhereUniqueInput: ArticleWhereUniqueInput;
  Asset: Asset;
  AssetConnectInput: AssetConnectInput;
  AssetConnection: AssetConnection;
  AssetCreateInput: AssetCreateInput;
  AssetCreateLocalizationDataInput: AssetCreateLocalizationDataInput;
  AssetCreateLocalizationInput: AssetCreateLocalizationInput;
  AssetCreateLocalizationsInput: AssetCreateLocalizationsInput;
  AssetCreateManyInlineInput: AssetCreateManyInlineInput;
  AssetCreateOneInlineInput: AssetCreateOneInlineInput;
  AssetEdge: AssetEdge;
  AssetManyWhereInput: AssetManyWhereInput;
  AssetTransformationInput: AssetTransformationInput;
  AssetUpdateInput: AssetUpdateInput;
  AssetUpdateLocalizationDataInput: AssetUpdateLocalizationDataInput;
  AssetUpdateLocalizationInput: AssetUpdateLocalizationInput;
  AssetUpdateLocalizationsInput: AssetUpdateLocalizationsInput;
  AssetUpdateManyInlineInput: AssetUpdateManyInlineInput;
  AssetUpdateManyInput: AssetUpdateManyInput;
  AssetUpdateManyLocalizationDataInput: AssetUpdateManyLocalizationDataInput;
  AssetUpdateManyLocalizationInput: AssetUpdateManyLocalizationInput;
  AssetUpdateManyLocalizationsInput: AssetUpdateManyLocalizationsInput;
  AssetUpdateManyWithNestedWhereInput: AssetUpdateManyWithNestedWhereInput;
  AssetUpdateOneInlineInput: AssetUpdateOneInlineInput;
  AssetUpdateWithNestedWhereUniqueInput: AssetUpdateWithNestedWhereUniqueInput;
  AssetUpsertInput: AssetUpsertInput;
  AssetUpsertLocalizationInput: AssetUpsertLocalizationInput;
  AssetUpsertWithNestedWhereUniqueInput: AssetUpsertWithNestedWhereUniqueInput;
  AssetVariant: AssetVariant;
  AssetVariantConnectInput: AssetVariantConnectInput;
  AssetVariantConnection: AssetVariantConnection;
  AssetVariantCreateInput: AssetVariantCreateInput;
  AssetVariantCreateManyInlineInput: AssetVariantCreateManyInlineInput;
  AssetVariantCreateOneInlineInput: AssetVariantCreateOneInlineInput;
  AssetVariantCreateWithPositionInput: AssetVariantCreateWithPositionInput;
  AssetVariantEdge: AssetVariantEdge;
  AssetVariantManyWhereInput: AssetVariantManyWhereInput;
  AssetVariantParent: ResolversUnionTypes['AssetVariantParent'];
  AssetVariantParentConnectInput: AssetVariantParentConnectInput;
  AssetVariantParentCreateInput: AssetVariantParentCreateInput;
  AssetVariantParentCreateManyInlineInput: AssetVariantParentCreateManyInlineInput;
  AssetVariantParentCreateOneInlineInput: AssetVariantParentCreateOneInlineInput;
  AssetVariantParentUpdateInput: AssetVariantParentUpdateInput;
  AssetVariantParentUpdateManyInlineInput: AssetVariantParentUpdateManyInlineInput;
  AssetVariantParentUpdateManyWithNestedWhereInput: AssetVariantParentUpdateManyWithNestedWhereInput;
  AssetVariantParentUpdateOneInlineInput: AssetVariantParentUpdateOneInlineInput;
  AssetVariantParentUpdateWithNestedWhereUniqueInput: AssetVariantParentUpdateWithNestedWhereUniqueInput;
  AssetVariantParentUpsertWithNestedWhereUniqueInput: AssetVariantParentUpsertWithNestedWhereUniqueInput;
  AssetVariantParentWhereInput: AssetVariantParentWhereInput;
  AssetVariantParentWhereUniqueInput: AssetVariantParentWhereUniqueInput;
  AssetVariantUpdateInput: AssetVariantUpdateInput;
  AssetVariantUpdateManyInlineInput: AssetVariantUpdateManyInlineInput;
  AssetVariantUpdateManyInput: AssetVariantUpdateManyInput;
  AssetVariantUpdateManyWithNestedWhereInput: AssetVariantUpdateManyWithNestedWhereInput;
  AssetVariantUpdateOneInlineInput: AssetVariantUpdateOneInlineInput;
  AssetVariantUpdateWithNestedWhereUniqueAndPositionInput: AssetVariantUpdateWithNestedWhereUniqueAndPositionInput;
  AssetVariantUpdateWithNestedWhereUniqueInput: AssetVariantUpdateWithNestedWhereUniqueInput;
  AssetVariantUpsertInput: AssetVariantUpsertInput;
  AssetVariantUpsertWithNestedWhereUniqueAndPositionInput: AssetVariantUpsertWithNestedWhereUniqueAndPositionInput;
  AssetVariantUpsertWithNestedWhereUniqueInput: AssetVariantUpsertWithNestedWhereUniqueInput;
  AssetVariantWhereInput: AssetVariantWhereInput;
  AssetVariantWhereUniqueInput: AssetVariantWhereUniqueInput;
  AssetWhereComparatorInput: AssetWhereComparatorInput;
  AssetWhereInput: AssetWhereInput;
  AssetWhereStageInput: AssetWhereStageInput;
  AssetWhereUniqueInput: AssetWhereUniqueInput;
  Attraction: Omit<Attraction, 'gallery'> & {
    gallery: Array<ResolversParentTypes['AttractionGallery']>;
  };
  AttractionAlacarte: AttractionAlacarte;
  AttractionAlacarteConnectInput: AttractionAlacarteConnectInput;
  AttractionAlacarteConnection: AttractionAlacarteConnection;
  AttractionAlacarteCreateInput: AttractionAlacarteCreateInput;
  AttractionAlacarteCreateManyInlineInput: AttractionAlacarteCreateManyInlineInput;
  AttractionAlacarteCreateOneInlineInput: AttractionAlacarteCreateOneInlineInput;
  AttractionAlacarteEdge: AttractionAlacarteEdge;
  AttractionAlacarteManyWhereInput: AttractionAlacarteManyWhereInput;
  AttractionAlacarteUpdateInput: AttractionAlacarteUpdateInput;
  AttractionAlacarteUpdateManyInlineInput: AttractionAlacarteUpdateManyInlineInput;
  AttractionAlacarteUpdateManyInput: AttractionAlacarteUpdateManyInput;
  AttractionAlacarteUpdateManyWithNestedWhereInput: AttractionAlacarteUpdateManyWithNestedWhereInput;
  AttractionAlacarteUpdateOneInlineInput: AttractionAlacarteUpdateOneInlineInput;
  AttractionAlacarteUpdateWithNestedWhereUniqueInput: AttractionAlacarteUpdateWithNestedWhereUniqueInput;
  AttractionAlacarteUpsertInput: AttractionAlacarteUpsertInput;
  AttractionAlacarteUpsertWithNestedWhereUniqueInput: AttractionAlacarteUpsertWithNestedWhereUniqueInput;
  AttractionAlacarteWhereComparatorInput: AttractionAlacarteWhereComparatorInput;
  AttractionAlacarteWhereInput: AttractionAlacarteWhereInput;
  AttractionAlacarteWhereStageInput: AttractionAlacarteWhereStageInput;
  AttractionAlacarteWhereUniqueInput: AttractionAlacarteWhereUniqueInput;
  AttractionAvailability: AttractionAvailability;
  AttractionAvailabilityConnectInput: AttractionAvailabilityConnectInput;
  AttractionAvailabilityConnection: AttractionAvailabilityConnection;
  AttractionAvailabilityCreateInput: AttractionAvailabilityCreateInput;
  AttractionAvailabilityCreateLocalizationDataInput: AttractionAvailabilityCreateLocalizationDataInput;
  AttractionAvailabilityCreateLocalizationInput: AttractionAvailabilityCreateLocalizationInput;
  AttractionAvailabilityCreateLocalizationsInput: AttractionAvailabilityCreateLocalizationsInput;
  AttractionAvailabilityCreateManyInlineInput: AttractionAvailabilityCreateManyInlineInput;
  AttractionAvailabilityCreateOneInlineInput: AttractionAvailabilityCreateOneInlineInput;
  AttractionAvailabilityEdge: AttractionAvailabilityEdge;
  AttractionAvailabilityManyWhereInput: AttractionAvailabilityManyWhereInput;
  AttractionAvailabilityUpdateInput: AttractionAvailabilityUpdateInput;
  AttractionAvailabilityUpdateLocalizationDataInput: AttractionAvailabilityUpdateLocalizationDataInput;
  AttractionAvailabilityUpdateLocalizationInput: AttractionAvailabilityUpdateLocalizationInput;
  AttractionAvailabilityUpdateLocalizationsInput: AttractionAvailabilityUpdateLocalizationsInput;
  AttractionAvailabilityUpdateManyInlineInput: AttractionAvailabilityUpdateManyInlineInput;
  AttractionAvailabilityUpdateManyInput: AttractionAvailabilityUpdateManyInput;
  AttractionAvailabilityUpdateManyLocalizationDataInput: AttractionAvailabilityUpdateManyLocalizationDataInput;
  AttractionAvailabilityUpdateManyLocalizationInput: AttractionAvailabilityUpdateManyLocalizationInput;
  AttractionAvailabilityUpdateManyLocalizationsInput: AttractionAvailabilityUpdateManyLocalizationsInput;
  AttractionAvailabilityUpdateManyWithNestedWhereInput: AttractionAvailabilityUpdateManyWithNestedWhereInput;
  AttractionAvailabilityUpdateOneInlineInput: AttractionAvailabilityUpdateOneInlineInput;
  AttractionAvailabilityUpdateWithNestedWhereUniqueInput: AttractionAvailabilityUpdateWithNestedWhereUniqueInput;
  AttractionAvailabilityUpsertInput: AttractionAvailabilityUpsertInput;
  AttractionAvailabilityUpsertLocalizationInput: AttractionAvailabilityUpsertLocalizationInput;
  AttractionAvailabilityUpsertWithNestedWhereUniqueInput: AttractionAvailabilityUpsertWithNestedWhereUniqueInput;
  AttractionAvailabilityWhereComparatorInput: AttractionAvailabilityWhereComparatorInput;
  AttractionAvailabilityWhereInput: AttractionAvailabilityWhereInput;
  AttractionAvailabilityWhereStageInput: AttractionAvailabilityWhereStageInput;
  AttractionAvailabilityWhereUniqueInput: AttractionAvailabilityWhereUniqueInput;
  AttractionConnectInput: AttractionConnectInput;
  AttractionConnection: AttractionConnection;
  AttractionCreateInput: AttractionCreateInput;
  AttractionCreateLocalizationDataInput: AttractionCreateLocalizationDataInput;
  AttractionCreateLocalizationInput: AttractionCreateLocalizationInput;
  AttractionCreateLocalizationsInput: AttractionCreateLocalizationsInput;
  AttractionCreateManyInlineInput: AttractionCreateManyInlineInput;
  AttractionCreateOneInlineInput: AttractionCreateOneInlineInput;
  AttractionEdge: AttractionEdge;
  AttractionGallery: ResolversUnionTypes['AttractionGallery'];
  AttractionGalleryConnectInput: AttractionGalleryConnectInput;
  AttractionGalleryCreateInput: AttractionGalleryCreateInput;
  AttractionGalleryCreateManyInlineInput: AttractionGalleryCreateManyInlineInput;
  AttractionGalleryCreateOneInlineInput: AttractionGalleryCreateOneInlineInput;
  AttractionGalleryUpdateInput: AttractionGalleryUpdateInput;
  AttractionGalleryUpdateManyInlineInput: AttractionGalleryUpdateManyInlineInput;
  AttractionGalleryUpdateManyWithNestedWhereInput: AttractionGalleryUpdateManyWithNestedWhereInput;
  AttractionGalleryUpdateOneInlineInput: AttractionGalleryUpdateOneInlineInput;
  AttractionGalleryUpdateWithNestedWhereUniqueInput: AttractionGalleryUpdateWithNestedWhereUniqueInput;
  AttractionGalleryUpsertWithNestedWhereUniqueInput: AttractionGalleryUpsertWithNestedWhereUniqueInput;
  AttractionGalleryWhereInput: AttractionGalleryWhereInput;
  AttractionGalleryWhereUniqueInput: AttractionGalleryWhereUniqueInput;
  AttractionGroup: AttractionGroup;
  AttractionGroupConnectInput: AttractionGroupConnectInput;
  AttractionGroupConnection: AttractionGroupConnection;
  AttractionGroupCreateInput: AttractionGroupCreateInput;
  AttractionGroupCreateManyInlineInput: AttractionGroupCreateManyInlineInput;
  AttractionGroupCreateOneInlineInput: AttractionGroupCreateOneInlineInput;
  AttractionGroupCreateWithPositionInput: AttractionGroupCreateWithPositionInput;
  AttractionGroupEdge: AttractionGroupEdge;
  AttractionGroupManyWhereInput: AttractionGroupManyWhereInput;
  AttractionGroupParent: ResolversUnionTypes['AttractionGroupParent'];
  AttractionGroupParentConnectInput: AttractionGroupParentConnectInput;
  AttractionGroupParentCreateInput: AttractionGroupParentCreateInput;
  AttractionGroupParentCreateManyInlineInput: AttractionGroupParentCreateManyInlineInput;
  AttractionGroupParentCreateOneInlineInput: AttractionGroupParentCreateOneInlineInput;
  AttractionGroupParentUpdateInput: AttractionGroupParentUpdateInput;
  AttractionGroupParentUpdateManyInlineInput: AttractionGroupParentUpdateManyInlineInput;
  AttractionGroupParentUpdateManyWithNestedWhereInput: AttractionGroupParentUpdateManyWithNestedWhereInput;
  AttractionGroupParentUpdateOneInlineInput: AttractionGroupParentUpdateOneInlineInput;
  AttractionGroupParentUpdateWithNestedWhereUniqueInput: AttractionGroupParentUpdateWithNestedWhereUniqueInput;
  AttractionGroupParentUpsertWithNestedWhereUniqueInput: AttractionGroupParentUpsertWithNestedWhereUniqueInput;
  AttractionGroupParentWhereInput: AttractionGroupParentWhereInput;
  AttractionGroupParentWhereUniqueInput: AttractionGroupParentWhereUniqueInput;
  AttractionGroupUpdateInput: AttractionGroupUpdateInput;
  AttractionGroupUpdateManyInlineInput: AttractionGroupUpdateManyInlineInput;
  AttractionGroupUpdateManyInput: AttractionGroupUpdateManyInput;
  AttractionGroupUpdateManyWithNestedWhereInput: AttractionGroupUpdateManyWithNestedWhereInput;
  AttractionGroupUpdateOneInlineInput: AttractionGroupUpdateOneInlineInput;
  AttractionGroupUpdateWithNestedWhereUniqueAndPositionInput: AttractionGroupUpdateWithNestedWhereUniqueAndPositionInput;
  AttractionGroupUpdateWithNestedWhereUniqueInput: AttractionGroupUpdateWithNestedWhereUniqueInput;
  AttractionGroupUpsertInput: AttractionGroupUpsertInput;
  AttractionGroupUpsertWithNestedWhereUniqueAndPositionInput: AttractionGroupUpsertWithNestedWhereUniqueAndPositionInput;
  AttractionGroupUpsertWithNestedWhereUniqueInput: AttractionGroupUpsertWithNestedWhereUniqueInput;
  AttractionGroupWhereInput: AttractionGroupWhereInput;
  AttractionGroupWhereUniqueInput: AttractionGroupWhereUniqueInput;
  AttractionLocation: AttractionLocation;
  AttractionLocationConnectInput: AttractionLocationConnectInput;
  AttractionLocationConnection: AttractionLocationConnection;
  AttractionLocationCreateInput: AttractionLocationCreateInput;
  AttractionLocationCreateLocalizationDataInput: AttractionLocationCreateLocalizationDataInput;
  AttractionLocationCreateLocalizationInput: AttractionLocationCreateLocalizationInput;
  AttractionLocationCreateLocalizationsInput: AttractionLocationCreateLocalizationsInput;
  AttractionLocationCreateManyInlineInput: AttractionLocationCreateManyInlineInput;
  AttractionLocationCreateOneInlineInput: AttractionLocationCreateOneInlineInput;
  AttractionLocationEdge: AttractionLocationEdge;
  AttractionLocationManyWhereInput: AttractionLocationManyWhereInput;
  AttractionLocationUpdateInput: AttractionLocationUpdateInput;
  AttractionLocationUpdateLocalizationDataInput: AttractionLocationUpdateLocalizationDataInput;
  AttractionLocationUpdateLocalizationInput: AttractionLocationUpdateLocalizationInput;
  AttractionLocationUpdateLocalizationsInput: AttractionLocationUpdateLocalizationsInput;
  AttractionLocationUpdateManyInlineInput: AttractionLocationUpdateManyInlineInput;
  AttractionLocationUpdateManyInput: AttractionLocationUpdateManyInput;
  AttractionLocationUpdateManyLocalizationDataInput: AttractionLocationUpdateManyLocalizationDataInput;
  AttractionLocationUpdateManyLocalizationInput: AttractionLocationUpdateManyLocalizationInput;
  AttractionLocationUpdateManyLocalizationsInput: AttractionLocationUpdateManyLocalizationsInput;
  AttractionLocationUpdateManyWithNestedWhereInput: AttractionLocationUpdateManyWithNestedWhereInput;
  AttractionLocationUpdateOneInlineInput: AttractionLocationUpdateOneInlineInput;
  AttractionLocationUpdateWithNestedWhereUniqueInput: AttractionLocationUpdateWithNestedWhereUniqueInput;
  AttractionLocationUpsertInput: AttractionLocationUpsertInput;
  AttractionLocationUpsertLocalizationInput: AttractionLocationUpsertLocalizationInput;
  AttractionLocationUpsertWithNestedWhereUniqueInput: AttractionLocationUpsertWithNestedWhereUniqueInput;
  AttractionLocationWhereComparatorInput: AttractionLocationWhereComparatorInput;
  AttractionLocationWhereInput: AttractionLocationWhereInput;
  AttractionLocationWhereStageInput: AttractionLocationWhereStageInput;
  AttractionLocationWhereUniqueInput: AttractionLocationWhereUniqueInput;
  AttractionManyWhereInput: AttractionManyWhereInput;
  AttractionOffer: AttractionOffer;
  AttractionOfferConnectInput: AttractionOfferConnectInput;
  AttractionOfferConnection: AttractionOfferConnection;
  AttractionOfferCreateInput: AttractionOfferCreateInput;
  AttractionOfferCreateLocalizationDataInput: AttractionOfferCreateLocalizationDataInput;
  AttractionOfferCreateLocalizationInput: AttractionOfferCreateLocalizationInput;
  AttractionOfferCreateLocalizationsInput: AttractionOfferCreateLocalizationsInput;
  AttractionOfferCreateManyInlineInput: AttractionOfferCreateManyInlineInput;
  AttractionOfferCreateOneInlineInput: AttractionOfferCreateOneInlineInput;
  AttractionOfferEdge: AttractionOfferEdge;
  AttractionOfferManyWhereInput: AttractionOfferManyWhereInput;
  AttractionOfferUpdateInput: AttractionOfferUpdateInput;
  AttractionOfferUpdateLocalizationDataInput: AttractionOfferUpdateLocalizationDataInput;
  AttractionOfferUpdateLocalizationInput: AttractionOfferUpdateLocalizationInput;
  AttractionOfferUpdateLocalizationsInput: AttractionOfferUpdateLocalizationsInput;
  AttractionOfferUpdateManyInlineInput: AttractionOfferUpdateManyInlineInput;
  AttractionOfferUpdateManyInput: AttractionOfferUpdateManyInput;
  AttractionOfferUpdateManyLocalizationDataInput: AttractionOfferUpdateManyLocalizationDataInput;
  AttractionOfferUpdateManyLocalizationInput: AttractionOfferUpdateManyLocalizationInput;
  AttractionOfferUpdateManyLocalizationsInput: AttractionOfferUpdateManyLocalizationsInput;
  AttractionOfferUpdateManyWithNestedWhereInput: AttractionOfferUpdateManyWithNestedWhereInput;
  AttractionOfferUpdateOneInlineInput: AttractionOfferUpdateOneInlineInput;
  AttractionOfferUpdateWithNestedWhereUniqueInput: AttractionOfferUpdateWithNestedWhereUniqueInput;
  AttractionOfferUpsertInput: AttractionOfferUpsertInput;
  AttractionOfferUpsertLocalizationInput: AttractionOfferUpsertLocalizationInput;
  AttractionOfferUpsertWithNestedWhereUniqueInput: AttractionOfferUpsertWithNestedWhereUniqueInput;
  AttractionOfferWhereComparatorInput: AttractionOfferWhereComparatorInput;
  AttractionOfferWhereInput: AttractionOfferWhereInput;
  AttractionOfferWhereStageInput: AttractionOfferWhereStageInput;
  AttractionOfferWhereUniqueInput: AttractionOfferWhereUniqueInput;
  AttractionPrice: AttractionPrice;
  AttractionPriceConnectInput: AttractionPriceConnectInput;
  AttractionPriceConnection: AttractionPriceConnection;
  AttractionPriceCreateInput: AttractionPriceCreateInput;
  AttractionPriceCreateLocalizationDataInput: AttractionPriceCreateLocalizationDataInput;
  AttractionPriceCreateLocalizationInput: AttractionPriceCreateLocalizationInput;
  AttractionPriceCreateLocalizationsInput: AttractionPriceCreateLocalizationsInput;
  AttractionPriceCreateManyInlineInput: AttractionPriceCreateManyInlineInput;
  AttractionPriceCreateOneInlineInput: AttractionPriceCreateOneInlineInput;
  AttractionPriceEdge: AttractionPriceEdge;
  AttractionPriceManyWhereInput: AttractionPriceManyWhereInput;
  AttractionPriceUpdateInput: AttractionPriceUpdateInput;
  AttractionPriceUpdateLocalizationDataInput: AttractionPriceUpdateLocalizationDataInput;
  AttractionPriceUpdateLocalizationInput: AttractionPriceUpdateLocalizationInput;
  AttractionPriceUpdateLocalizationsInput: AttractionPriceUpdateLocalizationsInput;
  AttractionPriceUpdateManyInlineInput: AttractionPriceUpdateManyInlineInput;
  AttractionPriceUpdateManyInput: AttractionPriceUpdateManyInput;
  AttractionPriceUpdateManyLocalizationDataInput: AttractionPriceUpdateManyLocalizationDataInput;
  AttractionPriceUpdateManyLocalizationInput: AttractionPriceUpdateManyLocalizationInput;
  AttractionPriceUpdateManyLocalizationsInput: AttractionPriceUpdateManyLocalizationsInput;
  AttractionPriceUpdateManyWithNestedWhereInput: AttractionPriceUpdateManyWithNestedWhereInput;
  AttractionPriceUpdateOneInlineInput: AttractionPriceUpdateOneInlineInput;
  AttractionPriceUpdateWithNestedWhereUniqueInput: AttractionPriceUpdateWithNestedWhereUniqueInput;
  AttractionPriceUpsertInput: AttractionPriceUpsertInput;
  AttractionPriceUpsertLocalizationInput: AttractionPriceUpsertLocalizationInput;
  AttractionPriceUpsertWithNestedWhereUniqueInput: AttractionPriceUpsertWithNestedWhereUniqueInput;
  AttractionPriceWhereComparatorInput: AttractionPriceWhereComparatorInput;
  AttractionPriceWhereInput: AttractionPriceWhereInput;
  AttractionPriceWhereStageInput: AttractionPriceWhereStageInput;
  AttractionPriceWhereUniqueInput: AttractionPriceWhereUniqueInput;
  AttractionReservation: AttractionReservation;
  AttractionReservationConnectInput: AttractionReservationConnectInput;
  AttractionReservationConnection: AttractionReservationConnection;
  AttractionReservationCreateInput: AttractionReservationCreateInput;
  AttractionReservationCreateManyInlineInput: AttractionReservationCreateManyInlineInput;
  AttractionReservationCreateOneInlineInput: AttractionReservationCreateOneInlineInput;
  AttractionReservationEdge: AttractionReservationEdge;
  AttractionReservationManyWhereInput: AttractionReservationManyWhereInput;
  AttractionReservationUpdateInput: AttractionReservationUpdateInput;
  AttractionReservationUpdateManyInlineInput: AttractionReservationUpdateManyInlineInput;
  AttractionReservationUpdateManyInput: AttractionReservationUpdateManyInput;
  AttractionReservationUpdateManyWithNestedWhereInput: AttractionReservationUpdateManyWithNestedWhereInput;
  AttractionReservationUpdateOneInlineInput: AttractionReservationUpdateOneInlineInput;
  AttractionReservationUpdateWithNestedWhereUniqueInput: AttractionReservationUpdateWithNestedWhereUniqueInput;
  AttractionReservationUpsertInput: AttractionReservationUpsertInput;
  AttractionReservationUpsertWithNestedWhereUniqueInput: AttractionReservationUpsertWithNestedWhereUniqueInput;
  AttractionReservationWhereComparatorInput: AttractionReservationWhereComparatorInput;
  AttractionReservationWhereInput: AttractionReservationWhereInput;
  AttractionReservationWhereStageInput: AttractionReservationWhereStageInput;
  AttractionReservationWhereUniqueInput: AttractionReservationWhereUniqueInput;
  AttractionUpdateInput: AttractionUpdateInput;
  AttractionUpdateLocalizationDataInput: AttractionUpdateLocalizationDataInput;
  AttractionUpdateLocalizationInput: AttractionUpdateLocalizationInput;
  AttractionUpdateLocalizationsInput: AttractionUpdateLocalizationsInput;
  AttractionUpdateManyInlineInput: AttractionUpdateManyInlineInput;
  AttractionUpdateManyInput: AttractionUpdateManyInput;
  AttractionUpdateManyLocalizationDataInput: AttractionUpdateManyLocalizationDataInput;
  AttractionUpdateManyLocalizationInput: AttractionUpdateManyLocalizationInput;
  AttractionUpdateManyLocalizationsInput: AttractionUpdateManyLocalizationsInput;
  AttractionUpdateManyWithNestedWhereInput: AttractionUpdateManyWithNestedWhereInput;
  AttractionUpdateOneInlineInput: AttractionUpdateOneInlineInput;
  AttractionUpdateWithNestedWhereUniqueInput: AttractionUpdateWithNestedWhereUniqueInput;
  AttractionUpsertInput: AttractionUpsertInput;
  AttractionUpsertLocalizationInput: AttractionUpsertLocalizationInput;
  AttractionUpsertWithNestedWhereUniqueInput: AttractionUpsertWithNestedWhereUniqueInput;
  AttractionVariant: Omit<AttractionVariant, 'gallery'> & {
    gallery: Array<ResolversParentTypes['AttractionVariantGallery']>;
  };
  AttractionVariantConnectInput: AttractionVariantConnectInput;
  AttractionVariantConnection: AttractionVariantConnection;
  AttractionVariantCreateInput: AttractionVariantCreateInput;
  AttractionVariantCreateLocalizationDataInput: AttractionVariantCreateLocalizationDataInput;
  AttractionVariantCreateLocalizationInput: AttractionVariantCreateLocalizationInput;
  AttractionVariantCreateLocalizationsInput: AttractionVariantCreateLocalizationsInput;
  AttractionVariantCreateManyInlineInput: AttractionVariantCreateManyInlineInput;
  AttractionVariantCreateOneInlineInput: AttractionVariantCreateOneInlineInput;
  AttractionVariantEdge: AttractionVariantEdge;
  AttractionVariantGallery: ResolversUnionTypes['AttractionVariantGallery'];
  AttractionVariantGalleryConnectInput: AttractionVariantGalleryConnectInput;
  AttractionVariantGalleryCreateInput: AttractionVariantGalleryCreateInput;
  AttractionVariantGalleryCreateManyInlineInput: AttractionVariantGalleryCreateManyInlineInput;
  AttractionVariantGalleryCreateOneInlineInput: AttractionVariantGalleryCreateOneInlineInput;
  AttractionVariantGalleryUpdateInput: AttractionVariantGalleryUpdateInput;
  AttractionVariantGalleryUpdateManyInlineInput: AttractionVariantGalleryUpdateManyInlineInput;
  AttractionVariantGalleryUpdateManyWithNestedWhereInput: AttractionVariantGalleryUpdateManyWithNestedWhereInput;
  AttractionVariantGalleryUpdateOneInlineInput: AttractionVariantGalleryUpdateOneInlineInput;
  AttractionVariantGalleryUpdateWithNestedWhereUniqueInput: AttractionVariantGalleryUpdateWithNestedWhereUniqueInput;
  AttractionVariantGalleryUpsertWithNestedWhereUniqueInput: AttractionVariantGalleryUpsertWithNestedWhereUniqueInput;
  AttractionVariantGalleryWhereInput: AttractionVariantGalleryWhereInput;
  AttractionVariantGalleryWhereUniqueInput: AttractionVariantGalleryWhereUniqueInput;
  AttractionVariantManyWhereInput: AttractionVariantManyWhereInput;
  AttractionVariantUpdateInput: AttractionVariantUpdateInput;
  AttractionVariantUpdateLocalizationDataInput: AttractionVariantUpdateLocalizationDataInput;
  AttractionVariantUpdateLocalizationInput: AttractionVariantUpdateLocalizationInput;
  AttractionVariantUpdateLocalizationsInput: AttractionVariantUpdateLocalizationsInput;
  AttractionVariantUpdateManyInlineInput: AttractionVariantUpdateManyInlineInput;
  AttractionVariantUpdateManyInput: AttractionVariantUpdateManyInput;
  AttractionVariantUpdateManyLocalizationDataInput: AttractionVariantUpdateManyLocalizationDataInput;
  AttractionVariantUpdateManyLocalizationInput: AttractionVariantUpdateManyLocalizationInput;
  AttractionVariantUpdateManyLocalizationsInput: AttractionVariantUpdateManyLocalizationsInput;
  AttractionVariantUpdateManyWithNestedWhereInput: AttractionVariantUpdateManyWithNestedWhereInput;
  AttractionVariantUpdateOneInlineInput: AttractionVariantUpdateOneInlineInput;
  AttractionVariantUpdateWithNestedWhereUniqueInput: AttractionVariantUpdateWithNestedWhereUniqueInput;
  AttractionVariantUpsertInput: AttractionVariantUpsertInput;
  AttractionVariantUpsertLocalizationInput: AttractionVariantUpsertLocalizationInput;
  AttractionVariantUpsertWithNestedWhereUniqueInput: AttractionVariantUpsertWithNestedWhereUniqueInput;
  AttractionVariantWhereComparatorInput: AttractionVariantWhereComparatorInput;
  AttractionVariantWhereInput: AttractionVariantWhereInput;
  AttractionVariantWhereStageInput: AttractionVariantWhereStageInput;
  AttractionVariantWhereUniqueInput: AttractionVariantWhereUniqueInput;
  AttractionWhereComparatorInput: AttractionWhereComparatorInput;
  AttractionWhereInput: AttractionWhereInput;
  AttractionWhereStageInput: AttractionWhereStageInput;
  AttractionWhereUniqueInput: AttractionWhereUniqueInput;
  Author: Author;
  AuthorConnectInput: AuthorConnectInput;
  AuthorConnection: AuthorConnection;
  AuthorCreateInput: AuthorCreateInput;
  AuthorCreateManyInlineInput: AuthorCreateManyInlineInput;
  AuthorCreateOneInlineInput: AuthorCreateOneInlineInput;
  AuthorEdge: AuthorEdge;
  AuthorManyWhereInput: AuthorManyWhereInput;
  AuthorUpdateInput: AuthorUpdateInput;
  AuthorUpdateManyInlineInput: AuthorUpdateManyInlineInput;
  AuthorUpdateManyInput: AuthorUpdateManyInput;
  AuthorUpdateManyWithNestedWhereInput: AuthorUpdateManyWithNestedWhereInput;
  AuthorUpdateOneInlineInput: AuthorUpdateOneInlineInput;
  AuthorUpdateWithNestedWhereUniqueInput: AuthorUpdateWithNestedWhereUniqueInput;
  AuthorUpsertInput: AuthorUpsertInput;
  AuthorUpsertWithNestedWhereUniqueInput: AuthorUpsertWithNestedWhereUniqueInput;
  AuthorWhereComparatorInput: AuthorWhereComparatorInput;
  AuthorWhereInput: AuthorWhereInput;
  AuthorWhereStageInput: AuthorWhereStageInput;
  AuthorWhereUniqueInput: AuthorWhereUniqueInput;
  BatchPayload: BatchPayload;
  Block: Block;
  BlockConnectInput: BlockConnectInput;
  BlockConnection: BlockConnection;
  BlockCreateInput: BlockCreateInput;
  BlockCreateManyInlineInput: BlockCreateManyInlineInput;
  BlockCreateOneInlineInput: BlockCreateOneInlineInput;
  BlockEdge: BlockEdge;
  BlockGroup: BlockGroup;
  BlockGroupConnectInput: BlockGroupConnectInput;
  BlockGroupConnection: BlockGroupConnection;
  BlockGroupCreateInput: BlockGroupCreateInput;
  BlockGroupCreateManyInlineInput: BlockGroupCreateManyInlineInput;
  BlockGroupCreateOneInlineInput: BlockGroupCreateOneInlineInput;
  BlockGroupEdge: BlockGroupEdge;
  BlockGroupManyWhereInput: BlockGroupManyWhereInput;
  BlockGroupUpdateInput: BlockGroupUpdateInput;
  BlockGroupUpdateManyInlineInput: BlockGroupUpdateManyInlineInput;
  BlockGroupUpdateManyInput: BlockGroupUpdateManyInput;
  BlockGroupUpdateManyWithNestedWhereInput: BlockGroupUpdateManyWithNestedWhereInput;
  BlockGroupUpdateOneInlineInput: BlockGroupUpdateOneInlineInput;
  BlockGroupUpdateWithNestedWhereUniqueInput: BlockGroupUpdateWithNestedWhereUniqueInput;
  BlockGroupUpsertInput: BlockGroupUpsertInput;
  BlockGroupUpsertWithNestedWhereUniqueInput: BlockGroupUpsertWithNestedWhereUniqueInput;
  BlockGroupWhereComparatorInput: BlockGroupWhereComparatorInput;
  BlockGroupWhereInput: BlockGroupWhereInput;
  BlockGroupWhereStageInput: BlockGroupWhereStageInput;
  BlockGroupWhereUniqueInput: BlockGroupWhereUniqueInput;
  BlockManyWhereInput: BlockManyWhereInput;
  BlockUI: BlockUi;
  BlockUIConnectInput: BlockUiConnectInput;
  BlockUIConnection: BlockUiConnection;
  BlockUICreateInput: BlockUiCreateInput;
  BlockUICreateManyInlineInput: BlockUiCreateManyInlineInput;
  BlockUICreateOneInlineInput: BlockUiCreateOneInlineInput;
  BlockUICreateWithPositionInput: BlockUiCreateWithPositionInput;
  BlockUIEdge: BlockUiEdge;
  BlockUIManyWhereInput: BlockUiManyWhereInput;
  BlockUIParent: ResolversUnionTypes['BlockUIParent'];
  BlockUIParentConnectInput: BlockUiParentConnectInput;
  BlockUIParentCreateInput: BlockUiParentCreateInput;
  BlockUIParentCreateManyInlineInput: BlockUiParentCreateManyInlineInput;
  BlockUIParentCreateOneInlineInput: BlockUiParentCreateOneInlineInput;
  BlockUIParentUpdateInput: BlockUiParentUpdateInput;
  BlockUIParentUpdateManyInlineInput: BlockUiParentUpdateManyInlineInput;
  BlockUIParentUpdateManyWithNestedWhereInput: BlockUiParentUpdateManyWithNestedWhereInput;
  BlockUIParentUpdateOneInlineInput: BlockUiParentUpdateOneInlineInput;
  BlockUIParentUpdateWithNestedWhereUniqueInput: BlockUiParentUpdateWithNestedWhereUniqueInput;
  BlockUIParentUpsertWithNestedWhereUniqueInput: BlockUiParentUpsertWithNestedWhereUniqueInput;
  BlockUIParentWhereInput: BlockUiParentWhereInput;
  BlockUIParentWhereUniqueInput: BlockUiParentWhereUniqueInput;
  BlockUIUpdateInput: BlockUiUpdateInput;
  BlockUIUpdateManyInlineInput: BlockUiUpdateManyInlineInput;
  BlockUIUpdateManyInput: BlockUiUpdateManyInput;
  BlockUIUpdateManyWithNestedWhereInput: BlockUiUpdateManyWithNestedWhereInput;
  BlockUIUpdateOneInlineInput: BlockUiUpdateOneInlineInput;
  BlockUIUpdateWithNestedWhereUniqueAndPositionInput: BlockUiUpdateWithNestedWhereUniqueAndPositionInput;
  BlockUIUpdateWithNestedWhereUniqueInput: BlockUiUpdateWithNestedWhereUniqueInput;
  BlockUIUpsertInput: BlockUiUpsertInput;
  BlockUIUpsertWithNestedWhereUniqueAndPositionInput: BlockUiUpsertWithNestedWhereUniqueAndPositionInput;
  BlockUIUpsertWithNestedWhereUniqueInput: BlockUiUpsertWithNestedWhereUniqueInput;
  BlockUIWhereInput: BlockUiWhereInput;
  BlockUIWhereUniqueInput: BlockUiWhereUniqueInput;
  BlockUpdateInput: BlockUpdateInput;
  BlockUpdateManyInlineInput: BlockUpdateManyInlineInput;
  BlockUpdateManyInput: BlockUpdateManyInput;
  BlockUpdateManyWithNestedWhereInput: BlockUpdateManyWithNestedWhereInput;
  BlockUpdateOneInlineInput: BlockUpdateOneInlineInput;
  BlockUpdateWithNestedWhereUniqueInput: BlockUpdateWithNestedWhereUniqueInput;
  BlockUpsertInput: BlockUpsertInput;
  BlockUpsertWithNestedWhereUniqueInput: BlockUpsertWithNestedWhereUniqueInput;
  BlockWhereComparatorInput: BlockWhereComparatorInput;
  BlockWhereInput: BlockWhereInput;
  BlockWhereStageInput: BlockWhereStageInput;
  BlockWhereUniqueInput: BlockWhereUniqueInput;
  Boolean: Scalars['Boolean'];
  Color: Color;
  ColorInput: ColorInput;
  ConnectPositionInput: ConnectPositionInput;
  CrossPromoBanner: CrossPromoBanner;
  CrossPromoBannerConnectInput: CrossPromoBannerConnectInput;
  CrossPromoBannerConnection: CrossPromoBannerConnection;
  CrossPromoBannerCreateInput: CrossPromoBannerCreateInput;
  CrossPromoBannerCreateManyInlineInput: CrossPromoBannerCreateManyInlineInput;
  CrossPromoBannerCreateOneInlineInput: CrossPromoBannerCreateOneInlineInput;
  CrossPromoBannerCreateWithPositionInput: CrossPromoBannerCreateWithPositionInput;
  CrossPromoBannerEdge: CrossPromoBannerEdge;
  CrossPromoBannerManyWhereInput: CrossPromoBannerManyWhereInput;
  CrossPromoBannerParent: ResolversUnionTypes['CrossPromoBannerParent'];
  CrossPromoBannerParentConnectInput: CrossPromoBannerParentConnectInput;
  CrossPromoBannerParentCreateInput: CrossPromoBannerParentCreateInput;
  CrossPromoBannerParentCreateManyInlineInput: CrossPromoBannerParentCreateManyInlineInput;
  CrossPromoBannerParentCreateOneInlineInput: CrossPromoBannerParentCreateOneInlineInput;
  CrossPromoBannerParentUpdateInput: CrossPromoBannerParentUpdateInput;
  CrossPromoBannerParentUpdateManyInlineInput: CrossPromoBannerParentUpdateManyInlineInput;
  CrossPromoBannerParentUpdateManyWithNestedWhereInput: CrossPromoBannerParentUpdateManyWithNestedWhereInput;
  CrossPromoBannerParentUpdateOneInlineInput: CrossPromoBannerParentUpdateOneInlineInput;
  CrossPromoBannerParentUpdateWithNestedWhereUniqueInput: CrossPromoBannerParentUpdateWithNestedWhereUniqueInput;
  CrossPromoBannerParentUpsertWithNestedWhereUniqueInput: CrossPromoBannerParentUpsertWithNestedWhereUniqueInput;
  CrossPromoBannerParentWhereInput: CrossPromoBannerParentWhereInput;
  CrossPromoBannerParentWhereUniqueInput: CrossPromoBannerParentWhereUniqueInput;
  CrossPromoBannerUpdateInput: CrossPromoBannerUpdateInput;
  CrossPromoBannerUpdateManyInlineInput: CrossPromoBannerUpdateManyInlineInput;
  CrossPromoBannerUpdateManyInput: CrossPromoBannerUpdateManyInput;
  CrossPromoBannerUpdateManyWithNestedWhereInput: CrossPromoBannerUpdateManyWithNestedWhereInput;
  CrossPromoBannerUpdateOneInlineInput: CrossPromoBannerUpdateOneInlineInput;
  CrossPromoBannerUpdateWithNestedWhereUniqueAndPositionInput: CrossPromoBannerUpdateWithNestedWhereUniqueAndPositionInput;
  CrossPromoBannerUpdateWithNestedWhereUniqueInput: CrossPromoBannerUpdateWithNestedWhereUniqueInput;
  CrossPromoBannerUpsertInput: CrossPromoBannerUpsertInput;
  CrossPromoBannerUpsertWithNestedWhereUniqueAndPositionInput: CrossPromoBannerUpsertWithNestedWhereUniqueAndPositionInput;
  CrossPromoBannerUpsertWithNestedWhereUniqueInput: CrossPromoBannerUpsertWithNestedWhereUniqueInput;
  CrossPromoBannerWhereInput: CrossPromoBannerWhereInput;
  CrossPromoBannerWhereUniqueInput: CrossPromoBannerWhereUniqueInput;
  Date: Scalars['Date'];
  DateRange: DateRange;
  DateRangeConnectInput: DateRangeConnectInput;
  DateRangeConnection: DateRangeConnection;
  DateRangeCreateInput: DateRangeCreateInput;
  DateRangeCreateManyInlineInput: DateRangeCreateManyInlineInput;
  DateRangeCreateOneInlineInput: DateRangeCreateOneInlineInput;
  DateRangeCreateWithPositionInput: DateRangeCreateWithPositionInput;
  DateRangeEdge: DateRangeEdge;
  DateRangeManyWhereInput: DateRangeManyWhereInput;
  DateRangeParent: ResolversUnionTypes['DateRangeParent'];
  DateRangeParentConnectInput: DateRangeParentConnectInput;
  DateRangeParentCreateInput: DateRangeParentCreateInput;
  DateRangeParentCreateManyInlineInput: DateRangeParentCreateManyInlineInput;
  DateRangeParentCreateOneInlineInput: DateRangeParentCreateOneInlineInput;
  DateRangeParentCreateWithPositionInput: DateRangeParentCreateWithPositionInput;
  DateRangeParentUpdateInput: DateRangeParentUpdateInput;
  DateRangeParentUpdateManyInlineInput: DateRangeParentUpdateManyInlineInput;
  DateRangeParentUpdateManyWithNestedWhereInput: DateRangeParentUpdateManyWithNestedWhereInput;
  DateRangeParentUpdateOneInlineInput: DateRangeParentUpdateOneInlineInput;
  DateRangeParentUpdateWithNestedWhereUniqueAndPositionInput: DateRangeParentUpdateWithNestedWhereUniqueAndPositionInput;
  DateRangeParentUpdateWithNestedWhereUniqueInput: DateRangeParentUpdateWithNestedWhereUniqueInput;
  DateRangeParentUpsertWithNestedWhereUniqueAndPositionInput: DateRangeParentUpsertWithNestedWhereUniqueAndPositionInput;
  DateRangeParentUpsertWithNestedWhereUniqueInput: DateRangeParentUpsertWithNestedWhereUniqueInput;
  DateRangeParentWhereInput: DateRangeParentWhereInput;
  DateRangeParentWhereUniqueInput: DateRangeParentWhereUniqueInput;
  DateRangeUpdateInput: DateRangeUpdateInput;
  DateRangeUpdateManyInlineInput: DateRangeUpdateManyInlineInput;
  DateRangeUpdateManyInput: DateRangeUpdateManyInput;
  DateRangeUpdateManyWithNestedWhereInput: DateRangeUpdateManyWithNestedWhereInput;
  DateRangeUpdateOneInlineInput: DateRangeUpdateOneInlineInput;
  DateRangeUpdateWithNestedWhereUniqueAndPositionInput: DateRangeUpdateWithNestedWhereUniqueAndPositionInput;
  DateRangeUpdateWithNestedWhereUniqueInput: DateRangeUpdateWithNestedWhereUniqueInput;
  DateRangeUpsertInput: DateRangeUpsertInput;
  DateRangeUpsertWithNestedWhereUniqueAndPositionInput: DateRangeUpsertWithNestedWhereUniqueAndPositionInput;
  DateRangeUpsertWithNestedWhereUniqueInput: DateRangeUpsertWithNestedWhereUniqueInput;
  DateRangeWhereInput: DateRangeWhereInput;
  DateRangeWhereUniqueInput: DateRangeWhereUniqueInput;
  DateTime: Scalars['DateTime'];
  DetailBlock: DetailBlock;
  DetailBlockConnectInput: DetailBlockConnectInput;
  DetailBlockConnection: DetailBlockConnection;
  DetailBlockCreateInput: DetailBlockCreateInput;
  DetailBlockCreateManyInlineInput: DetailBlockCreateManyInlineInput;
  DetailBlockCreateOneInlineInput: DetailBlockCreateOneInlineInput;
  DetailBlockEdge: DetailBlockEdge;
  DetailBlockManyWhereInput: DetailBlockManyWhereInput;
  DetailBlockUpdateInput: DetailBlockUpdateInput;
  DetailBlockUpdateManyInlineInput: DetailBlockUpdateManyInlineInput;
  DetailBlockUpdateManyInput: DetailBlockUpdateManyInput;
  DetailBlockUpdateManyWithNestedWhereInput: DetailBlockUpdateManyWithNestedWhereInput;
  DetailBlockUpdateOneInlineInput: DetailBlockUpdateOneInlineInput;
  DetailBlockUpdateWithNestedWhereUniqueInput: DetailBlockUpdateWithNestedWhereUniqueInput;
  DetailBlockUpsertInput: DetailBlockUpsertInput;
  DetailBlockUpsertWithNestedWhereUniqueInput: DetailBlockUpsertWithNestedWhereUniqueInput;
  DetailBlockWhereComparatorInput: DetailBlockWhereComparatorInput;
  DetailBlockWhereInput: DetailBlockWhereInput;
  DetailBlockWhereStageInput: DetailBlockWhereStageInput;
  DetailBlockWhereUniqueInput: DetailBlockWhereUniqueInput;
  DocumentOutputInput: DocumentOutputInput;
  DocumentTransformationInput: DocumentTransformationInput;
  DocumentVersion: DocumentVersion;
  Float: Scalars['Float'];
  GeneralReservation: GeneralReservation;
  GeneralReservationConnectInput: GeneralReservationConnectInput;
  GeneralReservationConnection: GeneralReservationConnection;
  GeneralReservationCreateInput: GeneralReservationCreateInput;
  GeneralReservationCreateManyInlineInput: GeneralReservationCreateManyInlineInput;
  GeneralReservationCreateOneInlineInput: GeneralReservationCreateOneInlineInput;
  GeneralReservationEdge: GeneralReservationEdge;
  GeneralReservationManyWhereInput: GeneralReservationManyWhereInput;
  GeneralReservationUpdateInput: GeneralReservationUpdateInput;
  GeneralReservationUpdateManyInlineInput: GeneralReservationUpdateManyInlineInput;
  GeneralReservationUpdateManyInput: GeneralReservationUpdateManyInput;
  GeneralReservationUpdateManyWithNestedWhereInput: GeneralReservationUpdateManyWithNestedWhereInput;
  GeneralReservationUpdateOneInlineInput: GeneralReservationUpdateOneInlineInput;
  GeneralReservationUpdateWithNestedWhereUniqueInput: GeneralReservationUpdateWithNestedWhereUniqueInput;
  GeneralReservationUpsertInput: GeneralReservationUpsertInput;
  GeneralReservationUpsertWithNestedWhereUniqueInput: GeneralReservationUpsertWithNestedWhereUniqueInput;
  GeneralReservationWhereComparatorInput: GeneralReservationWhereComparatorInput;
  GeneralReservationWhereInput: GeneralReservationWhereInput;
  GeneralReservationWhereStageInput: GeneralReservationWhereStageInput;
  GeneralReservationWhereUniqueInput: GeneralReservationWhereUniqueInput;
  Hex: Scalars['Hex'];
  ID: Scalars['ID'];
  Image: Image;
  ImageConnectInput: ImageConnectInput;
  ImageConnection: ImageConnection;
  ImageCreateInput: ImageCreateInput;
  ImageCreateLocalizationDataInput: ImageCreateLocalizationDataInput;
  ImageCreateLocalizationInput: ImageCreateLocalizationInput;
  ImageCreateLocalizationsInput: ImageCreateLocalizationsInput;
  ImageCreateManyInlineInput: ImageCreateManyInlineInput;
  ImageCreateOneInlineInput: ImageCreateOneInlineInput;
  ImageEdge: ImageEdge;
  ImageManyWhereInput: ImageManyWhereInput;
  ImageResizeInput: ImageResizeInput;
  ImageTransformationInput: ImageTransformationInput;
  ImageUpdateInput: ImageUpdateInput;
  ImageUpdateLocalizationDataInput: ImageUpdateLocalizationDataInput;
  ImageUpdateLocalizationInput: ImageUpdateLocalizationInput;
  ImageUpdateLocalizationsInput: ImageUpdateLocalizationsInput;
  ImageUpdateManyInlineInput: ImageUpdateManyInlineInput;
  ImageUpdateManyInput: ImageUpdateManyInput;
  ImageUpdateManyLocalizationDataInput: ImageUpdateManyLocalizationDataInput;
  ImageUpdateManyLocalizationInput: ImageUpdateManyLocalizationInput;
  ImageUpdateManyLocalizationsInput: ImageUpdateManyLocalizationsInput;
  ImageUpdateManyWithNestedWhereInput: ImageUpdateManyWithNestedWhereInput;
  ImageUpdateOneInlineInput: ImageUpdateOneInlineInput;
  ImageUpdateWithNestedWhereUniqueInput: ImageUpdateWithNestedWhereUniqueInput;
  ImageUpsertInput: ImageUpsertInput;
  ImageUpsertLocalizationInput: ImageUpsertLocalizationInput;
  ImageUpsertWithNestedWhereUniqueInput: ImageUpsertWithNestedWhereUniqueInput;
  ImageWhereComparatorInput: ImageWhereComparatorInput;
  ImageWhereInput: ImageWhereInput;
  ImageWhereStageInput: ImageWhereStageInput;
  ImageWhereUniqueInput: ImageWhereUniqueInput;
  Int: Scalars['Int'];
  Json: Scalars['Json'];
  LandingPage: LandingPage;
  LandingPageBodyRichRichText: Omit<LandingPageBodyRichRichText, 'references'> & {
    references: Array<ResolversParentTypes['LandingPageBodyRichRichTextEmbeddedTypes']>;
  };
  LandingPageBodyRichRichTextEmbeddedTypes: ResolversUnionTypes['LandingPageBodyRichRichTextEmbeddedTypes'];
  LandingPageConnectInput: LandingPageConnectInput;
  LandingPageConnection: LandingPageConnection;
  LandingPageCreateInput: LandingPageCreateInput;
  LandingPageCreateLocalizationDataInput: LandingPageCreateLocalizationDataInput;
  LandingPageCreateLocalizationInput: LandingPageCreateLocalizationInput;
  LandingPageCreateLocalizationsInput: LandingPageCreateLocalizationsInput;
  LandingPageCreateManyInlineInput: LandingPageCreateManyInlineInput;
  LandingPageCreateOneInlineInput: LandingPageCreateOneInlineInput;
  LandingPageEdge: LandingPageEdge;
  LandingPageManyWhereInput: LandingPageManyWhereInput;
  LandingPageUpdateInput: LandingPageUpdateInput;
  LandingPageUpdateLocalizationDataInput: LandingPageUpdateLocalizationDataInput;
  LandingPageUpdateLocalizationInput: LandingPageUpdateLocalizationInput;
  LandingPageUpdateLocalizationsInput: LandingPageUpdateLocalizationsInput;
  LandingPageUpdateManyInlineInput: LandingPageUpdateManyInlineInput;
  LandingPageUpdateManyInput: LandingPageUpdateManyInput;
  LandingPageUpdateManyLocalizationDataInput: LandingPageUpdateManyLocalizationDataInput;
  LandingPageUpdateManyLocalizationInput: LandingPageUpdateManyLocalizationInput;
  LandingPageUpdateManyLocalizationsInput: LandingPageUpdateManyLocalizationsInput;
  LandingPageUpdateManyWithNestedWhereInput: LandingPageUpdateManyWithNestedWhereInput;
  LandingPageUpdateOneInlineInput: LandingPageUpdateOneInlineInput;
  LandingPageUpdateWithNestedWhereUniqueInput: LandingPageUpdateWithNestedWhereUniqueInput;
  LandingPageUpsertInput: LandingPageUpsertInput;
  LandingPageUpsertLocalizationInput: LandingPageUpsertLocalizationInput;
  LandingPageUpsertWithNestedWhereUniqueInput: LandingPageUpsertWithNestedWhereUniqueInput;
  LandingPageWhereComparatorInput: LandingPageWhereComparatorInput;
  LandingPageWhereInput: LandingPageWhereInput;
  LandingPageWhereStageInput: LandingPageWhereStageInput;
  LandingPageWhereUniqueInput: LandingPageWhereUniqueInput;
  Link: Link;
  LinkConnectInput: LinkConnectInput;
  LinkConnection: LinkConnection;
  LinkCreateInput: LinkCreateInput;
  LinkCreateManyInlineInput: LinkCreateManyInlineInput;
  LinkCreateOneInlineInput: LinkCreateOneInlineInput;
  LinkEdge: LinkEdge;
  LinkManyWhereInput: LinkManyWhereInput;
  LinkUpdateInput: LinkUpdateInput;
  LinkUpdateManyInlineInput: LinkUpdateManyInlineInput;
  LinkUpdateManyInput: LinkUpdateManyInput;
  LinkUpdateManyWithNestedWhereInput: LinkUpdateManyWithNestedWhereInput;
  LinkUpdateOneInlineInput: LinkUpdateOneInlineInput;
  LinkUpdateWithNestedWhereUniqueInput: LinkUpdateWithNestedWhereUniqueInput;
  LinkUpsertInput: LinkUpsertInput;
  LinkUpsertWithNestedWhereUniqueInput: LinkUpsertWithNestedWhereUniqueInput;
  LinkWhereComparatorInput: LinkWhereComparatorInput;
  LinkWhereInput: LinkWhereInput;
  LinkWhereStageInput: LinkWhereStageInput;
  LinkWhereUniqueInput: LinkWhereUniqueInput;
  Location: Location;
  LocationInput: LocationInput;
  Long: Scalars['Long'];
  Menu: Omit<Menu, 'items'> & { items: Array<ResolversParentTypes['MenuItems']> };
  MenuConnectInput: MenuConnectInput;
  MenuConnection: MenuConnection;
  MenuCreateInput: MenuCreateInput;
  MenuCreateManyInlineInput: MenuCreateManyInlineInput;
  MenuCreateOneInlineInput: MenuCreateOneInlineInput;
  MenuEdge: MenuEdge;
  MenuItems: ResolversUnionTypes['MenuItems'];
  MenuItemsConnectInput: MenuItemsConnectInput;
  MenuItemsCreateInput: MenuItemsCreateInput;
  MenuItemsCreateManyInlineInput: MenuItemsCreateManyInlineInput;
  MenuItemsCreateOneInlineInput: MenuItemsCreateOneInlineInput;
  MenuItemsUpdateInput: MenuItemsUpdateInput;
  MenuItemsUpdateManyInlineInput: MenuItemsUpdateManyInlineInput;
  MenuItemsUpdateManyWithNestedWhereInput: MenuItemsUpdateManyWithNestedWhereInput;
  MenuItemsUpdateOneInlineInput: MenuItemsUpdateOneInlineInput;
  MenuItemsUpdateWithNestedWhereUniqueInput: MenuItemsUpdateWithNestedWhereUniqueInput;
  MenuItemsUpsertWithNestedWhereUniqueInput: MenuItemsUpsertWithNestedWhereUniqueInput;
  MenuItemsWhereInput: MenuItemsWhereInput;
  MenuItemsWhereUniqueInput: MenuItemsWhereUniqueInput;
  MenuManyWhereInput: MenuManyWhereInput;
  MenuUpdateInput: MenuUpdateInput;
  MenuUpdateManyInlineInput: MenuUpdateManyInlineInput;
  MenuUpdateManyInput: MenuUpdateManyInput;
  MenuUpdateManyWithNestedWhereInput: MenuUpdateManyWithNestedWhereInput;
  MenuUpdateOneInlineInput: MenuUpdateOneInlineInput;
  MenuUpdateWithNestedWhereUniqueInput: MenuUpdateWithNestedWhereUniqueInput;
  MenuUpsertInput: MenuUpsertInput;
  MenuUpsertWithNestedWhereUniqueInput: MenuUpsertWithNestedWhereUniqueInput;
  MenuWhereComparatorInput: MenuWhereComparatorInput;
  MenuWhereInput: MenuWhereInput;
  MenuWhereStageInput: MenuWhereStageInput;
  MenuWhereUniqueInput: MenuWhereUniqueInput;
  Mutation: {};
  Node:
    | ResolversParentTypes['AddOn']
    | ResolversParentTypes['AlacarteTicket']
    | ResolversParentTypes['AlacarteTicketType']
    | ResolversParentTypes['Article']
    | ResolversParentTypes['ArticleCategory']
    | ResolversParentTypes['Asset']
    | ResolversParentTypes['Attraction']
    | ResolversParentTypes['AttractionAlacarte']
    | ResolversParentTypes['AttractionAvailability']
    | ResolversParentTypes['AttractionLocation']
    | ResolversParentTypes['AttractionOffer']
    | ResolversParentTypes['AttractionPrice']
    | ResolversParentTypes['AttractionReservation']
    | ResolversParentTypes['AttractionVariant']
    | ResolversParentTypes['Author']
    | ResolversParentTypes['Block']
    | ResolversParentTypes['BlockGroup']
    | ResolversParentTypes['DetailBlock']
    | ResolversParentTypes['GeneralReservation']
    | ResolversParentTypes['Image']
    | ResolversParentTypes['LandingPage']
    | ResolversParentTypes['Link']
    | ResolversParentTypes['Menu']
    | ResolversParentTypes['Notice']
    | ResolversParentTypes['Page']
    | ResolversParentTypes['PageTemplate']
    | ResolversParentTypes['Partner']
    | ResolversParentTypes['PartnerOffer']
    | ResolversParentTypes['Product']
    | ResolversParentTypes['ProductActivity']
    | ResolversParentTypes['ProductActivityItem']
    | ResolversParentTypes['ProductAttractionLineup']
    | ResolversParentTypes['ProductReservation']
    | ResolversParentTypes['Promo']
    | ResolversParentTypes['ReservationText']
    | ResolversParentTypes['Sandbox']
    | ResolversParentTypes['ScheduledOperation']
    | ResolversParentTypes['ScheduledRelease']
    | ResolversParentTypes['Seo']
    | ResolversParentTypes['Text']
    | ResolversParentTypes['TextGroup']
    | ResolversParentTypes['TextList']
    | ResolversParentTypes['TicketGroup']
    | ResolversParentTypes['TitleDescription']
    | ResolversParentTypes['User']
    | ResolversParentTypes['Video'];
  Notice: Notice;
  NoticeConnectInput: NoticeConnectInput;
  NoticeConnection: NoticeConnection;
  NoticeCreateInput: NoticeCreateInput;
  NoticeCreateLocalizationDataInput: NoticeCreateLocalizationDataInput;
  NoticeCreateLocalizationInput: NoticeCreateLocalizationInput;
  NoticeCreateLocalizationsInput: NoticeCreateLocalizationsInput;
  NoticeCreateManyInlineInput: NoticeCreateManyInlineInput;
  NoticeCreateOneInlineInput: NoticeCreateOneInlineInput;
  NoticeEdge: NoticeEdge;
  NoticeManyWhereInput: NoticeManyWhereInput;
  NoticeUpdateInput: NoticeUpdateInput;
  NoticeUpdateLocalizationDataInput: NoticeUpdateLocalizationDataInput;
  NoticeUpdateLocalizationInput: NoticeUpdateLocalizationInput;
  NoticeUpdateLocalizationsInput: NoticeUpdateLocalizationsInput;
  NoticeUpdateManyInlineInput: NoticeUpdateManyInlineInput;
  NoticeUpdateManyInput: NoticeUpdateManyInput;
  NoticeUpdateManyLocalizationDataInput: NoticeUpdateManyLocalizationDataInput;
  NoticeUpdateManyLocalizationInput: NoticeUpdateManyLocalizationInput;
  NoticeUpdateManyLocalizationsInput: NoticeUpdateManyLocalizationsInput;
  NoticeUpdateManyWithNestedWhereInput: NoticeUpdateManyWithNestedWhereInput;
  NoticeUpdateOneInlineInput: NoticeUpdateOneInlineInput;
  NoticeUpdateWithNestedWhereUniqueInput: NoticeUpdateWithNestedWhereUniqueInput;
  NoticeUpsertInput: NoticeUpsertInput;
  NoticeUpsertLocalizationInput: NoticeUpsertLocalizationInput;
  NoticeUpsertWithNestedWhereUniqueInput: NoticeUpsertWithNestedWhereUniqueInput;
  NoticeWhereComparatorInput: NoticeWhereComparatorInput;
  NoticeWhereInput: NoticeWhereInput;
  NoticeWhereStageInput: NoticeWhereStageInput;
  NoticeWhereUniqueInput: NoticeWhereUniqueInput;
  Page: Page;
  PageConnectInput: PageConnectInput;
  PageConnection: PageConnection;
  PageCreateInput: PageCreateInput;
  PageCreateLocalizationDataInput: PageCreateLocalizationDataInput;
  PageCreateLocalizationInput: PageCreateLocalizationInput;
  PageCreateLocalizationsInput: PageCreateLocalizationsInput;
  PageCreateManyInlineInput: PageCreateManyInlineInput;
  PageCreateOneInlineInput: PageCreateOneInlineInput;
  PageEdge: PageEdge;
  PageInfo: PageInfo;
  PageManyWhereInput: PageManyWhereInput;
  PageTemplate: PageTemplate;
  PageTemplateConnectInput: PageTemplateConnectInput;
  PageTemplateConnection: PageTemplateConnection;
  PageTemplateCreateInput: PageTemplateCreateInput;
  PageTemplateCreateManyInlineInput: PageTemplateCreateManyInlineInput;
  PageTemplateCreateOneInlineInput: PageTemplateCreateOneInlineInput;
  PageTemplateEdge: PageTemplateEdge;
  PageTemplateManyWhereInput: PageTemplateManyWhereInput;
  PageTemplateUpdateInput: PageTemplateUpdateInput;
  PageTemplateUpdateManyInlineInput: PageTemplateUpdateManyInlineInput;
  PageTemplateUpdateManyInput: PageTemplateUpdateManyInput;
  PageTemplateUpdateManyWithNestedWhereInput: PageTemplateUpdateManyWithNestedWhereInput;
  PageTemplateUpdateOneInlineInput: PageTemplateUpdateOneInlineInput;
  PageTemplateUpdateWithNestedWhereUniqueInput: PageTemplateUpdateWithNestedWhereUniqueInput;
  PageTemplateUpsertInput: PageTemplateUpsertInput;
  PageTemplateUpsertWithNestedWhereUniqueInput: PageTemplateUpsertWithNestedWhereUniqueInput;
  PageTemplateWhereComparatorInput: PageTemplateWhereComparatorInput;
  PageTemplateWhereInput: PageTemplateWhereInput;
  PageTemplateWhereStageInput: PageTemplateWhereStageInput;
  PageTemplateWhereUniqueInput: PageTemplateWhereUniqueInput;
  PageUpdateInput: PageUpdateInput;
  PageUpdateLocalizationDataInput: PageUpdateLocalizationDataInput;
  PageUpdateLocalizationInput: PageUpdateLocalizationInput;
  PageUpdateLocalizationsInput: PageUpdateLocalizationsInput;
  PageUpdateManyInlineInput: PageUpdateManyInlineInput;
  PageUpdateManyInput: PageUpdateManyInput;
  PageUpdateManyLocalizationDataInput: PageUpdateManyLocalizationDataInput;
  PageUpdateManyLocalizationInput: PageUpdateManyLocalizationInput;
  PageUpdateManyLocalizationsInput: PageUpdateManyLocalizationsInput;
  PageUpdateManyWithNestedWhereInput: PageUpdateManyWithNestedWhereInput;
  PageUpdateOneInlineInput: PageUpdateOneInlineInput;
  PageUpdateWithNestedWhereUniqueInput: PageUpdateWithNestedWhereUniqueInput;
  PageUpsertInput: PageUpsertInput;
  PageUpsertLocalizationInput: PageUpsertLocalizationInput;
  PageUpsertWithNestedWhereUniqueInput: PageUpsertWithNestedWhereUniqueInput;
  PageWhereComparatorInput: PageWhereComparatorInput;
  PageWhereInput: PageWhereInput;
  PageWhereStageInput: PageWhereStageInput;
  PageWhereUniqueInput: PageWhereUniqueInput;
  Partner: Partner;
  PartnerConnectInput: PartnerConnectInput;
  PartnerConnection: PartnerConnection;
  PartnerCreateInput: PartnerCreateInput;
  PartnerCreateManyInlineInput: PartnerCreateManyInlineInput;
  PartnerCreateOneInlineInput: PartnerCreateOneInlineInput;
  PartnerEdge: PartnerEdge;
  PartnerManyWhereInput: PartnerManyWhereInput;
  PartnerOffer: PartnerOffer;
  PartnerOfferConnectInput: PartnerOfferConnectInput;
  PartnerOfferConnection: PartnerOfferConnection;
  PartnerOfferCreateInput: PartnerOfferCreateInput;
  PartnerOfferCreateLocalizationDataInput: PartnerOfferCreateLocalizationDataInput;
  PartnerOfferCreateLocalizationInput: PartnerOfferCreateLocalizationInput;
  PartnerOfferCreateLocalizationsInput: PartnerOfferCreateLocalizationsInput;
  PartnerOfferCreateManyInlineInput: PartnerOfferCreateManyInlineInput;
  PartnerOfferCreateOneInlineInput: PartnerOfferCreateOneInlineInput;
  PartnerOfferEdge: PartnerOfferEdge;
  PartnerOfferManyWhereInput: PartnerOfferManyWhereInput;
  PartnerOfferUpdateInput: PartnerOfferUpdateInput;
  PartnerOfferUpdateLocalizationDataInput: PartnerOfferUpdateLocalizationDataInput;
  PartnerOfferUpdateLocalizationInput: PartnerOfferUpdateLocalizationInput;
  PartnerOfferUpdateLocalizationsInput: PartnerOfferUpdateLocalizationsInput;
  PartnerOfferUpdateManyInlineInput: PartnerOfferUpdateManyInlineInput;
  PartnerOfferUpdateManyInput: PartnerOfferUpdateManyInput;
  PartnerOfferUpdateManyLocalizationDataInput: PartnerOfferUpdateManyLocalizationDataInput;
  PartnerOfferUpdateManyLocalizationInput: PartnerOfferUpdateManyLocalizationInput;
  PartnerOfferUpdateManyLocalizationsInput: PartnerOfferUpdateManyLocalizationsInput;
  PartnerOfferUpdateManyWithNestedWhereInput: PartnerOfferUpdateManyWithNestedWhereInput;
  PartnerOfferUpdateOneInlineInput: PartnerOfferUpdateOneInlineInput;
  PartnerOfferUpdateWithNestedWhereUniqueInput: PartnerOfferUpdateWithNestedWhereUniqueInput;
  PartnerOfferUpsertInput: PartnerOfferUpsertInput;
  PartnerOfferUpsertLocalizationInput: PartnerOfferUpsertLocalizationInput;
  PartnerOfferUpsertWithNestedWhereUniqueInput: PartnerOfferUpsertWithNestedWhereUniqueInput;
  PartnerOfferWhereComparatorInput: PartnerOfferWhereComparatorInput;
  PartnerOfferWhereInput: PartnerOfferWhereInput;
  PartnerOfferWhereStageInput: PartnerOfferWhereStageInput;
  PartnerOfferWhereUniqueInput: PartnerOfferWhereUniqueInput;
  PartnerUpdateInput: PartnerUpdateInput;
  PartnerUpdateManyInlineInput: PartnerUpdateManyInlineInput;
  PartnerUpdateManyInput: PartnerUpdateManyInput;
  PartnerUpdateManyWithNestedWhereInput: PartnerUpdateManyWithNestedWhereInput;
  PartnerUpdateOneInlineInput: PartnerUpdateOneInlineInput;
  PartnerUpdateWithNestedWhereUniqueInput: PartnerUpdateWithNestedWhereUniqueInput;
  PartnerUpsertInput: PartnerUpsertInput;
  PartnerUpsertWithNestedWhereUniqueInput: PartnerUpsertWithNestedWhereUniqueInput;
  PartnerWhereComparatorInput: PartnerWhereComparatorInput;
  PartnerWhereInput: PartnerWhereInput;
  PartnerWhereStageInput: PartnerWhereStageInput;
  PartnerWhereUniqueInput: PartnerWhereUniqueInput;
  Product: Product;
  ProductActivity: ProductActivity;
  ProductActivityConnectInput: ProductActivityConnectInput;
  ProductActivityConnection: ProductActivityConnection;
  ProductActivityCreateInput: ProductActivityCreateInput;
  ProductActivityCreateLocalizationDataInput: ProductActivityCreateLocalizationDataInput;
  ProductActivityCreateLocalizationInput: ProductActivityCreateLocalizationInput;
  ProductActivityCreateLocalizationsInput: ProductActivityCreateLocalizationsInput;
  ProductActivityCreateManyInlineInput: ProductActivityCreateManyInlineInput;
  ProductActivityCreateOneInlineInput: ProductActivityCreateOneInlineInput;
  ProductActivityEdge: ProductActivityEdge;
  ProductActivityItem: ProductActivityItem;
  ProductActivityItemConnectInput: ProductActivityItemConnectInput;
  ProductActivityItemConnection: ProductActivityItemConnection;
  ProductActivityItemCreateInput: ProductActivityItemCreateInput;
  ProductActivityItemCreateLocalizationDataInput: ProductActivityItemCreateLocalizationDataInput;
  ProductActivityItemCreateLocalizationInput: ProductActivityItemCreateLocalizationInput;
  ProductActivityItemCreateLocalizationsInput: ProductActivityItemCreateLocalizationsInput;
  ProductActivityItemCreateManyInlineInput: ProductActivityItemCreateManyInlineInput;
  ProductActivityItemCreateOneInlineInput: ProductActivityItemCreateOneInlineInput;
  ProductActivityItemEdge: ProductActivityItemEdge;
  ProductActivityItemManyWhereInput: ProductActivityItemManyWhereInput;
  ProductActivityItemUpdateInput: ProductActivityItemUpdateInput;
  ProductActivityItemUpdateLocalizationDataInput: ProductActivityItemUpdateLocalizationDataInput;
  ProductActivityItemUpdateLocalizationInput: ProductActivityItemUpdateLocalizationInput;
  ProductActivityItemUpdateLocalizationsInput: ProductActivityItemUpdateLocalizationsInput;
  ProductActivityItemUpdateManyInlineInput: ProductActivityItemUpdateManyInlineInput;
  ProductActivityItemUpdateManyInput: ProductActivityItemUpdateManyInput;
  ProductActivityItemUpdateManyLocalizationDataInput: ProductActivityItemUpdateManyLocalizationDataInput;
  ProductActivityItemUpdateManyLocalizationInput: ProductActivityItemUpdateManyLocalizationInput;
  ProductActivityItemUpdateManyLocalizationsInput: ProductActivityItemUpdateManyLocalizationsInput;
  ProductActivityItemUpdateManyWithNestedWhereInput: ProductActivityItemUpdateManyWithNestedWhereInput;
  ProductActivityItemUpdateOneInlineInput: ProductActivityItemUpdateOneInlineInput;
  ProductActivityItemUpdateWithNestedWhereUniqueInput: ProductActivityItemUpdateWithNestedWhereUniqueInput;
  ProductActivityItemUpsertInput: ProductActivityItemUpsertInput;
  ProductActivityItemUpsertLocalizationInput: ProductActivityItemUpsertLocalizationInput;
  ProductActivityItemUpsertWithNestedWhereUniqueInput: ProductActivityItemUpsertWithNestedWhereUniqueInput;
  ProductActivityItemWhereComparatorInput: ProductActivityItemWhereComparatorInput;
  ProductActivityItemWhereInput: ProductActivityItemWhereInput;
  ProductActivityItemWhereStageInput: ProductActivityItemWhereStageInput;
  ProductActivityItemWhereUniqueInput: ProductActivityItemWhereUniqueInput;
  ProductActivityManyWhereInput: ProductActivityManyWhereInput;
  ProductActivityUpdateInput: ProductActivityUpdateInput;
  ProductActivityUpdateLocalizationDataInput: ProductActivityUpdateLocalizationDataInput;
  ProductActivityUpdateLocalizationInput: ProductActivityUpdateLocalizationInput;
  ProductActivityUpdateLocalizationsInput: ProductActivityUpdateLocalizationsInput;
  ProductActivityUpdateManyInlineInput: ProductActivityUpdateManyInlineInput;
  ProductActivityUpdateManyInput: ProductActivityUpdateManyInput;
  ProductActivityUpdateManyLocalizationDataInput: ProductActivityUpdateManyLocalizationDataInput;
  ProductActivityUpdateManyLocalizationInput: ProductActivityUpdateManyLocalizationInput;
  ProductActivityUpdateManyLocalizationsInput: ProductActivityUpdateManyLocalizationsInput;
  ProductActivityUpdateManyWithNestedWhereInput: ProductActivityUpdateManyWithNestedWhereInput;
  ProductActivityUpdateOneInlineInput: ProductActivityUpdateOneInlineInput;
  ProductActivityUpdateWithNestedWhereUniqueInput: ProductActivityUpdateWithNestedWhereUniqueInput;
  ProductActivityUpsertInput: ProductActivityUpsertInput;
  ProductActivityUpsertLocalizationInput: ProductActivityUpsertLocalizationInput;
  ProductActivityUpsertWithNestedWhereUniqueInput: ProductActivityUpsertWithNestedWhereUniqueInput;
  ProductActivityWhereComparatorInput: ProductActivityWhereComparatorInput;
  ProductActivityWhereInput: ProductActivityWhereInput;
  ProductActivityWhereStageInput: ProductActivityWhereStageInput;
  ProductActivityWhereUniqueInput: ProductActivityWhereUniqueInput;
  ProductAttractionLineup: ProductAttractionLineup;
  ProductAttractionLineupConnectInput: ProductAttractionLineupConnectInput;
  ProductAttractionLineupConnection: ProductAttractionLineupConnection;
  ProductAttractionLineupCreateInput: ProductAttractionLineupCreateInput;
  ProductAttractionLineupCreateManyInlineInput: ProductAttractionLineupCreateManyInlineInput;
  ProductAttractionLineupCreateOneInlineInput: ProductAttractionLineupCreateOneInlineInput;
  ProductAttractionLineupEdge: ProductAttractionLineupEdge;
  ProductAttractionLineupManyWhereInput: ProductAttractionLineupManyWhereInput;
  ProductAttractionLineupUpdateInput: ProductAttractionLineupUpdateInput;
  ProductAttractionLineupUpdateManyInlineInput: ProductAttractionLineupUpdateManyInlineInput;
  ProductAttractionLineupUpdateManyInput: ProductAttractionLineupUpdateManyInput;
  ProductAttractionLineupUpdateManyWithNestedWhereInput: ProductAttractionLineupUpdateManyWithNestedWhereInput;
  ProductAttractionLineupUpdateOneInlineInput: ProductAttractionLineupUpdateOneInlineInput;
  ProductAttractionLineupUpdateWithNestedWhereUniqueInput: ProductAttractionLineupUpdateWithNestedWhereUniqueInput;
  ProductAttractionLineupUpsertInput: ProductAttractionLineupUpsertInput;
  ProductAttractionLineupUpsertWithNestedWhereUniqueInput: ProductAttractionLineupUpsertWithNestedWhereUniqueInput;
  ProductAttractionLineupWhereComparatorInput: ProductAttractionLineupWhereComparatorInput;
  ProductAttractionLineupWhereInput: ProductAttractionLineupWhereInput;
  ProductAttractionLineupWhereStageInput: ProductAttractionLineupWhereStageInput;
  ProductAttractionLineupWhereUniqueInput: ProductAttractionLineupWhereUniqueInput;
  ProductConnectInput: ProductConnectInput;
  ProductConnection: ProductConnection;
  ProductCreateInput: ProductCreateInput;
  ProductCreateLocalizationDataInput: ProductCreateLocalizationDataInput;
  ProductCreateLocalizationInput: ProductCreateLocalizationInput;
  ProductCreateLocalizationsInput: ProductCreateLocalizationsInput;
  ProductCreateManyInlineInput: ProductCreateManyInlineInput;
  ProductCreateOneInlineInput: ProductCreateOneInlineInput;
  ProductEdge: ProductEdge;
  ProductManyWhereInput: ProductManyWhereInput;
  ProductReservation: ProductReservation;
  ProductReservationConnectInput: ProductReservationConnectInput;
  ProductReservationConnection: ProductReservationConnection;
  ProductReservationCreateInput: ProductReservationCreateInput;
  ProductReservationCreateManyInlineInput: ProductReservationCreateManyInlineInput;
  ProductReservationCreateOneInlineInput: ProductReservationCreateOneInlineInput;
  ProductReservationEdge: ProductReservationEdge;
  ProductReservationManyWhereInput: ProductReservationManyWhereInput;
  ProductReservationUpdateInput: ProductReservationUpdateInput;
  ProductReservationUpdateManyInlineInput: ProductReservationUpdateManyInlineInput;
  ProductReservationUpdateManyInput: ProductReservationUpdateManyInput;
  ProductReservationUpdateManyWithNestedWhereInput: ProductReservationUpdateManyWithNestedWhereInput;
  ProductReservationUpdateOneInlineInput: ProductReservationUpdateOneInlineInput;
  ProductReservationUpdateWithNestedWhereUniqueInput: ProductReservationUpdateWithNestedWhereUniqueInput;
  ProductReservationUpsertInput: ProductReservationUpsertInput;
  ProductReservationUpsertWithNestedWhereUniqueInput: ProductReservationUpsertWithNestedWhereUniqueInput;
  ProductReservationWhereComparatorInput: ProductReservationWhereComparatorInput;
  ProductReservationWhereInput: ProductReservationWhereInput;
  ProductReservationWhereStageInput: ProductReservationWhereStageInput;
  ProductReservationWhereUniqueInput: ProductReservationWhereUniqueInput;
  ProductUpdateInput: ProductUpdateInput;
  ProductUpdateLocalizationDataInput: ProductUpdateLocalizationDataInput;
  ProductUpdateLocalizationInput: ProductUpdateLocalizationInput;
  ProductUpdateLocalizationsInput: ProductUpdateLocalizationsInput;
  ProductUpdateManyInlineInput: ProductUpdateManyInlineInput;
  ProductUpdateManyInput: ProductUpdateManyInput;
  ProductUpdateManyLocalizationDataInput: ProductUpdateManyLocalizationDataInput;
  ProductUpdateManyLocalizationInput: ProductUpdateManyLocalizationInput;
  ProductUpdateManyLocalizationsInput: ProductUpdateManyLocalizationsInput;
  ProductUpdateManyWithNestedWhereInput: ProductUpdateManyWithNestedWhereInput;
  ProductUpdateOneInlineInput: ProductUpdateOneInlineInput;
  ProductUpdateWithNestedWhereUniqueInput: ProductUpdateWithNestedWhereUniqueInput;
  ProductUpsertInput: ProductUpsertInput;
  ProductUpsertLocalizationInput: ProductUpsertLocalizationInput;
  ProductUpsertWithNestedWhereUniqueInput: ProductUpsertWithNestedWhereUniqueInput;
  ProductWhereComparatorInput: ProductWhereComparatorInput;
  ProductWhereInput: ProductWhereInput;
  ProductWhereStageInput: ProductWhereStageInput;
  ProductWhereUniqueInput: ProductWhereUniqueInput;
  Promo: Omit<Promo, 'template'> & { template: Maybe<ResolversParentTypes['PromotemplateUnion']> };
  PromoBanner: PromoBanner;
  PromoBannerConnectInput: PromoBannerConnectInput;
  PromoBannerConnection: PromoBannerConnection;
  PromoBannerCreateInput: PromoBannerCreateInput;
  PromoBannerCreateLocalizationDataInput: PromoBannerCreateLocalizationDataInput;
  PromoBannerCreateLocalizationInput: PromoBannerCreateLocalizationInput;
  PromoBannerCreateLocalizationsInput: PromoBannerCreateLocalizationsInput;
  PromoBannerCreateManyInlineInput: PromoBannerCreateManyInlineInput;
  PromoBannerCreateOneInlineInput: PromoBannerCreateOneInlineInput;
  PromoBannerCreateWithPositionInput: PromoBannerCreateWithPositionInput;
  PromoBannerEdge: PromoBannerEdge;
  PromoBannerManyWhereInput: PromoBannerManyWhereInput;
  PromoBannerParent: ResolversUnionTypes['PromoBannerParent'];
  PromoBannerParentConnectInput: PromoBannerParentConnectInput;
  PromoBannerParentCreateInput: PromoBannerParentCreateInput;
  PromoBannerParentCreateManyInlineInput: PromoBannerParentCreateManyInlineInput;
  PromoBannerParentCreateOneInlineInput: PromoBannerParentCreateOneInlineInput;
  PromoBannerParentUpdateInput: PromoBannerParentUpdateInput;
  PromoBannerParentUpdateManyInlineInput: PromoBannerParentUpdateManyInlineInput;
  PromoBannerParentUpdateManyWithNestedWhereInput: PromoBannerParentUpdateManyWithNestedWhereInput;
  PromoBannerParentUpdateOneInlineInput: PromoBannerParentUpdateOneInlineInput;
  PromoBannerParentUpdateWithNestedWhereUniqueInput: PromoBannerParentUpdateWithNestedWhereUniqueInput;
  PromoBannerParentUpsertWithNestedWhereUniqueInput: PromoBannerParentUpsertWithNestedWhereUniqueInput;
  PromoBannerParentWhereInput: PromoBannerParentWhereInput;
  PromoBannerParentWhereUniqueInput: PromoBannerParentWhereUniqueInput;
  PromoBannerUpdateInput: PromoBannerUpdateInput;
  PromoBannerUpdateLocalizationDataInput: PromoBannerUpdateLocalizationDataInput;
  PromoBannerUpdateLocalizationInput: PromoBannerUpdateLocalizationInput;
  PromoBannerUpdateLocalizationsInput: PromoBannerUpdateLocalizationsInput;
  PromoBannerUpdateManyInlineInput: PromoBannerUpdateManyInlineInput;
  PromoBannerUpdateManyInput: PromoBannerUpdateManyInput;
  PromoBannerUpdateManyLocalizationDataInput: PromoBannerUpdateManyLocalizationDataInput;
  PromoBannerUpdateManyLocalizationInput: PromoBannerUpdateManyLocalizationInput;
  PromoBannerUpdateManyLocalizationsInput: PromoBannerUpdateManyLocalizationsInput;
  PromoBannerUpdateManyWithNestedWhereInput: PromoBannerUpdateManyWithNestedWhereInput;
  PromoBannerUpdateOneInlineInput: PromoBannerUpdateOneInlineInput;
  PromoBannerUpdateWithNestedWhereUniqueAndPositionInput: PromoBannerUpdateWithNestedWhereUniqueAndPositionInput;
  PromoBannerUpdateWithNestedWhereUniqueInput: PromoBannerUpdateWithNestedWhereUniqueInput;
  PromoBannerUpsertInput: PromoBannerUpsertInput;
  PromoBannerUpsertLocalizationInput: PromoBannerUpsertLocalizationInput;
  PromoBannerUpsertWithNestedWhereUniqueAndPositionInput: PromoBannerUpsertWithNestedWhereUniqueAndPositionInput;
  PromoBannerUpsertWithNestedWhereUniqueInput: PromoBannerUpsertWithNestedWhereUniqueInput;
  PromoBannerWhereInput: PromoBannerWhereInput;
  PromoBannerWhereUniqueInput: PromoBannerWhereUniqueInput;
  PromoConnectInput: PromoConnectInput;
  PromoConnection: PromoConnection;
  PromoCreateInput: PromoCreateInput;
  PromoCreateLocalizationDataInput: PromoCreateLocalizationDataInput;
  PromoCreateLocalizationInput: PromoCreateLocalizationInput;
  PromoCreateLocalizationsInput: PromoCreateLocalizationsInput;
  PromoCreateManyInlineInput: PromoCreateManyInlineInput;
  PromoCreateOneInlineInput: PromoCreateOneInlineInput;
  PromoEdge: PromoEdge;
  PromoManyWhereInput: PromoManyWhereInput;
  PromoText: PromoText;
  PromoTextConnectInput: PromoTextConnectInput;
  PromoTextConnection: PromoTextConnection;
  PromoTextCreateInput: PromoTextCreateInput;
  PromoTextCreateLocalizationDataInput: PromoTextCreateLocalizationDataInput;
  PromoTextCreateLocalizationInput: PromoTextCreateLocalizationInput;
  PromoTextCreateLocalizationsInput: PromoTextCreateLocalizationsInput;
  PromoTextCreateManyInlineInput: PromoTextCreateManyInlineInput;
  PromoTextCreateOneInlineInput: PromoTextCreateOneInlineInput;
  PromoTextCreateWithPositionInput: PromoTextCreateWithPositionInput;
  PromoTextEdge: PromoTextEdge;
  PromoTextManyWhereInput: PromoTextManyWhereInput;
  PromoTextParent: ResolversUnionTypes['PromoTextParent'];
  PromoTextParentConnectInput: PromoTextParentConnectInput;
  PromoTextParentCreateInput: PromoTextParentCreateInput;
  PromoTextParentCreateManyInlineInput: PromoTextParentCreateManyInlineInput;
  PromoTextParentCreateOneInlineInput: PromoTextParentCreateOneInlineInput;
  PromoTextParentUpdateInput: PromoTextParentUpdateInput;
  PromoTextParentUpdateManyInlineInput: PromoTextParentUpdateManyInlineInput;
  PromoTextParentUpdateManyWithNestedWhereInput: PromoTextParentUpdateManyWithNestedWhereInput;
  PromoTextParentUpdateOneInlineInput: PromoTextParentUpdateOneInlineInput;
  PromoTextParentUpdateWithNestedWhereUniqueInput: PromoTextParentUpdateWithNestedWhereUniqueInput;
  PromoTextParentUpsertWithNestedWhereUniqueInput: PromoTextParentUpsertWithNestedWhereUniqueInput;
  PromoTextParentWhereInput: PromoTextParentWhereInput;
  PromoTextParentWhereUniqueInput: PromoTextParentWhereUniqueInput;
  PromoTextUpdateInput: PromoTextUpdateInput;
  PromoTextUpdateLocalizationDataInput: PromoTextUpdateLocalizationDataInput;
  PromoTextUpdateLocalizationInput: PromoTextUpdateLocalizationInput;
  PromoTextUpdateLocalizationsInput: PromoTextUpdateLocalizationsInput;
  PromoTextUpdateManyInlineInput: PromoTextUpdateManyInlineInput;
  PromoTextUpdateManyInput: PromoTextUpdateManyInput;
  PromoTextUpdateManyLocalizationDataInput: PromoTextUpdateManyLocalizationDataInput;
  PromoTextUpdateManyLocalizationInput: PromoTextUpdateManyLocalizationInput;
  PromoTextUpdateManyLocalizationsInput: PromoTextUpdateManyLocalizationsInput;
  PromoTextUpdateManyWithNestedWhereInput: PromoTextUpdateManyWithNestedWhereInput;
  PromoTextUpdateOneInlineInput: PromoTextUpdateOneInlineInput;
  PromoTextUpdateWithNestedWhereUniqueAndPositionInput: PromoTextUpdateWithNestedWhereUniqueAndPositionInput;
  PromoTextUpdateWithNestedWhereUniqueInput: PromoTextUpdateWithNestedWhereUniqueInput;
  PromoTextUpsertInput: PromoTextUpsertInput;
  PromoTextUpsertLocalizationInput: PromoTextUpsertLocalizationInput;
  PromoTextUpsertWithNestedWhereUniqueAndPositionInput: PromoTextUpsertWithNestedWhereUniqueAndPositionInput;
  PromoTextUpsertWithNestedWhereUniqueInput: PromoTextUpsertWithNestedWhereUniqueInput;
  PromoTextWhereInput: PromoTextWhereInput;
  PromoTextWhereUniqueInput: PromoTextWhereUniqueInput;
  PromoTicketBanner: PromoTicketBanner;
  PromoTicketBannerConnectInput: PromoTicketBannerConnectInput;
  PromoTicketBannerConnection: PromoTicketBannerConnection;
  PromoTicketBannerCreateInput: PromoTicketBannerCreateInput;
  PromoTicketBannerCreateManyInlineInput: PromoTicketBannerCreateManyInlineInput;
  PromoTicketBannerCreateOneInlineInput: PromoTicketBannerCreateOneInlineInput;
  PromoTicketBannerCreateWithPositionInput: PromoTicketBannerCreateWithPositionInput;
  PromoTicketBannerEdge: PromoTicketBannerEdge;
  PromoTicketBannerManyWhereInput: PromoTicketBannerManyWhereInput;
  PromoTicketBannerParent: ResolversUnionTypes['PromoTicketBannerParent'];
  PromoTicketBannerParentConnectInput: PromoTicketBannerParentConnectInput;
  PromoTicketBannerParentCreateInput: PromoTicketBannerParentCreateInput;
  PromoTicketBannerParentCreateManyInlineInput: PromoTicketBannerParentCreateManyInlineInput;
  PromoTicketBannerParentCreateOneInlineInput: PromoTicketBannerParentCreateOneInlineInput;
  PromoTicketBannerParentUpdateInput: PromoTicketBannerParentUpdateInput;
  PromoTicketBannerParentUpdateManyInlineInput: PromoTicketBannerParentUpdateManyInlineInput;
  PromoTicketBannerParentUpdateManyWithNestedWhereInput: PromoTicketBannerParentUpdateManyWithNestedWhereInput;
  PromoTicketBannerParentUpdateOneInlineInput: PromoTicketBannerParentUpdateOneInlineInput;
  PromoTicketBannerParentUpdateWithNestedWhereUniqueInput: PromoTicketBannerParentUpdateWithNestedWhereUniqueInput;
  PromoTicketBannerParentUpsertWithNestedWhereUniqueInput: PromoTicketBannerParentUpsertWithNestedWhereUniqueInput;
  PromoTicketBannerParentWhereInput: PromoTicketBannerParentWhereInput;
  PromoTicketBannerParentWhereUniqueInput: PromoTicketBannerParentWhereUniqueInput;
  PromoTicketBannerUpdateInput: PromoTicketBannerUpdateInput;
  PromoTicketBannerUpdateManyInlineInput: PromoTicketBannerUpdateManyInlineInput;
  PromoTicketBannerUpdateManyInput: PromoTicketBannerUpdateManyInput;
  PromoTicketBannerUpdateManyWithNestedWhereInput: PromoTicketBannerUpdateManyWithNestedWhereInput;
  PromoTicketBannerUpdateOneInlineInput: PromoTicketBannerUpdateOneInlineInput;
  PromoTicketBannerUpdateWithNestedWhereUniqueAndPositionInput: PromoTicketBannerUpdateWithNestedWhereUniqueAndPositionInput;
  PromoTicketBannerUpdateWithNestedWhereUniqueInput: PromoTicketBannerUpdateWithNestedWhereUniqueInput;
  PromoTicketBannerUpsertInput: PromoTicketBannerUpsertInput;
  PromoTicketBannerUpsertWithNestedWhereUniqueAndPositionInput: PromoTicketBannerUpsertWithNestedWhereUniqueAndPositionInput;
  PromoTicketBannerUpsertWithNestedWhereUniqueInput: PromoTicketBannerUpsertWithNestedWhereUniqueInput;
  PromoTicketBannerWhereInput: PromoTicketBannerWhereInput;
  PromoTicketBannerWhereUniqueInput: PromoTicketBannerWhereUniqueInput;
  PromoTicketText: PromoTicketText;
  PromoTicketTextConnectInput: PromoTicketTextConnectInput;
  PromoTicketTextConnection: PromoTicketTextConnection;
  PromoTicketTextCreateInput: PromoTicketTextCreateInput;
  PromoTicketTextCreateManyInlineInput: PromoTicketTextCreateManyInlineInput;
  PromoTicketTextCreateOneInlineInput: PromoTicketTextCreateOneInlineInput;
  PromoTicketTextCreateWithPositionInput: PromoTicketTextCreateWithPositionInput;
  PromoTicketTextEdge: PromoTicketTextEdge;
  PromoTicketTextManyWhereInput: PromoTicketTextManyWhereInput;
  PromoTicketTextParent: ResolversUnionTypes['PromoTicketTextParent'];
  PromoTicketTextParentConnectInput: PromoTicketTextParentConnectInput;
  PromoTicketTextParentCreateInput: PromoTicketTextParentCreateInput;
  PromoTicketTextParentCreateManyInlineInput: PromoTicketTextParentCreateManyInlineInput;
  PromoTicketTextParentCreateOneInlineInput: PromoTicketTextParentCreateOneInlineInput;
  PromoTicketTextParentUpdateInput: PromoTicketTextParentUpdateInput;
  PromoTicketTextParentUpdateManyInlineInput: PromoTicketTextParentUpdateManyInlineInput;
  PromoTicketTextParentUpdateManyWithNestedWhereInput: PromoTicketTextParentUpdateManyWithNestedWhereInput;
  PromoTicketTextParentUpdateOneInlineInput: PromoTicketTextParentUpdateOneInlineInput;
  PromoTicketTextParentUpdateWithNestedWhereUniqueInput: PromoTicketTextParentUpdateWithNestedWhereUniqueInput;
  PromoTicketTextParentUpsertWithNestedWhereUniqueInput: PromoTicketTextParentUpsertWithNestedWhereUniqueInput;
  PromoTicketTextParentWhereInput: PromoTicketTextParentWhereInput;
  PromoTicketTextParentWhereUniqueInput: PromoTicketTextParentWhereUniqueInput;
  PromoTicketTextUpdateInput: PromoTicketTextUpdateInput;
  PromoTicketTextUpdateManyInlineInput: PromoTicketTextUpdateManyInlineInput;
  PromoTicketTextUpdateManyInput: PromoTicketTextUpdateManyInput;
  PromoTicketTextUpdateManyWithNestedWhereInput: PromoTicketTextUpdateManyWithNestedWhereInput;
  PromoTicketTextUpdateOneInlineInput: PromoTicketTextUpdateOneInlineInput;
  PromoTicketTextUpdateWithNestedWhereUniqueAndPositionInput: PromoTicketTextUpdateWithNestedWhereUniqueAndPositionInput;
  PromoTicketTextUpdateWithNestedWhereUniqueInput: PromoTicketTextUpdateWithNestedWhereUniqueInput;
  PromoTicketTextUpsertInput: PromoTicketTextUpsertInput;
  PromoTicketTextUpsertWithNestedWhereUniqueAndPositionInput: PromoTicketTextUpsertWithNestedWhereUniqueAndPositionInput;
  PromoTicketTextUpsertWithNestedWhereUniqueInput: PromoTicketTextUpsertWithNestedWhereUniqueInput;
  PromoTicketTextWhereInput: PromoTicketTextWhereInput;
  PromoTicketTextWhereUniqueInput: PromoTicketTextWhereUniqueInput;
  PromoUpdateInput: PromoUpdateInput;
  PromoUpdateLocalizationDataInput: PromoUpdateLocalizationDataInput;
  PromoUpdateLocalizationInput: PromoUpdateLocalizationInput;
  PromoUpdateLocalizationsInput: PromoUpdateLocalizationsInput;
  PromoUpdateManyInlineInput: PromoUpdateManyInlineInput;
  PromoUpdateManyInput: PromoUpdateManyInput;
  PromoUpdateManyLocalizationDataInput: PromoUpdateManyLocalizationDataInput;
  PromoUpdateManyLocalizationInput: PromoUpdateManyLocalizationInput;
  PromoUpdateManyLocalizationsInput: PromoUpdateManyLocalizationsInput;
  PromoUpdateManyWithNestedWhereInput: PromoUpdateManyWithNestedWhereInput;
  PromoUpdateOneInlineInput: PromoUpdateOneInlineInput;
  PromoUpdateWithNestedWhereUniqueInput: PromoUpdateWithNestedWhereUniqueInput;
  PromoUpsertInput: PromoUpsertInput;
  PromoUpsertLocalizationInput: PromoUpsertLocalizationInput;
  PromoUpsertWithNestedWhereUniqueInput: PromoUpsertWithNestedWhereUniqueInput;
  PromoWhereComparatorInput: PromoWhereComparatorInput;
  PromoWhereInput: PromoWhereInput;
  PromoWhereStageInput: PromoWhereStageInput;
  PromoWhereUniqueInput: PromoWhereUniqueInput;
  PromotemplateUnion: ResolversUnionTypes['PromotemplateUnion'];
  PromotemplateUnionConnectInput: PromotemplateUnionConnectInput;
  PromotemplateUnionCreateInput: PromotemplateUnionCreateInput;
  PromotemplateUnionCreateManyInlineInput: PromotemplateUnionCreateManyInlineInput;
  PromotemplateUnionCreateOneInlineInput: PromotemplateUnionCreateOneInlineInput;
  PromotemplateUnionCreateWithPositionInput: PromotemplateUnionCreateWithPositionInput;
  PromotemplateUnionUpdateInput: PromotemplateUnionUpdateInput;
  PromotemplateUnionUpdateManyInlineInput: PromotemplateUnionUpdateManyInlineInput;
  PromotemplateUnionUpdateManyWithNestedWhereInput: PromotemplateUnionUpdateManyWithNestedWhereInput;
  PromotemplateUnionUpdateOneInlineInput: PromotemplateUnionUpdateOneInlineInput;
  PromotemplateUnionUpdateWithNestedWhereUniqueAndPositionInput: PromotemplateUnionUpdateWithNestedWhereUniqueAndPositionInput;
  PromotemplateUnionUpdateWithNestedWhereUniqueInput: PromotemplateUnionUpdateWithNestedWhereUniqueInput;
  PromotemplateUnionUpsertWithNestedWhereUniqueAndPositionInput: PromotemplateUnionUpsertWithNestedWhereUniqueAndPositionInput;
  PromotemplateUnionUpsertWithNestedWhereUniqueInput: PromotemplateUnionUpsertWithNestedWhereUniqueInput;
  PromotemplateUnionWhereInput: PromotemplateUnionWhereInput;
  PromotemplateUnionWhereUniqueInput: PromotemplateUnionWhereUniqueInput;
  PublishLocaleInput: PublishLocaleInput;
  Query: {};
  RGBA: Rgba;
  RGBAHue: Scalars['RGBAHue'];
  RGBAInput: RgbaInput;
  RGBATransparency: Scalars['RGBATransparency'];
  ReservationText: ReservationText;
  ReservationTextConnectInput: ReservationTextConnectInput;
  ReservationTextConnection: ReservationTextConnection;
  ReservationTextCreateInput: ReservationTextCreateInput;
  ReservationTextCreateLocalizationDataInput: ReservationTextCreateLocalizationDataInput;
  ReservationTextCreateLocalizationInput: ReservationTextCreateLocalizationInput;
  ReservationTextCreateLocalizationsInput: ReservationTextCreateLocalizationsInput;
  ReservationTextCreateManyInlineInput: ReservationTextCreateManyInlineInput;
  ReservationTextCreateOneInlineInput: ReservationTextCreateOneInlineInput;
  ReservationTextEdge: ReservationTextEdge;
  ReservationTextManyWhereInput: ReservationTextManyWhereInput;
  ReservationTextUpdateInput: ReservationTextUpdateInput;
  ReservationTextUpdateLocalizationDataInput: ReservationTextUpdateLocalizationDataInput;
  ReservationTextUpdateLocalizationInput: ReservationTextUpdateLocalizationInput;
  ReservationTextUpdateLocalizationsInput: ReservationTextUpdateLocalizationsInput;
  ReservationTextUpdateManyInlineInput: ReservationTextUpdateManyInlineInput;
  ReservationTextUpdateManyInput: ReservationTextUpdateManyInput;
  ReservationTextUpdateManyLocalizationDataInput: ReservationTextUpdateManyLocalizationDataInput;
  ReservationTextUpdateManyLocalizationInput: ReservationTextUpdateManyLocalizationInput;
  ReservationTextUpdateManyLocalizationsInput: ReservationTextUpdateManyLocalizationsInput;
  ReservationTextUpdateManyWithNestedWhereInput: ReservationTextUpdateManyWithNestedWhereInput;
  ReservationTextUpdateOneInlineInput: ReservationTextUpdateOneInlineInput;
  ReservationTextUpdateWithNestedWhereUniqueInput: ReservationTextUpdateWithNestedWhereUniqueInput;
  ReservationTextUpsertInput: ReservationTextUpsertInput;
  ReservationTextUpsertLocalizationInput: ReservationTextUpsertLocalizationInput;
  ReservationTextUpsertWithNestedWhereUniqueInput: ReservationTextUpsertWithNestedWhereUniqueInput;
  ReservationTextWhereComparatorInput: ReservationTextWhereComparatorInput;
  ReservationTextWhereInput: ReservationTextWhereInput;
  ReservationTextWhereStageInput: ReservationTextWhereStageInput;
  ReservationTextWhereUniqueInput: ReservationTextWhereUniqueInput;
  RichText: RichText;
  RichTextAST: Scalars['RichTextAST'];
  Sandbox: Sandbox;
  SandboxConnectInput: SandboxConnectInput;
  SandboxConnection: SandboxConnection;
  SandboxCreateInput: SandboxCreateInput;
  SandboxCreateLocalizationDataInput: SandboxCreateLocalizationDataInput;
  SandboxCreateLocalizationInput: SandboxCreateLocalizationInput;
  SandboxCreateLocalizationsInput: SandboxCreateLocalizationsInput;
  SandboxCreateManyInlineInput: SandboxCreateManyInlineInput;
  SandboxCreateOneInlineInput: SandboxCreateOneInlineInput;
  SandboxEdge: SandboxEdge;
  SandboxManyWhereInput: SandboxManyWhereInput;
  SandboxUpdateInput: SandboxUpdateInput;
  SandboxUpdateLocalizationDataInput: SandboxUpdateLocalizationDataInput;
  SandboxUpdateLocalizationInput: SandboxUpdateLocalizationInput;
  SandboxUpdateLocalizationsInput: SandboxUpdateLocalizationsInput;
  SandboxUpdateManyInlineInput: SandboxUpdateManyInlineInput;
  SandboxUpdateManyInput: SandboxUpdateManyInput;
  SandboxUpdateManyLocalizationDataInput: SandboxUpdateManyLocalizationDataInput;
  SandboxUpdateManyLocalizationInput: SandboxUpdateManyLocalizationInput;
  SandboxUpdateManyLocalizationsInput: SandboxUpdateManyLocalizationsInput;
  SandboxUpdateManyWithNestedWhereInput: SandboxUpdateManyWithNestedWhereInput;
  SandboxUpdateOneInlineInput: SandboxUpdateOneInlineInput;
  SandboxUpdateWithNestedWhereUniqueInput: SandboxUpdateWithNestedWhereUniqueInput;
  SandboxUpsertInput: SandboxUpsertInput;
  SandboxUpsertLocalizationInput: SandboxUpsertLocalizationInput;
  SandboxUpsertWithNestedWhereUniqueInput: SandboxUpsertWithNestedWhereUniqueInput;
  SandboxWhereComparatorInput: SandboxWhereComparatorInput;
  SandboxWhereInput: SandboxWhereInput;
  SandboxWhereStageInput: SandboxWhereStageInput;
  SandboxWhereUniqueInput: SandboxWhereUniqueInput;
  ScheduledOperation: Omit<ScheduledOperation, 'affectedDocuments'> & {
    affectedDocuments: Array<ResolversParentTypes['ScheduledOperationAffectedDocument']>;
  };
  ScheduledOperationAffectedDocument: ResolversUnionTypes['ScheduledOperationAffectedDocument'];
  ScheduledOperationConnectInput: ScheduledOperationConnectInput;
  ScheduledOperationConnection: ScheduledOperationConnection;
  ScheduledOperationCreateManyInlineInput: ScheduledOperationCreateManyInlineInput;
  ScheduledOperationCreateOneInlineInput: ScheduledOperationCreateOneInlineInput;
  ScheduledOperationEdge: ScheduledOperationEdge;
  ScheduledOperationManyWhereInput: ScheduledOperationManyWhereInput;
  ScheduledOperationUpdateManyInlineInput: ScheduledOperationUpdateManyInlineInput;
  ScheduledOperationUpdateOneInlineInput: ScheduledOperationUpdateOneInlineInput;
  ScheduledOperationWhereInput: ScheduledOperationWhereInput;
  ScheduledOperationWhereUniqueInput: ScheduledOperationWhereUniqueInput;
  ScheduledRelease: ScheduledRelease;
  ScheduledReleaseConnectInput: ScheduledReleaseConnectInput;
  ScheduledReleaseConnection: ScheduledReleaseConnection;
  ScheduledReleaseCreateInput: ScheduledReleaseCreateInput;
  ScheduledReleaseCreateManyInlineInput: ScheduledReleaseCreateManyInlineInput;
  ScheduledReleaseCreateOneInlineInput: ScheduledReleaseCreateOneInlineInput;
  ScheduledReleaseEdge: ScheduledReleaseEdge;
  ScheduledReleaseManyWhereInput: ScheduledReleaseManyWhereInput;
  ScheduledReleaseUpdateInput: ScheduledReleaseUpdateInput;
  ScheduledReleaseUpdateManyInlineInput: ScheduledReleaseUpdateManyInlineInput;
  ScheduledReleaseUpdateManyInput: ScheduledReleaseUpdateManyInput;
  ScheduledReleaseUpdateManyWithNestedWhereInput: ScheduledReleaseUpdateManyWithNestedWhereInput;
  ScheduledReleaseUpdateOneInlineInput: ScheduledReleaseUpdateOneInlineInput;
  ScheduledReleaseUpdateWithNestedWhereUniqueInput: ScheduledReleaseUpdateWithNestedWhereUniqueInput;
  ScheduledReleaseUpsertInput: ScheduledReleaseUpsertInput;
  ScheduledReleaseUpsertWithNestedWhereUniqueInput: ScheduledReleaseUpsertWithNestedWhereUniqueInput;
  ScheduledReleaseWhereInput: ScheduledReleaseWhereInput;
  ScheduledReleaseWhereUniqueInput: ScheduledReleaseWhereUniqueInput;
  Seo: Seo;
  SeoConnectInput: SeoConnectInput;
  SeoConnection: SeoConnection;
  SeoCreateInput: SeoCreateInput;
  SeoCreateLocalizationDataInput: SeoCreateLocalizationDataInput;
  SeoCreateLocalizationInput: SeoCreateLocalizationInput;
  SeoCreateLocalizationsInput: SeoCreateLocalizationsInput;
  SeoCreateManyInlineInput: SeoCreateManyInlineInput;
  SeoCreateOneInlineInput: SeoCreateOneInlineInput;
  SeoEdge: SeoEdge;
  SeoManyWhereInput: SeoManyWhereInput;
  SeoUpdateInput: SeoUpdateInput;
  SeoUpdateLocalizationDataInput: SeoUpdateLocalizationDataInput;
  SeoUpdateLocalizationInput: SeoUpdateLocalizationInput;
  SeoUpdateLocalizationsInput: SeoUpdateLocalizationsInput;
  SeoUpdateManyInlineInput: SeoUpdateManyInlineInput;
  SeoUpdateManyInput: SeoUpdateManyInput;
  SeoUpdateManyLocalizationDataInput: SeoUpdateManyLocalizationDataInput;
  SeoUpdateManyLocalizationInput: SeoUpdateManyLocalizationInput;
  SeoUpdateManyLocalizationsInput: SeoUpdateManyLocalizationsInput;
  SeoUpdateManyWithNestedWhereInput: SeoUpdateManyWithNestedWhereInput;
  SeoUpdateOneInlineInput: SeoUpdateOneInlineInput;
  SeoUpdateWithNestedWhereUniqueInput: SeoUpdateWithNestedWhereUniqueInput;
  SeoUpsertInput: SeoUpsertInput;
  SeoUpsertLocalizationInput: SeoUpsertLocalizationInput;
  SeoUpsertWithNestedWhereUniqueInput: SeoUpsertWithNestedWhereUniqueInput;
  SeoWhereComparatorInput: SeoWhereComparatorInput;
  SeoWhereInput: SeoWhereInput;
  SeoWhereStageInput: SeoWhereStageInput;
  SeoWhereUniqueInput: SeoWhereUniqueInput;
  SkuProperty: SkuProperty;
  SkuPropertyConnectInput: SkuPropertyConnectInput;
  SkuPropertyConnection: SkuPropertyConnection;
  SkuPropertyCreateInput: SkuPropertyCreateInput;
  SkuPropertyCreateManyInlineInput: SkuPropertyCreateManyInlineInput;
  SkuPropertyCreateOneInlineInput: SkuPropertyCreateOneInlineInput;
  SkuPropertyCreateWithPositionInput: SkuPropertyCreateWithPositionInput;
  SkuPropertyEdge: SkuPropertyEdge;
  SkuPropertyManyWhereInput: SkuPropertyManyWhereInput;
  SkuPropertyParent: ResolversUnionTypes['SkuPropertyParent'];
  SkuPropertyParentConnectInput: SkuPropertyParentConnectInput;
  SkuPropertyParentCreateInput: SkuPropertyParentCreateInput;
  SkuPropertyParentCreateManyInlineInput: SkuPropertyParentCreateManyInlineInput;
  SkuPropertyParentCreateOneInlineInput: SkuPropertyParentCreateOneInlineInput;
  SkuPropertyParentUpdateInput: SkuPropertyParentUpdateInput;
  SkuPropertyParentUpdateManyInlineInput: SkuPropertyParentUpdateManyInlineInput;
  SkuPropertyParentUpdateManyWithNestedWhereInput: SkuPropertyParentUpdateManyWithNestedWhereInput;
  SkuPropertyParentUpdateOneInlineInput: SkuPropertyParentUpdateOneInlineInput;
  SkuPropertyParentUpdateWithNestedWhereUniqueInput: SkuPropertyParentUpdateWithNestedWhereUniqueInput;
  SkuPropertyParentUpsertWithNestedWhereUniqueInput: SkuPropertyParentUpsertWithNestedWhereUniqueInput;
  SkuPropertyParentWhereInput: SkuPropertyParentWhereInput;
  SkuPropertyParentWhereUniqueInput: SkuPropertyParentWhereUniqueInput;
  SkuPropertyUpdateInput: SkuPropertyUpdateInput;
  SkuPropertyUpdateManyInlineInput: SkuPropertyUpdateManyInlineInput;
  SkuPropertyUpdateManyInput: SkuPropertyUpdateManyInput;
  SkuPropertyUpdateManyWithNestedWhereInput: SkuPropertyUpdateManyWithNestedWhereInput;
  SkuPropertyUpdateOneInlineInput: SkuPropertyUpdateOneInlineInput;
  SkuPropertyUpdateWithNestedWhereUniqueAndPositionInput: SkuPropertyUpdateWithNestedWhereUniqueAndPositionInput;
  SkuPropertyUpdateWithNestedWhereUniqueInput: SkuPropertyUpdateWithNestedWhereUniqueInput;
  SkuPropertyUpsertInput: SkuPropertyUpsertInput;
  SkuPropertyUpsertWithNestedWhereUniqueAndPositionInput: SkuPropertyUpsertWithNestedWhereUniqueAndPositionInput;
  SkuPropertyUpsertWithNestedWhereUniqueInput: SkuPropertyUpsertWithNestedWhereUniqueInput;
  SkuPropertyWhereInput: SkuPropertyWhereInput;
  SkuPropertyWhereUniqueInput: SkuPropertyWhereUniqueInput;
  String: Scalars['String'];
  Text: Text;
  TextConnectInput: TextConnectInput;
  TextConnection: TextConnection;
  TextCreateInput: TextCreateInput;
  TextCreateLocalizationDataInput: TextCreateLocalizationDataInput;
  TextCreateLocalizationInput: TextCreateLocalizationInput;
  TextCreateLocalizationsInput: TextCreateLocalizationsInput;
  TextCreateManyInlineInput: TextCreateManyInlineInput;
  TextCreateOneInlineInput: TextCreateOneInlineInput;
  TextEdge: TextEdge;
  TextGroup: Omit<TextGroup, 'text'> & { text: Array<ResolversParentTypes['TextGroupText']> };
  TextGroupConnectInput: TextGroupConnectInput;
  TextGroupConnection: TextGroupConnection;
  TextGroupCreateInput: TextGroupCreateInput;
  TextGroupCreateManyInlineInput: TextGroupCreateManyInlineInput;
  TextGroupCreateOneInlineInput: TextGroupCreateOneInlineInput;
  TextGroupEdge: TextGroupEdge;
  TextGroupManyWhereInput: TextGroupManyWhereInput;
  TextGroupText: ResolversUnionTypes['TextGroupText'];
  TextGroupTextConnectInput: TextGroupTextConnectInput;
  TextGroupTextCreateInput: TextGroupTextCreateInput;
  TextGroupTextCreateManyInlineInput: TextGroupTextCreateManyInlineInput;
  TextGroupTextCreateOneInlineInput: TextGroupTextCreateOneInlineInput;
  TextGroupTextUpdateInput: TextGroupTextUpdateInput;
  TextGroupTextUpdateManyInlineInput: TextGroupTextUpdateManyInlineInput;
  TextGroupTextUpdateManyWithNestedWhereInput: TextGroupTextUpdateManyWithNestedWhereInput;
  TextGroupTextUpdateOneInlineInput: TextGroupTextUpdateOneInlineInput;
  TextGroupTextUpdateWithNestedWhereUniqueInput: TextGroupTextUpdateWithNestedWhereUniqueInput;
  TextGroupTextUpsertWithNestedWhereUniqueInput: TextGroupTextUpsertWithNestedWhereUniqueInput;
  TextGroupTextWhereInput: TextGroupTextWhereInput;
  TextGroupTextWhereUniqueInput: TextGroupTextWhereUniqueInput;
  TextGroupUpdateInput: TextGroupUpdateInput;
  TextGroupUpdateManyInlineInput: TextGroupUpdateManyInlineInput;
  TextGroupUpdateManyInput: TextGroupUpdateManyInput;
  TextGroupUpdateManyWithNestedWhereInput: TextGroupUpdateManyWithNestedWhereInput;
  TextGroupUpdateOneInlineInput: TextGroupUpdateOneInlineInput;
  TextGroupUpdateWithNestedWhereUniqueInput: TextGroupUpdateWithNestedWhereUniqueInput;
  TextGroupUpsertInput: TextGroupUpsertInput;
  TextGroupUpsertWithNestedWhereUniqueInput: TextGroupUpsertWithNestedWhereUniqueInput;
  TextGroupWhereComparatorInput: TextGroupWhereComparatorInput;
  TextGroupWhereInput: TextGroupWhereInput;
  TextGroupWhereStageInput: TextGroupWhereStageInput;
  TextGroupWhereUniqueInput: TextGroupWhereUniqueInput;
  TextList: TextList;
  TextListConnectInput: TextListConnectInput;
  TextListConnection: TextListConnection;
  TextListCreateInput: TextListCreateInput;
  TextListCreateManyInlineInput: TextListCreateManyInlineInput;
  TextListCreateOneInlineInput: TextListCreateOneInlineInput;
  TextListEdge: TextListEdge;
  TextListManyWhereInput: TextListManyWhereInput;
  TextListUpdateInput: TextListUpdateInput;
  TextListUpdateManyInlineInput: TextListUpdateManyInlineInput;
  TextListUpdateManyInput: TextListUpdateManyInput;
  TextListUpdateManyWithNestedWhereInput: TextListUpdateManyWithNestedWhereInput;
  TextListUpdateOneInlineInput: TextListUpdateOneInlineInput;
  TextListUpdateWithNestedWhereUniqueInput: TextListUpdateWithNestedWhereUniqueInput;
  TextListUpsertInput: TextListUpsertInput;
  TextListUpsertWithNestedWhereUniqueInput: TextListUpsertWithNestedWhereUniqueInput;
  TextListWhereComparatorInput: TextListWhereComparatorInput;
  TextListWhereInput: TextListWhereInput;
  TextListWhereStageInput: TextListWhereStageInput;
  TextListWhereUniqueInput: TextListWhereUniqueInput;
  TextManyWhereInput: TextManyWhereInput;
  TextUpdateInput: TextUpdateInput;
  TextUpdateLocalizationDataInput: TextUpdateLocalizationDataInput;
  TextUpdateLocalizationInput: TextUpdateLocalizationInput;
  TextUpdateLocalizationsInput: TextUpdateLocalizationsInput;
  TextUpdateManyInlineInput: TextUpdateManyInlineInput;
  TextUpdateManyInput: TextUpdateManyInput;
  TextUpdateManyLocalizationDataInput: TextUpdateManyLocalizationDataInput;
  TextUpdateManyLocalizationInput: TextUpdateManyLocalizationInput;
  TextUpdateManyLocalizationsInput: TextUpdateManyLocalizationsInput;
  TextUpdateManyWithNestedWhereInput: TextUpdateManyWithNestedWhereInput;
  TextUpdateOneInlineInput: TextUpdateOneInlineInput;
  TextUpdateWithNestedWhereUniqueInput: TextUpdateWithNestedWhereUniqueInput;
  TextUpsertInput: TextUpsertInput;
  TextUpsertLocalizationInput: TextUpsertLocalizationInput;
  TextUpsertWithNestedWhereUniqueInput: TextUpsertWithNestedWhereUniqueInput;
  TextWhereComparatorInput: TextWhereComparatorInput;
  TextWhereInput: TextWhereInput;
  TextWhereStageInput: TextWhereStageInput;
  TextWhereUniqueInput: TextWhereUniqueInput;
  TicketGroup: TicketGroup;
  TicketGroupConnectInput: TicketGroupConnectInput;
  TicketGroupConnection: TicketGroupConnection;
  TicketGroupCreateInput: TicketGroupCreateInput;
  TicketGroupCreateManyInlineInput: TicketGroupCreateManyInlineInput;
  TicketGroupCreateOneInlineInput: TicketGroupCreateOneInlineInput;
  TicketGroupEdge: TicketGroupEdge;
  TicketGroupManyWhereInput: TicketGroupManyWhereInput;
  TicketGroupUpdateInput: TicketGroupUpdateInput;
  TicketGroupUpdateManyInlineInput: TicketGroupUpdateManyInlineInput;
  TicketGroupUpdateManyInput: TicketGroupUpdateManyInput;
  TicketGroupUpdateManyWithNestedWhereInput: TicketGroupUpdateManyWithNestedWhereInput;
  TicketGroupUpdateOneInlineInput: TicketGroupUpdateOneInlineInput;
  TicketGroupUpdateWithNestedWhereUniqueInput: TicketGroupUpdateWithNestedWhereUniqueInput;
  TicketGroupUpsertInput: TicketGroupUpsertInput;
  TicketGroupUpsertWithNestedWhereUniqueInput: TicketGroupUpsertWithNestedWhereUniqueInput;
  TicketGroupWhereComparatorInput: TicketGroupWhereComparatorInput;
  TicketGroupWhereInput: TicketGroupWhereInput;
  TicketGroupWhereStageInput: TicketGroupWhereStageInput;
  TicketGroupWhereUniqueInput: TicketGroupWhereUniqueInput;
  TitleDescription: TitleDescription;
  TitleDescriptionConnectInput: TitleDescriptionConnectInput;
  TitleDescriptionConnection: TitleDescriptionConnection;
  TitleDescriptionCreateInput: TitleDescriptionCreateInput;
  TitleDescriptionCreateLocalizationDataInput: TitleDescriptionCreateLocalizationDataInput;
  TitleDescriptionCreateLocalizationInput: TitleDescriptionCreateLocalizationInput;
  TitleDescriptionCreateLocalizationsInput: TitleDescriptionCreateLocalizationsInput;
  TitleDescriptionCreateManyInlineInput: TitleDescriptionCreateManyInlineInput;
  TitleDescriptionCreateOneInlineInput: TitleDescriptionCreateOneInlineInput;
  TitleDescriptionEdge: TitleDescriptionEdge;
  TitleDescriptionManyWhereInput: TitleDescriptionManyWhereInput;
  TitleDescriptionUpdateInput: TitleDescriptionUpdateInput;
  TitleDescriptionUpdateLocalizationDataInput: TitleDescriptionUpdateLocalizationDataInput;
  TitleDescriptionUpdateLocalizationInput: TitleDescriptionUpdateLocalizationInput;
  TitleDescriptionUpdateLocalizationsInput: TitleDescriptionUpdateLocalizationsInput;
  TitleDescriptionUpdateManyInlineInput: TitleDescriptionUpdateManyInlineInput;
  TitleDescriptionUpdateManyInput: TitleDescriptionUpdateManyInput;
  TitleDescriptionUpdateManyLocalizationDataInput: TitleDescriptionUpdateManyLocalizationDataInput;
  TitleDescriptionUpdateManyLocalizationInput: TitleDescriptionUpdateManyLocalizationInput;
  TitleDescriptionUpdateManyLocalizationsInput: TitleDescriptionUpdateManyLocalizationsInput;
  TitleDescriptionUpdateManyWithNestedWhereInput: TitleDescriptionUpdateManyWithNestedWhereInput;
  TitleDescriptionUpdateOneInlineInput: TitleDescriptionUpdateOneInlineInput;
  TitleDescriptionUpdateWithNestedWhereUniqueInput: TitleDescriptionUpdateWithNestedWhereUniqueInput;
  TitleDescriptionUpsertInput: TitleDescriptionUpsertInput;
  TitleDescriptionUpsertLocalizationInput: TitleDescriptionUpsertLocalizationInput;
  TitleDescriptionUpsertWithNestedWhereUniqueInput: TitleDescriptionUpsertWithNestedWhereUniqueInput;
  TitleDescriptionWhereComparatorInput: TitleDescriptionWhereComparatorInput;
  TitleDescriptionWhereInput: TitleDescriptionWhereInput;
  TitleDescriptionWhereStageInput: TitleDescriptionWhereStageInput;
  TitleDescriptionWhereUniqueInput: TitleDescriptionWhereUniqueInput;
  UnpublishLocaleInput: UnpublishLocaleInput;
  User: User;
  UserConnectInput: UserConnectInput;
  UserConnection: UserConnection;
  UserCreateManyInlineInput: UserCreateManyInlineInput;
  UserCreateOneInlineInput: UserCreateOneInlineInput;
  UserEdge: UserEdge;
  UserManyWhereInput: UserManyWhereInput;
  UserUpdateManyInlineInput: UserUpdateManyInlineInput;
  UserUpdateOneInlineInput: UserUpdateOneInlineInput;
  UserWhereComparatorInput: UserWhereComparatorInput;
  UserWhereInput: UserWhereInput;
  UserWhereStageInput: UserWhereStageInput;
  UserWhereUniqueInput: UserWhereUniqueInput;
  Version: Version;
  VersionWhereInput: VersionWhereInput;
  Video: Video;
  VideoConnectInput: VideoConnectInput;
  VideoConnection: VideoConnection;
  VideoCreateInput: VideoCreateInput;
  VideoCreateLocalizationDataInput: VideoCreateLocalizationDataInput;
  VideoCreateLocalizationInput: VideoCreateLocalizationInput;
  VideoCreateLocalizationsInput: VideoCreateLocalizationsInput;
  VideoCreateManyInlineInput: VideoCreateManyInlineInput;
  VideoCreateOneInlineInput: VideoCreateOneInlineInput;
  VideoEdge: VideoEdge;
  VideoManyWhereInput: VideoManyWhereInput;
  VideoUpdateInput: VideoUpdateInput;
  VideoUpdateLocalizationDataInput: VideoUpdateLocalizationDataInput;
  VideoUpdateLocalizationInput: VideoUpdateLocalizationInput;
  VideoUpdateLocalizationsInput: VideoUpdateLocalizationsInput;
  VideoUpdateManyInlineInput: VideoUpdateManyInlineInput;
  VideoUpdateManyInput: VideoUpdateManyInput;
  VideoUpdateManyLocalizationDataInput: VideoUpdateManyLocalizationDataInput;
  VideoUpdateManyLocalizationInput: VideoUpdateManyLocalizationInput;
  VideoUpdateManyLocalizationsInput: VideoUpdateManyLocalizationsInput;
  VideoUpdateManyWithNestedWhereInput: VideoUpdateManyWithNestedWhereInput;
  VideoUpdateOneInlineInput: VideoUpdateOneInlineInput;
  VideoUpdateWithNestedWhereUniqueInput: VideoUpdateWithNestedWhereUniqueInput;
  VideoUpsertInput: VideoUpsertInput;
  VideoUpsertLocalizationInput: VideoUpsertLocalizationInput;
  VideoUpsertWithNestedWhereUniqueInput: VideoUpsertWithNestedWhereUniqueInput;
  VideoWhereComparatorInput: VideoWhereComparatorInput;
  VideoWhereInput: VideoWhereInput;
  VideoWhereStageInput: VideoWhereStageInput;
  VideoWhereUniqueInput: VideoWhereUniqueInput;
};

export type AddOnResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AddOn'] = ResolversParentTypes['AddOn']
> = {
  attraction?: Resolver<
    Maybe<ResolversTypes['Attraction']>,
    ParentType,
    ContextType,
    Partial<AddOnAttractionArgs>
  >;
  createdAt?: Resolver<
    ResolversTypes['DateTime'],
    ParentType,
    ContextType,
    RequireFields<AddOnCreatedAtArgs, 'variation'>
  >;
  createdBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<AddOnCreatedByArgs>
  >;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  documentInStages?: Resolver<
    Array<ResolversTypes['AddOn']>,
    ParentType,
    ContextType,
    RequireFields<AddOnDocumentInStagesArgs, 'includeCurrent' | 'inheritLocale' | 'stages'>
  >;
  history?: Resolver<
    Array<ResolversTypes['Version']>,
    ParentType,
    ContextType,
    RequireFields<AddOnHistoryArgs, 'limit' | 'skip'>
  >;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  image?: Resolver<
    Maybe<ResolversTypes['Asset']>,
    ParentType,
    ContextType,
    Partial<AddOnImageArgs>
  >;
  internalTitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  link?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  locale?: Resolver<ResolversTypes['Locale'], ParentType, ContextType>;
  localizations?: Resolver<
    Array<ResolversTypes['AddOn']>,
    ParentType,
    ContextType,
    RequireFields<AddOnLocalizationsArgs, 'includeCurrent' | 'locales'>
  >;
  products?: Resolver<
    Array<ResolversTypes['Product']>,
    ParentType,
    ContextType,
    Partial<AddOnProductsArgs>
  >;
  publishedAt?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType,
    RequireFields<AddOnPublishedAtArgs, 'variation'>
  >;
  publishedBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<AddOnPublishedByArgs>
  >;
  qabu?: Resolver<Maybe<ResolversTypes['Json']>, ParentType, ContextType>;
  scheduledIn?: Resolver<
    Array<ResolversTypes['ScheduledOperation']>,
    ParentType,
    ContextType,
    Partial<AddOnScheduledInArgs>
  >;
  stage?: Resolver<ResolversTypes['Stage'], ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<
    ResolversTypes['DateTime'],
    ParentType,
    ContextType,
    RequireFields<AddOnUpdatedAtArgs, 'variation'>
  >;
  updatedBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<AddOnUpdatedByArgs>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AddOnConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AddOnConnection'] = ResolversParentTypes['AddOnConnection']
> = {
  aggregate?: Resolver<ResolversTypes['Aggregate'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['AddOnEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AddOnEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AddOnEdge'] = ResolversParentTypes['AddOnEdge']
> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['AddOn'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Aggregate'] = ResolversParentTypes['Aggregate']
> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AlacarteTicketResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AlacarteTicket'] = ResolversParentTypes['AlacarteTicket']
> = {
  age?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  attractionAlacarteTickets?: Resolver<
    Maybe<ResolversTypes['AttractionAlacarte']>,
    ParentType,
    ContextType,
    Partial<AlacarteTicketAttractionAlacarteTicketsArgs>
  >;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<AlacarteTicketCreatedByArgs>
  >;
  documentInStages?: Resolver<
    Array<ResolversTypes['AlacarteTicket']>,
    ParentType,
    ContextType,
    RequireFields<AlacarteTicketDocumentInStagesArgs, 'includeCurrent' | 'inheritLocale' | 'stages'>
  >;
  expiration?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  history?: Resolver<
    Array<ResolversTypes['Version']>,
    ParentType,
    ContextType,
    RequireFields<AlacarteTicketHistoryArgs, 'limit' | 'skip'>
  >;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  internalTitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  publishedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  publishedBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<AlacarteTicketPublishedByArgs>
  >;
  scheduledIn?: Resolver<
    Array<ResolversTypes['ScheduledOperation']>,
    ParentType,
    ContextType,
    Partial<AlacarteTicketScheduledInArgs>
  >;
  sku?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  stage?: Resolver<ResolversTypes['Stage'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updatedBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<AlacarteTicketUpdatedByArgs>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AlacarteTicketConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AlacarteTicketConnection'] = ResolversParentTypes['AlacarteTicketConnection']
> = {
  aggregate?: Resolver<ResolversTypes['Aggregate'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['AlacarteTicketEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AlacarteTicketEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AlacarteTicketEdge'] = ResolversParentTypes['AlacarteTicketEdge']
> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['AlacarteTicket'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AlacarteTicketTypeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AlacarteTicketType'] = ResolversParentTypes['AlacarteTicketType']
> = {
  attractionAlacarteTicketTypes?: Resolver<
    Maybe<ResolversTypes['AttractionAlacarte']>,
    ParentType,
    ContextType,
    Partial<AlacarteTicketTypeAttractionAlacarteTicketTypesArgs>
  >;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<AlacarteTicketTypeCreatedByArgs>
  >;
  description?: Resolver<
    Array<ResolversTypes['Text']>,
    ParentType,
    ContextType,
    Partial<AlacarteTicketTypeDescriptionArgs>
  >;
  documentInStages?: Resolver<
    Array<ResolversTypes['AlacarteTicketType']>,
    ParentType,
    ContextType,
    RequireFields<
      AlacarteTicketTypeDocumentInStagesArgs,
      'includeCurrent' | 'inheritLocale' | 'stages'
    >
  >;
  expiration?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  history?: Resolver<
    Array<ResolversTypes['Version']>,
    ParentType,
    ContextType,
    RequireFields<AlacarteTicketTypeHistoryArgs, 'limit' | 'skip'>
  >;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  inclusions?: Resolver<
    Array<ResolversTypes['Text']>,
    ParentType,
    ContextType,
    Partial<AlacarteTicketTypeInclusionsArgs>
  >;
  inclusionsNote?: Resolver<
    Array<ResolversTypes['Text']>,
    ParentType,
    ContextType,
    Partial<AlacarteTicketTypeInclusionsNoteArgs>
  >;
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  publishedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  publishedBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<AlacarteTicketTypePublishedByArgs>
  >;
  restrictions?: Resolver<
    Array<ResolversTypes['Text']>,
    ParentType,
    ContextType,
    Partial<AlacarteTicketTypeRestrictionsArgs>
  >;
  scheduledIn?: Resolver<
    Array<ResolversTypes['ScheduledOperation']>,
    ParentType,
    ContextType,
    Partial<AlacarteTicketTypeScheduledInArgs>
  >;
  stage?: Resolver<ResolversTypes['Stage'], ParentType, ContextType>;
  ticketType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updatedBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<AlacarteTicketTypeUpdatedByArgs>
  >;
  validPostPurchaseForDateRange?: Resolver<
    Array<ResolversTypes['Text']>,
    ParentType,
    ContextType,
    Partial<AlacarteTicketTypeValidPostPurchaseForDateRangeArgs>
  >;
  validity?: Resolver<
    Array<ResolversTypes['Text']>,
    ParentType,
    ContextType,
    Partial<AlacarteTicketTypeValidityArgs>
  >;
  validityPostPurchase?: Resolver<
    Array<ResolversTypes['Text']>,
    ParentType,
    ContextType,
    Partial<AlacarteTicketTypeValidityPostPurchaseArgs>
  >;
  validityPostPurchaseNote?: Resolver<
    Array<ResolversTypes['Text']>,
    ParentType,
    ContextType,
    Partial<AlacarteTicketTypeValidityPostPurchaseNoteArgs>
  >;
  validityShort?: Resolver<
    Array<ResolversTypes['Text']>,
    ParentType,
    ContextType,
    Partial<AlacarteTicketTypeValidityShortArgs>
  >;
  validityWillCall?: Resolver<
    Array<ResolversTypes['Text']>,
    ParentType,
    ContextType,
    Partial<AlacarteTicketTypeValidityWillCallArgs>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AlacarteTicketTypeConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AlacarteTicketTypeConnection'] = ResolversParentTypes['AlacarteTicketTypeConnection']
> = {
  aggregate?: Resolver<ResolversTypes['Aggregate'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['AlacarteTicketTypeEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AlacarteTicketTypeEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AlacarteTicketTypeEdge'] = ResolversParentTypes['AlacarteTicketTypeEdge']
> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['AlacarteTicketType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticleResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Article'] = ResolversParentTypes['Article']
> = {
  author?: Resolver<
    Maybe<ResolversTypes['Author']>,
    ParentType,
    ContextType,
    Partial<ArticleAuthorArgs>
  >;
  body?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  category?: Resolver<
    Maybe<ResolversTypes['ArticleCategory']>,
    ParentType,
    ContextType,
    Partial<ArticleCategoryArgs>
  >;
  createdAt?: Resolver<
    ResolversTypes['DateTime'],
    ParentType,
    ContextType,
    RequireFields<ArticleCreatedAtArgs, 'variation'>
  >;
  createdBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<ArticleCreatedByArgs>
  >;
  documentInStages?: Resolver<
    Array<ResolversTypes['Article']>,
    ParentType,
    ContextType,
    RequireFields<ArticleDocumentInStagesArgs, 'includeCurrent' | 'inheritLocale' | 'stages'>
  >;
  firstPublishedAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  hero?: Resolver<ResolversTypes['Asset'], ParentType, ContextType, Partial<ArticleHeroArgs>>;
  history?: Resolver<
    Array<ResolversTypes['Version']>,
    ParentType,
    ContextType,
    RequireFields<ArticleHistoryArgs, 'limit' | 'skip'>
  >;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  key?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  locale?: Resolver<ResolversTypes['Locale'], ParentType, ContextType>;
  localizations?: Resolver<
    Array<ResolversTypes['Article']>,
    ParentType,
    ContextType,
    RequireFields<ArticleLocalizationsArgs, 'includeCurrent' | 'locales'>
  >;
  publishedAt?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType,
    RequireFields<ArticlePublishedAtArgs, 'variation'>
  >;
  publishedBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<ArticlePublishedByArgs>
  >;
  qabu?: Resolver<Maybe<ResolversTypes['Json']>, ParentType, ContextType>;
  scheduledIn?: Resolver<
    Array<ResolversTypes['ScheduledOperation']>,
    ParentType,
    ContextType,
    Partial<ArticleScheduledInArgs>
  >;
  seo?: Resolver<Maybe<ResolversTypes['Seo']>, ParentType, ContextType, Partial<ArticleSeoArgs>>;
  seoDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  seoTitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  showPublishDate?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  stage?: Resolver<ResolversTypes['Stage'], ParentType, ContextType>;
  tags?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  teaser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['ArticleContentType']>, ParentType, ContextType>;
  updatedAt?: Resolver<
    ResolversTypes['DateTime'],
    ParentType,
    ContextType,
    RequireFields<ArticleUpdatedAtArgs, 'variation'>
  >;
  updatedBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<ArticleUpdatedByArgs>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticleCategoryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ArticleCategory'] = ResolversParentTypes['ArticleCategory']
> = {
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<ArticleCategoryCreatedByArgs>
  >;
  documentInStages?: Resolver<
    Array<ResolversTypes['ArticleCategory']>,
    ParentType,
    ContextType,
    RequireFields<
      ArticleCategoryDocumentInStagesArgs,
      'includeCurrent' | 'inheritLocale' | 'stages'
    >
  >;
  history?: Resolver<
    Array<ResolversTypes['Version']>,
    ParentType,
    ContextType,
    RequireFields<ArticleCategoryHistoryArgs, 'limit' | 'skip'>
  >;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  product?: Resolver<
    Maybe<ResolversTypes['Product']>,
    ParentType,
    ContextType,
    Partial<ArticleCategoryProductArgs>
  >;
  publishedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  publishedBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<ArticleCategoryPublishedByArgs>
  >;
  qabu?: Resolver<Maybe<ResolversTypes['Json']>, ParentType, ContextType>;
  scheduledIn?: Resolver<
    Array<ResolversTypes['ScheduledOperation']>,
    ParentType,
    ContextType,
    Partial<ArticleCategoryScheduledInArgs>
  >;
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  stage?: Resolver<ResolversTypes['Stage'], ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updatedBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<ArticleCategoryUpdatedByArgs>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticleCategoryConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ArticleCategoryConnection'] = ResolversParentTypes['ArticleCategoryConnection']
> = {
  aggregate?: Resolver<ResolversTypes['Aggregate'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['ArticleCategoryEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticleCategoryEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ArticleCategoryEdge'] = ResolversParentTypes['ArticleCategoryEdge']
> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['ArticleCategory'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticleConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ArticleConnection'] = ResolversParentTypes['ArticleConnection']
> = {
  aggregate?: Resolver<ResolversTypes['Aggregate'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['ArticleEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArticleEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ArticleEdge'] = ResolversParentTypes['ArticleEdge']
> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['Article'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AssetResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Asset'] = ResolversParentTypes['Asset']
> = {
  blurDataURL?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<
    ResolversTypes['DateTime'],
    ParentType,
    ContextType,
    RequireFields<AssetCreatedAtArgs, 'variation'>
  >;
  createdBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<AssetCreatedByArgs>
  >;
  crossPromoImageProduct?: Resolver<
    Array<ResolversTypes['Product']>,
    ParentType,
    ContextType,
    Partial<AssetCrossPromoImageProductArgs>
  >;
  documentInStages?: Resolver<
    Array<ResolversTypes['Asset']>,
    ParentType,
    ContextType,
    RequireFields<AssetDocumentInStagesArgs, 'includeCurrent' | 'inheritLocale' | 'stages'>
  >;
  fileName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  handle?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  height?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  heroArticle?: Resolver<
    Array<ResolversTypes['Article']>,
    ParentType,
    ContextType,
    Partial<AssetHeroArticleArgs>
  >;
  heroAttraction?: Resolver<
    Array<ResolversTypes['Attraction']>,
    ParentType,
    ContextType,
    Partial<AssetHeroAttractionArgs>
  >;
  heroLandingPage?: Resolver<
    Array<ResolversTypes['LandingPage']>,
    ParentType,
    ContextType,
    Partial<AssetHeroLandingPageArgs>
  >;
  heroPage?: Resolver<
    Array<ResolversTypes['Page']>,
    ParentType,
    ContextType,
    Partial<AssetHeroPageArgs>
  >;
  heroPageTemplate?: Resolver<
    Array<ResolversTypes['PageTemplate']>,
    ParentType,
    ContextType,
    Partial<AssetHeroPageTemplateArgs>
  >;
  heroProduct?: Resolver<
    Array<ResolversTypes['Product']>,
    ParentType,
    ContextType,
    Partial<AssetHeroProductArgs>
  >;
  history?: Resolver<
    Array<ResolversTypes['Version']>,
    ParentType,
    ContextType,
    RequireFields<AssetHistoryArgs, 'limit' | 'skip'>
  >;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  imageAddOn?: Resolver<
    Array<ResolversTypes['AddOn']>,
    ParentType,
    ContextType,
    Partial<AssetImageAddOnArgs>
  >;
  imageBlock?: Resolver<
    Array<ResolversTypes['Block']>,
    ParentType,
    ContextType,
    Partial<AssetImageBlockArgs>
  >;
  imageImage?: Resolver<
    Array<ResolversTypes['Image']>,
    ParentType,
    ContextType,
    Partial<AssetImageImageArgs>
  >;
  imagePartnerOffer?: Resolver<
    Array<ResolversTypes['PartnerOffer']>,
    ParentType,
    ContextType,
    Partial<AssetImagePartnerOfferArgs>
  >;
  internalTitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  locale?: Resolver<ResolversTypes['Locale'], ParentType, ContextType>;
  localizations?: Resolver<
    Array<ResolversTypes['Asset']>,
    ParentType,
    ContextType,
    RequireFields<AssetLocalizationsArgs, 'includeCurrent' | 'locales'>
  >;
  logoAttraction?: Resolver<
    Array<ResolversTypes['Attraction']>,
    ParentType,
    ContextType,
    Partial<AssetLogoAttractionArgs>
  >;
  logoProduct?: Resolver<
    Array<ResolversTypes['Product']>,
    ParentType,
    ContextType,
    Partial<AssetLogoProductArgs>
  >;
  logoSpotOutlineProduct?: Resolver<
    Array<ResolversTypes['Product']>,
    ParentType,
    ContextType,
    Partial<AssetLogoSpotOutlineProductArgs>
  >;
  logoSpotProduct?: Resolver<
    Array<ResolversTypes['Product']>,
    ParentType,
    ContextType,
    Partial<AssetLogoSpotProductArgs>
  >;
  logoTicketGroup?: Resolver<
    Array<ResolversTypes['TicketGroup']>,
    ParentType,
    ContextType,
    Partial<AssetLogoTicketGroupArgs>
  >;
  logoTicketProduct?: Resolver<
    Array<ResolversTypes['Product']>,
    ParentType,
    ContextType,
    Partial<AssetLogoTicketProductArgs>
  >;
  logoWhiteProduct?: Resolver<
    Array<ResolversTypes['Product']>,
    ParentType,
    ContextType,
    Partial<AssetLogoWhiteProductArgs>
  >;
  mapImageProduct?: Resolver<
    Array<ResolversTypes['Product']>,
    ParentType,
    ContextType,
    Partial<AssetMapImageProductArgs>
  >;
  mimeType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  productImageProduct?: Resolver<
    Array<ResolversTypes['Product']>,
    ParentType,
    ContextType,
    Partial<AssetProductImageProductArgs>
  >;
  publishedAt?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType,
    RequireFields<AssetPublishedAtArgs, 'variation'>
  >;
  publishedBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<AssetPublishedByArgs>
  >;
  relatedProductActivityItem?: Resolver<
    Array<ResolversTypes['ProductActivityItem']>,
    ParentType,
    ContextType,
    Partial<AssetRelatedProductActivityItemArgs>
  >;
  scheduledIn?: Resolver<
    Array<ResolversTypes['ScheduledOperation']>,
    ParentType,
    ContextType,
    Partial<AssetScheduledInArgs>
  >;
  size?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  stage?: Resolver<ResolversTypes['Stage'], ParentType, ContextType>;
  thumbAttraction?: Resolver<
    Array<ResolversTypes['Attraction']>,
    ParentType,
    ContextType,
    Partial<AssetThumbAttractionArgs>
  >;
  thumbProduct?: Resolver<
    Array<ResolversTypes['Product']>,
    ParentType,
    ContextType,
    Partial<AssetThumbProductArgs>
  >;
  thumbnailVideo?: Resolver<
    Array<ResolversTypes['Video']>,
    ParentType,
    ContextType,
    Partial<AssetThumbnailVideoArgs>
  >;
  ticketsBackgroundProductReservation?: Resolver<
    Array<ResolversTypes['ProductReservation']>,
    ParentType,
    ContextType,
    Partial<AssetTicketsBackgroundProductReservationArgs>
  >;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<
    ResolversTypes['DateTime'],
    ParentType,
    ContextType,
    RequireFields<AssetUpdatedAtArgs, 'variation'>
  >;
  updatedBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<AssetUpdatedByArgs>
  >;
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType, Partial<AssetUrlArgs>>;
  variants?: Resolver<
    Array<ResolversTypes['AssetVariant']>,
    ParentType,
    ContextType,
    Partial<AssetVariantsArgs>
  >;
  width?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AssetConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AssetConnection'] = ResolversParentTypes['AssetConnection']
> = {
  aggregate?: Resolver<ResolversTypes['Aggregate'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['AssetEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AssetEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AssetEdge'] = ResolversParentTypes['AssetEdge']
> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['Asset'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AssetVariantResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AssetVariant'] = ResolversParentTypes['AssetVariant']
> = {
  asset?: Resolver<
    ResolversTypes['Asset'],
    ParentType,
    ContextType,
    Partial<AssetVariantAssetArgs>
  >;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  size?: Resolver<ResolversTypes['AssetSizes'], ParentType, ContextType>;
  stage?: Resolver<ResolversTypes['Stage'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AssetVariantConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AssetVariantConnection'] = ResolversParentTypes['AssetVariantConnection']
> = {
  aggregate?: Resolver<ResolversTypes['Aggregate'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['AssetVariantEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AssetVariantEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AssetVariantEdge'] = ResolversParentTypes['AssetVariantEdge']
> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['AssetVariant'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AssetVariantParentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AssetVariantParent'] = ResolversParentTypes['AssetVariantParent']
> = {
  __resolveType: TypeResolveFn<'Asset', ParentType, ContextType>;
};

export type AttractionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Attraction'] = ResolversParentTypes['Attraction']
> = {
  accessibility?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  addon?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  addonAttractionProducts?: Resolver<
    Array<ResolversTypes['Product']>,
    ParentType,
    ContextType,
    Partial<AttractionAddonAttractionProductsArgs>
  >;
  admission?: Resolver<
    Array<ResolversTypes['Text']>,
    ParentType,
    ContextType,
    Partial<AttractionAdmissionArgs>
  >;
  affiliateId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ageAdult?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ageChild?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  alacarte?: Resolver<
    Maybe<ResolversTypes['AttractionAlacarte']>,
    ParentType,
    ContextType,
    Partial<AttractionAlacarteArgs>
  >;
  amenities?: Resolver<
    Array<ResolversTypes['Text']>,
    ParentType,
    ContextType,
    Partial<AttractionAmenitiesArgs>
  >;
  attractionAddOn?: Resolver<
    Maybe<ResolversTypes['AddOn']>,
    ParentType,
    ContextType,
    Partial<AttractionAttractionAddOnArgs>
  >;
  availability?: Resolver<
    Maybe<ResolversTypes['AttractionAvailability']>,
    ParentType,
    ContextType,
    Partial<AttractionAvailabilityArgs>
  >;
  bestTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<
    ResolversTypes['DateTime'],
    ParentType,
    ContextType,
    RequireFields<AttractionCreatedAtArgs, 'variation'>
  >;
  createdBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<AttractionCreatedByArgs>
  >;
  documentInStages?: Resolver<
    Array<ResolversTypes['Attraction']>,
    ParentType,
    ContextType,
    RequireFields<AttractionDocumentInStagesArgs, 'includeCurrent' | 'inheritLocale' | 'stages'>
  >;
  exclusiveFeature?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  exclusiveTo?: Resolver<
    Maybe<ResolversTypes['Product']>,
    ParentType,
    ContextType,
    Partial<AttractionExclusiveToArgs>
  >;
  gallery?: Resolver<
    Array<ResolversTypes['AttractionGallery']>,
    ParentType,
    ContextType,
    Partial<AttractionGalleryArgs>
  >;
  googlePlaceId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hero?: Resolver<
    Maybe<ResolversTypes['Asset']>,
    ParentType,
    ContextType,
    Partial<AttractionHeroArgs>
  >;
  heroCredit?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  highlights?: Resolver<
    Array<ResolversTypes['Text']>,
    ParentType,
    ContextType,
    Partial<AttractionHighlightsArgs>
  >;
  history?: Resolver<
    Array<ResolversTypes['Version']>,
    ParentType,
    ContextType,
    RequireFields<AttractionHistoryArgs, 'limit' | 'skip'>
  >;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  intro?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  locale?: Resolver<ResolversTypes['Locale'], ParentType, ContextType>;
  localizations?: Resolver<
    Array<ResolversTypes['Attraction']>,
    ParentType,
    ContextType,
    RequireFields<AttractionLocalizationsArgs, 'includeCurrent' | 'locales'>
  >;
  locations?: Resolver<
    Array<ResolversTypes['AttractionLocation']>,
    ParentType,
    ContextType,
    Partial<AttractionLocationsArgs>
  >;
  logo?: Resolver<
    Maybe<ResolversTypes['Asset']>,
    ParentType,
    ContextType,
    Partial<AttractionLogoArgs>
  >;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  nextPrice?: Resolver<
    Maybe<ResolversTypes['AttractionPrice']>,
    ParentType,
    ContextType,
    Partial<AttractionNextPriceArgs>
  >;
  offers?: Resolver<
    Array<ResolversTypes['AttractionOffer']>,
    ParentType,
    ContextType,
    Partial<AttractionOffersArgs>
  >;
  openStatus?: Resolver<
    Maybe<ResolversTypes['Text']>,
    ParentType,
    ContextType,
    Partial<AttractionOpenStatusArgs>
  >;
  parking?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  partnerOffers?: Resolver<
    Array<ResolversTypes['PartnerOffer']>,
    ParentType,
    ContextType,
    Partial<AttractionPartnerOffersArgs>
  >;
  planning?: Resolver<
    Maybe<ResolversTypes['DetailBlock']>,
    ParentType,
    ContextType,
    Partial<AttractionPlanningArgs>
  >;
  price?: Resolver<
    Maybe<ResolversTypes['AttractionPrice']>,
    ParentType,
    ContextType,
    Partial<AttractionPriceArgs>
  >;
  products?: Resolver<
    Array<ResolversTypes['Product']>,
    ParentType,
    ContextType,
    Partial<AttractionProductsArgs>
  >;
  promos?: Resolver<
    Array<ResolversTypes['Promo']>,
    ParentType,
    ContextType,
    Partial<AttractionPromosArgs>
  >;
  publishedAt?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType,
    RequireFields<AttractionPublishedAtArgs, 'variation'>
  >;
  publishedBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<AttractionPublishedByArgs>
  >;
  qabu?: Resolver<Maybe<ResolversTypes['Json']>, ParentType, ContextType>;
  reservation?: Resolver<
    Maybe<ResolversTypes['AttractionReservation']>,
    ParentType,
    ContextType,
    Partial<AttractionReservationArgs>
  >;
  safety?: Resolver<
    Array<ResolversTypes['Text']>,
    ParentType,
    ContextType,
    Partial<AttractionSafetyArgs>
  >;
  scheduledIn?: Resolver<
    Array<ResolversTypes['ScheduledOperation']>,
    ParentType,
    ContextType,
    Partial<AttractionScheduledInArgs>
  >;
  seo?: Resolver<Maybe<ResolversTypes['Seo']>, ParentType, ContextType, Partial<AttractionSeoArgs>>;
  shortIntro?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  skuProperties?: Resolver<
    Array<ResolversTypes['SkuProperty']>,
    ParentType,
    ContextType,
    Partial<AttractionSkuPropertiesArgs>
  >;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  stage?: Resolver<ResolversTypes['Stage'], ParentType, ContextType>;
  thumb?: Resolver<
    Maybe<ResolversTypes['Asset']>,
    ParentType,
    ContextType,
    Partial<AttractionThumbArgs>
  >;
  tips?: Resolver<
    Array<ResolversTypes['Text']>,
    ParentType,
    ContextType,
    Partial<AttractionTipsArgs>
  >;
  updatedAt?: Resolver<
    ResolversTypes['DateTime'],
    ParentType,
    ContextType,
    RequireFields<AttractionUpdatedAtArgs, 'variation'>
  >;
  updatedBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<AttractionUpdatedByArgs>
  >;
  variants?: Resolver<
    Array<ResolversTypes['AttractionVariant']>,
    ParentType,
    ContextType,
    Partial<AttractionVariantsArgs>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AttractionAlacarteResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AttractionAlacarte'] = ResolversParentTypes['AttractionAlacarte']
> = {
  attraction?: Resolver<
    Maybe<ResolversTypes['Attraction']>,
    ParentType,
    ContextType,
    Partial<AttractionAlacarteAttractionArgs>
  >;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<AttractionAlacarteCreatedByArgs>
  >;
  customInstructions?: Resolver<
    Array<ResolversTypes['TitleDescription']>,
    ParentType,
    ContextType,
    Partial<AttractionAlacarteCustomInstructionsArgs>
  >;
  description?: Resolver<
    Array<ResolversTypes['Text']>,
    ParentType,
    ContextType,
    Partial<AttractionAlacarteDescriptionArgs>
  >;
  documentInStages?: Resolver<
    Array<ResolversTypes['AttractionAlacarte']>,
    ParentType,
    ContextType,
    RequireFields<
      AttractionAlacarteDocumentInStagesArgs,
      'includeCurrent' | 'inheritLocale' | 'stages'
    >
  >;
  filters?: Resolver<Maybe<ResolversTypes['Json']>, ParentType, ContextType>;
  hideTicketPicker?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  history?: Resolver<
    Array<ResolversTypes['Version']>,
    ParentType,
    ContextType,
    RequireFields<AttractionAlacarteHistoryArgs, 'limit' | 'skip'>
  >;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  publishedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  publishedBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<AttractionAlacartePublishedByArgs>
  >;
  redemption?: Resolver<
    Array<ResolversTypes['Text']>,
    ParentType,
    ContextType,
    Partial<AttractionAlacarteRedemptionArgs>
  >;
  redemptionShort?: Resolver<
    Array<ResolversTypes['Text']>,
    ParentType,
    ContextType,
    Partial<AttractionAlacarteRedemptionShortArgs>
  >;
  restrictionsCommon?: Resolver<
    Array<ResolversTypes['Text']>,
    ParentType,
    ContextType,
    Partial<AttractionAlacarteRestrictionsCommonArgs>
  >;
  scheduledIn?: Resolver<
    Array<ResolversTypes['ScheduledOperation']>,
    ParentType,
    ContextType,
    Partial<AttractionAlacarteScheduledInArgs>
  >;
  stage?: Resolver<ResolversTypes['Stage'], ParentType, ContextType>;
  termsAndConditions?: Resolver<
    Array<ResolversTypes['Text']>,
    ParentType,
    ContextType,
    Partial<AttractionAlacarteTermsAndConditionsArgs>
  >;
  ticketTypes?: Resolver<
    Array<ResolversTypes['AlacarteTicketType']>,
    ParentType,
    ContextType,
    Partial<AttractionAlacarteTicketTypesArgs>
  >;
  tickets?: Resolver<
    Array<ResolversTypes['AlacarteTicket']>,
    ParentType,
    ContextType,
    Partial<AttractionAlacarteTicketsArgs>
  >;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updatedBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<AttractionAlacarteUpdatedByArgs>
  >;
  validityCommon?: Resolver<
    Array<ResolversTypes['Text']>,
    ParentType,
    ContextType,
    Partial<AttractionAlacarteValidityCommonArgs>
  >;
  validityWillCall?: Resolver<
    Array<ResolversTypes['Text']>,
    ParentType,
    ContextType,
    Partial<AttractionAlacarteValidityWillCallArgs>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AttractionAlacarteConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AttractionAlacarteConnection'] = ResolversParentTypes['AttractionAlacarteConnection']
> = {
  aggregate?: Resolver<ResolversTypes['Aggregate'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['AttractionAlacarteEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AttractionAlacarteEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AttractionAlacarteEdge'] = ResolversParentTypes['AttractionAlacarteEdge']
> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['AttractionAlacarte'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AttractionAvailabilityResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AttractionAvailability'] = ResolversParentTypes['AttractionAvailability']
> = {
  attraction?: Resolver<
    Maybe<ResolversTypes['Attraction']>,
    ParentType,
    ContextType,
    Partial<AttractionAvailabilityAttractionArgs>
  >;
  attractionVariant?: Resolver<
    Maybe<ResolversTypes['AttractionVariant']>,
    ParentType,
    ContextType,
    Partial<AttractionAvailabilityAttractionVariantArgs>
  >;
  createdAt?: Resolver<
    ResolversTypes['DateTime'],
    ParentType,
    ContextType,
    RequireFields<AttractionAvailabilityCreatedAtArgs, 'variation'>
  >;
  createdBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<AttractionAvailabilityCreatedByArgs>
  >;
  documentInStages?: Resolver<
    Array<ResolversTypes['AttractionAvailability']>,
    ParentType,
    ContextType,
    RequireFields<
      AttractionAvailabilityDocumentInStagesArgs,
      'includeCurrent' | 'inheritLocale' | 'stages'
    >
  >;
  history?: Resolver<
    Array<ResolversTypes['Version']>,
    ParentType,
    ContextType,
    RequireFields<AttractionAvailabilityHistoryArgs, 'limit' | 'skip'>
  >;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  internalTitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  limited?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  locale?: Resolver<ResolversTypes['Locale'], ParentType, ContextType>;
  localizations?: Resolver<
    Array<ResolversTypes['AttractionAvailability']>,
    ParentType,
    ContextType,
    RequireFields<AttractionAvailabilityLocalizationsArgs, 'includeCurrent' | 'locales'>
  >;
  publishedAt?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType,
    RequireFields<AttractionAvailabilityPublishedAtArgs, 'variation'>
  >;
  publishedBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<AttractionAvailabilityPublishedByArgs>
  >;
  qabu?: Resolver<Maybe<ResolversTypes['Json']>, ParentType, ContextType>;
  schedule?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  scheduledIn?: Resolver<
    Array<ResolversTypes['ScheduledOperation']>,
    ParentType,
    ContextType,
    Partial<AttractionAvailabilityScheduledInArgs>
  >;
  soldOut?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  stage?: Resolver<ResolversTypes['Stage'], ParentType, ContextType>;
  updatedAt?: Resolver<
    ResolversTypes['DateTime'],
    ParentType,
    ContextType,
    RequireFields<AttractionAvailabilityUpdatedAtArgs, 'variation'>
  >;
  updatedBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<AttractionAvailabilityUpdatedByArgs>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AttractionAvailabilityConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AttractionAvailabilityConnection'] = ResolversParentTypes['AttractionAvailabilityConnection']
> = {
  aggregate?: Resolver<ResolversTypes['Aggregate'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['AttractionAvailabilityEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AttractionAvailabilityEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AttractionAvailabilityEdge'] = ResolversParentTypes['AttractionAvailabilityEdge']
> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['AttractionAvailability'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AttractionConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AttractionConnection'] = ResolversParentTypes['AttractionConnection']
> = {
  aggregate?: Resolver<ResolversTypes['Aggregate'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['AttractionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AttractionEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AttractionEdge'] = ResolversParentTypes['AttractionEdge']
> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['Attraction'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AttractionGalleryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AttractionGallery'] = ResolversParentTypes['AttractionGallery']
> = {
  __resolveType: TypeResolveFn<'Image' | 'Video', ParentType, ContextType>;
};

export type AttractionGroupResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AttractionGroup'] = ResolversParentTypes['AttractionGroup']
> = {
  attractions?: Resolver<
    Array<ResolversTypes['Attraction']>,
    ParentType,
    ContextType,
    Partial<AttractionGroupAttractionsArgs>
  >;
  choices?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  stage?: Resolver<ResolversTypes['Stage'], ParentType, ContextType>;
  title?: Resolver<
    Maybe<ResolversTypes['Text']>,
    ParentType,
    ContextType,
    Partial<AttractionGroupTitleArgs>
  >;
  type?: Resolver<Maybe<ResolversTypes['AttractionGroupType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AttractionGroupConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AttractionGroupConnection'] = ResolversParentTypes['AttractionGroupConnection']
> = {
  aggregate?: Resolver<ResolversTypes['Aggregate'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['AttractionGroupEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AttractionGroupEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AttractionGroupEdge'] = ResolversParentTypes['AttractionGroupEdge']
> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['AttractionGroup'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AttractionGroupParentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AttractionGroupParent'] = ResolversParentTypes['AttractionGroupParent']
> = {
  __resolveType: TypeResolveFn<'ProductAttractionLineup', ParentType, ContextType>;
};

export type AttractionLocationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AttractionLocation'] = ResolversParentTypes['AttractionLocation']
> = {
  address?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  addressNote?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  attraction?: Resolver<
    Maybe<ResolversTypes['Attraction']>,
    ParentType,
    ContextType,
    Partial<AttractionLocationAttractionArgs>
  >;
  createdAt?: Resolver<
    ResolversTypes['DateTime'],
    ParentType,
    ContextType,
    RequireFields<AttractionLocationCreatedAtArgs, 'variation'>
  >;
  createdBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<AttractionLocationCreatedByArgs>
  >;
  documentInStages?: Resolver<
    Array<ResolversTypes['AttractionLocation']>,
    ParentType,
    ContextType,
    RequireFields<
      AttractionLocationDocumentInStagesArgs,
      'includeCurrent' | 'inheritLocale' | 'stages'
    >
  >;
  geolocation?: Resolver<Maybe<ResolversTypes['Location']>, ParentType, ContextType>;
  history?: Resolver<
    Array<ResolversTypes['Version']>,
    ParentType,
    ContextType,
    RequireFields<AttractionLocationHistoryArgs, 'limit' | 'skip'>
  >;
  hours?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  hoursNotes?: Resolver<
    Array<ResolversTypes['Text']>,
    ParentType,
    ContextType,
    Partial<AttractionLocationHoursNotesArgs>
  >;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  locale?: Resolver<ResolversTypes['Locale'], ParentType, ContextType>;
  localizations?: Resolver<
    Array<ResolversTypes['AttractionLocation']>,
    ParentType,
    ContextType,
    RequireFields<AttractionLocationLocalizationsArgs, 'includeCurrent' | 'locales'>
  >;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  phone?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  publishedAt?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType,
    RequireFields<AttractionLocationPublishedAtArgs, 'variation'>
  >;
  publishedBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<AttractionLocationPublishedByArgs>
  >;
  qabu?: Resolver<Maybe<ResolversTypes['Json']>, ParentType, ContextType>;
  scheduledIn?: Resolver<
    Array<ResolversTypes['ScheduledOperation']>,
    ParentType,
    ContextType,
    Partial<AttractionLocationScheduledInArgs>
  >;
  stage?: Resolver<ResolversTypes['Stage'], ParentType, ContextType>;
  updatedAt?: Resolver<
    ResolversTypes['DateTime'],
    ParentType,
    ContextType,
    RequireFields<AttractionLocationUpdatedAtArgs, 'variation'>
  >;
  updatedBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<AttractionLocationUpdatedByArgs>
  >;
  website?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AttractionLocationConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AttractionLocationConnection'] = ResolversParentTypes['AttractionLocationConnection']
> = {
  aggregate?: Resolver<ResolversTypes['Aggregate'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['AttractionLocationEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AttractionLocationEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AttractionLocationEdge'] = ResolversParentTypes['AttractionLocationEdge']
> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['AttractionLocation'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AttractionOfferResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AttractionOffer'] = ResolversParentTypes['AttractionOffer']
> = {
  active?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  attraction?: Resolver<
    Maybe<ResolversTypes['Attraction']>,
    ParentType,
    ContextType,
    Partial<AttractionOfferAttractionArgs>
  >;
  attractionVariants?: Resolver<
    Array<ResolversTypes['AttractionVariant']>,
    ParentType,
    ContextType,
    Partial<AttractionOfferAttractionVariantsArgs>
  >;
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  conditions?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<
    ResolversTypes['DateTime'],
    ParentType,
    ContextType,
    RequireFields<AttractionOfferCreatedAtArgs, 'variation'>
  >;
  createdBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<AttractionOfferCreatedByArgs>
  >;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  documentInStages?: Resolver<
    Array<ResolversTypes['AttractionOffer']>,
    ParentType,
    ContextType,
    RequireFields<
      AttractionOfferDocumentInStagesArgs,
      'includeCurrent' | 'inheritLocale' | 'stages'
    >
  >;
  endDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  expirationDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  history?: Resolver<
    Array<ResolversTypes['Version']>,
    ParentType,
    ContextType,
    RequireFields<AttractionOfferHistoryArgs, 'limit' | 'skip'>
  >;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  internalTitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  locale?: Resolver<ResolversTypes['Locale'], ParentType, ContextType>;
  localizations?: Resolver<
    Array<ResolversTypes['AttractionOffer']>,
    ParentType,
    ContextType,
    RequireFields<AttractionOfferLocalizationsArgs, 'includeCurrent' | 'locales'>
  >;
  platforms?: Resolver<Array<ResolversTypes['Platforms']>, ParentType, ContextType>;
  products?: Resolver<
    Array<ResolversTypes['Product']>,
    ParentType,
    ContextType,
    Partial<AttractionOfferProductsArgs>
  >;
  publishedAt?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType,
    RequireFields<AttractionOfferPublishedAtArgs, 'variation'>
  >;
  publishedBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<AttractionOfferPublishedByArgs>
  >;
  qabu?: Resolver<Maybe<ResolversTypes['Json']>, ParentType, ContextType>;
  scheduledIn?: Resolver<
    Array<ResolversTypes['ScheduledOperation']>,
    ParentType,
    ContextType,
    Partial<AttractionOfferScheduledInArgs>
  >;
  stage?: Resolver<ResolversTypes['Stage'], ParentType, ContextType>;
  startDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['AttractionOfferType'], ParentType, ContextType>;
  updatedAt?: Resolver<
    ResolversTypes['DateTime'],
    ParentType,
    ContextType,
    RequireFields<AttractionOfferUpdatedAtArgs, 'variation'>
  >;
  updatedBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<AttractionOfferUpdatedByArgs>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AttractionOfferConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AttractionOfferConnection'] = ResolversParentTypes['AttractionOfferConnection']
> = {
  aggregate?: Resolver<ResolversTypes['Aggregate'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['AttractionOfferEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AttractionOfferEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AttractionOfferEdge'] = ResolversParentTypes['AttractionOfferEdge']
> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['AttractionOffer'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AttractionPriceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AttractionPrice'] = ResolversParentTypes['AttractionPrice']
> = {
  adult?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  attraction?: Resolver<
    Maybe<ResolversTypes['Attraction']>,
    ParentType,
    ContextType,
    Partial<AttractionPriceAttractionArgs>
  >;
  attractionNext?: Resolver<
    Maybe<ResolversTypes['Attraction']>,
    ParentType,
    ContextType,
    Partial<AttractionPriceAttractionNextArgs>
  >;
  attractionVariant?: Resolver<
    Maybe<ResolversTypes['AttractionVariant']>,
    ParentType,
    ContextType,
    Partial<AttractionPriceAttractionVariantArgs>
  >;
  child?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  childNote?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<
    ResolversTypes['DateTime'],
    ParentType,
    ContextType,
    RequireFields<AttractionPriceCreatedAtArgs, 'variation'>
  >;
  createdBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<AttractionPriceCreatedByArgs>
  >;
  documentInStages?: Resolver<
    Array<ResolversTypes['AttractionPrice']>,
    ParentType,
    ContextType,
    RequireFields<
      AttractionPriceDocumentInStagesArgs,
      'includeCurrent' | 'inheritLocale' | 'stages'
    >
  >;
  history?: Resolver<
    Array<ResolversTypes['Version']>,
    ParentType,
    ContextType,
    RequireFields<AttractionPriceHistoryArgs, 'limit' | 'skip'>
  >;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  includesTax?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  internalTitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  locale?: Resolver<ResolversTypes['Locale'], ParentType, ContextType>;
  localizations?: Resolver<
    Array<ResolversTypes['AttractionPrice']>,
    ParentType,
    ContextType,
    RequireFields<AttractionPriceLocalizationsArgs, 'includeCurrent' | 'locales'>
  >;
  note?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  publishedAt?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType,
    RequireFields<AttractionPricePublishedAtArgs, 'variation'>
  >;
  publishedBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<AttractionPricePublishedByArgs>
  >;
  qabu?: Resolver<Maybe<ResolversTypes['Json']>, ParentType, ContextType>;
  scheduledIn?: Resolver<
    Array<ResolversTypes['ScheduledOperation']>,
    ParentType,
    ContextType,
    Partial<AttractionPriceScheduledInArgs>
  >;
  stage?: Resolver<ResolversTypes['Stage'], ParentType, ContextType>;
  updatedAt?: Resolver<
    ResolversTypes['DateTime'],
    ParentType,
    ContextType,
    RequireFields<AttractionPriceUpdatedAtArgs, 'variation'>
  >;
  updatedBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<AttractionPriceUpdatedByArgs>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AttractionPriceConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AttractionPriceConnection'] = ResolversParentTypes['AttractionPriceConnection']
> = {
  aggregate?: Resolver<ResolversTypes['Aggregate'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['AttractionPriceEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AttractionPriceEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AttractionPriceEdge'] = ResolversParentTypes['AttractionPriceEdge']
> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['AttractionPrice'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AttractionReservationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AttractionReservation'] = ResolversParentTypes['AttractionReservation']
> = {
  attraction?: Resolver<
    Maybe<ResolversTypes['Attraction']>,
    ParentType,
    ContextType,
    Partial<AttractionReservationAttractionArgs>
  >;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<AttractionReservationCreatedByArgs>
  >;
  documentInStages?: Resolver<
    Array<ResolversTypes['AttractionReservation']>,
    ParentType,
    ContextType,
    RequireFields<
      AttractionReservationDocumentInStagesArgs,
      'includeCurrent' | 'inheritLocale' | 'stages'
    >
  >;
  history?: Resolver<
    Array<ResolversTypes['Version']>,
    ParentType,
    ContextType,
    RequireFields<AttractionReservationHistoryArgs, 'limit' | 'skip'>
  >;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  internalTitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  publishedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  publishedBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<AttractionReservationPublishedByArgs>
  >;
  scheduledIn?: Resolver<
    Array<ResolversTypes['ScheduledOperation']>,
    ParentType,
    ContextType,
    Partial<AttractionReservationScheduledInArgs>
  >;
  showAttractionClosed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  showPhoneButton?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  showReservationButton?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  showReservationIndicator?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  stage?: Resolver<ResolversTypes['Stage'], ParentType, ContextType>;
  text?: Resolver<
    Array<ResolversTypes['ReservationText']>,
    ParentType,
    ContextType,
    Partial<AttractionReservationTextArgs>
  >;
  texts?: Resolver<
    Array<ResolversTypes['Text']>,
    ParentType,
    ContextType,
    Partial<AttractionReservationTextsArgs>
  >;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updatedBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<AttractionReservationUpdatedByArgs>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AttractionReservationConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AttractionReservationConnection'] = ResolversParentTypes['AttractionReservationConnection']
> = {
  aggregate?: Resolver<ResolversTypes['Aggregate'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['AttractionReservationEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AttractionReservationEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AttractionReservationEdge'] = ResolversParentTypes['AttractionReservationEdge']
> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['AttractionReservation'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AttractionVariantResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AttractionVariant'] = ResolversParentTypes['AttractionVariant']
> = {
  admission?: Resolver<
    Array<ResolversTypes['Text']>,
    ParentType,
    ContextType,
    Partial<AttractionVariantAdmissionArgs>
  >;
  attraction?: Resolver<
    Maybe<ResolversTypes['Attraction']>,
    ParentType,
    ContextType,
    Partial<AttractionVariantAttractionArgs>
  >;
  availability?: Resolver<
    Maybe<ResolversTypes['AttractionAvailability']>,
    ParentType,
    ContextType,
    Partial<AttractionVariantAvailabilityArgs>
  >;
  createdAt?: Resolver<
    ResolversTypes['DateTime'],
    ParentType,
    ContextType,
    RequireFields<AttractionVariantCreatedAtArgs, 'variation'>
  >;
  createdBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<AttractionVariantCreatedByArgs>
  >;
  documentInStages?: Resolver<
    Array<ResolversTypes['AttractionVariant']>,
    ParentType,
    ContextType,
    RequireFields<
      AttractionVariantDocumentInStagesArgs,
      'includeCurrent' | 'inheritLocale' | 'stages'
    >
  >;
  exclusiveFeature?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  gallery?: Resolver<
    Array<ResolversTypes['AttractionVariantGallery']>,
    ParentType,
    ContextType,
    Partial<AttractionVariantGalleryArgs>
  >;
  highlights?: Resolver<
    Array<ResolversTypes['Text']>,
    ParentType,
    ContextType,
    Partial<AttractionVariantHighlightsArgs>
  >;
  history?: Resolver<
    Array<ResolversTypes['Version']>,
    ParentType,
    ContextType,
    RequireFields<AttractionVariantHistoryArgs, 'limit' | 'skip'>
  >;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  internalTitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  intro?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  locale?: Resolver<ResolversTypes['Locale'], ParentType, ContextType>;
  localizations?: Resolver<
    Array<ResolversTypes['AttractionVariant']>,
    ParentType,
    ContextType,
    RequireFields<AttractionVariantLocalizationsArgs, 'includeCurrent' | 'locales'>
  >;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  offers?: Resolver<
    Array<ResolversTypes['AttractionOffer']>,
    ParentType,
    ContextType,
    Partial<AttractionVariantOffersArgs>
  >;
  openStatus?: Resolver<
    Maybe<ResolversTypes['Text']>,
    ParentType,
    ContextType,
    Partial<AttractionVariantOpenStatusArgs>
  >;
  price?: Resolver<
    Maybe<ResolversTypes['AttractionPrice']>,
    ParentType,
    ContextType,
    Partial<AttractionVariantPriceArgs>
  >;
  product?: Resolver<
    Maybe<ResolversTypes['Product']>,
    ParentType,
    ContextType,
    Partial<AttractionVariantProductArgs>
  >;
  publishedAt?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType,
    RequireFields<AttractionVariantPublishedAtArgs, 'variation'>
  >;
  publishedBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<AttractionVariantPublishedByArgs>
  >;
  qabu?: Resolver<Maybe<ResolversTypes['Json']>, ParentType, ContextType>;
  scheduledIn?: Resolver<
    Array<ResolversTypes['ScheduledOperation']>,
    ParentType,
    ContextType,
    Partial<AttractionVariantScheduledInArgs>
  >;
  shortIntro?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  stage?: Resolver<ResolversTypes['Stage'], ParentType, ContextType>;
  updatedAt?: Resolver<
    ResolversTypes['DateTime'],
    ParentType,
    ContextType,
    RequireFields<AttractionVariantUpdatedAtArgs, 'variation'>
  >;
  updatedBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<AttractionVariantUpdatedByArgs>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AttractionVariantConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AttractionVariantConnection'] = ResolversParentTypes['AttractionVariantConnection']
> = {
  aggregate?: Resolver<ResolversTypes['Aggregate'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['AttractionVariantEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AttractionVariantEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AttractionVariantEdge'] = ResolversParentTypes['AttractionVariantEdge']
> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['AttractionVariant'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AttractionVariantGalleryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AttractionVariantGallery'] = ResolversParentTypes['AttractionVariantGallery']
> = {
  __resolveType: TypeResolveFn<'Image' | 'Video', ParentType, ContextType>;
};

export type AuthorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Author'] = ResolversParentTypes['Author']
> = {
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<AuthorCreatedByArgs>
  >;
  documentInStages?: Resolver<
    Array<ResolversTypes['Author']>,
    ParentType,
    ContextType,
    RequireFields<AuthorDocumentInStagesArgs, 'includeCurrent' | 'inheritLocale' | 'stages'>
  >;
  history?: Resolver<
    Array<ResolversTypes['Version']>,
    ParentType,
    ContextType,
    RequireFields<AuthorHistoryArgs, 'limit' | 'skip'>
  >;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  publishedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  publishedBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<AuthorPublishedByArgs>
  >;
  scheduledIn?: Resolver<
    Array<ResolversTypes['ScheduledOperation']>,
    ParentType,
    ContextType,
    Partial<AuthorScheduledInArgs>
  >;
  stage?: Resolver<ResolversTypes['Stage'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updatedBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<AuthorUpdatedByArgs>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AuthorConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AuthorConnection'] = ResolversParentTypes['AuthorConnection']
> = {
  aggregate?: Resolver<ResolversTypes['Aggregate'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['AuthorEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AuthorEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AuthorEdge'] = ResolversParentTypes['AuthorEdge']
> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['Author'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BatchPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BatchPayload'] = ResolversParentTypes['BatchPayload']
> = {
  count?: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BlockResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Block'] = ResolversParentTypes['Block']
> = {
  active?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  blockGroups?: Resolver<
    Array<ResolversTypes['BlockGroup']>,
    ParentType,
    ContextType,
    Partial<BlockBlockGroupsArgs>
  >;
  body?: Resolver<Maybe<ResolversTypes['Text']>, ParentType, ContextType, Partial<BlockBodyArgs>>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<BlockCreatedByArgs>
  >;
  cta?: Resolver<Maybe<ResolversTypes['Text']>, ParentType, ContextType, Partial<BlockCtaArgs>>;
  documentInStages?: Resolver<
    Array<ResolversTypes['Block']>,
    ParentType,
    ContextType,
    RequireFields<BlockDocumentInStagesArgs, 'includeCurrent' | 'inheritLocale' | 'stages'>
  >;
  history?: Resolver<
    Array<ResolversTypes['Version']>,
    ParentType,
    ContextType,
    RequireFields<BlockHistoryArgs, 'limit' | 'skip'>
  >;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  image?: Resolver<
    Maybe<ResolversTypes['Asset']>,
    ParentType,
    ContextType,
    Partial<BlockImageArgs>
  >;
  internalTitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  publishedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  publishedBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<BlockPublishedByArgs>
  >;
  scheduledIn?: Resolver<
    Array<ResolversTypes['ScheduledOperation']>,
    ParentType,
    ContextType,
    Partial<BlockScheduledInArgs>
  >;
  stage?: Resolver<ResolversTypes['Stage'], ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['Text']>, ParentType, ContextType, Partial<BlockTitleArgs>>;
  ui?: Resolver<Array<ResolversTypes['BlockUI']>, ParentType, ContextType, Partial<BlockUiArgs>>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updatedBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<BlockUpdatedByArgs>
  >;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BlockConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BlockConnection'] = ResolversParentTypes['BlockConnection']
> = {
  aggregate?: Resolver<ResolversTypes['Aggregate'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['BlockEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BlockEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BlockEdge'] = ResolversParentTypes['BlockEdge']
> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['Block'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BlockGroupResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BlockGroup'] = ResolversParentTypes['BlockGroup']
> = {
  blocks?: Resolver<
    Array<ResolversTypes['Block']>,
    ParentType,
    ContextType,
    Partial<BlockGroupBlocksArgs>
  >;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<BlockGroupCreatedByArgs>
  >;
  documentInStages?: Resolver<
    Array<ResolversTypes['BlockGroup']>,
    ParentType,
    ContextType,
    RequireFields<BlockGroupDocumentInStagesArgs, 'includeCurrent' | 'inheritLocale' | 'stages'>
  >;
  history?: Resolver<
    Array<ResolversTypes['Version']>,
    ParentType,
    ContextType,
    RequireFields<BlockGroupHistoryArgs, 'limit' | 'skip'>
  >;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  publishedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  publishedBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<BlockGroupPublishedByArgs>
  >;
  scheduledIn?: Resolver<
    Array<ResolversTypes['ScheduledOperation']>,
    ParentType,
    ContextType,
    Partial<BlockGroupScheduledInArgs>
  >;
  stage?: Resolver<ResolversTypes['Stage'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updatedBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<BlockGroupUpdatedByArgs>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BlockGroupConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BlockGroupConnection'] = ResolversParentTypes['BlockGroupConnection']
> = {
  aggregate?: Resolver<ResolversTypes['Aggregate'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['BlockGroupEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BlockGroupEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BlockGroupEdge'] = ResolversParentTypes['BlockGroupEdge']
> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['BlockGroup'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BlockUiResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BlockUI'] = ResolversParentTypes['BlockUI']
> = {
  align?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  analyticsEvent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  colorScheme?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  component?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fullWidth?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  platforms?: Resolver<Array<ResolversTypes['Platforms']>, ParentType, ContextType>;
  props?: Resolver<Maybe<ResolversTypes['Json']>, ParentType, ContextType>;
  stage?: Resolver<ResolversTypes['Stage'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BlockUiConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BlockUIConnection'] = ResolversParentTypes['BlockUIConnection']
> = {
  aggregate?: Resolver<ResolversTypes['Aggregate'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['BlockUIEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BlockUiEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BlockUIEdge'] = ResolversParentTypes['BlockUIEdge']
> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['BlockUI'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BlockUiParentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BlockUIParent'] = ResolversParentTypes['BlockUIParent']
> = {
  __resolveType: TypeResolveFn<'Block', ParentType, ContextType>;
};

export type ColorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Color'] = ResolversParentTypes['Color']
> = {
  css?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  hex?: Resolver<ResolversTypes['Hex'], ParentType, ContextType>;
  rgba?: Resolver<ResolversTypes['RGBA'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CrossPromoBannerResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CrossPromoBanner'] = ResolversParentTypes['CrossPromoBanner']
> = {
  copyright?: Resolver<
    Maybe<ResolversTypes['Text']>,
    ParentType,
    ContextType,
    Partial<CrossPromoBannerCopyrightArgs>
  >;
  description?: Resolver<
    Maybe<ResolversTypes['Text']>,
    ParentType,
    ContextType,
    Partial<CrossPromoBannerDescriptionArgs>
  >;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  image?: Resolver<
    Maybe<ResolversTypes['Asset']>,
    ParentType,
    ContextType,
    Partial<CrossPromoBannerImageArgs>
  >;
  logo?: Resolver<
    Maybe<ResolversTypes['Asset']>,
    ParentType,
    ContextType,
    Partial<CrossPromoBannerLogoArgs>
  >;
  product?: Resolver<
    Maybe<ResolversTypes['Product']>,
    ParentType,
    ContextType,
    Partial<CrossPromoBannerProductArgs>
  >;
  stage?: Resolver<ResolversTypes['Stage'], ParentType, ContextType>;
  subDescription?: Resolver<
    Maybe<ResolversTypes['Text']>,
    ParentType,
    ContextType,
    Partial<CrossPromoBannerSubDescriptionArgs>
  >;
  title?: Resolver<
    Maybe<ResolversTypes['Text']>,
    ParentType,
    ContextType,
    Partial<CrossPromoBannerTitleArgs>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CrossPromoBannerConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CrossPromoBannerConnection'] = ResolversParentTypes['CrossPromoBannerConnection']
> = {
  aggregate?: Resolver<ResolversTypes['Aggregate'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['CrossPromoBannerEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CrossPromoBannerEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CrossPromoBannerEdge'] = ResolversParentTypes['CrossPromoBannerEdge']
> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['CrossPromoBanner'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CrossPromoBannerParentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CrossPromoBannerParent'] = ResolversParentTypes['CrossPromoBannerParent']
> = {
  __resolveType: TypeResolveFn<'Promo', ParentType, ContextType>;
};

export interface DateScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Date'], any> {
  name: 'Date';
}

export type DateRangeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DateRange'] = ResolversParentTypes['DateRange']
> = {
  endDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  stage?: Resolver<ResolversTypes['Stage'], ParentType, ContextType>;
  startDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DateRangeConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DateRangeConnection'] = ResolversParentTypes['DateRangeConnection']
> = {
  aggregate?: Resolver<ResolversTypes['Aggregate'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['DateRangeEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DateRangeEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DateRangeEdge'] = ResolversParentTypes['DateRangeEdge']
> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['DateRange'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DateRangeParentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DateRangeParent'] = ResolversParentTypes['DateRangeParent']
> = {
  __resolveType: TypeResolveFn<'PromoTicketText', ParentType, ContextType>;
};

export interface DateTimeScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['DateTime'], any> {
  name: 'DateTime';
}

export type DetailBlockResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DetailBlock'] = ResolversParentTypes['DetailBlock']
> = {
  attractions?: Resolver<
    Array<ResolversTypes['Attraction']>,
    ParentType,
    ContextType,
    Partial<DetailBlockAttractionsArgs>
  >;
  body?: Resolver<
    Array<ResolversTypes['Text']>,
    ParentType,
    ContextType,
    Partial<DetailBlockBodyArgs>
  >;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<DetailBlockCreatedByArgs>
  >;
  documentInStages?: Resolver<
    Array<ResolversTypes['DetailBlock']>,
    ParentType,
    ContextType,
    RequireFields<DetailBlockDocumentInStagesArgs, 'includeCurrent' | 'inheritLocale' | 'stages'>
  >;
  history?: Resolver<
    Array<ResolversTypes['Version']>,
    ParentType,
    ContextType,
    RequireFields<DetailBlockHistoryArgs, 'limit' | 'skip'>
  >;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  internalTitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  products?: Resolver<
    Array<ResolversTypes['Product']>,
    ParentType,
    ContextType,
    Partial<DetailBlockProductsArgs>
  >;
  publishedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  publishedBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<DetailBlockPublishedByArgs>
  >;
  scheduledIn?: Resolver<
    Array<ResolversTypes['ScheduledOperation']>,
    ParentType,
    ContextType,
    Partial<DetailBlockScheduledInArgs>
  >;
  stage?: Resolver<ResolversTypes['Stage'], ParentType, ContextType>;
  title?: Resolver<
    Maybe<ResolversTypes['Text']>,
    ParentType,
    ContextType,
    Partial<DetailBlockTitleArgs>
  >;
  type?: Resolver<Maybe<ResolversTypes['DetailType']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updatedBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<DetailBlockUpdatedByArgs>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DetailBlockConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DetailBlockConnection'] = ResolversParentTypes['DetailBlockConnection']
> = {
  aggregate?: Resolver<ResolversTypes['Aggregate'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['DetailBlockEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DetailBlockEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DetailBlockEdge'] = ResolversParentTypes['DetailBlockEdge']
> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['DetailBlock'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DocumentVersionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DocumentVersion'] = ResolversParentTypes['DocumentVersion']
> = {
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  data?: Resolver<Maybe<ResolversTypes['Json']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  revision?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  stage?: Resolver<ResolversTypes['Stage'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GeneralReservationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GeneralReservation'] = ResolversParentTypes['GeneralReservation']
> = {
  attractionText?: Resolver<
    Array<ResolversTypes['ReservationText']>,
    ParentType,
    ContextType,
    Partial<GeneralReservationAttractionTextArgs>
  >;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<GeneralReservationCreatedByArgs>
  >;
  documentInStages?: Resolver<
    Array<ResolversTypes['GeneralReservation']>,
    ParentType,
    ContextType,
    RequireFields<
      GeneralReservationDocumentInStagesArgs,
      'includeCurrent' | 'inheritLocale' | 'stages'
    >
  >;
  generalText?: Resolver<
    Array<ResolversTypes['ReservationText']>,
    ParentType,
    ContextType,
    Partial<GeneralReservationGeneralTextArgs>
  >;
  history?: Resolver<
    Array<ResolversTypes['Version']>,
    ParentType,
    ContextType,
    RequireFields<GeneralReservationHistoryArgs, 'limit' | 'skip'>
  >;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  productText?: Resolver<
    Array<ResolversTypes['ReservationText']>,
    ParentType,
    ContextType,
    Partial<GeneralReservationProductTextArgs>
  >;
  publishedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  publishedBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<GeneralReservationPublishedByArgs>
  >;
  scheduledIn?: Resolver<
    Array<ResolversTypes['ScheduledOperation']>,
    ParentType,
    ContextType,
    Partial<GeneralReservationScheduledInArgs>
  >;
  stage?: Resolver<ResolversTypes['Stage'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updatedBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<GeneralReservationUpdatedByArgs>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GeneralReservationConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GeneralReservationConnection'] = ResolversParentTypes['GeneralReservationConnection']
> = {
  aggregate?: Resolver<ResolversTypes['Aggregate'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['GeneralReservationEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GeneralReservationEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GeneralReservationEdge'] = ResolversParentTypes['GeneralReservationEdge']
> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['GeneralReservation'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface HexScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Hex'], any> {
  name: 'Hex';
}

export type ImageResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Image'] = ResolversParentTypes['Image']
> = {
  attraction?: Resolver<
    Maybe<ResolversTypes['Attraction']>,
    ParentType,
    ContextType,
    Partial<ImageAttractionArgs>
  >;
  attractionVariants?: Resolver<
    Array<ResolversTypes['AttractionVariant']>,
    ParentType,
    ContextType,
    Partial<ImageAttractionVariantsArgs>
  >;
  caption?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<
    ResolversTypes['DateTime'],
    ParentType,
    ContextType,
    RequireFields<ImageCreatedAtArgs, 'variation'>
  >;
  createdBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<ImageCreatedByArgs>
  >;
  credit?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  documentInStages?: Resolver<
    Array<ResolversTypes['Image']>,
    ParentType,
    ContextType,
    RequireFields<ImageDocumentInStagesArgs, 'includeCurrent' | 'inheritLocale' | 'stages'>
  >;
  history?: Resolver<
    Array<ResolversTypes['Version']>,
    ParentType,
    ContextType,
    RequireFields<ImageHistoryArgs, 'limit' | 'skip'>
  >;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  image?: Resolver<ResolversTypes['Asset'], ParentType, ContextType, Partial<ImageImageArgs>>;
  internalTags?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  internalTitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  key?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  locale?: Resolver<ResolversTypes['Locale'], ParentType, ContextType>;
  localizations?: Resolver<
    Array<ResolversTypes['Image']>,
    ParentType,
    ContextType,
    RequireFields<ImageLocalizationsArgs, 'includeCurrent' | 'locales'>
  >;
  publishedAt?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType,
    RequireFields<ImagePublishedAtArgs, 'variation'>
  >;
  publishedBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<ImagePublishedByArgs>
  >;
  qabu?: Resolver<Maybe<ResolversTypes['Json']>, ParentType, ContextType>;
  scheduledIn?: Resolver<
    Array<ResolversTypes['ScheduledOperation']>,
    ParentType,
    ContextType,
    Partial<ImageScheduledInArgs>
  >;
  stage?: Resolver<ResolversTypes['Stage'], ParentType, ContextType>;
  updatedAt?: Resolver<
    ResolversTypes['DateTime'],
    ParentType,
    ContextType,
    RequireFields<ImageUpdatedAtArgs, 'variation'>
  >;
  updatedBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<ImageUpdatedByArgs>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ImageConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ImageConnection'] = ResolversParentTypes['ImageConnection']
> = {
  aggregate?: Resolver<ResolversTypes['Aggregate'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['ImageEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ImageEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ImageEdge'] = ResolversParentTypes['ImageEdge']
> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['Image'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface JsonScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Json'], any> {
  name: 'Json';
}

export type LandingPageResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LandingPage'] = ResolversParentTypes['LandingPage']
> = {
  body?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  bodyRich?: Resolver<
    Maybe<ResolversTypes['LandingPageBodyRichRichText']>,
    ParentType,
    ContextType
  >;
  createdAt?: Resolver<
    ResolversTypes['DateTime'],
    ParentType,
    ContextType,
    RequireFields<LandingPageCreatedAtArgs, 'variation'>
  >;
  createdBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<LandingPageCreatedByArgs>
  >;
  documentInStages?: Resolver<
    Array<ResolversTypes['LandingPage']>,
    ParentType,
    ContextType,
    RequireFields<LandingPageDocumentInStagesArgs, 'includeCurrent' | 'inheritLocale' | 'stages'>
  >;
  hero?: Resolver<
    Maybe<ResolversTypes['Asset']>,
    ParentType,
    ContextType,
    Partial<LandingPageHeroArgs>
  >;
  history?: Resolver<
    Array<ResolversTypes['Version']>,
    ParentType,
    ContextType,
    RequireFields<LandingPageHistoryArgs, 'limit' | 'skip'>
  >;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  internalTitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  locale?: Resolver<ResolversTypes['Locale'], ParentType, ContextType>;
  localizations?: Resolver<
    Array<ResolversTypes['LandingPage']>,
    ParentType,
    ContextType,
    RequireFields<LandingPageLocalizationsArgs, 'includeCurrent' | 'locales'>
  >;
  publishedAt?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType,
    RequireFields<LandingPagePublishedAtArgs, 'variation'>
  >;
  publishedBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<LandingPagePublishedByArgs>
  >;
  scheduledIn?: Resolver<
    Array<ResolversTypes['ScheduledOperation']>,
    ParentType,
    ContextType,
    Partial<LandingPageScheduledInArgs>
  >;
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  stage?: Resolver<ResolversTypes['Stage'], ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<
    ResolversTypes['DateTime'],
    ParentType,
    ContextType,
    RequireFields<LandingPageUpdatedAtArgs, 'variation'>
  >;
  updatedBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<LandingPageUpdatedByArgs>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LandingPageBodyRichRichTextResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LandingPageBodyRichRichText'] = ResolversParentTypes['LandingPageBodyRichRichText']
> = {
  html?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  json?: Resolver<ResolversTypes['RichTextAST'], ParentType, ContextType>;
  markdown?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  raw?: Resolver<ResolversTypes['RichTextAST'], ParentType, ContextType>;
  references?: Resolver<
    Array<ResolversTypes['LandingPageBodyRichRichTextEmbeddedTypes']>,
    ParentType,
    ContextType,
    Partial<LandingPageBodyRichRichTextReferencesArgs>
  >;
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LandingPageBodyRichRichTextEmbeddedTypesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LandingPageBodyRichRichTextEmbeddedTypes'] = ResolversParentTypes['LandingPageBodyRichRichTextEmbeddedTypes']
> = {
  __resolveType: TypeResolveFn<
    'Article' | 'Asset' | 'Attraction' | 'Product' | 'Text',
    ParentType,
    ContextType
  >;
};

export type LandingPageConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LandingPageConnection'] = ResolversParentTypes['LandingPageConnection']
> = {
  aggregate?: Resolver<ResolversTypes['Aggregate'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['LandingPageEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LandingPageEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LandingPageEdge'] = ResolversParentTypes['LandingPageEdge']
> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['LandingPage'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LinkResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Link'] = ResolversParentTypes['Link']
> = {
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<LinkCreatedByArgs>
  >;
  documentInStages?: Resolver<
    Array<ResolversTypes['Link']>,
    ParentType,
    ContextType,
    RequireFields<LinkDocumentInStagesArgs, 'includeCurrent' | 'inheritLocale' | 'stages'>
  >;
  history?: Resolver<
    Array<ResolversTypes['Version']>,
    ParentType,
    ContextType,
    RequireFields<LinkHistoryArgs, 'limit' | 'skip'>
  >;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  internalTitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  label?: Resolver<Maybe<ResolversTypes['Text']>, ParentType, ContextType, Partial<LinkLabelArgs>>;
  menus?: Resolver<Array<ResolversTypes['Menu']>, ParentType, ContextType, Partial<LinkMenusArgs>>;
  publishedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  publishedBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<LinkPublishedByArgs>
  >;
  scheduledIn?: Resolver<
    Array<ResolversTypes['ScheduledOperation']>,
    ParentType,
    ContextType,
    Partial<LinkScheduledInArgs>
  >;
  stage?: Resolver<ResolversTypes['Stage'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updatedBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<LinkUpdatedByArgs>
  >;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LinkConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LinkConnection'] = ResolversParentTypes['LinkConnection']
> = {
  aggregate?: Resolver<ResolversTypes['Aggregate'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['LinkEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LinkEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LinkEdge'] = ResolversParentTypes['LinkEdge']
> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['Link'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LocationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Location'] = ResolversParentTypes['Location']
> = {
  distance?: Resolver<
    ResolversTypes['Float'],
    ParentType,
    ContextType,
    RequireFields<LocationDistanceArgs, 'from'>
  >;
  latitude?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  longitude?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface LongScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Long'], any> {
  name: 'Long';
}

export type MenuResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Menu'] = ResolversParentTypes['Menu']
> = {
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<MenuCreatedByArgs>
  >;
  documentInStages?: Resolver<
    Array<ResolversTypes['Menu']>,
    ParentType,
    ContextType,
    RequireFields<MenuDocumentInStagesArgs, 'includeCurrent' | 'inheritLocale' | 'stages'>
  >;
  history?: Resolver<
    Array<ResolversTypes['Version']>,
    ParentType,
    ContextType,
    RequireFields<MenuHistoryArgs, 'limit' | 'skip'>
  >;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  internalTitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  items?: Resolver<
    Array<ResolversTypes['MenuItems']>,
    ParentType,
    ContextType,
    Partial<MenuItemsArgs>
  >;
  menus?: Resolver<Array<ResolversTypes['Menu']>, ParentType, ContextType, Partial<MenuMenusArgs>>;
  publishedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  publishedBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<MenuPublishedByArgs>
  >;
  scheduledIn?: Resolver<
    Array<ResolversTypes['ScheduledOperation']>,
    ParentType,
    ContextType,
    Partial<MenuScheduledInArgs>
  >;
  stage?: Resolver<ResolversTypes['Stage'], ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['Text']>, ParentType, ContextType, Partial<MenuTitleArgs>>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updatedBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<MenuUpdatedByArgs>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MenuConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MenuConnection'] = ResolversParentTypes['MenuConnection']
> = {
  aggregate?: Resolver<ResolversTypes['Aggregate'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['MenuEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MenuEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MenuEdge'] = ResolversParentTypes['MenuEdge']
> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['Menu'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MenuItemsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MenuItems'] = ResolversParentTypes['MenuItems']
> = {
  __resolveType: TypeResolveFn<'Link' | 'Menu' | 'Page' | 'Product', ParentType, ContextType>;
};

export type MutationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']
> = {
  createAddOn?: Resolver<
    Maybe<ResolversTypes['AddOn']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateAddOnArgs, 'data'>
  >;
  createAlacarteTicket?: Resolver<
    Maybe<ResolversTypes['AlacarteTicket']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateAlacarteTicketArgs, 'data'>
  >;
  createAlacarteTicketType?: Resolver<
    Maybe<ResolversTypes['AlacarteTicketType']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateAlacarteTicketTypeArgs, 'data'>
  >;
  createArticle?: Resolver<
    Maybe<ResolversTypes['Article']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateArticleArgs, 'data'>
  >;
  createArticleCategory?: Resolver<
    Maybe<ResolversTypes['ArticleCategory']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateArticleCategoryArgs, 'data'>
  >;
  createAsset?: Resolver<
    Maybe<ResolversTypes['Asset']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateAssetArgs, 'data'>
  >;
  createAttraction?: Resolver<
    Maybe<ResolversTypes['Attraction']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateAttractionArgs, 'data'>
  >;
  createAttractionAlacarte?: Resolver<
    Maybe<ResolversTypes['AttractionAlacarte']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateAttractionAlacarteArgs, 'data'>
  >;
  createAttractionAvailability?: Resolver<
    Maybe<ResolversTypes['AttractionAvailability']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateAttractionAvailabilityArgs, 'data'>
  >;
  createAttractionLocation?: Resolver<
    Maybe<ResolversTypes['AttractionLocation']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateAttractionLocationArgs, 'data'>
  >;
  createAttractionOffer?: Resolver<
    Maybe<ResolversTypes['AttractionOffer']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateAttractionOfferArgs, 'data'>
  >;
  createAttractionPrice?: Resolver<
    Maybe<ResolversTypes['AttractionPrice']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateAttractionPriceArgs, 'data'>
  >;
  createAttractionReservation?: Resolver<
    Maybe<ResolversTypes['AttractionReservation']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateAttractionReservationArgs, 'data'>
  >;
  createAttractionVariant?: Resolver<
    Maybe<ResolversTypes['AttractionVariant']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateAttractionVariantArgs, 'data'>
  >;
  createAuthor?: Resolver<
    Maybe<ResolversTypes['Author']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateAuthorArgs, 'data'>
  >;
  createBlock?: Resolver<
    Maybe<ResolversTypes['Block']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateBlockArgs, 'data'>
  >;
  createBlockGroup?: Resolver<
    Maybe<ResolversTypes['BlockGroup']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateBlockGroupArgs, 'data'>
  >;
  createDetailBlock?: Resolver<
    Maybe<ResolversTypes['DetailBlock']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateDetailBlockArgs, 'data'>
  >;
  createGeneralReservation?: Resolver<
    Maybe<ResolversTypes['GeneralReservation']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateGeneralReservationArgs, 'data'>
  >;
  createImage?: Resolver<
    Maybe<ResolversTypes['Image']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateImageArgs, 'data'>
  >;
  createLandingPage?: Resolver<
    Maybe<ResolversTypes['LandingPage']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateLandingPageArgs, 'data'>
  >;
  createLink?: Resolver<
    Maybe<ResolversTypes['Link']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateLinkArgs, 'data'>
  >;
  createMenu?: Resolver<
    Maybe<ResolversTypes['Menu']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateMenuArgs, 'data'>
  >;
  createNotice?: Resolver<
    Maybe<ResolversTypes['Notice']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateNoticeArgs, 'data'>
  >;
  createPage?: Resolver<
    Maybe<ResolversTypes['Page']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreatePageArgs, 'data'>
  >;
  createPageTemplate?: Resolver<
    Maybe<ResolversTypes['PageTemplate']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreatePageTemplateArgs, 'data'>
  >;
  createPartner?: Resolver<
    Maybe<ResolversTypes['Partner']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreatePartnerArgs, 'data'>
  >;
  createPartnerOffer?: Resolver<
    Maybe<ResolversTypes['PartnerOffer']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreatePartnerOfferArgs, 'data'>
  >;
  createProduct?: Resolver<
    Maybe<ResolversTypes['Product']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateProductArgs, 'data'>
  >;
  createProductActivity?: Resolver<
    Maybe<ResolversTypes['ProductActivity']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateProductActivityArgs, 'data'>
  >;
  createProductActivityItem?: Resolver<
    Maybe<ResolversTypes['ProductActivityItem']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateProductActivityItemArgs, 'data'>
  >;
  createProductAttractionLineup?: Resolver<
    Maybe<ResolversTypes['ProductAttractionLineup']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateProductAttractionLineupArgs, 'data'>
  >;
  createProductReservation?: Resolver<
    Maybe<ResolversTypes['ProductReservation']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateProductReservationArgs, 'data'>
  >;
  createPromo?: Resolver<
    Maybe<ResolversTypes['Promo']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreatePromoArgs, 'data'>
  >;
  createReservationText?: Resolver<
    Maybe<ResolversTypes['ReservationText']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateReservationTextArgs, 'data'>
  >;
  createSandbox?: Resolver<
    Maybe<ResolversTypes['Sandbox']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateSandboxArgs, 'data'>
  >;
  createScheduledRelease?: Resolver<
    Maybe<ResolversTypes['ScheduledRelease']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateScheduledReleaseArgs, 'data'>
  >;
  createSeo?: Resolver<
    Maybe<ResolversTypes['Seo']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateSeoArgs, 'data'>
  >;
  createText?: Resolver<
    Maybe<ResolversTypes['Text']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateTextArgs, 'data'>
  >;
  createTextGroup?: Resolver<
    Maybe<ResolversTypes['TextGroup']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateTextGroupArgs, 'data'>
  >;
  createTextList?: Resolver<
    Maybe<ResolversTypes['TextList']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateTextListArgs, 'data'>
  >;
  createTicketGroup?: Resolver<
    Maybe<ResolversTypes['TicketGroup']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateTicketGroupArgs, 'data'>
  >;
  createTitleDescription?: Resolver<
    Maybe<ResolversTypes['TitleDescription']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateTitleDescriptionArgs, 'data'>
  >;
  createVideo?: Resolver<
    Maybe<ResolversTypes['Video']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateVideoArgs, 'data'>
  >;
  deleteAddOn?: Resolver<
    Maybe<ResolversTypes['AddOn']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteAddOnArgs, 'where'>
  >;
  deleteAlacarteTicket?: Resolver<
    Maybe<ResolversTypes['AlacarteTicket']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteAlacarteTicketArgs, 'where'>
  >;
  deleteAlacarteTicketType?: Resolver<
    Maybe<ResolversTypes['AlacarteTicketType']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteAlacarteTicketTypeArgs, 'where'>
  >;
  deleteArticle?: Resolver<
    Maybe<ResolversTypes['Article']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteArticleArgs, 'where'>
  >;
  deleteArticleCategory?: Resolver<
    Maybe<ResolversTypes['ArticleCategory']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteArticleCategoryArgs, 'where'>
  >;
  deleteAsset?: Resolver<
    Maybe<ResolversTypes['Asset']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteAssetArgs, 'where'>
  >;
  deleteAttraction?: Resolver<
    Maybe<ResolversTypes['Attraction']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteAttractionArgs, 'where'>
  >;
  deleteAttractionAlacarte?: Resolver<
    Maybe<ResolversTypes['AttractionAlacarte']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteAttractionAlacarteArgs, 'where'>
  >;
  deleteAttractionAvailability?: Resolver<
    Maybe<ResolversTypes['AttractionAvailability']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteAttractionAvailabilityArgs, 'where'>
  >;
  deleteAttractionLocation?: Resolver<
    Maybe<ResolversTypes['AttractionLocation']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteAttractionLocationArgs, 'where'>
  >;
  deleteAttractionOffer?: Resolver<
    Maybe<ResolversTypes['AttractionOffer']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteAttractionOfferArgs, 'where'>
  >;
  deleteAttractionPrice?: Resolver<
    Maybe<ResolversTypes['AttractionPrice']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteAttractionPriceArgs, 'where'>
  >;
  deleteAttractionReservation?: Resolver<
    Maybe<ResolversTypes['AttractionReservation']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteAttractionReservationArgs, 'where'>
  >;
  deleteAttractionVariant?: Resolver<
    Maybe<ResolversTypes['AttractionVariant']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteAttractionVariantArgs, 'where'>
  >;
  deleteAuthor?: Resolver<
    Maybe<ResolversTypes['Author']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteAuthorArgs, 'where'>
  >;
  deleteBlock?: Resolver<
    Maybe<ResolversTypes['Block']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteBlockArgs, 'where'>
  >;
  deleteBlockGroup?: Resolver<
    Maybe<ResolversTypes['BlockGroup']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteBlockGroupArgs, 'where'>
  >;
  deleteDetailBlock?: Resolver<
    Maybe<ResolversTypes['DetailBlock']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteDetailBlockArgs, 'where'>
  >;
  deleteGeneralReservation?: Resolver<
    Maybe<ResolversTypes['GeneralReservation']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteGeneralReservationArgs, 'where'>
  >;
  deleteImage?: Resolver<
    Maybe<ResolversTypes['Image']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteImageArgs, 'where'>
  >;
  deleteLandingPage?: Resolver<
    Maybe<ResolversTypes['LandingPage']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteLandingPageArgs, 'where'>
  >;
  deleteLink?: Resolver<
    Maybe<ResolversTypes['Link']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteLinkArgs, 'where'>
  >;
  deleteManyAddOns?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    Partial<MutationDeleteManyAddOnsArgs>
  >;
  deleteManyAddOnsConnection?: Resolver<
    ResolversTypes['AddOnConnection'],
    ParentType,
    ContextType,
    Partial<MutationDeleteManyAddOnsConnectionArgs>
  >;
  deleteManyAlacarteTicketTypes?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    Partial<MutationDeleteManyAlacarteTicketTypesArgs>
  >;
  deleteManyAlacarteTicketTypesConnection?: Resolver<
    ResolversTypes['AlacarteTicketTypeConnection'],
    ParentType,
    ContextType,
    Partial<MutationDeleteManyAlacarteTicketTypesConnectionArgs>
  >;
  deleteManyAlacarteTickets?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    Partial<MutationDeleteManyAlacarteTicketsArgs>
  >;
  deleteManyAlacarteTicketsConnection?: Resolver<
    ResolversTypes['AlacarteTicketConnection'],
    ParentType,
    ContextType,
    Partial<MutationDeleteManyAlacarteTicketsConnectionArgs>
  >;
  deleteManyArticleCategories?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    Partial<MutationDeleteManyArticleCategoriesArgs>
  >;
  deleteManyArticleCategoriesConnection?: Resolver<
    ResolversTypes['ArticleCategoryConnection'],
    ParentType,
    ContextType,
    Partial<MutationDeleteManyArticleCategoriesConnectionArgs>
  >;
  deleteManyArticles?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    Partial<MutationDeleteManyArticlesArgs>
  >;
  deleteManyArticlesConnection?: Resolver<
    ResolversTypes['ArticleConnection'],
    ParentType,
    ContextType,
    Partial<MutationDeleteManyArticlesConnectionArgs>
  >;
  deleteManyAssets?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    Partial<MutationDeleteManyAssetsArgs>
  >;
  deleteManyAssetsConnection?: Resolver<
    ResolversTypes['AssetConnection'],
    ParentType,
    ContextType,
    Partial<MutationDeleteManyAssetsConnectionArgs>
  >;
  deleteManyAttractionAlacartes?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    Partial<MutationDeleteManyAttractionAlacartesArgs>
  >;
  deleteManyAttractionAlacartesConnection?: Resolver<
    ResolversTypes['AttractionAlacarteConnection'],
    ParentType,
    ContextType,
    Partial<MutationDeleteManyAttractionAlacartesConnectionArgs>
  >;
  deleteManyAttractionAvailabilities?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    Partial<MutationDeleteManyAttractionAvailabilitiesArgs>
  >;
  deleteManyAttractionAvailabilitiesConnection?: Resolver<
    ResolversTypes['AttractionAvailabilityConnection'],
    ParentType,
    ContextType,
    Partial<MutationDeleteManyAttractionAvailabilitiesConnectionArgs>
  >;
  deleteManyAttractionLocations?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    Partial<MutationDeleteManyAttractionLocationsArgs>
  >;
  deleteManyAttractionLocationsConnection?: Resolver<
    ResolversTypes['AttractionLocationConnection'],
    ParentType,
    ContextType,
    Partial<MutationDeleteManyAttractionLocationsConnectionArgs>
  >;
  deleteManyAttractionOffers?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    Partial<MutationDeleteManyAttractionOffersArgs>
  >;
  deleteManyAttractionOffersConnection?: Resolver<
    ResolversTypes['AttractionOfferConnection'],
    ParentType,
    ContextType,
    Partial<MutationDeleteManyAttractionOffersConnectionArgs>
  >;
  deleteManyAttractionPrices?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    Partial<MutationDeleteManyAttractionPricesArgs>
  >;
  deleteManyAttractionPricesConnection?: Resolver<
    ResolversTypes['AttractionPriceConnection'],
    ParentType,
    ContextType,
    Partial<MutationDeleteManyAttractionPricesConnectionArgs>
  >;
  deleteManyAttractionReservations?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    Partial<MutationDeleteManyAttractionReservationsArgs>
  >;
  deleteManyAttractionReservationsConnection?: Resolver<
    ResolversTypes['AttractionReservationConnection'],
    ParentType,
    ContextType,
    Partial<MutationDeleteManyAttractionReservationsConnectionArgs>
  >;
  deleteManyAttractionVariants?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    Partial<MutationDeleteManyAttractionVariantsArgs>
  >;
  deleteManyAttractionVariantsConnection?: Resolver<
    ResolversTypes['AttractionVariantConnection'],
    ParentType,
    ContextType,
    Partial<MutationDeleteManyAttractionVariantsConnectionArgs>
  >;
  deleteManyAttractions?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    Partial<MutationDeleteManyAttractionsArgs>
  >;
  deleteManyAttractionsConnection?: Resolver<
    ResolversTypes['AttractionConnection'],
    ParentType,
    ContextType,
    Partial<MutationDeleteManyAttractionsConnectionArgs>
  >;
  deleteManyAuthors?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    Partial<MutationDeleteManyAuthorsArgs>
  >;
  deleteManyAuthorsConnection?: Resolver<
    ResolversTypes['AuthorConnection'],
    ParentType,
    ContextType,
    Partial<MutationDeleteManyAuthorsConnectionArgs>
  >;
  deleteManyBlockGroups?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    Partial<MutationDeleteManyBlockGroupsArgs>
  >;
  deleteManyBlockGroupsConnection?: Resolver<
    ResolversTypes['BlockGroupConnection'],
    ParentType,
    ContextType,
    Partial<MutationDeleteManyBlockGroupsConnectionArgs>
  >;
  deleteManyBlocks?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    Partial<MutationDeleteManyBlocksArgs>
  >;
  deleteManyBlocksConnection?: Resolver<
    ResolversTypes['BlockConnection'],
    ParentType,
    ContextType,
    Partial<MutationDeleteManyBlocksConnectionArgs>
  >;
  deleteManyDetailBlocks?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    Partial<MutationDeleteManyDetailBlocksArgs>
  >;
  deleteManyDetailBlocksConnection?: Resolver<
    ResolversTypes['DetailBlockConnection'],
    ParentType,
    ContextType,
    Partial<MutationDeleteManyDetailBlocksConnectionArgs>
  >;
  deleteManyGeneralReservations?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    Partial<MutationDeleteManyGeneralReservationsArgs>
  >;
  deleteManyGeneralReservationsConnection?: Resolver<
    ResolversTypes['GeneralReservationConnection'],
    ParentType,
    ContextType,
    Partial<MutationDeleteManyGeneralReservationsConnectionArgs>
  >;
  deleteManyImages?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    Partial<MutationDeleteManyImagesArgs>
  >;
  deleteManyImagesConnection?: Resolver<
    ResolversTypes['ImageConnection'],
    ParentType,
    ContextType,
    Partial<MutationDeleteManyImagesConnectionArgs>
  >;
  deleteManyLandingPages?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    Partial<MutationDeleteManyLandingPagesArgs>
  >;
  deleteManyLandingPagesConnection?: Resolver<
    ResolversTypes['LandingPageConnection'],
    ParentType,
    ContextType,
    Partial<MutationDeleteManyLandingPagesConnectionArgs>
  >;
  deleteManyLinks?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    Partial<MutationDeleteManyLinksArgs>
  >;
  deleteManyLinksConnection?: Resolver<
    ResolversTypes['LinkConnection'],
    ParentType,
    ContextType,
    Partial<MutationDeleteManyLinksConnectionArgs>
  >;
  deleteManyMenus?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    Partial<MutationDeleteManyMenusArgs>
  >;
  deleteManyMenusConnection?: Resolver<
    ResolversTypes['MenuConnection'],
    ParentType,
    ContextType,
    Partial<MutationDeleteManyMenusConnectionArgs>
  >;
  deleteManyNotices?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    Partial<MutationDeleteManyNoticesArgs>
  >;
  deleteManyNoticesConnection?: Resolver<
    ResolversTypes['NoticeConnection'],
    ParentType,
    ContextType,
    Partial<MutationDeleteManyNoticesConnectionArgs>
  >;
  deleteManyPageTemplates?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    Partial<MutationDeleteManyPageTemplatesArgs>
  >;
  deleteManyPageTemplatesConnection?: Resolver<
    ResolversTypes['PageTemplateConnection'],
    ParentType,
    ContextType,
    Partial<MutationDeleteManyPageTemplatesConnectionArgs>
  >;
  deleteManyPages?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    Partial<MutationDeleteManyPagesArgs>
  >;
  deleteManyPagesConnection?: Resolver<
    ResolversTypes['PageConnection'],
    ParentType,
    ContextType,
    Partial<MutationDeleteManyPagesConnectionArgs>
  >;
  deleteManyPartnerOffers?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    Partial<MutationDeleteManyPartnerOffersArgs>
  >;
  deleteManyPartnerOffersConnection?: Resolver<
    ResolversTypes['PartnerOfferConnection'],
    ParentType,
    ContextType,
    Partial<MutationDeleteManyPartnerOffersConnectionArgs>
  >;
  deleteManyPartners?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    Partial<MutationDeleteManyPartnersArgs>
  >;
  deleteManyPartnersConnection?: Resolver<
    ResolversTypes['PartnerConnection'],
    ParentType,
    ContextType,
    Partial<MutationDeleteManyPartnersConnectionArgs>
  >;
  deleteManyProductActivities?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    Partial<MutationDeleteManyProductActivitiesArgs>
  >;
  deleteManyProductActivitiesConnection?: Resolver<
    ResolversTypes['ProductActivityConnection'],
    ParentType,
    ContextType,
    Partial<MutationDeleteManyProductActivitiesConnectionArgs>
  >;
  deleteManyProductActivityItems?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    Partial<MutationDeleteManyProductActivityItemsArgs>
  >;
  deleteManyProductActivityItemsConnection?: Resolver<
    ResolversTypes['ProductActivityItemConnection'],
    ParentType,
    ContextType,
    Partial<MutationDeleteManyProductActivityItemsConnectionArgs>
  >;
  deleteManyProductAttractionLineups?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    Partial<MutationDeleteManyProductAttractionLineupsArgs>
  >;
  deleteManyProductAttractionLineupsConnection?: Resolver<
    ResolversTypes['ProductAttractionLineupConnection'],
    ParentType,
    ContextType,
    Partial<MutationDeleteManyProductAttractionLineupsConnectionArgs>
  >;
  deleteManyProductReservations?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    Partial<MutationDeleteManyProductReservationsArgs>
  >;
  deleteManyProductReservationsConnection?: Resolver<
    ResolversTypes['ProductReservationConnection'],
    ParentType,
    ContextType,
    Partial<MutationDeleteManyProductReservationsConnectionArgs>
  >;
  deleteManyProducts?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    Partial<MutationDeleteManyProductsArgs>
  >;
  deleteManyProductsConnection?: Resolver<
    ResolversTypes['ProductConnection'],
    ParentType,
    ContextType,
    Partial<MutationDeleteManyProductsConnectionArgs>
  >;
  deleteManyPromos?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    Partial<MutationDeleteManyPromosArgs>
  >;
  deleteManyPromosConnection?: Resolver<
    ResolversTypes['PromoConnection'],
    ParentType,
    ContextType,
    Partial<MutationDeleteManyPromosConnectionArgs>
  >;
  deleteManyReservationTexts?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    Partial<MutationDeleteManyReservationTextsArgs>
  >;
  deleteManyReservationTextsConnection?: Resolver<
    ResolversTypes['ReservationTextConnection'],
    ParentType,
    ContextType,
    Partial<MutationDeleteManyReservationTextsConnectionArgs>
  >;
  deleteManySandboxes?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    Partial<MutationDeleteManySandboxesArgs>
  >;
  deleteManySandboxesConnection?: Resolver<
    ResolversTypes['SandboxConnection'],
    ParentType,
    ContextType,
    Partial<MutationDeleteManySandboxesConnectionArgs>
  >;
  deleteManySeos?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    Partial<MutationDeleteManySeosArgs>
  >;
  deleteManySeosConnection?: Resolver<
    ResolversTypes['SeoConnection'],
    ParentType,
    ContextType,
    Partial<MutationDeleteManySeosConnectionArgs>
  >;
  deleteManyTextGroups?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    Partial<MutationDeleteManyTextGroupsArgs>
  >;
  deleteManyTextGroupsConnection?: Resolver<
    ResolversTypes['TextGroupConnection'],
    ParentType,
    ContextType,
    Partial<MutationDeleteManyTextGroupsConnectionArgs>
  >;
  deleteManyTextLists?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    Partial<MutationDeleteManyTextListsArgs>
  >;
  deleteManyTextListsConnection?: Resolver<
    ResolversTypes['TextListConnection'],
    ParentType,
    ContextType,
    Partial<MutationDeleteManyTextListsConnectionArgs>
  >;
  deleteManyTexts?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    Partial<MutationDeleteManyTextsArgs>
  >;
  deleteManyTextsConnection?: Resolver<
    ResolversTypes['TextConnection'],
    ParentType,
    ContextType,
    Partial<MutationDeleteManyTextsConnectionArgs>
  >;
  deleteManyTicketGroups?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    Partial<MutationDeleteManyTicketGroupsArgs>
  >;
  deleteManyTicketGroupsConnection?: Resolver<
    ResolversTypes['TicketGroupConnection'],
    ParentType,
    ContextType,
    Partial<MutationDeleteManyTicketGroupsConnectionArgs>
  >;
  deleteManyTitleDescriptions?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    Partial<MutationDeleteManyTitleDescriptionsArgs>
  >;
  deleteManyTitleDescriptionsConnection?: Resolver<
    ResolversTypes['TitleDescriptionConnection'],
    ParentType,
    ContextType,
    Partial<MutationDeleteManyTitleDescriptionsConnectionArgs>
  >;
  deleteManyVideos?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    Partial<MutationDeleteManyVideosArgs>
  >;
  deleteManyVideosConnection?: Resolver<
    ResolversTypes['VideoConnection'],
    ParentType,
    ContextType,
    Partial<MutationDeleteManyVideosConnectionArgs>
  >;
  deleteMenu?: Resolver<
    Maybe<ResolversTypes['Menu']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteMenuArgs, 'where'>
  >;
  deleteNotice?: Resolver<
    Maybe<ResolversTypes['Notice']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteNoticeArgs, 'where'>
  >;
  deletePage?: Resolver<
    Maybe<ResolversTypes['Page']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeletePageArgs, 'where'>
  >;
  deletePageTemplate?: Resolver<
    Maybe<ResolversTypes['PageTemplate']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeletePageTemplateArgs, 'where'>
  >;
  deletePartner?: Resolver<
    Maybe<ResolversTypes['Partner']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeletePartnerArgs, 'where'>
  >;
  deletePartnerOffer?: Resolver<
    Maybe<ResolversTypes['PartnerOffer']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeletePartnerOfferArgs, 'where'>
  >;
  deleteProduct?: Resolver<
    Maybe<ResolversTypes['Product']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteProductArgs, 'where'>
  >;
  deleteProductActivity?: Resolver<
    Maybe<ResolversTypes['ProductActivity']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteProductActivityArgs, 'where'>
  >;
  deleteProductActivityItem?: Resolver<
    Maybe<ResolversTypes['ProductActivityItem']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteProductActivityItemArgs, 'where'>
  >;
  deleteProductAttractionLineup?: Resolver<
    Maybe<ResolversTypes['ProductAttractionLineup']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteProductAttractionLineupArgs, 'where'>
  >;
  deleteProductReservation?: Resolver<
    Maybe<ResolversTypes['ProductReservation']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteProductReservationArgs, 'where'>
  >;
  deletePromo?: Resolver<
    Maybe<ResolversTypes['Promo']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeletePromoArgs, 'where'>
  >;
  deleteReservationText?: Resolver<
    Maybe<ResolversTypes['ReservationText']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteReservationTextArgs, 'where'>
  >;
  deleteSandbox?: Resolver<
    Maybe<ResolversTypes['Sandbox']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteSandboxArgs, 'where'>
  >;
  deleteScheduledOperation?: Resolver<
    Maybe<ResolversTypes['ScheduledOperation']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteScheduledOperationArgs, 'where'>
  >;
  deleteScheduledRelease?: Resolver<
    Maybe<ResolversTypes['ScheduledRelease']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteScheduledReleaseArgs, 'where'>
  >;
  deleteSeo?: Resolver<
    Maybe<ResolversTypes['Seo']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteSeoArgs, 'where'>
  >;
  deleteText?: Resolver<
    Maybe<ResolversTypes['Text']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteTextArgs, 'where'>
  >;
  deleteTextGroup?: Resolver<
    Maybe<ResolversTypes['TextGroup']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteTextGroupArgs, 'where'>
  >;
  deleteTextList?: Resolver<
    Maybe<ResolversTypes['TextList']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteTextListArgs, 'where'>
  >;
  deleteTicketGroup?: Resolver<
    Maybe<ResolversTypes['TicketGroup']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteTicketGroupArgs, 'where'>
  >;
  deleteTitleDescription?: Resolver<
    Maybe<ResolversTypes['TitleDescription']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteTitleDescriptionArgs, 'where'>
  >;
  deleteVideo?: Resolver<
    Maybe<ResolversTypes['Video']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteVideoArgs, 'where'>
  >;
  publishAddOn?: Resolver<
    Maybe<ResolversTypes['AddOn']>,
    ParentType,
    ContextType,
    RequireFields<MutationPublishAddOnArgs, 'publishBase' | 'to' | 'where' | 'withDefaultLocale'>
  >;
  publishAlacarteTicket?: Resolver<
    Maybe<ResolversTypes['AlacarteTicket']>,
    ParentType,
    ContextType,
    RequireFields<MutationPublishAlacarteTicketArgs, 'to' | 'where'>
  >;
  publishAlacarteTicketType?: Resolver<
    Maybe<ResolversTypes['AlacarteTicketType']>,
    ParentType,
    ContextType,
    RequireFields<MutationPublishAlacarteTicketTypeArgs, 'to' | 'where'>
  >;
  publishArticle?: Resolver<
    Maybe<ResolversTypes['Article']>,
    ParentType,
    ContextType,
    RequireFields<MutationPublishArticleArgs, 'publishBase' | 'to' | 'where' | 'withDefaultLocale'>
  >;
  publishArticleCategory?: Resolver<
    Maybe<ResolversTypes['ArticleCategory']>,
    ParentType,
    ContextType,
    RequireFields<MutationPublishArticleCategoryArgs, 'to' | 'where'>
  >;
  publishAsset?: Resolver<
    Maybe<ResolversTypes['Asset']>,
    ParentType,
    ContextType,
    RequireFields<MutationPublishAssetArgs, 'publishBase' | 'to' | 'where' | 'withDefaultLocale'>
  >;
  publishAttraction?: Resolver<
    Maybe<ResolversTypes['Attraction']>,
    ParentType,
    ContextType,
    RequireFields<
      MutationPublishAttractionArgs,
      'publishBase' | 'to' | 'where' | 'withDefaultLocale'
    >
  >;
  publishAttractionAlacarte?: Resolver<
    Maybe<ResolversTypes['AttractionAlacarte']>,
    ParentType,
    ContextType,
    RequireFields<MutationPublishAttractionAlacarteArgs, 'to' | 'where'>
  >;
  publishAttractionAvailability?: Resolver<
    Maybe<ResolversTypes['AttractionAvailability']>,
    ParentType,
    ContextType,
    RequireFields<
      MutationPublishAttractionAvailabilityArgs,
      'publishBase' | 'to' | 'where' | 'withDefaultLocale'
    >
  >;
  publishAttractionLocation?: Resolver<
    Maybe<ResolversTypes['AttractionLocation']>,
    ParentType,
    ContextType,
    RequireFields<
      MutationPublishAttractionLocationArgs,
      'publishBase' | 'to' | 'where' | 'withDefaultLocale'
    >
  >;
  publishAttractionOffer?: Resolver<
    Maybe<ResolversTypes['AttractionOffer']>,
    ParentType,
    ContextType,
    RequireFields<
      MutationPublishAttractionOfferArgs,
      'publishBase' | 'to' | 'where' | 'withDefaultLocale'
    >
  >;
  publishAttractionPrice?: Resolver<
    Maybe<ResolversTypes['AttractionPrice']>,
    ParentType,
    ContextType,
    RequireFields<
      MutationPublishAttractionPriceArgs,
      'publishBase' | 'to' | 'where' | 'withDefaultLocale'
    >
  >;
  publishAttractionReservation?: Resolver<
    Maybe<ResolversTypes['AttractionReservation']>,
    ParentType,
    ContextType,
    RequireFields<MutationPublishAttractionReservationArgs, 'to' | 'where'>
  >;
  publishAttractionVariant?: Resolver<
    Maybe<ResolversTypes['AttractionVariant']>,
    ParentType,
    ContextType,
    RequireFields<
      MutationPublishAttractionVariantArgs,
      'publishBase' | 'to' | 'where' | 'withDefaultLocale'
    >
  >;
  publishAuthor?: Resolver<
    Maybe<ResolversTypes['Author']>,
    ParentType,
    ContextType,
    RequireFields<MutationPublishAuthorArgs, 'to' | 'where'>
  >;
  publishBlock?: Resolver<
    Maybe<ResolversTypes['Block']>,
    ParentType,
    ContextType,
    RequireFields<MutationPublishBlockArgs, 'to' | 'where'>
  >;
  publishBlockGroup?: Resolver<
    Maybe<ResolversTypes['BlockGroup']>,
    ParentType,
    ContextType,
    RequireFields<MutationPublishBlockGroupArgs, 'to' | 'where'>
  >;
  publishDetailBlock?: Resolver<
    Maybe<ResolversTypes['DetailBlock']>,
    ParentType,
    ContextType,
    RequireFields<MutationPublishDetailBlockArgs, 'to' | 'where'>
  >;
  publishGeneralReservation?: Resolver<
    Maybe<ResolversTypes['GeneralReservation']>,
    ParentType,
    ContextType,
    RequireFields<MutationPublishGeneralReservationArgs, 'to' | 'where'>
  >;
  publishImage?: Resolver<
    Maybe<ResolversTypes['Image']>,
    ParentType,
    ContextType,
    RequireFields<MutationPublishImageArgs, 'publishBase' | 'to' | 'where' | 'withDefaultLocale'>
  >;
  publishLandingPage?: Resolver<
    Maybe<ResolversTypes['LandingPage']>,
    ParentType,
    ContextType,
    RequireFields<
      MutationPublishLandingPageArgs,
      'publishBase' | 'to' | 'where' | 'withDefaultLocale'
    >
  >;
  publishLink?: Resolver<
    Maybe<ResolversTypes['Link']>,
    ParentType,
    ContextType,
    RequireFields<MutationPublishLinkArgs, 'to' | 'where'>
  >;
  publishManyAddOns?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationPublishManyAddOnsArgs, 'publishBase' | 'to' | 'withDefaultLocale'>
  >;
  publishManyAddOnsConnection?: Resolver<
    ResolversTypes['AddOnConnection'],
    ParentType,
    ContextType,
    RequireFields<
      MutationPublishManyAddOnsConnectionArgs,
      'from' | 'publishBase' | 'to' | 'withDefaultLocale'
    >
  >;
  publishManyAlacarteTicketTypes?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationPublishManyAlacarteTicketTypesArgs, 'to'>
  >;
  publishManyAlacarteTicketTypesConnection?: Resolver<
    ResolversTypes['AlacarteTicketTypeConnection'],
    ParentType,
    ContextType,
    RequireFields<MutationPublishManyAlacarteTicketTypesConnectionArgs, 'from' | 'to'>
  >;
  publishManyAlacarteTickets?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationPublishManyAlacarteTicketsArgs, 'to'>
  >;
  publishManyAlacarteTicketsConnection?: Resolver<
    ResolversTypes['AlacarteTicketConnection'],
    ParentType,
    ContextType,
    RequireFields<MutationPublishManyAlacarteTicketsConnectionArgs, 'from' | 'to'>
  >;
  publishManyArticleCategories?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationPublishManyArticleCategoriesArgs, 'to'>
  >;
  publishManyArticleCategoriesConnection?: Resolver<
    ResolversTypes['ArticleCategoryConnection'],
    ParentType,
    ContextType,
    RequireFields<MutationPublishManyArticleCategoriesConnectionArgs, 'from' | 'to'>
  >;
  publishManyArticles?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationPublishManyArticlesArgs, 'publishBase' | 'to' | 'withDefaultLocale'>
  >;
  publishManyArticlesConnection?: Resolver<
    ResolversTypes['ArticleConnection'],
    ParentType,
    ContextType,
    RequireFields<
      MutationPublishManyArticlesConnectionArgs,
      'from' | 'publishBase' | 'to' | 'withDefaultLocale'
    >
  >;
  publishManyAssets?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationPublishManyAssetsArgs, 'publishBase' | 'to' | 'withDefaultLocale'>
  >;
  publishManyAssetsConnection?: Resolver<
    ResolversTypes['AssetConnection'],
    ParentType,
    ContextType,
    RequireFields<
      MutationPublishManyAssetsConnectionArgs,
      'from' | 'publishBase' | 'to' | 'withDefaultLocale'
    >
  >;
  publishManyAttractionAlacartes?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationPublishManyAttractionAlacartesArgs, 'to'>
  >;
  publishManyAttractionAlacartesConnection?: Resolver<
    ResolversTypes['AttractionAlacarteConnection'],
    ParentType,
    ContextType,
    RequireFields<MutationPublishManyAttractionAlacartesConnectionArgs, 'from' | 'to'>
  >;
  publishManyAttractionAvailabilities?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<
      MutationPublishManyAttractionAvailabilitiesArgs,
      'publishBase' | 'to' | 'withDefaultLocale'
    >
  >;
  publishManyAttractionAvailabilitiesConnection?: Resolver<
    ResolversTypes['AttractionAvailabilityConnection'],
    ParentType,
    ContextType,
    RequireFields<
      MutationPublishManyAttractionAvailabilitiesConnectionArgs,
      'from' | 'publishBase' | 'to' | 'withDefaultLocale'
    >
  >;
  publishManyAttractionLocations?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<
      MutationPublishManyAttractionLocationsArgs,
      'publishBase' | 'to' | 'withDefaultLocale'
    >
  >;
  publishManyAttractionLocationsConnection?: Resolver<
    ResolversTypes['AttractionLocationConnection'],
    ParentType,
    ContextType,
    RequireFields<
      MutationPublishManyAttractionLocationsConnectionArgs,
      'from' | 'publishBase' | 'to' | 'withDefaultLocale'
    >
  >;
  publishManyAttractionOffers?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<
      MutationPublishManyAttractionOffersArgs,
      'publishBase' | 'to' | 'withDefaultLocale'
    >
  >;
  publishManyAttractionOffersConnection?: Resolver<
    ResolversTypes['AttractionOfferConnection'],
    ParentType,
    ContextType,
    RequireFields<
      MutationPublishManyAttractionOffersConnectionArgs,
      'from' | 'publishBase' | 'to' | 'withDefaultLocale'
    >
  >;
  publishManyAttractionPrices?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<
      MutationPublishManyAttractionPricesArgs,
      'publishBase' | 'to' | 'withDefaultLocale'
    >
  >;
  publishManyAttractionPricesConnection?: Resolver<
    ResolversTypes['AttractionPriceConnection'],
    ParentType,
    ContextType,
    RequireFields<
      MutationPublishManyAttractionPricesConnectionArgs,
      'from' | 'publishBase' | 'to' | 'withDefaultLocale'
    >
  >;
  publishManyAttractionReservations?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationPublishManyAttractionReservationsArgs, 'to'>
  >;
  publishManyAttractionReservationsConnection?: Resolver<
    ResolversTypes['AttractionReservationConnection'],
    ParentType,
    ContextType,
    RequireFields<MutationPublishManyAttractionReservationsConnectionArgs, 'from' | 'to'>
  >;
  publishManyAttractionVariants?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<
      MutationPublishManyAttractionVariantsArgs,
      'publishBase' | 'to' | 'withDefaultLocale'
    >
  >;
  publishManyAttractionVariantsConnection?: Resolver<
    ResolversTypes['AttractionVariantConnection'],
    ParentType,
    ContextType,
    RequireFields<
      MutationPublishManyAttractionVariantsConnectionArgs,
      'from' | 'publishBase' | 'to' | 'withDefaultLocale'
    >
  >;
  publishManyAttractions?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationPublishManyAttractionsArgs, 'publishBase' | 'to' | 'withDefaultLocale'>
  >;
  publishManyAttractionsConnection?: Resolver<
    ResolversTypes['AttractionConnection'],
    ParentType,
    ContextType,
    RequireFields<
      MutationPublishManyAttractionsConnectionArgs,
      'from' | 'publishBase' | 'to' | 'withDefaultLocale'
    >
  >;
  publishManyAuthors?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationPublishManyAuthorsArgs, 'to'>
  >;
  publishManyAuthorsConnection?: Resolver<
    ResolversTypes['AuthorConnection'],
    ParentType,
    ContextType,
    RequireFields<MutationPublishManyAuthorsConnectionArgs, 'from' | 'to'>
  >;
  publishManyBlockGroups?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationPublishManyBlockGroupsArgs, 'to'>
  >;
  publishManyBlockGroupsConnection?: Resolver<
    ResolversTypes['BlockGroupConnection'],
    ParentType,
    ContextType,
    RequireFields<MutationPublishManyBlockGroupsConnectionArgs, 'from' | 'to'>
  >;
  publishManyBlocks?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationPublishManyBlocksArgs, 'to'>
  >;
  publishManyBlocksConnection?: Resolver<
    ResolversTypes['BlockConnection'],
    ParentType,
    ContextType,
    RequireFields<MutationPublishManyBlocksConnectionArgs, 'from' | 'to'>
  >;
  publishManyDetailBlocks?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationPublishManyDetailBlocksArgs, 'to'>
  >;
  publishManyDetailBlocksConnection?: Resolver<
    ResolversTypes['DetailBlockConnection'],
    ParentType,
    ContextType,
    RequireFields<MutationPublishManyDetailBlocksConnectionArgs, 'from' | 'to'>
  >;
  publishManyGeneralReservations?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationPublishManyGeneralReservationsArgs, 'to'>
  >;
  publishManyGeneralReservationsConnection?: Resolver<
    ResolversTypes['GeneralReservationConnection'],
    ParentType,
    ContextType,
    RequireFields<MutationPublishManyGeneralReservationsConnectionArgs, 'from' | 'to'>
  >;
  publishManyImages?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationPublishManyImagesArgs, 'publishBase' | 'to' | 'withDefaultLocale'>
  >;
  publishManyImagesConnection?: Resolver<
    ResolversTypes['ImageConnection'],
    ParentType,
    ContextType,
    RequireFields<
      MutationPublishManyImagesConnectionArgs,
      'from' | 'publishBase' | 'to' | 'withDefaultLocale'
    >
  >;
  publishManyLandingPages?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationPublishManyLandingPagesArgs, 'publishBase' | 'to' | 'withDefaultLocale'>
  >;
  publishManyLandingPagesConnection?: Resolver<
    ResolversTypes['LandingPageConnection'],
    ParentType,
    ContextType,
    RequireFields<
      MutationPublishManyLandingPagesConnectionArgs,
      'from' | 'publishBase' | 'to' | 'withDefaultLocale'
    >
  >;
  publishManyLinks?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationPublishManyLinksArgs, 'to'>
  >;
  publishManyLinksConnection?: Resolver<
    ResolversTypes['LinkConnection'],
    ParentType,
    ContextType,
    RequireFields<MutationPublishManyLinksConnectionArgs, 'from' | 'to'>
  >;
  publishManyMenus?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationPublishManyMenusArgs, 'to'>
  >;
  publishManyMenusConnection?: Resolver<
    ResolversTypes['MenuConnection'],
    ParentType,
    ContextType,
    RequireFields<MutationPublishManyMenusConnectionArgs, 'from' | 'to'>
  >;
  publishManyNotices?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationPublishManyNoticesArgs, 'publishBase' | 'to' | 'withDefaultLocale'>
  >;
  publishManyNoticesConnection?: Resolver<
    ResolversTypes['NoticeConnection'],
    ParentType,
    ContextType,
    RequireFields<
      MutationPublishManyNoticesConnectionArgs,
      'from' | 'publishBase' | 'to' | 'withDefaultLocale'
    >
  >;
  publishManyPageTemplates?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationPublishManyPageTemplatesArgs, 'to'>
  >;
  publishManyPageTemplatesConnection?: Resolver<
    ResolversTypes['PageTemplateConnection'],
    ParentType,
    ContextType,
    RequireFields<MutationPublishManyPageTemplatesConnectionArgs, 'from' | 'to'>
  >;
  publishManyPages?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationPublishManyPagesArgs, 'publishBase' | 'to' | 'withDefaultLocale'>
  >;
  publishManyPagesConnection?: Resolver<
    ResolversTypes['PageConnection'],
    ParentType,
    ContextType,
    RequireFields<
      MutationPublishManyPagesConnectionArgs,
      'from' | 'publishBase' | 'to' | 'withDefaultLocale'
    >
  >;
  publishManyPartnerOffers?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationPublishManyPartnerOffersArgs, 'publishBase' | 'to' | 'withDefaultLocale'>
  >;
  publishManyPartnerOffersConnection?: Resolver<
    ResolversTypes['PartnerOfferConnection'],
    ParentType,
    ContextType,
    RequireFields<
      MutationPublishManyPartnerOffersConnectionArgs,
      'from' | 'publishBase' | 'to' | 'withDefaultLocale'
    >
  >;
  publishManyPartners?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationPublishManyPartnersArgs, 'to'>
  >;
  publishManyPartnersConnection?: Resolver<
    ResolversTypes['PartnerConnection'],
    ParentType,
    ContextType,
    RequireFields<MutationPublishManyPartnersConnectionArgs, 'from' | 'to'>
  >;
  publishManyProductActivities?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<
      MutationPublishManyProductActivitiesArgs,
      'publishBase' | 'to' | 'withDefaultLocale'
    >
  >;
  publishManyProductActivitiesConnection?: Resolver<
    ResolversTypes['ProductActivityConnection'],
    ParentType,
    ContextType,
    RequireFields<
      MutationPublishManyProductActivitiesConnectionArgs,
      'from' | 'publishBase' | 'to' | 'withDefaultLocale'
    >
  >;
  publishManyProductActivityItems?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<
      MutationPublishManyProductActivityItemsArgs,
      'publishBase' | 'to' | 'withDefaultLocale'
    >
  >;
  publishManyProductActivityItemsConnection?: Resolver<
    ResolversTypes['ProductActivityItemConnection'],
    ParentType,
    ContextType,
    RequireFields<
      MutationPublishManyProductActivityItemsConnectionArgs,
      'from' | 'publishBase' | 'to' | 'withDefaultLocale'
    >
  >;
  publishManyProductAttractionLineups?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationPublishManyProductAttractionLineupsArgs, 'to'>
  >;
  publishManyProductAttractionLineupsConnection?: Resolver<
    ResolversTypes['ProductAttractionLineupConnection'],
    ParentType,
    ContextType,
    RequireFields<MutationPublishManyProductAttractionLineupsConnectionArgs, 'from' | 'to'>
  >;
  publishManyProductReservations?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationPublishManyProductReservationsArgs, 'to'>
  >;
  publishManyProductReservationsConnection?: Resolver<
    ResolversTypes['ProductReservationConnection'],
    ParentType,
    ContextType,
    RequireFields<MutationPublishManyProductReservationsConnectionArgs, 'from' | 'to'>
  >;
  publishManyProducts?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationPublishManyProductsArgs, 'publishBase' | 'to' | 'withDefaultLocale'>
  >;
  publishManyProductsConnection?: Resolver<
    ResolversTypes['ProductConnection'],
    ParentType,
    ContextType,
    RequireFields<
      MutationPublishManyProductsConnectionArgs,
      'from' | 'publishBase' | 'to' | 'withDefaultLocale'
    >
  >;
  publishManyPromos?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationPublishManyPromosArgs, 'publishBase' | 'to' | 'withDefaultLocale'>
  >;
  publishManyPromosConnection?: Resolver<
    ResolversTypes['PromoConnection'],
    ParentType,
    ContextType,
    RequireFields<
      MutationPublishManyPromosConnectionArgs,
      'from' | 'publishBase' | 'to' | 'withDefaultLocale'
    >
  >;
  publishManyReservationTexts?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<
      MutationPublishManyReservationTextsArgs,
      'publishBase' | 'to' | 'withDefaultLocale'
    >
  >;
  publishManyReservationTextsConnection?: Resolver<
    ResolversTypes['ReservationTextConnection'],
    ParentType,
    ContextType,
    RequireFields<
      MutationPublishManyReservationTextsConnectionArgs,
      'from' | 'publishBase' | 'to' | 'withDefaultLocale'
    >
  >;
  publishManySandboxes?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationPublishManySandboxesArgs, 'publishBase' | 'to' | 'withDefaultLocale'>
  >;
  publishManySandboxesConnection?: Resolver<
    ResolversTypes['SandboxConnection'],
    ParentType,
    ContextType,
    RequireFields<
      MutationPublishManySandboxesConnectionArgs,
      'from' | 'publishBase' | 'to' | 'withDefaultLocale'
    >
  >;
  publishManySeos?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationPublishManySeosArgs, 'publishBase' | 'to' | 'withDefaultLocale'>
  >;
  publishManySeosConnection?: Resolver<
    ResolversTypes['SeoConnection'],
    ParentType,
    ContextType,
    RequireFields<
      MutationPublishManySeosConnectionArgs,
      'from' | 'publishBase' | 'to' | 'withDefaultLocale'
    >
  >;
  publishManyTextGroups?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationPublishManyTextGroupsArgs, 'to'>
  >;
  publishManyTextGroupsConnection?: Resolver<
    ResolversTypes['TextGroupConnection'],
    ParentType,
    ContextType,
    RequireFields<MutationPublishManyTextGroupsConnectionArgs, 'from' | 'to'>
  >;
  publishManyTextLists?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationPublishManyTextListsArgs, 'to'>
  >;
  publishManyTextListsConnection?: Resolver<
    ResolversTypes['TextListConnection'],
    ParentType,
    ContextType,
    RequireFields<MutationPublishManyTextListsConnectionArgs, 'from' | 'to'>
  >;
  publishManyTexts?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationPublishManyTextsArgs, 'publishBase' | 'to' | 'withDefaultLocale'>
  >;
  publishManyTextsConnection?: Resolver<
    ResolversTypes['TextConnection'],
    ParentType,
    ContextType,
    RequireFields<
      MutationPublishManyTextsConnectionArgs,
      'from' | 'publishBase' | 'to' | 'withDefaultLocale'
    >
  >;
  publishManyTicketGroups?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationPublishManyTicketGroupsArgs, 'to'>
  >;
  publishManyTicketGroupsConnection?: Resolver<
    ResolversTypes['TicketGroupConnection'],
    ParentType,
    ContextType,
    RequireFields<MutationPublishManyTicketGroupsConnectionArgs, 'from' | 'to'>
  >;
  publishManyTitleDescriptions?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<
      MutationPublishManyTitleDescriptionsArgs,
      'publishBase' | 'to' | 'withDefaultLocale'
    >
  >;
  publishManyTitleDescriptionsConnection?: Resolver<
    ResolversTypes['TitleDescriptionConnection'],
    ParentType,
    ContextType,
    RequireFields<
      MutationPublishManyTitleDescriptionsConnectionArgs,
      'from' | 'publishBase' | 'to' | 'withDefaultLocale'
    >
  >;
  publishManyVideos?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationPublishManyVideosArgs, 'publishBase' | 'to' | 'withDefaultLocale'>
  >;
  publishManyVideosConnection?: Resolver<
    ResolversTypes['VideoConnection'],
    ParentType,
    ContextType,
    RequireFields<
      MutationPublishManyVideosConnectionArgs,
      'from' | 'publishBase' | 'to' | 'withDefaultLocale'
    >
  >;
  publishMenu?: Resolver<
    Maybe<ResolversTypes['Menu']>,
    ParentType,
    ContextType,
    RequireFields<MutationPublishMenuArgs, 'to' | 'where'>
  >;
  publishNotice?: Resolver<
    Maybe<ResolversTypes['Notice']>,
    ParentType,
    ContextType,
    RequireFields<MutationPublishNoticeArgs, 'publishBase' | 'to' | 'where' | 'withDefaultLocale'>
  >;
  publishPage?: Resolver<
    Maybe<ResolversTypes['Page']>,
    ParentType,
    ContextType,
    RequireFields<MutationPublishPageArgs, 'publishBase' | 'to' | 'where' | 'withDefaultLocale'>
  >;
  publishPageTemplate?: Resolver<
    Maybe<ResolversTypes['PageTemplate']>,
    ParentType,
    ContextType,
    RequireFields<MutationPublishPageTemplateArgs, 'to' | 'where'>
  >;
  publishPartner?: Resolver<
    Maybe<ResolversTypes['Partner']>,
    ParentType,
    ContextType,
    RequireFields<MutationPublishPartnerArgs, 'to' | 'where'>
  >;
  publishPartnerOffer?: Resolver<
    Maybe<ResolversTypes['PartnerOffer']>,
    ParentType,
    ContextType,
    RequireFields<
      MutationPublishPartnerOfferArgs,
      'publishBase' | 'to' | 'where' | 'withDefaultLocale'
    >
  >;
  publishProduct?: Resolver<
    Maybe<ResolversTypes['Product']>,
    ParentType,
    ContextType,
    RequireFields<MutationPublishProductArgs, 'publishBase' | 'to' | 'where' | 'withDefaultLocale'>
  >;
  publishProductActivity?: Resolver<
    Maybe<ResolversTypes['ProductActivity']>,
    ParentType,
    ContextType,
    RequireFields<
      MutationPublishProductActivityArgs,
      'publishBase' | 'to' | 'where' | 'withDefaultLocale'
    >
  >;
  publishProductActivityItem?: Resolver<
    Maybe<ResolversTypes['ProductActivityItem']>,
    ParentType,
    ContextType,
    RequireFields<
      MutationPublishProductActivityItemArgs,
      'publishBase' | 'to' | 'where' | 'withDefaultLocale'
    >
  >;
  publishProductAttractionLineup?: Resolver<
    Maybe<ResolversTypes['ProductAttractionLineup']>,
    ParentType,
    ContextType,
    RequireFields<MutationPublishProductAttractionLineupArgs, 'to' | 'where'>
  >;
  publishProductReservation?: Resolver<
    Maybe<ResolversTypes['ProductReservation']>,
    ParentType,
    ContextType,
    RequireFields<MutationPublishProductReservationArgs, 'to' | 'where'>
  >;
  publishPromo?: Resolver<
    Maybe<ResolversTypes['Promo']>,
    ParentType,
    ContextType,
    RequireFields<MutationPublishPromoArgs, 'publishBase' | 'to' | 'where' | 'withDefaultLocale'>
  >;
  publishReservationText?: Resolver<
    Maybe<ResolversTypes['ReservationText']>,
    ParentType,
    ContextType,
    RequireFields<
      MutationPublishReservationTextArgs,
      'publishBase' | 'to' | 'where' | 'withDefaultLocale'
    >
  >;
  publishSandbox?: Resolver<
    Maybe<ResolversTypes['Sandbox']>,
    ParentType,
    ContextType,
    RequireFields<MutationPublishSandboxArgs, 'publishBase' | 'to' | 'where' | 'withDefaultLocale'>
  >;
  publishSeo?: Resolver<
    Maybe<ResolversTypes['Seo']>,
    ParentType,
    ContextType,
    RequireFields<MutationPublishSeoArgs, 'publishBase' | 'to' | 'where' | 'withDefaultLocale'>
  >;
  publishText?: Resolver<
    Maybe<ResolversTypes['Text']>,
    ParentType,
    ContextType,
    RequireFields<MutationPublishTextArgs, 'publishBase' | 'to' | 'where' | 'withDefaultLocale'>
  >;
  publishTextGroup?: Resolver<
    Maybe<ResolversTypes['TextGroup']>,
    ParentType,
    ContextType,
    RequireFields<MutationPublishTextGroupArgs, 'to' | 'where'>
  >;
  publishTextList?: Resolver<
    Maybe<ResolversTypes['TextList']>,
    ParentType,
    ContextType,
    RequireFields<MutationPublishTextListArgs, 'to' | 'where'>
  >;
  publishTicketGroup?: Resolver<
    Maybe<ResolversTypes['TicketGroup']>,
    ParentType,
    ContextType,
    RequireFields<MutationPublishTicketGroupArgs, 'to' | 'where'>
  >;
  publishTitleDescription?: Resolver<
    Maybe<ResolversTypes['TitleDescription']>,
    ParentType,
    ContextType,
    RequireFields<
      MutationPublishTitleDescriptionArgs,
      'publishBase' | 'to' | 'where' | 'withDefaultLocale'
    >
  >;
  publishVideo?: Resolver<
    Maybe<ResolversTypes['Video']>,
    ParentType,
    ContextType,
    RequireFields<MutationPublishVideoArgs, 'publishBase' | 'to' | 'where' | 'withDefaultLocale'>
  >;
  schedulePublishAddOn?: Resolver<
    Maybe<ResolversTypes['AddOn']>,
    ParentType,
    ContextType,
    RequireFields<
      MutationSchedulePublishAddOnArgs,
      'publishBase' | 'to' | 'where' | 'withDefaultLocale'
    >
  >;
  schedulePublishAlacarteTicket?: Resolver<
    Maybe<ResolversTypes['AlacarteTicket']>,
    ParentType,
    ContextType,
    RequireFields<MutationSchedulePublishAlacarteTicketArgs, 'to' | 'where'>
  >;
  schedulePublishAlacarteTicketType?: Resolver<
    Maybe<ResolversTypes['AlacarteTicketType']>,
    ParentType,
    ContextType,
    RequireFields<MutationSchedulePublishAlacarteTicketTypeArgs, 'to' | 'where'>
  >;
  schedulePublishArticle?: Resolver<
    Maybe<ResolversTypes['Article']>,
    ParentType,
    ContextType,
    RequireFields<
      MutationSchedulePublishArticleArgs,
      'publishBase' | 'to' | 'where' | 'withDefaultLocale'
    >
  >;
  schedulePublishArticleCategory?: Resolver<
    Maybe<ResolversTypes['ArticleCategory']>,
    ParentType,
    ContextType,
    RequireFields<MutationSchedulePublishArticleCategoryArgs, 'to' | 'where'>
  >;
  schedulePublishAsset?: Resolver<
    Maybe<ResolversTypes['Asset']>,
    ParentType,
    ContextType,
    RequireFields<
      MutationSchedulePublishAssetArgs,
      'publishBase' | 'to' | 'where' | 'withDefaultLocale'
    >
  >;
  schedulePublishAttraction?: Resolver<
    Maybe<ResolversTypes['Attraction']>,
    ParentType,
    ContextType,
    RequireFields<
      MutationSchedulePublishAttractionArgs,
      'publishBase' | 'to' | 'where' | 'withDefaultLocale'
    >
  >;
  schedulePublishAttractionAlacarte?: Resolver<
    Maybe<ResolversTypes['AttractionAlacarte']>,
    ParentType,
    ContextType,
    RequireFields<MutationSchedulePublishAttractionAlacarteArgs, 'to' | 'where'>
  >;
  schedulePublishAttractionAvailability?: Resolver<
    Maybe<ResolversTypes['AttractionAvailability']>,
    ParentType,
    ContextType,
    RequireFields<
      MutationSchedulePublishAttractionAvailabilityArgs,
      'publishBase' | 'to' | 'where' | 'withDefaultLocale'
    >
  >;
  schedulePublishAttractionLocation?: Resolver<
    Maybe<ResolversTypes['AttractionLocation']>,
    ParentType,
    ContextType,
    RequireFields<
      MutationSchedulePublishAttractionLocationArgs,
      'publishBase' | 'to' | 'where' | 'withDefaultLocale'
    >
  >;
  schedulePublishAttractionOffer?: Resolver<
    Maybe<ResolversTypes['AttractionOffer']>,
    ParentType,
    ContextType,
    RequireFields<
      MutationSchedulePublishAttractionOfferArgs,
      'publishBase' | 'to' | 'where' | 'withDefaultLocale'
    >
  >;
  schedulePublishAttractionPrice?: Resolver<
    Maybe<ResolversTypes['AttractionPrice']>,
    ParentType,
    ContextType,
    RequireFields<
      MutationSchedulePublishAttractionPriceArgs,
      'publishBase' | 'to' | 'where' | 'withDefaultLocale'
    >
  >;
  schedulePublishAttractionReservation?: Resolver<
    Maybe<ResolversTypes['AttractionReservation']>,
    ParentType,
    ContextType,
    RequireFields<MutationSchedulePublishAttractionReservationArgs, 'to' | 'where'>
  >;
  schedulePublishAttractionVariant?: Resolver<
    Maybe<ResolversTypes['AttractionVariant']>,
    ParentType,
    ContextType,
    RequireFields<
      MutationSchedulePublishAttractionVariantArgs,
      'publishBase' | 'to' | 'where' | 'withDefaultLocale'
    >
  >;
  schedulePublishAuthor?: Resolver<
    Maybe<ResolversTypes['Author']>,
    ParentType,
    ContextType,
    RequireFields<MutationSchedulePublishAuthorArgs, 'to' | 'where'>
  >;
  schedulePublishBlock?: Resolver<
    Maybe<ResolversTypes['Block']>,
    ParentType,
    ContextType,
    RequireFields<MutationSchedulePublishBlockArgs, 'to' | 'where'>
  >;
  schedulePublishBlockGroup?: Resolver<
    Maybe<ResolversTypes['BlockGroup']>,
    ParentType,
    ContextType,
    RequireFields<MutationSchedulePublishBlockGroupArgs, 'to' | 'where'>
  >;
  schedulePublishDetailBlock?: Resolver<
    Maybe<ResolversTypes['DetailBlock']>,
    ParentType,
    ContextType,
    RequireFields<MutationSchedulePublishDetailBlockArgs, 'to' | 'where'>
  >;
  schedulePublishGeneralReservation?: Resolver<
    Maybe<ResolversTypes['GeneralReservation']>,
    ParentType,
    ContextType,
    RequireFields<MutationSchedulePublishGeneralReservationArgs, 'to' | 'where'>
  >;
  schedulePublishImage?: Resolver<
    Maybe<ResolversTypes['Image']>,
    ParentType,
    ContextType,
    RequireFields<
      MutationSchedulePublishImageArgs,
      'publishBase' | 'to' | 'where' | 'withDefaultLocale'
    >
  >;
  schedulePublishLandingPage?: Resolver<
    Maybe<ResolversTypes['LandingPage']>,
    ParentType,
    ContextType,
    RequireFields<
      MutationSchedulePublishLandingPageArgs,
      'publishBase' | 'to' | 'where' | 'withDefaultLocale'
    >
  >;
  schedulePublishLink?: Resolver<
    Maybe<ResolversTypes['Link']>,
    ParentType,
    ContextType,
    RequireFields<MutationSchedulePublishLinkArgs, 'to' | 'where'>
  >;
  schedulePublishMenu?: Resolver<
    Maybe<ResolversTypes['Menu']>,
    ParentType,
    ContextType,
    RequireFields<MutationSchedulePublishMenuArgs, 'to' | 'where'>
  >;
  schedulePublishNotice?: Resolver<
    Maybe<ResolversTypes['Notice']>,
    ParentType,
    ContextType,
    RequireFields<
      MutationSchedulePublishNoticeArgs,
      'publishBase' | 'to' | 'where' | 'withDefaultLocale'
    >
  >;
  schedulePublishPage?: Resolver<
    Maybe<ResolversTypes['Page']>,
    ParentType,
    ContextType,
    RequireFields<
      MutationSchedulePublishPageArgs,
      'publishBase' | 'to' | 'where' | 'withDefaultLocale'
    >
  >;
  schedulePublishPageTemplate?: Resolver<
    Maybe<ResolversTypes['PageTemplate']>,
    ParentType,
    ContextType,
    RequireFields<MutationSchedulePublishPageTemplateArgs, 'to' | 'where'>
  >;
  schedulePublishPartner?: Resolver<
    Maybe<ResolversTypes['Partner']>,
    ParentType,
    ContextType,
    RequireFields<MutationSchedulePublishPartnerArgs, 'to' | 'where'>
  >;
  schedulePublishPartnerOffer?: Resolver<
    Maybe<ResolversTypes['PartnerOffer']>,
    ParentType,
    ContextType,
    RequireFields<
      MutationSchedulePublishPartnerOfferArgs,
      'publishBase' | 'to' | 'where' | 'withDefaultLocale'
    >
  >;
  schedulePublishProduct?: Resolver<
    Maybe<ResolversTypes['Product']>,
    ParentType,
    ContextType,
    RequireFields<
      MutationSchedulePublishProductArgs,
      'publishBase' | 'to' | 'where' | 'withDefaultLocale'
    >
  >;
  schedulePublishProductActivity?: Resolver<
    Maybe<ResolversTypes['ProductActivity']>,
    ParentType,
    ContextType,
    RequireFields<
      MutationSchedulePublishProductActivityArgs,
      'publishBase' | 'to' | 'where' | 'withDefaultLocale'
    >
  >;
  schedulePublishProductActivityItem?: Resolver<
    Maybe<ResolversTypes['ProductActivityItem']>,
    ParentType,
    ContextType,
    RequireFields<
      MutationSchedulePublishProductActivityItemArgs,
      'publishBase' | 'to' | 'where' | 'withDefaultLocale'
    >
  >;
  schedulePublishProductAttractionLineup?: Resolver<
    Maybe<ResolversTypes['ProductAttractionLineup']>,
    ParentType,
    ContextType,
    RequireFields<MutationSchedulePublishProductAttractionLineupArgs, 'to' | 'where'>
  >;
  schedulePublishProductReservation?: Resolver<
    Maybe<ResolversTypes['ProductReservation']>,
    ParentType,
    ContextType,
    RequireFields<MutationSchedulePublishProductReservationArgs, 'to' | 'where'>
  >;
  schedulePublishPromo?: Resolver<
    Maybe<ResolversTypes['Promo']>,
    ParentType,
    ContextType,
    RequireFields<
      MutationSchedulePublishPromoArgs,
      'publishBase' | 'to' | 'where' | 'withDefaultLocale'
    >
  >;
  schedulePublishReservationText?: Resolver<
    Maybe<ResolversTypes['ReservationText']>,
    ParentType,
    ContextType,
    RequireFields<
      MutationSchedulePublishReservationTextArgs,
      'publishBase' | 'to' | 'where' | 'withDefaultLocale'
    >
  >;
  schedulePublishSandbox?: Resolver<
    Maybe<ResolversTypes['Sandbox']>,
    ParentType,
    ContextType,
    RequireFields<
      MutationSchedulePublishSandboxArgs,
      'publishBase' | 'to' | 'where' | 'withDefaultLocale'
    >
  >;
  schedulePublishSeo?: Resolver<
    Maybe<ResolversTypes['Seo']>,
    ParentType,
    ContextType,
    RequireFields<
      MutationSchedulePublishSeoArgs,
      'publishBase' | 'to' | 'where' | 'withDefaultLocale'
    >
  >;
  schedulePublishText?: Resolver<
    Maybe<ResolversTypes['Text']>,
    ParentType,
    ContextType,
    RequireFields<
      MutationSchedulePublishTextArgs,
      'publishBase' | 'to' | 'where' | 'withDefaultLocale'
    >
  >;
  schedulePublishTextGroup?: Resolver<
    Maybe<ResolversTypes['TextGroup']>,
    ParentType,
    ContextType,
    RequireFields<MutationSchedulePublishTextGroupArgs, 'to' | 'where'>
  >;
  schedulePublishTextList?: Resolver<
    Maybe<ResolversTypes['TextList']>,
    ParentType,
    ContextType,
    RequireFields<MutationSchedulePublishTextListArgs, 'to' | 'where'>
  >;
  schedulePublishTicketGroup?: Resolver<
    Maybe<ResolversTypes['TicketGroup']>,
    ParentType,
    ContextType,
    RequireFields<MutationSchedulePublishTicketGroupArgs, 'to' | 'where'>
  >;
  schedulePublishTitleDescription?: Resolver<
    Maybe<ResolversTypes['TitleDescription']>,
    ParentType,
    ContextType,
    RequireFields<
      MutationSchedulePublishTitleDescriptionArgs,
      'publishBase' | 'to' | 'where' | 'withDefaultLocale'
    >
  >;
  schedulePublishVideo?: Resolver<
    Maybe<ResolversTypes['Video']>,
    ParentType,
    ContextType,
    RequireFields<
      MutationSchedulePublishVideoArgs,
      'publishBase' | 'to' | 'where' | 'withDefaultLocale'
    >
  >;
  scheduleUnpublishAddOn?: Resolver<
    Maybe<ResolversTypes['AddOn']>,
    ParentType,
    ContextType,
    RequireFields<MutationScheduleUnpublishAddOnArgs, 'from' | 'unpublishBase' | 'where'>
  >;
  scheduleUnpublishAlacarteTicket?: Resolver<
    Maybe<ResolversTypes['AlacarteTicket']>,
    ParentType,
    ContextType,
    RequireFields<MutationScheduleUnpublishAlacarteTicketArgs, 'from' | 'where'>
  >;
  scheduleUnpublishAlacarteTicketType?: Resolver<
    Maybe<ResolversTypes['AlacarteTicketType']>,
    ParentType,
    ContextType,
    RequireFields<MutationScheduleUnpublishAlacarteTicketTypeArgs, 'from' | 'where'>
  >;
  scheduleUnpublishArticle?: Resolver<
    Maybe<ResolversTypes['Article']>,
    ParentType,
    ContextType,
    RequireFields<MutationScheduleUnpublishArticleArgs, 'from' | 'unpublishBase' | 'where'>
  >;
  scheduleUnpublishArticleCategory?: Resolver<
    Maybe<ResolversTypes['ArticleCategory']>,
    ParentType,
    ContextType,
    RequireFields<MutationScheduleUnpublishArticleCategoryArgs, 'from' | 'where'>
  >;
  scheduleUnpublishAsset?: Resolver<
    Maybe<ResolversTypes['Asset']>,
    ParentType,
    ContextType,
    RequireFields<MutationScheduleUnpublishAssetArgs, 'from' | 'unpublishBase' | 'where'>
  >;
  scheduleUnpublishAttraction?: Resolver<
    Maybe<ResolversTypes['Attraction']>,
    ParentType,
    ContextType,
    RequireFields<MutationScheduleUnpublishAttractionArgs, 'from' | 'unpublishBase' | 'where'>
  >;
  scheduleUnpublishAttractionAlacarte?: Resolver<
    Maybe<ResolversTypes['AttractionAlacarte']>,
    ParentType,
    ContextType,
    RequireFields<MutationScheduleUnpublishAttractionAlacarteArgs, 'from' | 'where'>
  >;
  scheduleUnpublishAttractionAvailability?: Resolver<
    Maybe<ResolversTypes['AttractionAvailability']>,
    ParentType,
    ContextType,
    RequireFields<
      MutationScheduleUnpublishAttractionAvailabilityArgs,
      'from' | 'unpublishBase' | 'where'
    >
  >;
  scheduleUnpublishAttractionLocation?: Resolver<
    Maybe<ResolversTypes['AttractionLocation']>,
    ParentType,
    ContextType,
    RequireFields<
      MutationScheduleUnpublishAttractionLocationArgs,
      'from' | 'unpublishBase' | 'where'
    >
  >;
  scheduleUnpublishAttractionOffer?: Resolver<
    Maybe<ResolversTypes['AttractionOffer']>,
    ParentType,
    ContextType,
    RequireFields<MutationScheduleUnpublishAttractionOfferArgs, 'from' | 'unpublishBase' | 'where'>
  >;
  scheduleUnpublishAttractionPrice?: Resolver<
    Maybe<ResolversTypes['AttractionPrice']>,
    ParentType,
    ContextType,
    RequireFields<MutationScheduleUnpublishAttractionPriceArgs, 'from' | 'unpublishBase' | 'where'>
  >;
  scheduleUnpublishAttractionReservation?: Resolver<
    Maybe<ResolversTypes['AttractionReservation']>,
    ParentType,
    ContextType,
    RequireFields<MutationScheduleUnpublishAttractionReservationArgs, 'from' | 'where'>
  >;
  scheduleUnpublishAttractionVariant?: Resolver<
    Maybe<ResolversTypes['AttractionVariant']>,
    ParentType,
    ContextType,
    RequireFields<
      MutationScheduleUnpublishAttractionVariantArgs,
      'from' | 'unpublishBase' | 'where'
    >
  >;
  scheduleUnpublishAuthor?: Resolver<
    Maybe<ResolversTypes['Author']>,
    ParentType,
    ContextType,
    RequireFields<MutationScheduleUnpublishAuthorArgs, 'from' | 'where'>
  >;
  scheduleUnpublishBlock?: Resolver<
    Maybe<ResolversTypes['Block']>,
    ParentType,
    ContextType,
    RequireFields<MutationScheduleUnpublishBlockArgs, 'from' | 'where'>
  >;
  scheduleUnpublishBlockGroup?: Resolver<
    Maybe<ResolversTypes['BlockGroup']>,
    ParentType,
    ContextType,
    RequireFields<MutationScheduleUnpublishBlockGroupArgs, 'from' | 'where'>
  >;
  scheduleUnpublishDetailBlock?: Resolver<
    Maybe<ResolversTypes['DetailBlock']>,
    ParentType,
    ContextType,
    RequireFields<MutationScheduleUnpublishDetailBlockArgs, 'from' | 'where'>
  >;
  scheduleUnpublishGeneralReservation?: Resolver<
    Maybe<ResolversTypes['GeneralReservation']>,
    ParentType,
    ContextType,
    RequireFields<MutationScheduleUnpublishGeneralReservationArgs, 'from' | 'where'>
  >;
  scheduleUnpublishImage?: Resolver<
    Maybe<ResolversTypes['Image']>,
    ParentType,
    ContextType,
    RequireFields<MutationScheduleUnpublishImageArgs, 'from' | 'unpublishBase' | 'where'>
  >;
  scheduleUnpublishLandingPage?: Resolver<
    Maybe<ResolversTypes['LandingPage']>,
    ParentType,
    ContextType,
    RequireFields<MutationScheduleUnpublishLandingPageArgs, 'from' | 'unpublishBase' | 'where'>
  >;
  scheduleUnpublishLink?: Resolver<
    Maybe<ResolversTypes['Link']>,
    ParentType,
    ContextType,
    RequireFields<MutationScheduleUnpublishLinkArgs, 'from' | 'where'>
  >;
  scheduleUnpublishMenu?: Resolver<
    Maybe<ResolversTypes['Menu']>,
    ParentType,
    ContextType,
    RequireFields<MutationScheduleUnpublishMenuArgs, 'from' | 'where'>
  >;
  scheduleUnpublishNotice?: Resolver<
    Maybe<ResolversTypes['Notice']>,
    ParentType,
    ContextType,
    RequireFields<MutationScheduleUnpublishNoticeArgs, 'from' | 'unpublishBase' | 'where'>
  >;
  scheduleUnpublishPage?: Resolver<
    Maybe<ResolversTypes['Page']>,
    ParentType,
    ContextType,
    RequireFields<MutationScheduleUnpublishPageArgs, 'from' | 'unpublishBase' | 'where'>
  >;
  scheduleUnpublishPageTemplate?: Resolver<
    Maybe<ResolversTypes['PageTemplate']>,
    ParentType,
    ContextType,
    RequireFields<MutationScheduleUnpublishPageTemplateArgs, 'from' | 'where'>
  >;
  scheduleUnpublishPartner?: Resolver<
    Maybe<ResolversTypes['Partner']>,
    ParentType,
    ContextType,
    RequireFields<MutationScheduleUnpublishPartnerArgs, 'from' | 'where'>
  >;
  scheduleUnpublishPartnerOffer?: Resolver<
    Maybe<ResolversTypes['PartnerOffer']>,
    ParentType,
    ContextType,
    RequireFields<MutationScheduleUnpublishPartnerOfferArgs, 'from' | 'unpublishBase' | 'where'>
  >;
  scheduleUnpublishProduct?: Resolver<
    Maybe<ResolversTypes['Product']>,
    ParentType,
    ContextType,
    RequireFields<MutationScheduleUnpublishProductArgs, 'from' | 'unpublishBase' | 'where'>
  >;
  scheduleUnpublishProductActivity?: Resolver<
    Maybe<ResolversTypes['ProductActivity']>,
    ParentType,
    ContextType,
    RequireFields<MutationScheduleUnpublishProductActivityArgs, 'from' | 'unpublishBase' | 'where'>
  >;
  scheduleUnpublishProductActivityItem?: Resolver<
    Maybe<ResolversTypes['ProductActivityItem']>,
    ParentType,
    ContextType,
    RequireFields<
      MutationScheduleUnpublishProductActivityItemArgs,
      'from' | 'unpublishBase' | 'where'
    >
  >;
  scheduleUnpublishProductAttractionLineup?: Resolver<
    Maybe<ResolversTypes['ProductAttractionLineup']>,
    ParentType,
    ContextType,
    RequireFields<MutationScheduleUnpublishProductAttractionLineupArgs, 'from' | 'where'>
  >;
  scheduleUnpublishProductReservation?: Resolver<
    Maybe<ResolversTypes['ProductReservation']>,
    ParentType,
    ContextType,
    RequireFields<MutationScheduleUnpublishProductReservationArgs, 'from' | 'where'>
  >;
  scheduleUnpublishPromo?: Resolver<
    Maybe<ResolversTypes['Promo']>,
    ParentType,
    ContextType,
    RequireFields<MutationScheduleUnpublishPromoArgs, 'from' | 'unpublishBase' | 'where'>
  >;
  scheduleUnpublishReservationText?: Resolver<
    Maybe<ResolversTypes['ReservationText']>,
    ParentType,
    ContextType,
    RequireFields<MutationScheduleUnpublishReservationTextArgs, 'from' | 'unpublishBase' | 'where'>
  >;
  scheduleUnpublishSandbox?: Resolver<
    Maybe<ResolversTypes['Sandbox']>,
    ParentType,
    ContextType,
    RequireFields<MutationScheduleUnpublishSandboxArgs, 'from' | 'unpublishBase' | 'where'>
  >;
  scheduleUnpublishSeo?: Resolver<
    Maybe<ResolversTypes['Seo']>,
    ParentType,
    ContextType,
    RequireFields<MutationScheduleUnpublishSeoArgs, 'from' | 'unpublishBase' | 'where'>
  >;
  scheduleUnpublishText?: Resolver<
    Maybe<ResolversTypes['Text']>,
    ParentType,
    ContextType,
    RequireFields<MutationScheduleUnpublishTextArgs, 'from' | 'unpublishBase' | 'where'>
  >;
  scheduleUnpublishTextGroup?: Resolver<
    Maybe<ResolversTypes['TextGroup']>,
    ParentType,
    ContextType,
    RequireFields<MutationScheduleUnpublishTextGroupArgs, 'from' | 'where'>
  >;
  scheduleUnpublishTextList?: Resolver<
    Maybe<ResolversTypes['TextList']>,
    ParentType,
    ContextType,
    RequireFields<MutationScheduleUnpublishTextListArgs, 'from' | 'where'>
  >;
  scheduleUnpublishTicketGroup?: Resolver<
    Maybe<ResolversTypes['TicketGroup']>,
    ParentType,
    ContextType,
    RequireFields<MutationScheduleUnpublishTicketGroupArgs, 'from' | 'where'>
  >;
  scheduleUnpublishTitleDescription?: Resolver<
    Maybe<ResolversTypes['TitleDescription']>,
    ParentType,
    ContextType,
    RequireFields<MutationScheduleUnpublishTitleDescriptionArgs, 'from' | 'unpublishBase' | 'where'>
  >;
  scheduleUnpublishVideo?: Resolver<
    Maybe<ResolversTypes['Video']>,
    ParentType,
    ContextType,
    RequireFields<MutationScheduleUnpublishVideoArgs, 'from' | 'unpublishBase' | 'where'>
  >;
  unpublishAddOn?: Resolver<
    Maybe<ResolversTypes['AddOn']>,
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishAddOnArgs, 'from' | 'unpublishBase' | 'where'>
  >;
  unpublishAlacarteTicket?: Resolver<
    Maybe<ResolversTypes['AlacarteTicket']>,
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishAlacarteTicketArgs, 'from' | 'where'>
  >;
  unpublishAlacarteTicketType?: Resolver<
    Maybe<ResolversTypes['AlacarteTicketType']>,
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishAlacarteTicketTypeArgs, 'from' | 'where'>
  >;
  unpublishArticle?: Resolver<
    Maybe<ResolversTypes['Article']>,
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishArticleArgs, 'from' | 'unpublishBase' | 'where'>
  >;
  unpublishArticleCategory?: Resolver<
    Maybe<ResolversTypes['ArticleCategory']>,
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishArticleCategoryArgs, 'from' | 'where'>
  >;
  unpublishAsset?: Resolver<
    Maybe<ResolversTypes['Asset']>,
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishAssetArgs, 'from' | 'unpublishBase' | 'where'>
  >;
  unpublishAttraction?: Resolver<
    Maybe<ResolversTypes['Attraction']>,
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishAttractionArgs, 'from' | 'unpublishBase' | 'where'>
  >;
  unpublishAttractionAlacarte?: Resolver<
    Maybe<ResolversTypes['AttractionAlacarte']>,
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishAttractionAlacarteArgs, 'from' | 'where'>
  >;
  unpublishAttractionAvailability?: Resolver<
    Maybe<ResolversTypes['AttractionAvailability']>,
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishAttractionAvailabilityArgs, 'from' | 'unpublishBase' | 'where'>
  >;
  unpublishAttractionLocation?: Resolver<
    Maybe<ResolversTypes['AttractionLocation']>,
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishAttractionLocationArgs, 'from' | 'unpublishBase' | 'where'>
  >;
  unpublishAttractionOffer?: Resolver<
    Maybe<ResolversTypes['AttractionOffer']>,
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishAttractionOfferArgs, 'from' | 'unpublishBase' | 'where'>
  >;
  unpublishAttractionPrice?: Resolver<
    Maybe<ResolversTypes['AttractionPrice']>,
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishAttractionPriceArgs, 'from' | 'unpublishBase' | 'where'>
  >;
  unpublishAttractionReservation?: Resolver<
    Maybe<ResolversTypes['AttractionReservation']>,
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishAttractionReservationArgs, 'from' | 'where'>
  >;
  unpublishAttractionVariant?: Resolver<
    Maybe<ResolversTypes['AttractionVariant']>,
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishAttractionVariantArgs, 'from' | 'unpublishBase' | 'where'>
  >;
  unpublishAuthor?: Resolver<
    Maybe<ResolversTypes['Author']>,
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishAuthorArgs, 'from' | 'where'>
  >;
  unpublishBlock?: Resolver<
    Maybe<ResolversTypes['Block']>,
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishBlockArgs, 'from' | 'where'>
  >;
  unpublishBlockGroup?: Resolver<
    Maybe<ResolversTypes['BlockGroup']>,
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishBlockGroupArgs, 'from' | 'where'>
  >;
  unpublishDetailBlock?: Resolver<
    Maybe<ResolversTypes['DetailBlock']>,
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishDetailBlockArgs, 'from' | 'where'>
  >;
  unpublishGeneralReservation?: Resolver<
    Maybe<ResolversTypes['GeneralReservation']>,
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishGeneralReservationArgs, 'from' | 'where'>
  >;
  unpublishImage?: Resolver<
    Maybe<ResolversTypes['Image']>,
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishImageArgs, 'from' | 'unpublishBase' | 'where'>
  >;
  unpublishLandingPage?: Resolver<
    Maybe<ResolversTypes['LandingPage']>,
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishLandingPageArgs, 'from' | 'unpublishBase' | 'where'>
  >;
  unpublishLink?: Resolver<
    Maybe<ResolversTypes['Link']>,
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishLinkArgs, 'from' | 'where'>
  >;
  unpublishManyAddOns?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishManyAddOnsArgs, 'from' | 'unpublishBase'>
  >;
  unpublishManyAddOnsConnection?: Resolver<
    ResolversTypes['AddOnConnection'],
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishManyAddOnsConnectionArgs, 'from' | 'stage' | 'unpublishBase'>
  >;
  unpublishManyAlacarteTicketTypes?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishManyAlacarteTicketTypesArgs, 'from'>
  >;
  unpublishManyAlacarteTicketTypesConnection?: Resolver<
    ResolversTypes['AlacarteTicketTypeConnection'],
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishManyAlacarteTicketTypesConnectionArgs, 'from' | 'stage'>
  >;
  unpublishManyAlacarteTickets?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishManyAlacarteTicketsArgs, 'from'>
  >;
  unpublishManyAlacarteTicketsConnection?: Resolver<
    ResolversTypes['AlacarteTicketConnection'],
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishManyAlacarteTicketsConnectionArgs, 'from' | 'stage'>
  >;
  unpublishManyArticleCategories?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishManyArticleCategoriesArgs, 'from'>
  >;
  unpublishManyArticleCategoriesConnection?: Resolver<
    ResolversTypes['ArticleCategoryConnection'],
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishManyArticleCategoriesConnectionArgs, 'from' | 'stage'>
  >;
  unpublishManyArticles?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishManyArticlesArgs, 'from' | 'unpublishBase'>
  >;
  unpublishManyArticlesConnection?: Resolver<
    ResolversTypes['ArticleConnection'],
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishManyArticlesConnectionArgs, 'from' | 'stage' | 'unpublishBase'>
  >;
  unpublishManyAssets?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishManyAssetsArgs, 'from' | 'unpublishBase'>
  >;
  unpublishManyAssetsConnection?: Resolver<
    ResolversTypes['AssetConnection'],
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishManyAssetsConnectionArgs, 'from' | 'stage' | 'unpublishBase'>
  >;
  unpublishManyAttractionAlacartes?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishManyAttractionAlacartesArgs, 'from'>
  >;
  unpublishManyAttractionAlacartesConnection?: Resolver<
    ResolversTypes['AttractionAlacarteConnection'],
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishManyAttractionAlacartesConnectionArgs, 'from' | 'stage'>
  >;
  unpublishManyAttractionAvailabilities?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishManyAttractionAvailabilitiesArgs, 'from' | 'unpublishBase'>
  >;
  unpublishManyAttractionAvailabilitiesConnection?: Resolver<
    ResolversTypes['AttractionAvailabilityConnection'],
    ParentType,
    ContextType,
    RequireFields<
      MutationUnpublishManyAttractionAvailabilitiesConnectionArgs,
      'from' | 'stage' | 'unpublishBase'
    >
  >;
  unpublishManyAttractionLocations?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishManyAttractionLocationsArgs, 'from' | 'unpublishBase'>
  >;
  unpublishManyAttractionLocationsConnection?: Resolver<
    ResolversTypes['AttractionLocationConnection'],
    ParentType,
    ContextType,
    RequireFields<
      MutationUnpublishManyAttractionLocationsConnectionArgs,
      'from' | 'stage' | 'unpublishBase'
    >
  >;
  unpublishManyAttractionOffers?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishManyAttractionOffersArgs, 'from' | 'unpublishBase'>
  >;
  unpublishManyAttractionOffersConnection?: Resolver<
    ResolversTypes['AttractionOfferConnection'],
    ParentType,
    ContextType,
    RequireFields<
      MutationUnpublishManyAttractionOffersConnectionArgs,
      'from' | 'stage' | 'unpublishBase'
    >
  >;
  unpublishManyAttractionPrices?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishManyAttractionPricesArgs, 'from' | 'unpublishBase'>
  >;
  unpublishManyAttractionPricesConnection?: Resolver<
    ResolversTypes['AttractionPriceConnection'],
    ParentType,
    ContextType,
    RequireFields<
      MutationUnpublishManyAttractionPricesConnectionArgs,
      'from' | 'stage' | 'unpublishBase'
    >
  >;
  unpublishManyAttractionReservations?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishManyAttractionReservationsArgs, 'from'>
  >;
  unpublishManyAttractionReservationsConnection?: Resolver<
    ResolversTypes['AttractionReservationConnection'],
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishManyAttractionReservationsConnectionArgs, 'from' | 'stage'>
  >;
  unpublishManyAttractionVariants?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishManyAttractionVariantsArgs, 'from' | 'unpublishBase'>
  >;
  unpublishManyAttractionVariantsConnection?: Resolver<
    ResolversTypes['AttractionVariantConnection'],
    ParentType,
    ContextType,
    RequireFields<
      MutationUnpublishManyAttractionVariantsConnectionArgs,
      'from' | 'stage' | 'unpublishBase'
    >
  >;
  unpublishManyAttractions?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishManyAttractionsArgs, 'from' | 'unpublishBase'>
  >;
  unpublishManyAttractionsConnection?: Resolver<
    ResolversTypes['AttractionConnection'],
    ParentType,
    ContextType,
    RequireFields<
      MutationUnpublishManyAttractionsConnectionArgs,
      'from' | 'stage' | 'unpublishBase'
    >
  >;
  unpublishManyAuthors?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishManyAuthorsArgs, 'from'>
  >;
  unpublishManyAuthorsConnection?: Resolver<
    ResolversTypes['AuthorConnection'],
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishManyAuthorsConnectionArgs, 'from' | 'stage'>
  >;
  unpublishManyBlockGroups?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishManyBlockGroupsArgs, 'from'>
  >;
  unpublishManyBlockGroupsConnection?: Resolver<
    ResolversTypes['BlockGroupConnection'],
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishManyBlockGroupsConnectionArgs, 'from' | 'stage'>
  >;
  unpublishManyBlocks?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishManyBlocksArgs, 'from'>
  >;
  unpublishManyBlocksConnection?: Resolver<
    ResolversTypes['BlockConnection'],
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishManyBlocksConnectionArgs, 'from' | 'stage'>
  >;
  unpublishManyDetailBlocks?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishManyDetailBlocksArgs, 'from'>
  >;
  unpublishManyDetailBlocksConnection?: Resolver<
    ResolversTypes['DetailBlockConnection'],
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishManyDetailBlocksConnectionArgs, 'from' | 'stage'>
  >;
  unpublishManyGeneralReservations?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishManyGeneralReservationsArgs, 'from'>
  >;
  unpublishManyGeneralReservationsConnection?: Resolver<
    ResolversTypes['GeneralReservationConnection'],
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishManyGeneralReservationsConnectionArgs, 'from' | 'stage'>
  >;
  unpublishManyImages?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishManyImagesArgs, 'from' | 'unpublishBase'>
  >;
  unpublishManyImagesConnection?: Resolver<
    ResolversTypes['ImageConnection'],
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishManyImagesConnectionArgs, 'from' | 'stage' | 'unpublishBase'>
  >;
  unpublishManyLandingPages?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishManyLandingPagesArgs, 'from' | 'unpublishBase'>
  >;
  unpublishManyLandingPagesConnection?: Resolver<
    ResolversTypes['LandingPageConnection'],
    ParentType,
    ContextType,
    RequireFields<
      MutationUnpublishManyLandingPagesConnectionArgs,
      'from' | 'stage' | 'unpublishBase'
    >
  >;
  unpublishManyLinks?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishManyLinksArgs, 'from'>
  >;
  unpublishManyLinksConnection?: Resolver<
    ResolversTypes['LinkConnection'],
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishManyLinksConnectionArgs, 'from' | 'stage'>
  >;
  unpublishManyMenus?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishManyMenusArgs, 'from'>
  >;
  unpublishManyMenusConnection?: Resolver<
    ResolversTypes['MenuConnection'],
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishManyMenusConnectionArgs, 'from' | 'stage'>
  >;
  unpublishManyNotices?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishManyNoticesArgs, 'from' | 'unpublishBase'>
  >;
  unpublishManyNoticesConnection?: Resolver<
    ResolversTypes['NoticeConnection'],
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishManyNoticesConnectionArgs, 'from' | 'stage' | 'unpublishBase'>
  >;
  unpublishManyPageTemplates?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishManyPageTemplatesArgs, 'from'>
  >;
  unpublishManyPageTemplatesConnection?: Resolver<
    ResolversTypes['PageTemplateConnection'],
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishManyPageTemplatesConnectionArgs, 'from' | 'stage'>
  >;
  unpublishManyPages?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishManyPagesArgs, 'from' | 'unpublishBase'>
  >;
  unpublishManyPagesConnection?: Resolver<
    ResolversTypes['PageConnection'],
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishManyPagesConnectionArgs, 'from' | 'stage' | 'unpublishBase'>
  >;
  unpublishManyPartnerOffers?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishManyPartnerOffersArgs, 'from' | 'unpublishBase'>
  >;
  unpublishManyPartnerOffersConnection?: Resolver<
    ResolversTypes['PartnerOfferConnection'],
    ParentType,
    ContextType,
    RequireFields<
      MutationUnpublishManyPartnerOffersConnectionArgs,
      'from' | 'stage' | 'unpublishBase'
    >
  >;
  unpublishManyPartners?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishManyPartnersArgs, 'from'>
  >;
  unpublishManyPartnersConnection?: Resolver<
    ResolversTypes['PartnerConnection'],
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishManyPartnersConnectionArgs, 'from' | 'stage'>
  >;
  unpublishManyProductActivities?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishManyProductActivitiesArgs, 'from' | 'unpublishBase'>
  >;
  unpublishManyProductActivitiesConnection?: Resolver<
    ResolversTypes['ProductActivityConnection'],
    ParentType,
    ContextType,
    RequireFields<
      MutationUnpublishManyProductActivitiesConnectionArgs,
      'from' | 'stage' | 'unpublishBase'
    >
  >;
  unpublishManyProductActivityItems?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishManyProductActivityItemsArgs, 'from' | 'unpublishBase'>
  >;
  unpublishManyProductActivityItemsConnection?: Resolver<
    ResolversTypes['ProductActivityItemConnection'],
    ParentType,
    ContextType,
    RequireFields<
      MutationUnpublishManyProductActivityItemsConnectionArgs,
      'from' | 'stage' | 'unpublishBase'
    >
  >;
  unpublishManyProductAttractionLineups?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishManyProductAttractionLineupsArgs, 'from'>
  >;
  unpublishManyProductAttractionLineupsConnection?: Resolver<
    ResolversTypes['ProductAttractionLineupConnection'],
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishManyProductAttractionLineupsConnectionArgs, 'from' | 'stage'>
  >;
  unpublishManyProductReservations?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishManyProductReservationsArgs, 'from'>
  >;
  unpublishManyProductReservationsConnection?: Resolver<
    ResolversTypes['ProductReservationConnection'],
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishManyProductReservationsConnectionArgs, 'from' | 'stage'>
  >;
  unpublishManyProducts?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishManyProductsArgs, 'from' | 'unpublishBase'>
  >;
  unpublishManyProductsConnection?: Resolver<
    ResolversTypes['ProductConnection'],
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishManyProductsConnectionArgs, 'from' | 'stage' | 'unpublishBase'>
  >;
  unpublishManyPromos?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishManyPromosArgs, 'from' | 'unpublishBase'>
  >;
  unpublishManyPromosConnection?: Resolver<
    ResolversTypes['PromoConnection'],
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishManyPromosConnectionArgs, 'from' | 'stage' | 'unpublishBase'>
  >;
  unpublishManyReservationTexts?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishManyReservationTextsArgs, 'from' | 'unpublishBase'>
  >;
  unpublishManyReservationTextsConnection?: Resolver<
    ResolversTypes['ReservationTextConnection'],
    ParentType,
    ContextType,
    RequireFields<
      MutationUnpublishManyReservationTextsConnectionArgs,
      'from' | 'stage' | 'unpublishBase'
    >
  >;
  unpublishManySandboxes?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishManySandboxesArgs, 'from' | 'unpublishBase'>
  >;
  unpublishManySandboxesConnection?: Resolver<
    ResolversTypes['SandboxConnection'],
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishManySandboxesConnectionArgs, 'from' | 'stage' | 'unpublishBase'>
  >;
  unpublishManySeos?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishManySeosArgs, 'from' | 'unpublishBase'>
  >;
  unpublishManySeosConnection?: Resolver<
    ResolversTypes['SeoConnection'],
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishManySeosConnectionArgs, 'from' | 'stage' | 'unpublishBase'>
  >;
  unpublishManyTextGroups?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishManyTextGroupsArgs, 'from'>
  >;
  unpublishManyTextGroupsConnection?: Resolver<
    ResolversTypes['TextGroupConnection'],
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishManyTextGroupsConnectionArgs, 'from' | 'stage'>
  >;
  unpublishManyTextLists?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishManyTextListsArgs, 'from'>
  >;
  unpublishManyTextListsConnection?: Resolver<
    ResolversTypes['TextListConnection'],
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishManyTextListsConnectionArgs, 'from' | 'stage'>
  >;
  unpublishManyTexts?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishManyTextsArgs, 'from' | 'unpublishBase'>
  >;
  unpublishManyTextsConnection?: Resolver<
    ResolversTypes['TextConnection'],
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishManyTextsConnectionArgs, 'from' | 'stage' | 'unpublishBase'>
  >;
  unpublishManyTicketGroups?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishManyTicketGroupsArgs, 'from'>
  >;
  unpublishManyTicketGroupsConnection?: Resolver<
    ResolversTypes['TicketGroupConnection'],
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishManyTicketGroupsConnectionArgs, 'from' | 'stage'>
  >;
  unpublishManyTitleDescriptions?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishManyTitleDescriptionsArgs, 'from' | 'unpublishBase'>
  >;
  unpublishManyTitleDescriptionsConnection?: Resolver<
    ResolversTypes['TitleDescriptionConnection'],
    ParentType,
    ContextType,
    RequireFields<
      MutationUnpublishManyTitleDescriptionsConnectionArgs,
      'from' | 'stage' | 'unpublishBase'
    >
  >;
  unpublishManyVideos?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishManyVideosArgs, 'from' | 'unpublishBase'>
  >;
  unpublishManyVideosConnection?: Resolver<
    ResolversTypes['VideoConnection'],
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishManyVideosConnectionArgs, 'from' | 'stage' | 'unpublishBase'>
  >;
  unpublishMenu?: Resolver<
    Maybe<ResolversTypes['Menu']>,
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishMenuArgs, 'from' | 'where'>
  >;
  unpublishNotice?: Resolver<
    Maybe<ResolversTypes['Notice']>,
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishNoticeArgs, 'from' | 'unpublishBase' | 'where'>
  >;
  unpublishPage?: Resolver<
    Maybe<ResolversTypes['Page']>,
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishPageArgs, 'from' | 'unpublishBase' | 'where'>
  >;
  unpublishPageTemplate?: Resolver<
    Maybe<ResolversTypes['PageTemplate']>,
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishPageTemplateArgs, 'from' | 'where'>
  >;
  unpublishPartner?: Resolver<
    Maybe<ResolversTypes['Partner']>,
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishPartnerArgs, 'from' | 'where'>
  >;
  unpublishPartnerOffer?: Resolver<
    Maybe<ResolversTypes['PartnerOffer']>,
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishPartnerOfferArgs, 'from' | 'unpublishBase' | 'where'>
  >;
  unpublishProduct?: Resolver<
    Maybe<ResolversTypes['Product']>,
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishProductArgs, 'from' | 'unpublishBase' | 'where'>
  >;
  unpublishProductActivity?: Resolver<
    Maybe<ResolversTypes['ProductActivity']>,
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishProductActivityArgs, 'from' | 'unpublishBase' | 'where'>
  >;
  unpublishProductActivityItem?: Resolver<
    Maybe<ResolversTypes['ProductActivityItem']>,
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishProductActivityItemArgs, 'from' | 'unpublishBase' | 'where'>
  >;
  unpublishProductAttractionLineup?: Resolver<
    Maybe<ResolversTypes['ProductAttractionLineup']>,
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishProductAttractionLineupArgs, 'from' | 'where'>
  >;
  unpublishProductReservation?: Resolver<
    Maybe<ResolversTypes['ProductReservation']>,
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishProductReservationArgs, 'from' | 'where'>
  >;
  unpublishPromo?: Resolver<
    Maybe<ResolversTypes['Promo']>,
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishPromoArgs, 'from' | 'unpublishBase' | 'where'>
  >;
  unpublishReservationText?: Resolver<
    Maybe<ResolversTypes['ReservationText']>,
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishReservationTextArgs, 'from' | 'unpublishBase' | 'where'>
  >;
  unpublishSandbox?: Resolver<
    Maybe<ResolversTypes['Sandbox']>,
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishSandboxArgs, 'from' | 'unpublishBase' | 'where'>
  >;
  unpublishSeo?: Resolver<
    Maybe<ResolversTypes['Seo']>,
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishSeoArgs, 'from' | 'unpublishBase' | 'where'>
  >;
  unpublishText?: Resolver<
    Maybe<ResolversTypes['Text']>,
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishTextArgs, 'from' | 'unpublishBase' | 'where'>
  >;
  unpublishTextGroup?: Resolver<
    Maybe<ResolversTypes['TextGroup']>,
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishTextGroupArgs, 'from' | 'where'>
  >;
  unpublishTextList?: Resolver<
    Maybe<ResolversTypes['TextList']>,
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishTextListArgs, 'from' | 'where'>
  >;
  unpublishTicketGroup?: Resolver<
    Maybe<ResolversTypes['TicketGroup']>,
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishTicketGroupArgs, 'from' | 'where'>
  >;
  unpublishTitleDescription?: Resolver<
    Maybe<ResolversTypes['TitleDescription']>,
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishTitleDescriptionArgs, 'from' | 'unpublishBase' | 'where'>
  >;
  unpublishVideo?: Resolver<
    Maybe<ResolversTypes['Video']>,
    ParentType,
    ContextType,
    RequireFields<MutationUnpublishVideoArgs, 'from' | 'unpublishBase' | 'where'>
  >;
  updateAddOn?: Resolver<
    Maybe<ResolversTypes['AddOn']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateAddOnArgs, 'data' | 'where'>
  >;
  updateAlacarteTicket?: Resolver<
    Maybe<ResolversTypes['AlacarteTicket']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateAlacarteTicketArgs, 'data' | 'where'>
  >;
  updateAlacarteTicketType?: Resolver<
    Maybe<ResolversTypes['AlacarteTicketType']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateAlacarteTicketTypeArgs, 'data' | 'where'>
  >;
  updateArticle?: Resolver<
    Maybe<ResolversTypes['Article']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateArticleArgs, 'data' | 'where'>
  >;
  updateArticleCategory?: Resolver<
    Maybe<ResolversTypes['ArticleCategory']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateArticleCategoryArgs, 'data' | 'where'>
  >;
  updateAsset?: Resolver<
    Maybe<ResolversTypes['Asset']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateAssetArgs, 'data' | 'where'>
  >;
  updateAttraction?: Resolver<
    Maybe<ResolversTypes['Attraction']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateAttractionArgs, 'data' | 'where'>
  >;
  updateAttractionAlacarte?: Resolver<
    Maybe<ResolversTypes['AttractionAlacarte']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateAttractionAlacarteArgs, 'data' | 'where'>
  >;
  updateAttractionAvailability?: Resolver<
    Maybe<ResolversTypes['AttractionAvailability']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateAttractionAvailabilityArgs, 'data' | 'where'>
  >;
  updateAttractionLocation?: Resolver<
    Maybe<ResolversTypes['AttractionLocation']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateAttractionLocationArgs, 'data' | 'where'>
  >;
  updateAttractionOffer?: Resolver<
    Maybe<ResolversTypes['AttractionOffer']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateAttractionOfferArgs, 'data' | 'where'>
  >;
  updateAttractionPrice?: Resolver<
    Maybe<ResolversTypes['AttractionPrice']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateAttractionPriceArgs, 'data' | 'where'>
  >;
  updateAttractionReservation?: Resolver<
    Maybe<ResolversTypes['AttractionReservation']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateAttractionReservationArgs, 'data' | 'where'>
  >;
  updateAttractionVariant?: Resolver<
    Maybe<ResolversTypes['AttractionVariant']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateAttractionVariantArgs, 'data' | 'where'>
  >;
  updateAuthor?: Resolver<
    Maybe<ResolversTypes['Author']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateAuthorArgs, 'data' | 'where'>
  >;
  updateBlock?: Resolver<
    Maybe<ResolversTypes['Block']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateBlockArgs, 'data' | 'where'>
  >;
  updateBlockGroup?: Resolver<
    Maybe<ResolversTypes['BlockGroup']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateBlockGroupArgs, 'data' | 'where'>
  >;
  updateDetailBlock?: Resolver<
    Maybe<ResolversTypes['DetailBlock']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateDetailBlockArgs, 'data' | 'where'>
  >;
  updateGeneralReservation?: Resolver<
    Maybe<ResolversTypes['GeneralReservation']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateGeneralReservationArgs, 'data' | 'where'>
  >;
  updateImage?: Resolver<
    Maybe<ResolversTypes['Image']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateImageArgs, 'data' | 'where'>
  >;
  updateLandingPage?: Resolver<
    Maybe<ResolversTypes['LandingPage']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateLandingPageArgs, 'data' | 'where'>
  >;
  updateLink?: Resolver<
    Maybe<ResolversTypes['Link']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateLinkArgs, 'data' | 'where'>
  >;
  updateManyAddOns?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateManyAddOnsArgs, 'data'>
  >;
  updateManyAddOnsConnection?: Resolver<
    ResolversTypes['AddOnConnection'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateManyAddOnsConnectionArgs, 'data'>
  >;
  updateManyAlacarteTicketTypes?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateManyAlacarteTicketTypesArgs, 'data'>
  >;
  updateManyAlacarteTicketTypesConnection?: Resolver<
    ResolversTypes['AlacarteTicketTypeConnection'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateManyAlacarteTicketTypesConnectionArgs, 'data'>
  >;
  updateManyAlacarteTickets?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateManyAlacarteTicketsArgs, 'data'>
  >;
  updateManyAlacarteTicketsConnection?: Resolver<
    ResolversTypes['AlacarteTicketConnection'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateManyAlacarteTicketsConnectionArgs, 'data'>
  >;
  updateManyArticleCategories?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateManyArticleCategoriesArgs, 'data'>
  >;
  updateManyArticleCategoriesConnection?: Resolver<
    ResolversTypes['ArticleCategoryConnection'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateManyArticleCategoriesConnectionArgs, 'data'>
  >;
  updateManyArticles?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateManyArticlesArgs, 'data'>
  >;
  updateManyArticlesConnection?: Resolver<
    ResolversTypes['ArticleConnection'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateManyArticlesConnectionArgs, 'data'>
  >;
  updateManyAssets?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateManyAssetsArgs, 'data'>
  >;
  updateManyAssetsConnection?: Resolver<
    ResolversTypes['AssetConnection'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateManyAssetsConnectionArgs, 'data'>
  >;
  updateManyAttractionAlacartes?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateManyAttractionAlacartesArgs, 'data'>
  >;
  updateManyAttractionAlacartesConnection?: Resolver<
    ResolversTypes['AttractionAlacarteConnection'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateManyAttractionAlacartesConnectionArgs, 'data'>
  >;
  updateManyAttractionAvailabilities?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateManyAttractionAvailabilitiesArgs, 'data'>
  >;
  updateManyAttractionAvailabilitiesConnection?: Resolver<
    ResolversTypes['AttractionAvailabilityConnection'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateManyAttractionAvailabilitiesConnectionArgs, 'data'>
  >;
  updateManyAttractionLocations?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateManyAttractionLocationsArgs, 'data'>
  >;
  updateManyAttractionLocationsConnection?: Resolver<
    ResolversTypes['AttractionLocationConnection'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateManyAttractionLocationsConnectionArgs, 'data'>
  >;
  updateManyAttractionOffers?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateManyAttractionOffersArgs, 'data'>
  >;
  updateManyAttractionOffersConnection?: Resolver<
    ResolversTypes['AttractionOfferConnection'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateManyAttractionOffersConnectionArgs, 'data'>
  >;
  updateManyAttractionPrices?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateManyAttractionPricesArgs, 'data'>
  >;
  updateManyAttractionPricesConnection?: Resolver<
    ResolversTypes['AttractionPriceConnection'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateManyAttractionPricesConnectionArgs, 'data'>
  >;
  updateManyAttractionReservations?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateManyAttractionReservationsArgs, 'data'>
  >;
  updateManyAttractionReservationsConnection?: Resolver<
    ResolversTypes['AttractionReservationConnection'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateManyAttractionReservationsConnectionArgs, 'data'>
  >;
  updateManyAttractionVariants?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateManyAttractionVariantsArgs, 'data'>
  >;
  updateManyAttractionVariantsConnection?: Resolver<
    ResolversTypes['AttractionVariantConnection'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateManyAttractionVariantsConnectionArgs, 'data'>
  >;
  updateManyAttractions?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateManyAttractionsArgs, 'data'>
  >;
  updateManyAttractionsConnection?: Resolver<
    ResolversTypes['AttractionConnection'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateManyAttractionsConnectionArgs, 'data'>
  >;
  updateManyAuthors?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateManyAuthorsArgs, 'data'>
  >;
  updateManyAuthorsConnection?: Resolver<
    ResolversTypes['AuthorConnection'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateManyAuthorsConnectionArgs, 'data'>
  >;
  updateManyBlockGroups?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateManyBlockGroupsArgs, 'data'>
  >;
  updateManyBlockGroupsConnection?: Resolver<
    ResolversTypes['BlockGroupConnection'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateManyBlockGroupsConnectionArgs, 'data'>
  >;
  updateManyBlocks?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateManyBlocksArgs, 'data'>
  >;
  updateManyBlocksConnection?: Resolver<
    ResolversTypes['BlockConnection'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateManyBlocksConnectionArgs, 'data'>
  >;
  updateManyDetailBlocks?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateManyDetailBlocksArgs, 'data'>
  >;
  updateManyDetailBlocksConnection?: Resolver<
    ResolversTypes['DetailBlockConnection'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateManyDetailBlocksConnectionArgs, 'data'>
  >;
  updateManyGeneralReservations?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateManyGeneralReservationsArgs, 'data'>
  >;
  updateManyGeneralReservationsConnection?: Resolver<
    ResolversTypes['GeneralReservationConnection'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateManyGeneralReservationsConnectionArgs, 'data'>
  >;
  updateManyImages?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateManyImagesArgs, 'data'>
  >;
  updateManyImagesConnection?: Resolver<
    ResolversTypes['ImageConnection'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateManyImagesConnectionArgs, 'data'>
  >;
  updateManyLandingPages?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateManyLandingPagesArgs, 'data'>
  >;
  updateManyLandingPagesConnection?: Resolver<
    ResolversTypes['LandingPageConnection'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateManyLandingPagesConnectionArgs, 'data'>
  >;
  updateManyLinks?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateManyLinksArgs, 'data'>
  >;
  updateManyLinksConnection?: Resolver<
    ResolversTypes['LinkConnection'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateManyLinksConnectionArgs, 'data'>
  >;
  updateManyMenus?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateManyMenusArgs, 'data'>
  >;
  updateManyMenusConnection?: Resolver<
    ResolversTypes['MenuConnection'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateManyMenusConnectionArgs, 'data'>
  >;
  updateManyNotices?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateManyNoticesArgs, 'data'>
  >;
  updateManyNoticesConnection?: Resolver<
    ResolversTypes['NoticeConnection'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateManyNoticesConnectionArgs, 'data'>
  >;
  updateManyPageTemplates?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateManyPageTemplatesArgs, 'data'>
  >;
  updateManyPageTemplatesConnection?: Resolver<
    ResolversTypes['PageTemplateConnection'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateManyPageTemplatesConnectionArgs, 'data'>
  >;
  updateManyPages?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateManyPagesArgs, 'data'>
  >;
  updateManyPagesConnection?: Resolver<
    ResolversTypes['PageConnection'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateManyPagesConnectionArgs, 'data'>
  >;
  updateManyPartnerOffers?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateManyPartnerOffersArgs, 'data'>
  >;
  updateManyPartnerOffersConnection?: Resolver<
    ResolversTypes['PartnerOfferConnection'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateManyPartnerOffersConnectionArgs, 'data'>
  >;
  updateManyPartners?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateManyPartnersArgs, 'data'>
  >;
  updateManyPartnersConnection?: Resolver<
    ResolversTypes['PartnerConnection'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateManyPartnersConnectionArgs, 'data'>
  >;
  updateManyProductActivities?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateManyProductActivitiesArgs, 'data'>
  >;
  updateManyProductActivitiesConnection?: Resolver<
    ResolversTypes['ProductActivityConnection'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateManyProductActivitiesConnectionArgs, 'data'>
  >;
  updateManyProductActivityItems?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateManyProductActivityItemsArgs, 'data'>
  >;
  updateManyProductActivityItemsConnection?: Resolver<
    ResolversTypes['ProductActivityItemConnection'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateManyProductActivityItemsConnectionArgs, 'data'>
  >;
  updateManyProductAttractionLineups?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateManyProductAttractionLineupsArgs, 'data'>
  >;
  updateManyProductAttractionLineupsConnection?: Resolver<
    ResolversTypes['ProductAttractionLineupConnection'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateManyProductAttractionLineupsConnectionArgs, 'data'>
  >;
  updateManyProductReservations?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateManyProductReservationsArgs, 'data'>
  >;
  updateManyProductReservationsConnection?: Resolver<
    ResolversTypes['ProductReservationConnection'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateManyProductReservationsConnectionArgs, 'data'>
  >;
  updateManyProducts?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateManyProductsArgs, 'data'>
  >;
  updateManyProductsConnection?: Resolver<
    ResolversTypes['ProductConnection'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateManyProductsConnectionArgs, 'data'>
  >;
  updateManyPromos?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateManyPromosArgs, 'data'>
  >;
  updateManyPromosConnection?: Resolver<
    ResolversTypes['PromoConnection'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateManyPromosConnectionArgs, 'data'>
  >;
  updateManyReservationTexts?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateManyReservationTextsArgs, 'data'>
  >;
  updateManyReservationTextsConnection?: Resolver<
    ResolversTypes['ReservationTextConnection'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateManyReservationTextsConnectionArgs, 'data'>
  >;
  updateManySandboxes?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateManySandboxesArgs, 'data'>
  >;
  updateManySandboxesConnection?: Resolver<
    ResolversTypes['SandboxConnection'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateManySandboxesConnectionArgs, 'data'>
  >;
  updateManySeos?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateManySeosArgs, 'data'>
  >;
  updateManySeosConnection?: Resolver<
    ResolversTypes['SeoConnection'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateManySeosConnectionArgs, 'data'>
  >;
  updateManyTextGroups?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateManyTextGroupsArgs, 'data'>
  >;
  updateManyTextGroupsConnection?: Resolver<
    ResolversTypes['TextGroupConnection'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateManyTextGroupsConnectionArgs, 'data'>
  >;
  updateManyTextLists?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateManyTextListsArgs, 'data'>
  >;
  updateManyTextListsConnection?: Resolver<
    ResolversTypes['TextListConnection'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateManyTextListsConnectionArgs, 'data'>
  >;
  updateManyTexts?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateManyTextsArgs, 'data'>
  >;
  updateManyTextsConnection?: Resolver<
    ResolversTypes['TextConnection'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateManyTextsConnectionArgs, 'data'>
  >;
  updateManyTicketGroups?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateManyTicketGroupsArgs, 'data'>
  >;
  updateManyTicketGroupsConnection?: Resolver<
    ResolversTypes['TicketGroupConnection'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateManyTicketGroupsConnectionArgs, 'data'>
  >;
  updateManyTitleDescriptions?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateManyTitleDescriptionsArgs, 'data'>
  >;
  updateManyTitleDescriptionsConnection?: Resolver<
    ResolversTypes['TitleDescriptionConnection'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateManyTitleDescriptionsConnectionArgs, 'data'>
  >;
  updateManyVideos?: Resolver<
    ResolversTypes['BatchPayload'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateManyVideosArgs, 'data'>
  >;
  updateManyVideosConnection?: Resolver<
    ResolversTypes['VideoConnection'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateManyVideosConnectionArgs, 'data'>
  >;
  updateMenu?: Resolver<
    Maybe<ResolversTypes['Menu']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateMenuArgs, 'data' | 'where'>
  >;
  updateNotice?: Resolver<
    Maybe<ResolversTypes['Notice']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateNoticeArgs, 'data' | 'where'>
  >;
  updatePage?: Resolver<
    Maybe<ResolversTypes['Page']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdatePageArgs, 'data' | 'where'>
  >;
  updatePageTemplate?: Resolver<
    Maybe<ResolversTypes['PageTemplate']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdatePageTemplateArgs, 'data' | 'where'>
  >;
  updatePartner?: Resolver<
    Maybe<ResolversTypes['Partner']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdatePartnerArgs, 'data' | 'where'>
  >;
  updatePartnerOffer?: Resolver<
    Maybe<ResolversTypes['PartnerOffer']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdatePartnerOfferArgs, 'data' | 'where'>
  >;
  updateProduct?: Resolver<
    Maybe<ResolversTypes['Product']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateProductArgs, 'data' | 'where'>
  >;
  updateProductActivity?: Resolver<
    Maybe<ResolversTypes['ProductActivity']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateProductActivityArgs, 'data' | 'where'>
  >;
  updateProductActivityItem?: Resolver<
    Maybe<ResolversTypes['ProductActivityItem']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateProductActivityItemArgs, 'data' | 'where'>
  >;
  updateProductAttractionLineup?: Resolver<
    Maybe<ResolversTypes['ProductAttractionLineup']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateProductAttractionLineupArgs, 'data' | 'where'>
  >;
  updateProductReservation?: Resolver<
    Maybe<ResolversTypes['ProductReservation']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateProductReservationArgs, 'data' | 'where'>
  >;
  updatePromo?: Resolver<
    Maybe<ResolversTypes['Promo']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdatePromoArgs, 'data' | 'where'>
  >;
  updateReservationText?: Resolver<
    Maybe<ResolversTypes['ReservationText']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateReservationTextArgs, 'data' | 'where'>
  >;
  updateSandbox?: Resolver<
    Maybe<ResolversTypes['Sandbox']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateSandboxArgs, 'data' | 'where'>
  >;
  updateScheduledRelease?: Resolver<
    Maybe<ResolversTypes['ScheduledRelease']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateScheduledReleaseArgs, 'data' | 'where'>
  >;
  updateSeo?: Resolver<
    Maybe<ResolversTypes['Seo']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateSeoArgs, 'data' | 'where'>
  >;
  updateText?: Resolver<
    Maybe<ResolversTypes['Text']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateTextArgs, 'data' | 'where'>
  >;
  updateTextGroup?: Resolver<
    Maybe<ResolversTypes['TextGroup']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateTextGroupArgs, 'data' | 'where'>
  >;
  updateTextList?: Resolver<
    Maybe<ResolversTypes['TextList']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateTextListArgs, 'data' | 'where'>
  >;
  updateTicketGroup?: Resolver<
    Maybe<ResolversTypes['TicketGroup']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateTicketGroupArgs, 'data' | 'where'>
  >;
  updateTitleDescription?: Resolver<
    Maybe<ResolversTypes['TitleDescription']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateTitleDescriptionArgs, 'data' | 'where'>
  >;
  updateVideo?: Resolver<
    Maybe<ResolversTypes['Video']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateVideoArgs, 'data' | 'where'>
  >;
  upsertAddOn?: Resolver<
    Maybe<ResolversTypes['AddOn']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpsertAddOnArgs, 'upsert' | 'where'>
  >;
  upsertAlacarteTicket?: Resolver<
    Maybe<ResolversTypes['AlacarteTicket']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpsertAlacarteTicketArgs, 'upsert' | 'where'>
  >;
  upsertAlacarteTicketType?: Resolver<
    Maybe<ResolversTypes['AlacarteTicketType']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpsertAlacarteTicketTypeArgs, 'upsert' | 'where'>
  >;
  upsertArticle?: Resolver<
    Maybe<ResolversTypes['Article']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpsertArticleArgs, 'upsert' | 'where'>
  >;
  upsertArticleCategory?: Resolver<
    Maybe<ResolversTypes['ArticleCategory']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpsertArticleCategoryArgs, 'upsert' | 'where'>
  >;
  upsertAsset?: Resolver<
    Maybe<ResolversTypes['Asset']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpsertAssetArgs, 'upsert' | 'where'>
  >;
  upsertAttraction?: Resolver<
    Maybe<ResolversTypes['Attraction']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpsertAttractionArgs, 'upsert' | 'where'>
  >;
  upsertAttractionAlacarte?: Resolver<
    Maybe<ResolversTypes['AttractionAlacarte']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpsertAttractionAlacarteArgs, 'upsert' | 'where'>
  >;
  upsertAttractionAvailability?: Resolver<
    Maybe<ResolversTypes['AttractionAvailability']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpsertAttractionAvailabilityArgs, 'upsert' | 'where'>
  >;
  upsertAttractionLocation?: Resolver<
    Maybe<ResolversTypes['AttractionLocation']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpsertAttractionLocationArgs, 'upsert' | 'where'>
  >;
  upsertAttractionOffer?: Resolver<
    Maybe<ResolversTypes['AttractionOffer']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpsertAttractionOfferArgs, 'upsert' | 'where'>
  >;
  upsertAttractionPrice?: Resolver<
    Maybe<ResolversTypes['AttractionPrice']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpsertAttractionPriceArgs, 'upsert' | 'where'>
  >;
  upsertAttractionReservation?: Resolver<
    Maybe<ResolversTypes['AttractionReservation']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpsertAttractionReservationArgs, 'upsert' | 'where'>
  >;
  upsertAttractionVariant?: Resolver<
    Maybe<ResolversTypes['AttractionVariant']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpsertAttractionVariantArgs, 'upsert' | 'where'>
  >;
  upsertAuthor?: Resolver<
    Maybe<ResolversTypes['Author']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpsertAuthorArgs, 'upsert' | 'where'>
  >;
  upsertBlock?: Resolver<
    Maybe<ResolversTypes['Block']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpsertBlockArgs, 'upsert' | 'where'>
  >;
  upsertBlockGroup?: Resolver<
    Maybe<ResolversTypes['BlockGroup']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpsertBlockGroupArgs, 'upsert' | 'where'>
  >;
  upsertDetailBlock?: Resolver<
    Maybe<ResolversTypes['DetailBlock']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpsertDetailBlockArgs, 'upsert' | 'where'>
  >;
  upsertGeneralReservation?: Resolver<
    Maybe<ResolversTypes['GeneralReservation']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpsertGeneralReservationArgs, 'upsert' | 'where'>
  >;
  upsertImage?: Resolver<
    Maybe<ResolversTypes['Image']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpsertImageArgs, 'upsert' | 'where'>
  >;
  upsertLandingPage?: Resolver<
    Maybe<ResolversTypes['LandingPage']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpsertLandingPageArgs, 'upsert' | 'where'>
  >;
  upsertLink?: Resolver<
    Maybe<ResolversTypes['Link']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpsertLinkArgs, 'upsert' | 'where'>
  >;
  upsertMenu?: Resolver<
    Maybe<ResolversTypes['Menu']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpsertMenuArgs, 'upsert' | 'where'>
  >;
  upsertNotice?: Resolver<
    Maybe<ResolversTypes['Notice']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpsertNoticeArgs, 'upsert' | 'where'>
  >;
  upsertPage?: Resolver<
    Maybe<ResolversTypes['Page']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpsertPageArgs, 'upsert' | 'where'>
  >;
  upsertPageTemplate?: Resolver<
    Maybe<ResolversTypes['PageTemplate']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpsertPageTemplateArgs, 'upsert' | 'where'>
  >;
  upsertPartner?: Resolver<
    Maybe<ResolversTypes['Partner']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpsertPartnerArgs, 'upsert' | 'where'>
  >;
  upsertPartnerOffer?: Resolver<
    Maybe<ResolversTypes['PartnerOffer']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpsertPartnerOfferArgs, 'upsert' | 'where'>
  >;
  upsertProduct?: Resolver<
    Maybe<ResolversTypes['Product']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpsertProductArgs, 'upsert' | 'where'>
  >;
  upsertProductActivity?: Resolver<
    Maybe<ResolversTypes['ProductActivity']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpsertProductActivityArgs, 'upsert' | 'where'>
  >;
  upsertProductActivityItem?: Resolver<
    Maybe<ResolversTypes['ProductActivityItem']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpsertProductActivityItemArgs, 'upsert' | 'where'>
  >;
  upsertProductAttractionLineup?: Resolver<
    Maybe<ResolversTypes['ProductAttractionLineup']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpsertProductAttractionLineupArgs, 'upsert' | 'where'>
  >;
  upsertProductReservation?: Resolver<
    Maybe<ResolversTypes['ProductReservation']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpsertProductReservationArgs, 'upsert' | 'where'>
  >;
  upsertPromo?: Resolver<
    Maybe<ResolversTypes['Promo']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpsertPromoArgs, 'upsert' | 'where'>
  >;
  upsertReservationText?: Resolver<
    Maybe<ResolversTypes['ReservationText']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpsertReservationTextArgs, 'upsert' | 'where'>
  >;
  upsertSandbox?: Resolver<
    Maybe<ResolversTypes['Sandbox']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpsertSandboxArgs, 'upsert' | 'where'>
  >;
  upsertSeo?: Resolver<
    Maybe<ResolversTypes['Seo']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpsertSeoArgs, 'upsert' | 'where'>
  >;
  upsertText?: Resolver<
    Maybe<ResolversTypes['Text']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpsertTextArgs, 'upsert' | 'where'>
  >;
  upsertTextGroup?: Resolver<
    Maybe<ResolversTypes['TextGroup']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpsertTextGroupArgs, 'upsert' | 'where'>
  >;
  upsertTextList?: Resolver<
    Maybe<ResolversTypes['TextList']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpsertTextListArgs, 'upsert' | 'where'>
  >;
  upsertTicketGroup?: Resolver<
    Maybe<ResolversTypes['TicketGroup']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpsertTicketGroupArgs, 'upsert' | 'where'>
  >;
  upsertTitleDescription?: Resolver<
    Maybe<ResolversTypes['TitleDescription']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpsertTitleDescriptionArgs, 'upsert' | 'where'>
  >;
  upsertVideo?: Resolver<
    Maybe<ResolversTypes['Video']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpsertVideoArgs, 'upsert' | 'where'>
  >;
};

export type NodeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Node'] = ResolversParentTypes['Node']
> = {
  __resolveType: TypeResolveFn<
    | 'AddOn'
    | 'AlacarteTicket'
    | 'AlacarteTicketType'
    | 'Article'
    | 'ArticleCategory'
    | 'Asset'
    | 'Attraction'
    | 'AttractionAlacarte'
    | 'AttractionAvailability'
    | 'AttractionLocation'
    | 'AttractionOffer'
    | 'AttractionPrice'
    | 'AttractionReservation'
    | 'AttractionVariant'
    | 'Author'
    | 'Block'
    | 'BlockGroup'
    | 'DetailBlock'
    | 'GeneralReservation'
    | 'Image'
    | 'LandingPage'
    | 'Link'
    | 'Menu'
    | 'Notice'
    | 'Page'
    | 'PageTemplate'
    | 'Partner'
    | 'PartnerOffer'
    | 'Product'
    | 'ProductActivity'
    | 'ProductActivityItem'
    | 'ProductAttractionLineup'
    | 'ProductReservation'
    | 'Promo'
    | 'ReservationText'
    | 'Sandbox'
    | 'ScheduledOperation'
    | 'ScheduledRelease'
    | 'Seo'
    | 'Text'
    | 'TextGroup'
    | 'TextList'
    | 'TicketGroup'
    | 'TitleDescription'
    | 'User'
    | 'Video',
    ParentType,
    ContextType
  >;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  stage?: Resolver<ResolversTypes['Stage'], ParentType, ContextType>;
};

export type NoticeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Notice'] = ResolversParentTypes['Notice']
> = {
  attractions?: Resolver<
    Array<ResolversTypes['Attraction']>,
    ParentType,
    ContextType,
    Partial<NoticeAttractionsArgs>
  >;
  createdAt?: Resolver<
    ResolversTypes['DateTime'],
    ParentType,
    ContextType,
    RequireFields<NoticeCreatedAtArgs, 'variation'>
  >;
  createdBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<NoticeCreatedByArgs>
  >;
  defaultOpen?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  documentInStages?: Resolver<
    Array<ResolversTypes['Notice']>,
    ParentType,
    ContextType,
    RequireFields<NoticeDocumentInStagesArgs, 'includeCurrent' | 'inheritLocale' | 'stages'>
  >;
  endDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  history?: Resolver<
    Array<ResolversTypes['Version']>,
    ParentType,
    ContextType,
    RequireFields<NoticeHistoryArgs, 'limit' | 'skip'>
  >;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  locale?: Resolver<ResolversTypes['Locale'], ParentType, ContextType>;
  localizations?: Resolver<
    Array<ResolversTypes['Notice']>,
    ParentType,
    ContextType,
    RequireFields<NoticeLocalizationsArgs, 'includeCurrent' | 'locales'>
  >;
  placements?: Resolver<Array<ResolversTypes['NoticePlacements']>, ParentType, ContextType>;
  platforms?: Resolver<Array<ResolversTypes['Platforms']>, ParentType, ContextType>;
  products?: Resolver<
    Array<ResolversTypes['Product']>,
    ParentType,
    ContextType,
    Partial<NoticeProductsArgs>
  >;
  publishedAt?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType,
    RequireFields<NoticePublishedAtArgs, 'variation'>
  >;
  publishedBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<NoticePublishedByArgs>
  >;
  qabu?: Resolver<Maybe<ResolversTypes['Json']>, ParentType, ContextType>;
  scheduledIn?: Resolver<
    Array<ResolversTypes['ScheduledOperation']>,
    ParentType,
    ContextType,
    Partial<NoticeScheduledInArgs>
  >;
  stage?: Resolver<ResolversTypes['Stage'], ParentType, ContextType>;
  startDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  theme?: Resolver<ResolversTypes['NoticeTheme'], ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<
    ResolversTypes['DateTime'],
    ParentType,
    ContextType,
    RequireFields<NoticeUpdatedAtArgs, 'variation'>
  >;
  updatedBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<NoticeUpdatedByArgs>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NoticeConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NoticeConnection'] = ResolversParentTypes['NoticeConnection']
> = {
  aggregate?: Resolver<ResolversTypes['Aggregate'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['NoticeEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NoticeEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NoticeEdge'] = ResolversParentTypes['NoticeEdge']
> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['Notice'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PageResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Page'] = ResolversParentTypes['Page']
> = {
  body?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<
    ResolversTypes['DateTime'],
    ParentType,
    ContextType,
    RequireFields<PageCreatedAtArgs, 'variation'>
  >;
  createdBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<PageCreatedByArgs>
  >;
  documentInStages?: Resolver<
    Array<ResolversTypes['Page']>,
    ParentType,
    ContextType,
    RequireFields<PageDocumentInStagesArgs, 'includeCurrent' | 'inheritLocale' | 'stages'>
  >;
  footer?: Resolver<
    Maybe<ResolversTypes['Menu']>,
    ParentType,
    ContextType,
    Partial<PageFooterArgs>
  >;
  header?: Resolver<
    Maybe<ResolversTypes['Menu']>,
    ParentType,
    ContextType,
    Partial<PageHeaderArgs>
  >;
  hero?: Resolver<Maybe<ResolversTypes['Asset']>, ParentType, ContextType, Partial<PageHeroArgs>>;
  history?: Resolver<
    Array<ResolversTypes['Version']>,
    ParentType,
    ContextType,
    RequireFields<PageHistoryArgs, 'limit' | 'skip'>
  >;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  internalTitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isTemplate?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  locale?: Resolver<ResolversTypes['Locale'], ParentType, ContextType>;
  localizations?: Resolver<
    Array<ResolversTypes['Page']>,
    ParentType,
    ContextType,
    RequireFields<PageLocalizationsArgs, 'includeCurrent' | 'locales'>
  >;
  menus?: Resolver<Array<ResolversTypes['Menu']>, ParentType, ContextType, Partial<PageMenusArgs>>;
  publishedAt?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType,
    RequireFields<PagePublishedAtArgs, 'variation'>
  >;
  publishedBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<PagePublishedByArgs>
  >;
  qabu?: Resolver<Maybe<ResolversTypes['Json']>, ParentType, ContextType>;
  scheduledIn?: Resolver<
    Array<ResolversTypes['ScheduledOperation']>,
    ParentType,
    ContextType,
    Partial<PageScheduledInArgs>
  >;
  seo?: Resolver<Maybe<ResolversTypes['Seo']>, ParentType, ContextType, Partial<PageSeoArgs>>;
  siteSection?: Resolver<ResolversTypes['SiteSection'], ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  stage?: Resolver<ResolversTypes['Stage'], ParentType, ContextType>;
  textGroups?: Resolver<
    Array<ResolversTypes['TextGroup']>,
    ParentType,
    ContextType,
    Partial<PageTextGroupsArgs>
  >;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['PageType'], ParentType, ContextType>;
  updatedAt?: Resolver<
    ResolversTypes['DateTime'],
    ParentType,
    ContextType,
    RequireFields<PageUpdatedAtArgs, 'variation'>
  >;
  updatedBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<PageUpdatedByArgs>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PageConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PageConnection'] = ResolversParentTypes['PageConnection']
> = {
  aggregate?: Resolver<ResolversTypes['Aggregate'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['PageEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PageEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PageEdge'] = ResolversParentTypes['PageEdge']
> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['Page'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PageInfoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PageInfo'] = ResolversParentTypes['PageInfo']
> = {
  endCursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hasNextPage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hasPreviousPage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  pageSize?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  startCursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PageTemplateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PageTemplate'] = ResolversParentTypes['PageTemplate']
> = {
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<PageTemplateCreatedByArgs>
  >;
  documentInStages?: Resolver<
    Array<ResolversTypes['PageTemplate']>,
    ParentType,
    ContextType,
    RequireFields<PageTemplateDocumentInStagesArgs, 'includeCurrent' | 'inheritLocale' | 'stages'>
  >;
  header?: Resolver<
    Maybe<ResolversTypes['Menu']>,
    ParentType,
    ContextType,
    Partial<PageTemplateHeaderArgs>
  >;
  hero?: Resolver<
    Maybe<ResolversTypes['Asset']>,
    ParentType,
    ContextType,
    Partial<PageTemplateHeroArgs>
  >;
  history?: Resolver<
    Array<ResolversTypes['Version']>,
    ParentType,
    ContextType,
    RequireFields<PageTemplateHistoryArgs, 'limit' | 'skip'>
  >;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  internalTitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  pageType?: Resolver<ResolversTypes['PageType'], ParentType, ContextType>;
  publishedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  publishedBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<PageTemplatePublishedByArgs>
  >;
  scheduledIn?: Resolver<
    Array<ResolversTypes['ScheduledOperation']>,
    ParentType,
    ContextType,
    Partial<PageTemplateScheduledInArgs>
  >;
  seo?: Resolver<
    Maybe<ResolversTypes['Seo']>,
    ParentType,
    ContextType,
    Partial<PageTemplateSeoArgs>
  >;
  stage?: Resolver<ResolversTypes['Stage'], ParentType, ContextType>;
  textGroups?: Resolver<
    Array<ResolversTypes['TextGroup']>,
    ParentType,
    ContextType,
    Partial<PageTemplateTextGroupsArgs>
  >;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updatedBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<PageTemplateUpdatedByArgs>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PageTemplateConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PageTemplateConnection'] = ResolversParentTypes['PageTemplateConnection']
> = {
  aggregate?: Resolver<ResolversTypes['Aggregate'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['PageTemplateEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PageTemplateEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PageTemplateEdge'] = ResolversParentTypes['PageTemplateEdge']
> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['PageTemplate'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PartnerResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Partner'] = ResolversParentTypes['Partner']
> = {
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<PartnerCreatedByArgs>
  >;
  documentInStages?: Resolver<
    Array<ResolversTypes['Partner']>,
    ParentType,
    ContextType,
    RequireFields<PartnerDocumentInStagesArgs, 'includeCurrent' | 'inheritLocale' | 'stages'>
  >;
  excludedProducts?: Resolver<
    Array<ResolversTypes['Product']>,
    ParentType,
    ContextType,
    Partial<PartnerExcludedProductsArgs>
  >;
  history?: Resolver<
    Array<ResolversTypes['Version']>,
    ParentType,
    ContextType,
    RequireFields<PartnerHistoryArgs, 'limit' | 'skip'>
  >;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  publishedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  publishedBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<PartnerPublishedByArgs>
  >;
  scheduledIn?: Resolver<
    Array<ResolversTypes['ScheduledOperation']>,
    ParentType,
    ContextType,
    Partial<PartnerScheduledInArgs>
  >;
  stage?: Resolver<ResolversTypes['Stage'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['PartnerType'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updatedBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<PartnerUpdatedByArgs>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PartnerConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PartnerConnection'] = ResolversParentTypes['PartnerConnection']
> = {
  aggregate?: Resolver<ResolversTypes['Aggregate'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['PartnerEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PartnerEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PartnerEdge'] = ResolversParentTypes['PartnerEdge']
> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['Partner'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PartnerOfferResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PartnerOffer'] = ResolversParentTypes['PartnerOffer']
> = {
  attractions?: Resolver<
    Array<ResolversTypes['Attraction']>,
    ParentType,
    ContextType,
    Partial<PartnerOfferAttractionsArgs>
  >;
  campaignId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<
    ResolversTypes['DateTime'],
    ParentType,
    ContextType,
    RequireFields<PartnerOfferCreatedAtArgs, 'variation'>
  >;
  createdBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<PartnerOfferCreatedByArgs>
  >;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  documentInStages?: Resolver<
    Array<ResolversTypes['PartnerOffer']>,
    ParentType,
    ContextType,
    RequireFields<PartnerOfferDocumentInStagesArgs, 'includeCurrent' | 'inheritLocale' | 'stages'>
  >;
  endDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  history?: Resolver<
    Array<ResolversTypes['Version']>,
    ParentType,
    ContextType,
    RequireFields<PartnerOfferHistoryArgs, 'limit' | 'skip'>
  >;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  image?: Resolver<
    ResolversTypes['Asset'],
    ParentType,
    ContextType,
    Partial<PartnerOfferImageArgs>
  >;
  locale?: Resolver<ResolversTypes['Locale'], ParentType, ContextType>;
  localizations?: Resolver<
    Array<ResolversTypes['PartnerOffer']>,
    ParentType,
    ContextType,
    RequireFields<PartnerOfferLocalizationsArgs, 'includeCurrent' | 'locales'>
  >;
  platforms?: Resolver<Array<ResolversTypes['Platforms']>, ParentType, ContextType>;
  products?: Resolver<
    Array<ResolversTypes['Product']>,
    ParentType,
    ContextType,
    Partial<PartnerOfferProductsArgs>
  >;
  publishedAt?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType,
    RequireFields<PartnerOfferPublishedAtArgs, 'variation'>
  >;
  publishedBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<PartnerOfferPublishedByArgs>
  >;
  qabu?: Resolver<Maybe<ResolversTypes['Json']>, ParentType, ContextType>;
  scheduledIn?: Resolver<
    Array<ResolversTypes['ScheduledOperation']>,
    ParentType,
    ContextType,
    Partial<PartnerOfferScheduledInArgs>
  >;
  stage?: Resolver<ResolversTypes['Stage'], ParentType, ContextType>;
  startDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<
    ResolversTypes['DateTime'],
    ParentType,
    ContextType,
    RequireFields<PartnerOfferUpdatedAtArgs, 'variation'>
  >;
  updatedBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<PartnerOfferUpdatedByArgs>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PartnerOfferConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PartnerOfferConnection'] = ResolversParentTypes['PartnerOfferConnection']
> = {
  aggregate?: Resolver<ResolversTypes['Aggregate'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['PartnerOfferEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PartnerOfferEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PartnerOfferEdge'] = ResolversParentTypes['PartnerOfferEdge']
> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['PartnerOffer'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProductResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Product'] = ResolversParentTypes['Product']
> = {
  addOns?: Resolver<
    Array<ResolversTypes['AddOn']>,
    ParentType,
    ContextType,
    Partial<ProductAddOnsArgs>
  >;
  addonAttractions?: Resolver<
    Array<ResolversTypes['Attraction']>,
    ParentType,
    ContextType,
    Partial<ProductAddonAttractionsArgs>
  >;
  admissionTitle?: Resolver<
    Maybe<ResolversTypes['Text']>,
    ParentType,
    ContextType,
    Partial<ProductAdmissionTitleArgs>
  >;
  ageAdult?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ageChild?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  attractionLineups?: Resolver<
    Array<ResolversTypes['ProductAttractionLineup']>,
    ParentType,
    ContextType,
    Partial<ProductAttractionLineupsArgs>
  >;
  attractions?: Resolver<
    Array<ResolversTypes['Attraction']>,
    ParentType,
    ContextType,
    Partial<ProductAttractionsArgs>
  >;
  bookingComId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cityName?: Resolver<
    Maybe<ResolversTypes['Text']>,
    ParentType,
    ContextType,
    Partial<ProductCityNameArgs>
  >;
  createdAt?: Resolver<
    ResolversTypes['DateTime'],
    ParentType,
    ContextType,
    RequireFields<ProductCreatedAtArgs, 'variation'>
  >;
  createdBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<ProductCreatedByArgs>
  >;
  crossPromoImage?: Resolver<
    Maybe<ResolversTypes['Asset']>,
    ParentType,
    ContextType,
    Partial<ProductCrossPromoImageArgs>
  >;
  crowdriffAlbumId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<
    Maybe<ResolversTypes['Text']>,
    ParentType,
    ContextType,
    Partial<ProductDescriptionArgs>
  >;
  documentInStages?: Resolver<
    Array<ResolversTypes['Product']>,
    ParentType,
    ContextType,
    RequireFields<ProductDocumentInStagesArgs, 'includeCurrent' | 'inheritLocale' | 'stages'>
  >;
  excludeDiscounts?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  exclusiveAttraction?: Resolver<
    Array<ResolversTypes['Attraction']>,
    ParentType,
    ContextType,
    Partial<ProductExclusiveAttractionArgs>
  >;
  exlcudedPartners?: Resolver<
    Array<ResolversTypes['Partner']>,
    ParentType,
    ContextType,
    Partial<ProductExlcudedPartnersArgs>
  >;
  geolocation?: Resolver<Maybe<ResolversTypes['Location']>, ParentType, ContextType>;
  hasThingsToDo?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hasTopMuseums?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  headline?: Resolver<
    Maybe<ResolversTypes['Text']>,
    ParentType,
    ContextType,
    Partial<ProductHeadlineArgs>
  >;
  hero?: Resolver<
    Maybe<ResolversTypes['Asset']>,
    ParentType,
    ContextType,
    Partial<ProductHeroArgs>
  >;
  hideReviews?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hideVideo?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  history?: Resolver<
    Array<ResolversTypes['Version']>,
    ParentType,
    ContextType,
    RequireFields<ProductHistoryArgs, 'limit' | 'skip'>
  >;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  interchangeId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  interfaceKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  key?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  locale?: Resolver<ResolversTypes['Locale'], ParentType, ContextType>;
  localizations?: Resolver<
    Array<ResolversTypes['Product']>,
    ParentType,
    ContextType,
    RequireFields<ProductLocalizationsArgs, 'includeCurrent' | 'locales'>
  >;
  logo?: Resolver<
    Maybe<ResolversTypes['Asset']>,
    ParentType,
    ContextType,
    Partial<ProductLogoArgs>
  >;
  logoSpot?: Resolver<
    Maybe<ResolversTypes['Asset']>,
    ParentType,
    ContextType,
    Partial<ProductLogoSpotArgs>
  >;
  logoSpotOutline?: Resolver<
    Maybe<ResolversTypes['Asset']>,
    ParentType,
    ContextType,
    Partial<ProductLogoSpotOutlineArgs>
  >;
  logoTicket?: Resolver<
    Maybe<ResolversTypes['Asset']>,
    ParentType,
    ContextType,
    Partial<ProductLogoTicketArgs>
  >;
  logoWhite?: Resolver<
    Maybe<ResolversTypes['Asset']>,
    ParentType,
    ContextType,
    Partial<ProductLogoWhiteArgs>
  >;
  mapImage?: Resolver<
    Maybe<ResolversTypes['Asset']>,
    ParentType,
    ContextType,
    Partial<ProductMapImageArgs>
  >;
  menus?: Resolver<
    Array<ResolversTypes['Menu']>,
    ParentType,
    ContextType,
    Partial<ProductMenusArgs>
  >;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  nativeCurrency?: Resolver<Maybe<ResolversTypes['Currency']>, ParentType, ContextType>;
  notices?: Resolver<
    Array<ResolversTypes['Notice']>,
    ParentType,
    ContextType,
    Partial<ProductNoticesArgs>
  >;
  partnerOffers?: Resolver<
    Array<ResolversTypes['PartnerOffer']>,
    ParentType,
    ContextType,
    Partial<ProductPartnerOffersArgs>
  >;
  planning?: Resolver<
    Maybe<ResolversTypes['DetailBlock']>,
    ParentType,
    ContextType,
    Partial<ProductPlanningArgs>
  >;
  productActivityItem?: Resolver<
    Array<ResolversTypes['ProductActivityItem']>,
    ParentType,
    ContextType,
    Partial<ProductProductActivityItemArgs>
  >;
  productImage?: Resolver<
    Maybe<ResolversTypes['Asset']>,
    ParentType,
    ContextType,
    Partial<ProductProductImageArgs>
  >;
  promos?: Resolver<
    Array<ResolversTypes['Promo']>,
    ParentType,
    ContextType,
    Partial<ProductPromosArgs>
  >;
  publishedAt?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType,
    RequireFields<ProductPublishedAtArgs, 'variation'>
  >;
  publishedBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<ProductPublishedByArgs>
  >;
  qabu?: Resolver<Maybe<ResolversTypes['Json']>, ParentType, ContextType>;
  relatedProducts?: Resolver<
    Array<ResolversTypes['Product']>,
    ParentType,
    ContextType,
    Partial<ProductRelatedProductsArgs>
  >;
  reservation?: Resolver<
    Maybe<ResolversTypes['ProductReservation']>,
    ParentType,
    ContextType,
    Partial<ProductReservationArgs>
  >;
  reservationsRequired?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  scheduledIn?: Resolver<
    Array<ResolversTypes['ScheduledOperation']>,
    ParentType,
    ContextType,
    Partial<ProductScheduledInArgs>
  >;
  seo?: Resolver<Maybe<ResolversTypes['Seo']>, ParentType, ContextType, Partial<ProductSeoArgs>>;
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  stage?: Resolver<ResolversTypes['Stage'], ParentType, ContextType>;
  tagline?: Resolver<
    Maybe<ResolversTypes['Text']>,
    ParentType,
    ContextType,
    Partial<ProductTaglineArgs>
  >;
  text?: Resolver<Array<ResolversTypes['Text']>, ParentType, ContextType, Partial<ProductTextArgs>>;
  thingsToDo?: Resolver<
    Maybe<ResolversTypes['ProductActivity']>,
    ParentType,
    ContextType,
    Partial<ProductThingsToDoArgs>
  >;
  thumb?: Resolver<
    Maybe<ResolversTypes['Asset']>,
    ParentType,
    ContextType,
    Partial<ProductThumbArgs>
  >;
  ticketGroups?: Resolver<
    Array<ResolversTypes['TicketGroup']>,
    ParentType,
    ContextType,
    Partial<ProductTicketGroupsArgs>
  >;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  topMuseums?: Resolver<
    Maybe<ResolversTypes['ProductActivity']>,
    ParentType,
    ContextType,
    Partial<ProductTopMuseumsArgs>
  >;
  type?: Resolver<Maybe<ResolversTypes['ProductType']>, ParentType, ContextType>;
  updatedAt?: Resolver<
    ResolversTypes['DateTime'],
    ParentType,
    ContextType,
    RequireFields<ProductUpdatedAtArgs, 'variation'>
  >;
  updatedBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<ProductUpdatedByArgs>
  >;
  validDays?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  video?: Resolver<
    Maybe<ResolversTypes['Video']>,
    ParentType,
    ContextType,
    Partial<ProductVideoArgs>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProductActivityResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProductActivity'] = ResolversParentTypes['ProductActivity']
> = {
  createdAt?: Resolver<
    ResolversTypes['DateTime'],
    ParentType,
    ContextType,
    RequireFields<ProductActivityCreatedAtArgs, 'variation'>
  >;
  createdBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<ProductActivityCreatedByArgs>
  >;
  documentInStages?: Resolver<
    Array<ResolversTypes['ProductActivity']>,
    ParentType,
    ContextType,
    RequireFields<
      ProductActivityDocumentInStagesArgs,
      'includeCurrent' | 'inheritLocale' | 'stages'
    >
  >;
  history?: Resolver<
    Array<ResolversTypes['Version']>,
    ParentType,
    ContextType,
    RequireFields<ProductActivityHistoryArgs, 'limit' | 'skip'>
  >;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  intro?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  items?: Resolver<
    Array<ResolversTypes['ProductActivityItem']>,
    ParentType,
    ContextType,
    Partial<ProductActivityItemsArgs>
  >;
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  locale?: Resolver<ResolversTypes['Locale'], ParentType, ContextType>;
  localizations?: Resolver<
    Array<ResolversTypes['ProductActivity']>,
    ParentType,
    ContextType,
    RequireFields<ProductActivityLocalizationsArgs, 'includeCurrent' | 'locales'>
  >;
  outro?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  publishedAt?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType,
    RequireFields<ProductActivityPublishedAtArgs, 'variation'>
  >;
  publishedBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<ProductActivityPublishedByArgs>
  >;
  qabu?: Resolver<Maybe<ResolversTypes['Json']>, ParentType, ContextType>;
  scheduledIn?: Resolver<
    Array<ResolversTypes['ScheduledOperation']>,
    ParentType,
    ContextType,
    Partial<ProductActivityScheduledInArgs>
  >;
  stage?: Resolver<ResolversTypes['Stage'], ParentType, ContextType>;
  thingsToDo?: Resolver<
    Maybe<ResolversTypes['Product']>,
    ParentType,
    ContextType,
    Partial<ProductActivityThingsToDoArgs>
  >;
  title?: Resolver<
    Maybe<ResolversTypes['Text']>,
    ParentType,
    ContextType,
    Partial<ProductActivityTitleArgs>
  >;
  topMuseums?: Resolver<
    Maybe<ResolversTypes['Product']>,
    ParentType,
    ContextType,
    Partial<ProductActivityTopMuseumsArgs>
  >;
  updatedAt?: Resolver<
    ResolversTypes['DateTime'],
    ParentType,
    ContextType,
    RequireFields<ProductActivityUpdatedAtArgs, 'variation'>
  >;
  updatedBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<ProductActivityUpdatedByArgs>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProductActivityConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProductActivityConnection'] = ResolversParentTypes['ProductActivityConnection']
> = {
  aggregate?: Resolver<ResolversTypes['Aggregate'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['ProductActivityEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProductActivityEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProductActivityEdge'] = ResolversParentTypes['ProductActivityEdge']
> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['ProductActivity'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProductActivityItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProductActivityItem'] = ResolversParentTypes['ProductActivityItem']
> = {
  createdAt?: Resolver<
    ResolversTypes['DateTime'],
    ParentType,
    ContextType,
    RequireFields<ProductActivityItemCreatedAtArgs, 'variation'>
  >;
  createdBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<ProductActivityItemCreatedByArgs>
  >;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  documentInStages?: Resolver<
    Array<ResolversTypes['ProductActivityItem']>,
    ParentType,
    ContextType,
    RequireFields<
      ProductActivityItemDocumentInStagesArgs,
      'includeCurrent' | 'inheritLocale' | 'stages'
    >
  >;
  history?: Resolver<
    Array<ResolversTypes['Version']>,
    ParentType,
    ContextType,
    RequireFields<ProductActivityItemHistoryArgs, 'limit' | 'skip'>
  >;
  hours?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  image?: Resolver<
    ResolversTypes['Asset'],
    ParentType,
    ContextType,
    Partial<ProductActivityItemImageArgs>
  >;
  intro?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  locale?: Resolver<ResolversTypes['Locale'], ParentType, ContextType>;
  localizations?: Resolver<
    Array<ResolversTypes['ProductActivityItem']>,
    ParentType,
    ContextType,
    RequireFields<ProductActivityItemLocalizationsArgs, 'includeCurrent' | 'locales'>
  >;
  productActivity?: Resolver<
    Array<ResolversTypes['ProductActivity']>,
    ParentType,
    ContextType,
    Partial<ProductActivityItemProductActivityArgs>
  >;
  products?: Resolver<
    Array<ResolversTypes['Product']>,
    ParentType,
    ContextType,
    Partial<ProductActivityItemProductsArgs>
  >;
  publishedAt?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType,
    RequireFields<ProductActivityItemPublishedAtArgs, 'variation'>
  >;
  publishedBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<ProductActivityItemPublishedByArgs>
  >;
  qabu?: Resolver<Maybe<ResolversTypes['Json']>, ParentType, ContextType>;
  scheduledIn?: Resolver<
    Array<ResolversTypes['ScheduledOperation']>,
    ParentType,
    ContextType,
    Partial<ProductActivityItemScheduledInArgs>
  >;
  stage?: Resolver<ResolversTypes['Stage'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<
    ResolversTypes['DateTime'],
    ParentType,
    ContextType,
    RequireFields<ProductActivityItemUpdatedAtArgs, 'variation'>
  >;
  updatedBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<ProductActivityItemUpdatedByArgs>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProductActivityItemConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProductActivityItemConnection'] = ResolversParentTypes['ProductActivityItemConnection']
> = {
  aggregate?: Resolver<ResolversTypes['Aggregate'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['ProductActivityItemEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProductActivityItemEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProductActivityItemEdge'] = ResolversParentTypes['ProductActivityItemEdge']
> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['ProductActivityItem'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProductAttractionLineupResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProductAttractionLineup'] = ResolversParentTypes['ProductAttractionLineup']
> = {
  attractionGroups?: Resolver<
    Array<ResolversTypes['AttractionGroup']>,
    ParentType,
    ContextType,
    Partial<ProductAttractionLineupAttractionGroupsArgs>
  >;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<ProductAttractionLineupCreatedByArgs>
  >;
  documentInStages?: Resolver<
    Array<ResolversTypes['ProductAttractionLineup']>,
    ParentType,
    ContextType,
    RequireFields<
      ProductAttractionLineupDocumentInStagesArgs,
      'includeCurrent' | 'inheritLocale' | 'stages'
    >
  >;
  history?: Resolver<
    Array<ResolversTypes['Version']>,
    ParentType,
    ContextType,
    RequireFields<ProductAttractionLineupHistoryArgs, 'limit' | 'skip'>
  >;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  internalTitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  product?: Resolver<
    Maybe<ResolversTypes['Product']>,
    ParentType,
    ContextType,
    Partial<ProductAttractionLineupProductArgs>
  >;
  publishedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  publishedBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<ProductAttractionLineupPublishedByArgs>
  >;
  scheduledIn?: Resolver<
    Array<ResolversTypes['ScheduledOperation']>,
    ParentType,
    ContextType,
    Partial<ProductAttractionLineupScheduledInArgs>
  >;
  shortDescription?: Resolver<
    Maybe<ResolversTypes['Text']>,
    ParentType,
    ContextType,
    Partial<ProductAttractionLineupShortDescriptionArgs>
  >;
  stage?: Resolver<ResolversTypes['Stage'], ParentType, ContextType>;
  startDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  subtitle?: Resolver<
    Maybe<ResolversTypes['Text']>,
    ParentType,
    ContextType,
    Partial<ProductAttractionLineupSubtitleArgs>
  >;
  ticketGroup?: Resolver<
    Maybe<ResolversTypes['TicketGroup']>,
    ParentType,
    ContextType,
    Partial<ProductAttractionLineupTicketGroupArgs>
  >;
  title?: Resolver<
    Maybe<ResolversTypes['Text']>,
    ParentType,
    ContextType,
    Partial<ProductAttractionLineupTitleArgs>
  >;
  type?: Resolver<Maybe<ResolversTypes['ProductType']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updatedBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<ProductAttractionLineupUpdatedByArgs>
  >;
  year?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProductAttractionLineupConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProductAttractionLineupConnection'] = ResolversParentTypes['ProductAttractionLineupConnection']
> = {
  aggregate?: Resolver<ResolversTypes['Aggregate'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['ProductAttractionLineupEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProductAttractionLineupEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProductAttractionLineupEdge'] = ResolversParentTypes['ProductAttractionLineupEdge']
> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['ProductAttractionLineup'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProductConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProductConnection'] = ResolversParentTypes['ProductConnection']
> = {
  aggregate?: Resolver<ResolversTypes['Aggregate'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['ProductEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProductEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProductEdge'] = ResolversParentTypes['ProductEdge']
> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['Product'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProductReservationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProductReservation'] = ResolversParentTypes['ProductReservation']
> = {
  attractionSorting?: Resolver<
    Array<ResolversTypes['Attraction']>,
    ParentType,
    ContextType,
    Partial<ProductReservationAttractionSortingArgs>
  >;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<ProductReservationCreatedByArgs>
  >;
  documentInStages?: Resolver<
    Array<ResolversTypes['ProductReservation']>,
    ParentType,
    ContextType,
    RequireFields<
      ProductReservationDocumentInStagesArgs,
      'includeCurrent' | 'inheritLocale' | 'stages'
    >
  >;
  history?: Resolver<
    Array<ResolversTypes['Version']>,
    ParentType,
    ContextType,
    RequireFields<ProductReservationHistoryArgs, 'limit' | 'skip'>
  >;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  internalTitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  product?: Resolver<
    Maybe<ResolversTypes['Product']>,
    ParentType,
    ContextType,
    Partial<ProductReservationProductArgs>
  >;
  productCodes?: Resolver<Maybe<ResolversTypes['Json']>, ParentType, ContextType>;
  publishedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  publishedBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<ProductReservationPublishedByArgs>
  >;
  scheduledIn?: Resolver<
    Array<ResolversTypes['ScheduledOperation']>,
    ParentType,
    ContextType,
    Partial<ProductReservationScheduledInArgs>
  >;
  showReservationHelp?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  sorting?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  stage?: Resolver<ResolversTypes['Stage'], ParentType, ContextType>;
  text?: Resolver<
    Array<ResolversTypes['ReservationText']>,
    ParentType,
    ContextType,
    Partial<ProductReservationTextArgs>
  >;
  texts?: Resolver<
    Array<ResolversTypes['Text']>,
    ParentType,
    ContextType,
    Partial<ProductReservationTextsArgs>
  >;
  ticketsBackground?: Resolver<
    Maybe<ResolversTypes['Asset']>,
    ParentType,
    ContextType,
    Partial<ProductReservationTicketsBackgroundArgs>
  >;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updatedBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<ProductReservationUpdatedByArgs>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProductReservationConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProductReservationConnection'] = ResolversParentTypes['ProductReservationConnection']
> = {
  aggregate?: Resolver<ResolversTypes['Aggregate'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['ProductReservationEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProductReservationEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProductReservationEdge'] = ResolversParentTypes['ProductReservationEdge']
> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['ProductReservation'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PromoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Promo'] = ResolversParentTypes['Promo']
> = {
  active?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  attraction?: Resolver<
    Maybe<ResolversTypes['Attraction']>,
    ParentType,
    ContextType,
    Partial<PromoAttractionArgs>
  >;
  createdAt?: Resolver<
    ResolversTypes['DateTime'],
    ParentType,
    ContextType,
    RequireFields<PromoCreatedAtArgs, 'variation'>
  >;
  createdBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<PromoCreatedByArgs>
  >;
  documentInStages?: Resolver<
    Array<ResolversTypes['Promo']>,
    ParentType,
    ContextType,
    RequireFields<PromoDocumentInStagesArgs, 'includeCurrent' | 'inheritLocale' | 'stages'>
  >;
  endDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  history?: Resolver<
    Array<ResolversTypes['Version']>,
    ParentType,
    ContextType,
    RequireFields<PromoHistoryArgs, 'limit' | 'skip'>
  >;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  internalTitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  locale?: Resolver<ResolversTypes['Locale'], ParentType, ContextType>;
  localizationFix?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  localizations?: Resolver<
    Array<ResolversTypes['Promo']>,
    ParentType,
    ContextType,
    RequireFields<PromoLocalizationsArgs, 'includeCurrent' | 'locales'>
  >;
  placements?: Resolver<Array<ResolversTypes['PromoPlacement']>, ParentType, ContextType>;
  product?: Resolver<
    Maybe<ResolversTypes['Product']>,
    ParentType,
    ContextType,
    Partial<PromoProductArgs>
  >;
  publishedAt?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType,
    RequireFields<PromoPublishedAtArgs, 'variation'>
  >;
  publishedBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<PromoPublishedByArgs>
  >;
  qabu?: Resolver<Maybe<ResolversTypes['Json']>, ParentType, ContextType>;
  scheduledIn?: Resolver<
    Array<ResolversTypes['ScheduledOperation']>,
    ParentType,
    ContextType,
    Partial<PromoScheduledInArgs>
  >;
  stage?: Resolver<ResolversTypes['Stage'], ParentType, ContextType>;
  startDate?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  template?: Resolver<
    Maybe<ResolversTypes['PromotemplateUnion']>,
    ParentType,
    ContextType,
    Partial<PromoTemplateArgs>
  >;
  updatedAt?: Resolver<
    ResolversTypes['DateTime'],
    ParentType,
    ContextType,
    RequireFields<PromoUpdatedAtArgs, 'variation'>
  >;
  updatedBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<PromoUpdatedByArgs>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PromoBannerResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PromoBanner'] = ResolversParentTypes['PromoBanner']
> = {
  copyright?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  image?: Resolver<
    Maybe<ResolversTypes['Asset']>,
    ParentType,
    ContextType,
    Partial<PromoBannerImageArgs>
  >;
  locale?: Resolver<ResolversTypes['Locale'], ParentType, ContextType>;
  localizations?: Resolver<
    Array<ResolversTypes['PromoBanner']>,
    ParentType,
    ContextType,
    RequireFields<PromoBannerLocalizationsArgs, 'includeCurrent' | 'locales'>
  >;
  logo?: Resolver<
    Maybe<ResolversTypes['Asset']>,
    ParentType,
    ContextType,
    Partial<PromoBannerLogoArgs>
  >;
  product?: Resolver<
    Maybe<ResolversTypes['Product']>,
    ParentType,
    ContextType,
    Partial<PromoBannerProductArgs>
  >;
  stage?: Resolver<ResolversTypes['Stage'], ParentType, ContextType>;
  subDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PromoBannerConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PromoBannerConnection'] = ResolversParentTypes['PromoBannerConnection']
> = {
  aggregate?: Resolver<ResolversTypes['Aggregate'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['PromoBannerEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PromoBannerEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PromoBannerEdge'] = ResolversParentTypes['PromoBannerEdge']
> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['PromoBanner'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PromoBannerParentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PromoBannerParent'] = ResolversParentTypes['PromoBannerParent']
> = {
  __resolveType: TypeResolveFn<'Promo', ParentType, ContextType>;
};

export type PromoConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PromoConnection'] = ResolversParentTypes['PromoConnection']
> = {
  aggregate?: Resolver<ResolversTypes['Aggregate'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['PromoEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PromoEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PromoEdge'] = ResolversParentTypes['PromoEdge']
> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['Promo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PromoTextResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PromoText'] = ResolversParentTypes['PromoText']
> = {
  badge?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  locale?: Resolver<ResolversTypes['Locale'], ParentType, ContextType>;
  localizations?: Resolver<
    Array<ResolversTypes['PromoText']>,
    ParentType,
    ContextType,
    RequireFields<PromoTextLocalizationsArgs, 'includeCurrent' | 'locales'>
  >;
  stage?: Resolver<ResolversTypes['Stage'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PromoTextConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PromoTextConnection'] = ResolversParentTypes['PromoTextConnection']
> = {
  aggregate?: Resolver<ResolversTypes['Aggregate'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['PromoTextEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PromoTextEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PromoTextEdge'] = ResolversParentTypes['PromoTextEdge']
> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['PromoText'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PromoTextParentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PromoTextParent'] = ResolversParentTypes['PromoTextParent']
> = {
  __resolveType: TypeResolveFn<'Promo', ParentType, ContextType>;
};

export type PromoTicketBannerResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PromoTicketBanner'] = ResolversParentTypes['PromoTicketBanner']
> = {
  badge?: Resolver<
    Maybe<ResolversTypes['Text']>,
    ParentType,
    ContextType,
    Partial<PromoTicketBannerBadgeArgs>
  >;
  button?: Resolver<
    Maybe<ResolversTypes['Text']>,
    ParentType,
    ContextType,
    Partial<PromoTicketBannerButtonArgs>
  >;
  clickHandler?: Resolver<Maybe<ResolversTypes['Json']>, ParentType, ContextType>;
  copyright?: Resolver<
    Maybe<ResolversTypes['Text']>,
    ParentType,
    ContextType,
    Partial<PromoTicketBannerCopyrightArgs>
  >;
  description?: Resolver<
    Maybe<ResolversTypes['Text']>,
    ParentType,
    ContextType,
    Partial<PromoTicketBannerDescriptionArgs>
  >;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  image?: Resolver<
    Maybe<ResolversTypes['Asset']>,
    ParentType,
    ContextType,
    Partial<PromoTicketBannerImageArgs>
  >;
  logo?: Resolver<
    Maybe<ResolversTypes['Asset']>,
    ParentType,
    ContextType,
    Partial<PromoTicketBannerLogoArgs>
  >;
  showBadge?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  stage?: Resolver<ResolversTypes['Stage'], ParentType, ContextType>;
  subDescription?: Resolver<
    Maybe<ResolversTypes['Text']>,
    ParentType,
    ContextType,
    Partial<PromoTicketBannerSubDescriptionArgs>
  >;
  title?: Resolver<
    Maybe<ResolversTypes['Text']>,
    ParentType,
    ContextType,
    Partial<PromoTicketBannerTitleArgs>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PromoTicketBannerConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PromoTicketBannerConnection'] = ResolversParentTypes['PromoTicketBannerConnection']
> = {
  aggregate?: Resolver<ResolversTypes['Aggregate'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['PromoTicketBannerEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PromoTicketBannerEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PromoTicketBannerEdge'] = ResolversParentTypes['PromoTicketBannerEdge']
> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['PromoTicketBanner'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PromoTicketBannerParentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PromoTicketBannerParent'] = ResolversParentTypes['PromoTicketBannerParent']
> = {
  __resolveType: TypeResolveFn<'Promo', ParentType, ContextType>;
};

export type PromoTicketTextResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PromoTicketText'] = ResolversParentTypes['PromoTicketText']
> = {
  badge?: Resolver<
    Maybe<ResolversTypes['Text']>,
    ParentType,
    ContextType,
    Partial<PromoTicketTextBadgeArgs>
  >;
  description?: Resolver<
    Maybe<ResolversTypes['Text']>,
    ParentType,
    ContextType,
    Partial<PromoTicketTextDescriptionArgs>
  >;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  skus?: Resolver<Maybe<ResolversTypes['Json']>, ParentType, ContextType>;
  stage?: Resolver<ResolversTypes['Stage'], ParentType, ContextType>;
  ticketTypes?: Resolver<Maybe<ResolversTypes['Json']>, ParentType, ContextType>;
  validity?: Resolver<
    Array<ResolversTypes['DateRange']>,
    ParentType,
    ContextType,
    Partial<PromoTicketTextValidityArgs>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PromoTicketTextConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PromoTicketTextConnection'] = ResolversParentTypes['PromoTicketTextConnection']
> = {
  aggregate?: Resolver<ResolversTypes['Aggregate'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['PromoTicketTextEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PromoTicketTextEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PromoTicketTextEdge'] = ResolversParentTypes['PromoTicketTextEdge']
> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['PromoTicketText'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PromoTicketTextParentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PromoTicketTextParent'] = ResolversParentTypes['PromoTicketTextParent']
> = {
  __resolveType: TypeResolveFn<'Promo', ParentType, ContextType>;
};

export type PromotemplateUnionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PromotemplateUnion'] = ResolversParentTypes['PromotemplateUnion']
> = {
  __resolveType: TypeResolveFn<
    'CrossPromoBanner' | 'PromoBanner' | 'PromoText' | 'PromoTicketBanner' | 'PromoTicketText',
    ParentType,
    ContextType
  >;
};

export type QueryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']
> = {
  addOn?: Resolver<
    Maybe<ResolversTypes['AddOn']>,
    ParentType,
    ContextType,
    RequireFields<QueryAddOnArgs, 'locales' | 'stage' | 'where'>
  >;
  addOnVersion?: Resolver<
    Maybe<ResolversTypes['DocumentVersion']>,
    ParentType,
    ContextType,
    RequireFields<QueryAddOnVersionArgs, 'where'>
  >;
  addOns?: Resolver<
    Array<ResolversTypes['AddOn']>,
    ParentType,
    ContextType,
    RequireFields<QueryAddOnsArgs, 'locales' | 'stage'>
  >;
  addOnsConnection?: Resolver<
    ResolversTypes['AddOnConnection'],
    ParentType,
    ContextType,
    RequireFields<QueryAddOnsConnectionArgs, 'locales' | 'stage'>
  >;
  alacarteTicket?: Resolver<
    Maybe<ResolversTypes['AlacarteTicket']>,
    ParentType,
    ContextType,
    RequireFields<QueryAlacarteTicketArgs, 'locales' | 'stage' | 'where'>
  >;
  alacarteTicketType?: Resolver<
    Maybe<ResolversTypes['AlacarteTicketType']>,
    ParentType,
    ContextType,
    RequireFields<QueryAlacarteTicketTypeArgs, 'locales' | 'stage' | 'where'>
  >;
  alacarteTicketTypeVersion?: Resolver<
    Maybe<ResolversTypes['DocumentVersion']>,
    ParentType,
    ContextType,
    RequireFields<QueryAlacarteTicketTypeVersionArgs, 'where'>
  >;
  alacarteTicketTypes?: Resolver<
    Array<ResolversTypes['AlacarteTicketType']>,
    ParentType,
    ContextType,
    RequireFields<QueryAlacarteTicketTypesArgs, 'locales' | 'stage'>
  >;
  alacarteTicketTypesConnection?: Resolver<
    ResolversTypes['AlacarteTicketTypeConnection'],
    ParentType,
    ContextType,
    RequireFields<QueryAlacarteTicketTypesConnectionArgs, 'locales' | 'stage'>
  >;
  alacarteTicketVersion?: Resolver<
    Maybe<ResolversTypes['DocumentVersion']>,
    ParentType,
    ContextType,
    RequireFields<QueryAlacarteTicketVersionArgs, 'where'>
  >;
  alacarteTickets?: Resolver<
    Array<ResolversTypes['AlacarteTicket']>,
    ParentType,
    ContextType,
    RequireFields<QueryAlacarteTicketsArgs, 'locales' | 'stage'>
  >;
  alacarteTicketsConnection?: Resolver<
    ResolversTypes['AlacarteTicketConnection'],
    ParentType,
    ContextType,
    RequireFields<QueryAlacarteTicketsConnectionArgs, 'locales' | 'stage'>
  >;
  article?: Resolver<
    Maybe<ResolversTypes['Article']>,
    ParentType,
    ContextType,
    RequireFields<QueryArticleArgs, 'locales' | 'stage' | 'where'>
  >;
  articleCategories?: Resolver<
    Array<ResolversTypes['ArticleCategory']>,
    ParentType,
    ContextType,
    RequireFields<QueryArticleCategoriesArgs, 'locales' | 'stage'>
  >;
  articleCategoriesConnection?: Resolver<
    ResolversTypes['ArticleCategoryConnection'],
    ParentType,
    ContextType,
    RequireFields<QueryArticleCategoriesConnectionArgs, 'locales' | 'stage'>
  >;
  articleCategory?: Resolver<
    Maybe<ResolversTypes['ArticleCategory']>,
    ParentType,
    ContextType,
    RequireFields<QueryArticleCategoryArgs, 'locales' | 'stage' | 'where'>
  >;
  articleCategoryVersion?: Resolver<
    Maybe<ResolversTypes['DocumentVersion']>,
    ParentType,
    ContextType,
    RequireFields<QueryArticleCategoryVersionArgs, 'where'>
  >;
  articleVersion?: Resolver<
    Maybe<ResolversTypes['DocumentVersion']>,
    ParentType,
    ContextType,
    RequireFields<QueryArticleVersionArgs, 'where'>
  >;
  articles?: Resolver<
    Array<ResolversTypes['Article']>,
    ParentType,
    ContextType,
    RequireFields<QueryArticlesArgs, 'locales' | 'stage'>
  >;
  articlesConnection?: Resolver<
    ResolversTypes['ArticleConnection'],
    ParentType,
    ContextType,
    RequireFields<QueryArticlesConnectionArgs, 'locales' | 'stage'>
  >;
  asset?: Resolver<
    Maybe<ResolversTypes['Asset']>,
    ParentType,
    ContextType,
    RequireFields<QueryAssetArgs, 'locales' | 'stage' | 'where'>
  >;
  assetVersion?: Resolver<
    Maybe<ResolversTypes['DocumentVersion']>,
    ParentType,
    ContextType,
    RequireFields<QueryAssetVersionArgs, 'where'>
  >;
  assets?: Resolver<
    Array<ResolversTypes['Asset']>,
    ParentType,
    ContextType,
    RequireFields<QueryAssetsArgs, 'locales' | 'stage'>
  >;
  assetsConnection?: Resolver<
    ResolversTypes['AssetConnection'],
    ParentType,
    ContextType,
    RequireFields<QueryAssetsConnectionArgs, 'locales' | 'stage'>
  >;
  attraction?: Resolver<
    Maybe<ResolversTypes['Attraction']>,
    ParentType,
    ContextType,
    RequireFields<QueryAttractionArgs, 'locales' | 'stage' | 'where'>
  >;
  attractionAlacarte?: Resolver<
    Maybe<ResolversTypes['AttractionAlacarte']>,
    ParentType,
    ContextType,
    RequireFields<QueryAttractionAlacarteArgs, 'locales' | 'stage' | 'where'>
  >;
  attractionAlacarteVersion?: Resolver<
    Maybe<ResolversTypes['DocumentVersion']>,
    ParentType,
    ContextType,
    RequireFields<QueryAttractionAlacarteVersionArgs, 'where'>
  >;
  attractionAlacartes?: Resolver<
    Array<ResolversTypes['AttractionAlacarte']>,
    ParentType,
    ContextType,
    RequireFields<QueryAttractionAlacartesArgs, 'locales' | 'stage'>
  >;
  attractionAlacartesConnection?: Resolver<
    ResolversTypes['AttractionAlacarteConnection'],
    ParentType,
    ContextType,
    RequireFields<QueryAttractionAlacartesConnectionArgs, 'locales' | 'stage'>
  >;
  attractionAvailabilities?: Resolver<
    Array<ResolversTypes['AttractionAvailability']>,
    ParentType,
    ContextType,
    RequireFields<QueryAttractionAvailabilitiesArgs, 'locales' | 'stage'>
  >;
  attractionAvailabilitiesConnection?: Resolver<
    ResolversTypes['AttractionAvailabilityConnection'],
    ParentType,
    ContextType,
    RequireFields<QueryAttractionAvailabilitiesConnectionArgs, 'locales' | 'stage'>
  >;
  attractionAvailability?: Resolver<
    Maybe<ResolversTypes['AttractionAvailability']>,
    ParentType,
    ContextType,
    RequireFields<QueryAttractionAvailabilityArgs, 'locales' | 'stage' | 'where'>
  >;
  attractionAvailabilityVersion?: Resolver<
    Maybe<ResolversTypes['DocumentVersion']>,
    ParentType,
    ContextType,
    RequireFields<QueryAttractionAvailabilityVersionArgs, 'where'>
  >;
  attractionLocation?: Resolver<
    Maybe<ResolversTypes['AttractionLocation']>,
    ParentType,
    ContextType,
    RequireFields<QueryAttractionLocationArgs, 'locales' | 'stage' | 'where'>
  >;
  attractionLocationVersion?: Resolver<
    Maybe<ResolversTypes['DocumentVersion']>,
    ParentType,
    ContextType,
    RequireFields<QueryAttractionLocationVersionArgs, 'where'>
  >;
  attractionLocations?: Resolver<
    Array<ResolversTypes['AttractionLocation']>,
    ParentType,
    ContextType,
    RequireFields<QueryAttractionLocationsArgs, 'locales' | 'stage'>
  >;
  attractionLocationsConnection?: Resolver<
    ResolversTypes['AttractionLocationConnection'],
    ParentType,
    ContextType,
    RequireFields<QueryAttractionLocationsConnectionArgs, 'locales' | 'stage'>
  >;
  attractionOffer?: Resolver<
    Maybe<ResolversTypes['AttractionOffer']>,
    ParentType,
    ContextType,
    RequireFields<QueryAttractionOfferArgs, 'locales' | 'stage' | 'where'>
  >;
  attractionOfferVersion?: Resolver<
    Maybe<ResolversTypes['DocumentVersion']>,
    ParentType,
    ContextType,
    RequireFields<QueryAttractionOfferVersionArgs, 'where'>
  >;
  attractionOffers?: Resolver<
    Array<ResolversTypes['AttractionOffer']>,
    ParentType,
    ContextType,
    RequireFields<QueryAttractionOffersArgs, 'locales' | 'stage'>
  >;
  attractionOffersConnection?: Resolver<
    ResolversTypes['AttractionOfferConnection'],
    ParentType,
    ContextType,
    RequireFields<QueryAttractionOffersConnectionArgs, 'locales' | 'stage'>
  >;
  attractionPrice?: Resolver<
    Maybe<ResolversTypes['AttractionPrice']>,
    ParentType,
    ContextType,
    RequireFields<QueryAttractionPriceArgs, 'locales' | 'stage' | 'where'>
  >;
  attractionPriceVersion?: Resolver<
    Maybe<ResolversTypes['DocumentVersion']>,
    ParentType,
    ContextType,
    RequireFields<QueryAttractionPriceVersionArgs, 'where'>
  >;
  attractionPrices?: Resolver<
    Array<ResolversTypes['AttractionPrice']>,
    ParentType,
    ContextType,
    RequireFields<QueryAttractionPricesArgs, 'locales' | 'stage'>
  >;
  attractionPricesConnection?: Resolver<
    ResolversTypes['AttractionPriceConnection'],
    ParentType,
    ContextType,
    RequireFields<QueryAttractionPricesConnectionArgs, 'locales' | 'stage'>
  >;
  attractionReservation?: Resolver<
    Maybe<ResolversTypes['AttractionReservation']>,
    ParentType,
    ContextType,
    RequireFields<QueryAttractionReservationArgs, 'locales' | 'stage' | 'where'>
  >;
  attractionReservationVersion?: Resolver<
    Maybe<ResolversTypes['DocumentVersion']>,
    ParentType,
    ContextType,
    RequireFields<QueryAttractionReservationVersionArgs, 'where'>
  >;
  attractionReservations?: Resolver<
    Array<ResolversTypes['AttractionReservation']>,
    ParentType,
    ContextType,
    RequireFields<QueryAttractionReservationsArgs, 'locales' | 'stage'>
  >;
  attractionReservationsConnection?: Resolver<
    ResolversTypes['AttractionReservationConnection'],
    ParentType,
    ContextType,
    RequireFields<QueryAttractionReservationsConnectionArgs, 'locales' | 'stage'>
  >;
  attractionVariant?: Resolver<
    Maybe<ResolversTypes['AttractionVariant']>,
    ParentType,
    ContextType,
    RequireFields<QueryAttractionVariantArgs, 'locales' | 'stage' | 'where'>
  >;
  attractionVariantVersion?: Resolver<
    Maybe<ResolversTypes['DocumentVersion']>,
    ParentType,
    ContextType,
    RequireFields<QueryAttractionVariantVersionArgs, 'where'>
  >;
  attractionVariants?: Resolver<
    Array<ResolversTypes['AttractionVariant']>,
    ParentType,
    ContextType,
    RequireFields<QueryAttractionVariantsArgs, 'locales' | 'stage'>
  >;
  attractionVariantsConnection?: Resolver<
    ResolversTypes['AttractionVariantConnection'],
    ParentType,
    ContextType,
    RequireFields<QueryAttractionVariantsConnectionArgs, 'locales' | 'stage'>
  >;
  attractionVersion?: Resolver<
    Maybe<ResolversTypes['DocumentVersion']>,
    ParentType,
    ContextType,
    RequireFields<QueryAttractionVersionArgs, 'where'>
  >;
  attractions?: Resolver<
    Array<ResolversTypes['Attraction']>,
    ParentType,
    ContextType,
    RequireFields<QueryAttractionsArgs, 'locales' | 'stage'>
  >;
  attractionsConnection?: Resolver<
    ResolversTypes['AttractionConnection'],
    ParentType,
    ContextType,
    RequireFields<QueryAttractionsConnectionArgs, 'locales' | 'stage'>
  >;
  author?: Resolver<
    Maybe<ResolversTypes['Author']>,
    ParentType,
    ContextType,
    RequireFields<QueryAuthorArgs, 'locales' | 'stage' | 'where'>
  >;
  authorVersion?: Resolver<
    Maybe<ResolversTypes['DocumentVersion']>,
    ParentType,
    ContextType,
    RequireFields<QueryAuthorVersionArgs, 'where'>
  >;
  authors?: Resolver<
    Array<ResolversTypes['Author']>,
    ParentType,
    ContextType,
    RequireFields<QueryAuthorsArgs, 'locales' | 'stage'>
  >;
  authorsConnection?: Resolver<
    ResolversTypes['AuthorConnection'],
    ParentType,
    ContextType,
    RequireFields<QueryAuthorsConnectionArgs, 'locales' | 'stage'>
  >;
  block?: Resolver<
    Maybe<ResolversTypes['Block']>,
    ParentType,
    ContextType,
    RequireFields<QueryBlockArgs, 'locales' | 'stage' | 'where'>
  >;
  blockGroup?: Resolver<
    Maybe<ResolversTypes['BlockGroup']>,
    ParentType,
    ContextType,
    RequireFields<QueryBlockGroupArgs, 'locales' | 'stage' | 'where'>
  >;
  blockGroupVersion?: Resolver<
    Maybe<ResolversTypes['DocumentVersion']>,
    ParentType,
    ContextType,
    RequireFields<QueryBlockGroupVersionArgs, 'where'>
  >;
  blockGroups?: Resolver<
    Array<ResolversTypes['BlockGroup']>,
    ParentType,
    ContextType,
    RequireFields<QueryBlockGroupsArgs, 'locales' | 'stage'>
  >;
  blockGroupsConnection?: Resolver<
    ResolversTypes['BlockGroupConnection'],
    ParentType,
    ContextType,
    RequireFields<QueryBlockGroupsConnectionArgs, 'locales' | 'stage'>
  >;
  blockVersion?: Resolver<
    Maybe<ResolversTypes['DocumentVersion']>,
    ParentType,
    ContextType,
    RequireFields<QueryBlockVersionArgs, 'where'>
  >;
  blocks?: Resolver<
    Array<ResolversTypes['Block']>,
    ParentType,
    ContextType,
    RequireFields<QueryBlocksArgs, 'locales' | 'stage'>
  >;
  blocksConnection?: Resolver<
    ResolversTypes['BlockConnection'],
    ParentType,
    ContextType,
    RequireFields<QueryBlocksConnectionArgs, 'locales' | 'stage'>
  >;
  detailBlock?: Resolver<
    Maybe<ResolversTypes['DetailBlock']>,
    ParentType,
    ContextType,
    RequireFields<QueryDetailBlockArgs, 'locales' | 'stage' | 'where'>
  >;
  detailBlockVersion?: Resolver<
    Maybe<ResolversTypes['DocumentVersion']>,
    ParentType,
    ContextType,
    RequireFields<QueryDetailBlockVersionArgs, 'where'>
  >;
  detailBlocks?: Resolver<
    Array<ResolversTypes['DetailBlock']>,
    ParentType,
    ContextType,
    RequireFields<QueryDetailBlocksArgs, 'locales' | 'stage'>
  >;
  detailBlocksConnection?: Resolver<
    ResolversTypes['DetailBlockConnection'],
    ParentType,
    ContextType,
    RequireFields<QueryDetailBlocksConnectionArgs, 'locales' | 'stage'>
  >;
  generalReservation?: Resolver<
    Maybe<ResolversTypes['GeneralReservation']>,
    ParentType,
    ContextType,
    RequireFields<QueryGeneralReservationArgs, 'locales' | 'stage' | 'where'>
  >;
  generalReservationVersion?: Resolver<
    Maybe<ResolversTypes['DocumentVersion']>,
    ParentType,
    ContextType,
    RequireFields<QueryGeneralReservationVersionArgs, 'where'>
  >;
  generalReservations?: Resolver<
    Array<ResolversTypes['GeneralReservation']>,
    ParentType,
    ContextType,
    RequireFields<QueryGeneralReservationsArgs, 'locales' | 'stage'>
  >;
  generalReservationsConnection?: Resolver<
    ResolversTypes['GeneralReservationConnection'],
    ParentType,
    ContextType,
    RequireFields<QueryGeneralReservationsConnectionArgs, 'locales' | 'stage'>
  >;
  image?: Resolver<
    Maybe<ResolversTypes['Image']>,
    ParentType,
    ContextType,
    RequireFields<QueryImageArgs, 'locales' | 'stage' | 'where'>
  >;
  imageVersion?: Resolver<
    Maybe<ResolversTypes['DocumentVersion']>,
    ParentType,
    ContextType,
    RequireFields<QueryImageVersionArgs, 'where'>
  >;
  images?: Resolver<
    Array<ResolversTypes['Image']>,
    ParentType,
    ContextType,
    RequireFields<QueryImagesArgs, 'locales' | 'stage'>
  >;
  imagesConnection?: Resolver<
    ResolversTypes['ImageConnection'],
    ParentType,
    ContextType,
    RequireFields<QueryImagesConnectionArgs, 'locales' | 'stage'>
  >;
  landingPage?: Resolver<
    Maybe<ResolversTypes['LandingPage']>,
    ParentType,
    ContextType,
    RequireFields<QueryLandingPageArgs, 'locales' | 'stage' | 'where'>
  >;
  landingPageVersion?: Resolver<
    Maybe<ResolversTypes['DocumentVersion']>,
    ParentType,
    ContextType,
    RequireFields<QueryLandingPageVersionArgs, 'where'>
  >;
  landingPages?: Resolver<
    Array<ResolversTypes['LandingPage']>,
    ParentType,
    ContextType,
    RequireFields<QueryLandingPagesArgs, 'locales' | 'stage'>
  >;
  landingPagesConnection?: Resolver<
    ResolversTypes['LandingPageConnection'],
    ParentType,
    ContextType,
    RequireFields<QueryLandingPagesConnectionArgs, 'locales' | 'stage'>
  >;
  link?: Resolver<
    Maybe<ResolversTypes['Link']>,
    ParentType,
    ContextType,
    RequireFields<QueryLinkArgs, 'locales' | 'stage' | 'where'>
  >;
  linkVersion?: Resolver<
    Maybe<ResolversTypes['DocumentVersion']>,
    ParentType,
    ContextType,
    RequireFields<QueryLinkVersionArgs, 'where'>
  >;
  links?: Resolver<
    Array<ResolversTypes['Link']>,
    ParentType,
    ContextType,
    RequireFields<QueryLinksArgs, 'locales' | 'stage'>
  >;
  linksConnection?: Resolver<
    ResolversTypes['LinkConnection'],
    ParentType,
    ContextType,
    RequireFields<QueryLinksConnectionArgs, 'locales' | 'stage'>
  >;
  menu?: Resolver<
    Maybe<ResolversTypes['Menu']>,
    ParentType,
    ContextType,
    RequireFields<QueryMenuArgs, 'locales' | 'stage' | 'where'>
  >;
  menuVersion?: Resolver<
    Maybe<ResolversTypes['DocumentVersion']>,
    ParentType,
    ContextType,
    RequireFields<QueryMenuVersionArgs, 'where'>
  >;
  menus?: Resolver<
    Array<ResolversTypes['Menu']>,
    ParentType,
    ContextType,
    RequireFields<QueryMenusArgs, 'locales' | 'stage'>
  >;
  menusConnection?: Resolver<
    ResolversTypes['MenuConnection'],
    ParentType,
    ContextType,
    RequireFields<QueryMenusConnectionArgs, 'locales' | 'stage'>
  >;
  node?: Resolver<
    Maybe<ResolversTypes['Node']>,
    ParentType,
    ContextType,
    RequireFields<QueryNodeArgs, 'id' | 'locales' | 'stage'>
  >;
  notice?: Resolver<
    Maybe<ResolversTypes['Notice']>,
    ParentType,
    ContextType,
    RequireFields<QueryNoticeArgs, 'locales' | 'stage' | 'where'>
  >;
  noticeVersion?: Resolver<
    Maybe<ResolversTypes['DocumentVersion']>,
    ParentType,
    ContextType,
    RequireFields<QueryNoticeVersionArgs, 'where'>
  >;
  notices?: Resolver<
    Array<ResolversTypes['Notice']>,
    ParentType,
    ContextType,
    RequireFields<QueryNoticesArgs, 'locales' | 'stage'>
  >;
  noticesConnection?: Resolver<
    ResolversTypes['NoticeConnection'],
    ParentType,
    ContextType,
    RequireFields<QueryNoticesConnectionArgs, 'locales' | 'stage'>
  >;
  page?: Resolver<
    Maybe<ResolversTypes['Page']>,
    ParentType,
    ContextType,
    RequireFields<QueryPageArgs, 'locales' | 'stage' | 'where'>
  >;
  pageTemplate?: Resolver<
    Maybe<ResolversTypes['PageTemplate']>,
    ParentType,
    ContextType,
    RequireFields<QueryPageTemplateArgs, 'locales' | 'stage' | 'where'>
  >;
  pageTemplateVersion?: Resolver<
    Maybe<ResolversTypes['DocumentVersion']>,
    ParentType,
    ContextType,
    RequireFields<QueryPageTemplateVersionArgs, 'where'>
  >;
  pageTemplates?: Resolver<
    Array<ResolversTypes['PageTemplate']>,
    ParentType,
    ContextType,
    RequireFields<QueryPageTemplatesArgs, 'locales' | 'stage'>
  >;
  pageTemplatesConnection?: Resolver<
    ResolversTypes['PageTemplateConnection'],
    ParentType,
    ContextType,
    RequireFields<QueryPageTemplatesConnectionArgs, 'locales' | 'stage'>
  >;
  pageVersion?: Resolver<
    Maybe<ResolversTypes['DocumentVersion']>,
    ParentType,
    ContextType,
    RequireFields<QueryPageVersionArgs, 'where'>
  >;
  pages?: Resolver<
    Array<ResolversTypes['Page']>,
    ParentType,
    ContextType,
    RequireFields<QueryPagesArgs, 'locales' | 'stage'>
  >;
  pagesConnection?: Resolver<
    ResolversTypes['PageConnection'],
    ParentType,
    ContextType,
    RequireFields<QueryPagesConnectionArgs, 'locales' | 'stage'>
  >;
  partner?: Resolver<
    Maybe<ResolversTypes['Partner']>,
    ParentType,
    ContextType,
    RequireFields<QueryPartnerArgs, 'locales' | 'stage' | 'where'>
  >;
  partnerOffer?: Resolver<
    Maybe<ResolversTypes['PartnerOffer']>,
    ParentType,
    ContextType,
    RequireFields<QueryPartnerOfferArgs, 'locales' | 'stage' | 'where'>
  >;
  partnerOfferVersion?: Resolver<
    Maybe<ResolversTypes['DocumentVersion']>,
    ParentType,
    ContextType,
    RequireFields<QueryPartnerOfferVersionArgs, 'where'>
  >;
  partnerOffers?: Resolver<
    Array<ResolversTypes['PartnerOffer']>,
    ParentType,
    ContextType,
    RequireFields<QueryPartnerOffersArgs, 'locales' | 'stage'>
  >;
  partnerOffersConnection?: Resolver<
    ResolversTypes['PartnerOfferConnection'],
    ParentType,
    ContextType,
    RequireFields<QueryPartnerOffersConnectionArgs, 'locales' | 'stage'>
  >;
  partnerVersion?: Resolver<
    Maybe<ResolversTypes['DocumentVersion']>,
    ParentType,
    ContextType,
    RequireFields<QueryPartnerVersionArgs, 'where'>
  >;
  partners?: Resolver<
    Array<ResolversTypes['Partner']>,
    ParentType,
    ContextType,
    RequireFields<QueryPartnersArgs, 'locales' | 'stage'>
  >;
  partnersConnection?: Resolver<
    ResolversTypes['PartnerConnection'],
    ParentType,
    ContextType,
    RequireFields<QueryPartnersConnectionArgs, 'locales' | 'stage'>
  >;
  product?: Resolver<
    Maybe<ResolversTypes['Product']>,
    ParentType,
    ContextType,
    RequireFields<QueryProductArgs, 'locales' | 'stage' | 'where'>
  >;
  productActivities?: Resolver<
    Array<ResolversTypes['ProductActivity']>,
    ParentType,
    ContextType,
    RequireFields<QueryProductActivitiesArgs, 'locales' | 'stage'>
  >;
  productActivitiesConnection?: Resolver<
    ResolversTypes['ProductActivityConnection'],
    ParentType,
    ContextType,
    RequireFields<QueryProductActivitiesConnectionArgs, 'locales' | 'stage'>
  >;
  productActivity?: Resolver<
    Maybe<ResolversTypes['ProductActivity']>,
    ParentType,
    ContextType,
    RequireFields<QueryProductActivityArgs, 'locales' | 'stage' | 'where'>
  >;
  productActivityItem?: Resolver<
    Maybe<ResolversTypes['ProductActivityItem']>,
    ParentType,
    ContextType,
    RequireFields<QueryProductActivityItemArgs, 'locales' | 'stage' | 'where'>
  >;
  productActivityItemVersion?: Resolver<
    Maybe<ResolversTypes['DocumentVersion']>,
    ParentType,
    ContextType,
    RequireFields<QueryProductActivityItemVersionArgs, 'where'>
  >;
  productActivityItems?: Resolver<
    Array<ResolversTypes['ProductActivityItem']>,
    ParentType,
    ContextType,
    RequireFields<QueryProductActivityItemsArgs, 'locales' | 'stage'>
  >;
  productActivityItemsConnection?: Resolver<
    ResolversTypes['ProductActivityItemConnection'],
    ParentType,
    ContextType,
    RequireFields<QueryProductActivityItemsConnectionArgs, 'locales' | 'stage'>
  >;
  productActivityVersion?: Resolver<
    Maybe<ResolversTypes['DocumentVersion']>,
    ParentType,
    ContextType,
    RequireFields<QueryProductActivityVersionArgs, 'where'>
  >;
  productAttractionLineup?: Resolver<
    Maybe<ResolversTypes['ProductAttractionLineup']>,
    ParentType,
    ContextType,
    RequireFields<QueryProductAttractionLineupArgs, 'locales' | 'stage' | 'where'>
  >;
  productAttractionLineupVersion?: Resolver<
    Maybe<ResolversTypes['DocumentVersion']>,
    ParentType,
    ContextType,
    RequireFields<QueryProductAttractionLineupVersionArgs, 'where'>
  >;
  productAttractionLineups?: Resolver<
    Array<ResolversTypes['ProductAttractionLineup']>,
    ParentType,
    ContextType,
    RequireFields<QueryProductAttractionLineupsArgs, 'locales' | 'stage'>
  >;
  productAttractionLineupsConnection?: Resolver<
    ResolversTypes['ProductAttractionLineupConnection'],
    ParentType,
    ContextType,
    RequireFields<QueryProductAttractionLineupsConnectionArgs, 'locales' | 'stage'>
  >;
  productReservation?: Resolver<
    Maybe<ResolversTypes['ProductReservation']>,
    ParentType,
    ContextType,
    RequireFields<QueryProductReservationArgs, 'locales' | 'stage' | 'where'>
  >;
  productReservationVersion?: Resolver<
    Maybe<ResolversTypes['DocumentVersion']>,
    ParentType,
    ContextType,
    RequireFields<QueryProductReservationVersionArgs, 'where'>
  >;
  productReservations?: Resolver<
    Array<ResolversTypes['ProductReservation']>,
    ParentType,
    ContextType,
    RequireFields<QueryProductReservationsArgs, 'locales' | 'stage'>
  >;
  productReservationsConnection?: Resolver<
    ResolversTypes['ProductReservationConnection'],
    ParentType,
    ContextType,
    RequireFields<QueryProductReservationsConnectionArgs, 'locales' | 'stage'>
  >;
  productVersion?: Resolver<
    Maybe<ResolversTypes['DocumentVersion']>,
    ParentType,
    ContextType,
    RequireFields<QueryProductVersionArgs, 'where'>
  >;
  products?: Resolver<
    Array<ResolversTypes['Product']>,
    ParentType,
    ContextType,
    RequireFields<QueryProductsArgs, 'locales' | 'stage'>
  >;
  productsConnection?: Resolver<
    ResolversTypes['ProductConnection'],
    ParentType,
    ContextType,
    RequireFields<QueryProductsConnectionArgs, 'locales' | 'stage'>
  >;
  promo?: Resolver<
    Maybe<ResolversTypes['Promo']>,
    ParentType,
    ContextType,
    RequireFields<QueryPromoArgs, 'locales' | 'stage' | 'where'>
  >;
  promoVersion?: Resolver<
    Maybe<ResolversTypes['DocumentVersion']>,
    ParentType,
    ContextType,
    RequireFields<QueryPromoVersionArgs, 'where'>
  >;
  promos?: Resolver<
    Array<ResolversTypes['Promo']>,
    ParentType,
    ContextType,
    RequireFields<QueryPromosArgs, 'locales' | 'stage'>
  >;
  promosConnection?: Resolver<
    ResolversTypes['PromoConnection'],
    ParentType,
    ContextType,
    RequireFields<QueryPromosConnectionArgs, 'locales' | 'stage'>
  >;
  reservationText?: Resolver<
    Maybe<ResolversTypes['ReservationText']>,
    ParentType,
    ContextType,
    RequireFields<QueryReservationTextArgs, 'locales' | 'stage' | 'where'>
  >;
  reservationTextVersion?: Resolver<
    Maybe<ResolversTypes['DocumentVersion']>,
    ParentType,
    ContextType,
    RequireFields<QueryReservationTextVersionArgs, 'where'>
  >;
  reservationTexts?: Resolver<
    Array<ResolversTypes['ReservationText']>,
    ParentType,
    ContextType,
    RequireFields<QueryReservationTextsArgs, 'locales' | 'stage'>
  >;
  reservationTextsConnection?: Resolver<
    ResolversTypes['ReservationTextConnection'],
    ParentType,
    ContextType,
    RequireFields<QueryReservationTextsConnectionArgs, 'locales' | 'stage'>
  >;
  sandbox?: Resolver<
    Maybe<ResolversTypes['Sandbox']>,
    ParentType,
    ContextType,
    RequireFields<QuerySandboxArgs, 'locales' | 'stage' | 'where'>
  >;
  sandboxVersion?: Resolver<
    Maybe<ResolversTypes['DocumentVersion']>,
    ParentType,
    ContextType,
    RequireFields<QuerySandboxVersionArgs, 'where'>
  >;
  sandboxes?: Resolver<
    Array<ResolversTypes['Sandbox']>,
    ParentType,
    ContextType,
    RequireFields<QuerySandboxesArgs, 'locales' | 'stage'>
  >;
  sandboxesConnection?: Resolver<
    ResolversTypes['SandboxConnection'],
    ParentType,
    ContextType,
    RequireFields<QuerySandboxesConnectionArgs, 'locales' | 'stage'>
  >;
  scheduledOperation?: Resolver<
    Maybe<ResolversTypes['ScheduledOperation']>,
    ParentType,
    ContextType,
    RequireFields<QueryScheduledOperationArgs, 'locales' | 'stage' | 'where'>
  >;
  scheduledOperations?: Resolver<
    Array<ResolversTypes['ScheduledOperation']>,
    ParentType,
    ContextType,
    RequireFields<QueryScheduledOperationsArgs, 'locales' | 'stage'>
  >;
  scheduledOperationsConnection?: Resolver<
    ResolversTypes['ScheduledOperationConnection'],
    ParentType,
    ContextType,
    RequireFields<QueryScheduledOperationsConnectionArgs, 'locales' | 'stage'>
  >;
  scheduledRelease?: Resolver<
    Maybe<ResolversTypes['ScheduledRelease']>,
    ParentType,
    ContextType,
    RequireFields<QueryScheduledReleaseArgs, 'locales' | 'stage' | 'where'>
  >;
  scheduledReleases?: Resolver<
    Array<ResolversTypes['ScheduledRelease']>,
    ParentType,
    ContextType,
    RequireFields<QueryScheduledReleasesArgs, 'locales' | 'stage'>
  >;
  scheduledReleasesConnection?: Resolver<
    ResolversTypes['ScheduledReleaseConnection'],
    ParentType,
    ContextType,
    RequireFields<QueryScheduledReleasesConnectionArgs, 'locales' | 'stage'>
  >;
  seo?: Resolver<
    Maybe<ResolversTypes['Seo']>,
    ParentType,
    ContextType,
    RequireFields<QuerySeoArgs, 'locales' | 'stage' | 'where'>
  >;
  seoVersion?: Resolver<
    Maybe<ResolversTypes['DocumentVersion']>,
    ParentType,
    ContextType,
    RequireFields<QuerySeoVersionArgs, 'where'>
  >;
  seos?: Resolver<
    Array<ResolversTypes['Seo']>,
    ParentType,
    ContextType,
    RequireFields<QuerySeosArgs, 'locales' | 'stage'>
  >;
  seosConnection?: Resolver<
    ResolversTypes['SeoConnection'],
    ParentType,
    ContextType,
    RequireFields<QuerySeosConnectionArgs, 'locales' | 'stage'>
  >;
  text?: Resolver<
    Maybe<ResolversTypes['Text']>,
    ParentType,
    ContextType,
    RequireFields<QueryTextArgs, 'locales' | 'stage' | 'where'>
  >;
  textGroup?: Resolver<
    Maybe<ResolversTypes['TextGroup']>,
    ParentType,
    ContextType,
    RequireFields<QueryTextGroupArgs, 'locales' | 'stage' | 'where'>
  >;
  textGroupVersion?: Resolver<
    Maybe<ResolversTypes['DocumentVersion']>,
    ParentType,
    ContextType,
    RequireFields<QueryTextGroupVersionArgs, 'where'>
  >;
  textGroups?: Resolver<
    Array<ResolversTypes['TextGroup']>,
    ParentType,
    ContextType,
    RequireFields<QueryTextGroupsArgs, 'locales' | 'stage'>
  >;
  textGroupsConnection?: Resolver<
    ResolversTypes['TextGroupConnection'],
    ParentType,
    ContextType,
    RequireFields<QueryTextGroupsConnectionArgs, 'locales' | 'stage'>
  >;
  textList?: Resolver<
    Maybe<ResolversTypes['TextList']>,
    ParentType,
    ContextType,
    RequireFields<QueryTextListArgs, 'locales' | 'stage' | 'where'>
  >;
  textListVersion?: Resolver<
    Maybe<ResolversTypes['DocumentVersion']>,
    ParentType,
    ContextType,
    RequireFields<QueryTextListVersionArgs, 'where'>
  >;
  textLists?: Resolver<
    Array<ResolversTypes['TextList']>,
    ParentType,
    ContextType,
    RequireFields<QueryTextListsArgs, 'locales' | 'stage'>
  >;
  textListsConnection?: Resolver<
    ResolversTypes['TextListConnection'],
    ParentType,
    ContextType,
    RequireFields<QueryTextListsConnectionArgs, 'locales' | 'stage'>
  >;
  textVersion?: Resolver<
    Maybe<ResolversTypes['DocumentVersion']>,
    ParentType,
    ContextType,
    RequireFields<QueryTextVersionArgs, 'where'>
  >;
  texts?: Resolver<
    Array<ResolversTypes['Text']>,
    ParentType,
    ContextType,
    RequireFields<QueryTextsArgs, 'locales' | 'stage'>
  >;
  textsConnection?: Resolver<
    ResolversTypes['TextConnection'],
    ParentType,
    ContextType,
    RequireFields<QueryTextsConnectionArgs, 'locales' | 'stage'>
  >;
  ticketGroup?: Resolver<
    Maybe<ResolversTypes['TicketGroup']>,
    ParentType,
    ContextType,
    RequireFields<QueryTicketGroupArgs, 'locales' | 'stage' | 'where'>
  >;
  ticketGroupVersion?: Resolver<
    Maybe<ResolversTypes['DocumentVersion']>,
    ParentType,
    ContextType,
    RequireFields<QueryTicketGroupVersionArgs, 'where'>
  >;
  ticketGroups?: Resolver<
    Array<ResolversTypes['TicketGroup']>,
    ParentType,
    ContextType,
    RequireFields<QueryTicketGroupsArgs, 'locales' | 'stage'>
  >;
  ticketGroupsConnection?: Resolver<
    ResolversTypes['TicketGroupConnection'],
    ParentType,
    ContextType,
    RequireFields<QueryTicketGroupsConnectionArgs, 'locales' | 'stage'>
  >;
  titleDescription?: Resolver<
    Maybe<ResolversTypes['TitleDescription']>,
    ParentType,
    ContextType,
    RequireFields<QueryTitleDescriptionArgs, 'locales' | 'stage' | 'where'>
  >;
  titleDescriptionVersion?: Resolver<
    Maybe<ResolversTypes['DocumentVersion']>,
    ParentType,
    ContextType,
    RequireFields<QueryTitleDescriptionVersionArgs, 'where'>
  >;
  titleDescriptions?: Resolver<
    Array<ResolversTypes['TitleDescription']>,
    ParentType,
    ContextType,
    RequireFields<QueryTitleDescriptionsArgs, 'locales' | 'stage'>
  >;
  titleDescriptionsConnection?: Resolver<
    ResolversTypes['TitleDescriptionConnection'],
    ParentType,
    ContextType,
    RequireFields<QueryTitleDescriptionsConnectionArgs, 'locales' | 'stage'>
  >;
  user?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    RequireFields<QueryUserArgs, 'locales' | 'stage' | 'where'>
  >;
  users?: Resolver<
    Array<ResolversTypes['User']>,
    ParentType,
    ContextType,
    RequireFields<QueryUsersArgs, 'locales' | 'stage'>
  >;
  usersConnection?: Resolver<
    ResolversTypes['UserConnection'],
    ParentType,
    ContextType,
    RequireFields<QueryUsersConnectionArgs, 'locales' | 'stage'>
  >;
  video?: Resolver<
    Maybe<ResolversTypes['Video']>,
    ParentType,
    ContextType,
    RequireFields<QueryVideoArgs, 'locales' | 'stage' | 'where'>
  >;
  videoVersion?: Resolver<
    Maybe<ResolversTypes['DocumentVersion']>,
    ParentType,
    ContextType,
    RequireFields<QueryVideoVersionArgs, 'where'>
  >;
  videos?: Resolver<
    Array<ResolversTypes['Video']>,
    ParentType,
    ContextType,
    RequireFields<QueryVideosArgs, 'locales' | 'stage'>
  >;
  videosConnection?: Resolver<
    ResolversTypes['VideoConnection'],
    ParentType,
    ContextType,
    RequireFields<QueryVideosConnectionArgs, 'locales' | 'stage'>
  >;
};

export type RgbaResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RGBA'] = ResolversParentTypes['RGBA']
> = {
  a?: Resolver<ResolversTypes['RGBATransparency'], ParentType, ContextType>;
  b?: Resolver<ResolversTypes['RGBAHue'], ParentType, ContextType>;
  g?: Resolver<ResolversTypes['RGBAHue'], ParentType, ContextType>;
  r?: Resolver<ResolversTypes['RGBAHue'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface RgbaHueScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['RGBAHue'], any> {
  name: 'RGBAHue';
}

export interface RgbaTransparencyScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['RGBATransparency'], any> {
  name: 'RGBATransparency';
}

export type ReservationTextResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ReservationText'] = ResolversParentTypes['ReservationText']
> = {
  attractionReservations?: Resolver<
    Array<ResolversTypes['AttractionReservation']>,
    ParentType,
    ContextType,
    Partial<ReservationTextAttractionReservationsArgs>
  >;
  createdAt?: Resolver<
    ResolversTypes['DateTime'],
    ParentType,
    ContextType,
    RequireFields<ReservationTextCreatedAtArgs, 'variation'>
  >;
  createdBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<ReservationTextCreatedByArgs>
  >;
  documentInStages?: Resolver<
    Array<ResolversTypes['ReservationText']>,
    ParentType,
    ContextType,
    RequireFields<
      ReservationTextDocumentInStagesArgs,
      'includeCurrent' | 'inheritLocale' | 'stages'
    >
  >;
  generalReservation?: Resolver<
    Maybe<ResolversTypes['GeneralReservation']>,
    ParentType,
    ContextType,
    Partial<ReservationTextGeneralReservationArgs>
  >;
  generalReservationAttraction?: Resolver<
    Maybe<ResolversTypes['GeneralReservation']>,
    ParentType,
    ContextType,
    Partial<ReservationTextGeneralReservationAttractionArgs>
  >;
  generalReservationProduct?: Resolver<
    Maybe<ResolversTypes['GeneralReservation']>,
    ParentType,
    ContextType,
    Partial<ReservationTextGeneralReservationProductArgs>
  >;
  history?: Resolver<
    Array<ResolversTypes['Version']>,
    ParentType,
    ContextType,
    RequireFields<ReservationTextHistoryArgs, 'limit' | 'skip'>
  >;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  internalTitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  locale?: Resolver<ResolversTypes['Locale'], ParentType, ContextType>;
  localizations?: Resolver<
    Array<ResolversTypes['ReservationText']>,
    ParentType,
    ContextType,
    RequireFields<ReservationTextLocalizationsArgs, 'includeCurrent' | 'locales'>
  >;
  platforms?: Resolver<Array<ResolversTypes['Platforms']>, ParentType, ContextType>;
  productReservations?: Resolver<
    Array<ResolversTypes['ProductReservation']>,
    ParentType,
    ContextType,
    Partial<ReservationTextProductReservationsArgs>
  >;
  products?: Resolver<
    Array<ResolversTypes['Product']>,
    ParentType,
    ContextType,
    Partial<ReservationTextProductsArgs>
  >;
  publishedAt?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType,
    RequireFields<ReservationTextPublishedAtArgs, 'variation'>
  >;
  publishedBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<ReservationTextPublishedByArgs>
  >;
  qabu?: Resolver<Maybe<ResolversTypes['Json']>, ParentType, ContextType>;
  scheduledIn?: Resolver<
    Array<ResolversTypes['ScheduledOperation']>,
    ParentType,
    ContextType,
    Partial<ReservationTextScheduledInArgs>
  >;
  stage?: Resolver<ResolversTypes['Stage'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['ReservationTextType'], ParentType, ContextType>;
  updatedAt?: Resolver<
    ResolversTypes['DateTime'],
    ParentType,
    ContextType,
    RequireFields<ReservationTextUpdatedAtArgs, 'variation'>
  >;
  updatedBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<ReservationTextUpdatedByArgs>
  >;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReservationTextConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ReservationTextConnection'] = ResolversParentTypes['ReservationTextConnection']
> = {
  aggregate?: Resolver<ResolversTypes['Aggregate'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['ReservationTextEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReservationTextEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ReservationTextEdge'] = ResolversParentTypes['ReservationTextEdge']
> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['ReservationText'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RichTextResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RichText'] = ResolversParentTypes['RichText']
> = {
  html?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  markdown?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  raw?: Resolver<ResolversTypes['RichTextAST'], ParentType, ContextType>;
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface RichTextAstScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['RichTextAST'], any> {
  name: 'RichTextAST';
}

export type SandboxResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Sandbox'] = ResolversParentTypes['Sandbox']
> = {
  createdAt?: Resolver<
    ResolversTypes['DateTime'],
    ParentType,
    ContextType,
    RequireFields<SandboxCreatedAtArgs, 'variation'>
  >;
  createdBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<SandboxCreatedByArgs>
  >;
  documentInStages?: Resolver<
    Array<ResolversTypes['Sandbox']>,
    ParentType,
    ContextType,
    RequireFields<SandboxDocumentInStagesArgs, 'includeCurrent' | 'inheritLocale' | 'stages'>
  >;
  focalPoint?: Resolver<Maybe<ResolversTypes['Json']>, ParentType, ContextType>;
  history?: Resolver<
    Array<ResolversTypes['Version']>,
    ParentType,
    ContextType,
    RequireFields<SandboxHistoryArgs, 'limit' | 'skip'>
  >;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  locale?: Resolver<ResolversTypes['Locale'], ParentType, ContextType>;
  localizations?: Resolver<
    Array<ResolversTypes['Sandbox']>,
    ParentType,
    ContextType,
    RequireFields<SandboxLocalizationsArgs, 'includeCurrent' | 'locales'>
  >;
  markdown?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  publishedAt?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType,
    RequireFields<SandboxPublishedAtArgs, 'variation'>
  >;
  publishedBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<SandboxPublishedByArgs>
  >;
  qabu?: Resolver<Maybe<ResolversTypes['Json']>, ParentType, ContextType>;
  richText?: Resolver<Maybe<ResolversTypes['RichText']>, ParentType, ContextType>;
  scheduledIn?: Resolver<
    Array<ResolversTypes['ScheduledOperation']>,
    ParentType,
    ContextType,
    Partial<SandboxScheduledInArgs>
  >;
  stage?: Resolver<ResolversTypes['Stage'], ParentType, ContextType>;
  test?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  test2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  text?: Resolver<Maybe<ResolversTypes['Text']>, ParentType, ContextType, Partial<SandboxTextArgs>>;
  textList?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  texts?: Resolver<
    Array<ResolversTypes['Text']>,
    ParentType,
    ContextType,
    Partial<SandboxTextsArgs>
  >;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<
    ResolversTypes['DateTime'],
    ParentType,
    ContextType,
    RequireFields<SandboxUpdatedAtArgs, 'variation'>
  >;
  updatedBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<SandboxUpdatedByArgs>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SandboxConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SandboxConnection'] = ResolversParentTypes['SandboxConnection']
> = {
  aggregate?: Resolver<ResolversTypes['Aggregate'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['SandboxEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SandboxEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SandboxEdge'] = ResolversParentTypes['SandboxEdge']
> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['Sandbox'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ScheduledOperationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ScheduledOperation'] = ResolversParentTypes['ScheduledOperation']
> = {
  affectedDocuments?: Resolver<
    Array<ResolversTypes['ScheduledOperationAffectedDocument']>,
    ParentType,
    ContextType,
    Partial<ScheduledOperationAffectedDocumentsArgs>
  >;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<ScheduledOperationCreatedByArgs>
  >;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  documentInStages?: Resolver<
    Array<ResolversTypes['ScheduledOperation']>,
    ParentType,
    ContextType,
    RequireFields<
      ScheduledOperationDocumentInStagesArgs,
      'includeCurrent' | 'inheritLocale' | 'stages'
    >
  >;
  errorMessage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  publishedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  publishedBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<ScheduledOperationPublishedByArgs>
  >;
  rawPayload?: Resolver<ResolversTypes['Json'], ParentType, ContextType>;
  release?: Resolver<
    Maybe<ResolversTypes['ScheduledRelease']>,
    ParentType,
    ContextType,
    Partial<ScheduledOperationReleaseArgs>
  >;
  stage?: Resolver<ResolversTypes['Stage'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['ScheduledOperationStatus'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updatedBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<ScheduledOperationUpdatedByArgs>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ScheduledOperationAffectedDocumentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ScheduledOperationAffectedDocument'] = ResolversParentTypes['ScheduledOperationAffectedDocument']
> = {
  __resolveType: TypeResolveFn<
    | 'AddOn'
    | 'AlacarteTicket'
    | 'AlacarteTicketType'
    | 'Article'
    | 'ArticleCategory'
    | 'Asset'
    | 'Attraction'
    | 'AttractionAlacarte'
    | 'AttractionAvailability'
    | 'AttractionLocation'
    | 'AttractionOffer'
    | 'AttractionPrice'
    | 'AttractionReservation'
    | 'AttractionVariant'
    | 'Author'
    | 'Block'
    | 'BlockGroup'
    | 'DetailBlock'
    | 'GeneralReservation'
    | 'Image'
    | 'LandingPage'
    | 'Link'
    | 'Menu'
    | 'Notice'
    | 'Page'
    | 'PageTemplate'
    | 'Partner'
    | 'PartnerOffer'
    | 'Product'
    | 'ProductActivity'
    | 'ProductActivityItem'
    | 'ProductAttractionLineup'
    | 'ProductReservation'
    | 'Promo'
    | 'ReservationText'
    | 'Sandbox'
    | 'Seo'
    | 'Text'
    | 'TextGroup'
    | 'TextList'
    | 'TicketGroup'
    | 'TitleDescription'
    | 'Video',
    ParentType,
    ContextType
  >;
};

export type ScheduledOperationConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ScheduledOperationConnection'] = ResolversParentTypes['ScheduledOperationConnection']
> = {
  aggregate?: Resolver<ResolversTypes['Aggregate'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['ScheduledOperationEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ScheduledOperationEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ScheduledOperationEdge'] = ResolversParentTypes['ScheduledOperationEdge']
> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['ScheduledOperation'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ScheduledReleaseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ScheduledRelease'] = ResolversParentTypes['ScheduledRelease']
> = {
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<ScheduledReleaseCreatedByArgs>
  >;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  documentInStages?: Resolver<
    Array<ResolversTypes['ScheduledRelease']>,
    ParentType,
    ContextType,
    RequireFields<
      ScheduledReleaseDocumentInStagesArgs,
      'includeCurrent' | 'inheritLocale' | 'stages'
    >
  >;
  errorMessage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isActive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isImplicit?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  operations?: Resolver<
    Array<ResolversTypes['ScheduledOperation']>,
    ParentType,
    ContextType,
    Partial<ScheduledReleaseOperationsArgs>
  >;
  publishedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  publishedBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<ScheduledReleasePublishedByArgs>
  >;
  releaseAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  stage?: Resolver<ResolversTypes['Stage'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['ScheduledReleaseStatus'], ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updatedBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<ScheduledReleaseUpdatedByArgs>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ScheduledReleaseConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ScheduledReleaseConnection'] = ResolversParentTypes['ScheduledReleaseConnection']
> = {
  aggregate?: Resolver<ResolversTypes['Aggregate'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['ScheduledReleaseEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ScheduledReleaseEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ScheduledReleaseEdge'] = ResolversParentTypes['ScheduledReleaseEdge']
> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['ScheduledRelease'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SeoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Seo'] = ResolversParentTypes['Seo']
> = {
  attractions?: Resolver<
    Array<ResolversTypes['Attraction']>,
    ParentType,
    ContextType,
    Partial<SeoAttractionsArgs>
  >;
  canonical?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<
    ResolversTypes['DateTime'],
    ParentType,
    ContextType,
    RequireFields<SeoCreatedAtArgs, 'variation'>
  >;
  createdBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<SeoCreatedByArgs>
  >;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  documentInStages?: Resolver<
    Array<ResolversTypes['Seo']>,
    ParentType,
    ContextType,
    RequireFields<SeoDocumentInStagesArgs, 'includeCurrent' | 'inheritLocale' | 'stages'>
  >;
  excludeRobots?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  history?: Resolver<
    Array<ResolversTypes['Version']>,
    ParentType,
    ContextType,
    RequireFields<SeoHistoryArgs, 'limit' | 'skip'>
  >;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  internalTags?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  internalTitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  locale?: Resolver<ResolversTypes['Locale'], ParentType, ContextType>;
  localizations?: Resolver<
    Array<ResolversTypes['Seo']>,
    ParentType,
    ContextType,
    RequireFields<SeoLocalizationsArgs, 'includeCurrent' | 'locales'>
  >;
  pageTemplates?: Resolver<
    Array<ResolversTypes['PageTemplate']>,
    ParentType,
    ContextType,
    Partial<SeoPageTemplatesArgs>
  >;
  productSeos?: Resolver<
    Array<ResolversTypes['Product']>,
    ParentType,
    ContextType,
    Partial<SeoProductSeosArgs>
  >;
  publishedAt?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType,
    RequireFields<SeoPublishedAtArgs, 'variation'>
  >;
  publishedBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<SeoPublishedByArgs>
  >;
  qabu?: Resolver<Maybe<ResolversTypes['Json']>, ParentType, ContextType>;
  scheduledIn?: Resolver<
    Array<ResolversTypes['ScheduledOperation']>,
    ParentType,
    ContextType,
    Partial<SeoScheduledInArgs>
  >;
  stage?: Resolver<ResolversTypes['Stage'], ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<
    ResolversTypes['DateTime'],
    ParentType,
    ContextType,
    RequireFields<SeoUpdatedAtArgs, 'variation'>
  >;
  updatedBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<SeoUpdatedByArgs>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SeoConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SeoConnection'] = ResolversParentTypes['SeoConnection']
> = {
  aggregate?: Resolver<ResolversTypes['Aggregate'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['SeoEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SeoEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SeoEdge'] = ResolversParentTypes['SeoEdge']
> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['Seo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SkuPropertyResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SkuProperty'] = ResolversParentTypes['SkuProperty']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  sku?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  stage?: Resolver<ResolversTypes['Stage'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['SkuTicketTypeProperty'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SkuPropertyConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SkuPropertyConnection'] = ResolversParentTypes['SkuPropertyConnection']
> = {
  aggregate?: Resolver<ResolversTypes['Aggregate'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['SkuPropertyEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SkuPropertyEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SkuPropertyEdge'] = ResolversParentTypes['SkuPropertyEdge']
> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['SkuProperty'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SkuPropertyParentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SkuPropertyParent'] = ResolversParentTypes['SkuPropertyParent']
> = {
  __resolveType: TypeResolveFn<'Attraction', ParentType, ContextType>;
};

export type TextResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Text'] = ResolversParentTypes['Text']
> = {
  alacarteTicketTypeDescription?: Resolver<
    Array<ResolversTypes['AlacarteTicketType']>,
    ParentType,
    ContextType,
    Partial<TextAlacarteTicketTypeDescriptionArgs>
  >;
  alacarteTicketTypeInclusions?: Resolver<
    Array<ResolversTypes['AlacarteTicketType']>,
    ParentType,
    ContextType,
    Partial<TextAlacarteTicketTypeInclusionsArgs>
  >;
  alacarteTicketTypeInclusionsNote?: Resolver<
    Array<ResolversTypes['AlacarteTicketType']>,
    ParentType,
    ContextType,
    Partial<TextAlacarteTicketTypeInclusionsNoteArgs>
  >;
  alacarteTicketTypeRestrictions?: Resolver<
    Array<ResolversTypes['AlacarteTicketType']>,
    ParentType,
    ContextType,
    Partial<TextAlacarteTicketTypeRestrictionsArgs>
  >;
  alacarteTicketTypeValidPostPurchaseForDateRange?: Resolver<
    Array<ResolversTypes['AlacarteTicketType']>,
    ParentType,
    ContextType,
    Partial<TextAlacarteTicketTypeValidPostPurchaseForDateRangeArgs>
  >;
  alacarteTicketTypeValidity?: Resolver<
    Array<ResolversTypes['AlacarteTicketType']>,
    ParentType,
    ContextType,
    Partial<TextAlacarteTicketTypeValidityArgs>
  >;
  alacarteTicketTypeValidityPostPurchase?: Resolver<
    Array<ResolversTypes['AlacarteTicketType']>,
    ParentType,
    ContextType,
    Partial<TextAlacarteTicketTypeValidityPostPurchaseArgs>
  >;
  alacarteTicketTypeValidityPostPurchaseNote?: Resolver<
    Array<ResolversTypes['AlacarteTicketType']>,
    ParentType,
    ContextType,
    Partial<TextAlacarteTicketTypeValidityPostPurchaseNoteArgs>
  >;
  alacarteTicketTypeValidityShort?: Resolver<
    Array<ResolversTypes['AlacarteTicketType']>,
    ParentType,
    ContextType,
    Partial<TextAlacarteTicketTypeValidityShortArgs>
  >;
  alacarteTicketTypeValidityWillCall?: Resolver<
    Array<ResolversTypes['AlacarteTicketType']>,
    ParentType,
    ContextType,
    Partial<TextAlacarteTicketTypeValidityWillCallArgs>
  >;
  attractionAdmission?: Resolver<
    Array<ResolversTypes['Attraction']>,
    ParentType,
    ContextType,
    Partial<TextAttractionAdmissionArgs>
  >;
  attractionAlacarteDescription?: Resolver<
    Array<ResolversTypes['AttractionAlacarte']>,
    ParentType,
    ContextType,
    Partial<TextAttractionAlacarteDescriptionArgs>
  >;
  attractionAlacarteRedemption?: Resolver<
    Array<ResolversTypes['AttractionAlacarte']>,
    ParentType,
    ContextType,
    Partial<TextAttractionAlacarteRedemptionArgs>
  >;
  attractionAlacarteRedemptionShort?: Resolver<
    Array<ResolversTypes['AttractionAlacarte']>,
    ParentType,
    ContextType,
    Partial<TextAttractionAlacarteRedemptionShortArgs>
  >;
  attractionAlacarteRestrictionsCommon?: Resolver<
    Array<ResolversTypes['AttractionAlacarte']>,
    ParentType,
    ContextType,
    Partial<TextAttractionAlacarteRestrictionsCommonArgs>
  >;
  attractionAlacarteTermsAndConditions?: Resolver<
    Array<ResolversTypes['AttractionAlacarte']>,
    ParentType,
    ContextType,
    Partial<TextAttractionAlacarteTermsAndConditionsArgs>
  >;
  attractionAlacarteValidityCommon?: Resolver<
    Array<ResolversTypes['AttractionAlacarte']>,
    ParentType,
    ContextType,
    Partial<TextAttractionAlacarteValidityCommonArgs>
  >;
  attractionAlacarteValidityWillCall?: Resolver<
    Array<ResolversTypes['AttractionAlacarte']>,
    ParentType,
    ContextType,
    Partial<TextAttractionAlacarteValidityWillCallArgs>
  >;
  attractionAmenities?: Resolver<
    Array<ResolversTypes['Attraction']>,
    ParentType,
    ContextType,
    Partial<TextAttractionAmenitiesArgs>
  >;
  attractionHighlights?: Resolver<
    Array<ResolversTypes['Attraction']>,
    ParentType,
    ContextType,
    Partial<TextAttractionHighlightsArgs>
  >;
  attractionLocationHoursNotes?: Resolver<
    Array<ResolversTypes['AttractionLocation']>,
    ParentType,
    ContextType,
    Partial<TextAttractionLocationHoursNotesArgs>
  >;
  attractionOpenStatus?: Resolver<
    Array<ResolversTypes['Attraction']>,
    ParentType,
    ContextType,
    Partial<TextAttractionOpenStatusArgs>
  >;
  attractionReservations?: Resolver<
    Array<ResolversTypes['AttractionReservation']>,
    ParentType,
    ContextType,
    Partial<TextAttractionReservationsArgs>
  >;
  attractionSafety?: Resolver<
    Array<ResolversTypes['Attraction']>,
    ParentType,
    ContextType,
    Partial<TextAttractionSafetyArgs>
  >;
  attractionTips?: Resolver<
    Array<ResolversTypes['Attraction']>,
    ParentType,
    ContextType,
    Partial<TextAttractionTipsArgs>
  >;
  attractionVariantAdmission?: Resolver<
    Array<ResolversTypes['AttractionVariant']>,
    ParentType,
    ContextType,
    Partial<TextAttractionVariantAdmissionArgs>
  >;
  attractionVariantHighlights?: Resolver<
    Array<ResolversTypes['AttractionVariant']>,
    ParentType,
    ContextType,
    Partial<TextAttractionVariantHighlightsArgs>
  >;
  attractionVariantOpenStatus?: Resolver<
    Array<ResolversTypes['AttractionVariant']>,
    ParentType,
    ContextType,
    Partial<TextAttractionVariantOpenStatusArgs>
  >;
  createdAt?: Resolver<
    ResolversTypes['DateTime'],
    ParentType,
    ContextType,
    RequireFields<TextCreatedAtArgs, 'variation'>
  >;
  createdBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<TextCreatedByArgs>
  >;
  documentInStages?: Resolver<
    Array<ResolversTypes['Text']>,
    ParentType,
    ContextType,
    RequireFields<TextDocumentInStagesArgs, 'includeCurrent' | 'inheritLocale' | 'stages'>
  >;
  history?: Resolver<
    Array<ResolversTypes['Version']>,
    ParentType,
    ContextType,
    RequireFields<TextHistoryArgs, 'limit' | 'skip'>
  >;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  locale?: Resolver<ResolversTypes['Locale'], ParentType, ContextType>;
  localizations?: Resolver<
    Array<ResolversTypes['Text']>,
    ParentType,
    ContextType,
    RequireFields<TextLocalizationsArgs, 'includeCurrent' | 'locales'>
  >;
  productAdmissionTitles?: Resolver<
    Array<ResolversTypes['Product']>,
    ParentType,
    ContextType,
    Partial<TextProductAdmissionTitlesArgs>
  >;
  productAttractionLineups?: Resolver<
    Array<ResolversTypes['ProductAttractionLineup']>,
    ParentType,
    ContextType,
    Partial<TextProductAttractionLineupsArgs>
  >;
  productAttractionLineupsSubtitle?: Resolver<
    Array<ResolversTypes['ProductAttractionLineup']>,
    ParentType,
    ContextType,
    Partial<TextProductAttractionLineupsSubtitleArgs>
  >;
  productAttractionLineupsTitle?: Resolver<
    Array<ResolversTypes['ProductAttractionLineup']>,
    ParentType,
    ContextType,
    Partial<TextProductAttractionLineupsTitleArgs>
  >;
  productHeadlines?: Resolver<
    Array<ResolversTypes['Product']>,
    ParentType,
    ContextType,
    Partial<TextProductHeadlinesArgs>
  >;
  productReservations?: Resolver<
    Array<ResolversTypes['ProductReservation']>,
    ParentType,
    ContextType,
    Partial<TextProductReservationsArgs>
  >;
  products?: Resolver<
    Array<ResolversTypes['Product']>,
    ParentType,
    ContextType,
    Partial<TextProductsArgs>
  >;
  productsCityName?: Resolver<
    Array<ResolversTypes['Product']>,
    ParentType,
    ContextType,
    Partial<TextProductsCityNameArgs>
  >;
  productsDescription?: Resolver<
    Array<ResolversTypes['Product']>,
    ParentType,
    ContextType,
    Partial<TextProductsDescriptionArgs>
  >;
  productsTagline?: Resolver<
    Array<ResolversTypes['Product']>,
    ParentType,
    ContextType,
    Partial<TextProductsTaglineArgs>
  >;
  publishedAt?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType,
    RequireFields<TextPublishedAtArgs, 'variation'>
  >;
  publishedBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<TextPublishedByArgs>
  >;
  qabu?: Resolver<Maybe<ResolversTypes['Json']>, ParentType, ContextType>;
  scheduledIn?: Resolver<
    Array<ResolversTypes['ScheduledOperation']>,
    ParentType,
    ContextType,
    Partial<TextScheduledInArgs>
  >;
  stage?: Resolver<ResolversTypes['Stage'], ParentType, ContextType>;
  tags?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  textGroups?: Resolver<
    Array<ResolversTypes['TextGroup']>,
    ParentType,
    ContextType,
    Partial<TextTextGroupsArgs>
  >;
  title?: Resolver<
    Array<ResolversTypes['ProductActivity']>,
    ParentType,
    ContextType,
    Partial<TextTitleArgs>
  >;
  updatedAt?: Resolver<
    ResolversTypes['DateTime'],
    ParentType,
    ContextType,
    RequireFields<TextUpdatedAtArgs, 'variation'>
  >;
  updatedBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<TextUpdatedByArgs>
  >;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TextConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TextConnection'] = ResolversParentTypes['TextConnection']
> = {
  aggregate?: Resolver<ResolversTypes['Aggregate'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['TextEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TextEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TextEdge'] = ResolversParentTypes['TextEdge']
> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['Text'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TextGroupResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TextGroup'] = ResolversParentTypes['TextGroup']
> = {
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<TextGroupCreatedByArgs>
  >;
  documentInStages?: Resolver<
    Array<ResolversTypes['TextGroup']>,
    ParentType,
    ContextType,
    RequireFields<TextGroupDocumentInStagesArgs, 'includeCurrent' | 'inheritLocale' | 'stages'>
  >;
  history?: Resolver<
    Array<ResolversTypes['Version']>,
    ParentType,
    ContextType,
    RequireFields<TextGroupHistoryArgs, 'limit' | 'skip'>
  >;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  key?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pageTemplates?: Resolver<
    Array<ResolversTypes['PageTemplate']>,
    ParentType,
    ContextType,
    Partial<TextGroupPageTemplatesArgs>
  >;
  publishedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  publishedBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<TextGroupPublishedByArgs>
  >;
  scheduledIn?: Resolver<
    Array<ResolversTypes['ScheduledOperation']>,
    ParentType,
    ContextType,
    Partial<TextGroupScheduledInArgs>
  >;
  stage?: Resolver<ResolversTypes['Stage'], ParentType, ContextType>;
  text?: Resolver<
    Array<ResolversTypes['TextGroupText']>,
    ParentType,
    ContextType,
    Partial<TextGroupTextArgs>
  >;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updatedBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<TextGroupUpdatedByArgs>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TextGroupConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TextGroupConnection'] = ResolversParentTypes['TextGroupConnection']
> = {
  aggregate?: Resolver<ResolversTypes['Aggregate'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['TextGroupEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TextGroupEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TextGroupEdge'] = ResolversParentTypes['TextGroupEdge']
> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['TextGroup'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TextGroupTextResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TextGroupText'] = ResolversParentTypes['TextGroupText']
> = {
  __resolveType: TypeResolveFn<'Text' | 'TextList', ParentType, ContextType>;
};

export type TextListResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TextList'] = ResolversParentTypes['TextList']
> = {
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<TextListCreatedByArgs>
  >;
  documentInStages?: Resolver<
    Array<ResolversTypes['TextList']>,
    ParentType,
    ContextType,
    RequireFields<TextListDocumentInStagesArgs, 'includeCurrent' | 'inheritLocale' | 'stages'>
  >;
  history?: Resolver<
    Array<ResolversTypes['Version']>,
    ParentType,
    ContextType,
    RequireFields<TextListHistoryArgs, 'limit' | 'skip'>
  >;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  publishedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  publishedBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<TextListPublishedByArgs>
  >;
  scheduledIn?: Resolver<
    Array<ResolversTypes['ScheduledOperation']>,
    ParentType,
    ContextType,
    Partial<TextListScheduledInArgs>
  >;
  stage?: Resolver<ResolversTypes['Stage'], ParentType, ContextType>;
  textGroups?: Resolver<
    Array<ResolversTypes['TextGroup']>,
    ParentType,
    ContextType,
    Partial<TextListTextGroupsArgs>
  >;
  texts?: Resolver<
    Array<ResolversTypes['Text']>,
    ParentType,
    ContextType,
    Partial<TextListTextsArgs>
  >;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updatedBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<TextListUpdatedByArgs>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TextListConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TextListConnection'] = ResolversParentTypes['TextListConnection']
> = {
  aggregate?: Resolver<ResolversTypes['Aggregate'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['TextListEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TextListEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TextListEdge'] = ResolversParentTypes['TextListEdge']
> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['TextList'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TicketGroupResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TicketGroup'] = ResolversParentTypes['TicketGroup']
> = {
  attractionLineup?: Resolver<
    Maybe<ResolversTypes['ProductAttractionLineup']>,
    ParentType,
    ContextType,
    Partial<TicketGroupAttractionLineupArgs>
  >;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<TicketGroupCreatedByArgs>
  >;
  documentInStages?: Resolver<
    Array<ResolversTypes['TicketGroup']>,
    ParentType,
    ContextType,
    RequireFields<TicketGroupDocumentInStagesArgs, 'includeCurrent' | 'inheritLocale' | 'stages'>
  >;
  history?: Resolver<
    Array<ResolversTypes['Version']>,
    ParentType,
    ContextType,
    RequireFields<TicketGroupHistoryArgs, 'limit' | 'skip'>
  >;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  key?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  logo?: Resolver<
    Maybe<ResolversTypes['Asset']>,
    ParentType,
    ContextType,
    Partial<TicketGroupLogoArgs>
  >;
  product?: Resolver<
    Maybe<ResolversTypes['Product']>,
    ParentType,
    ContextType,
    Partial<TicketGroupProductArgs>
  >;
  publishedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  publishedBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<TicketGroupPublishedByArgs>
  >;
  scheduledIn?: Resolver<
    Array<ResolversTypes['ScheduledOperation']>,
    ParentType,
    ContextType,
    Partial<TicketGroupScheduledInArgs>
  >;
  skus?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  stage?: Resolver<ResolversTypes['Stage'], ParentType, ContextType>;
  subtitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updatedBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<TicketGroupUpdatedByArgs>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TicketGroupConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TicketGroupConnection'] = ResolversParentTypes['TicketGroupConnection']
> = {
  aggregate?: Resolver<ResolversTypes['Aggregate'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['TicketGroupEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TicketGroupEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TicketGroupEdge'] = ResolversParentTypes['TicketGroupEdge']
> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['TicketGroup'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TitleDescriptionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TitleDescription'] = ResolversParentTypes['TitleDescription']
> = {
  attractionAlacarteCustomInstructions?: Resolver<
    Maybe<ResolversTypes['AttractionAlacarte']>,
    ParentType,
    ContextType,
    Partial<TitleDescriptionAttractionAlacarteCustomInstructionsArgs>
  >;
  createdAt?: Resolver<
    ResolversTypes['DateTime'],
    ParentType,
    ContextType,
    RequireFields<TitleDescriptionCreatedAtArgs, 'variation'>
  >;
  createdBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<TitleDescriptionCreatedByArgs>
  >;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  documentInStages?: Resolver<
    Array<ResolversTypes['TitleDescription']>,
    ParentType,
    ContextType,
    RequireFields<
      TitleDescriptionDocumentInStagesArgs,
      'includeCurrent' | 'inheritLocale' | 'stages'
    >
  >;
  history?: Resolver<
    Array<ResolversTypes['Version']>,
    ParentType,
    ContextType,
    RequireFields<TitleDescriptionHistoryArgs, 'limit' | 'skip'>
  >;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  locale?: Resolver<ResolversTypes['Locale'], ParentType, ContextType>;
  localizations?: Resolver<
    Array<ResolversTypes['TitleDescription']>,
    ParentType,
    ContextType,
    RequireFields<TitleDescriptionLocalizationsArgs, 'includeCurrent' | 'locales'>
  >;
  publishedAt?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType,
    RequireFields<TitleDescriptionPublishedAtArgs, 'variation'>
  >;
  publishedBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<TitleDescriptionPublishedByArgs>
  >;
  qabu?: Resolver<Maybe<ResolversTypes['Json']>, ParentType, ContextType>;
  scheduledIn?: Resolver<
    Array<ResolversTypes['ScheduledOperation']>,
    ParentType,
    ContextType,
    Partial<TitleDescriptionScheduledInArgs>
  >;
  stage?: Resolver<ResolversTypes['Stage'], ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<
    ResolversTypes['DateTime'],
    ParentType,
    ContextType,
    RequireFields<TitleDescriptionUpdatedAtArgs, 'variation'>
  >;
  updatedBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<TitleDescriptionUpdatedByArgs>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TitleDescriptionConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TitleDescriptionConnection'] = ResolversParentTypes['TitleDescriptionConnection']
> = {
  aggregate?: Resolver<ResolversTypes['Aggregate'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['TitleDescriptionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TitleDescriptionEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TitleDescriptionEdge'] = ResolversParentTypes['TitleDescriptionEdge']
> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['TitleDescription'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['User'] = ResolversParentTypes['User']
> = {
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  documentInStages?: Resolver<
    Array<ResolversTypes['User']>,
    ParentType,
    ContextType,
    RequireFields<UserDocumentInStagesArgs, 'includeCurrent' | 'inheritLocale' | 'stages'>
  >;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isActive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  kind?: Resolver<ResolversTypes['UserKind'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  picture?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  publishedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  stage?: Resolver<ResolversTypes['Stage'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserConnection'] = ResolversParentTypes['UserConnection']
> = {
  aggregate?: Resolver<ResolversTypes['Aggregate'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['UserEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserEdge'] = ResolversParentTypes['UserEdge']
> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VersionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Version'] = ResolversParentTypes['Version']
> = {
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  revision?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  stage?: Resolver<ResolversTypes['Stage'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VideoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Video'] = ResolversParentTypes['Video']
> = {
  attraction?: Resolver<
    Maybe<ResolversTypes['Attraction']>,
    ParentType,
    ContextType,
    Partial<VideoAttractionArgs>
  >;
  attractionVariants?: Resolver<
    Array<ResolversTypes['AttractionVariant']>,
    ParentType,
    ContextType,
    Partial<VideoAttractionVariantsArgs>
  >;
  caption?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<
    ResolversTypes['DateTime'],
    ParentType,
    ContextType,
    RequireFields<VideoCreatedAtArgs, 'variation'>
  >;
  createdBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<VideoCreatedByArgs>
  >;
  documentInStages?: Resolver<
    Array<ResolversTypes['Video']>,
    ParentType,
    ContextType,
    RequireFields<VideoDocumentInStagesArgs, 'includeCurrent' | 'inheritLocale' | 'stages'>
  >;
  history?: Resolver<
    Array<ResolversTypes['Version']>,
    ParentType,
    ContextType,
    RequireFields<VideoHistoryArgs, 'limit' | 'skip'>
  >;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  internalTitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  locale?: Resolver<ResolversTypes['Locale'], ParentType, ContextType>;
  localizations?: Resolver<
    Array<ResolversTypes['Video']>,
    ParentType,
    ContextType,
    RequireFields<VideoLocalizationsArgs, 'includeCurrent' | 'locales'>
  >;
  products?: Resolver<
    Array<ResolversTypes['Product']>,
    ParentType,
    ContextType,
    Partial<VideoProductsArgs>
  >;
  publishedAt?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType,
    RequireFields<VideoPublishedAtArgs, 'variation'>
  >;
  publishedBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<VideoPublishedByArgs>
  >;
  qabu?: Resolver<Maybe<ResolversTypes['Json']>, ParentType, ContextType>;
  scheduledIn?: Resolver<
    Array<ResolversTypes['ScheduledOperation']>,
    ParentType,
    ContextType,
    Partial<VideoScheduledInArgs>
  >;
  stage?: Resolver<ResolversTypes['Stage'], ParentType, ContextType>;
  thumb?: Resolver<
    Maybe<ResolversTypes['Asset']>,
    ParentType,
    ContextType,
    Partial<VideoThumbArgs>
  >;
  updatedAt?: Resolver<
    ResolversTypes['DateTime'],
    ParentType,
    ContextType,
    RequireFields<VideoUpdatedAtArgs, 'variation'>
  >;
  updatedBy?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    Partial<VideoUpdatedByArgs>
  >;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VideoConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['VideoConnection'] = ResolversParentTypes['VideoConnection']
> = {
  aggregate?: Resolver<ResolversTypes['Aggregate'], ParentType, ContextType>;
  edges?: Resolver<Array<ResolversTypes['VideoEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VideoEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['VideoEdge'] = ResolversParentTypes['VideoEdge']
> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['Video'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Resolvers<ContextType = any> = {
  AddOn?: AddOnResolvers<ContextType>;
  AddOnConnection?: AddOnConnectionResolvers<ContextType>;
  AddOnEdge?: AddOnEdgeResolvers<ContextType>;
  Aggregate?: AggregateResolvers<ContextType>;
  AlacarteTicket?: AlacarteTicketResolvers<ContextType>;
  AlacarteTicketConnection?: AlacarteTicketConnectionResolvers<ContextType>;
  AlacarteTicketEdge?: AlacarteTicketEdgeResolvers<ContextType>;
  AlacarteTicketType?: AlacarteTicketTypeResolvers<ContextType>;
  AlacarteTicketTypeConnection?: AlacarteTicketTypeConnectionResolvers<ContextType>;
  AlacarteTicketTypeEdge?: AlacarteTicketTypeEdgeResolvers<ContextType>;
  Article?: ArticleResolvers<ContextType>;
  ArticleCategory?: ArticleCategoryResolvers<ContextType>;
  ArticleCategoryConnection?: ArticleCategoryConnectionResolvers<ContextType>;
  ArticleCategoryEdge?: ArticleCategoryEdgeResolvers<ContextType>;
  ArticleConnection?: ArticleConnectionResolvers<ContextType>;
  ArticleEdge?: ArticleEdgeResolvers<ContextType>;
  Asset?: AssetResolvers<ContextType>;
  AssetConnection?: AssetConnectionResolvers<ContextType>;
  AssetEdge?: AssetEdgeResolvers<ContextType>;
  AssetVariant?: AssetVariantResolvers<ContextType>;
  AssetVariantConnection?: AssetVariantConnectionResolvers<ContextType>;
  AssetVariantEdge?: AssetVariantEdgeResolvers<ContextType>;
  AssetVariantParent?: AssetVariantParentResolvers<ContextType>;
  Attraction?: AttractionResolvers<ContextType>;
  AttractionAlacarte?: AttractionAlacarteResolvers<ContextType>;
  AttractionAlacarteConnection?: AttractionAlacarteConnectionResolvers<ContextType>;
  AttractionAlacarteEdge?: AttractionAlacarteEdgeResolvers<ContextType>;
  AttractionAvailability?: AttractionAvailabilityResolvers<ContextType>;
  AttractionAvailabilityConnection?: AttractionAvailabilityConnectionResolvers<ContextType>;
  AttractionAvailabilityEdge?: AttractionAvailabilityEdgeResolvers<ContextType>;
  AttractionConnection?: AttractionConnectionResolvers<ContextType>;
  AttractionEdge?: AttractionEdgeResolvers<ContextType>;
  AttractionGallery?: AttractionGalleryResolvers<ContextType>;
  AttractionGroup?: AttractionGroupResolvers<ContextType>;
  AttractionGroupConnection?: AttractionGroupConnectionResolvers<ContextType>;
  AttractionGroupEdge?: AttractionGroupEdgeResolvers<ContextType>;
  AttractionGroupParent?: AttractionGroupParentResolvers<ContextType>;
  AttractionLocation?: AttractionLocationResolvers<ContextType>;
  AttractionLocationConnection?: AttractionLocationConnectionResolvers<ContextType>;
  AttractionLocationEdge?: AttractionLocationEdgeResolvers<ContextType>;
  AttractionOffer?: AttractionOfferResolvers<ContextType>;
  AttractionOfferConnection?: AttractionOfferConnectionResolvers<ContextType>;
  AttractionOfferEdge?: AttractionOfferEdgeResolvers<ContextType>;
  AttractionPrice?: AttractionPriceResolvers<ContextType>;
  AttractionPriceConnection?: AttractionPriceConnectionResolvers<ContextType>;
  AttractionPriceEdge?: AttractionPriceEdgeResolvers<ContextType>;
  AttractionReservation?: AttractionReservationResolvers<ContextType>;
  AttractionReservationConnection?: AttractionReservationConnectionResolvers<ContextType>;
  AttractionReservationEdge?: AttractionReservationEdgeResolvers<ContextType>;
  AttractionVariant?: AttractionVariantResolvers<ContextType>;
  AttractionVariantConnection?: AttractionVariantConnectionResolvers<ContextType>;
  AttractionVariantEdge?: AttractionVariantEdgeResolvers<ContextType>;
  AttractionVariantGallery?: AttractionVariantGalleryResolvers<ContextType>;
  Author?: AuthorResolvers<ContextType>;
  AuthorConnection?: AuthorConnectionResolvers<ContextType>;
  AuthorEdge?: AuthorEdgeResolvers<ContextType>;
  BatchPayload?: BatchPayloadResolvers<ContextType>;
  Block?: BlockResolvers<ContextType>;
  BlockConnection?: BlockConnectionResolvers<ContextType>;
  BlockEdge?: BlockEdgeResolvers<ContextType>;
  BlockGroup?: BlockGroupResolvers<ContextType>;
  BlockGroupConnection?: BlockGroupConnectionResolvers<ContextType>;
  BlockGroupEdge?: BlockGroupEdgeResolvers<ContextType>;
  BlockUI?: BlockUiResolvers<ContextType>;
  BlockUIConnection?: BlockUiConnectionResolvers<ContextType>;
  BlockUIEdge?: BlockUiEdgeResolvers<ContextType>;
  BlockUIParent?: BlockUiParentResolvers<ContextType>;
  Color?: ColorResolvers<ContextType>;
  CrossPromoBanner?: CrossPromoBannerResolvers<ContextType>;
  CrossPromoBannerConnection?: CrossPromoBannerConnectionResolvers<ContextType>;
  CrossPromoBannerEdge?: CrossPromoBannerEdgeResolvers<ContextType>;
  CrossPromoBannerParent?: CrossPromoBannerParentResolvers<ContextType>;
  Date?: GraphQLScalarType;
  DateRange?: DateRangeResolvers<ContextType>;
  DateRangeConnection?: DateRangeConnectionResolvers<ContextType>;
  DateRangeEdge?: DateRangeEdgeResolvers<ContextType>;
  DateRangeParent?: DateRangeParentResolvers<ContextType>;
  DateTime?: GraphQLScalarType;
  DetailBlock?: DetailBlockResolvers<ContextType>;
  DetailBlockConnection?: DetailBlockConnectionResolvers<ContextType>;
  DetailBlockEdge?: DetailBlockEdgeResolvers<ContextType>;
  DocumentVersion?: DocumentVersionResolvers<ContextType>;
  GeneralReservation?: GeneralReservationResolvers<ContextType>;
  GeneralReservationConnection?: GeneralReservationConnectionResolvers<ContextType>;
  GeneralReservationEdge?: GeneralReservationEdgeResolvers<ContextType>;
  Hex?: GraphQLScalarType;
  Image?: ImageResolvers<ContextType>;
  ImageConnection?: ImageConnectionResolvers<ContextType>;
  ImageEdge?: ImageEdgeResolvers<ContextType>;
  Json?: GraphQLScalarType;
  LandingPage?: LandingPageResolvers<ContextType>;
  LandingPageBodyRichRichText?: LandingPageBodyRichRichTextResolvers<ContextType>;
  LandingPageBodyRichRichTextEmbeddedTypes?: LandingPageBodyRichRichTextEmbeddedTypesResolvers<ContextType>;
  LandingPageConnection?: LandingPageConnectionResolvers<ContextType>;
  LandingPageEdge?: LandingPageEdgeResolvers<ContextType>;
  Link?: LinkResolvers<ContextType>;
  LinkConnection?: LinkConnectionResolvers<ContextType>;
  LinkEdge?: LinkEdgeResolvers<ContextType>;
  Location?: LocationResolvers<ContextType>;
  Long?: GraphQLScalarType;
  Menu?: MenuResolvers<ContextType>;
  MenuConnection?: MenuConnectionResolvers<ContextType>;
  MenuEdge?: MenuEdgeResolvers<ContextType>;
  MenuItems?: MenuItemsResolvers<ContextType>;
  Mutation?: MutationResolvers<ContextType>;
  Node?: NodeResolvers<ContextType>;
  Notice?: NoticeResolvers<ContextType>;
  NoticeConnection?: NoticeConnectionResolvers<ContextType>;
  NoticeEdge?: NoticeEdgeResolvers<ContextType>;
  Page?: PageResolvers<ContextType>;
  PageConnection?: PageConnectionResolvers<ContextType>;
  PageEdge?: PageEdgeResolvers<ContextType>;
  PageInfo?: PageInfoResolvers<ContextType>;
  PageTemplate?: PageTemplateResolvers<ContextType>;
  PageTemplateConnection?: PageTemplateConnectionResolvers<ContextType>;
  PageTemplateEdge?: PageTemplateEdgeResolvers<ContextType>;
  Partner?: PartnerResolvers<ContextType>;
  PartnerConnection?: PartnerConnectionResolvers<ContextType>;
  PartnerEdge?: PartnerEdgeResolvers<ContextType>;
  PartnerOffer?: PartnerOfferResolvers<ContextType>;
  PartnerOfferConnection?: PartnerOfferConnectionResolvers<ContextType>;
  PartnerOfferEdge?: PartnerOfferEdgeResolvers<ContextType>;
  Product?: ProductResolvers<ContextType>;
  ProductActivity?: ProductActivityResolvers<ContextType>;
  ProductActivityConnection?: ProductActivityConnectionResolvers<ContextType>;
  ProductActivityEdge?: ProductActivityEdgeResolvers<ContextType>;
  ProductActivityItem?: ProductActivityItemResolvers<ContextType>;
  ProductActivityItemConnection?: ProductActivityItemConnectionResolvers<ContextType>;
  ProductActivityItemEdge?: ProductActivityItemEdgeResolvers<ContextType>;
  ProductAttractionLineup?: ProductAttractionLineupResolvers<ContextType>;
  ProductAttractionLineupConnection?: ProductAttractionLineupConnectionResolvers<ContextType>;
  ProductAttractionLineupEdge?: ProductAttractionLineupEdgeResolvers<ContextType>;
  ProductConnection?: ProductConnectionResolvers<ContextType>;
  ProductEdge?: ProductEdgeResolvers<ContextType>;
  ProductReservation?: ProductReservationResolvers<ContextType>;
  ProductReservationConnection?: ProductReservationConnectionResolvers<ContextType>;
  ProductReservationEdge?: ProductReservationEdgeResolvers<ContextType>;
  Promo?: PromoResolvers<ContextType>;
  PromoBanner?: PromoBannerResolvers<ContextType>;
  PromoBannerConnection?: PromoBannerConnectionResolvers<ContextType>;
  PromoBannerEdge?: PromoBannerEdgeResolvers<ContextType>;
  PromoBannerParent?: PromoBannerParentResolvers<ContextType>;
  PromoConnection?: PromoConnectionResolvers<ContextType>;
  PromoEdge?: PromoEdgeResolvers<ContextType>;
  PromoText?: PromoTextResolvers<ContextType>;
  PromoTextConnection?: PromoTextConnectionResolvers<ContextType>;
  PromoTextEdge?: PromoTextEdgeResolvers<ContextType>;
  PromoTextParent?: PromoTextParentResolvers<ContextType>;
  PromoTicketBanner?: PromoTicketBannerResolvers<ContextType>;
  PromoTicketBannerConnection?: PromoTicketBannerConnectionResolvers<ContextType>;
  PromoTicketBannerEdge?: PromoTicketBannerEdgeResolvers<ContextType>;
  PromoTicketBannerParent?: PromoTicketBannerParentResolvers<ContextType>;
  PromoTicketText?: PromoTicketTextResolvers<ContextType>;
  PromoTicketTextConnection?: PromoTicketTextConnectionResolvers<ContextType>;
  PromoTicketTextEdge?: PromoTicketTextEdgeResolvers<ContextType>;
  PromoTicketTextParent?: PromoTicketTextParentResolvers<ContextType>;
  PromotemplateUnion?: PromotemplateUnionResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  RGBA?: RgbaResolvers<ContextType>;
  RGBAHue?: GraphQLScalarType;
  RGBATransparency?: GraphQLScalarType;
  ReservationText?: ReservationTextResolvers<ContextType>;
  ReservationTextConnection?: ReservationTextConnectionResolvers<ContextType>;
  ReservationTextEdge?: ReservationTextEdgeResolvers<ContextType>;
  RichText?: RichTextResolvers<ContextType>;
  RichTextAST?: GraphQLScalarType;
  Sandbox?: SandboxResolvers<ContextType>;
  SandboxConnection?: SandboxConnectionResolvers<ContextType>;
  SandboxEdge?: SandboxEdgeResolvers<ContextType>;
  ScheduledOperation?: ScheduledOperationResolvers<ContextType>;
  ScheduledOperationAffectedDocument?: ScheduledOperationAffectedDocumentResolvers<ContextType>;
  ScheduledOperationConnection?: ScheduledOperationConnectionResolvers<ContextType>;
  ScheduledOperationEdge?: ScheduledOperationEdgeResolvers<ContextType>;
  ScheduledRelease?: ScheduledReleaseResolvers<ContextType>;
  ScheduledReleaseConnection?: ScheduledReleaseConnectionResolvers<ContextType>;
  ScheduledReleaseEdge?: ScheduledReleaseEdgeResolvers<ContextType>;
  Seo?: SeoResolvers<ContextType>;
  SeoConnection?: SeoConnectionResolvers<ContextType>;
  SeoEdge?: SeoEdgeResolvers<ContextType>;
  SkuProperty?: SkuPropertyResolvers<ContextType>;
  SkuPropertyConnection?: SkuPropertyConnectionResolvers<ContextType>;
  SkuPropertyEdge?: SkuPropertyEdgeResolvers<ContextType>;
  SkuPropertyParent?: SkuPropertyParentResolvers<ContextType>;
  Text?: TextResolvers<ContextType>;
  TextConnection?: TextConnectionResolvers<ContextType>;
  TextEdge?: TextEdgeResolvers<ContextType>;
  TextGroup?: TextGroupResolvers<ContextType>;
  TextGroupConnection?: TextGroupConnectionResolvers<ContextType>;
  TextGroupEdge?: TextGroupEdgeResolvers<ContextType>;
  TextGroupText?: TextGroupTextResolvers<ContextType>;
  TextList?: TextListResolvers<ContextType>;
  TextListConnection?: TextListConnectionResolvers<ContextType>;
  TextListEdge?: TextListEdgeResolvers<ContextType>;
  TicketGroup?: TicketGroupResolvers<ContextType>;
  TicketGroupConnection?: TicketGroupConnectionResolvers<ContextType>;
  TicketGroupEdge?: TicketGroupEdgeResolvers<ContextType>;
  TitleDescription?: TitleDescriptionResolvers<ContextType>;
  TitleDescriptionConnection?: TitleDescriptionConnectionResolvers<ContextType>;
  TitleDescriptionEdge?: TitleDescriptionEdgeResolvers<ContextType>;
  User?: UserResolvers<ContextType>;
  UserConnection?: UserConnectionResolvers<ContextType>;
  UserEdge?: UserEdgeResolvers<ContextType>;
  Version?: VersionResolvers<ContextType>;
  Video?: VideoResolvers<ContextType>;
  VideoConnection?: VideoConnectionResolvers<ContextType>;
  VideoEdge?: VideoEdgeResolvers<ContextType>;
};

export type AllProductPropsFragment = {
  __typename?: 'Product';
  key: string | null;
  name: string | null;
  type: ProductType | null;
  slug: string;
  ageAdult: string | null;
  ageChild: string | null;
  validDays: number | null;
  cityName: { __typename?: 'Text'; value: string | null } | null;
  hero: { __typename?: 'Asset'; url: string; width: number | null; height: number | null } | null;
  logo: { __typename?: 'Asset'; url: string; width: number | null; height: number | null } | null;
  logoWhite: {
    __typename?: 'Asset';
    url: string;
    width: number | null;
    height: number | null;
  } | null;
  logoSpotOutline: { __typename?: 'Asset'; url: string } | null;
  logoTicket: {
    __typename?: 'Asset';
    url: string;
    width: number | null;
    height: number | null;
  } | null;
  admissionTitle: { __typename?: 'Text'; value: string | null } | null;
  attractionLineups: Array<{
    __typename?: 'ProductAttractionLineup';
    attractionGroups: Array<{
      __typename?: 'AttractionGroup';
      attractions: Array<{ __typename?: 'Attraction'; key: string }>;
    }>;
  }>;
  attractions: Array<{
    __typename?: 'Attraction';
    key: string;
    name: string | null;
    intro: string | null;
    shortIntro: string | null;
    exclusiveFeature: string | null;
    accessibility: string | null;
    bestTime: string | null;
    parking: string | null;
    thumb: { __typename?: 'Asset'; url: string } | null;
    hero: { __typename?: 'Asset'; url: string } | null;
    admission: Array<{ __typename?: 'Text'; value: string | null }>;
    highlights: Array<{ __typename?: 'Text'; value: string | null }>;
    openStatus: { __typename?: 'Text'; value: string | null } | null;
    tips: Array<{ __typename?: 'Text'; value: string | null }>;
    safety: Array<{ __typename?: 'Text'; value: string | null }>;
    amenities: Array<{ __typename?: 'Text'; value: string | null }>;
    logo: { __typename?: 'Asset'; url: string } | null;
    locations: Array<{
      __typename?: 'AttractionLocation';
      name: string | null;
      address: Array<string>;
      addressNote: string | null;
      hours: Array<string>;
      website: string | null;
      phone: Array<string>;
      geolocation: { __typename?: 'Location'; latitude: number; longitude: number } | null;
      hoursNotes: Array<{ __typename?: 'Text'; value: string | null }>;
    }>;
    gallery: Array<
      | {
          __typename: 'Image';
          caption: string | null;
          credit: string | null;
          image: { __typename?: 'Asset'; url: string };
        }
      | { __typename: 'Video'; url: string | null; caption: string | null }
    >;
    offers: Array<{
      __typename?: 'AttractionOffer';
      id: string;
      internalTitle: string;
      title: string | null;
      description: string | null;
      conditions: string | null;
      code: string | null;
      type: AttractionOfferType;
      startDate: any;
      endDate: any | null;
      expirationDate: any | null;
      platforms: Array<Platforms>;
      products: Array<{ __typename?: 'Product'; key: string | null }>;
      attraction: { __typename?: 'Attraction'; key: string } | null;
    }>;
    variants: Array<{
      __typename?: 'AttractionVariant';
      name: string | null;
      intro: string | null;
      shortIntro: string | null;
      exclusiveFeature: string | null;
      product: { __typename?: 'Product'; key: string | null; type: ProductType | null } | null;
      admission: Array<{ __typename?: 'Text'; value: string | null }>;
    }>;
    reservation: {
      __typename?: 'AttractionReservation';
      attraction: { __typename?: 'Attraction'; key: string } | null;
      texts: Array<{
        __typename?: 'Text';
        key: string;
        value: string | null;
        products: Array<{ __typename?: 'Product'; key: string | null }>;
      }>;
    } | null;
  }>;
  reservation: {
    __typename?: 'ProductReservation';
    texts: Array<{ __typename?: 'Text'; key: string; value: string | null }>;
    ticketsBackground: {
      __typename?: 'Asset';
      url: string;
      width: number | null;
      height: number | null;
    } | null;
  } | null;
  partnerOffers: Array<{
    __typename?: 'PartnerOffer';
    id: string;
    campaignId: string;
    title: string | null;
    description: string | null;
    startDate: any;
    endDate: any;
    image: { __typename?: 'Asset'; url: string; width: number | null; height: number | null };
  }>;
};

export type ImagePropsFragment = {
  __typename?: 'Asset';
  url: string;
  width: number | null;
  height: number | null;
};

export type AllAttractionPropsFragment = {
  __typename?: 'Attraction';
  key: string;
  name: string | null;
  intro: string | null;
  shortIntro: string | null;
  exclusiveFeature: string | null;
  accessibility: string | null;
  bestTime: string | null;
  parking: string | null;
  thumb: { __typename?: 'Asset'; url: string } | null;
  hero: { __typename?: 'Asset'; url: string } | null;
  admission: Array<{ __typename?: 'Text'; value: string | null }>;
  highlights: Array<{ __typename?: 'Text'; value: string | null }>;
  openStatus: { __typename?: 'Text'; value: string | null } | null;
  tips: Array<{ __typename?: 'Text'; value: string | null }>;
  safety: Array<{ __typename?: 'Text'; value: string | null }>;
  amenities: Array<{ __typename?: 'Text'; value: string | null }>;
  logo: { __typename?: 'Asset'; url: string } | null;
  locations: Array<{
    __typename?: 'AttractionLocation';
    name: string | null;
    address: Array<string>;
    addressNote: string | null;
    hours: Array<string>;
    website: string | null;
    phone: Array<string>;
    geolocation: { __typename?: 'Location'; latitude: number; longitude: number } | null;
    hoursNotes: Array<{ __typename?: 'Text'; value: string | null }>;
  }>;
  gallery: Array<
    | {
        __typename: 'Image';
        caption: string | null;
        credit: string | null;
        image: { __typename?: 'Asset'; url: string };
      }
    | { __typename: 'Video'; url: string | null; caption: string | null }
  >;
  offers: Array<{
    __typename?: 'AttractionOffer';
    id: string;
    internalTitle: string;
    title: string | null;
    description: string | null;
    conditions: string | null;
    code: string | null;
    type: AttractionOfferType;
    startDate: any;
    endDate: any | null;
    expirationDate: any | null;
    platforms: Array<Platforms>;
    products: Array<{ __typename?: 'Product'; key: string | null }>;
    attraction: { __typename?: 'Attraction'; key: string } | null;
  }>;
  variants: Array<{
    __typename?: 'AttractionVariant';
    name: string | null;
    intro: string | null;
    shortIntro: string | null;
    exclusiveFeature: string | null;
    product: { __typename?: 'Product'; key: string | null; type: ProductType | null } | null;
    admission: Array<{ __typename?: 'Text'; value: string | null }>;
  }>;
  reservation: {
    __typename?: 'AttractionReservation';
    attraction: { __typename?: 'Attraction'; key: string } | null;
    texts: Array<{
      __typename?: 'Text';
      key: string;
      value: string | null;
      products: Array<{ __typename?: 'Product'; key: string | null }>;
    }>;
  } | null;
};

export type GetDestinationsQueryVariables = Exact<{
  locale: Locale;
}>;

export type GetDestinationsQuery = {
  __typename?: 'Query';
  products: Array<{
    __typename?: 'Product';
    key: string | null;
    name: string | null;
    type: ProductType | null;
    slug: string;
    cityName: { __typename?: 'Text'; value: string | null } | null;
    thumb: {
      __typename?: 'Asset';
      url: string;
      width: number | null;
      height: number | null;
    } | null;
    logoSpot: {
      __typename?: 'Asset';
      url: string;
      width: number | null;
      height: number | null;
      mimeType: string | null;
    } | null;
  }>;
};

export type GetContentQueryVariables = Exact<{
  locale: Locale;
}>;

export type GetContentQuery = {
  __typename?: 'Query';
  textGroups: Array<{
    __typename?: 'TextGroup';
    key: string | null;
    text: Array<
      | { __typename?: 'Text'; key: string; value: string | null; locale: Locale }
      | {
          __typename?: 'TextList';
          key: string;
          texts: Array<{ __typename?: 'Text'; value: string | null; locale: Locale }>;
        }
    >;
  }>;
};

export type GetProductQueryVariables = Exact<{
  productId: Scalars['String'];
  locale: Locale;
  today: Scalars['Date'];
}>;

export type GetProductQuery = {
  __typename?: 'Query';
  product: {
    __typename?: 'Product';
    key: string | null;
    name: string | null;
    type: ProductType | null;
    slug: string;
    ageAdult: string | null;
    ageChild: string | null;
    validDays: number | null;
    cityName: { __typename?: 'Text'; value: string | null } | null;
    hero: { __typename?: 'Asset'; url: string; width: number | null; height: number | null } | null;
    logo: { __typename?: 'Asset'; url: string; width: number | null; height: number | null } | null;
    logoWhite: {
      __typename?: 'Asset';
      url: string;
      width: number | null;
      height: number | null;
    } | null;
    logoSpotOutline: { __typename?: 'Asset'; url: string } | null;
    logoTicket: {
      __typename?: 'Asset';
      url: string;
      width: number | null;
      height: number | null;
    } | null;
    admissionTitle: { __typename?: 'Text'; value: string | null } | null;
    attractionLineups: Array<{
      __typename?: 'ProductAttractionLineup';
      attractionGroups: Array<{
        __typename?: 'AttractionGroup';
        attractions: Array<{ __typename?: 'Attraction'; key: string }>;
      }>;
    }>;
    attractions: Array<{
      __typename?: 'Attraction';
      key: string;
      name: string | null;
      intro: string | null;
      shortIntro: string | null;
      exclusiveFeature: string | null;
      accessibility: string | null;
      bestTime: string | null;
      parking: string | null;
      thumb: { __typename?: 'Asset'; url: string } | null;
      hero: { __typename?: 'Asset'; url: string } | null;
      admission: Array<{ __typename?: 'Text'; value: string | null }>;
      highlights: Array<{ __typename?: 'Text'; value: string | null }>;
      openStatus: { __typename?: 'Text'; value: string | null } | null;
      tips: Array<{ __typename?: 'Text'; value: string | null }>;
      safety: Array<{ __typename?: 'Text'; value: string | null }>;
      amenities: Array<{ __typename?: 'Text'; value: string | null }>;
      logo: { __typename?: 'Asset'; url: string } | null;
      locations: Array<{
        __typename?: 'AttractionLocation';
        name: string | null;
        address: Array<string>;
        addressNote: string | null;
        hours: Array<string>;
        website: string | null;
        phone: Array<string>;
        geolocation: { __typename?: 'Location'; latitude: number; longitude: number } | null;
        hoursNotes: Array<{ __typename?: 'Text'; value: string | null }>;
      }>;
      gallery: Array<
        | {
            __typename: 'Image';
            caption: string | null;
            credit: string | null;
            image: { __typename?: 'Asset'; url: string };
          }
        | { __typename: 'Video'; url: string | null; caption: string | null }
      >;
      offers: Array<{
        __typename?: 'AttractionOffer';
        id: string;
        internalTitle: string;
        title: string | null;
        description: string | null;
        conditions: string | null;
        code: string | null;
        type: AttractionOfferType;
        startDate: any;
        endDate: any | null;
        expirationDate: any | null;
        platforms: Array<Platforms>;
        products: Array<{ __typename?: 'Product'; key: string | null }>;
        attraction: { __typename?: 'Attraction'; key: string } | null;
      }>;
      variants: Array<{
        __typename?: 'AttractionVariant';
        name: string | null;
        intro: string | null;
        shortIntro: string | null;
        exclusiveFeature: string | null;
        product: { __typename?: 'Product'; key: string | null; type: ProductType | null } | null;
        admission: Array<{ __typename?: 'Text'; value: string | null }>;
      }>;
      reservation: {
        __typename?: 'AttractionReservation';
        attraction: { __typename?: 'Attraction'; key: string } | null;
        texts: Array<{
          __typename?: 'Text';
          key: string;
          value: string | null;
          products: Array<{ __typename?: 'Product'; key: string | null }>;
        }>;
      } | null;
    }>;
    reservation: {
      __typename?: 'ProductReservation';
      texts: Array<{ __typename?: 'Text'; key: string; value: string | null }>;
      ticketsBackground: {
        __typename?: 'Asset';
        url: string;
        width: number | null;
        height: number | null;
      } | null;
    } | null;
    partnerOffers: Array<{
      __typename?: 'PartnerOffer';
      id: string;
      campaignId: string;
      title: string | null;
      description: string | null;
      startDate: any;
      endDate: any;
      image: { __typename?: 'Asset'; url: string; width: number | null; height: number | null };
    }>;
  } | null;
  faqs: {
    __typename?: 'TextList';
    key: string;
    texts: Array<{ __typename?: 'Text'; value: string | null }>;
  } | null;
};

export type GetAttractionQueryVariables = Exact<{
  locale: Locale;
  attrId: Scalars['String'];
  today: Scalars['Date'];
}>;

export type GetAttractionQuery = {
  __typename?: 'Query';
  attraction: {
    __typename?: 'Attraction';
    key: string;
    name: string | null;
    intro: string | null;
    shortIntro: string | null;
    exclusiveFeature: string | null;
    accessibility: string | null;
    bestTime: string | null;
    parking: string | null;
    thumb: { __typename?: 'Asset'; url: string } | null;
    hero: { __typename?: 'Asset'; url: string } | null;
    admission: Array<{ __typename?: 'Text'; value: string | null }>;
    highlights: Array<{ __typename?: 'Text'; value: string | null }>;
    openStatus: { __typename?: 'Text'; value: string | null } | null;
    tips: Array<{ __typename?: 'Text'; value: string | null }>;
    safety: Array<{ __typename?: 'Text'; value: string | null }>;
    amenities: Array<{ __typename?: 'Text'; value: string | null }>;
    logo: { __typename?: 'Asset'; url: string } | null;
    locations: Array<{
      __typename?: 'AttractionLocation';
      name: string | null;
      address: Array<string>;
      addressNote: string | null;
      hours: Array<string>;
      website: string | null;
      phone: Array<string>;
      geolocation: { __typename?: 'Location'; latitude: number; longitude: number } | null;
      hoursNotes: Array<{ __typename?: 'Text'; value: string | null }>;
    }>;
    gallery: Array<
      | {
          __typename: 'Image';
          caption: string | null;
          credit: string | null;
          image: { __typename?: 'Asset'; url: string };
        }
      | { __typename: 'Video'; url: string | null; caption: string | null }
    >;
    offers: Array<{
      __typename?: 'AttractionOffer';
      id: string;
      internalTitle: string;
      title: string | null;
      description: string | null;
      conditions: string | null;
      code: string | null;
      type: AttractionOfferType;
      startDate: any;
      endDate: any | null;
      expirationDate: any | null;
      platforms: Array<Platforms>;
      products: Array<{ __typename?: 'Product'; key: string | null }>;
      attraction: { __typename?: 'Attraction'; key: string } | null;
    }>;
    variants: Array<{
      __typename?: 'AttractionVariant';
      name: string | null;
      intro: string | null;
      shortIntro: string | null;
      exclusiveFeature: string | null;
      product: { __typename?: 'Product'; key: string | null; type: ProductType | null } | null;
      admission: Array<{ __typename?: 'Text'; value: string | null }>;
    }>;
    reservation: {
      __typename?: 'AttractionReservation';
      attraction: { __typename?: 'Attraction'; key: string } | null;
      texts: Array<{
        __typename?: 'Text';
        key: string;
        value: string | null;
        products: Array<{ __typename?: 'Product'; key: string | null }>;
      }>;
    } | null;
  } | null;
};

export type GetAttractionsQueryVariables = Exact<{
  attrIds: Array<Scalars['String']> | Scalars['String'];
}>;

export type GetAttractionsQuery = {
  __typename?: 'Query';
  attractions: Array<{
    __typename?: 'Attraction';
    key: string;
    name: string | null;
    hero: { __typename?: 'Asset'; url: string } | null;
  }>;
};

export type GetAssetsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAssetsQuery = {
  __typename?: 'Query';
  authHelp: {
    __typename?: 'Asset';
    url: string;
    width: number | null;
    height: number | null;
  } | null;
  productBroadwayPromo: {
    __typename?: 'Asset';
    url: string;
    width: number | null;
    height: number | null;
  } | null;
  productSocialShare: {
    __typename?: 'Asset';
    url: string;
    width: number | null;
    height: number | null;
  } | null;
  productOffers: {
    __typename?: 'Asset';
    url: string;
    width: number | null;
    height: number | null;
  } | null;
};

export type GetProductScreenBlocksQueryVariables = Exact<{
  locale?: Locale;
}>;

export type GetProductScreenBlocksQuery = {
  __typename?: 'Query';
  blockGroup: {
    __typename?: 'BlockGroup';
    id: string;
    key: string;
    blocks: Array<{
      __typename?: 'Block';
      id: string;
      url: string | null;
      title: { __typename?: 'Text'; key: string; value: string | null } | null;
      body: { __typename?: 'Text'; key: string; value: string | null } | null;
      cta: { __typename?: 'Text'; key: string; value: string | null } | null;
      image: {
        __typename?: 'Asset';
        url: string;
        width: number | null;
        height: number | null;
      } | null;
      ui: Array<{
        __typename?: 'BlockUI';
        component: string | null;
        fullWidth: boolean;
        colorScheme: string;
        align: string;
        props: any | null;
        analyticsEvent: string | null;
      }>;
    }>;
  } | null;
};

export type GetNoticesQueryVariables = Exact<{
  locale?: Locale;
  today: Scalars['Date'];
}>;

export type GetNoticesQuery = {
  __typename?: 'Query';
  notices: Array<{
    __typename?: 'Notice';
    id: string;
    key: string;
    title: string | null;
    description: string | null;
    startDate: any | null;
    endDate: any | null;
    theme: NoticeTheme;
    defaultOpen: boolean;
    placements: Array<NoticePlacements>;
    updatedAt: any;
    products: Array<{ __typename?: 'Product'; key: string | null; name: string | null }>;
    attractions: Array<{ __typename?: 'Attraction'; key: string; name: string | null }>;
  }>;
};

export type GetAllContentQueryVariables = Exact<{
  guestCenterImportTag: Scalars['String'];
}>;

export type GetAllContentQuery = {
  __typename?: 'Query';
  products: Array<{
    __typename?: 'Product';
    key: string | null;
    name: string | null;
    type: ProductType | null;
    reservation: { __typename?: 'ProductReservation'; id: string } | null;
  }>;
  attractions: Array<{
    __typename?: 'Attraction';
    key: string;
    name: string | null;
    products: Array<{ __typename?: 'Product'; key: string | null; type: ProductType | null }>;
    reservation: { __typename?: 'AttractionReservation'; id: string } | null;
  }>;
  texts: Array<{ __typename?: 'Text'; id: string; key: string; value: string | null }>;
};

export type CreateProductReservationMutationVariables = Exact<{
  data: ProductReservationCreateInput;
}>;

export type CreateProductReservationMutation = {
  __typename?: 'Mutation';
  createProductReservation: { __typename?: 'ProductReservation'; id: string } | null;
};

export type CreateAttractionReservationMutationVariables = Exact<{
  data: AttractionReservationCreateInput;
}>;

export type CreateAttractionReservationMutation = {
  __typename?: 'Mutation';
  createAttractionReservation: { __typename?: 'AttractionReservation'; id: string } | null;
};

export type DeleteReservationContentMutationVariables = Exact<{
  guestCenterImportTag: Scalars['String'];
}>;

export type DeleteReservationContentMutation = {
  __typename?: 'Mutation';
  texts: {
    __typename?: 'TextConnection';
    aggregate: { __typename?: 'Aggregate'; count: number };
    pageInfo: { __typename?: 'PageInfo'; hasNextPage: boolean };
  };
};

export type UpdateProductReservationMutationVariables = Exact<{
  where: ProductReservationWhereUniqueInput;
  data: ProductReservationUpdateInput;
}>;

export type UpdateProductReservationMutation = {
  __typename?: 'Mutation';
  updateProductReservation: { __typename?: 'ProductReservation'; id: string } | null;
};

export type UpdateAttractionReservationMutationVariables = Exact<{
  where: AttractionReservationWhereUniqueInput;
  data: AttractionReservationUpdateInput;
}>;

export type UpdateAttractionReservationMutation = {
  __typename?: 'Mutation';
  updateAttractionReservation: { __typename?: 'AttractionReservation'; id: string } | null;
};

export type UpsertTextGroupMutationVariables = Exact<{
  where: TextGroupWhereUniqueInput;
  upsert: TextGroupUpsertInput;
}>;

export type UpsertTextGroupMutation = {
  __typename?: 'Mutation';
  upsertTextGroup: { __typename?: 'TextGroup'; id: string } | null;
};

export type PublishProductAttractionReservationsMutationVariables = Exact<{ [key: string]: never }>;

export type PublishProductAttractionReservationsMutation = {
  __typename?: 'Mutation';
  publishManyGeneralReservationsConnection: {
    __typename?: 'GeneralReservationConnection';
    aggregate: { __typename?: 'Aggregate'; count: number };
  };
  publishManyProductReservationsConnection: {
    __typename?: 'ProductReservationConnection';
    aggregate: { __typename?: 'Aggregate'; count: number };
  };
  publishManyAttractionReservationsConnection: {
    __typename?: 'AttractionReservationConnection';
    aggregate: { __typename?: 'Aggregate'; count: number };
  };
  guestcenter: { __typename?: 'TextGroup'; id: string } | null;
  reservations: { __typename?: 'TextGroup'; id: string } | null;
};

export type PublishTextsMutationVariables = Exact<{
  count: Scalars['Int'];
  skip: Scalars['Int'];
  guestCenterImportTag: Scalars['String'];
}>;

export type PublishTextsMutation = {
  __typename?: 'Mutation';
  publishManyTextsConnection: {
    __typename?: 'TextConnection';
    aggregate: { __typename?: 'Aggregate'; count: number };
    pageInfo: { __typename?: 'PageInfo'; hasNextPage: boolean };
  };
};

export type GetNonGuescenterImportAppTextsQueryVariables = Exact<{ [key: string]: never }>;

export type GetNonGuescenterImportAppTextsQuery = {
  __typename?: 'Query';
  texts: Array<{
    __typename?: 'Text';
    id: string;
    key: string;
    localizations: Array<{ __typename?: 'Text'; locale: Locale; value: string | null }>;
  }>;
};

export type GetStaticContentTextGroupsQueryVariables = Exact<{
  groupKeys: Array<Scalars['String']> | Scalars['String'];
  locales: Array<Locale> | Locale;
  stage?: InputMaybe<Stage>;
  locale?: Array<Locale> | Locale;
}>;

export type GetStaticContentTextGroupsQuery = {
  __typename?: 'Query';
  textGroups: Array<{
    __typename?: 'TextGroup';
    text: Array<
      | {
          __typename: 'Text';
          key: string;
          value: string | null;
          localizations: Array<{ __typename?: 'Text'; value: string | null; locale: Locale }>;
        }
      | { __typename: 'TextList'; key: string }
    >;
  }>;
};

export type GetStaticContentTextsQueryVariables = Exact<{
  textKeys: Array<Scalars['String']> | Scalars['String'];
  locales: Array<Locale> | Locale;
  stage?: InputMaybe<Stage>;
  locale?: Array<Locale> | Locale;
}>;

export type GetStaticContentTextsQuery = {
  __typename?: 'Query';
  texts: Array<{
    __typename: 'Text';
    key: string;
    value: string | null;
    localizations: Array<{ __typename?: 'Text'; value: string | null; locale: Locale }>;
  }>;
};

export const ImagePropsFragmentDoc = `
    fragment ImageProps on Asset {
  url
  width
  height
}
    `;
export const AllAttractionPropsFragmentDoc = `
    fragment AllAttractionProps on Attraction {
  key
  name
  intro
  shortIntro
  thumb {
    url
  }
  hero {
    url
  }
  admission {
    value
  }
  exclusiveFeature
  highlights {
    value
  }
  accessibility
  bestTime
  parking
  openStatus {
    value
  }
  tips {
    value
  }
  safety {
    value
  }
  amenities {
    value
  }
  logo {
    url
  }
  locations {
    name
    geolocation {
      latitude
      longitude
    }
    address
    addressNote
    hours
    hoursNotes {
      value
    }
    website
    phone
  }
  gallery {
    __typename
    ... on Image {
      image {
        url(transformation: {image: {resize: {fit: clip, width: 700}}})
      }
      caption
      credit
    }
    ... on Video {
      url
      caption
    }
  }
  offers(
    where: {active: true, platforms_contains_some: [guide], startDate_lte: $today, OR: [{endDate_gte: $today}, {endDate: null}]}
  ) {
    id
    internalTitle
    title
    description
    conditions
    code
    type
    startDate
    endDate
    expirationDate
    platforms
    products {
      key
    }
    attraction {
      key
    }
  }
  variants {
    product {
      key
      type
    }
    name
    intro
    shortIntro
    admission {
      value
    }
    exclusiveFeature
  }
  reservation {
    attraction {
      key
    }
    texts {
      key
      value
      products {
        key
      }
    }
  }
}
    `;
export const AllProductPropsFragmentDoc = `
    fragment AllProductProps on Product {
  key
  name
  type
  slug
  cityName {
    value
  }
  hero {
    ...ImageProps
  }
  logo {
    ...ImageProps
  }
  logoWhite {
    ...ImageProps
  }
  logoSpotOutline {
    url
  }
  logoTicket {
    ...ImageProps
  }
  ageAdult
  ageChild
  validDays
  admissionTitle {
    value
  }
  attractionLineups(orderBy: year_DESC, first: 1) {
    attractionGroups {
      attractions {
        key
      }
    }
  }
  attractions {
    ...AllAttractionProps
  }
  reservation {
    texts {
      key
      value
    }
    ticketsBackground {
      ...ImageProps
    }
  }
  partnerOffers(
    where: {platforms_contains_some: [app], startDate_lte: $today, endDate_gte: $today}
  ) {
    id
    campaignId
    title
    description
    image {
      url
      width
      height
    }
    startDate
    endDate
  }
}
    ${ImagePropsFragmentDoc}
${AllAttractionPropsFragmentDoc}`;
export const GetDestinationsDocument = `
    query getDestinations($locale: Locale!) {
  products(orderBy: key_ASC, locales: [$locale, en]) {
    key
    name
    cityName {
      value
    }
    type
    thumb {
      url
      width
      height
    }
    slug
    logoSpot {
      url
      width
      height
      mimeType
    }
  }
}
    `;
export const useGetDestinationsQuery = <TData = GetDestinationsQuery, TError = unknown>(
  client: GraphQLClient,
  variables: GetDestinationsQueryVariables,
  options?: UseQueryOptions<GetDestinationsQuery, TError, TData>,
  headers?: RequestInit['headers']
) =>
  useQuery<GetDestinationsQuery, TError, TData>(
    ['getDestinations', variables],
    fetcher<GetDestinationsQuery, GetDestinationsQueryVariables>(
      client,
      GetDestinationsDocument,
      variables,
      headers
    ),
    options
  );

useGetDestinationsQuery.getKey = (variables: GetDestinationsQueryVariables) => [
  'getDestinations',
  variables,
];
useGetDestinationsQuery.fetcher = (
  client: GraphQLClient,
  variables: GetDestinationsQueryVariables,
  headers?: RequestInit['headers']
) =>
  fetcher<GetDestinationsQuery, GetDestinationsQueryVariables>(
    client,
    GetDestinationsDocument,
    variables,
    headers
  );
export const GetContentDocument = `
    query getContent($locale: Locale!) {
  textGroups(where: {key_starts_with: "app"}, locales: [$locale, en]) {
    key
    text(first: 1000) {
      ... on Text {
        key
        value
        locale
      }
      ... on TextList {
        key
        texts {
          value
          locale
        }
      }
    }
  }
}
    `;
export const useGetContentQuery = <TData = GetContentQuery, TError = unknown>(
  client: GraphQLClient,
  variables: GetContentQueryVariables,
  options?: UseQueryOptions<GetContentQuery, TError, TData>,
  headers?: RequestInit['headers']
) =>
  useQuery<GetContentQuery, TError, TData>(
    ['getContent', variables],
    fetcher<GetContentQuery, GetContentQueryVariables>(
      client,
      GetContentDocument,
      variables,
      headers
    ),
    options
  );

useGetContentQuery.getKey = (variables: GetContentQueryVariables) => ['getContent', variables];
useGetContentQuery.fetcher = (
  client: GraphQLClient,
  variables: GetContentQueryVariables,
  headers?: RequestInit['headers']
) =>
  fetcher<GetContentQuery, GetContentQueryVariables>(
    client,
    GetContentDocument,
    variables,
    headers
  );
export const GetProductDocument = `
    query getProduct($productId: String!, $locale: Locale!, $today: Date!) {
  product(where: {key: $productId}, locales: [$locale, en]) {
    ...AllProductProps
  }
  faqs: textList(
    where: {key: "gen_product_details_tips_body"}
    locales: [$locale, en]
  ) {
    key
    texts {
      value
    }
  }
}
    ${AllProductPropsFragmentDoc}`;
export const useGetProductQuery = <TData = GetProductQuery, TError = unknown>(
  client: GraphQLClient,
  variables: GetProductQueryVariables,
  options?: UseQueryOptions<GetProductQuery, TError, TData>,
  headers?: RequestInit['headers']
) =>
  useQuery<GetProductQuery, TError, TData>(
    ['getProduct', variables],
    fetcher<GetProductQuery, GetProductQueryVariables>(
      client,
      GetProductDocument,
      variables,
      headers
    ),
    options
  );

useGetProductQuery.getKey = (variables: GetProductQueryVariables) => ['getProduct', variables];
useGetProductQuery.fetcher = (
  client: GraphQLClient,
  variables: GetProductQueryVariables,
  headers?: RequestInit['headers']
) =>
  fetcher<GetProductQuery, GetProductQueryVariables>(
    client,
    GetProductDocument,
    variables,
    headers
  );
export const GetAttractionDocument = `
    query getAttraction($locale: Locale!, $attrId: String!, $today: Date!) {
  attraction(where: {key: $attrId}, locales: [$locale, en]) {
    ...AllAttractionProps
  }
}
    ${AllAttractionPropsFragmentDoc}`;
export const useGetAttractionQuery = <TData = GetAttractionQuery, TError = unknown>(
  client: GraphQLClient,
  variables: GetAttractionQueryVariables,
  options?: UseQueryOptions<GetAttractionQuery, TError, TData>,
  headers?: RequestInit['headers']
) =>
  useQuery<GetAttractionQuery, TError, TData>(
    ['getAttraction', variables],
    fetcher<GetAttractionQuery, GetAttractionQueryVariables>(
      client,
      GetAttractionDocument,
      variables,
      headers
    ),
    options
  );

useGetAttractionQuery.getKey = (variables: GetAttractionQueryVariables) => [
  'getAttraction',
  variables,
];
useGetAttractionQuery.fetcher = (
  client: GraphQLClient,
  variables: GetAttractionQueryVariables,
  headers?: RequestInit['headers']
) =>
  fetcher<GetAttractionQuery, GetAttractionQueryVariables>(
    client,
    GetAttractionDocument,
    variables,
    headers
  );
export const GetAttractionsDocument = `
    query getAttractions($attrIds: [String!]!) {
  attractions(where: {key_in: $attrIds}) {
    key
    name
    hero {
      url
    }
  }
}
    `;
export const useGetAttractionsQuery = <TData = GetAttractionsQuery, TError = unknown>(
  client: GraphQLClient,
  variables: GetAttractionsQueryVariables,
  options?: UseQueryOptions<GetAttractionsQuery, TError, TData>,
  headers?: RequestInit['headers']
) =>
  useQuery<GetAttractionsQuery, TError, TData>(
    ['getAttractions', variables],
    fetcher<GetAttractionsQuery, GetAttractionsQueryVariables>(
      client,
      GetAttractionsDocument,
      variables,
      headers
    ),
    options
  );

useGetAttractionsQuery.getKey = (variables: GetAttractionsQueryVariables) => [
  'getAttractions',
  variables,
];
useGetAttractionsQuery.fetcher = (
  client: GraphQLClient,
  variables: GetAttractionsQueryVariables,
  headers?: RequestInit['headers']
) =>
  fetcher<GetAttractionsQuery, GetAttractionsQueryVariables>(
    client,
    GetAttractionsDocument,
    variables,
    headers
  );
export const GetAssetsDocument = `
    query getAssets {
  authHelp: asset(where: {id: "cl4eb0w4a440n0fmyyqd7uq14"}) {
    ...ImageProps
  }
  productBroadwayPromo: asset(where: {id: "clbop3pmy3cqp0aljy93vkgzf"}) {
    ...ImageProps
  }
  productSocialShare: asset(where: {id: "clbop3pns3bpp0aob0w6qp2y5"}) {
    ...ImageProps
  }
  productOffers: asset(where: {id: "clbpixtyd4plj0aljn2plyc0f"}) {
    ...ImageProps
  }
}
    ${ImagePropsFragmentDoc}`;
export const useGetAssetsQuery = <TData = GetAssetsQuery, TError = unknown>(
  client: GraphQLClient,
  variables?: GetAssetsQueryVariables,
  options?: UseQueryOptions<GetAssetsQuery, TError, TData>,
  headers?: RequestInit['headers']
) =>
  useQuery<GetAssetsQuery, TError, TData>(
    variables === undefined ? ['getAssets'] : ['getAssets', variables],
    fetcher<GetAssetsQuery, GetAssetsQueryVariables>(client, GetAssetsDocument, variables, headers),
    options
  );

useGetAssetsQuery.getKey = (variables?: GetAssetsQueryVariables) =>
  variables === undefined ? ['getAssets'] : ['getAssets', variables];
useGetAssetsQuery.fetcher = (
  client: GraphQLClient,
  variables?: GetAssetsQueryVariables,
  headers?: RequestInit['headers']
) =>
  fetcher<GetAssetsQuery, GetAssetsQueryVariables>(client, GetAssetsDocument, variables, headers);
export const GetProductScreenBlocksDocument = `
    query getProductScreenBlocks($locale: Locale! = en) {
  blockGroup(where: {key: "app-product-screen"}, locales: [$locale, en]) {
    id
    key
    blocks(where: {active: true}) {
      id
      title {
        key
        value
      }
      body {
        key
        value
      }
      cta {
        key
        value
      }
      image {
        url
        width
        height
      }
      url
      ui(where: {platforms_contains_some: [app]}, first: 1) {
        component
        fullWidth
        colorScheme
        align
        props
        analyticsEvent
      }
    }
  }
}
    `;
export const useGetProductScreenBlocksQuery = <
  TData = GetProductScreenBlocksQuery,
  TError = unknown
>(
  client: GraphQLClient,
  variables?: GetProductScreenBlocksQueryVariables,
  options?: UseQueryOptions<GetProductScreenBlocksQuery, TError, TData>,
  headers?: RequestInit['headers']
) =>
  useQuery<GetProductScreenBlocksQuery, TError, TData>(
    variables === undefined ? ['getProductScreenBlocks'] : ['getProductScreenBlocks', variables],
    fetcher<GetProductScreenBlocksQuery, GetProductScreenBlocksQueryVariables>(
      client,
      GetProductScreenBlocksDocument,
      variables,
      headers
    ),
    options
  );

useGetProductScreenBlocksQuery.getKey = (variables?: GetProductScreenBlocksQueryVariables) =>
  variables === undefined ? ['getProductScreenBlocks'] : ['getProductScreenBlocks', variables];
useGetProductScreenBlocksQuery.fetcher = (
  client: GraphQLClient,
  variables?: GetProductScreenBlocksQueryVariables,
  headers?: RequestInit['headers']
) =>
  fetcher<GetProductScreenBlocksQuery, GetProductScreenBlocksQueryVariables>(
    client,
    GetProductScreenBlocksDocument,
    variables,
    headers
  );
export const GetNoticesDocument = `
    query getNotices($locale: Locale! = en, $today: Date!) {
  notices(
    where: {platforms_contains_some: [guide, app], startDate_lte: $today, OR: [{endDate_gte: $today}, {endDate: null}]}
    first: 1000
    locales: [$locale, en]
  ) {
    id
    key
    title
    description
    startDate
    endDate
    theme
    defaultOpen
    placements
    products {
      key
      name
    }
    attractions {
      key
      name
    }
    updatedAt
  }
}
    `;
export const useGetNoticesQuery = <TData = GetNoticesQuery, TError = unknown>(
  client: GraphQLClient,
  variables: GetNoticesQueryVariables,
  options?: UseQueryOptions<GetNoticesQuery, TError, TData>,
  headers?: RequestInit['headers']
) =>
  useQuery<GetNoticesQuery, TError, TData>(
    ['getNotices', variables],
    fetcher<GetNoticesQuery, GetNoticesQueryVariables>(
      client,
      GetNoticesDocument,
      variables,
      headers
    ),
    options
  );

useGetNoticesQuery.getKey = (variables: GetNoticesQueryVariables) => ['getNotices', variables];
useGetNoticesQuery.fetcher = (
  client: GraphQLClient,
  variables: GetNoticesQueryVariables,
  headers?: RequestInit['headers']
) =>
  fetcher<GetNoticesQuery, GetNoticesQueryVariables>(
    client,
    GetNoticesDocument,
    variables,
    headers
  );
export const GetAllContentDocument = `
    query getAllContent($guestCenterImportTag: String!) {
  products(orderBy: key_ASC, first: 1000) {
    key
    name
    type
    reservation {
      id
    }
  }
  attractions(orderBy: key_ASC, first: 1000) {
    key
    name
    products {
      key
      type
    }
    reservation {
      id
    }
  }
  texts(
    where: {tags_contains_some: [$guestCenterImportTag]}
    orderBy: key_ASC
    first: 1000
  ) {
    id
    key
    value
  }
}
    `;
export const useGetAllContentQuery = <TData = GetAllContentQuery, TError = unknown>(
  client: GraphQLClient,
  variables: GetAllContentQueryVariables,
  options?: UseQueryOptions<GetAllContentQuery, TError, TData>,
  headers?: RequestInit['headers']
) =>
  useQuery<GetAllContentQuery, TError, TData>(
    ['getAllContent', variables],
    fetcher<GetAllContentQuery, GetAllContentQueryVariables>(
      client,
      GetAllContentDocument,
      variables,
      headers
    ),
    options
  );

useGetAllContentQuery.getKey = (variables: GetAllContentQueryVariables) => [
  'getAllContent',
  variables,
];
useGetAllContentQuery.fetcher = (
  client: GraphQLClient,
  variables: GetAllContentQueryVariables,
  headers?: RequestInit['headers']
) =>
  fetcher<GetAllContentQuery, GetAllContentQueryVariables>(
    client,
    GetAllContentDocument,
    variables,
    headers
  );
export const CreateProductReservationDocument = `
    mutation createProductReservation($data: ProductReservationCreateInput!) {
  createProductReservation(data: $data) {
    id
  }
}
    `;
export const useCreateProductReservationMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    CreateProductReservationMutation,
    TError,
    CreateProductReservationMutationVariables,
    TContext
  >,
  headers?: RequestInit['headers']
) =>
  useMutation<
    CreateProductReservationMutation,
    TError,
    CreateProductReservationMutationVariables,
    TContext
  >(
    ['createProductReservation'],
    (variables?: CreateProductReservationMutationVariables) =>
      fetcher<CreateProductReservationMutation, CreateProductReservationMutationVariables>(
        client,
        CreateProductReservationDocument,
        variables,
        headers
      )(),
    options
  );
useCreateProductReservationMutation.fetcher = (
  client: GraphQLClient,
  variables: CreateProductReservationMutationVariables,
  headers?: RequestInit['headers']
) =>
  fetcher<CreateProductReservationMutation, CreateProductReservationMutationVariables>(
    client,
    CreateProductReservationDocument,
    variables,
    headers
  );
export const CreateAttractionReservationDocument = `
    mutation createAttractionReservation($data: AttractionReservationCreateInput!) {
  createAttractionReservation(data: $data) {
    id
  }
}
    `;
export const useCreateAttractionReservationMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    CreateAttractionReservationMutation,
    TError,
    CreateAttractionReservationMutationVariables,
    TContext
  >,
  headers?: RequestInit['headers']
) =>
  useMutation<
    CreateAttractionReservationMutation,
    TError,
    CreateAttractionReservationMutationVariables,
    TContext
  >(
    ['createAttractionReservation'],
    (variables?: CreateAttractionReservationMutationVariables) =>
      fetcher<CreateAttractionReservationMutation, CreateAttractionReservationMutationVariables>(
        client,
        CreateAttractionReservationDocument,
        variables,
        headers
      )(),
    options
  );
useCreateAttractionReservationMutation.fetcher = (
  client: GraphQLClient,
  variables: CreateAttractionReservationMutationVariables,
  headers?: RequestInit['headers']
) =>
  fetcher<CreateAttractionReservationMutation, CreateAttractionReservationMutationVariables>(
    client,
    CreateAttractionReservationDocument,
    variables,
    headers
  );
export const DeleteReservationContentDocument = `
    mutation deleteReservationContent($guestCenterImportTag: String!) {
  texts: deleteManyTextsConnection(
    where: {tags: [$guestCenterImportTag]}
    first: 1000
  ) {
    aggregate {
      count
    }
    pageInfo {
      hasNextPage
    }
  }
}
    `;
export const useDeleteReservationContentMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    DeleteReservationContentMutation,
    TError,
    DeleteReservationContentMutationVariables,
    TContext
  >,
  headers?: RequestInit['headers']
) =>
  useMutation<
    DeleteReservationContentMutation,
    TError,
    DeleteReservationContentMutationVariables,
    TContext
  >(
    ['deleteReservationContent'],
    (variables?: DeleteReservationContentMutationVariables) =>
      fetcher<DeleteReservationContentMutation, DeleteReservationContentMutationVariables>(
        client,
        DeleteReservationContentDocument,
        variables,
        headers
      )(),
    options
  );
useDeleteReservationContentMutation.fetcher = (
  client: GraphQLClient,
  variables: DeleteReservationContentMutationVariables,
  headers?: RequestInit['headers']
) =>
  fetcher<DeleteReservationContentMutation, DeleteReservationContentMutationVariables>(
    client,
    DeleteReservationContentDocument,
    variables,
    headers
  );
export const UpdateProductReservationDocument = `
    mutation updateProductReservation($where: ProductReservationWhereUniqueInput!, $data: ProductReservationUpdateInput!) {
  updateProductReservation(where: $where, data: $data) {
    id
  }
}
    `;
export const useUpdateProductReservationMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    UpdateProductReservationMutation,
    TError,
    UpdateProductReservationMutationVariables,
    TContext
  >,
  headers?: RequestInit['headers']
) =>
  useMutation<
    UpdateProductReservationMutation,
    TError,
    UpdateProductReservationMutationVariables,
    TContext
  >(
    ['updateProductReservation'],
    (variables?: UpdateProductReservationMutationVariables) =>
      fetcher<UpdateProductReservationMutation, UpdateProductReservationMutationVariables>(
        client,
        UpdateProductReservationDocument,
        variables,
        headers
      )(),
    options
  );
useUpdateProductReservationMutation.fetcher = (
  client: GraphQLClient,
  variables: UpdateProductReservationMutationVariables,
  headers?: RequestInit['headers']
) =>
  fetcher<UpdateProductReservationMutation, UpdateProductReservationMutationVariables>(
    client,
    UpdateProductReservationDocument,
    variables,
    headers
  );
export const UpdateAttractionReservationDocument = `
    mutation updateAttractionReservation($where: AttractionReservationWhereUniqueInput!, $data: AttractionReservationUpdateInput!) {
  updateAttractionReservation(where: $where, data: $data) {
    id
  }
}
    `;
export const useUpdateAttractionReservationMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    UpdateAttractionReservationMutation,
    TError,
    UpdateAttractionReservationMutationVariables,
    TContext
  >,
  headers?: RequestInit['headers']
) =>
  useMutation<
    UpdateAttractionReservationMutation,
    TError,
    UpdateAttractionReservationMutationVariables,
    TContext
  >(
    ['updateAttractionReservation'],
    (variables?: UpdateAttractionReservationMutationVariables) =>
      fetcher<UpdateAttractionReservationMutation, UpdateAttractionReservationMutationVariables>(
        client,
        UpdateAttractionReservationDocument,
        variables,
        headers
      )(),
    options
  );
useUpdateAttractionReservationMutation.fetcher = (
  client: GraphQLClient,
  variables: UpdateAttractionReservationMutationVariables,
  headers?: RequestInit['headers']
) =>
  fetcher<UpdateAttractionReservationMutation, UpdateAttractionReservationMutationVariables>(
    client,
    UpdateAttractionReservationDocument,
    variables,
    headers
  );
export const UpsertTextGroupDocument = `
    mutation upsertTextGroup($where: TextGroupWhereUniqueInput!, $upsert: TextGroupUpsertInput!) {
  upsertTextGroup(where: $where, upsert: $upsert) {
    id
  }
}
    `;
export const useUpsertTextGroupMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    UpsertTextGroupMutation,
    TError,
    UpsertTextGroupMutationVariables,
    TContext
  >,
  headers?: RequestInit['headers']
) =>
  useMutation<UpsertTextGroupMutation, TError, UpsertTextGroupMutationVariables, TContext>(
    ['upsertTextGroup'],
    (variables?: UpsertTextGroupMutationVariables) =>
      fetcher<UpsertTextGroupMutation, UpsertTextGroupMutationVariables>(
        client,
        UpsertTextGroupDocument,
        variables,
        headers
      )(),
    options
  );
useUpsertTextGroupMutation.fetcher = (
  client: GraphQLClient,
  variables: UpsertTextGroupMutationVariables,
  headers?: RequestInit['headers']
) =>
  fetcher<UpsertTextGroupMutation, UpsertTextGroupMutationVariables>(
    client,
    UpsertTextGroupDocument,
    variables,
    headers
  );
export const PublishProductAttractionReservationsDocument = `
    mutation publishProductAttractionReservations {
  publishManyGeneralReservationsConnection(first: 1000, to: [PUBLISHED]) {
    aggregate {
      count
    }
  }
  publishManyProductReservationsConnection(first: 1000, to: [PUBLISHED]) {
    aggregate {
      count
    }
  }
  publishManyAttractionReservationsConnection(first: 1000, to: [PUBLISHED]) {
    aggregate {
      count
    }
  }
  guestcenter: publishTextGroup(where: {key: "guestcenter"}, to: [PUBLISHED]) {
    id
  }
  reservations: publishTextGroup(
    where: {key: "app_reservations"}
    to: [PUBLISHED]
  ) {
    id
  }
}
    `;
export const usePublishProductAttractionReservationsMutation = <
  TError = unknown,
  TContext = unknown
>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    PublishProductAttractionReservationsMutation,
    TError,
    PublishProductAttractionReservationsMutationVariables,
    TContext
  >,
  headers?: RequestInit['headers']
) =>
  useMutation<
    PublishProductAttractionReservationsMutation,
    TError,
    PublishProductAttractionReservationsMutationVariables,
    TContext
  >(
    ['publishProductAttractionReservations'],
    (variables?: PublishProductAttractionReservationsMutationVariables) =>
      fetcher<
        PublishProductAttractionReservationsMutation,
        PublishProductAttractionReservationsMutationVariables
      >(client, PublishProductAttractionReservationsDocument, variables, headers)(),
    options
  );
usePublishProductAttractionReservationsMutation.fetcher = (
  client: GraphQLClient,
  variables?: PublishProductAttractionReservationsMutationVariables,
  headers?: RequestInit['headers']
) =>
  fetcher<
    PublishProductAttractionReservationsMutation,
    PublishProductAttractionReservationsMutationVariables
  >(client, PublishProductAttractionReservationsDocument, variables, headers);
export const PublishTextsDocument = `
    mutation publishTexts($count: Int!, $skip: Int!, $guestCenterImportTag: String!) {
  publishManyTextsConnection(
    first: $count
    skip: $skip
    where: {tags: [$guestCenterImportTag]}
    locales: [en, de, es, fr, it, ja, pt, zh]
    publishBase: true
    to: [PUBLISHED]
  ) {
    aggregate {
      count
    }
    pageInfo {
      hasNextPage
    }
  }
}
    `;
export const usePublishTextsMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    PublishTextsMutation,
    TError,
    PublishTextsMutationVariables,
    TContext
  >,
  headers?: RequestInit['headers']
) =>
  useMutation<PublishTextsMutation, TError, PublishTextsMutationVariables, TContext>(
    ['publishTexts'],
    (variables?: PublishTextsMutationVariables) =>
      fetcher<PublishTextsMutation, PublishTextsMutationVariables>(
        client,
        PublishTextsDocument,
        variables,
        headers
      )(),
    options
  );
usePublishTextsMutation.fetcher = (
  client: GraphQLClient,
  variables: PublishTextsMutationVariables,
  headers?: RequestInit['headers']
) =>
  fetcher<PublishTextsMutation, PublishTextsMutationVariables>(
    client,
    PublishTextsDocument,
    variables,
    headers
  );
export const GetNonGuescenterImportAppTextsDocument = `
    query getNonGuescenterImportAppTexts {
  texts(
    where: {tags_contains_none: ["guestcenter_import"], OR: [{textGroups_some: {key_starts_with: "app"}}, {productReservations_some: {}}, {attractionReservations_some: {}}]}
    first: 1000
    orderBy: key_ASC
  ) {
    id
    key
    localizations(includeCurrent: false, locales: [es, de, fr, it, pt, ja, zh]) {
      locale
      value
    }
  }
}
    `;
export const useGetNonGuescenterImportAppTextsQuery = <
  TData = GetNonGuescenterImportAppTextsQuery,
  TError = unknown
>(
  client: GraphQLClient,
  variables?: GetNonGuescenterImportAppTextsQueryVariables,
  options?: UseQueryOptions<GetNonGuescenterImportAppTextsQuery, TError, TData>,
  headers?: RequestInit['headers']
) =>
  useQuery<GetNonGuescenterImportAppTextsQuery, TError, TData>(
    variables === undefined
      ? ['getNonGuescenterImportAppTexts']
      : ['getNonGuescenterImportAppTexts', variables],
    fetcher<GetNonGuescenterImportAppTextsQuery, GetNonGuescenterImportAppTextsQueryVariables>(
      client,
      GetNonGuescenterImportAppTextsDocument,
      variables,
      headers
    ),
    options
  );

useGetNonGuescenterImportAppTextsQuery.getKey = (
  variables?: GetNonGuescenterImportAppTextsQueryVariables
) =>
  variables === undefined
    ? ['getNonGuescenterImportAppTexts']
    : ['getNonGuescenterImportAppTexts', variables];
useGetNonGuescenterImportAppTextsQuery.fetcher = (
  client: GraphQLClient,
  variables?: GetNonGuescenterImportAppTextsQueryVariables,
  headers?: RequestInit['headers']
) =>
  fetcher<GetNonGuescenterImportAppTextsQuery, GetNonGuescenterImportAppTextsQueryVariables>(
    client,
    GetNonGuescenterImportAppTextsDocument,
    variables,
    headers
  );
export const GetStaticContentTextGroupsDocument = `
    query getStaticContentTextGroups($groupKeys: [String!]!, $locales: [Locale!]!, $stage: Stage = PUBLISHED, $locale: [Locale!]! = [en]) {
  textGroups(
    where: {key_in: $groupKeys}
    first: 1000
    stage: $stage
    locales: $locale
  ) {
    text(first: 1000) {
      __typename
      ... on TextList {
        key
      }
      ... on Text {
        key
        value
        localizations(includeCurrent: false, locales: $locales) {
          value
          locale
        }
      }
    }
  }
}
    `;
export const useGetStaticContentTextGroupsQuery = <
  TData = GetStaticContentTextGroupsQuery,
  TError = unknown
>(
  client: GraphQLClient,
  variables: GetStaticContentTextGroupsQueryVariables,
  options?: UseQueryOptions<GetStaticContentTextGroupsQuery, TError, TData>,
  headers?: RequestInit['headers']
) =>
  useQuery<GetStaticContentTextGroupsQuery, TError, TData>(
    ['getStaticContentTextGroups', variables],
    fetcher<GetStaticContentTextGroupsQuery, GetStaticContentTextGroupsQueryVariables>(
      client,
      GetStaticContentTextGroupsDocument,
      variables,
      headers
    ),
    options
  );

useGetStaticContentTextGroupsQuery.getKey = (
  variables: GetStaticContentTextGroupsQueryVariables
) => ['getStaticContentTextGroups', variables];
useGetStaticContentTextGroupsQuery.fetcher = (
  client: GraphQLClient,
  variables: GetStaticContentTextGroupsQueryVariables,
  headers?: RequestInit['headers']
) =>
  fetcher<GetStaticContentTextGroupsQuery, GetStaticContentTextGroupsQueryVariables>(
    client,
    GetStaticContentTextGroupsDocument,
    variables,
    headers
  );
export const GetStaticContentTextsDocument = `
    query getStaticContentTexts($textKeys: [String!]!, $locales: [Locale!]!, $stage: Stage = PUBLISHED, $locale: [Locale!]! = [en]) {
  texts(where: {key_in: $textKeys}, first: 1000, stage: $stage, locales: $locale) {
    __typename
    key
    value
    localizations(includeCurrent: false, locales: $locales) {
      value
      locale
    }
  }
}
    `;
export const useGetStaticContentTextsQuery = <TData = GetStaticContentTextsQuery, TError = unknown>(
  client: GraphQLClient,
  variables: GetStaticContentTextsQueryVariables,
  options?: UseQueryOptions<GetStaticContentTextsQuery, TError, TData>,
  headers?: RequestInit['headers']
) =>
  useQuery<GetStaticContentTextsQuery, TError, TData>(
    ['getStaticContentTexts', variables],
    fetcher<GetStaticContentTextsQuery, GetStaticContentTextsQueryVariables>(
      client,
      GetStaticContentTextsDocument,
      variables,
      headers
    ),
    options
  );

useGetStaticContentTextsQuery.getKey = (variables: GetStaticContentTextsQueryVariables) => [
  'getStaticContentTexts',
  variables,
];
useGetStaticContentTextsQuery.fetcher = (
  client: GraphQLClient,
  variables: GetStaticContentTextsQueryVariables,
  headers?: RequestInit['headers']
) =>
  fetcher<GetStaticContentTextsQuery, GetStaticContentTextsQueryVariables>(
    client,
    GetStaticContentTextsDocument,
    variables,
    headers
  );
