import Constants from 'expo-constants';
import { Button, Text } from 'native-base';
import React, { ReactNode, useMemo, useRef } from 'react';
import { Linking, Platform } from 'react-native';

import CPAlertDialog from 'app/components/CPAlertDialog/CPAlertDialog';
import useConfiguration from 'app/hooks/useConfiguration';
import { useI18n } from 'app/providers/I18nProvider';
import { tID } from 'app/services/TestHelper';

const appVersion = Constants.expoConfig?.version;
const appStoreUrl = Constants.expoConfig?.extra?.install?.appStoreURL;
const playStoreUrl = Constants.expoConfig?.extra?.install?.playStoreURL;

export type ForcedUpgradeProps = {
  children?: ReactNode;
};

export default function ForcedUpgrade({ children }: ForcedUpgradeProps) {
  const { t } = useI18n();
  const dialogRef = useRef();
  const { data, refetch, isFetching } = useConfiguration({
    staleTime: 1000 * 60 * 5,
    refetchOnMount: true,
    refetchOnReconnect: true,
    select: ({ minAppVersion, install }) => ({
      minAppVersion,
      installConfig: install,
    }),
    notifyOnChangeProps: ['data', 'isRefetching'],
  });

  const isOutdated = useMemo(
    () => isVersionOutdated(appVersion, data?.minAppVersion),
    [data?.minAppVersion]
  );

  const handleUpdate = () => {
    if (Platform.OS === 'web') {
      window.location.reload();
      return;
    }
    Linking.openURL(
      Platform.select({
        ios: data?.installConfig?.appStoreUrl ?? appStoreUrl,
        android: data?.installConfig?.playStoreUrl ?? playStoreUrl,
      })
    );
  };

  if (isOutdated) {
    return (
      <CPAlertDialog isOpen leastDestructiveRef={dialogRef} testID={tID('ForcedUpgrade')}>
        <CPAlertDialog.Content>
          <CPAlertDialog.Header>{t('app_forced_upgrade_title')}</CPAlertDialog.Header>
          <CPAlertDialog.Body>
            <Text textAlign="center">{t('app_forced_upgrade_body')}</Text>
          </CPAlertDialog.Body>
          <CPAlertDialog.Footer>
            <Button.Group>
              <Button testID={tID('ForcedUpgradeCTA')} onPress={handleUpdate}>
                {t('app_forced_upgrade_cta')}
              </Button>
              <Button
                isLoading={isFetching}
                testID={tID('ForcedUpgradeCheckCTA')}
                variant="link"
                onPress={() => refetch()}
              >
                {t('app_forced_upgrade_check_cta')}
              </Button>
            </Button.Group>
          </CPAlertDialog.Footer>
        </CPAlertDialog.Content>
      </CPAlertDialog>
    );
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}

export function isVersionOutdated(currentVersion: Maybe<string>, requiredVersion: Maybe<string>) {
  if (!currentVersion || !requiredVersion) return false;
  const current = currentVersion.split('.').map((v) => parseInt(v, 10));
  const required = requiredVersion.split('.').map((v) => parseInt(v, 10));
  return current.some((version, i) => version < required[i] ?? 0);
}
