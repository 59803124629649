import noop from 'lodash/noop';
import { ITextProps, Text } from 'native-base';
import React, { useMemo } from 'react';
import { Linking } from 'react-native';

export type TextLinkProps = ITextProps & {
  children: string | string[];
  url?: string;
  color?: string;
  onPress?: () => void;
  testID?: string;
};

export default function TextLink({
  children,
  url,
  color = 'black',
  onPress,
  testID,
  ...props
}: TextLinkProps) {
  const handlePress = useMemo(() => {
    if (onPress) return onPress;
    if (url) return () => Linking.openURL(url);
    return noop;
  }, [onPress, url]);
  return (
    <Text
      {...props}
      color={color}
      fontFamily="ProximaNova-Bold"
      testID={testID}
      underline
      onPress={handlePress}
    >
      {children}
    </Text>
  );
}
