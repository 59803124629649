import { Text } from 'native-base';
import React from 'react';

export type TextWithTitleProps = {
  children: string[] | string;
  title: string;
  color?: string;
  testID?: string;
  bold?: boolean;
  selectable?: boolean;
};

export default function TextWithTitle({
  children,
  title,
  color = 'black',
  testID,
  bold = true,
  selectable = false,
}: TextWithTitleProps) {
  return (
    <Text color={color} testID={testID}>
      <Text fontFamily={bold ? 'ProximaNova-Bold' : undefined}>{title}:</Text>{' '}
      <Text color={color} selectable={selectable}>
        {children}
      </Text>
    </Text>
  );
}
