import { useMemo } from 'react';
import { shallow } from 'zustand/shallow';

import useStore from 'app/hooks/useStore';
import { RootStackParamList } from 'app/navigation/RootNavigator';

/**
 * Determines where to send users when they open the app.
 */
export default function useAppInitialRouteName(): keyof RootStackParamList {
  const { productId, hasCompletedWelcome } = useStore(
    (s) => ({
      productId: s.productId,
      hasCompletedWelcome: s.hasCompletedWelcome,
    }),
    shallow
  );
  const initialRouteName: keyof RootStackParamList = useMemo(() => {
    if (!hasCompletedWelcome) return 'Welcome';
    if (productId) return 'Tabs';
    return 'Destinations';
  }, [productId, hasCompletedWelcome]);

  return initialRouteName;
}
