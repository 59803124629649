// yes, this is ridiculous -- see https://github.com/iamkun/dayjs/issues/1377
import '@formatjs/intl-getcanonicallocales/polyfill';
import '@formatjs/intl-locale/polyfill';
import '@formatjs/intl-displaynames/polyfill';
import '@formatjs/intl-displaynames/locale-data/en';
import '@formatjs/intl-displaynames/locale-data/de';
import '@formatjs/intl-displaynames/locale-data/es';
import '@formatjs/intl-displaynames/locale-data/fr';
import '@formatjs/intl-displaynames/locale-data/it';
import '@formatjs/intl-displaynames/locale-data/ja';
import '@formatjs/intl-displaynames/locale-data/pt';
import '@formatjs/intl-displaynames/locale-data/zh';
import '@formatjs/intl-listformat/polyfill';
import '@formatjs/intl-listformat/locale-data/en';
import '@formatjs/intl-listformat/locale-data/de';
import '@formatjs/intl-listformat/locale-data/es';
import '@formatjs/intl-listformat/locale-data/fr';
import '@formatjs/intl-listformat/locale-data/it';
import '@formatjs/intl-listformat/locale-data/ja';
import '@formatjs/intl-listformat/locale-data/pt';
import '@formatjs/intl-listformat/locale-data/zh';
import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-pluralrules/locale-data/en';
import '@formatjs/intl-pluralrules/locale-data/de';
import '@formatjs/intl-pluralrules/locale-data/es';
import '@formatjs/intl-pluralrules/locale-data/fr';
import '@formatjs/intl-pluralrules/locale-data/it';
import '@formatjs/intl-pluralrules/locale-data/ja';
import '@formatjs/intl-pluralrules/locale-data/pt';
import '@formatjs/intl-pluralrules/locale-data/zh';
import '@formatjs/intl-numberformat/polyfill';
import '@formatjs/intl-numberformat/locale-data/en';
import '@formatjs/intl-numberformat/locale-data/de';
import '@formatjs/intl-numberformat/locale-data/es';
import '@formatjs/intl-numberformat/locale-data/fr';
import '@formatjs/intl-numberformat/locale-data/it';
import '@formatjs/intl-numberformat/locale-data/ja';
import '@formatjs/intl-numberformat/locale-data/pt';
import '@formatjs/intl-numberformat/locale-data/zh';
import '@formatjs/intl-relativetimeformat/polyfill';
import '@formatjs/intl-relativetimeformat/locale-data/en';
import '@formatjs/intl-relativetimeformat/locale-data/de';
import '@formatjs/intl-relativetimeformat/locale-data/es';
import '@formatjs/intl-relativetimeformat/locale-data/fr';
import '@formatjs/intl-relativetimeformat/locale-data/it';
import '@formatjs/intl-relativetimeformat/locale-data/ja';
import '@formatjs/intl-relativetimeformat/locale-data/pt';
import '@formatjs/intl-relativetimeformat/locale-data/zh';
import '@formatjs/intl-datetimeformat/polyfill';
import '@formatjs/intl-datetimeformat/locale-data/en';
import '@formatjs/intl-datetimeformat/locale-data/de';
import '@formatjs/intl-datetimeformat/locale-data/es';
import '@formatjs/intl-datetimeformat/locale-data/fr';
import '@formatjs/intl-datetimeformat/locale-data/it';
import '@formatjs/intl-datetimeformat/locale-data/ja';
import '@formatjs/intl-datetimeformat/locale-data/pt';
import '@formatjs/intl-datetimeformat/locale-data/zh';
import '@formatjs/intl-datetimeformat/add-all-tz';

/* eslint-disable @typescript-eslint/ban-ts-comment */
// see https://github.com/iamkun/dayjs/issues/1377#issuecomment-1191900686

// @ts-ignore
// eslint-disable-next-line no-extend-native, no-underscore-dangle
Date.prototype._toLocaleString = Date.prototype.toLocaleString;

// @ts-ignore
// eslint-disable-next-line no-extend-native
Date.prototype.toLocaleString = function toLocaleStringPatched(a: string | string[], b) {
  if (b && Object.keys(b).length === 1 && 'timeZone' in b && a === 'en-US') {
    return Intl.DateTimeFormat('en-us', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
      timeZone: b.timeZone,
    })
      .format(this)
      .replace(/(\d{2})\/(\d{2})\/(\d{4}),/g, '$3-$1-$2');
  }

  // @ts-ignore
  // eslint-disable-next-line no-underscore-dangle
  return this._toLocaleString(a, b);
};
/* eslint-enable @typescript-eslint/ban-ts-comment */
