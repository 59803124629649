/* eslint-disable no-underscore-dangle */
import { Box, IBoxProps, IPressableProps, IStackProps, Pressable } from 'native-base';
import React, { ReactNode, useState } from 'react';
import Collapsible from 'react-native-collapsible';

import ArrowDown from 'app/components/icons/ArrowDown';
import IconTitle, { IconTitleProps, VERTICAL_PADDING } from 'app/components/IconTitle';
import Rotate from 'app/components/Rotate';
import { tID } from 'app/services/TestHelper';

type AccordionSection = {
  id: string;
  title: ReactNode;
  status?: ReactNode;
  icon?: JSX.Element;
  content: ReactNode;
  initialOpen?: boolean;
  _container?: IBoxProps;
  _trigger?: IPressableProps;
  _status?: IStackProps;
  _title?: IconTitleProps['_title'];
};

type AccordionSectionProps = {
  section: AccordionSection;
  index: number;
  onExpand?: () => void;
};

function AccordionSection({ section, index, onExpand }: AccordionSectionProps) {
  const [open, setOpen] = useState(section.initialOpen ?? false);
  const handleToggle = () => {
    if (!open && onExpand) onExpand();
    setOpen(!open);
  };

  return (
    <Box
      key={section.id}
      borderBottomWidth="1"
      borderColor="gray.300"
      borderTopWidth={index === 0 ? '1px' : 0}
      pb={open ? '4' : '0'}
      w="full"
      {...section._container}
    >
      <Pressable
        py={VERTICAL_PADDING}
        onPress={handleToggle}
        {...section._trigger}
        _pressed={{ bg: 'muted.100' }}
        w="full"
      >
        <IconTitle
          _hstack={section._status}
          _title={section._title}
          icon={section.icon}
          statusNode={section.status}
          title={section.title}
        >
          <Box>
            <Rotate end={180} rotated={open} start={0}>
              <ArrowDown size={16} />
            </Rotate>
          </Box>
        </IconTitle>
      </Pressable>

      <Collapsible collapsed={!open} renderChildrenCollapsed>
        <Box>{section.content}</Box>
      </Collapsible>
    </Box>
  );
}

export type AccordionProps<T extends AccordionSection> = IBoxProps & {
  sections: T[];
  onExpand?: (data: T, idx: number) => void;
};

export default function Accordion<T extends AccordionSection>({
  sections = [],
  onExpand,
  ...props
}: AccordionProps<T>) {
  return (
    <Box testID={tID('Accordion')} {...props}>
      {sections.map((section, idx) => (
        <AccordionSection
          key={section.id}
          index={idx}
          section={section}
          onExpand={onExpand ? () => onExpand(section, idx) : undefined}
        />
      ))}
    </Box>
  );
}
