import { ISelectProps, Select } from 'native-base';
import React from 'react';

import { Locale } from 'app/generated/hygraph';
import { useI18n } from 'app/providers/I18nProvider';

export type LocaleSelectorProps = ISelectProps;

export default function LocaleSelect(props: LocaleSelectorProps) {
  const { locale, locales, setLocale } = useI18n();

  return (
    <Select
      accessibilityLabel="Choose Locale"
      selectedValue={locale}
      {...props}
      onValueChange={($locale) => {
        setLocale($locale as Locale);
        if (props.onValueChange) props.onValueChange($locale);
      }}
    >
      {locales.map(([$locale, { label }]) => (
        <Select.Item key={$locale} label={label} value={$locale} />
      ))}
    </Select>
  );
}
