import { Box, Button, HStack, IBoxProps } from 'native-base';
import { ReactNode } from 'react';
import { Linking, Platform } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { DEFAULT_PADDING } from 'app/components/ResponsiveContainer';
import useDestinations from 'app/hooks/useDestinations';
import useGuest from 'app/hooks/useGuest';
import useRootNavigation from 'app/hooks/useRootNavigation';
import { getCurrentRouteParams } from 'app/navigation/navigationHelper';
import { useI18n } from 'app/providers/I18nProvider';
import Analytics, { Events } from 'app/services/Analytics';
import { webProduct } from 'app/services/LinkHelper';

const VERTICAL_PADDING = 3;

export type LinkTicketsPromptProps = Pick<IBoxProps, 'bottom' | 'position' | 'top' | 'zIndex'> & {
  productContentKey?: string;
  children?: ReactNode;
  safeInsetBottom?: boolean;
  alwaysShow?: boolean;
};

export default function LinkTicketsPrompt({
  safeInsetBottom,
  productContentKey,
  alwaysShow,
  ...props
}: LinkTicketsPromptProps) {
  const navigation = useRootNavigation();
  const { bottom } = useSafeAreaInsets();
  const { t, locale } = useI18n();
  const { hasEntitlements, entitlements } = useGuest();
  const { data: destinations } = useDestinations({ enabled: !!productContentKey });
  const productEntitlement = entitlements.find(
    (ent) => ent.productContentKey === productContentKey
  );

  if (!alwaysShow && (productContentKey ? productEntitlement : hasEntitlements)) return null;

  const handleLinkTickets = () => {
    Analytics.trackEvent(
      Events.NavLinktickets,
      getNavEventProps({ navigation, productContentKey })
    );
    navigation.navigate('Authentication');
  };
  const handleBuyNow = () => {
    Analytics.trackEvent(Events.NavBuyNow, getNavEventProps({ navigation, productContentKey }));
    const productSlug =
      destinations?.find((product) => product.key === productContentKey)?.slug ?? '';
    Linking.openURL(webProduct({ locale, productSlug }));
  };

  return (
    <Box
      bgColor="brand.secondary"
      color="white"
      pb={safeInsetBottom && Platform.OS !== 'web' && bottom ? bottom : VERTICAL_PADDING}
      position="relative"
      px={DEFAULT_PADDING}
      py={VERTICAL_PADDING}
      testID="LinkTicketsPrompt"
      w="full"
      {...props}
    >
      <HStack alignItems="center" flexDirection="row" justifyContent="space-evenly" space="4">
        <Button variant="linkInverted" onPress={handleBuyNow}>
          {t('gen_buy_now')}
        </Button>

        <Button colorScheme="primary" maxW="65%" minH="0" onPress={handleLinkTickets}>
          {t('gen_link_tickets')}
        </Button>
      </HStack>
    </Box>
  );
}

function getNavEventProps({
  navigation,
  productContentKey,
}: {
  navigation: ReturnType<typeof useRootNavigation>;
  productContentKey: Maybe<string>;
}) {
  const params = getCurrentRouteParams(navigation);
  const productId = params?.productId ?? productContentKey;

  if (!productId) return undefined;

  return {
    productId,
    ...(params?.attractionId && {
      attractionId: params?.attractionId,
    }),
  };
}
