import AsyncStorage from '@react-native-async-storage/async-storage';
import Constants from 'expo-constants';
import { AppState, AppStateStatus, Platform } from 'react-native';
import { focusManager, QueryClient as $QueryClient } from 'react-query';
import { createAsyncStoragePersistor } from 'react-query/createAsyncStoragePersistor-experimental';
import { persistQueryClient } from 'react-query/persistQueryClient-experimental';

import { FEATURES, FEATURES_QUERY_KEY } from 'app/hooks/useFeatures';
import Logger from 'app/services/Logger';
import { toMs } from 'app/utils/time';

let instance: $QueryClient;

export enum QueryKeys {
  GuestData = 'getUserData',
  Configuration = 'getConfiguration',
  VerifyEmail = 'verifyEmail',
  AddToWallet = 'addToWallet',
}

export default function QueryClient() {
  if (instance) return instance;

  const queryClient = new $QueryClient({
    defaultOptions: {
      queries: {
        staleTime: toMs(1, 'h'),
        cacheTime: toMs(60, 'd'),
        refetchOnWindowFocus: 'always',
        retry: 10,
        retryDelay: (attemptIdx) =>
          Math.min(Math.floor(1000 * 2 ** attemptIdx * (Math.random() + 0.8)), toMs(30, 's')),
      },
    },
  });

  // https://react-query.tanstack.com/plugins/createAsyncStoragePersistor
  const asyncStoragePersistor = createAsyncStoragePersistor({
    storage: AsyncStorage,
  });

  // https://react-query.tanstack.com/plugins/persistQueryClient
  persistQueryClient({
    queryClient,
    persistor: asyncStoragePersistor,
    buster: Constants.expoConfig?.version || 'dev',
  });

  // fill the cache to make features available outside the component tree
  queryClient.setQueryData(FEATURES_QUERY_KEY, FEATURES);

  // use AppState for focus when not on web
  if (Platform.OS !== 'web') {
    focusManager.setEventListener((handleFocus) => {
      const handler = (state: AppStateStatus) => {
        Logger.debug('[QueryClient] app state change', { state });
        handleFocus(state === 'active');
      };
      const subscription = AppState.addEventListener('change', handler);

      return () => {
        subscription.remove();
      };
    });
  }

  instance = queryClient;

  return instance;
}
