import { Box, Button, Text, VStack } from 'native-base';
import React from 'react';
import { Platform } from 'react-native';

import EntitlementsList from 'app/components/EntitlementsList';
import LinkTicketsPrompt from 'app/components/LinkTicketsPrompt';
import LogoutDialog from 'app/components/LogoutDialog';
import Screen from 'app/components/Screen';
import useAuth from 'app/hooks/useAuth';
import useGuest from 'app/hooks/useGuest';
import useGuestDataQuery from 'app/hooks/useGuestDataQuery';
import useGuestDataRefreshControl from 'app/hooks/useGuestDataRefreshControl';
import { RootScreenProps, RootStackParamList } from 'app/navigation/RootNavigator';
import { useI18n } from 'app/providers/I18nProvider';

export type PurchasesScreenProps = RootScreenProps<RootStackParamList, 'Purchases'>;

export default function PurchasesScreen({ navigation, route }: PurchasesScreenProps) {
  const { email, hasEntitlements, entitlements } = useGuest();
  const userData = useGuestDataQuery();
  const { user } = useAuth();
  const { t } = useI18n();
  const { guestDataRefreshControl, guestDataUpdatedAt } = useGuestDataRefreshControl();

  const showEntitlements = user && email;
  const webRefreshButton =
    Platform.OS === 'web' ? (
      <Button isLoading={userData.isFetching} variant="link" onPress={() => userData.refetch()}>
        {t('app_auth_refresh_purchases')}
      </Button>
    ) : (
      <Box h="0" w="0" />
    );

  const handleEntitlementChange = (newEntitlementKey: string) => {
    setTimeout(() => {
      const productContentKey =
        entitlements.find((ent) => ent.entitlementKey === newEntitlementKey)?.productContentKey ??
        '';
      navigation.replace('Tabs', {
        screen: 'ProductStack',
        params: {
          screen: 'Product',
          params: { productId: productContentKey },
        },
      });
    }, 600);
  };
  const handleLinkTickets = () => {
    navigation.navigate('Authentication', {
      screen: 'AuthenticationStepLinkPasses',
    });
  };

  return (
    <Screen
      after={hasEntitlements ? null : <LinkTicketsPrompt alwaysShow safeInsetBottom />}
      refreshControl={guestDataRefreshControl}
      testID="PurchasesScreen"
    >
      {showEntitlements ? (
        <VStack space="6">
          <EntitlementsList showInvalid onChange={handleEntitlementChange} />

          <Button.Group>
            {webRefreshButton}
            <Button variant="link" onPress={handleLinkTickets}>
              {t('app_auth_tickets_link_new')}
            </Button>
            <LogoutDialog disabled={!user} />
          </Button.Group>
        </VStack>
      ) : (
        <Box>
          <Text>{t('app_auth_tickets_none_linked')}</Text>
        </Box>
      )}

      <Box mt="10">{guestDataUpdatedAt}</Box>
    </Screen>
  );
}
