/* eslint-disable no-param-reassign */
import axios, { AxiosInstance } from 'axios';
import Constants from 'expo-constants';

import { Env } from 'app/hooks/useStore';
import { auth } from 'app/services/Firebase';
import Logger from 'app/services/Logger';

const MODULE = '[GuestCenterClient]';

class GuestCenterApiError extends Error {
  response: unknown;

  constructor(message: string, response: unknown) {
    super(message);
    this.name = 'CitypassApiError';
    this.response = response;
  }
}

export default function GuestCenterClient(): AxiosInstance {
  const client = axios.create({
    baseURL: envToBaseURL(Constants.expoConfig?.extra?.environment),
  });

  client.interceptors.request.use(async (config) => {
    config.baseURL = client.defaults.baseURL;

    if (config.headers) {
      const authToken = await getAuthToken();
      if (authToken) config.headers.Authorization = `Bearer ${authToken}`;
    }

    Logger.debug(`${MODULE} request`, {
      method: config.method?.toUpperCase(),
      url: `${config.baseURL}${config.url}`,
      ...(config.data && { data: config.data }),
      withAuth: !!config.headers?.Authorization,
    });

    return config;
  });

  client.interceptors.response.use(
    (res) => {
      const { config, status, data } = res;

      Logger.debug(`${MODULE} response`, {
        method: config.method?.toUpperCase(),
        url: `${config.baseURL}${config.url}`,
        status,
        // data,
      });

      // handle html error pages with 200 status
      if (typeof data === 'string' && data.includes('<html')) {
        Logger.debug(`${MODULE} response`, { data });
        throw new GuestCenterApiError('unexpected html response', data);
      }

      // handle json error payloads with 200 status
      if (
        typeof data === 'object' &&
        data !== null &&
        'success' in data &&
        data.success === false
      ) {
        Logger.debug(`${MODULE} response`, { data });
        throw new GuestCenterApiError('unsuccessful response', data);
      }

      return res;
    },
    (error) => {
      const level = error.response?.status === 401 ? 'warn' : 'error';
      Logger[level](`${MODULE} response`, {
        method: error?.config.method?.toUpperCase(),
        url: `${error?.config.baseURL}${error?.config.url}`,
        status: error.response?.status,
        data: error.response?.data,
        message: error.message,
        readyState: error.request?.readyState,
      });

      throw error;
    }
  );

  return client;
}

export const setBaseURL = (client: AxiosInstance, env: Env) => {
  // eslint-disable-next-line no-param-reassign
  client.defaults.baseURL = envToBaseURL(env);
};

export const setAuthorizationToken = (client: AxiosInstance, token: string) => {
  if (token) client.defaults.headers.common.Authorization = `Bearer ${token}`;
};

export const setLanguageHeader = (client: AxiosInstance, lang: string) => {
  if (lang) client.defaults.headers.common['Accept-Language'] = lang;
};

function envToBaseURL(env: Env) {
  return Constants.expoConfig?.extra?.guestCenter?.apiBaseURLTemplate.replace('{env}', env);
}

async function getAuthToken(): Promise<string | undefined> {
  try {
    const res = await auth().currentUser?.getIdTokenResult(true);
    return res?.token;
  } catch (error) {
    Logger.error(`${MODULE} token error`, { error });
  }
  return undefined;
}
