import { Locale } from 'app/generated/hygraph';

export function webHome({ locale }: { locale: Locale }) {
  const subdomain = getSubdomainForLocale(locale);
  return `https://${subdomain}.citypass.com`;
}

export function webSupport({ locale }: { locale: Locale }) {
  return `https://support.citypass.com/hc/${locale}`;
}

export function webReviews({ locale }: { locale: Locale }) {
  return `${webHome({ locale })}/reviews`;
}

export function webProduct({ locale, productSlug }: { locale: Locale; productSlug: string }) {
  return `${webHome({ locale })}/${productSlug}`;
}

export function broadway() {
  return 'https://broadway.citypass.com/new-york';
}

export function htmlLink({ url, title, label }: { url: string; label: string; title?: string }) {
  return `<a href="${url}" title="${title ?? label}">${label ?? url}</a>`;
}

export function getSubdomainForLocale(locale: Locale) {
  return locale === 'en' ? 'www' : locale;
}
