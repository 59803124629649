import { Pressable } from 'native-base';
import React, { ReactNode } from 'react';

import Cross from 'app/components/icons/Cross';
import { useI18n } from 'app/providers/I18nProvider';

export type HeaderCloseButtonProps = {
  children?: ReactNode;
  onPress: () => void;
};

export default function HeaderCloseButton({ onPress }: HeaderCloseButtonProps) {
  const { t } = useI18n();
  return (
    <Pressable
      _android={{
        pr: 3,
        pl: 3,
        pt: 3,
        pb: 3,
        mr: -2.5,
        mt: 0,
        rounded: 'full',
        _pressed: {
          backgroundColor: 'rgba(0, 0, 0, 0.2)',
        },
      }}
      _web={{ pr: 4, pl: 4, mr: 0, mt: 0 }}
      accessibilityLabel={`${t<string>('gen_close')} Screen`}
      mr="-4"
      mt="-1"
      pl="6"
      pr="2"
      py="4"
      onPress={onPress}
    >
      <Cross color="white" />
    </Pressable>
  );
}
