/* eslint-disable no-underscore-dangle */
import dayjs from 'dayjs';
import { locale as $locale } from 'expo-localization';
import get from 'lodash/get';
import set from 'lodash/set';

import jsonContent from 'app/assets/content.json';
import { GetContentQuery, Locale, ReservationTextType, Text } from 'app/generated/hygraph';
import { AttractionContent } from 'app/hooks/useAttraction';
import { ProductContent } from 'app/hooks/useProduct';
import type { Store, StoreState } from 'app/hooks/useStore';
import Logger from 'app/services/Logger';
import { isActualObject, isLegacyGetContentQuery, isText } from 'app/utils/guards';

export type JSONContent = {
  [key: string]: Record<Locale, string | string[]> | JSONContent;
};

export type I18nContent = {
  [locale: string]: {
    [key: string]: string | string[];
  };
};

export type TranslationHelper = <T extends string | string[] = string>(
  key: string,
  props?: Record<string, string | number>
) => T;
export type TemplateHelper = (msg: string, props?: Record<string, string | number>) => string;

const reservationTextGroupKey = 'app_reservations';

export const locales: Record<Locale, { label: string; dateFormat: string }> = {
  en: {
    label: 'English',
    dateFormat: 'M/D/YYYY',
  },
  de: {
    label: 'Deutsch',
    dateFormat: 'YYYY-MM-DD',
  },
  es: {
    label: 'Español',
    dateFormat: 'DD/MM/YYYY',
  },
  fr: {
    label: 'Français',
    dateFormat: 'DD/MM/YYYY',
  },
  it: {
    label: 'Italiano',
    dateFormat: 'DD/MM/YYYY',
  },
  ja: {
    label: '日本人',
    dateFormat: 'YYYY年MM月DD日',
  },
  pt: {
    label: 'Português',
    dateFormat: 'DD/MM/YYYY',
  },
  zh: {
    label: '中文',
    dateFormat: 'YYYY-MM-DD',
  },
};

export const initialContent: I18nContent = (function validateJSONContent() {
  if (!isActualObject(jsonContent)) {
    Logger.error('[I18n] content.json is invalid, will use empty object', { jsonContent });
    return {};
  }

  Object.entries(jsonContent).forEach(([locale, values]) => {
    if (typeof locale !== 'string') {
      Logger.warn('[I18n] found unexpected locale format, will be removed', { locale });
      delete jsonContent[locale];
    } else if (!isActualObject(values)) {
      Logger.warn('[I18n] found unexpected locale values, will be removed', {
        locale,
        values,
      });
      delete jsonContent[locale];
    }
    if (!(locale in locales)) {
      Logger.warn('[I18n] found unexpected locale, will be included', { locale });
    }
  });

  return jsonContent as object as I18nContent;
})();

export function getDeviceLocaleWithoutRegion(): string {
  return $locale.substr(0, 2).toLowerCase();
}

export function getInitialLocale(): Locale {
  const deviceLocale = getDeviceLocaleWithoutRegion();
  const defaultLocale = deviceLocale in locales ? (deviceLocale as Locale) : Locale.En;

  return defaultLocale;
}

/**
 * Creates a state aware helper to return messages by key.
 */
export function createTranslationHelper(
  getState: () => Pick<StoreState, 'locale' | 'content' | 'showContentKeys'>
): TranslationHelper {
  return function t<T extends string | string[] = string>(
    key: string,
    props?: Record<string, string | number>
  ): T {
    const { content, locale, showContentKeys } = getState();
    const msg = get(content[locale], key) || get(content.en, key);

    if (!msg) {
      // Logger.warn(`unable to find content`, {
      //   key,
      //   locale,
      // });
      return '' as T;
    }

    const prefix = !showContentKeys ? '' : `${locale}: ${key}: `;

    try {
      if (typeof msg === 'string') {
        return (prefix + expandTpl(msg, { locale, content, props })) as T;
      }
      if (Array.isArray(msg)) {
        return msg.map((item) => expandTpl(item, { locale, content, props })) as T;
      }

      Logger.warn('unexpected msg type', {
        key,
        type: typeof msg,
        msg,
        props,
      });

      return (__DEV__ ? `UNEXPECTED_TYPE::${key}` : '') as T;
    } catch (error) {
      Logger.error('unable to expand template', {
        key,
        msg,
        props,
        error,
      });
      return (__DEV__ ? `ERROR_EXPANDING::${msg}` : msg) as T;
    }
  };
}

export type ReservationTextKey = `${ReservationTextType}`;
export type ReservationTextHelper = ReturnType<typeof createReservationTextTranslationHelper>;

export function createReservationTextTranslationHelper(
  t: TranslationHelper,
  {
    attraction,
    product,
  }: {
    attraction?: AttractionContent;
    product?: ProductContent;
  }
) {
  return (
    key: string,
    opts: {
      [k: string]: unknown;
      fallback?: boolean;
      general?: boolean;
    } = {}
  ): string => {
    const { fallback = true, general = false, ...$props } = opts ?? {};
    const props = Object.entries($props).reduce<Record<string, string | number>>(
      (acc, [k, val]) => {
        if (typeof val === 'string' || typeof val === 'number') acc[k] = val;
        return acc;
      },
      {}
    );

    const attractionSpecific =
      !general && !!attraction && findAttractionReservationText(key, attraction, product, props);
    if (attractionSpecific) return attractionSpecific;

    const productSpecific =
      !general && !!product && findProductReservationText(key, product, props);
    if (productSpecific) return productSpecific;

    const generic = fallback && findGeneralReservationText(key, t, props);
    if (generic) return generic;

    return '';
  };
}

function findAttractionReservationText(
  key: string,
  attraction: AttractionContent,
  product: ProductContent | undefined,
  props: Record<string, string | number>
): string | undefined | null {
  const texts = attraction?.reservation?.texts;
  if (!texts) return null;

  let msg;

  // eslint-disable-next-line no-restricted-syntax, no-unreachable-loop
  for (const text of texts) {
    const { value, products } = text;
    const $key = getTextKey(text);

    if ($key === key && typeof value === 'string') {
      const isProductSpecific = product?.key && products.length > 0;

      // product specific content immediately wins
      if (isProductSpecific) {
        if (products.some(($product) => $product.key === product.key)) {
          msg = value;
          break;
        }
      } else {
        // otherwise store the value and check remaining messages for possible product specific content
        msg = value;
      }
    }
  }

  return msg ? expandTpl(msg, { locale: Locale.En, content: {}, props }) : msg;
}

function findProductReservationText(
  key: string,
  product: ProductContent,
  props: Record<string, string | number>
): string | undefined | null {
  const texts = product?.reservation?.texts;
  if (!texts) return null;
  const msg = texts.find((text) => getTextKey(text) === key)?.value;
  return msg ? expandTpl(msg, { locale: Locale.En, content: {}, props }) : msg;
}

function findGeneralReservationText(
  key: string,
  t: TranslationHelper,
  props: Record<string, string | number>
) {
  return t<string>(`reservations.${key}`, props);
}

/**
 * Creates a state aware helper to expand the provided message.
 */
export function createTemplateHelper(
  getState: () => Pick<Store, 'content' | 'locale'>
): TemplateHelper {
  return function tpl(msg: string, props?: Record<string, string | number>) {
    const { content, locale } = getState();
    return expandTpl(msg, { locale, content, props });
  };
}

export function expandTpl(
  msg: string,
  {
    locale = Locale.En,
    content = {},
    props,
  }: {
    locale?: string;
    content?: Record<string, unknown>;
    props?: Record<string, string | number>;
  }
): string {
  return msg.replace(/\[\[(.+?)\]\]/g, (_, key) => {
    const value = (props && get(props, key)) ?? get(content[locale], key) ?? get(content.en, key);

    // if (__DEV__) Logger.debug('expandTemplate', { tpl, param: key, value });
    if (typeof value === 'undefined') {
      return __DEV__ ? `UNDEFINED::${key}` : '';
    }

    return expandTpl(`${value ?? ''}`, { locale, content, props });
  });
}

export function mapRemoteContentToI18n(remoteContent: GetContentQuery): I18nContent {
  if (!remoteContent?.textGroups) return {};

  const i18n: I18nContent = {};

  if (isLegacyGetContentQuery(remoteContent)) {
    remoteContent.generalReservations.forEach(({ generalText, productText, attractionText }) => {
      [...(generalText ?? []), ...(productText ?? []), ...(attractionText ?? [])].forEach(
        ({ type, value, locale }) => {
          set(i18n, [locale, 'reservations', type], value);
        }
      );
      return i18n;
    });
  }

  remoteContent.textGroups.forEach((group) => {
    group.text.forEach((item) => {
      if (group.key === reservationTextGroupKey && 'key' in item && 'value' in item) {
        if (isText(item)) {
          const { key, value, locale } = item;
          i18n[locale] = i18n[locale] || {};
          set(i18n, [locale, 'reservations', key], value);
          return;
        }
      }

      if (isText(item)) {
        const { key, value, locale } = item;
        i18n[locale] = i18n[locale] || {};
        if (value) i18n[locale][key] = value;
        return;
      }

      if ('key' in item && 'texts' in item && Array.isArray(item.texts)) {
        const { key, texts } = item;
        const locale = texts[0]?.locale ?? Locale.En;

        i18n[locale] = i18n[locale] || {};
        i18n[locale][key] = texts.map((t) => t.value).filter(Boolean) as string[];
      }
    });
  });

  return i18n;
}

export function formatDate(date: string | Date, locale: Locale): string {
  return dayjs(date).format(locales[locale].dateFormat || locales.en.dateFormat);
}

// for backwards compatibility with ReservationText
export function getTextKey(text: Pick<Text, 'key' | 'value'>) {
  return text.key ?? (text as unknown as { type: string })?.type;
}
