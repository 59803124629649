import { Box, HStack, Text, VStack } from 'native-base';
import React from 'react';

import AppDownloadButton from 'app/components/AppDownloadButton';
import TextLink from 'app/components/TextLink';
import { useI18n } from 'app/providers/I18nProvider';
import { tID } from 'app/services/TestHelper';

export type AppDownloadPromptProps = {
  onContinue: () => void;
};

export default function AppDownloadPrompt({ onContinue }: AppDownloadPromptProps) {
  const { t } = useI18n();

  return (
    <Box testID={tID('AppDownloadPrompt')} w="full">
      <VStack bg="gray.200" justifyContent="center" p="4" space="2" w="full">
        <Text bold textAlign="center">
          {t('app_welcome_download_title')}
        </Text>

        <Text textAlign="center">{t('app_welcome_download_body')}</Text>

        <HStack alignItems="center" justifyContent="center" mt="2" space="2" w="full">
          <AppDownloadButton height={35} platform="ios" />
          <AppDownloadButton height={35} platform="android" />
        </HStack>
      </VStack>

      <Box mt="4">
        <Text textAlign="center">{t('app_welcome_download_web')}</Text>
        <TextLink textAlign="center" onPress={onContinue}>
          {t('app_welcome_download_web_cta')}
        </TextLink>
      </Box>
    </Box>
  );
}
