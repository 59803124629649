import { OfferCardProps } from 'app/components/OfferCard';
import { ImagePropsFragment, Locale, ProductType } from 'app/generated/hygraph';
import { ProductContent } from 'app/hooks/useProduct';
import {
  getOffers as getAttractionOffers,
  getSharedAdmissionOffers,
} from 'app/services/AttractionHelper';
import { expandTpl } from 'app/services/I18n';
import { webHome } from 'app/services/LinkHelper';
import { cdn, ImageTransform } from 'app/utils/image';

type Image = {
  url: string;
  width: number;
  height: number;
  ratio: number;
};

export function getUrl(product: Maybe<ProductContent>, locale: Locale = Locale.En): string {
  return `${webHome({ locale })}/${product?.slug}`;
}

export function getCityName(product: Maybe<ProductContent>): string {
  return product?.cityName?.value ?? '';
}

export function getHero(product: Maybe<ProductContent>, transforms?: ImageTransform[]): Image {
  return getImage(product, 'hero', transforms);
}

export function getLogo(product: Maybe<ProductContent>): Image {
  return getImage(product, 'logo', [['output', { format: 'png', density: 200 }]]);
}

export function getLogoWhite(product: Maybe<ProductContent>): Image {
  return getImage(product, 'logoWhite', [['output', { format: 'png', density: 200 }]]);
}

export function getTicketsBackground(product: Maybe<ProductContent>): Maybe<ImagePropsFragment> {
  return product?.reservation?.ticketsBackground ?? product?.hero ?? null;
}

export function getFAQs(product: Maybe<ProductContent>) {
  return (product?.faqs?.texts ?? []).map(({ value }) => {
    return expandTpl(value ?? '', {
      props: {
        num_days: product?.validDays ?? 0,
        product_id: product?.key ?? '',
      },
    });
  });
}

export function hasFAQs(product: Maybe<ProductContent>) {
  return product?.type !== ProductType.Alacarte;
}

export function hasBroadway(product: Maybe<ProductContent>) {
  return /^new-york/.test(product?.key ?? '');
}

export function getOffers(product: Maybe<ProductContent>) {
  return product?.attractions.flatMap((attr) => getAttractionOffers(attr)) ?? [];
}

export function hasOffers(product: Maybe<ProductContent>) {
  return getOffers(product).length > 0;
}

export function getOptionTicketAdmissionOffers(product: Maybe<ProductContent>) {
  return (
    product?.attractions.flatMap((attr) =>
      getSharedAdmissionOffers(attr).map((offer) => ({
        ...offer,
        $attraction: attr,
      }))
    ) ?? []
  );
}

type OfferDisplayGroup = {
  id: string;
  attractionContentKey: string;
  title: string;
  offers: OfferCardProps['offer'][];
  theme?: OfferCardProps['theme'];
};

export function getOfferDisplayGroups(product: Maybe<ProductContent>): OfferDisplayGroup[] {
  const groups = [];

  const optionOffers = getOptionTicketAdmissionOffers(product);
  if (optionOffers.length > 0) {
    const optionGroup: OfferDisplayGroup = {
      id: 'optionTickets',
      attractionContentKey: '',
      title: 'Option Ticket Admission Discounts',
      offers: optionOffers.map((offer) => ({
        ...offer,
        title: offer.$attraction.name ?? '',
        subtitle: offer.title ?? '',
      })),
      theme: 'highlight',
    };
    groups.push(optionGroup);
  }

  product?.attractions.forEach((attr) => {
    const offers = getAttractionOffers(attr);

    if (offers.length > 0) {
      groups.push({
        id: attr.key ?? '',
        attractionContentKey: attr.key ?? '',
        title: attr.name ?? '',
        offers: getAttractionOffers(attr),
      });
    }
  });

  return groups;
}

function getImage(
  product: Maybe<ProductContent>,
  prop: 'hero' | 'logo' | 'logoWhite' | 'logoTicket',
  transforms: ImageTransform[] = []
): Image {
  const $width = product?.[prop]?.width ?? 1;
  const height = product?.[prop]?.height ?? 1;
  return {
    url: cdn(product?.[prop]?.url ?? '', transforms),
    width: $width,
    height,
    ratio: $width / height,
  };
}
