import { Box, Fab, Text, VStack } from 'native-base';
import React, { ReactNode } from 'react';
import { shallow } from 'zustand/shallow';

import { ProgrammingBrowser } from 'app/components/icons';
import { Stage } from 'app/generated/hygraph';
import useRootNavigation from 'app/hooks/useRootNavigation';
import useStore from 'app/hooks/useStore';
import { tID } from 'app/services/TestHelper';

export type DevToolsFABProps = {
  children?: ReactNode;
};

export default function DevToolsFAB({ children }: DevToolsFABProps) {
  const [devTools, cmsStage] = useStore((s) => [s.devTools, s.cmsStage], shallow);
  const navigation = useRootNavigation();
  const isDraft = cmsStage === Stage.Draft;

  if (!devTools) return null;

  return (
    <Box>
      <Fab
        bgColor={isDraft ? 'purple.600' : 'gray.900'}
        label={
          <VStack
            alignItems="center"
            overflow="hidden"
            px={isDraft ? 1 : 2}
            py="2"
            space="1"
            w="12"
          >
            <ProgrammingBrowser key="icon" color="white" size={24} />
            {cmsStage === Stage.Draft && (
              <Text key="cmsStage" color="white" fontSize="xs" lineHeight="xs" numberOfLines={1}>
                DRAFT
              </Text>
            )}
          </VStack>
        }
        mt="-20px"
        p="0"
        placement="top-right"
        right="0"
        roundedLeft="sm"
        roundedRight="none"
        testID={tID('DevToolsFAB')}
        top="1/2"
        onPress={() => {
          navigation.navigate('DevTools');
        }}
      />
    </Box>
  );
}
