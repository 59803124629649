import { createEntitlement } from 'app/services/EntitlementHelper';

export interface Configuration<Type> {
  count: number;
  configuration: Type[];
  configurationHash: string;
}

export type ConfigurationResponse = {
  attractions: AttractionsResponse;
  barcodes: BarcodeResponse;
  offline: OfflineResponse;
  productLineups: ProductLineupResponse;
  minAppVersion: string;
  install: InstallResponse;
};

export type CacheData = {
  attractions: AttractionsResponse;
  barcodes: BarcodeResponse;
  offline: OfflineResponse;
  productLineups: ProductLineupResponse;
};

// barcodes ----------------------------------------------------------------------------------------------------
export type BarcodeResponse = Configuration<BarcodeConfig>;

export type BarcodeConfig = {
  length: number;
  prefix: string;
};

// offline attractions -----------------------------------------------------------------------------------------
export type OfflineResponse = Configuration<OfflineConfig>;

export type OfflineConfig = {
  attractionCode: string;
  // TODO date / timezone handling review
  offlineFrom: string;
  // TODO date / timezone handling review
  offlineTo: string;
};

// attractions -------------------------------------------------------------------------------------------------
export type AttractionsResponse = Configuration<AttractionConfig>;

export type InstallResponse = {
  appStoreUrl: string;
  playStoreUrl: string;
  firebaseTemplate: string;
};

export enum ReservationRequirement {
  Required = 'required',
  NotRequired = 'notrequired',
  Recommended = 'recommended',
}

export enum ReservationModel {
  None = 'none',
  Phone = 'phone',
  Redirect = 'redirect',
  Integrated = 'integrated',
}

export type AttractionConfig = {
  attractionKey: string;
  contentKey: string;
  cityCode: string;
  ianaTimezone: string;
  productLineupKeys: string[];
  isClosed: boolean;
  reservationRequirement: ReservationRequirement;
  reservationModel: ReservationModel;
  ticketingSystem: Maybe<TicketingSystem>;
};

export type TicketingSystem = {
  online: boolean;
  redirectURL: string;
  isPhone: boolean;
  search?: Search;
  reserve?: Reserve;
  cancel?: Cancel;
  options: ReservationOption[];
  customerDataItems: CustomerDataItem[];
  showOrderBarcode: boolean;
};

export type Search = {
  minutesOut: number;
  daysOutMin?: number;
  daysOutMax?: number;
  increments: Increment[];
};

export type Increment = {
  incrementKey: string;
  contentKey: string;
  startTime: string;
  endTime: string;
  isOpen: boolean;
  isClose: boolean;
  isDefault: boolean;
};

export type Reserve = {
  allowHold: boolean;
};

export type Cancel = {
  allowCancel: boolean;
  minutesOut: number;
  daysOut?: number;
};

export type ReservationOption = {
  optionKey: string;
  contentKey: string;
  optionType: string;
  familyCode: ProductFamily;
  priority: number;
};

export enum ProductFamily {
  Core = 'core',
  CTicket = 'cticket',
  Alacarte = 'alacarte',
}

export type CustomerDataItem = {
  itemKey: string;
  contentKey: string;
  isRequired: boolean;
  itemType: Maybe<string>;
};

// product lineups  --------------------------------------------------------------------------------------
export type ProductLineupResponse = Configuration<ProductLineup>;

export type ProductLineupConfiguration = {
  count: number;
  products: ProductLineup[];
};

export enum GroupType {
  Single = 'single',
  Choice = 'choice',
  Cticket = 'cticket',
  Split = 'split',
}

export enum ReservationType {
  Day = 'day',
  Time = 'time',
}

export type GroupEntitlement = {
  maxVisits: number;
  productCode: Maybe<string>;
};

export type AttractionGroup = {
  type: GroupType | string;
  attractions: LineupAttraction[];
  entitlements: GroupEntitlement[];
};

export type LineupAttraction = {
  attractionKey: string;
  contentKey: string;
  reservationType?: ReservationType;
  optionKeys?: string[];
  customerDataItemsKeys?: string[];
};

export type ProductLineup = {
  productLineupKey: string;
  contentKey: string;
  cityCode: string;
  year: number;
  usageWindowDays: number;
  familyCode: ProductFamily;
  productCodes: string[] | undefined;
  isCurrent: boolean;
  attractionGroups: AttractionGroup[];
};

// user data -----------------------------------------------------------------------------------------
export type GuestDataResponse = {
  success: boolean;
  message: string;
  guestData: GuestData;
  cacheData: CacheData;
};

export type GuestData = {
  email: string;
  reservations: Reservation[];
  travelParties: TravelParty[];
  passes: Pass[];
};

export enum TicketClass {
  Adult = 'Adult',
  Child = 'Child',
}

export enum ValidStates {
  Valid = 'valid',
  Invalid = 'invalid',
}

export type Reservation = {
  reservationKey: string;
  attractionOrderNumber: string;
  display: string;
  scan: string;
  reservationDateTime: string;
  attractionKey: string;
  barcodes: string[];
  optionKey?: string;
};

export type TravelParty = {
  locator: string;
  orderNumber: string;
  firstUsageBy: string;
  status: ValidStates;
  statusContentKeys: ContentKey[];
};

export type ContentKey = {
  key: string;
  count: number;
};

export type EntitlementReservation = Reservation & {
  entitlementKey: string;
  passes: Pass[];
  attractionConfig: AttractionConfig;
  productLineup: ProductLineup;
  option: ReservationOption | undefined;
  type: ReservationType | undefined;
};

export type Ticket = {
  attractionKey: string;
  reservationKey?: string;
  barcode?: string;
  scan?: string;
  display?: string;
};

export type Pass = {
  name: string;
  barcode: string;
  scan?: string;
  display?: string;
  ticketClass: TicketClass;
  validFrom: string;
  validTo: string;
  locator?: string;
  productLineupKey: string;
  productCode: Maybe<string>;
  status: ValidStates;
  statusContentKey: Maybe<string>;
  tickets: Ticket[];
};

export type Entitlement = ReturnType<typeof createEntitlement>;

export type UserVerificationResponse = {
  success: boolean;
  actionCode: string;
  errorContentCode: string;
};

export type AuthTokenVerificationResponse = {
  success: boolean;
  actionCode: AuthTokenActionCode;
  email: string;
  token: string;
  locator: string;
  cityPassEmail: string | null;
};

export enum AuthTokenActionCode {
  ReserveOnBehalfOf = 'reserve_on_behalf_of',
  SignIn = 'sign_in',
  VerifyEmail = 'verify_email',
  SignInAsCitypassUser = 'sign_in_as_citypass_user',
}
