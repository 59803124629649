import noop from 'lodash/noop';
import pick from 'lodash/pick';
import { createContext, ReactNode, useEffect, useMemo } from 'react';
import { AppState, AppStateStatus, Platform } from 'react-native';
import { useMutation, UseMutationResult } from 'react-query';
import { shallow } from 'zustand/shallow';

import useGuestDataQuery from 'app/hooks/useGuestDataQuery';
import useStore, { StoreState, VerificationState } from 'app/hooks/useStore';
import Analytics from 'app/services/Analytics';
import { auth } from 'app/services/Firebase';
import { signInWithEmail } from 'app/services/GuestCenterService';
import { UserVerificationResponse } from 'app/services/GuestCenterService.types';
import Logger from 'app/services/Logger';
import { QueryKeys } from 'app/services/QueryClient';

const MODULE = '[AuthProvider]';

export type AuthContextProps = {
  user: StoreState['user'];
  isVerified: boolean;
  verificationState: StoreState['verificationState'];
  verificationProcessing: StoreState['verificationProcessing'];
  verifyEmail: UseMutationResult<UserVerificationResponse, unknown, { email: string }, void>;
  getToken: () => Maybe<string> | Promise<string>;
  signOut: () => void;
};

export const AuthContext = createContext<AuthContextProps>({
  user: null,
  getToken: () => null,
  signOut: () => Promise.resolve(),
  isVerified: false,
  verifyEmail: {
    reset: noop,
    mutate: noop,
    mutateAsync: noop,
  } as AuthContextProps['verifyEmail'],
  verificationState: VerificationState.Unverified,
  verificationProcessing: false,
});

export type AuthProviderProps = {
  children: ReactNode;
};

export default function AuthProvider({ children }: AuthProviderProps) {
  const { remove: clearGuestData } = useGuestDataQuery({
    notifyOnChangeProps: [],
  });
  const {
    locale,
    user,
    accessToken,
    authMode,
    setVerificationState,
    setEmail,
    verificationState,
    verificationProcessing,
    reset,
  } = useStore(
    (s) =>
      pick(s, [
        'locale',
        'email',
        'user',
        'authMode',
        'accessToken',
        'setVerificationState',
        'setEmail',
        'verificationState',
        'verificationProcessing',
        'reset',
      ]),
    shallow
  );

  const verifyEmail = useMutation(
    QueryKeys.VerifyEmail,
    async ({ email }: { email: string }) => {
      const res = await signInWithEmail(email, locale);
      if (res.data?.success !== true) throw res;
      return res.data;
    },
    {
      onMutate: ({ email }) => {
        Logger.debug(`${MODULE} verify email sign in starting`, {
          email,
          uid: auth().currentUser?.uid,
        });
      },
      onSettled: () => {
        Logger.debug(`${MODULE} verify email sign in settled`, {});
      },
      onSuccess: (data, { email }) => {
        Logger.debug(`${MODULE} verify email sign in success`, {
          email,
          data,
        });

        setVerificationState(VerificationState.Pending);
        setEmail(email);
      },
      onError: (err, { email }) => {
        Logger.error(`${MODULE} verify email sign in error`, { email, err });
      },
    }
  );

  const context = useMemo(
    () => ({
      user,
      isVerified: user?.emailVerified ?? false,
      verificationState,
      verificationProcessing,
      getToken: () => user?.getIdToken() ?? accessToken,
      signOut: () => {
        auth().signOut();
        clearGuestData();
        Logger.debug('[AuthProvider] signOut clear guest data');
        reset();
      },
      verifyEmail,
    }),
    [
      accessToken,
      clearGuestData,
      reset,
      user,
      verificationProcessing,
      verificationState,
      verifyEmail,
    ]
  );

  useEffect(() => {
    const unsubscribe = auth().onAuthStateChanged((currentUser) => {
      const { user: $user, setUser, setAccessToken, resetVerificationEmail } = useStore.getState();

      if (currentUser) {
        Logger.debug('[useAuth] signed in', { user: currentUser });
        if (currentUser.uid) Analytics.setUserId(currentUser.uid);
        if ($user?.uid !== currentUser?.uid || $user.emailVerified !== currentUser.emailVerified) {
          setUser(currentUser);
          currentUser.getIdToken().then((token) => {
            setAccessToken(token);
          });
        }
      } else {
        Logger.debug('[useAuth] signed out', { user: currentUser });
        if ($user) {
          resetVerificationEmail();
        }
      }
    });
    return unsubscribe;
  }, []);

  useEffect(() => {
    if (Platform.OS === 'web') {
      const handleChange = (nextState: AppStateStatus) => {
        if (nextState === 'active') {
          auth()
            .currentUser?.reload()
            .then(() => {
              if (auth().currentUser?.emailVerified) {
                setVerificationState(VerificationState.Verified);
              }
            });
        }
      };

      const subscription = AppState.addEventListener('change', handleChange);
      return () => {
        subscription.remove();
      };
    }
    return noop;
  }, [authMode, setVerificationState]);

  return <AuthContext.Provider value={context}>{children}</AuthContext.Provider>;
}
